<template>
    <div class="results">
        <div class="row">
            <div class="col-8 results-container">
                <div class="card box-content">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8">
                                <h3>{{ init.title }}</h3>
                            </div>
                            <div class="col-2 floating_label">
                                <datepicker v-model="init.start_month" minimum-view="month" :use-utc="true" format="MMM yyyy" input-class="form-control" ></datepicker>
                                <label>From</label>
                            </div>
                            <div class="col-2 floating_label">
                                <datepicker v-model="init.start_month" minimum-view="month" :use-utc="true" format="MMM yyyy" input-class="form-control" ></datepicker>
                                <label>To</label>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Metric</th>
                                    <th scope="col">Projection</th>
                                    <th scope="col">Actual</th>
                                    <th scope="col">Variance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>CPM</td>
                                    <td>$4.23</td>
                                    <td>$3.45</td>
                                    <td class="positive-4">-41%</td>
                                </tr>
                                <tr>
                                    <td>Click Rate</td>
                                    <td>1.3%</td>
                                    <td>1.4%</td>
                                    <td class="positive-1">8%</td>
                                </tr>
                                <tr>
                                    <td>Email Sign Up Rate</td>
                                    <td>43%</td>
                                    <td>35%</td>
                                    <td class="negative-2">-19%</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col">
                                <a :href="'/results/' + client.url + '/projections'" class="btn btn-secondary" style="text-decoration: none;"><i class="fa fa-check"></i> Finished</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-10">
                                <h4>Assigned Results</h4>
                            </div>
                            <div class="col text-right">
                                <button type="button" class="btn-sm btn-secondary"
                                    @click="modal_version='Result'"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <div class="card box-content" v-if="init.results_filter == null || init.results_filter.length == 0">
                            <div class="card-body">
                                None Added Yet
                            </div>
                        </div>
                        <div class="accordion" v-else>
                            <div class="card box-content" v-for="result in init.results_filter">
                                <div class="row">
                                    <div class="col-9">
                                        <button class="btn btn-link text-left collapsed" type="button" data-toggle="collapse" :data-target="'#result-' + result.id"  aria-controls="collapseOne">
                                            <strong>{{ result.data_source | propercase }}</strong>: {{result.description}}
                                        </button>
                                    </div>
                                    <div class="col text-right">
                                        <button type="button" class="btn-xs btn-none" @click="editResult(result)"><i class="fa fa-edit"></i></button>
                                        <button type="button" class="btn-xs btn-none" @click="deleteResult(result)" ><i class="fa fa-remove"></i></button>
                                    </div>
                                </div>
                                <div :id="'result-' + result.id" class="collapse card-body" v-if="result.data_source == 'manual'">
                                    <strong>Date:</strong> {{result.date | date}}<br>
                                    <strong>Emails:</strong> {{result.emails | number_with_zero}}<br>
                                    <strong>Gifts:</strong> {{result.gifts | number_with_zero}}<br>
                                    <strong>New Donors:</strong> {{result.new_donors | number_with_zero}}<br>
                                    <strong>Revenue:</strong> {{result.revenue | currency_with_zero}}<br>
                                    <strong>Recurring Revenue:</strong> {{result.recurring_revenue | currency_with_zero}}
                                </div>
                                <div :id="'result-' + result.id" class="collapse card-body" v-else >
                                    <strong>Filter Logic:</strong><br>
                                    <div style="line-height: 2.4em;" v-html="logicToString(result.value.filter_logic, result.value.logic_units)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:30px;">
                    <div class="col">
                        <div class="row">
                            <div class="col-10">
                                <h4>Assigned Costs</h4>
                            </div>
                            <div class="col text-right">
                                <button type="button" class="btn-sm btn-secondary"
                                    @click="modal_version='Cost'"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <div class="card box-content" v-if="init.costs_filter == null || init.costs_filter.length == 0">
                            <div class="card-body">
                                None Added Yet
                            </div>
                        </div>
                        <div class="accordion" v-else>
                            <div class="card box-content" v-for="cost in init.costs_filter">
                                <div class="row">
                                    <div class="col-9">
                                        <button class="btn btn-link text-left collapsed" type="button" data-toggle="collapse" :data-target="'#cost-' + cost.id"  aria-controls="collapseOne">
                                            <strong>{{ cost.data_source | propercase }}</strong>: {{cost.description}}
                                        </button>
                                    </div>
                                    <div class="col text-right">
                                        <button type="button" class="btn-xs btn-none" @click="editCost(cost)"><i class="fa fa-edit"></i></button>
                                        <button type="button" class="btn-xs btn-none" @click="deleteCost(cost)" ><i class="fa fa-remove"></i></button>
                                    </div>
                                </div>
                                <div :id="'cost-' + cost.id" class="collapse card-body" v-if="cost.data_source == 'manual'">
                                    Total: {{cost.amount | currency_with_zero}}<br>
                                    Date: {{cost.date | date}}<br>
                                    Cost Type: {{cost.cost_type | propercase}}
                                </div>
                                <div :id="'cost-' + cost.id" class="collapse card-body" v-else >
                                    <strong>Filter Logic:</strong><br>
                                    <div style="line-height: 2.4em;" v-html="logicToString(cost.value.filter_logic, cost.value.logic_units)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cost-result-modal :modal_version.sync="modal_version" :initiative.sync="init" :client="client" :filter="filter"  v-if="modal_version != null"></cost-result-modal>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: { Datepicker },

        props: ['initiative', 'client'],
        data() {
            return {
                init: null,
                modal_version: null,
                filter: {
                    data_source: "manual",
                    title: "",
                    value: null,
                    date: null,
                    cost_type: 'media_cost',
                    emails: 0,
                    gifts: 0,
                    revenue: 0,
                    new_donors: 0,
                    recurring_revenue: 0,
                    id: null,
                    exclude_other_initiatives: false
                },
                errors :{
                    date: "",
                    title: "",
                    value: ""
                },
                form: {
                    error: false
                },
            };
        },
        watch: {
            init(old_pv, new_pv) {
                //Reset the filter if the initiative gets updated
                this.resetFilter();
            },
            modal_version(old_pv, new_pv) {
                //If they closed the modal, then wipe the filter
                if(old_pv == null)
                    this.resetFilter();
            }
        },
        beforeMount() {
            this.init = this.initiative;
        },
        methods: {
            resetFilter() {
                this.filter.data_source = "manual";
                this.filter.title = "";
                this.filter.value = null;
                this.filter.date = null;
                this.filter.cost_type = 'media_cost';
                this.filter.emails=  0;
                this.filter.gifts= 0;
                this.filter.revenue=  0;
                this.filter.new_donors=  0;
                this.filter.recurring_revenue= 0;
                this.filter.id = null;
                this.filter.exclude_other_initiatives = false;
            },
            editCost(cost) {
                this.filter.data_source = cost.data_source;
                this.filter.title = cost.description;

                this.filter.date = cost.date;
                this.filter.cost_type = cost.cost_type;
                this.filter.id = cost.id;
                if(this.filter.data_source == 'manual')
                    this.filter.value = cost.amount;
                else
                    this.filter.value = cost.value;

                this.modal_version='Cost';
            },
            editResult(result) {
                this.filter.data_source = result.data_source;
                this.filter.title = result.description;
                this.filter.value = result.value;
                this.filter.date = result.date;
                this.filter.id = result.id;
                this.filter.emails = result.emails;
                this.filter.gifts = result.gifts;
                this.filter.revenue = result.revenue;
                this.filter.new_donors = result.new_donors;
                this.filter.recurring_revenue = result.recurring_revenue;
                this.filter.exclude_other_initiatives = result.exclude_other_initiatives;
                this.modal_version='Result';
            },

            deleteCost(cost) {
                var self = this;
                cost.initiative_id = this.initiative.id;
                window.axios.post('/api/cost/delete/' + cost.id, cost).then(response => {
                    if(response.status == 200) {
                        self.form.successful = true;
                        self.modal_version = null;

                        self.init = response.data.initiative;
                        self.$emit('update:initiative', self.init);
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                  });
            },
            //When deleting results, we have to pass the actual result so it can find the initiative ID
            deleteResult(result) {
                var self = this;

                result.initiative_id = this.initiative.id;
                //I have to do a "post" instead of "delete" since I have to pass the initiative_id
                // in cases where it isn't an actual "Result" object
                window.axios.post('/api/result/delete/' + result.id, result).then(response => {
                    if(response.status == 200) {
                        self.form.successful = true;
                        self.modal_version = null;

                        self.init = response.data.initiative;
                        self.$emit('update:initiative', self.init);
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                  });
            },
            logicToString(logic, units) {
                var arr = [];
                for(var i = 0; i < units.length; i++) {

                    var str = "<code>" + units[i].db_column.text + " " + units[i].operator + " ";

                    //if it is a static date, just format it
                    if(units[i].db_column.type == 'date' && units[i].is_static)
                        str += this.$options.filters.date(units[i].value);
                    else if(units[i].db_column.type == 'float64' && !isNaN(units[i].value))
                        str += this.$options.filters.currency(units[i].value);
                    else if(units[i].db_column.type == 'int' && !isNaN(units[i].value))
                        str += this.$options.filters.number(units[i].value);
                    else if(typeof units[i].value === 'object' && units[i].value !== null)
                        str += units[i].value.text;
                    else if(typeof units[i].operator === "string" &&  units[i].operator.indexOf("NULL") == -1)
                        str += units[i].value;
                    else if(typeof units[i].operator !== "string" && units[i].operator.value != null && units[i].operator.value.indexOf("NULL") == -1)
                        str += units[i].value;


                    arr.push(str+ "</code>");
                }
                for(var i = 0; i < arr.length; i++) {
                    var str_num = String(i+1);
                    logic = logic.replaceAll(str_num, arr[i])
                }
                return logic;
            }

        }
    }
</script>
