var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scorecard-template" }, [
    _vm.chart_selected.data
      ? _c("div", { staticClass: "mt-1" }, [
          _vm.url != null
            ? _c("img", {
                staticClass: "float-start me-3",
                attrs: { id: "scorecard-image", src: _vm.url },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.current_value) + "\n            "),
            _vm.variance != null
              ? _c(
                  "span",
                  {
                    staticClass: "badge ms-2",
                    class: {
                      "bg-label-success": _vm.variance >= 0,
                      "bg-label-danger": _vm.variance < 0,
                    },
                  },
                  [_vm._v(_vm._s(_vm._f("percentage_na")(_vm.variance)))]
                )
              : _vm._e(),
          ]),
        ])
      : _c("div", { staticClass: "d-inline-block text-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "text-secondary_v1 mb-1" }, [
      _c("i", { staticClass: "fa-solid fa-chart-simple" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-secondary_v1 mb-2" }, [
      _c("em", [
        _vm._v("Press the "),
        _c("strong", [_vm._v("Preview")]),
        _vm._v(" button to render the chart"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }