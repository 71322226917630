var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.id,
        "data-bs-backdrop": "static",
        "aria-labelledby": "support-modal",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Type of Request"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.category,
                        expression: "form.category",
                      },
                    ],
                    staticClass: "form-select",
                    class: _vm.errors.category != "" ? "is-invalid" : "",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "category",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          _vm.errors.category = ""
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("** Please Select **"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "BILLING_ISSUE" } }, [
                      _vm._v("Billing / Pricing"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "FEATURE_REQUEST" } }, [
                      _vm._v("Feature Request"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "GENERAL_INQUIRY" } }, [
                      _vm._v("General Question"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "PRODUCT_ISSUE" } }, [
                      _vm._v("Report a Bug"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "SETUP_HELP" } }, [
                      _vm._v("Setup Assistance"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.errors.category != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.category) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.form.category == "PRODUCT_ISSUE"
                ? _c("div", { staticClass: "col-sm-9 mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Level of Urgency"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.urgency,
                            expression: "form.urgency",
                          },
                        ],
                        staticClass: "form-select",
                        class: _vm.errors.urgency != "" ? "is-invalid" : "",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "urgency",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              _vm.errors.urgency = ""
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "LOW" } }, [
                          _vm._v('Low - "Something looks off"'),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "MEDIUM" } }, [
                          _vm._v(
                            'Medium - "Something\'s broken but I can work around it."'
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "HIGH" } }, [
                          _vm._v(
                            'High - "It\'s broken and I need it fixed ASAP."'
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.urgency != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.errors.urgency) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Quick Summary"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.title,
                      expression: "form.title",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.title != "" ? "is-invalid" : "",
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.title },
                  on: {
                    change: function ($event) {
                      _vm.errors.title = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "title", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.title != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.title) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm.form.category == "PRODUCT_ISSUE"
                    ? _c("span", [_vm._v("Description of the Issue")])
                    : _vm.form.category == "FEATURE_REQUEST"
                    ? _c("span", [_vm._v("Description of the Idea")])
                    : _c("span", [_vm._v("More Details")]),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.description,
                      expression: "form.description",
                    },
                  ],
                  staticClass: "form-control full-height",
                  class: _vm.errors.description != "" ? "is-invalid" : "",
                  staticStyle: { "min-height": "8 0px" },
                  domProps: { value: _vm.form.description },
                  on: {
                    change: function ($event) {
                      _vm.errors.description = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "description", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.description != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.description) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row footer" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary me-3",
                    attrs: { disabled: _vm.working },
                    on: {
                      click: function ($event) {
                        return _vm.submitTicket()
                      },
                    },
                  },
                  [
                    _vm.working
                      ? _c("span", [_vm._m(1), _vm._v(" Working...")])
                      : _c("span", [_vm._v("Submit")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: {
                      "data-bs-dismiss": "modal",
                      disabled: _vm.working,
                    },
                  },
                  [_vm._v("Cancel")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
      _c("div", { staticClass: "text-center w-100" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-none float-end close-modal",
            attrs: { type: "button", "data-bs-dismiss": "modal" },
          },
          [_c("i", { staticClass: "fa fa-close me-0" })]
        ),
        _vm._v(" "),
        _c("h4", { staticClass: "mt-2" }, [_vm._v("Support / Suggestions")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-white float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }