<template>
<div class="query-inclusion-builder query-inclusion-revamp">

    <div class="row ">
        <div class="col-12 logic-grouping" v-if="data_filter.logic_units.length > 0" :class="{inverted: inverted, white: is_white}" v-for="(unit, index) in nested_units">
            <div class="dropdown" v-if="index==0" id="toggle-logic" >
                <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >{{inverted? "OR" : "AND"}}</button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" v-on:click.prevent="setInverted(false)">AND</a></li>
                    <li><a class="dropdown-item" href="#" v-on:click.prevent="setInverted(true)">OR</a></li>
                </ul>
            </div>
            <div >
                <div class="row ms-0 pb-2" v-for="el in unit"  v-bind:class="{ 'is-invalid' : hasLogicUnitError(data_filter.logic_units[el]) && errors.logic_units[el]}">
                    <div class="col-lg-3 pb-2">
                        <v-select :options="db_columns" v-model="data_filter.logic_units[el].db_column"
                            :searchable="true" class="searchable-select column-display "
                            :selectable="(option) => option.text != ''"
                            @search="fetchOptions" label="text"  :filterable="false" data-bs-toggle="tooltip" 
                            :title="(data_filter.logic_units[el] && data_filter.logic_units[el].db_column.category) ?  data_filter.logic_units[el].db_column.category + ': ' + data_filter.logic_units[el].db_column.text : data_filter.logic_units[el].db_column.text">
                            <template #selected-option="{ text, category }">
                              {{(category != null && category != "" && text!=null && text!="") ? category + ": " : ""}}{{text}}
                            </template>
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                            </template>

                            <template #option="{ text, category }">
                              <div v-if="text == ''" :data-category="category" class="category-header"
                                v-on:click="return expandCategory(category, null)"
                                data-isexpanded="false">
                                {{ category }} <i class="fa-solid fa-caret-right"></i>
                              </div>
                              <div v-else class="suboption" :data-subcategory="category"
                                :class="category==null || category==''? 'show' : ''">
                                {{ text }}
                              </div>
                            </template>
                            <template #no-options="{ search, searching, loading }">
                                <div class="suboption show" v-if="is_loading">
                                    <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                </div>
                                <div class="suboption show" v-else>
                                    <em>No results found</em>
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div class="col-lg-3 pb-2 operations" >
                        <v-select :options="operator_options"
                            v-model="data_filter.logic_units[el].operator"
                            :selectable="(option) => option.text != ''" label="text"
                            @input="updateOperator(data_filter.logic_units[el]);">

                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                            </template>

                            <template #option="{ text, category }">
                              <div v-if="text == ''" :data-category="category" class="category-header"
                                v-on:click="return expandCategory(category, null)"
                                :data-isexpanded="true">
                                {{ category }} <i class="fa-solid fa-caret-down"></i>
                              </div>
                              <div v-else class="suboption expanded" :data-subcategory="category">
                                {{ text }}
                              </div>
                            </template>
                        </v-select>
                    </div>
                    <div  class="col-lg-5 pb-2 text-center" v-if="shouldShowValues(data_filter.logic_units[el].operator)">
                        <div class="input-group">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" v-bind:disabled="data_filter.logic_units[el].db_column==''" v-if="data_filter.logic_units[el].operator == null || (data_filter.logic_units[el].operator.value != 'IN' && data_filter.logic_units[el].operator.value != 'NOT IN')">
                                {{ typeOfSelection(data_filter.logic_units[el]) }}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" v-if="data_filter.logic_units[el].operator == null || (data_filter.logic_units[el].operator.value != 'IN' && data_filter.logic_units[el].operator.value != 'NOT IN')">
                                <li><a class="dropdown-item" href="#"
                                    v-on:click.prevent="data_filter.logic_units[el].is_static = false; data_filter.logic_units[el].show_options = false; data_filter.logic_units[el].value = '';">Compare to Column</a></li>
                                <li>
                                    <a class="dropdown-item" href="#"
                                        v-if="data_filter.logic_units[el].data_type!='datetime'"
                                        v-on:click.prevent="data_filter.logic_units[el].is_static = true; data_filter.logic_units[el].show_options = true; data_filter.logic_units[el].value = '';">List of Options</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#"
                                        v-if="data_filter.logic_units[el].data_type=='datetime'"
                                        v-on:click.prevent="data_filter.logic_units[el].is_static = false; data_filter.logic_units[el].show_options = true; data_filter.logic_units[el].value = '';"
                                        >Date Comparisons</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                v-on:click.prevent="data_filter.logic_units[el].is_static = true; data_filter.logic_units[el].show_options = false; data_filter.logic_units[el].value = '';"
                                >Static Value</a></li>
                            </ul>
                            <!-- STATIC TEXT BOX -->
                            <input type="text" class="form-control ps-2"  v-model="data_filter.logic_units[el].value"
                                v-if="data_filter.logic_units[el].is_static && data_filter.logic_units[el].show_options == false && !isDate(data_filter.logic_units[el].data_type)"
                                @change="updateStaticValue(data_filter.logic_units[el])">
 
                            <!-- STATIC WITH DATE FORMATTING -->
                            <datepicker v-model="data_filter.logic_units[el].value" input-class="form-control date" placeholder='MM/DD/YYYY'
                                :bootstrap-styling="true" :use-utc="true" format="M/d/yyyy"
                                v-if="data_filter.logic_units[el].is_static  && data_filter.logic_units[el].show_options == false && isDate(data_filter.logic_units[el].data_type)" />

                            <!-- DATABASE COLUMN SELECTION -->
                            <v-select :options="db_columns" v-model="data_filter.logic_units[el].value"
                                :searchable="true" class="searchable-select value_db_select vue_select column-display "
                                :selectable="(option) => option.text != ''"
                                label="text" @search="fetchOptions"
                                v-if="data_filter.logic_units[el].is_static == false && !data_filter.logic_units[el].show_options"
                                :filterable="false">

                                <template #open-indicator="{ attributes }">
                                  <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                                </template>

                                <template #option="{ text, category }">
                                  <div v-if="text == ''" :data-category="category" class="category-header"
                                    v-on:click="return expandCategory(category, null)"
                                    data-isexpanded="false">
                                    {{ category }} <i class="fa-solid fa-caret-right"></i>
                                  </div>
                                  <div v-else class="suboption" :data-subcategory="category"
                                    :class="category==null || category==''? 'show' : ''">
                                    {{ text }}
                                  </div>
                                </template>
                                <template #no-options="{ search, searching, loading }">
                                    <div class="suboption show" v-if="is_loading">
                                        <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                    </div>
                                    <div class="suboption show" v-else>
                                        <em>No results found</em>
                                    </div>
                                </template>
                            </v-select>

                            <!-- SELECT FROM AVAILABLE DB VALUES -->
                            <select class="form-select" v-model="data_filter.logic_units[el].value"
                                v-if="data_filter.logic_units[el].is_static && data_filter.logic_units[el].show_options">
                                <option v-for="col in db_column_values[data_filter.logic_units[el].db_column.value]" v-bind:value="col.name"
                                >{{ col.name }}</option>
                            </select>

                            <!-- SELECT FROM DATE COMPARISONS -->
                            <input type="number" class="form-control date_num ps-2"
                                v-bind:value="dateNumCalc(data_filter.logic_units[el].value)"
                                v-bind:data-index="el" placeholder="###"
                                v-on:change="updateDateValue(el, data_filter.logic_units[el]);"
                                v-if="!data_filter.logic_units[el].is_static && data_filter.logic_units[el].show_options">

                            <select class="form-select input-group-prepend date_comparison"
                                v-bind:value="dateComparisonCalc(data_filter.logic_units[el].value)"
                                v-bind:data-index="el"
                                v-on:change="updateDateValue(el, data_filter.logic_units[el]);"
                                v-if="!data_filter.logic_units[el].is_static && data_filter.logic_units[el].show_options">
                                <option value="day">Days Ago</option>
                                <option value="week">Weeks Ago</option>
                                <option value="month">Months Ago</option>
                                <option value="year">Years Ago</option>
                            </select>

                        </div>
                        <div class="form-check" v-if="data_filter.logic_units[el].operator != null && data_filter.logic_units[el].operator.value != null
                             && (data_filter.logic_units[el].operator.value.indexOf('LIKE') > -1 || data_filter.logic_units[el].operator.value == 'IN' || data_filter.logic_units[el].operator.value == 'NOT IN' || ((data_filter.logic_units[el].operator == '=' || data_filter.logic_units[el].operator.value == '=' || data_filter.logic_units[el].operator.value == '<>' || data_filter.logic_units[el].operator == '<>') && (data_filter.logic_units[el].data_type=='string')))">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" value="LOWER" v-model="data_filter.logic_units[el].modification">
                                <small style="margin-top: 2px; display: inline-block;">Case-insensitive comparison</small>
                            </label>
                        </div>
                        <small v-if="data_filter.logic_units[el].operator != null && (data_filter.logic_units[el].operator.value=='IN' || data_filter.logic_units[el].operator.value=='NOT IN')">Comma separate the unique values. Quotes around strings are <em>not</em> needed.</small>
                    </div>
                    <div class="col-lg-1 col-close-btn ">
                        <button class="btn btn-none show-on-hover" v-on:click="removeLogicUnit(el)">
                            <img class="icon p-0" src="/img/icons/dialexa-icons/trash.svg"> 
                        </button>
                    </div>

                    <div class="pb-2 invalid-feedback col-12" v-if="hasLogicUnitError(data_filter.logic_units[el]) && errors.logic_units[el]">
                        All of the fields must be completed.
                    </div>
                </div>
                <button class="btn btn-secondary btn-sm shadow-sm" v-on:click="addLogicUnit(index)"><i class="fas fa-plus"></i> {{inverted? "AND" : "OR"}}</button>
            </div>


        </div>

    </div>

    <div class="add-condition">
        <button class="btn btn-dark shadow-sm" v-on:click="addLogicUnit(-1)"><i class="fas fa-plus"></i>Add Condition</button>
    </div>
</div>

</template>

<script>
    import moment from 'moment';
    import vSelect from "vue-select";
    import Datepicker from 'vuejs-datepicker';

    export default {
        props: {
            value: {
              type: Object,
            },
            client: {
                type: Object,
                required: true
            },
            source: {default: 'client_db' },
            data_source_id: {default: null },
            static_db_columns: {default: null },
            include_trans: {default: false },
            is_white: {default: true },
            add_blank: {default: true }

        },
        components: {
            vSelect,
            Datepicker
        },
        data() {
            return {
                db_columns: [],
                unfiltered_db_columns: [],
                db_column_values: [],
                errors: {
                    logic_units: [ false ]
                },
                data_filter: null,
                inverted: false,
                is_loading: false,
                operator_options: [
                    { category: "Common Operators", value: '', text: '' },
                    { category: "Common Operators", value: '=', text: 'Equals' },
                    { category: "Common Operators", value: '<>', text: 'Does Not Equal' },
                    { category: "Common Operators", value: 'IS NULL', text: 'Is Blank' },
                    { category: "Common Operators", value: 'IS NOT NULL', text: 'Is Not Blank' },
                    { category: "Common Operators", value: 'IN', text: 'Is One Of' },
                    { category: "Common Operators", value: 'NOT IN', text: 'Is Not One Of' },

                    { category: "Text Operators", value: '', text: '' },
                    { category: "Text Operators", value: 'LIKE', text: 'Contains' },
                    { category: "Text Operators", value: 'NOT LIKE', text: 'Does Not Contain' },
                    { category: 'Text Operators', value:"REGEXP_CONTAINS", text: 'Contains (Regex)' },
                    { category: 'Text Operators', value:"NOT REGEXP_CONTAINS", text: 'Does Not Contain (Regex)' },

                    { category: "Number Operators", value: '', text: '' },
                    { category: "Number Operators", value: '>', text: '>' },
                    { category: "Number Operators", value: '>=', text: '>=' },
                    { category: "Number Operators", value: '<', text: '<' },
                    { category: "Number Operators", value: '<=', text: '<=' },

                    { category: "Date Operators", value: '', text: '' },
                    { category: "Date Operators", value: '>', text: 'Is After' },
                    { category: "Date Operators", value: '>=', text: 'Is On or After' },
                    { category: "Date Operators", value: '<', text: 'Is Before' },
                    { category: "Date Operators", value: '<=', text: 'Is On or Before' },
                ]
            };
        },
        beforeMount() {
            if(this.value != null){
                this.data_filter = this.value;
            }
            else
                this.data_filter = this.getBlankDataFilter();

            this.data_filter.filter_ui = "simple";
            this.data_filter.filter_option = 'custom';
            this.inverted = this.isInverted(this.data_filter.filter_logic);

            //Update the operator to be an object if it already isn't one
            var nested_units = this.nested_units;
            if(nested_units != null)
                for(var i = 0; i< nested_units.length; i++) {
                    for(var j = 0; j < nested_units[i].length; j++) {
                        if(this.data_filter.logic_units.length > 0 && this.data_filter.logic_units[nested_units[i][j]] && typeof this.data_filter.logic_units[nested_units[i][j]].operator == 'string' ){
                            var op = this.data_filter.logic_units[nested_units[i][j]].operator;

                            //Find the op in the operator_options array for the value
                            for(var k = 0; k < this.operator_options.length; k++) {
                                if(this.operator_options[k].value == op) {
                                    op = this.operator_options[k];
                                    break;
                                }
                            }

                            this.data_filter.logic_units[nested_units[i][j]].operator = op;
                        }
                    }
                }
        },
        mounted() {
            this.loadDropdownColumns();

        },
        computed: {
            nested_units() {
                //In this case, OR is the outer unit
                var split = "AND";
                if(this.inverted)
                    split = "OR";

                var logic = this.data_filter.filter_logic;
                if(logic == null)
                    return null;

                //Write a statement to remove all ( and ) from the string
                logic = logic.replaceAll("(", "").replaceAll(")", "");

                var units = logic.split(split).filter(Boolean);
                var arr = [];
                for(var i = 0; i < units.length; i++) {
                    arr.push( units[i].split(this.inverted?"AND":"OR").map(function(item) {
                      return parseInt(item.trim())-1;
                    }));
                }
                return arr;

            }
        },
        watch: {
            value(old_pv, new_pv) {
                if(old_pv != null)
                    this.data_filter = old_pv;
                else
                    this.data_filter = this.getBlankDataFilter();

                this.data_filter.filter_ui = "simple";
            },
            data_filter: {
                handler(n, o) {
                    this.$emit('input', this.data_filter);
                    for(var i = 0; i < this.data_filter.logic_units.length; i++)
                        if(this.data_filter.logic_units[i].db_column != null &&
                            this.db_column_values[this.data_filter.logic_units[i].db_column.value] == null)
                            this.loadDatabaseValues(this.data_filter.logic_units[i].db_column.value);
                },
                deep: true
            },
            source(old_pv, new_pv) {
                this.loadDropdownColumns();
            },
            data_source_id(old_pv, new_pv) {
                this.loadDropdownColumns();
            }
        },
        methods: {
            isFormValid() {
                //Reset the error messages
                for(var i = 0; i < this.data_filter.logic_units.length; i++)
                    this.errors.logic_units[i] = false;

                var form_is_valid = true;
                for(var i = 0; i < this.data_filter.logic_units.length; i++) {
                    this.errors.logic_units[i] = this.hasLogicUnitError(this.data_filter.logic_units[i]);
                    if(this.errors.logic_units[i])
                        form_is_valid = false;
                }

                if(!form_is_valid)
                    this.$forceUpdate();

                return form_is_valid;
            },
            setInverted(val) {
                this.inverted = val;

                //Swap the AND and OR in the logic
                var logic = this.data_filter.filter_logic;
                this.data_filter.filter_logic = logic.replace(/\bAND\b|\bOR\b/g, function(match) {
                    return match === "AND" ? "OR" : "AND";
                });

            },
            isInverted(stmt) {
                if(stmt == null)
                    stmt = '';

                stmt = stmt.toUpperCase().replaceAll("(", " ( ").replaceAll(")", " ) ");
                const tokens = stmt.match(/[\w()]+|[^\w\s()+-]+/g);

                //If it is only one, return false
                if(tokens == null || tokens.length == 1)
                    return false;

                //If it starts with a number, then I care about the second one
                if(/^\d+$/.test(tokens[0]))
                    return tokens[1] === "OR";

                //It starts with a parenthesis, I need to loop through the tokens to find the first logic that isn't within a parenthesis
                var parensCount = 0;
                for (let i = 0; i < tokens.length; i++) {
                    const token = tokens[i];

                    if (token === "(")
                      parensCount++;
                    else if (token === ")")
                      parensCount--;
                    else if (parensCount === 0)
                        return token === "OR";
                }

                //Everything is within a paranthesis, so just return the first operator
                return tokens[2] === "AND";
            },
            getBlankDataFilter() {
                var data = {
                    filter_option: "custom",
                    filter_logic: '1',
                    filter_ui: 'simple',
                    logic_error_msg: "",
                    logic_units: []
                }

                if(this.add_blank)
                    data.logic_units.push(
                        {
                            db_column:{ value: "", text: "", category: ""},
                            data_type:'string',
                            operator: { value: "" , text: "", category: ""},
                            is_static: true,
                            show_options: false,
                            value:'',
                            modification:''
                        });
                return data;
            },
            dateNumCalc(value) {
                if(value == undefined || moment.isDate(value) || value.indexOf("DATE_SUB") == -1)
                    return value;
                value = value.replace("DATE_SUB(CURRENT_DATE(), INTERVAL ", "");

                var parts = value.split(" ");
                return parts[0];

                //num + " " + comparison + ")
            },
            dateComparisonCalc(value) {
                if(value == undefined || moment.isDate(value) || value.indexOf("DATE_SUB") == -1)
                    return value;
                value = value.replace("DATE_SUB(CURRENT_DATE(), INTERVAL ", "");

                var parts = value.split(" ");
                parts[1] = parts[1].replace(")", "");
                return parts[1];

                //num + " " + comparison + ")
            },
            isComparison(data_type) {
                if(data_type == undefined)
                    return true;

                return data_type.indexOf('float') > -1 ||
                    data_type.indexOf('int') > -1 || data_type.indexOf('date') > -1 || data_type.indexOf('time') > -1;

            },
            isDate(data_type) {
                if(data_type == undefined)
                    return false;

                return data_type.indexOf('date') > -1 || data_type.indexOf('time') > -1;

            },
            typeOfSelection(unit) {
                if(unit.is_static && unit.show_options )
                    return 'Options';
                else if(unit.is_static)
                    return "Static";
                else if(unit.show_options)
                    return 'Date';
                else return "Column";
            },

            loadDropdownColumns() {
                if(this.static_db_columns == null)
                    this.loadDatabaseColumns();
                else {
                    this.db_columns = this.getFormattedColumns();
                    this.unfiltered_db_columns = JSON.parse(JSON.stringify(this.db_columns));
                }
            },
            getFormattedColumns() {
                var columns = [];
                //Sort the this.src_columns
                var keys = Object.keys(this.static_db_columns);
                //sort keys but, if it has a period in the name, it should be lower than works without a period
                keys.sort(function(a, b) {
                    if(a.indexOf(".") == -1 && b.indexOf(".") != -1)
                        return -1;
                    else if(a.indexOf(".") != -1 && b.indexOf(".") == -1)
                        return 1;
                    else
                        return a.localeCompare(b);
                });

                var old_category = '';
                for(var i in keys) {
                    let key = keys[i];
                    let text = this.static_db_columns[key];
                    let category = null;

                    //If the key contains a .
                    if(key.indexOf(".") != -1) {
                        //split string by . , separate any structs that are 3+ layers deep with a > in between
                        var split = key.split(".");
                        category = this.$options.filters.propercase(split[0]);
                        text = this.$options.filters.propercase(split.slice(1).join(' > '));

                        if(category != old_category)
                            columns.push({
                                value: "DIVIDER-"+ category,
                                category: category,
                                text: ""
                            });
                    }
                    old_category = category;

                    columns.push({
                        value: key,
                        text: text,
                        category: category
                    });
                }
                return columns;
            },
            loadDatabaseColumns() {

                var _self = this;
                var data = {
                  client: this.client,
                  include_trans: this.include_trans,
                  source: this.source,
                  data_source_id: this.data_source_id
                };
                this.is_loading = true;
                window.axios.post('/api/bigquery/get_db_columns', data)
                  .then(response => {
                    var cols = response.data.columns;
                    var headers = "";
                    for(var i = 0; i < cols.length; i++)
                        if(headers != cols[i].category && cols[i].category != null) {
                            headers = cols[i].category;
                            cols.splice(i, 0, {
                                value: "DIVIDER-"+ headers,
                                category: headers,
                                text: ""
                            });
                        }
                    _self.db_columns = cols;
                    _self.unfiltered_db_columns = JSON.parse(JSON.stringify(cols));
                    _self.is_loading = false;

                  });
            },
            shouldShowValues(operator) {
                //If the logic operator is an object
                if(operator != null && typeof operator == "object")
                    operator = operator.value;

                return operator != 'IS NULL' && operator != 'IS NOT NULL';

            },
            hasLogicUnitError(logic) {
                //If the logic operator is an object
                var operator ;
                if(logic.operator != null && typeof logic.operator == "object")
                    operator = logic.operator.value;

                if(logic.db_column == null || operator == null || logic.db_column.value == "" || operator == "" ||
                    ( logic.value == "" && operator.indexOf("NULL") == -1))
                    return true;

                //If the database column is no longer available in the source table
                if(this.unfiltered_db_columns != null && this.unfiltered_db_columns.length > 0 && !this.unfiltered_db_columns.find(obj => obj.value === logic.db_column.value) ) {
                    logic.db_column.value = "";
                    logic.db_column.text = "";
                    return true;
                }

                //If the column it is comparing against has been remove...
                if(!logic.is_static && !logic.show_options && this.unfiltered_db_columns != null &&
                    this.unfiltered_db_columns.length > 0 && !this.unfiltered_db_columns.find(obj => obj.value === logic.value.value) ) {
                    logic.value.text = "";
                    logic.value.value = "";
                    return true;
                }
                return false;
            },

            loadDatabaseValues(db_column) {

                if(db_column == undefined || db_column == null || db_column == "")
                    return;

                var data = {
                  client: this.client,
                  source: this.source,
                  column: db_column,
                  limit: 1000,
                  data_source_id: this.data_source_id,
                  staging_table: (this.static_db_columns != null)
                };

                var _self = this;

                this.db_column_values[db_column] = [
                    {name: 'Loading Options...'}
                ];
                window.axios.post('/api/bigquery/get_samples', data)
                  .then(response => {
                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--)
                        if(response.data.samples[i].name == null)
                            response.data.samples.splice(i, 1);

                    response.data.samples.sort((a, b) => (a.name > b.name ) ? 1 : -1);

                    if(response.data.samples.length > 0)
                        _self.db_column_values[db_column] = response.data.samples;
                    else
                        _self.db_column_values[db_column] = [
                            {name: '** All values are blank **'}
                        ];
                    _self.$forceUpdate();
                });
            },
            addLogicUnit(index) {
                this.data_filter.logic_units.push(
                    {
                        db_column: { value: "" , text: "", category: ""},
                        data_type:'string',
                        operator: { value: "" , text: "", category: ""},
                        value:'',
                        is_static: true,
                        show_options: false,
                        modification:'',
                    });

                this.errors.logic_units.push(false);

                //Now update the logic statement

                //Get the nested units;
                //Loop through and reassemble the logic statement
                var units = this.nested_units;
                var stmt = "";

                if(units != null)
                    for(var i =0; i < units.length; i++){
                        var logic = "";
                        for(var j = 0; j < units[i].length; j++){
                            if(j > 0)
                                logic += ((this.inverted)? " AND " : " OR ");
                            logic += (units[i][j]+1);
                        }
                        if(index == i)
                            logic += ((this.inverted)? " AND " : " OR ") + this.data_filter.logic_units.length;

                        stmt += "(" + logic + ")"
                        if(i < units.length-1)
                            stmt += ((this.inverted)? " OR " : " AND ");
                    }

                //If it is the "Add Condition" it will just append to the end
                if(index == -1)
                    //If this is the first unit
                    if(this.data_filter.logic_units.length == 1)
                        stmt = "1";
                    else
                        stmt += ((this.inverted)? " OR " : " AND ") + this.data_filter.logic_units.length;

                this.data_filter.filter_logic = stmt;

            },
            removeLogicUnit(index) {
                var units = this.nested_units;
                for(var i =0; i < units.length; i++)
                    for(var j = 0; j < units[i].length; j++)
                        if(units[i][j] == index) {
                            units[i].splice(j,1);

                            //Remove empty arrays
                            if(units[i].length == 0)
                                units.splice(i, 1);

                            i = units.length;
                            break;
                        }

                this.data_filter.logic_units.splice(index,1);
                this.errors.logic_units.splice(index,1);

                //If it is empty, just save the logic statement and be done
                if(this.data_filter.logic_units.length == 0) {
                    this.data_filter.filter_logic = "";
                    return;
                }

                //Remake the logic

                var stmt = "";
                for(var i =0; i < units.length; i++){
                    var logic = "";
                    for(var j = 0; j < units[i].length; j++){
                        if(j > 0)
                            logic += ((this.inverted)? " AND " : " OR ");
                        //Need to subtract one from all elements above the removed one to reset the index
                        logic += (units[i][j]+(index > units[i][j] ? 1 : 0));
                    }

                    stmt += "(" + logic + ")"
                    if(i < units.length-1)
                        stmt += ((this.inverted)? " OR " : " AND ");
                }

                this.data_filter.filter_logic = stmt;

                //The showCustomError method will handle the rest

            },
            updateOperator(unit) {

                if(unit.operator == null) return;

                if(unit.operator.category.indexOf("Number") > -1){
                    unit.data_type = 'float';
                    unit.db_column.type = 'float';
                }
                else if(unit.operator.category.indexOf("Date") > -1){
                    unit.data_type = 'datetime';
                    unit.db_column.type = 'datetime';
                }
                else if(unit.db_column.type == 'bool') {
                    unit.data_type = 'bool';
                    unit.db_column.type = 'bool';
                }
                else {
                    unit.data_type = 'string';
                    unit.db_column.type = 'string';
                }

                if(unit.operator != undefined &&
                    (unit.operator.value == 'REGEXP_CONTAINS' || unit.operator.value.indexOf("IN") >= 0
                        || unit.operator.value == "LIKE" || unit.operator.value.indexOf('NULL') >= 0 )){
                    unit.is_static = true;
                    unit.show_options = false;

                    if(typeof unit.value === 'object' && unit.value !== null)
                        unit.value = unit.value.text;
                }

            },

            updateStaticValue(unit) {
                if(unit == undefined || unit.value == undefined || unit.value == null)
                    return;
                
                var val = unit.value.toLowerCase().trim();

                if(val == 'true' || val == 'false')
                    unit.data_type = 'bool';
                else if (!isNaN(val))
                    unit.data_type = 'float';
                else
                    unit.data_type = 'string';
            },
            updateDateValue(index, unit) {
                var num = $(".date_num[data-index='" + index + "']").val();
                var comparison = $(".date_comparison[data-index='" + index + "']").val();

                unit.value = "DATE_SUB(CURRENT_DATE(), INTERVAL " + num + " " + comparison + ")" ;
            },
            expandCategory(cat, expand) {
                var headline = document.querySelector("div[data-category='"+cat+"']");
                var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");

                if(headline == undefined || lines == undefined)
                    return false;

                if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="block";
                    var divs = headline.getElementsByClassName("fa-caret-right");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-down");
                        divs[i].classList.remove("fa-caret-right");
                    }
                    headline.dataset.isexpanded = true;
                }
                else {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="none";
                    var divs = headline.getElementsByClassName("fa-caret-down");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-right");
                        divs[i].classList.remove("fa-caret-down");
                    }
                    headline.dataset.isexpanded = false;
                }

                return false;
            },

            fetchOptions (search, loading) {
                //Reset the array
                this.db_columns = JSON.parse(JSON.stringify(this.unfiltered_db_columns));

                if(search == "") return;
                this.is_loading = true;

                //Look at each column
                for(var i = this.db_columns.length-1; i >= 0 ; i--) {
                    //If the search string isn't in the text or the category
                    if(this.db_columns[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                        && (this.db_columns[i].category == null
                        || this.db_columns[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                        && this.db_columns[i].text != "" ) //And not a category divider

                        this.db_columns.splice(i, 1);
                }

                //Get the remaining categories
                var cats = [];
                for(var i = 0; i < this.db_columns.length; i++)
                    if(this.db_columns[i].category != null && !cats.includes(this.db_columns[i].category) && this.db_columns[i].text != "")
                        cats.push(this.db_columns[i].category);

                //Expand the categories
                for(var i = 0; i < cats.length; i++)
                    this.expandCategory(cats[i], true);

                //Remove a category if it isn't in the array of categories
                for(var i = this.db_columns.length-1; i >= 0 ; i--)
                    if(this.db_columns[i].text == "" && this.db_columns[i].category != null && !cats.includes(this.db_columns[i].category))
                        this.db_columns.splice(i, 1);

                this.is_loading = false;
                return true;
            }
        }

    }
</script>
