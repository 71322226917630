var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asset-details" }, [
    _c("div", { attrs: { id: "asset-card-" + _vm.asset.id } }, [
      _c("div", { staticClass: "card-body position-relative" }, [
        _vm.asset.status != "approved" || _vm.is_editing_approved_asset
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "mb-3",
                  class:
                    (!_vm.is_client_edit_toggled &&
                      (_vm.local_asset.status == "for_approval" ||
                        _vm.local_asset.status == "in_review") &&
                      _vm.local_asset.latest_review) ||
                    _vm.is_client_edit_toggled
                      ? "col-7"
                      : "col-9",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      class: {
                        "border-bottom":
                          _vm.asset.status == "approved" &&
                          !_vm.is_editing_approved_asset,
                      },
                    },
                    [_vm._v("Name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_asset.name,
                        expression: "local_asset.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors["asset_name"] != "" ? "is-invalid" : "",
                    domProps: { value: _vm.local_asset.name },
                    on: {
                      change: function ($event) {
                        return _vm.inputFieldValueChange()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.local_asset, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  (_vm.errors["asset_name"] && _vm.errors["asset_name"]) != ""
                    ? _c("div", {
                        staticClass: "text-danger invalid-feedback",
                        domProps: {
                          innerHTML: _vm._s(_vm.errors["asset_name"]),
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-3 mb-3" }, [
                _c("label", { staticClass: "form-label" }, [_vm._v("Status")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_asset.status,
                        expression: "local_asset.status",
                      },
                    ],
                    staticClass: "form-select",
                    attrs: { id: "status-select" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.local_asset,
                            "status",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.inputFieldValueChange()
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "", disabled: "", selected: "" } },
                      [_vm._v("Select the status...")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "draft" } }, [
                      _vm._v("Draft"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "for_approval" } }, [
                      _vm._v("Ready for Review"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "in_review" } }, [
                      _vm._v("Reviewed"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "approved" } }, [
                      _vm._v("Approved"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              (!_vm.is_client_edit_toggled &&
                (_vm.local_asset.status == "for_approval" ||
                  _vm.local_asset.status == "in_review") &&
                _vm.local_asset.latest_review) ||
              _vm.is_client_edit_toggled
                ? _c("div", { staticClass: "col-2 mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-check-label form-label" },
                      [_vm._v("Client Edits")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "copy-toggle-button form-check form-switch",
                      },
                      [
                        _c("input", {
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            role: "switch",
                            "data-bs-toggle": "tooltip",
                            "data-bs-placement": "bottom",
                            title:
                              "When this switch is on, it shows the latest client approved copy of the asset detail. When it is off, it shows the original copy.",
                          },
                          domProps: { checked: _vm.is_client_edit_toggled },
                          on: { change: _vm.toggleClientEdits },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label border-bottom" }, [
                  _vm._v("Name"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(_vm._s(_vm.local_asset.name)),
                ]),
              ]),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "label",
              {
                staticClass: "form-label",
                class: {
                  "border-bottom":
                    _vm.asset.status == "approved" &&
                    !_vm.is_editing_approved_asset,
                },
              },
              [_vm._v("Asset Description\n                        ")]
            ),
            _vm._v(" "),
            _vm.asset.status != "approved" || _vm.is_editing_approved_asset
              ? _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_asset.description,
                      expression: "local_asset.description",
                    },
                  ],
                  ref: "textarea",
                  staticClass: "form-control full-height",
                  attrs: { id: "description-" + _vm.asset.id },
                  domProps: { value: _vm.local_asset.description },
                  on: {
                    change: function ($event) {
                      return _vm.inputFieldValueChange(
                        "description-" + _vm.asset.id
                      )
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_asset,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                })
              : _vm.local_asset.description != null &&
                _vm.local_asset.description != "" &&
                _vm.local_asset.description != "TODO"
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(_vm._s(_vm.local_asset.description)),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider mt-3" }, [
          _vm._v(
            "The content below will be automatically generated or you can add information manually"
          ),
        ]),
        _vm._v(" "),
        _vm.asset.status != "approved" ||
        (_vm.asset.status == "approved" && _vm.is_editing_approved_asset)
          ? _c(
              "div",
              { staticClass: "mt-3" },
              _vm._l(_vm.local_inputs, function (input_field, index) {
                return _c(
                  "div",
                  { key: _vm.asset.id + input_field.placeholder },
                  [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { staticClass: "form-label mb-2" }, [
                          _c(
                            "span",
                            {
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                "data-bs-placement": "bottom",
                                title: input_field.description,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(input_field.name) +
                                  "\n                                "
                              ),
                              input_field.optional == 1
                                ? _c("em", [_vm._v("(Optional)")])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.local_asset.source == "generated" &&
                          (input_field.type == "text" ||
                            input_field.type == "textarea" ||
                            input_field.type == "wysiwyg")
                            ? _c(
                                "a",
                                {
                                  staticClass: "ms-2",
                                  attrs: {
                                    href: "#",
                                    "data-bs-toggle": "modal",
                                    "data-bs-target":
                                      "#refine-prompt-modal-" +
                                      _vm.local_asset.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.regenerateIndividualInput(
                                        input_field
                                      )
                                    },
                                  },
                                },
                                [_vm._m(0, true)]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          input_field.type == "image"
                            ? _c(
                                "a",
                                {
                                  staticClass: "ms-2",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addEmptyImageSlot(index)
                                    },
                                  },
                                },
                                [_vm._m(1, true)]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            input_field.type == "text" ||
                            input_field.type == "link"
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      _vm.getErrorForMaxLength(input_field),
                                    attrs: {
                                      maxlength: input_field.max_length,
                                      currentlength:
                                        input_field.value != null
                                          ? input_field.value.length
                                          : 0,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: input_field.value,
                                          expression: "input_field.value",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class:
                                        _vm.getErrorForInputField(input_field),
                                      attrs: {
                                        type: "text",
                                        maxlength: input_field.max_length,
                                      },
                                      domProps: { value: input_field.value },
                                      on: {
                                        change: function ($event) {
                                          return _vm.inputFieldValueChange()
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            input_field,
                                            "value",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            input_field.type == "textarea" ||
                            input_field.type == "template"
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: input_field.value,
                                      expression: "input_field.value",
                                    },
                                  ],
                                  ref: "textarea",
                                  refInFor: true,
                                  staticClass: "full-height form-control",
                                  class:
                                    _vm.errors[input_field.placeholder] != ""
                                      ? "is-invalid"
                                      : "",
                                  attrs: {
                                    id:
                                      input_field.placeholder +
                                      "-" +
                                      _vm.asset.id,
                                    maxlength: input_field.max_length,
                                  },
                                  domProps: { value: input_field.value },
                                  on: {
                                    change: function ($event) {
                                      return _vm.inputFieldValueChange(
                                        input_field.placeholder +
                                          "-" +
                                          _vm.asset.id
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        input_field,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            input_field.type == "image"
                              ? _c(
                                  "div",
                                  { staticClass: "image-array-section" },
                                  _vm._l(
                                    input_field.value,
                                    function (image_url, image_index) {
                                      return _c(
                                        "div",
                                        { staticClass: "me-3" },
                                        [
                                          _c("vue-dropzone", {
                                            ref: "image-dropzone",
                                            refInFor: true,
                                            staticClass: "input-dropzone",
                                            class:
                                              image_url == null
                                                ? ""
                                                : "has-background",
                                            style:
                                              image_url != null
                                                ? "background-image: url('" +
                                                  image_url +
                                                  "')"
                                                : "",
                                            attrs: {
                                              id:
                                                "image-dropzone-" +
                                                _vm.local_asset.id +
                                                "-" +
                                                image_index,
                                              disabled:
                                                _vm.asset.status == "approved",
                                              options: _vm.imageDropzoneOptions,
                                              awss3: _vm.awss3,
                                            },
                                            on: {
                                              "vdropzone-s3-upload-success":
                                                _vm.imageUpload,
                                              "vdropzone-sending": function (
                                                $event
                                              ) {
                                                return _vm.imageUploadStarted(
                                                  input_field,
                                                  image_index
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "image-field-buttons",
                                            },
                                            [
                                              _c("span", [
                                                image_url != null
                                                  ? _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-label mb-2",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass: "ms-2",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.deleteImage(
                                                                  index,
                                                                  image_index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-trash",
                                                            }),
                                                            _vm._v("Delete"),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            input_field.type == "file"
                              ? _c("vue-dropzone", {
                                  ref: "file-dropzone",
                                  refInFor: true,
                                  staticClass: "input-dropzone",
                                  attrs: {
                                    id: "file-dropzone-" + _vm.local_asset.id,
                                    options: _vm.fileDropzoneOptions,
                                    disabled: _vm.asset.status == "approved",
                                  },
                                  on: {
                                    "vdropzone-processing": _vm.onProcessing,
                                    "vdropzone-success": _vm.onSuccess,
                                    "vdropzone-sending": _vm.onSending,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            input_field.type == "wysiwyg"
                              ? _c("wysiwyg-editor-advanced", {
                                  attrs: {
                                    if_show_button_input:
                                      _vm.if_show_button_input,
                                    input_value: input_field.value,
                                    input_index: index,
                                    asset_id: _vm.local_asset.id,
                                  },
                                  on: {
                                    wysiwygValueChange: _vm.wysiwygValueChange,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            input_field.type == "dropdown"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: input_field.value,
                                        expression: "input_field.value",
                                      },
                                    ],
                                    staticClass:
                                      "form-select w-auto form-control",
                                    class:
                                      _vm.errors[input_field.placeholder] != ""
                                        ? "is-invalid"
                                        : "",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            input_field,
                                            "value",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.inputFieldValueChange()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          disabled: "",
                                          selected: "",
                                        },
                                      },
                                      [_vm._v("Select the value...")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      input_field.options,
                                      function (option) {
                                        return _c(
                                          "option",
                                          { domProps: { value: option } },
                                          [_vm._v(_vm._s(option))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.errors[input_field.placeholder] != null &&
                        _vm.errors[input_field.placeholder] != ""
                          ? _c("div", {
                              staticClass: "text-danger invalid-feedback",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.errors[input_field.placeholder]
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c("div", [
              _c(
                "div",
                { staticClass: "mt-3" },
                _vm._l(this.local_inputs, function (input_field) {
                  return _c("div", [
                    (input_field.value != "" && input_field.value != null) ||
                    input_field.optional == 0
                      ? _c("div", { staticClass: "row mb-3" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "label",
                              { staticClass: "form-label border-bottom" },
                              [_vm._v(_vm._s(input_field.name))]
                            ),
                            _vm._v(" "),
                            input_field.type == "image"
                              ? _c(
                                  "div",
                                  { staticClass: "image-array-section" },
                                  _vm._l(
                                    input_field.value,
                                    function (image_url, image_index) {
                                      return _c("div", [
                                        _c("img", {
                                          staticClass:
                                            "me-3 mb-1 img-thumbnail",
                                          attrs: { src: image_url },
                                        }),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(input_field.value),
                                  },
                                }),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col" }, [
            _vm.form.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(
                    "\n                        There was an error saving the asset information.\n                    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.asset.status != "approved"
              ? _c("span", { staticClass: "dropup" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary me-2 dropdown-toggle",
                      attrs: {
                        type: "button",
                        disabled: _vm.form.busy,
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-edit" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.local_asset.source == "generated"
                              ? "Regenerate Asset "
                              : "Generate Asset "
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      ref: "dropdown",
                      staticClass: "dropdown-menu pt-0 prompt-dropdown",
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "px-3 py-2 text-white form-label bg-dark",
                        },
                        [_vm._v("Generate asset based upon ...")]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "dropdown-item btn btn-link",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.generatePrompt("description", null)
                            },
                          },
                        },
                        [_vm._v("Description Only")]
                      ),
                      _vm._v(" "),
                      _vm.pages != null && _vm.pages.length > 0
                        ? _c("li", [
                            _c(
                              "button",
                              {
                                staticClass: "dropdown-item btn btn-link",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleLandingOptions()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Other Assets\n                                "
                                ),
                                !_vm.is_landing_pages_expanded
                                  ? _c("i", {
                                      staticClass: "fa fa-caret-right mx-1",
                                    })
                                  : _c("i", {
                                      staticClass: "fa fa-caret-down mx-1",
                                    }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pages != null &&
                      _vm.pages.length > 0 &&
                      _vm.is_landing_pages_expanded
                        ? _c(
                            "ul",
                            { staticClass: "my-1" },
                            _vm._l(_vm.pages, function (page) {
                              return _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link p-0 dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.generatePrompt(
                                          "page",
                                          page.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        page.asset_type.name.substring(
                                          0,
                                          page.asset_type.name.length - 1
                                        )
                                      ) +
                                        ": " +
                                        _vm._s(page.name)
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass: "dropdown-item btn btn-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleDonorPersonaOptions()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Constituent Personas\n                                "
                            ),
                            !_vm.is_donor_personas_expanded
                              ? _c("i", {
                                  staticClass: "fa fa-caret-right mx-1",
                                })
                              : _c("i", {
                                  staticClass: "fa fa-caret-down mx-1",
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.client.personas != null &&
                      _vm.is_donor_personas_expanded
                        ? _c(
                            "ul",
                            { staticClass: "my-1" },
                            _vm._l(_vm.client.personas, function (persona) {
                              return _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link p-0 dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.generatePrompt(
                                          "donor_persona",
                                          persona.description
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(persona.name))]
                                ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.client.personas == null &&
                      _vm.is_donor_personas_expanded
                        ? _c("ul", { staticClass: "my-1" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    href:
                                      "/settings/" +
                                      _vm.client.url +
                                      "/#personas",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v("Define your first persona "),
                                  _vm._m(2),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass: "dropdown-item btn btn-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleBehavioralConceptOptions()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Behavioral Psychology Concept\n                                "
                            ),
                            !_vm.is_concept_expanded
                              ? _c("i", {
                                  staticClass: "fa fa-caret-right mx-1",
                                })
                              : _c("i", {
                                  staticClass: "fa fa-caret-down mx-1",
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.is_concept_expanded
                        ? _c(
                            "ul",
                            { staticClass: "my-1" },
                            [
                              _vm.concepts.length == 0
                                ? _c("li", [
                                    _vm._v(
                                      "No behavioral concepts defined yet"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.concepts, function (concept) {
                                return _c("li", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-link p-0 dropdown-item",
                                      attrs: {
                                        "data-bs-toggle": "tooltip",
                                        "data-bs-placement": "right",
                                        title: concept.definition,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.generatePrompt(
                                            "concept",
                                            concept.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                     " +
                                          _vm._s(concept.name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item btn btn-link",
                            attrs: {
                              href: "#",
                              "data-bs-toggle": "modal",
                              "data-bs-target":
                                "#provide-example-modal-" + _vm.local_asset.id,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Provide Examples\n                            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.local_asset.source == "generated"
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item btn btn-link",
                                attrs: {
                                  href: "#",
                                  "data-bs-toggle": "modal",
                                  "data-bs-target":
                                    "#refine-prompt-modal-" +
                                    _vm.local_asset.id,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.is_variant = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Provide Additional Direction to AI\n                            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasAllInputs() &&
            (_vm.asset.status != "approved" ||
              (_vm.asset.status == "approved" &&
                !_vm.is_editing_approved_asset))
              ? _c("span", { staticClass: "dropup" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-2 dropdown-toggle",
                      class:
                        _vm.asset.status == "approved" &&
                        !_vm.is_editing_approved_asset
                          ? "btn-primary"
                          : "btn-secondary",
                      attrs: {
                        type: "button",
                        disabled: _vm.form.busy,
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-copy" }),
                      _vm._v(" Make a Variant\n                        "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      ref: "variant_dropdown",
                      staticClass: "dropdown-menu pt-0 prompt-dropdown",
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "px-3 py-2 text-white form-label bg-dark",
                        },
                        [_vm._v("Generate a variant based upon ...")]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "dropdown-item btn btn-link",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.copyApprovedAsset(_vm.local_asset)
                            },
                          },
                        },
                        [_vm._v("Duplicate Asset")]
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass: "dropdown-item btn btn-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleDonorPersonaOptions()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Constituent Personas\n                                "
                            ),
                            !_vm.is_donor_personas_expanded
                              ? _c("i", {
                                  staticClass: "fa fa-caret-right mx-1",
                                })
                              : _c("i", {
                                  staticClass: "fa fa-caret-down mx-1",
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.client.personas != null &&
                      _vm.is_donor_personas_expanded
                        ? _c(
                            "ul",
                            { staticClass: "my-1" },
                            _vm._l(_vm.client.personas, function (persona) {
                              return _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link p-0 dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.generateVariantPrompt(
                                          "donor_persona",
                                          persona.description
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(persona.name))]
                                ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.client.personas == null &&
                      _vm.is_donor_personas_expanded
                        ? _c("ul", { staticClass: "my-1" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    href:
                                      "/settings/" +
                                      _vm.client.url +
                                      "/#personas",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v("Define your first persona "),
                                  _vm._m(3),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass: "dropdown-item btn btn-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleBehavioralConceptOptions()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Behavioral Psychology Concept\n                                "
                            ),
                            !_vm.is_concept_expanded
                              ? _c("i", {
                                  staticClass: "fa fa-caret-right mx-1",
                                })
                              : _c("i", {
                                  staticClass: "fa fa-caret-down mx-1",
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.is_concept_expanded
                        ? _c(
                            "ul",
                            { staticClass: "my-1" },
                            [
                              _vm.concepts.length == 0
                                ? _c("li", [
                                    _vm._v(
                                      "No behavioral concepts defined yet"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.concepts, function (concept) {
                                return _c("li", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-link p-0 dropdown-item",
                                      attrs: {
                                        "data-bs-toggle": "tooltip",
                                        "data-bs-placement": "right",
                                        title: concept.definition,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.generateVariantPrompt(
                                            "concept",
                                            concept.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                     " +
                                          _vm._s(concept.name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item btn btn-link",
                            attrs: {
                              href: "#",
                              "data-bs-toggle": "modal",
                              "data-bs-target":
                                "#refine-prompt-modal-" + _vm.local_asset.id,
                            },
                            on: {
                              click: function ($event) {
                                _vm.is_variant = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Describe the Modification\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.asset.status == "approved" && !_vm.is_editing_approved_asset
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary me-2",
                    attrs: { type: "button", disabled: _vm.form.busy },
                    on: {
                      click: function ($event) {
                        return _vm.editApprovedAsset()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-edit" }),
                    _vm._v(" Edit\n                    "),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.asset.status == "approved" && _vm.is_editing_approved_asset
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary me-2",
                      attrs: { type: "submit", disabled: _vm.form.busy },
                      on: {
                        click: function ($event) {
                          return _vm.saveEditOnApprovedAsset()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-save" }),
                      _vm._v(" Save\n                        "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", disabled: _vm.form.busy },
                      on: {
                        click: function ($event) {
                          return _vm.cancelEditOnApprovedAsset()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-close" }),
                      _vm._v(" Cancel\n                        "),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal example-modal fade",
        attrs: {
          id: "provide-example-modal-" + _vm.local_asset.id,
          "aria-labelledby": "provide-example-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "close btn",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss":
                        "provide-example-modal-" + _vm.local_asset.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal(
                          "provide-example-modal-" + _vm.local_asset.id
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-0" }, [
                  _vm._v("Provide Examples to Generate Asset"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "You can add up to 5 examples that will be used to inform the type of tone, grammatical style, and marketing tactics you are wanting."
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body pt-0 pb-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row d-flex align-items-center justify-content-center mb-2",
                },
                [
                  _vm.provided_examples_index > 0
                    ? _c("div", { staticClass: "col-2 col-sm-1" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-none",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.moveExamplesLeft()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa-solid fa-chevron-left" })]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(
                          "Example #" + _vm._s(_vm.provided_examples_index + 1)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.provided_examples_array[
                                _vm.provided_examples_index
                              ],
                            expression:
                              "provided_examples_array[provided_examples_index]",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { name: "example_content" },
                        domProps: {
                          value:
                            _vm.provided_examples_array[
                              _vm.provided_examples_index
                            ],
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.provided_examples_array,
                              _vm.provided_examples_index,
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.provided_examples_index < 4
                    ? _c("div", { staticClass: "col-2 col-sm-1" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-none",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.moveExamplesRight()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa-solid fa-chevron-right",
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit", disabled: _vm.form.busy },
                    on: {
                      click: function ($event) {
                        return _vm.generatePrompt(
                          "provided_examples",
                          _vm.provided_examples_array
                        )
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-edit" }),
                    _vm._v(" "),
                    _vm.local_asset.source == "generated"
                      ? _c("span", [_vm._v("Regenerate")])
                      : _c("span", [_vm._v("Generate")]),
                    _vm._v(" Asset\n                        "),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal example-modal fade",
        attrs: {
          id: "refine-prompt-modal-" + _vm.local_asset.id,
          "aria-labelledby": "refine-prompt-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "close btn",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss":
                        "refine-prompt-modal-" + _vm.local_asset.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal(
                          "refine-prompt-modal-" + _vm.local_asset.id
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _vm.field_selected_regenerate == null
                  ? _c("h4", { staticClass: "mt-0" }, [
                      _vm._v("Provide Additional Direction to Avid AI"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.field_selected_regenerate != null
                  ? _c("h4", { staticClass: "mt-0" }, [
                      _vm._v(
                        "Regenerate " +
                          _vm._s(_vm.field_selected_regenerate.name)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Use the field below to describe how you want the generated content to be modified. The AI will then regenerate the content based upon your directions."
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body pt-0 pb-0" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.provided_examples_array[0],
                    expression: "provided_examples_array[0]",
                  },
                ],
                staticClass: "form-control mb-3",
                attrs: { name: "example_content" },
                domProps: { value: _vm.provided_examples_array[0] },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.provided_examples_array,
                      0,
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _vm.is_variant == false
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "submit",
                          "data-bs-dismiss":
                            "refine-prompt-modal-" + _vm.local_asset.id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.generatePrompt(
                              "refine_prompt",
                              _vm.provided_examples_array[0]
                            )
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-btn fa-edit" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.field_selected_regenerate == null
                                ? "Regenerate Asset"
                                : "Regenerate " +
                                    _vm.field_selected_regenerate.name
                            ) +
                            "\n                        "
                        ),
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "submit",
                          "data-bs-dismiss":
                            "refine-prompt-modal-" + _vm.local_asset.id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.generateVariantPrompt(
                              "refine_prompt",
                              _vm.provided_examples_array[0]
                            )
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-btn fa-edit" }),
                        _vm._v(" Generate New Asset\n                        "),
                      ]
                    ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        attrs: {
          "data-bs-toggle": "tooltip",
          title: "Regenerate this element",
        },
      },
      [_c("i", { staticClass: "fas fa-undo" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { attrs: { "data-bs-toggle": "tooltip", title: "Add Image" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ms-2" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-up-right-from-square" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ms-2" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-up-right-from-square" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }