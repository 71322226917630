var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-report p-2 mb-4", attrs: { id: "view-report-page" } },
    [
      _c("div", { staticClass: "row mx-0" }, [
        _c(
          "div",
          {
            staticClass: "p-0",
            class: _vm.is_filter_panel_expanded
              ? "has-filter-panel-open"
              : "w-100",
          },
          [
            _c("div", { staticClass: "row mx-0 p-0" }, [
              _c("div", { staticClass: "col-8 px-0" }, [
                _c("h1", [_vm._v(_vm._s(_vm.page.name))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4 px-0" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-end ms-2 mb-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleFilterPanel()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon pe-0",
                      attrs: { src: "/img/icons/dialexa-icons/sort.svg" },
                    }),
                    _vm._v(" "),
                    !_vm.is_filter_panel_expanded
                      ? _c("span", { staticClass: "ps-1" }, [
                          _vm._v("Filters (" + _vm._s(_vm.filter_count) + ")"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-secondary float-end mb-2",
                    attrs: {
                      href:
                        "/export/" +
                        _vm.page.client.url +
                        "/" +
                        _vm.page.url +
                        "/" +
                        _vm.local_page.sections[_vm.active_tab_index].id +
                        _vm.params,
                      target: "_blank",
                      id: "export-button",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon pe-0",
                      attrs: {
                        src: "/img/icons/dialexa-icons/presention-chart.svg",
                      },
                    }),
                    _vm._v(" "),
                    !_vm.is_filter_panel_expanded
                      ? _c("span", { staticClass: "ps-1" }, [
                          _vm._v("Generate Presentation"),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "col-12 px-0" }, [
                _vm._v(_vm._s(_vm.page.description)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3 px-2" }, [
              _c("div", { staticClass: "card box-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "nav",
                      {
                        staticClass:
                          "navbar navbar-expand secondary-tab-bar p-0 mb-2",
                        attrs: { id: "report-section-nav" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "container-fluid px-0 mb-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "collapse navbar-collapse",
                                attrs: { id: "filter-navbar" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "navbar-nav mb-2 mb-lg-0" },
                                  _vm._l(
                                    _vm.local_page.sections,
                                    function (section, index) {
                                      return _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "nav-link d-flex flex-nowrap secondary-tab",
                                              class: {
                                                "secondary-tab-active":
                                                  section.is_section_tab_active,
                                              },
                                              attrs: {
                                                href: "#",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "data-bs-toggle": "tooltip",
                                                title: section.description,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.setActiveTab(index)
                                                },
                                              },
                                            },
                                            [
                                              _c("h6", [
                                                _vm._v(_vm._s(section.title)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.local_page.sections, function (section, index) {
                      return section.is_section_tab_active
                        ? _c(
                            "div",
                            [
                              _c("report-view-section", {
                                attrs: {
                                  page: _vm.local_page,
                                  client: _vm.client,
                                  connections: _vm.connections,
                                  page_section: section,
                                  is_filter_panel_expanded:
                                    _vm.is_filter_panel_expanded,
                                  if_refreshing: _vm.if_refreshing,
                                  applied_chart_filter:
                                    _vm.applied_chart_filter,
                                  if_benchmark_report: false,
                                  section_index: index,
                                },
                                on: {
                                  exploreChartInSection:
                                    _vm.exploreChartInSection,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-fade", appear: "", duration: "500" } },
        [
          _c(
            "div",
            {
              staticClass: "border-start border-secondary",
              class: _vm.is_filter_panel_expanded ? "open" : "hidden",
              attrs: { id: "filter-panel" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-4 pb-0 mt-2",
                  attrs: { id: "filter-panel--heading" },
                },
                [
                  _c("h4", [_vm._v("Filters")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-none float-end",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFilterPanel()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-regular fa-circle-xmark" })]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("hr", {
                staticClass: "mx-4",
                attrs: { id: "filter-panel-heading-hr" },
              }),
              _vm._v(" "),
              _c("report-view-filter-panel", {
                attrs: {
                  page: _vm.local_page,
                  applied_chart_filter: _vm.applied_chart_filter,
                  section_id: _vm.section_id,
                },
                on: {
                  refreshing: _vm.refreshing,
                  updatePage: _vm.updatePage,
                  removeChartFilter: _vm.removeChartFilter,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.if_benchmark_report
        ? _c(
            "div",
            {
              style: { left: _vm.x + "px", top: _vm.y + "px" },
              attrs: { id: "chart-meta-data" },
              on: { mousedown: _vm.startDrag },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tooltip-container",
                  on: { mouseenter: _vm.adjustTooltipPosition },
                },
                [
                  _c("button", { staticClass: "btn btn-secondary" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "meta-details" }, [
                    _c("h4", [_vm._v("Report Details")]),
                    _vm._v(" "),
                    _vm.meta_data != null &&
                    _vm.meta_data._avid_max_display_date != null
                      ? _c("div", [
                          _c("label", [
                            _vm._v("Last " + _vm._s(_vm.date_type) + "Date:"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.meta_data._avid_max_display_date.date !=
                                    null
                                    ? _vm.meta_data._avid_max_display_date.date
                                    : _vm.meta_data._avid_max_display_date
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.applied_filters.length > 0
                      ? _c("div", [_c("label", [_vm._v("Filters:")])])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.applied_filters.length > 0
                      ? _c(
                          "div",
                          { staticClass: "filter-badge-container" },
                          [
                            _vm._l(
                              _vm.applied_filters,
                              function (filter, index) {
                                return filter.filter_id != -1
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          filter.filter_id +
                                          filter.filter_value,
                                        staticClass: "badge badge-primary",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.setActiveTab(
                                              filter.group_index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        filter.filter_type == "single_select"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(filter.filter_label) +
                                                  ' = "' +
                                                  _vm._s(filter.filter_value) +
                                                  '"\n                    '
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        filter.filter_type == "multi_select" ||
                                        filter.filter_type == "month_picker"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(filter.filter_label) +
                                                  _vm._s(
                                                    filter.filter_value.length >
                                                      1
                                                      ? " (" +
                                                          filter.filter_value
                                                            .length +
                                                          ")"
                                                      : ' = "' +
                                                          filter
                                                            .filter_value[0] +
                                                          '"'
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        filter.filter_type == "date_range"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(filter.filter_label) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.dateRangeDisplay(
                                                      filter.filter_value
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        filter.filter_type == "month_range"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(filter.filter_label) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.monthRangeDisplay(
                                                      filter.filter_value
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e()
                              }
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.applied_filters,
                              function (filter, index) {
                                return filter.filter_id == -1
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          filter.filter_id +
                                          filter.filter_value,
                                        staticClass:
                                          "badge badge-secondary text-dark",
                                      },
                                      [
                                        filter.filter_type == "single_select"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(filter.filter_label) +
                                                  ' = "' +
                                                  _vm._s(filter.filter_value) +
                                                  '"\n                    '
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e()
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }