<template>
    <div class="initiative client-user">
        <div class="alert alert-warning mb-3 box-content" v-if="plan.is_published_plan == 1">
              The plan being view is currently the <em>published plan</em>. This means you are not able to make any adjustments to the assumptions or initatives. If you'd like to make any updates, <a :href="'/planning/' + client.url + '/versions'">you will need to make a new version. You can do that here.</a>
        </div>
        <div class="alert alert-warning mb-3 box-content"  v-if="initiative.id == null">
              Any edits you make to the fields below will be <strong>applied to every initiative</strong> with the same name and initiative type on the current annual plan. Leave a field blank if you don't want to override every initiative; you can then update those initiatives individually.</a>
        </div>
        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Initiative Details:</h4>
                <div class="row">
                    <div class="col-lg-6 col-sm-8">
                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">Initiative Title</label>
                            <input type="text" class="form-control" :class="((errors.title != '' )? 'is-invalid':'')"
                                name="title" v-model="initiative.title">
                            <div class="text-danger invalid-feedback" v-if="errors.title != ''">
                                <div>
                                    {{errors.title}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-4">
                        <div class="mb-3" >
                            <label class="form-label">Priority</label>
                            <select class="form-select"  v-model="initiative.priority" >
                                <option>Urgent</option>
                                <option>Critical</option>
                                <option>Elevated</option>
                                <option>Recommended</option>
                                <option>Required</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label">Initiative Type</label>
                            <input type="text" class="form-control " disabled
                                name="type" v-model="initiative.initiative_type.name">
                        </div>
                    </div>
                </div>
                <!--
                <div class="row">
                    <div class="col-sm-8">
                        <div class="mb-3">
                            <label class="form-label">Initiative Subtitle <em style="font-weight: normal;">(Optional)</em></label>
                            <input type="text" class="form-control" name="subtitle" v-model="initiative.subtitle">
                        </div>
                    </div>
                </div>
                -->
                <div class="row" v-if="initiative.id != null">
                    <div class="col-sm">
                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">Start Month</label>
                            <datepicker v-model="initiative.start_month" minimum-view="month" :use-utc="true"  :class="((errors.start_month != '' )? 'is-invalid':'')"
                                format="MMM yyyy" input-class="form-control" ></datepicker>
                            <div class="text-danger invalid-feedback" v-if="errors.start_month != ''">
                                <div>
                                    {{errors.start_month}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm" v-if="initiative.initiative_type.calc_type != 'one_time'">

                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">End Month</label>
                            <datepicker v-model="initiative.end_month" minimum-view="month" :use-utc="true"
                                format="MMM yyyy" input-class="form-control"  :class="((errors.end_month != '' )? 'is-invalid':'')"></datepicker>
                            <div class="text-danger invalid-feedback" v-if="errors.end_month != ''">
                                <div>
                                    {{errors.end_month}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="mb-3" >
                            <label class="form-label">Strategic Focus</label>
                            <select class="form-select"  v-model="initiative.strategic_focus" >
                                <option>Acquisition</option>
                                <option>Analysis & Reporting</option>
                                <option>Audience Generation</option>
                                <option>Donor Upgrade</option>
                                <option>Reactivation</option>
                                <option>Retention</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3 inline-dropdown">
                            <label class="form-label mb-0">Costs</label>
                            <div class="row">
                                <div class="col" v-if="initiative.costs.length == 0">
                                    <p><em>No costs have been added yet.</em></p>
                                </div>
                                <div class="col-md-3 col-sm-6 has-prefix" v-else v-for="(c, index) in initiative.costs">
                                    <label class="floating_label">{{c.name }}</label>

                                    <div class="input-group mb-3 ">
                                        <span class="input-group-text">$</span>
                                        <input type="text" class="form-control" v-model="c.value" @keypress="isNumber($event)" @paste="cleanUpPaste"
                                            :class="((errors.costs[index] != '' )? 'is-invalid':'')">
                                        <div class="text-danger invalid-feedback  order-last" v-if="errors.costs[index] != ''">
                                            {{errors.costs[index]}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mb-5" v-if="initiative.inputs.length > 0">
            <div class="card-body">
                <h4 >Projected Metrics</h4>
                <div class="row" v-if="initiative.inputs.length > 0">
                    <div class="col">
                        <div class="mb-3 inline-dropdown">
                            <div class="row ">
                                <div class="col-md-3 col-sm-6" v-for="(i, index) in initiative.inputs"
                                    :class="i.type == 'money' ? 'has-prefix' : ''">
                                    <label class="form-label">{{i.name }} <i class="fas fa-info-circle" v-if="i.description" data-bs-toggle="tooltip" data-placement="top" :title="i.description"></i></label>
                                    <div class="mb-3" :class="i.type != 'number' ? 'input-group' : ''">
                                        <span class="input-group-text" v-if="i.type == 'money'">$</span>
                                        <input type="text" class="form-control" v-model="i.value" @keypress="isNumber($event)" v-on:paste="cleanUpPaste"
                                            :class="((errors.inputs[index] != '' )? 'is-invalid':'')">
                                        <div class="text-danger invalid-feedback  order-last" v-if="errors.inputs[index] != ''">
                                            {{errors.inputs[index]}}
                                        </div>
                                        <span class="input-group-text" v-if="i.type == 'percentage'">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3" v-if="initiative.initiative_type.is_lifecycle">

            <div class="card box-content">
                <div class="card-body">
                    <h4 >Data Inclusion Filter</h4>
                    <query-inclusion-builder :client="client" :include_trans="true" ref="filter"
                        v-model="initiative.inputs_filter"  :in_card="true"
                        v-if="initiative.initiative_type.is_lifecycle"></query-inclusion-builder>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-3"  >
                    <label class="form-label">Notes</label>
                    <textarea class="form-control bg-white" v-model="initiative.notes" rows="3"></textarea>
                </div>
            </div>
        </div>

        <div class="card box-content mb-3" v-if="false">
            <div class="card-body">
                <h4>Roadmap Documentation</h4>
                <p>The descriptions below will be used when exporting the initiative documentation for the Roadmap books.</p>
                <div class="row">
                    <div class="col">
                        <div class="mb-3"  >
                            <label class="form-label">Strategic Objective<br>
                                <em style="font-weight: normal;">What is the primary purpose of this initiative?</em></label>
                            <vue-editor v-model="initiative.objective" :editorToolbar="customToolbar" ></vue-editor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3"  >
                            <label class="form-label">Tactical Overview<br>
                                <em style="font-weight: normal;">Describe all of the tactical steps necessary to fully execute this initiative. Be as verbose as necessary.</em></label>
                            <vue-editor v-model="initiative.description"  :editorToolbar="customToolbar" ></vue-editor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3"  >
                            <label class="form-label">Implications of Not Implementing<br>
                                <em style="font-weight: normal;">What bad things will happen if this initiative is not implemented?</em></label>
                            <vue-editor v-model="initiative.at_stake"  :editorToolbar="customToolbar" ></vue-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Success Message -->
                <div class="alert alert-success mb-3" v-if="form.successful">
                    The initiative has been updated!
                </div>
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an issue saving this initiative. Check the fields above to get more information.
                </div>
                <!-- Update Button -->
                <div class="mb-3">
                    <button type="submit" class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> Update
                    </button>
                    <button type="button" class="btn btn-secondary ms-2"
                        v-on:click="deleteInitiative()"  v-if="initiative.id != null">
                        <i class="fa fa-btn fa-trash"></i> Delete</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import Swal from 'sweetalert2';
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: { VueEditor, Swal, Datepicker},
        props: ['initiative', 'client', 'plan', 'standard_inputs'],
        data() {
            return {
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    title: "",
                    end_month: "",
                    start_month: "",
                    costs: [],
                    inputs: []
                },
                customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], [{ indent: "-1" }, { indent: "+1" }],["clean"]],
            };
        },
        created() {

            this.addStandardInputs();

            //Populate the plan defaults for the available metrics
            for(var i = 0; i < this.initiative.inputs.length; i++) {
                var token = this.initiative.inputs[i].token;
                var value = this.initiative.inputs[i].value;

                //Average Gift
                if(token == '{avg_gift}' && (value == null || value == ""))
                    this.initiative.inputs[i].value = this.plan.avg_gift;
                //Recurring Average Gift
                if(token == '{recur_avg_gift}' && (value == null || value == ""))
                    this.initiative.inputs[i].value = this.plan.recur_avg_gift;
                //New Donor Average Gift
                if(token == '{new_donor_avg_gift}' && (value == null || value == ""))
                    this.initiative.inputs[i].value = this.plan.new_donor_avg_gift;

                //Add an error flag for each of the inputs
                this.errors.inputs[i] = "";

                //For percentages, need to make it look more readable
                if(this.initiative.inputs[i].type == 'percentage' &&
                    this.initiative.inputs[i].value != null && this.initiative.inputs[i].value != ""){
                    //This is to prevent the floating point precision issue
                    var str_num = this.initiative.inputs[i].value.toString();
                    str_num = str_num.split(".");

                    //If it didn't have a decimal, remove all numbers after the decimal
                    if(str_num.length == 1)
                        this.initiative.inputs[i].value= (this.initiative.inputs[i].value*100).toFixed(0);
                    else
                        //Otherwise cut it off based upon the number of decimal places in the original saved number
                        this.initiative.inputs[i].value= (this.initiative.inputs[i].value*100).toFixed(Math.max(0, str_num[1].length-2));
                }
            }

            //Add an error flag for each of the costs
            for(var i = 0; i < this.initiative.costs.length; i++) {
                this.errors.costs[i] = "";
            }

            this.addNewInputs(this.initiative.initiative_type.inputs, this.initiative.inputs, this.errors.inputs);
            this.addNewInputs(this.initiative.initiative_type.costs, this.initiative.costs, this.errors.costs);


        },
        methods: {
            addNewInputs(inputs, destination, errors) {
                //Loop through the initiative type and verify that all of the inputs exist in the initiative
                for(var i = 0; i < inputs.length; i++) {
                    var token = inputs[i].token;
                    var found = false;
                    for(var j = 0; j < destination.length; j++) {
                        if(destination[j].token == token) {
                            found = true;
                            break;
                        }
                    }
                    if(!found) {
                        destination.push(inputs[i]);
                        errors.push("");
                    }
                }

            },
            addStandardInputs() {
                //Go through all of the calc fields to find the distinct tokens
                var tokens = [];
                if(this.initiative.initiative_type.emails_calc != null)
                    tokens = tokens.concat(this.initiative.initiative_type.emails_calc.match(/{(.*?)}/g));
                if(this.initiative.initiative_type.gifts_calc != null)
                    tokens = tokens.concat(this.initiative.initiative_type.gifts_calc.match(/{(.*?)}/g));
                if(this.initiative.initiative_type.new_donor_calc != null)
                    tokens = tokens.concat(this.initiative.initiative_type.new_donor_calc.match(/{(.*?)}/g));
                if(this.initiative.initiative_type.revenue_calc != null)
                    tokens = tokens.concat(this.initiative.initiative_type.revenue_calc.match(/{(.*?)}/g));
                if(this.initiative.initiative_type.recurring_revenue_calc != null)
                    tokens = tokens.concat(this.initiative.initiative_type.recurring_revenue_calc.match(/{(.*?)}/g));
                if(this.initiative.initiative_type.lifetime_value_calc != null)
                    tokens = tokens.concat(this.initiative.initiative_type.lifetime_value_calc.match(/{(.*?)}/g));

                var unique_tokens = tokens.filter((c, index) => {
                    return tokens.indexOf(c) == index;
                });

                //Then remove the cost tokens
                for(var i = 0; i < this.initiative.costs.length; i++) {
                    if(unique_tokens.indexOf(this.initiative.costs[i].token ) !== -1)
                        unique_tokens.splice(unique_tokens.indexOf( this.initiative.costs[i].token ), 1);
                }

                //Then remove the input tokens
                for(var i = 0; i < this.initiative.inputs.length; i++) {
                    if(unique_tokens.indexOf(this.initiative.inputs[i].token ) !== -1)
                        unique_tokens.splice(unique_tokens.indexOf(this.initiative.inputs[i].token), 1);
                }

                //Now get the standard inputs and compare against those and insert them into the input array
                for(var i = 0; i < this.standard_inputs.length; i++) {
                    if(unique_tokens.indexOf(this.standard_inputs[i].token ) !== -1 &&
                        this.standard_inputs[i].overridable == 1)
                        this.initiative.inputs.push(this.standard_inputs[i]);
                }
            },

            update() {
                var self = this;
                this.form.error = false;

                //If it doesn't have media, make the billing end month equal the start month
                if(this.initiative.initiative_type.calc_type == 'one_time')
                    this.initiative.end_month = this.initiative.start_month;

                //Validate the form
                if(!this.formIsValid()){
                    this.form.error = true;
                    return false;
                }

                //Change all percentages to their actual number
                for(var i = 0; i < this.initiative.inputs.length; i++)
                    if(this.initiative.inputs[i].type == 'percentage')
                        this.initiative.inputs[i].value = parseFloat(this.initiative.inputs[i].value) / 100;

                this.form.busy = true;

                //If it is an initiative type update, then call a different API url
                if(this.initiative.id == null) {
                    window.axios.post('/api/initiative/update_all', this.initiative)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            //setTimeout( function() {self.form.successful = false} , 5000);
                            Swal.fire({
                                title: "Updated!",
                                text: "The initiatives have been updated.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                window.location = "/planning/"+self.plan.url+"/annual"

                            });
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }
                else {
                    window.axios.put('/api/initiative/' + this.initiative.id, this.initiative)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            //setTimeout( function() {self.form.successful = false} , 5000);
                            Swal.fire({
                                title: "Updated!",
                                text: "This initiative has been updated.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                window.location = "/planning/"+self.plan.url+"/annual"

                            });
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }

                  //Change the percentage back
                  for(var i = 0; i < this.initiative.inputs.length; i++)
                    if(this.initiative.inputs[i].type == 'percentage')
                        this.initiative.inputs[i].value = parseFloat(this.initiative.inputs[i].value * 100) ;

            },
            resetErrors() {
                this.errors.title = "";
                this.errors.end_month = "";
                this.errors.start_month = "";

                for(var i = 0; i < this.errors.costs.length; i++)
                    this.errors.costs[i] = "";

                for(var i = 0; i < this.errors.inputs.length; i++)
                    this.errors.inputs[i] = "";
            },
            formIsValid() {
                var is_valid = true;

                //First reset the errors
                this.resetErrors();
                if(this.initiative.title == ""){
                    this.errors.title = "The initiative title is required."
                    is_valid = false;
                }
                if( this.initiative.id != null) {
                    //Blank start month
                    if(this.initiative.start_month == "" ){
                        this.errors.start_month = "You must set a month for the initiative to start."
                        is_valid = false;
                    }
                    //If the month is more than 10 years in the future
                    else if(new Date(this.initiative.start_month).getFullYear() >= new Date().getFullYear()+10){
                        this.errors.start_month = "The initiative start date must be within the next 10 years."
                        is_valid = false;
                    }

                    if(this.initiative.end_month == ""){
                        this.errors.end_month = "You must set the end month for calculating the results and billing amounts."
                        is_valid = false;
                    }
                    else if(new Date(this.initiative.end_month).getFullYear() >= new Date().getFullYear()+10){
                        this.errors.end_month = "The initiative end date must be within the next 10 years."
                        is_valid = false;
                    }
                    //If the start month is after the end month
                    else if(new Date(this.initiative.end_month) < new Date(this.initiative.start_month)){
                        this.errors.end_month = "The end month must come on or after the starting month."
                        is_valid = false;
                    }
                }

                for(var i = 0; i < this.initiative.costs.length; i++)
                    if((this.initiative.costs[i].value == null || this.initiative.costs[i].value === "") && this.initiative.id != null){
                        is_valid = false;
                        this.errors.costs[i] = "Required";
                    }

                for(var i = 0; i < this.initiative.inputs.length; i++)
                    if((this.initiative.inputs[i].value == null || this.initiative.inputs[i].value === "")  && this.initiative.id != null) {
                        this.errors.inputs[i] = "Required";
                        is_valid = false;
                    }

                if(this.initiative.initiative_type.is_lifecycle && !this.$refs.filter.isFormValid())
                    is_valid = false;

                return is_valid;

            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },

            cleanUpPaste: function(e) {

                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                var paste = (e.clipboardData || window.clipboardData).getData('text/plain')
                paste = paste.replace(/[^\d.-]/g, '');

                if (document.queryCommandSupported("insertText")) {
                  document.execCommand("insertText", false, paste);
                } else {
                  document.execCommand("paste", false, paste);
                }
            },
            deleteInitiative() {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this initiative?",
                  text: "The initiative will be removed from the current annual plan.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/initiative/' + self.initiative.id)
                        .then(response => {
                            if (response.status == 200) {
                                //self.getSystemArray(system.system_id).$remove(system);
                                //console.log('deleted',response.data);
                                Swal.fire({
                                    title: "Deleted!",
                                    text: "This initiative has been removed.",
                                    icon: "success",
                                    iconColor:  "#46705F",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    window.location = "/planning/"+self.plan.url+"/annual"

                                });
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });
            }
        }
    }
</script>
