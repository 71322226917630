<template>
    <div class="asset-details-review-edits">
        <div class="row" v-if="local_asset.comments != null && local_asset.comments != ''">
            <div class="col-12">
                <div class="card box-content mt-3">
                    <div class="card-body">
                        <h4 class="mb-2">Client's Comments:</h4>
                        <p v-html="$options.filters.htmlify(local_asset.comments)" class='mb-0'></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="card box-content mt-3">
                    <div class="card-body">
                        <div class="mt-2 mb-4">
                            <h4 class="mb-2">Client's Approved Version</h4>
                            <div class="mb-3">
                                <label class='form-label mb-0'>Reviewed By: </label>
                                <div>
                                    <span class="viewed-user" v-for="review in local_asset.previous_review_list"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" 
                                    :title="(review.status=='approved') ?  'Approved at: '+  getDateAsString(review.updated_at):  'Rejected at: '+  getDateAsString(review.updated_at)">
                                        <i class="fa fa-check green" v-if="review.status=='approved'"></i>
                                        <i class="fa fa-close red" v-if="review.status=='rejected'"></i>
                                        {{ review.updated_by_user.name }}
                                    </span>                                
                                </div>
                            </div>

                            <div v-for="(input_field, index) in edit_inputs" v-if="(input_field.value != '' && input_field.value != null) || (original_inputs[index].value != null && original_inputs[index].value != '')">
                                <div class="row">
                                    <div class="col mb-3" v-if="input_field.type!='image' && input_field.type!='file' && input_field.type!='link'" >
                                        <label class="form-label mb-0">{{input_field.name}} <em v-if="input_field.optional == 1">(Optional)</em></label>

                                        <div :class="getErrorForMaxLength(input_field)" :maxlength="input_field.max_length" :currentlength="(input_field.value != null) ? input_field.value.length: 0" 
                                        v-if="(input_field.type=='text' || input_field.type=='textarea' || input_field.type=='template')">
                                            <!-- <input type="text" v-model="input_field.value" :maxlength="input_field.max_length"
                                            class="form-control asset-description" :class="getErrorForMaxLength(input_field)" @change="inputFieldValueChange()"> -->
                                            <textarea type="text" v-model="input_field.value"  :maxlength="input_field.max_length" class="form-control full-height" :id="'textarea-'+input_field.placeholder + '-' + local_asset.id"
                                            :class="getErrorForMaxLength(input_field)" @change="inputFieldValueChange(index)" @input="resizeTextarea('textarea-'+input_field.placeholder + '-' + local_asset.id)" ref="textarea"></textarea>
                                        </div>

                                        <select v-if="input_field.type=='dropdown'"  v-model="input_field.value" class="form-select asset-details-input-dropdown form-control" 
                                        :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange()">
                                            <option value="" disabled selected>Select the value...</option>
                                            <option v-for="option in input_field.options" :value="option">{{ option }}</option>
                                        </select>        

                                        <!-- <vue-editor v-if="input_field.type=='wysiwyg'"  v-model="input_field.value" @focus="onEditorFocus" :editorOptions="editorOptions" 
                                        @text-change="inputFieldValueChange(index)" ref="textarea"></vue-editor>
                                         -->
                                        <wysiwyg-editor-advanced v-if="input_field.type=='wysiwyg'" :input_value="input_field.value" :input_index="index" :asset_id="local_asset.id" @wysiwygValueChange="wysiwygValueChange" ></wysiwyg-editor-advanced>

                                        <div class="text-danger invalid-feedback" v-if="((errors[input_field.placeholder] && errors[input_field.placeholder]) != '' )" v-html="errors[input_field.placeholder]">
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                            <div>
                                <label class="form-label">Status</label>
                                <select class="form-select" id="status-select" v-model="local_asset.status"
                                :class="((errors.status != '' )? 'is-invalid':'')" @change="clearErrors()">
                                    <option value="" disabled selected>Select the status...</option>
                                    <option value="delete" v-if="wasRejected()">Delete</option>
                                    <option value="for_approval">Send Back for Review</option>
                                    <option value="approved">Approved</option>
                                </select>
                                <div class="text-danger invalid-feedback" v-if="errors.status != ''">
                                    {{errors.status}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- Success Message -->
                            <div class="alert alert-success" v-if="form.successful">
                                The asset's information has been updated!
                            </div>
                            <div class="alert alert-danger" v-if="form.error">
                                There was an error saving the asset information.
                            </div>
                            <div class="alert alert-danger" v-if="form.warning">
                                This is the last edit version for the asset.
                            </div>
                            <!-- Update Button -->
                            <div>
                                <button type="button" class="btn btn-primary me-2"  
                                        :disabled="form.busy" @click="updateEdit()">
                                    <i class="fa fa-btn fa-save"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <!-- <br class="input-field-description"/> -->
                <div class="card box-content mt-3">
                    <div class="card-body">
                        <div class="mt-2">
                            <h4 class="mb-2">Original</h4>
                            <p><em>This version is not editable. Make any additional changes to the Client's Approved Version.</em></p>
                            <div v-for="(input_field, index) in original_inputs" v-if="(input_field.value != '' && input_field.value != null) || (original_inputs[index].value != null && original_inputs[index].value != '')">
                                <div class="row">
                                    <div class="col-12 mb-3" v-if="input_field.type!='image' && input_field.type!='file' && input_field.type!='link'" >
                                        <label class="form-label mb-0">{{input_field.name}}</label>

                                        <div class="input-value-display form-control form-control-sm " :class="input_field.type" v-html="input_field.displayed_value"></div>
                                        
                                        <!-- <div class="text-danger invalid-feedback" v-if="((errors[input_field.placeholder] && errors[input_field.placeholder]) != '' )">
                                            {{errors[input_field.placeholder]}}
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import Swal from 'sweetalert2';
    import moment from 'moment';

    export default {
        components: {VueEditor, Swal},
        props: {
            asset: {},
            client: {},
        },
        data() {
            return {    
                local_asset: {},
                local_edit_version: {},
                original_inputs:[],
                edit_inputs:[],
                if_show_button_input: false,
                errors: {
                    status: '',
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false,
                    warning: false,
                }
            }
        },
        beforeMount() {
            this.local_asset = {...this.asset};
            this.inputFieldsSetup();
            this.checkIfShowButtonInput();            
        },
        mounted(){
            this.resizeTextarea();
        },
        methods: {
            wasRejected() {
                //Loop through local_asset.previous_review_list and see if any of hte statuses are rejected
                var rejected = false;
                this.local_asset.previous_review_list.forEach(review => {
                    if(review.status == 'rejected')
                        rejected = true;
                });

                return rejected;
            },
            getDateAsString(value) {
                return moment.utc(String(value)).local().format('M/DD/YYYY h:mm a')
            },
            checkIfShowButtonInput(){
                if(this.asset.asset_type.name == "Donor Emails" || this.asset.asset_type.name == "Prospecting Emails"
                || this.asset.asset_type.name == "Prospecting Emails"){
                    this.if_show_button_input = true;
                }
            },
            closeModal(id) {
                var myModalEl = document.getElementById(id)
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
            },
            inputFieldsSetup(){
                this.loadInputs('original');
                if(this.local_asset.latest_review != null){
                    this.local_edit_version = this.local_asset.latest_review;
                    this.loadInputs('edit')

                    for (var i = 0; i < this.edit_inputs.length; i++){ 
                        this.compareInputFieldValue(i);
                    }
                }
            },
            loadInputs(version){
                if (version == 'original'){
                    this.original_inputs = [];
                    this.local_asset.asset_type.inputs.forEach(input => {
                        var val = null;
                        if(this.local_asset.inputs != null) {
                            let ph = this.local_asset.inputs.find(i => i.placeholder == input.placeholder);
                            if(ph != null)
                                val = ph.value;
                        }

                        this.original_inputs.push({
                            name: input.name,
                            description:input.description,
                            max_length:input.max_length,
                            placeholder:input.placeholder,
                            optional:input.optional,
                            type:input.type,
                            value: val,
                            displayed_value: val
                        });
                        this.errors[input.placeholder]='';
                    });                    
                } else {
                    this.edit_inputs = [];
                    this.local_asset.asset_type.inputs.forEach(input => {
                        var val = null;
                        if(this.local_edit_version.inputs != null) {
                            let ph = this.local_edit_version.inputs.find(i => i.placeholder == input.placeholder);
                            if(ph != null)
                                val = ph.value;
                        }

                        this.edit_inputs.push({
                            name: input.name,
                            description:input.description,
                            max_length:input.max_length,
                            placeholder:input.placeholder,
                            optional:input.optional,
                            type:input.type,
                            options: input.options,
                            value: val,
                        });
                        this.errors[input.placeholder]='';
                    });     
                }
            },
            compareInputFieldValue(inputFieldIndex){ // return a html elements with text highlighted
                if (this.original_inputs[inputFieldIndex].type == 'wysiwyg'){
                    this.original_inputs[inputFieldIndex].displayed_value = '';

                    // For all <a> links, remove href, change to underlined(<u>) and do text comparison only 
                    let original_value = this.original_inputs[inputFieldIndex].value.replaceAll(/<a.*?\/>/g,'<u></u>').replaceAll(/<a.*?>/g,'<u>').replaceAll(/<\/a>/g,'</u>')
                    .replaceAll(/<img.*?>/g,'<img>').replaceAll(/ style=".*?"/g,'').replaceAll(/ class=".*?"/g,'').split(/<[p>]*>/g).filter(val => val.trim()!='');

                    let edited_value = this.edit_inputs[inputFieldIndex].value.replaceAll(/<a.*?\/>/g,'<u></u>').replaceAll(/<a.*?>/g,'<u>').replaceAll(/<\/a>/g,'</u>')
                    .replaceAll(/<img.*?>/g,'<img>').replaceAll(/ style=".*?"/g,'').replaceAll(/ class=".*?"/g,'').split(/<[p>]*>/g).filter(val => val.trim()!='');

                    //Section by section comparison
                    for (var i = 0; i<original_value.length; i++){
                        if (edited_value[i]){
                            this.original_inputs[inputFieldIndex].displayed_value += this.compareTexts(original_value[i], edited_value[i]);
                        } else{ // if original_value has more sections, highlight in red as being removed
                            this.original_inputs[inputFieldIndex].displayed_value = this.original_inputs[inputFieldIndex].displayed_value 
                            +' <p> <span class="changed_inputs_removed">'+ original_value[i] + '</span> </p> '
                        }
                    }
                    if (edited_value.length > original_value.length){ //if edited_value has more sections, highlight in green as being added
                        this.original_inputs[inputFieldIndex].displayed_value = this.original_inputs[inputFieldIndex].displayed_value 
                            +'<p> <span class="changed_inputs_added">&nbsp</span> </p>'
                    }
                    // Parse result with image, link and button
                    // Note: image, link, and button would have no highlight showing changes made
                    this.processComparedResultWithAdditionalInfo(this.original_inputs[inputFieldIndex].displayed_value, 
                    this.original_inputs[inputFieldIndex].value, inputFieldIndex);
                } else {
                    let original_value = this.original_inputs[inputFieldIndex].value;
                    let edited_value = this.edit_inputs[inputFieldIndex].value;      
                    this.original_inputs[inputFieldIndex].displayed_value = this.compareTexts(original_value, edited_value);
                }
            },
            compareTexts(copyA, copyB){
                let comparedResult = ['<p>'];                                
                let original_value = copyA? copyA.replaceAll('\n', ' <br/> ').split(' ').filter(val => val.trim()!='') : [];
                let edited_value = copyB? copyB.replaceAll('\n', ' <br/> ').split(' ').filter(val => val.trim()!='') : [];                  
                let index_o  = 0; // word index checking in original_value array
                let index_e  = 0; // word index checking in edited_value array
                
                while(index_o < original_value.length) {
                    if (original_value[index_o] && edited_value[index_e] && original_value[index_o].replace(/<[^>]*>/g, '') == edited_value[index_e].replace(/<[^>]*>/g, '')){
                        comparedResult.push(original_value[index_o]); 
                        index_o++;
                        index_e++;
                    // } else if (original_value[index_o] != null && original_value[index_o].trim()=='<br/>'){
                    //     index_o++;
                    //     comparedResult.push('<br/>')
                    // } else if (edited_value[index_e] != null && edited_value[index_e].trim()=='<br/>'){
                    //     index_e++;
                    } else {
                        // Use index_temp to check if original_value[index_o] exists in edited_value
                        // If exist in later order => some words have been added prior to it, highlight them as changed_inputs_added
                        // If not exist in later order => this word has been removed or replaced, highlight a space as changed_inputs_removed
                        var index_temp = [...edited_value].slice(index_e).findIndex(v => v == original_value[index_o]);
                        if (index_temp > 0){
                            var stringAdded = '<span class="changed_inputs_added">&nbsp</span>'+ original_value[index_o];
                            comparedResult.push(stringAdded);
                            index_o++;
                            index_e = index_e + index_temp + 1; // move index_o to the one after index_temp to continue checking                            
                        } else {
                            var strRemoved = '<span class="changed_inputs_removed">' + original_value[index_o] + '</span>'
                            comparedResult.push(strRemoved);
                            index_o++;
                        }
                    }
                }

                // If index_e not at the last word but index_o is at the end, add remaining words highlighted
                if(index_e < edited_value.length ){ 
                    var stringAdded = '<span class="changed_inputs_added">&nbsp</span>'
                    comparedResult.push(stringAdded);
                }
                comparedResult.push('</p>');

                return this.checkForWordReplacement(comparedResult).join(' ');
            },
            checkForWordReplacement(valueArray){
                var result = [];
                for (var i = 0; i< valueArray.length; i++){

                    if (valueArray[i] && valueArray[i+1]){
                        var temp1 = valueArray[i].split('>');
                        var temp2 = valueArray[i+1].split('>');
                        // if word removed and word added are next to each other => word is p
                        if (temp1[0] == '<span class="changed_inputs_removed"' && 
                            temp2[0] == '<span class="changed_inputs_added"'){
                                // temp2 might contain more element after span closing tag, append it to the result as well
                                var wordReplacement = '<span class="changed_inputs_updated">'+ temp1[1].split('<').join(' <') + '>' + temp2.slice(2).join('>');
                                result.push(wordReplacement);
                                i++;                       
                        }
                        else{
                            result.push(valueArray[i])
                        }
                    } else{ // reach the end of valueArray
                        result.push(valueArray[i])
                    }
                }   
                return result;
            },
            processComparedResultWithAdditionalInfo(currentResult, originalValue, inputFieldIndex){
                var resultReturned = currentResult.split('<p');
                var originalValueSectionArray = originalValue.split('<p');
                var specialElementIndexArray = [];
                originalValueSectionArray.forEach((val, index) => {
                    // start with <img : image
                    if (val.startsWith(' class="element-inserted"')){ // this is <p> wrapped custom element, either image or button
                        specialElementIndexArray.push(index);
                    }
                });
                // Loop through specialElementIndexArray and add each element into the final result to display
                specialElementIndexArray.forEach(i =>{
                    resultReturned.splice(i, 0, originalValueSectionArray[i]+'</p>');
                });

                this.original_inputs[inputFieldIndex].displayed_value = resultReturned.join('<p');
            },
            inputFieldValueChange(index){
                this.compareInputFieldValue(index);
                this.$forceUpdate();
            },
            wysiwygValueChange(newValueWithIndex){
                this.edit_inputs.forEach((input, index) => {
                    if (input.type=='wysiwyg' && index == newValueWithIndex.index){
                        this.edit_inputs[index].value = newValueWithIndex.value;
                        this.compareInputFieldValue(index);
                    }
                });
            },
            resizeTextarea(id) {
                if(id == undefined)
                    var element = document.querySelectorAll('textarea');
                else
                    var element = [document.getElementById(id)];

                for(var index = 0; index<element.length; index++){
                    if (element[index] && element[index].style){
                        element[index].style.height = '';
                        element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                    }
                }
            },
            getErrorForMaxLength(input) {
                var classes = ''
                if (input.value != null && input.max_length != null && input.value.length > input.max_length){
                    this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". When generating text, the AI has a hard time following character limit rules&mdash;you'll need to adjust."
                }
                else if(input.value != null && input.value != "")
                    this.errors[input.placeholder] = "";

                if (input.max_length != null)
                    classes += 'max-warning'

                if(this.errors[input.placeholder] != null && this.errors[input.placeholder] != '' )
                    classes +=' is-invalid';

                return classes;
            },
            clearErrors(){
                for (const error_name in this.errors) {
                    this.errors[error_name] = ''
                }
            },
            hasErrors(){
                // only two statuses allowed
                if(this.local_asset.status != 'for_approval' && this.local_asset.status != 'approved' && this.local_asset.status != 'delete'){
                    this.errors.status = 'Status is required.';
                } else
                    this.errors.status = '';

                this.edit_inputs.forEach(input => {
                    if ((input.value == null || input.value == '') && (input.type == 'text' || input.type=='textarea' || input.type=='wysiwyg') && input.optional == 0){
                        this.errors[input.placeholder] = input.name + ' is required.';
                    } else if (input.max_length != null && input.value.length > input.max_length){
                        this.errors[input.placeholder] = 'Maximum length exceeded. The max length for ' + input.name + ' is ' + input.max_length;
                    } else {
                        this.errors[input.placeholder] = ''
                    }
                })

                for (const error_name in this.errors) {
                    if (this.errors[error_name] != '') {
                        return true;
                    }
                }
                return false;
            },    
            updateEdit(){
                var self = this;

                this.clearErrors();
                if(this.hasErrors()) {
                    this.$forceUpdate();
                    return;
                }
                this.form.busy = true;

                var asset_input = []
                this.edit_inputs.forEach(input_field =>{
                    if(input_field.type == "wysiwyg" && input_field.value){
                        var formatted_value = input_field.value.replace('data-bs-toggle="modal" ', '')
                        .replace('data-bs-target="#insert-image-modal-'+this.local_asset.id +'\" ', '')
                        .replace('data-bs-target="#insert-button-modal-'+this.local_asset.id +'\" ', '');

                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: formatted_value
                        });
                    } else {
                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: input_field.value
                        });                        
                    }
                });
                this.local_asset.inputs = asset_input;
                
                window.axios.put('/api/campaigns/'+this.client.url+'/assets/'+this.local_asset.id, this.local_asset)
                      .then(response => {
                        self.form.busy = true;
                        if(response.status == 200) {
                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false} , 5000);
                            this.$emit("assetReviewedWithStatusChange", response.data.asset);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                    });
            },        
        }
    }
</script>
