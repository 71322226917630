<template>
    <div class="modal-dialog modal-lg w-100">
        <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
                <div class="text-center w-100">
                    <button type="button" class="btn btn-none float-end" data-bs-dismiss="dimension-filter-modal" @click.prevent="closeModal('dimension-filter-modal')">
                        <i class="fa fa-close"></i>
                    </button>
                    <h4 class="mt-2">{{ this.action }} a User-Defined Filter</h4>
                </div>
            </div>
            <div class="modal-body pt-0 ">
                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label">Filter Label</label>
                        <input type="text" id="filter-name-input" class="form-control" v-model="local_dimensional_filter.label"
                            :class="((local_filter_errors.filter_label != '' )? 'is-invalid':'')" @change="clearErrorForField('filter_label')">
                        <div class="text-danger invalid-feedback" v-if="local_filter_errors.filter_label != ''">
                            {{local_filter_errors.filter_label}}
                        </div>
                    </div>

                    <div class="col mb-3">
                        <label class="form-label">Database Column Name</label>
                        <accordion-select-multi-tier-category v-model="local_dimensional_filter.column" :class="((local_filter_errors.filter_column != '' )? 'is-invalid':'')"
                            :options="columns" @input="loadDatabaseValues()" @change="clearErrorForField('filter_column')"></accordion-select-multi-tier-category>
                        <div class="text-danger invalid-feedback" v-if="local_filter_errors.filter_column != ''">
                            {{local_filter_errors.filter_column}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label">Type</label>
                        <select class="form-select" :class="((local_filter_errors.field_type != '' )? 'is-invalid':'')"
                        id="filter-type-select" v-model="local_dimensional_filter.field_type" @change="changeFieldType()">
                            <option value="" disabled selected>Select the filter type...</option>
                            <option value="single_select">Single Select</option>
                            <option value="multi_select">Multiple Select</option>
                            <option value="date_range">Date Range</option>
                            <option value="month_range">Month Range</option>
                            <option value="month_picker">Month Picker</option>
                            <option value="numeric_slider">Numeric Slider</option>
                        </select>
                        <div class="text-danger invalid-feedback" v-if="local_filter_errors.field_type != ''">
                            {{local_filter_errors.field_type}}
                        </div>
                    </div>

                    <div class="col mb-3" >
                        <advanced-filter-dropdown v-if="local_dimensional_filter.field_type == 'single_select' || 
                            local_dimensional_filter.field_type == 'multi_select'|| 
                            local_dimensional_filter.field_type == 'numeric_slider'" 
                            :class="((local_filter_errors.default_value != '' )? 'is-invalid':'')"
                            :filter="local_dimensional_filter" @searchInLongOptionList="searchInLongOptionList" 
                            @updateFilter="updateDimensionalFilterDefaultValue"></advanced-filter-dropdown>

                        <div v-else-if="local_dimensional_filter.field_type == 'date_range'" class="d-flex w-100">
                            <div class="me-3">
                                <label class="form-label">Start Date</label>
                                <datepicker v-model="local_dimensional_filter.default_value.start_date" input-class="form-control date" placeholder='MM/DD/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/d/yyyy"  :clear-button="true" />
                            </div>
                            <div>
                                <label class="form-label">End Date</label>
                                <datepicker v-model="local_dimensional_filter.default_value.end_date" input-class="form-control date" placeholder='MM/DD/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/d/yyyy"  :clear-button="true" />
                            </div>
                        </div>
                        <div v-else-if="local_dimensional_filter.field_type == 'month_range'" class="d-flex w-100">
                            <div class="me-3">
                                <label class="form-label">Start Month</label>
                                <datepicker v-model="local_dimensional_filter.default_value.start_date" input-class="form-control date" :minimumView="'month'" :maximumView="'month'" placeholder='MM/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/yyyy"  :clear-button="true" />
                            </div>
                            <div>
                                <label class="form-label">End Month</label>
                                <datepicker v-model="local_dimensional_filter.default_value.end_date" input-class="form-control date" :minimumView="'month'" :maximumView="'month'" placeholder='MM/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/yyyy"  :clear-button="true" />
                            </div>
                        </div>

                        <div v-else-if="local_dimensional_filter.field_type == 'month_picker'">
                            <label class="form-label">Default Value</label>
                            <div v-if="local_dimensional_filter.options && local_dimensional_filter.options.includes('Loading Options...')">Loading Options...</div>
                            <div v-else class="row month-picker-grid mx-1">
                                <div v-for="month in local_dimensional_filter.options" class="month-grid col-3 text-center py-2" @click="changeMonthValue(month)"
                                :class="local_dimensional_filter.default_value && local_dimensional_filter.default_value.includes(month)? 'month-grid-selected':''" >{{month}}</div>
                            </div>
                        </div>
                        
                        <div v-else>
                            <label class="form-label">Default Value</label>
                            <input type="text" class="form-control" :class="((local_filter_errors.default_value != '' )? 'is-invalid':'')" :value="local_dimensional_filter.default_value">
                        </div>

                        <div class="text-danger invalid-feedback" v-if="local_filter_errors.default_value != ''">
                            {{local_filter_errors.default_value}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col" v-if="show_group_name">
                        <div class="mb-3">
                            <label class="form-label">Group Name</label>
                            <select type="text" class="form-select"
                                :class="((local_filter_errors.group_name != '' )? 'is-invalid':'')"
                                v-model="local_dimensional_filter.group_name"  @change="clearErrorForField('group_name')">
                                <option v-for="group in group_names" :value="group">{{group}}</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="local_filter_errors.group_name != ''">
                                {{local_filter_errors.group_name}}
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="mb-3" :class="(show_group_name?'mt-4':'')">
                            <label class="form-label"><input class="form-check-input me-2" type="checkbox" v-model="local_dimensional_filter.is_required">Required for this report</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-secondary" id="save-button"
                                @click.prevent="updateFilterWithinGroup()"
                                :disabled="form.busy">
                            <i class="fa fa-btn fa-plus"></i> {{ this.action }} Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import vSelect from "vue-select";
import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
            Swal, vSelect, Datepicker,
        },
        props: {
          dimensional_filter: {
            type: Object, // Adjust type according to your needs
            required: false // Set to true if this prop is required
          },
          action: {
            type: String,
            default: 'Add'
          },
          data_blend: {
            required: true // Set to true if this prop is required
          },
          metrics_filters : {
            type: Array,
            required: false
          },
          client_id: {},
          show_group_name : {
            default: false
          }
        },
        data() {
            return {
                local_dimensional_filter: null,
                local_metrics_filters: null,
                local_filter_errors: {
                    filter_label: '',
                    field_type: '',
                    filter_column: '',
                    default_value: '',
                    group_name: ''
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                group_names: [],
                columns: []
            };
        },
        beforeMount() {
            if(this.dimensional_filter != null) {
                this.local_dimensional_filter = JSON.parse(JSON.stringify(this.dimensional_filter));
                this.local_dimensional_filter.options = [];
            } else{
                this.resetDimensionalFilter();
            }
                
            this.local_metrics_filters = this.metrics_filters;
            if(this.data_blend != null)
                this.refreshColumns();
        },
        watch:{
            dimensional_filter: {
                handler: function(newVal, oldVal) {
                    if(this.dimensional_filter != null) {
                        this.local_dimensional_filter = JSON.parse(JSON.stringify(this.dimensional_filter));
                    } else
                        this.resetDimensionalFilter();

                    this.loadDatabaseValues();
                },
                deep: true,
            },
            data_blend: {
                handler: function(newVal, oldVal) {
                    this.refreshColumns()

                    if(this.show_group_name){
                        this.getGroupNames();
                    }
                },
                deep: true,
            }
        },
        methods: {
            refreshColumns(){
                var data = {
                  include_trans: true,
                  source: 'data_blend',
                  data_source_id: this.data_blend.id
                };
                this.columns = [];

                window.axios.post('/api/bigquery/get_db_columns', data)
                  .then(response => {
                    var cols = response.data.columns;
                    var headers = "";
                    for(var i = cols.length - 1; i >= 0; i--){
                        //If it is a custom column
                        if((this.client_id == null || this.client_id == "") && (cols[i].value.indexOf('custom.') >= 0 || cols[i].value.indexOf('ustom_calculations') > 0))
                            //Remove the column
                            cols.splice(i, 1);
                        else
                            cols[i].main_category = 'Columns';
                    }
                    // this.columns = cols;
                    if(this.local_metrics_filters != null && this.local_metrics_filters.length > 0)
                        this.addCalculationsToColumnDropdown();
                    else
                        this.getMetricsFilters();
                  });
            },
            getMetricsFilters() {
                window.axios.post('/api/metric_filter/list', {data_blend_id: this.data_blend.id})
                  .then(response => {
                    this.local_metrics_filters = response.data.metrics_filters;
                    if(this.local_metrics_filters != null && this.local_metrics_filters.length > 0)
                        this.addCalculationsToColumnDropdown();
                  });
            },
            getGroupNames() {
                window.axios.post('/api/metric_filter/group_names', {client_id: this.client_id})
                  .then(response => {
                    this.group_names = response.data.group_names;
                    this.$forceUpdate();
                  });
            },
            addCalculationsToColumnDropdown(){
                var metrics = [];

                this.local_metrics_filters.forEach(metric_filter_obj =>{
                    //If it is a specific client and that client isn't selected
                    if(metric_filter_obj.data_blend_id != null && metric_filter_obj.data_blend_id != this.data_blend.id)
                        return;

                    if (metric_filter_obj.type == 'dimension' && metric_filter_obj.data_blend_type == this.data_blend.type){
                        metric_filter_obj.main_category = 'Dimensions';
                        metric_filter_obj.value = metric_filter_obj.id;
                        metric_filter_obj.text = metric_filter_obj.admin_name;
                        metrics.push(metric_filter_obj)
                    }
                })
                this.columns = this.columns.concat(metrics);
                this.$forceUpdate();
            },
            resetDimensionalFilter() {
                this.local_dimensional_filter = {
                    label: 'Default Value',
                    field_type: '',
                    is_required: false,
                    column: null,
                    default_value: [],
                    order: null,
                    group_name: '',
                    options: []
                };
            },
            loadDatabaseValues() {
                if(this.local_dimensional_filter.column == null){
                    this.local_dimensional_filter.options = [];
                    return;
                }
                //Don't get DB values for a numeric slider, get the min/max
                else if(this.local_dimensional_filter.field_type == 'numeric_slider'){
                    this.getMinMax();
                    return;
                }

                var data = {
                  source: 'data_blend',
                  column: this.local_dimensional_filter.column,
                  limit: 1000,
                  data_source_id: this.data_blend.id,
                  staging_table: false
                };

                if(this.local_dimensional_filter.field_type == 'single_select' || this.local_dimensional_filter.field_type == 'multi_select'){
                    //Don't add it if it is already loading the options
                    if(!this.local_dimensional_filter.options)
                        this.local_dimensional_filter.options = [];
                    if (!this.local_dimensional_filter.options.includes("Loading Options..."))
                        this.local_dimensional_filter.options.push("Loading Options...");
                }

                window.axios.post('/api/bigquery/get_samples', data)
                  .then(response => {
                    this.local_dimensional_filter.options = [];
                    
                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--){
                        if(response.data.samples[i].name == null)
                            response.data.samples.splice(i, 1);
                        else {
                            this.local_dimensional_filter.options.push(response.data.samples[i].name);                 
                        }                        
                    }

                    if (this.local_dimensional_filter.field_type == 'month_picker') {
                        // sort by month order
                        const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        this.local_dimensional_filter.options.sort((a, b) => monthOrder.indexOf(a) > monthOrder.indexOf(b) ? 1 : -1);
                    } else {
                        this.local_dimensional_filter.options.sort((a, b) => (a.name > b.name ) ? 1 : -1); 
                    }

                    //Make a copy of the local_dimensional_filter object to trigger the filter refresh
                    var filter = JSON.parse(JSON.stringify(this.local_dimensional_filter));
                    this.local_dimensional_filter = filter;

                    this.$forceUpdate();
                });
            },

            getMinMax() {
                var data = {
                  source: 'data_blend',
                  column: this.local_dimensional_filter.column,
                  data_source_id: this.data_blend.id,
                };
                if(this.local_dimensional_filter.options && this.local_dimensional_filter.options.length == 2){
                    //Verify they are both numbers
                    if(!isNaN(this.local_dimensional_filter.options[0]) && !isNaN(this.local_dimensional_filter.options[1])){
                        //Temporarily set it to the default values
                        this.local_dimensional_filter.slider_max = this.local_dimensional_filter.options[1];
                        this.local_dimensional_filter.slider_min = this.local_dimensional_filter.options[0];
                    }
                    else{
                        this.local_dimensional_filter.slider_max = 1;
                        this.local_dimensional_filter.slider_min = 0;
                        this.local_dimensional_filter.options = [0, 1];
                    }
                }
                else {
                    //Just set it to 0 and 1 for now until the data can be pulled
                    this.local_dimensional_filter.slider_max = 1;
                    this.local_dimensional_filter.slider_min = 0;
                    this.local_dimensional_filter.options = [0, 1];
                }

                window.axios.post('/api/bigquery/get_min_max', data)
                    .then(response => {
                        this.local_dimensional_filter.slider_min = response.data.min;
                        this.local_dimensional_filter.slider_max = response.data.max;

                        if(this.local_dimensional_filter.options && this.local_dimensional_filter.options.length == 2 && !isNaN(this.local_dimensional_filter.options[0]) && !isNaN(this.local_dimensional_filter.options[1]) ){
                            if(this.local_dimensional_filter.options[0] < this.slider_min)
                                this.local_dimensional_filter.slider_min = this.local_dimensional_filter.options[0];
                            if(this.local_dimensional_filter.options[1] > this.slider_max)
                                this.local_dimensional_filter.slider_max = this.local_dimensional_filter.options[1];
                        }
                        //Make a copy of the local_dimensional_filter object to trigger the filter refresh
                        var filter = JSON.parse(JSON.stringify(this.local_dimensional_filter));
                        this.local_dimensional_filter = filter;
                        this.$forceUpdate();
                    });
            },
            updateDimensionalFilterDefaultValue(value_details){
                this.clearErrorForField('default_value');
                this.local_dimensional_filter.default_value = value_details.filter_value;
            },
            hasErrors() {//validate the form
                if(this.local_dimensional_filter.label == null || this.local_dimensional_filter.label == ""){
                    this.local_filter_errors.filter_label = "A filter label is required.";
                } else
                    this.local_filter_errors.filter_label = "";
                
                if(this.local_dimensional_filter.field_type == null || this.local_dimensional_filter.field_type == ""){
                    this.local_filter_errors.field_type = "A filter type is required.";
                } else
                    this.local_filter_errors.field_type = "";
                
                if(this.local_dimensional_filter.column == null || this.local_dimensional_filter.column == ""){
                    this.local_filter_errors.filter_column = "A database column is required.";
                } else
                    this.local_filter_errors.filter_column = "";

                if(this.local_dimensional_filter.is_required && (this.local_dimensional_filter.default_value == null || this.local_dimensional_filter.default_value.length == 0) ){
                    this.local_filter_errors.default_value = "You must select default value for a required filter";
                } else
                    this.local_filter_errors.default_value = "";

                if(this.show_group_name && (this.local_dimensional_filter.group_name == null || this.local_dimensional_filter.group_name == '') ){
                    this.local_filter_errors.group_name = "You must select a group name";
                } else
                    this.local_filter_errors.group_name = "";

                if (this.local_filter_errors.filter_label!="" || this.local_filter_errors.field_type!="" 
                || this.local_filter_errors.filter_column!="" || this.local_filter_errors.default_value!=""  || this.local_filter_errors.group_name!="" ){
                    return true
                } else {
                    return false
                }
            },
            updateFilterWithinGroup(){
                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                if(this.local_dimensional_filter.field_type == 'month_range'){
                    this.local_dimensional_filter.default_value.start_date = moment(String(this.local_dimensional_filter.default_value.start_date)).utc().format('YYYY-MM-DD');
                    this.local_dimensional_filter.default_value.end_date = moment(String(this.local_dimensional_filter.default_value.end_date)).utc().format('YYYY-MM-DD');
                }
                else if(this.local_dimensional_filter.field_type == 'numeric_slider'){
                    //If the min and max are the same, just wipe the default value
                    if(this.local_dimensional_filter.default_value && 
                        this.local_dimensional_filter.default_value[0] == this.local_dimensional_filter.slider_min && 
                        this.local_dimensional_filter.default_value[1] == this.local_dimensional_filter.slider_max)
                        this.local_dimensional_filter.default_value = null;
                }

                this.$emit('updateFilterWithinGroup', this.local_dimensional_filter);
                this.resetDimensionalFilter();
                // this.$forceUpdate()
            },
            changeMonthValue(month){
                if (this.local_dimensional_filter.default_value.includes(month)){
                    this.local_dimensional_filter.default_value = this.local_dimensional_filter.default_value.filter((m) => m !== month);
                } else {
                    this.local_dimensional_filter.default_value.push(month);
                }
            },
            searchInLongOptionList(search_details){
                var data = {
                    client: this.client,
                    source: 'data_blend',
                    column: filter.column,
                    limit: 1000,
                    data_source_id: this.pages_selected[0].data_blend.id,
                    staging_table: false,
                    search: search_details.search_key
                };
                window.axios.post('/api/bigquery/get_samples', data)
                  .then(response => {
                    this.local_dimensional_filter.options = [];
                    
                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--)
                        if(response.data.samples[i].name == null)
                            response.data.samples.splice(i, 1);
                        else {
                            //if(this.local_dimensional_filter.field_type == 'single_select' || this.local_dimensional_filter.field_type == 'multi_select'){
                                this.local_dimensional_filter.options.push(response.data.samples[i].name);                 
                            //}
                        }
                    //No way a long seach can be a month picker
                    this.local_dimensional_filter.options.sort((a, b) => (a.name > b.name ) ? 1 : -1); 
                    this.local_dimensional_filter = JSON.parse(JSON.stringify(this.local_dimensional_filter));
                    
                    // if(this.local_dimensional_filter.options.length == 0)
                    //     this.local_dimensional_filter.options.push("** All values are blank **");  
                    this.$forceUpdate();
                });
            },
            changeFieldType(){
                this.clearErrorForField('field_type');
                if (this.local_dimensional_filter.field_type == 'date_range' || this.local_dimensional_filter.field_type == 'month_range' ){
                    this.local_dimensional_filter.default_value = {
                        start_date: null,
                        end_date: null
                    }
                } 
                if (this.local_dimensional_filter.field_type == 'single_select'){
                    this.local_dimensional_filter.default_value = null;
                } 
                if (this.local_dimensional_filter.field_type == 'multi_select'){
                    this.local_dimensional_filter.default_value = [];
                } 
                if (this.local_dimensional_filter.field_type == 'month_picker'){
                    this.local_dimensional_filter.default_value = [];
                } 
                if (this.local_dimensional_filter.field_type == 'numeric_slider'){
                    this.getMinMax();
                } 

                this.$forceUpdate();
            },
            clearErrorForField(field_name){
                this.local_filter_errors[field_name] = '';
            },
            clearErrors() { //reset errors to empty strings
                this.local_filter_errors = {
                    filter_name: '',
                    field_type: '',
                    filter_column: '',
                    default_value: '',
                    group_name: ''
                }
            },
            closeModal(modal_ID){
                this.resetDimensionalFilter();
                var myModalEl = document.getElementById(modal_ID)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
        }
    }
</script>
