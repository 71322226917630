<template>
    <div class="calendar-view">
        <div class="calendar-grid">
            <div class="month" v-for="(month, monthIndex) in months" :key="monthIndex">
                <div class="month-name">{{ month.name }}</div>
                <div class="days">
                    <div 
                        class="day" 
                        v-for="(day, dayIndex) in month.days" 
                        :key="dayIndex"
                        :class="{ 'launch-date': isLaunchDate(day) }"
                        @dragover.prevent="onDragOver"
                        @drop="onDrop(day)"
                    >

                        <div v-if="isLaunchDate(day)" class="launch-label">End Date</div>
                        <div class="date-number" v-else>{{ day.date }}</div>
                        <div class="assets-container">
                            <div
                                v-for="(asset, assetIndex) in day.assets"
                                :key="asset.pivot.id"
                                class="asset"
                                :style="{ backgroundColor: getAssetColor(asset) }"
                                draggable="true"
                                @dragstart="onDragStart(asset, day)"
                                data-bs-toggle="tooltip"
                                data-bs-html="true"
                                :title="asset.pivot.name + '<br>(' + (asset.name.endsWith('s') ? asset.name.slice(0, -1) : asset.name) + ')'"
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Key shown below the calendars -->
        <div class="asset-key">
            <div v-for="(assetType, index) in getUniqueAssetTypes()" :key="assetType.id" class="key-item">
                <div class="color-box" :style="{ backgroundColor: getAssetColor(assetType) }"></div>
                <span>{{ assetType.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        assetTypes: Array,
        end_date: {
            type: [Date, String],
            default: null,
        },
    },
    data() {
        return {
            months: [],
            assetTypesWithLaunchDate: [],
            draggedAsset: null, // Holds the asset being dragged
            sourceDay: null,     // Holds the source day of the dragged asset
            launchDay: null,     // Holds the launch day
        };
    },
    mounted() {
        this.initializeCalendar();
    },

    methods: {
        // Retrieve the last day of the second month when end_date is not set
        getLastDayOfSecondMonth() {
            const today = new Date();
            // Set to the last day of the second month (28th day)
            const secondMonth = new Date(today.getFullYear(), today.getMonth() + 1, 28);
            return secondMonth;
        },
        getLastDayOfFirstMonth() {
            const today = new Date();
            // Set to the last day of the second month (28th day)
            const firstMonth = new Date(today.getFullYear(), today.getMonth(), 28);
            return firstMonth;
        },

        // Check if a given day is the launch date
        isLaunchDate(day) {
            return (
                day.date === this.launchDay.getDate() &&
                day.month === this.launchDay.getMonth() &&
                day.year === this.launchDay.getFullYear()
            );
        },

        // Retrieve unique asset types for the asset key
        getUniqueAssetTypes() {
            // Get the unique asset types and return them
            return this.assetTypesWithLaunchDate.filter((asset, index, self) =>
                index === self.findIndex((t) => (
                    t.id === asset.id
                ))
            );
        },

        // Initialize the calendar by setting up months and placing assets
        initializeCalendar() {
            // Filter assetTypes where has_launch_date is true
            this.assetTypesWithLaunchDate = this.assetTypes.filter(asset => asset.has_launch_date);

            if (this.end_date) {
                // If end_date is provided, use it as the last day
                this.launchDay = new Date(this.end_date);
            } else {
                //Get the max days_before_end_date in the assetTypesWithLaunchDate array
                const maxDaysFromEnd = Math.max(...this.assetTypesWithLaunchDate.map(asset => asset.pivot.days_before_end_date));

                if(maxDaysFromEnd <= 28){
                    // If the max days_before_end_date is less than 28, set the launch day to today
                    this.launchDay = this.getLastDayOfFirstMonth();
                } else
                    // If end_date is null, use the last day of the second month (28th day)
                    this.launchDay =  this.getLastDayOfSecondMonth();
            }

            // Generate months and days based on the lastDayOfCalendar
            this.months = this.generateMonths();

            // Place assets into the calendar based on their days_before_end_date
            this.placeAssetsInCalendar();

            // Initialize bootstrap tooltips
            this.$nextTick(() => {
                var tooltipTriggerList = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'))
                tooltipTriggerList.forEach(function (tooltipTriggerEl) {
                    new bootstrap.Tooltip(tooltipTriggerEl)
                })
            });
        },

        // Generate month and day structures
        generateMonths() {
            let months = [];

            if (this.end_date) {
                //Get the min days_before_end_date in the assetTypesWithLaunchDate array
                const minDaysFromEnd = Math.min(...this.assetTypesWithLaunchDate.map(asset => asset.pivot.days_before_end_date));
                //Get the max days_before_end_date in the assetTypesWithLaunchDate array
                const maxDaysFromEnd = Math.max(...this.assetTypesWithLaunchDate.map(asset => asset.pivot.days_before_end_date));
                //Get the day of the end date
                const endDay = new Date(this.end_date).getDate();

                let monthDiff = 1;
                if(endDay - minDaysFromEnd >= 28)
                    monthDiff = 0;

                // Generate two months ending with the end_date
                let startMonth = new Date(this.launchDay.getFullYear(), this.launchDay.getMonth() - monthDiff, 1);

                for (let m = 0; m < 2; m++) {
                    let monthDate = new Date(startMonth.getFullYear(), startMonth.getMonth() + m, 1);
                    let monthName = monthDate.toLocaleString('default', { month: 'long', year: 'numeric' });
                    let daysInMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();

                    let days = [];
                    for (let d = 1; d <= daysInMonth; d++) {
                        days.push({
                            date: d,
                            month: monthDate.getMonth(),
                            year: monthDate.getFullYear(),
                            assets: [],
                        });
                    }

                    months.push({
                        name: monthName,
                        days: days,
                    });
                }
            } else {
                // Generate two months labeled "Month 1" and "Month 2", each with 28 days
                const today = new Date();
                for (let m = 1; m <= 2; m++) {
                    let monthName = `Month ${m}`;
                    let year = today.getFullYear();
                    let month = today.getMonth() + m; // Current month +1 and +2

                    let days = [];
                    for (let d = 1; d <= 28; d++) {
                        days.push({
                            date: d,
                            month: month - 1, // JavaScript months are 0-indexed
                            year: year,
                            assets: [],
                        });
                    }

                    months.push({
                        name: monthName,
                        days: days,
                    });
                }
            }

            return months;
        },

        // Place assets into their respective days based on days_before_end_date
        placeAssetsInCalendar() {
            if (this.assetTypesWithLaunchDate.length === 0) return;

            // Place assets into days based on their days_before_end_date relative to lastDayOfCalendar
            this.assetTypesWithLaunchDate.forEach(asset => {
                let daysFromEnd = asset.pivot.days_before_end_date;
                //If it is the the 28 day view and it is in Month 2
                if (this.end_date == null && daysFromEnd < 0) {
                    //Figure out how many days are in the launchDay month
                    const daysInMonth = new Date(this.launchDay.getFullYear(), this.launchDay.getMonth() + 1, 0).getDate();
                    let dayDiff = daysInMonth - this.launchDay.getDate();
                    daysFromEnd -= dayDiff;
                }
                //Make a copy of the launchDay
                let assetDate = new Date(this.launchDay);
                assetDate.setDate(this.launchDay.getDate() - daysFromEnd);
                let monthName;

                if (this.end_date) {
                    monthName = assetDate.toLocaleString('default', { month: 'long', year: 'numeric' });
                } else {
                    // If it is a negative, it is after the end date
                    if (daysFromEnd < 0) {
                        monthName = 'Month 2';
                    } else {
                        monthName = 'Month 1';
                    }
                }

                let monthIndex = this.months.findIndex(month => month.name === monthName);
                if (monthIndex >= 0) {
                    let dayIndex = assetDate.getDate() - 1;
                    if (dayIndex >= 0 && dayIndex < this.months[monthIndex].days.length) {
                        this.months[monthIndex].days[dayIndex].assets.push(asset);
                    } else {
                        console.warn(`Day index out of bounds for asset ${asset.name}:`, dayIndex);
                    }
                } else {
                    console.warn(`Month not found for asset ${asset.name}:`, monthName);
                }
            });
        },

        // Assign colors based on the provided theme colors
        getAssetColor(asset) {
            const themeColors = [
                // Primary Colors
                '#25473E', '#46705F', '#33A96E', '#19CF78', '#A7F8D1',
                
                // Secondary Colors
                '#6FB5FC', '#9ACEFD', '#C5E8FF',
                
                // Tertiary Colors
                '#FFBB31', '#FFD36F', '#FFECAE',

                // Data Revenue Colors
                '#08874A', '#0AA65C', '#19CF78', '#45DF95', '#6BF3B2', '#A7F8D1',

                // Data Donors Colors
                '#094789', '#0A74E6', '#3395FF', '#7ABAFF', '#A8CCF4', '#D3E4F7',

                // Data Gift Colors
                '#EBB500', '#F8BE00', '#FFCB20', '#FFDB66', '#FFE591', '#FFECAE',

                // Data Other Colors
                '#6B14C3', '#8726E9', '#A64CFF', '#B77BF4', '#D2A8FD', '#DFC0FF'
            ];

            // Cycle through the colors using asset.id to ensure each asset gets a consistent color
            const colorIndex = asset.id % themeColors.length;
            return themeColors[colorIndex];
        },

        // Handle drag start
        onDragStart(asset, day) {
            this.draggedAsset = asset;
            this.sourceDay = day;
        },

        // Handle drag over (already prevented in template)
        onDragOver(event) {
            // No additional logic needed here since we prevent default in the template
        },

        // Handle drop
        onDrop(targetDay) {
            if (this.draggedAsset && this.sourceDay) {
                // Remove the asset from the source day
                const assetIndex = this.sourceDay.assets.findIndex(a => a.pivot.id === this.draggedAsset.pivot.id);
                if (assetIndex !== -1) {
                    this.sourceDay.assets.splice(assetIndex, 1);
                }

                // Add the asset to the target day if it's not already present
                if (!targetDay.assets.some(a => a.pivot.id === this.draggedAsset.pivot.id)) {
                    targetDay.assets.push(this.draggedAsset);
                }

                // Update days_before_end_date based on the new position
                //Get the days different between the lauchDate and the target day
                let launchDate = new Date(this.launchDay);
                let targetDate = new Date(targetDay.year, targetDay.month, targetDay.date);

                // Ensure both dates are treated in the same time zone
                launchDate.setUTCHours(0, 0, 0, 0);
                targetDate.setUTCHours(0, 0, 0, 0);

                let daysFromEnd = Math.floor((launchDate - targetDate) / (1000 * 3600 * 24));
                //If it is the default
                if (this.end_date == null && daysFromEnd < 0) {
                    //Figure out how many days are in the launchDay month
                    const daysInMonth = new Date(this.launchDay.getFullYear(), this.launchDay.getMonth() + 1, 0).getDate();
                    let dayDiff = daysInMonth - this.launchDay.getDate();
                    daysFromEnd += dayDiff;
                }

                this.draggedAsset.pivot.days_before_end_date = daysFromEnd;

                // Emit the updated asset types to the parent component
                this.$emit('update-days', this.assetTypesWithLaunchDate);

                // Reset the dragged asset and source day
                this.draggedAsset = null;
                this.sourceDay = null;
            }

            // Hide all the tooltips
            this.hideAllTooltips();
        },

        // Hide all Bootstrap tooltips
        hideAllTooltips() {
            const tooltipTriggerList = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                const tooltipInstance = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltipInstance) {
                    tooltipInstance.hide(); // Hides the tooltip
                }
            });
        },

    },
    watch: {
        assetTypes: {
            handler() {
                this.initializeCalendar();
            },
            deep: true,
        },
        end_date: {
            handler() {
                this.initializeCalendar();
            },
        },
    },
};
</script>

<style scoped>
.calendar-view {
    /* Styles for the calendar view */
}

.calendar-grid {
    display: flex;
    flex-wrap: wrap; /* Allow months to wrap if necessary */
}

.month {
    flex: 1;
    margin: 0 10px;
}

.month-name {
    text-align: center;
    font-weight: bold;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #25473E; /* Example color; will be overridden by inline style */
    color:white;
}

.days {
    display: flex;
    flex-wrap: wrap;
}

.day {
    width: calc(100% / 7); /* 7 days a week */
    border: 1px solid #ccc;
    min-height: 120px; /* Increased to provide more space for assets */
    position: relative;
    box-sizing: border-box;
}

.date-number {
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 2px 6px;
}

.assets-container {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    padding-top: 0;
    gap: 5px; /* Space between assets */
}

.asset {
    /* Calculate width to fit 3 assets per row with gap */
    width: calc((100% / 3) - (2 * 5px / 3)); /* Adjust width with gap */
    aspect-ratio: 1 / 1; /* Ensures height matches width */
    margin: 0;
    cursor: grab;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    box-sizing: border-box;
    padding: 5px;
    background-color: #46705A; /* Example color; will be overridden by inline style */
}

.asset:active {
    cursor: grabbing;
}

.asset-key {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap; /* Allow keys to wrap if necessary */
}

.key-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 5px; /* Add spacing for wrapped items */
}

.color-box {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 4px;
}

.launch-date {
    background-color: #FFD36F; /* Highlight color for launch date */
    border: 2px solid #FFBB31; /* Stronger border for emphasis */
}

.launch-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    padding: 2px 6px 6px;
}
</style>
