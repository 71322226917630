<template>
    <div class="bar-line-chart-template">
        <canvas :id="'chart-'+this.id" :class="(if_chart_has_data ? '':'hide')" class="bar-line-chart chart-template" ></canvas>
        <div :class="(!if_chart_has_data ? 'd-inline-block':'hide')" class='text-center'>
            <h1 class="text-secondary_v1 mb-1"><i class="fa-solid fa-chart-simple"></i></h1>
            <h5 class="text-secondary_v1 mb-2"><em>Press the <strong>Preview</strong> button to render the chart</em></h5>
        </div>
    </div>
</template>

<script>
    // import { registerables } from 'chart.js';
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    export default {
       props: ['chart_selected', 'id', 'if_refreshing', 'if_on_report_page', 'if_benchmark_report', 'applied_chart_filter'],
       data() {
           return {
               chart: null,
               color: null,
               formatType: [],
               if_chart_has_data: false,
               if_chart_filter_applied: false,
               chart_filter_label: '',
               font_size: 12,
               max_value: 0,
           };
       },
       watch:{
            chart_selected: {
                handler: function(newVal, oldVal) {
                    // Since chart_selected is a complex object, you might want to perform
                    // a more specific check to determine if renderChart should be called.
                    // For now, we'll just check if it has 'data' to decide.
                    if (newVal.data) {
                        if(this.chart_selected.metric_formats){
                            this.formatType = this.chart_selected.metric_formats
                            var color_array = [];
                            newVal.metrics.forEach((m)=>{
                                color_array.push(m.color);
                            })
                            color_array[0] = 'gray'; // the first metric is shown as bar in gray scale
                            this.color = color_array;
                        } else {
                            this.formatType = ['number', 'number']; // bar_line combo chart should have at least 2 metrics
                            this.color = ['gray', 'yellow']; // the first metric is shown as bar in gray scale
                        }
                            
                        this.renderChart();
                        this.if_chart_has_data = true;
                    }
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
            if_refreshing:{
                handler: function(newVal, oldVal) {
                    this.$forceUpdate();
                }
            },
            applied_chart_filter:{
                handler: function(newVal, oldVal) {
                    this.if_chart_filter_applied = this.applied_chart_filter.if_chart_filter_applied && this.id == this.applied_chart_filter.section_chart_id;
                    this.renderChart();
                }
            }
        },
       mounted() {
            var export_report_class = document.querySelector('.export-report');
            if (export_report_class){ // if on export view, make font bigger, set to 15 for now
                this.font_size = 15;
            }
            if (this.chart_selected.data){
                // loop through metric_objects in chart to find format 
                if(this.chart_selected.metric_formats){
                    this.formatType = this.chart_selected.metric_formats
                    var color_array = [];
                    this.chart_selected.metrics.forEach((m)=>{
                        color_array.push(m.color);
                    })
                    color_array[0] = 'gray'; // the first metric is shown as bar in gray scale
                    this.color = color_array;
                } else {
                    this.formatType = ['number', 'number']; // bar_line combo chart should have at least 2 metrics
                    this.color = ['gray', 'yellow']; // the first metric is shown as bar in gray scale
                }
 
                this.renderChart();
                this.if_chart_has_data = true;
            }
       },
       methods: {
            renderChart() {
                var dataset = this.getChartData(); 
                var options = this.getChartOptions(dataset); 

                if(this.chart != null)
                    this.chart.destroy();

                var ctx = document.getElementById('chart-'+this.id);
                if(ctx != undefined)
                    this.chart = new Chart(ctx, options);
            },
            getColor(color) { // colors are from dark to light shades
                const colors = {
                    yellows: [
                        'rgb(220, 150, 0)',
                        'rgb(235, 181, 0)',
                        'rgb(255, 203, 32)',
                        'rgb(255, 219, 102)',
                        'rgb(255, 229, 145)',
                        'rgb(255, 236, 174)',
                    ],
                    greens: [
                        'rgb(10, 110, 62)',
                        'rgb(15, 166, 92)',
                        'rgb(25, 207, 120)',
                        'rgb(69, 223, 149)',
                        'rgb(107, 243, 178)',
                        'rgb(167, 248, 209)',
                    ],
                    blues: [
                        'rgb(9, 71, 137)',
                        'rgb(10, 89, 250)',
                        'rgb(51, 149, 255)',
                        'rgb(122, 186, 255)',
                        'rgb(168, 204, 244)',
                        'rgb(211, 228, 247)',
                    ],
                    purples: [
                        'rgb(65, 1, 121)',
                        'rgb(133, 48, 209)',
                        'rgb(124, 82, 149)',
                        'rgb(183, 123, 244)',
                        'rgb(210, 168, 253)',
                        'rgb(223, 192, 255)',
                    ],
                    grays: [
                        'rgb(18, 18, 18)',
                        'rgb(57, 74, 69)',
                        'rgb(101, 121, 115)',
                        'rgb(143, 158, 153)',
                        'rgb(173, 188, 183)',
                        'rgb(200, 215, 211)',
                        'rgb(225, 234, 230)',
                    ],
                };
                if(color == 'yellow')
                    return colors.yellows;
                if(color == 'green')
                    return colors.greens;
                if(color == 'blue')
                    return colors.blues;
                if(color == 'purple')
                    return colors.purples;
                if(color == 'gray')
                    return colors.grays;
                return colors.yellows;
            },
            getTopBorderRadiusByDataLength(dataLength) {
                if (dataLength < 5) {
                    return 16;
                } else if (dataLength < 10) {
                    return 8;
                } else if (dataLength < 15) {
                    return 4;
                } else {
                    return 0;
                }
            },
            // getDataLabelSizeByDataLength(dataLength) {
            //     if (dataLength <= 5) {
            //         return 12;
            //     } else if (dataLength < 10) {
            //         return 10;
            //     } else if (dataLength < 15) {
            //         return 8;
            //     } else {
            //         return 6;
            //     }
            // },
            getLegendLabelStylesByDatasetType(dataset, index) {
                const defaultStyle = {
                    text: dataset.label,
                    fillStyle: dataset.backgroundColor[0],
                    strokeStyle: dataset.backgroundColor[0],
                    pointStyleWidth: 16,
                    lineWidth: 0, // remove box border by default
                    hidden: false,
                };
                let style = defaultStyle;
                switch (dataset.type) {
                    case 'pie':
                        style.pointStyle = 'circle';
                        break;
                    case 'bar':
                        style.pointStyle = 'rect';
                        break;
                    case 'line':
                        style.pointStyle = 'line';
                        style.lineWidth = 1;
                        style.borderWidth = this.if_benchmark_report? 3 : 1.5;
                        break;
                    default:
                        break;
                }
                return style;
            },
            getChartData() {
                var datasetArray = [];
                var num_of_datasets= Object.keys(this.chart_selected.data).length;
                var chartColors = this.getColor(this.color[1]).slice(0,num_of_datasets);
                var grayColors = this.getColor(this.color[0]);
                var element_on_scorecard = document.querySelector('.scorecard-page');
                var max = 0;
                var chart_filter_label_index = this.chart_selected.labels.findIndex(label => label == this.chart_filter_label);  

                Object.keys(this.chart_selected.data).forEach((key,index) => {
                    var max_temp = Math.max(...this.chart_selected.data[key]);
                    if (max_temp > max){
                        max = max_temp;
                    }

                    if(index==0){ // first dataset will be ploted as grayscale bar
                        var backgroundColorArray = [];
                        backgroundColorArray = Array(this.chart_selected.data[key].length).fill(grayColors[3]);

                        if (element_on_scorecard && this.chart_selected.data[key].length >=3){
                            for(var i = backgroundColorArray.length - 1; i >= backgroundColorArray.length - 3; i--) {
                                backgroundColorArray[i] = 'rgba(' + grayColors[3].substring(4, grayColors[3].length - 1) + ', 0.5)';
                            }
                        } 

                        datasetArray.push({
                            index: index,
                            label: key==""? '(not set)':key,
                            data: this.chart_selected.data[key],
                            backgroundColor: backgroundColorArray, 
                            borderRadius: {
                                topLeft: this.getTopBorderRadiusByDataLength(this.chart_selected.data[key].length), // round the top corners
                                topRight: this.getTopBorderRadiusByDataLength(this.chart_selected.data[key].length), // round the top corners
                                bottomLeft: 0,
                                bottomRight: 0,
                            },
                            order: 5, // higher order means drawing behind; always render bar data behind the line data
                            yAxisID: 'y',
                        })   
                        
                    } else{ // second and onward dataset will be ploted as line
                        var backgroundColorArray = [];
                        backgroundColorArray =  Array(this.chart_selected.data[key].length).fill(chartColors[0]);

                        if (element_on_scorecard){
                            // for(var i = backgroundColorArray.length - 1; i >= backgroundColorArray.length - 3; i--) {
                            //     backgroundColorArray[i] = 'rgba(' + chartColors[0].substring(4, chartColors[0].length - 1) + ', 0.5)';
                                
                            // }

                            datasetArray.push({
                                index: index,
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: backgroundColorArray,
                                borderColor: backgroundColorArray,
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash: [],
                                borderRadius: 3,
                                type: 'line',
                                segment: {
                                    borderDash: ctx => this.projected(ctx, this.chart_selected.data[key].length, index, [10, 5]),
                                },
                                order: index+1,
                                yAxisID: 'y1',
                            }) 
                        }  else if (this.if_chart_filter_applied){
                            // var backgroundColorArray = Array(this.chart_selected.data[key].length).fill(this.getColor('gray')[3]);
                            backgroundColorArray[chart_filter_label_index] = chartColors[0];
                            datasetArray.push({
                                index: index,
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: backgroundColorArray,
                                borderColor: grayColors[6-index],
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash: [],
                                borderRadius: 3,
                                // pointRadius: 0, // no dot at data point
                                type: 'line',
                                // spanGaps: true,
                                // tension: 0.4,
                                order: index+1,
                                yAxisID: 'y1',
                            }) 
                        } else if(this.chart_selected.highlight_last_value){
                            datasetArray.push({
                                index: index,
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: (index== num_of_datasets-1)? chartColors[0]: grayColors[6-index],
                                borderColor: (index== num_of_datasets-1)? chartColors[0]: grayColors[6-index],
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash:[],
                                borderRadius: 3,
                                // pointRadius: 0, // no dot at data point
                                type: 'line',
                                // spanGaps: true,
                                // tension: 0.4,
                                order: index+1,
                                yAxisID: 'y1',
                            }) 
                        }else{
                            datasetArray.push({
                                index: index,
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: chartColors[chartColors.length-index-1],
                                borderColor: chartColors[chartColors.length-index-1],
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash: [],
                                borderRadius: 3,
                                // pointRadius: 0, // no dot at data point
                                type: 'line',
                                // spanGaps: true,
                                // tension: 0.4,
                                order: index+1,
                                yAxisID: 'y1',
                            }) 
                        }
                    }
                });
                this.max_value = max;

                return {
                    labels: this.chart_selected.labels,
                    datasets: datasetArray
                };
           },
            projected(ctx, total_length, index, value) {
                return (total_length-1) - ctx.p0DataIndex <=3 ? value : undefined;
            },
            getChartOptions(data) {
                var self = this;
                var config = {
                    plugins: [ChartDataLabels],
                    type: 'bar',
                    data: data,

                    //All of the special aspects that make these charts unique
                    options: {
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        responsive: true,
                        maintainAspectRatio: false,

                        animations: {
                            x: {
                                duration: 1000,
                            }
                        },
                        layout: {
                            padding: {
                                top: 30
                            }
                        },
                        indexAxis: 'x',
                        scales: {
                            x: {
                                grid: {
                                    drawBorder: false,
                                    display : false,
                                },
                                border: {
                                    display: false,
                                },
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    maxRotation:0,
                                    autoSkip:false,
                                    callback: function(value, index, ticks, context) {
                                        // Wrap around long labels
                                        var label_substrings = '';
                                        if(self.chart_selected.labels[value]){
                                            let label = self.chart_selected.labels[value] + "";
                                            label_substrings = label.split(" ");
                                        }
        
                                        for(var i = 0; i <label_substrings.length-1; i++){
                                            if ((label_substrings[i].length + 1 + label_substrings[i+1].length) < 15){ 
                                                // if two substrings have total length < 15, concat them together
                                                // Note: length=15 is just a temporary value which makes the chart looks fine with dummy data
                                                label_substrings[i] = label_substrings[i]+' '+label_substrings[i+1]
                                                label_substrings[i+1] = ''
                                            }
                                        }
                                        return label_substrings;
                                    }
                                }
                            },
                            y: {
                                display: true,
                                position: 'left',
                                beginAtZero: true,
                                border: {
                                    display: false,
                                    width: 2,
                                    dash: [5,5],
                                    dashOffset: 0,
                                },
                                grid: {
                                    drawOnChartArea: true,
                                    drawBorder: false,
                                    
                                    color: 'rgba(206, 219, 215, 1)',
                                    lineWidth: 1, // gridline thickness
                                    borderDash: [2,2], // gridline in the chart area that is perpendicular to this axis

                                    drawTicks: true,
                                    tickColor: 'rgba(206, 219, 215, 1)',
                                    tickLength: 20,
                                    tickBorderDash: [2,2],
                                    tickBorderDashOffset: 0,
                                    z: -2,
                                },
                                //For the ticks on the axes, format the numbers to make the pretty
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    // color: 'rgba(57, 74, 69, 1.0)',
                                    offset: false,
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    callback: function(value, index, ticks, context) {
                                        if(self.formatType[0] == 'money' && value < 1000)
                                            return self.$options.filters.currency_with_zero(value);
                                        if(self.formatType[0] == 'money')
                                            return self.$options.filters.currency_abbr(value);
                                        if(self.formatType[0]  == 'number' && value >= 1000)
                                            return self.$options.filters.num_abbr(value);
                                        if(self.formatType[0] == 'number' && self.max_value >= 10)
                                            return self.$options.filters.number_with_zero(value);
                                        if(self.formatType[0]  == 'number')
                                            return self.$options.filters.number_with_decimal(value);
                                        if(self.formatType[0] == 'percent')
                                            return self.$options.filters.percentage(value);
                                    }
                                },
                            },
                            y1: {
                                display: true,
                                position: 'right',
                                beginAtZero: true,
                                border: {
                                    display: false,
                                    width: 2,
                                    dash: [5,5],
                                    dashOffset: 0,
                                },
                                grid: {
                                    /// TODO: Hide bottom (first) y-axis gridline on additional y-axis if there is more than one y-axis. This will prevent both dashed lines from appearing on the bottom level.

                                    drawOnChartArea: true,
                                    drawBorder: false,
                                    
                                    color: 'rgba(206, 219, 215, 1)',
                                    lineWidth: 1, // gridline thickness
                                    borderDash: [2,2], // gridline in the chart area that is perpendicular to this axis

                                    drawTicks: true,
                                    tickColor: 'rgba(206, 219, 215, 1)',
                                    tickLength: 20,
                                    tickBorderDash: [2,2],
                                    tickBorderDashOffset: 0,
                                    z: -2,
                                },
                                //For the ticks on the axes, format the numbers to make the pretty
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    offset: false,
                                    color: 'rgba(57, 74, 69, 1.0)',
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    callback: function(value, index, ticks, context) {

                                        if(self.formatType[1] == 'money' && value < 1000)
                                            return self.$options.filters.currency_with_zero(value);
                                        if(self.formatType[1] == 'money')
                                            return self.$options.filters.currency_abbr(value);
                                        if(self.formatType[1] == 'number' && value < 1000)
                                            return self.$options.filters.number_with_decimal(value);
                                        if(self.formatType[1] == 'number' )
                                            return self.$options.filters.num_abbr(value);
                                        if(self.formatType[1] == 'percent')
                                            return self.$options.filters.percentage(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                //Only show the legend if there is more than one media type
                                display: true,
                                position: (data.datasets.length > 1) ? 'bottom' : 'none',
                                align: 'start',
                                boxHeight: 40,
                                labels: {
                                    usePointStyle: true,
                                    generateLabels: (chart) => chart.data.datasets.map(this.getLegendLabelStylesByDatasetType),                                    
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                }
                            },
                            tooltip:  {
                                enabled: false,
                                external: function(context) {
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chart-'+self.id+'-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chart-'+self.id+'-tooltip';
                                        tooltipEl.classList.add("chart-template-tooltip");
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    const position = context.chart.canvas.getBoundingClientRect();
                                    // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

                                    // Display, position, and set styles for font
                                    const tooltipModel = context.tooltip;
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX - tooltipModel.width/2 + 'px';// center align
                                    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';
                                    tooltipEl.style.backgroundColor = 'white';
                                    tooltipEl.style.padding = '5px';
                                    tooltipEl.style.boxShadow = '2px 4px 20px 0px #0000001A';
                                    tooltipEl.style.fontFamily = 'MDSystem-Regular';

                                    // Hide if no tooltip 
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }
                                    tooltipEl.addEventListener('mouseover',()=>{
                                        tooltipEl.style.opacity = 1;
                                    });
                                    tooltipEl.addEventListener('mouseout',()=>{
                                        tooltipEl.style.opacity = 0;
                                    });

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        const titleLines = tooltipModel.title || [];
                                        const bodyLines = tooltipModel.body.map(b => b.lines);

                                        let innerHtml = '<thead>';
                                        
                                        // title
                                        innerHtml += '<tr><th>' + titleLines[0] + '</th></tr>';
                                        innerHtml += '</thead><tbody>';

                                        var format= self.formatType.slice().reverse();
                                        bodyLines.forEach(function(body, i) {
                                            var label = '';
                                            var body_label = body[0].split(': ');
                                            var value = tooltipModel.dataPoints[i].raw;
                                            
                                            var colors = tooltipModel.labelColors[i];
                                            var style = 'background-color:' + colors.backgroundColor + '; width:10px; height:10px; display:inline-block; margin-right:5px'
                                            var colorDiv = '<div style="' + style + '"></div>';

                                            if(format[i] == 'money')
                                                label = body_label[0] + ': ' + self.$options.filters.currency_with_zero(value);
                                            if(format[i] == 'number' && self.max_value >= 10)
                                                label = body_label[0] + ': ' + self.$options.filters.number_with_zero(value);
                                            if(format[i] == 'number' && self.max_value < 10)
                                                label = body_label[0] + ': ' + self.$options.filters.number_with_decimal(value);
                                            if(format[i] == 'percent')
                                                label = body_label[0] + ': ' + self.$options.filters.percentage(value);

                                            const span = '<span>' + label + '</span>';
                                            innerHtml += '<tr><td>' + colorDiv + span + '</td></tr>';
                                        });

                                        if(self.if_on_report_page && !self.if_benchmark_report){
                                            innerHtml += '<hr class="m-1">';
                                            
                                            if (self.if_chart_filter_applied && self.chart_filter_label == titleLines[0]){
                                                innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Remove as Filter</button>'
                                            } else {
                                                innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Explore</button>'
                                            }
                                       
                                            innerHtml += '</tbody>';  

                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;

                                            let btn = tooltipEl.querySelector('button');
                                            if (self.if_chart_filter_applied && self.chart_filter_label != titleLines[0]){
                                                btn.disabled=true;
                                            }
                                            // if chart filter applied on any other chart, disable explore for this current chart to block cross filtering for now
                                            // this is temporyary till we add cross filtering in
                                            if (self.applied_chart_filter.if_chart_filter_applied && self.if_chart_filter_applied == false){
                                                btn.disabled=true;
                                            }

                
                                            if (self.if_chart_filter_applied){
                                                btn.addEventListener('click', () => {
                                                    self.if_chart_filter_applied = false;
                                                    self.$emit('exploreChart', {
                                                        chart_selected: null,
                                                        chart_filters: []
                                                    });
                                                    var dataset = self.getChartData(); 
                                                    self.chart.data = dataset;
                                                    self.chart.update();
                                                });
                                            } else {
                                                btn.addEventListener('click', () => {
                                                    self.if_chart_filter_applied = true;
                                                    self.chart_filter_label = titleLines[0];
                                                    self.$emit('exploreChart', {
                                                        chart_selected: self.chart_selected,
                                                        chart_filters: [{
                                                            name: self.chart_selected.group_by[0].text,
                                                            column: self.chart_selected.group_by[0].value,
                                                            value: titleLines[0]
                                                        }]
                                                    });

                                                    // set the other columns as grayscale and change button text
                                                    var dataset = self.getChartData(); 
                                                    let btn = tooltipEl.querySelector('button');
                                                    btn.textContent = "Remove as Filter";
                                                    self.chart.data = dataset;
                                                    self.chart.update();
                                                });
                                            }
                                        } else { // benchmark chart tooltip
                                            innerHtml += '</tbody>';  
                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;
                                        }
                                    }  
                                }
                            },
                            datalabels: {
                                anchor: 'end', // Specify the anchor position for the labels
                                align: 'end', // Specify the alignment of the labels
                                color: this.getColor(this.color[1])[0],
                                //backgroundColor: 'rgba(255, 255, 255, 0.4)', // data label background contrast
                                font: {
                                    size: this.font_size,
                                },
                                // clamp: true,
                                formatter: function(value, context) {
                                    if (self.chart_selected.show_all_data_labels && context.datasetIndex == 0){
                                        // for bar line combo chart, if show all data labels, only show the labels for lines
                                            return null;
                                    }
                                    if (!self.chart_selected.show_all_data_labels && context.datasetIndex < data.datasets.length-1)
                                        return null;

                                    if (self.if_chart_filter_applied){
                                        var chart_filter_index = self.chart_selected.labels.findIndex((label) => label == self.chart_filter_label)// use to keep track on which data to explore one
                                        if(context.dataIndex != chart_filter_index)
                                            return null;
                                    }

                                    if(self.formatType[1]  == 'money' && value < 1000)
                                        var label = self.$options.filters.currency_with_zero(value);
                                    if(self.formatType[1]  == 'money')
                                        var label = self.$options.filters.currency_abbr(value);
                                    if(self.formatType[1]  == 'number' && value >= 1000)
                                        var label = self.$options.filters.num_abbr(value);
                                    if(self.formatType[1] == 'number' && value < 1000 && self.max_value >= 10)
                                        var label = self.$options.filters.number_with_zero(value);
                                    if(self.formatType[1]  == 'number' && value < 1000 && self.max_value < 10)
                                        var label = self.$options.filters.number_with_decimal(value);
                                    if(self.formatType[1]  == 'percent')
                                        var label = self.$options.filters.percentage(value);

                                    //If it is within 95% of the width, add padding to the end
                                    // Ideally it would be based upon the width of the bar + label but
                                    // this is an easy alternative
                                    // var chart_width = context.chart.chartArea.width;
                                    //The characters are, at most 7px wide and then the padding of 6
                                    // var label_width = label.length*7+6;

                                    // if(total >= context.chart.scales.x.end*(1-label_width/chart_width) && context.chart.options.layout.padding.right == 0){

                                    //     context.chart.options.layout.padding.right = label_width;
                                    //     context.chart.update();

                                    // }
                                    return label;
                                },
                            }    
                        },
                        title: {
                            display: false,
                        },
                    }
               };
               return config;
           }
       }
   }
</script>
