<template>
    <div :class="{ 'sticky-top': is_sticky }" ref="stickyDiv">
        <div class="card mb-2" v-for="index in depth" :key="index">
            <div class="card-body px-0 pb-0" :class="{'pt-0': depth != index}">
                <div class="row" v-if="depth == index">
                    <div class="col mx-3 mb-3">
                        <label class="form-label ">Column to Preview</label>

                        <accordion-select v-model="preview_table_val[index-1]" :options="source_columns" @input="updatePreviewTable(preview_table_val[index-1])" ></accordion-select>

                    </div>
                </div>
                <p v-if="preview_table_val[index-1] != null && depth == 1" class="mx-3">The table below represents the number of records for each of the top 10 values for the given column. Drill deeper into the data or view a preview using the icons below.</p>
                <div class="table-responsive mx-2" style="max-height: 300px;" >
                    <!-- <div class="overflow-hidden rounded-top" v-if="depth != index"><div class="p-1 bg-dark"></div></div> -->
                    <table class="mapping-table preview-mapping-table gy-0 mx-0 my-2" v-if="preview_table_val[index-1] != null">
                        <thead>
                            <tr>
                                <th class='ps-3 py-1'>{{mirrorAccordionSelectStyle(preview_table_val[index-1]) | propercase}}</th>
                                <th class="py-1 ps-2">Records</th>
                                <th  class="py-1" style="width:70px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr v-if="preview_table[index-1] == null">
                                <td colspan="3" class="text-center"><div class="spinner-border  spinner-border-sm float-left" role="status"></div> Loading</td></tr>
                            <tr v-else-if="preview_table[index-1].length == 0">
                                <td colspan="3" class="text-center"><strong><em>No records were found for the given column.</em></strong></td></tr>

                            <tr v-for="(f, row_num) in preview_table[index-1]" :key="f.name" v-if="preview_table[index-1] != null && (drill_index == null || drill_index == row_num || index > 1)" >
                                <td class='ps-3 align-middle ' v-if="f.name != null">
                                    {{f.name}}
                                </td>
                                <td class='ps-3 align-middle ' v-else>
                                    <em>null</em>
                                </td>
                                <td class="align-middle ps-2">{{ f.count | number_with_zero }}</td>
                                <td class="text-nowrap" >
                
                                    <button type="button" class="btn btn-sm btn-none p-1" v-if="depth == index" @click="previewRecords(f.name)">
                                        <i class="fa fa-eye" v-if="preview_detail_loading != f.name" data-bs-toggle="tooltip" data-placement="top"  title="Preview" ></i>
                                        <div class="spinner-border spinner-border-sm" role="status" v-else></div>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-none p-1" v-if="depth == 1" @click="drilldown(row_num, f.name)">
                                        <i class="fa-solid fa-arrow-down" data-bs-toggle="tooltip" data-placement="top"  title="Drill Down" ></i>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-none p-1" v-if="depth == 2 && index == 1" @click="removeDrilldown()">
                                        <i class="fas fa-close" data-bs-toggle="tooltip" data-placement="top"  title="Remove Drill Down"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="modal fade modal modal-lg" id="pathway-column-mapping-preview-modal" aria-labelledby="pathway-column-mapping-preview-modal" aria-hidden="true">
            <div class="modal-dialog" >
                <div class="modal-content" v-if="preview_detail_table.length > 0">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none position-absolute" style="top: 10px; right: 10px;" data-bs-dismiss="modal" v-on:click="preview_detail_index=0" >
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2 mb-1">Preview</h4>
                            <h5>
                                <button class="btn btn-none" v-if="preview_detail_index > 0 && preview_detail_table.length > 1" v-on:click="preview_detail_index--"><i class="fa-solid fa-chevron-left"></i></button>
                                {{preview_detail_index+1}} of {{preview_detail_table.length}} samples
                                <button class="btn btn-none" v-if="preview_detail_index < preview_detail_table.length - 1" v-on:click="preview_detail_index++"><i class="fa-solid fa-chevron-right"></i></button>
                            </h5>
                        </div>
                    </div>
                    <div class="modal-body p-0">
                        <div class="table-responsive">
                            <table class="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th class="ps-3">Column Name</th>
                                        <th>Column Value</th>
                                    </tr>
                                </thead>
                                <tbody class="table-striped">
                                    <tr v-for="(value, index) in Object.values(preview_detail_table[preview_detail_index])">
                                        <td class="ps-3">{{getPrettyColNameByIndex(index)}}</td>
                                        <td>{{value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-start border-top-0">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="preview_detail_index=0" >Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default {
        props: {
            client: {
              type: Object,
            },
            source: {type: Object },
            source_columns: {
                default: () => []
            }
        },
        data() {
            return {
                //data preview
                is_sticky: false,
                //data preview
                depth: 1,
                drill_index: null,
                preview_table_val: [],
                preview_table_logic: [],
                preview_table: [],
                preview_detail_table: [],
                preview_detail_index: 0,
                preview_detail_loading: "",

                src_column_array: null,
                preview_column_array: null, // preview_column_array is basically src_column_array without custom logic option at the end
            };
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        mounted() {
            //Ensure the tooltips show up
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }

        },
        watch: {
            // Watch for changes in the computed property to trigger the re-render of the component.
            local_census_connection: {
              handler(n, o) {
                this.setupUI();
              },
              deep: true,
            },
        },
        methods: {
            updatePreviewTable(column_value) {
                this.preview_table[this.depth-1] = null;
                this.preview_table_logic[this.depth-1] = null;

                if(column_value == "") {
                    this.preview_table_val[this.depth-1] = null
                    this.preview_table_logic[this.depth-1] = null;
                    return;
                }

                this.getPreviewTableData(column_value);
            },
            getPreviewTableData(column, column_logic = null) {
                //Hide the preview tool tips
                let tips = document.getElementsByClassName('tooltip');
                for (var i = 0; i < tips.length; i++)
                //Remove the show class from ti
                    tips[i].classList.remove('show');

                var data = {
                    client: this.client,
                    source: this.source.data_source.data_source,
                    column: column,
                    logic: null,
                    limit: 10,
                    data_source_id: this.source.data_source.id,
                    // value: this.dataset.filter
                };

                //I need to add the filter for the first level if I'm two down.
                if(this.depth == 2) {
                    data.filter = [{
                        column: this.preview_table_val[0],
                        value: this.preview_table[0][this.drill_index].name
                    }];
                }

                var self = this;

                window.axios.post('/api/bigquery/get_samples', data)
                .then(response => {
                    self.preview_table[self.depth-1] = response.data.samples;
                    self.$forceUpdate();
                })
                .catch(error => {
                    // Handle errors here
                    if (error.response.status == 400 && error.response.data.error_type == 'missing_column') {
                        //Find the object that has a value of the column variable and remove it from the src_column_array array.
                        var index = self.src_column_array.findIndex(c => c.value == column);
                        self.src_column_array.splice(index, 1);
                        self.preview_column_array.splice(index, 1);

                        self.preview_table_val = [];

                        //Find all of the places where the source column is set to the column
                        for(var i = 0; i < self.mapping.length; i++) {
                            if(self.mapping[i].source_column == column)
                                self.mapping[i].source_column = null;
                        }

                        Swal.fire({
                        title: "The selected column has been removed",
                        text: "The database column was removed from the source data. We've updated this data set to reflect that recent change.",
                        icon: "warning",
                        iconColor: "#A61C3C",
                        showCancelButton: false,
                        confirmButtonColor: "#A61C3C",
                        confirmButtonText: "OK",
                        })

                        self.$forceUpdate();
                    }
                    else if (error.response.status == 400 && error.response.data.error_type == 'sql_error') {
                        Swal.fire({
                        title: "There is an error with your custom logic",
                        html: error.response.data.message,
                        icon: "warning",
                        iconColor: "#A61C3C",
                        showCancelButton: false,
                        confirmButtonColor: "#A61C3C",
                        confirmButtonText: "OK",
                        })
                    }
                });;
            },
            mirrorAccordionSelectStyle(val) {
                //If it contains a ., then replace it with a : and a space and then propercase all the words
                if(val.indexOf(".") != -1) {
                    var split = val.split(".", 2);
                    return this.$options.filters.propercase(split[0].trim()) + ": " + this.$options.filters.propercase(split[1].trim());
                }
                return val;
            },
            previewRecords(column_value) {
                var self = this;
                var data = {
                    data_source: this.source.data_source.data_source,
                    filter:  [],
                    limit: 10,
                    data_source_id: this.source.data_source.id,
                    client_id: this.client.id,
                };

                //I need to add the filter for the first level if I'm two down.
                if(this.depth == 2) {
                    data.filter.push({
                        column: this.preview_table_val[0],
                        logic: this.preview_table_logic[0],
                        value: this.preview_table[0][this.drill_index].name
                    });
                }

                this.preview_detail_loading = column_value;

                window.axios.post('/api/bigquery/get_detailed_samples', data).then(response => {
                    if(response.status == 200) {

                        self.preview_detail_table = response.data.samples;
                        self.preview_detail_loading = "";

                        var myModal = new bootstrap.Modal(document.getElementById('pathway-column-mapping-preview-modal'))
                        myModal.show()
                    }
                });
            },
            drilldown(index, column_value) {
                this.depth++;
                this.drill_index = index;
            },
            removeDrilldown() {
                this.drill_index = null;
                this.depth--;
                this.preview_table_val[1] = null;
                this.preview_table[1] = null;

            },
            getPrettyColNameByIndex(index) {
                var name = Object.keys(this.preview_detail_table[this.preview_detail_index])[index];
                return this.getPrettyColName(name);
            },
            getPrettyColName(name) {

                var cols = Object.keys(this.source_columns);
                for(var i = 0; i < cols.length; i++) {
                    if(cols[i] == name){
                        let value = Object.values(this.source_columns)[i].value;
                        if(value && value.indexOf(".") != -1) {
                            let split = value.split(".", 2);
                            return this.$options.filters.propercase(split[0].trim()) + ": " + this.$options.filters.propercase(split[1].trim());
                        }
                        return value;
                    }
                }

                return name;
            },

            handleScroll() {
                if(this.$refs.stickyDiv == null) return;

                const divTop = this.$refs.stickyDiv.getBoundingClientRect().top;
                this.is_sticky = divTop <= 0;
            },
        }
    }
</script>
