<template>
    <div id="org_settings_connections_systems" class="d-flex flex-column gap-3">
        <div id="system-connections-grid">
            <div v-for="(conn, index) in local_connections" class="card system-connection-card h-100">
                <div class="card-body">
                    <div class="system-connection-card__header">
                        <div class="system-connection-card__header__icon">
                            <img :src="conn.integration.logo_url" :alt="conn.integration.display_name" class="avatar rounded-circle avatar-sm me-2 bg-white">
                        </div>
                        
                        <div class="system-connection-card__header__status">
                            <span class="badge badge-secondary d-inline-block" v-if="!conn.sync_error_msg && !conn.last_synced_at && !conn.initial_sync_at">Initializing</span>
                            <span class="badge badge-error d-inline-block" v-else-if="conn.sync_error_msg">Error</span>
                            <span class="badge badge-success-dark d-inline-block"  v-else-if="conn.active_jobs && conn.active_jobs.length > 0">Syncing</span>
                            <span class="badge badge-success d-inline-block" v-else >Active</span>
                        </div>

                        <div class="system-connection-card__header__actions" v-if="canSync(conn) || canDelete(conn)">
                            <button class="btn btn-none p-1" data-bs-toggle="dropdown"  data-bs-auto-close="true" aria-expanded="false">
                                <img class="icon pe-0" src="/img/icons/dialexa-icons/vertical-dots.svg">
                            </button>
                            <div class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" @click.prevent="updateConnection(conn)">
                                    <i class="fa fa-btn fa-key"></i>Update Credentials
                                </a></li>
    
                                <li v-if="canSync(conn)"><a class="dropdown-item" href="#" @click.prevent="syncConnection(conn)" :disabled="disable_actions">
                                    <i class="fa fa-btn fa-sync me-2"></i>Sync Now</a>
                                </li>
                
                                <li v-if="canDelete(conn)"><a href="#" class="dropdown-item" :disabled="disable_actions" @click.prevent="deleteConnection(conn.id)">
                                    <i class="fa fa-btn fa-trash me-2"></i>Delete</a>
                                </li>
                            </div>
                        </div>
                    </div>
                    
                    <div class="system-connection-card__content">
                        <div class="d-flex flex-row align-items-center">
                            <div class="d-flex-flex-column">
                                <h4>{{ conn.integration.display_name }}</h4>
                                <p>{{ conn.name }}</p>
                            </div>
                        </div>
                        
                        <div class="d-flex flex-row align-items-center">
                            <div class="d-flex-flex-column">
                                <small v-if="conn.sync_error_msg">Error Message: {{ conn.sync_error_msg }}</small>
                                <small v-else>Last Synced: {{ conn.last_synced_at | date }} | Created on: {{ conn.created_at | date }}</small>
                            </div>
                        </div>
                        
                        <div class="w-100 d-flex flex-row align-items-center">
                            <div class="d-flex-flex-column flex-grow-1" @click="viewConnection(conn)">
                                <button class="btn btn-secondary w-100 mt-2">View Connection</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row" id="need-more-insights">
            <div class="d-inline-flex justify-content-center align-items-center">
                <p>Need more insights?
                <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#connections-setting-systems-system-modal">Add another connection</a></p>
            </div>
        </div>

        <system-modal :id="'connections-setting-systems-system-modal'" :client="client" :integrations="integrations" :connections="connections"></system-modal>
    </div>
</template>

<script>
    export default {
        props: ['client', 'integrations', 'connections'],
        data() {
            return {
                local_connections:[],
                disable_actions: false
            };
        },
        beforeMount() {
            this.local_connections = this.connections;
        },
        watch:{
            connections:{
                handler(newVal, oldVal) {
                    this.local_connections = this.connections;
                    this.$forceUpdate();
                },
                deep: true
            },
        },
        methods: {
            canSync(conn){
                //The SFTPs
                if(conn.integration_id == '7dbce608-4906-4606-977c-32ab1259b2e6' || conn.integration_id == '397b5b86-e954-495f-8fdb-513885f53251')
                    return false
                //File upload
                if(conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38')
                    return false;
                //If it is still unconfigured...
                if(conn.system_account_id == null)
                    return false;

                return  conn.active_jobs == null || conn.active_jobs.length == 0
            },
            canDelete(conn){
                //Can't delete the File Upload connection
                return conn.integration_id != '71b0c3b0-8390-49ec-9984-1c04b7384d38';
            },
            viewConnection(conn){
                this.$emit('viewConnection', conn)
            },
            updateConnection(conn){
                this.$emit('updateConnection', conn)
            },
            deleteConnection(conn_id) {
                Swal.fire({
                  title: "Are you sure you want to delete this connection?",
                  text: "Deleting the connection will remove the connection and all associated data.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {

                    this.disable_actions = true;

                    window.axios.delete('/api/connection/' + conn_id).then(response => {
                        //Find the connection in the local_connections
                        let index = this.local_connections.findIndex(x => x.id === conn_id);
                        //Remove it
                        this.local_connections.splice(index, 1);
                        this.disable_actions = false;
                        this.$forceUpdate();

                        Swal.fire({
                            title: "Deleted!",
                            text: "The connection has been removed.",
                            icon: "success",
                            iconColor:  "#46705F",
                            confirmButtonColor: "#46705F"
                        });
                    }).catch(error => {
                        this.disable_actions = false;
                        // Handle errors here
                        Swal.fire({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                            iconColor:  "#D11F1F",
                            confirmButtonColor: "#D11F1F"
                        });
                    });
                }
                });
            },
            syncConnection(conn) {
                this.disable_actions = true;
                window.axios.get('/api/connection/' + conn.id + '/refresh').then(response => {
                    this.disable_actions = false;
                    //This will change the status
                    conn.active_jobs = ["something"];
                    this.$forceUpdate();

                    Swal.fire({
                        title: "Success!",
                        text: "The connection will begin refreshing momentarily.",
                        icon: "success",
                        iconColor:  "#46705F",
                        confirmButtonColor: "#46705F"
                    });
                }).catch(error => {
                    this.disable_actions = false;
                    // Handle errors here
                    Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        iconColor:  "#D11F1F",
                        confirmButtonColor: "#D11F1F"
                    });
                });
            }

        }
    }
</script>
