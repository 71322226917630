var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "query-inclusion-builder" }, [
    _vm.filter_type == 1
      ? _c("p", [
          _vm._v("(For more advanced logic, "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.filter_type = 2
                },
              },
            },
            [_vm._v("you can use the advanced filter here.")]
          ),
          _vm._v(")"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.filter_type == 2
      ? _c("p", [
          _vm._v("(For an better user experience, "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.filter_type = 1
                },
              },
            },
            [_vm._v("switch back to the simple filter.")]
          ),
          _vm._v(")"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "mt-2",
        class: _vm.errors.filter != "" ? "is-invalid" : "",
      },
      [
        _vm.filter_type == 1
          ? _c("query-inclusion-simple", {
              ref: "simple",
              attrs: {
                client: _vm.client,
                is_white: !_vm.in_card,
                include_trans: _vm.include_trans,
                source: _vm.source,
                add_blank: _vm.add_blank,
                static_db_columns: _vm.static_db_columns,
                data_source_id: _vm.data_source_id,
              },
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            })
          : _c("query-inclusion-advanced", {
              ref: "advanced",
              attrs: {
                client: _vm.client,
                include_trans: _vm.include_trans,
                source: _vm.source,
                static_db_columns: _vm.static_db_columns,
                data_source_id: _vm.data_source_id,
              },
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.errors.filter != ""
      ? _c("div", { staticClass: "text-danger invalid-feedback mb-2" }, [
          _vm._v("\n        " + _vm._s(_vm.errors.filter) + "\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }