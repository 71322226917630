<template>
    <div class="campaign-list">
        <div class="alert alert-warning mb-3 box-content" v-if="client.client_mission==null || client.why_give==null ">
            Organization details that are necessary for campaign generation are currently empty, <a :href='"/settings/" + client.url + "#organization-details"'>please fill them out here</a>.
        </div>
        <div v-if="campaigns.length > 0">
            <div v-if="active_campaigns.length > 0">
                <div class="row">
                    <div class="col">
                        <h2 class="d-inline">Active Campaigns</h2>
                        <a class="btn btn-secondary mb-2 float-end"  :href="'/campaigns/'+ client.url +'/new'">
                            <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Campaign
                        </a>
                    </div>
                </div>
                <div class="card box-content mb-3">
                    <div class="card-body p-0">
                        <div class="overflow-hidden rounded-top">
                            <div class="p-1 bg-dark"></div>
                        </div>
                        <div class="table-responsive mb-2">
                            <table class="table gy-0 m-0">
                                <thead class="table-dark">
                                    <tr>
                                        <th class='align-middle pt-0'>Campaign Name</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Type</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Assets</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Status</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Created On</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="(campaign, index) in active_campaigns">
                                        <td class="align-middle">
                                            <a :href="'/campaigns/'+ client.url +'/overview/' + campaign.url"> {{ campaign.name }}</a>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ campaign.campaign_type.name }}
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <span v-html="getIcons(campaign.campaign_type.asset_types)"></span>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <span class="badge " :class="getStatusFontColor(campaign.status)">{{ campaign.status | propercase}}</span>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ campaign.created_at | date }}
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Copy Campaign" data-bs-toggle="tooltip" v-on:click="copyCampaign(campaign.id)">
                                                <i class="fa-solid fa-copy me-0" aria-hidden="true"></i>
                                            </button>
                                            <a :href="'/campaigns/'+ client.url +'/review/' + campaign.url" title="Review Changes" v-if="hasAssetEdits('active', index)" 
                                            class="btn btn-secondary btn-sm ms-1 mb-1" data-bs-toggle="tooltip"><i class="fa-solid fa-magnifying-glass me-0" aria-hidden="true"></i></a>
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Campaign" data-bs-toggle="tooltip" v-on:click="deleteCampaign(campaign.id)">
                                                <i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>       
                </div>
            </div>

            <div v-if="inactive_campaigns.length > 0">
                <div class="row ">
                    <div class="col">
                        <h2 class="d-inline">Inactive Campaigns</h2>
                        <a class="btn btn-secondary mb-2 float-end"  :href="'/campaigns/'+ client.url +'/new'">
                            <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Campaign
                        </a>
                    </div>
                </div>
                <div class="card box-content mb-3">
                    <div class="card-body p-0">
                        <div class="overflow-hidden rounded-top">
                            <div class="p-1 bg-dark"></div>
                        </div>
                        <div class="table-responsive mb-2">
                            <table class="table gy-0 m-0">
                                <thead class="table-dark">
                                    <tr>
                                        <th class='align-middle pt-0'>Campaign Name</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Type</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Assets</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Status</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Created On</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="(campaign, index) in inactive_campaigns">
                                        <td class="align-middle">
                                            <a :href="'/campaigns/'+ client.url +'/overview/' + campaign.url"> {{ campaign.name }}</a>
                                        </td>
                                        <td class="align-middle d-none d-sm-table-cell"> 
                                            {{ campaign.campaign_type.name }}
                                        </td>
                                        <td class="align-middle d-none d-sm-table-cell">
                                            <span v-html="getIcons(campaign.campaign_type.asset_types)"></span>
                                        </td>
                                        <td class="align-middle d-none d-sm-table-cell">
                                            <span class="badge " :class="getStatusFontColor(campaign.status)">{{ campaign.status | propercase}}</span>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ campaign.created_at | date }}
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Copy Campaign" data-bs-toggle="tooltip" v-on:click="copyCampaign(campaign.id)">
                                                <i class="fa-solid fa-copy me-0" aria-hidden="true"></i>
                                            </button>
                                            <a :href="'/campaigns/'+ client.url +'/review/' + campaign.url" title="Review Changes" v-if="hasAssetEdits('inactive', index)" 
                                            class="btn btn-secondary btn-sm ms-1 mb-1" data-bs-toggle="tooltip"><i class="fa-solid fa-magnifying-glass me-0" aria-hidden="true"></i></a>
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Campaign" data-bs-toggle="tooltip" v-on:click="deleteCampaign(campaign.id)">
                                                <i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>       
            </div>
        </div>

        <div v-if="campaigns.length == 0">
            <div class="card box-content mb-3">
                <div class="card-body mt-3 text-center">
                    <h3 class="text-center">No campaigns have been created for {{client.name}} yet.</h3>
                    <p class="text-center">
                        Making a campaign would allow you to generate multiple assets that are a part of a larger marketing / fundraising effort such as a year end campaign or a welcome series. You will be able to generate new copy to use in your marketing efforts or import existing assets from an already created campaign.</p>
                    <a class="btn btn-primary mb-3" :href="'/campaigns/'+ client.url +'/new'">
                        <span><i class="fas fa-fw fa-plus"></i>Add Campaign</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['client', 'campaigns'],
        data() {
            return {
                local_client: null,
                active_campaigns:[],
                inactive_campaigns:[],
            };
        },
        beforeMount() {
            this.local_client = this.client;
            if(this.campaigns.length > 0){  
                // split campaigns into two list: active and inactive/archive
                this.campaigns.forEach(campaign => {
                    if (campaign.status == "archive"){
                        this.inactive_campaigns.push(campaign);
                    } else{
                        this.active_campaigns.push(campaign);
                    }                        
                });
            }
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            }
        },
        methods: {
            getStatusFontColor(status){
                if (status == 'draft') // yellow
                    return 'badge-primary';
                if (status == 'in_review') // blue
                    return "badge-danger";
                if (status == 'active') // green
                    return "badge-success";
                if (status == 'archive') // red
                    return "badge-secondary";

                //return yellow by default
                return 'status-draft5';
            },
            getIcons(asset_types){
                if (asset_types == null || asset_types.length == 0) return '<span>No Asset Attached</span>'
                else {
                    var icon_list = this.getIconsByAssetType(asset_types);

                    var iconElements = '';
                    icon_list.forEach(icon_class => {
                        iconElements = iconElements +'<i class="'+icon_class+' mx-1"></i>'
                    })
                }
                return iconElements
            },
            getIconsByAssetType(asset_types){
                var icon_list = [];

                asset_types.forEach(asset_obj => {
                    if (!icon_list.includes(asset_obj.icon_class)){ 
                        icon_list.push(asset_obj.icon_class);
                    }
                });
                return icon_list;
            },
            hasAssetEdits(status, index) {
                if(status == 'active' && this.active_campaigns){
                    for(var i = 0; i < this.active_campaigns[index].assets.length; i++){
                        if(this.active_campaigns[index].assets[i].status == 'in_review' && this.active_campaigns[index].assets[i].latest_review)
                            return true;
                    }
                    return false;                    
                }
                if(status == 'inactive' && this.inactive_campaigns){
                    for(var i = 0; i < this.inactive_campaigns[index].assets.length; i++){
                        if(this.inactive_campaigns[index].assets[i].status == 'in_review' && this.inactive_campaigns[index].assets[i].latest_review)
                            return true;
                    }
                    return false;       
                }
            },
            deleteCampaign(campaign_id) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this campaign?",
                  text: "This campaign and all associated assets will be removed from Avid AI. This is different from archiving which keeps the campaign but moves it out of the active campaign list.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/campaigns/'+this.client.url +'/'+ campaign_id);

                        for(var i = 0; i < this.active_campaigns.length; i++){
                            if(this.active_campaigns[i].id == campaign_id){
                                this.active_campaigns.splice(i, 1);
                                break;
                            }
                        }
                    }
                });
            },
            copyCampaign(campaign_id) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to copy this campaign?",
                  text:  "A fresh copy of this campaign will be generated for the client.",
                  icon: "question",
                  iconColor: "#46705F",
                  showCancelButton: true,
                  confirmButtonColor: "#46705F",
                  confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.post('/api/campaigns/'+this.client.url +'/copy/'+ campaign_id)
                            .then(response => {
                                if (response.status == 200) {
                                    window.location = "/campaigns/"+this.client.url + '/overview/' + response.data.campaign.url;
                                }
                            });
                    }
                });
            }
        }
    }
</script>
