var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "stickyDiv", class: { "sticky-top": _vm.is_sticky } },
    [
      _vm._l(_vm.depth, function (index) {
        return _c("div", { key: index, staticClass: "card mb-2" }, [
          _c(
            "div",
            {
              staticClass: "card-body px-0 pb-0",
              class: { "pt-0": _vm.depth != index },
            },
            [
              _vm.depth == index
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col mx-3 mb-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Column to Preview"),
                        ]),
                        _vm._v(" "),
                        _c("accordion-select", {
                          attrs: { options: _vm.source_columns },
                          on: {
                            input: function ($event) {
                              return _vm.updatePreviewTable(
                                _vm.preview_table_val[index - 1]
                              )
                            },
                          },
                          model: {
                            value: _vm.preview_table_val[index - 1],
                            callback: function ($$v) {
                              _vm.$set(_vm.preview_table_val, index - 1, $$v)
                            },
                            expression: "preview_table_val[index-1]",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.preview_table_val[index - 1] != null && _vm.depth == 1
                ? _c("p", { staticClass: "mx-3" }, [
                    _vm._v(
                      "The table below represents the number of records for each of the top 10 values for the given column. Drill deeper into the data or view a preview using the icons below."
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "table-responsive mx-2",
                  staticStyle: { "max-height": "300px" },
                },
                [
                  _vm.preview_table_val[index - 1] != null
                    ? _c(
                        "table",
                        {
                          staticClass:
                            "mapping-table preview-mapping-table gy-0 mx-0 my-2",
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "ps-3 py-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("propercase")(
                                      _vm.mirrorAccordionSelectStyle(
                                        _vm.preview_table_val[index - 1]
                                      )
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "py-1 ps-2" }, [
                                _vm._v("Records"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass: "py-1",
                                  staticStyle: { width: "70px" },
                                },
                                [_vm._v("Actions")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            [
                              _vm.preview_table[index - 1] == null
                                ? _c("tr", [_vm._m(0, true)])
                                : _vm.preview_table[index - 1].length == 0
                                ? _c("tr", [_vm._m(1, true)])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.preview_table[index - 1],
                                function (f, row_num) {
                                  return _vm.preview_table[index - 1] != null &&
                                    (_vm.drill_index == null ||
                                      _vm.drill_index == row_num ||
                                      index > 1)
                                    ? _c("tr", { key: f.name }, [
                                        f.name != null
                                          ? _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "ps-3 align-middle",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(f.name) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "ps-3 align-middle",
                                              },
                                              [_c("em", [_vm._v("null")])]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "align-middle ps-2" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("number_with_zero")(
                                                  f.count
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-nowrap" },
                                          [
                                            _vm.depth == index
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-none p-1",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.previewRecords(
                                                          f.name
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.preview_detail_loading !=
                                                    f.name
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-eye",
                                                          attrs: {
                                                            "data-bs-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "top",
                                                            title: "Preview",
                                                          },
                                                        })
                                                      : _c("div", {
                                                          staticClass:
                                                            "spinner-border spinner-border-sm",
                                                          attrs: {
                                                            role: "status",
                                                          },
                                                        }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.depth == 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-none p-1",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.drilldown(
                                                          row_num,
                                                          f.name
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa-solid fa-arrow-down",
                                                      attrs: {
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        "data-placement": "top",
                                                        title: "Drill Down",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.depth == 2 && index == 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-none p-1",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeDrilldown()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-close",
                                                      attrs: {
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        "data-placement": "top",
                                                        title:
                                                          "Remove Drill Down",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e()
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade modal modal-lg",
          attrs: {
            id: "pathway-column-mapping-preview-modal",
            "aria-labelledby": "pathway-column-mapping-preview-modal",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _vm.preview_detail_table.length > 0
              ? _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "div",
                    { staticClass: "modal-header border-bottom-0 pb-0" },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-none position-absolute",
                            staticStyle: { top: "10px", right: "10px" },
                            attrs: {
                              type: "button",
                              "data-bs-dismiss": "modal",
                            },
                            on: {
                              click: function ($event) {
                                _vm.preview_detail_index = 0
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-close" })]
                        ),
                        _vm._v(" "),
                        _c("h4", { staticClass: "mt-2 mb-1" }, [
                          _vm._v("Preview"),
                        ]),
                        _vm._v(" "),
                        _c("h5", [
                          _vm.preview_detail_index > 0 &&
                          _vm.preview_detail_table.length > 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-none",
                                  on: {
                                    click: function ($event) {
                                      _vm.preview_detail_index--
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-solid fa-chevron-left",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.preview_detail_index + 1) +
                              " of " +
                              _vm._s(_vm.preview_detail_table.length) +
                              " samples\n                            "
                          ),
                          _vm.preview_detail_index <
                          _vm.preview_detail_table.length - 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-none",
                                  on: {
                                    click: function ($event) {
                                      _vm.preview_detail_index++
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-solid fa-chevron-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body p-0" }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table table-striped mb-0" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          { staticClass: "table-striped" },
                          _vm._l(
                            Object.values(
                              _vm.preview_detail_table[_vm.preview_detail_index]
                            ),
                            function (value, index) {
                              return _c("tr", [
                                _c("td", { staticClass: "ps-3" }, [
                                  _vm._v(
                                    _vm._s(_vm.getPrettyColNameByIndex(index))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(value))]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-footer justify-content-start border-top-0",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-bs-dismiss": "modal" },
                          on: {
                            click: function ($event) {
                              _vm.preview_detail_index = 0
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "3" } }, [
      _c("div", {
        staticClass: "spinner-border spinner-border-sm float-left",
        attrs: { role: "status" },
      }),
      _vm._v(" Loading"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "3" } }, [
      _c("strong", [
        _c("em", [_vm._v("No records were found for the given column.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "ps-3" }, [_vm._v("Column Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Column Value")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }