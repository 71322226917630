<template>
    <div class="input-group">
        <div class="token-textfield form-control" ref="editable"
            :class="((error != '' )? 'is-invalid':'')"
            contenteditable
            v-on:input="onInput"
            v-on:keypress="onKeypress"
            v-on:keydown.delete="onDelete"
            v-on:paste="onPaste"
             >
        </div>
        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="((error != '' )? 'is-invalid':'')" v-if="selections.length > 0">Variables</button>
        <div class="dropdown-menu token-dropdown">
          <a class="dropdown-item" href="#" v-for="s in selections" v-on:click.prevent="addToken(s)" v-if="s.id == null" >{{s.name}}</a>
          <div role="separator" class="dropdown-divider" v-if="showDivider()"></div>
          <a class="dropdown-item" href="#" v-for="s in selections" v-on:click.prevent="addToken(s)" v-if="s.id != null" >{{s.name}}</a>
        </div>
    </div>

</template>
<style>
    .token-textfield {
        color: #495057;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        font-size: 0.9rem;
        font-family: 'Courier New', serif;
        padding: 0.375rem 0.75rem;
        height: auto;
    }
    .token-textfield span {
        display: inline-block;
        font-size: 80%;
        background-color: #f7d15d;
        padding: .4em .6em .4em;
        line-height: 1;
        color: #252e2d;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border: 1px solid #46705F;
        border-radius: .25em;
    }
    .token-dropdown a {
        font-size: 0.85em;
        text-decoration: none;
        font-weight: normal;
        color: #252e2d;
        padding-left: 10px;
        line-height: 1.2em;
    }
    .token-dropdown a:hover {font-weight: bold;}

</style>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    selections: {
        type: Array,
        default: []
    },
    error: {
        type: String,
        default: ''
    }
  },
  mounted() {
    this.$refs.editable.innerHTML = this.value;
    this.styleTokens();
  },
  watch: {
    selections: {
        handler(n, o) {
            this.$refs.editable.innerHTML = this.value;
            this.styleTokens();
        },
        deep: false
      }
  },
  methods: {
    onPaste (e) {
        // Prevent the default pasting event and stop bubbling
        e.preventDefault()
        e.stopPropagation()
        // Get the clipboard data
        var paste = (e.clipboardData || window.clipboardData).getData('text/plain')

        //Get the selections names and sort them by length
        var names = [];
        for(var i = 0; i < this.selections.length; i++) {
            names.push(this.selections[i]);
        }
        names.sort(function(a, b){
          return b.name.length - a.name.length;
        });

        //Create the HTML version of the tokens
        for(var i = 0; i < names.length; i++)
            paste = paste.replaceAll(names[i].name, names[i].token);

        if (document.queryCommandSupported("insertText")) {
          document.execCommand("insertText", false, paste);
        } else {
          document.execCommand("paste", false, paste);
        }
    },
    showDivider() {
        return (this.selections.length > 0 && this.selections[0].id == null);
    },
    onKeypress(e) {
        var chr = String.fromCharCode(e.which);
        //Letters, numbers, or math characters
        if(!chr.match(/[a-z0-9\*\+\-\./ {}()_]/g))
            e.preventDefault();
        //If it is within a span, prevent the default
        var caretPos = this.getCaretPosition();
        var el = this.$refs.editable;
        if(caretPos == 0 && (el.childNodes.length == 0 || el.childNodes[0].nodeType == 1)) {
            el.innerHTML = "&nbsp;" + el.innerHTML;
        }
        else if(caretPos > 0) {

            for(var i = 0; i < el.childNodes.length; i++) {
                //Text field
                if(el.childNodes[i].nodeType == 3 ){
                    //If the position is in one of the subsequent nodes
                    if(el.childNodes[i].length <= caretPos)
                        caretPos -= el.childNodes[i].length;
                    //I'm in the middle of text, so that is just fine
                    else break;
                }
                //Span or other HTML elements
                else if(el.childNodes[i].nodeType == 1){
                    var len = el.childNodes[i].innerText.length;
                    //If the position is in one of the subsequent nodes
                    if(len < caretPos)
                        caretPos -= len;
                    //If the caret is at the beginning
                    else if(caretPos==0) break;

                    //It is within the token so prevent the input
                    else
                        e.preventDefault();
                }
            }
        }

    },
    onDelete(e) {
        var caretPos = this.getCaretPosition();
        var el = this.$refs.editable;

        for(var i = 0; i < el.childNodes.length; i++) {
            //Text field
            if(el.childNodes[i].nodeType == 3 ){
                //If the position is in one of the subsequent nodes
                if(el.childNodes[i].length <= caretPos)
                    caretPos -= el.childNodes[i].length;
                //If the caret is at the beginning of the node and the previous node is a span
                else if(caretPos == 0 && i > 0 && el.childNodes[i-1].nodeType == 1) {
                    e.preventDefault();
                    el.removeChild(el.childNodes[i-1]);
                }
                //I'm in the middle of text, so just delete that text
                else break;
            }
            //Span or other HTML elements
            else if(el.childNodes[i].nodeType == 1){
                var len = el.childNodes[i].innerText.length;
                //If the position is in one of the subsequent nodes
                if(len < caretPos)
                    caretPos -= len;
                else if(caretPos==0) break;
                //If the caret is within this node
                else{
                    e.preventDefault();
                    el.removeChild(el.childNodes[i]);
                    break;
                }
            }
        }

        this.$emit('input', this.stripHtml(this.unstyleTokens(el.innerHTML)));
    },
    onInput(e) {
        this.$emit('input', this.stripHtml(this.unstyleTokens(e.target.innerHTML)));

        //Remember where the caret is
        var caret = this.getCaretPosition();
        var starting_length = e.target.innerHTML.replaceAll("&nbsp;", " ").length;
        this.styleTokens();
        var ending_length = e.target.innerHTML.replaceAll("&nbsp;", " ").length;
        var length_change = ending_length - starting_length;

        //If the last character isn't a space, add a space
        if(e.target.innerHTML.replaceAll("&nbsp;", " ").substring(ending_length-1) != " "){
            e.target.innerHTML += "&nbsp;";
            this.setCaretPosition(caret);
        }
        //If I added a token, reset the caret position
        if(length_change > 0)
            this.setCaretPosition(caret-("<span></span> ".length-length_change-1));


    },
    styleTokens() {
        //Reset the text
        if(this.selections.length == 0)
            this.styleAnyToken();
        else
            this.styleSelectTokens();
    },
    styleSelectTokens() {
        for(var i = 0; i < this.selections.length; i++){
            //If there is something to replace
            if(this.$refs.editable.innerHTML.indexOf(this.selections[i].token) !== -1)
                this.$refs.editable.innerHTML = this.$refs.editable.innerHTML.replaceAll(
                    this.selections[i].token,
                    "<span>" + this.selections[i].name + "</span>&nbsp;"
                );
        }
    },
    styleAnyToken() {
        var closing_pos = -1;
        for(var j = 0; j < this.$refs.editable.childNodes.length; j++) {
            //Only do the replacement if it is a text node (not within a span)
            if(this.$refs.editable.childNodes[j].nodeType == 3){
                var original_text = this.$refs.editable.childNodes[j].nodeValue;
                var text = original_text;
                for(var i = text.length-1; i>= 0; i--) {
                    //Record the closing position
                    if(text.charAt(i) == "}")
                        closing_pos = i;
                    //Find the first opening position
                    if(closing_pos > -1 && text.charAt(i) == "{") {
                        //Replace the closing } with a closing span
                        text = text.substring(0, closing_pos) + "</span> " + text.substring(closing_pos+1);
                        //Replace the opening { with an opening span
                        text = text.substring(0, i) + "<span>" + text.substring(i+1);
                        //Get rid of double spaces
                        text = text.replaceAll("  ", " ");
                        closing_pos = -1;

                    }
                }

                //If I added a span, replace the HTML and start the for loop over
                if(original_text != text) {
                    //Replace the space
                    var html = this.$refs.editable.innerHTML.replaceAll("&nbsp;", " ");
                    html = html.replaceAll(original_text, text)
                    this.$refs.editable.innerHTML = html;
                    j = 0;
                }
            }
        }
    },
    unstyleTokens(text) {
        //Reset the text
        if(this.selections.length == 0) {
            text = text.replaceAll("<span>", "{");
            text = text.replaceAll("</span>", "}");
        }
        else{
            for(var i = 0; i < this.selections.length; i++){
                //If there is something to replace
                if(text.indexOf("<span>" + this.selections[i].name + "</span>&nbsp;") !== -1)
                    text = text.replaceAll(
                        "<span>" + this.selections[i].name + "</span>&nbsp;",
                        this.selections[i].token
                    );
            }
        }
        return text;
    },

    setCaretPosition(caretPos) {
        var el = this.$refs.editable
        var range = document.createRange()
        var sel = window.getSelection()
        var pos = 0;

        //nodeType of 3 == text
        //nodeType of 1 == span
        var i = 0;
        for(; i < el.childNodes.length; i++) {
            //Text field
            if(el.childNodes[i].nodeType == 3 ){
                //If the position is in one of the subsequent nodes
                if(el.childNodes[i].length <= caretPos)
                    caretPos -= el.childNodes[i].length;
                //If the caret is within this node
                else
                    break;
            }
            //Span or other HTML elements
            else if(el.childNodes[i].nodeType == 1){
                var len = el.childNodes[i].innerText.length;
                //If the position is in one of the subsequent nodes
                if(len <= caretPos)
                    caretPos -= len;
                //If the caret is within this node
                else {
                    break;
                }
            }

        }

        //If the cursor should be at the end of the text
        if(i >= el.childNodes.length)
            //Text
            if(el.childNodes[i-1].nodeType == 3)
                range.setStart(el.childNodes[i-1], el.childNodes[i-1].length);
            //All other HTML elements
            else
                range.setStart(el.childNodes[i-1], el.childNodes[i-1].innerText.length);
        //Otherwise, keep it where it was
        else
            range.setStart(el.childNodes[i], caretPos)

        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
    },
    getCaretPosition() {
      var caretOffset = 0;
        var doc = this.$refs.editable.ownerDocument || this.$refs.editable.document;
        var win = doc.defaultView || doc.parentWindow;
        var sel;
        if (typeof win.getSelection != "undefined") {
            sel = win.getSelection();
            if (sel.rangeCount > 0) {
                var range = win.getSelection().getRangeAt(0);
                var preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(this.$refs.editable);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                caretOffset = preCaretRange.toString().length;
            }
        } else if ( (sel = doc.selection) && sel.type != "Control") {
            var textRange = sel.createRange();
            var preCaretTextRange = doc.body.createTextRange();
            preCaretTextRange.moveToElementText(this.$refs.editable);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }
        return caretOffset;
    },
    stripHtml(html)
    {
       let tmp = document.createElement("DIV");
       tmp.innerHTML = html;
       return tmp.textContent || tmp.innerText || "";
    },
    addToken(selection) {
        var html = "<span>" + selection.name + "</span>&nbsp;";
        var sel, range;
        var token_field = this.$refs.editable;

        if (window.getSelection) {
            // IE9 and non-IE
            sel = window.getSelection();
            //If I'm not focused on this textfield, shift focus to this text field

            if(sel.focusNode == null || sel.focusNode.parentNode != token_field){
                var r = document.createRange();
                r.setStart(token_field, 0);
                r.setEnd(token_field, 0);
                sel.removeAllRanges();
                sel.addRange(r);
            }

            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();

                // Range.createContextualFragment() would be useful here but is
                // only relatively recently standardized and is not supported in
                // some browsers (IE9, for one)
                var el = document.createElement("div");
                el.innerHTML = html;
                var frag = document.createDocumentFragment(), node, lastNode;
                while ( (node = el.firstChild) ) {
                    lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);

                // Preserve the selection
                if (lastNode) {
                    range = range.cloneRange();
                    range.setStartAfter(lastNode);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        } else if (document.selection && document.selection.type != "Control") {
            // IE < 9
            document.selection.createRange().pasteHTML(html);
        }

        this.$emit('input', this.stripHtml(this.unstyleTokens(token_field.innerHTML)).trim());
    }
  },
};
</script>
