var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "org_settings_prioritization" } },
    [
      _c("h4", [
        _vm._v("Insight Prioritization for " + _vm._s(_vm.client.name)),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The Insights dashboard can be tailored to the channels and user segments that you care most about. Use the inputs below to ensure that your dashboard provides you with the insights that are most important to you."
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "This prioritization will only apply to your user; each user can make adjustments based upon their own preferences."
        ),
      ]),
      _vm._v(" "),
      _vm.user_prioritization.length == 0
        ? _c("div", [_vm._m(0)])
        : _vm._l(_vm.user_prioritization, function (p) {
            return _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("h5", [_vm._v(_vm._s(p.label) + ": " + _vm._s(p.text))]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 mx-2" },
                    [
                      _c("vue-slider", {
                        attrs: {
                          "tooltip-formatter": (val) =>
                            Math.round(p.value * 100) + "%",
                          min: 0.1,
                          max: 1,
                          interval: 0.1,
                        },
                        model: {
                          value: p.value,
                          callback: function ($$v) {
                            _vm.$set(p, "value", $$v)
                          },
                          expression: "p.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(2, true),
                ]),
              ]),
            ])
          }),
      _vm._v(" "),
      _vm.user_prioritization.length > 0
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary mt-3",
              attrs: { type: "submit", disabled: _vm.form.busy },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(" Save Changes\n    "),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "d-flex align-items-center" }, [
      _c(
        "div",
        {
          staticClass: "spinner-border text-primary me-2",
          attrs: { role: "status" },
        },
        [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
      ),
      _vm._v("\n            Loading Prioritization Options\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-center align-items-center" },
      [
        _c("label", { staticClass: "form-label mb-0" }, [
          _vm._v("Least Important"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-center align-items-center" },
      [
        _c("label", { staticClass: "form-label mb-0" }, [
          _vm._v("Most Important"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }