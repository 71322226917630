var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "results" },
    [
      _vm.client != null
        ? _c(
            "h2",
            {
              staticClass:
                "mb-4 mt-5 border-bottom border-secondary border-3 pb-1",
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.client.name) + ":")]),
              _vm._v(" "),
              _c("em", [_vm._v("Media Management")]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "nav",
            {
              staticClass: "navbar navbar-expand-lg bg-secondary mb-4 rounded",
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("span", { staticClass: "navbar-brand mb-0 ms-1 h1" }, [
                  _vm._v("Filters:"),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse",
                    attrs: { id: "filter-navbar" },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "navbar-nav me-auto mb-2 mb-lg-0" },
                      [
                        _vm.client == null
                          ? _c(
                              "li",
                              { staticClass: "nav-item dropdown me-3" },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "dropdown-menu" },
                                  [
                                    _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link",
                                          on: {
                                            click: function ($event) {
                                              _vm.filters.clients =
                                                _vm.getAllClientIds()
                                            },
                                          },
                                        },
                                        [_c("em", [_vm._v("Select All")])]
                                      ),
                                      _vm._v(
                                        "\n                            |\n                            "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link",
                                          on: {
                                            click: function ($event) {
                                              _vm.filters.clients = []
                                            },
                                          },
                                        },
                                        [_c("em", [_vm._v("Unselect All")])]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "dropdown-divider",
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.clients, function (c) {
                                      return _c(
                                        "label",
                                        {
                                          key: c.id,
                                          staticClass: "dropdown-item",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.filters.clients,
                                                expression: "filters.clients",
                                              },
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              type: "checkbox",
                                              "true-value": [],
                                            },
                                            domProps: {
                                              value: c.id,
                                              checked: Array.isArray(
                                                _vm.filters.clients
                                              )
                                                ? _vm._i(
                                                    _vm.filters.clients,
                                                    c.id
                                                  ) > -1
                                                : _vm._q(
                                                    _vm.filters.clients,
                                                    []
                                                  ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.filters.clients,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? []
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = c.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.filters,
                                                        "clients",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.filters,
                                                        "clients",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.filters,
                                                    "clients",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "ms-2" }, [
                                            _vm._v(
                                              "\n                              " +
                                                _vm._s(c.name) +
                                                "\n                            "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item dropdown me-3" }, [
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "dropdown-menu" },
                            _vm._l(_vm.timeframes, function (t) {
                              return _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.filters.timeframe = t
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getTimeframeText(t)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.filters.timeframe == t
                                      ? _c("i", {
                                          staticClass:
                                            "fa fa-circle-check text-primary ms-2",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item dropdown me-3" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dropdown-menu" },
                            _vm._l(_vm.media_sources, function (item) {
                              return _c(
                                "label",
                                { key: item, staticClass: "dropdown-item" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filters.media_sources,
                                        expression: "filters.media_sources",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": [],
                                    },
                                    domProps: {
                                      value: item,
                                      checked: Array.isArray(
                                        _vm.filters.media_sources
                                      )
                                        ? _vm._i(
                                            _vm.filters.media_sources,
                                            item
                                          ) > -1
                                        : _vm._q(_vm.filters.media_sources, []),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.filters.media_sources,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? [] : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filters,
                                                "media_sources",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filters,
                                                "media_sources",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filters,
                                            "media_sources",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ms-2" }, [
                                    _vm._v(
                                      "\n                              " +
                                        _vm._s(item) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item dropdown me-3" }, [
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dropdown-menu" },
                            _vm._l(_vm.media_types, function (item) {
                              return _c(
                                "label",
                                { key: item, staticClass: "dropdown-item" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filters.media_types,
                                        expression: "filters.media_types",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": [],
                                    },
                                    domProps: {
                                      value: item,
                                      checked: Array.isArray(
                                        _vm.filters.media_types
                                      )
                                        ? _vm._i(
                                            _vm.filters.media_types,
                                            item
                                          ) > -1
                                        : _vm._q(_vm.filters.media_types, []),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.filters.media_types,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? [] : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filters,
                                                "media_types",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filters,
                                                "media_types",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filters,
                                            "media_types",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ms-2" }, [
                                    _vm._v(
                                      "\n                              " +
                                        _vm._s(item) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-dark float-end btn-sm d-none d-sm-inline-block me-3 text-nowrap",
                  class: { disabled: !_vm.filtered },
                  on: {
                    click: function ($event) {
                      return _vm.saveSettings()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-save" }),
                  _vm._v(" Save Settings"),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row stat-container mb-3" },
        _vm._l(_vm.metrics, function (m) {
          return _c(
            "div",
            { staticClass: "col-sm-6 col-md-4 mb-4 scorecard" },
            [
              _c("div", { staticClass: "card box-content" }, [
                _c("h5", { staticClass: "card-header pt-3" }, [
                  _vm._v(_vm._s(_vm._f("propercase")(m.name))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-0 pb-3" }, [
                  _c("img", {
                    staticClass: "float-start me-3",
                    attrs: { src: m.icon },
                  }),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mt-2" }, [
                    _vm._v(
                      _vm._s(_vm._f("currency_with_zero")(m.value)) +
                        "\n                    "
                    ),
                    m.variance != 0
                      ? _c(
                          "span",
                          {
                            staticClass: "badge",
                            class: {
                              "bg-label-success": m.variance > 0,
                              "bg-label-danger": m.variance < 0,
                            },
                          },
                          [_vm._v(_vm._s(_vm._f("percentage_na")(m.variance)))]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._l(_vm.clients, function (c) {
        return _vm.filters.clients.includes(c.id)
          ? _c("media-management-chart", {
              key: c.id,
              ref: "chart",
              refInFor: true,
              attrs: {
                title: _vm.client == null ? c.name : "",
                client: c,
                actuals: _vm.actuals[c.id],
                projections: _vm.projections[c.id],
                media_balance: _vm.media_balances[c.id],
                "data-client-id": c.id,
                filters: _vm.filters,
                notifications: _vm.notifs[c.id],
              },
            })
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#filter-navbar",
          "aria-controls": "filter-navbar",
          "aria-expanded": "false",
          "aria-label": "Toggle Filters",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          role: "button",
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa-solid fa-building me-1" }),
        _vm._v(" Clients\n                      "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          role: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", {
          staticClass: "fa fa-calendar me-1",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" Timeframe\n                      "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          role: "button",
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa-solid fa-photo-film me-1" }),
        _vm._v(" Media Sources\n                      "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          role: "button",
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa-solid fa-money-bill me-1" }),
        _vm._v(" Media Type\n                      "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }