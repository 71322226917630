<template>
    <div class="wysiwyg-editor-advanced" :id='"editor-" + this.asset_id'>
        <vue-editor v-model="local_input_value" @focus="onEditorFocus" :editorOptions="editorOptions" 
        @blur="inputFieldValueChange()" ref="textarea" :disabled="if_disabled"></vue-editor>

        <div class="modal fade" :id="'insert-image-modal-'+asset_id" aria-labelledby="insert-image-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="close btn" :data-bs-dismiss="'insert-image-modal-'+asset_id" @click="closeModal('insert-image-modal-'+asset_id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-0">Insert Image</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <div class="mb-3">
                            <label class="form-label">Image URL</label>
                            <input class="form-control" type="text" v-model="image.url" :class="((errors.image_url != '' )? 'is-invalid':'')"/>                            
                            <div class="text-danger invalid-feedback" v-if="errors.image_url != ''">
                                {{errors.image_url }}
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label">Align Image With Text</label>
                                <select class="form-select" v-model="image.float_position" 
                                :class="((errors.image_float_position != '' )? 'is-invalid':'')">
                                    <option value="right">Right</option>
                                    <option value="center">Center</option>
                                    <option value="left">Left</option>
                                </select> 
                                <div class="text-danger invalid-feedback" v-if="errors.image_float_position != ''">
                                    {{errors.image_float_position }}
                                </div>
                            </div>                           
                            <div class="col-6 ">
                                <label class="form-label">Image Width (Optional)</label>
                                <div class="input-group">
                                    <input class="form-control" type="number" v-model="image.width" min="1" max="720">
                                    <div class="input-group-append">
                                        <span class="input-group-text">px</span>
                                    </div>     
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Link Attached (Optional)</label>
                            <input class="form-control" type="text" v-model="image.link" :class="((errors.image_link != '' )? 'is-invalid':'')"/>   
                            <div class="text-danger invalid-feedback" v-if="errors.image_link != ''">
                                {{errors.image_link }}
                            </div>                         
                        </div>
                    </div>
                    <div class="modal-footer justify-content-start border-top-0">
                        <button type="button" class="btn btn-primary" @click.prevent="insertImageToWysiwyg();">
                            <i class="fa fa-btn fa-save"></i>Save</button>
                        <button type="button" class="btn btn-secondary" :disabled="form.busy" @click="closeModal('insert-image-modal-'+asset_id)">
                            <i class="fa fa-btn fa-close"></i>Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" :id="'insert-button-modal-'+asset_id" aria-labelledby="insert-button-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="close btn" :data-bs-dismiss="'insert-button-modal-'+asset_id" @click="closeModal('insert-button-modal-'+asset_id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-0">Insert Button</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <div class="row mb-3">
                            <div class="col-6 ">
                                <label class="form-label">Button Color</label>
                                <select class="form-select" v-model="button.button_color" 
                                :class="((errors.button_color != '' )? 'is-invalid':'')">
                                    <option value="" disabled selected>Select the button color...</option>
                                    <option value="blue">Blue</option>
                                    <option value="var(purple)">Purple</option>
                                    <option value="pink">Pink</option>
                                    <option value="red">Red</option>
                                    <option value="orange">Orange</option>
                                    <option value="yellow">Yellow</option>
                                    <option value="green">Green</option>
                                    <option value="gray">Gray</option>
                                </select> 
                                
                                <div class="text-danger invalid-feedback" v-if="errors.button_color != ''">
                                    {{errors.button_color }}
                                </div>      
                            </div>                            
                            <div class="col-6 ">
                                <label class="form-label">Text Color</label>
                                <select class="form-select" v-model="button.text_color" 
                                :class="((errors.button_text_color != '' )? 'is-invalid':'')">
                                    <option value="" disabled selected>Select the text color...</option>
                                    <option value="black">Black</option>
                                    <option value="white">White</option>
                                    <option value="gray">Gray</option>
                                    <option value="blue">Blue</option>
                                    <option value="purple">Purple</option>
                                    <option value="pink">Pink</option>
                                    <option value="red">Red</option>
                                    <option value="orange">Orange</option>
                                    <option value="yellow">Yellow</option>
                                    <option value="green">Green</option>
                                    <option value="gray">Gray</option>
                                </select> 
                                <div class="text-danger invalid-feedback" v-if="errors.button_text_color != ''">
                                    {{errors.button_text_color }}
                                </div>
                            </div>                            
                        </div>
                        <div class="row mb-3">
                            <div class="col-6 ">
                                <label class="form-label">Button Text</label>
                                <input class="form-control" type="text" v-model="button.text"
                                :class="((errors.button_text != '' )? 'is-invalid':'')">
                                <div class="text-danger invalid-feedback" v-if="errors.button_text != ''">
                                    {{errors.button_text }}
                                </div>     
                            </div>                            
                            <div class="col-6">
                                <label class="form-label">Align Button With Text</label>
                                <select class="form-select" v-model="button.float_position" 
                                :class="((errors.button_float_position != '' )? 'is-invalid':'')">
                                    <option value="right">Right</option>
                                    <option value="center">Center</option>
                                    <option value="left">Left</option>
                                </select> 
                                <div class="text-danger invalid-feedback" v-if="errors.button_float_position != ''">
                                    {{errors.button_float_position }}
                                </div>
                            </div>                           
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Link Attached</label>
                            <input class="form-control" type="text" v-model="button.link" 
                            :class="((errors.button_link != '' )? 'is-invalid':'')"/>   
                            <div class="text-danger invalid-feedback" v-if="errors.button_link != ''">
                                {{errors.button_link }}
                            </div>                         
                        </div>
                    </div>
                    <div class="modal-footer justify-content-start border-top-0">
                        <button type="button" class="btn btn-primary" @click.prevent="insertButtonToWysiwyg();">
                            <i class="fa fa-btn fa-save"></i>Save</button>
                        <button type="button" class="btn btn-secondary" :disabled="form.busy" @click="closeModal('insert-button-modal-'+asset_id)">
                            <i class="fa fa-btn fa-close"></i>Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal example-modal fade" :id="'edit-html-modal-'+asset_id" aria-labelledby="edit-html-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="close btn" :data-bs-dismiss="'edit-html-modal-'+asset_id" @click="closeModal('edit-html-modal-'+asset_id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-0">Edit HTML</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <label class="form-label mb-0">HTML</label>
                        <!-- <div class="view-html-block form-control mb-3" contenteditable="true" ref="editable">
                            {{ local_input_value }} 
                        </div>  -->
                        <textarea type="text" v-model="local_input_value_html" class="form-control full-height html-textarea"></textarea>  
                        <div class="text-danger invalid-feedback" v-if="!is_valid_html">
                            There is an error saving your change on the HTML content, please validate that it is in correct format.
                        </div>                 
                    </div>
                    <div class="modal-footer justify-content-start border-top-0">
                        <button type="button" class="btn btn-primary" @click.prevent="saveHTMLChange();">
                            <i class="fa fa-btn fa-save"></i>Save</button>
                        <button type="button" class="btn btn-secondary" :disabled="form.busy" @click="closeModal('edit-html-modal-'+asset_id)">
                            <i class="fa fa-btn fa-close"></i>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { VueEditor } from "vue2-editor";

        export default {
        components: {VueEditor},
        props: {
            input_value: "",
            input_index: null,
            asset_id: null,
            if_show_button_input: false,
            if_disabled: false
        },
        data() {
            return {    
                local_input_value: null,
                local_input_value_html: null,
                is_valid_html: true,
                image:{
                    id: null,  // if assigned, it will be a random id used to locate with image has been clicked/edited, new image id will be null
                    url: null,
                    width: null,
                    float_position: 'left',  // left align by default
                    link: null
                },
                button: {
                    id: null, // if assigned, it will be a random id used to locate with image has been clicked/edited, new button id will be null
                    button_color: null,
                    text_color: null,
                    text: null,
                    float_position: 'left', // left align by default
                    link: null
                },
                errors: {
                    image_url: '',
                    image_float_position: '',
                    image_width: '',
                    image_link: '',

                    button_color: '',
                    button_text_color: '',
                    button_text: '',
                    button_float_position: '',
                    button_link: ''
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false,
                    warning: false,
                },
                editorFocused: null,
                editorOptions:{
                    modules: {
                        toolbar: {
                            container: [
                                ["bold", "italic", "underline"],          
                                ["image", "link", "clean"],
                                [{ list: "ordered" }, { list: "bullet" }], 
                                [{ indent: "-1" }, { indent: "+1" }],
                            ],
                            handlers: {
                                image: this.customNewImageHandler,
                                formula: this.customNewButtonHandler,
                                clean: this.customCodeEditerHandler, 
                            }
                        }
                    }
                }
            }
        },
        watch:{
            input_value(new_value, old_value){
                this.local_input_value = new_value;
                this.local_input_value_html = new_value;
            }
        },
        beforeMount() {
            this.local_input_value = this.input_value;
            this.local_input_value_html = this.local_input_value;
            if(this.if_show_button_input){
                // if if_show_button_input == true, add button option to the toolbar  
                this.editorOptions.modules.toolbar.container = [
                    ["bold", "italic", "underline"],          
                    ["image", "link", "formula", "clean"],
                    [{ list: "ordered" }, { list: "bullet" }], 
                    [{ indent: "-1" }, { indent: "+1" }]
                ]
            } 
        },
        mounted(){
            if(this.if_show_button_input){
                const formulaButtons = document.querySelectorAll('#editor-' + this.asset_id + ' .ql-formula');
                if (formulaButtons.length){
                    formulaButtons.forEach(formulaButton => {
                        formulaButton.removeChild(formulaButton.children[0])
                        let node = document.createElement('i');
                        node.classList.add('fa', 'fa-btn', 'fa-plus-circle');
                        node.setAttribute('data-bs-toggle', 'modal');
                        node.setAttribute('data-bs-target', '#insert-button-modal-'+ this.asset_id);
                        formulaButton.appendChild(node);                        
                    });
                }                  
            }

            const linkButtons = document.querySelectorAll('#editor-' + this.asset_id + ' .ql-link');
            if (linkButtons.length){
                linkButtons.forEach(linkButton => {
                    linkButton.removeChild(linkButton.children[0])
                    let node = document.createElement('i');
                    node.classList.add('fa', 'fa-btn', 'fa-link');
                    node.setAttribute('data-bs-toggle', 'tooltip');
                    node.setAttribute('data-bs-placement', 'bottom');
                    node.setAttribute('title', 'Select the text first then click this option to add a hyperlink');
                    linkButton.appendChild(node);
                });
            } 

            const cleanButtons = document.querySelectorAll('#editor-' + this.asset_id + ' .ql-clean');
            if (cleanButtons.length){
                cleanButtons.forEach(cleanButton => {
                    cleanButton.removeChild(cleanButton.children[0])
                    let node = document.createElement('i');
                    node.classList.add('fa', 'fa-btn', 'fa-code');
                    node.setAttribute('data-bs-toggle', 'modal');
                    node.setAttribute('data-bs-target', '#edit-html-modal-'+ this.asset_id);
                    cleanButton.appendChild(node);
                });
            } 

            const imageButtons = document.querySelectorAll('#editor-' + this.asset_id + ' .ql-image');
            if (imageButtons.length){
                imageButtons.forEach(imageButton => {
                    imageButton.removeChild(imageButton.children[0])
                    let node = document.createElement('i');
                    node.classList.add('fa', 'fa-btn', 'fa-lg', 'fa-image');
                    node.setAttribute('data-bs-toggle', 'modal');
                    node.setAttribute('data-bs-target', '#insert-image-modal-'+ this.asset_id);
                    imageButton.appendChild(node);
                });
            } 

            const images = document.querySelectorAll('#editor-' + this.asset_id + ' .ql-image-input');
            images.forEach(image => {
                image.addEventListener('click', (e) => {
                    image.setAttribute('data-bs-toggle', 'modal');
                    image.setAttribute('data-bs-target', '#insert-image-modal-'+ this.asset_id);
                    if (image.parentElement.nodeName == 'A') {
                        this.image = {
                            id: image.id,
                            url: image.src,
                            width: image.width,
                            float_position: image.parentElement.parentElement.style.textAlign,
                            link: image.parentElement.href
                        };                        
                    } else { // parentElement is <p>
                        this.image = {
                            id: image.id,
                            url: image.src,
                            width: image.width,
                            float_position: image.parentElement.style.textAlign,
                            link: null
                        };   
                    }
                });
            });

            const buttons = document.querySelectorAll('#editor-' + this.asset_id + ' .ql-button-input');
            buttons.forEach(button => {
                button.addEventListener('click', (e) => {
                    button.setAttribute('data-bs-toggle', 'modal');
                    button.setAttribute('data-bs-target', '#insert-button-modal-'+ this.asset_id);
                  
                    this.button ={
                        id: button.id, 
                        button_color: button.style.backgroundColor,
                        text_color: button.style.color,
                        text: button.textContent,
                        float_position: button.parentElement.parentElement.style.textAlign,
                        link: button.parentElement.href
                    };         
                });
            });
        },
        methods: {
            onEditorFocus(quill) {    
                this.editorFocused = quill; 
            }, 
            inputFieldValueChange(){
                this.local_input_value_html = this.local_input_value;
                this.$emit('wysiwygValueChange', {value: this.local_input_value, index: this.input_index});
                this.addClickListenerToNewImages();
                this.addClickListenerToNewButton();
            },
            addClickListenerToNewImages(){
                var allImages, savedImages;
                var allImageIds = []
                var savedImageIds = []

                if (this.local_input_value){
                    allImages = this.local_input_value.match(/<img[^>]*>/g);
                    if (allImages){
                        allImages.forEach(i => {
                            var ele = new DOMParser().parseFromString(i, "text/xml").children[0];
                            allImageIds.push(ele.id);
                        });                    
                    }
                }
                if (this.input_value){
                    savedImages = this.input_value.match(/<img[^>]*>/g);
                    if (savedImages){
                        savedImages.forEach(i => {
                            var ele = new DOMParser().parseFromString(i, "text/xml").children[0];
                            savedImageIds.push(ele.id);
                        });                    
                    }
                }

                if(allImageIds.length > savedImageIds.length){
                    let newlyAddedImageIds = allImageIds.filter(x => !savedImageIds.includes(x));
                    newlyAddedImageIds.forEach(id => {
                        const newImage = document.getElementById(id);
                        if(newImage){
                            newImage.addEventListener('click', (e) => {
                                newImage.setAttribute('data-bs-toggle', 'modal');
                                newImage.setAttribute('data-bs-target', '#insert-image-modal-'+ this.asset_id);
                                if (newImage.parentElement.nodeName == 'A') {
                                    this.image = {
                                        id: newImage.id,
                                        url: newImage.src,
                                        width: newImage.width,
                                        float_position: newImage.parentElement.parentElement.style.textAlign,
                                        link: newImage.parentElement.href
                                    };                        
                                } else { // parentElement is <p>
                                    this.image = {
                                        id: newImage.id,
                                        url: newImage.src,
                                        width: newImage.width,
                                        float_position: newImage.parentElement.style.textAlign,
                                        link: null
                                    };   
                                }
                            });                            
                        }

                    })                    
                }
            },
            addClickListenerToNewButton(){
                var allButton, savedButtons;
                var allButtonIds = [];
                var savedButtonIds = [];

                if (this.local_input_value){
                    allButton= this.local_input_value.match(/<span[^>]*>/g);
                    if (allButton){
                        allButton.forEach(i => {
                            var ele = new DOMParser().parseFromString(i, "text/xml").children[0];
                            allButtonIds.push(ele.id);
                        });                    
                    }                    
                }
                if (this.input_value){
                    savedButtons = this.input_value.match(/<span[^>]*>/g);
                    if (savedButtons){
                        savedButtons.forEach(i => {
                            var ele = new DOMParser().parseFromString(i, "text/xml").children[0];
                            savedButtonIds.push(ele.id);
                        });                    
                    }
                }

                if(allButtonIds.length > savedButtonIds.length){
                    let newlyAddedButtonIds = allButtonIds.filter(x => !savedButtonIds.includes(x));
                    newlyAddedButtonIds.forEach(id => {
                        const newButton = document.getElementById(id);
                        if (newButton){
                            newButton.addEventListener('click', (e) => {
                                newButton.setAttribute('data-bs-toggle', 'modal');
                                newButton.setAttribute('data-bs-target', '#insert-button-modal-'+ this.asset_id);
                                
                                this.button ={
                                    id: newButton.id, 
                                    button_color: newButton.style.backgroundColor,
                                    text_color: newButton.style.color,
                                    text: newButton.textContent,
                                    float_position: newButton.parentElement.parentElement.style.textAlign,
                                    link: newButton.parentElement.href
                                };                          
                            });                            
                        }

                    })                    
                }
            },
            customNewImageHandler(val){
                this.image = {
                    id: null,
                    url: null,
                    width: null,
                    float_position: 'left',
                    link: null
                };
            },
            customNewButtonHandler(val){
                this.button = {
                    id: null,
                    button_color: null,
                    text_color: null,
                    text: null,
                    float_position: 'left',
                    link: null
                };
            },
            customCodeEditerHandler(val){
                console.log(this.$refs.editable)
            },
            saveHTMLChange(){
                this.is_valid_html = this.validateHTML(this.local_input_value_html);
                if (this.is_valid_html){
                    this.local_input_value = this.local_input_value_html;
                    this.closeModal('edit-html-modal-'+this.asset_id);
                }
            },
            validateHTML(htmlString){
                var doc = document.createElement('div');
                doc.innerHTML = htmlString;
                return ( doc.innerHTML === htmlString );
            },
            getInsertIndex(){
                var insertIndex = 0;
                const insertIndexTemp = this.editorFocused? this.editorFocused.getSelection(true).index: 0; // this is the index focused on the text only, need to account for the html tags too
                const priorText = this.local_input_value ? this.local_input_value.slice(0, insertIndexTemp) : '';
                const tagsInPriorText = priorText.match(/<[^>]*>/g);
                var insertIndex = 0;
                if (priorText != '' && tagsInPriorText){
                    insertIndex = tagsInPriorText.join('').length + insertIndexTemp - 1;
                }
                if (this.local_input_value.slice(0, insertIndex).slice(-1) == '<'){
                    // breaking the closing tag, so move the insertIndex after the closing tag
                    var remainingString = this.local_input_value.slice(insertIndex, -1);
                    insertIndex = insertIndex + remainingString.indexOf('>') + 1
                } 
                return insertIndex;
            },
            insertImageToWysiwyg() {
                this.clearErrorsImageModal();
                if(this.hasErrorsImageModal()) {
                    return;
                }
                if (this.image.url) {
                    var insertIndex = this.getInsertIndex();
                   
                    const randomID = Date.now();
                    var imageInserted;
                    // construct image element
                    if(this.image.link == null || this.image.link.trim() == ''){
                        imageInserted = '<p class="element-inserted" style="text-align: '+ this.image.float_position + '"><img id="' + randomID + '"class="ql-image-input" src="' + this.image.url + '" width="' + this.image.width + 'px"></p>';                       
                    } else { // wrap image in <a> 
                        if (!this.image.link.startsWith('https://') && !this.image.link.startsWith('http://')){
                            this.image.link = 'http://'+this.image.link;
                        }
                        imageInserted = '<p class="element-inserted" style="text-align: '+ this.image.float_position + '"><a href="'+this.image.link+'"><img id="' + randomID + '"class="ql-image-input" src="' + this.image.url + '" width="' + this.image.width + 'px"></a></p>'; 
                    }

                    if (this.image.id){ // editing on image
                        var tpl = document.createElement('div');
                        tpl.innerHTML = this.local_input_value;
                        const imageFragment = tpl.querySelector("[id='"+ this.image.id+"']").parentElement;
                        const newImageFragment = document.createElement('div');
                        newImageFragment.innerHTML = imageInserted
                        imageFragment.replaceWith(newImageFragment.children[0]);
                        this.local_input_value = tpl.innerHTML;
                    } else {
                        this.local_input_value = this.local_input_value? this.local_input_value.slice(0, insertIndex) 
                            + imageInserted + this.local_input_value.slice(insertIndex) : imageInserted;   
                    }

                    this.closeModal('insert-image-modal-'+this.asset_id);
                    this.image ={
                        id: null,
                        url: null,
                        width: null,
                        float_position: 'left',
                        link: null
                    };
                    this.clearErrorsImageModal();
                }
            },
            insertButtonToWysiwyg(){
                this.clearErrorsButtonModal();
                if(this.hasErrorsButtonModal()) {
                    return;
                }
                if (this.button.text) {  
                    var insertIndex = this.getInsertIndex();

                    const randomID = Date.now();
                    var buttonInserted;
                    // construct button element, wrap span in <a> 
                    if (!this.button.link.startsWith('https://') && !this.button.link.startsWith('http://')){
                        this.button.link = 'http://'+this.button.link;
                    }
                    buttonInserted = '<p class="element-inserted" style="text-align: '+ this.button.float_position + '"><a class="ql-button-link" href="'+this.button.link+'" ><span class="ql-button-input" id="' + randomID 
                        + '" style="background-color: '+this.button.button_color+'!important; color: '+ this.button.text_color+'!important;">' + this.button.text + '</span></a></p>';

                    if (this.button.id){ // editing on button
                        var tpl = document.createElement('div');
                        tpl.innerHTML = this.local_input_value;
                        const buttonFragment = tpl.querySelector("[id='"+ this.button.id+"']").parentElement.parentElement;
                        const newButtonFragment = document.createElement('div');
                        newButtonFragment.innerHTML = buttonInserted
                        buttonFragment.replaceWith(newButtonFragment.children[0]);
                        this.local_input_value = tpl.innerHTML;
                    } else {
                        this.local_input_value = this.local_input_value ? this.local_input_value.slice(0, insertIndex) 
                            + buttonInserted + this.local_input_value.slice(insertIndex) : buttonInserted;   
                    }

                    this.closeModal('insert-button-modal-'+this.asset_id);
                    this.button ={
                        id: null, 
                        button_color: null,
                        text_color: null,
                        text: null,
                        float_position: 'left',
                        link: null
                    };
                    this.clearErrorsButtonModal();
                }
            },
            clearErrorsImageModal(){
                this.errors.image_url = '';
                this.errors.image_float_position = '';
                this.errors.image_width = '';
                this.errors.image_link = '';
            },
            clearErrorsButtonModal(){
                this.errors.button_color = '';
                this.errors.button_text_color = '';
                this.errors.button_text = '';
                this.errors.button_float_position = '';
                this.errors.button_link = '';
            },
            hasErrorsImageModal(){
                if(this.image.url == null || this.image.url == ""){
                    this.errors.image_url = "URL is required.";
                } else
                    this.errors.image_url = "";

                if(this.image.float_position == null || this.image.float_position == ""){
                    this.errors.image_float_position = "Float Position is required.";
                } else
                    this.errors.image_float_position = "";

                if(this.image.width != null && this.image.width != ""){
                    var image_width_number = Number(this.image.width);
                    if(typeof image_width_number!= 'number' || image_width_number < 1 || image_width_number > 720 ){
                        this.errors.image_width = "Please enter a number between 1 and 720 for the image width";
                    } else
                        this.errors.image_width = "";
                }
                
                if (this.errors.image_url!="" || this.errors.image_width!=""
                || this.errors.image_float_position!="" || this.errors.image_link!=""){
                    return true
                } else {
                    return false
                }
            }, 
            hasErrorsButtonModal(){
                if(this.button.button_color == null || this.button.button_color == ""){
                    this.errors.button_color = "Button Color is required.";
                } else
                    this.errors.button_color = "";

                if(this.button.text_color == null || this.button.text_color == ""){
                    this.errors.button_text_color = "Text Color is required.";
                } else
                    this.errors.button_text_color = "";

                if(this.button.text == null || this.button.text == ""){
                    this.errors.button_text = "Button Text is required.";
                } else
                    this.errors.button_text = "";

                if(this.button.float_position == null || this.button.float_position == ""){
                    this.errors.button_float_position = "Float Position is required.";
                } else
                    this.errors.button_float_position = "";

                if(this.button.link == null || this.button.link == ""){
                    this.errors.button_link = "Link is required.";
                } else
                    this.errors.button_link = "";

                if (this.errors.button_color!="" || this.errors.button_text_color!=""
                || this.errors.button_text!="" || this.errors.button_float_position!=""
                || this.errors.button_link!=""){
                    return true
                } else {
                    return false
                }
            }, 
            closeModal(id) {
                var myModalEl = document.getElementById(id)
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();

                if (id == ('edit-html-modal-'+this.asset_id)){
                    // reset edit html modal data on closing
                    this.is_valid_html = true;
                    this.local_input_value_html = this.local_input_value
                }
            },    
        }
    }
</script>