var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "annual-plan-initiatives" }, [
    _c("div", { staticClass: "date-selector" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-outline-secondary back-btn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.moveDatesBackward()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-angle-double-left",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "inline-block", "line-height": "1.1em" } },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.month_names[_vm.current_month]) +
              " " +
              _vm._s(_vm.current_year) +
              " — " +
              _vm._s(_vm.future_date) +
              "\n            "
          ),
          _vm.plan.is_published_plan != 1
            ? _c("span", { staticClass: "duplicate-annual" }, [
                _vm._v("("),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-link",
                    class: _vm.processing ? "disabled" : "",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.copyYear()
                      },
                    },
                  },
                  [_vm._v("Duplicate Annual Initiatives")]
                ),
                _vm._v(")"),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-outline-secondary forward-btn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.moveDatesForward()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-angle-double-right",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "initiatives-selections",
        class: { "show-priority-zones": _vm.show_zones },
      },
      [
        _c(
          "table",
          { staticClass: "table" },
          [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", {
                    staticClass: "bare",
                    attrs: { colspan: "3", scope: "colgroup" },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return [
                      _c(
                        "th",
                        {
                          attrs: { colspan: months.length, scope: "colgroup" },
                        },
                        [_vm._v(_vm._s(year))]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "tr",
                [
                  _c("th", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "priority" }, [_vm._v("Priority")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "cost" }, [_vm._v("Cost")]),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return _vm._l(months, function (month) {
                      return _c(
                        "th",
                        {
                          staticClass: "month",
                          class: { disabled: _vm.isInThePast(month, year) },
                          attrs: { scope: "col" },
                        },
                        [_vm._v(_vm._s(_vm.month_names[month]))]
                      )
                    })
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.all_initiatives, function (val, key) {
              return [
                _c(
                  "draggable",
                  {
                    staticClass: "zone",
                    class: "count-" + val.length + " " + key.toLowerCase(),
                    attrs: {
                      list: val,
                      group: "initiatives",
                      tag: "tbody",
                      disabled: _vm.plan.is_published_plan == 1,
                      handle: ".handle",
                      "item-key": "id",
                    },
                    on: {
                      start: _vm.startSort,
                      end: _vm.endSort,
                      change: function ($event) {
                        return _vm.enterNewDropZone($event, key)
                      },
                    },
                  },
                  [
                    _vm._l(val, function (item) {
                      return _c(
                        "tr",
                        {
                          staticClass: "init-row",
                          class: item.priority.toLowerCase(),
                          attrs: { "data-id": item.id },
                        },
                        [
                          _vm.plan.is_published_plan == 1
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    scope: "row",
                                    colspan: key == "Required" ? 2 : 1,
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              )
                            : item.url.length == 1
                            ? _c(
                                "td",
                                {
                                  staticClass: "handle",
                                  attrs: {
                                    scope: "row",
                                    colspan: key == "Required" ? 2 : 1,
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/planning/" +
                                          _vm.plan.url +
                                          "/get_initiative/" +
                                          item.url[0],
                                      },
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                ]
                              )
                            : _c(
                                "td",
                                {
                                  staticClass: "handle",
                                  attrs: {
                                    scope: "row",
                                    colspan: key == "Required" ? 2 : 1,
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/planning/" +
                                          _vm.plan.url +
                                          "/get_initiative_type/" +
                                          item.url[0],
                                      },
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          key != "Required"
                            ? _c("td", { staticClass: "priority" }, [
                                _vm._v(_vm._s(item.priority)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("td", { staticClass: "cost-type" }, [
                            _c("span", [_vm._v("Project")]),
                            _vm.hasMedia(item.media)
                              ? _c("span", [_vm._v("Media")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.calendar_headers, function (months, year) {
                            return _vm._l(months, function (month) {
                              return _c(
                                "td",
                                {
                                  staticClass: "cost-type",
                                  class: _vm.initiativeClasses(
                                    item,
                                    month,
                                    year
                                  ),
                                  attrs: {
                                    scope: "col",
                                    "data-month": month,
                                    "data-year": year,
                                    "data-id":
                                      _vm.initiativeIndex(item, month, year) !==
                                      false
                                        ? item.id[
                                            _vm.initiativeIndex(
                                              item,
                                              month,
                                              year
                                            )
                                          ]
                                        : "",
                                  },
                                  on: {
                                    mousedown: _vm.dragStart,
                                    mouseenter: _vm.mouseDraggedOrExpanded,
                                    mouseup: _vm.mouseDraggedOrExpandedStop,
                                  },
                                },
                                [
                                  _vm.initiativeIndex(item, month, year) !==
                                  false
                                    ? [
                                        _vm.isFirstMonthForInitiative(
                                          item,
                                          month,
                                          year
                                        )
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatShortMoney(
                                                    item.project[
                                                      _vm.initiativeIndex(
                                                        item,
                                                        month,
                                                        year
                                                      )
                                                    ] +
                                                      item.retainer[
                                                        _vm.initiativeIndex(
                                                          item,
                                                          month,
                                                          year
                                                        )
                                                      ]
                                                  )
                                                )
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatShortMoney(
                                                    item.retainer[
                                                      _vm.initiativeIndex(
                                                        item,
                                                        month,
                                                        year
                                                      )
                                                    ]
                                                  )
                                                )
                                              ),
                                            ]),
                                        _vm._v(" "),
                                        _vm.hasMedia(item.media)
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatShortMoney(
                                                    item.media[
                                                      _vm.initiativeIndex(
                                                        item,
                                                        month,
                                                        year
                                                      )
                                                    ]
                                                  )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isLastMonthForInitiative(
                                          item,
                                          month,
                                          year
                                        ) &&
                                        !_vm.isOneTimeInitiative(item) &&
                                        _vm.plan.is_published_plan != 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "stretch",
                                                on: {
                                                  mousedown: _vm.stretchStart,
                                                },
                                              },
                                              [_c("span")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.plan.is_published_plan != 1 &&
                                        _vm.isFirstMonthForInitiative(
                                          item,
                                          month,
                                          year
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "tiny_button copy",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.copyInitiative(
                                                      item,
                                                      month,
                                                      year
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-copy",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.plan.is_published_plan != 1 &&
                                        _vm.isFirstMonthForInitiative(
                                          item,
                                          month,
                                          year
                                        )
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "tiny_button delete",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.deleteInitiative(
                                                      item,
                                                      month,
                                                      year
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-trash",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.plan.is_published_plan != 1 &&
                                        _vm.isFirstMonthForInitiative(
                                          item,
                                          month,
                                          year
                                        )
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "tiny_button edit",
                                                attrs: {
                                                  href:
                                                    "/planning/" +
                                                    _vm.plan.url +
                                                    "/get_initiative/" +
                                                    item.url[
                                                      _vm.initiativeIndex(
                                                        item,
                                                        month,
                                                        year
                                                      )
                                                    ],
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-edit",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            })
                          }),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    !val.length && _vm.show_zones
                      ? _c("tr", { staticClass: "drop" }, [
                          _c("td", { attrs: { colspan: "16" } }, [
                            _c("span", [_vm._v(_vm._s(key))]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            }),
            _vm._v(" "),
            _c("tfoot", [
              _c(
                "tr",
                [
                  _c("th", { staticClass: "bare", attrs: { rowspan: "2" } }),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "vertical-align": "middle",
                      },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v("Totals")]
                  ),
                  _vm._v(" "),
                  _c("th", [_vm._v("Project")]),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return _vm._l(months, function (month) {
                      return _c(
                        "td",
                        { class: { disabled: _vm.isInThePast(month, year) } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatShortMoney(
                                _vm.getTotalByDate(month, year, "project") +
                                  _vm.getTotalByDate(month, year, "retainer")
                              )
                            )
                          ),
                        ]
                      )
                    })
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "tr",
                [
                  _c("th", { staticClass: "media-total" }, [_vm._v("Media")]),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return _vm._l(months, function (month) {
                      return _c(
                        "td",
                        {
                          class: {
                            disabled: _vm.isInThePast(month, year),
                            "media-total": 1,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatShortMoney(
                                _vm.getTotalByDate(month, year, "media")
                              )
                            )
                          ),
                        ]
                      )
                    })
                  }),
                ],
                2
              ),
            ]),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }