var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "campaign-type-editor" }, [
    _c("div", { staticClass: "campaign-type-overview pb-5" }, [
      _c("h2", [
        _vm._v(
          _vm._s(_vm.local_campaign_type.id ? "Update" : "Add") +
            " Campaign Type"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content mt-3" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-9 col mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_campaign_type.name,
                    expression: "local_campaign_type.name",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.name != "" ? "is-invalid" : "",
                attrs: { type: "text", id: "name-input" },
                domProps: { value: _vm.local_campaign_type.name },
                on: {
                  change: function ($event) {
                    return _vm.clearErrorForField("name")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_campaign_type,
                      "name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.name != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.errors.name) +
                        "\n                            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 col-sm-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Strategy")]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn form-select btn-field",
                    class: _vm.errors.strategy != "" ? "is-invalid" : "",
                    attrs: {
                      type: "button",
                      id: "dropdownMenuButton",
                      "data-bs-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                      "data-bs-auto-close": "outside",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clearErrorForField("strategy")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                                " +
                        _vm._s(
                          _vm.local_campaign_type.strategy != null &&
                            _vm.local_campaign_type.strategy.length > 0
                            ? _vm.local_campaign_type.strategy.join(", ")
                            : "Please Select"
                        ) +
                        "\n                              "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    attrs: { "aria-labelledby": "dropdownMenuButton" },
                  },
                  _vm._l(_vm.strategy_types, function (item) {
                    return _c(
                      "label",
                      { key: item, staticClass: "dropdown-item" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_campaign_type.strategy,
                              expression: "local_campaign_type.strategy",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", "true-value": [] },
                          domProps: {
                            value: item,
                            checked: Array.isArray(
                              _vm.local_campaign_type.strategy
                            )
                              ? _vm._i(_vm.local_campaign_type.strategy, item) >
                                -1
                              : _vm._q(_vm.local_campaign_type.strategy, []),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.local_campaign_type.strategy,
                                $$el = $event.target,
                                $$c = $$el.checked ? [] : false
                              if (Array.isArray($$a)) {
                                var $$v = item,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.local_campaign_type,
                                      "strategy",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.local_campaign_type,
                                      "strategy",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.local_campaign_type,
                                  "strategy",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ms-2" }, [
                          _vm._v(
                            "\n                                      " +
                              _vm._s(item) +
                              "\n                                    "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.errors.strategy != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.errors.strategy) +
                        "\n                            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Description"),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_campaign_type.description,
                    expression: "local_campaign_type.description",
                  },
                ],
                staticClass: "form-control full-height",
                class: _vm.errors.description != "" ? "is-invalid" : "",
                attrs: { id: "report-description" },
                domProps: { value: _vm.local_campaign_type.description },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_campaign_type,
                        "description",
                        $event.target.value
                      )
                    },
                    function ($event) {
                      return _vm.resizeTextarea()
                    },
                  ],
                  change: function ($event) {
                    return _vm.clearErrorForField("description")
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.description != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.errors.description) +
                        "\n                            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(
                    "Prompts               \n                                "
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-white ms-2 py-0",
                      attrs: { type: "submit", disabled: _vm.form.busy },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addPrompt()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-btn fa-plus me-0" })]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.local_campaign_type.prompts,
                  function (prompt, index) {
                    return _c("div", { key: index, staticClass: "mb-3" }, [
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-1 text-center mt-1" }, [
                          _c("h5", [_vm._v(_vm._s(index + 1) + ".")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _c("label", { staticClass: "form-label mb-0" }, [
                            _vm._v("Title:"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: prompt.title,
                                expression: "prompt.title",
                              },
                            ],
                            staticClass: "form-control mb-1",
                            class:
                              _vm.errors.prompts[index] &&
                              _vm.errors.prompts[index].title
                                ? "is-invalid"
                                : "",
                            attrs: { type: "text", id: "title-input" },
                            domProps: { value: prompt.title },
                            on: {
                              change: function ($event) {
                                return _vm.clearPromptError(index, "title")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(prompt, "title", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.prompts[index] &&
                          _vm.errors.prompts[index].title
                            ? _c(
                                "div",
                                { staticClass: "text-danger invalid-feedback" },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(_vm.errors.prompts[index].title) +
                                      "\n                                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("label", { staticClass: "form-label mb-0" }, [
                            _vm._v("Question:"),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: prompt.question,
                                expression: "prompt.question",
                              },
                            ],
                            staticClass: "form-control",
                            class:
                              _vm.errors.prompts[index] &&
                              _vm.errors.prompts[index].question
                                ? "is-invalid"
                                : "",
                            attrs: { type: "text", id: "question-input" },
                            domProps: { value: prompt.question },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    prompt,
                                    "question",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.resizeTextarea()
                                },
                              ],
                              change: function ($event) {
                                return _vm.clearPromptError(index, "question")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.prompts[index] &&
                          _vm.errors.prompts[index].question
                            ? _c(
                                "div",
                                { staticClass: "text-danger invalid-feedback" },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.errors.prompts[index].question
                                      ) +
                                      "\n                                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.local_campaign_type.prompts.length > 1
                          ? _c("div", { staticClass: "col-1" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none ms-2",
                                  attrs: {
                                    type: "submit",
                                    disabled: _vm.form.busy,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removePrompt(index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-close me-0" })]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  }
                ),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h5", { staticClass: "mt-4" }, [
          _vm._v("Asset Types\n                    "),
          _c(
            "button",
            {
              staticClass: "btn btn-white ms-2",
              attrs: {
                type: "submit",
                id: "add-asset-type-button",
                "data-bs-toggle": "modal",
                "data-bs-target": "#asset-type-modal",
                disabled: _vm.form.busy,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addAssetType()
                },
              },
            },
            [_c("i", { staticClass: "fa fa-btn fa-plus me-0" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "float-end" }, [
            _c(
              "button",
              {
                staticClass: "btn me-2",
                class:
                  _vm.currentView == "table" ? "btn-primary" : "btn-secondary",
                on: {
                  click: function ($event) {
                    _vm.currentView = "table"
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-table me-0" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.currentView == "calendar"
                    ? "btn-primary"
                    : "btn-secondary",
                on: {
                  click: function ($event) {
                    _vm.currentView = "calendar"
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-calendar me-0" })]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.local_campaign_type.asset_types &&
        _vm.local_campaign_type.asset_types.length === 0
          ? _c("div", [
              _c("div", { staticClass: "card box-content mb-3" }, [
                _c("div", { staticClass: "card-body mt-3 text-center" }, [
                  _c("h3", { staticClass: "text-center" }, [
                    _vm._v("No asset types have been added yet."),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: {
                        type: "submit",
                        id: "add-asset-type-button",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#asset-type-modal",
                        disabled: _vm.form.busy,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addAssetType()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-plus" }),
                      _vm._v(" Add an Asset Type\n\t\t\t\t\t\t\t"),
                    ]
                  ),
                ]),
              ]),
            ])
          : _c("div", [
              _vm.currentView === "table"
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "card box-content mb-3",
                        class:
                          _vm.errors.has_primary_asset_type != ""
                            ? "border-danger border"
                            : "",
                      },
                      [
                        _c("div", { staticClass: "card-body p-0" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("div", { staticClass: "table-responsive mb-2" }, [
                            _c("table", { staticClass: "table gy-0 m-0" }, [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                { staticClass: "table-border-bottom-0" },
                                _vm._l(
                                  _vm.local_campaign_type.asset_types,
                                  function (asset_type, index) {
                                    return _c("tr", [
                                      _c(
                                        "td",
                                        { staticClass: "align-middle ps-3" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(asset_type.pivot.name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-middle text-center d-none d-sm-table-cell",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(asset_type.name)),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-middle text-center d-none d-sm-table-cell",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                asset_type.pivot.is_primary
                                                  ? "Yes"
                                                  : "No"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-middle text-center d-none d-sm-table-cell",
                                        },
                                        [
                                          asset_type.has_launch_date
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    asset_type.pivot
                                                      .days_before_end_date
                                                  )
                                                ),
                                              ])
                                            : _c("span", [_vm._v("--")]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-middle text-center d-none d-sm-table-cell text-end",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-secondary btn-sm ms-1 mb-1",
                                              attrs: {
                                                "data-bs-toggle": "modal",
                                                "data-bs-target":
                                                  "#asset-type-modal",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.editAssetType(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-solid fa-edit me-0",
                                                attrs: {
                                                  title: "Edit Asset Type",
                                                  "data-bs-toggle": "tooltip",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary btn-sm ms-1 mb-1",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteAssetType(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-solid fa-trash me-0",
                                                attrs: {
                                                  title: "Delete Asset Type",
                                                  "data-bs-toggle": "tooltip",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.errors.has_primary_asset_type != ""
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "text-danger invalid-feedback m-3",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.errors.has_primary_asset_type
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ])
                : _vm.currentView === "calendar"
                ? _c("div", [
                    _c("div", { staticClass: "card box-content mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("calendar-view", {
                            attrs: {
                              assetTypes: _vm.local_campaign_type.asset_types,
                            },
                            on: { "update-days": _vm.updateDaysFromEnd },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
      ]),
      _vm._v(" "),
      _vm.local_campaign_type.asset_types.length > 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 navbar navbar-expand" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ms-2",
                  attrs: {
                    type: "submit",
                    id: "save-button",
                    disabled: _vm.form.busy,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.update.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-save" }),
                  _vm._v(" Save \n                    "),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "asset-type-modal",
          "aria-labelledby": "asset-type-modal",
          "data-bs-backdrop": "static",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal("asset-type-modal")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(_vm._s(_vm.modal_action) + " Asset Type"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body pt-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Asset Type"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.modal_asset_type.asset_type_id,
                          expression: "modal_asset_type.asset_type_id",
                        },
                      ],
                      staticClass: "form-select",
                      class:
                        _vm.modal_errors.asset_type_id != ""
                          ? "is-invalid"
                          : "",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.modal_asset_type,
                              "asset_type_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.changeAssetType()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.asset_types, function (a_t, index) {
                      return _c("option", { domProps: { value: a_t.id } }, [
                        _vm._v(_vm._s(a_t.name)),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.modal_errors.asset_type_id != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.modal_errors.asset_type_id) +
                              "\n                                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal_asset_type.name,
                        expression: "modal_asset_type.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.modal_errors.name != "" ? "is-invalid" : "",
                    attrs: { type: "text", id: "name-input" },
                    domProps: { value: _vm.modal_asset_type.name },
                    on: {
                      change: function ($event) {
                        return _vm.clearErrorForModalField("name")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.modal_asset_type,
                          "name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.modal_errors.name != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.modal_errors.name) +
                              "\n                                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.modal_asset_type.has_launch_date
                  ? _c("div", { staticClass: "col-4 mb-3" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.modal_asset_type.days_before_end_date,
                            expression: "modal_asset_type.days_before_end_date",
                          },
                        ],
                        staticClass: "form-control",
                        class:
                          _vm.modal_errors.days_before_end_date != ""
                            ? "is-invalid"
                            : "",
                        attrs: {
                          type: "number",
                          id: "days-from-end-date-input",
                        },
                        domProps: {
                          value: _vm.modal_asset_type.days_before_end_date,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.clearErrorForModalField(
                              "days_before_end_date"
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.modal_asset_type,
                              "days_before_end_date",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.modal_errors.days_before_end_date != ""
                        ? _c(
                            "div",
                            { staticClass: "text-danger invalid-feedback" },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.modal_errors.days_before_end_date
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Description"),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal_asset_type.description,
                        expression: "modal_asset_type.description",
                      },
                    ],
                    ref: "textarea",
                    staticClass: "form-control full-height",
                    class:
                      _vm.modal_errors.description != "" ? "is-invalid" : "",
                    attrs: { id: "report-description" },
                    domProps: { value: _vm.modal_asset_type.description },
                    on: {
                      change: function ($event) {
                        return _vm.clearErrorForModalField("description")
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.modal_asset_type,
                            "description",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.resizeTextarea()
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _vm.modal_errors.description != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.modal_errors.description) +
                              "\n                                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 mb-3" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.modal_asset_type.is_primary,
                            expression: "modal_asset_type.is_primary",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.modal_asset_type.is_primary
                          )
                            ? _vm._i(_vm.modal_asset_type.is_primary, null) > -1
                            : _vm.modal_asset_type.is_primary,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.modal_asset_type.is_primary,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.modal_asset_type,
                                    "is_primary",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.modal_asset_type,
                                    "is_primary",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.modal_asset_type, "is_primary", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(
                        "\n                                        Primary Asset Type "
                      ),
                      _c("i", {
                        staticClass: "fas fa-info-circle ms-2",
                        attrs: {
                          title:
                            "This should be the asset that all others are based upon. There will only be one.",
                          "data-bs-toggle": "tooltip",
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex flex-row justify-content-center gap-3",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "w-50 btn btn-primary ms-2",
                      attrs: {
                        type: "submit",
                        id: "save-button",
                        disabled: _vm.form.busy,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveAssetType()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                                Save \n                            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "w-50 btn btn-secondary ms-2",
                      attrs: {
                        id: "add-asset-type-button",
                        disabled: _vm.form.busy,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.closeModal("asset-type-modal")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                                Cancel\n                            "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "align-middle text-center pt-0" }, [
          _vm._v("Name"),
        ]),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "align-middle text-center d-none pt-0 d-sm-table-cell",
          },
          [_vm._v("Type")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "align-middle text-center d-none pt-0 d-sm-table-cell",
          },
          [_vm._v("Is Primary Asset Type?")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "align-middle text-center d-none pt-0 d-sm-table-cell",
          },
          [_vm._v("Days Before End Date ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "align-middle text-center d-none pt-0 d-sm-table-cell",
          },
          [_vm._v("Action")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Days Before End Date \n                                    "),
      _c("i", {
        staticClass: "fas fa-info-circle ms-2",
        attrs: {
          title: "How far before the last day should this asset be launched? ",
          "data-bs-toggle": "tooltip",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }