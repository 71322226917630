var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "accordion-multi-select w-100",
      on: { click: _vm.handleClick },
    },
    [
      _c("v-select", {
        staticClass: "searchable-select v-multi-select column-display",
        attrs: {
          options: _vm.local_options,
          searchable: true,
          selectable: (option) =>
            option.text != "" && option.text != "CATEGORY DIVIDER",
          label: "text",
          multiple: "",
          filterable: false,
        },
        on: { search: _vm.fetchOptions, input: _vm.emitInput },
        scopedSlots: _vm._u([
          {
            key: "selected-option",
            fn: function ({ text, category }) {
              return [_c("span", [_vm._v(_vm._s(text))])]
            },
          },
          {
            key: "open-indicator",
            fn: function ({ attributes }) {
              return [
                _c(
                  "span",
                  _vm._b(
                    { staticStyle: { width: "12px", "line-height": "8px" } },
                    "span",
                    attributes,
                    false
                  ),
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 16 16",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "none",
                            stroke: "#343a40",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "m2 5 6 6 6-6",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "option",
            fn: function ({ text, category, extra }) {
              return [
                text == "CATEGORY DIVIDER"
                  ? _c("div", { staticClass: "main-category-header" }, [
                      _vm._v(
                        " \n            " + _vm._s(category) + "\n          "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                category == "metric" ||
                category == "filter" ||
                category == "dimension"
                  ? _c(
                      "div",
                      {
                        staticClass: "suboption show",
                        attrs: { "data-subcategory": category },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(text) + " \n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                text == ""
                  ? _c(
                      "div",
                      {
                        staticClass: "category-header",
                        attrs: {
                          "data-category": category,
                          "data-isexpanded": "false",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.expandCategory(category, null)
                          },
                        },
                      },
                      [
                        _vm._v("\n            " + _vm._s(category) + " "),
                        _c("i", { staticClass: "fa-solid fa-caret-right" }),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "suboption",
                        class: category == null || category == "" ? "show" : "",
                        attrs: { "data-subcategory": category },
                      },
                      [
                        _vm._v("\n            " + _vm._s(text) + " "),
                        extra != null
                          ? _c("span", {
                              domProps: { innerHTML: _vm._s(extra) },
                            })
                          : _vm._e(),
                      ]
                    ),
              ]
            },
          },
          {
            key: "no-options",
            fn: function ({ search, searching, loading }) {
              return [
                _vm.is_loading
                  ? _c("div", { staticClass: "suboption show" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "spinner-border spinner-border-sm text-warning float-left",
                          attrs: { role: "status" },
                        },
                        [
                          _c("span", { staticClass: "visually-hidden" }, [
                            _vm._v("Loading..."),
                          ]),
                        ]
                      ),
                      _vm._v("  Loading columns\n            "),
                    ])
                  : _c("div", { staticClass: "suboption show" }, [
                      _c("em", [_vm._v("No results found")]),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.value_obj,
          callback: function ($$v) {
            _vm.value_obj = $$v
          },
          expression: "value_obj",
        },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field_name },
        domProps: {
          value: _vm.value_obj == null ? [null] : _vm.value_obj.value,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }