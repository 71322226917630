var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "files" },
    [
      _c(
        "div",
        { staticClass: "card file-dropzone box-content mb-3" },
        [
          _c("vue-dropzone", {
            ref: "myVueDropzone",
            staticClass: "dropzone",
            attrs: { id: "dropzone", options: _vm.dropzoneOptions },
            on: {
              "vdropzone-processing": _vm.onProcessing,
              "vdropzone-success": _vm.onSuccess,
              "vdropzone-sending": _vm.onSending,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-slow" } }, [
        _vm.successful_file
          ? _c("div", { staticClass: "alert alert-success mb-3 box-content" }, [
              _vm._v(
                "\n            Your file(s) have been uploaded and processed. Thank you!\n        "
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }