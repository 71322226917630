var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pathways-manage", attrs: { id: "org_settings_clients" } },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary float-end",
              attrs: { href: _vm.addClientUrl() },
            },
            [_vm._m(1)]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card my-3" }, [
        _c("div", { staticClass: "card-header" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row justify-content-between align-items-center gap-3",
            },
            [
              _c("div", { staticClass: "d-flex justify-content-start" }, [
                _c("h5", [
                  _vm._v("Clients (" + _vm._s(_vm.agency.clients.length) + ")"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-end justify-self-end gap-2",
                },
                [
                  _c("div", { staticClass: "position-relative" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search_key,
                          expression: "search_key",
                        },
                      ],
                      staticClass: "search-box form-control me-1",
                      attrs: {
                        type: "text",
                        placeholder: "Search by Name...",
                        "aria-label": "Search by Name...",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.search_key },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.search_key = $event.target.value
                          },
                          function ($event) {
                            return _vm.filterClients()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _vm.search_key === ""
                      ? _c("i", {
                          staticClass: "fa fa-search search-placeholder-icon",
                        })
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "card-body avid-table pathways-manage-segment-table p-0",
          },
          [
            _c(
              "table",
              {
                staticClass:
                  "table table-responsive table-with-pagination pathways-manage-segment-table mb-0",
              },
              [
                _c("thead", [
                  _c("tr", { staticClass: "table-header-row" }, [
                    _c(
                      "th",
                      {
                        staticClass: "table-column-1",
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey("name")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _vm._v(
                            "\n                                Name\n                                "
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "name" &&
                                _vm.sort_order === "asc"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "name" &&
                                _vm.sort_order === "des"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "table-column-3",
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey("url_slug")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _vm._v(
                            "\n                                Plan Level\n                                "
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "plan_level" &&
                                _vm.sort_order === "asc"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "plan_level" &&
                                _vm.sort_order === "des"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "table-column-2",
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey("domain")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _vm._v(
                            "\n                                Domain\n                                "
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "domain" &&
                                _vm.sort_order === "asc"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "domain" &&
                                _vm.sort_order === "des"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "table-column-2",
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey("created_at")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _vm._v(
                            "\n                                Created On\n                                "
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "created_at" &&
                                _vm.sort_order === "asc"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key === "created_at" &&
                                _vm.sort_order === "des"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("th", { staticClass: "table-column-4 text-end" }, [
                      _vm._v("Action"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.clients_shown, function (client, index) {
                    return _c(
                      "tr",
                      { key: client.id, class: `table-row-${index + 1}` },
                      [
                        _c(
                          "td",
                          { staticClass: "align-start table-column-1" },
                          [
                            _c("img", {
                              staticClass: "avatar rounded-circle me-3",
                              attrs: { src: client.image },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              { attrs: { href: _vm.editClientUrl(client.id) } },
                              [_vm._v(_vm._s(client.name))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "align-start table-column-3" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("propercase")(client.plan_level)
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "align-start table-column-2" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(client.domain) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "align-start table-column-2" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm._f("date")(client.created_at)) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "align-start table-column-4 show-on-row-hover text-end",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-none px-1",
                                attrs: {
                                  href: "/landing/" + client.url,
                                  "data-bs-toggle": "tooltip",
                                  "data-bs-placement": "bottom",
                                  title: "View Client Insights",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: "/img/icons/dialexa-icons/eye.svg",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-none px-1",
                                attrs: { title: "Delete Client" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDeleteClient(client)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: "/img/icons/dialexa-icons/trash.svg",
                                    "data-bs-toggle": "tooltip",
                                    "data-bs-placement": "bottom",
                                    title: "Delete Client",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("tfoot", { staticClass: "table-border-bottom-0" }, [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "py-0", attrs: { colspan: "100%" } },
                      [
                        _c("div", { staticClass: "table-pagination" }, [
                          _c(
                            "div",
                            { staticClass: "table-pagination--group" },
                            [
                              _c("span", [_vm._v("Showing: ")]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.num_per_page,
                                      expression: "num_per_page",
                                    },
                                  ],
                                  staticClass: "form-select",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.num_per_page = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        return _vm.reloadPagination()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "5000" } }, [
                                    _vm._v("ALL"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.num_per_page_options,
                                    function (num) {
                                      return _c(
                                        "option",
                                        { key: num, domProps: { value: num } },
                                        [_vm._v(_vm._s(num))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("Clients")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "table-pagination--group" },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.item_interval) +
                                  " of " +
                                  _vm._s(_vm.filtered_clients.length) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "table-pagination--group" },
                            [
                              _vm.page_count > 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: _vm.page_index === 0 },
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveToPrevPage()
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/chevron-left.svg",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index_array.length <= 3
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.page_index_array,
                                      function (page) {
                                        return _c(
                                          "button",
                                          {
                                            key: page,
                                            staticClass: "btn btn-none px-1",
                                            class:
                                              page === _vm.page_index + 1
                                                ? "current-page"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.moveToPage(page)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(page) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm._l(
                                        _vm.page_index_array.slice(0, 2),
                                        function (page) {
                                          return _c(
                                            "button",
                                            {
                                              key: page,
                                              staticClass: "btn btn-none px-1",
                                              class:
                                                page === _vm.page_index + 1
                                                  ? "current-page"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveToPage(page)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(page) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.page_index > 2
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-none px-1",
                                              attrs: { disabled: "" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            ...\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.page_index >= 2 &&
                                      _vm.page_index <
                                        _vm.page_index_array.length - 1
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-none px-1 current-page",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(_vm.page_index + 1) +
                                                  " \n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.page_index <
                                      _vm.page_index_array.length - 2
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-none px-1",
                                              attrs: { disabled: "" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            ...\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          key: _vm.page_index_array.length,
                                          staticClass: "btn btn-none px-1",
                                          class:
                                            _vm.page_index ===
                                            _vm.page_index_array.length - 1
                                              ? "current-page"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.moveToPage(
                                                _vm.page_index_array.length
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.page_index_array.length
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                              _vm._v(" "),
                              _vm.page_count > 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: {
                                        disabled:
                                          _vm.page_index === _vm.page_count - 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveToNextPage()
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/chevron-right.svg",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("h4", [_vm._v("Clients")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Manage your clients by adding, modifying, or removing them."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("\n                    Add Client\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }