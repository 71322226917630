<template>
    <div id="pathways-push-segment-summary" class="my-2">
        <div class="pathways-push-segment-summary__text">
            <div class="d-flex flex-column gap-1 flex-grow-1">
                <div>
                    <h5>Pathway Summary</h5>
                    <p>Below is a summary of the pathway you've identified. Click on the <em>Advanced Settings</em> to map additional columns or to change how the systems are connected.</p>
                </div>
            </div>
            <div>
                <button v-if="if_download_records_finished && allColumnsMapped() && !isFile()" class="btn btn-secondary" @click.prevent="exportSegment()">
                    <img class="icon pe-1" src="/img/icons/dialexa-icons/page-search.svg">Export Records
                </button>
                <button v-else-if="allColumnsMapped() && !isFile()" class="btn btn-secondary" disabled>
                    <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Export Records
                </button>
            </div>
        </div>

        <div class="pathways-push-segment-summary__flow-chart">

            <div class="card summary-card" id="summary-card-segment">
                <div class="card-body">
                    <div>
                        <div class="summary-card-icon-background">
                            <img class="summary-card-icon" src="/img/icons/dialexa-icons/people-outline.svg"> 
                        </div>
                    </div>
                    
                    <div>
                        <p>Audience</p>
                        <h5>{{ segment_selected.name }}</h5>
                        <p>{{ segment_selected.description }}</p>
                    </div>
                </div>
            </div>

            <figure class="d-flex flex-column justify-content-center m-0 fs-2">
                <i class="connector-1"></i>
            </figure>

            <div class="card summary-card" id="summary-info">
                <div class="card-body">
                    <div>
                        <p>Sync Frequency</p>
                        <h5>{{isFile() ? 'One Time' : 'Daily'}}</h5>
                    </div>                 
                    <div>
                        <p>Records</p>
                        <h5 v-if="segment_size != null && segment_size >= 0">{{ segment_size | number_with_zero}}
                            <i class="fas fa-info-circle text-primary ms-2" data-bs-toggle="tooltip" title="This record count is based upon the distinct values of the Primary Key Column selected below. Open 'Advanced Settings' and then 'Column Mapping' to adjust this."></i></h5>
                        <div v-else-if="segment_size == -1"><em>Needs More Info</em> <i class="fas fa-info-circle text-primary ms-2" data-bs-toggle="tooltip" title="You need to select a primary key column below before an estimated number of records can be calculated."></i></div>
                        <div v-else><div class="spinner-border text-primary spinner-border-sm float-left" role="status"></div>  <em>Loading</em></div>
                    </div> 
                </div>
            </div>

            <figure class="d-flex flex-column justify-content-center m-0 fs-2">
                <i class="fa-sharp fa-solid fa-arrow-right m-0"></i>
            </figure>

            <div class="card summary-card" id="summary-card-pathway">
                <div class="card-body">
                    <div>
                        <div class="summary-card-icon-background">
                            <img class="summary-card-icon" src="/img/icons/dialexa-icons/directions.svg">
                        </div>
                    </div>
                    
                    <div>
                        <p>Pathway</p>
                        <h5>{{ isFile() ? 'File Export' : local_pathway_selected.name }}</h5>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="instructions != null">
            <div class="alert alert-danger" role="alert" v-html="instructions"></div>
        </div>

        <div v-if="segment_selected.data_source && census_connection.is_file" class="mt-4">
            <file-column-mapping :client="client" :source="local_census_connection"
                :source_columns="source_fields" :destination="local_pathway_selected"
                v-model="local_census_connection.mappings" @prioritize="updatePrioritization"
                @changeMappingDetails="changeMappingDetails"
                ref="file_mapping"></file-column-mapping>
        </div>

        <div class="pathways-push-segment-summary__advanced-push-settings-toggle my-2" v-else-if="segment_selected.data_source">
            <div class="form-check form-switch d-inline-flex align-items-center gap-2 my-0">
                <input class="form-check-input mt-0" type="checkbox" role="switch" :checked="if_advanced_setting" @change="if_advanced_setting = !if_advanced_setting">
                <span>Advanced Settings</span>
            </div>
        </div>

        <div v-if="if_advanced_setting && segment_selected.data_source && census_connection.is_file == false" id="advanced-settings-section">
            <div class="d-flex flex-column gap-1">
                <h5>Advanced Settings</h5>
                <p>Customize how you want to push this audience to pathway</p>
            </div>

            <transition-group v-if="if_advanced_setting_ready" tag="div" class="d-flex flex-column justify-content-start gap-3">
                <div class="accordion" key="advanced-setting-information">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <div class="accordion-button collapsed" type="button"  
                                :data-bs-target="'#advanced-setting-information'" data-bs-toggle="collapse"
                                aria-expanded="false" :aria-controls="'#advanced-setting-information'" >
                                <h5>Information</h5>
                            </div>                                        
                        </div>
                        <div class="accordion-body accordion-collapse pt-0" id="advanced-setting-information" :class="if_expand_info_section ? '': 'collapse'">
                            <div class="d-flex flex-column gap-2">
                                <!-- https://www.facebook.com/customaudiences/value_based/tos/?act=1474160189570282 -->
                                <p>Before data can be sent to {{ local_pathway_selected.name }}, you first need to align
                                        the columns to those available in this data source and decide how the data should be kept 
                                        in sync on an ongoing basis. 
                                    <!-- <span v-if="this.census_connection.source == 'data_blend'">
                                        If there is information missing from the data source, <a :href="'/integrations/'+client.url+'/mapping/data_set/'+data_set_id +'?step=3'">you can click here to map additional columns to that source</a>.
                                    </span> -->
                                </p>
                                <div class="w-100 d-flex flex-row gap-3">
                                    <div class="w-50">
                                        <label>Destination Object</label>
                                        <select class="form-select" v-model="local_census_connection.destination_object" @change="changeDestination()" :disabled="local_pathway_selected.census_destination_objects == null || local_pathway_selected.census_destination_objects.length==1 || local_census_connection.id > 0"
                                            :class="{'is-invalid' : information_errors.destination_object != ''}">
                                            <option v-for="obj in local_pathway_selected.census_destination_objects" :value="obj.full_name" v-if="obj.data == null">{{obj.label}}</option>
                                        </select>
                                        <div class="text-danger invalid-feedback" v-if="information_errors.destination_object != ''">
                                            {{information_errors.destination_object}}
                                        </div>
                                    </div>
                                    <div v-if="operations.length > 1" class="w-50">
                                        <label>Method of Sync</label>
                                        <select class="form-select" v-model="local_census_connection.operation"
                                            :class="{'is-invalid' : information_errors.operation != ''}" :disabled="local_census_connection.id > 0" >
                                            <option v-for="ops in operations" :value="ops" >{{getOperationExplanation(ops)}}</option>
                                        </select>
                                        <div class="text-danger invalid-feedback" v-if="information_errors.operation != ''">
                                            {{information_errors.operation}}
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>
                </div>
        
                <div class="accordion" key="advanced-setting-primary-key" v-if="local_census_connection.destination_object || local_pathway_selected.census_destination_objects.length == 1">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <div class="accordion-button collapsed" type="button"
                                :data-bs-target="'#advanced-setting-primary-key'" data-bs-toggle="collapse"
                                aria-expanded="false" :aria-controls="'#advanced-setting-primary-key'" >
                                <h5>Column Mapping</h5>
                            </div>                                        
                        </div>
                        <div class="accordion-body accordion-collapse pt-0" id="advanced-setting-primary-key"  :class="if_expand_column_mapping_section ? '': 'collapse'">
                            <div class="d-flex flex-column gap-3" v-if="local_census_connection.destination_object != null">

                                <pathways-column-mapping :client="client" :source="local_census_connection"
                                    :source_columns="source_fields" :destination="local_pathway_selected"
                                    :dest_columns="destination_fields" v-model="local_census_connection.mappings"
                                    @refresh="refreshColumns()" @prioritize="updatePrioritization"
                                    @changeMappingDetails="changeMappingDetails" :show_unmapped_only="show_unmapped_only"
                                    ref="column_mapping"></pathways-column-mapping>

                                <!-- Buttons -->
                                <div class="my-2 d-flex flex-row gap-2">
                                    <button class="btn btn-secondary" v-on:click="addColumnMapping()" v-if="!show_unmapped_only" ><i class="fas fa-plus"></i> Add Column Mapping</button>
                                    <button class="btn btn-secondary" v-else @click.prevent="show_unmapped_only=false">Show All Mapped Columns</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
            
            <div v-else>
                <p>                        
                    <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>
                    Loading Advanced Settings Options...
                </p>
            </div>
        </div>

    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },
        props: ['segment_selected', 'pathway_selected', 'client', 'census_connection', 'information_errors'],
        data() {
            return {    
                if_advanced_setting: false,
                if_advanced_setting_ready: false,
                local_census_connection: {},
                all_column_mappings:[],
                local_pathway_selected: {},
                source_fields: null,
                operations: [],
                destination_fields: [],
                if_download_records_finished: true,
                show_unmapped_only: false,
                if_expand_info_section: true,
                if_expand_column_mapping_section: true,
                primary_identifier: null,
                instructions: null,
                segment_size: null
            };
        },
        beforeMount() { 
            this.local_census_connection = this.census_connection;
            this.local_pathway_selected = this.pathway_selected;
            this.segment_size = this.getSize(this.segment_selected.sizes);
            this.loadConnectionDetails();
        },
        watch: {
            pathway_selected() {
                this.local_pathway_selected = this.pathway_selected;
                this.if_advanced_setting = false;
                this.if_advanced_setting_ready = false;
                // this.$forceUpdate();
            }
        },
        mounted() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            isFile() {
                return this.local_pathway_selected.integration_id == "71b0c3b0-8390-49ec-9984-1c04b7384d38";
            },
            getSize(sizes) {
                //If it hasn't been sent, just show 0
                if(!sizes || this.local_census_connection == null)
                    return null;

                let census_conn_id = this.local_census_connection.id;

                //Get one that is specific to this census connection
                for (var i = 0; i < sizes.length; i++){
                    //Get the default size if it matches or is a data_set
                    if (sizes[i].id == census_conn_id || (census_conn_id == null && sizes[i].id == 0 && this.local_census_connection.source == "data_set"))
                        return sizes[i].size;
                }

                return null;
            },
            exportSegment() {
                this.if_download_records_finished = false;

                var form_data = {
                    segment_id: this.segment_selected.id,
                    census_connection: this.local_census_connection
                }

                //Call the webhook to save the new sync
                window.axios.post('/api/census/export', form_data)
                    .then(response => {
                        //Make a link and then "click" on it
                        var link = document.createElement("a");
                        link.href = response.data.url;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.if_download_records_finished = true;
                        this.$forceUpdate();
                    });
            },
            loadConnectionDetails(){

                var url;
                if (this.segment_selected.data_source){
                    url = '/api/pathway_mapping/' + this.client.url + '/' + this.local_pathway_selected.id + '/' + this.segment_selected.data_source.data_source + '/' + this.segment_selected.data_source_id + '/' + this.segment_selected.id;
                } else if(this.segment_selected.datablend) {
                    url = '/api/pathway_mapping/' + this.client.url + '/' + this.local_pathway_selected.id + '/data_blend/' + this.segment_selected.data_source_id+ '/' + this.segment_selected.id;
                }
                else {
                    url = '/api/pathway_mapping/' + this.client.url + '/' + this.local_pathway_selected.id + '/data_set/' + this.segment_selected.data_source_id+ '/' + this.segment_selected.id;
                }
                
                window.axios.get(url).then(response => {
                    this.if_advanced_setting_ready = true;
                    this.local_census_connection = response.data.census_connection;
                    this.all_column_mappings = response.data.census_connection.mappings;
                    this.local_pathway_selected = response.data.destination;
                    this.source_fields = response.data.source_fields;

                    this.segment_size = this.getSize(this.segment_selected.sizes);
                    this.getInstructions();

                    //If there isn't a segment size already stored, look it up
                    if(this.segment_size == null)
                        this.getSegmentSize();
                    else if(this.local_census_connection.mappings != null) { //If there is, save primary ID so I can reference it later
                        let pi = this.local_census_connection.mappings.find(mapping => mapping.is_primary_identifier == 1);

                        //If pi is blank...
                        if(pi == null) {
                            //Insert a blank mapping into the top so it will be the primary identifier
                            this.local_census_connection.mappings.unshift({
                                id: -1 * Math.random(),
                                client_id: this.client.id,
                                census_connection_id: this.local_census_connection.id,
                                source_column: this.getBlankSourceColumn(),
                                destination_column: null,
                                is_primary_identifier: true,
                            });
                        }
                        //There is a primary identifier
                        else {
                            if(!Array.isArray(pi.source_column))
                                pi.source_column = [pi.source_column];
                            //Make a copy of pi
                            this.primary_identifier = JSON.parse(JSON.stringify(pi));
                        }
                    }
                    this.$emit('updateCensusConnection', this.local_census_connection);

                    if(!this.local_census_connection.is_file)
                        this.setUpFields();
                });
            },
            getInstructions() {

                window.axios.get('/api/pathways/'+ this.local_census_connection.connection_id+'/get_instructions').then(response => {
                    this.instructions = response.data.instructions;
                });

            },
            setUpFields(){
                //Set the column options if the destination has been saved already
                if(this.local_census_connection.destination_object != null){
                    for(var i = 0; i < this.local_pathway_selected.census_destination_objects.length; i++) {
                        var obj = this.local_pathway_selected.census_destination_objects[i];
                        if(obj.full_name == this.local_census_connection.destination_object){
                            this.operations = obj.supported_operations;
                            this.destination_fields = obj.fields;
                            break;
                        }
                    }
                }
                //If there is only one option
                else if(this.local_pathway_selected.census_destination_objects.length == 1){
                    this.local_census_connection.destination_object = this.local_pathway_selected.census_destination_objects[0].full_name;
                    this.destination_fields = this.local_pathway_selected.census_destination_objects[0].fields;
                    this.operations = this.local_pathway_selected.census_destination_objects[0].supported_operations;
                    this.changeDestination();
                }

                this.if_expand_info_section = !this.local_census_connection.destination_object && this.local_pathway_selected.census_destination_objects.length > 1
                
                this.checkIfShowAllMappings();
                if(this.local_pathway_selected.census_destination_objects == null || this.local_pathway_selected.census_destination_objects.length == 0) {
                    this.getDestinationObjects();
                }
            },
            getOperationExplanation(ops) {
                if(ops == 'mirror')
                    return "Mirror - Records will added, updated, and removed to keep the sources in full sync"
                if(ops == 'append')
                    return 'Append - New records will be added to the destination but existing will not be updated or removed';
                if(ops == 'upsert')
                    return "Update or Create - Update existing destination records when IDs match, otherwise create new ones if they're missing"
                if(ops == 'update')
                    return "Update Only - Matching records will be updated but nothing will be added or removed"
                if(ops == 'delete')
                    return "Delete - Delete syncs takes the list of provided IDs and deletes them from the destination. Be careful! Providing an incorrect set of IDs can result in data loss!"
                if(ops == 'create' || ops == 'insert')
                    return "Create Only - Create a new object if it doesn't exist in the destination"
            },
            changeDestination() {
                for(var i = 0; i < this.local_pathway_selected.census_destination_objects.length; i++) {
                    var obj = this.local_pathway_selected.census_destination_objects[i];
                    if(obj.full_name == this.local_census_connection.destination_object){
                        this.operations = obj.supported_operations;
                        this.destination_fields = obj.fields;
                        break;
                    }
                }

                //Now update the operation on the local census connection
                if(this.operations == null){
                    this.local_census_connection.operation = null;
                    this.operations = [];
                    this.destination_fields = [];
                }

                //Set the operation to the first one if it isn't already set
                else if(this.local_census_connection.operation == null){
                    this.local_census_connection.operation = this.operations[0];
                }
                //If this.local_census_connection.operation is set and not in the operation array
                else if(this.local_census_connection.operation != null && !this.operations.includes(this.local_census_connection.operation)) {
                    this.local_census_connection.operation = this.operations[0];
                }

                //Update the pathway mapping vue to remove columns that don't exist once they select a destination
                if(this.local_census_connection.destination_object != null && this.local_census_connection.mappings != null) {
                    for(var i = this.local_census_connection.mappings.length-1; i >= 0; i--) {
                        let found = false;
                        for(var j = 0; j < this.destination_fields.length; j++) {
                        //If the [j].full_name is not in any of the destination columns
                            if(this.destination_fields[j].full_name == this.local_census_connection.mappings[i].destination_column) {
                                found = true;
                                break;
                            }
                        }
                        //If I didn't find the mapping, remove it
                        if(!found)
                            this.local_census_connection.mappings.splice(i, 1);
                    }
                }

                if(this.$refs.column_mapping != null)
                    this.$refs.column_mapping.resetMapping();
                
                this.$emit('updateCensusConnection', this.local_census_connection);
            },
            updatePrioritization(priority) {
                this.local_census_connection.prioritization_column = priority;
                this.$emit('updateCensusConnection', this.local_census_connection);
            },
            arraysAreEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) return false;
                return arr1.every((obj, index) => {
                    return JSON.stringify(obj) === JSON.stringify(arr2[index]);
                });
            },
            changeMappingDetails(mappings) {
                //Find the mapping that was the is_primary identifier and see if it has changed from the previous verison.
                var new_primary_identifier = mappings.find(mapping => mapping.is_primary_identifier == 1);

                if(!Array.isArray(new_primary_identifier.source_column)){
                    new_primary_identifier.source_column = [new_primary_identifier.source_column];
                }
                //This is a stupid way to do this but the inside if was getting too complex
                if(new_primary_identifier.source_column[0].value != null){
                    //If the array wasn't set but now is or
                    if(
                        (new_primary_identifier != null && this.primary_identifier == null)
                        || (new_primary_identifier && this.primary_identifier
                        && !this.arraysAreEqual(new_primary_identifier.source_column,
                            this.primary_identifier.source_column))) {
                        //The primary changed so I need to go find the new record count
                        this.getSegmentSize();
                    }
                }

                this.local_census_connection.mappings = mappings;
                this.$emit('updateCensusConnection', this.local_census_connection);
            },
            getSegmentSize() {

                if(this.local_census_connection == null || this.local_census_connection.mappings == null){
                    this.segment_size = -1;
                    return;
                }
                let pi = this.local_census_connection.mappings.find(mapping => mapping.is_primary_identifier == 1);
                this.segment_size = null;

                if(!Array.isArray(pi.source_column))
                    pi.source_column = [pi.source_column];

                //Make a copy of pi
                this.primary_identifier = JSON.parse(JSON.stringify(pi));

                let form_data = {
                    mapping: this.primary_identifier,
                    census_connection: this.local_census_connection,
                    segment_id: this.segment_selected.id
                }
                window.axios.post('/api/segment/' + this.client.url + "/size", form_data)
                  .then(response => {
                        //Now I need to set a timeout to check the destination to see when it changes
                        this.segment_size = response.data.size;
                });
            },
            getDestinationObjects() {
                var form_data = {
                    client_id: this.client.id,
                    connection_id: this.local_pathway_selected.id,
                    refresh_key: this.local_pathway_selected.refresh_key
                }
                window.axios.post('/api/census/get_destination_objects', form_data)
                  .then(response => {
                    //Now I need to set a timeout to check the destination to see when it changes
                    if(!response.data.success)
                        setTimeout(this.getDestinationObjects, 500);
                    else{
                        this.local_pathway_selected.census_destination_objects = response.data.objects;
                        this.if_expand_info_section = !this.local_census_connection.destination_object && this.local_pathway_selected.census_destination_objects.length > 1
                        this.$forceUpdate();
                    }
                  });
            },
            refreshColumns() {
                var form_data = {
                    client_id: this.client.id,
                    connection_id: this.local_pathway_selected.id,
                    destination_object: this.local_census_connection.destination_object
                }
                window.axios.post('/api/census/refresh_destination', form_data)
                  .then(response => {
                    //Now I need to set a timeout to check the destination to see when it changes
                    Swal.fire({
                        title: "Processing...",
                        html: "The refresh process has begun. Once it has finished, the columns options below will be updated.",
                        iconColor:  "#46705F",
                        confirmButtonColor: "#46705F"
                    }).then((result) => {

                        //Now I need to set a timeout to check the destination to see when it changes
                        setTimeout(function(scope) {
                            window.axios.get('/api/connection/' + scope.local_pathway_selected.id).then(response => {
                                if(response.data.connection != scope.local_pathway_selected){
                                    scope.local_pathway_selected = response.data.connection;
                                    for(var i = 0; i < scope.local_pathway_selected.census_destination_objects.length; i++) {
                                        var obj = scope.local_pathway_selected.census_destination_objects[i];
                                        if(obj.full_name == scope.local_census_connection.destination_object){
                                            scope.destination_fields = obj.fields;
                                            break;
                                        }
                                    }
                                }
                            });
                        }, 5000, this);
                    });
                  });
            },
            allColumnsMapped() {
                //No template mappings, show the advanced section
                if(this.local_census_connection.mappings == null || this.local_census_connection.mappings.length == 0){
                    return false;
                }

                //I
                var unmappedFields = this.local_census_connection.mappings.filter((mapping, index) =>
                    //If the first one isn't a primary identifier
                     (index == 0 && mapping.is_primary_identifier != 1) ||
                     //If the first one is an array but the first value isn't set
                     (index == 0 && Array.isArray(mapping.source_column) && mapping.source_column.length > 0 && (mapping.source_column[0].value == null || mapping.source_column[0].value == "")) ||
                     //If it is a normal object but the value hasn't been set
                     (mapping.source_column && !Array.isArray(mapping.source_column) && (mapping.source_column.value == null || mapping.source_column.value == ""))
                );
                if (unmappedFields.length > 0){
                    return false
                }
                return true;
            },
            checkIfShowAllMappings(){

                if (!this.allColumnsMapped()){
                    this.if_advanced_setting = true;
                    this.if_expand_column_mapping_section = true;
                    this.show_unmapped_only = true;
                } else {
                    this.if_expand_column_mapping_section = false;
                    this.show_unmapped_only = false;
                }
            },
            addColumnMapping(){
                this.local_census_connection.mappings.push({
                    id: -1 * Math.random(),
                    client_id: this.client.id,
                    census_connection_id: this.local_census_connection.id,
                    source_column: this.getBlankSourceColumn(),
                    destination_column: null,
                    is_primary_identifier: false,
                });
                this.$emit('updateCensusConnection', this.local_census_connection);
            },
            getBlankSourceColumn() {
                return {
                    main_category: 'Columns',
                    category: null,
                    type: 'string',
                    value: null,
                    text: ""
                };
            },
        }
    }
</script>
