<template>
    <div class="campaign-type-list">
       <div v-if="campaign_types.length > 0" class="mb-5">
			<div class="row">
				<div class="col">
					<h2 class="d-inline">Campaign Type List</h2>
					<a class="btn btn-secondary mb-2 float-end" href="/campaigns/campaign_type/new">
						<i class="fa fas fa-fw fa-btn fa-plus"></i> Add Campaign Type
					</a>
				</div>
			</div>
			<div class="card box-content mb-3">
				<div class="card-body p-0">
					<div class="overflow-hidden rounded-top">
						<div class="p-1 bg-dark"></div>
					</div>
					<div class="table-responsive mb-2">
						<table class="table gy-0 m-0">
							<thead class="table-dark">
								<tr>
								<th class='align-middle text-center pt-0'>Name</th>
								<th class="align-middle text-center d-none pt-0 d-sm-table-cell">Asset Type</th>
								<th class="align-middle text-center d-none pt-0 d-sm-table-cell"># of Asset Types</th>
								<th class="align-middle text-center d-none pt-0 d-sm-table-cell">Updated At</th>
								<!-- <th class="align-middle text-center d-none pt-0 d-sm-table-cell text-end">Actions</th> -->
								</tr>
							</thead>
							<tbody class="table-border-bottom-0">
								<tr v-for="(campaign_type, index) in campaign_types">
									<td class="align-middle">
										<a :href="'/campaigns/campaign_type/'+campaign_type.id">{{ campaign_type.name }}</a>
									</td>
									<td class="align-middle d-none d-sm-table-cell">
										<span v-html="getIcons(campaign_type.asset_types)"></span>
									</td>
									<td class="align-middle text-center d-none d-sm-table-cell">
										<span>{{ campaign_type.asset_types.length }}</span>
									</td>
									<td class="align-middle text-center d-none d-sm-table-cell">
										{{ campaign_type.updated_at | datetime }}
									</td>

									<!-- <td class="align-middle text-center d-none d-sm-table-cell text-end">
										<a class="btn btn-secondary btn-sm ms-1 mb-1" title="View Live Report" data-bs-toggle="tooltip" v-if="page.is_template == false" :href="'/insights/'+local_client.url+'/' + page.url">
											<i class="fa-solid fa-eye me-0" aria-hidden="true"></i>
										</a>
										<button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Report" data-bs-toggle="tooltip" v-on:click="deleteReport(page.id)">
											<i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
										</button>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>       
			</div>
		</div>


		<div v-if="asset_types.length > 0">
			<div class="row">
				<div class="col">
					<h2 class="d-inline">Asset Type List</h2>
					<a class="btn btn-secondary mb-2 float-end" href="/campaigns/asset_type/new">
						<i class="fa fas fa-fw fa-btn fa-plus"></i> Add Asset Type
					</a>
				</div>
			</div>
			<div class="card box-content mb-3">
				<div class="card-body p-0">
					<div class="overflow-hidden rounded-top">
						<div class="p-1 bg-dark"></div>
					</div>
					<div class="table-responsive mb-2">
						<table class="table gy-0 m-0">
							<thead class="table-dark">
								<tr>
								<th class='align-middle text-center pt-0'>Name</th>
								<th class="align-middle text-center d-none pt-0 d-sm-table-cell">Icon</th>
								<th class="align-middle text-center d-none pt-0 d-sm-table-cell"># of Input Fields</th>
								<th class="align-middle text-center d-none pt-0 d-sm-table-cell">Last Updated At</th>
								<!-- <th class="align-middle d-none pt-0 d-sm-table-cell text-end">Actions</th> -->
								</tr>
							</thead>
							<tbody class="table-border-bottom-0">
								<tr v-for="(asset_type, index) in asset_types">
									<td class="align-middle">
										<a :href="'/campaigns/asset_type/' + asset_type.id">{{ asset_type.name }}</a>
									</td>
									<td class="align-middle text-center d-none d-sm-table-cell">
										<i :class="asset_type.icon_class"></i>
									</td>
									<td class="align-middle text-center d-none d-sm-table-cell">
										{{ asset_type.inputs.length }}
									</td>
									<td class="align-middle text-center d-none d-sm-table-cell">
										{{ asset_type.updated_at | datetime }}
									</td>

									<!-- <td class="align-middle d-none d-sm-table-cell text-end">
										<a class="btn btn-secondary btn-sm ms-1 mb-1" title="View Live Report" data-bs-toggle="tooltip" v-if="page.is_template == false" :href="'/insights/'+local_client.url+'/' + page.url">
											<i class="fa-solid fa-eye me-0" aria-hidden="true"></i>
										</a>
										<button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Report" data-bs-toggle="tooltip" v-on:click="deleteReport(page.id)">
											<i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
										</button>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>       
			</div>
		</div>
   </div>
  </template>
  
  <script>
  export default {
    props: {
        campaign_types: {
            type: Array,
            default: [],
        },
		asset_types: {
            type: Array,
            default: [],
        },
    },
    data() {
      return {

      };
    },
    methods: {
      getIcons(asset_types){
          if (asset_types == null || asset_types.length == 0) 
            return '<span>No Asset Attached</span>'
          else {
              var icon_list = this.getIconsByAssetType(asset_types);

              var iconElements = '';
              icon_list.forEach(icon_class => {
                  iconElements = iconElements +'<i class="'+icon_class+' mx-1"></i>'
              })
          }
          return iconElements
      },
      getIconsByAssetType(asset_types){
            var icon_list = [];

            asset_types.forEach(asset_obj => {
                if (!icon_list.includes(asset_obj.icon_class)){ 
                    icon_list.push(asset_obj.icon_class);
                }
            });
            return icon_list;
        },
    },
  };
  </script>
  