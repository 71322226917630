var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "campaign-type-editor" }, [
    _c("div", { staticClass: "asset-type-overview" }, [
      _c("h2", [
        _vm._v(
          _vm._s(_vm.local_asset_type.id ? "Update" : "Add") + " Asset Type"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content mt-3" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_asset_type.name,
                    expression: "local_asset_type.name",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.name != "" ? "is-invalid" : "",
                attrs: { type: "text", id: "name-input" },
                domProps: { value: _vm.local_asset_type.name },
                on: {
                  change: function ($event) {
                    return _vm.clearErrorForField("name")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.local_asset_type, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.name != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.errors.name) +
                        "\n                            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4 mb-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_asset_type.icon_class,
                    expression: "local_asset_type.icon_class",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.icon_class != "" ? "is-invalid" : "",
                attrs: { type: "text", id: "icon-class-input" },
                domProps: { value: _vm.local_asset_type.icon_class },
                on: {
                  change: function ($event) {
                    return _vm.clearErrorForField("icon_class")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_asset_type,
                      "icon_class",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.icon_class != ""
                ? _c("div", {
                    staticClass: "text-danger invalid-feedback",
                    domProps: { innerHTML: _vm._s(_vm.errors.icon_class) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2 mb-3" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_asset_type.has_launch_date,
                      expression: "local_asset_type.has_launch_date",
                    },
                  ],
                  staticClass: "form-select",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_asset_type,
                        "has_launch_date",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("Yes")]),
                ]
              ),
              _vm._v(" "),
              _vm.errors.has_launch_date != ""
                ? _c("div", {
                    staticClass: "text-danger invalid-feedback",
                    domProps: { innerHTML: _vm._s(_vm.errors.has_launch_date) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Description"),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_asset_type.description,
                    expression: "local_asset_type.description",
                  },
                ],
                ref: "textarea",
                staticClass: "form-control full-height",
                class: _vm.errors.description != "" ? "is-invalid" : "",
                attrs: { id: "report-description" },
                domProps: { value: _vm.local_asset_type.description },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_asset_type,
                        "description",
                        $event.target.value
                      )
                    },
                    function ($event) {
                      return _vm.resizeTextarea()
                    },
                  ],
                  change: function ($event) {
                    return _vm.clearErrorForField("description")
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.description != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.errors.description) +
                        "\n                            "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("h5", { staticClass: "mt-4" }, [
            _vm._v("Asset Inputs\n                    "),
            _c(
              "button",
              {
                staticClass: "btn btn-white ms-2",
                attrs: {
                  type: "submit",
                  id: "add-section-button",
                  disabled: _vm.form.busy,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addAssetInput.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-btn fa-plus me-0" })]
            ),
          ]),
          _vm._v(" "),
          _vm.local_asset_type.inputs &&
          _vm.local_asset_type.inputs.length === 0
            ? _c("div", [
                _c("div", { staticClass: "card box-content mb-3" }, [
                  _c("div", { staticClass: "card-body mt-3 text-center" }, [
                    _c("h3", { staticClass: "text-center" }, [
                      _vm._v("No inputs have been added yet."),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: {
                          type: "submit",
                          id: "add-section-button",
                          disabled: _vm.form.busy,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addAssetInput.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-btn fa-plus" }),
                        _vm._v(" Add an Input\n\t\t\t\t\t\t\t"),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "mb-5",
              attrs: { handle: ".handle" },
              model: {
                value: _vm.local_asset_type.inputs,
                callback: function ($$v) {
                  _vm.$set(_vm.local_asset_type, "inputs", $$v)
                },
                expression: "local_asset_type.inputs",
              },
            },
            _vm._l(_vm.local_asset_type.inputs, function (input, index) {
              return _c(
                "div",
                {
                  key: input.id,
                  ref: "assetInput",
                  refInFor: true,
                  staticClass: "mb-3",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card mx-0",
                      class: input.if_active
                        ? "active-section"
                        : " box-content",
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          { staticClass: "accordion accordion-flush" },
                          [
                            _c(
                              "div",
                              { staticClass: "accordion-item border-0" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion-header ms-2 me-5",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.setActiveInputSection(index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-start collapsed p-2 accordion-button section-accordion-button",
                                        attrs: {
                                          type: "button",
                                          "data-bs-target":
                                            "#section-editing-" + index,
                                          "data-bs-toggle": "collapse",
                                          "aria-expanded": "false",
                                          "aria-controls":
                                            "#section-editing-" + index,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resizeTextareaExpanding(
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("h4", { staticClass: "mb-1" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "handle float-start me-2",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "icon",
                                                attrs: {
                                                  src: "/img/icons/dialexa-icons/vertical-dots.svg",
                                                  alt: "drag",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.errors.inputs == null ||
                                          Object.values(
                                            _vm.errors.inputs["i" + input.id]
                                          ).join("") != ""
                                            ? _c("i", {
                                                staticClass:
                                                  "fa-solid fa-circle-exclamation text-danger",
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                                    Input: " +
                                              _vm._s(
                                                input.name
                                                  ? input.name
                                                  : "New Input (unsaved)"
                                              ) +
                                              " \n                                                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "collapse accordion-collapse section-accordion-collapse",
                                    attrs: { id: "section-editing-" + index },
                                  },
                                  [
                                    _c("div", { staticClass: "mx-2" }, [
                                      _c("hr", {
                                        staticClass:
                                          "mx-3 mb-2 section-divider",
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 mb-3" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Name *")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: input.name,
                                                  expression: "input.name",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class:
                                                _vm.errors.inputs[
                                                  "i" + input.id
                                                ].name != ""
                                                  ? "is-invalid"
                                                  : "",
                                              attrs: {
                                                type: "text",
                                                id: "name-input",
                                              },
                                              domProps: { value: input.name },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.clearErrorForInputField(
                                                    "i" + input.id,
                                                    "name"
                                                  )
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    input,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.errors.inputs["i" + input.id]
                                              .name != ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-danger invalid-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                            " +
                                                        _vm._s(
                                                          _vm.errors.inputs[
                                                            "i" + input.id
                                                          ].name
                                                        ) +
                                                        "\n                                                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 mb-3" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Description")]
                                            ),
                                            _vm._v(" "),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: input.description,
                                                  expression:
                                                    "input.description",
                                                },
                                              ],
                                              ref: "textarea",
                                              refInFor: true,
                                              staticClass:
                                                "form-control full-height",
                                              attrs: {
                                                id: "report-description",
                                              },
                                              domProps: {
                                                value: input.description,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      input,
                                                      "description",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.resizeTextarea()
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 mb-3" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Type *")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: input.type,
                                                    expression: "input.type",
                                                  },
                                                ],
                                                staticClass: "form-select",
                                                class:
                                                  _vm.errors.inputs[
                                                    "i" + input.id
                                                  ].type != ""
                                                    ? "is-invalid"
                                                    : "",
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        input,
                                                        "type",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.changeInputType(
                                                        index
                                                      )
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "text" } },
                                                  [_vm._v("Text")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "textarea",
                                                    },
                                                  },
                                                  [_vm._v("Textarea")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "wysiwyg" },
                                                  },
                                                  [_vm._v("WYSIWYG")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "image" } },
                                                  [_vm._v("Image Upload")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "link" } },
                                                  [_vm._v("Link")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "file" } },
                                                  [_vm._v("File Upload")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "dropdown",
                                                    },
                                                  },
                                                  [_vm._v("Dropdown")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.errors.inputs["i" + input.id]
                                              .type != ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-danger invalid-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                            " +
                                                        _vm._s(
                                                          _vm.errors.inputs[
                                                            "i" + input.id
                                                          ].type
                                                        ) +
                                                        "\n                                                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        input.type == "text" ||
                                        input.type == "textarea"
                                          ? _c(
                                              "div",
                                              { staticClass: "col-6 mb-3" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _vm._v(
                                                      "Maximum Number of Characters"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: input.max_length,
                                                      expression:
                                                        "input.max_length",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    id: "name-input",
                                                  },
                                                  domProps: {
                                                    value: input.max_length,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        input,
                                                        "max_length",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        input.type == "dropdown"
                                          ? _c(
                                              "div",
                                              { staticClass: "col-12 mb-3" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _vm._v(
                                                      "Options *                     \n                                                            "
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-white ms-2",
                                                        attrs: {
                                                          type: "submit",
                                                          disabled:
                                                            _vm.form.busy,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.addOption(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-btn fa-plus me-0",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  input.options,
                                                  function (o, o_i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: o_i,
                                                        staticClass:
                                                          "d-flex mb-1",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "me-2 mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(o_i + 1) +
                                                                ". "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                input.options[
                                                                  o_i
                                                                ],
                                                              expression:
                                                                "input.options[o_i]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                            id: "name-input",
                                                          },
                                                          domProps: {
                                                            value:
                                                              input.options[
                                                                o_i
                                                              ],
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                input.options,
                                                                o_i,
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        input.options.length > 1
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-none ms-2",
                                                                attrs: {
                                                                  type: "submit",
                                                                  disabled:
                                                                    _vm.form
                                                                      .busy,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.removeOption(
                                                                        index,
                                                                        o_i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "icon",
                                                                  attrs: {
                                                                    src: "/img/icons/dialexa-icons/trash.svg",
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm.errors.inputs[
                                                  "i" + input.id
                                                ].options != ""
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-danger invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                            " +
                                                            _vm._s(
                                                              _vm.errors.inputs[
                                                                "i" + input.id
                                                              ].options
                                                            ) +
                                                            "\n                                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "mb-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-check" },
                                            [
                                              _c("label", {
                                                staticClass: "form-check-label",
                                              }),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: input.optional,
                                                    expression:
                                                      "input.optional",
                                                  },
                                                ],
                                                staticClass: "form-check-input",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    input.optional
                                                  )
                                                    ? _vm._i(
                                                        input.optional,
                                                        null
                                                      ) > -1
                                                    : input.optional,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = input.optional,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            input,
                                                            "optional",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            input,
                                                            "optional",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        input,
                                                        "optional",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(
                                                "\n                                                            Optional "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-info-circle ms-2",
                                                attrs: {
                                                  title:
                                                    "This means that the input may or may not be generated by the AI",
                                                  "data-bs-toggle": "tooltip",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "asset-accordion-delete btn",
                                attrs: {
                                  type: "button",
                                  "aria-label": "Close",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteAssetInput(index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-times",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.local_asset_type.inputs && _vm.local_asset_type.inputs.length > 0
      ? _c("div", { staticClass: "sticky-footer w-100" }, [
          _c("div", [
            _c("nav", { staticClass: "col-12 navbar navbar-expand" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ms-2",
                  attrs: {
                    type: "submit",
                    id: "save-button",
                    disabled: _vm.form.busy,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.update.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-save" }),
                  _vm._v(" Save \n                    "),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary ms-2",
                  attrs: { id: "add-section-button", disabled: _vm.form.busy },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addAssetInput.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-plus" }),
                  _vm._v(" Add Input Field\n                    "),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _c(
        "a",
        { attrs: { href: "https://fontawesome.com/search", target: "_blank" } },
        [_vm._v("Icon Class")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Has a Launch Date? \n                                "),
      _c("i", {
        staticClass: "fas fa-info-circle ms-2",
        attrs: {
          title:
            "Does this asset have a launch date or does it need to be ready when the campaign launches?",
          "data-bs-toggle": "tooltip",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }