var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org_settings_fiscal_year_donor" } }, [
    _c("div", { staticClass: "row" }, [
      _c("h4", [_vm._v("Fiscal Year & Donor Settings")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 mb-3" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Fiscal Year Start Month"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_client.fiscal_year_offset,
                  expression: "local_client.fiscal_year_offset",
                },
              ],
              staticClass: "form-select",
              attrs: { name: "local_client.fiscal_year_offset" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.local_client,
                    "fiscal_year_offset",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "0" } }, [_vm._v("January")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("February")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("March")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("April")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("May")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("June")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("July")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("August")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("September")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "9" } }, [_vm._v("October")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "10" } }, [_vm._v("November")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "11" } }, [_vm._v("December")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 mb-3" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Donor Level Criteria"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_client.giving_levels.split,
                  expression: "local_client.giving_levels.split",
                },
              ],
              staticClass: "form-select",
              attrs: { name: "local_client.giving_levels.split" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.local_client.giving_levels,
                    "split",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "HAC" } }, [
                _vm._v("Highest Annual Cumulative Giving"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "HPC" } }, [
                _vm._v("Highest Previous Gift"),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 mb-3" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Preferred Reporting Period"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_client.default_reporting_period,
                  expression: "local_client.default_reporting_period",
                },
              ],
              staticClass: "form-select",
              attrs: { name: "local_client.default_reporting_period" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.local_client,
                    "default_reporting_period",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "Calendar Year to Date" } }, [
                _vm._v("Calendar Year to Date"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Fiscal Year to Date" } }, [
                _vm._v("Fiscal Year to Date"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Trailing 12 Months" } }, [
                _vm._v("Trailing 12 Months"),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("Donor Levels")]),
      _vm._v(" "),
      _c("div", { staticClass: "overflow-table" }, [
        _c(
          "table",
          {
            staticClass:
              "table table-responsive border rounded table-with-pagination",
          },
          [
            _c("thead", { staticClass: "table-header-row" }, [
              _c("tr", [
                _c("th", { staticClass: "align-middle" }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", { staticClass: "align-middle" }, [
                  _vm._v("Minimum Threshold"),
                ]),
                _vm._v(" "),
                _vm.local_client.giving_levels.levels.length > 3
                  ? _c("th", { staticClass: "align-middle" }, [
                      _vm._v(
                        "Report on Upgrades? \n                            "
                      ),
                      _c("i", {
                        staticClass: "fas fa-info-circle text-white ms-2",
                        attrs: {
                          "data-bs-toggle": "tooltip",
                          title:
                            "AVID has special reporting set up to monitor donors who upgrade to a higher giving level. However, only two giving levels can be reported on at a time. Select those two levels below.",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "align-middle",
                    staticStyle: { width: "80px" },
                  },
                  [_vm._v("Actions")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(
                _vm.local_client.giving_levels.levels,
                function (level, index) {
                  return _c("tr", [
                    index != _vm.edit_index
                      ? _c("td", { staticClass: "align-middle w-50" }, [
                          _vm._v(_vm._s(level.name)),
                        ])
                      : _c("td", { staticClass: "align-middle w-50" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.local_level.name,
                                expression: "local_level.name",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "text", placeholder: "" },
                            domProps: { value: _vm.local_level.name },
                            on: {
                              change: function ($event) {
                                return _vm.changeDonorLevelInputs()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.local_level,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                    _vm._v(" "),
                    index != _vm.edit_index
                      ? _c("td", { staticClass: "align-middle" }, [
                          index ==
                          _vm.local_client.giving_levels.levels.length - 1
                            ? _c("span", [_vm._v("—")])
                            : _c("span", [
                                _vm._v(_vm._s(_vm._f("currency")(level.min))),
                              ]),
                        ])
                      : _c("td", { staticClass: "align-middle" }, [
                          index !=
                          _vm.local_client.giving_levels.levels.length - 1
                            ? _c(
                                "div",
                                { staticClass: "input-group input-group-sm" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "input-group-text" },
                                    [_vm._v("$")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.local_level.min,
                                        expression: "local_level.min",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.local_level.min },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.isPositiveNumber($event)
                                      },
                                      paste: _vm.cleanUpPaste,
                                      change: function ($event) {
                                        return _vm.changeDonorLevelInputs()
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.local_level,
                                          "min",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                    _vm._v(" "),
                    _vm.local_client.giving_levels.levels.length > 3
                      ? _c("td", { staticClass: "align-middle" }, [
                          index !=
                          _vm.local_client.giving_levels.levels.length - 1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "checkbox-animated mt-1 mb-0 ms-2",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: level.report,
                                        expression: "level.report",
                                      },
                                    ],
                                    attrs: {
                                      id: "checkbox_animated_" + index,
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(level.report)
                                        ? _vm._i(level.report, null) > -1
                                        : level.report,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = level.report,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  level,
                                                  "report",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  level,
                                                  "report",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(level, "report", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.limitChecks(index)
                                        },
                                      ],
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "checkbox_animated_" + index,
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "check" }),
                                      _c("span", { staticClass: "box" }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [
                      _vm.edit_index != index
                        ? _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1",
                                attrs: { title: "Edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-fw fa-edit" })]
                            ),
                          ])
                        : _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1",
                                attrs: {
                                  title: "Save",
                                  disabled: !_vm.if_can_save_new_level,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-save" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1 me-0",
                                attrs: { title: "Cancel" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-close" })]
                            ),
                          ]),
                      _vm._v(" "),
                      index !=
                        _vm.local_client.giving_levels.levels.length - 1 &&
                      _vm.edit_index != index
                        ? _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1 me-0",
                                attrs: { title: "Delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-fw fa-trash" })]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                }
              ),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.errors.giving_levels != ""
        ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.errors.giving_levels) + "\n        "
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex mt-3" }, [
      _vm.edit_index == -1
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary me-3",
              on: {
                click: function ($event) {
                  return _vm.addLevel()
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit", disabled: _vm.form.busy },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.update.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "fa fa-btn fa-save" }),
          _vm._v(" Save Changes\n        "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Level"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }