var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asset-details" }, [
    _c(
      "div",
      {
        staticClass: "card",
        class: _vm.getCardViewClassList(),
        attrs: { id: "asset-card-" + _vm.asset.id },
      },
      [
        _c("div", { staticClass: "card-body position-relative" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col mb-3" }, [
              _c("label", { staticClass: "form-label border-bottom" }, [
                _vm._v("Name"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.local_asset.name)),
              ]),
            ]),
            _vm._v(" "),
            !_vm.local_asset.if_current_user_reviewed_already &&
            _vm.local_asset.previous_review_list.length > 0
              ? _c("div", { staticClass: "col-6 mb-3" }, [
                  _c("label", { staticClass: "form-label border-bottom" }, [
                    _vm._v("Reviewed By"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(
                      _vm.local_asset.previous_review_list,
                      function (review) {
                        return _c(
                          "span",
                          {
                            staticClass: "viewed-user",
                            attrs: {
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "bottom",
                              title:
                                review.status == "approved"
                                  ? "Approved at: " +
                                    _vm.getDateAsString(review.updated_at)
                                  : "Rejected at: " +
                                    _vm.getDateAsString(review.updated_at),
                            },
                          },
                          [
                            review.status == "approved"
                              ? _c("i", { staticClass: "fa fa-check green" })
                              : _vm._e(),
                            _vm._v(" "),
                            review.status == "rejected"
                              ? _c("i", { staticClass: "fa fa-close red" })
                              : _vm._e(),
                            _vm._v(
                              "\n                            " +
                                _vm._s(review.updated_by_user.name) +
                                "\n                        "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { staticClass: "form-label border-bottom" }, [
                _vm._v("Asset Description"),
              ]),
              _vm._v(" "),
              _vm.local_asset.description != null &&
              _vm.local_asset.description != "" &&
              _vm.local_asset.description != "TODO"
                ? _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(_vm.local_asset.description)),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.asset.status != "approved" ||
          (_vm.asset.status == "approved" && _vm.is_editing_approved_asset)
            ? _c(
                "div",
                { staticClass: "mt-3" },
                _vm._l(_vm.local_inputs, function (input_field, index) {
                  return _c(
                    "div",
                    { key: _vm.asset.id + input_field.placeholder },
                    [
                      input_field.orig_value != null &&
                      input_field.orig_value != ""
                        ? _c("div", { staticClass: "row mb-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label mb-0",
                                  class: { "border-bottom": _vm.is_pdf_view },
                                },
                                [_vm._v(_vm._s(input_field.name))]
                              ),
                              _vm._v(" "),
                              _vm.is_pdf_view && input_field.type != "wysiwyg"
                                ? _c("p", [_vm._v(_vm._s(input_field.value))])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_pdf_view && input_field.type == "wysiwyg"
                                ? _c("div", {
                                    staticClass: "mt-2",
                                    domProps: {
                                      innerHTML: _vm._s(input_field.value),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.is_pdf_view
                                ? _c(
                                    "div",
                                    [
                                      input_field.type == "text" ||
                                      input_field.type == "link"
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                _vm.getErrorForMaxLength(
                                                  input_field
                                                ),
                                              attrs: {
                                                maxlength:
                                                  input_field.max_length,
                                                currentlength:
                                                  input_field.value != null
                                                    ? input_field.value.length
                                                    : 0,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: input_field.value,
                                                    expression:
                                                      "input_field.value",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class:
                                                  _vm.getErrorForInputField(
                                                    input_field
                                                  ),
                                                attrs: {
                                                  type: "text",
                                                  maxlength:
                                                    input_field.max_length,
                                                },
                                                domProps: {
                                                  value: input_field.value,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.inputFieldValueChange()
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      input_field,
                                                      "value",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      input_field.type == "textarea" ||
                                      input_field.type == "template"
                                        ? _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: input_field.value,
                                                expression: "input_field.value",
                                              },
                                            ],
                                            ref: "textarea",
                                            refInFor: true,
                                            staticClass:
                                              "full-height form-control",
                                            class:
                                              _vm.errors[
                                                input_field.placeholder
                                              ] != ""
                                                ? "is-invalid"
                                                : "",
                                            attrs: {
                                              id:
                                                input_field.placeholder +
                                                "-" +
                                                _vm.asset.id,
                                              maxlength: input_field.max_length,
                                            },
                                            domProps: {
                                              value: input_field.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.inputFieldValueChange()
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  input_field,
                                                  "value",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      input_field.type == "image"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "image-array-section",
                                            },
                                            _vm._l(
                                              input_field.value,
                                              function (
                                                image_url,
                                                image_index
                                              ) {
                                                return _c("div", [
                                                  _c("img", {
                                                    staticClass:
                                                      "me-3 mb-1 img-thumbnail",
                                                    attrs: { src: image_url },
                                                  }),
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      input_field.type == "wysiwyg"
                                        ? _c("wysiwyg-editor-advanced", {
                                            attrs: {
                                              if_show_button_input:
                                                _vm.if_show_button_input,
                                              input_value: input_field.value,
                                              input_index: index,
                                              asset_id: _vm.local_asset.id,
                                            },
                                            on: {
                                              wysiwygValueChange:
                                                _vm.wysiwygValueChange,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      input_field.type == "dropdown"
                                        ? _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: input_field.value,
                                                  expression:
                                                    "input_field.value",
                                                },
                                              ],
                                              staticClass:
                                                "form-select w-auto form-control",
                                              class:
                                                _vm.errors[
                                                  input_field.placeholder
                                                ] != ""
                                                  ? "is-invalid"
                                                  : "",
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      input_field,
                                                      "value",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.inputFieldValueChange()
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: "",
                                                  },
                                                },
                                                [_vm._v("Select the value...")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                input_field.options,
                                                function (option) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      domProps: {
                                                        value: option,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(option))]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errors[input_field.placeholder] != null &&
                              _vm.errors[input_field.placeholder] != ""
                                ? _c("div", {
                                    staticClass: "text-danger invalid-feedback",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.errors[input_field.placeholder]
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col" }, [
              _vm.form.successful
                ? _c("div", { staticClass: "alert alert-success" }, [
                    _vm._v(
                      "\n                        The asset's information has been updated!\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                        There was an error saving the asset information.\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "alert alert-danger unsaved-warning d-none" },
                [
                  _vm._v(
                    "\n                        This asset has been changed since you last saved it. Please save your changes or they will be lost.\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.is_pdf_view
                ? _c("div", [
                    _c("hr", { staticClass: "section-divider" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2 mb-3" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_asset.comments,
                            expression: "local_asset.comments",
                          },
                        ],
                        ref: "textarea",
                        staticClass: "full-height form-control",
                        attrs: { id: "comment-" + _vm.asset.id },
                        domProps: { value: _vm.local_asset.comments },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.local_asset,
                                "comments",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.resizeTextarea(
                                "comment-" + _vm.asset.id
                              )
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary me-2",
                          attrs: { type: "submit", disabled: _vm.form.busy },
                          on: {
                            click: function ($event) {
                              return _vm.approveAsset()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-btn fa-check" }),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                this.is_changes_made
                                  ? "Approve with Edits"
                                  : "Approve"
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", disabled: _vm.form.busy },
                          on: {
                            click: function ($event) {
                              return _vm.unapproveAsset()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-btn fa-trash" }),
                          _vm._v(" Reject\n                            "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "callout" }, [
                        _vm._v(
                          "\n                                Please note, this is not a final approval or rejection. Others in your organization can review once you are finished, if you choose.\n                            "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Your Comments "),
      _c("em", [_vm._v("(Optional)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }