var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "generate-ppt p-2 mb-4",
      attrs: { id: "generate-ppt-page" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v("Generate Presentation")]),
      _vm._v(" "),
      _c("div", { staticClass: "row px-2" }, [
        _c(
          "nav",
          {
            staticClass: "col-12 navbar navbar-expand primary-tab-bar pb-0",
            attrs: { id: "generate-ppt-tab-nav" },
          },
          [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "collapse navbar-collapse" }, [
                _c(
                  "ul",
                  { staticClass: "navbar-nav" },
                  _vm._l(_vm.generate_ppt_tabs, function (tab_name) {
                    return _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link pb-0 mb-0 primary-tab mx-2",
                          class: {
                            "primary-tab-active":
                              tab_name == _vm.generate_ppt_tab_active,
                          },
                          attrs: {
                            href: "#",
                            role: "button",
                            "aria-expanded": "false",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setActiveTab(tab_name)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(tab_name))])]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card box-content py-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.generate_ppt_tab_active == "BUILD PRESENTATION"
              ? _c(
                  "div",
                  [
                    _c("generate-presentation-build-new", {
                      ref: "generate",
                      attrs: {
                        client: _vm.client,
                        report_pages: _vm.report_pages,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.generate_ppt_tab_active == "PREVIOUS PRESENTATIONS"
              ? _c(
                  "div",
                  [
                    _c("generate-presentation-previous-list", {
                      attrs: { client: _vm.client, exports: _vm.exports },
                      on: { clone: _vm.clonePresentation },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }