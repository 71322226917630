var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "campaign-overview asset-details" }, [
    _c("div", { staticClass: "campaign-detail pb-3" }, [
      _c("div", { staticClass: "card box-content" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("h4", { staticClass: "mt-2 mb-0" }, [
              _vm._v("Campaign Details"),
            ]),
            _vm._v(" "),
            _vm.campaign.id > 0 && _vm.campaign.created_by_user
              ? _c("div", { staticClass: "created_by_label mt-1" }, [
                  _c("span", [
                    _c("label", { staticClass: "mb-0 me-1" }, [
                      _vm._v("Created By: "),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.campaign.created_by_user.first_name +
                          " " +
                          _vm.campaign.created_by_user.last_name
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3 mt-3" }, [
              _c("div", { staticClass: "col-8" }, [
                _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_campaign.name,
                      expression: "local_campaign.name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.name != "" ? "is-invalid" : "",
                  attrs: { type: "text", id: "campaign-name-input" },
                  domProps: { value: _vm.local_campaign.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.local_campaign, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.name) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("label", { staticClass: "form-label" }, [_vm._v("Status")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_campaign.status,
                        expression: "local_campaign.status",
                      },
                    ],
                    staticClass: "form-select",
                    class: _vm.errors.status != "" ? "is-invalid" : "",
                    attrs: { id: "status-select" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_campaign,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "", disabled: "", selected: "" } },
                      [_vm._v("Select the status...")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "draft" } }, [
                      _vm._v("Draft"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "in_review" } }, [
                      _vm._v("In Review"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "active" } }, [
                      _vm._v("Active"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "archive" } }, [
                      _vm._v("Archive"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.errors.status != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.status) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-4" }, [
                _c("label", { staticClass: "form-label" }, [_vm._v("Type")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_campaign.campaign_type_id,
                        expression: "local_campaign.campaign_type_id",
                      },
                    ],
                    staticClass: "form-select",
                    class: _vm.errors.campaign_type != "" ? "is-invalid" : "",
                    attrs: { id: "type-select" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.local_campaign,
                            "campaign_type_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.changeCampaignType()
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "", disabled: "", selected: "" } },
                      [_vm._v("Select the type...")]
                    ),
                    _vm._v(" "),
                    _vm._l(this.campaign_types, function (i) {
                      return i.id > 0
                        ? _c("option", { domProps: { value: i.id } }, [
                            _vm._v(_vm._s(i.name)),
                          ])
                        : _vm._e()
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.errors.campaign_type != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.campaign_type) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Start Date"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input-group",
                    class: _vm.errors.start_date != "" ? "is-invalid" : "",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("datepicker", {
                      class: _vm.errors.start_date != "" ? "is-invalid" : "",
                      attrs: {
                        "use-utc": true,
                        format: "MMM d, yyyy",
                        "input-class": "form-control",
                      },
                      model: {
                        value: _vm.local_campaign.start_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.local_campaign, "start_date", $$v)
                        },
                        expression: "local_campaign.start_date",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.start_date != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errors.start_date) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("End Date (Optional)"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input-group",
                    class: _vm.errors.date != "" ? "is-invalid" : "",
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("datepicker", {
                      attrs: {
                        "use-utc": true,
                        format: "MMM d, yyyy",
                        "input-class": "form-control",
                      },
                      model: {
                        value: _vm.local_campaign.end_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.local_campaign, "end_date", $$v)
                        },
                        expression: "local_campaign.end_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.local_campaign.prompts, function (prompt) {
              return _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", { staticClass: "form-label mb-0" }, [
                    _vm._v(_vm._s(prompt.title)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "input-field-description text-dark" },
                    [_vm._v(_vm._s(prompt.question))]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: prompt.answer,
                        expression: "prompt.answer",
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: prompt.answer },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(prompt, "answer", $event.target.value)
                      },
                    },
                  }),
                ]),
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary me-2",
                    attrs: {
                      type: "submit",
                      id: "save-button",
                      disabled: _vm.form.busy,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.update.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-save" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          this.local_campaign.id ? "Update" : "Add Campaign"
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.local_campaign.id != null
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", id: "delete-button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteCampaign()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-btn fa-trash" }),
                        _vm._v(" Delete\n                        "),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { href: "/campaigns/" + _vm.client.url },
                      },
                      [
                        _c("i", { staticClass: "fas fa-close" }),
                        _vm._v(" Cancel"),
                      ]
                    ),
                _vm._v(" "),
                _vm.form.successful
                  ? _c("div", { staticClass: "alert alert-success mt-3" }, [
                      _vm._v(
                        "\n                            The campaign details have been saved!\n                        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.error
                  ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                      _vm._v(
                        "\n                            There was an error saving the campaign details.\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.local_campaign.id != null
      ? _c("div", { staticClass: "asset-list mt-4" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("h4", { staticClass: "mb-2" }, [
                _vm._v("\n                    Assets "),
                _vm.hasAssetEdits()
                  ? _c("span", { staticClass: "fst-italic fs-6 ms-2 mb-2" }, [
                      _vm._v("("),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/campaigns/" +
                              _vm.client.url +
                              "/review/" +
                              _vm.local_campaign.url,
                          },
                        },
                        [_vm._v("Review All Edits")]
                      ),
                      _vm._v(")"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.available_asset_types.length > 0
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary btn-sm dropdown-toggle float-end ms-3 mb-2",
                        attrs: {
                          type: "button",
                          id: "add-asset-type",
                          "data-bs-toggle": "dropdown",
                          "aria-expanded": "false",
                          disabled: _vm.campaign_type_edited,
                        },
                      },
                      [_vm._m(2)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.available_asset_types.length > 0
                  ? _c(
                      "ul",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { "aria-labelledby": "add-asset-type" },
                      },
                      _vm._l(_vm.available_asset_types, function (type) {
                        return _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addAssetType(type.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(type.name))]
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.if_has_unfinished_asset
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-sm float-end mb-2",
                        attrs: { type: "button", disabled: _vm.form.busy },
                        on: {
                          click: function ($event) {
                            return _vm.generateAllAssets()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-btn fa-edit" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Generate Unfinished Assets")]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card box-content mb-3" }, [
            _c("div", { staticClass: "card-body p-0" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive mb-2" }, [
                _c("table", { staticClass: "table gy-0 m-0" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { staticClass: "table-border-bottom-0" },
                    _vm._l(
                      _vm.local_campaign.asset_types,
                      function (asset_type) {
                        return _c("tr", [
                          _c("td", { staticClass: "align-middle" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "d-inline-block mb-0 text-center me-2",
                                  },
                                  [
                                    _c("i", {
                                      class: asset_type.icon_class,
                                      staticStyle: { width: "24px" },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "p-0",
                                    attrs: {
                                      href:
                                        "/campaigns/" +
                                        _vm.client.url +
                                        "/assets/" +
                                        _vm.local_campaign.url +
                                        "/" +
                                        asset_type.id +
                                        "/false",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(asset_type.name) +
                                        "\n                                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "align-middle d-none d-md-table-cell d-sm-table-cell",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getNumAssets(asset_type.id)) + " "
                                ),
                                _vm.hasAssetEdits(asset_type.id)
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "/campaigns/" +
                                            _vm.client.url +
                                            "/review/" +
                                            _vm.local_campaign.url +
                                            "/" +
                                            asset_type.id,
                                        },
                                      },
                                      [_vm._v("(Review Edits)")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "align-middle d-none d-md-table-cell d-sm-table-cell",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getNumUnfinishedAssets(asset_type.id)
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "align-middle d-none d-md-table-cell d-sm-table-cell",
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.getLastUpdateDate(asset_type.id)
                                    )
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "align-middle d-none d-md-table-cell d-sm-table-cell",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary btn-sm",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAssets(asset_type.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash me-0",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.campaign_type_edited
                ? _c("div", { staticClass: "oauth-overlay" }, [
                    _c("div", { staticClass: "overlay" }),
                    _vm._v(" "),
                    _vm._m(5),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.local_campaign.id != null
            ? _c("div", { staticClass: "row my-3" }, [
                _c(
                  "div",
                  { staticClass: "col", attrs: { id: "public-links" } },
                  [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.current_domain +
                            "campaigns/" +
                            _vm.client.url +
                            "/approval/" +
                            _vm.local_campaign.url,
                          expression:
                            "current_domain + 'campaigns/' + client.url + '/approval/' + local_campaign.url",
                        },
                      ],
                      ref: "clone",
                      staticClass: "form-control bg-white ps-2 text-muted",
                      attrs: { type: "text", readonly: "" },
                      domProps: {
                        value:
                          _vm.current_domain +
                          "campaigns/" +
                          _vm.client.url +
                          "/approval/" +
                          _vm.local_campaign.url,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.copyToClipboard()
                        },
                        focus: function ($event) {
                          return $event.target.select()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.current_domain +
                              "campaigns/" +
                              _vm.client.url +
                              "/approval/" +
                              _vm.local_campaign,
                            "url",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", {
        staticClass: "fa fa-calendar",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", {
        staticClass: "fa fa-calendar",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Asset Type"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "align-middle pt-0 ps-3" }, [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Total Assets"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Number in Draft"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Last Updated At"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Actions"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cta" }, [
      _c("div", { staticClass: "row d-flex align-items-center mx-3" }, [
        _vm._v(
          "\n                            You must save your campaign updates before you can edit the new assets.\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Approval Page Link "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "The link below will allow clients to review any assets that have the status of 'Ready for Review'.",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }