var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "view-report benchmark-report p-2 mb-4",
      attrs: { id: "view-benchmark-page" },
    },
    [
      _c("div", { staticClass: "row mx-0" }, [
        _c("div", { staticClass: "p-0 w-100" }, [
          _c("div", { staticClass: "row mx-2 mb-3" }, [
            _c("div", { staticClass: "col-xl-5 col-12 mb-3" }, [
              _c("img", {
                staticClass: "benchmark-title-image me-3",
                attrs: { src: "/img/images/imagery_column_chart.png" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "circle-1" }, [
                _vm.page == null
                  ? _c("span", [
                      _c("span", {
                        staticClass: "d-inline-block spinner-border m-0",
                        attrs: { role: "status" },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v("Loading\n                        "),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("currency_abbr")(_vm.meta_data.revenue)) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" Revenue"),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "circle-3" }, [
                _vm.page == null
                  ? _c("span", [
                      _c("span", {
                        staticClass: "d-inline-block spinner-border m-0",
                        attrs: { role: "status" },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v("Loading\n                        "),
                    ])
                  : _c("span", [
                      _vm._v(_vm._s(_vm._f("number")(_vm.meta_data.org)) + " "),
                      _c("br"),
                      _vm._v(" Orgs"),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "circle-2" }, [
                _vm.page == null
                  ? _c("span", [
                      _c("span", {
                        staticClass: "d-inline-block spinner-border m-0",
                        attrs: { role: "status" },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v("Loading\n                        "),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("num_abbr")(_vm.meta_data.donors)) + " "
                      ),
                      _c("br"),
                      _vm._v(" Donors"),
                    ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-7 col-12" }, [
              _c("h1", {}, [
                _vm._v(
                  "Welcome to Avid's Benchmarks\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "card system-connection-card h-100" },
                    [
                      _c("div", { staticClass: "card-body m-2" }, [
                        _c("div", { staticClass: "row" }, [
                          _vm.page == null ||
                          _vm.local_page.crm_status == "connected" ||
                          _vm.local_page.crm_status == "loading"
                            ? _c("div", { staticClass: "col" }, [
                                _c("p", { staticClass: "top-card-text mb-0" }, [
                                  _vm._v(
                                    "\n                                                The challenge with most nonprofit benchmarks is that they are frozen to a single point in time. Avid aims to provide real-time insights by offering benchmarks that reflect the latest industry trends, \n                                                updated as recently as last month. By comparing your organization to the broader industry, these dynamic benchmarks allow you to clearly see where you’re excelling and where you should focus your efforts for improvement."
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.local_page != null &&
                                _vm.local_page.crm_status == "loading"
                                  ? _c("div", [_vm._m(0)])
                                  : _vm._e(),
                              ])
                            : _c("div", { staticClass: "col" }, [
                                _vm._m(1),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100 text-end" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: {
                                        href:
                                          "/settings/" +
                                          _vm.client.url +
                                          "/integrations",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Customize your Benchmarks—Connect your CRM"
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._m(2),
                              ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.page == null
            ? _c("div", { staticClass: "row mb-3" }, [_vm._m(3)])
            : _c("div", { staticClass: "row mb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("benchmark-filter-panel", {
                      attrs: {
                        page: _vm.page,
                        client: _vm.client,
                        section_id: _vm.section_id,
                      },
                      on: {
                        refreshing: _vm.refreshing,
                        updatePage: _vm.updatePage,
                      },
                    }),
                  ],
                  1
                ),
              ]),
          _vm._v(" "),
          _vm.page != null
            ? _c(
                "div",
                { staticClass: "row mt-3 px-2", attrs: { id: "top-of-page" } },
                [
                  _c("div", { staticClass: "card box-content" }, [
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c(
                          "nav",
                          {
                            staticClass:
                              "navbar navbar-expand secondary-tab-bar p-0 mb-2",
                            attrs: { id: "report-section-nav" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "container-fluid px-0 mb-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "collapse navbar-collapse",
                                    attrs: { id: "filter-navbar" },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "navbar-nav mb-2 mb-lg-0",
                                      },
                                      _vm._l(
                                        _vm.local_page.sections,
                                        function (section, index) {
                                          return index != 0
                                            ? _c(
                                                "li",
                                                { staticClass: "nav-item" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "nav-link d-flex flex-nowrap secondary-tab",
                                                      class: {
                                                        "secondary-tab-active":
                                                          section.is_section_tab_active,
                                                      },
                                                      attrs: {
                                                        href: "#",
                                                        role: "button",
                                                        "aria-expanded":
                                                          "false",
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        title:
                                                          section.description,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.setActiveTab(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("h6", [
                                                        _vm._v(
                                                          _vm._s(section.title)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.local_page.sections,
                          function (section, index) {
                            return _c(
                              "div",
                              {
                                class: section.is_section_tab_active
                                  ? ""
                                  : "d-none",
                              },
                              [
                                index != 0
                                  ? _c("report-view-section", {
                                      attrs: {
                                        page: _vm.local_page,
                                        client: _vm.client,
                                        connections: _vm.connections,
                                        page_section: section,
                                        is_filter_panel_expanded: false,
                                        if_refreshing: _vm.if_refreshing,
                                        applied_chart_filter:
                                          _vm.applied_chart_filter,
                                        if_benchmark_report: true,
                                        section_index: index,
                                      },
                                      on: {
                                        goToNextSection: _vm.goToNextSection,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.page != null
            ? _c("div", { staticClass: "row mt-3 px-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", [
                    _vm._v(
                      "We are always looking for ways to make this benchmark better. In the coming months, we'll be rolling out benchmarks for more areas of fundraising like Email and Paid Social Media. \n                        "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          "data-bs-toggle": "modal",
                          "data-bs-target":
                            "#support-modal-" + _vm.selected_ticket_type,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openSupportModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "If you have suggestions or questions, we would love to hear about it."
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("support-modal", {
        attrs: {
          user: _vm.user,
          client: _vm.client,
          selected_ticket_type: _vm.selected_ticket_type,
          id: "support-modal-" + _vm.selected_ticket_type,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "alert alert-warning mt-2 mb-0",
        attrs: { role: "alert" },
      },
      [
        _c(
          "div",
          {
            staticClass: "spinner-border spinner-border-sm float-left",
            attrs: { role: "status" },
          },
          [
            _c("span", { staticClass: "visually-hidden" }, [
              _vm._v("Loading..."),
            ]),
          ]
        ),
        _vm._v(
          "\n                                                    Your data is being processed, we'll notify you when your benchmarks have been customized.\n                                                "
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100" }, [
      _c("p", { staticClass: "top-card-text" }, [
        _vm._v(
          "The challenge with most nonprofit benchmarks is that they are frozen to a single point in time. Avid aims to provide real-time insights by offering benchmarks that reflect the latest industry trends, updated as recently as last month. If you want to get the most out of Avid (and keep your access to benchmarks), you simply need to keep your giving updated on a monthly basis. (Connecting your CRM is the easiest way to do that.)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 text-end" }, [
      _c("small", [
        _vm._v(
          "* Without a CRM connection, your benchmark access ends in 30 days"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "navbar navbar-expand bg-secondary rounded p-3" },
        [
          _c("h5", { staticClass: "mb-0 text-primary" }, [
            _c("span", {
              staticClass:
                "d-inline-block spinner-border spinner-border-sm me-2",
              attrs: { role: "status" },
            }),
            _vm._v(
              "\n                        Your benchmark report is loading..."
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }