<template>
    <div class="table-responsive border rounded">
        <div v-if="chart_selected.data">
            <table class="table m-0">
                <thead>
                    <tr class="table-header-row">
                        <th style="width:15%" v-on:click="sortByKey('label')">
                            <div class="d-flex flex-row">
                                {{ chart_selected.group_by[0].text }}
                                <div class="d-flex flex-column">
                                    <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='label' && sort_order=='asc')? 'sort-key-active' :''">
                                    <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='label' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                </div>
                            </div>
                        </th>

                        <th v-for="key in Object.keys(chart_selected.data)" class="align-middle" v-on:click="sortByKey(key)">
                            <div class="d-flex flex-row">
                                {{ key }}
                                <div class="d-flex flex-column">
                                    <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key==key && sort_order=='asc')? 'sort-key-active' :''">
                                    <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key==key && sort_order=='des')? 'sort-key-active' :''">                                                            
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in table_data_shown"  class="table-template-row" @click="(if_on_report_page && !if_benchmark_report) ? exploreData(data) : null"
                        :class="applied_chart_filter.if_chart_filter_applied && id == applied_chart_filter.section_chart_id && data.label != applied_chart_filter.chart_filters[0].value? 'grayscale-table-template-row': ''">
                        <td v-for="(value, key, index) in data" class='ps-3 align-middle' >
                            {{ value==null? '(not set)': applyFormatToCurrentValue(value, key) }}
                        </td>
                    </tr>
                </tbody>

                <tfoot class="table-border-bottom-0">
                    <tr>
                        <td colspan="100%" class="py-0">
                            <div class="table-pagination">
                                <div class="table-pagination--group">
                                    <span class="d-none d-lg-inline">Showing: </span>
                                    <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                        <option value="5000">ALL</option>
                                        <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                                    </select>
                                    <span>Results </span>
                                </div>

                                <div class="table-pagination--group text-center">
                                    {{ item_interval }} of {{ table_data.length }}
                                </div>

                                <div class="table-pagination--group">
                                    <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                        <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                    </button>   

                                    <div v-if="page_index_array.length<=3">
                                        <button  v-for="page in page_index_array" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                        {{page}}
                                        </button>  
                                    </div>
                                    <div v-else>
                                        <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                        {{page}}
                                        </button>  

                                        <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                        ...
                                        </button>  
                                        <button v-if="page_index >= 2 && page_index< page_index_array.length -1" class="btn btn-none px-1 current-page">
                                            {{page_index + 1 }} 
                                        </button>  

                                        <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1" disabled>
                                        ...
                                        </button>  
                                        <button  class="btn btn-none px-1" :class="page_index ==page_index_array.length -1 ? 'current-page':''" @click="moveToPage(page_index_array.length)">
                                        {{ page_index_array.length }}
                                        </button>  
                                    </div>

                                    <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == page_count -1 ">
                                        <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                    </button>   
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div v-else class='d-inline-block text-center'>
            <h1 class="text-secondary_v1  mb-1"><i class="fa-solid fa-chart-simple"></i></h1>
            <h5 class="text-secondary_v1  mb-2"><em>Press the <strong>Preview</strong> button to render the chart</em></h5>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['chart_selected', 'id', 'if_refreshing', 'if_on_report_page', 'if_benchmark_report', 'applied_chart_filter'],
        data() {
            return {
                chart: null,
                formatType: [],
                if_chart_has_data: false,
                if_chart_filter_applied: false,
                chart_filter_label: null,

                table_data: [],
                table_data_shown:[],
                sort_key: null,
                sort_order: 'asc',

                // pagination
                num_per_page: 15, // default to 15
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: ''
            };
        },
        watch:{
            chart_selected: {
                handler: function(newVal, oldVal) {
                    // Since chart_selected is a complex object, you might want to perform
                    // a more specific check to determine if renderChart should be called.
                    // For now, we'll just check if it has 'data' to decide.
                    if (newVal.data) {
                        if(this.chart_selected.metric_formats){
                            this.formatType = this.chart_selected.metric_formats
                        } else {
                            this.formatType = ['number'];
                        }
                        this.loadTableData();
                        this.reloadPagination();
                        this.if_chart_has_data = true;
                    }
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
            if_refreshing:{
                handler: function(newVal, oldVal) {
                    this.$forceUpdate();
                }
            },
            applied_chart_filter:{
                handler: function(newVal, oldVal) {
                    this.if_chart_filter_applied = this.applied_chart_filter.if_chart_filter_applied && this.id == this.applied_chart_filter.section_chart_id;
                    this.reloadPagination();
                }
            }
        },
        mounted() {
            if (this.chart_selected.data){
                // loop through metric_objects in chart to find format 
                if(this.chart_selected.metric_formats){
                    this.formatType = this.chart_selected.metric_formats
                } else {
                    this.formatType = ['number'];
                }

                this.loadTableData();
                this.reloadPagination();
                this.if_chart_has_data = true;
            }
       },
        methods: {
            loadTableData(){
                var table_data_temp =[];
                
                this.chart_selected.labels.forEach((value,index)=>{
                    var data_obj = {label: value}

                    Object.keys(this.chart_selected.data).forEach((key, key_index) =>{
                        data_obj[key] = this.chart_selected.data[key][index];
                    })
                    table_data_temp.push(data_obj);

                })
                this.table_data = table_data_temp;
            },
            applyFormatToCurrentValue(data, key){
                if (key =='label'){
                    return data;
                }
                var index = this.chart_selected.metrics.findIndex(m => m.text == key);
                if(index == -1){
                    index = this.chart_selected.group_by.findIndex(m => m.text == key) - 1;
                }
                else
                    index += this.chart_selected.group_by.length - 1;

                if(this.formatType[index] == 'money'){
                    //Call the currency filter
                    return this.$options.filters.currency_with_zero(data);
                }
                else if(this.formatType[index] == 'percent'){
                    return this.$options.filters.percentage(data);
                }
                else if(this.formatType[index] == 'number'){
                    return this.$options.filters.number_with_decimal(data);
                }
                else if(this.formatType[index] == 'date'){
                    return this.$options.filters.date(data);
                }
                else {
                    return data;
                }
            },
            sortByKey(key){
                if (this.sort_key != key){
                    this.sort_key = key;
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel();
            },
            sortDataWithLabel(){
                if(this.sort_order == 'asc')
                    this.table_data.sort((a, b) => (a[this.sort_key ] > b[this.sort_key ]) ? 1 : -1);
                else
                    this.table_data.sort((a, b) => (a[this.sort_key ] < b[this.sort_key ]) ? 1 : -1);
                this.reloadPagination();
            },   
            reloadPagination(){
                this.page_count = Math.ceil(this.table_data.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            }, 
            getItemInterval(){
                var from = this.page_index * this.num_per_page + 1;
                var to = (this.page_index +1) * this.num_per_page;
                if(to>this.table_data.length)
                    to = this.table_data.length;
                if (from >= to) 
                    this.item_interval = to
                else 
                    this.item_interval = from +' - ' +to;
                
                this.table_data_shown = this.table_data.slice(from-1, to);
            },
            moveToPrevPage(){
                this.page_index--;
                this.getItemInterval();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getItemInterval();
            },
            moveToNextPage(){
                this.page_index++;
                this.getItemInterval();
            },
            exploreData(data){
                if (this.if_chart_filter_applied){
                    this.if_chart_filter_applied = false;
                    this.chart_filter_label = null;
                    this.$emit('exploreChart', {
                        chart_selected: null,
                        chart_filters: []
                    });
                } else {
                    this.if_chart_filter_applied = true;
                    this.chart_filter_label = data.label;
                    var filter_array = [];
                    this.chart_selected.group_by.forEach((dimension, index) => {
                        filter_array.push({
                            name: dimension.text,
                            column: dimension.value,
                            value: index==0 ? data['label'] : data[dimension.text]
                        })
                    })
                    this.$emit('exploreChart', {
                        chart_selected: this.chart_selected,
                        chart_filters: filter_array 
                    });
                }
            }
        }
    }
</script>
