var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2", attrs: { id: "pathways-push-segment-summary" } },
    [
      _c("div", { staticClass: "pathways-push-segment-summary__text" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _vm.if_download_records_finished &&
          _vm.allColumnsMapped() &&
          !_vm.isFile()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.exportSegment()
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "icon pe-1",
                    attrs: { src: "/img/icons/dialexa-icons/page-search.svg" },
                  }),
                  _vm._v("Export Records\n            "),
                ]
              )
            : _vm.allColumnsMapped() && !_vm.isFile()
            ? _c(
                "button",
                { staticClass: "btn btn-secondary", attrs: { disabled: "" } },
                [_vm._m(1), _vm._v(" Export Records\n            ")]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pathways-push-segment-summary__flow-chart" }, [
        _c(
          "div",
          {
            staticClass: "card summary-card",
            attrs: { id: "summary-card-segment" },
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("Audience")]),
                _vm._v(" "),
                _c("h5", [_vm._v(_vm._s(_vm.segment_selected.name))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.segment_selected.description))]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card summary-card", attrs: { id: "summary-info" } },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", [
                _c("p", [_vm._v("Sync Frequency")]),
                _vm._v(" "),
                _c("h5", [_vm._v(_vm._s(_vm.isFile() ? "One Time" : "Daily"))]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("Records")]),
                _vm._v(" "),
                _vm.segment_size != null && _vm.segment_size >= 0
                  ? _c("h5", [
                      _vm._v(
                        _vm._s(_vm._f("number_with_zero")(_vm.segment_size)) +
                          "\n                        "
                      ),
                      _c("i", {
                        staticClass: "fas fa-info-circle text-primary ms-2",
                        attrs: {
                          "data-bs-toggle": "tooltip",
                          title:
                            "This record count is based upon the distinct values of the Primary Key Column selected below. Open 'Advanced Settings' and then 'Column Mapping' to adjust this.",
                        },
                      }),
                    ])
                  : _vm.segment_size == -1
                  ? _c("div", [
                      _c("em", [_vm._v("Needs More Info")]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fas fa-info-circle text-primary ms-2",
                        attrs: {
                          "data-bs-toggle": "tooltip",
                          title:
                            "You need to select a primary key column below before an estimated number of records can be calculated.",
                        },
                      }),
                    ])
                  : _c("div", [
                      _c("div", {
                        staticClass:
                          "spinner-border text-primary spinner-border-sm float-left",
                        attrs: { role: "status" },
                      }),
                      _vm._v(" "),
                      _c("em", [_vm._v("Loading")]),
                    ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card summary-card",
            attrs: { id: "summary-card-pathway" },
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("Pathway")]),
                _vm._v(" "),
                _c("h5", [
                  _vm._v(
                    _vm._s(
                      _vm.isFile()
                        ? "File Export"
                        : _vm.local_pathway_selected.name
                    )
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.instructions != null
        ? _c("div", [
            _c("div", {
              staticClass: "alert alert-danger",
              attrs: { role: "alert" },
              domProps: { innerHTML: _vm._s(_vm.instructions) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.segment_selected.data_source && _vm.census_connection.is_file
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("file-column-mapping", {
                ref: "file_mapping",
                attrs: {
                  client: _vm.client,
                  source: _vm.local_census_connection,
                  source_columns: _vm.source_fields,
                  destination: _vm.local_pathway_selected,
                },
                on: {
                  prioritize: _vm.updatePrioritization,
                  changeMappingDetails: _vm.changeMappingDetails,
                },
                model: {
                  value: _vm.local_census_connection.mappings,
                  callback: function ($$v) {
                    _vm.$set(_vm.local_census_connection, "mappings", $$v)
                  },
                  expression: "local_census_connection.mappings",
                },
              }),
            ],
            1
          )
        : _vm.segment_selected.data_source
        ? _c(
            "div",
            {
              staticClass:
                "pathways-push-segment-summary__advanced-push-settings-toggle my-2",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "form-check form-switch d-inline-flex align-items-center gap-2 my-0",
                },
                [
                  _c("input", {
                    staticClass: "form-check-input mt-0",
                    attrs: { type: "checkbox", role: "switch" },
                    domProps: { checked: _vm.if_advanced_setting },
                    on: {
                      change: function ($event) {
                        _vm.if_advanced_setting = !_vm.if_advanced_setting
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Advanced Settings")]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.if_advanced_setting &&
      _vm.segment_selected.data_source &&
      _vm.census_connection.is_file == false
        ? _c(
            "div",
            { attrs: { id: "advanced-settings-section" } },
            [
              _vm._m(6),
              _vm._v(" "),
              _vm.if_advanced_setting_ready
                ? _c(
                    "transition-group",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-start gap-3",
                      attrs: { tag: "div" },
                    },
                    [
                      _c(
                        "div",
                        {
                          key: "advanced-setting-information",
                          staticClass: "accordion",
                        },
                        [
                          _c("div", { staticClass: "accordion-item" }, [
                            _c("div", { staticClass: "accordion-header" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "accordion-button collapsed",
                                  attrs: {
                                    type: "button",
                                    "data-bs-target":
                                      "#advanced-setting-information",
                                    "data-bs-toggle": "collapse",
                                    "aria-expanded": "false",
                                    "aria-controls":
                                      "#advanced-setting-information",
                                  },
                                },
                                [_c("h5", [_vm._v("Information")])]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "accordion-body accordion-collapse pt-0",
                                class: _vm.if_expand_info_section
                                  ? ""
                                  : "collapse",
                                attrs: { id: "advanced-setting-information" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column gap-2" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Before data can be sent to " +
                                          _vm._s(
                                            _vm.local_pathway_selected.name
                                          ) +
                                          ", you first need to align\n                                    the columns to those available in this data source and decide how the data should be kept \n                                    in sync on an ongoing basis. \n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 d-flex flex-row gap-3",
                                      },
                                      [
                                        _c("div", { staticClass: "w-50" }, [
                                          _c("label", [
                                            _vm._v("Destination Object"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.local_census_connection
                                                      .destination_object,
                                                  expression:
                                                    "local_census_connection.destination_object",
                                                },
                                              ],
                                              staticClass: "form-select",
                                              class: {
                                                "is-invalid":
                                                  _vm.information_errors
                                                    .destination_object != "",
                                              },
                                              attrs: {
                                                disabled:
                                                  _vm.local_pathway_selected
                                                    .census_destination_objects ==
                                                    null ||
                                                  _vm.local_pathway_selected
                                                    .census_destination_objects
                                                    .length == 1 ||
                                                  _vm.local_census_connection
                                                    .id > 0,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.local_census_connection,
                                                      "destination_object",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.changeDestination()
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.local_pathway_selected
                                                .census_destination_objects,
                                              function (obj) {
                                                return obj.data == null
                                                  ? _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: obj.full_name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(obj.label)
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _vm.information_errors
                                            .destination_object != ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-danger invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.information_errors
                                                          .destination_object
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _vm.operations.length > 1
                                          ? _c("div", { staticClass: "w-50" }, [
                                              _c("label", [
                                                _vm._v("Method of Sync"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .local_census_connection
                                                          .operation,
                                                      expression:
                                                        "local_census_connection.operation",
                                                    },
                                                  ],
                                                  staticClass: "form-select",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.information_errors
                                                        .operation != "",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm
                                                        .local_census_connection
                                                        .id > 0,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.local_census_connection,
                                                        "operation",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.operations,
                                                  function (ops) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: ops,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getOperationExplanation(
                                                              ops
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _vm.information_errors
                                                .operation != ""
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-danger invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm
                                                              .information_errors
                                                              .operation
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.local_census_connection.destination_object ||
                      _vm.local_pathway_selected.census_destination_objects
                        .length == 1
                        ? _c(
                            "div",
                            {
                              key: "advanced-setting-primary-key",
                              staticClass: "accordion",
                            },
                            [
                              _c("div", { staticClass: "accordion-item" }, [
                                _c("div", { staticClass: "accordion-header" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "accordion-button collapsed",
                                      attrs: {
                                        type: "button",
                                        "data-bs-target":
                                          "#advanced-setting-primary-key",
                                        "data-bs-toggle": "collapse",
                                        "aria-expanded": "false",
                                        "aria-controls":
                                          "#advanced-setting-primary-key",
                                      },
                                    },
                                    [_c("h5", [_vm._v("Column Mapping")])]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "accordion-body accordion-collapse pt-0",
                                    class: _vm.if_expand_column_mapping_section
                                      ? ""
                                      : "collapse",
                                    attrs: {
                                      id: "advanced-setting-primary-key",
                                    },
                                  },
                                  [
                                    _vm.local_census_connection
                                      .destination_object != null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column gap-3",
                                          },
                                          [
                                            _c("pathways-column-mapping", {
                                              ref: "column_mapping",
                                              attrs: {
                                                client: _vm.client,
                                                source:
                                                  _vm.local_census_connection,
                                                source_columns:
                                                  _vm.source_fields,
                                                destination:
                                                  _vm.local_pathway_selected,
                                                dest_columns:
                                                  _vm.destination_fields,
                                                show_unmapped_only:
                                                  _vm.show_unmapped_only,
                                              },
                                              on: {
                                                refresh: function ($event) {
                                                  return _vm.refreshColumns()
                                                },
                                                prioritize:
                                                  _vm.updatePrioritization,
                                                changeMappingDetails:
                                                  _vm.changeMappingDetails,
                                              },
                                              model: {
                                                value:
                                                  _vm.local_census_connection
                                                    .mappings,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.local_census_connection,
                                                    "mappings",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "local_census_connection.mappings",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "my-2 d-flex flex-row gap-2",
                                              },
                                              [
                                                !_vm.show_unmapped_only
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addColumnMapping()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-plus",
                                                        }),
                                                        _vm._v(
                                                          " Add Column Mapping"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            _vm.show_unmapped_only = false
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Show All Mapped Columns"
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _c("div", [
                    _c("p"),
                    _c(
                      "div",
                      {
                        staticClass: "spinner-border spinner-border-sm",
                        attrs: { role: "status" },
                      },
                      [_c("span", { staticClass: "visually-hidden" })]
                    ),
                    _vm._v(
                      "\n                Loading Advanced Settings Options...\n            "
                    ),
                    _c("p"),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column gap-1 flex-grow-1" }, [
      _c("div", [
        _c("h5", [_vm._v("Pathway Summary")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Below is a summary of the pathway you've identified. Click on the "
          ),
          _c("em", [_vm._v("Advanced Settings")]),
          _vm._v(
            " to map additional columns or to change how the systems are connected."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "summary-card-icon-background" }, [
        _c("img", {
          staticClass: "summary-card-icon",
          attrs: { src: "/img/icons/dialexa-icons/people-outline.svg" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "figure",
      { staticClass: "d-flex flex-column justify-content-center m-0 fs-2" },
      [_c("i", { staticClass: "connector-1" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "figure",
      { staticClass: "d-flex flex-column justify-content-center m-0 fs-2" },
      [_c("i", { staticClass: "fa-sharp fa-solid fa-arrow-right m-0" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "summary-card-icon-background" }, [
        _c("img", {
          staticClass: "summary-card-icon",
          attrs: { src: "/img/icons/dialexa-icons/directions.svg" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column gap-1" }, [
      _c("h5", [_vm._v("Advanced Settings")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Customize how you want to push this audience to pathway"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }