<template>
    <div class="initiative-results">
        <div class='row'>
            <div class="col-md-9 col-sm-8"><h4>{{initiative.title}}</h4></div>
            <div class="col text-sm-end mb-3">
                <a :href="'/results/'+client.url+'/costs_results/' + initiative.url " class="btn btn-sm btn-secondary">Edit Costs / Results</a>
            </div>
        </div>
        <div class="row show-tables" @click="showTable()">
            <div class="col-sm-4 col-lg-3 col-xl mb-3 scorecard "
                :class="isVisible(m) ? '' : 'hidden'"
                v-for="m in metrics" v-if="hasMetric(m)">
                <button type="button" class="close btn" aria-label="Close"
                    v-on:click="customizeVisibility(m);">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
                <div class="card box-content">
                    <canvas class="tiny-chart" :ref="m + '_chart'"></canvas>
                    <div class="card-body">
                        <h5 class="text-muted"><img :src="getImgSrc(m)" class="icon me-2">{{m | propercase}}</h5>
                        <div class="card-info">

                            <h4 class="card-title mb-0 me-2">
                                <span v-if="m=='all_revenue' || m == 'project_cost' || m == 'media_cost'">{{ getMetricLabel(m).actuals | currency_with_zero }}</span>
                                <span v-else >{{ getMetricLabel(m).actuals | number_with_zero }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide">
            <div v-if="show_table" class="table-container mt-3">
                <div class="card box-content">
                    <div class="card-body p-0 table-responsive text-nowrap stat-table-holder">
                        <table class="table table-sm"
                            v-for="m in all_metrics" v-if="hasMetric(m) && isVisible(m)">
                            <thead class="table-dark ">
                                <tr>
                                    <th scope="col" class="ps-3">{{(m=='revenue' ? 'One-Time Revenue' : m) | propercase }}</th>
                                    <th scope="col" v-for="h in headers">{{h}}</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-3">Projections</td>
                                    <td v-for="dte in getDateArray()">{{formatNumber(m, initiative[m].projections[dte])}}</td>
                                    <td>{{formatNumber(m, getTotal(initiative[m].projections))}}</td>
                                </tr>
                                <tr>
                                    <td class="ps-3">Actuals</td>
                                    <td v-for="dte in getDateArray()">{{formatNumber(m, initiative[m].actuals[dte])}}</td>
                                    <td>{{formatNumber(m, getTotal(initiative[m].actuals))}}</td>
                                </tr>
                                <tr>
                                    <td class="ps-3">Variance</td>
                                    <td v-for="dte in getDateArray()" :class="getVarianceClass(initiative[m].variance[dte])">{{initiative[m].variance[dte] | percentage}}</td>
                                    <td :class="getVarianceClass(getTotalVariance(initiative[m]))">{{getTotalVariance(initiative[m]) | percentage}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </transition>
        <br><br>
    </div>
</template>
<script>

import moment from 'moment';
export default {
  props: ['client', 'initiative', 'headers', 'start_date', 'end_date', 'customizations'],
  data() {
    return {
        charts: [],
        metrics: ['media_cost', 'project_cost', 'all_revenue', 'new_donors', 'gifts', 'emails'],
        all_metrics: ['media_cost', 'project_cost', 'revenue', 'recurring_revenue', 'new_donors', 'gifts', 'emails'],
        show_table: false
    }
  },
  mounted() {
    this.drawCharts();
  },
  methods: {
    formatNumber(metric, num) {
        switch(metric) {
            case 'media_cost':
            case 'project_cost':
            case 'revenue':
            case 'recurring_revenue':
                return this.$options.filters.currency_with_zero(num);
        }
        return this.$options.filters.number_with_zero(num);
    },
    showTable() {
        if(document.getElementsByClassName('customize').length == 0)
            this.show_table = !this.show_table;
    },
    getDateArray() {
        var arr = [];
        for(var month = new Date(this.start_date); month < this.end_date; month.setUTCMonth(month.getUTCMonth()+1))
            arr.push(this.getDateAsString(month));
        return arr;
    },
    hasMetric(arr) {
        if(typeof arr == 'string')
            arr = this.initiative[arr];

        var total = 0;
        for (const [key, i] of Object.entries(arr.projections)) {
            total += i;
        };
        for (const [key, i] of Object.entries(arr.actuals)) {
            total += i;
        };
        return total != 0;
    },
    isVisible(metric) {
        //Loop through the customizations array
        if(this.customizations != undefined)
            for (const [key, i] of Object.entries(this.customizations)) {
                //If the metric is in the array, return true
                if(i.action == 'hide' && (i.filter.metric == metric ||
                    i.filter.metric == 'all_revenue' && metric.indexOf('revenue') >= 0) &&
                    i.filter.initiative_id == this.initiative.id )
                    return false;
            };
        return true;
    },
    getDateAsString(value) {
        return moment(String(value)).utc().format('YYYY-MM-DD')
    },
    getVarianceClass(val) {
        if(val < 0)
            return 'negative-' + Math.min(10, Math.round(Math.abs(val)*10));
        else if(val == 0) return "";

        return 'positive-' + Math.min(10, Math.round(Math.abs(val)*10));

    },
    getImgSrc(val) {
        if(val == 'media_cost' || val == 'project_cost')
            return '/img/icons/average_amount.png';
        if(val == 'revenue')
            return '/img/icons/revenue.png';
        if(val == 'new_donors')
            return '/img/icons/donors_new.png';
        if(val == 'gifts')
            return '/img/icons/donors.png';

        return '/img/icons/subscribers.png';
    },
    getMetricLabel(metric) {
        var results = {
            projections: 0,
            actuals: 0,
            variance: 0
        };

        //Loop through the start and end months
        for(var month = new Date(this.start_date); month < new Date(); month.setUTCMonth(month.getUTCMonth()+1)) {
            //Combine project and media for costs
            results.projections += this.initiative[metric].projections[this.getDateAsString(month)];
            results.actuals += this.initiative[metric].actuals[this.getDateAsString(month)];
        }

        if(results.projections > 0)
            results.variance = (results.actuals - results.projections) / results.projections;

        return results;
    },
    customizeVisibility(metric) {
        var data = {
            action: 'hide',
            client_id: this.client.id,
            filter: {
                initiative_id: this.initiative.id,
                metric: metric
            }
        };

        var c_id = null;
        for(var i = 0; i < this.customizations.length; i++) {
            if(data.action == this.customizations[i].action &&
                data.filter.metric == this.customizations[i].filter.metric &&
                data.filter.initiative_id == this.customizations[i].filter.initiative_id) {
                c_id = i;
                break;
            }
        }

        if(c_id == null) {
            var self = this;
            window.axios.post('/api/customization', data)
              .then(response => {
                if(response.status == 200) {
                    //update the customization object
                    self.$emit('addCustomization', response.data.data);
                }
              });
        }
        else {
            window.axios.delete('/api/customization/' + this.customizations[c_id].id);
            this.$emit('deleteCustomization', c_id);
        }
    },
    drawCharts() {
        for(var i = 0; i < this.metrics.length; i++ ) {
            if(this.$refs[this.metrics[i] + "_chart"] == undefined) continue;

            //Set up the data
            var chart_data = [
                {
                    label: 'Projections',
                    data: Object.values(this.initiative[this.metrics[i]].projections),
                    backgroundColor: '#C7CCDB',
                    borderColor: '#C7CCDB',
                    borderDash: [3, 3],
                    borderWidth: 1,
                    tension: 0.2,
                    pointRadius: 0
                },
                {
                    label: 'Actuals',
                    data: Object.values(this.initiative[this.metrics[i]].actuals),
                    backgroundColor: '#46705F',
                    borderColor: '#46705F',
                    borderWidth: 3,
                    tension: 0.2,
                    pointRadius: 0
                }
            ];

            var config = this.getChartConfig(chart_data)

            if(this.charts[this.metrics[i]] != null)
                this.charts[this.metrics[i]].destroy();

            this.charts[this.metrics[i]] = new Chart(this.$refs[this.metrics[i]+"_chart"], config);
        }
    },
    getChartConfig(chart_data) {

        var self = this;

        var config = {
            type: 'line',
            data: {
                labels: this.headers,
                datasets: chart_data
            },
            options: {
                layout: {
                    padding: {
                        bottom: 4,
                        top: 4
                    }
                },
                responsive: true,
                title: {  display:false },
                scales: {
                    x:{
                        display: false,
                        grid: {
                          display: false,
                          drawBorder: false
                        },
                        ticks: { display: false }
                    },
                    y: {
                        display: false,
                        grid: {
                          display: false,
                          drawBorder: false
                        },
                        ticks: { display: false }
                    }
                },
                plugins: { legend: { display: false } }
            }
        };
        return config;


    },
    getDateAsString(value) {
        return moment(String(value)).utc().format('YYYY-MM-DD')
    },
    getTotal(arr) {
        var total = 0;
        var date_array = this.getDateArray();
        for(var i = 0; i < date_array.length; i++)
            total += arr[date_array[i]];
        return total;
    },
    getTotalVariance(obj) {
        var projections = this.getTotal(obj.projections);
        var actuals = this.getTotal(obj.actuals);
        if(projections == 0)
            return 1;
        return (actuals - projections) / projections;
    }
  }
};
</script>
