<template>
    <div class="card box-content banner mx-3 mb-3">
        <div class="card-body">
            <h6>{{ title }}</h6>
            <p>{{ text }}</p>

            <div class="d-flex flex-row gap-1">
                <button class="btn btn-secondary">Dismiss</button>
                <button class="btn btn-primary">Upgrade</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['client'],
        data() {
            return {
                title: '',
                text: '',
            };
        },
        beforeMount() {
            this.title = 'x Days Left Of Your Free Trial!';
            this.text = 'Be sure to subscribe in order to keep access to your Insights and more!';

        },
        computed: {

        },
        methods: {
            // dismiss(n) {
            //     window.axios.get('/api/notification/read/' + n.id);

            //     //Remove the notification from the list
            //     for(var i = 0; i < this.notif.length; i++) {
            //         if(this.notif[i].id == n.id) {
            //             this.notif.splice(i, 1);
            //         }
            //     }
            // },
        }

    }
</script>