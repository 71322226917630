<template>
    <div class="manage-users client-user">
        <div v-if="this.local_users.length > 1">
            <h1 class="mb-2">Agency Users</h1>
            <p class="mb-3">The users below are those associated with your agency. Unless they are set as an "Agency Admin," they will only have access to clients they are associated with. When you delete a user, they will lose access to all clients.</p>
            <div class="row mb-3">
                <div class="col-sm-6">
                    <div class="card box-content">
                        <div class="card-body">
                            <div class="mb-3">
                                <label class="form-label">Select a user</label>
                                <select class="form-select" name="user" v-model="user_pos" v-on:change="clearErrors()">
                                    <option :value="pos" v-for="(u, pos) in local_users">{{(pos == 0?"Add a New User" : u.name + (u.agency != null ? ("(" + u.agency.name + ")") : ""))}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h1 class="mb-2">{{ (user_pos > 0 || local_users.length == 1 && local_users[0].id > 0? "Update" : "Add" ) }} Team Member</h1>
        </div>

        <div class="card box-content mb-3">
            <div class="card-body">
                <h4> User Information</h4>
                <div class="row">
                    <div class="col-sm-8">
                        <!-- Name -->
                        <div class="row">
                            <div class="col-sm mb-3">
                                <label class="form-label">First Name</label>
                                <input type="text" class="form-control" @change="errors.first_name = ''"
                                    :class="((errors.first_name != '' )? 'is-invalid':'')"
                                    name="first_name" v-model="local_users[user_pos].first_name">
                                <div class="text-danger invalid-feedback" v-if="errors.first_name != ''">
                                    {{errors.first_name}}
                                </div>
                            </div>
                            <div class="col-sm mb-3">
                                <label class="form-label">Last Name</label>
                                <input type="text" class="form-control" @change="errors.last_name = ''"
                                    :class="((errors.last_name != '' )? 'is-invalid':'')"
                                    name="last_name" v-model="local_users[user_pos].last_name">
                                <div class="text-danger invalid-feedback" v-if="errors.last_name != ''">
                                    {{errors.last_name}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3" v-if="Array.isArray(agencies)">
                            <label class="form-label">Agency</label>
                            <select class="form-select"  v-model="local_users[user_pos].agency_id" >
                                <option :value="a.id" v-for="a in agencies" >{{a.name}}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" :class="((errors.email != '' )? 'is-invalid':'')"
                                name="email" v-model="local_users[user_pos].email"  v-on:change="validateEmail()">
                            <div class="text-danger invalid-feedback" v-if="errors.email != ''">
                                {{errors.email}}
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">User Role</label>
                            <select class="form-select"  v-model="local_users[user_pos].user_type" @change="errors.user_type = ''" 
                                :class="((errors.user_type != '' )? 'is-invalid':'')" >
                                <option value="admin" v-if="user.user_type=='admin'">Avid Admin</option>
                                <option value="agency_admin">Admin</option>
                                <option value="user">User</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.user_type != ''">
                                {{errors.user_type}}
                            </div>
                        </div>
                    </div>
                    <div class="col profile-image">
                        <div class="row"><div class="col">
                            <!-- Photo Preview-->
                            <label class="form-label">Profile Image</label>
                            <span :data-name='local_users[user_pos].id' id="user_id"></span>
                            <vue-dropzone ref="'dropzone'" id="dropzone" class="profile-dropzone"
                                v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                                :options="dropzoneOptions" :awss3="awss3" :style="'background-image: url(' +local_users[user_pos].image+')'" ></vue-dropzone>
                        </div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Clients</h4>
                <div class="row">
                    <div class="col-sm-5">
                        <label class="form-label">Client Name</label>
                        <select class="form-select" id="client-select" v-model="role.client" placeholder="Client" 
                            :class="((errors.clients != '' )? 'is-invalid':'')">
                            <option value="" disabled selected hidden>Select the client...</option>
                            <option v-for="i in local_clients" v-bind:value="i.id">{{i.name}}</option>
                        </select>
                    </div>
                    <div class="col-sm-2">
                        <label class="form-label w-100">&nbsp;</label>
                        <button type="button" class="btn btn-secondary"
                                @click.prevent="addRole">
                                <span><i class="fas fa-fw fa-plus"></i>Add</span>
                        </button>
                    </div>
                </div>
                <div class="text-danger invalid-feedback" v-if="errors.clients != ''">
                    {{errors.clients}}
                </div>
                 <div class="row mt-3" v-if="local_users[user_pos].clients.length > 0" >
                    <div class="col">
                        <span class="label label-default tag" v-for="client in local_users[user_pos].clients">
                            <i class="fa fa-remove me-2 ms-0" id="remove-role" v-on:click="removeClient(client.id)"></i>{{client.name}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Success Message -->
                <div class="alert alert-success mb-3" v-if="form.successful">
                    The user's information has been updated!
                </div>
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an error saving your information.
                </div>
                <!-- Update Button -->
                <div class="mb-3">
                    <button type="submit" class="btn btn-primary btn-big"
                            @click.prevent="update"
                            :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> {{ (user_pos > 0 || local_users.length == 1 && local_users[0].id > 0? "Update" : "Add Team Member" ) }}
                    </button>
                    <button type="button" class="btn btn-secondary btn-big ms-2" v-on:click="deleteUser()"v-if="user_pos > 0">
                        <i class="fa fa-btn fa-trash"></i> Delete</button>
                    <button type="button" class="btn btn-secondary btn-big ms-2" v-on:click="back()" v-if="local_users.length == 1">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Swal from 'sweetalert2';
    export default {
        props: ['user', 'clients', 'users', 'agencies'],
        components: {
            vueDropzone: vue2Dropzone,
            Swal
        },
        data() {
            return {

                dropzoneOptions: {
                    url: "#",
                    resizeWidth: 512,
                    resizeHeight: 512,
                    resizeMethod: 'crop',
                    acceptedFiles: "image/*",
                    dictDefaultMessage: "Click or Drag/Drop a new profile image here",
                    renameFile: function (file) {
                        let new_name = "users/" + Date.now() + "_" + file.name;
                        return new_name;
                    },
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> DROP IMAGE TO UPLOAD",
                    clickable: true
                },
                awss3: {
                      signingURL: '/api/user/get_signed_url', //Where you will get signed url
                      headers: {
                        'X-Requested-With':'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                      },
                      params : {},
                      sendFileToServer : false //If you want to upload file to your server along with s3
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    email: "",
                    first_name: "",
                    last_name: "",
                    user_type: "",
                    clients: ""
                },
                user_pos: 0,
                local_users: [],
                role: {
                    client: ""
                },
                local_clients: []
            };
        },
        beforeMount() {
            this.local_clients = this.clients;
            //Sort local clients by the name attribute
            this.local_clients.sort((a, b) => (a.name > b.name) ? 1 : -1);
            //If the users is an object
            if(Array.isArray(this.users) == false){
                this.local_users = [this.users];
                //If it is a new user
                if(this.local_users[0].clients == null){
                    this.local_users[0]= this.getNewUser();
                    this.local_users[0].agency_id = this.agencies.id;
                }
            }else{
                this.local_users = this.users;
                this.local_users.unshift(this.getNewUser());
            }
        },
        methods: {
            getNewUser() {
                return {
                        id: -1,
                        url: null,
                        name: "",
                        first_name: "",
                        last_name: "",
                        organization: "",
                        agency_id: -1,
                        email: "",
                        photo_url: "",
                        image: "/img/icons/dialexa-icons/user-edit.svg",
                        user_type: "",
                        clients:[]
                    };
            },
            clearErrors() {
                this.errors.email = "";
                this.errors.first_name = "";
                this.errors.last_name = "";
                this.errors.user_type = "";
                this.errors.clients = "";
            },
            validateEmail() {
                var self = this;
                window.axios.post('/api/user/check_email', this.local_users[this.user_pos])
                  .then(response => {
                    if(response.status == 200) {
                        if(response.data.exists) {
                            self.errors.email = 'The email entered is already attached to a user at a different agency.';
                        }
                        else
                            self.errors.email = '';
                    }
                  });
            },
            checkErrors() {
                if(this.local_users[this.user_pos].first_name == null || this.local_users[this.user_pos].first_name == "")
                    this.errors.first_name = "First name is required.";
                else
                    this.errors.first_name = "";

                if(this.local_users[this.user_pos].last_name == null || this.local_users[this.user_pos].last_name == "")
                    this.errors.last_name = "Last name is required.";
                else
                    this.errors.last_name = "";

                if(this.local_users[this.user_pos].user_type == null || this.local_users[this.user_pos].user_type == "")
                    this.errors.user_type = "User type is required.";
                else
                    this.errors.user_type = "";

                if(this.local_users[this.user_pos].email == null || this.local_users[this.user_pos].email == "")
                    this.errors.email = "Email is required.";
                else if(this.errors.email != 'The email entered is already attached to a different agency.')
                    this.errors.email = "";

                //If there are no clients selected
                if(this.local_users[this.user_pos].clients.length == 0)
                    this.errors.clients = "You must select at least one client for this team member.";
                else
                    this.errors.clients = "";
            },
            update() {
                var self = this
                this.form.error = false;

                this.checkErrors();
                if(this.hasError()) {
                    this.form.error = true;
                    return;
                }

                //If it is an agency making the user
                if(Array.isArray(this.agencies) == false) {
                    this.local_users[this.user_pos].agency_id = this.agencies.id;
                    this.local_users[this.user_pos].organization = this.agencies.name;
                }
                else if(this.local_users[this.user_pos].agency_id != -1)
                    //Set the organization to be the agency name they have selected
                    for(let i = 0; i < this.agencies.length; i++){
                        if(this.agencies[i].id == this.local_users[this.user_pos].agency_id) {
                            this.local_users[this.user_pos].organization = this.agencies[i].name;
                            break;
                        }
                    }


                this.form.busy = true;

                //Make a new user
                if(this.local_users[this.user_pos].id == -1) {

                    window.axios.post('/api/user', this.local_users[this.user_pos])
                      .then(response => {
                        self.form.busy = false;

                        if(this.local_users.length == 1){
                            window.location.href = '/admin/agency/team';
                        }
                        else{
                            
                            //Add the new user to the list and set the dropdown
                            self.local_users.push(response.data.data);

                            //Reset the "New User" option
                            self.local_users.shift();
                            self.local_users.unshift(self.getNewUser());

                            self.user_pos = self.local_users.length -1;

                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false;} , 5000);
                        }
                      }).catch(error => {
                        // Handle errors here
                        if (error.response.status === 422) {
                          self.form.error = true;
                          self.errors.email = 'The email entered is not in the correct format.';
                        }
                        // Handle errors here
                        else if (error.response.status === 412) {
                          self.form.error = true;
                          self.errors.email = 'The user is a part of a different organization. You can only be a part of one group.';
                        }
                      });
                }
                else {
                    window.axios.put('/api/user/' + this.local_users[this.user_pos].id, this.local_users[this.user_pos])
                      .then(response => {
                        if(this.local_users.length == 1){
                            window.location.href = '/admin/agency/team';
                        }
                        else {
                            self.form.busy = false;
                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                        
                      }).catch(error => {
                        // Handle errors here
                        if (error.response.status === 422) {
                          self.form.error = true;
                          self.errors.email = 'The email entered is not in the correct format.';
                        }
                      });
                }

            },
            hasError() {
                return this.errors.email != "" || this.errors.first_name != ""  || this.errors.last_name != ""|| this.errors.user_type != ""
                    || this.errors.clients != "";
            },
            s3UploadSuccess(s3ObjectLocation){
                //Stupid Amazon URL encoding my folder
                s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
                this.local_users[this.user_pos].photo_url = s3ObjectLocation;
                this.local_users[this.user_pos].image = s3ObjectLocation;
                //Only call this if they've saved the client already
                if(this.local_users[this.user_pos].id != -1)
                    this.update();
            },
            removeClient(client_id) {

                //Now remove them from the user array.
                for(var i = 0; i < this.local_users[this.user_pos].clients.length; i++ ){
                    if(this.local_users[this.user_pos].clients[i].id == client_id ) {
                        this.local_users[this.user_pos].clients.splice(i, 1);
                        break;
                    }
                }
            },
            addRole() {
                var self = this;

                if(this.role.user == "")
                    return;

                //Make users an array (just in case)
                if(this.local_users[this.user_pos].clients == null)
                    this.local_users[this.user_pos].clients = [];

                //See if the role already exists before trying to add a new one
                for(var i = 0; i < this.local_users[this.user_pos].clients.length; i++ ){
                    if(this.local_users[this.user_pos].clients[i].id == this.role.client )
                        //If it does exist, just exit the function
                        return;
                }

                this.errors.clients = "";

                //Find the user's name
                var client_name = "";
                for(var i = 0; i < this.local_clients.length; i++)
                    if(this.local_clients[i].id == this.role.client)
                        client_name = this.local_clients[i].name;

                //Add them to the "users" array
                this.local_users[this.user_pos].clients.push({
                    id: this.role.client,
                    name: client_name
                })

                //Clear the drop down menus
                this.role.client = "";
            },
            deleteUser() {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete " + self.local_users[self.user_pos].name + "?",
                  text: "This user will be removed from all clients and will no longer be able to log into Avid AI.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/user/' + self.local_users[self.user_pos].id)
                        .then(response => {
                            if (response.status == 200) {
                                //self.getSystemArray(system.system_id).$remove(system);
                                //console.log('deleted',response.data);
                                Swal.fire({
                                    title: "Deleted!",
                                    text: self.local_users[self.user_pos].name + " has been deleted.",
                                    icon: "success",
                                    iconColor:  "#46705F",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    self.local_users.splice(self.user_pos, 1);
                                    self.user_pos = 0;

                                });
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });
            },
            back() {
                //Redirect back to agency admin
                window.location.href = '/admin/agency/team';
            }
        }
    }
</script>
