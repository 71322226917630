<template>
    <div class="modal fade modal-lg" id="cost-result-modal"
        aria-labelledby="cost-result-modal" aria-hidden="true">
        <div class="modal-dialog"  :class="modal_size">
            <div class="modal-content">
                <div class="modal-header border-bottom-0 pb-0">
                    <div class="text-center w-100">
                        <button type="button" class="btn btn-none float-end" data-bs-dismiss="cost-result-modal" @click="closeModal()">
                            <i class="fa fa-close"></i>
                        </button>
                        <button type="button" class="btn btn-none float-end" @click="toggleModalSize()">
                            <i class="fa" :class="(modal_size == 'modal-xl' ? 'fa-expand' : 'fa-compress')"></i>
                        </button>
                        <h4 class="mt-2">Add a {{modal_version}} Filter</h4>
                    </div>
                </div>
                <div class="modal-body pt-0 pb-0">

                    <h4 class="modal-title text-center"></h4>

                    <div class="mb-3" >
                        <label class="form-label">Data Source</label>
                        <select class="form-select"  v-model="local_filter.data_source"
                            :disabled="local_filter.id != null">
                            <option v-for="s in sources" :value="s.key">{{s.value}}</option>
                        </select>
                    </div>
                    <!-- Description / Cost Type Row -->
                    <div class="row">
                        <div class="col">
                            <div class="mb-3" >
                                <label class="form-label">Description</label>
                                <input type="text" class="form-control" name="title"
                                    v-model="local_filter.title"
                                    :class="((errors.title != '' )? 'is-invalid':'')" >

                                <div class="text-danger invalid-feedback" v-if="errors.title != ''">
                                    {{errors.title }}
                                </div>
                            </div>
                        </div>
                        <div class="col-4" v-if="modal_version=='Cost'">
                            <div class="mb-3" >
                                <label class="form-label">Cost Type</label>
                                <select class="form-select"  v-model="local_filter.cost_type" >
                                    <option value="media_cost">Media Cost</option>
                                    <option value="project_cost">Project Cost</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- Cost Value and Date Row -->
                    <div class="row"  v-if="local_filter.data_source == 'manual' && modal_version =='Cost'">
                        <div class="col">
                            <div class="mb-3">
                                <label class="form-label">Value</label>
                                <div class="input-group" :class="((errors.value != '' )? 'is-invalid':'')">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control"
                                        v-model="local_filter.value"  @keypress="isNumber($event)"
                                        v-on:paste="cleanUpPaste"
                                        :class="((errors.value != '' )? 'is-invalid':'')" >

                                    <div class="text-danger invalid-feedback"
                                        v-if="errors.value != ''">
                                        {{errors.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3" >
                                <label class="form-label">Date</label>
                                <div class="input-group" :class="((errors.date != '' )? 'is-invalid':'')" >
                                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                    <datepicker v-model="local_filter.date" :use-utc="true" format="MMM d, yyyy"  input-class="form-control" :class="((errors.date != '' )? 'is-invalid':'')" ></datepicker>
                                    <div class="text-danger invalid-feedback" v-if="errors.date != ''">
                                        {{errors.date }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Result Values Row -->
                    <div v-else-if="local_filter.data_source == 'manual' && modal_version =='Result'">
                        <div class="row" >
                            <div class="col">
                                <div class="mb-3" >
                                    <label class="form-label">Date</label>
                                    <div class="input-group" :class="((errors.date != '' )? 'is-invalid':'')" >
                                        <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                        <datepicker v-model="local_filter.date" :use-utc="true" format="MMM d, yyyy"  input-class="form-control" :class="((errors.date != '' )? 'is-invalid':'')" ></datepicker>
                                        <div class="text-danger invalid-feedback" v-if="errors.date != ''">
                                            {{errors.date }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">Emails</label>
                                    <input type="text" class="form-control"
                                        @keypress="isNumber($event)" v-on:paste="cleanUpPaste"
                                        v-model="local_filter.emails"
                                        :class="((errors.emails != '' )? 'is-invalid':'')" >
                                    <div class="text-danger invalid-feedback"
                                        v-if="errors.emails != ''">
                                        {{errors.emails }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">Gifts</label>
                                    <input type="text" class="form-control"
                                         @keypress="isNumber($event)" v-on:paste="cleanUpPaste"
                                         v-model="local_filter.gifts"
                                        :class="((errors.gifts != '' )? 'is-invalid':'')" >

                                    <div class="text-danger invalid-feedback"
                                        v-if="errors.gifts != ''">
                                        {{errors.gifts }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">New Donors</label>
                                    <input type="text" class="form-control"
                                         @keypress="isNumber($event)" v-on:paste="cleanUpPaste"
                                         v-model="local_filter.new_donors"
                                        :class="((errors.new_donors != '' )? 'is-invalid':'')" >
                                    <div class="text-danger invalid-feedback"
                                        v-if="errors.new_donors != ''">
                                        {{errors.new_donors }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">Revenue</label>
                                    <div class="input-group " :class="((errors.revenue != '' )? 'is-invalid':'')" >
                                        <span class="input-group-text">$</span>
                                        <input type="text" class="form-control"
                                             @keypress="isNumber($event)" v-on:paste="cleanUpPaste"
                                             v-model="local_filter.revenue"
                                            :class="((errors.revenue != '' )? 'is-invalid':'')" >

                                        <div class="text-danger invalid-feedback"
                                            v-if="errors.revenue != ''">
                                            {{errors.revenue }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">Recurring Revenue</label>
                                    <div class="input-group"
                                            :class="((errors.recurring_revenue != '' )? 'is-invalid':'')">
                                        <span class="input-group-text">$</span>
                                        <input type="text" class="form-control"
                                             @keypress="isNumber($event)" v-on:paste="cleanUpPaste"
                                             v-model="local_filter.recurring_revenue"
                                            :class="((errors.recurring_revenue != '' )? 'is-invalid':'')" >

                                        <div class="text-danger invalid-feedback"
                                            v-if="errors.recurring_revenue != ''">
                                            {{errors.recurring_revenue }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="modal_version!= null">
                        <div class="col">
                            <label class="form-label">Inclusion Filter Logic</label>
                            <div class="mb-3">
                                <query-inclusion-builder ref="filter" :client="client" v-model="local_filter.value"
                                    :include_trans="local_filter.data_source == 'warehouse'" :source="(isNaN(local_filter.data_source) ? local_filter.data_source : 'dataset')"
                                    :data_source_id="(isNaN(local_filter.data_source) ? null : local_filter.data_source)"></query-inclusion-builder>
                            </div>
                        </div>
                    </div>
                    <!-- Description / Cost Type Row -->
                    <div class="row" v-show="local_filter.data_source == 'warehouse'">
                        <div class="col">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" v-model="local_filter.exclude_other_initiatives" id="exclude_other_initiatives" value="1">
                              <label class="form-check-label" for="exclude_other_initiatives">
                                Also exclude results that would be included in other initiatives
                                <i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="For initiatives that pull from the same source, their inclusion criteria would be treated as an exclusion in this initiative. For initiatives that pull from a different source, like Google Analytics, their raw results will be subtracted from this total."></i>
                              </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="form.error">
                        <div class="col">
                            <div class="alert alert-danger">
                                There was an issue saving your entry. Check the fields above to get more information.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-start border-top-0">
                    <button type="button" class="btn btn-primary" :disabled="form.saving"
                        @click.prevent="addFilter();"><span v-if="form.saving"><div class="spinner-border  spinner-border-sm float-left" role="status"> <span class="visually-hidden">Loading...</span></div> Saving</span><span v-else><i class="fa fa-btn fa-save"></i> Save</span></button>
                    <button type="button" class="btn btn-secondary" :disabled="form.saving"
                        @click="closeModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: { Datepicker },
        props: ['modal_version', 'initiative', 'client', 'filter'],
        data() {
            return {
                init: null,
                local_filter:null,
                query: null,
                errors :{
                    date: "",
                    title: "",
                    value: "",
                    emails: "",
                    gifts: "",
                    revenue: "",
                    new_donors: "",
                    recurring_revenue: ""
                },
                modal_size: 'modal-xl',
                sources: [],
                form: {
                    saving: false,
                    error: false
                },
            };
        },
        beforeMount() {
            this.init = this.initiative;
            this.local_filter = this.filter;
        },
        watch: {
            modal_version(old_pv, new_pv) {
                //If they closed the modal, then wipe the filter
                if(old_pv != null) {
                    this.createDataSourceArray();
                    this.local_filter = this.filter;
                }
            }
        },
        methods: {

            toggleModalSize() {
                if(this.modal_size == 'modal-fullscreen')
                    this.modal_size = 'modal-xl';
                else
                    this.modal_size = 'modal-fullscreen';
            },
            createDataSourceArray() {
                this.sources = [];

                var self = this;

                window.axios.get('/api/data_set/'+this.client.url)
                  .then(response => {
                    if(response.status == 200) {
                        var arr = response.data.data;
                        for(var i = 0; i < arr.length; i++) {
                            if(self.modal_version.toLowerCase() == arr[i].source_type && arr[i].table_name != null)
                                self.sources.push({
                                    'key': arr[i].id,
                                    'value': arr[i].name
                                });
                        }

                        self.local_filter = self.filter;
                        self.addManualSources();
                    }
                  });
            },
            addManualSources() {
                if(this.modal_version=='Cost')
                    this.sources.push({
                        'key': "acq_dashboard",
                        'value': "Acquisition Dashboard"
                    });
                else {
                    this.sources.push({
                        'key': "warehouse",
                        'value': "Data Warehouse"
                    });
                    this.sources.push({
                        'key': "google_analytics",
                        'value': "Google Analytics"
                    });
                }
                this.sources.push({
                    'key': "manual",
                    'value': "Manual"
                });
            },
            getDataSourceName() {
                for(var i = 0; i < this.sources.length; i++)
                    if(this.local_filter.data_source == this.sources[i].key)
                        return this.sources[i].value;

                return "";
            },
            addFilter() {
                if(this.local_filter.data_source == 'manual' && this.modal_version == 'Cost')
                    this.addManualCost();
                else if(this.modal_version == 'Cost')
                    this.addCostAsFilter();
                else if(this.local_filter.data_source == 'manual' && this.modal_version == 'Result')
                    this.addManualResult();
                else if(this.modal_version == 'Result')
                    this.addResultAsFilter();

            },
            addResultAsFilter() {
                this.form.error = false;

                //Validate the form
                if(!this.filterLogicIsValid()){
                    this.form.error = true;
                    return false;
                }

                var result = {
                    description: this.local_filter.title,
                    data_source: this.local_filter.data_source,
                    data_source_name: this.getDataSourceName(),
                    date: this.local_filter.date,
                    initiative_id: this.initiative.id,
                    emails: 0,
                    gifts: 0,
                    revenue: 0,
                    new_donors: 0,
                    recurring_revenue: 0,
                    lifetime_value: 0,
                    value: this.local_filter.value,
                    exclude_other_initiatives: this.local_filter.exclude_other_initiatives
                };
                this.saveResult(result);
            },
            addManualResult() {
                this.form.error = false;

                //Validate the form
                if(!this.manualResultFormIsValid()){
                    this.form.error = true;
                    return false;
                }

                var result = {
                    description: this.local_filter.title,
                    data_source: 'manual',
                    data_source_name: "Manual",
                    date: this.local_filter.date,
                    initiative_id: this.initiative.id,
                    emails: this.local_filter.emails,
                    gifts: this.local_filter.gifts,
                    revenue: this.local_filter.revenue,
                    new_donors: this.local_filter.new_donors,
                    recurring_revenue: this.local_filter.recurring_revenue,
                    lifetime_value: parseFloat(this.local_filter.revenue) + parseFloat(this.local_filter.recurring_revenue),
                    exclude_other_initiatives: this.local_filter.exclude_other_initiatives
                };

                this.saveResult(result);
            },
            saveResult(result) {

                var self = this;
                this.form.saving = true;
                //If it is a new filter
                if(this.local_filter.id == null) {

                    window.axios.post('/api/result', result)
                      .then(response => {

                        self.form.saving = false;
                        if(response.status == 200) {
                            self.$emit('update:initiative', response.data.initiative);
                            self.closeModal();
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }
                  //Just update the old filter
                  else {
                    window.axios.put('/api/result/'+this.local_filter.id, result)
                      .then(response => {

                        self.form.saving = false;
                        if(response.status == 200) {
                            //Update the parent variables to close the modal window
                            self.$emit('update:initiative', response.data.initiative);
                            self.closeModal();

                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }
            },
            addManualCost() {
                this.form.error = false;

                //Validate the form
                if(!this.formIsValid()){
                    this.form.error = true;
                    return false;
                }

                var cost = {
                    client_id: this.client.id,
                    cost_type: this.local_filter.cost_type,
                    description: this.local_filter.title,
                    data_source: 'manual',
                    data_source_name: "Manual",
                    amount: this.local_filter.value,
                    date: this.local_filter.date,
                    initiative_id: this.initiative.id
                };
                this.saveCost(cost);
            },

            addCostAsFilter() {
                this.form.error = false;

                //Validate the form
                if(!this.filterLogicIsValid()){
                    this.form.error = true;
                    return false;
                }

                var cost = {
                    client_id: this.client.id,
                    cost_type: this.local_filter.cost_type,
                    description: this.local_filter.title,
                    data_source: this.local_filter.data_source,
                    data_source_name: this.getDataSourceName(),
                    amount: 0,
                    date: this.local_filter.date,
                    initiative_id: this.initiative.id,
                    value: this.local_filter.value
                };

                this.saveCost(cost);
            },
            saveCost(cost) {

                var self = this;
                this.form.saving = true;

                //If it is a new filter
                if(this.local_filter.id == null) {

                    window.axios.post('/api/cost', cost)
                      .then(response => {
                        self.form.saving = false;
                        if(response.status == 200) {
                            self.form.successful = true;

                            self.$emit('update:initiative', response.data.initiative);
                            self.closeModal();
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }
                  //Just update the old filter
                  else {
                    window.axios.put('/api/cost/'+this.local_filter.id, cost)
                      .then(response => {
                        self.form.saving = false;
                        if(response.status == 200) {
                            self.form.successful = true;

                            //Update the parent variables to close the modal window
                            self.$emit('update:initiative', response.data.initiative);
                            self.closeModal();

                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }
            },
            formIsValid() {
                var is_valid = true;

                //First reset the errors
                this.errors.title = "";
                this.errors.date = "";
                this.errors.value = "";

                if(this.local_filter.title == ""){
                    this.errors.title = "Please enter a description."
                    is_valid = false;
                }
                if(this.local_filter.date == null){
                    this.errors.date = "Please select a date."
                    is_valid = false;
                }
                if(this.local_filter.value == "" || this.local_filter.value == null || isNaN(this.local_filter.value)){
                    this.errors.value = "Please enter a numeric value."
                    is_valid = false;
                }

                return is_valid;

            },
            filterLogicIsValid() {
                var is_valid = true;

                //First reset the errors
                this.errors.title = "";
                this.errors.date = "";
                this.errors.value = "";

                //If they haven't named the filter
                if(this.local_filter.title == ""){
                    this.errors.title = "Please enter a description."
                    is_valid = false;
                }
                //If they didn't enter any logic
                if(this.local_filter.value == "" || this.local_filter.value == null){
                    this.errors.value = "Please select a column and value to use with the filter."
                    is_valid = false;
                }
                //If they left one of the inputs blank in the logic units
                else {
                    if(is_valid)
                        var is_valid = this.$refs.filter.isFormValid();
                    else
                        this.$refs.filter.isFormValid();

                    if(!is_valid) {
                        this.errors.value = "Please enter values for all of the inputs above."
                    }
                }

                return is_valid;

            },

            manualResultFormIsValid() {
                var is_valid = true;

                //First reset the errors
                this.errors.title = "";
                this.errors.date = "";
                this.errors.emails = "";
                this.errors.new_donors = "";
                this.errors.gifts = "";
                this.errors.revenue = "";
                this.errors.recurring_revenue = "";

                if(this.local_filter.title == ""){
                    this.errors.title = "Please enter a description."
                    is_valid = false;
                }
                if(this.local_filter.date == null){
                    this.errors.date = "Please select a date."
                    is_valid = false;
                }
                if(this.local_filter.emails == "") this.local_filter.emails = 0;
                else if(isNaN(this.local_filter.emails)){
                    this.errors.emails = "Please enter a valid number of emails."
                    is_valid = false;
                }

                if(this.local_filter.new_donors == "") this.local_filter.new_donors = 0;
                else if(isNaN(this.local_filter.new_donors)){
                    this.errors.new_donors = "Please enter a valid number of new donors."
                    is_valid = false;
                }

                if(this.local_filter.gifts == "") this.local_filter.gifts = 0;
                else if(isNaN(this.local_filter.gifts)){
                    this.errors.gifts = "Please enter a valid number of gifts."
                    is_valid = false;
                }

                if(this.local_filter.revenue == "") this.local_filter.revenue = 0;
                else if(isNaN(this.local_filter.revenue)){
                    this.errors.revenue = "Please enter a valid amount of revenue raised."
                    is_valid = false;
                }

                if(this.local_filter.recurring_revenue == "") this.local_filter.recurring_revenue = 0;
                else if(isNaN(this.local_filter.recurring_revenue)){
                    this.errors.recurring_revenue = "Please enter a valid amount of recurring donor revenue."
                    is_valid = false;
                }

                return is_valid;

            },
            cleanUpPaste: function(e) {

                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                var paste = (e.clipboardData || window.clipboardData).getData('text/plain')
                paste = paste.replace(/[^\d.-]/g, '');

                if (document.queryCommandSupported("insertText")) {
                  document.execCommand("insertText", false, paste);
                } else {
                  document.execCommand("paste", false, paste);
                }
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },

            closeModal() {
                var self = this;
                var myModalEl = document.getElementById('cost-result-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)

                myModalEl.addEventListener('hidden.bs.modal', function (event) {
                  self.$emit('update:modal_version', null);
                })

                modal.hide();
            }

        }
    }
</script>
