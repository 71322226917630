<template>
    <div class="files" >
        <div class="row">
            <div class="col">
                <h3>Upload a New Data File</h3>
            </div>
            <div class="col-sm-4 d-flex flex-row" id="public-links">
                <div class="input-group">
                    <button class="btn ms-auto "
                        data-bs-toggle="tooltip" title="Enabling a public link will allow you, or other users, to upload data files without having to log in. You'll be given a secure link and users will only be able to add new files; they cannot view or edit existing ones."
                        v-on:click="toggleLink()" :class="(local_conn && local_conn.connection_criteria.public_link == true)?'btn-secondary': 'btn-primary'">
                        <span v-if="local_conn && local_conn.connection_criteria.public_link == true">Disable</span><span v-else>Enable</span> Public Link
                    </button>
                    <transition name="slide-slow">

                        <input type="text" class="form-control border-primary bg-white ps-2 text-muted "
                            v-if="local_conn && local_conn.connection_criteria.public_link == true"
                            v-model="file_url + client.url"  @click="copyToClipboard()"
                            ref="clone" readonly v-on:focus="$event.target.select()">
                    </transition>

                </div>

                <div class="dropdown ms-2" id="settings-dropdown">
                    <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-gear me-0"></i>
                    </button>
                    <div class="dropdown-menu p-3" style="max-width: 300px;">
                        <form>
                            <div class="mb-3">
                                <label for="data_qualifier" class="form-label">Text Qualifier for Files <i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" title="For CSV or TXT files, enter the character used to enclose data fields containing the delimiter; it is typically double quotes (”)."></i></label>
                                <input type="text" class="form-control" id="data_qualifier" v-model="local_conn.connection_criteria.data_qualifier">
                            </div>
                            <button type="button" class="btn btn-primary" v-on:click.prevent="saveFileSettings()">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <p>Click on the box below or drag and drop a data file to add it to your Avid AI instance. The files are limited to csv, txt, and similar delimited text files.</p>
        <div class="card box-content mb-5">
            <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone"
                :options="dropzoneOptions"
                v-on:vdropzone-processing="onProcessing"
                v-on:vdropzone-success="onSuccess"
                v-on:vdropzone-sending="onSending"></vue-dropzone>
        </div>

        <div v-if="local_files.length > 0">
            <h3 >Existing Files</h3>
            <div class="card box-content mb-3">
                <div class="card-body p-0">
                    <div class="overflow-hidden rounded-top">
                        <div class="p-1 bg-dark"></div>
                    </div>
                    <table class="table gy-0 m-0 mb-2">
                        <thead class="table-dark">
                            <tr>
                                <th class='align-middle pt-0'>File Name</th>
                                <th class="align-middle pt-0 d-none d-md-table-cell">Source</th>
                                <th class="align-middle pt-0">Status</th>
                                <th class="align-middle pt-0">Data Set Name</th>
                                <th class="align-middle pt-0 d-none d-md-table-cell">Uploaded On</th>
                                <th class="align-middle pt-0" style="width:70px;">Actions</th>
                            </tr>
                        </thead>
                        <transition-group tag="tbody" name="table-row" class="table-border-bottom-0" >
                            <tr v-for="(f, index) in local_files" :key="f.id">
                                <td class='ps-3 align-middle'>
                                    {{f.name}}
                                </td>
                                <td class="align-middle d-none d-md-table-cell">
                                    {{f.source}}
                                </td>
                                <td class="align-middle text-nowrap" >
                                    <span style="cursor: pointer;" v-if="f.id >=0" :title="f.status_msg" :id="'tooltip' + f.id"
                                    :onmouseover="'$(\'#tooltip' + f.id+ '\').tooltip(\'show\');'" >
                                        <i class="fa-solid fa-circle me-2" :class="getColorClass(f.status)"></i> {{f.status | propercase}}
                                    </span>
                                    <span v-else>
                                        <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Processing...</span></div>
                                        <span class="ms-2">Processing...</span>
                                    </span>
                                </td>

                                <td class="align-middle">
                                    <span v-if="f.dataset != null" >
                                        {{f.dataset.name | propercase}}
                                    </span>
                                    <a class="btn btn-sm btn-primary" v-else-if = "f.id >= 0 && f.status == 'ready' && isDataFile(f)" :href="'/integrations/'+client.url+'/mapping/file/'+f.id"  >
                                        <i class="fa fa-btn fa-plus"></i> Create a Data Set
                                    </a>
                                </td>

                                <td class="align-middle d-none d-md-table-cell">{{ f.created_at | date }}</td>
                                <td class="align-middle text-center" v-if="f.id > -1">
                                    <div class="dropdown">
                                          <button class="btn btn-secondary" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-caret-down me-0"></i>
                                          </button>
                                          <ul class="dropdown-menu" >
                                            <li><a class="dropdown-item btn btn-link" v-if="f.status == 'error'" :href="'/integrations/'+ client.url +'/file_errors'" ><i class="fa fa-wrench me-2"></i>Fix the Error</a>
                                            </li>
                                            <li><a class="dropdown-item btn btn-link" v-if="f.dataset != null"  :href="'/integrations/'+ client.url +'/mapping/data_set/'+ f.dataset.id" ><i class="fa fa-edit me-2"></i>Edit Data Set</a>
                                            </li>
                                            <li v-if="isDataFile(f)"><a href="#" class="dropdown-item btn btn-link" data-bs-toggle="modal" data-bs-target="#preview-modal"
                                                @click="previewTable(f)"><i class="fa fa-eye me-2"></i>Preview File</a></li>
                                            <li v-if="withinThirtyDays(f)"><a class="dropdown-item btn btn-link" :href="'/integrations/'+ client.url +'/files/download/'+ f.id" target="_blank"><i class="fa fa-download me-2"></i>Download File</a></li>
                                            <li><a class="dropdown-item btn btn-link" @click="removeFile(f)" ><i class="fa fa-trash me-2 ms-1" ></i>Delete File</a></li>

                                          </ul>
                                    </div>

                                </td>
                                <td v-else></td>
                            </tr>
                        </transition-group>
                    </table>
                    <div class="text-center" v-if="local_files.length < local_conn.num_files" >
                        <button class="btn btn-secondary mt-3 mb-3"
                        v-on:click="loadFiles()" >Load More Files</button>
                    </div>
                </div>
            </div>
            <p class="mt-5"><em>Files are automatically deleted 30 days after the initial upload, however, any data processed will be retained until your account is closed or deletion is requested.</em></p>
        </div>
        <div class="modal fade" id="preview-modal" aria-labelledby="preview-modal" aria-hidden="true">

            <div class="modal-dialog " :class="modal_size">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" data-bs-dismiss="preview-modal" @click="closePreviewModal()">
                                <i class="fa fa-close"></i>
                            </button>
                            <button type="button" class="btn btn-none float-end" @click="toggleModalSize()">
                                <i class="fa" :class="(modal_size == 'modal-xl' ? 'fa-expand' : 'fa-compress')"></i>
                            </button>
                            <h4 class="mt-2">Preview File Contents</h4>


                        </div>
                    </div>
                    <div class="modal-body p-0">
                        <div v-if="typeof table_data === 'string' && table_data == ''" class="m-2 mb-4 text-center">
                            <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>
                            <span class="ms-2">Loading...</span>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length > 0'>
                            <div class="table-responsive gy-0 m-0 mt-0">
                                <table class="table gy-0 m-0">
                                    <thead class="table-dark">
                                        <tr>
                                            <th v-for="(row, index) in Object.keys(table_data[0])" class='align-middle pt-0'
                                                :class="(index==0) ? 'ps-3' : ''">{{originalHeader(row)}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr v-for="row in table_data" >
                                            <td v-for="(col, index) in row" :class="(index==0) ? 'ps-3' : ''">{{ col }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length == 0'>
                            <div class="alert alert-warning box-content m-2"  >
                                No data was returned.
                            </div>
                        </div>
                        <div v-else>
                            <div class="alert alert-danger box-content m-2" >
                                There was an error pulling the data:<br>
                                <em>{{table_data}}</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';
    import Swal from 'sweetalert2';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        components: {
            Swal,
            vueDropzone: vue2Dropzone,
        },

        props: ['files', 'client', 'connection'],
        data() {
            return {
                table_data: null,
                local_files: [],
                local_conn: null,
                current_file: null,
                modal_size: 'modal-xl',
                dropzoneOptions: {
                    url: "#",
                    method: 'put',
                    timeout: 0,
                    parallelUploads: 2,
                    dictDefaultMessage: "Click or Drag/Drop a File Here",
                    headers: {
                        'charset': "utf-8",
                        "Cache-Control": "",
                    },
                    withCredentials: false,
                    accept: function(file, done) {

                        var url_parts = window.location.pathname.split('/');
                        var client_url = url_parts[2];
                        this.options.headers['Content-Type'] = file.type;
                        window.axios.post( "/api/file_upload/get_signed_url", { url: client_url, file_name: Date.now() + "_" +file.name }).then(function(data) {

                           //I can't access the variable in the "data" property so I'll just save it to the html
                          if($("#dropzone").data("file_urls") == undefined)
                            $("#dropzone").data("file_urls", []);

                            //Save the URL for when it is actually sent in a second
                            var arr = $("#dropzone").data("file_urls");
                            arr[file.upload.uuid] = data.data.url;

                            $("#dropzone").data("file_urls", arr);
                            done();
                        });

                    },
                    init: function() {
                        const dz = this;

                        dz.on("processing", (file) => {
                            dz.options.headers["Content-Type"] = file.type;
                        });
                    },
                    maxFilesize: null,
                },
            };
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            }
        },
        computed: {
            file_url() {
                return window.location.protocol + '//'+window.location.host+'/f/';
            }
        },
        beforeMount() {
            this.local_files = this.files;
            this.local_conn = this.connection;
            //Set a default value for the data_qualifer
            if(this.local_conn.connection_criteria.data_qualifier == undefined)
                this.local_conn.connection_criteria.data_qualifier = '"';
        },
        methods: {
            isDataFile(file) {
                // If the file type is a csv, tab, tsv, or txt
                return file.status == 'ready' && ['csv', 'tsv', 'tab', 'txt'].includes(file.type);
            },
            withinThirtyDays(file) {
                //If the file.created_at is within the last 30 days
                return moment().subtract(30, 'days').isBefore(file.created_at) && file.gcs_processed_path != null;
            },
            copyToClipboard() {
              this.$refs.clone.focus();
              document.execCommand('copy');
              Swal.fire({
                  text: 'Copied!',
                  target: '#public-links',
                  customClass: {
                    container: 'position-absolute'
                  },
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 2000
                })
            },
            toggleLink() {
                if(this.local_conn.connection_criteria.public_link)
                    this.local_conn.connection_criteria.public_link = false;
                else
                    this.local_conn.connection_criteria.public_link = true;

                this.saveFileSettings();
            },
            saveFileSettings() {
                window.axios.post('/api/connection/'+this.local_conn.id, this.local_conn);
                let dropdown = document.getElementById('settings-dropdown');
                //Get all children that have a show class on them
                var children = dropdown.getElementsByClassName('show');
                //Remove that class from the children
                for(var i = children.length-1; i >= 0 ; i--)
                    children[i].classList.remove('show');
            },
            toggleModalSize() {
                if(this.modal_size == 'modal-fullscreen')
                    this.modal_size = 'modal-xl';
                else
                    this.modal_size = 'modal-fullscreen';
            },
            originalHeader(value) {
                //Do a foreach loop through this.current_file.columns with a key/val
                for (var key in this.current_file.columns) {
                    if(key == value)
                        return this.current_file.columns[key];
                }
                return value;
            },
            previewTable(file) {
                var self = this;
                this.current_file = file;
                this.table_data = "";
                this.client_id = this.client.id;

                window.axios.post('/api/bigquery/get_detailed_samples', {'data_source': 'file', 'data_source_id': file.id }).then(response => {
                    if(response.status == 200) {
                        self.table_data = response.data.samples;
                    }
                    else
                        self.table_data = response.data.error;
                  });
            },
            loadFiles() {
                var self = this;
                window.axios.get('/api/files/'+ this.client.url +"/list/" + this.local_files.length).then(response => {
                    if(response.data)
                        self.local_files = self.local_files.concat(response.data.files);
                });
            },
            removeFile(file) {
                var self  = this;
                Swal.fire({
                  title: "Are you sure you want to delete <em>" + file.name + "</em>?",
                  text: "This will remove the file from the system and prevent it from being used with future data processes.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                      if (result.isConfirmed) {
                        for(var key in self.local_files)
                                if(self.local_files[key].id == file.id) {
                                    self.local_files.splice(key, 1);
                                    break;
                                }
                        self.local_conn.num_files--;
                        window.axios.delete('/api/files/' + self.client.url + '/destroy/' + file.id);
                  } else if (result.isDenied) {

                  }
                });

            },
            onProcessing(file) {
                //Set the upload URL for this specific file
                var file_urls = $("#dropzone").data('file_urls');
                document.getElementById("dropzone").dropzone.options.url = file_urls[file.upload.uuid];

            },
            onSuccess( file, res ) {
                var data = new FormData();
                data.append("file_name", file.name);
                data.append("gcs_path", file.xhr.responseURL);
                data.append("client_url", this.client.url);

                //Remove it from the dropzone UI
                var fadeTarget = document.getElementsByClassName("dz-success")[0];
                var fadeEffect = setInterval(function () {
                    if (!fadeTarget.style.opacity) {
                        fadeTarget.style.opacity = 1;
                    }
                    if (fadeTarget.style.opacity > 0) {
                        fadeTarget.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                        document.getElementById("dropzone").dropzone.removeFile(file);
                    }
                }, 50);

                this.local_files.unshift({
                    id: Math.floor(Math.random() * -100000),
                    source: 'Upload',
                    status: "Processing",
                    name: file.name,
                    created_at: new Date().getTime()
                });
                this.local_conn.num_files++;

                var self = this;
                var id_to_remove = this.local_files[0].id;
                window.axios.post('/api/file_upload/new_file', data).then(function(data) {
                    //Insert the file returned in data.file to the top of the local_files variable
                    for(var i = 0 ; i < self.local_files.length; i++)
                        if(self.local_files[i].id == id_to_remove) {
                            self.local_files[i] = data.data.file;
                            break;
                        }

                    self.$forceUpdate();
                });
            },
            onSending( file, xhr, formData ) {
                var _send = xhr.send;
                xhr.send = function() {
                  _send.call(xhr, file);
                };
            },
            getColorClass(word) {
                if(word == 'received' || word == 'processing')
                    return 'yellow';
                if(word == 'ready')
                    return 'green';

                return 'red';
            },
            closePreviewModal() {
                var myModalEl = document.getElementById('preview-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },

        }
    }
</script>