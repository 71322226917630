<template>
    <div class="annual-plan">
        <div class="border-bottom border-secondary border-3 mb-4 mt-5">
            <div class="row">
                <div class="col">
                    <h2 class="mb-2 pb-1">
                        <span >{{client.name}}:</span> <em>{{plan.name}}</em>
                    </h2>
                </div>
                <div class="col-2 d-none d-sm-block" v-if="plans.length > 1">
                    <button class="btn form-select btn-field pe-5 bg-white"
                        id="version-dropdown" href="#" type="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Switch Version
                    </button>

                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="version-dropdown">
                        <!-- Add additional menu options here -->
                        <a class="dropdown-item" v-for="p in plans"
                            :href="'/planning/'+p.url+'/annual/'">{{ p.name }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-warning mb-3 box-content" v-if="plan.is_published_plan == 1">
                The plan being view is currently the <em>published plan</em>. This means you are not able to make any adjustments to the assumptions or initatives. If you'd like to make any updates, <a :href="'/planning/' + client.url + '/versions'">you will need to make a new version. You can do that here.</a>
        </div>
        <div class="row mb-3" v-if="plan.is_published_plan != 1">
            <div class="col-sm-6">
                <div class="card box-content">
                    <div class="card-body">
                        <div class="mb-3">
                            <label class="form-label">Add New Initiative</label>
                            <select class="form-select" name="initiative_type" v-model="initiative_type"
                                @change="add_initiative()">
                                <option value="-1">** Please select from the list **</option>
                                 <option v-for="init in init_types" :value="init.id">{{init.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Billing Schedule</h4>
                <button type="button" class="btn btn-primary btn-sm"
                    @click="calculate_results()"  v-if="plan.is_published_plan != 1">
                    Calculate Results <i class="fas fa-sync fa-spin ms-2"
                    v-if="calculating"></i></button>
                <button type="button" class="btn btn-secondary btn-sm" data-toggle="button"
                    aria-pressed="false" autocomplete="off" @click="make_print_ready();" >
                    <span class='show'>Show</span><span class='hide'>Hide</span> Print-Ready Version
                </button>

                <annual-plan-table :plan.sync="plan_copy" :client="client" :init_types="init_types" :current_month.sync="current_month"  :current_year.sync="current_year"
                     @notificationEvent="notification"></annual-plan-table>
            </div>
        </div>

        <annual-plan-results :plan.sync="plan_copy" :client="client" :results="result_set"
            :result_details="result_details_copy" v-if="result_set.length > 0" :current_month.sync="current_month"  :current_year.sync="current_year"></annual-plan-results>

        <transition name="slide">
            <div class="notification" v-if="notification_message != ''" :class="notification_class">
                <span v-html="notification_message"></span>
                <a class='btn' v-on:click="notification_message=''"><i class="fas fa-times-circle"></i></a>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: ['plan', 'client', 'results', 'init_types', 'result_details', 'plans'],
        data() {
            return {
                initiative_type: -1,
                result_set: null,
                result_details_copy: null,
                notification_message: "",
                notification_class:"",
                calculating: false,
                plan_copy: null,
                current_month: null,
                current_year: null
            };
        },
        beforeMount() {
            this.plan_copy = this.plan;
            this.result_set = this.results;
            this.result_details_copy = this.result_details;

            // set to current date or use something sent from the database (starting_point)
            this.current_year = parseInt(this.plan.start_month.substring(0, 4));
            this.current_month = parseInt(this.plan.start_month.substring(5, 7))-1;

            console.log(this.plan);
        },
        watch: {
            plan_copy: function(newVal, oldVal) {
                //If the plan changes, we need to reorder the initiatives in the results
                var inits = [];
                for(var i = 0; i < newVal.initiatives.length; i++) {
                    for(var j = 0; j < this.result_set.length; j++) {
                        var arr = this.result_set[j].initiative_ids.split(",");
                        if(arr.includes(""+newVal.initiatives[i].id)) {
                            this.result_set[j].priority = newVal.initiatives[i].priority;
                            this.result_set[j].sort_priority = newVal.initiatives[i].order;
                        }
                    }
                }
                this.result_set.sort((a,b) => (a.sort_priority > b.sort_priority) ? 1 : ((b.sort_priority > a.sort_priority) ? -1 : 0));
            }
        },

        methods: {
            make_print_ready() {
                if(document.getElementsByClassName("print-ready").length == 0)
                    document.getElementsByClassName('annual-plan')[0].classList.add("print-ready");
                else
                    document.getElementsByClassName('annual-plan')[0].classList.remove("print-ready");
            },

            add_initiative() {
                if(this.initiative_type != -1 )
                    window.location = '/planning/'+this.plan_copy.url+'/add_initiative/' + this.initiative_type;
            },
            notification(data) {
                this.notification_class = data.type;
                this.notification_message = data.message;

                setTimeout(function() {self.notification_message = '';}, 5000);
            },
            calculate_results() {
                var self = this;
                this.calculating = true;

                window.axios.get('/api/result/'+this.plan_copy.url+'/calc')
                  .then(response => {

                    if(response.status == 200) {
                        self.result_set = response.data.results;
                        window.axios.get('/api/result/'+this.plan_copy.url+'/get_details/projected')
                        .then(response => {
                            if(response.status == 200) {
                                self.calculating = false;

                                self.notification_class = "success";
                                self.notification_message = '<strong>Success:</strong> The results have been refreshed below.';
                                self.result_details_copy = response.data.results;

                                setTimeout(function() {self.notification_message = '';}, 5000);
                            }
                        });

                    }
                  }).catch(error => {
                    this.calculating = false;
                    self.notification_class = "warning";

                    if(error.response.data.error == 'bad_formula') {
                        self.notification_message = '<strong>Warning:</strong> The ' +
                            error.response.data.formula_type.replace("_"," ") + " formula in " + error.response.data.name + " was not able to be evaluated. <a href='/planning/" + self.plan_copy.url + "/get_initiative/" + error.response.data.initiative.url+"'>Click here to fix it.</a><br><br>Original Formula: "+ error.response.data.orig_formula + "<br>Evaluated Formula: " + error.response.data.formula;

                    } else if(error.response.data.error == 'negative_result') {
                        self.notification_message = '<strong>Warning:</strong> The ' +
                            error.response.data.formula_type.replace("_"," ") + " formula in " + error.response.data.name + " returned a negative results; this should not be possible. <a href='/planning/" + self.plan_copy.url + "/get_initiative/" + error.response.data.initiative.url+"'>Click here to fix it.</a><br><br>Original Formula: "+ error.response.data.orig_formula + "<br>Evaluated Formula: " + error.response.data.formula;
                    } else if(error.response.data.error == 'missing_cost') {
                        self.notification_message = '<strong>Warning:</strong> There is a cost missing from ' + error.response.data.name + ". <a href='/planning/" + self.plan_copy.url + "/get_initiative/" + error.response.data.initiative.url+"'>Click here to fix it.</a>";
                    } else if(error.response.data.error == 'missing_input') {
                        self.notification_message = '<strong>Warning:</strong> There is an input missing from ' + error.response.data.name + ". <a href='/planning/" + self.plan_copy.url + "/get_initiative/" + error.response.data.initiative.url+"'>Click here to fix it.</a>";
                    }
                  });

            },
        }
    }
</script>
