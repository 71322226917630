var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asset-page" }, [
    _c("h2", { staticClass: "mt-5" }, [
      _vm._v(
        "\n        Download " + _vm._s(_vm.local_assets_type.name) + "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "When you fill out the information below, Avid AI will automatically update the tracking for every link in all of the selected assets. It will then insert each email's content into the selected template and combine them into a single zip file. The download will begin immediately once you press the Submit button at the bottom."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content" }, [
      _c("div", { staticClass: "card-body" }, [
        Object.keys(this.inputs["templates"]).length > 1
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Email Template"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form_data.template,
                        expression: "form_data.template",
                      },
                    ],
                    staticClass: "form-select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form_data,
                          "template",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.inputs["templates"], function (val, key) {
                    return _c("option", { domProps: { value: key } }, [
                      _vm._v(_vm._s(val)),
                    ])
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row mb-3" },
          [
            _c(
              "label",
              {
                staticClass: "form-label",
                class: _vm.errors.assets != "" ? "is-invalid" : "",
              },
              [_vm._v("Select the Emails to Include:")]
            ),
            _vm._v(" "),
            _vm._l(_vm.assets, function (asset) {
              return _c("div", [
                _c("div", { staticClass: "checkbox-animated ms-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form_data.assets,
                        expression: "form_data.assets",
                      },
                    ],
                    attrs: {
                      id: "checkbox_animated_" + asset.id,
                      type: "checkbox",
                    },
                    domProps: {
                      value: asset.id,
                      checked: Array.isArray(_vm.form_data.assets)
                        ? _vm._i(_vm.form_data.assets, asset.id) > -1
                        : _vm.form_data.assets,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.form_data.assets,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = asset.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.form_data,
                                "assets",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form_data,
                                "assets",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form_data, "assets", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "checkbox_animated_" + asset.id } },
                    [
                      _c("span", { staticClass: "check" }),
                      _c("span", { staticClass: "box" }),
                      _vm._v(" " + _vm._s(asset.name) + " "),
                      _vm.form_data.template != ""
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/campaigns/" +
                                  _vm.client.url +
                                  "/preview/" +
                                  asset.id +
                                  "/" +
                                  _vm.form_data.template,
                                target: "_blank",
                              },
                            },
                            [_vm._v("(Preview)")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.errors.assets != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.assets) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("UTM Campaign"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.utm_campaign,
                  expression: "form_data.utm_campaign",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors.utm_campaign != "" ? "is-invalid" : "",
              attrs: { type: "text", name: "utm_campaign" },
              domProps: { value: _vm.form_data.utm_campaign },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form_data, "utm_campaign", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.utm_campaign != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.errors.utm_campaign) +
                      "\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col mb-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("UTM Medium")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.utm_medium,
                  expression: "form_data.utm_medium",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors.utm_medium != "" ? "is-invalid" : "",
              attrs: { type: "text", name: "utm_medium" },
              domProps: { value: _vm.form_data.utm_medium },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form_data, "utm_medium", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.utm_medium != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.errors.utm_medium) +
                      "\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("UTM Source")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.utm_source,
                  expression: "form_data.utm_source",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors.utm_source != "" ? "is-invalid" : "",
              attrs: { type: "text", name: "utm_source" },
              domProps: { value: _vm.form_data.utm_source },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form_data, "utm_source", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.utm_source != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.errors.utm_source) +
                      "\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col mb-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.utm_content,
                  expression: "form_data.utm_content",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "utm_content" },
              domProps: { value: _vm.form_data.utm_content },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form_data, "utm_content", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Other Tracking Codes"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.other_tracking,
                  expression: "form_data.other_tracking",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "other_tracking" },
              domProps: { value: _vm.form_data.other_tracking },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form_data, "other_tracking", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.has_errors
              ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
                  _vm._v(
                    "\n                        There was an error generating your email(s). See the messages above.\n                    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      return _vm.generateEmails()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-envelope" }),
                  _vm._v(" Generate Emails\n                        "),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("UTM Content "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-placement": "top",
          title:
            "The {email_name} value will get replaced with a URL-friendly version of the individual email's name.",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }