var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "segment-page p-2", attrs: { id: "segment-page" } },
    [
      _c("h1", { staticClass: "mb-3" }, [
        _vm._v(
          _vm._s(_vm.page_name) +
            " " +
            _vm._s(_vm.page_name == "Add" ? "an" : "the") +
            " Audience"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content p-0 mb-4" }, [
        _c("div", { staticClass: "card-body" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Data Source"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_segment.combo_source,
                      expression: "local_segment.combo_source",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: {
                    name: "source",
                    disabled: _vm.local_segment.id != null,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_segment,
                          "combo_source",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.changeSource()
                      },
                    ],
                  },
                },
                _vm._l(_vm.sources, function (s) {
                  return _c("option", { domProps: { value: s.value } }, [
                    _vm._v(_vm._s(_vm._f("propercase")(s.text))),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col mb-3",
                class: _vm.errors.name != "" ? "is-invalid" : "",
              },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Audience Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_segment.name,
                      expression: "local_segment.name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.name != "" ? "is-invalid" : "",
                  attrs: { type: "text", name: "name" },
                  domProps: { value: _vm.local_segment.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.local_segment, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.errors.name) +
                          "\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-2 mb-3" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: {
                    "use-utc": true,
                    "disabled-dates": _vm.disable_dates,
                    "open-date": new Date(),
                    format: "MMM d, yyyy",
                    "input-class": "form-control date",
                    "clear-button": true,
                    placeholder: "MM/DD/YYYY",
                    "bootstrap-styling": true,
                  },
                  model: {
                    value: _vm.local_segment.expires_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.local_segment, "expires_at", $$v)
                    },
                    expression: "local_segment.expires_at",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("h6", { staticClass: "mt-4" }, [_vm._v("Audience Definition")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { class: _vm.errors.value != "" ? "is-invalid" : "" },
                [
                  _vm.local_segment.data_source_id != null
                    ? _c("query-builder", {
                        attrs: {
                          client: _vm.client,
                          include_trans: true,
                          data_source_id: _vm.local_segment.data_source_id,
                          source: _vm.local_segment.source,
                          in_card: false,
                        },
                        model: {
                          value: _vm.local_segment.filter,
                          callback: function ($$v) {
                            _vm.$set(_vm.local_segment, "filter", $$v)
                          },
                          expression: "local_segment.filter",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.errors.value != ""
                ? _c(
                    "div",
                    { staticClass: "text-danger invalid-feedback mb-2" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.errors.value) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xl-4 d-none d-xl-block drill-down pe-3" },
              [
                _c("preview-table", {
                  attrs: {
                    client: _vm.client,
                    source: _vm.local_segment,
                    source_columns: _vm.db_columns,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.form.error
        ? _c("div", { staticClass: "row mt-2" }, [_vm._m(2)])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mb-4 mt-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-big save-button",
            attrs: { type: "button", disabled: _vm.form.saving },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.saveSegment()
              },
            },
          },
          [
            _vm.form.saving
              ? _c("span", [_vm._m(3), _vm._v(" Saving")])
              : _c("span", [
                  _c("i", { staticClass: "fa fa-btn fa-save" }),
                  _vm._v(" Save"),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "analysis-segment-modal-" + _vm.local_segment.id,
            "aria-labelledby": "analysis-segment-modal",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
                _c("div", { staticClass: "text-center w-100" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-none float-end close-modal",
                      attrs: {
                        type: "button",
                        "data-bs-dismiss":
                          "analysis-segment-modal-" + _vm.local_segment.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal(
                            "analysis-segment-modal-" + _vm.local_segment.id
                          )
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-close" })]
                  ),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mt-2" }, [
                    _vm._v(_vm._s(_vm.modal_action) + " Analysis Audience"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body pt-0 pb-0" },
                [
                  _c("analysis-segment-builder", {
                    attrs: {
                      client: _vm.client,
                      segment_selected: _vm.local_segment,
                      modal_action: _vm.modal_action,
                    },
                    on: {
                      updated: _vm.analyasisSegmentUpdated,
                      deleted: _vm.analyasisSegmentDeleted,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col mb-3" }, [
        _c("h6", { staticClass: "mt-2" }, [_vm._v("Audience Source")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Expires At "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-placement": "top",
          title:
            "Set a date for this audience to be automatically removed. This is helpful for data that is only being used for a short time period. The data will not be removed from any connected systems--it will simply stop syncing.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "alert alert-danger" }, [
        _vm._v(
          "\n                There was an issue saving your audience. Check the fields above to get more information.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Saving...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }