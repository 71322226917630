var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg w-100" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
        _c("div", { staticClass: "text-center w-100" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-none float-end",
              attrs: {
                type: "button",
                "data-bs-dismiss": "dimension-filter-modal",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeModal("dimension-filter-modal")
                },
              },
            },
            [_c("i", { staticClass: "fa fa-close" })]
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "mt-2" }, [
            _vm._v(_vm._s(this.action) + " a User-Defined Filter"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body pt-0" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Filter Label"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_dimensional_filter.label,
                  expression: "local_dimensional_filter.label",
                },
              ],
              staticClass: "form-control",
              class:
                _vm.local_filter_errors.filter_label != "" ? "is-invalid" : "",
              attrs: { type: "text", id: "filter-name-input" },
              domProps: { value: _vm.local_dimensional_filter.label },
              on: {
                change: function ($event) {
                  return _vm.clearErrorForField("filter_label")
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.local_dimensional_filter,
                    "label",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.local_filter_errors.filter_label != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.local_filter_errors.filter_label) +
                      "\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col mb-3" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Database Column Name"),
              ]),
              _vm._v(" "),
              _c("accordion-select-multi-tier-category", {
                class:
                  _vm.local_filter_errors.filter_column != ""
                    ? "is-invalid"
                    : "",
                attrs: { options: _vm.columns },
                on: {
                  input: function ($event) {
                    return _vm.loadDatabaseValues()
                  },
                  change: function ($event) {
                    return _vm.clearErrorForField("filter_column")
                  },
                },
                model: {
                  value: _vm.local_dimensional_filter.column,
                  callback: function ($$v) {
                    _vm.$set(_vm.local_dimensional_filter, "column", $$v)
                  },
                  expression: "local_dimensional_filter.column",
                },
              }),
              _vm._v(" "),
              _vm.local_filter_errors.filter_column != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.local_filter_errors.filter_column) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("Type")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_dimensional_filter.field_type,
                    expression: "local_dimensional_filter.field_type",
                  },
                ],
                staticClass: "form-select",
                class:
                  _vm.local_filter_errors.field_type != "" ? "is-invalid" : "",
                attrs: { id: "filter-type-select" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_dimensional_filter,
                        "field_type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.changeFieldType()
                    },
                  ],
                },
              },
              [
                _c(
                  "option",
                  { attrs: { value: "", disabled: "", selected: "" } },
                  [_vm._v("Select the filter type...")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { value: "single_select" } }, [
                  _vm._v("Single Select"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "multi_select" } }, [
                  _vm._v("Multiple Select"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "date_range" } }, [
                  _vm._v("Date Range"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "month_range" } }, [
                  _vm._v("Month Range"),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.local_filter_errors.field_type != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.local_filter_errors.field_type) +
                      "\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col mb-3" },
            [
              _vm.local_dimensional_filter.field_type == "single_select" ||
              _vm.local_dimensional_filter.field_type == "multi_select"
                ? _c("advanced-filter-dropdown", {
                    class:
                      _vm.local_filter_errors.default_value != ""
                        ? "is-invalid"
                        : "",
                    attrs: {
                      field_label: "Default Value",
                      options: _vm.db_values,
                      options_selected:
                        _vm.local_dimensional_filter.default_value == null
                          ? []
                          : _vm.local_dimensional_filter.default_value,
                      field_type: _vm.local_dimensional_filter.field_type,
                    },
                    on: {
                      searchInLongOptionList: _vm.searchInLongOptionList,
                      updateFilter: _vm.updateDimensionalFilterDefaultValue,
                    },
                  })
                : _vm.local_dimensional_filter.field_type == "date_range"
                ? _c("div", { staticClass: "d-flex w-100" }, [
                    _c(
                      "div",
                      { staticClass: "me-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Start Date"),
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            "input-class": "form-control date",
                            placeholder: "MM/DD/YYYY",
                            "bootstrap-styling": true,
                            "use-utc": true,
                            format: "M/d/yyyy",
                            "clear-button": true,
                          },
                          model: {
                            value:
                              _vm.local_dimensional_filter.default_value
                                .start_date,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.local_dimensional_filter.default_value,
                                "start_date",
                                $$v
                              )
                            },
                            expression:
                              "local_dimensional_filter.default_value.start_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("End Date"),
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            "input-class": "form-control date",
                            placeholder: "MM/DD/YYYY",
                            "bootstrap-styling": true,
                            "use-utc": true,
                            format: "M/d/yyyy",
                            "clear-button": true,
                          },
                          model: {
                            value:
                              _vm.local_dimensional_filter.default_value
                                .end_date,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.local_dimensional_filter.default_value,
                                "end_date",
                                $$v
                              )
                            },
                            expression:
                              "local_dimensional_filter.default_value.end_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.local_dimensional_filter.field_type == "month_range"
                ? _c("div", { staticClass: "d-flex w-100" }, [
                    _c(
                      "div",
                      { staticClass: "me-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Start Month"),
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            "input-class": "form-control date",
                            minimumView: "month",
                            maximumView: "month",
                            placeholder: "MM/YYYY",
                            "bootstrap-styling": true,
                            "use-utc": true,
                            format: "M/yyyy",
                            "clear-button": true,
                          },
                          model: {
                            value:
                              _vm.local_dimensional_filter.default_value
                                .start_date,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.local_dimensional_filter.default_value,
                                "start_date",
                                $$v
                              )
                            },
                            expression:
                              "local_dimensional_filter.default_value.start_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("End Month"),
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            "input-class": "form-control date",
                            minimumView: "month",
                            maximumView: "month",
                            placeholder: "MM/YYYY",
                            "bootstrap-styling": true,
                            "use-utc": true,
                            format: "M/yyyy",
                            "clear-button": true,
                          },
                          model: {
                            value:
                              _vm.local_dimensional_filter.default_value
                                .end_date,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.local_dimensional_filter.default_value,
                                "end_date",
                                $$v
                              )
                            },
                            expression:
                              "local_dimensional_filter.default_value.end_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("div", [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Default Value"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      class:
                        _vm.local_filter_errors.default_value != ""
                          ? "is-invalid"
                          : "",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.local_dimensional_filter.default_value,
                      },
                    }),
                  ]),
              _vm._v(" "),
              _vm.local_filter_errors.default_value != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.local_filter_errors.default_value) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm.show_group_name
            ? _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Group Name"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_dimensional_filter.group_name,
                          expression: "local_dimensional_filter.group_name",
                        },
                      ],
                      staticClass: "form-select",
                      class:
                        _vm.local_filter_errors.group_name != ""
                          ? "is-invalid"
                          : "",
                      attrs: { type: "text" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_dimensional_filter,
                              "group_name",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.clearErrorForField("group_name")
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.group_names, function (group) {
                      return _c("option", { domProps: { value: group } }, [
                        _vm._v(_vm._s(group)),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.local_filter_errors.group_name != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.local_filter_errors.group_name) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3", class: _vm.show_group_name ? "mt-4" : "" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_dimensional_filter.is_required,
                        expression: "local_dimensional_filter.is_required",
                      },
                    ],
                    staticClass: "form-check-input me-2",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.local_dimensional_filter.is_required
                      )
                        ? _vm._i(
                            _vm.local_dimensional_filter.is_required,
                            null
                          ) > -1
                        : _vm.local_dimensional_filter.is_required,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.local_dimensional_filter.is_required,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.local_dimensional_filter,
                                "is_required",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.local_dimensional_filter,
                                "is_required",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.local_dimensional_filter,
                            "is_required",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v("Required for this report"),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  type: "submit",
                  id: "save-button",
                  disabled: _vm.form.busy,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.updateFilterWithinGroup()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-btn fa-plus" }),
                _vm._v(
                  " " + _vm._s(this.action) + " Filter\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }