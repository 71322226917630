var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row onboarding" }, [
    _c("div", { staticClass: "col-lg-6" }, [
      _c("div", { staticClass: "d-lg-block d-none" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.has_agency
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "col-4 mt-3" }, [
                  _c("img", {
                    staticClass: "w-100",
                    attrs: { src: _vm.agency.logo },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-10 offset-sm-1 mt-3 text-center" },
                  [
                    _c("h5", [
                      _vm._v(
                        "Get access today to the platform used and endorsed by " +
                          _vm._s(_vm.agency.name) +
                          ". Maximize your year-end results with realtime insights from similar nonprofits."
                      ),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        !_vm.has_agency
          ? _c("div", [_vm._m(4), _vm._v(" "), _vm._m(5)])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-block d-lg-none mb-3" }, [
        _vm._m(6),
        _vm._v(" "),
        _vm.has_agency
          ? _c("h5", { staticClass: "mt-3 text-center" }, [
              _vm._v(
                "Get access today to the platform used and endorsed by " +
                  _vm._s(_vm.agency.name) +
                  "."
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "mt-3" }, [
          _vm._v(
            "Avid AI is rethinking the old nonprofit benchmark report. The FREE year end benchmark will give you live insights on your fundraising and marketing efforts compared to prior years and similar nonprofits."
          ),
        ]),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c("h5", { staticClass: "mt-3 text-center mb-5" }, [
          _vm._v(
            "The benchmarks go live on November 25, 2024—get ready today!"
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("Snowf", {
          attrs: {
            amount: 50,
            size: 5,
            speed: 1.5,
            wind: 0,
            opacity: 0.8,
            swing: 1,
            image: null,
            zIndex: null,
            resize: true,
            color: "#E5F3FE",
          },
        }),
        _vm._v(" "),
        _c("nav", { staticClass: "navbar navbar-expand-lg pb-0 mt-lg-5" }, [
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "filter-navbar" },
            },
            [
              _c("ul", { staticClass: "flex-row d-flex navbar-nav w-100" }, [
                _c("li", { staticClass: "col-3 nav-item px-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link border-bottom border-4",
                      class: { "border-primary": _vm.step_num >= 1 },
                      attrs: {
                        href: "/register?step=1",
                        role: "button",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.step_num == 1
                        ? _c("span", [_vm._v("Sign Up")])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "col-3 nav-item pe-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link border-bottom border-4",
                      class: {
                        "pe-none": _vm.step_num == 1,
                        "border-primary": _vm.step_num >= 2,
                      },
                      attrs: {
                        href: "/register?step=2",
                        role: "button",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.step_num == 2
                        ? _c("span", [_vm._v("Organization")])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "col-3 nav-item pe-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link border-bottom border-4",
                      class: {
                        "pe-none": _vm.step_num == 2,
                        "border-primary": _vm.step_num >= 3,
                      },
                      attrs: {
                        href: "/register?step=3",
                        role: "button",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.step_num == 3
                        ? _c("span", [_vm._v("Systems")])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "col-3 nav-item pe-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link border-bottom border-4",
                      class: {
                        "pe-none": _vm.step_num <= 3,
                        "border-primary": _vm.step_num >= 4,
                      },
                      attrs: {
                        href: "/register?step=4",
                        role: "button",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.step_num == 4
                        ? _c("span", [_vm._v("Connect")])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card box-content",
            class: _vm.step_num == 4 ? "mb-3" : "mb-5",
          },
          [
            _c("img", {
              staticClass: "holly-background-left",
              attrs: { src: "/img/images/holly-top-corner.png" },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "holly-background-right",
              attrs: { src: "/img/images/holly-bottom-corner.png" },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.step_num == 1
                  ? _c("onboarding-personal-info", {
                      attrs: { error: _vm.error },
                      on: { nextStep: _vm.nextStep },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step_num == 2
                  ? _c("onboarding-org-info", {
                      attrs: { agency: _vm.agency, user: _vm.local_user },
                      on: { nextStep: _vm.nextStep, reset: _vm.reset },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step_num == 3
                  ? _c("onboarding-current-systems", {
                      attrs: {
                        user: _vm.local_user,
                        client: _vm.local_client,
                        integrations: _vm.integrations,
                      },
                      on: { nextStep: _vm.nextStep, reset: _vm.reset },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step_num == 4
                  ? _c("onboarding-connect-crm", {
                      attrs: {
                        user: _vm.local_user,
                        client: _vm.local_client,
                        integrations: _vm.integrations,
                      },
                      on: { reset: _vm.reset },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm._m(8),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "text-primary mt-5 text-center" }, [
      _vm._v("Be a part of the nonprofit industry's first "),
      _c("u", [_vm._v("live")]),
      _vm._v(" year-end benchmark report"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 mt-3 offset-sm-1" }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: "/img/logo_png/logo_square_white@2x.png" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-2 mt-3 d-flex justify-content-center align-items-center",
      },
      [_c("span", { staticClass: "h1" }, [_vm._v("&")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-10 offset-sm-1" }, [
        _c("p", { staticClass: "mt-3" }, [
          _vm._v(
            "Avid AI is rethinking the old nonprofit benchmark report. The FREE year end benchmark will give you live insights on your fundraising and marketing efforts compared to prior years and similar nonprofits."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("PLUS, get a 45-day free trial ("),
          _c("em", [_vm._v("with no credit card necessary")]),
          _vm._v(
            ") to the full Avid platform with Insights, Scorecard, and more!"
          ),
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "mt-4 text-center" }, [
          _vm._v(
            "The benchmarks go live on November 25, 2024—get ready today!"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row d-none d-lg-block" }, [
      _c("div", { staticClass: "col-sm-10 offset-sm-1 mt-3" }, [
        _c("img", {
          staticClass: "w-100",
          attrs: { src: "/img/screenshot.png" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row d-none d-lg-block" }, [
      _c("div", { staticClass: "col-sm-10 offset-sm-1 mt-3" }, [
        _c("div", { staticClass: "card box-content" }, [
          _c("div", { staticClass: "card-body d-flex align-items-center" }, [
            _c("img", {
              staticClass: "avatar rounded-circle me-3",
              attrs: {
                src: "https://s3.us-west-2.amazonaws.com/nextafter-pulse/users%2F1726175140680_image+%2816%29.png",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                '"Avid seems to excel at integrating data from our current complicated donor data collection methods, turning it into actionable insights that lead to measurable improvements." '
              ),
              _c("small", [_vm._v("- Rebecca, FamilyLife Ministries")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "text-primary mt-5 text-center" }, [
      _vm._v("Be a part of the nonprofit industry's first "),
      _c("u", [_vm._v("live")]),
      _vm._v(" year-end benchmark report"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("PLUS, get a 45-day free trial ("),
      _c("em", [_vm._v("with no credit card necessary")]),
      _vm._v(") to the full Avid platform with Insights, Scorecard, and more!"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-5 row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
          _c("img", {
            staticClass: "me-3",
            staticStyle: { width: "64px" },
            attrs: { src: "/img/lock.png", alt: "lock" },
          }),
          _vm._v(" "),
          _c("div", [
            _c("h5", { staticClass: "mb-2" }, [_vm._v("Your data is secure")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "Avid AI takes the security of your data seriously.\n                        "
              ),
              _c(
                "a",
                {
                  attrs: { href: "https://trust.avidai.com", target: "_blank" },
                },
                [_vm._v("Visit our trust center")]
              ),
              _vm._v(
                "\n                        to see our SOC2 compliance status and the steps taken to ensure your organization's privacy.\n                        "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }