var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "pathways-manage" } }, [
    _c("div", { staticClass: "row px-2" }, [
      _c(
        "nav",
        {
          staticClass: "navbar navbar-expand primary-tab-bar pb-0",
          attrs: { id: "pathway-manage-nav" },
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "collapse navbar-collapse" }, [
              _c(
                "div",
                {
                  staticClass: "btn-group toggle-btn-group mb-4",
                  attrs: { role: "group" },
                },
                _vm._l(_vm.pathway_manage_tabs, function (tab_name, index) {
                  return _c(
                    "button",
                    {
                      staticClass: "btn btn-lg",
                      class:
                        index == _vm.pathway_manage_tab_index_active
                          ? "btn-primary"
                          : "btn-white",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setActiveTab(index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(tab_name) +
                          "\n                        "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.pathway_manage_tab_index_active == 0
        ? _c(
            "div",
            [
              _c("pathways-manage-pathways", {
                attrs: {
                  connection_datasets: _vm.connection_datasets,
                  client: _vm.client,
                  segments: _vm.segments,
                  datasets: _vm.datasets,
                  datablends: _vm.datablends,
                  census_connections: _vm.census_connections,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pathway_manage_tab_index_active == 1
        ? _c(
            "div",
            [
              _c("pathways-manage-segments", {
                attrs: {
                  connection_datasets: _vm.connection_datasets,
                  client: _vm.client,
                  segments: _vm.segments,
                  datasets: _vm.datasets,
                  census_connections: _vm.census_connections,
                  datablends: _vm.datablends,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }