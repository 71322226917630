var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "datasets" }, [
    _c("h1", { staticClass: "mb-3" }, [
      _vm._v(
        _vm._s(_vm._f("propercase")(_vm.datablend.type)) +
          " Data Blend Aggregations"
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Once the various disparate data sources have been assembled into a single record, there is often the need to perform additional custom calculations. Using the fields below, you can create unique aggregations that will be appended to this data blend at the end of the data processing process."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("h4", [_vm._v("Aggreggations")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._l(_vm.local_db.custom_aggregations, function (agg, index) {
            return _c("div", [
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Aggregation #" + _vm._s(index + 1) + " Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: agg.name,
                      expression: "agg.name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors[index].name != "" ? "is-invalid" : "",
                  attrs: { type: "text", name: "agg_name" },
                  domProps: { value: agg.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(agg, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors[index].name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.errors[index].name) +
                          "\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class:
                    index + 1 == _vm.local_db.custom_aggregations.length
                      ? "mb-3"
                      : "mb-5",
                },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("SQL Code"),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: agg.sql,
                        expression: "agg.sql",
                      },
                    ],
                    staticClass: "form-control font-monospace",
                    class: {
                      "is-invalid": _vm.errors[index].sql != "",
                      "is-valid":
                        _vm.errors[index].sql == "" &&
                        agg.sql.trim() != "" &&
                        !_vm.is_validating[index],
                      "is-validating": _vm.is_validating[index],
                    },
                    attrs: { name: "agg_sql" },
                    domProps: { value: agg.sql },
                    on: {
                      change: function ($event) {
                        return _vm.validateSQL(index)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(agg, "sql", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors[index].sql != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.errors[index].sql) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ])
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary mb-3",
              on: {
                click: function ($event) {
                  return _vm.addAggregation()
                },
              },
            },
            [_vm._m(3)]
          ),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.form.error
      ? _c("div", { staticClass: "alert alert-danger mb-3 box-content" }, [
          _vm._v(
            "\n        There was an error in the fields above. Please make any necessary corrections and try again.\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary mb-3 btn-big",
        attrs: { disabled: _vm.form.busy },
        on: {
          click: function ($event) {
            return _vm.update()
          },
        },
      },
      [_vm._m(4)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("em", [
        _c("strong", { staticClass: "text-danger" }, [_vm._v("WARNING: ")]),
        _vm._v(
          "This is a power-user level screen. We'll build a better UI once we know how we're going to use this and how the various queries end up looking."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            "data-bs-toggle": "collapse",
            href: "#collapseExample",
            role: "button",
            "aria-expanded": "false",
            "aria-controls": "collapseExample",
          },
        },
        [_vm._v("View Examples")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "collapse border-start border-3 border-secondary ps-3",
        attrs: { id: "collapseExample" },
      },
      [
        _c("label", { staticClass: "form-label" }, [_vm._v("A Boolean Value")]),
        _vm._v(" "),
        _c("p", { staticClass: "font-monospace mb-3" }, [
          _vm._v(
            "SELECT COUNTIF(t.is_online) > 0 FROM UNNEST(transactions) AS t WHERE NOT _is_dummy"
          ),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "form-label" }, [
          _vm._v("An Aggregated Value (SUM, COUNT, AVG)"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-monospace mb-3" }, [
          _vm._v(
            "SELECT SUM(IF(t.subchannel = 'PTV App',t.amount,NULL)) FROM UNNEST(transactions) AS t WHERE NOT _is_dummy"
          ),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "form-label" }, [
          _vm._v("A Full Transaction Record"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-monospace mb-3" }, [
          _vm._v(
            "SELECT AS STRUCT t.* FROM UNNEST(transactions) AS t WHERE t.channel = 'Email' ORDER BY t.date LIMIT 1"
          ),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "form-label" }, [
          _vm._v("A Cohort Segmentation"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-monospace" }, [
          _vm._v(
            "SELECT CASE WHEN COUNTIF(t.channel = 'Email') > 0 AND COUNTIF(t.channel <> 'Email') > 0 THEN 'Email & Others Cohort' WHEN COUNTIF(t.channel = 'Email')>0 THEN 'Email Only' ELSE 'Others Only' END FROM UNNEST(transactions) AS t"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Aggregation"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-save" }), _vm._v("Save")])
  },
]
render._withStripped = true

export { render, staticRenderFns }