<template>
    <div class="asset-page">
        <div class="row">
                <h2 class="pb-1 d-flex border-bottom border-secondary border-3 mt-3">
                    <span class="col-7">
                     {{(campaign.campaign_type_id==0? campaign.name:local_assets_type.name)}}   
                    </span>
                    
                    <div class="col-5">
                        <button class="btn btn-primary btn-sm ms-3 float-end mb-2" v-on:click="addAsset(null)" id="add-asset" >
                            <span><i class="fas fa-fw fa-plus"></i>Add Asset</span>
                        </button>
                        <button id="sticky-button" class="btn btn-primary btn-sm" v-on:click="addAsset(null)" style="display:none;">
                            <span><i class="fas fa-fw fa-plus"></i>Add Asset</span>
                        </button>
                        <a class="btn btn-secondary btn-sm float-end ms-3 mb-2" v-if="canDeploy()" :href="'/campaigns/'+client.url+'/deploy/'+campaign.url+'/' + this.local_assets_type.id">
                            <span><i class="fas fa-fw fa-cube"></i>Deploy Assets</span>
                        </a>

                        <button class="btn btn-secondary btn-sm float-end mb-2" v-if="if_has_unfinished_asset" type="button" v-on:click="generateAllAssets()">
                            <i class="fa fa-btn fa-edit"></i> <span>Generate Unfinished Assets</span>
                        </button>                                     
                    </div>
                </h2>
        </div>

        <transition-group tag="div" name="list" >
            <div v-for="(asset, index) in local_assets_array" ref="assetSection" :key="asset.id"  class="mb-3 vertical-center-text asset-row">
                <div class="card box-content mb-3 mx-0 row-with-reorder-btns">
                    <div class="card-body">
                        <div class="accordion accordion-flush" >
                            <div class="accordion-item border-bottom-0">
                                <div class="accordion-header ms-2 me-5">
                                    <div class="text-start collapsed p-2 accordion-button" type="button"
                                        :data-bs-target="'#asset-editing-' + asset.id" data-bs-toggle="collapse"
                                        aria-expanded="false" :aria-controls="'#asset-editing-' + asset.id" @click="resizeTextareaExpanding(index)" >
                                        <div class="row col-12">
                                            <div :class="(asset.if_expanded? 'col-11' : 'col-8')">
                                                <h4 class="mb-1">{{getAssetTypeNameInSingular(asset.asset_type.name)}} - {{ asset.name }}</h4>
                                                <div class="created_by_label" v-if="asset.id > 0 && asset.created_by_user">
                                                    <label class='mb-0 me-1'>Created By: {{ asset.created_by_user.first_name + ' ' + asset.created_by_user.last_name }}</label>
                                                </div> 
                                            </div>
                                            <div class="col-3" v-if="asset.if_expanded==false" >
                                                <label class="form-label border-bottom">Status</label>
                                                <p class="mb-0">{{ getStatusText(asset.status) }}</p>
                                            </div>
                                            <div class="mt-2 col-11" v-if="asset.if_expanded==false && asset.description != null">
                                                <label class="form-label border-bottom">Asset Description</label>
                                                <p class="mb-0">{{ asset.description }}</p>
                                            </div>                                                    
                                        </div>
                                    </div>                                        
                                </div>
                                <div :id="'asset-editing-' + asset.id" class="collapse accordion-collapse" :class="{show: asset.if_expanded}" >
                                    <div>
                                        <hr class="mx-3 section-divider">
                                        <asset-details :asset="asset" :client="client" :concepts="concepts" :pages="pages" @generateVariantPrompt="generateVariantPrompt"
                                        @copyAsset="copyAsset" ref="asset" @assetGenerating="assetGenerating" @assetSaved="assetSaved"></asset-details>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="asset-accordion-delete btn" aria-label="Close"
                                @click="deleteAsset(index)" v-if="(asset.status !='approved' && !asset.generating)">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <div v-if="asset.status =='approved'" class="ribbon"><span>{{asset.status | propercase}}</span></div>
                        </div>

                        <div class="asset-overlay" v-if="asset.generating">
                            <div class="overlay"></div>
                            <div class="cta">
                                <div class="row d-flex align-items-center mx-3">
                                    <div class="col-2">
                                        <div class="spinner-border text-white float-end" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <p class="text-start m-0">Your content is being generated&mdash;when it is ready, it will appear for you to edit or approve. You can leave the page and return at any time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="px-3 hide reorder-btn-containter">
                    <div class="reorder-btn-containter">
                    <button v-if="index!=0" type="button" class="reorder-btn mb-2" v-on:click="moveAssetUp(index)"><i class="fa fa-arrow-up"></i></button>
                    <button v-if="index!=local_assets_array.length-1" type="button" class="reorder-btn" v-on:click="moveAssetDown(index)"><i class="fa fa-arrow-down"></i></button>
                    </div>

                </div>
            </div>
        </transition-group>

        <div class="row mb-3" >
            <div class="col row-with-reorder-btns" id="public-links">
                <label class="form-label">Asset Approval Page Link <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="The link below will allow clients to review any assets on this page that have the status of 'Ready for Review'."></i></label>
                <input type="text" class="form-control bg-white ps-2 text-muted "
                    @click="copyToClipboard()" ref="clone" readonly
                    v-model=" current_domain + 'campaigns/' + client.url + '/approval/' + campaign.url + '/' + local_assets_type.id"
                    @focus="$event.target.select()">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['client', 'campaign', 'assets', 'concepts', 'pages'],
        data() {
            return {
              local_assets_type: {},
              local_assets_array: [],
              if_has_unfinished_asset: false
            };
        },
        beforeMount() {
            this.local_assets_type = this.assets[0].asset_type;

            this.assets.forEach((asset) => {
                asset.if_expanded = (asset.source == 'generating');
                asset.generating = (asset.source == 'generating');
                this.local_assets_array.push(asset)
            });    

            this.checkUnfinishedAssets();
        },
        mounted() {
            const addAssetButton = document.getElementById('add-asset');
            const stickyButton = document.getElementById('sticky-button');

            window.addEventListener('scroll', function() {
                let rect = addAssetButton.getBoundingClientRect();

                if (rect.bottom <= 0 && stickyButton.style.display == 'none') {
                    stickyButton.style.display = 'block';
                } else if(rect.bottom > 0 && stickyButton.style.display == 'block') {
                    stickyButton.style.display = 'none';
                }
            });
        },
        computed: {
            current_domain() {
                return window.location.protocol + '//'+window.location.host+'/';
            }
        },
        methods: {
            checkUnfinishedAssets(){
                this.local_assets_array.forEach(asset => {
                    if (asset.source == null){
                        this.if_has_unfinished_asset = true;
                    }
                })
            },
            copyToClipboard() {
              this.$refs.clone.focus();
              document.execCommand('copy');
              Swal.fire({
                  html: '<h4 class="mb-0 text-white">Copied!</h4>',
                  target: '#public-links',
                  icon: 'success',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 2000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
            },
            assetSaved(asset) {
                // save action always happen when the accordion is expanded, and the new asset returned in response won't have this field
                asset.if_expanded = true; 

                for(var i = 0; i < this.local_assets_array.length; i++){
                    if(asset.id == this.local_assets_array[i].id){
                        this.local_assets_array.splice(i, 1, asset);
                        break;
                    }
                }
            },
            canDeploy() {
                //If it isn't email prospecting
                if(this.local_assets_type.id != 6) //this.local_assets_type.id != 1 &&
                    return false;

                for(var i = 0; i < this.local_assets_array.length; i++){
                    if(this.local_assets_array[i].status == 'approved')
                        return true;
                }

                return false;
            },
            getStatusText(status){
                if (status == 'draft') {
                    return 'Draft'
                } 
                if (status == 'for_approval') {
                    return 'Ready for Review'
                } 
                if (status == 'in_review') {
                    return 'Reviewed'
                } 
                if (status == 'approved') {
                    return 'Approved'
                } 
            },
            getAssetTypeNameInSingular(assetTypeName){
                if (assetTypeName == 'Popups, "Dear Reader" Ads, and Sticky Bars'){
                    return 'Popup, "Dear Reader" Ad, and Sticky Bar';
                } else {
                    return assetTypeName.slice(0, -1);
                }
            },
            resizeTextareaExpanding(assetIndex) {
                this.local_assets_array[assetIndex].if_expanded = !this.local_assets_array[assetIndex].if_expanded;

                if(this.local_assets_array[assetIndex].if_expanded == true){
                    var element = document.querySelectorAll('textarea');

                    for(var index = 0; index<element.length; index++){
                        if (element[index] && element[index].style){
                            element[index].style.height = '';
                            element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                        }                            
                    }                    
                }
                // else{ // on collapsing the accordion
                //                 this.$forceUpdate();
                // }
            },
            addAsset(obj, addIndex){  
                var newAsset;
                if (obj == null){
                    newAsset = {
                        id: -1,
                        client_id: this.client.id,
                        campaign_id: this.campaign.id,
                        campaign: this.campaign,
                        asset_type_id: this.local_assets_type.id,
                        asset_type: this.local_assets_type,
                        initiative_id: null, // TODO
                        name: this.local_assets_type.name.slice(0, -1),
                        description: "",
                        inputs: null,
                        status:'draft',
                        if_expanded: true,
                        generating: false
                    };                    
                } else{
                    newAsset = obj;
                }
                if(addIndex){
                    this.local_assets_array.splice(addIndex, 0, newAsset);
                }
                else{
                   this.local_assets_array.push(newAsset); 
                }
                
                window.axios.post('/api/campaigns/'+this.client.url+'/assets', newAsset).then(response => {
                    //Replace the last asset in the local_assets_array with new asset
                    if(response.status == 200) {
                        response.data.asset.if_expanded = true;
                        response.data.asset.generating = false;

                        if(addIndex){
                            this.local_assets_array[addIndex] = response.data.asset;
                        }
                        else{
                            this.local_assets_array.splice(this.local_assets_array.length-1, 1, response.data.asset);

                        }
                        
                        this.$forceUpdate();
                    }
                });

                //next tick then do the scroll
                this.$nextTick(() => {
                    const lastAssetElement = this.$refs.assetSection[this.$refs.assetSection.length -1];
                    lastAssetElement.scrollIntoView({ behavior: 'smooth'});
                });

            },
            generateAllAssets() {
                Swal.fire({
                  html: '<h4 class="mb-0 text-white">Generating Unfinished Assets...</h4>',
                  target: '#public-links',
                  icon: 'warning',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 3000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
                
                this.local_assets_array.forEach(asset => {
                    if(asset.source == null){
                        asset.generating = true;
                    }
                })

                window.axios.post('/api/campaigns/'+this.client.url + '/generate_all/' + this.campaign.url + '/' + this.local_assets_type.id)
                    .then(response => { 
                        Swal.fire({
                            title: "Generated " + response.data.num_assets + " assets" ,
                            text: "The unfinished assets has been generated." ,
                            icon: "success",
                            iconColor:  "#46705F",
                            confirmButtonColor: "#46705F"
                        });

                        // reload local_assets_array
                        response.data.assets.forEach((asset) => {
                            for(var i = 0; i < this.local_assets_array.length; i++){
                                if (this.local_assets_array[i].id == asset.id){
                                    var expand_status = this.local_assets_array[i].if_expanded;
                                    this.local_assets_array[i] = asset;
                                    this.local_assets_array[i].if_expanded = expand_status;
                                }
                            }
                        });    
                        this.if_has_unfinished_asset = false; 
                    });
            },
            deleteAsset(assetIndex){
                var self = this;
                Swal.fire({
                  title: "Are you sure you want to delete this asset?",
                  text: "The copy and all associated inputs will be removed from this campaign." ,
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/campaigns/'+this.client.url+'/assets/'+this.local_assets_array[assetIndex].id)
                        .then(response => {
                            if (response.status == 200) {
                                this.local_assets_array.splice(assetIndex, 1); 

                                if(this.local_assets_array.length == 0){
                                    // Redirect back to the campaign page
                                    window.location = "/campaigns/"+this.client.url+"/overview/"+this.campaign.url;
                                }
                                else { 
                                    Swal.fire({
                                        title: "Deleted!",
                                        text: "The asset has been removed.",
                                        icon: "success",
                                        iconColor:  "#46705F",
                                        confirmButtonColor: "#46705F"
                                    });                                    
                                }
                            }
                        });
                    }
                });
            },
            assetGenerating(assetDetails){
                var asset_index = this.local_assets_array.findIndex(asset => asset.id == assetDetails.id);
                this.local_assets_array[asset_index].generating = assetDetails.generating;
                if(assetDetails.generating == false)
                    this.local_assets_array[asset_index].if_expanded = true;
                this.$forceUpdate();
            },
            copyAsset(assetToCopy){
                var newAssetObj = {
                    id: -1,
                    client_id: this.client.id,
                    campaign_id: this.campaign.id,
                    asset_type_id: this.local_assets_type.id,
                    asset_type: this.local_assets_type,
                    initiative_id: null, // TODO
                    name: assetToCopy.name + " (Copy)",
                    description: assetToCopy.description,
                    inputs: assetToCopy.inputs,
                    campaign: assetToCopy.campaign,
                    status:'draft',
                    order:assetToCopy.order+1,
                    if_expanded: true,
                    generating: false
                };
                var original_asset_index = this.local_assets_array.findIndex(asset => asset.id == assetToCopy.id);
                this.addAsset(newAssetObj, original_asset_index+1);
            },
            moveAssetUp(assetIndex){
                var current_asset = {...this.local_assets_array[assetIndex]};
                var previous_asset = {...this.local_assets_array[assetIndex-1]};

                current_asset.order = this.local_assets_array[assetIndex-1].order;
                previous_asset.order = this.local_assets_array[assetIndex].order;

                window.axios.put('/api/campaigns/'+this.client.url+'/assets/'+current_asset.id, current_asset)
                window.axios.put('/api/campaigns/'+this.client.url+'/assets/'+previous_asset.id, previous_asset)

                // switch the two assets' order on the UI
                this.local_assets_array[assetIndex-1] = current_asset;
                this.local_assets_array[assetIndex] = previous_asset;
                this.$forceUpdate();
            },
            moveAssetDown(assetIndex){
                var current_asset = {...this.local_assets_array[assetIndex]};
                var next_asset = {...this.local_assets_array[assetIndex+1]};

                current_asset.order = this.local_assets_array[assetIndex+1].order;
                next_asset.order = this.local_assets_array[assetIndex].order;

                window.axios.put('/api/campaigns/'+this.client.url+'/assets/'+current_asset.id, current_asset)
                window.axios.put('/api/campaigns/'+this.client.url+'/assets/'+next_asset.id, next_asset)

                // switch the two assets' order on the UI
                this.local_assets_array[assetIndex+1] = current_asset;
                this.local_assets_array[assetIndex] = next_asset;
                this.$forceUpdate();
            },

            generateVariantPrompt(assetToCopy, option, content){

                var original_asset_index = this.local_assets_array.findIndex(asset => asset.id == assetToCopy.id);

                var newAsset = {
                    id: -1,
                    client_id: this.client.id,
                    campaign_id: this.campaign.id,
                    asset_type_id: this.local_assets_type.id,
                    asset_type: this.local_assets_type,
                    initiative_id: null, // TODO
                    name: assetToCopy.name + " (Variant)",
                    description: assetToCopy.description,
                    inputs: assetToCopy.inputs,
                    campaign: assetToCopy.campaign,
                    status:'draft',
                    source:'generating',
                    option_selected: option,
                    order:original_asset_index+1,
                    content: content,
                    parent_id: assetToCopy.id,
                    generating: true,
                    if_expanded: false
                };

                this.local_assets_array.splice(original_asset_index+1, 0, newAsset);

                window.axios.post('/api/campaigns/'+this.client.url+'/variant', newAsset).then(response => {
                    //Replace the asset in the local_assets_array with new asset
                    this.local_assets_array[original_asset_index+1] = response.data.asset;

                    this.local_assets_array[original_asset_index+1].generating = true;
                    this.local_assets_array[original_asset_index+1].if_expanded = false;
                    this.$forceUpdate();

                    Swal.fire({
                        html: '<h5 class="mb-0 mt-1">Variant Generation Started!</h5>',
                        target: '#public-links',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 3000,
                        iconColor: '#FFFFFF',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                });

                // this.$forceUpdate();

                // //next tick then do the scroll
                this.$nextTick(() => {
                    const newAssetElement = this.$refs.assetSection[original_asset_index];
                    newAssetElement.scrollIntoView({ behavior: 'smooth'});
                });

            }
        }
    }
</script>
