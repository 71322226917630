<template>
    <div class="home-table pb-2">
        <p class="px-3">The media, revenue and gifts are the actual program results based upon the start of the client's current fiscal plan through the last full month ({{ getLastMonth() }}). The percentage shown is your progress toward the annual plan at the end of that month.</p>

        <div class="table-responsive">
            <table class="table gy-0 m-0">
              <thead class="table-dark">
                <tr>
                  <th class='ps-3 align-middle'>Client</th>
                  <th class="align-middle d-none d-sm-table-cell">Media Budget</th>
                  <th class="align-middle d-none d-sm-table-cell">Total Revenue</th>
                  <th class="align-middle d-none d-sm-table-cell">Total Gifts</th>
                  <th class="align-middle d-none d-sm-table-cell">Emails Acquired</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-for="c in clients">
                    <td class='ps-3'><a :href="c.home_link"><img class="avatar rounded-circle me-3" :src="c.image" /> {{c.name}}</a></td>
                    <td class='align-middle d-none d-sm-table-cell placeholder-glow' v-if="!loaded[c.url]"><span class="placeholder col-9 placeholder-lg"></span></td>
                    <td class='align-middle d-none d-sm-table-cell placeholder-glow' v-if="!loaded[c.url]"><span class="placeholder col-9 placeholder-lg"></span></td>
                    <td class='align-middle d-none d-sm-table-cell placeholder-glow' v-if="!loaded[c.url]"><span class="placeholder col-9 placeholder-lg"></span></td>
                    <td class='align-middle d-none d-sm-table-cell placeholder-glow' v-if="!loaded[c.url]"><span class="placeholder col-9 placeholder-lg"></span></td>

                    <td class="align-middle d-none d-sm-table-cell placeholder-glow" v-else v-for="(actual, i) in getActuals(c.url)">
                        <span v-if="i == 'media_cost' || i == 'revenue'">{{ actual.value | currency }}</span>
                        <span v-else>{{ actual.value | number }}</span>
                        <span class="badge ms-2" :class="actual.class">{{ actual.label }}</span></td>

                </tr>
              </tbody>
            </table>
          </div>

    </div>
</template>

<script>
    export default {
        props: ['clients'],
        data() {
            return {
                loaded: [],
                results: [],
                limit: 15,
                offset: 0
            };
        },
        mounted() {
            for(let i = 0; i < this.clients.length; i++){
                this.loaded[this.clients[i].url] =false;
            }

            this.getResults();
        },

        methods: {
            getResults() {
                window.axios.post('/api/home/results', {'limit': this.limit, 'offset': this.offset})
                  .then(response => {

                    //Get the keys from the array
                    let keys = Object.keys(response.data.clients);

                    //Change to a foreach and set the index using the key
                    for(let i = 0; i < keys.length; i++){
                        //Find the assoicated client (client is an object)
                        let all_client_keys = Object.keys(this.clients);

                        for(let j = 0; j < all_client_keys.length; j++){
                            if(this.clients[all_client_keys[j]].id == keys[i]){
                                this.results[this.clients[all_client_keys[j]].url] = response.data.clients[keys[i]];
                                this.loaded[this.clients[all_client_keys[j]].url] = true;
                                break;
                            }
                        }
                    }

                    if(response.data.count > this.offset){
                        this.offset += this.limit;
                        this.getResults();
                    }
                    this.$forceUpdate();

                  });
            },
            getLastMonth() {
                let today = new Date();

                // Decrease the month by one to get the last month
                let lastMonth = new Date(today);
                lastMonth.setMonth(today.getMonth() - 1);

                // Format the date to get the full month name and year
                return lastMonth.toLocaleString('default', { month: 'long', year: 'numeric' });

            },
            getActuals( client_id ) {
                return this.results[client_id];
            }
        }
    }
</script>
