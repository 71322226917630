var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org_settings_scorecard" } }, [
    _c("h4", [_vm._v("Scorecard Filter for " + _vm._s(_vm.client.name))]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Avid AI's Fundraiser's Scorecard is a great way to track the progress on the most important metrics for a successful fundraising program. However, not everyone at an organization cares about every segment of donor. To make this scorecard more meaningful for you, we've created a way for you to filter the metrics to the metrics you care most about."
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-4" }, [
      _vm._v(
        "This filter will only apply to your user; each user can make adjustments based upon their own preferences."
      ),
    ]),
    _vm._v(" "),
    _vm.data_blend.id == null
      ? _c("div", [_vm._m(0)])
      : _c(
          "div",
          [
            _c("query-inclusion-builder", {
              ref: "filter",
              attrs: {
                client: _vm.client,
                static_db_columns: _vm.data_blend.source_columns,
                include_trans: false,
                source: _vm.data_blend.data_source,
                in_card: true,
                add_blank: true,
                data_source_id: _vm.data_blend.id,
              },
              model: {
                value: _vm.scorecard_filter,
                callback: function ($$v) {
                  _vm.scorecard_filter = $$v
                },
                expression: "scorecard_filter",
              },
            }),
          ],
          1
        ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary mt-5 btn-big",
        attrs: { type: "submit", disabled: _vm.form.busy },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.update.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "fa fa-btn fa-save" }),
        _vm._v(" Save Changes\n    "),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "d-flex align-items-center" }, [
      _c(
        "div",
        {
          staticClass: "spinner-border text-primary me-2",
          attrs: { role: "status" },
        },
        [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
      ),
      _vm._v("\n            Loading Filter Options\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }