var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scorecard-projection" }, [
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("h2", [_vm._v(_vm._s(_vm.data.fytd_chart.title) + " ")]),
        _vm._v(" "),
        _vm.data.link != null
          ? _c(
              "a",
              {
                staticClass: "btn btn-secondary float-end ms-3",
                attrs: { href: _vm.data.link },
              },
              [
                _c("img", {
                  staticClass: "icon me-2",
                  attrs: { src: "/img/icons/dialexa-icons/chart.svg" },
                }),
                _vm._v("View Insights Details"),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.data.description))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-4 border-end border-1" }, [
        _c("h5", [_vm._v(_vm._s(_vm.data.fytd_chart.subtitle))]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("chart-template", {
              key: "fytd_chart" + _vm.data.id,
              attrs: {
                chart: _vm.data.fytd_chart.chart,
                id: "fytd_chart" + _vm.data.id,
                if_benchmark_report: true,
                if_on_report_page: false,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "ms-3" },
          [
            _c("h5", [_vm._v("Projected Impact on Future Fiscal Years")]),
            _vm._v(" "),
            _c("chart-template", {
              key: "fy_chart" + _vm.data.id,
              attrs: {
                chart: _vm.data.fy_chart.chart,
                id: "fy_chart" + _vm.data.id,
                if_benchmark_report: true,
                if_on_report_page: false,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "d-flex pink-gradient-left summary pt-3" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _c("h5", [_vm._v("What If...")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "The projections above are based upon the most current time period's trends. However, there is still time to make changes to improve results. "
              ),
              _c("strong", [
                _vm._v(
                  "Use the slider below to see how changes made today to the " +
                    _vm._s(_vm.data.fytd_chart.title) +
                    " will impact both this year and the 3 years' value to your organization."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                },
                [
                  _c("label", { staticClass: "form-label mb-0" }, [
                    _vm._v(_vm._s(Math.round(_vm.min * 100) + "%")),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-grow-1 mx-2",
                  class: _vm.local_value < 0 ? "negative" : "",
                },
                [
                  _c("vue-slider", {
                    attrs: {
                      "tooltip-formatter": (val) => Math.round(val * 100) + "%",
                      min: _vm.min,
                      max: _vm.max,
                      interval: 0.01,
                      tooltip: "always",
                    },
                    on: {
                      "drag-start": function ($event) {
                        _vm.dragging = true
                      },
                      "drag-end": _vm.dragEnd,
                      change: _vm.change,
                    },
                    model: {
                      value: _vm.local_value,
                      callback: function ($$v) {
                        _vm.local_value = $$v
                      },
                      expression: "local_value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                },
                [
                  _c("label", { staticClass: "form-label mb-0" }, [
                    _vm._v(_vm._s(Math.round(_vm.max * 100) + "%")),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "primary-chart-insight-icon mt-3 mx-3" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/img/icons/dialexa-icons/flag.svg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }