import { render, staticRenderFns } from "./ChartConfiguration.vue?vue&type=template&id=31602391"
import script from "./ChartConfiguration.vue?vue&type=script&lang=js"
export * from "./ChartConfiguration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev.aviddata.co/releases/20241116232211/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31602391')) {
      api.createRecord('31602391', component.options)
    } else {
      api.reload('31602391', component.options)
    }
    module.hot.accept("./ChartConfiguration.vue?vue&type=template&id=31602391", function () {
      api.rerender('31602391', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/insights/ChartConfiguration.vue"
export default component.exports