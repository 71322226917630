<template>
    <div class="onboarding-connect-crm">       
        <input type="hidden" :value="local_client.url" id="client_url">
        <h2 class="mt-2 mb-0">Step 4: Make Your First Connection!</h2>
        <p>To get custom-built benchmarks, {{ crm== null ? 'upload your donor data' : ('connect ' + crm.display_name) }} to see your organization's key metrics compared to similar nonprofits.</p>

        <div v-if="connection_option=='account'">
            <h3 class="mt-4" > <img :src="crm.logo_url" :alt="crm.display_name" class="avatar rounded-circle avatar-sm me-2 bg-white">{{ crm.display_name }}</h3>
            
            <div v-if="selected_crm_info.type=='credentials'">
                <div v-html="selected_crm_info.instructions"></div>

                <div class="system-connection-fields row mt-2">
                    <div v-for="(field, field_index) in selected_crm_info.field" class="mb-1">
                        <label v-if="field.type != 'checkbox' && field.type != 'hidden'" class="form-label">{{ field.label }}
                            <i v-if="field.tooltip && field.tooltip.length > 0" class="fas fa-info-circle" data-bs-toggle="tooltip" :title="field.tooltip"></i>
                        </label>

                        <input v-if="field.type == 'text'" type="text" class="form-control" v-on:change="field.error = false;"
                            :name="field.id" v-model="field.value"
                            :class="((field.error)? 'is-invalid':'')">


                        <input v-if="field.type == 'label'" type="text"
                            class="form-control" disabled="disabled" :name="field.id" v-model="field.value">

                        <textarea v-if="field.type == 'textarea'"  :name="field.id" class="form-control full-height" v-model="field.value"  v-on:change="field.error = false;"></textarea>

                        <div v-if="field.type == 'password' || field.type == 'password-text'" class="input-group" >
                            <input aria-label="Password" aria-describedby="password-addon" ref="password" autocomplete="off"  :name="field.id" :type="field.type == 'password'? 'password' : 'text'"  :class="((field.error)? 'is-invalid':'')"
                            class="form-control password-input input_v1" name="password"  v-model="field.value"  v-on:change="field.error = false;">

                            <span :title="!field.visible ? 'Show password' : 'Hide password'" @click="toggleVisibility(field)"
                                class="input-group-text" id="password-addon"
                                style="border-left: none; background-color: white">
                                <i class="fa" :class="[!field.visible ? 'fa-eye' : 'fa-eye-slash']"></i>
                            </span>
                        </div>

                        <div v-if="field.type == 'select'">
                            <select class="form-select" :name="field.id" v-model="field.value" :class="((field.error)? 'is-invalid':'')"  v-on:change="field.error = false;">
                                <option v-for="option in Object.keys(field.options)" :value="option">{{field.options[option]}}</option>
                            </select>
                        </div>

                        <div v-if="field.type == 'warning'">
                            <p style="margin-top:-8px;"><small class="text-muted">{{ field.text }}</small></p>
                            <div class="alert alert-warning " role="alert">
                                <code v-html="field.value"></code>
                            </div>
                        </div>

                        <div v-if="field.type == 'vue-select'">
                            <accordion-select :options="field.options" :name="field.id" v-model="field.value" :class="((field.error)? 'is-invalid':'')"  v-on:change="field.error = false;"></accordion-select>
                        </div>

                        <div v-if="field.type == 'checkbox'" class="form-check"  :class="((field.error)? 'is-invalid':'')">
                            <input class="form-check-input" type="checkbox" value="true" :id="field.id" :name="field.id" v-model="field.value">
                            <label class="form-check-label" :for="field.id">
                                {{ field.label }}
                                <i v-if="field.tooltip && field.tooltip.length > 0" class="fas fa-info-circle" data-bs-toggle="tooltip" :title="field.tooltip"></i>
                            </label>
                        </div>

                        <div v-if="field.type == 'tables'" :id="field.id">
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-sm-7">
                                    <label class="form-label">Dataset and Table Name
                                        <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="The value below should be in the format of [DATASET].[TABLE NAME]"></i>
                                    </label></div>
                                <div class="col">
                                    <label class="form-label">Table Type</label>
                                </div>
                                <div class="col-1"></div>
                            </div>

                            <div class="row mb-3" v-for="(row, row_index) in field.value" >
                                <div class="col-1">
                                    <h4 class="text-end mb-0 mt-1 nums">{{ row_index + 1 }}.</h4>
                                </div>
                                <div class="col-sm-7">
                                <input name="table_name[]" type="text" v-model="field.value[row_index]['table_name']" class="form-control"
                                    :class="((field.error && field.value[row_index]['table_name'] == '')? 'is-invalid':'')" />
                                </div>
                                <div class="col">
                                    <select name="table_type[]" v-model="field.value[row_index]['table_type']"
                                        class="form-select" v-html="bigquery_table_type_html" :class="((field.error && field.value[row_index]['table_type'] == '')? 'is-invalid':'')"
                                        v-on:change="field.error = false;" >

                                    </select>
                                </div>
                                <div class="col-1 delete_table" v-if="field.value.length > 1">
                                    <button class="btn btn-none" @click="removeTable(field_index, row_index)"><i class="fa fa-trash"></i></button>
                                </div>
                            </div>
                            <div class="text-danger invalid-feedback" v-if="field.error">
                                {{field.error_msg}}
                            </div>

                            <button class="btn btn-secondary mt-3 mb-4"  @click="addTable(field_index)"><i class="fas fa-fw fa-plus"></i> Add Table</button>
                        </div>

                        <div class="text-danger invalid-feedback" v-if="field.error && field.type != 'tables'">
                                {{field.error_msg}}
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mt-3" v-if="form.error ">
                    <span v-html="form.msg"></span>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary w-100 mt-2" @click="nextStep()" :disabled="processing">
                            <span v-if="processing">
                                <div class="spinner-border  spinner-border-sm text-white float-left" role="status"></div>Processing
                            </span>
                            <span v-else>
                                    Connect <i class="fa fa-arrow-right"></i>
                                </span>
                        </button>
                    </div>
                </div>
            </div>

            <div v-else-if="selected_crm_info.type=='oauth'">
                <p><strong>Avid AI connects to {{ crm.display_name }} through a one-click authentication.</strong> To connect your instance, click on the button below&mdash;you will be redirecting to {{ crm.display_name }} to complete the process. If additional details are needed after connecting, you'll be prompted when you get back.</p>
                <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">
                    <a class="btn btn-primary w-100" :href="selected_crm_info.url">
                        <img class="icon p-0" src="/img/icons/dialexa-icons/profile-tick.svg"> Authenticate
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mt-2 text-end">
                    <a :href="'/year_end_benchmarks/' + this.local_client.url">
                        (Skip for now and view the benchmarks)
                    </a>                
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <h5>Can't connect your CRM yourself?</h5>
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-secondary w-100 mt-2" @click="connection_option = 'file_upload'"  :disabled="processing">
                                Upload Files
                            </button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-secondary w-100 mt-2" @click="connection_option = 'send_to_colleague'"  :disabled="processing">
                                Ask a Colleague for Help
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="connection_option=='file_upload'">

            <div class="row">

                <p>Avid AI requires two file types: transactions and constituents. <a href="/templates/transactions.csv" target="_blank">Click here for an example file of transactions</a> and <a href="/templates/constituents.csv" target="_blank">here for an example file of constituents.</a> <em>(Required fields are denoted with a *.)</em></p>

                <div class="file-dropzone my-2">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone"
                        :options="dropzoneOptions"
                        v-on:vdropzone-processing="onProcessing"
                        v-on:vdropzone-success="onSuccess"
                        v-on:vdropzone-sending="onSending"></vue-dropzone>
                </div>

                <div v-if="if_uploaded" class="alert alert-primary" role="alert">
                    This file has been uploaded and is processing. We'll notify you once it is ready for mapping. <a :href="'/year_end_benchmarks/' + client.url">Until then, you can explore Avid's benchmarks.</a>
                </div>
                <p><small>Accepted File Types: .csv, .txt, and similar delimited text files.</small></p>
            </div>

            <div class="row" v-if="if_uploaded == false">
                <div class="col-12 mt-2 text-end">
                    <a  :href="'/year_end_benchmarks/' + this.local_client.url">
                        (Skip for now and view the benchmarks)
                    </a>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <h5>Need help uploading files?</h5>
                    <div class="row">
                        <div class="col-sm-6" v-if="crm != null">
                            <button type="button" class="btn btn-primary w-100 mt-2" @click="connection_option = 'account'"  :disabled="processing">
                                1-Click Integration
                            </button>
                        </div>
                        <div class="col">
                            <button type="button" :class="crm == null ? 'btn-secondary' : 'btn-primary'" class="btn w-100 mt-2" @click="connection_option = 'send_to_colleague'"  :disabled="processing">
                                Ask a Colleague for Help
                            </button>
                        </div>
                        <div class="col-sm-6"  v-if="crm == null">
                            <a class="btn btn-secondary w-100 mt-2" target="_blank" href="https://share.hsforms.com/1GwisSMM2TTKAQ8uKpNUBpQram1t">
                                Get Help from Avid Staff
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="connection_option=='send_to_colleague'">
            <div class="row">
                <div class="mb-3 col-6">
                    <label>First Name *</label>
                    <input type="text" class="form-control" :class="((send_to_colleague_errors.first_name != '' )? 'is-invalid':'')"
                        name="first_name" v-model="send_to_colleague.first_name">
                    <div class="text-danger invalid-feedback" v-if="send_to_colleague_errors.first_name != ''">
                        {{send_to_colleague_errors.first_name}}
                    </div>
                </div>
                <div class="mb-3 col-6">
                    <label>Last Name *</label>
                    <input type="text" class="form-control" :class="((send_to_colleague_errors.last_name != '' )? 'is-invalid':'')"
                        name="last_name" v-model="send_to_colleague.last_name">
                    <div class="text-danger invalid-feedback" v-if="send_to_colleague_errors.last_name != ''">
                        {{send_to_colleague_errors.last_name}}
                    </div>
                </div>
                <div class="mb-3 col-12">
                    <label>Email *</label>
                    <input type="text" class="form-control" :class="((send_to_colleague_errors.email != '' )? 'is-invalid':'')"
                        name="email" v-model="send_to_colleague.email">
                    <div class="text-danger invalid-feedback" v-if="send_to_colleague_errors.email != ''">
                        {{send_to_colleague_errors.email}}
                    </div>
                </div>
            </div>

            <div class="row ">
                <div class="col-12">
                    <button type="submit" class="btn btn-primary w-100 mt-2" @click="nextStep()" :disabled="processing">
                        <span v-if="processing">
                            <div class="spinner-border  spinner-border-sm text-white float-left" role="status"></div>Processing
                        </span>
                        <span v-else>
                            Send Invite <i class="fa fa-arrow-right"></i>
                        </span>
                    </button>                
                </div>
                <div class="col-12 mt-2 text-end">
                    <a :href="'/year_end_benchmarks/' + this.local_client.url">
                        (Skip for now and view the benchmarks)
                    </a>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <h5>Want to set up the integration yourself?</h5>
                    <div class="row">
                        <div class="col-sm-6" v-if="crm != null">
                            <button type="button" class="btn btn-secondary w-100 mt-2" @click="connection_option = 'account'" :disabled="processing">
                                1-Click Integration
                            </button>
                        </div>
                        <div class="col-sm">
                            <button type="button" class="btn btn-secondary w-100 mt-2" :disabled="processing" @click="connection_option = 'file_upload'">
                                Upload Files
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";   
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Swal from 'sweetalert2';

    export default {
        components: {
            vSelect, vueDropzone: vue2Dropzone,Swal
        },
        props: ['user', 'client', 'integrations'],
        data() {
            return {
                local_client: null,
                local_user: null,
                connection_option: 'account',
                crm: null,
                selected_crm_info:{},
                // file upload
                if_uploaded:false, // for file upload only
                dropzoneOptions: {
                    url: "#",
                    method: 'put',
                    timeout: 0,
                    parallelUploads: 2,
                    dictDefaultMessage: "Click or Drag/Drop a File Here",
                    headers: {
                        'charset': "utf-8",
                        "Cache-Control": "",
                    },
                    withCredentials: false,
                    accept: function(file, done) {
                        let client_url = document.getElementById('client_url').value
                        this.options.headers['Content-Type'] = file.type;
                        window.axios.post( "/api/file_upload/get_signed_url", { url: client_url, file_name: Date.now() + "_" +file.name }).then(function(data) {

                           //I can't access the variable in the "data" property so I'll just save it to the html
                          if($("#dropzone").data("file_urls") == undefined)
                            $("#dropzone").data("file_urls", []);

                            //Save the URL for when it is actually sent in a second
                            var arr = $("#dropzone").data("file_urls");
                            arr[file.upload.uuid] = data.data.url;

                            $("#dropzone").data("file_urls", arr);
                            done();
                        });

                    },
                    init: function() {
                        const dz = this;

                        dz.on("processing", (file) => {
                            dz.options.headers["Content-Type"] = file.type;
                        });
                    },
                    maxFilesize: null,
                },
                form: {
                    error: false,
                    msg: "",
                },
                // send to colleague
                send_to_colleague: {
                    first_name: '',
                    last_name: '',
                    email: ''
                },
                send_to_colleague_errors: {
                    first_name: '',
                    last_name: '',
                    email: ''
                },
                processing: false
            };
        },
        beforeMount() {
            this.local_client = this.client;
            this.local_user = this.user;

            let systems = this.local_client.systems.crm;
            //Loop through the systems and this.integrations to find the system that matches the display_name
            for(let i = systems.length - 1; i >= 0; i--){
                for(let j = 0; j < this.integrations.length; j++)
                    if(this.integrations[j].display_name == systems[i]){
                        this.crm = this.integrations[j];
                        break;
                    }
            }

            if(this.crm == null) {
                this.connection_option = 'file_upload';
            } else {
                window.axios.get('/api/new_user/integration/' + this.crm.id)
                    .then(response => {
                        this.selected_crm_info = response.data;
                        if (response.data.type == 'credential') {
                            var fields = response.data.field;
                            for (var i = 0; i< fields.length; i++){
                                if (fields[i].type == 'password')
                                    fields[i].visible = false;

                                if (fields[i].type == 'select')
                                    fields[i].value = Object.keys(fields[i].options)[0];

                                fields[i].error = false;
                            }
                        }
                    }).catch(error => {
                        this.form.error = true;
                        this.form.msg = error.response.data.message;
                    });
            }
        },
        methods: {
            toggleVisibility(field){
                // const input = this.$refs.password;
                if (field.type === 'password') {
                    field.type = 'password-text';
                    field.visible = true;
                } else if (field.type === 'password-text') {
                    field.type = 'password';
                    field.visible = false;
                }
            },
            addTable(field_index) {
                this.selected_crm_info.field[field_index].value.push({
                    table_name:"",
                    table_type:""
                });
            },
            removeTable(field_index, table_row_index) {
                this.selected_crm_info.field[field_index].value.splice(table_row_index, 1);
            },
            credsAreValid() {
                if(this.selected_crm_info == null || this.selected_crm_info.field == null)
                    return true;

                let is_valid = true;
                for (var i = 0; i < this.selected_crm_info.field.length; i++) {
                    if(this.selected_crm_info.field[i].type == 'tables'){
                        for (var j = 0; j < this.selected_crm_info.field[i].value.length; j++) {
                            if(this.selected_crm_info.field[i].value[j].table_name == '' || this.selected_crm_info.field[i].value[j].table_type == ''){
                                this.selected_crm_info.field[i].error = true;
                                this.selected_crm_info.field[i].error_msg = 'This field is required.';
                                if(this.selected_crm_info.field[i].value.length > 1)
                                    this.selected_crm_info.field[i].error_msg += ' Be sure to remove any empty rows.';
                                is_valid = false;
                            }
                            else
                                this.selected_crm_info.field[i].error = false;
                        }
                    }
                    else if(this.selected_crm_info.field[i].required && (this.selected_crm_info.field[i].value == null ||
                        this.selected_crm_info.field[i].value == '')){
                        this.selected_crm_info.field[i].error = true;
                        this.selected_crm_info.field[i].error_msg = 'This field is required';

                        is_valid = false;
                    }
                    else
                        this.selected_crm_info.field[i].error = false;
                }

                this.$forceUpdate();
                return is_valid;
            },
            onProcessing(file) {
                this.if_uploaded = false;  
                //Set the upload URL for this specific file
                var file_urls = $("#dropzone").data('file_urls');
                document.getElementById("dropzone").dropzone.options.url = file_urls[file.upload.uuid];

            },
            onSuccess( file, res ) {
                var data = new FormData();
                data.append("file_name", file.name);
                data.append("gcs_path", file.xhr.responseURL);
                data.append("client_url", this.client.url);

                //Remove it from the dropzone UI
                var fadeTarget = document.getElementsByClassName("dz-success")[0];
                var fadeEffect = setInterval(function () {
                    if (!fadeTarget.style.opacity) {
                        fadeTarget.style.opacity = 1;
                    }
                    if (fadeTarget.style.opacity > 0) {
                        fadeTarget.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                        document.getElementById("dropzone").dropzone.removeFile(file);
                    }
                }, 50);

                if(document.getElementsByClassName("dz-file-preview").length == document.getElementsByClassName("dz-success").length){ 
                     //only appear once all of the uploads on the page have finished
                    this.if_uploaded = true;                    
                }
                window.axios.post('/api/file_upload/new_file', data);
            },
            onSending( file, xhr, formData ) {
                var _send = xhr.send;
                xhr.send = function() {
                  _send.call(xhr, file);
                };
            },
            hasFormErrors() {//validate the form
                if(this.send_to_colleague.first_name == null || this.send_to_colleague.first_name == ""){
                    this.send_to_colleague_errors.first_name = "First Name is required.";
                } else
                    this.send_to_colleague_errors.first_name = "";
                
                if(this.send_to_colleague.last_name == null || this.send_to_colleague.last_name == ""){
                    this.send_to_colleague_errors.last_name = "Last Name is required.";
                } else
                    this.send_to_colleague_errors.last_name = "";

                const reg = /^\w+([\.-]?\w+)*(\+?\w+([\.-]?\w+)*)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if(this.send_to_colleague.email == null || this.send_to_colleague.email == ""){
                    this.send_to_colleague_errors.email = "Email is required.";
                } else if(!reg.test(this.send_to_colleague.email)){
                    this.send_to_colleague_errors.email = 'Please enter a valid email address.';
                } else {
                    this.send_to_colleague_errors.email = "";
                }
                return false; 
            },
            inviteColleague() {
                this.processing = true;
                this.send_to_colleague.client_id = this.local_client.id;
                this.send_to_colleague.mail_type = 'crm';
                this.send_to_colleague.system = this.local_client.systems.crm[0];

                window.axios.post('/api/client/add_team_user', this.send_to_colleague)
                .then(response => {
                    this.processing = false;
                    Swal.fire({
                      title: "An invite has been sent",
                      text: this.send_to_colleague.first_name + " will receive an email invite shortly inviting them to connect your " + this.crm.display_name + " instance. In the meantime, you can explore the benchmarks. Your data will be added as soon as the system is connected.",
                      icon: "success",
                      iconColor: "#46705F",
                      showCancelButton: false,
                      confirmButtonColor: "#46705F",
                      confirmButtonText: "View Year End Benchmarks",
                    }).then((result) => {
                        window.location = "/year_end_benchmarks/" + this.local_client.url;
                    });
                }).catch(error => {
                    this.processing = false;
                    this.form.error = true;
                    this.form.msg = error.response.data.message;
                });
            },
            addIntegration() {
                this.processing = true;
                //Copy the value of the field data
                let data = {
                    client_id: this.local_client.id
                }
                //Loop through the field data and get the key as the data id
                this.selected_crm_info.field.forEach(field => {
                    data[field.id] = field.value;
                });

                window.axios.post('/api' + this.selected_crm_info.url, data)
                    .then(response => {
                        this.processing = false;
                        Swal.fire({
                          title: "Connection was successful!",
                          text: "Avid AI has successfully connected to your " + this.crm.display_name + " instance. Your data will begin loading and should be ready for the next steps in a few hours (we'll let you know when it is ready). In the meantime, you can explore the benchmarks.",
                          icon: "success",
                          iconColor: "#46705F",
                          showCancelButton: false,
                          confirmButtonColor: "#46705F",
                          confirmButtonText: "View Benchmarks",
                        }).then((result) => {
                            window.location = "/year_end_benchmarks/" + this.local_client.url;
                        });

                    }).catch(error => {
                        if(error.response.data.message != null && error.response.data.message != ""){
                            this.form.msg = error.response.data.message;
                        }
                        else{
                            this.form.msg = "There was an error saving your information. Please try again shortly";
                        }

                        this.processing = false;
                        this.form.error = true;
                    });
            },
            nextStep(){
                if(this.connection_option=='account' && this.credsAreValid()) {
                    this.addIntegration();
                    this.$emit('nextStep', null);
                    return;
                } else if(this.connection_option=='send_to_colleague' && !this.hasFormErrors()) {
                    this.inviteColleague();
                    // TODO: Api call to create the user
                    this.$emit('nextStep', null);

                    return;
                }
            }
        }
    }
</script>
