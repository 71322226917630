<template>
    <div class="org_settings" >
        <h1 class="mb-2">Organization Settings</h1>
        <div class="row px-2 mb-5">
            <nav id="org-setting-tab-nav" class="col-12 navbar navbar-expand primary-tab-bar pb-0">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mb-lg-0">
                        <li class="nav-item" v-for="tab_name in setting_tabs">
                            <a class="nav-link d-flex flex-nowrap pb-0 mb-0 mx-2 primary-tab" :class="{'primary-tab-active': tab_name == setting_tab_name_active}"
                            href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(tab_name)">
                                <span>{{ tab_name }}</span>
                            </a>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>

            <div class="card box-content py-3">
                <div class="card-body">
                    <div v-if="setting_tab_name_active == 'CONNECTIONS'">
                        <connections-settings :user="user" :client="client" :tab_name="tab_name" :integrations="integrations" :connections="connections" 
                        :datablends="datablends" :files="files" :num_files="num_files"></connections-settings>
                    </div>
                    <div v-if="setting_tab_name_active == 'AUDIENCES'" class="pathways-manage">
                        <pathways-manage-segments :client="client" :segments="local_segments"  :census_connections="census_connections" 
                            :connection_datasets="connections" :datasets="[]" :datablends="datablends"></pathways-manage-segments>
                    </div>
                    <div v-if="setting_tab_name_active == 'DATA BLENDS'">
                        <data-blends :client="client" :datablends="datablends"></data-blends>
                    </div>
                    <div v-if="setting_tab_name_active == 'PROFILE'">
                        <profile-settings :client="client" :user="user"></profile-settings>
                    </div>
                    <div v-if="setting_tab_name_active == 'FISCAL YEAR & DONORS'">
                        <fiscal-year-and-donor-settings :client="client"></fiscal-year-and-donor-settings>
                    </div>
                    <div v-if="setting_tab_name_active == 'TEAM'">
                        <team-settings :client="client" :user="user"></team-settings>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user', 'client', 'tab_name', 'integrations', 'connections', 'datablends', 'files', 'num_files', 'census_connections','segments'],
        data() {
            return {
                setting_tabs:['CONNECTIONS', 'AUDIENCES', 'PROFILE', 'FISCAL YEAR & DONORS', 'TEAM'],
                setting_tab_name_active: 'CONNECTIONS',
                user_prioritization: [],
                local_segments: [],
            };
        },
        beforeMount() {
            if (this.client.plan_level == 'insights' || this.client.plan_level == 'trial'){
                this.setting_tabs = ['CONNECTIONS','AUDIENCES', 'DATA BLENDS', 'PROFILE', 'FISCAL YEAR & DONORS', 'TEAM'];
            }

            // get segment info to pass into Audiences tab
            this.loadSegmentsInfo();

            //remove datablends if there aren't any
            if (this.datablends.length == 0 || this.user.user_type != 'admin'){
                this.setting_tabs = this.setting_tabs.filter(e => e !== 'DATA BLENDS');
            }

            if (this.tab_name =='integrations' || this.tab_name =='files'){
                this.setting_tab_name_active = 'CONNECTIONS';
            }
            else if (this.tab_name =='profile'){
                this.setting_tab_name_active = 'PROFILE';
            }
            else if (this.tab_name =='fiscal_year_donors'){
                this.setting_tab_name_active = 'FISCAL YEAR & DONORS';
            }
            else if (this.tab_name =='audiences'){
                this.setting_tab_name_active = 'AUDIENCES';
            }
            else if (this.tab_name =='team'){
                this.setting_tab_name_active = 'TEAM';
            }
            else if (this.tab_name =='data_blends'){
                this.setting_tab_name_active = 'DATA BLENDS';
            }
        },
        methods: {
            setActiveTab(tab_name){
                this.setting_tab_name_active = tab_name;
                var newURL = '/settings/'+this.client.url+'/';

                if (tab_name =='CONNECTIONS'){
                    newURL += 'integrations';
                } else if (tab_name =='PROFILE'){
                    newURL += 'profile';
                } else if (tab_name =='FISCAL YEAR & DONORS'){
                    newURL += 'fiscal_year_donors';
                } else if (tab_name =='AUDIENCES'){
                    newURL += 'audiences';
                } else if (tab_name =='TEAM'){
                    newURL += 'team';
                } else if (tab_name =='DATA BLENDS'){
                    newURL += 'data_blends';
                }

                window.history.replaceState({}, {}, newURL);
                // this.$forceUpdate();
            },
            loadSegmentsInfo(){
                var segments_temp = [];
                this.segments.forEach((segment) =>{

                    var pushed_pathways = [];
                    this.census_connections.forEach((conn) => {
                        conn.syncs.forEach((conn_sync)=>{
                            if (segment.id == conn_sync.segment_id){
                                var connection = this.connections.find((c)=>c.id == conn.connection_id);

                                if (!pushed_pathways.find((pathway) => pathway.conn_id == connection.id)){ // remove duplicates
                                    pushed_pathways.push({
                                        conn_id: connection.id,
                                        conn_name: connection.name,
                                        conn_icon: connection.integration.logo_url
                                    });                                    
                                }
                            }
                        })
                    });

                    segment.pushed_pathways = pushed_pathways;
                    segments_temp.push(segment);
                });
                this.local_segments = segments_temp;
            },
        }
    }
</script>
