var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 mb-3" },
      [
        _c("label", { staticClass: "form-label" }, [_vm._v("Chart")]),
        _vm._v(" "),
        _c("v-select", {
          staticClass: "searchable-select column-display w-100",
          attrs: {
            id: "add-chart-dropdown",
            options: _vm.chart_options,
            placeholder: "Select chart...",
            searchable: true,
            selectable: (option) => option.text != "",
            label: "text",
            filterable: false,
          },
          on: { search: _vm.searchChartOption, input: _vm.emitChartSelect },
          scopedSlots: _vm._u([
            {
              key: "selected-option",
              fn: function ({ text, category }) {
                return [_c("span", [_vm._v(_vm._s(text))])]
              },
            },
            {
              key: "option",
              fn: function ({ text, category }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "suboption",
                      class: category == null || category == "" ? "show" : "",
                      attrs: { "data-subcategory": category },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(text) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "no-options",
              fn: function ({ search, searching, loading }) {
                return [
                  _c("div", { staticClass: "suboption show" }, [
                    _c("em", [_vm._v("No results found")]),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.chart_dropdown_selected,
            callback: function ($$v) {
              _vm.chart_dropdown_selected = $$v
            },
            expression: "chart_dropdown_selected",
          },
        }),
        _vm._v(" "),
        _vm.add_chart_errors.chart_selected != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.add_chart_errors.chart_selected) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("Title")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.chart_modal_fields.title,
            expression: "chart_modal_fields.title",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "text",
          id: "chart-name-input",
          disabled: !_vm.chart_modal_fields.chart_selected,
        },
        domProps: { value: _vm.chart_modal_fields.title },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.chart_modal_fields, "title", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("Subtitle")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.chart_modal_fields.subtitle,
            expression: "chart_modal_fields.subtitle",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "text",
          id: "chart-name-input",
          disabled: !_vm.chart_modal_fields.chart_selected,
        },
        domProps: { value: _vm.chart_modal_fields.subtitle },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.chart_modal_fields, "subtitle", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: {
            type: "submit",
            id: "add-chart-button",
            disabled: _vm.form.busy || !_vm.chart_modal_fields.chart_selected,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.emitChartChangeToSection.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "fa fa-btn fa-save" }),
          _vm._v(" " + _vm._s(_vm.modal_action) + "\n        "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }