var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "metric-filter-list" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card box-content mb-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h2", [_vm._v("Metric, Dimension & Filter Management")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Create or edit the metrics, dimensions and filters that power the charts utilized throughout Avid AI. You can create new values that will be available for all clients or you can select a client from the list below to create some specific to them."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-sm-6 col-lg-4" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Select Visibility"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_client,
                        expression: "local_client",
                      },
                    ],
                    staticClass: "form-select",
                    attrs: { name: "client" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.local_client = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.changeClient()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("*** Global Calculations & Filters ***"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.clients, function (c) {
                      return _c("option", { domProps: { value: c } }, [
                        _vm._v(_vm._s(c.name)),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-lg-4" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Data Source"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data_blend,
                        expression: "data_blend",
                      },
                    ],
                    staticClass: "form-select",
                    attrs: { name: "data_source" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.data_blend = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.local_data_blends, function (d) {
                    return _c("option", { domProps: { value: d } }, [
                      _vm._v(
                        _vm._s(_vm._f("propercase")(d.type)) + " Data Blend"
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search_key,
                      expression: "search_key",
                    },
                  ],
                  staticClass: "form-control white-background",
                  attrs: {
                    type: "text",
                    placeholder: "Search by Name...",
                    name: "metric_filter_search",
                  },
                  domProps: { value: _vm.search_key },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.search_key = $event.target.value
                      },
                      function ($event) {
                        return _vm.searchByName()
                      },
                    ],
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.data_types, function (type) {
        return _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card box-content mb-3" }, [
            _c("div", { staticClass: "card-body px-0 pb-2" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h3", { staticClass: "mx-3" }, [
                    _vm._v(
                      _vm._s(_vm._f("propercase")(type)) +
                        "s\n                                "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-secondary mb-2 float-end btn-sm",
                        attrs: {
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#metric-filter-modal",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addMetricFilter(type)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                        _vm._v(
                          " Add a " +
                            _vm._s(_vm._f("propercase")(type)) +
                            "\n                                "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.getDataTypeOptions(type).length > 0
                ? _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c(
                      "table",
                      { staticClass: "table gy-0 m-0" },
                      [
                        _c("thead", { staticClass: "table-dark" }, [
                          _c("tr", [
                            _c("th", { staticClass: "align-middle ps-3" }, [
                              _vm._v("Name"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "align-middle d-none d-sm-table-cell",
                              },
                              [_vm._v("Source")]
                            ),
                            _vm._v(" "),
                            type == "metric"
                              ? _c(
                                  "th",
                                  {
                                    staticClass:
                                      "align-middle d-none d-sm-table-cell",
                                  },
                                  [_vm._v("Format")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "align-middle d-none d-sm-table-cell",
                              },
                              [_vm._v("Pages in Use")]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "align-middle d-none d-sm-table-cell",
                              },
                              [_vm._v("Charts in Use")]
                            ),
                            _vm._v(" "),
                            _c("th", {
                              staticClass:
                                "align-middle d-none d-sm-table-cell",
                              staticStyle: { width: "138px" },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "transition-group",
                          {
                            staticClass: "table-border-bottom-0",
                            attrs: { tag: "tbody", name: "table-row" },
                          },
                          _vm._l(
                            _vm.getDataTypeOptions(type),
                            function (metric) {
                              return _c("tr", { key: metric.id }, [
                                _c("td", { staticClass: "align-middle ps-3" }, [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(metric.admin_name) +
                                      " "
                                  ),
                                  metric.description != null &&
                                  metric.description != ""
                                    ? _c("i", {
                                        staticClass: "fas fa-info-circle ms-2",
                                        attrs: {
                                          "data-bs-toggle": "tooltip",
                                          title: metric.description,
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-md-table-cell d-sm-table-cell",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm._f("propercase")(
                                            metric.data_blend_type
                                          )
                                        ) +
                                        " Blend\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                type == "metric"
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "align-middle d-none d-md-table-cell d-sm-table-cell",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm._f("propercase")(
                                                metric.format
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-md-table-cell d-sm-table-cell",
                                  },
                                  [
                                    metric.page_usage_count > 0
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/insights/report_builder/filter/" +
                                                metric.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm._f("number_with_zero")(
                                                    metric.page_usage_count
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      : metric.page_usage_count == 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("number_with_zero")(
                                                metric.page_usage_count
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", {
                                          staticClass:
                                            "spinner-border spinner-border-sm text-primary",
                                          attrs: { role: "status" },
                                        }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-md-table-cell d-sm-table-cell",
                                  },
                                  [
                                    metric.chart_usage_count > 0
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/insights/charts/" + metric.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm._f("number_with_zero")(
                                                    metric.chart_usage_count
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      : metric.chart_usage_count == 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("number_with_zero")(
                                                metric.chart_usage_count
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", {
                                          staticClass:
                                            "spinner-border spinner-border-sm text-primary",
                                          attrs: { role: "status" },
                                        }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "align-middle text-end pe-3" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-bs-toggle": "tooltip",
                                          title: "Copy " + type,
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-sm ms-1 mb-1",
                                            attrs: {
                                              "data-bs-toggle": "modal",
                                              "data-bs-target":
                                                "#metric-filter-modal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyMetricFilter(
                                                  metric
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa-solid fa-copy me-0",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-bs-toggle": "tooltip",
                                          title: "Edit " + type,
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-sm ms-1 mb-1",
                                            attrs: {
                                              "data-bs-toggle": "modal",
                                              "data-bs-target":
                                                "#metric-filter-modal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editMetricFilter(
                                                  metric
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa-solid fa-edit me-0",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary btn-sm ms-1 mb-1",
                                        attrs: {
                                          title: "Delete " + type,
                                          "data-bs-toggle": "tooltip",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteMetricFilter(
                                              metric
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa-solid fa-trash me-0",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm.search_key != ""
                ? _c(
                    "div",
                    { staticClass: "text-center px-3 border-top pt-3 pb-2" },
                    [
                      _c("p", [
                        _vm._v(
                          "No " + _vm._s(type) + "s match your search criteria"
                        ),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "text-center px-3 border-top pt-3 pb-2" },
                    [
                      _c("p", [
                        _vm._v(
                          "No " +
                            _vm._s(type) +
                            "s have been created yet. Add your first " +
                            _vm._s(type) +
                            " using the button below."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-bs-toggle": "modal",
                            "data-bs-target": "#metric-filter-modal",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addMetricFilter(type)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-fw fa-plus" }),
                          _vm._v(
                            " Add a " +
                              _vm._s(_vm._f("propercase")(type)) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
            ]),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "metric-filter-modal",
          "data-bs-backdrop": "static",
          "aria-labelledby": "add-metric-filter-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end close-modal",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "add-metric-filter-modal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(
                    _vm._s(_vm._f("propercase")(_vm.local_type)) + " Editor"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0 pb-0" },
              [
                _c("metric-filter-builder", {
                  ref: "mf_builder",
                  attrs: {
                    data_blend: _vm.data_blend,
                    modal_id: "metric-filter-modal",
                    client_id: _vm.local_client_id,
                    type: _vm.local_type,
                  },
                  on: { update: _vm.mfAdded },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }