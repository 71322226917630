var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-builder-list" }, [
    _c("h2", { staticClass: "mb-3" }, [_vm._v("Report Builder")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-4 mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.local_client_id,
                expression: "local_client_id",
              },
            ],
            staticClass: "form-select d-inline white-background",
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.local_client_id = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.changeClientSelection()
                },
              ],
            },
          },
          [
            _c("option", { attrs: { value: "", selected: "" } }, [
              _vm._v("*** Template Reports ***"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.clients, function (c) {
              return _c("option", { domProps: { value: c.id } }, [
                _vm._v(_vm._s(c.name) + " Reports"),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col mb-3" }, [
        _vm.local_client_id != "" &&
        _vm.local_report_pages.length > 0 &&
        _vm.local_blends.length <= 1
          ? _c(
              "a",
              {
                staticClass: "btn btn-secondary float-sm-end ms-3",
                attrs: {
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#dimension-filter-modal",
                },
              },
              [
                _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                _vm._v(" Add Custom Filter\n            "),
              ]
            )
          : _vm.local_client_id != "" &&
            _vm.local_report_pages.length > 0 &&
            _vm.local_blends.length > 1
          ? _c("div", { staticClass: "dropdown float-sm-end ms-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "dropdownMenuButton" },
                },
                _vm._l(_vm.local_blends, function (blend) {
                  return _c("li", { key: blend.id }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          href: "#",
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#dimension-filter-modal",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.selected_blend = blend
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("propercase")(blend.type)))]
                    ),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.local_client_id == "" && _vm.local_report_pages.length > 0
          ? _c(
              "a",
              {
                staticClass: "btn btn-primary float-sm-end",
                attrs: { href: "/insights/report_builder/template/null/new" },
              },
              [
                _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                _vm._v(" Create a Report\n            "),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.local_client_id != "" && _vm.local_report_pages.length > 0
          ? _c(
              "a",
              {
                staticClass: "btn btn-primary float-sm-end",
                attrs: {
                  href:
                    "/insights/report_builder/client_specific/" +
                    _vm.local_client.url +
                    "/new",
                },
              },
              [
                _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                _vm._v(" Create a Report\n            "),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.local_report_pages.length > 0
      ? _c("div", { staticClass: "card box-content mb-3" }, [
          _c("div", { staticClass: "card-body p-0" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive mb-2" }, [
              _c("table", { staticClass: "table gy-0 m-0" }, [
                _c("thead", { staticClass: "table-dark" }, [
                  _c("tr", [
                    _c("th", { staticClass: "align-middle pt-0" }, [
                      _vm._v("Report Name"),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "align-middle pt-0" }, [
                      _vm._v("Status"),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "align-middle pt-0" }, [
                      _vm._v("Group Name"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "align-middle d-none pt-0 d-sm-table-cell",
                      },
                      [_vm._v("Data Blend Type")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "align-middle d-none pt-0 d-sm-table-cell",
                      },
                      [_vm._v("Last Updated At")]
                    ),
                    _vm._v(" "),
                    _vm.local_client_id == ""
                      ? _c(
                          "th",
                          {
                            staticClass:
                              "align-middle d-none pt-0 d-sm-table-cell",
                          },
                          [_vm._v("Last Deployed At")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "align-middle d-none pt-0 d-sm-table-cell text-end",
                      },
                      [_vm._v("Actions")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  { staticClass: "table-border-bottom-0" },
                  _vm._l(_vm.local_report_pages, function (page, index) {
                    return _c("tr", [
                      _c("td", { staticClass: "align-middle" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/insights/report_builder/" + page.id,
                            },
                          },
                          [_vm._v(_vm._s(page.name))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle" }, [
                        _c(
                          "div",
                          {
                            staticClass: "badge p-2",
                            class: {
                              "badge-secondary": page.status == "archived",
                              "badge-success": page.status == "active",
                              "badge-primary": page.status == "draft",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("propercase")(page.status)) +
                                "\n                                "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(page.group_name) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-middle d-none d-sm-table-cell" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm._f("propercase")(page.data_blend_type)
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-middle d-none d-sm-table-cell" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm._f("datetime")(page.updated_at)) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.local_client_id == ""
                        ? _c(
                            "td",
                            {
                              staticClass:
                                "align-middle d-none d-sm-table-cell",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm._f("datetime")(page.deployed_at)) +
                                  "\n                            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "align-middle d-none d-sm-table-cell text-end",
                        },
                        [
                          page.is_template
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-secondary btn-sm ms-1 mb-1",
                                  attrs: {
                                    title:
                                      "Deploy Report: This will update existing copies and create new versions in organizations that don't yet have a copy of this report.",
                                    "data-bs-toggle": "tooltip",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deployTemplate(page.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-solid fa-rocket me-0",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary btn-sm ms-1 mb-1",
                              attrs: {
                                title:
                                  "Copy Report: This will duplicate the report and all associated charts and filters.",
                                "data-bs-toggle": "tooltip",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyReport(page.id)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-solid fa-copy me-0",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          page.is_template == false
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-secondary btn-sm ms-1 mb-1",
                                  attrs: {
                                    title: "View Live Report",
                                    "data-bs-toggle": "tooltip",
                                    href:
                                      "/insights/" +
                                      _vm.local_client.url +
                                      "/" +
                                      page.url,
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-solid fa-eye me-0",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary btn-sm ms-1 mb-1",
                              attrs: {
                                title: "Delete Report",
                                "data-bs-toggle": "tooltip",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteReport(page.id)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-solid fa-trash me-0",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ])
      : _c("div", [
          _c("div", { staticClass: "card box-content mb-3" }, [
            _c("div", { staticClass: "card-body mt-3 text-center" }, [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v("No reports have been created yet."),
              ]),
              _vm._v(" "),
              _vm.local_client_id == ""
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mb-3",
                      attrs: {
                        href: "/insights/report_builder/template/null/new",
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                      _vm._v(" Create a Report\n                "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.local_client_id != ""
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mb-3",
                      attrs: {
                        href:
                          "/insights/report_builder/client_specific/" +
                          _vm.local_client.url +
                          "/new",
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                      _vm._v(" Create a Report\n                "),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "dimension-filter-modal",
          "aria-labelledby": "dimension-filter-modal",
          "data-bs-backdrop": "static",
          "aria-hidden": "true",
        },
      },
      [
        _c("report-builder-udf-popup", {
          attrs: {
            client_id: _vm.local_client_id,
            data_blend: _vm.selected_blend,
            show_group_name: true,
          },
          on: { updateFilterWithinGroup: _vm.addFilter },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary dropdown-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenuButton",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
        _vm._v(" Add Custom Filter\n                "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }