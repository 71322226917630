<template>
    <div class="modal fade" :id="id" data-bs-backdrop="static"
        aria-labelledby="support-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header border-bottom-0 pb-0">
                    <div class="text-center w-100">
                        <button type="button" class="btn btn-none float-end close-modal"
                            data-bs-dismiss="modal">
                            <i class="fa fa-close me-0"></i>
                        </button>
                        <h4 class="mt-2">Support / Suggestions</h4>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col mb-3">
                            <label class="form-label">Type of Request</label>
                            <select class="form-select"  v-model="form.category"
                                v-on:change="errors.category = ''"
                                :class="((errors.category != '' )? 'is-invalid':'')">
                                <option value="">** Please Select **</option>
                                <option value="BILLING_ISSUE">Billing / Pricing</option>
                                <option value="FEATURE_REQUEST">Feature Request</option>
                                <option value="GENERAL_INQUIRY">General Question</option>
                                <option value="PRODUCT_ISSUE">Report a Bug</option>
                                <option value="SETUP_HELP">Setup Assistance</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.category != ''">
                                {{errors.category}}
                            </div>
                        </div>
                        <div class="col-sm-9 mb-3" v-if="form.category == 'PRODUCT_ISSUE'">
                            <label class="form-label">Level of Urgency</label>
                            <select class="form-select"  v-model="form.urgency"
                                v-on:change="errors.urgency = ''"
                                :class="((errors.urgency != '' )? 'is-invalid':'')">
                                <option value="LOW" >Low - "Something looks off"</option>
                                <option value="MEDIUM">Medium - "Something's broken but I can work around it."</option>
                                <option value="HIGH">High - "It's broken and I need it fixed ASAP."</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.urgency != ''">
                                {{errors.urgency}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-3">
                            <label class="form-label">Quick Summary</label>
                            <input type="text" class="form-control" v-model="form.title"
                                v-on:change="errors.title = ''" :class="((errors.title != '' )? 'is-invalid':'')">
                            <div class="text-danger invalid-feedback" v-if="errors.title != ''">
                                {{errors.title}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-3">
                            <label class="form-label">
                                <span v-if="form.category == 'PRODUCT_ISSUE'">Description of the Issue</span>
                                <span v-else-if="form.category == 'FEATURE_REQUEST'">Description of the Idea</span>
                                <span v-else>More Details</span>
                            </label>
                            <textarea class="form-control full-height" style="min-height: 8 0px;"  v-model="form.description" v-on:change="errors.description = ''" :class="((errors.description != '' )? 'is-invalid':'')"></textarea>
                            <div class="text-danger invalid-feedback" v-if="errors.description != ''">
                                {{errors.description}}
                            </div>
                        </div>
                    </div>
                    <div class="row footer">
                        <div class="col mb-3">
                            <button class="btn btn-primary me-3" v-on:click="submitTicket()"
                            :disabled="working">
                                <span v-if="working">
                                    <div class="spinner-border  spinner-border-sm text-white float-left" role="status"> <span class="visually-hidden">Loading...</span></div> Working...</span>
                                <span v-else>Submit</span>
                            </button>
                            <button class="btn btn-secondary" data-bs-dismiss="modal" :disabled="working">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import html2canvas from 'html2canvas';

    export default {
        props: {
            user: {},
            client: {},
            selected_ticket_type: {
                default: '',
            },
            id: {
                default: 'support-modal',
            },
        },
        data() {
            return {
                form: {
                    category: '',
                    urgency: '',
                    title: '',
                    description: ''
                },
                errors: {
                    category: '',
                    title: '',
                    description: '',
                    urgency: ''
                },
                working: false

            };
        },
        updated() {
            if(this.selected_ticket_type != ''){
                this.form.category = this.selected_ticket_type;
            }
        },
        computed: {

        },
        methods: {
            submitTicket() {
                this.errors = {
                    category: '',
                    title: '',
                    description: '',
                    urgency: ''
                };
                if(this.form.category == '') {
                    this.errors.category = 'Please select a category';
                }
                if(this.form.title == '') {
                    this.errors.title = 'Please provide a title';
                }
                if(this.form.description == '') {
                    this.errors.description = 'Please provide a description';
                }
                if(this.form.category == 'PRODUCT_ISSUE' && this.form.urgency == '') {
                    this.errors.urgency = 'Please select a level of urgency';
                }

                if(this.errors.category == '' && this.errors.title == '' && this.errors.description == '' && this.errors.urgency == ''){

                    this.working = true;
                    html2canvas(document.getElementById('snapshot-content')).then(canvas => {
                      // Create an image
                      const image = canvas.toDataURL("image/png");
                      this.form.image = image;
                      this.postForm();
                    });
                }
            },
            postForm() {
                //Add the URL into the form variable
                this.form.url = window.location.href;
                if(this.client != null)
                    this.form.company_hubspot_id = this.client.hubspot_id;
                this.form.user_hubspot_id = this.user.hubspot_id;
                this.form.email = this.user.email;

                window.axios.post('/api/support', this.form)
                    .then(response => {
                        this.working = false;
                        this.form = {
                            category: '',
                            urgency: '',
                            title: '',
                            description: ''
                        };
                        this.errors = {
                            category: '',
                            title: '',
                            description: ''
                        };
                        //Close the modal (using javascript, not jquery)
                        var myModalEl = document.getElementById('support-modal');
                        var modal = bootstrap.Modal.getInstance(myModalEl)
                        modal.hide();

                        //Show a SweetAlert modal
                        Swal.fire({
                            title: 'Support Ticket Submitted',
                            text: 'Your request has been received. Your account manager will be reaching out to you shortly to collect additional information and discuss next steps. We\'re sorry about the inconvenience!',
                            icon: "success",
                            iconColor:  "#46705F",
                            confirmButtonColor: "#46705F",
                            confirmButtonText: 'OK'
                        });

                    })
                    .catch(error => {
                        this.working = false;
                        Swal.fire({
                            title: 'Error',
                            html: 'This is embarrassing! We ran into an error submitting your error. We\'ve created a standalone page for just this situation. <a target="_blank" href="https://share.hsforms.com/1GwisSMM2TTKAQ8uKpNUBpQram1t">Please visit this link to try again.</a>',
                            icon: "warning",
                            iconColor:  "#A61C3C",
                            confirmButtonColor: "#A61C3C",
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            var myModalEl = document.getElementById('support-modal');
                            var modal = bootstrap.Modal.getInstance(myModalEl)
                            modal.hide();
                        });
                    });
            }
        }

    }
</script>
