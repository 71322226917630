<template>
    <div id="pathways-manage-pathways">
        <div class="row mt-3">
            <div class="col-12">
                <h5>Your Pathways ({{ local_connection_datasets.length }})</h5>
                <p>Pathways are a simple way to link your various tools together so that critical data is kept in sync. Once you've made an connection into one of your marketing or fundraising systems, it will show up below (if data is able to be pushed). Below each system, you will see the available audiences within each data set and data blend. If there are additional audiences you'd like to push, simply create it here.</p>
            </div>
        </div>

        <div class="row my-3">
            <transition-group tag="div" name="fade-slow">
                <div v-for="(conn, conn_index) in local_connection_datasets" class="mb-2 position-relative" :id="'conn_' + conn.id" :key="conn.id" ref="managePathways">
                    <div class="accordion accordion-flush">
                        <div class="accordion-item">
                            <div class="accordion-header px-3 py-2">
                                <div class="accordion-button collapsed p-0" type="button"
                                    :data-bs-target="'#manage-pathway-' + conn.id" data-bs-toggle="collapse"
                                    aria-expanded="false" :aria-controls="'#manage-pathway-' + conn.id">
                                    <div class="d-flex align-items-center">
                                        <img :src="conn.integration.logo_url" :alt="conn.integration.name" class="avatar rounded-circle avatar-sm me-2 bg-white">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex align-items-center">
                                                <h4 class="d-inline-block m-0">{{(isFile(conn) ? 'File Export' : conn.name)}}</h4>
                                                <div class="badge badge-success d-inline-block ms-2">Active</div>
                                            </div>
                                            <p class="m-0">
                                                {{conn.pushed_segment_count}} {{conn.pushed_segment_count != 1 ? "Audiences" : "Audience" }}
                                                {{ isFile(conn) ? "Customized" : "Pushed" }}
                                            </p>
                                        </div>    
                                    </div>
                                </div>     
                            </div>

                            <div :id="'manage-pathway-' + conn.id" class="collapse accordion-collapse p-3 pt-0">
                                <hr class="m-0">
                                <div class="card mt-3" v-for="(set, set_index) in conn.datasets_to_sync">
                                    <div class="card-header">
                                        <div class="d-flex flex-row justify-content-between align-items-center gap-3">
                                            <div class="d-flex justify-content-start">
                                                <h5>{{ set.name | propercase }}</h5>
                                            </div>
                                            <div class="d-flex justify-content-end justify-self-end gap-2" v-if="conn.census_destination_id != null || isFile(conn) ">
                                                <a class="btn btn-secondary me-1" :href="'/pathways/' + client.url + '/' + conn.id + '/mapping/' + set.data_source + '/' + set.id">
                                                    <img class="icon pe-0" src="/img/icons/dialexa-icons/map.svg"> Default Mapping
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body avid-table pathways-manage-pathway-table p-0">
                                        <table class="table table-responsive m-0 mb-2">
                                            <thead>
                                                <tr class="table-header-row">
                                                    <th v-on:click="sortByKey('name', conn_index, set_index)" class="table-column-1">
                                                        <div class="d-flex flex-row">
                                                            Audience
                                                            <div class="d-flex flex-column">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='name' && sort_order=='asc')? 'sort-key-active' :''">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='name' && sort_order=='des')? 'sort-key-active' :''">
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th v-on:click="sortByKey('sizes', conn_index, set_index)" class="table-column-2">
                                                        <div class="d-flex flex-row">
                                                            Estimated Records
                                                            <div class="d-flex flex-column">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='sizes' && sort_order=='asc')? 'sort-key-active' :''">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='sizes' && sort_order=='des')? 'sort-key-active' :''">
                                                            </div>
                                                             <i class="fas fa-info-circle text-primary ms-2" data-bs-toggle="tooltip" title="This estimate will vary, even for the same audience, based upon the destination and what it considers the 'primary key'."></i>
                                                        </div>
                                                    </th>
                                                    <th v-on:click="sortByKey('sync_active', conn_index, set_index)" class="table-column-3">
                                                        <div class="d-flex flex-row">
                                                            {{ isFile(conn) ? 'Custom Export Columns' : 'Sync Status' }}
                                                            <div class="d-flex flex-column">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='sync_active' && sort_order=='asc')? 'sort-key-active' :''">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='sync_active' && sort_order=='des')? 'sort-key-active' :''">                                                          
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th v-on:click="sortByKey('last_synced', conn_index, set_index)" class="table-column-4"  v-if="!isFile(conn)">
                                                        <div class="d-flex flex-row">
                                                            Last Synced
                                                            <div class="d-flex flex-column">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='last_synced' && sort_order=='asc')? 'sort-key-active' :''">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='last_synced' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th v-on:click="sortByKey('sync_errors', conn_index, set_index)" class="table-column-5" v-if="!isFile(conn)">
                                                        <div class="d-flex flex-row">
                                                            Errors
                                                            <div class="d-flex flex-column">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='sync_errors' && sort_order=='asc')? 'sort-key-active' :''">
                                                                <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_conn_index == conn_index && sort_set_index == set_index && sort_key=='sync_errors' && sort_order=='des')? 'sort-key-active' :''">                                                          
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th class="table-column-6"  style="width:182px;">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody class="table-border-bottom-0">
                                                <tr v-for="(segment, seg_index) in set.segments" :key="segment.id" :id="'conn-'+conn.id+'-segment-row-' + segment.id">
                                                    <td class="align-start" >
                                                        <a :href="'/audience/' + client.url +'/' + segment.id">
                                                            {{ segment.name }}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {{ getSize(segment, conn.id) | number_with_zero }}
                                                    </td>
                                                    <td class="align-start" v-if="!isFile(conn)">
                                                        <div v-if="segment.sync_active ==1" class="badge badge-success ms-2">Active</div>
                                                        <div v-else class="badge badge-danger ms-2">Inactive</div>
                                                    </td>
                                                    <td class="align-start" v-else>
                                                        <div v-if="segment.sync_active ==1" class="badge badge-success ms-2">True</div>
                                                        <div v-else class="badge badge-secondary ms-2">False</div>
                                                    </td>
                                                    <td class="align-start" v-if="!isFile(conn)">
                                                        {{ segment.last_synced | date }}
                                                    </td>
                                                    <td class="align-start"  v-if="!isFile(conn)">
                                                        {{ segment.sync_errors ? segment.sync_errors : "-" }}
                                                    </td>

                                                    <td class="align-start show-on-row-hover">
                                                        <button class="btn btn-none me-1" :disabled="export_busy[conn.id + '_' + segment.id] != null && export_busy[conn.id + '_' + segment.id] == true"
                                                        @click.prevent="exportSegment(conn.id, segment)">
                                                            <img class="icon" src="/img/icons/dialexa-icons/file-download.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Export Audience"
                                                            v-if="export_busy[conn.id + '_' + segment.id] == null || export_busy[conn.id + '_' + segment.id] == false "> 
                                                            <div v-else class="spinner-border spinner-border-sm ms-2" role="status"><span class="visually-hidden">Loading...</span></div>
                                                        </button>

                                                        <a class="btn btn-none me-1" :href="'/pathways/' + client.url + '/' + conn.id + '/mapping/' + set.data_source + '/' + set.id + '/' + segment.id" v-if="segment.sync_active != 0" >
                                                            <img class="icon" src="/img/icons/dialexa-icons/map.svg" data-bs-toggle="tooltip" :title="isFile(conn) ? 'Change Export Columns' : 'Map Audience to Destination'" >
                                                        </a>

                                                        <button class="btn btn-none me-1" @click.prevent="toggleSyncSegment(conn, segment)" :disabled="sync_busy[conn.id + '_' + segment.id] != null && sync_busy[conn.id + '_' + segment.id] == true" v-if="!isFile(conn)">
                                                            <span v-if="sync_busy[conn.id + '_' + segment.id] == null || sync_busy[conn.id + '_' + segment.id] == false ">
                                                                <img class="icon" src="/img/icons/dialexa-icons/sync.svg" data-bs-toggle="tooltip" title="Activate Sync" v-if="segment.sync_active == 0" >
                                                                <img class="icon" src="/img/icons/dialexa-icons/forbidden.svg" data-bs-toggle="tooltip" title="Deactivate Sync" v-else >
                                                            </span>
                                                            <div v-else class="spinner-border spinner-border-sm ms-2" role="status"><span class="visually-hidden">Loading...</span></div>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>   
    </div>
</template>

<script>
    export default {
        props: ['connection_datasets', 'client', 'segments', 'datasets', 'datablends', 'census_connections'],
        data() {
            return {
                local_datasets: [],
                local_datablends: [],
                local_census_connections: [],
                local_connection_datasets:[],
                segment: {
                    name: '',
                    data_source_id: null,
                    filter: null
                },
                sort_conn_index: null,
                sort_set_index: null,
                sort_key: null,
                sort_order: 'asc',
                export_busy:[],
                sync_busy:[]
            };
        },
        beforeMount() {
            this.local_datasets = this.datasets;
            this.local_datablends = this.datablends;
            this.local_census_connections = this.census_connections;
            this.local_connection_datasets = this.connection_datasets;

        },
        mounted(){
            const params = new URLSearchParams(window.location.search);
            if(params.get('conn') != null){
                var pathwayDiv = document.querySelector('#manage-pathway-'+params.get('conn'));
                pathwayDiv.classList.add('show');

                var accordionToggleBtn = document.querySelector('[data-bs-target="#manage-pathway-' + params.get('conn') + '"]');
                accordionToggleBtn.classList.remove('collapsed');
            }
            //Highlight the segment row
            if(params.get('conn') != null && params.get('seg') != null){
                var seg_row = document.querySelector('#conn-'+params.get('conn')+'-segment-row-'+params.get('seg'));
                seg_row.classList.add('segment-row-highlight');
            }
       
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            isFile(conn) {
                return conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38';
            },
            getSize(segment, conn_id) {
                //If it hasn't been sent, just show 0
                if(!segment.sizes)
                    return null;

                let sizes = segment.sizes;
                let census_conn_id = 0;
                //Find the census connection that matches connection and data source info
                for(var i = 0; i < this.local_census_connections.length; i++)
                    if(this.local_census_connections[i].connection_id == conn_id && segment.data_source_id == this.local_census_connections[i].data_source_id &&
                        this.local_census_connections[i].source == segment.source)
                        census_conn_id = this.local_census_connections[i].id;

                //Get one that is specific to this census connection
                for (var i = 0; i < sizes.length; i++){
                    if (sizes[i].id == census_conn_id)
                        return sizes[i].size;
                }

                //Get one that is specific to this census connection
                for (var i = 0; i < sizes.length; i++){
                    if (sizes[i].id == 0)
                        return sizes[i].size;
                }

                return null
            },
            exportSegment(conn_id, segment) {
                //See if there is a census_connection set up
                for(var i = 0; i < this.local_census_connections.length; i++) {
                    if(this.local_census_connections[i].connection_id == conn_id && segment.source ==
                        this.local_census_connections[i].source && segment.data_source_id ==
                        this.local_census_connections[i].data_source_id) {

                        var form_data = {
                            segment_id: segment.id,
                            census_connection: this.local_census_connections[i]
                        }

                        this.export_busy[conn_id + '_' + segment.id] = true;
                        this.$forceUpdate();

                        //Call the webhook to save the new sync
                        window.axios.post('/api/census/export', form_data)
                            .then(response => {
                                //Make a link and then "click" on it
                                var link = document.createElement("a");
                                link.href = response.data.url;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                this.export_busy[conn_id + '_' + segment.id] = false;
                                this.$forceUpdate();
                            })
                            .catch(error => {
                                if(error.response.status == 400){
                                    //Fields weren't mapped, time to redirect....
                                    this.export_busy[conn_id + '_' + segment.id] = false;
                                    document.location.href = '/pathways/' + this.client.url + '/' + segment.id + '/' + conn_id;
                                }
                                    
                            });
                        return;
                    }
                }

                //If they get this far, the fields weren't mapped. Send to the mapping page that will also export
                document.location.href = '/pathways/' + this.client.url + '/' + segment.id + '/' + conn_id;
            },
            editSegment(seg) {
                this.segment = seg;
            },
            toggleSyncSegment(conn, segment) {
                var conn_id = conn.id;

                if(conn.census_destination_id == null){
                    //If it requires OAuth, show the popup
                    if(conn.integration.census_requires_oauth){
                        // census oauth
                        var self = this;
                        Swal.fire({
                            title: "Additional Access is Needed",
                            text: "Avid AI has partnered with a software service named Census to activate the ability to push data to "+conn.integration.display_name+". This destination requires additional authentication before it can be used.",
                            iconHtml: '<i class="fa-solid fa-exclamation"></i>',
                            iconColor: "#25473E",
                            showCancelButton: true,
                            confirmButtonColor: "#25473E",
                            confirmButtonText: "Proceed",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location = '/integrations/'+this.client.url+'/census/oauth/' + conn.id + '/'+ segment.id;
                                }
                            });
                    }
                    else {
                        //Otherwise, jump to the mapping page
                        window.location = '/pathways/' + this.client.url + '/' +segment.id + '/' + conn.id;
                    }

                    return;
                }


                //See if there is a census_connection set up
                for(var i = 0; i < this.local_census_connections.length; i++) {
                    if(this.local_census_connections[i].connection_id == conn_id && segment.source ==
                        this.local_census_connections[i].source && segment.data_source_id ==
                        this.local_census_connections[i].data_source_id) {

                        var form_data = {
                            client_id: this.client.id,
                            segment_id: segment.id,
                            census_connection_id: this.local_census_connections[i].id,
                            active: !segment.sync_active,
                        }
                        this.sync_busy[conn_id + '_' + segment.id] = true;
                        this.$forceUpdate();

                        //Call the webhook to save the new sync
                        window.axios.post('/api/census/add_sync', form_data)
                            .then(response => {
                                Swal.fire({
                                    html: '<h4 class="mb-0 text-white">Sync '+(segment.sync_active == 1 ? 'Deactivated' : 'Activated')+'!</h4>',
                                    target: '#pathways-manage-pathways',
                                    icon: 'success',
                                    toast: true,
                                    position: 'bottom-right',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    iconColor: 'white',
                                    customClass: {
                                        popup: 'bg-primary'
                                    },
                                });
                                //Toggle the sync status (and the date)
                                if(segment.sync_active == 1){
                                    segment.sync_active = 0;
                                    segment.last_synced = null;
                                }
                                else {
                                    segment.sync_active = 1;
                                    segment.last_synced = new Date();
                                }
                                this.sync_busy[conn_id + '_' + segment.id] = false;
                                this.updatePushedSegmentCount(conn_id);
                                this.$forceUpdate();
            
                            }).catch(error => {
                                if(error.response.status == 400)
                                    //Fields weren't mapped, time to redirect....
                                    document.location.href = '/pathways/' + this.client.url + '/' + conn_id + '/mapping/' + segment.source + '/' + segment.data_source_id;
                                if(error.response.status == 500) {
                                    if(error.response.data.census_error != null)
                                        this.local_census_connections[i].status_msg = error.response.data.census_error;
                                    if(error.response.data.sync_error != null)
                                        this.local_census_connections[i].syncs.push({
                                            last_sync_status: error.response.data.sync_error,
                                            client_id: this.client.id,
                                            segment_id: segment.id,
                                            census_connection_id: this.local_census_connections[i].id
                                        });
                                }
                                this.sync_busy[conn_id + '_' + segment.id] = false;
                                this.$forceUpdate();
                            });
                        return;
                    }
                }

                //If not, redirect to the mapping page
                document.location.href = '/pathways/' + this.client.url + '/' + conn_id + '/mapping/' + segment.source + '/' + segment.data_source_id;
            },
            updatePushedSegmentCount(conn_id){
                var index = this.local_connection_datasets.findIndex(conn => conn.id = conn_id);
 
                var count_temp = 0;
                this.local_connection_datasets[index].datasets_to_sync.forEach(dataset =>{
                    dataset.segments.forEach(segment =>{
                        if(segment.last_synced){
                            count_temp++;
                        }
                    })
                })
                this.local_connection_datasets[index].pushed_segment_count = count_temp;
            },
            updateSegmentToDataSet(seg) {
                var set = this.local_datasets;

                if(seg.source == 'data_blend' || seg.source == 'datablend')
                    set = this.local_datablends;

                for(var i = 0; i < set.length; i++)
                    if(set[i].id == seg.data_source_id)
                        for(var j= 0; j < set[i].segments.length; j++)
                            if( set[i].segments[j].id == seg.id)
                                set[i].segments[j] = seg;

                for(var i = 0; i < this.local_connection_datasets.length; i++)
                    for(var j = 0; j < this.local_connection_datasets[i].datasets_to_sync.length; j++)
                        for(var k = 0; k < this.local_connection_datasets[i].datasets_to_sync[j].segments.length; k++)
                            if(this.local_connection_datasets[i].datasets_to_sync[j].segments[k].id == seg.id){
                                let tmp_seg = {...seg};
                                tmp_seg.sync_active = this.local_connection_datasets[i].datasets_to_sync[j].segments[k].sync_active;
                                tmp_seg.sync_errors = this.local_connection_datasets[i].datasets_to_sync[j].segments[k].sync_errors;
                                this.local_connection_datasets[i].datasets_to_sync[j].segments[k] = tmp_seg;
                            }

                this.$forceUpdate();
            },
            sortByKey(key, conn_index, set_index){
                this.sort_key = key;
                this.sort_conn_index = conn_index;
                this.sort_set_index = set_index;

                if (this.sort_key != key){
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel();
            },
            sortDataWithLabel(){
                let segments = this.local_connection_datasets[this.sort_conn_index].datasets_to_sync[this.sort_set_index].segments;
                if(segments.length == 0)
                    return;

                //Find the right census connection ID
                let census_conn_id = 0;
                for(var i = 0; i < this.local_census_connections.length; i++)
                    if(this.local_census_connections[i].connection_id == this.local_connection_datasets[this.sort_conn_index].id && segments[0].data_source_id == this.local_census_connections[i].data_source_id &&
                        this.local_census_connections[i].source == segments[0].source)
                        census_conn_id = this.local_census_connections[i].id;

                if(this.sort_key == 'sizes'){
                    if(this.sort_order == 'asc')
                        segments.sort((a, b) => {
                            //Find the size that matches the census connection ID
                            let aFound = (a[this.sort_key] == null) ? false : a[this.sort_key].find(item => item.id === census_conn_id);
                            //If I didn't find one, just find the default (where ID = 0)
                            if(!aFound)
                                aFound = (a[this.sort_key] == null) ? false : a[this.sort_key].find(item => item.id === 0);
                            const aValue = aFound ? aFound.size : 0;

                            //Find the size that matches the census connection ID
                            let bFound = (b[this.sort_key] == null) ? false : b[this.sort_key].find(item => item.id === census_conn_id);
                            //If I didn't find one, just find the default (where ID = 0)
                            if(!bFound)
                                bFound = (b[this.sort_key] == null) ? false : b[this.sort_key].find(item => item.id === 0);

                            const bValue = bFound ? bFound.size : 0;

                            // Compare the size values
                            return aValue - bValue;
                        });
                    else
                        segments.sort((a, b) => {
                            //Find the size that matches the census connection ID
                            let aFound = (a[this.sort_key] == null) ? false : a[this.sort_key].find(item => item.id === census_conn_id);
                            //If I didn't find one, just find the default (where ID = 0)
                            if(!aFound)
                                aFound = (a[this.sort_key] == null) ? false : a[this.sort_key].find(item => item.id === 0);
                            const aValue = aFound ? aFound.size : 0;

                            //Find the size that matches the census connection ID
                            let bFound = (b[this.sort_key] == null) ? false : b[this.sort_key].find(item => item.id === census_conn_id);
                            //If I didn't find one, just find the default (where ID = 0)
                            if(!bFound)
                                bFound = (b[this.sort_key] == null) ? false : b[this.sort_key].find(item => item.id === 0);
                            const bValue = bFound ? bFound.size : 0;

                            // Compare the size values
                            return bValue - aValue;
                        });
                }
                else if(this.sort_order == 'asc')
                    segments.sort((a, b) => (a[this.sort_key ] > b[this.sort_key ]) ? 1 : -1);
                else
                    segments.sort((a, b) => (a[this.sort_key ] < b[this.sort_key ]) ? 1 : -1);
            }   
        }
    }
</script>
