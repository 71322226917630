<template>
    <div class="analysis-segment-builder">
        <div class="row">
            <div class="col-6 mb-3">
                <label class="form-label">Name *</label>
                <input type="text" id="name-input" class="form-control" v-model="local_analysis_audience.name"
                    :class="((errors.name != '' )? 'is-invalid':'')" @change="clearErrorForField('name')">
                <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                    {{errors.name }}
                </div>
            </div>


            <div class="col-6 mb-3">
                <label class="form-label">Start Date *</label>

                <div class="input-group">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i v-if="local_analysis_audience.start_date.type == 'static'" class="fa fa-calendar"></i>
                        <i v-else-if="local_analysis_audience.start_date.type == 'minimum'" class="fa fa-dollar"></i>
                        <i v-else class="fa fa-font"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a class="dropdown-item" href="#"
                            @click.prevent="local_analysis_audience.start_date.type = 'static'; local_analysis_audience.start_date.value = null; local_analysis_audience.if_retain_constituents = false">
                                Static Date
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#"
                            @click.prevent="local_analysis_audience.start_date.type = 'variable'; local_analysis_audience.start_date.value = 'segment_entry_date'; clearErrorForField('start_date'); local_analysis_audience.if_retain_constituents = true">
                                Date Constituent is Added to Audience
                            </a>
                        </li>
                        <li v-if="!if_hide_appeal_code_toggle">
                            <a class="dropdown-item" href="#"
                                @click.prevent="local_analysis_audience.start_date.type = 'minimum'; local_analysis_audience.start_date.value = 'minimum_gift_date'; clearErrorForField('start_date');">
                                Minimum Gift Date of Audience
                            </a>
                        </li>
                    </ul>
           
                    <input v-if="local_analysis_audience.start_date.type == 'variable'" type="text" class="form-control ps-2"  placeholder="Date Constituent Added to Audience" disabled>
                    <input v-if="local_analysis_audience.start_date.type == 'minimum'" type="text" class="form-control ps-2"  placeholder="Minimum Gift Date of Audience" disabled>

                    <datepicker v-if="local_analysis_audience.start_date.type == 'static'" v-model="local_analysis_audience.start_date.value" format="M/d/yyyy"
                    :class="((errors.start_date != '' )? 'is-invalid':'')" input-class="form-control date" placeholder='MM/DD/YYYY' :use-utc="true" 
                    :bootstrap-styling="true" @change.prevent="clearErrorForField('start_date')"/>
                </div>
                <div class="text-danger invalid-feedback" v-if="errors.start_date != ''">
                    {{errors.start_date }}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-3">
                <label class="form-label">Appeal Code</label>
                <div class="input-group">
                    <button v-if="!if_hide_appeal_code_toggle" class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img v-if="local_analysis_audience.appeal_code.type == 'static'" class="icon smaller-icon" src="/img/icons/dialexa-icons/text.svg">
                        <i v-else class="fa fa-font"></i>
                    </button>
                    <ul v-if="!if_hide_appeal_code_toggle" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a class="dropdown-item" href="#"
                            @click.prevent="local_analysis_audience.appeal_code.type = 'static'; local_analysis_audience.appeal_code.value ='';">
                                Static
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#"
                            @click.prevent="local_analysis_audience.appeal_code.type = 'variable'; local_analysis_audience.appeal_code.value = 'transactions.appeal_code';">
                                Transaction Appeal Code
                            </a>
                        </li>
                    </ul>
           
                    <input v-if="local_analysis_audience.appeal_code.type == 'static'" type="text" class="form-control ps-2" v-model="local_analysis_audience.appeal_code.value">
                    <input v-if="local_analysis_audience.appeal_code.type == 'variable'" type="text" class="form-control ps-2"  placeholder="Transaction Appeal Code" disabled>
                </div>


            </div>
            <div class="col-12 mb-3">
                <label class="form-label">Category</label>
                <!--<div class="input-group">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img v-if="local_analysis_audience.category.type == 'static'" class="icon smaller-icon" src="/img/icons/dialexa-icons/text.svg">
                        <i v-else class="fa fa-columns"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a class="dropdown-item" href="#"
                            @click.prevent="local_analysis_audience.category.type = 'static'; local_analysis_audience.category.value = '';">
                                Static Value
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#"
                            @click.prevent="local_analysis_audience.category.type = 'variable'; local_analysis_audience.category.value = ''; ">
                                Column
                            </a>
                        </li>
                    </ul>
                    -->
                    <input v-if="local_analysis_audience.category.type == 'static'" type="text" class="form-control ps-2" v-model="local_analysis_audience.category.value">
                    <!--<v-select v-if="local_analysis_audience.category.type == 'variable'" :options="db_columns" v-model="local_analysis_audience.category.value" 
                            :searchable="true" ref="valueSelect"  :selectable="(option) => option.text != ''" class="searchable-select column-display"
                            label="text" :filterable="false">

                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                            </template>

                            <template #option="{ text, category }">
                                <div v-if="text == ''" :data-category="category" class="category-header"
                                v-on:click="return expandCategory(category, null)"
                                data-isexpanded="false">
                                {{ category }} <i class="fa-solid fa-caret-right"></i>
                                </div>
                                <div v-else class="suboption" :data-subcategory="category"
                                :class="category==null || category==''? 'show' : ''">
                                {{ text }}
                                </div>
                            </template>
                            <template #no-options="{ search, searching, loading }">
                                <div class="suboption show" v-if="is_loading">
                                    <div class="spinner-border  spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                </div>
                                <div class="suboption show" v-else-if="is_searching">
                                    <div class="spinner-border  spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Searching...</span></div>  Searching
                                </div>
                                <div class="suboption show" v-else>
                                    <em>No results found</em>
                                </div>
                            </template>
                        </v-select> 
                </div>
                        -->
            </div>

            <div class="col-12 mb-3">
                <input class="form-check-input" type="checkbox" v-model="local_analysis_audience.if_retain_constituents" :disabled="local_analysis_audience.start_date.value == 'segment_entry_date'">
                <span>Retain constituent if they no longer match criteria. 
                    <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="When selected, constituents will stay in the analysis even if they no longer qualify for the audience. This is helpful when analyzing reactivation or similar efforts"></i>
                </span>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col">
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an error saving the analysis Audience information.
                </div>
                <button type="submit" class="btn btn-primary me-3" id="save-button"
                        @click.prevent="saveAnalysisSegment"
                        :disabled="form.busy">
                    <i class="fa fa-btn fa-save"></i> Save
                </button>

                <button v-if="segment_selected.analysis" type="submit" class="btn btn-secondary" id="delete-button"
                        @click.prevent="deleteAnalysisSegment"
                        :disabled="form.busy">
                    <i class="fa fa-btn fa-save"></i> Delete
                </button>
            </div>
        </div>
    </div>
</template>

<script>
 import Datepicker from 'vuejs-datepicker';
import vSelect from "vue-select";
import { start } from '@popperjs/core';
    export default {
        components: {
            vSelect,
            Datepicker
        },
        props: {
            client: {
				type: Object,
				default: {},
			},
			segment_selected: {
				type: Object,
				default: {},
			},
            modal_action: {
				type: String,
			},
		},
        data() {
            return {
                local_analysis_audience: {
                    id: null,
                    segment_id: null,
                    name: null,
                    start_date: {
                        type: 'minimum',
                        value: 'minimum_gift_date'
                    },
                    appeal_code: {
                        type: 'static',
                        value: '',
                    },
                    category: {
                        type: 'static',
                        value: ''
                    },
                    if_retain_constituents: false
                },
                if_hide_appeal_code_toggle: true,
                db_columns: [],
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    name: '',
                    start_date: '',
                },
            };
        },
        beforeMount() {
            this.resetFields();
        },
        watch:{
            segment_selected(old_value, new_value){
                if(this.db_columns.length == 0){
                    this.loadDatabaseColumns();
                }else {
                    this.resetFields(); 
                }         
            },
            modal_action(old_value, new_value){
                if(this.db_columns.length == 0){
                    this.loadDatabaseColumns();
                }else {
                    this.resetFields(); 
                }           
            }
        },
        methods: {
            resetFields(){

                this.if_hide_appeal_code_toggle = true;
                this.checkIfShowAppealCodeToggle();

                if (this.segment_selected.analysis){ // update
                    //If the update the segment and remove the transaction, need to remove the appeal code too
                    if(this.if_hide_appeal_code_toggle && this.segment_selected.analysis.appeal_code == 'transactions.appeal_code')
                        this.segment_selected.analysis.appeal_code = '';

                    let start_date = {
                        type: 'static',
                        value: this.segment_selected.analysis.start_date
                    };

                    if(this.segment_selected.analysis.start_date == 'segment_entry_date'){
                        start_date = {
                            type: 'variable',
                            value: 'segment_entry_date'
                        };
                    }
                    else if(this.segment_selected.analysis.start_date == 'minimum_gift_date'){
                        start_date = {
                            type: 'minimum',
                            value: 'minimum_gift_date'
                        };
                    }

                    this.local_analysis_audience = {
                        id: this.segment_selected.analysis.id,
                        segment_id: this.segment_selected.id,
                        name: this.segment_selected.analysis.name,
                        start_date: start_date,
                        appeal_code: this.segment_selected.analysis.appeal_code == 'transactions.appeal_code' ? 
                        {
                            type: 'variable',
                            value: 'transactions.appeal_code'
                        }: {
                            type: 'static',
                            value: this.segment_selected.analysis.appeal_code
                        },
                        category: this.segment_selected.analysis.category_type == 'variable' ? 
                        {
                            type: 'variable',
                            value: this.db_columns.length > 0? this.db_columns.find((col) => col.value == this.segment_selected.analysis.category): null
                        }: {
                            type: 'static',
                            value: this.segment_selected.analysis.category
                        },
                        if_retain_constituents: this.segment_selected.analysis.start_date == 'segment_entry_date'? true : false
                    };
                } else { // add
                    this.local_analysis_audience = {
                        id: null,
                        segment_id: this.segment_selected.id,
                        name: this.segment_selected.name,
                        start_date: {
                            type: 'static',
                            value: null
                        },
                        appeal_code: {
                            type: 'static',
                            value: '',
                        },
                        category: {
                            type: 'static',
                            value: ''
                        },
                        if_retain_constituents: false
                    };
                }

                this.form = {
                    successful: false,
                    busy: false,
                    error: false
                };
                this.errors = {
                    name: '',
                    start_date: '',
                };
            },
            checkIfShowAppealCodeToggle(){
                if(this.segment_selected && this.segment_selected.filter && this.segment_selected.filter.logic_units && this.segment_selected.filter.logic_units.length >0 ){
                    this.segment_selected.filter.logic_units.forEach((filter) => {
                        if(filter.db_column && filter.db_column.value && filter.db_column.value.startsWith('transactions.')){
                            this.if_hide_appeal_code_toggle = false;
                        }
                    });                    
                }
                this.$forceUpdate();

            },
            loadDatabaseColumns() {
                let data = {
                    client: this.client,
                    include_trans: true,
                    source: this.segment_selected.source,
                    data_source_id: this.segment_selected.data_source_id,
                };
                window.axios.post('/api/bigquery/get_db_columns', data)
                .then(response => {
                    var cols = response.data.columns.filter((col) => !col.value.startsWith('_')); // filter out all hidden fields start with _
                    var headers = "";
                    for(var i = 0; i < cols.length; i++)
                        if(headers != cols[i].category && cols[i].category != null) {
                            headers = cols[i].category;
                            cols.splice(i, 0, {
                                value: "DIVIDER-"+ headers,
                                category: headers,
                                text: ""
                            });
                        }
                    this.db_columns = cols;
                    this.resetFields();
                });
            },
            expandCategory(cat, expand) {
                    var headline = document.querySelector("div[data-category='"+cat+"']");
                    var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");
    
                    if(headline == undefined || lines == undefined)
                        return false;
    
                    if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                        for(var i = 0; i < lines.length; i++)
                            lines[i].style.display="block";
                        var divs = headline.getElementsByClassName("fa-caret-right");
                        for(var i = 0; i < divs.length; i++){
                            divs[i].classList.add("fa-caret-down");
                            divs[i].classList.remove("fa-caret-right");
                        }
                        headline.dataset.isexpanded = true;
                    }
                    else {
                        for(var i = 0; i < lines.length; i++)
                            lines[i].style.display="none";
                        var divs = headline.getElementsByClassName("fa-caret-down");
                        for(var i = 0; i < divs.length; i++){
                            divs[i].classList.add("fa-caret-right");
                            divs[i].classList.remove("fa-caret-down");
                        }
                        headline.dataset.isexpanded = false;
                    }
    
                    return false;
                },
            hasErrors() { //validate the form
                if(this.local_analysis_audience.name == null || this.local_analysis_audience.name == ""){
                    this.errors.name = "Name is required.";
                } else
                    this.errors.name = "";

                if(this.local_analysis_audience.start_date.type == 'static' && !this.local_analysis_audience.start_date.value){
                    this.errors.start_date = "Start Date is required.";
                } else
                    this.errors.start_date = "";

                for (const error_name in this.errors) {
                    if (this.errors[error_name] != '') {
                        return true;
                    }
                }
                return false;
            },
            clearErrors() {//reset errors to empty strings
                this.errors = {
                    name: '',
                    start_date: '',
                }
            },
            clearErrorForField(field_name){
                this.errors[field_name] = '';
            }, 
            saveAnalysisSegment() {
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                this.form.busy = true; 

                var analysis_audience = {
                    id: this.local_analysis_audience.id,
                    segment_id: this.local_analysis_audience.segment_id,
                    name: this.local_analysis_audience.name,
                    start_date: this.local_analysis_audience.start_date.value,
                    appeal_code: this.local_analysis_audience.appeal_code.value,
                    category: this.local_analysis_audience.category.type == 'static' ? this.local_analysis_audience.category.value : this.local_analysis_audience.category.value.value,
                    category_type: this.local_analysis_audience.category.type,
                    retain_constituents: this.local_analysis_audience.if_retain_constituents
                };


                if(this.local_analysis_audience.id == null) {
                    window.axios.post('/api/segment_analysis/'+ this.client.url, analysis_audience)
                    .then(response => {
                        self.form.busy = false;
                        self.form.successful = true;
                        setTimeout( function() {self.form.successful = false;} , 5000);
                        this.$emit("updated", response.data.segment_analysis);
                      });
                  } else {
                    window.axios.put('/api/segment_analysis/'+ this.client.url + '/' + this.local_analysis_audience.id, analysis_audience)
                      .then(response => {
                        self.form.busy = false;
                        self.form.successful = true;
                        this.$emit("updated", response.data.segment_analysis);
                        setTimeout( function() {self.form.successful = false;} , 5000);
                      });
                  }
            },
            deleteAnalysisSegment(){
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete the Analysis Audience?",
                  text: "",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setTimeout( function() {self.form.successful = false;} , 5000);
                        this.$emit("deleted");
                        window.axios.delete('/api/segment_analysis/'+ this.client.url + '/' + this.local_analysis_audience.id);
                    }
                });
            },
        }
    }
</script>
