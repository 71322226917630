<template>
    <div class="campaign-list">
        <div class="alert alert-warning mb-3 box-content" v-if="client.client_mission==null || client.why_give==null ">
            Organization details that are necessary for campaign generation are currently empty, <a :href='"/settings/" + client.url + "#organization-details"'>please fill them out here</a>.
        </div>

        <div v-if="campaigns.length == 0">
            <div class="card box-content mb-3">
                <div class="card-body mt-3 text-center">
                    <h3 class="text-center">No experiment treatments have been created for {{client.name}} yet.</h3>
                    <p class="text-center">An experiment treatment is an single asset like an ad or single email. You will be able import your existing version and then generate new versions to test against it.</p>
                    <a class="btn btn-primary mb-3 ms-3" :href="'/experiment_treatments/'+ client.url +'/new'">
                        <span><i class="fas fa-fw fa-plus"></i>Add Treatment</span>
                    </a>

                </div>
            </div>
        </div>

        <div v-if="campaigns.length != 0">
            <div class="row">
                <div class="col">
                    <h2 class="d-inline">Experiment Treatments</h2>
                    <a class="btn btn-secondary mb-2 float-end" :href="'/experiment_treatments/'+ client.url +'/new'">
                        <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Treatment
                    </a>
                </div>
            </div>
            <div class="card box-content mb-3">
                <div class="card-body p-0">
                    <div class="overflow-hidden rounded-top">
                        <div class="p-1 bg-dark"></div>
                    </div>
                    <div class="table-responsive mb-2">
                        <table class="table gy-0 m-0">
                            <thead class="table-dark">
                                <tr>
                                    <th class='align-middle pt-0'>Asset Name</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Asset Type</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Status</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Created On</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr v-if="experiment_variants.length>0" v-for="(campaign, index) in experiment_variants">
                                    <td class="align-middle">
                                        <a :href="'/campaigns/'+ client.url +'/assets/' + campaign.url + '/'+campaign.asset_types[0].id+'/false'"> {{ campaign.name }}</a>
                                    </td>
                                    <td class="align-middle d-none d-sm-table-cell placeholder-glow"> 
                                        <span v-html="getIcons(campaign.asset_types)"></span>{{ campaign.asset_types[0].name }}
                                    </td>
                                    <td class="align-middle d-none d-sm-table-cell placeholder-glow">
                                        <span class="badge " :class="getStatusFontColor(campaign.status)">{{ campaign.status | propercase}}</span>
                                    </td>
                                    <td class="align-middle d-none d-md-table-cell d-sm-table-cell placeholder-glow">
                                        {{ campaign.created_at | date }}
                                    </td>
                                    <td class="align-middle d-none d-md-table-cell d-sm-table-cell placeholder-glow">
                                        <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Copy Experiment Treatment" data-bs-toggle="tooltip" v-on:click="copyExperiment(index)">
                                            <i class="fa-solid fa-copy me-0" aria-hidden="true"></i>
                                        </button>
                                        <a :href="'/campaigns/'+ client.url +'/review/' + campaign.url" title="Review Changes" v-if="hasAssetEdits(index)" 
                                        class="btn btn-secondary btn-sm ms-1 mb-1" data-bs-toggle="tooltip"><i class="fa-solid fa-magnifying-glass me-0" aria-hidden="true"></i></a>
                                        <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Experiment Treatment" data-bs-toggle="tooltip" v-on:click="deleteExperiment(index)">
                                            <i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="experiment_variants.length == 0">
                                    <td>
                                        There is no experiment treatments created for this organization yet.
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                    </div>
                </div>
            </div>                
        </div>
 
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['client', 'campaigns'],
        data() {
            return {
                local_client: null,
                experiment_variants: [],
            };
        },
        beforeMount() {
            this.local_client = this.client;
            this.experiment_variants = [...this.campaigns]
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            }
        },
        methods: {
            getStatusFontColor(status){
                if (status == 'draft') // yellow
                    return 'bg-primary';
                if (status == 'in_review') // blue
                    return "bg-danger";
                if (status == 'active') // green
                    return "bg-success";
                if (status == 'archive') // red
                    return "bg-secondary";

                //return yellow by default
                return 'status-draft5';
            },
            getIcons(asset_types){
                if (asset_types == null || asset_types.length == 0) return '<span>No Asset Attached</span>'
                else {
                    var icon_list = this.getIconsByAssetType(asset_types);

                    var iconElements = '';
                    icon_list.forEach(icon_class => {
                        iconElements = iconElements +'<i class="'+icon_class+' mx-1"></i>'
                    })
                }
                return iconElements
            },
            getIconsByAssetType(asset_types){
                var icon_list = [];

                asset_types.forEach(asset_obj => {
                    if (!icon_list.includes(asset_obj.icon_class)){ 
                        icon_list.push(asset_obj.icon_class);
                    }
                });
                return icon_list;
            },
            hasAssetEdits(index) {
                for(var i = 0; i < this.experiment_variants[index].assets.length; i++){
                    if(this.experiment_variants[index].assets[i].status == 'in_review' && this.experiment_variants[index].assets[i].latest_review)
                        return true;
                }
                return false;                    
            },
            deleteExperiment(index) {
                Swal.fire({
                  title: "Are you sure you want to delete this experiment treatment?",
                  text: "This experiment treatment and all associated assets will be removed from Avid AI and cannot be restored.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/campaigns/'+this.client.url +'/'+ this.experiment_variants[index].id)
                            .then(response => {
                                if (response.status == 200) {
                                    this.experiment_variants.splice(index, 1);
                                }
                            });
                    }
                });
            },
            copyExperiment(index) {
                Swal.fire({
                  title: "Are you sure you want to copy this experiment treatment?",
                  text: "A fresh copy of this experiment treatment will be generated.",
                  icon: "question",
                  iconColor: "#46705F",
                  showCancelButton: true,
                  confirmButtonColor: "#46705F",
                  confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.post('/api/campaigns/'+this.client.url +'/copy/'+ this.experiment_variants[index].id)
                            .then(response => {
                                if (response.status == 200) {
                                    window.location = "/campaigns/"+this.client.url + '/assets/' + response.data.campaign.url+ '/'+ this.experiment_variants[index].asset_types[0].id+'/false';
                                }
                            });
                    }
                });
            }
        }
    }
</script>
