<template>
    <div id="org_settings_notification">
        <h4>Notification Preferences for {{ client.name }}</h4>
        <p class="mb-4">Decide which alerts you would like to receive email notifications for.</p>
        <div v-if="user_notification.length == 0">
            <h5 class="d-flex align-items-center">
                <div class="spinner-border text-primary me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading Notification Options
            </h5>
        </div>
        <div class="row mb-4" v-for="n in user_notification">
            <div class="col">
                <div class="form-check form-switch d-inline-flex align-items-center gap-2 my-0">
                    <input class="form-check-input mt-0 me-2" type="checkbox" role="switch" :checked="n.active" @change="n.active = !n.active">
                    <div>
                        <h6 class="mb-0">{{n.name}}</h6>
                        <small>{{n.description}}</small>
                    </div>
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-primary mt-3" @click.prevent="update" :disabled="form.busy"  v-if="user_notification.length != 0">
            <i class="fa fa-btn fa-save"></i> Save Changes
        </button>

    </div>
</template>

<script>
    export default {
        props: ['client'],
        data() {
            return {
                local_client:{},
                form: {
                    busy: false,
                    error: false
                },
                user_notification: []
            };
        },
        beforeMount() {
            this.local_client = this.client;
            this.getNotificationOptions();
        },
        methods: {

            getNotificationOptions() {
                window.axios.get('/api/notification/'+this.client.url+'/options', [])
                  .then(response => {
                        this.user_notification = response.data.notifications;
                  });
            },
            update() {
                var self = this;
                this.form.busy = true;

                this.local_client.notification_preference = this.user_notification;

                window.axios.put('/api/client/' + this.local_client.id, this.local_client)
                .then(response => {
                    self.form.busy = false;
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Notification settings updated!</h4>',
                        target: '#org_settings_notification',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        }
                    });
                }).catch(error =>{
                    self.form.busy = false;
                    self.form.error = true;
                    setTimeout( function() {self.form.error = false} , 10000);
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Error on updating notification settings</h4>',
                        target: '#org_settings_notification',
                        icon: 'warning',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-danger'
                        },
                    });

                });
            },
        }
    }
</script>
