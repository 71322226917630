var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row chart mb-4" }, [
    _c("div", { staticClass: "col-md-12 col-lg-10 mb-3" }, [
      _c("div", { staticClass: "card box-content p-3" }, [
        _vm.title != ""
          ? _c("h5", { staticClass: "mt-2 mx-2 mb-1" }, [
              _c("img", {
                staticClass: "avatar avatar-sm rounded-circle me-2",
                attrs: { src: _vm.client.image },
              }),
              _vm._v(" " + _vm._s(_vm.title)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _vm.data != null
              ? _c("horizontal-bar-chart", {
                  attrs: {
                    id: _vm.client.id,
                    type: "revenue",
                    data: _vm.data,
                    labels: _vm.labels,
                  },
                })
              : _c("div", [
                  _vm._v(
                    "There is no projected paid media spend based upon the currently selected filters."
                  ),
                ]),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade-slow" } },
              _vm._l(_vm.notifs, function (n) {
                return _c(
                  "div",
                  { staticClass: "alert mt-3 mb-0", class: n.data.class },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-none px-0 close-btn",
                        on: {
                          click: function ($event) {
                            return _vm.dismissNotification(n)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-close" })]
                    ),
                    _vm._v(
                      "\n                    " +
                        _vm._s(n.data.error_msg) +
                        "\n                "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.data != null
      ? _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-6 col-lg-12 scorecard mb-3",
                staticStyle: { "max-width": "100%" },
              },
              [
                _c("div", { staticClass: "card box-content p-3" }, [
                  _c("h5", { staticClass: "mb-2" }, [
                    _vm._v("Total Media Spent"),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mt-0 mb-0" }, [
                    _vm._v(
                      _vm._s(_vm._f("currency_with_zero")(_vm.actuals_total)) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "badge",
                        class: {
                          "bg-label-success": _vm.percent_spent > 0,
                          "bg-label-danger": _vm.percent_spent < 0,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("percentage_na")(_vm.percent_spent))
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.show_media_balance
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-6 col-lg-12 scorecard mb-3",
                    staticStyle: { "max-width": "100%" },
                  },
                  [
                    _c("div", { staticClass: "card box-content p-3" }, [
                      _c("h5", { staticClass: "mb-2" }, [
                        _vm._v("Billable Media Dollars Available"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "h4",
                        {
                          staticClass: "mt-0 mb-0",
                          class: { "text-danger": _vm.media_balance_total < 0 },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency_with_zero")(
                                _vm.media_balance_total
                              )
                            ) + "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.outstanding_invoices != 0
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-6 col-lg-12 scorecard mb-3",
                    staticStyle: { "max-width": "100%" },
                  },
                  [
                    _c("div", { staticClass: "card box-content p-3" }, [
                      _c("h5", { staticClass: "mb-2" }, [
                        _vm._v("Outstanding Media Invoices"),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-0 mb-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency_with_zero")(
                              _vm.outstanding_invoices
                            )
                          ) + "\n                    "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 mb-3",
                staticStyle: { "max-width": "100%" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: {
                      href: "/results/" + _vm.client.url + "/projections",
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-eye" }),
                    _vm._v(" View Results"),
                  ]
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }