var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org_settings_team" } }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary float-end",
            attrs: {
              disabled: _vm.form.busy,
              "data-bs-toggle": "modal",
              "data-bs-target": "#user-modal",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addTeamUser()
              },
            },
          },
          [
            _c("span", [
              _vm.form.busy
                ? _c(
                    "div",
                    {
                      staticClass:
                        "spinner-border spinner-border-sm float-left",
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "visually-hidden" }, [
                        _vm._v("Loading..."),
                      ]),
                    ]
                  )
                : _c("i", { staticClass: "fas fa-fw fa-plus" }),
              _vm._v(
                "\n                        Add Team Member\n                "
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 position-relative" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search_key,
              expression: "search_key",
            },
          ],
          staticClass: "search-box form-control me-1",
          attrs: {
            type: "text",
            placeholder: "Search by Name...",
            "aria-label": "Search by Name...",
            autocomplete: "off",
          },
          domProps: { value: _vm.search_key },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.search_key = $event.target.value
              },
              function ($event) {
                return _vm.searchUser()
              },
            ],
          },
        }),
        _vm._v(" "),
        _vm.search_key == ""
          ? _c("i", { staticClass: "fa fa-search search-placeholder-icon" })
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 avid-table" }, [
        _c(
          "table",
          {
            staticClass:
              "table table-responsive border rounded table-with-pagination my-3",
          },
          [
            _c("thead", [
              _c("tr", { staticClass: "table-header-row" }, [
                _c(
                  "th",
                  {
                    staticClass: "table-column-1",
                    on: {
                      click: function ($event) {
                        return _vm.sortByKey("name")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _vm._v(
                        "\n                                Name\n                                "
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "name" && _vm.sort_order == "asc"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "name" && _vm.sort_order == "des"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.if_show_org_column
                  ? _c(
                      "th",
                      {
                        staticClass: "table-column-1",
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey("organization")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _vm._v(
                            "\n                                Organization\n                                "
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key == "organization" &&
                                _vm.sort_order == "asc"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key == "organization" &&
                                _vm.sort_order == "des"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "table-column-3",
                    on: {
                      click: function ($event) {
                        return _vm.sortByKey("email")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _vm._v(
                        "\n                                Email\n                                "
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "email" && _vm.sort_order == "asc"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "email" && _vm.sort_order == "des"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "table-column-4",
                    staticStyle: { width: "96px" },
                  },
                  [_vm._v("Action")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.user_list_shown, function (u, index) {
                return _c("tr", { class: `table-row-${index + 1}` }, [
                  _c("td", { staticClass: "align-start table-column-1" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(u.name) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.if_show_org_column
                    ? _c("td", { staticClass: "align-start table-column-2" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(u.organization) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-start table-column-3" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(u.email) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "align-start table-column-4 show-on-row-hover",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-none px-1",
                          attrs: { title: "Remove Team Member" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteTeamUser(u, index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: "/img/icons/dialexa-icons/trash.svg",
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "bottom",
                              title: "Delete Team Member",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      (_vm.user.user_type == "admin" ||
                        _vm.user.user_type == "agency_admin") &&
                      u.agency_id == null &&
                      u.user_type != "admin"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: {
                                title: "Edit",
                                "data-bs-toggle": "modal",
                                "data-bs-target": "#user-modal",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateTeamUser(u)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/pencil.svg",
                                  "data-bs-toggle": "tooltip",
                                  "data-bs-placement": "bottom",
                                  title: "Edit Team Member",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("tfoot", { staticClass: "table-border-bottom-0" }, [
              _c("tr", [
                _c("td", { staticClass: "py-0", attrs: { colspan: "100%" } }, [
                  _c("div", { staticClass: "table-pagination" }, [
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _c("span", [_vm._v("Showing: ")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.num_per_page,
                              expression: "num_per_page",
                            },
                          ],
                          staticClass: "form-select",
                          attrs: { name: "num_per_page", id: "num_per_page" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.num_per_page = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.reloadPagination()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "5000" } }, [
                            _vm._v("ALL"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.num_per_page_options, function (num) {
                            return _c("option", { domProps: { value: num } }, [
                              _vm._v(_vm._s(num)),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Team Members")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.item_interval) +
                          " of " +
                          _vm._s(_vm.filtered_user_list.length) +
                          "\n                                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _vm.page_count > 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: { disabled: _vm.page_index == 0 },
                              on: {
                                click: function ($event) {
                                  return _vm.moveToPrevPage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-left.svg",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.page_index_array.length <= 3
                        ? _c(
                            "div",
                            _vm._l(_vm.page_index_array, function (page) {
                              return _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  class:
                                    page == _vm.page_index + 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(page)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(page) +
                                      "\n                                        "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            [
                              _vm._l(
                                _vm.page_index_array.slice(0, 2),
                                function (page) {
                                  return _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      class:
                                        page == _vm.page_index + 1
                                          ? "current-page"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveToPage(page)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(page) +
                                          "\n                                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.page_index > 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        ...\n                                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index >= 2 &&
                              _vm.page_index < _vm.page_index_array.length - 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-none px-1 current-page",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(_vm.page_index + 1) +
                                          " \n                                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index < _vm.page_index_array.length - 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        ...\n                                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  class:
                                    _vm.page_index ==
                                    _vm.page_index_array.length - 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(
                                        _vm.page_index_array.length
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.page_index_array.length) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                      _vm._v(" "),
                      _vm.page_count > 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: {
                                disabled: _vm.page_index == _vm.page_count - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.moveToNextPage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-right.svg",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade new-modal",
        attrs: {
          id: "user-modal",
          "aria-labelledby": "user-modal",
          "aria-hidden": "true",
          "data-bs-backdrop": "static",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content mx-2" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-none close-modal",
                  attrs: { type: "button", "data-bs-dismiss": "user-modal" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-close" })]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0" },
              [
                _c("h3", { staticClass: "mb-0" }, [
                  _vm._v(_vm._s(_vm.user_info.action) + " Team member"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Please fill out the details for the team member you would like to " +
                      _vm._s(_vm.user_info.action.toLowerCase()) +
                      "."
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("First Name *"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user_info.first_name,
                          expression: "user_info.first_name",
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.errors.first_name != "" ? "is-invalid" : "",
                      attrs: { type: "text", name: "first_name" },
                      domProps: { value: _vm.user_info.first_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.user_info,
                            "first_name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.first_name != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errors.first_name) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Last Name *"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user_info.last_name,
                          expression: "user_info.last_name",
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.errors.last_name != "" ? "is-invalid" : "",
                      attrs: { type: "text", name: "last_name" },
                      domProps: { value: _vm.user_info.last_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.user_info,
                            "last_name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.last_name != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errors.last_name) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Email *"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user_info.email,
                          expression: "user_info.email",
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.errors.email != "" ? "is-invalid" : "",
                      attrs: {
                        type: "text",
                        name: "email",
                        disabled: _vm.user_info.action == "Update",
                      },
                      domProps: { value: _vm.user_info.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.user_info, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.email != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errors.email) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _vm.user_info.action == "Update"
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Password"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user_info.password,
                              expression: "user_info.password",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors.password != "" ? "is-invalid" : "",
                          attrs: {
                            type: "password",
                            name: "password",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.user_info.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.user_info,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.password != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.errors.password) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.user_notification.length == 0 &&
                _vm.user_info.action != "Update"
                  ? _c("div", [_vm._m(2)])
                  : _vm.user_info.action != "Update"
                  ? _c("div", [
                      _c("h5", { staticClass: "mt-3" }, [
                        _vm._v("User Notification Options"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.user_notification, function (n) {
                  return _vm.user_info.action != "Update"
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("label", { staticClass: "mb-1" }, [
                            _c("input", {
                              staticClass: "form-check-input mt-0 me-2",
                              attrs: { type: "checkbox", role: "switch" },
                              domProps: { checked: n.active },
                              on: {
                                change: function ($event) {
                                  n.active = !n.active
                                },
                              },
                            }),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(n.name) +
                                " "
                            ),
                            _c("i", {
                              staticClass:
                                "fas fa-question-circle ms-1 text-primary",
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                title: n.description,
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.form.error
                      ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                          _vm._v(
                            "\n                                There was an error saving the user information.\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Cancel\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-50",
                        attrs: { disabled: _vm.form.busy },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.update()
                          },
                        },
                      },
                      [
                        _vm.form.busy
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "spinner-border spinner-border-sm float-left",
                                attrs: { role: "status" },
                              },
                              [
                                _c("span", { staticClass: "visually-hidden" }, [
                                  _vm._v("Loading..."),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.user_info.action) +
                            " Team Member\n                        "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("h4", [_vm._v("Team")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Manage your team by adding, modifying, or removing users."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 d-flex flex-column gap-1" }, [
      _c("img", {
        staticClass: "modal-icon",
        attrs: { src: "/img/icons/dialexa-icons/person-add-outline.svg" },
      }),
      _vm._v(" "),
      _c("p", [_vm._v("TEAM")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center mt-3" }, [
      _c(
        "div",
        {
          staticClass: "spinner-border text-primary me-2",
          attrs: { role: "status" },
        },
        [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
      ),
      _vm._v(
        "\n                            Loading Notification Options\n                        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }