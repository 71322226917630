var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pathways-mapping mb-5" }, [
    (_vm.local_census_connection.id == null ||
      _vm.local_census_connection.id <= 0) &&
    !_vm.isFile()
      ? _c("div", { staticClass: "row px-2" }, [
          _c("div", { staticClass: "card box-content mb-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", [_vm._v("Information")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Before data can be sent to " +
                    _vm._s(_vm.local_destination.integration.name) +
                    ", you first need to align the columns to those available in this data source and decide how the data should be kept in sync on an ongoing basis. "
                ),
                this.census_connection.source == "data_blend"
                  ? _c("span", [
                      _vm._v(
                        "If there is information missing from the data source, "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/integrations/" +
                              _vm.client.url +
                              "/mapping/data_set/" +
                              _vm.data_set_id +
                              "?step=3",
                          },
                        },
                        [
                          _vm._v(
                            "you can click here to map additional columns to that source"
                          ),
                        ]
                      ),
                      _vm._v("."),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-100 d-flex flex-row gap-3" }, [
                _c("div", { staticClass: "w-50" }, [
                  _c("label", [_vm._v("Destination Object")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_census_connection.destination_object,
                          expression:
                            "local_census_connection.destination_object",
                        },
                      ],
                      staticClass: "form-select",
                      class: {
                        "is-invalid": _vm.errors.destination_object != "",
                      },
                      attrs: {
                        disabled:
                          _vm.local_destination.census_destination_objects
                            .length == 1 || _vm.local_census_connection.id > 0,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_census_connection,
                              "destination_object",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.changeDestination()
                          },
                        ],
                      },
                    },
                    _vm._l(
                      _vm.local_destination.census_destination_objects,
                      function (obj) {
                        return obj.data == null
                          ? _c(
                              "option",
                              { domProps: { value: obj.full_name } },
                              [_vm._v(_vm._s(obj.label))]
                            )
                          : _vm._e()
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _vm.errors.destination_object != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.destination_object) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.operations.length > 1
                  ? _c("div", { staticClass: "w-50" }, [
                      _c("label", [_vm._v("Method of Sync")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_census_connection.operation,
                              expression: "local_census_connection.operation",
                            },
                          ],
                          staticClass: "form-select",
                          class: { "is-invalid": _vm.errors.operation != "" },
                          attrs: {
                            disabled: _vm.local_census_connection.id > 0,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.local_census_connection,
                                "operation",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.operations, function (ops) {
                          return _c("option", { domProps: { value: ops } }, [
                            _vm._v(_vm._s(_vm.getOperationExplanation(ops))),
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.operation != ""
                        ? _c(
                            "div",
                            { staticClass: "text-danger invalid-feedback" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.operation) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.local_census_connection.destination_object != null && !_vm.isFile()
      ? _c("div", { staticClass: "row px-2 mb-3" }, [
          _c("div", { staticClass: "card box-content" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("pathways-column-mapping", {
                  ref: "column_mapping",
                  attrs: {
                    client: _vm.client,
                    source: _vm.local_census_connection,
                    destination: _vm.local_destination,
                    dest_columns: _vm.destination_fields,
                    source_columns: _vm.source_fields,
                  },
                  on: {
                    refresh: function ($event) {
                      return _vm.refreshColumns()
                    },
                    prioritize: _vm.updatePrioritization,
                  },
                  model: {
                    value: _vm.local_census_connection.mappings,
                    callback: function ($$v) {
                      _vm.$set(_vm.local_census_connection, "mappings", $$v)
                    },
                    expression: "local_census_connection.mappings",
                  },
                }),
                _vm._v(" "),
                _vm.local_census_connection.destination_object != null &&
                !_vm.isFile()
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary mt-3",
                        attrs: { disabled: _vm.busy == true },
                        on: {
                          click: function ($event) {
                            return _vm.addColumnMapping()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus" }),
                        _vm._v(" Add Column Mapping\n                "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm.isFile()
      ? _c("div", { staticClass: "row px-2 mb-3" }, [
          _c("div", { staticClass: "card box-content" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("file-column-mapping", {
                  ref: "file_mapping",
                  attrs: {
                    client: _vm.client,
                    source: _vm.local_census_connection,
                    source_columns: _vm.source_fields,
                    destination: _vm.local_destination,
                  },
                  on: { prioritize: _vm.updatePrioritization },
                  model: {
                    value: _vm.local_census_connection.mappings,
                    callback: function ($$v) {
                      _vm.$set(_vm.local_census_connection, "mappings", $$v)
                    },
                    expression: "local_census_connection.mappings",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.errors.form
      ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
          _vm._v(
            "\n        There was an issue saving this pathway mapping. Check the fields above to get more information.\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mb-3 d-flex flex-row gap-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary save-button",
            attrs: { type: "submit", disabled: _vm.busy == true },
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [
            _vm.busy
              ? _c("span", [_vm._m(0), _vm._v(" Saving")])
              : _c("span", [
                  _c("i", { staticClass: "fa fa-btn fa-save" }),
                  _vm._v(" Save"),
                ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Saving...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }