<template>
    <canvas :id="'chart-'+this.id" class="bar-chart" :class="'bars-' + labels.length"></canvas>
</template>

<script>
    import moment from 'moment';
    import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        props: {
            data: {
                required: true
            },
            labels: {
                type: Array,
                required: true
            },
            type: {
                type:String,
                default: 'number'
                //Can also be revenue or percent
            },
            id: {
                default: Math.floor(Math.random() * (10000))
            }
        },
        data() {
            return {
                chart: null
            };
        },
        watch: {
            data: {
                handler(n, o) {
                    //Only update it after it has initially been set
                    if(o != null)
                        this.renderChart()
                },
                deep: true
            },
        },
        mounted() {
            this.renderChart();
        },
        methods: {

            renderChart() {
                var dataset = this.getChartData();
                var options = this.getChartOptions(dataset);

                if(this.chart != null)
                    this.chart.destroy();

                var ctx = document.getElementById('chart-'+this.id);
                if(ctx != undefined)
                    this.chart = new Chart(ctx, options);
            },
            getLabels() {

                if(this.data instanceof Array)
                    return null;

                //Get the keys of the data object
                return Object.keys(this.data);
            },
            getColor(source) {
                //Blue
                if(source == 'Facebook')
                    return "52, 144, 220";
                //Purple
                if(source=="Email Prospecting")
                    return "153, 102, 255";
                //Red
                if(source=="Other")
                    return "224, 102, 102";
                //Green
                if(source=="Pay Per Click")
                    return "147, 196, 125";
                //Gray
                if(source=="Google Grant")
                    return "199, 204, 219";
                //Gray
                if(source=="QuickBooks")
                    return "199, 204, 219";

                //Yellow
                if(this.type == 'number')
                    return "245, 198, 53";
                //Green
                if(this.type == 'revenue')
                    return "147, 196, 125";
                //Blue
                if(this.type == 'percent')
                    return "52, 144, 220";

                //Yellow
                return "245, 198, 53";
            },
            getChartData() {
                var sources = this.getLabels();

                var datasets = [];

                var data = this.data;
                if(data instanceof Object)
                    data = Object.values(data);

                for(var i = 0; i < data.length; i++) {
                    var row = {
                        data: data[i],
                        backgroundColor: 'rgba('+this.getColor(sources[i])+', 0.8)',
                        borderColor: 'rgba('+this.getColor(sources[i])+', 1)',
                        borderWidth: 1,
                        borderSkipped: false,
                    }
                    //Add a label if the data is an object with labeled elements
                    if(sources[i] != null)
                        row.label = sources[i];

                    datasets.push( row );
                }

                return {
                    labels: this.labels,
                    datasets: datasets
                };
            },

            getChartOptions(data) {

                var self = this;

                var config = {

                    plugins: [ChartDataLabels],
                    type: 'bar',
                    data: data,
                    //All of the special aspects that make these charts unique
                    options: {
                        indexAxis: 'y',
                        responsive: true,
                        title: {
                            display:false,
                        },
                        scales: {
                            y: {
                                //If the type is bar-stacked, it will make it a stacked bar chart
                                stacked: true,
                            },
                            x: {
                                beginAtZero: true,
                                stacked: true,
                                //For the ticks on the axes, format the numbers to make the pretty
                                ticks: {
                                    callback: function(value, index, ticks, context) {

                                        if(self.type == 'revenue' && value < 1000)
                                            return self.$options.filters.currency(value);
                                        if(self.type == 'revenue')
                                            return self.$options.filters.currency_abbr(value);
                                        if(self.type == 'number' && value < 1000)
                                            return self.$options.filters.number(value);
                                        if(self.type == 'number' )
                                            return self.$options.filters.num_abbr(value);
                                        if(self.type == 'percent')
                                            return self.$options.filters.percentage(value);
                                    }
                                }
                            }
                        },

                        plugins: {
                            legend: {
                                //Only show the legend if there is more than one media type
                                position: (data.datasets.length > 0 && data.datasets[0].label != null) ? 'bottom' : 'none'
                            },
                            tooltip: {
                                callbacks: {
                                    title: function(tooltipItems, data) {
                                        //Return value for title
                                        return tooltipItems[0].dataset.label;
                                    },
                                    label: function(context) {
                                        if(self.type == 'revenue')
                                            return self.$options.filters.currency(context.parsed.x);
                                        if(self.type == 'number')
                                            return self.$options.filters.number(context.parsed.x);
                                        if(self.type == 'percent')
                                            return self.$options.filters.percentage(context.parsed.x);
                                    }
                                }
                            },
                            datalabels: {
                                anchor: 'end', // Specify the anchor position for the labels
                                align: 'end', // Specify the alignment of the labels
                                clamp: true,
                                formatter: function(value, context) {
                                    if(context.datasetIndex < data.datasets.length-1)
                                        return null;

                                    // Calculate the total amount for each stack
                                    var total = 0;
                                    for (var i = 0; i < context.datasetIndex; i++) {
                                        total += context.chart.data.datasets[i].data[context.dataIndex];
                                    }
                                    total += value;

                                    if(self.type == 'revenue' && value < 1000)
                                        var label = self.$options.filters.currency(total);
                                    if(self.type == 'revenue')
                                        var label = self.$options.filters.currency_abbr(total);
                                    if(self.type == 'number' && value < 1000)
                                        var label = self.$options.filters.number(total);
                                    if(self.type == 'number' )
                                        var label = self.$options.filters.num_abbr(total);
                                    if(self.type == 'percent')
                                        var label = self.$options.filters.percentage(total);

                                    //If it is within 95% of the width, add padding to the end
                                    // Ideally it would be based upon the width of the bar + label but
                                    // this is an easy alternative
                                    var chart_width = context.chart.chartArea.width;
                                    //The characters are, at most 7px wide and then the padding of 6
                                    var label_width = label.length*7+6;

                                    if(total >= context.chart.scales.x.end*(1-label_width/chart_width) && context.chart.options.layout.padding.right == 0){

                                        context.chart.options.layout.padding.right = label_width;
                                        context.chart.update();

                                    }
                                    return label;
                                },
                            }
                        },

                        layout: {
                            padding: {
                                right: 0
                            }
                        }
                    }
                };
                return config;


            }
        }
    }
</script>
