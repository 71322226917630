var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asset-details-review-edits" }, [
    _vm.local_asset.comments != null && _vm.local_asset.comments != ""
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card box-content mt-3" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "mb-2" }, [
                  _vm._v("Client's Comments:"),
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "mb-0",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$options.filters.htmlify(_vm.local_asset.comments)
                    ),
                  },
                }),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "card box-content mt-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "mt-2 mb-4" },
              [
                _c("h4", { staticClass: "mb-2" }, [
                  _vm._v("Client's Approved Version"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label mb-0" }, [
                    _vm._v("Reviewed By: "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(
                      _vm.local_asset.previous_review_list,
                      function (review) {
                        return _c(
                          "span",
                          {
                            staticClass: "viewed-user",
                            attrs: {
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "bottom",
                              title:
                                review.status == "approved"
                                  ? "Approved at: " +
                                    _vm.getDateAsString(review.updated_at)
                                  : "Rejected at: " +
                                    _vm.getDateAsString(review.updated_at),
                            },
                          },
                          [
                            review.status == "approved"
                              ? _c("i", { staticClass: "fa fa-check green" })
                              : _vm._e(),
                            _vm._v(" "),
                            review.status == "rejected"
                              ? _c("i", { staticClass: "fa fa-close red" })
                              : _vm._e(),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(review.updated_by_user.name) +
                                "\n                                "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.edit_inputs, function (input_field, index) {
                  return (input_field.value != "" &&
                    input_field.value != null) ||
                    (_vm.original_inputs[index].value != null &&
                      _vm.original_inputs[index].value != "")
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          input_field.type != "image" &&
                          input_field.type != "file" &&
                          input_field.type != "link"
                            ? _c(
                                "div",
                                { staticClass: "col mb-3" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label mb-0" },
                                    [
                                      _vm._v(_vm._s(input_field.name) + " "),
                                      input_field.optional == 1
                                        ? _c("em", [_vm._v("(Optional)")])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  input_field.type == "text" ||
                                  input_field.type == "textarea" ||
                                  input_field.type == "template"
                                    ? _c(
                                        "div",
                                        {
                                          class:
                                            _vm.getErrorForMaxLength(
                                              input_field
                                            ),
                                          attrs: {
                                            maxlength: input_field.max_length,
                                            currentlength:
                                              input_field.value != null
                                                ? input_field.value.length
                                                : 0,
                                          },
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: input_field.value,
                                                expression: "input_field.value",
                                              },
                                            ],
                                            ref: "textarea",
                                            refInFor: true,
                                            staticClass:
                                              "form-control full-height",
                                            class:
                                              _vm.getErrorForMaxLength(
                                                input_field
                                              ),
                                            attrs: {
                                              type: "text",
                                              maxlength: input_field.max_length,
                                              id:
                                                "textarea-" +
                                                input_field.placeholder +
                                                "-" +
                                                _vm.local_asset.id,
                                            },
                                            domProps: {
                                              value: input_field.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.inputFieldValueChange(
                                                  index
                                                )
                                              },
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    input_field,
                                                    "value",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.resizeTextarea(
                                                    "textarea-" +
                                                      input_field.placeholder +
                                                      "-" +
                                                      _vm.local_asset.id
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "dropdown"
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: input_field.value,
                                              expression: "input_field.value",
                                            },
                                          ],
                                          staticClass:
                                            "form-select asset-details-input-dropdown form-control",
                                          class:
                                            _vm.errors[
                                              input_field.placeholder
                                            ] != ""
                                              ? "is-invalid"
                                              : "",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  input_field,
                                                  "value",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.inputFieldValueChange()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                value: "",
                                                disabled: "",
                                                selected: "",
                                              },
                                            },
                                            [_vm._v("Select the value...")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            input_field.options,
                                            function (option) {
                                              return _c(
                                                "option",
                                                { domProps: { value: option } },
                                                [_vm._v(_vm._s(option))]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "wysiwyg"
                                    ? _c("wysiwyg-editor-advanced", {
                                        attrs: {
                                          input_value: input_field.value,
                                          input_index: index,
                                          asset_id: _vm.local_asset.id,
                                        },
                                        on: {
                                          wysiwygValueChange:
                                            _vm.wysiwygValueChange,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  (_vm.errors[input_field.placeholder] &&
                                    _vm.errors[input_field.placeholder]) != ""
                                    ? _c("div", {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.errors[input_field.placeholder]
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _c("div", [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Status"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_asset.status,
                          expression: "local_asset.status",
                        },
                      ],
                      staticClass: "form-select",
                      class: _vm.errors.status != "" ? "is-invalid" : "",
                      attrs: { id: "status-select" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_asset,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.clearErrors()
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", selected: "" } },
                        [_vm._v("Select the status...")]
                      ),
                      _vm._v(" "),
                      _vm.wasRejected()
                        ? _c("option", { attrs: { value: "delete" } }, [
                            _vm._v("Delete"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "for_approval" } }, [
                        _vm._v("Send Back for Review"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "approved" } }, [
                        _vm._v("Approved"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errors.status != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.errors.status) +
                              "\n                            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", [
              _vm.form.successful
                ? _c("div", { staticClass: "alert alert-success" }, [
                    _vm._v(
                      "\n                            The asset's information has been updated!\n                        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                            There was an error saving the asset information.\n                        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.warning
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                            This is the last edit version for the asset.\n                        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary me-2",
                    attrs: { type: "button", disabled: _vm.form.busy },
                    on: {
                      click: function ($event) {
                        return _vm.updateEdit()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-save" }),
                    _vm._v(" Save\n                            "),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "card box-content mt-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("h4", { staticClass: "mb-2" }, [_vm._v("Original")]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.original_inputs, function (input_field, index) {
                  return (input_field.value != "" &&
                    input_field.value != null) ||
                    (_vm.original_inputs[index].value != null &&
                      _vm.original_inputs[index].value != "")
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          input_field.type != "image" &&
                          input_field.type != "file" &&
                          input_field.type != "link"
                            ? _c("div", { staticClass: "col-12 mb-3" }, [
                                _c(
                                  "label",
                                  { staticClass: "form-label mb-0" },
                                  [_vm._v(_vm._s(input_field.name))]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "input-value-display form-control form-control-sm",
                                  class: input_field.type,
                                  domProps: {
                                    innerHTML: _vm._s(
                                      input_field.displayed_value
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e()
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("em", [
        _vm._v(
          "This version is not editable. Make any additional changes to the Client's Approved Version."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }