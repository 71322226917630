<template>
    <div id="generate-ppt-build-new">
        <div id="choose">
            <div id="choose-report" class="card">
                <div id="choose-report-description">
                    <h5>Add Report Pages</h5>
                    <p>Select the report pages you want to include in your presentation.</p>
                </div>

                <div id="choose-report_search-row" class="flex-row flex-nowrap gap-2 align-items-stretch mt-1">
                    <div class="search-box flex-grow-1 position-relative">
                        <input type="text" class="form-control" placeholder="Search by Report Name, Group Name or Description ..." aria-label="Search by Report Name, Group Name or Description ..." v-model="search_key" @input="searchPage()">
                        <i class="fa fa-search"></i>
                    </div>
                </div>

                <div id="choose-report-list" class="d-flex flex-column gap-3">
                    <div v-if="filtered_page_list.length == 0">
                        <p>No reports match your search.</p>
                    </div>

                    <div v-else v-for="(page, page_index) in available_page_list" class="card long-card"  @click="addPageSelectionToPPT(page, page_index)">
                        
                        <div class="card-body long-card__body" >
                            <div class="long-card__content d-flex flex-row justfy-content-start gap-2">

                                <div class="d-flex flex-column flex-grow-1 gap-1">
                                    <div class="d-block">
                                        <div class="d-flex flex-row align-items-center position-relative">
                                            <h5>{{ page.name }}</h5>
                                            <small>
                                                <span class="badge">{{ page.group_name}}</span>
                                            </small>
                                        </div>
                                    </div>
                                    
                                    <p>{{ page.description }}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="table-pagination">
                        <div class="table-pagination--group">
                            <span class="d-none d-lg-inline">Showing: </span>
                            <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                <option value="5000">ALL</option>
                                <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                            </select>
                            <span>Results</span>
                        </div>

                        <div class="table-pagination--group text-center">
                            {{ item_interval }} of {{ filtered_page_list.length }}
                        </div>

                        <div class="table-pagination--group">
                            <button v-if="page_count>1" class="btn btn-none" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                            </button>   

                            <div v-if="page_index_array.length<=3">
                                <button  v-for="page in page_index_array" class="btn btn-none" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                {{page}}
                                </button>  
                            </div>
                            <div v-else>
                                <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none " :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                {{page}}
                                </button>  

                                <button v-if="page_index > 2" class="btn btn-none">
                                ...
                                </button>  
                                <button v-if="page_index >= 2 && page_index< page_index_array.length -1" class="btn btn-none  current-page">
                                    {{page_index + 1 }} 
                                </button>  

                                <button v-if="page_index < page_index_array.length -2" class="btn btn-none ">
                                ...
                                </button>  
                                <button  class="btn btn-none" :class="page_index ==page_index_array.length -1 ? 'current-page':''" @click="moveToPage(page_index_array.length)">
                                {{ page_index_array.length }}
                                </button>  
                            </div>

                            <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == page_count -1 ">
                                <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                            </button>   
                        </div>
                    </div>
                </div>

            </div>

            <div id="selected-report" class="card">
                <div id="selected-report-description" class="row d-flex">
                    <div class="col-10">
                        <h5>Manage the Page Order</h5>
                        <p>Change the order of the various pages or remove selected reports.</p>
                    </div>
                    <div class="col-2" v-if="selected_page_list.length > 0">
                        <button type="button" class="btn btn-primary float-end mb-2" v-on:click="toggleFilterPanel()">
                            <img class="icon pe-0" src="/img/icons/dialexa-icons/sort.svg"> 
                            <span>({{ filter_count }})</span>
                        </button>                         
                    </div>
                </div>

                <div id="selected-report-list" class="flex-grow-1 position-relative">
                    <div class="position-absolute top-0 start-0 end-0 bottom-0">
                        <div class="position-relative h-100">
                            <div class="scroll-view d-flex flex-column gap-3 flex-shrink-1 pt-1" style="max-height: 100%;" :class="selected_page_list.length >= 5 ? 'pe-2' : ''">
                                <div v-if="selected_page_list.length == 0"  class="card long-card me-1 disabled">
                                    <div class="card-body long-card__body row ">
                                        <div class="long-card__content">
                                            <h5 class="mb-1">No Pages Selected</h5>
                                            <p>Select a page from the list on the left to start building your presentation.</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else >
                                <draggable v-model="selected_page_list" handle=".handle">

                                <div v-for="(page, page_index) in selected_page_list" class="card long-card me-1 mb-3" style="cursor: default;" :key="page.id">
                                    <div class="card-body long-card__body row">
                                        <div class="long-card__content">
                                            <div class="row d-flex align-items-center position-relative">
                                                <div class="col-1 float-start ">
                                                    <div class="handle">
                                                        <img src="/img/icons/dialexa-icons/vertical-dots.svg" alt="drag" class="icon">
                                                    </div>
                                                </div>
                                                <div class="col-11">
                                                    <h5 class="mb-1">{{ page.name }}</h5>
                                                    <p class="mb-1">
                                                        <small>
                                                            <span class="badge">{{ page.group_name}}</span>
                                                        </small>
                                                        <small v-if="page.sections.length">
                                                            <span class="badge">{{ page.sections.length}} {{(page.sections.length > 1 ? 'Sections': 'Section')}}</span>
                                                        </small>
                                                    </p>
                                                    <div class="d-flex">
                                                    <!--
                                                        <div class="flex-shrink-1 me-3">
                                                            <label class="text-nowrap">
                                                                <input class="form-check-input me-1" type="checkbox" v-model="page.include_linked_sections" >
                                                                Includes linked section
                                                                <i class="fas fa-question-circle text-primary mt-1" data-bs-toggle="tooltip" title="Linked sections are those referenced in the key takeaways. When selected, the presentation will incorporate the section after the referenced chart." ></i>
                                                            </label>
                                                        </div>

                                                    -->
                                                        <div class="w-100">
                                                            <label>
                                                                <input class="form-check-input me-1" type="checkbox" v-model="page.blend_sections" >
                                                                Blend sections
                                                                <i class="fas fa-question-circle text-primary mt-1" data-bs-toggle="tooltip" title="In pages where the donors and revenue are split, it can be helpful to blend the sections together. This will alternate the charts in the first two sections." ></i>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="asset-accordion-delete btn" aria-label="Close"
                                        v-on:click="removePageSelection(page, page_index)">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </draggable>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade"  duration="500">
            <div class="border-start border-secondary" :class="is_filter_panel_expanded ? 'open' : 'hidden'" id="filter-panel">
                <div id="filter-panel--heading" class="p-4 pb-0 mt-2">
                    <h4>Filters</h4>
                    <button type="button" class="btn btn-none float-end" @click="toggleFilterPanel()">
                        <i class="fa-regular fa-circle-xmark"></i> 
                    </button>
                </div>
                <p class="mx-4 small">Filters will only be applied to a page if the filter is present on the page by default.</p>
                <hr id="filter-panel-heading-hr" class="mx-4">
                <generate-presentation-filter-panel :pages_selected="selected_page_list" :client="client" @updateFilters="updateFilters" @updateFilterCount="updateFilterCount"></generate-presentation-filter-panel>
            </div>
        </transition>

        <div class="sticky-footer">
            <nav class="navbar navbar-expand py-0 d-flex justify-content-between row">
                <div class="col-12">
                    <button class="btn btn-primary float-end" @click.prevent="downloadPPT()" :disabled="form.busy || selected_page_list.length == 0" >
                        <span v-if="!form.busy">Download PPT</span>
                        <span v-else><span class="spinner-border spinner-border-sm me-2" role="status"><span class="visually-hidden"></span></span> Processing</span>
                    </button>
                </div>
            </nav>
        </div>
        <link rel="preload" href="/img/icons/dialexa-icons/vertical-dots.svg" as="image" type="image/svg+xml" />
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import draggable from 'vuedraggable';
    export default {
        components: { draggable, Swal },
        props: ['client', 'report_pages'],
        data() {
            return {
                search_key: '',
                filtered_page_list: [],
                available_page_list:[],
                selected_page_list:[],
                // pagination
                num_per_page: 5, // default to 5
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: '',

                is_filter_panel_expanded: false,
                selected_filter_list: [],
                filter_count: 0,
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                export_id: null
            };
        },
        beforeMount() {
            this.resetFilteredPages();
        },
        mounted() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            resetFilteredPages() {
                this.search_key = '';
                this.filtered_page_list = [...this.report_pages];
                this.filtered_page_list.sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.reloadPagination();
            },
            clonePresentation(old_preso) {
                this.selected_page_list = [];
                this.resetFilteredPages();

                //loop through the sections array on old_preso
                //and add each section to the selected_page_list
                old_preso.sections.forEach(section => {
                    for(let i = this.filtered_page_list.length-1; i >= 0 ; i--) {
                        //The ID can change. This will have to match by name
                        if(this.filtered_page_list[i].name == section.page.name) {
                            this.addPageSelectionToPPT(this.filtered_page_list[i], i);
                        }
                    }

                });
            },
            searchPage(){
                var filtered_list = [];

                if (this.search_key == ''){
                    filtered_list = this.report_pages;
                    //And then remove those in the selected_page_list
                    this.selected_page_list.forEach(page_tim => {
                        var page_id = page_tim.id;
                        var page_index = filtered_list.findIndex(page => page.id == page_id);
                        if (page_index != -1)
                            filtered_list.splice(page_index, 1);
                    });
                } else {
                    // go to first page then conduct searching
                    this.moveToPage(1);
                    this.report_pages.forEach(page => {
                        if(page.name.toLowerCase().includes(this.search_key.toLowerCase()) 
                        || page.group_name.toLowerCase().includes(this.search_key.toLowerCase())
                        || page.description.toLowerCase().includes(this.search_key.toLowerCase())){
                            filtered_list.push(page);
                        } 
                    });                    
                }
                this.filtered_page_list = filtered_list.sort((a, b) => (a.name > b.name) ? 1 : -1);;
                this.reloadPagination();
                this.$forceUpdate();
            },
            reloadPagination(){
                this.page_count = Math.ceil(this.filtered_page_list.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            }, 
            getItemInterval(){
                var from = this.page_index * this.num_per_page + 1;
                var to = (this.page_index +1) * this.num_per_page;
                if(to>this.filtered_page_list.length)
                    to = this.filtered_page_list.length;
                if (from >= to) 
                    this.item_interval = to
                else 
                    this.item_interval = from +' - ' +to;
                
                this.available_page_list = this.filtered_page_list.slice(from-1, to);
            },
            moveToPrevPage(){
                this.page_index--;
                this.getItemInterval();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getItemInterval();
            },
            moveToNextPage(){
                this.page_index++;
                this.getItemInterval();
            },
            addPageSelectionToPPT(page, index){
                var page_temp = page;
                page_temp.order = this.selected_page_list.length + 1;
                page_temp.include_linked_sections = false;
                page_temp.blend_sections = false;
                this.selected_page_list.push(page_temp);

                //Find the ID of page_tim in the filtered_page_list and remove it
                var page_id = page.id;
                var page_index = this.filtered_page_list.findIndex(page => page.id == page_id);
                this.filtered_page_list.splice(page_index, 1);

                this.reloadPagination();
            },
            removePageSelection(page, index) {
                this.filtered_page_list.push(page);
                this.selected_page_list.splice(index, 1);
                this.filtered_page_list.sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.adjustOrderInSelectedPageList();
                this.reloadPagination();
            },
            adjustOrderInSelectedPageList(){
                for (var i = 0; i < this.selected_page_list.length; i++){
                    this.selected_page_list[i].order = i+1;
                }
            },
            toggleFilterPanel(){
                this.is_filter_panel_expanded = !this.is_filter_panel_expanded
            },
            updateFilters(filter_list){
                this.selected_filter_list = filter_list;
            },
            updateFilterCount(filter_count){
                this.filter_count = filter_count;
            },
            downloadPPT() {
                this.form.busy = true;

                window.axios.post('/api/export/ppt', {pages: this.selected_page_list})
                    .then(response => {
                        this.export_id = response.data.export_id;
                        Swal.fire({
                            title: "Your Presentation is Being Built",
                            html: "It will take a few minutes to compile all of the data. In the meantime, you can enter a presentation name below which will save the template to be downloaded or duplicated in the future.",
                            input: "text",
                            inputLabel: "Presentation Name",
                            showCancelButton: true,
                            iconColor: "#46705F",
                            confirmButtonColor: "#46705F",
                            confirmButtonText: "Save",
                            cancelButtonText: "No Thanks",
                            preConfirm: (presentationName) => {
                                if (presentationName) {
                                    // Call your function with the presentation name
                                    this.savePresoName(presentationName);
                                }
                            }
                        }).then((result) => {
                            if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
                                // Ensure startProgressToast is a function before calling it
                                if (typeof this.startProgressToast === 'function') {
                                    this.startProgressToast();
                                } else {
                                    //console.error('startProgressToast is not a function');
                                }
                            }
                        });

                    });
            },
            savePresoName(name) {
                window.axios.put('/api/export/ppt/' + this.export_id, {name: name})
                    .then(response => {

                        this.startProgressToast();
                    });
            },
            startProgressToast(){
                var self = this;
                Swal.fire({
                    title: 'Generating the Slides...',
                    html: '<span id="swal-text">A download link will soon appear both here and in your notifications.</span><div class="progress mt-2"><div id="progress-bar" class="progress-bar bg-primary progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="1"></div></div>',
                    toast: true,
                    position: 'bottom-end',
                    icon: 'info',
                    showConfirmButton: false,
                    showCloseButton: true,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('#progress-bar');
                        let percentage = 0;
                        let close = document.getElementsByClassName("swal2-close");
                        //Loop through the close and hide them
                        for (let i = 0; i < close.length; i++) {
                            close[i].style.display = 'none';
                        }


                        // Update the percentage meter every second
                        const interval = setInterval(() => {
                            fetch('/api/export/ppt/' + self.export_id)
                                .then(response => response.json())
                                .then(data => {
                                    percentage = data.export.percent_finished;
                                    b.style.width = `${100*percentage}%`;

                                    if(data.export.status == 'error'){
                                        clearInterval(interval);
                                        Swal.close();
                                        Swal.fire({
                                            title: 'Error',
                                            text: 'There was an error generating the presentation. Please try again.',
                                            icon: 'error',
                                            iconColor: "#D11F1F",
                                            confirmButtonColor: "#D11F1F",
                                            showConfirmButton: false,
                                            showCloseButton: true,
                                        });
                                        self.form.busy = false;
                                    }
                                    // If it is completely done
                                    else if (percentage >= 1 && data.export.status == 'completed') {
                                        clearInterval(interval);
                                        //Download the PPT (show the download link)
                                        document.getElementById('swal2-title').innerText = 'It\'s Ready!';
                                        document.getElementById('swal2-html-container').innerHTML = "Your presenation has been specially prepared for you!<br><a href='"+data.url+"' target='_blank' class='btn btn-primary mt-2'>Download it here</a>"
                                        self.form.busy = false;
                                        //Show the close and hide the loader
                                        let close = document.getElementsByClassName("swal2-close");
                                        let loader = document.getElementsByClassName("swal2-loader");
                                        //Loop through the close and hide them
                                        for (let i = 0; i < close.length; i++)
                                            close[i].style.display = 'flex';
                                        for (let i = 0; i < loader.length; i++)
                                            loader[i].style.display = 'none';
                                    }
                                    //If it is done but needs to assemble it all
                                    else if(percentage >= 1) {
                                        document.getElementById('swal2-title').innerText = 'Pulling It All Together...';
                                        document.getElementById('swal-text').innerText = "You're less than a minute away. The download link will appear momentarily."
                                    }
                                })
                                .catch(error => {
                                    console.error('Error fetching percentage:', error);
                                    clearInterval(interval);
                                    Swal.close();
                                });
                        }, 3000);
                    }
                });
            }
        }
    }
</script>
