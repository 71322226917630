var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "analysis-segment-builder" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Name *")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_analysis_audience.name,
              expression: "local_analysis_audience.name",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors.name != "" ? "is-invalid" : "",
          attrs: { type: "text", id: "name-input" },
          domProps: { value: _vm.local_analysis_audience.name },
          on: {
            change: function ($event) {
              return _vm.clearErrorForField("name")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.local_analysis_audience, "name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.name != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.name) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Start Date *")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-group" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary dropdown-toggle",
                attrs: {
                  type: "button",
                  "data-bs-toggle": "dropdown",
                  "aria-expanded": "false",
                },
              },
              [
                _vm.local_analysis_audience.start_date.type == "static"
                  ? _c("i", { staticClass: "fa fa-calendar" })
                  : _vm.local_analysis_audience.start_date.type == "minimum"
                  ? _c("i", { staticClass: "fa fa-dollar" })
                  : _c("i", { staticClass: "fa fa-font" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu",
                attrs: { "aria-labelledby": "dropdownMenuButton1" },
              },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.local_analysis_audience.start_date.type = "static"
                          _vm.local_analysis_audience.start_date.value = null
                          _vm.local_analysis_audience.if_retain_constituents = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Static Date\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.local_analysis_audience.start_date.type =
                            "variable"
                          _vm.local_analysis_audience.start_date.value =
                            "segment_entry_date"
                          _vm.clearErrorForField("start_date")
                          _vm.local_analysis_audience.if_retain_constituents = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Date Constituent is Added to Audience\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                !_vm.if_hide_appeal_code_toggle
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.local_analysis_audience.start_date.type =
                                "minimum"
                              _vm.local_analysis_audience.start_date.value =
                                "minimum_gift_date"
                              _vm.clearErrorForField("start_date")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Minimum Gift Date of Audience\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.local_analysis_audience.start_date.type == "variable"
              ? _c("input", {
                  staticClass: "form-control ps-2",
                  attrs: {
                    type: "text",
                    placeholder: "Date Constituent Added to Audience",
                    disabled: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.local_analysis_audience.start_date.type == "minimum"
              ? _c("input", {
                  staticClass: "form-control ps-2",
                  attrs: {
                    type: "text",
                    placeholder: "Minimum Gift Date of Audience",
                    disabled: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.local_analysis_audience.start_date.type == "static"
              ? _c("datepicker", {
                  class: _vm.errors.start_date != "" ? "is-invalid" : "",
                  attrs: {
                    format: "M/d/yyyy",
                    "input-class": "form-control date",
                    placeholder: "MM/DD/YYYY",
                    "use-utc": true,
                    "bootstrap-styling": true,
                  },
                  on: {
                    change: function ($event) {
                      $event.preventDefault()
                      return _vm.clearErrorForField("start_date")
                    },
                  },
                  model: {
                    value: _vm.local_analysis_audience.start_date.value,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.local_analysis_audience.start_date,
                        "value",
                        $$v
                      )
                    },
                    expression: "local_analysis_audience.start_date.value",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.errors.start_date != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.start_date) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Appeal Code")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          !_vm.if_hide_appeal_code_toggle
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary dropdown-toggle",
                  attrs: {
                    type: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                  },
                },
                [
                  _vm.local_analysis_audience.appeal_code.type == "static"
                    ? _c("img", {
                        staticClass: "icon smaller-icon",
                        attrs: { src: "/img/icons/dialexa-icons/text.svg" },
                      })
                    : _c("i", { staticClass: "fa fa-font" }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.if_hide_appeal_code_toggle
            ? _c(
                "ul",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "dropdownMenuButton1" },
                },
                [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.local_analysis_audience.appeal_code.type =
                              "static"
                            _vm.local_analysis_audience.appeal_code.value = ""
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Static\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.local_analysis_audience.appeal_code.type =
                              "variable"
                            _vm.local_analysis_audience.appeal_code.value =
                              "transactions.appeal_code"
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Transaction Appeal Code\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.local_analysis_audience.appeal_code.type == "static"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_analysis_audience.appeal_code.value,
                    expression: "local_analysis_audience.appeal_code.value",
                  },
                ],
                staticClass: "form-control ps-2",
                attrs: { type: "text" },
                domProps: {
                  value: _vm.local_analysis_audience.appeal_code.value,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_analysis_audience.appeal_code,
                      "value",
                      $event.target.value
                    )
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.local_analysis_audience.appeal_code.type == "variable"
            ? _c("input", {
                staticClass: "form-control ps-2",
                attrs: {
                  type: "text",
                  placeholder: "Transaction Appeal Code",
                  disabled: "",
                },
              })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Category")]),
        _vm._v(" "),
        _vm.local_analysis_audience.category.type == "static"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_analysis_audience.category.value,
                  expression: "local_analysis_audience.category.value",
                },
              ],
              staticClass: "form-control ps-2",
              attrs: { type: "text" },
              domProps: { value: _vm.local_analysis_audience.category.value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.local_analysis_audience.category,
                    "value",
                    $event.target.value
                  )
                },
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_analysis_audience.if_retain_constituents,
              expression: "local_analysis_audience.if_retain_constituents",
            },
          ],
          staticClass: "form-check-input",
          attrs: {
            type: "checkbox",
            disabled:
              _vm.local_analysis_audience.start_date.value ==
              "segment_entry_date",
          },
          domProps: {
            checked: Array.isArray(
              _vm.local_analysis_audience.if_retain_constituents
            )
              ? _vm._i(
                  _vm.local_analysis_audience.if_retain_constituents,
                  null
                ) > -1
              : _vm.local_analysis_audience.if_retain_constituents,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.local_analysis_audience.if_retain_constituents,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.local_analysis_audience,
                      "if_retain_constituents",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.local_analysis_audience,
                      "if_retain_constituents",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(
                  _vm.local_analysis_audience,
                  "if_retain_constituents",
                  $$c
                )
              }
            },
          },
        }),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
              _vm._v(
                "\n                There was an error saving the analysis Audience information.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary me-3",
            attrs: {
              type: "submit",
              id: "save-button",
              disabled: _vm.form.busy,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.saveAnalysisSegment.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "fa fa-btn fa-save" }),
            _vm._v(" Save\n            "),
          ]
        ),
        _vm._v(" "),
        _vm.segment_selected.analysis
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  type: "submit",
                  id: "delete-button",
                  disabled: _vm.form.busy,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteAnalysisSegment.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-btn fa-save" }),
                _vm._v(" Delete\n            "),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v(
        "Retain constituent if they no longer match criteria. \n                "
      ),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "When selected, constituents will stay in the analysis even if they no longer qualify for the audience. This is helpful when analyzing reactivation or similar efforts",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }