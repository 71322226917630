var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "results" },
    [
      _c(
        "h2",
        {
          staticClass: "mb-4 mt-5 border-bottom border-secondary border-3 pb-1",
        },
        [_vm._v(_vm._s(_vm.initiative.title))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3" },
          [
            _c("div", { staticClass: "row pb-3" }, [
              _c("div", { staticClass: "col" }, [
                _c("h4", { staticClass: "d-inline" }, [
                  _vm._v("Assigned Results"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary float-end",
                    attrs: {
                      type: "button",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#cost-result-modal",
                    },
                    on: {
                      click: function ($event) {
                        _vm.modal_version = "Result"
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" Add Result"),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.init.results_filter == null ||
            _vm.init.results_filter.length == 0
              ? _c("div", { staticClass: "card box-content" }, [_vm._m(0)])
              : _vm._l(_vm.init.results_filter, function (result) {
                  return _c("div", { staticClass: "card box-content mb-2" }, [
                    _c("div", { staticClass: "card-body p-2" }, [
                      _c("div", { staticClass: "accordion accordion-flush" }, [
                        _c(
                          "div",
                          { staticClass: "accordion-item border-bottom-0" },
                          [
                            _c("div", { staticClass: "row accordion-header" }, [
                              _c(
                                "div",
                                { staticClass: "col col-sm-8 col-md-9" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-link text-start collapsed p-2",
                                      attrs: {
                                        type: "button",
                                        "data-bs-target":
                                          "#result-" + result.id,
                                        "data-bs-toggle": "collapse",
                                        "aria-expanded": "false",
                                        "aria-controls": "#result-" + result.id,
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("propercase")(
                                              result.data_source_name !=
                                                undefined
                                                ? result.data_source_name
                                                : result.data_source
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(result.description) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-xs btn-none float-end p-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteResult(result)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-remove" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-xs btn-none float-end p-2",
                                    attrs: {
                                      type: "button",
                                      "data-bs-toggle": "modal",
                                      "data-bs-target": "#cost-result-modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editResult(result)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-edit" })]
                                ),
                                _vm._v(" "),
                                result.data_source != "manual"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-xs btn-none float-end p-2",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewTable(result)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-eye" })]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            result.data_source == "manual"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "collapse accordion-collapse",
                                    attrs: { id: "result-" + result.id },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "accordion-body" },
                                      [
                                        _c("strong", [_vm._v("Date:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm._f("date")(result.date))
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("strong", [_vm._v("Emails:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("number_with_zero")(
                                                result.emails
                                              )
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("strong", [_vm._v("Gifts:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("number_with_zero")(
                                                result.gifts
                                              )
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("strong", [_vm._v("New Donors:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("number_with_zero")(
                                                result.new_donors
                                              )
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("strong", [_vm._v("Revenue:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency_with_zero")(
                                                result.revenue
                                              )
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("strong", [
                                          _vm._v("Recurring Revenue:"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency_with_zero")(
                                                result.recurring_revenue
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "collapse accordion-collapse",
                                    attrs: { id: "result-" + result.id },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "accordion-body" },
                                      [
                                        _c("strong", [_vm._v("Filter Logic:")]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticStyle: {
                                            "line-height": "2.4em",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.logicToString(
                                                result.value.filter_logic,
                                                result.value.logic_units
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col mb-3" },
          [
            _c("div", { staticClass: "row pb-3" }, [
              _c("div", { staticClass: "col" }, [
                _c("h4", { staticClass: "d-inline" }, [
                  _vm._v("Assigned Costs"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary float-end",
                    attrs: {
                      type: "button",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#cost-result-modal",
                    },
                    on: {
                      click: function ($event) {
                        _vm.modal_version = "Cost"
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" Add Cost")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.init.costs_filter == null || _vm.init.costs_filter.length == 0
              ? _c("div", { staticClass: "card box-content" }, [_vm._m(1)])
              : _vm._l(_vm.init.costs_filter, function (cost) {
                  return _c("div", { staticClass: "card box-content mb-2" }, [
                    _c("div", { staticClass: "card-body p-2" }, [
                      _c("div", { staticClass: "accordion accordion-flush" }, [
                        _c(
                          "div",
                          { staticClass: "accordion-item border-bottom-0" },
                          [
                            _c("div", { staticClass: "row accordion-header" }, [
                              _c(
                                "div",
                                { staticClass: "col col-sm-8 col-md-9" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-link text-start collapsed p-2",
                                      attrs: {
                                        type: "button",
                                        "data-bs-target": "#cost-" + cost.id,
                                        "data-bs-toggle": "collapse",
                                        "aria-expanded": "false",
                                        "aria-controls": "#cost-" + cost.id,
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("propercase")(
                                              cost.data_source_name != undefined
                                                ? cost.data_source_name
                                                : cost.data_source
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(cost.description) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-xs btn-none float-end p-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCost(cost)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-remove" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-xs btn-none float-end p-2",
                                    attrs: {
                                      type: "button",
                                      "data-bs-toggle": "modal",
                                      "data-bs-target": "#cost-result-modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editCost(cost)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-edit" })]
                                ),
                                _vm._v(" "),
                                cost.data_source != "manual"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-xs btn-none float-end p-2",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewTable(cost)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-eye" })]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        cost.data_source == "manual"
                          ? _c(
                              "div",
                              {
                                staticClass: "collapse accordion-collapse",
                                attrs: { id: "cost-" + cost.id },
                              },
                              [
                                _c("div", { staticClass: "accordion-body" }, [
                                  _vm._v(
                                    "\n                                Total: " +
                                      _vm._s(
                                        _vm._f("currency_with_zero")(
                                          cost.amount
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                Date: " +
                                      _vm._s(_vm._f("date")(cost.date))
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                Cost Type: " +
                                      _vm._s(
                                        _vm._f("propercase")(cost.cost_type)
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "collapse accordion-collapse",
                                attrs: { id: "cost-" + cost.id },
                              },
                              [
                                _c("div", { staticClass: "accordion-body" }, [
                                  _c("strong", [_vm._v("Filter Logic:")]),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticStyle: { "line-height": "2.4em" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.logicToString(
                                          cost.value.filter_logic,
                                          cost.value.logic_units
                                        )
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                      ]),
                    ]),
                  ])
                }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.table_data != null
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col mb-3" }, [
              _c("div", { staticClass: "card box-content" }, [
                _c("div", { staticClass: "card-body p-0" }, [
                  typeof _vm.table_data === "string" && _vm.table_data == ""
                    ? _c("div", { staticClass: "m-3" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("span", { staticClass: "ms-2" }, [
                          _vm._v("Loading..."),
                        ]),
                      ])
                    : Array.isArray(_vm.table_data) && _vm.table_data.length > 0
                    ? _c("div", [
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "table-responsive gy-0 m-0 mb-2" },
                          [
                            _c("table", { staticClass: "table gy-0 m-0" }, [
                              _c("thead", { staticClass: "table-dark" }, [
                                _c(
                                  "tr",
                                  _vm._l(
                                    Object.keys(_vm.table_data[0]),
                                    function (row, index) {
                                      return _c(
                                        "th",
                                        {
                                          staticClass: "align-middle pt-0",
                                          class: index == 0 ? "ps-3" : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm._f("propercase")(row))
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                { staticClass: "table-border-bottom-0" },
                                _vm._l(_vm.table_data, function (row) {
                                  return _c(
                                    "tr",
                                    _vm._l(row, function (col, index) {
                                      return _c(
                                        "td",
                                        { class: index == 0 ? "ps-3" : "" },
                                        [_vm._v(_vm._s(col))]
                                      )
                                    }),
                                    0
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : Array.isArray(_vm.table_data) &&
                      _vm.table_data.length == 0
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "alert alert-warning box-content" },
                          [
                            _vm._v(
                              "\n                            No data was returned.\n                        "
                            ),
                          ]
                        ),
                      ])
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "alert alert-danger box-content" },
                          [
                            _vm._v(
                              "\n                            There was an error pulling the data:"
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("em", [_vm._v(_vm._s(_vm.table_data))]),
                          ]
                        ),
                      ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("cost-result-modal", {
        attrs: {
          modal_version: _vm.modal_version,
          initiative: _vm.init,
          client: _vm.client,
          filter: _vm.filter,
        },
        on: {
          "update:modal_version": function ($event) {
            _vm.modal_version = $event
          },
          "update:initiative": function ($event) {
            _vm.init = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary mt-4",
              attrs: { href: "/results/" + _vm.client.url + "/projections" },
            },
            [_c("i", { staticClass: "fa fa-btn fa-save" }), _vm._v(" Update")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body p-2" }, [
      _c("div", { staticClass: "p-2" }, [_vm._v("None Added Yet")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body p-2" }, [
      _c("div", { staticClass: "p-2" }, [_vm._v("None Added Yet ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-warning float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }