<template>
    <div id="org_settings_clients" class=" pathways-manage">
        <div class="row">
            <div class="col-10">
                <h4>Clients</h4>
                <p>Manage your clients by adding, modifying, or removing them.</p>
            </div>
            <div class="col-2">
                <a :href="addClientUrl()" class="btn btn-primary float-end">
                    <span>
                        <i class="fas fa-fw fa-plus"></i>
                        Add Client
                    </span>
                </a>       
            </div>
        </div>

        <div class= "card my-3">
            <div class="card-header">
                <div class="d-flex flex-row justify-content-between align-items-center gap-3">
                    <div class="d-flex justify-content-start"><h5>Clients ({{ agency.clients.length }})</h5></div> 
                    <div class="d-flex justify-content-end justify-self-end gap-2">
                        <div class="position-relative">
                            <input type="text" placeholder="Search by Name..." aria-label="Search by Name..." v-model="search_key" @input="filterClients()" autocomplete="off" class="search-box form-control me-1"> <i class="fa fa-search search-placeholder-icon" v-if="search_key === ''"></i>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="card-body avid-table pathways-manage-segment-table p-0">
                <table class="table table-responsive table-with-pagination pathways-manage-segment-table mb-0">
                    <thead>
                        <tr class="table-header-row">
                            <th @click="sortByKey('name')" class="table-column-1">
                                <div class="d-flex flex-row">
                                    Name
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'name' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'name' && sort_order === 'des') ? 'sort-key-active' : ''">
                                    </div>
                                </div>
                            </th>
                            <th @click="sortByKey('url_slug')" class="table-column-3">
                                <div class="d-flex flex-row">
                                    Plan Level
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'plan_level' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'plan_level' && sort_order === 'des') ? 'sort-key-active' : ''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th @click="sortByKey('domain')" class="table-column-2">
                                <div class="d-flex flex-row">
                                    Domain
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'domain' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'domain' && sort_order === 'des') ? 'sort-key-active' : ''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th @click="sortByKey('created_at')" class="table-column-2">
                                <div class="d-flex flex-row">
                                    Created On
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'created_at' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'created_at' && sort_order === 'des') ? 'sort-key-active' : ''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th class="table-column-4 text-end" >Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(client, index) in clients_shown" :key="client.id" :class="`table-row-${index + 1}`">
                            <td class="align-start table-column-1">
                                <img class="avatar rounded-circle me-3" :src="client.image" /> 
                                <a :href="editClientUrl(client.id)">{{ client.name }}</a>
                            </td>
                            <td class="align-start table-column-3">
                                {{ client.plan_level | propercase}}
                            </td>
                            <td class="align-start table-column-2">
                                {{ client.domain }}
                            </td>
                            <td class="align-start table-column-2">
                                {{ client.created_at | date }}
                            </td>

                            <td class="align-start table-column-4 show-on-row-hover text-end">
                                <a :href="'/landing/' + client.url" class="btn btn-none px-1"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Insights">
                                    <img class="icon" src="/img/icons/dialexa-icons/eye.svg">
                                </a>
                                <button class="btn btn-none px-1" @click="confirmDeleteClient(client)" title="Delete Client">
                                    <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Client">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="table-border-bottom-0">
                        <tr>
                            <td colspan="100%" class="py-0">
                                <div class="table-pagination">
                                    <div class="table-pagination--group">
                                        <span>Showing: </span>
                                        <select class="form-select" v-model="num_per_page" @change="reloadPagination()">
                                            <option value="5000">ALL</option>
                                            <option v-for="num in num_per_page_options" :key="num" :value="num">{{ num }}</option>
                                        </select>
                                        <span>Clients</span>
                                    </div>

                                    <div class="table-pagination--group">
                                        {{ item_interval }} of {{ filtered_clients.length }}
                                    </div>

                                    <div class="table-pagination--group">
                                        <button v-if="page_count > 1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index === 0">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                        </button>   

                                        <div v-if="page_index_array.length <= 3">
                                            <button v-for="page in page_index_array" :key="page" class="btn btn-none px-1" :class="page === (page_index + 1) ? 'current-page' : ''" @click="moveToPage(page)">
                                                {{ page }}
                                            </button>  
                                        </div>
                                        <div v-else>
                                            <button v-for="page in page_index_array.slice(0,2)" :key="page" class="btn btn-none px-1" :class="page === (page_index + 1) ? 'current-page' : ''" @click="moveToPage(page)">
                                                {{ page }}
                                            </button>  

                                            <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                                ...
                                            </button>  
                                            <button v-if="page_index >= 2 && page_index < page_index_array.length -1" class="btn btn-none px-1 current-page">
                                                {{ page_index + 1 }} 
                                            </button>  

                                            <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1" disabled>
                                                ...
                                            </button>  
                                            <button :class="page_index === (page_index_array.length -1) ? 'current-page' : ''" class="btn btn-none px-1" @click="moveToPage(page_index_array.length)" :key="page_index_array.length">
                                                {{ page_index_array.length }}
                                            </button>  
                                        </div>

                                        <button v-if="page_count > 1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index === page_count -1">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                        </button>   
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    props: ['agency'], // 'user' prop for user type and permissions
    data() {
        return {
            filtered_clients: [...this.agency.clients],
            clients_shown: [],
            search_key: '',
            sort_key: null,
            sort_order: 'asc',
            num_per_page: 20,
            num_per_page_options: [5,10,15,20,25,30,50,100],
            page_count: 0,
            page_index: 0,
            page_index_array: [],
            item_interval: '',
        };
    },
    mounted() {
        this.reloadPagination();
    },
    methods: {
        filterClients() {
            if (this.search_key.trim() === "") {
                this.filtered_clients = [...this.agency.clients];
            } else {
                const key = this.search_key.toLowerCase();
                this.filtered_clients = this.agency.clients.filter(client => client.name.toLowerCase().includes(key) || client.domain.toLowerCase().includes(key));
            }
            this.reloadPagination();
        },
        sortByKey(key) {
            if (this.sort_key === key) {
                this.sort_order = (this.sort_order === 'asc') ? 'des' : 'asc';
            } else {
                this.sort_key = key;
                this.sort_order = 'asc';
            }
            this.sortData();
        },
        sortData() {
            if (this.sort_order === 'asc') {
                this.filtered_clients.sort((a, b) => a[this.sort_key].localeCompare(b[this.sort_key]));
            } else {
                this.filtered_clients.sort((a, b) => b[this.sort_key].localeCompare(a[this.sort_key]));
            }
            this.reloadPagination();
        },
        reloadPagination() {
            this.page_count = Math.ceil(this.filtered_clients.length / this.num_per_page);
            this.page_index = 0;
            this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
            this.updateItemInterval();
        },
        updateItemInterval() {
            const from = this.page_index * this.num_per_page + 1;
            let to = (this.page_index + 1) * this.num_per_page;
            if (to > this.filtered_clients.length) to = this.filtered_clients.length;
            this.item_interval = `${from} - ${to}`;
            this.clients_shown = this.filtered_clients.slice(from - 1, to);
        },
        moveToPrevPage() {
            if (this.page_index > 0) {
                this.page_index--;
                this.updateItemInterval();
            }
        },
        moveToPage(page) {
            this.page_index = page - 1;
            this.updateItemInterval();
        },
        moveToNextPage() {
            if (this.page_index < this.page_count -1) {
                this.page_index++;
                this.updateItemInterval();
            }
        },
        confirmDeleteClient(client) {
            Swal.fire({
                title: `Are you sure you want to remove ${client.name}?`,
                text: "This action cannot be undone.",
                icon: "warning",
                iconColor: "#D11F1F",
                showCancelButton: true,
                confirmButtonColor: "#D11F1F",
                confirmButtonText: "Yes, confirm",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteClient(client);
                }
            });
        },
        deleteClient(client) {
            // Placeholder for API call to delete client
            // Replace with actual API endpoint and method
            axios.delete(`/api/agencies/${this.agency.id}/clients/${client.id}`)
                .then(response => {
                    // Remove client from the local list
                    const index = this.agency.clients.findIndex(c => c.id === client.id);
                    if (index !== -1) {
                        this.agency.clients.splice(index, 1);
                        this.filterClients();
                    }
                    Swal.fire({
                        html: `<h4 class="mb-0 text-white">${client.name} was removed!</h4>`,
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                })
                .catch(error => {
                    console.error('Error deleting client:', error);
                    Swal.fire({
                        html: `<h4 class="mb-0 text-white">Error removing this client!</h4>`,
                        icon: 'warning',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                });
        },
        addClientUrl() {
            // Placeholder URL for adding a new client
            return `/admin/agency/${this.agency.id}/client/add`;
        },
        editClientUrl(clientId) {
            // Placeholder URL for editing a client
            return `/admin/agency/${this.agency.id}/client/${clientId}`;
        }
    }
}
</script>