var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "versions" }, [
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body p-0" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("table", { staticClass: "table gy-0 m-0 mb-2" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            { staticClass: "table-border-bottom-0" },
            _vm._l(_vm.plans, function (plan, index) {
              return _c("tr", { attrs: { id: "row" + plan.id } }, [
                plan.client_id != null
                  ? _c("td", { staticClass: "ps-3 align-middle" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "/planning/" + plan.url + "/annual" },
                        },
                        [_vm._v(_vm._s(plan.name))]
                      ),
                    ])
                  : _c(
                      "td",
                      {
                        staticClass: "ps-3 m align-middle",
                        attrs: { colspan: "2" },
                      },
                      [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c("span", { staticClass: "ms-2" }, [
                          _vm._v("Copying..."),
                        ]),
                      ]
                    ),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle" }, [
                  plan.is_published_plan == 1
                    ? _c(
                        "span",
                        { staticClass: "badge rounded-pill badge-primary" },
                        [_vm._v("Published")]
                      )
                    : plan.is_active_plan == 1
                    ? _c(
                        "span",
                        { staticClass: "badge rounded-pill badge-secondary" },
                        [_vm._v("Draft")]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                plan.client_id != null
                  ? _c(
                      "td",
                      { staticClass: "align-middle d-none d-md-table-cell" },
                      [_vm._v(_vm._s(_vm._f("date")(plan.created_at)))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "align-middle d-none d-md-table-cell" },
                  [
                    plan.client_id != null
                      ? _c("img", {
                          staticClass: "avatar rounded-circle me-3",
                          attrs: {
                            "data-bs-toggle": "tooltip",
                            "data-popup": "tooltip-custom",
                            "data-bs-placement": "top",
                            "data-bs-original-title": plan.creator.name,
                            src: plan.creator.image,
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "align-middle d-none d-md-table-cell" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(
                          _vm.checkDuplicate(
                            plan.created_at,
                            plan.updated_at,
                            plan.updated_at
                          )
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "align-middle d-none d-md-table-cell" },
                  [
                    plan.client_id != null
                      ? _c("img", {
                          staticClass: "avatar rounded-circle me-3",
                          attrs: {
                            "data-bs-toggle": "tooltip",
                            "data-popup": "tooltip-custom",
                            "data-bs-placement": "top",
                            "data-bs-original-title": plan.updater.name,
                            src: plan.updater.image,
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle" }, [
                  _c("div", { staticClass: "dropdown" }, [
                    _vm._m(3, true),
                    _vm._v(" "),
                    _c("ul", { staticClass: "dropdown-menu" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              href: "/planning/" + plan.url + "/annual",
                            },
                          },
                          [_vm._v("View Plan")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              href: "/planning/" + plan.url + "/assumptions",
                            },
                          },
                          [_vm._v("View Assumptions")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editPlan(plan.id)
                              },
                            },
                          },
                          [_vm._v("Edit Title")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        plan.is_published_plan == 0
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setAsPublished(plan)
                                  },
                                },
                              },
                              [_vm._v("Publish Plan")]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        plan.is_active_plan == 0
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#", title: "Edit" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setAsActive(plan)
                                  },
                                },
                              },
                              [_vm._v("Set as Primary Draft")]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.copyPlan(plan.id)
                              },
                            },
                          },
                          [_vm._v("Clone Plan")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.transferPlan(plan)
                              },
                            },
                          },
                          [_vm._v("Transfer Plan")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        !(
                          plan.is_active_plan == 1 ||
                          plan.is_published_plan == 1
                        )
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deletePlan(plan)
                                  },
                                },
                              },
                              [_vm._v("Delete Plan")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _vm.plan.id == null
          ? _c("h4", [_vm._v("Create a Blank Plan")])
          : _c("h4", [_vm._v("Edit This Plan Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "pb-3", attrs: { id: "form_start" } }, [
          _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.plan.name,
                expression: "plan.name",
              },
            ],
            staticClass: "form-control",
            class: _vm.errors.name != "" ? "is-invalid" : "",
            attrs: { type: "text", name: "name" },
            domProps: { value: _vm.plan.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.plan, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.name != ""
            ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.errors.name) +
                    "\n                "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.form.successful
          ? _c("div", { staticClass: "alert alert-success pb-3" }, [
              _vm._v(
                "\n                Your information has been updated!\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger pb-3" }, [
              _vm._v(
                "\n                There was an error saving your information. Please tell Kevin.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "pb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "submit",
                disabled: _vm.form.busy || _vm.hasError(),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(" "),
              _vm.plan.id == null
                ? _c("span", [_vm._v("Save")])
                : _c("span", [_vm._v("Update")]),
            ]
          ),
          _vm._v(" "),
          _vm.plan.id != null
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button ms-2" },
                  on: {
                    click: function ($event) {
                      return _vm.resetPlan()
                    },
                  },
                },
                [_vm._v("Cancel")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "ps-3 align-middle pt-0" }, [
          _vm._v("Version Name"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0" }, [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0 d-none d-md-table-cell" }, [
          _vm._v("Created On"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0 d-none d-md-table-cell" }, [
          _vm._v("Created By"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0 d-none d-md-table-cell" }, [
          _vm._v("Updated On"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0 d-none d-md-table-cell" }, [
          _vm._v("Updated By"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0" }, [_vm._v("Actions")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-warning float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Copying...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fa-solid fa-caret-down me-0" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }