var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "initiative-editor client-user" }, [
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Select an Initiative"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.init_pos,
                      expression: "init_pos",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { name: "initiative" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.init_pos = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.resetErrors()
                      },
                    ],
                  },
                },
                _vm._l(_vm.initiatives, function (i, pos) {
                  return _c("option", { domProps: { value: pos } }, [
                    _vm._v(_vm._s(pos == 0 ? "Add a New Initiative" : i.name)),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v(" Initiative Information")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.initiatives[_vm.init_pos].name,
                    expression: "initiatives[init_pos].name",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.name != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "name" },
                domProps: { value: _vm.initiatives[_vm.init_pos].name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.initiatives[_vm.init_pos],
                      "name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.name != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.name) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Strategic Focus"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.initiatives[_vm.init_pos].strategic_focus,
                      expression: "initiatives[init_pos].strategic_focus",
                    },
                  ],
                  staticClass: "form-select",
                  class: _vm.errors.strategic_focus != "" ? "is-invalid" : "",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "strategic_focus",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", [_vm._v("Acquisition")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Analysis & Reporting")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Audience Generation")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Donor Upgrade")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Reactivation")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Retention")]),
                ]
              ),
              _vm._v(" "),
              _vm.errors.strategic_focus != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.strategic_focus) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Result Calculation Type"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.initiatives[_vm.init_pos].calc_type,
                      expression: "initiatives[init_pos].calc_type",
                    },
                  ],
                  staticClass: "form-select",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "calc_type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "one_time" } }, [
                    _vm._v(
                      "One-Time Launch - The start and end date are the same month"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "repeatable" } }, [
                    _vm._v(
                      "Repeatable - Results will be recalculated every month"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "launch_and_automate" } }, [
                    _vm._v(
                      "Launch and Repeat - Project launched to the whole file and is then automated or promoted on the website moving forward."
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group inline-dropdown" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Suggested Costs  "),
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("(\n                          "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle",
                      attrs: {
                        type: "button",
                        id: "costDropdown",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                    },
                    [_vm._v("Add a Cost")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "dropdown-menu",
                      attrs: {
                        role: "menu",
                        "aria-labelledby": "costDropdown",
                      },
                    },
                    _vm._l(_vm.cost_types, function (c) {
                      return _c("li", { attrs: { role: "presentation" } }, [
                        !_vm.isInCosts(c.name)
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  role: "menuitem",
                                  tabindex: "-1",
                                  href: "#",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addCost(c.name, c.type)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(c.name))]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                  _vm._v("\n                        )"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.initiatives[_vm.init_pos].costs.length == 0
                    ? _c("div", { staticClass: "col" }, [_vm._m(0)])
                    : _vm._l(_vm.initiatives[_vm.init_pos].costs, function (c) {
                        return _c("div", { staticClass: "col-md-3 col-sm-6" }, [
                          _c("div", { staticClass: "input-group" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("$"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-floating" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: c.value,
                                    expression: "c.value",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", id: c.token },
                                domProps: { value: c.value },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.isNumber($event)
                                  },
                                  paste: _vm.cleanUpPaste,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(c, "value", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: c.token } }, [
                                _vm._v(_vm._s(c.name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeCost(c.name)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Projection Calculations")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group inline-dropdown" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Custom Variables  "),
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("(\n                          "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle",
                      attrs: {
                        type: "button",
                        id: "inputDropdown",
                        "data-bs-toggle": "dropdown",
                      },
                    },
                    [_vm._v("Add a Variable")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "dropdown-menu",
                      attrs: {
                        role: "menu",
                        "aria-labelledby": "inputDropdown",
                      },
                    },
                    [
                      _c("li", { attrs: { role: "presentation" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              role: "menuitem",
                              tabindex: "-1",
                              href: "#",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addInput("money")
                              },
                            },
                          },
                          [_vm._v("Money")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { attrs: { role: "presentation" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              role: "menuitem",
                              tabindex: "-1",
                              href: "#",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addInput("number")
                              },
                            },
                          },
                          [_vm._v("Number")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { attrs: { role: "presentation" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              role: "menuitem",
                              tabindex: "-1",
                              href: "#",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addInput("percentage")
                              },
                            },
                          },
                          [_vm._v("Percentage")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v("\n                        )"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.initiatives[_vm.init_pos].inputs.length == 0
                  ? _c("div", { staticClass: "col" }, [_vm._m(3)])
                  : _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(
                        _vm.initiatives[_vm.init_pos].inputs,
                        function (i, pos) {
                          return _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c("div", { staticClass: "form-floating mb-3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: i.name,
                                      expression: "i.name",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: i.name },
                                  on: {
                                    change: function ($event) {
                                      return _vm.generateToken(pos, i)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(i, "name", $event.target.value)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", [_vm._v("Name")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c("div", { staticClass: "form-floating mb-3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: i.description,
                                      expression: "i.description",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: i.description },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        i,
                                        "description",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", [_vm._v("Description")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "col-sm-3",
                                class: i.type == "money" ? "has-prefix" : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-3",
                                    class:
                                      i.type != "number" ? "input-group" : "",
                                  },
                                  [
                                    i.type == "money"
                                      ? _c(
                                          "div",
                                          { staticClass: "input-group-text" },
                                          [
                                            _vm._v(
                                              "\n                                                $\n                                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-floating" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: i.value,
                                              expression: "i.value",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: i.value },
                                          on: {
                                            keypress: function ($event) {
                                              return _vm.isNumber($event)
                                            },
                                            paste: _vm.cleanUpPaste,
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                i,
                                                "value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm._m(4, true),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    i.type == "percentage"
                                      ? _c(
                                          "div",
                                          { staticClass: "input-group-text" },
                                          [_vm._v("%")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("div", { staticClass: "form-floating mb-3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: i.token,
                                      expression: "i.token",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text", disabled: "" },
                                  domProps: { value: i.token },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(i, "token", $event.target.value)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", [_vm._v("Formula Token")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-1" }, [
                              _c("div", { staticClass: "mb-3" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeInput(pos)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.initiatives[_vm.init_pos].is_lifecycle,
                    expression: "initiatives[init_pos].is_lifecycle",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", id: "is_lifecycle" },
                domProps: {
                  checked: Array.isArray(
                    _vm.initiatives[_vm.init_pos].is_lifecycle
                  )
                    ? _vm._i(_vm.initiatives[_vm.init_pos].is_lifecycle, null) >
                      -1
                    : _vm.initiatives[_vm.init_pos].is_lifecycle,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.initiatives[_vm.init_pos].is_lifecycle,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.initiatives[_vm.init_pos],
                            "is_lifecycle",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.initiatives[_vm.init_pos],
                            "is_lifecycle",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "is_lifecycle",
                        $$c
                      )
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "is_lifecycle" },
                },
                [_vm._v("Include extended lifecycle variables?")]
              ),
            ]),
            _vm._v(" "),
            _c("br"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Email Formula"),
                ]),
                _vm._v(" "),
                _c("TokenTextfield", {
                  attrs: {
                    selections: _vm.getAllInputs,
                    error: _vm.errors.emails_calc,
                  },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].emails_calc,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "emails_calc",
                        $$v
                      )
                    },
                    expression: "initiatives[init_pos].emails_calc",
                  },
                }),
                _vm._v(" "),
                _vm.errors.emails_calc != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.emails_calc) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Gifts Formula"),
                ]),
                _vm._v(" "),
                _c("TokenTextfield", {
                  attrs: {
                    selections: _vm.getAllInputs,
                    error: _vm.errors.gifts_calc,
                  },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].gifts_calc,
                    callback: function ($$v) {
                      _vm.$set(_vm.initiatives[_vm.init_pos], "gifts_calc", $$v)
                    },
                    expression: "initiatives[init_pos].gifts_calc",
                  },
                }),
                _vm._v(" "),
                _vm.errors.gifts_calc != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.gifts_calc) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("New Donor Formula"),
                ]),
                _vm._v(" "),
                _c("TokenTextfield", {
                  attrs: {
                    selections: _vm.getAllInputs,
                    error: _vm.errors.new_donor_calc,
                  },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].new_donor_calc,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "new_donor_calc",
                        $$v
                      )
                    },
                    expression: "initiatives[init_pos].new_donor_calc",
                  },
                }),
                _vm._v(" "),
                _vm.errors.new_donor_calc != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.new_donor_calc) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Revenue Formula"),
                ]),
                _vm._v(" "),
                _c("TokenTextfield", {
                  attrs: {
                    selections: _vm.getAllInputs,
                    error: _vm.errors.revenue_calc,
                  },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].revenue_calc,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "revenue_calc",
                        $$v
                      )
                    },
                    expression: "initiatives[init_pos].revenue_calc",
                  },
                }),
                _vm._v(" "),
                _vm.errors.revenue_calc != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.revenue_calc) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Monthly Recurring Revenue Formula"),
                ]),
                _vm._v(" "),
                _c("TokenTextfield", {
                  attrs: {
                    selections: _vm.getAllInputs,
                    error: _vm.errors.recurring_revenue_calc,
                  },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].recurring_revenue_calc,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "recurring_revenue_calc",
                        $$v
                      )
                    },
                    expression: "initiatives[init_pos].recurring_revenue_calc",
                  },
                }),
                _vm._v(" "),
                _vm.errors.recurring_revenue_calc != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.recurring_revenue_calc) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Lifetime Value Formula"),
                ]),
                _vm._v(" "),
                _c("TokenTextfield", {
                  attrs: {
                    selections: _vm.getAllInputs,
                    error: _vm.errors.lifetime_value_calc,
                  },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].lifetime_value_calc,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "lifetime_value_calc",
                        $$v
                      )
                    },
                    expression: "initiatives[init_pos].lifetime_value_calc",
                  },
                }),
                _vm._v(" "),
                _vm.errors.lifetime_value_calc != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.lifetime_value_calc) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Roadmap Documentation")]),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _vm._m(6),
                _vm._v(" "),
                _c("vue-editor", {
                  attrs: { editorToolbar: _vm.customToolbar },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].objective,
                    callback: function ($$v) {
                      _vm.$set(_vm.initiatives[_vm.init_pos], "objective", $$v)
                    },
                    expression: "initiatives[init_pos].objective",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _vm._m(7),
                _vm._v(" "),
                _c("vue-editor", {
                  attrs: { editorToolbar: _vm.customToolbar },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].description,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.initiatives[_vm.init_pos],
                        "description",
                        $$v
                      )
                    },
                    expression: "initiatives[init_pos].description",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _vm._m(8),
                _vm._v(" "),
                _c("vue-editor", {
                  attrs: { editorToolbar: _vm.customToolbar },
                  model: {
                    value: _vm.initiatives[_vm.init_pos].at_stake,
                    callback: function ($$v) {
                      _vm.$set(_vm.initiatives[_vm.init_pos], "at_stake", $$v)
                    },
                    expression: "initiatives[init_pos].at_stake",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.successful
          ? _c("div", { staticClass: "alert alert-success" }, [
              _vm._v(
                "\n                The initiative's details have been updated!\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(
                "\n                There was an issue saving this initiative. Check the fields above to get more information.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "submit", disabled: _vm.form.busy },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(
                " " +
                  _vm._s(_vm.init_pos > 0 ? "Update" : "Add Initiative") +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.init_pos > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteInitiative()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-trash" }),
                  _vm._v(" Delete"),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("em", [_vm._v("No costs have been added yet.")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Initiative projections are calculated on a monthly basis. That means, "
      ),
      _c("strong", [
        _vm._v(
          "the formulas below will be run every month that this initiative is active in the annual plan"
        ),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "For one-time initiatives like appeals or reactivation campaigns, this means you will likely want to use the entire available audience in calculations; e.g. "
      ),
      _c("code", [_vm._v("All Subscribers")]),
      _vm._v(" or "),
      _c("code", [_vm._v("All Active Donors")]),
      _vm._v(
        ". For ongoing automations such as a New Subscriber Welcome Series or New Donor Onboarding, you will want to utilize the previous month's data with tokens such as "
      ),
      _c("code", [_vm._v("Last Month's New Subscribers")]),
      _vm._v(" or "),
      _c("code", [_vm._v("Last Month's New Donors")]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("em", [_vm._v("No custom variables have been created.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Default Value "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v("(Optional)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("You can use "),
      _c("code", [_vm._v("{client_name}")]),
      _vm._v(
        " in the descriptions below as placeholders. The tool will automatically replace them when outputting the information for the client."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Strategic Objective"),
      _c("br"),
      _vm._v(" "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v("What is the primary purpose of this initiative?"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Tactical Overview"),
      _c("br"),
      _vm._v(" "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v(
          "Describe all of the tactical steps necessary to fully execute this initiative. Be as verbose as necessary."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Implications of Not Implementing"),
      _c("br"),
      _vm._v(" "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v(
          "What bad things will happen if this initiative is not implemented?"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }