<template>
    <div class="asset-page">
        <h2 class="mt-5">
            Download {{local_assets_type.name}}
        </h2>
        <p>When you fill out the information below, Avid AI will automatically update the tracking for every link in all of the selected assets. It will then insert each email's content into the selected template and combine them into a single zip file. The download will begin immediately once you press the Submit button at the bottom.</p>
        <div class="card box-content">
            <div class="card-body">
                <div class="row" v-if="Object.keys(this.inputs['templates']).length > 1">
                    <div class="col mb-3" >
                        <label class="form-label">Email Template</label>
                        <select class="form-select"  v-model="form_data.template" >
                            <option v-for="(val, key) in inputs['templates']" :value="key">{{val}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="form-label"  :class="((errors.assets != '' )? 'is-invalid':'')">Select the Emails to Include:</label>
                    <div v-for="asset in assets">
                        <div class="checkbox-animated ms-2">
                            <input :id="'checkbox_animated_'+asset.id" type="checkbox" v-model="form_data.assets" :value="asset.id" >
                            <label :for="'checkbox_animated_'+asset.id" ><span class="check"></span><span class="box"></span> {{asset.name}} <a :href="'/campaigns/'+client.url+'/preview/'+asset.id+'/' +form_data.template" target="_blank" v-if="form_data.template != ''">(Preview)</a></label>
                        </div>
                    </div>
                    <div class="text-danger invalid-feedback" v-if="errors.assets != ''">
                        {{errors.assets}}
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label">UTM Campaign</label>
                        <input class="form-control" type="text" v-model="form_data.utm_campaign" name="utm_campaign"  :class="((errors.utm_campaign != '' )? 'is-invalid':'')">
                        <div class="text-danger invalid-feedback" v-if="errors.utm_campaign != ''">
                            {{errors.utm_campaign}}
                        </div>
                    </div>
                    <div class="col mb-3">
                        <label class="form-label">UTM Medium</label>
                        <input class="form-control" type="text" v-model="form_data.utm_medium" name="utm_medium"  :class="((errors.utm_medium != '' )? 'is-invalid':'')">
                        <div class="text-danger invalid-feedback" v-if="errors.utm_medium != ''">
                            {{errors.utm_medium}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label">UTM Source</label>
                        <input class="form-control" type="text" v-model="form_data.utm_source" name="utm_source"  :class="((errors.utm_source != '' )? 'is-invalid':'')">
                        <div class="text-danger invalid-feedback" v-if="errors.utm_source != ''">
                            {{errors.utm_source}}
                        </div>
                    </div>
                    <div class="col mb-3">
                        <label class="form-label">UTM Content <i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="The {email_name} value will get replaced with a URL-friendly version of the individual email's name."></i></label>
                        <input class="form-control" type="text" v-model="form_data.utm_content" name="utm_content">
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label">Other Tracking Codes</label>
                        <input class="form-control" type="text" v-model="form_data.other_tracking" name="other_tracking" >
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        <div class="alert alert-danger mb-3" v-if="has_errors">
                            There was an error generating your email(s). See the messages above.
                        </div>
                        <!-- Update Button -->
                        <div>
                            <button type="submit" class="btn btn-primary" @click="generateEmails()">
                                <i class="fas fa-envelope"></i> Generate Emails
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['client', 'campaign', 'assets', 'inputs'],
        data() {
            return {
              form_data: {
                template: '',
                assets: [],
                utm_campaign: "",
                utm_medium: "email",
                utm_source: "prospecting",
                utm_content: "{email_name}",
                other_tracking: "",
              },
              errors: {
                assets: "",
                utm_campaign: "",
                utm_medium: "",
                utm_source: "",
              },
              has_errors: false,
              local_assets_type: {}
            };
        },
        beforeMount() {
            this.local_assets_type = this.assets[0].asset_type;

            //If there is only one template, just set that to be the value of the template
            if(Object.keys(this.inputs['templates']).length == 1){
                //Get the key from the first element in on object
                let keys = Object.keys(this.inputs['templates']);
                this.form_data.template = keys[0];
            }

            //If the tracking codes have been set before
            for(var i = 0; i < this.assets.length; i++){
                if(this.assets[i].tracking_codes != null){
                    this.form_data.utm_campaign = this.assets[i].tracking_codes['utm_campaign'];
                    this.form_data.utm_medium = this.assets[i].tracking_codes['utm_medium'];
                    this.form_data.utm_source = this.assets[i].tracking_codes['utm_source'];
                    if(this.assets[i].tracking_codes['utm_content'] != null)
                        this.form_data.utm_content = this.assets[i].tracking_codes['utm_content'];
                    else
                        this.form_data.utm_content = "";

                    this.form_data.other_tracking = this.assets[i].tracking_codes['other_tracking'];
                    break;
                }
            }

            //If they haven't been set, set some default values for the utms
            if(this.form_data.utm_campaign == ""){
                this.form_data.utm_campaign = this.formatVariable(this.campaign.name);
            }
        },
        methods: {
            formatVariable(input) {
              // Step 1: Convert to lowercase
              let lowercaseString = input.toLowerCase();

              // Step 2: Replace spaces with underscores
              let underscoredString = lowercaseString.replace(/\s+/g, '_');

              // Step 3: Remove special characters
              let cleanedString = underscoredString.replace(/[^a-z0-9_]/g, '');

              return cleanedString;
            },
            async generateEmails() {
                this.has_errors = false;
                if(this.hasErrors()){
                    this.has_errors = true;
                    return
                }

                // Send a POST request using Axios
                const response = await window.axios.post('/api/campaigns/'+this.client.url+'/deploy/' + this.campaign.url + "/" + this.local_assets_type.id , this.form_data, {
                  responseType: 'blob', // Set the response type to blob
                });

                // Create a Blob from the response data
                const blob = new Blob([response.data], { type: 'application/zip' });

                // Create a URL for the Blob data
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';

                // Set the filename using the download attribute
                a.download = this.getFileName(this.campaign.name) + '.zip'; // Replace 'your-desired-filename' with the desired name

                // Trigger a click event on the anchor to initiate the download
                a.click();

                // Clean up by revoking the Blob URL after the download has started
                window.URL.revokeObjectURL(url);

            },
            getFileName(str) {
              // Convert the string to lowercase
              str = str.toLowerCase();

              // Remove all non-alpha characters using a regular expression
              str = str.replace(/[^a-z0-9\s]/g, '');

              // Replace spaces with underscores
              str = str.replace(/\s+/g, '_');

              return str;
            },

            hasErrors() {
                var has_errors = false;

                if(this.form_data.assets.length == 0){
                    has_errors = true;
                    this.errors.assets = 'Please select at least one email to download.'
                }

                if(this.form_data.utm_campaign == ''){
                    has_errors = true;
                    this.errors.utm_campaign = 'The UTM Campaign is required for proper tracking'
                }
                if(this.form_data.utm_medium == ''){
                    has_errors = true;
                    this.errors.utm_medium = 'The UTM Medium is required for proper tracking'
                }
                if(this.form_data.utm_source == ''){
                    has_errors = true;
                    this.errors.utm_source = 'The UTM Source is required for proper tracking'
                }

                return has_errors;
            }
        }
    }
</script>
