<template>
    <div class="campaign-type-editor">
        <div class="campaign-type-overview pb-5">
        	<h2>{{local_campaign_type.id? 'Update': 'Add'}} Campaign Type</h2>
         
            <div class="card box-content mt-3" >
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-9 col mb-3">
                            <label class="form-label">Name</label>
                            <input type="text" id="name-input" class="form-control" v-model="local_campaign_type.name"
                                :class="((errors.name != '' )? 'is-invalid':'')" @change="clearErrorForField('name')">
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name}}
                            </div>
                        </div>
                        <div class="mb-3 col-sm-3">
                            <label class="form-label">Strategy</label>
                            <div class="dropdown">
                              <button class="btn form-select btn-field" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside"
                                :class="((errors.strategy != '' )? 'is-invalid':'')" @click="clearErrorForField('strategy')">
                                {{ (local_campaign_type.strategy != null && local_campaign_type.strategy.length > 0 ) ? local_campaign_type.strategy.join(", ") : 'Please Select' }}
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                                  <label class="dropdown-item" v-for="item in strategy_types" :key="item">
                                    <input class="form-check-input" type="checkbox" :value="item"  v-model="local_campaign_type.strategy" :true-value="[]">
                                    <span class="ms-2" >
                                      {{ item }}
                                    </span>
                                  </label>
                              </div>
                            </div>
                            <div class="text-danger invalid-feedback" v-if="errors.strategy != ''">
                                {{errors.strategy}}
                            </div>
                        </div>

                        <div class="col-12 mb-3">
                            <label class="form-label">Description</label>
                            <textarea id="report-description" class="form-control full-height" :class="((errors.description != '' )? 'is-invalid':'')"
                            v-model="local_campaign_type.description"  @input="resizeTextarea()" @change="clearErrorForField('description')"></textarea>
                            <div class="text-danger invalid-feedback" v-if="errors.description != ''">
                                {{errors.description}}
                            </div>
                        </div>

                        <div class="col-12">
                            <label class="form-label">Prompts               
                                <button type="submit" class="btn btn-white ms-2 py-0" @click.prevent="addPrompt()" :disabled="form.busy">
                                    <i class="fa fa-btn fa-plus me-0"></i>
                                </button> 
                            </label>
                            <div v-for="(prompt, index) in local_campaign_type.prompts" :key="index" class="mb-3">
                                <div class="row mb-2">
                                    <div class="col-1 text-center mt-1"><h5>{{ index + 1 }}.</h5></div>
                                    <div class="col">
                                        <label class="form-label mb-0">Title:</label>
                                        <input 
                                            type="text" 
                                            id="title-input" 
                                            class="form-control mb-1" 
                                            v-model="prompt.title" 
                                            :class="errors.prompts[index] && errors.prompts[index].title ? 'is-invalid' : ''" 
                                            @change="clearPromptError(index, 'title')" 
                                        >   
                                        <div class="text-danger invalid-feedback" v-if="errors.prompts[index] && errors.prompts[index].title">
                                            {{ errors.prompts[index].title }}
                                        </div>
  

                                        <label class="form-label mb-0">Question:</label>
                                        <textarea 
                                            type="text" 
                                            id="question-input" 
                                            class="form-control" 
                                            v-model="prompt.question"  
                                            @input="resizeTextarea()" 
                                            :class="errors.prompts[index] && errors.prompts[index].question ? 'is-invalid' : ''" 
                                            @change="clearPromptError(index, 'question')"
                                        ></textarea>                                               
                                        <div class="text-danger invalid-feedback" v-if="errors.prompts[index] && errors.prompts[index].question">
                                            {{ errors.prompts[index].question }}
                                        </div>
                                              
                                    </div>
                                    <div class="col-1" v-if="local_campaign_type.prompts.length > 1">
                                        <button type="submit" class="btn btn-none ms-2" @click.prevent="removePrompt(index)" :disabled="form.busy">
                                            <i class="fa fa-close me-0"></i>
                                        </button>                                         
                                    </div>
                                </div>
                            </div>
                        </div>  
					</div>
                </div>
            </div>

            <div>
				<h5 class="mt-4">Asset Types
                    <button type="submit" class="btn btn-white ms-2" id="add-asset-type-button" @click.prevent="addAssetType()" data-bs-toggle="modal" data-bs-target="#asset-type-modal" :disabled="form.busy">
                        <i class="fa fa-btn fa-plus me-0"></i>
                    </button>
                    <!-- Add toggle buttons above the table -->
                    <div class="float-end">
                        <button class="btn me-2" :class="(currentView == 'table')?'btn-primary':'btn-secondary'" @click="currentView = 'table'">
                            <i class="fa fa-table me-0"></i>
                        </button>
                        <button class="btn" :class="(currentView == 'calendar')?'btn-primary':'btn-secondary'" @click="currentView = 'calendar'">
                            <i class="fa fa-calendar me-0"></i>
                        </button>
                    </div>
                </h5>

				<div v-if="local_campaign_type.asset_types && local_campaign_type.asset_types.length === 0">
					<div class="card box-content mb-3">
						<div class="card-body mt-3 text-center">
							<h3 class="text-center">No asset types have been added yet.</h3>
							<button type="submit" class="btn btn-secondary" id="add-asset-type-button" @click.prevent="addAssetType()" data-bs-toggle="modal" data-bs-target="#asset-type-modal" :disabled="form.busy">
								<i class="fa fa-btn fa-plus"></i> Add an Asset Type
							</button>
						</div>
					</div>
				</div>
                <div  v-else>
                    <!-- Conditionally render the table or the calendar view -->
                    <div v-if="currentView === 'table'">
                
                        <div class="card box-content mb-3" :class="((errors.has_primary_asset_type != '' )? 'border-danger border':'')">
                            <div class="card-body p-0">
                                <div class="overflow-hidden rounded-top">
                                    <div class="p-1 bg-dark"></div>
                                </div>
                                <div class="table-responsive mb-2">
                                    <table class="table gy-0 m-0">
                                        <thead class="table-dark">
                                            <tr>
                                                <th class='align-middle text-center pt-0'>Name</th>
                                                <th class="align-middle text-center d-none pt-0 d-sm-table-cell">Type</th>
                                                <th class="align-middle text-center d-none pt-0 d-sm-table-cell">Is Primary Asset Type?</th>
                                                <th class="align-middle text-center d-none pt-0 d-sm-table-cell">Days Before End Date </th>
                                                <th class="align-middle text-center d-none pt-0 d-sm-table-cell">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-border-bottom-0">
                                            <tr v-for="(asset_type, index) in local_campaign_type.asset_types">
                                                <td class="align-middle ps-3">
                                                    <span>{{ asset_type.pivot.name }}</span>
                                                </td>
                                                <td class="align-middle text-center d-none d-sm-table-cell">
                                                    <span>{{ asset_type.name }}</span>
                                                </td>
                                                <td class="align-middle text-center d-none d-sm-table-cell">
                                                    <span>{{ asset_type.pivot.is_primary ? 'Yes': 'No' }}</span>
                                                </td>
                                                <td class="align-middle text-center d-none d-sm-table-cell">
                                                    <span v-if="asset_type.has_launch_date">{{ asset_type.pivot.days_before_end_date }}</span>
                                                    <span v-else>--</span>
                                                </td>
                                                <td class="align-middle text-center d-none d-sm-table-cell text-end">
                                                    <a class="btn btn-secondary btn-sm ms-1 mb-1" @click.prevent="editAssetType(index)" data-bs-toggle="modal" data-bs-target="#asset-type-modal" >
                                                        <i class="fa-solid fa-edit me-0" title="Edit Asset Type" data-bs-toggle="tooltip" ></i>
                                                    </a>
                                                    <button class="btn btn-secondary btn-sm ms-1 mb-1" @click.prevent="deleteAssetType(index)">
                                                        <i class="fa-solid fa-trash me-0"  title="Delete Asset Type" data-bs-toggle="tooltip"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="text-danger invalid-feedback m-3" v-if="errors.has_primary_asset_type != ''">
                                    {{errors.has_primary_asset_type}}
                                </div>
                            </div>       
                        </div>
                    </div>
                    <div v-else-if="currentView === 'calendar'">
                        <div class="card box-content mb-3" >
                            <div class="card-body">
                                <!-- Include the CalendarView component -->
                                <calendar-view
                                    :assetTypes="local_campaign_type.asset_types"
                                    @update-days="updateDaysFromEnd"
                                ></calendar-view>
                            </div>
                        </div>
                    </div>
                </div>
			</div>		

            <div v-if="local_campaign_type.asset_types.length >0" class="row">
                <div class="col-12 navbar navbar-expand">
                    <button type="submit" class="btn btn-primary ms-2" id="save-button" @click.prevent="update" :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> Save 
                    </button>
                    <!-- <button class="btn btn-secondary ms-2" id="add-asset-type-button" @click.prevent="addAssetType" :disabled="form.busy">
                        <i class="fa fa-btn fa-plus"></i> Add Asset Type
                    </button> -->
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="asset-type-modal" aria-labelledby="asset-type-modal" data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" @click.prevent="closeModal('asset-type-modal')">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">{{modal_action}} Asset Type</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 ">
                        <div class='row'>
                            <div class="col-12 mb-3">
                                <label class="form-label">Asset Type</label>
                                <select class="form-select" v-model="modal_asset_type.asset_type_id" :class="((modal_errors.asset_type_id != '' )? 'is-invalid':'')" 
                                @change="changeAssetType()">
                                    <option v-for="(a_t, index) in asset_types" :value="a_t.id">{{a_t.name}}</option>
                                </select>
                                <div class="text-danger invalid-feedback" v-if="modal_errors.asset_type_id != ''">
                                    {{ modal_errors.asset_type_id }}
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class="col mb-3">
                                <label class="form-label">Name</label>
                                <input type="text" id="name-input" class="form-control" v-model="modal_asset_type.name"
                                    :class="((modal_errors.name != '' )? 'is-invalid':'')" @change="clearErrorForModalField('name')">
                                <div class="text-danger invalid-feedback" v-if="modal_errors.name != ''">
                                    {{ modal_errors.name }}
                                </div>
                            </div>

                            <div class="col-4 mb-3" v-if="modal_asset_type.has_launch_date">
                                <label class="form-label">Days Before End Date 
                                    <i class="fas fa-info-circle ms-2" title="How far before the last day should this asset be launched? " data-bs-toggle="tooltip"></i></label>
                                <input type="number" id="days-from-end-date-input" class="form-control" v-model="modal_asset_type.days_before_end_date"
                                    :class="((modal_errors.days_before_end_date != '' )? 'is-invalid':'')" @change="clearErrorForModalField('days_before_end_date')">
                                <div class="text-danger invalid-feedback" v-if="modal_errors.days_before_end_date != ''">
                                    {{ modal_errors.days_before_end_date }}
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class="col-12 mb-3">
                                <label class="form-label">Description</label>
                                <textarea id="report-description" class="form-control full-height" v-model="modal_asset_type.description" ref="textarea" 
                                :class="((modal_errors.description != '' )? 'is-invalid':'')" @change="clearErrorForModalField('description')" @input="resizeTextarea()" ></textarea>
                                <div class="text-danger invalid-feedback" v-if="modal_errors.description != ''">
                                    {{ modal_errors.description }}
                                </div>                       
                            </div>

                            <div class="col-12 mb-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" v-model="modal_asset_type.is_primary" >
                                        Primary Asset Type <i class="fas fa-info-circle ms-2" title="This should be the asset that all others are based upon. There will only be one." data-bs-toggle="tooltip" ></i>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="w-100 d-flex flex-row justify-content-center gap-3">  
                            <button type="submit" class="w-50 btn btn-primary ms-2" id="save-button" @click.prevent="saveAssetType()" :disabled="form.busy">
                                Save 
                            </button>
                            <button class="w-50 btn btn-secondary ms-2" id="add-asset-type-button" @click.prevent="closeModal('asset-type-modal')" :disabled="form.busy">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>   
        </div> 
    </div>
</template>

<script>
import { has } from 'lodash';
import Swal from 'sweetalert2';
import vSelect from "vue-select";
import draggable from 'vuedraggable';

    export default {
        components: { draggable, Swal, vSelect },
        props: {
			campaign_type: {
				type: Object,
				default: {},
			},
			asset_types: {
				type: Array,
				default: [],
			},
		},
        data() {
            return {
                local_campaign_type: {},
                errors: {
                    name: '',
                    description: '',
                    strategy: '',
                    has_primary_asset_type: '',
                    prompts: [] // Added to track errors for each prompt
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false 
                },
                if_changes_made: false,

                modal_action: 'Add',
                modal_asset_type: {},
                modal_asset_type_index: null,
                modal_errors: {
                    asset_type_id: '',
                    name: '',
                    days_before_end_date: '',
                    description: ''
                },
                strategy_types: ['Acquisition', 'Awareness','Reactivation', 'Retention', 'Upgrade'],
                currentView: 'table', // Add this line
            };
        },

        beforeMount() {
            // If editing an existing CampaignType, ensure asset_types are initialized
            this.local_campaign_type = { ...this.campaign_type };

            // If there aren't any prompts, set the array correctly
            if(!this.local_campaign_type.prompts){
                this.local_campaign_type.prompts = [];
                this.addPrompt();
            } else {
                // Initialize errors.prompts array based on existing prompts
                this.errors.prompts = this.local_campaign_type.prompts.map(() => ({
                    title: '',
                    question: ''
                }));
            }

            // Same with strategy
            if(!this.local_campaign_type.strategy){
                this.local_campaign_type.strategy = [];
            }

            if(!this.local_campaign_type.asset_types){
                this.local_campaign_type.asset_types = [];
            } else {
                // Ensure asset_types are in the correct format
                this.local_campaign_type.asset_types = this.local_campaign_type.asset_types.map(asset => ({
                    id: asset.id || asset.asset_type_id,
                    has_launch_date: asset.has_launch_date,
                    name: asset.name,
                    icon_class: asset.icon_class,
                    description: asset.description,
                    pivot: {
                        id: asset.pivot.id,
                        asset_type_id: asset.pivot.asset_type_id,
                        campaign_type_id: asset.pivot.campaign_type_id,
                        description: asset.pivot.description,
                        is_primary: asset.pivot.is_primary,
                        days_before_end_date: asset.pivot.days_before_end_date,
                        name: asset.pivot.name,
                    }
                }));
            }

            this.sortCampaignAssets();
        },
        methods: {
            sortCampaignAssets() {

                // Now sort the assets by days_before_end_date and then by type
                this.local_campaign_type.asset_types.sort((a, b) => {
                    //Put those with has_launch_date as fales at the beginning
                    if(a.has_launch_date && !b.has_launch_date){
                        return 1;
                    } else if(!a.has_launch_date && b.has_launch_date){
                        return -1;
                    }
                    if(a.pivot.days_before_end_date === b.pivot.days_before_end_date){
                        return a.name.localeCompare(b.name);
                    }
                    return b.pivot.days_before_end_date - a.pivot.days_before_end_date;
                });
            },
            changeAssetType(){
                this.modal_asset_type.has_launch_date = this.asset_types.find(a_t => a_t.id === this.modal_asset_type.asset_type_id).has_launch_date;
            },  
            addPrompt(){
                this.local_campaign_type.prompts.push({
                    title: '',
                    question: '',
                    strategy: []
                });
                this.errors.prompts.push({
                    title: '',
                    question: ''
                });
            },
            removePrompt(index){
                this.local_campaign_type.prompts.splice(index,1);
                this.errors.prompts.splice(index,1);
            },

            addAssetType(){
                this.modal_action = 'Add';
                this.modal_asset_type = {
                    //Make a random negative number
                    id: Math.round(Math.random()*-10000),
                    campaign_type_id: this.local_campaign_type.id,
                    has_launch_date: false,
                    asset_type_id: null,
                    is_primary: this.local_campaign_type.asset_types.length == 0,
                    name: '',
                    description: '',
                    days_before_end_date: 0
                };
            },
            editAssetType(index){
                this.modal_action = 'Update';
                this.modal_asset_type_index = index;
                let asset_type = this.local_campaign_type.asset_types[index];
                this.modal_asset_type = {
                    id: asset_type.pivot.id,
                    campaign_type_id: asset_type.pivot.campaign_type_id,
                    has_launch_date: asset_type.has_launch_date,
                    asset_type_id: asset_type.pivot.asset_type_id,
                    is_primary: asset_type.pivot.is_primary,
                    name: asset_type.pivot.name,
                    description: asset_type.pivot.description,
                    days_before_end_date: asset_type.pivot.days_before_end_date
                };
            },
            deleteAssetType(index){
                Swal.fire({
                  title: "Are you sure you want to delete the Asset Type?",
                  text: "Once saving the current Campaign Type, this Asset Type will be removed from it and all Campaign associated with it.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.local_campaign_type.asset_types.splice(index, 1);
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Asset Type Deleted!</h4>',
                            target: '.campaign-type-builder',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });
                    }
                });
            },  
            saveAssetType(){
                var self = this

                this.clearModalErrors();
                if(this.hasModalErrors()) {
                    Swal.fire({
                        title: "Error Saving Asset Type",
                        text: "There were some issues saving this asset type. Please review and make the suggested changes.",
                        icon: "warning",
                        iconColor: "#D11F1F",
                        confirmButtonColor: "#D11F1F",
                        confirmButtonText: "View Errors",
                    });
                    return;
                }

                this.form.busy = true;

                //If the asset type is primary, make sure all others are not
                if(this.modal_asset_type.is_primary){
                    this.local_campaign_type.asset_types.forEach((asset, index) => {
                        if(index != this.modal_asset_type_index){
                            asset.pivot.is_primary = false;
                        }
                    });
                }

                if(this.modal_action === 'Add'){
                    const asset_type = this.asset_types.find(a_t => a_t.id === this.modal_asset_type.asset_type_id);
                    if(asset_type){
                        this.local_campaign_type.asset_types.push({
                            id: asset_type.id,
                            has_launch_date: asset_type.has_launch_date,
                            name: asset_type.name,
                            icon_class: asset_type.icon_class,
                            description: asset_type.description,
                            pivot: {
                                id: this.modal_asset_type.is_primary,
                                asset_type_id: asset_type.id,
                                campaign_type_id: this.local_campaign_type.id,
                                description: this.modal_asset_type.description,
                                is_primary: this.modal_asset_type.is_primary,
                                days_before_end_date: this.modal_asset_type.days_before_end_date,
                                name: this.modal_asset_type.name,
                            }
                        });
                        Swal.fire({
                            title: "Success",
                            text: "Asset Type added successfully.",
                            icon: "success",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        this.closeModal('asset-type-modal');
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Selected Asset Type not found.",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    }
                    this.form.busy = false;
                } else { // Update existing AssetType
                    const assetIndex = this.modal_asset_type_index;
                    const asset_type = this.asset_types.find(a_t => a_t.id === this.modal_asset_type.asset_type_id);
                    if(asset_type && assetIndex !== null){
                        this.local_campaign_type.asset_types[assetIndex] = {
                            id: asset_type.id,
                            name: asset_type.name,
                            has_launch_date: asset_type.has_launch_date,
                            icon_class: asset_type.icon_class,
                            description: asset_type.description,
                            pivot: {
                                id: this.modal_asset_type.id,
                                asset_type_id: asset_type.id,
                                campaign_type_id: this.local_campaign_type.id,
                                description: this.modal_asset_type.description,
                                is_primary: this.modal_asset_type.is_primary,
                                days_before_end_date: this.modal_asset_type.days_before_end_date,
                                name: this.modal_asset_type.name,
                            }
                        };
                        Swal.fire({
                            title: "Success",
                            text: "Asset Type updated successfully.",
                            icon: "success",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        this.closeModal('asset-type-modal');
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Asset Type update failed. Please try again.",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    }
                    this.form.busy = false;
                }

                //Now sort the assets by days_before_end_date and then by type
                this.sortCampaignAssets();
            },
  
            clearErrors() { //reset errors to empty strings
                this.errors = {
                    name: '',
                    description: '',
                    strategy: '',
                    prompts: [],
                    has_primary_asset_type: '',
                }
            },
            clearErrorForField(field_name){
                this.errors[field_name] = '';
            },     
            clearErrorForModalField(field_name){
                this.modal_errors[field_name] = '';
            },     
            clearModalErrors() { //reset errors to empty strings
                this.modal_errors = {
                    asset_type_id: '',
                    name: '',
                    description: '',
                    days_before_end_date: ''
                };
            }, 
            resizeTextarea() {
                var elements = document.querySelectorAll('textarea');
                for(let i = 0; i < elements.length; i++){
                    let element = elements[i];
                    if (element && element.style){
                        element.style.height = '';
                        element.style.height = Math.max(element.offsetHeight, element.scrollHeight) + "px";
                    }           
                }        
            },
            hasModalErrors(){
                // Reset previous errors
                this.modal_errors = {
                    asset_type_id: '',
                    name: '',
                    description: '',
                    days_before_end_date: ''
                };

                // Validate asset_type_id
                if(!this.modal_asset_type.asset_type_id){
                    this.modal_errors.asset_type_id = "Asset type selection is required.";
                }

                // Validate name
                if(!this.modal_asset_type.name){
                    this.modal_errors.name = "A name is required.";
                }

                // Validate description
                if(!this.modal_asset_type.description){
                    this.modal_errors.description = "A description is required.";
                }

                // Validate days_before_end_date
                if(this.modal_asset_type.days_before_end_date === null || this.modal_asset_type.days_before_end_date === undefined){
                    this.modal_errors.days_before_end_date = "You must enter the number of days from the end date (0 would be the last day).";
                } else if(!Number.isInteger(Number(this.modal_asset_type.days_before_end_date)) || Number(this.modal_asset_type.days_before_end_date) < 0){
                    this.modal_errors.days_before_end_date = "Days from end date must be a non-negative integer.";
                }

                // Return true if any errors exist
                return Object.values(this.modal_errors).some(error => error !== "");
            },
            clearPromptError(index, field){
                if(this.errors.prompts[index]){
                    this.errors.prompts[index][field] = '';
                }
            },

            hasErrors() {
                // Error checking for overview section
                if(!this.local_campaign_type.name){
                    this.errors.name = "A name is required.";
                } else {
                    this.errors.name = "";
                }

                if(!this.local_campaign_type.description){
                    this.errors.description = "A description is required.";
                } else {
                    this.errors.description = "";
                }

                if(!this.local_campaign_type.strategy || this.local_campaign_type.strategy.length === 0){
                    this.errors.strategy = "At least one strategy is required.";
                } else {
                    this.errors.strategy = "";
                }

                // Check for at least one primary asset type
                const hasPrimary = this.local_campaign_type.asset_types.some(asset => asset.pivot.is_primary);
                if(!hasPrimary){
                    this.errors.has_primary_asset_type = "Must have one primary asset type.";
                } else {
                    this.errors.has_primary_asset_type = "";
                }

                // Validate each prompt's title and question
                this.local_campaign_type.prompts.forEach((prompt, index) => {
                    // Initialize errors for this prompt if not already
                    if(!this.errors.prompts[index]){
                        this.$set(this.errors.prompts, index, { title: '', question: '' });
                    }

                    // Validate Title
                    if(!prompt.title || prompt.title.trim() === ""){
                        this.errors.prompts[index].title = "Title is required.";
                    } else {
                        this.errors.prompts[index].title = "";
                    }

                    // Validate Question
                    if(!prompt.question || prompt.question.trim() === ""){
                        this.errors.prompts[index].question = "Question is required.";
                    } else {
                        this.errors.prompts[index].question = "";
                    }
                });

                // Return true if any errors exist
                const overviewErrors = Object.values(this.errors).filter(key => key !== this.errors.prompts);
                const promptErrors = this.errors.prompts.some(promptError => promptError.title !== "" || promptError.question !== "");
                return overviewErrors.some(error => error !== "") || promptErrors;
            },


            checkIfHasPrimaryAssetType(section){
                for(var i = 0; i < section.charts.length; i++) {
                    if (section.charts[i].is_primary) {
                        return true;
                    }
                }
                return false;
            },
            resizeTextareaExpanding(section_index) {
                if(this.local_report_page.sections[section_index].if_active == true){
                    var element = document.querySelectorAll('textarea');

                    for(var index = 0; index<element.length; index++){
                        if (element[index] && element[index].style){
                            element[index].style.height = '';
                            element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                        }                            
                    }                    
                }
            },
            update(){
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    Swal.fire({
                        title: "Error Saving Campaign Type",
                        text: "There were some issues saving this campaign type. Please review and make the suggested changes.",
                        icon: "warning",
                        iconColor: "#D11F1F",
                        confirmButtonColor: "#D11F1F",
                        confirmButtonText: "View Errors",
                    });
                    return;
                }

                this.form.busy = true;

                // Prepare the payload to match the API's expected structure
                const payload = {
                    name: this.local_campaign_type.name,
                    description: this.local_campaign_type.description,
                    prompts: this.local_campaign_type.prompts,
                    strategy: this.local_campaign_type.strategy,
                    asset_types: this.local_campaign_type.asset_types.map(asset => ({
                        asset_type_id: asset.id || asset.asset_type_id, // Adjust based on how asset_type_id is stored
                        is_primary: asset.pivot.is_primary,
                        name: asset.pivot.name,
                        description: asset.pivot.description,
                        days_before_end_date: asset.pivot.days_before_end_date,
                    }))
                };

                if(this.local_campaign_type.id == null){
                    // Create a new CampaignType
                    window.axios.post('/api/campaigns/campaign_type', payload)
                        .then(response => {
                            this.local_campaign_type = response.data.data; // Updated based on API response structure
                            this.if_changes_made = false;
                            
                            if(!this.local_campaign_type.asset_types){
                                this.local_campaign_type.asset_types = [];
                            }
                            this.form.busy = false;
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: "The campaign type has been created successfully!",
                                iconColor: "#46705F",
                                confirmButtonColor: "#46705F",
                                confirmButtonText: 'OK',
                            }).then(() => {
                                // Redirect to the admin list page
                                window.location.href = '/campaigns/admin/list';
                            });
                        })
                        .catch(error => {
                            this.form.busy = false;
                            if (error.response && error.response.status === 422) {
                                // Handle validation errors
                                this.errors = error.response.data.errors;
                                Swal.fire({
                                    title: "Validation Error",
                                    text: "Please correct the highlighted errors and try again.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                            } else {
                                // Handle other errors
                                this.form.error = true;
                                Swal.fire({
                                    title: "Error",
                                    text: "An unexpected error occurred while creating the Campaign Type.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                            }
                        });
                } else{
                    // Update existing CampaignType
                    window.axios.put(`/api/campaigns/campaign_type/${this.local_campaign_type.id}`, payload)
                        .then(response => {
                            this.local_campaign_type = response.data.data; // Updated based on API response structure
                            this.if_changes_made = false;
                            this.form.busy = false;
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: "The campaign type has been updated successfully!",
                                iconColor: "#46705F",
                                confirmButtonColor: "#46705F",
                                confirmButtonText: 'OK',
                            }).then(() => {
                                // Redirect to the admin list page
                                window.location.href = '/campaigns/admin/list';
                            });
                        })
                        .catch(error => {
                            this.form.busy = false;
                            if (error.response && error.response.status === 422) {
                                // Handle validation errors
                                this.errors = error.response.data.errors;
                                Swal.fire({
                                    title: "Validation Error",
                                    text: "Please correct the highlighted errors and try again.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                            } else {
                                // Handle other errors
                                this.form.error = true;
                                Swal.fire({
                                    title: "Error",
                                    text: "An unexpected error occurred while updating the Campaign Type.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                            }
                        });
                }
            },

            closeModal(modal_ID){
                var myModalEl = document.getElementById(modal_ID)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
            updateDaysFromEnd(updatedAssetTypes) {
                // Update local_campaign_type.asset_types with new days_before_end_date values
                this.local_campaign_type.asset_types.forEach(asset => {
                    const updatedAsset = updatedAssetTypes.find(a => a.pivot.id === asset.pivot.id);
                    if (updatedAsset) {
                        asset.pivot.days_before_end_date = updatedAsset.pivot.days_before_end_date;
                    }
                });
                this.sortCampaignAssets();
            },
        }
    }
</script>

  