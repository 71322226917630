<template>
    <div class="assumptions">
        <div class="alert alert-warning mb-3 box-content" v-if="plan.is_published_plan == 1">
            The plan being view is currently the <em>published plan</em>. This means you are not able to make any adjustments to the assumptions or initatives. If you'd like to make any updates, <a :href="'/planning/' + client.url + '/versions'">you will need to make a new version. You can do that here.</a>
        </div>

        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Assumptions for <em v-html="plan.name"></em></h4>
                <p>The data shown in the <em>Actual</em> fields are pulled from the transaction data. The data can be filtered using the filters below. To change the dataset, you will need to update the client information in the organization's Profile page.</p>
                <div class="row mb-3">
                    <div class="col-sm-5">
                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">Starting Month of Plan</label>
                            <datepicker v-model="plan.start_month" minimum-view="month" :use-utc="true"
                                format="MMM yyyy" input-class="form-control" :disabled="(plan.is_published_plan==1)" ></datepicker>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <label class="form-label mb-0">Average Gift</label>
                        <div class="row ">
                            <div class="col-sm-6 ">
                                <!-- Name -->
                                <label class="floating_label">Override</label>
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="plan.avg_gift"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @paste="stripNonNumbers($event)">
                                </div>

                            </div>
                            <div class="col-sm-6 ">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="actuals.avg_gift" disabled>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">

                    <div class="col-sm-5">
                        <label class="form-label mb-0">Average New Donor Gift</label>
                        <div class="row">
                            <div class="col-sm-6 ">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="plan.new_donor_avg_gift"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                          @change="stripNonNumbers()">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="actuals.new_donor_avg_gift" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <label class="form-label mb-0">Average Recurring Gift</label>
                        <div class="row">
                            <div class="col-sm-6 ">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="plan.recur_avg_gift"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()">
                                </div>
                            </div>
                            <div class="col-sm-6 ">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control"
                                        v-model="actuals.recur_avg_gift" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm-5">
                        <label class="form-label mb-0">New Subscriber Annual Attrition</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <input type="text" class="form-control" v-model="plan.new_subscriber_attrition"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()" style="text-align: right;">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <!-- Name -->
                                <label class="floating_label">Actual</label>
                                <div class="mb-3 input-group">
                                    <input type="text" class="form-control" v-model="actuals.new_subscriber_attrition" disabled style="text-align: right;">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <label class="form-label mb-0">Existing Subscriber Annual Attrition</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <input type="text" class="form-control" v-model="plan.existing_subscriber_attrition"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()" style="text-align: right;">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <input type="text" class="form-control" v-model="actuals.existing_subscriber_attrition" disabled style="text-align: right;">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-5">

                        <label class="form-label mb-0">Active Email File Size</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="plan.starting_email_size"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="actuals.starting_email_size" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <label class="form-label mb-0">Previous Year's Donors with Emails</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="plan.existing_donors"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="actuals.existing_donors" disabled="true">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-5">
                        <label class="form-label mb-0">Lifetime Value</label>
                        <div class="row">
                            <div class="col-sm-6 ">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="plan.lifetime_value"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()">
                                </div>
                            </div>
                            <div class="col-sm-6 ">
                                <label class="floating_label">Actual</label>
                                <!-- Name -->
                                <div class="mb-3 input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="actuals.lifetime_value" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <label class="form-label mb-0">Donor Retention</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="floating_label">Override</label>
                                <!-- Name -->
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="plan.donor_retention"
                                         :disabled="(plan.is_published_plan==1)" @keypress="isNumber($event)"
                                         @change="stripNonNumbers()">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <!-- Name -->
                                <label class="floating_label">Actual</label>
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="actuals.donor_retention" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-5">
                        <div class="row">
                            <div class="col-6">
                                <label class="form-label">Avg. Rev. per Donor:</label>
                                <em>{{actuals.rev_per_donor | currency}}</em>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Attrition Rate:</label>
                                <em>{{actuals.attrition_rate | percentage}}</em>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <label class="form-label mb-0">Annual Recurring Donor Attrition</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="floating_label">Override</label>
                                <div class="mb-3 input-group">
                                    <input type="text" class="form-control"
                                        v-model="plan.recurring_attrition" @keypress="isNumber($event)"
                                         :disabled="(plan.is_published_plan==1)"
                                         @change="stripNonNumbers()" style="text-align: right;">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <!-- Name -->
                                <label class="floating_label">Actual</label>
                                <div class="mb-3 input-group">
                                    <input type="text" class="form-control"
                                        v-model="actuals.recurring_attrition" disabled
                                         @change="stripNonNumbers()" style="text-align: right;">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Data Inclusion Filter</h4>
                <query-inclusion-builder :client="client" v-model="plan.data_filter"
                    :in_card="true"  :add_blank="false" ref="filter" ></query-inclusion-builder>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Success Message -->
                <div class="alert alert-success mb-3" v-if="form.successful">
                    Your information has been updated!
                </div>
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an error saving your information. Please make the corrections noted above.
                </div>
                <!-- Update Button -->
                <div class="mb-3">
                    <button type="submit" class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy || (plan.is_published_plan==1)" >
                        <i class="fa fa-btn fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-secondary" style="ms-2"
                            @click.prevent="getManualActuals"
                            :disabled="form.busy || (plan.is_published_plan==1)">
                        <i class="fas fa-sync"></i> Refresh Stats
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    export default {
        props: ['plan', 'client'],
        components: {
            Datepicker
        },
        data() {
            return {
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                actuals :{
                    avg_gift: "Loading...",
                    new_donor_avg_gift: "Loading...",
                    recur_avg_gift: "Loading...",
                    lifetime_value: "Loading...",
                    starting_email_size: "Loading...",
                    new_subscriber_attrition: "Loading...",
                    existing_subscriber_attrition: "Loading...",
                    donor_retention: "Loading...",
                    existing_donors: "Loading...",
                    attrition_rate: "Loading...",
                    rev_per_donor: "Loading...",
                    recurring_attrition: "Loading..."
                }
            };
        },
        beforeMount() {
            //Change the format of the percentages (I'll change it back before saving)
            this.plan.avg_gift = this.formatMoney(this.plan.avg_gift);
            this.plan.new_donor_avg_gift = this.formatMoney(this.plan.new_donor_avg_gift);
            this.plan.recur_avg_gift = this.formatMoney(this.plan.recur_avg_gift);
            this.plan.lifetime_value = this.formatMoney(this.plan.lifetime_value);
            this.plan.starting_email_size = this.formatNumber(this.plan.starting_email_size);
            this.plan.existing_donors = this.formatNumber(this.plan.existing_donors);
            this.plan.new_subscriber_attrition = this.formatNumber(this.plan.new_subscriber_attrition*100);
            this.plan.existing_subscriber_attrition = this.formatNumber(this.plan.existing_subscriber_attrition*100);
            this.plan.donor_retention = this.formatNumber(this.plan.donor_retention*100);
            this.plan.recurring_attrition = this.formatNumber(this.plan.recurring_attrition*100);

        },
        mounted() {
            this.getActuals();
        },

        methods: {
            stripNonNumbers(e) {
                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                var paste = (e.clipboardData || window.clipboardData).getData('text/plain')

                paste = paste.replace(/[^\d.-]/g, '');

                if (document.queryCommandSupported("insertText")) {
                  document.execCommand("insertText", false, paste);
                } else {
                  document.execCommand("paste", false, paste);
                }
            },
            formatMoney(value) {
                if(value == null) return 0;
                if (value === undefined || value == null || value == '')
                    return "0.00";
                var parts = value.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if(parts[1] != undefined && parts[1].length > 2) {
                    parts[1] = parts[1].substr(0,2);
                    if(parts[1].length == 1) parts[1] = parts[1] + "0";
                }
                else if(parts[1] == undefined)
                    parts[0] =  parts[0] + ".00";
                return parts.join(".");
            },
            formatNumber: function (value) {
                if (value === undefined || value == '')
                    return 0;
                var parts = value.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if(parts.length > 1)
                    parts[1] = (parts[1].length > 2 ? parts[1].substring(0,2) : parts[1]);
                return parts.join(".");
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            getManualActuals() {
                if(!this.$refs.filter.isFormValid()){
                    this.form.error = true;
                    this.form.busy = false;
                    return;
                }

                this.form.busy = true;
                this.actuals.avg_gift = "Loading...";
                this.actuals.new_donor_avg_gift = "Loading...";
                this.actuals.recur_avg_gift = "Loading...";
                this.actuals.lifetime_value = "Loading...";
                this.actuals.starting_email_size = "Loading...";
                this.actuals.new_subscriber_attrition = "Loading...";
                this.actuals.existing_subscriber_attrition = "Loading...";
                this.actuals.donor_retention = "Loading...";
                this.actuals.existing_donors = "Loading...";
                this.actuals.attrition_rate = "Loading...";
                this.actuals.rev_per_donor = "Loading...";
                this.actuals.recurring_attrition = "Loading...";
                this.getActuals();
            },
            getActuals() {
                if(!this.$refs.filter.isFormValid()){
                    this.form.error = true;
                    this.form.busy = false;
                    return;
                }

                var self = this;
                var data = {
                  client: this.client,
                  data_filter: this.plan.data_filter
                };

                window.axios.post('/api/bigquery/get_actuals', data)
                  .then(response => {
                    if(response.status == 200) {
                        if(!isNaN(response.data.data.avg_gift))
                            self.actuals.avg_gift = this.formatMoney(response.data.data.avg_gift);
                        else
                            self.actuals.avg_gift = response.data.data.avg_gift;

                        if(!isNaN(response.data.data.new_donor_avg_gift))
                            self.actuals.new_donor_avg_gift = this.formatMoney(response.data.data.new_donor_avg_gift);
                        else
                            self.actuals.new_donor_avg_gift = response.data.data.new_donor_avg_gift;

                        if(!isNaN(response.data.data.recur_avg_gift))
                            self.actuals.recur_avg_gift = this.formatMoney(response.data.data.recur_avg_gift);
                        else
                            self.actuals.recur_avg_gift = response.data.data.recur_avg_gift;

                        if(!isNaN(response.data.data.lifetime_value))
                            self.actuals.lifetime_value =this.formatMoney(response.data.data.lifetime_value);
                        else
                            self.actuals.lifetime_value =response.data.data.lifetime_value;

                        if(!isNaN(response.data.data.num_active))
                            self.actuals.starting_email_size = this.formatNumber(response.data.data.num_active);
                        else
                            self.actuals.starting_email_size = response.data.data.num_active;

                        if(!isNaN(response.data.data.existing_donors))
                            self.actuals.existing_donors = this.formatNumber(response.data.data.existing_donors);
                        else
                            self.actuals.existing_donors = response.data.data.existing_donors;

                        if(!isNaN(response.data.data.new_subscriber_attrition))
                            self.actuals.new_subscriber_attrition = this.formatNumber(parseFloat(response.data.data.new_subscriber_attrition*100));
                        else
                            self.actuals.new_subscriber_attrition = response.data.data.new_subscriber_attrition;

                        if(!isNaN(response.data.data.existing_subscriber_attrition))
                            self.actuals.existing_subscriber_attrition = this.formatNumber(parseFloat(response.data.data.existing_subscriber_attrition*100));
                        else
                            self.actuals.existing_subscriber_attrition = response.data.data.existing_subscriber_attrition;

                        if(!isNaN(response.data.data.donor_retention))
                            self.actuals.donor_retention = this.formatNumber(parseFloat(response.data.data.donor_retention*100));
                        else
                            self.actuals.donor_retention = response.data.data.donor_retention;

                        if(!isNaN(response.data.data.rev_per_donor))
                            self.actuals.rev_per_donor = this.formatNumber(parseFloat(response.data.data.rev_per_donor));
                        else
                            self.actuals.rev_per_donor = response.data.data.rev_per_donor;

                        if(!isNaN(response.data.data.attrition_rate))
                            self.actuals.attrition_rate = this.formatNumber(parseFloat(response.data.data.attrition_rate));
                        else
                            self.actuals.attrition_rate = response.data.data.attrition_rate;

                        if(!isNaN(response.data.data.recurring_attrition))
                            self.actuals.recurring_attrition = this.formatNumber(parseFloat(response.data.data.recurring_attrition*100));
                        else
                            self.actuals.recurring_attrition = response.data.data.recurring_attrition;
                    }
                    else {
                        self.actuals.avg_gift = "Failed";
                        self.actuals.new_donor_avg_gift = "Failed";
                        self.actuals.recur_avg_gift = "Failed";
                        self.actuals.lifetime_value = "Failed";
                        self.actuals.starting_email_size = "Failed";
                        self.actuals.new_subscriber_attrition = "Failed";
                        self.actuals.existing_subscriber_attrition = "Failed";
                        self.actuals.donor_retention = "Failed";
                        self.actuals.existing_donors = "Failed";
                        self.actuals.rev_per_donor = "Failed";
                        self.actuals.attrition_rate = "Failed";
                        self.actuals.recurring_attrition = "Failed";
                    }
                    self.form.busy = false;
                  });
            },

            update() {

                if(!this.$refs.filter.isFormValid()) {
                    this.form.error = true;
                    this.form.busy = false;
                    return;
                }

                if(this.plan.avg_gift == "") this.plan.avg_gift = 0;
                else this.plan.avg_gift = parseFloat((""+this.plan.avg_gift).replace(/\,/g,''));
                if(this.plan.new_donor_avg_gift == "") this.plan.new_donor_avg_gift = 0;
                else this.plan.new_donor_avg_gift = parseFloat((""+this.plan.new_donor_avg_gift).replace(/\,/g,''));
                if(this.plan.recur_avg_gift == "") this.plan.recur_avg_gift = 0;
                else this.plan.recur_avg_gift = parseFloat((""+this.plan.recur_avg_gift).replace(/\,/g,''));
                if(this.plan.lifetime_value == "") this.plan.lifetime_value = 0;
                else this.plan.lifetime_value = parseFloat((""+this.plan.lifetime_value).replace(/\,/g,''));
                if(this.plan.starting_email_size == "") this.plan.starting_email_size = 0;
                else this.plan.starting_email_size = parseInt((""+this.plan.starting_email_size).replace(/\,/g,''));
                if(this.plan.existing_donors == "") this.plan.existing_donors = 0;
                else this.plan.existing_donors = parseInt((""+this.plan.existing_donors).replace(/\,/g,''));
                if(this.plan.new_subscriber_attrition == "")
                    this.plan.new_subscriber_attrition = 0;
                else this.plan.new_subscriber_attrition = parseFloat((""+this.plan.new_subscriber_attrition).replace(/\,/g,''))/100;
                if(this.plan.existing_subscriber_attrition == "")
                    this.plan.existing_subscriber_attrition = 0;
                else this.plan.existing_subscriber_attrition = parseFloat((""+this.plan.existing_subscriber_attrition).replace(/\,/g,''))/100;

                if(this.plan.donor_retention == "")
                    this.plan.donor_retention = 0;
                else this.plan.donor_retention = parseFloat((""+this.plan.donor_retention).replace(/\,/g,''))/100;


                if(this.plan.recurring_attrition == "")
                    this.plan.recurring_attrition = 0;
                else this.plan.recurring_attrition = parseFloat((""+this.plan.recurring_attrition).replace(/\,/g,''))/100;

                var self = this;
                this.form.busy = true;
                window.axios.put('/api/annual_plan/' + this.plan.id, this.plan)
                  .then(response => {
                    self.form.busy = false;
                    if(response.status == 200) {
                        self.form.successful = true;
                        setTimeout( function() {self.form.successful = false} , 5000);
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                  });

                this.plan.avg_gift = this.formatMoney(this.plan.avg_gift);
                this.plan.new_donor_avg_gift = this.formatMoney(this.plan.new_donor_avg_gift);
                this.plan.recur_avg_gift = this.formatMoney(this.plan.recur_avg_gift);
                this.plan.lifetime_value = this.formatMoney(this.plan.lifetime_value);
                this.plan.starting_email_size = this.formatNumber(this.plan.starting_email_size);
                this.plan.existing_donors = this.formatNumber(this.plan.existing_donors);
                this.plan.new_subscriber_attrition = this.formatNumber(this.plan.new_subscriber_attrition*100);
                this.plan.existing_subscriber_attrition = this.formatNumber(this.plan.existing_subscriber_attrition*100);
                this.plan.donor_retention = this.formatNumber(this.plan.donor_retention*100);
                this.plan.recurring_attrition = this.formatNumber(this.plan.recurring_attrition*100);

            }
        }
    }
</script>
