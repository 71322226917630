var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-builder" }, [
    _c("div", { staticClass: "report-builder-overview" }, [
      !_vm.is_editing_report_overview
        ? _c("h2", { staticClass: "align-baseline" }, [
            _vm._v(_vm._s(_vm.local_report_page.name) + "\n            "),
            _c(
              "a",
              {
                staticClass: "fs-6",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.is_editing_report_overview = true
                  },
                },
              },
              [_vm._v("(Edit Report Details)")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", {}, [
        _vm._v("Report Client Name: "),
        _c("code", [_vm._v(_vm._s(_vm.local_client.name))]),
      ]),
      _vm._v(" "),
      _vm.is_editing_report_overview
        ? _c("div", { staticClass: "card box-content mt-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Report Name"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_report_page.name,
                        expression: "local_report_page.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors.name != "" ? "is-invalid" : "",
                    attrs: { type: "text", id: "name-input" },
                    domProps: { value: _vm.local_report_page.name },
                    on: {
                      change: function ($event) {
                        return _vm.clearErrorForField("name")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.local_report_page,
                          "name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.name != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.name) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.report_page.id == null
                  ? _c("div", { staticClass: "col-sm-3 mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Client / Template"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_report_page.client_id,
                              expression: "local_report_page.client_id",
                            },
                          ],
                          staticClass: "form-select",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.local_report_page,
                                  "client_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeClientSelection()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.clients, function (c) {
                          return _c("option", { domProps: { value: c.id } }, [
                            _vm._v(_vm._s(c.name)),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.local_report_page.client_id > 0
                  ? _c("div", { staticClass: "col-sm-3 mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Report Status"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_report_page.status,
                              expression: "local_report_page.status",
                            },
                          ],
                          staticClass: "form-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.local_report_page,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "active" } }, [
                            _vm._v("Active"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "archived" } }, [
                            _vm._v("Archived"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "draft" } }, [
                            _vm._v("Draft"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3 mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("User Visibility"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_report_page.visibility,
                          expression: "local_report_page.visibility",
                        },
                      ],
                      staticClass: "form-select",
                      class: _vm.errors.visibility != "" ? "is-invalid" : "",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_report_page,
                              "visibility",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.clearErrorForField("visibility")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "user" } }, [
                        _vm._v("All Users"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "agency" } }, [
                        _vm._v("Only Agency Users"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "admin" } }, [
                        _vm._v("Only Avid Admins"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errors.visibility != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.visibility) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Group Name"),
                  ]),
                  _vm._v(" "),
                  _vm.group_names.length == 0
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_report_page.group_name,
                            expression: "local_report_page.group_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.errors.group_name != "" ? "is-invalid" : "",
                        attrs: { type: "text", id: "group-input" },
                        domProps: { value: _vm.local_report_page.group_name },
                        on: {
                          change: function ($event) {
                            return _vm.clearErrorForField("group_name")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local_report_page,
                              "group_name",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _c("div", { staticClass: "input-group" }, [
                        _vm.is_new_group_name
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.local_report_page.group_name,
                                  expression: "local_report_page.group_name",
                                },
                              ],
                              staticClass: "form-control",
                              class:
                                _vm.errors.group_name != "" ? "is-invalid" : "",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.local_report_page.group_name,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.clearErrorForField("group_name")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.local_report_page,
                                    "group_name",
                                    $event.target.value
                                  )
                                },
                              },
                            })
                          : _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.local_report_page.group_name,
                                    expression: "local_report_page.group_name",
                                  },
                                ],
                                staticClass: "form-select",
                                class:
                                  _vm.errors.group_name != ""
                                    ? "is-invalid"
                                    : "",
                                attrs: { id: "group-select" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.local_report_page,
                                        "group_name",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.clearErrorForField(
                                        "group_name"
                                      )
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "",
                                      disabled: "",
                                      selected: "",
                                    },
                                  },
                                  [_vm._v("Select the group name...")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.group_names, function (group) {
                                  return _c(
                                    "option",
                                    { domProps: { value: group.group_name } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("propercase")(group.group_name)
                                        )
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                        _vm._v(" "),
                        _vm.is_new_group_name
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleGroupNameField()
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa-solid fa-map" })]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleGroupNameField()
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-edit" })]
                            ),
                      ]),
                  _vm._v(" "),
                  _vm.errors.group_name != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.group_name) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.local_report_page.id == null
                  ? _c("div", { staticClass: "col mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Data Blend Type"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_report_page.data_blend_type,
                              expression: "local_report_page.data_blend_type",
                            },
                          ],
                          staticClass: "form-select",
                          attrs: {
                            id: "data-blend-select",
                            disabled: this.report_page.id != null,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.local_report_page,
                                  "data_blend_type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.filterMetricFilters()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.local_client.data_blends, function (db) {
                          return _c(
                            "option",
                            { domProps: { value: db.type } },
                            [_vm._v(_vm._s(_vm._f("propercase")(db.type)))]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col mb-3" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("accordion-select", {
                      attrs: {
                        id: "deploy-filter-column",
                        options: _vm.columns,
                        show_hidden_fields: false,
                      },
                      model: {
                        value: _vm.local_report_page.deploy_filter_column,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.local_report_page,
                            "deploy_filter_column",
                            $$v
                          )
                        },
                        expression: "local_report_page.deploy_filter_column",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Description"),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_report_page.description,
                        expression: "local_report_page.description",
                      },
                    ],
                    ref: "textarea",
                    staticClass: "form-control full-height",
                    class: _vm.errors.description != "" ? "is-invalid" : "",
                    attrs: { id: "report-description" },
                    domProps: { value: _vm.local_report_page.description },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.local_report_page,
                            "description",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.resizeTextarea()
                        },
                      ],
                      change: function ($event) {
                        return _vm.clearErrorForField("description")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.description != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.description) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _vm.form.successful
                    ? _c("div", { staticClass: "alert alert-success mt-3" }, [
                        _vm._v(
                          "\n                            The report's information has been updated!\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.error
                    ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                        _vm._v(
                          "\n                            There was an error saving the information above. Please make any necessary corrections noted above.\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "submit",
                          id: "save-button",
                          disabled: _vm.form.busy,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.update.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-btn fa-save" }),
                        _vm._v(" "),
                        _vm.local_report_page.id != null
                          ? _c("span", [_vm._v("Save")])
                          : _c("span", [_vm._v("Create")]),
                        _vm._v(" Report\n                            "),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.local_report_page.id != null
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary ms-2",
                            attrs: {
                              id: "cancel-button",
                              disabled: _vm.form.busy,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.revertOverviewChange()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-btn fa-close" }),
                            _vm._v("Cancel\n                            "),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.local_report_page.id != null
      ? _c(
          "div",
          [
            _c("report-builder-filter-settings", {
              ref: "filters",
              attrs: {
                report_page: _vm.local_report_page,
                data_blend: _vm.data_blend,
                metrics_filters: _vm.metrics_filters,
              },
              on: {
                updatePageFilters: _vm.updatePageFilters,
                updateUserDefinedFilters: _vm.updateUserDefinedFilters,
              },
            }),
            _vm._v(" "),
            _c("h5", { staticClass: "mt-4" }, [
              _vm._v("Report Section Settings"),
            ]),
            _vm._v(" "),
            _vm.local_report_page.sections &&
            _vm.local_report_page.sections.length === 0
              ? _c("div", [
                  _c("div", { staticClass: "card box-content mb-3" }, [
                    _c("div", { staticClass: "card-body mt-3 text-center" }, [
                      _c("h3", { staticClass: "text-center" }, [
                        _vm._v("No section have been added yet."),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: {
                            type: "submit",
                            id: "add-section-button",
                            disabled: _vm.form.busy,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addSection.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-btn fa-plus" }),
                          _vm._v(" Add a Section\n                        "),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _c(
                  "transition-group",
                  { staticClass: "mb-5", attrs: { tag: "div", name: "list" } },
                  _vm._l(
                    _vm.local_report_page.sections,
                    function (section, index) {
                      return _c(
                        "div",
                        {
                          key: section.id,
                          ref: "pageSection",
                          refInFor: true,
                          staticClass: "vertical-center-text",
                          class:
                            _vm.local_report_page.sections.length > 1
                              ? "section-row"
                              : "",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setActiveSection(index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card mb-3 mx-0 row-with-reorder-btns",
                              class: section.if_active
                                ? "active-section"
                                : " box-content",
                            },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "accordion accordion-flush" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "accordion-item border-0",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "accordion-header ms-2 me-5",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-start collapsed p-2 accordion-button section-accordion-button",
                                                attrs: {
                                                  type: "button",
                                                  "data-bs-target":
                                                    "#section-editing-" + index,
                                                  "data-bs-toggle": "collapse",
                                                  "aria-expanded": "false",
                                                  "aria-controls":
                                                    "#section-editing-" + index,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.resizeTextareaExpanding(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass: "mb-1",
                                                          },
                                                          [
                                                            _vm.errors.sections
                                                              .length == 0 ||
                                                            Object.values(
                                                              _vm.errors
                                                                .sections[index]
                                                            ).join("") != ""
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "fa-solid fa-circle-exclamation fa-red",
                                                                })
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                    Section " +
                                                                _vm._s(
                                                                  section.order
                                                                ) +
                                                                " : " +
                                                                _vm._s(
                                                                  section.title
                                                                    ? section.title
                                                                    : "New Section(unsaved)"
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "collapse accordion-collapse section-accordion-collapse",
                                            attrs: {
                                              id: "section-editing-" + index,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("hr", {
                                                  staticClass:
                                                    "mx-3 mb-2 section-divider",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "report-builder-section-settings",
                                                  {
                                                    attrs: {
                                                      section_selected: section,
                                                      metrics_filters:
                                                        _vm.local_metrics_filters,
                                                      charts: _vm.charts,
                                                      page_client_id:
                                                        _vm.local_report_page
                                                          .client_id,
                                                      data_blend:
                                                        _vm.data_blend,
                                                      data_set_types:
                                                        _vm.data_set_types,
                                                      section_index: index,
                                                      section_errors:
                                                        _vm.errors.sections[
                                                          index
                                                        ],
                                                    },
                                                    on: {
                                                      updateSection:
                                                        _vm.updateSection,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "asset-accordion-delete btn",
                                        attrs: {
                                          type: "button",
                                          "aria-label": "Close",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSection(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-times",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "px-3 reorder-btn-containter" },
                            [
                              _c(
                                "div",
                                { staticClass: "reorder-btn-containter" },
                                [
                                  index != 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "reorder-btn mb-2",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.moveSectionUp(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-arrow-up",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index !=
                                  _vm.local_report_page.sections.length - 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "reorder-btn",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.moveSectionDown(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-arrow-down",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
            _vm._v(" "),
            _vm.local_report_page.sections.length > 0
              ? _c("div", { staticClass: "sticky-footer w-100" }, [
                  _c("div", [
                    _c("nav", { staticClass: "col-12 navbar navbar-expand" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary ms-2",
                          attrs: {
                            type: "submit",
                            id: "save-button",
                            disabled: _vm.form.busy,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.update.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-btn fa-save" }),
                          _vm._v(" Save \n                    "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary ms-2",
                          attrs: {
                            id: "add-section-button",
                            disabled: _vm.form.busy,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addSection.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-btn fa-plus" }),
                          _vm._v(" Add Section\n                    "),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.checkIfAnyActiveSection()
                        ? _c(
                            "div",
                            { staticClass: "btn btn-secondary dropup ms-2" },
                            [_vm._m(1), _vm._v(" "), _vm._m(2)]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary ms-2",
                              attrs: {
                                id: "add-chart-button",
                                "data-bs-toggle": "modal",
                                "data-bs-target": "#add-chart-modal",
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-btn fa-plus" }),
                              _vm._v(" Add Chart\n                    "),
                            ]
                          ),
                      _vm._v(" "),
                      _vm.local_report_page.client_id == null
                        ? _c("div", { staticClass: "dropup" }, [
                            _vm._m(3),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "dropdown-menu" },
                              _vm._l(_vm.clients, function (client) {
                                return client.id > 0
                                  ? _c("li", { key: client.id }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href:
                                              "/preview/" +
                                              client.url +
                                              "/" +
                                              _vm.local_report_page.id,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(client.name))]
                                      ),
                                    ])
                                  : _vm._e()
                              }),
                              0
                            ),
                          ])
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn-secondary ms-2",
                              attrs: {
                                target: "_blank",
                                href:
                                  "/preview/" +
                                  _vm.local_client.url +
                                  "/" +
                                  _vm.local_report_page.id,
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-btn fa-eye" }),
                              _vm._v(" Preview\n                    "),
                            ]
                          ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "add-chart-modal",
          "aria-labelledby": "add-chart-modal",
          "data-bs-backdrop": "static",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "add-chart-modal",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal("add-chart-modal")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(
                    "Add Chart in " +
                      _vm._s(
                        _vm.active_section && _vm.active_section.title
                          ? _vm.active_section.title
                          : "New Section(unsaved)"
                      )
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0" },
              [
                _c("section-chart-modal", {
                  attrs: { modal_action: "Add", charts: _vm.local_charts },
                  on: { chartAdded: _vm.addChartToSection },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Auto-Generated Filter Column "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "If a value is entered, this will make a unique version of this report for every value of the selected column. ** The column value will be placed at the beginning of the page name. **",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          role: "button",
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa fa-btn fa-plus" }),
        _vm._v(" Add Chart\n                        "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "dropdown-menu dropdown-menu-overflow px-2" },
      [
        _c("span", [
          _vm._v(
            "No section has been selected. Please click on a Section which you want to add a chart to."
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary dropdown-toggle ms-2",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa fa-btn fa-eye" }),
        _vm._v(" Preview\n                      "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }