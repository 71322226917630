<template>
    <div class="results">
        <h2 class="mb-4 mt-5 border-bottom border-secondary border-3 pb-1">
            <span >{{client.name}}:</span> <em>{{plan.name}}</em>
            <button class="btn btn-primary float-end btn-sm d-none d-sm-inline-block" v-on:click="customize = !customize">{{(customize)?'Finished' : 'Customize Dashboard'}}</button>
        </h2>

        <!-- Notifications -->
        <transition name="fade-slow">
        <div class="alert mb-4 box-content" v-for="n in notifs" :class='n.data.class'>
            <button class="btn btn-none px-0 close-btn" v-on:click="dismissNotification(n);"><i class="fa fa-close"
                ></i></button>
            {{ n.data.error_msg}}
        </div>
        </transition>

        <!-- Overview Scorecards -->
        <div class="row stat-container" :class="customize?'customize' : ''">

            <div class="col-sm-6 col-md-4 mb-4 scorecard" v-for="m in metrics"
                :class="isVisible(m) ? '' : 'hidden'">
                <button type="button" class="close btn" aria-label="Close"
                    v-on:click="customizeVisibility(m);">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
                <div class="card box-content" v-on:click="current_metric=m">
                  <h5 class="card-header pt-3">{{m | propercase}}</h5>
                  <div class="card-body pt-0 pb-3">
                    <img :src="getMetricIcon(m)" class="float-start me-3" >
                    <h4 class="mt-2">{{formatNumber(m, annual_stats[m]['actuals'])}}
                        <span class="badge" :class="{ 'bg-label-success':( annual_stats[m]['variance'] > 0), 'bg-label-danger': (annual_stats[m]['variance'] < 0)}">{{annual_stats[m]['variance'] | percentage_na}}</span>
                        </h4 >
                  </div>
                </div>
            </div>
        </div>

        <!-- Chart -->
        <div class="card box-content mb-5" v-if="numMetricsVisible() > 0">
            <div class="card-body">
                <div class="row">

                    <div class="col-4">
                        <h4>{{page_view == 'ytd' ? "Year to Date" : "Total"}} {{current_metric | propercase }}</h4>
                    </div>
                    <div class="col-8">
                        <div class="btn-toolbar flex-row-reverse " role="toolbar" aria-label="Toolbar with button groups">

                            <div class="btn-group mx-2 mb-2 result-selector btn-group-sm" role="group">
                                <button type="button" class="btn"
                                    v-for="m in metrics" @click="current_metric = m"
                                    v-if="isVisible(m)"
                                    :class="current_metric == m ? 'btn-primary' : 'btn-secondary'">{{m | propercase}}</button>
                            </div>

                            <div class="btn-group mx-2 mb-2 btn-group-sm" role="group">
                              <button type="button" class="btn" @click="current_view = 'monthly'"
                                :class="current_view == 'monthly' ? 'btn-primary' : 'btn-secondary'">Monthly</button>
                                <button type="button" class="btn" @click="current_view = 'cumulative'"
                                :class="current_view == 'cumulative' ? 'btn-primary' : 'btn-secondary'">Cumulative</button>
                            </div>

                            <div class="btn-group mx-2 mb-2 btn-group-sm" role="group">
                              <button type="button" class="btn" @click="page_view = 'ytd'"
                                :class="page_view == 'ytd' ? 'btn-primary' : 'btn-secondary'">Year to Date</button>
                                <button type="button" class="btn" @click="page_view = 'full'"
                                :class="page_view == 'full' ? 'btn-primary' : 'btn-secondary'">Full Year</button>
                                <button type="button" class="btn btn-secondary" @click="changeYear(-1)">Last Year</button>
                                <button type="button" class="btn btn-secondary" @click="changeYear(1)">Next Year</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row chart-container">
                    <div class="col">
                        <canvas id="stats-chart"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <TransitionGroup name="move" tag="div">
            <div class="row results-container align-items-center" v-for="(init_ids, index) in sortedDataset()" :class="customize?'customize' : ''" v-if="dataset[init_ids] != null && (shouldRender(dataset[init_ids])|| customize) "  :key="init_ids">
                <div class="col-1 text-center" v-if="customize">
                    <button class="btn btn-secondary my-2" v-if="index > 0"
                        v-on:click="customizePosition(dataset[init_ids], -1)"><i class="fa-solid fa-chevron-up"></i></button>
                    <div class="clearfix"></div>
                    <button class="btn btn-secondary my-2" v-if="index < Object.keys(dataset).length-1"
                        v-on:click="customizePosition(dataset[init_ids], 1)"><i class="fa-solid fa-chevron-down"></i></button>
                </div>
                <div class="col" v-if="hasAnyMetric(dataset[init_ids])">
                    <initiative-results-table :headers="headers" :initiative="dataset[init_ids]"
                        :client="client" @addCustomization="addCustomization"
                        @deleteCustomization="deleteCustomization" :customizations="local_custs"
                        :start_date="start_date" :end_date="end_date" ></initiative-results-table>
                </div>
                <div class="col" v-else>
                    <h4>{{dataset[init_ids].title}}</h4>
                    <div class="card box-content mb-5">
                        <div class="card-body">
                            <p class="mb-0">The initiative has not launched yet so there are no metrics to display.</p>
                        </div>
                    </div>
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import moment from 'moment';
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import InitiativeResultsTable from '../components/InitiativeResultsTable';

    export default {
        components: { Swal},
        props: ['plan', 'client', 'projections', 'actuals', 'customizations', 'notifications'],
        data() {
            return {
                chart: null,
                dataset: null,
                monthly_stats: null,
                page_view: 'ytd',
                current_view: 'monthly',
                current_metric: 'media_cost',
                metrics: ['media_cost', 'project_cost', 'new_donors', 'revenue', 'gifts', 'emails'],
                chart_data: [],
                initiative_types: [],
                start_date: null,
                end_date: new Date(),
                annual_stats: {
                    emails: { projections: 0, actuals: 0, variance: 0 },
                    media_cost: { projections: 0, actuals: 0, variance: 0 },
                    project_cost: { projections: 0, actuals: 0, variance: 0 },
                    revenue: { projections: 0, actuals: 0, variance: 0 },
                    new_donors: { projections: 0, actuals: 0, variance: 0 },
                    gifts: { projections: 0, actuals: 0, variance: 0 },
                },
                local_custs: null,
                customize: false,
                notifs: []
            };
        },
        computed: {
            // a computed getter
            headers() {
                var headers = {};

                var start_month = parseInt(this.plan.start_month.split("-")[1]);

                var len = 12;
                if(this.page_view == 'ytd') {
                    var start_year = parseInt(this.plan.start_month.split("-")[0]);
                    var dte = new Date();
                    var end_month = dte.getMonth()+1;
                    var end_year = dte.getFullYear();

                    len = (end_month - start_month + 1) + 12*(end_year-start_year);
                }

                const months = Array.from({length: len}, (item, i) => {
                  return new Date(0, i+start_month-1).toLocaleString('en-US', {month: 'short'})
                });

                return months;
            }

        },
        watch: {
            page_view(old_pv, new_pv) {
                if(new_pv == 'full')
                    this.end_date = new Date();
                else
                    this.end_date = this.getEndDate();
                this.populateChartData();
                this.dataset = this.getDataset();
            },
            current_metric(old_metric, new_metric) {
                this.populateChartData()
            },
            current_view(old_metric, new_metric) {
                this.populateChartData();
            },
        },
        mounted() {

            this.local_custs = this.customizations;
            this.notifs = this.notifications;

            //Find the first visible metrics
            for(var i = 0; i < this.metrics.length; i++)
                if(this.isVisible(this.metrics[i])) {
                    this.current_metric = this.metrics[i];
                    break;
                }
            this.start_date = this.getStartDate();
            this.dataset = this.getDataset();
            this.annual_stats = this.getAnnualStats();
            this.monthly_stats = this.getMonthlyStats();

            this.populateChartData();
            this.getInitiativeTypes();
        },
        methods: {
            changeYear(direction) {
                //Move the start date back a year
                this.page_view = 'full'
                this.start_date.setUTCFullYear(this.start_date.getUTCFullYear() + direction);
                this.dataset = this.getDataset();
                this.annual_stats = this.getAnnualStats();
                this.monthly_stats = this.getMonthlyStats();

                this.populateChartData();
            },
            dismissNotification(n) {
                window.axios.get('/api/notification/read/' + n.id);

                //Remove the notification from the list
                for(var i = 0; i < this.notifs.length; i++) {
                    if(this.notifs[i].id == n.id) {
                        this.notifs.splice(i, 1);
                    }
                }
            },
            shouldRender(init) {
                return this.hasMetric(init, init.media_cost) && this.isInitMetricVisible(init, 'media_cost')
                    || this.hasMetric(init, init.project_cost) && this.isInitMetricVisible(init, 'project_cost')
                    || this.hasMetric(init, init.all_revenue) && this.isInitMetricVisible(init, 'all_revenue')
                    || this.hasMetric(init, init.new_donors) && this.isInitMetricVisible(init, 'new_donors')
                    || this.hasMetric(init, init.emails) && this.isInitMetricVisible(init, 'emails')
                    || this.hasMetric(init, init.gifts) && this.isInitMetricVisible(init, 'gifts');
            },
            hasAnyMetric(init) {
                return this.hasMetric(init, init.media_cost)
                    || this.hasMetric(init, init.project_cost)
                    || this.hasMetric(init, init.all_revenue)
                    || this.hasMetric(init, init.new_donors)
                    || this.hasMetric(init, init.emails)
                    || this.hasMetric(init, init.gifts);
            },
            hasMetric(init, arr) {
                if(typeof arr == 'string')
                    arr = init[arr];

                var total = 0;
                for (const [key, i] of Object.entries(arr.projections)) {
                    total += i;
                };
                for (const [key, i] of Object.entries(arr.actuals)) {
                    total += i;
                };
                return total != 0;
            },
            isInitMetricVisible(init, metric) {
                //Loop through the customizations array
                if(this.local_custs != undefined && this.local_custs != null)
                    for (const [key, i] of Object.entries(this.local_custs)) {
                        //If the metric is in the array, return true
                        if(i.action == 'hide' && (i.filter.metric == metric ||
                            i.filter.metric == 'all_revenue' && metric.indexOf('revenue') >= 0) &&
                            i.filter.initiative_id == init.id )
                            return false;
                    };
                return true;
            },

            numMetricsVisible() {
                var num_visible = 0;
                for(var i = 0; i < this.metrics.length; i++)
                    if(this.isVisible(this.metrics[i])) num_visible++
                return num_visible;
            },
            isVisible(metric) {
                //Loop through the customizations array
                if(this.local_custs != undefined)
                    for (const [key, i] of Object.entries(this.local_custs)) {
                        //If the metric is in the array, return true
                        if(i.action == 'hide' && i.filter.metric == metric &&
                            i.filter.initiative_id == null )
                            return false;
                    };
                return true;
            },
            addCustomization(c) {
                this.local_custs.push(c);
            },
            deleteCustomization(index) {
                this.local_custs.splice(index, 1);
            },
            customizePosition(init, distance) {

                //Create the baseline data structure
                var data = {
                    action: 'move',
                    client_id: this.client.id,
                    filter: {
                        initiative_id: init.id,
                        distance: distance
                    }
                };

                //See if the customization already exists
                for(var i = 0; i < this.local_custs.length; i++) {
                    if(data.action == this.local_custs[i].action &&
                        data.filter.initiative_id == this.local_custs[i].filter.initiative_id ) {
                        //Update the distance
                        this.local_custs[i].filter.distance += distance;
                        //Override the data variable
                        data = this.local_custs[i];
                        break;
                    }
                }

                //If it is new...
                if(data.id == null) {
                    var self = this;
                    window.axios.post('/api/customization', data)
                      .then(response => {
                        if(response.status == 200) {
                            //update the customization object
                            self.addCustomization(response.data.data);
                        }
                      });
                }
                //It is existing so update it
                else {
                    var self = this;
                    window.axios.put('/api/customization/' + data.id, data);
                }
            },

            customizeVisibility(metric) {
                var data = {
                    action: 'hide',
                    client_id: this.client.id,
                    filter: {
                        metric: metric
                    }
                };

                var c_id = null;
                for(var i = 0; i < this.local_custs.length; i++) {
                    if(data.action == this.local_custs[i].action &&
                        data.filter.metric == this.local_custs[i].filter.metric &&
                        this.local_custs[i].filter.initiative_id == null) {
                        c_id = i;
                        break;
                    }
                }

                if(c_id == null) {
                    var self = this;
                    window.axios.post('/api/customization', data)
                      .then(response => {
                        if(response.status == 200) {
                            //update the customization object
                            self.addCustomization(response.data.data);
                        }
                      });
                }
                else {
                    window.axios.delete('/api/customization/' + this.local_custs[c_id].id);
                    this.deleteCustomization(c_id);

                    //If all were previously hidden and now this one is showing, redraw the chart
                    if(this.numMetricsVisible() == 1){
                        this.current_metric = metric;
                        // Need to wait for the chart to render again before redrawing it
                        this.$nextTick(function () {
                            this.populateChartData()
                        });
                    }


                }
            },
            formatNumber(metric, num) {
                switch(metric) {
                    case 'media_cost':
                    case 'project_cost':
                    case 'revenue':
                        return this.$options.filters.currency_with_zero(num);
                }
                return this.$options.filters.number_with_zero(num);
            },
            getMetricIcon(metric) {
                switch(metric) {
                    case 'media_cost':
                        return '/img/icons/average_amount.png';
                    case 'project_cost':
                        return '/img/icons/average_amount.png';
                    case 'new_donors':
                        return "/img/icons/donors_new.png";
                    case 'revenue':
                        return "/img/icons/revenue.png";
                    case 'gifts':
                        return "/img/icons/donors.png";
                    case 'emails':
                        return "/img/icons/subscribers.png";
                }
                return "";
            },

            sortedDataset() {
                if(this.dataset) {
                    var self = this;
                    //Sort the initiatives based upon priority first
                    var obj = Object.keys(this.dataset).sort(
                        function(a,b){return self.dataset[a].sort_priority-self.dataset[b].sort_priority}
                    );

                    //Now move the array positions around based upon customizations
                    for(var i = 0; i < this.local_custs.length; i++) {
                        if(this.local_custs[i].action == 'move') {
                            var index = obj.indexOf(this.local_custs[i].filter.initiative_id);
                            var distance = this.local_custs[i].filter.distance;
                            if(index != -1) {
                                //Move the item
                                obj.splice(index, 1);
                                obj.splice(index + distance, 0, this.local_custs[i].filter.initiative_id);
                            }
                        }
                    }

                    return obj;
                }
                return null;
            },
            getEmptyInitiative(i) {
                return {
                    id: i.initiative_ids,
                    title: i.title,
                    priority: i.priority,
                    sort_priority: i.sort_priority,
                    url: i.url,
                    cost : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    project_cost : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    media_cost : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    new_donors : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    gifts : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    emails : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    revenue : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    recurring_revenue : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    },
                    all_revenue : {
                        projections: {},
                        actuals:{},
                        variance: {}
                    }
                };
            },
            addInitiativeValues(arr, arr_name, init) {
                arr.project_cost[arr_name][init.date] = parseFloat(init.project_cost);
                arr.media_cost[arr_name][init.date] = parseFloat(init.media_cost);
                arr.cost[arr_name][init.date]= parseFloat(init.media_cost) + parseFloat(init.project_cost);
                arr.new_donors[arr_name][init.date] = parseFloat(init.new_donors);
                arr.gifts[arr_name][init.date] = parseFloat(init.gifts);
                arr.emails[arr_name][init.date] = parseFloat(init.emails);
                arr.revenue[arr_name][init.date] = parseFloat(init.revenue);
                arr.recurring_revenue[arr_name][init.date] = parseFloat(init.recurring_revenue);
                arr.all_revenue[arr_name][init.date] = parseFloat(init.revenue) + parseFloat(init.recurring_revenue);
            },
            calculateVariance(arr, pos) {
                var proj = Object.keys(arr[pos].projections);
                var actu = Object.keys(arr[pos].actuals);
                var dates = this.getUniqueArray(proj.concat(actu));

                dates.forEach(function(dte) {
                    if(arr[pos].projections[dte] == null || arr[pos].projections[dte] == 0)
                        arr[pos].variance[dte] = 0;
                    else if(arr[pos].actuals[dte] == null || arr[pos].actuals[dte] == 0)
                        arr[pos].variance[dte] = -1;
                    else
                        arr[pos].variance[dte] = (arr[pos].actuals[dte] - arr[pos].projections[dte]) * 1.0 / arr[pos].projections[dte];
                });
            },
            getMonthlyStats() {
                var self = this;
                var dataset = this.getDataset();
                var data =  {
                    projections: [],
                    actuals: [],
                }

                //Loop through the start and end months
                for(var month = new Date(this.start_date); month < this.end_date; month.setUTCMonth(month.getUTCMonth()+1)) {
                    var actuals = 0;
                    var projections = 0;

                    //Now loop through each initiative
                    for (const [key, el] of Object.entries(dataset)) {

                        //if it is revenue, combine onetime and recurring
                        if(self.current_metric == 'revenue') {
                            projections += el.revenue.projections[self.getDateAsString(month)] +
                                el.recurring_revenue.projections[self.getDateAsString(month)];
                            actuals += el.revenue.actuals[self.getDateAsString(month)] +
                                el.recurring_revenue.actuals[self.getDateAsString(month)];
                        }
                        //Otherwise, I can do a straight pull from the array
                        else {
                            projections += el[self.current_metric].projections[self.getDateAsString(month)];
                            actuals += el[self.current_metric].actuals[self.getDateAsString(month)];
                        }
                    }

                    data.projections.push(projections);
                    data.actuals.push(actuals);

                }

                return data;
            },
            getDataset() {
                var inits = {};
                var self = this;
                var blank_init = {
                    id: -1,
                    date: "",
                    emails: 0,
                    gifts:0,
                    lifetime_value:0,
                    media_cost: 0,
                    new_donors: 0,
                    project_cost: 0,
                    recurring_revenue: 0,
                    revenue: 0
                };

                //Loop through every projection to populate the array
                //Only include the data if it is between the start and end dates
                this.projections.filter(i => new Date(Date.parse(i.date)) <= self.end_date &&
                            new Date(Date.parse(i.date)) >= self.start_date).forEach(function(i) {

                    if(inits[i.initiative_ids] == null)
                        inits[i.initiative_ids] = self.getEmptyInitiative(i);

                    self.addInitiativeValues(inits[i.initiative_ids], 'projections', i);

                    //In cases where there are no actuals, this will ensure there is at least on value
                    blank_init.date = i.date;
                    self.addInitiativeValues(inits[i.initiative_ids], 'actuals', blank_init);
                });

                //Loop through every "actual"
                this.actuals.filter(i => new Date(Date.parse(i.date)) <= self.end_date &&
                            new Date(Date.parse(i.date)) >= self.start_date).forEach(function(i) {

                    if(inits[i.initiative_ids] == null)
                        inits[i.initiative_ids] = self.getEmptyInitiative(i);

                    self.addInitiativeValues(inits[i.initiative_ids], 'actuals', i);
                });



                //Now calculate the variance between projections and actuals
                for (const [key, i] of Object.entries(inits)) {
                    self.calculateVariance(i, 'project_cost');
                    self.calculateVariance(i, 'media_cost');
                    self.calculateVariance(i, 'gifts');
                    self.calculateVariance(i, 'new_donors');
                    self.calculateVariance(i, 'emails');
                    self.calculateVariance(i, 'revenue');
                    self.calculateVariance(i, 'recurring_revenue');
                }

                return inits;
            },
            getAnnualStats() {
                var self = this;
                var dataset = this.dataset;
                var data = this.annual_stats;

                //Loop through the start and end months
                for(var month = new Date(this.start_date); month < new Date(); month.setUTCMonth(month.getUTCMonth()+1)) {

                    //Now loop through each initiative
                    for (const [key, el] of Object.entries(dataset)) {
                        //Combine project and media for costs
                        data.media_cost.projections += el.media_cost.projections[self.getDateAsString(month)];
                        data.media_cost.actuals += el.media_cost.actuals[self.getDateAsString(month)];

                        data.project_cost.projections += el.project_cost.projections[self.getDateAsString(month)];
                        data.project_cost.actuals += el.project_cost.actuals[self.getDateAsString(month)];

                        //For revenue,
                        data.revenue.projections  += el.all_revenue.projections[self.getDateAsString(month)];
                        data.revenue.actuals  += el.all_revenue.actuals[self.getDateAsString(month)];

                        //Emails
                        data.emails.projections += el['emails'].projections[self.getDateAsString(month)];
                        data.emails.actuals += el['emails'].actuals[self.getDateAsString(month)];

                        //Gifts
                        data.gifts.projections += el['gifts'].projections[self.getDateAsString(month)];
                        data.gifts.actuals += el['gifts'].actuals[self.getDateAsString(month)];

                        //New Donors
                        data.new_donors.projections += el['new_donors'].projections[self.getDateAsString(month)];
                        data.new_donors.actuals += el['new_donors'].actuals[self.getDateAsString(month)];

                    };

                }

                //Calculate the percent change
                for(var i = 0; i < this.metrics.length; i++)
                    if(data[this.metrics[i]].projections > 0)
                        data[this.metrics[i]].variance = (data[this.metrics[i]].actuals - data[this.metrics[i]].projections) / data[this.metrics[i]].projections;


                return data;
            },
            getStartDate() {
                var start_date = new Date(Date.parse(this.plan.start_month));
                var today = new Date();
                today.setFullYear(today.getUTCFullYear() - 1);
                while(start_date < today) {
                    start_date.setUTCFullYear(start_date.getUTCFullYear() + 1);
                }
                return start_date;
            },
            getEndDate() {
                var end_date = new Date(this.start_date);
                end_date.setUTCFullYear(end_date.getUTCFullYear() + 1);
                end_date.setUTCDate(end_date.getUTCDate()-1);
                return end_date;

            },
            getInitiativeTypes() {
                var self = this;
                window.axios.get('/api/initiative_type').then(response => {
                    self.initiative_types = response.data.data;
                });
            },
            populateChartData() {

                var type = 'bar';

                var monthly_stats = this.getMonthlyStats();
                //Make it cumulative
                if(this.current_view == 'cumulative') {
                    for(var i = 1; i < monthly_stats['projections'].length; i++){
                        monthly_stats['projections'][i] += monthly_stats['projections'][i-1];
                        monthly_stats['actuals'][i] += monthly_stats['actuals'][i-1];
                    }


                    //Set up the data
                    this.chart_data = [
                        {
                            label: 'Projections',
                            data: monthly_stats['projections'],
                            backgroundColor: [ '#C7CCDB'],
                            borderColor: [ '#C7CCDB'],
                            borderDash: [10, 10],
                            borderWidth: 2,
                            tension: 0.1
                        },
                        {
                            label: 'Actuals',
                            data: monthly_stats['actuals'],
                            backgroundColor: [ '#46705F'],
                            borderColor: [ '#46705F' ],
                            borderWidth: 4,
                            tension: 0.1
                        }
                    ];

                    type = 'line';
                }
                else {
                    this.chart_data = [
                        {
                            label: 'Projections',
                            data: monthly_stats['projections'],
                            backgroundColor: [ 'rgb(199, 204, 219, 0.8)'],
                            borderColor: [ '#C7CCDB' ],
                            borderWidth: 1,
                            borderRadius: 6,
                            borderSkipped: false,
                            barPercentage:0.8
                        },
                        {
                            label: 'Actuals',
                            data: monthly_stats['actuals'],
                            backgroundColor: [ 'rgb(245, 198, 53, 0.8)'],
                            borderColor: [ '#46705F' ],
                            borderWidth: 1,
                            borderRadius: 6,
                            borderSkipped: false,
                            barPercentage:0.8
                        }
                    ];
                }

                var config = this.getChartConfig(type)

                if(this.chart != null)
                    this.chart.destroy();

                var ctx = document.getElementById('stats-chart');
                if(ctx != undefined)
                    this.chart = new Chart(ctx, config);
            },
            getChartConfig(type) {

                var self = this;

                var config = {
                    type: type,
                    plugins: [ChartDataLabels],
                    data: {
                        labels: self.headers,
                        datasets: this.chart_data
                    },
                    options: {
                        responsive: true,
                        title: {
                            display:false,
                        },
                        scales: {
                            y: {
                                ticks: {
                                    // Include a dollar sign in the ticks for revenue and spend
                                    callback: function(value, index, ticks) {
                                        if(self.current_metric == 'revenue' || self.current_metric.indexOf("cost") > -1)
                                            return self.$options.filters.currency(value);
                                        else
                                            return self.$options.filters.number(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                position: 'bottom'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        //Format the tooltip
                                        if(self.current_metric == 'revenue' || self.current_metric.indexOf("cost") > -1)
                                            return self.$options.filters.currency(context.parsed.y);
                                        else
                                            return self.$options.filters.number(context.parsed.y);
                                    }
                                }
                            },
                            datalabels: {
                                anchor: (type == 'bar' ? 'end' : ""),
                                color: function(context) {
                                  var index = context.datasetIndex;
                                  //Zero should be the black, 1 is white on the blue bar
                                  //if(index == 1 && type == 'bar')
                                  //  return 'white';
                                  return '#2A324B';
                                },
                                align: 'start',
                                formatter: function(value, context) {
                                    if(self.current_metric == 'revenue' || self.current_metric.indexOf("cost") > -1)
                                        return "$" + self.$options.filters.num_abbr(value);
                                    else
                                        return self.$options.filters.num_abbr(value);
                                }
                            }
                        }
                    }
                };
                return config;


            },
            monthDiff(dateFrom, dateTo) {
                if(dateTo.getUTCDate() == dateFrom.getUTCDate())
                    return dateTo.getUTCMonth() - dateFrom.getUTCMonth() +
                        (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear()));
                else
                    return dateTo.getUTCMonth() - dateFrom.getUTCMonth() +
                        (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear())) + 1;
            },
            getUniqueArray(array) {
                var a = array.concat();
                for(var i=0; i<a.length; ++i) {
                    for(var j=i+1; j<a.length; ++j) {
                        if(a[i] === a[j])
                            a.splice(j--, 1);
                    }
                }

                return a;
            },
            getDateAsString(value) {
                return moment(String(value)).utc().format('YYYY-MM-DD')
            }
        }
    }
</script>
