<template>
<div class="accordion-select w-100" @click="handleClick">
    <v-select :options="columns" v-model="value_obj"
        :searchable="true" class="searchable-select column-display"
        :selectable="(option) => option.text != ''"
        label="text" @search="fetchOptions"
        @input="emitInput()"
        :filterable="false">
        <template #list-header="{ search }">
            <li class="list-header border-bottom border-primary border-1" @click.prevent="emitListHeaderAction(search)" @mouseover="focusOnListHeader()">
                <a class="btn btn-link">{{ list_header_text }}</a>
            </li>
        </template>
        <template #selected-option="{ text, category }">
            <span :option-selected="text"> {{(category != null && category != "" && category != "list-header" && text!=null && text!="") ? category + ": " : ""}}{{text}}</span>
        </template>

        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
        </template>

        <template #option="{ text, category, extra }">
          <div v-if="text == '' && category != 'list-header'" :data-category="category" class="category-header"
            v-on:click="return expandCategory(category, null)"
            data-isexpanded="false">
            {{ category }} <i class="fa-solid fa-caret-right"></i>
          </div>
          <div v-else class="suboption" :data-subcategory="category"
            :class="category==null || category==''? 'show' : ''">
            {{text}} <span v-html="extra" v-if='extra != null'></span>
          </div>
        </template>
        <template #no-options="{ search, searching, loading }">
            <div class="suboption show">
                <em>No results found</em>
            </div>
        </template>
    </v-select>
    <input type="hidden" :name="name" :value="(value_obj == null ? null : value_obj.value)" />
</div>
</template>
<script>
    import vSelect from "vue-select";

    export default {
        props: {
            value: {
              default: '',
            },
            list_header_text:{
                type: String,
                default: '',
            },
            options: {
                type: Array,
                default: []
            },
            name: {
              type: String,
              default: 'vue-select',
            },
        },
        components: {
            vSelect
        },
        data() {
            return {
                value_obj: null,
                columns: [],
                orig_columns: []
            };
        },
        beforeMount() {
            this.resetValueObject();
        },
        watch:{
            options(old_value, new_value){
                this.columns =[];
                for(var i = 0; i < this.options.length; i++) {
                    //Don't include any columns that start with _ but do include if it starts with __
                    if((this.options[i].value.indexOf("_") == 0 && this.options[i].value.indexOf("__") != 0) || (this.options[i].value.indexOf("._") >= 0 && this.options[i].value.indexOf(".__") == -1))
                        continue;
                    this.columns.push(this.options[i]);
                }
                this.addHeaders();
                this.orig_columns = JSON.parse(JSON.stringify(this.columns))

                if(this.value != null)
                    this.value_obj = this.options.find(option => option.value == this.value);
            },
            value(old_value, new_value){

                this.$emit('change');
                this.resetValueObject();
            }   
        },
        mounted() {
            this.columns =[];
            if(this.options){
                for(var i = 0; i < this.options.length; i++) {
                    //Don't include any columns that start with _
                    if((this.options[i].value.indexOf("_") == 0 && this.options[i].value.indexOf("__") != 0) || (this.options[i].value.indexOf("._") >= 0 && this.options[i].value.indexOf(".__") == -1))
                        continue;
                    this.columns.push(this.options[i]);
                }                
            }

            this.addHeaders();
            this.orig_columns = JSON.parse(JSON.stringify(this.columns))
        },
        methods: {
            resetValueObject(){
                if(this.value != null)
                    this.value_obj = this.options.find(option => option.value == this.value);
                else
                    this.value_obj = {
                        value: '',
                        category: '',
                        text: ""
                    }
            },
            handleClick() {
              this.$emit('editing');
            },
            focusOnListHeader(){
                // when hovering on list header, remove highlight on suboptions
                const highlightedSuboption = document.querySelector('.vs__dropdown-option--highlight');
                if(highlightedSuboption){
                    highlightedSuboption.classList.remove("vs__dropdown-option--highlight");
                }
            },
            emitInput() {
                if(this.value_obj == null)
                    this.$emit('input', null);
                else
                    this.$emit('input', this.value_obj.value);
            },
            emitListHeaderAction(search){
                this.$emit('listHeaderAction', search);
            },
            addHeaders() {
                var headers = "";
                for(var i = 0; i < this.columns.length; i++)
                    if(headers != this.columns[i].category && this.columns[i].category != null) {
                        headers = this.columns[i].category;
                        this.columns.splice(i, 0, {
                            value: "DIVIDER-"+ headers,
                            category: headers,
                            text: ""
                        });
                    }
            },
            expandCategory(cat, expand) {
                var headline = document.querySelector("div[data-category='"+cat+"']");
                var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");

                if(headline == undefined || lines == undefined)
                    return false;

                if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="block";
                    var divs = headline.getElementsByClassName("fa-caret-right");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-down");
                        divs[i].classList.remove("fa-caret-right");
                    }
                    headline.dataset.isexpanded = true;
                }
                else {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="none";
                    var divs = headline.getElementsByClassName("fa-caret-down");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-right");
                        divs[i].classList.remove("fa-caret-down");
                    }
                    headline.dataset.isexpanded = false;
                }

                return false;
            },
            fetchOptions (search, loading) {
                //Reset the array
                this.columns = JSON.parse(JSON.stringify(this.orig_columns));

                if(search == "") return;

                //Look at each column
                for(var i = this.columns.length-1; i >= 0 ; i--) {
                    //If the search string isn't in the text or the category
                    if(this.columns[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                        && (this.columns[i].category == null
                        || this.columns[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                        && this.columns[i].text != "" ) //And not a category divider

                        this.columns.splice(i, 1);
                }

                //Get the remaining categories
                var cats = [];
                for(var i = 0; i < this.columns.length; i++)
                    if(this.columns[i].category != null && !cats.includes(this.columns[i].category) && this.columns[i].text != "")
                        cats.push(this.columns[i].category);

                //Expand the categories
                for(var i = 0; i < cats.length; i++)
                    this.expandCategory(cats[i], true);

                //Remove a category if it isn't in the array of categories
                for(var i = this.columns.length-1; i >= 0 ; i--)
                    if(this.columns[i].text == "" && this.columns[i].category != null && !cats.includes(this.columns[i].category))
                        this.columns.splice(i, 1);

                return true;
            }

        }

    }
</script>
