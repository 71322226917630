<template>
    <div class="agency_settings org_settings">
        <h1 class="mb-2">Agency Settings</h1>
        <div class="row px-2 mb-5">
            <nav id="agency-setting-tab-nav" class="col-12 navbar navbar-expand primary-tab-bar pb-0">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav mb-lg-0">
                            <li class="nav-item" v-for="tab_name in setting_tabs" :key="tab_name">
                                <a class="nav-link d-flex flex-nowrap pb-0 mb-0 mx-2 primary-tab"
                                   :class="{'primary-tab-active': tab_name === setting_tab_name_active}"
                                   href="#"
                                   role="button"
                                   aria-expanded="false"
                                   @click.prevent="setActiveTab(tab_name)">
                                    <span>{{ tab_name }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div class="card box-content py-3">
                <div class="card-body">
                    <div v-if="setting_tab_name_active === 'PROFILE'">
                        <agency-profile :agency="agency"></agency-profile>
                    </div>
                    <div v-else-if="setting_tab_name_active === 'TEAM'">
                        <agency-users :agency="agency" ></agency-users>
                    </div>
                    <div v-else-if="setting_tab_name_active === 'CLIENTS'">
                        <agency-clients :agency="agency" ></agency-clients>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['agency', 'user'],
    data() {
        return {
            setting_tabs: ['PROFILE', 'TEAM', 'CLIENTS'],
            setting_tab_name_active: 'PROFILE',
        };
    },
    beforeMount() {
        let tab_name = window.location.pathname.split('/').pop();

        if (tab_name =='profile')
            this.setting_tab_name_active = 'PROFILE';
        else if (tab_name =='team')
            this.setting_tab_name_active = 'TEAM';
        else if (tab_name =='clients')
            this.setting_tab_name_active = 'CLIENTS';
    },
    methods: {
        setActiveTab(tab_name) {
            this.setting_tab_name_active = tab_name;
            let newURL = `/admin/agency`;

            if (tab_name === 'PROFILE') {
                newURL += '/profile';
            } else if (tab_name === 'TEAM') {
                newURL += '/team';
            } else if (tab_name === 'CLIENTS') {
                newURL += '/clients';
            }

            window.history.replaceState({}, '', newURL);
        },
    }
}
</script>
