var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "pathways-manage-pathways" } }, [
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h5", [
          _vm._v(
            "Your Pathways (" +
              _vm._s(_vm.local_connection_datasets.length) +
              ")"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Pathways are a simple way to link your various tools together so that critical data is kept in sync. Once you've made an connection into one of your marketing or fundraising systems, it will show up below (if data is able to be pushed). Below each system, you will see the available audiences within each data set and data blend. If there are additional audiences you'd like to push, simply create it here."
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row my-3" },
      [
        _c(
          "transition-group",
          { attrs: { tag: "div", name: "fade-slow" } },
          _vm._l(_vm.local_connection_datasets, function (conn, conn_index) {
            return _c(
              "div",
              {
                key: conn.id,
                ref: "managePathways",
                refInFor: true,
                staticClass: "mb-2 position-relative",
                attrs: { id: "conn_" + conn.id },
              },
              [
                _c("div", { staticClass: "accordion accordion-flush" }, [
                  _c("div", { staticClass: "accordion-item" }, [
                    _c("div", { staticClass: "accordion-header px-3 py-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "accordion-button collapsed p-0",
                          attrs: {
                            type: "button",
                            "data-bs-target": "#manage-pathway-" + conn.id,
                            "data-bs-toggle": "collapse",
                            "aria-expanded": "false",
                            "aria-controls": "#manage-pathway-" + conn.id,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("img", {
                                staticClass:
                                  "avatar rounded-circle avatar-sm me-2 bg-white",
                                attrs: {
                                  src: conn.integration.logo_url,
                                  alt: conn.integration.name,
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "d-inline-block m-0" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isFile(conn)
                                              ? "File Export"
                                              : conn.name
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "badge badge-success d-inline-block ms-2",
                                      },
                                      [_vm._v("Active")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(conn.pushed_segment_count) +
                                      " " +
                                      _vm._s(
                                        conn.pushed_segment_count != 1
                                          ? "Audiences"
                                          : "Audience"
                                      ) +
                                      "\n                                            " +
                                      _vm._s(
                                        _vm.isFile(conn)
                                          ? "Customized"
                                          : "Pushed"
                                      ) +
                                      "\n                                        "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse accordion-collapse p-3 pt-0",
                        attrs: { id: "manage-pathway-" + conn.id },
                      },
                      [
                        _c("hr", { staticClass: "m-0" }),
                        _vm._v(" "),
                        _vm._l(
                          conn.datasets_to_sync,
                          function (set, set_index) {
                            return _c("div", { staticClass: "card mt-3" }, [
                              _c("div", { staticClass: "card-header" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row justify-content-between align-items-center gap-3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start",
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("propercase")(set.name)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    conn.census_destination_id != null ||
                                    _vm.isFile(conn)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end justify-self-end gap-2",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn btn-secondary me-1",
                                                attrs: {
                                                  href:
                                                    "/pathways/" +
                                                    _vm.client.url +
                                                    "/" +
                                                    conn.id +
                                                    "/mapping/" +
                                                    set.data_source +
                                                    "/" +
                                                    set.id,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "icon pe-0",
                                                  attrs: {
                                                    src: "/img/icons/dialexa-icons/map.svg",
                                                  },
                                                }),
                                                _vm._v(
                                                  " Default Mapping\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card-body avid-table pathways-manage-pathway-table p-0",
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-responsive m-0 mb-2",
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          { staticClass: "table-header-row" },
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "table-column-1",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortByKey(
                                                      "name",
                                                      conn_index,
                                                      set_index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                        Audience\n                                                        "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "sort-key",
                                                          class:
                                                            _vm.sort_conn_index ==
                                                              conn_index &&
                                                            _vm.sort_set_index ==
                                                              set_index &&
                                                            _vm.sort_key ==
                                                              "name" &&
                                                            _vm.sort_order ==
                                                              "asc"
                                                              ? "sort-key-active"
                                                              : "",
                                                          attrs: {
                                                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("img", {
                                                          staticClass:
                                                            "sort-key",
                                                          class:
                                                            _vm.sort_conn_index ==
                                                              conn_index &&
                                                            _vm.sort_set_index ==
                                                              set_index &&
                                                            _vm.sort_key ==
                                                              "name" &&
                                                            _vm.sort_order ==
                                                              "des"
                                                              ? "sort-key-active"
                                                              : "",
                                                          attrs: {
                                                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "table-column-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortByKey(
                                                      "sizes",
                                                      conn_index,
                                                      set_index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                        Estimated Records\n                                                        "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "sort-key",
                                                          class:
                                                            _vm.sort_conn_index ==
                                                              conn_index &&
                                                            _vm.sort_set_index ==
                                                              set_index &&
                                                            _vm.sort_key ==
                                                              "sizes" &&
                                                            _vm.sort_order ==
                                                              "asc"
                                                              ? "sort-key-active"
                                                              : "",
                                                          attrs: {
                                                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("img", {
                                                          staticClass:
                                                            "sort-key",
                                                          class:
                                                            _vm.sort_conn_index ==
                                                              conn_index &&
                                                            _vm.sort_set_index ==
                                                              set_index &&
                                                            _vm.sort_key ==
                                                              "sizes" &&
                                                            _vm.sort_order ==
                                                              "des"
                                                              ? "sort-key-active"
                                                              : "",
                                                          attrs: {
                                                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-info-circle text-primary ms-2",
                                                      attrs: {
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        title:
                                                          "This estimate will vary, even for the same audience, based upon the destination and what it considers the 'primary key'.",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "table-column-3",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortByKey(
                                                      "sync_active",
                                                      conn_index,
                                                      set_index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                        " +
                                                        _vm._s(
                                                          _vm.isFile(conn)
                                                            ? "Custom Export Columns"
                                                            : "Sync Status"
                                                        ) +
                                                        "\n                                                        "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "sort-key",
                                                          class:
                                                            _vm.sort_conn_index ==
                                                              conn_index &&
                                                            _vm.sort_set_index ==
                                                              set_index &&
                                                            _vm.sort_key ==
                                                              "sync_active" &&
                                                            _vm.sort_order ==
                                                              "asc"
                                                              ? "sort-key-active"
                                                              : "",
                                                          attrs: {
                                                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("img", {
                                                          staticClass:
                                                            "sort-key",
                                                          class:
                                                            _vm.sort_conn_index ==
                                                              conn_index &&
                                                            _vm.sort_set_index ==
                                                              set_index &&
                                                            _vm.sort_key ==
                                                              "sync_active" &&
                                                            _vm.sort_order ==
                                                              "des"
                                                              ? "sort-key-active"
                                                              : "",
                                                          attrs: {
                                                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.isFile(conn)
                                              ? _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "table-column-4",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sortByKey(
                                                          "last_synced",
                                                          conn_index,
                                                          set_index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        Last Synced\n                                                        "
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column",
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "sort-key",
                                                              class:
                                                                _vm.sort_conn_index ==
                                                                  conn_index &&
                                                                _vm.sort_set_index ==
                                                                  set_index &&
                                                                _vm.sort_key ==
                                                                  "last_synced" &&
                                                                _vm.sort_order ==
                                                                  "asc"
                                                                  ? "sort-key-active"
                                                                  : "",
                                                              attrs: {
                                                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              staticClass:
                                                                "sort-key",
                                                              class:
                                                                _vm.sort_conn_index ==
                                                                  conn_index &&
                                                                _vm.sort_set_index ==
                                                                  set_index &&
                                                                _vm.sort_key ==
                                                                  "last_synced" &&
                                                                _vm.sort_order ==
                                                                  "des"
                                                                  ? "sort-key-active"
                                                                  : "",
                                                              attrs: {
                                                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.isFile(conn)
                                              ? _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "table-column-5",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sortByKey(
                                                          "sync_errors",
                                                          conn_index,
                                                          set_index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        Errors\n                                                        "
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column",
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "sort-key",
                                                              class:
                                                                _vm.sort_conn_index ==
                                                                  conn_index &&
                                                                _vm.sort_set_index ==
                                                                  set_index &&
                                                                _vm.sort_key ==
                                                                  "sync_errors" &&
                                                                _vm.sort_order ==
                                                                  "asc"
                                                                  ? "sort-key-active"
                                                                  : "",
                                                              attrs: {
                                                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              staticClass:
                                                                "sort-key",
                                                              class:
                                                                _vm.sort_conn_index ==
                                                                  conn_index &&
                                                                _vm.sort_set_index ==
                                                                  set_index &&
                                                                _vm.sort_key ==
                                                                  "sync_errors" &&
                                                                _vm.sort_order ==
                                                                  "des"
                                                                  ? "sort-key-active"
                                                                  : "",
                                                              attrs: {
                                                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "table-column-6",
                                                staticStyle: { width: "182px" },
                                              },
                                              [_vm._v("Action")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        {
                                          staticClass: "table-border-bottom-0",
                                        },
                                        _vm._l(
                                          set.segments,
                                          function (segment, seg_index) {
                                            return _c(
                                              "tr",
                                              {
                                                key: segment.id,
                                                attrs: {
                                                  id:
                                                    "conn-" +
                                                    conn.id +
                                                    "-segment-row-" +
                                                    segment.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "align-start",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "/audience/" +
                                                            _vm.client.url +
                                                            "/" +
                                                            segment.id,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              segment.name
                                                            ) +
                                                            "\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "number_with_zero"
                                                        )(
                                                          _vm.getSize(
                                                            segment,
                                                            conn.id
                                                          )
                                                        )
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                !_vm.isFile(conn)
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "align-start",
                                                      },
                                                      [
                                                        segment.sync_active == 1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "badge badge-success ms-2",
                                                              },
                                                              [_vm._v("Active")]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "badge badge-danger ms-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Inactive"
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "align-start",
                                                      },
                                                      [
                                                        segment.sync_active == 1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "badge badge-success ms-2",
                                                              },
                                                              [_vm._v("True")]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "badge badge-secondary ms-2",
                                                              },
                                                              [_vm._v("False")]
                                                            ),
                                                      ]
                                                    ),
                                                _vm._v(" "),
                                                !_vm.isFile(conn)
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "align-start",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm._f("date")(
                                                                segment.last_synced
                                                              )
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.isFile(conn)
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "align-start",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              segment.sync_errors
                                                                ? segment.sync_errors
                                                                : "-"
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-start show-on-row-hover",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-none me-1",
                                                        attrs: {
                                                          disabled:
                                                            _vm.export_busy[
                                                              conn.id +
                                                                "_" +
                                                                segment.id
                                                            ] != null &&
                                                            _vm.export_busy[
                                                              conn.id +
                                                                "_" +
                                                                segment.id
                                                            ] == true,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.exportSegment(
                                                              conn.id,
                                                              segment
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.export_busy[
                                                          conn.id +
                                                            "_" +
                                                            segment.id
                                                        ] == null ||
                                                        _vm.export_busy[
                                                          conn.id +
                                                            "_" +
                                                            segment.id
                                                        ] == false
                                                          ? _c("img", {
                                                              staticClass:
                                                                "icon",
                                                              attrs: {
                                                                src: "/img/icons/dialexa-icons/file-download.svg",
                                                                "data-bs-toggle":
                                                                  "tooltip",
                                                                "data-bs-placement":
                                                                  "bottom",
                                                                title:
                                                                  "Export Audience",
                                                              },
                                                            })
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "spinner-border spinner-border-sm ms-2",
                                                                attrs: {
                                                                  role: "status",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "visually-hidden",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Loading..."
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    segment.sync_active != 0
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "btn btn-none me-1",
                                                            attrs: {
                                                              href:
                                                                "/pathways/" +
                                                                _vm.client.url +
                                                                "/" +
                                                                conn.id +
                                                                "/mapping/" +
                                                                set.data_source +
                                                                "/" +
                                                                set.id +
                                                                "/" +
                                                                segment.id,
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "icon",
                                                              attrs: {
                                                                src: "/img/icons/dialexa-icons/map.svg",
                                                                "data-bs-toggle":
                                                                  "tooltip",
                                                                title:
                                                                  _vm.isFile(
                                                                    conn
                                                                  )
                                                                    ? "Change Export Columns"
                                                                    : "Map Audience to Destination",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.isFile(conn)
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-none me-1",
                                                            attrs: {
                                                              disabled:
                                                                _vm.sync_busy[
                                                                  conn.id +
                                                                    "_" +
                                                                    segment.id
                                                                ] != null &&
                                                                _vm.sync_busy[
                                                                  conn.id +
                                                                    "_" +
                                                                    segment.id
                                                                ] == true,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.toggleSyncSegment(
                                                                  conn,
                                                                  segment
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.sync_busy[
                                                              conn.id +
                                                                "_" +
                                                                segment.id
                                                            ] == null ||
                                                            _vm.sync_busy[
                                                              conn.id +
                                                                "_" +
                                                                segment.id
                                                            ] == false
                                                              ? _c("span", [
                                                                  segment.sync_active ==
                                                                  0
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "icon",
                                                                          attrs:
                                                                            {
                                                                              src: "/img/icons/dialexa-icons/sync.svg",
                                                                              "data-bs-toggle":
                                                                                "tooltip",
                                                                              title:
                                                                                "Activate Sync",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "icon",
                                                                          attrs:
                                                                            {
                                                                              src: "/img/icons/dialexa-icons/forbidden.svg",
                                                                              "data-bs-toggle":
                                                                                "tooltip",
                                                                              title:
                                                                                "Deactivate Sync",
                                                                            },
                                                                        }
                                                                      ),
                                                                ])
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "spinner-border spinner-border-sm ms-2",
                                                                    attrs: {
                                                                      role: "status",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "visually-hidden",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Loading..."
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }