<template>
    <div>
        <div class="report-builder-filter-settings">
            <h5 class="mt-4">Report Filter Settings</h5>
            
            <div class="card box-content mt-2" >
                <div class="card-body">                            
                    <div class="row">
                        <div class="col-12">
                            <nav class="bg-secondary mt-0 rounded">
                                <div class="d-flex pt-3 px-2">            
                                    <span class="py-1 ms-1 me-3 mb-0 h5 text-nowrap">Static Page Filters:</span>
                                    <div class="input-group" >
                                        <v-select id="page-filter-dropdown" :options="page_filter_options" placeholder="Select the Filter to add to Report..."
                                            :searchable="true" class="searchable-select input_v1 column-display w-50"
                                            :selectable="(option) => option.text != ''"
                                            label="text" @search="searchPageFilterOption"
                                            @input="emitPageFilterChange"
                                            :filterable="false">
                                            <template #selected-option="{ text, category }">
                                                <span>{{"Select the Filter to add to the report..."}}</span>
                                            </template>

                                            <template #option="{ text, category }">
                                                <div class="suboption" :data-subcategory="category"
                                                    :class="category==null || category==''? 'show' : ''">
                                                    {{ text }}
                                                </div>
                                            </template>
                                            
                                            <template #no-options="{ search, searching, loading }">
                                                <div class="suboption show">
                                                    <em>No results found</em>
                                                </div>
                                            </template>
                                        </v-select>

                                        <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#page-filter-modal" @click="addPageFilter()" >
                                            <i class="fa fa-btn fa-plus"></i>
                                        </button>                                                     
                                    </div>
               
                                </div>    
                                    
                                <div class="d-flex p-2">
                                    <span class="badge badge-dark me-2" v-for="(filter,index) in page_level_filters_list">
                                        &nbsp;{{ filter.admin_name }} <i class="fa fa-remove" id="remove-filter" @click="removePageFilterByIndex(index)"></i>
                                    </span>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <nav class="navbar navbar-expand bg-secondary mt-2 rounded">
                                <div class="container-fluid">            
                                    <span class="navbar-brand mb-0 ms-1 h5">User-Defined Filters:</span>
                                    <div class="collapse navbar-collapse" id="filter-navbar">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li class="nav-item dropdown me-3 d-flex" v-for="(group, group_index) in dimensional_filters_list">
                                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                                                    <label class="form-label">{{ group.group_name }}</label>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-overflow px-2">
                                                    <div class="mb-2" v-for="(filter,filter_index) in group.filters">
                                                        <div class="d-flex">
                                                            <div>
                                                                <span class="text-nowrap">
                                                                    <label class="form-label">{{ filter.label }} </label>
                                                                    <i class="fa fa-remove mt-1 ms-1" id="remove-filter" @click.prevent="editFilterWithinGroup(filter_index, group_index, 'Delete')"></i>
                                                                </span>
                                                                <button class="btn form-select btn-field min-width-125" type="button" 
                                                                data-bs-toggle="modal" data-bs-target="#dimension-filter-modal" @click="editFilterWithinGroup(filter_index, group_index, 'Update')">
                                                                    <span class="selected-value"><i class="fa fa-btn fa-edit"></i><span>Edit Filter</span></span>     
                                                                </button>                                                            
                                                            </div>
                                                        
                                                            <div class="reorder-btn-containter">
                                                                <button v-if="filter_index!=0" type="button" class="reorder-btn-filter-dropdown mt-1 mb-2" v-on:click="moveFilterUpInGroup(group_index, filter_index)"><i class="fa fa-arrow-up"></i></button>
                                                                <button v-if="filter_index!=group.filters.length-1" type="button" class="reorder-btn-filter-dropdown" v-on:click="moveFilterDownInGroup(group_index, filter_index)"><i class="fa fa-arrow-down"></i></button>
                                                            </div>
                                                        </div>
                                                        <hr class="mt-2 section-divider">

                                                       

                                                    </div>

                                                    <button class="btn btn-secondary"  data-bs-toggle="modal" data-bs-target="#dimension-filter-modal" @click="editFilterWithinGroup(null, group_index, 'Add')">
                                                        <i class="fa fa-btn fa-plus"></i> Add Filter
                                                    </button>
                                                </div>
                                                <span class="mt-2">
                                                    <a href="#">
                                                        <i class="fa fa-edit" data-bs-toggle="modal" data-bs-target="#filter-group-modal" @click="editFilterGroup(group_index, 'Update')"></i>
                                                    </a>
                                                </span>
                                                <span class="mt-2" v-if="group.filters.length == 0">
                                                    <a href="#">
                                                    <i class="fa fa-remove" @click="editFilterGroup(group_index, 'Delete')"></i>
                                                    </a>
                                                </span>
                                            </li>

                                            <li class="nav-item float-end">
                                                <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#filter-group-modal" @click="editFilterGroup(null, 'Add')">
                                                    <i class="fa fa-btn fa-plus"></i> Add Filter Group
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>  

        <!-- User-Defined Filter Modal - Grouped -->
        <div class="modal fade" id="dimension-filter-modal" aria-labelledby="dimension-filter-modal" data-bs-backdrop="static" aria-hidden="true">
            <report-builder-udf-popup :action="action_for_filter_in_group" :client_id="report_page.client_id"
                :dimensional_filter="dimensional_filter" :data_blend="data_blend" :metrics_filters="local_metrics_filters" @updateFilterWithinGroup="updateFilterWithinGroup"></report-builder-udf-popup>
        </div>


        <!-- Filter Group Modal -->
        <div class="modal fade" id="filter-group-modal" aria-labelledby="filter-group-modal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" data-bs-dismiss="filter-group-modal" @click.prevent="closeModal('filter-group-modal')">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">{{ this.action_for_filter_group }} a Filter Group</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 ">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Filter Group Name</label>
                                    <input type="text" id="filter-group-name-input" class="form-control" v-model="dimensional_filter_group.group_name"
                                        :class="((local_filter_group_errors.group_name != '' )? 'is-invalid':'')" @change="clearErrorForGroupField('group_name')">
                                    <div class="text-danger invalid-feedback" v-if="local_filter_group_errors.group_name != ''">
                                        {{local_filter_group_errors.group_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-secondary" id="save-button"
                                            @click.prevent="updateFilterGroup()"
                                            :disabled="form.busy">
                                        <i class="fa fa-btn" :class="((action_for_filter_group == 'Add' ? 'fa-plus': 'fa-save'))"></i> {{ this.action_for_filter_group }} Filter Group
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="page-filter-modal" aria-labelledby="page-filter-modal"  data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end close-modal"
                                data-bs-dismiss="page-filter-modal" @click="closeModal('page-filter-modal')">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">Filter Builder</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <metric-filter-builder :data_blend="data_blend" :modal_id="'page-filter-modal'" :client_id="report_page.client_id"
                            ref="mf_builder" :type="'filter'" @update="pageFilterAdded"></metric-filter-builder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import vSelect from "vue-select";
import draggable from 'vuedraggable';
import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
            Swal, vSelect, draggable, Datepicker
        },
        props: ['report_page', 'data_blend', 'metrics_filters'],
        data() {
            return {
                columns: [],
                local_metrics_filters: [],
                dimensional_filters_list:[], 
                filter_index_within_group: null, // keep track of which filter within its group
                group_index: null, // keep track of which group to interact
                action_for_filter_in_group: null,
                action_for_filter_group: null,
                
                dimensional_filter: {
                    label: '',
                    field_type: '',
                    is_required: false,
                    column: null,
                    default_value: [],
                    order: null
                },
                dimensional_filter_group:{
                    group_name: '',
                    filters:[]
                },
                local_filter_group_errors: {
                    group_name: '',
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                page_level_filters_list:[],
                page_filter_options:[],
                // dragging: false  // For draggable
            };
        },
        beforeMount() {
            this.dimensional_filters_list = [];
            this.local_metrics_filters = [...this.metrics_filters];
            this.page_filter_options = this.getAllPageFilterOptions();

            //Get the filters into the right format
            if(this.report_page.filters != null){
                for(let i = 0; i < this.report_page.filters.length; i++){
                    //Find the right filter
                    var f = this.local_metrics_filters.find((filter)=>filter.id == this.report_page.filters[i].report_metrics_filters_id);
                    //If it still exists, add it to the UI
                    if(f != null ) {
                        this.page_level_filters_list.push(f);
                        var val = this.page_level_filters_list[this.page_level_filters_list.length-1].id;
                        this.page_filter_options = this.page_filter_options.filter(option => option.value != val);
                    }
                }
            }
            //Get the filters into the right format
            if(this.report_page.dimensions != null){
                var group = {
                    group_name: '',
                    filters:[]
                };
                for(let i = 0; i < this.report_page.dimensions.length; i++){
                    if(group.group_name != this.report_page.dimensions[i].filter_group){
                        //Add to the list
                        if(group.group_name != ''){
                            this.dimensional_filters_list.push(group);
                        }
                        group = {
                            group_name: this.report_page.dimensions[i].filter_group,
                            filters:[]
                        };
                    }
                    //Add the filter
                    let f = {
                        id: this.report_page.dimensions[i].id,
                        label: this.report_page.dimensions[i].label,
                        field_type: this.report_page.dimensions[i].field_type,
                        is_required: this.report_page.dimensions[i].is_required,
                        column: this.report_page.dimensions[i].column,
                        default_value: this.report_page.dimensions[i].default_value,
                        order: this.report_page.dimensions[i].order
                    };
                    group.filters.push(f);
                }
                if(group.filters.length > 0){
                    this.dimensional_filters_list.push(group);
                    this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                }
            }

        },
        methods: {

            getAllPageFilterOptions(){
                var filter_list = [];
                this.local_metrics_filters.forEach((mf)=>{
                    // push if the mf not in the selected list
                    if (this.page_level_filters_list.indexOf(mf) < 0){
                        if(mf.type=='filter' && mf.data_blend_type == this.report_page.data_blend_type){
                            filter_list.push({
                                category:"",
                                text: mf.admin_name,
                                type: mf.format,
                                value: mf.id,
                            })
                        }
                    }
                });
                return filter_list;
            },
            searchPageFilterOption(search_key){
                var option_list = this.getAllPageFilterOptions();
                if(search_key == "") 

                    this.page_filter_options = option_list;
                else    
                    this.page_filter_options = option_list.filter(option => option.text.toLowerCase().includes(search_key.toLowerCase()));
            },
            addPageFilter() {
                // this.local_type = type;
                this.$refs.mf_builder.setMetricFilter(null);
            },
            pageFilterAdded(mf) {
                //Add to the array and re-sort
                //Look through local_mfs to see if the ID is already in there
                var found = false;
                for(var i = 0; i < this.page_filter_options.length; i++){
                    if(this.page_filter_options[i].id == mf.id){
                        this.page_filter_options[i] = mf;
                        found = true;
                        break;
                    }
                }

                if(!found)
                    this.local_metrics_filters.push(mf);

                // sort this.local_metric_filter
                this.local_metrics_filters.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

                //reload page filter option list
                this.page_filter_options = this.getAllPageFilterOptions();
                this.closeModal('page-filter-modal');
            },
            emitPageFilterChange(event){
                var filter_object = this.local_metrics_filters.find((filter)=>filter.id == event.value);
                this.page_level_filters_list.push(filter_object);
                this.page_filter_options = this.page_filter_options.filter(option => option.value != event.value);
                this.$emit('updatePageFilters', this.page_level_filters_list);
            },
            removePageFilterByIndex(index){
                this.page_level_filters_list.splice(index, 1);
                // reset option dropdown list
                this.page_filter_options = this.getAllPageFilterOptions();
                this.$emit('updatePageFilters', this.page_level_filters_list);
            },
            editFilterGroup(group_index, action){
                if(action == 'Delete'){
                    this.dimensional_filters_list.splice(group_index, 1);
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Filter Group Deleted!</h4>',
                        target: '.report-builder-filter-settings',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                    this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                } else{ // update or add
                    this.group_index = group_index;
                    this.action_for_filter_group = action;                    
                }
                if(action == 'Update'){
                    this.dimensional_filter_group = this.dimensional_filters_list[group_index];
                }
            },
            editFilterWithinGroup(filter_index, group_index, action){
                if(action == 'Delete'){
                    this.dimensional_filters_list[group_index].filters.splice(filter_index, 1);
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Filter Deleted!</h4>',
                        target: '.report-builder-filter-settings',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                    this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                    this.reorderFiltersInGroup(group_index);
                } else {
                    this.filter_index_within_group = filter_index
                    this.group_index = group_index;
                    this.action_for_filter_in_group = action;
                    if(filter_index != null){
                        this.dimensional_filter = {...this.dimensional_filters_list[group_index].filters[filter_index]};
                        if(this.dimensional_filter.default_value == null && (this.dimensional_filter.field_type == 'date_range' || this.dimensional_filter.field_type == 'month_range')){
                            this.dimensional_filter.default_value = {
                                start_date: null,
                                end_date: null
                            }
                        }
                    }
                }
            },
            reorderFiltersInGroup(group_index){
                this.dimensional_filters_list[group_index].filters.forEach((filter, index) => {
                    filter.order = index + 1;
                })
            },
            moveFilterUpInGroup(group_index,filter_index){
                var current_filter = {...this.dimensional_filters_list[group_index].filters[filter_index]};
                var previous_filter = {...this.dimensional_filters_list[group_index].filters[filter_index-1]};

                current_filter.order = this.dimensional_filters_list[group_index].filters[filter_index-1].order;
                previous_filter.order = this.dimensional_filters_list[group_index].filters[filter_index].order;

                // switch the two filter' order on the UI
                this.dimensional_filters_list[group_index].filters[filter_index-1] = current_filter;
                this.dimensional_filters_list[group_index].filters[filter_index] = previous_filter;
                this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                this.$forceUpdate();
            },
            moveFilterDownInGroup(group_index, filter_index){
                var current_filter = {...this.dimensional_filters_list[group_index].filters[filter_index]};
                var next_filter = {...this.dimensional_filters_list[group_index].filters[filter_index+1]};

                current_filter.order = this.dimensional_filters_list[group_index].filters[filter_index+1].order;
                next_filter.order = this.dimensional_filters_list[group_index].filters[filter_index].order;

                // switch the two filter' order on the UI
                this.dimensional_filters_list[group_index].filters[filter_index+1] = current_filter;
                this.dimensional_filters_list[group_index].filters[filter_index] = next_filter;
                this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                this.$forceUpdate();
            },
            resetFilterFields(){
                this.dimensional_filter = {
                    label: '',
                    field_type: '',
                    is_required: false,
                    column: null,
                    default_value: null,
                    order: null
                }
            },
            resetFilterGroupFields(){
                this.dimensional_filter_group = {
                    group_name: '',
                    filters:[]
                }; 
            },
            hasGroupErrors(){
                if(this.dimensional_filter_group.group_name == null || this.dimensional_filter_group.group_name == ""){
                    this.local_filter_group_errors.group_name = "A group name is required.";
                } else
                    this.local_filter_group_errors.group_name = "";

                if (this.local_filter_group_errors.group_name!=""){
                    return true
                } else {
                    return false
                }
            },
            updateFilterWithinGroup(dim_filter){
                this.dimensional_filter = dim_filter;

                if(this.action_for_filter_in_group == 'Add'){
                    if (this.dimensional_filter.field_type =='date_range' || this.dimensional_filter.field_type =='month_range'){
                        this.dimensional_filter.default_value = {
                            start_date: this.dimensional_filter.start_date,
                            end_date: this.dimensional_filter.end_date
                        }
                    } 
                    this.dimensional_filter.order = this.dimensional_filters_list[this.group_index].filters.length + 1;
                    this.dimensional_filters_list[this.group_index].filters.push({...this.dimensional_filter}); 
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Filter Added!</h4>',
                        target: '.report-builder-filter-settings',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                } else {
                    this.dimensional_filters_list[this.group_index].filters[this.filter_index_within_group] = {...this.dimensional_filter}; 
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Filter Updated!</h4>',
                        target: '.report-builder-filter-settings',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                }
                this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                this.closeModal('dimension-filter-modal');
                
                // this.$forceUpdate()
            },
            updateFilterGroup(){
                this.clearGroupErrors();
                if(this.hasGroupErrors()) {
                    return;
                }
                if(this.action_for_filter_group == 'Add'){
                    this.dimensional_filters_list.push(this.dimensional_filter_group); 
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Filter Group Added!</h4>',
                        target: '.report-builder-filter-settings',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                } else {
                    this.dimensional_filters_list[this.group_index]= this.dimensional_filter_group; 
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Filter Group Updated!</h4>',
                        target: '.report-builder-filter-settings',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                }
                this.$emit('updateUserDefinedFilters', this.dimensional_filters_list);
                this.closeModal('filter-group-modal');
            },
            clearErrorForGroupField(field_name){
                this.local_filter_group_errors[field_name] = '';
            },
            clearGroupErrors(){
                this.local_filter_group_errors = {
                    group_name: '',
                }
            },
            closeModal(modal_ID){
                if (modal_ID=='dimension-filter-modal')
                    this.resetFilterFields();
                if (modal_ID=='filter-group-modal')
                    this.resetFilterGroupFields();
                var myModalEl = document.getElementById(modal_ID)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
        }
    }
</script>
