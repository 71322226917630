<template>
    <div id="data-blend" aria-labelledby="data-blend" aria-hidden="true">
        <div class="card box-content mt-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">Data Blend Type</label>
                            <select class="form-select" :class="((errors.data_blend_type != '' )? 'is-invalid':'')" :disabled="(mf_selected.id!=-1)"
                            id="data-blend-type-select" v-model="local_mf_selected.data_blend_type" v-on:change="changeDataBlendType()">
                                <option value="" disabled selected>Select the data blend type...</option>
                                <option value="constituent">Constituent</option>
                                <option value="subscriber">Subscriber</option>
                                <option value="paid_media">Paid Media</option>
                                <option value="benchmark">Benchmark</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.data_blend_type != ''">
                                {{errors.data_blend_type}}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">Client (optional)</label>
                            <select class="form-select" id="client-select" v-model="local_db_selected" :disabled="(mf_selected.id!=-1)"
                            v-on:change="changeDataBlendSelection()" >
                                <option value="" selected>Select the client...</option>
                                <option v-for="i in this.clients_filtered_by_data_blend_type" v-bind:value="i">{{i.client.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">Name</label>
                            <input type="text" id="name-input" class="form-control" v-model="local_mf_selected.name"
                                :class="((errors.name != '' )? 'is-invalid':'')" >
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name }}
                            </div>
                        </div>
                    </div>

                    <div v-if="this.local_mf_selected.type == 'metric'" class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">Format</label>
                            <select class="form-select" :class="((errors.format != '' )? 'is-invalid':'')"
                            id="format-select" v-model="local_mf_selected.format" >
                                <option value="" disabled selected>Select the format...</option>
                                <option value="money">Money</option>
                                <option value="percent">Percent</option>
                                <option value="number">Number</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.format != ''">
                                {{errors.format}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="mb-3">
                            <label class="form-label">Formula</label>
                            <div class="input-group formula-input-group" >
                                <input type="text" class="form-control col-9" :class="((errors.formula != '' )? 'is-invalid':'')"
                                id="formula-input" v-model="local_mf_selected.formula" >
                               
                                <v-select :options="local_columns" v-model="column_selected"
                                    :searchable="true" class="searchable-select col-3"
                                    :selectable="(option) => option.text != ''"
                                    label="text" @search="fetchOptions"
                                    @input="addColumnToText(column_selected)"
                                    :filterable="false">
                                    <template #selected-option="{ text, category }">
                                    {{(category != null&& category != ''&& text!=null && text!="") ? category + ": " : ""}}{{text}}
                                    </template>

                                    <template #open-indicator="{ attributes }">
                                    <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                                    </template>

                                    <template #option="{ text, category }">
                                    <div v-if="text == ''" :data-category="category" class="category-header"
                                        v-on:click="return expandCategory(category, null)"
                                        data-isexpanded="false">
                                        {{ category }} <i class="fa-solid fa-caret-right"></i>
                                    </div>
                                    <div v-else class="suboption" :data-subcategory="category"
                                        :class="category==null || category==''? 'show' : ''">
                                        {{ text }}
                                    </div>
                                    </template>
                                    <template #no-options="{ search, searching, loading }">
                                        <div class="suboption show" v-if="is_loading">
                                            <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                        </div>
                                        <div class="suboption show" v-else>
                                            <em>No results found</em>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="text-danger invalid-feedback" v-if="errors.formula != ''">
                                {{errors.formula}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <!-- Success Message -->
                <div class="alert alert-success mt-3" v-if="form.successful">
                    The {{this.mf_selected.type}}'s information has been updated!
                </div>
                <div class="alert alert-danger mt-3" v-if="form.error">
                    There was an error saving the {{this.mf_selected.type}} information.
                </div>
                <!-- Update Button -->
                <div class="mt-3">
                    <button type="submit" class="btn btn-primary" id="save-button"
                            @click.prevent="update"
                            :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> {{ (this.local_mf_selected.id > 0 ? "Update" : "Add" ) }}
                    </button>
                    <button type="button" class="btn btn-secondary" id="delete-button" v-on:click="deleteMetricFilter()"
                        style="margin-left:10px;" v-if="this.local_mf_selected.id > 0">
                        <i class="fa fa-btn fa-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import vSelect from "vue-select";
    export default {
        components: {
            Swal, vSelect
        },
        props: ['mf_selected', 'data_blends', 'columns'],
        data() {
            return {
                local_mf_selected: {},
                mf_pos: 0,
                local_db_selected:{}, // data_blend_selected
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    name: '',
                    data_blend_type: '',
                    format: '',
                    formula: '',     
                },
                clients_filtered_by_data_blend_type:[],                
                local_columns:[], //columns_filtered_by_data_blend_type,
                column_selected: null,
                is_loading: false,
            };
        },
        mounted() {
            // this.local_mfs = this.metrics_filters;
            this.local_mf_selected = this.mf_selected;
            this.loadColumnOptions()
            this.getClientList()
            // this.local_data_blends = this.data_blends;
        },
        watch:{
            mf_selected(old_value, new_value){
                this.local_db_selected = {}
                this.local_mf_selected = this.mf_selected
                if(this.mf_selected.data_blend_id!= -1){
                    this.getClientList()      
                }
            }
        },
        methods: {
            loadColumnOptions(){
                if (this.local_mf_selected.data_blend_type =="constituent"){
                    this.local_columns = this.getFormattedColumns(this.columns.Constituents); // default to use consituent columns
                }
            },
            changeDataBlendType(){ // clear data_blend information everytime changing the data_blend_type
                this.local_db_selected = {}
                this.local_mf_selected.data_blend_id = null
                this.local_mf_selected.client_id = null
                this.getClientList() 
                
                //TODO refactor later
                if(this.local_mf_selected.data_blend_type =='constituent'){
                  this.local_columns = this.columns.Constituents  
                } else if(this.local_mf_selected.data_blend_type =='subscriber'){
                  this.local_columns = this.columns.Subscriber  
                } else if(this.local_mf_selected.data_blend_type =='paid_media'){
                  this.local_columns = this.columns.Paid_media  
                } else if(this.local_mf_selected.data_blend_type =='benchmark'){
                  this.local_columns = this.columns.Benchmark  
                }
            },
            changeDataBlendSelection(){
                if(this.local_db_selected != undefined){
                    if(this.local_db_selected ==""){
                        this.local_mf_selected.data_blend_id = null
                        this.local_mf_selected.client_id = null
                    } else{
                        this.local_mf_selected.data_blend_id = this.local_db_selected.id
                        this.local_mf_selected.client_id = this.local_db_selected.client.id                    
                    }
                }
            },
            getClientList(){
                var data_blends_list = [];
                for (let i in this.data_blends) {
                    if (this.data_blends[i].type == this.local_mf_selected.data_blend_type)
                        data_blends_list.push(this.data_blends[i]);
                    if (this.data_blends[i].id == this.local_mf_selected.data_blend_id)
                        this.local_db_selected = this.data_blends[i]
                }
                this.clients_filtered_by_data_blend_type = data_blends_list;
            },
            addColumnToText(valueToAdd) {
                var textField = document.getElementById('formula-input');

                // Get the cursor's position
                const startPos = textField.selectionStart;
                const endPos = textField.selectionEnd;

                // Get the current value of the text field
                const currentValue = textField.value;

                // Construct the new value with the added text
                const newValue = currentValue.substring(0, startPos) + valueToAdd.value + currentValue.substring(endPos);

                // Set the new value to the text field
                textField.value = newValue;

                // Move the cursor after the added text
                const newCursorPos = startPos + valueToAdd.value.length;
                textField.setSelectionRange(newCursorPos, newCursorPos);

                // // Trigger any necessary events, e.g., input or change
                // const inputEvent = new Event('input');
                // textField.dispatchEvent(inputEvent);

                this.local_mf_selected.formula = newValue;
            },
            getFormattedColumns(db_columns) {
                var columns = [];

                var old_category = '';
                for(var i in db_columns) {
                    let key = db_columns[i].value;
                    let text = db_columns[i].text;
                    let category = db_columns[i].category;

                    if(category != old_category)
                        columns.push({
                            value: "DIVIDER-"+ category,
                            category: category,
                            text: ""
                        });
                   
                    old_category = category;

                    columns.push({
                        value: key,
                        text: text,
                        category: category
                    });
                }
                return columns;
            },
            expandCategory(cat, expand) {
                var headline = document.querySelector("div[data-category='"+cat+"']");
                var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");

                if(headline == undefined || lines == undefined)
                    return false;

                if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="block";
                    var divs = headline.getElementsByClassName("fa-caret-right");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-down");
                        divs[i].classList.remove("fa-caret-right");
                    }
                    headline.dataset.isexpanded = true;
                }
                else {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="none";
                    var divs = headline.getElementsByClassName("fa-caret-down");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-right");
                        divs[i].classList.remove("fa-caret-down");
                    }
                    headline.dataset.isexpanded = false;
                }

                return false;
            },

            fetchOptions (search, loading) {
                //Reset the array
                // this.local_columns = JSON.parse(JSON.stringify(this.columns));

                if(search == "") {
                    this.loadColumnOptions()
                    return;
                }
                    
                this.is_loading = true;

                //Look at each column
                for(var i = this.local_columns.length-1; i >= 0 ; i--) {
                    //If the search string isn't in the text or the category
                    if(this.local_columns[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                        && (this.local_columns[i].category == null
                        || this.local_columns[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                        && this.local_columns[i].text != "" ) //And not a category divider

                        this.local_columns.splice(i, 1);
                }

                //Get the remaining categories
                var cats = [];
                for(var i = 0; i < this.local_columns.length; i++)
                    if(this.local_columns[i].category != null && !cats.includes(this.local_columns[i].category) && this.local_columns[i].text != "")
                        cats.push(this.local_columns[i].category);

                //Expand the categories
                for(var i = 0; i < cats.length; i++)
                    this.expandCategory(cats[i], true);

                //Remove a category if it isn't in the array of categories
                for(var i = this.local_columns.length-1; i >= 0 ; i--)
                    if(this.local_columns[i].text == "" && this.local_columns[i].category != null && !cats.includes(this.local_columns[i].category))
                        this.local_columns.splice(i, 1);

                this.is_loading = false;
                return true;
            },
            clearErrors() { //reset errors to empty strings
                this.errors = {
                    data_blend_type: '',
                    name:'',
                    format: '',
                    formula: '',     
                }
            },
            hasErrors() {//validate the form
                if(this.local_mf_selected.data_blend_type == null || this.local_mf_selected.data_blend_type == ""){
                    this.errors.data_blend_type = "Data Blend Type is required.";
                    return true
                } else
                    this.errors.data_blend_type = "";
                
                if(this.local_mf_selected.name == null || this.local_mf_selected.name == ""){
                    this.errors.data_blend_type = "Name is required.";
                    return true
                } else
                    this.errors.name = "";

                if(this.local_mf_selected.type == 'metric' && (this.local_mf_selected.format == null || this.local_mf_selected.format == "")){
                    this.errors.format = "Format is required for metric.";
                    return true
                } else
                    this.errors.format = "";

                if(this.local_mf_selected.formula == null || this.local_mf_selected.formula == ""){
                    this.errors.formula = "Formula is required.";
                    return true
                } else
                    this.errors.formula = "";
                
                return false
            },
            update() {
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }

                this.form.busy = true;

                //Make a new chart
                if(this.local_mf_selected.id == -1) {

                    window.axios.post('/api/metric_filter', this.local_mf_selected)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            self.$emit('addMF', response.data.metric_filter);
                            setTimeout( function() {self.form.successful = false;} , 5000);
                            
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }
                else {
                    window.axios.put('/api/metric_filter/' + this.local_mf_selected.id, this.local_mf_selected)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            self.$emit('updateMF', response.data.metric_filter);
                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }

            },
            deleteMetricFilter() {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete " + self.local_mf_selected.name + "?",
                  text: "This "+self.local_mf_selected.type+" will be removed from the available list and all charts that use it will be deleted.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/metric_filter/' + this.local_mf_selected.id)
                        .then(response => {
                            if (response.status == 200) {
                                self.form.successful = true;
                                self.$emit('deleteMF', this.local_mf_selected.id);
                                setTimeout( function() {self.form.successful = false} , 5000);
                            }
                            else {
                                self.form.error = true;
                                setTimeout( function() {self.form.error = false} , 10000);
                        }
                        });
                  }
                });
            }
        }
    }
</script>
