var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "top-nav navbar navbar-expand-md" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "navbar-nav flex-row" },
          [
            _vm.clients.length > 1
              ? _c("v-select", {
                  staticClass:
                    "searchable-select column-display client-select w-100",
                  attrs: {
                    options: _vm.local_clients,
                    name: "org_name",
                    id: "org_search",
                    filterable: false,
                    searchable: true,
                  },
                  on: { input: _vm.selectOrg, search: _vm.fetchClientOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function ({ value, label }) {
                          return [
                            _c("li", { staticClass: "suboption show" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(label) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "no-options",
                        fn: function ({ search }) {
                          return [
                            _c("div", { staticClass: "suboption show mt-1" }, [
                              _c("em", [
                                _vm._v("No organizations match your search"),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2524250131
                  ),
                  model: {
                    value: _vm.local_client,
                    callback: function ($$v) {
                      _vm.local_client = $$v
                    },
                    expression: "local_client",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "navbar-nav ms-auto flex-row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass:
                "nav-item dropdown text-center mx-2 mt-2 alert-dropdown icon-nav",
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle no-toggle",
                  attrs: {
                    id: "alert-dropdown",
                    href: "#",
                    type: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                    "data-bs-auto-close": "outside",
                  },
                },
                [
                  _c("img", {
                    staticClass: "icon",
                    staticStyle: { height: "24px" },
                    attrs: { src: "/img/icons/dialexa-icons/bell.svg" },
                  }),
                  _vm._v(" "),
                  _vm.notif != null && _vm.notif.length > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "position-absolute translate-middle badge rounded-pill bg-danger",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.num_notifications) +
                              "\n                        "
                          ),
                          _c("span", { staticClass: "visually-hidden" }, [
                            _vm._v("unread messages"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-end position-absolute pb-3",
                  class: _vm.notif.length <= 3 ? "pt-3" : " pt-0",
                  attrs: {
                    id: "notification-dropdown",
                    "aria-labelledby": "alert-dropdown",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "px-3 pt-3 mb-3 border-bottom bg-secondary",
                    },
                    [
                      _vm.notif.length > 3
                        ? _c(
                            "a",
                            {
                              staticClass: "float-end",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.dismissAll()
                                },
                              },
                            },
                            [_vm._v("Mark All As Read")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h4", [_vm._v("Notifications")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    { attrs: { name: "fade-slow" } },
                    _vm._l(_vm.notif, function (n) {
                      return _c(
                        "div",
                        {
                          key: n.id,
                          staticClass: "dropdown-item",
                          class: n.type.split("\\").pop(),
                        },
                        [
                          _c("h5", { staticClass: "float-end lh-1" }, [
                            _vm._v(
                              _vm._s(_vm._f("date")(n.created_at)) +
                                " \n                                "
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-none btn-sm ms-1 p-0",
                                on: {
                                  click: function ($event) {
                                    return _vm.dismiss(n)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-close" })]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mb-2 mt-1" }, [
                            _vm._v(_vm._s(n.data["client_name"])),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex w-100" }, [
                            _c("div", { staticClass: "notification-icon" }, [
                              _c("img"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ms-2" }, [
                              _c("p", [_vm._v(_vm._s(n.data["error_msg"]))]),
                              _vm._v(" "),
                              n.data["cta"] != null
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-secondary me-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.markRead(n)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(n.data["cta"]))]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.notif == null || _vm.notif.length == 0
                    ? _c("div", { staticClass: "dropdown-item w-auto" }, [
                        _vm._v("No Notifications"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item dropdown text-center mx-2 mt-2 text-nav" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-end position-absolute",
                  staticStyle: { "z-index": "1003" },
                  attrs: { "aria-labelledby": "profile-avatar" },
                },
                [
                  _vm.user.user_type == "admin" ||
                  _vm.user.user_type == "agency_admin"
                    ? _c("span", [
                        _vm.user.user_type == "agency_admin"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item new-feature",
                                attrs: { href: "/admin/agency/profile" },
                              },
                              [
                                _c("i", { staticClass: "fas fa-building" }),
                                _vm._v(" Agency Settings"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user.user_type == "admin"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/admin/manage_client" },
                              },
                              [
                                _c("i", { staticClass: "fas fa-building" }),
                                _vm._v(" Agency Clients"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user.user_type == "admin"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/admin/manage_user" },
                              },
                              [
                                _c("i", { staticClass: "fas fa-user-shield" }),
                                _vm._v(" Agency Users"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user.user_type == "admin"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/campaigns/admin/list" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-solid fa-bullseye",
                                }),
                                _vm._v(" Campaign Editor"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._m(2),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.user_type == "admin"
                    ? _c("span", [
                        _c("div", { staticClass: "dropdown-divider" }),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _vm._m(4),
                        _vm._v(" "),
                        _vm._m(5),
                        _vm._v(" "),
                        _c("div", { staticClass: "dropdown-divider" }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.client != null
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "/user/" + _vm.client.url + "/profile",
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-user" }),
                          _vm._v(" Edit Profile"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.client != null
                    ? _c("div", { staticClass: "dropdown-divider" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(6),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("support-modal", {
        attrs: {
          user: _vm.user,
          client: _vm.local_client,
          selected_ticket_type: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "nav-item text-center mx-2 mt-2 icon-nav" },
      [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: {
              href: "#",
              type: "button",
              "data-bs-toggle": "modal",
              "data-bs-target": "#support-modal",
            },
          },
          [
            _c("img", {
              staticClass: "icon",
              staticStyle: { width: "24px" },
              attrs: { src: "/img/icons/dialexa-icons/message-question.svg" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          id: "profile-avatar",
          href: "#",
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("span", [_vm._v("My Account")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "/admin/initiative_editor" },
      },
      [_c("i", { staticClass: "fas fa-wrench" }), _vm._v(" Initiative Editor")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "/insights/metrics_filters" },
      },
      [
        _c("i", { staticClass: "fa-solid fa-filter" }),
        _vm._v(" Calculations / Filters"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "dropdown-item", attrs: { href: "/insights/charts" } },
      [
        _c("i", { staticClass: "fa-solid fa-chart-simple" }),
        _vm._v(" Chart Builder"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "/insights/report_builder" },
      },
      [_c("i", { staticClass: "fa-solid fa-book" }), _vm._v(" Report Builder")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: {
          href: "/logout",
          onclick:
            "event.preventDefault(); document.getElementById('logout-form').submit();",
        },
      },
      [
        _c("i", { staticClass: "fas fa-sign-out-alt" }),
        _vm._v(" Logout\n                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }