var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org_settings_profile" } }, [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "flex-shrink-1 profile-image px-2 pe-4" },
        [
          _c("span", {
            attrs: { "data-name": _vm.local_client.id, id: "client_id" },
          }),
          _vm._v(" "),
          _c("vue-dropzone", {
            ref: "dropzone",
            staticClass: "profile-dropzone",
            style: "background-image: url(" + _vm.local_client.image + ")",
            attrs: {
              id: "dropzone",
              options: _vm.dropzoneOptions,
              awss3: _vm.awss3,
            },
            on: { "vdropzone-s3-upload-success": _vm.s3UploadSuccess },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "px-2 w-100" }, [
        _c("h4", [_vm._v("Profile")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Organization Name"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_client.name,
                    expression: "local_client.name",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.name != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "name" },
                domProps: { value: _vm.local_client.name },
                on: {
                  change: function ($event) {
                    return _vm.validateName()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.local_client, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.name != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.name) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.user.user_type == "agency_admin" ||
              _vm.user.user_type == "admin"
                ? _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Stage"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_client.stage,
                              expression: "local_client.stage",
                            },
                          ],
                          staticClass: "form-select",
                          attrs: { id: "stage-select" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.local_client,
                                "stage",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "roadmap" } }, [
                            _vm._v("Roadmap"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "full_service" } }, [
                            _vm._v("Full Service"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "data_only" } }, [
                            _vm._v("Data Only"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "inactive" } }, [
                            _vm._v("Inactive"),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Vertical"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_client.vertical,
                            expression: "local_client.vertical",
                          },
                        ],
                        staticClass: "form-select",
                        class: _vm.errors.vertical != "" ? "is-invalid" : "",
                        attrs: { id: "vertical" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.local_client,
                                "vertical",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              _vm.errors.vertical = ""
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.vertical_types, function (item) {
                        return _c(
                          "option",
                          { key: item, domProps: { value: item } },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.errors.vertical != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.errors.vertical) +
                              "\n                                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Website Domain"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.domain,
                      expression: "local_client.domain",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.domain != "" ? "is-invalid" : "",
                  attrs: { type: "text", name: "domain" },
                  domProps: { value: _vm.local_client.domain },
                  on: {
                    change: function ($event) {
                      _vm.errors.domain = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.local_client, "domain", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.domain != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.errors.domain) +
                          "\n                                "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-2" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3" }, [
                    _c(
                      "span",
                      {
                        staticClass: "input-group-text",
                        style: {
                          "background-color": _vm.local_client.dark_color,
                        },
                      },
                      [
                        _c("verte", {
                          attrs: { picker: "square", model: "hex" },
                          model: {
                            value: _vm.local_client.dark_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.local_client, "dark_color", $$v)
                            },
                            expression: "local_client.dark_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_client.dark_color,
                          expression: "local_client.dark_color",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.local_client.dark_color },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.local_client,
                            "dark_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3" }, [
                    _c(
                      "span",
                      {
                        staticClass: "input-group-text",
                        style: {
                          "background-color": _vm.local_client.light_color,
                        },
                      },
                      [
                        _c("verte", {
                          attrs: { picker: "square", model: "hex" },
                          model: {
                            value: _vm.local_client.light_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.local_client, "light_color", $$v)
                            },
                            expression: "local_client.light_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_client.light_color,
                          expression: "local_client.light_color",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.local_client.light_color },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.local_client,
                            "light_color",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_client.slack_url,
                        expression: "local_client.slack_url",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors.slack_url != "" ? "is-invalid" : "",
                    attrs: { type: "text", name: "slack_url" },
                    domProps: { value: _vm.local_client.slack_url },
                    on: {
                      change: function ($event) {
                        return _vm.validateURL()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.local_client,
                          "slack_url",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.slack_url != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.errors.slack_url) +
                              "\n                                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "my-4" }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("h4", [_vm._v("Mission & Goals")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The information below is used when generating copy for the various campaigns made within AVID. For best results, be both concise and descriptive."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Client Mission")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_client.client_mission,
              expression: "local_client.client_mission",
            },
          ],
          staticClass: "form-control",
          attrs: { name: "client_mission" },
          domProps: { value: _vm.local_client.client_mission },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.local_client, "client_mission", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v(
            "Why would a donor give to " +
              _vm._s(
                _vm.local_client.name == null || _vm.local_client.name == ""
                  ? "this organization"
                  : _vm.local_client.name
              ) +
              " rather than some other nonprofit or not at all?"
          ),
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_client.why_give,
              expression: "local_client.why_give",
            },
          ],
          staticClass: "form-control",
          attrs: { name: "why_give" },
          domProps: { value: _vm.local_client.why_give },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.local_client, "why_give", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_client.style_guide,
              expression: "local_client.style_guide",
            },
          ],
          staticClass: "form-control",
          attrs: { name: "style_guide" },
          domProps: { value: _vm.local_client.style_guide },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.local_client, "style_guide", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "my-4" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("h4", [_vm._v("Constituent Personas")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "When AVID generates copy, it is possible to craft the text so that it would be more likely to appeal to certain types of people. Use the fields below to describe your constituents and "
          ),
          _c("strong", [_vm._v("what motivates them to take action")]),
          _vm._v(
            ". If you're unsure about the organization's personas, it is possible to ask ChatGPT to describe the types of donors who would give to " +
              _vm._s(
                _vm.local_client.name == null || _vm.local_client.name == ""
                  ? "this organization"
                  : _vm.local_client.name
              ) +
              ". This is often a good starting place."
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.local_client.personas, function (persona, index) {
          return _c("div", [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Persona Name #" + _vm._s(index + 1)),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: persona.name,
                    expression: "persona.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "persona_name" },
                domProps: { value: persona.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(persona, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class:
                  index + 1 == _vm.local_client.personas.length
                    ? "mb-3"
                    : "mb-5",
              },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Description"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: persona.description,
                      expression: "persona.description",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { name: "persona_description" },
                  domProps: { value: persona.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(persona, "description", $event.target.value)
                    },
                  },
                }),
              ]
            ),
          ])
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary me-3",
              on: {
                click: function ($event) {
                  return _vm.addPersona()
                },
              },
            },
            [_vm._m(4)]
          ),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _c("hr", { staticClass: "my-4" }),
    _vm._v(" "),
    _vm.form.error
      ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
          _vm._v(
            "\n        There is an error in the fields above. Please correct and try again.\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary mt-3",
        attrs: { type: "submit", disabled: _vm.form.busy || _vm.hasError() },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.update.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "fa fa-btn fa-save" }),
        _vm._v(" Save Changes\n    "),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Dark Color\n                                    "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-placement": "top",
          title:
            "Pick a dark color used in titles and other fonts that will be used when generating slides. It should match your branding.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Accent Color\n                                    "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-placement": "top",
          title:
            "Pick a bright color that will be used as an accent when generating slides. It should match your branding.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Slack Webhook URL\n                                    "),
      _c(
        "a",
        {
          attrs: {
            href: "https://api.slack.com/apps/A054J13HM0T/incoming-webhooks",
            target: "_blank",
          },
        },
        [
          _c("i", {
            staticClass: "fas fa-info-circle",
            attrs: {
              "data-bs-toggle": "tooltip",
              "data-placement": "top",
              title:
                "Slack Webhook URLs have to be set up for each client so the messages go to a client-specific channel. Click on this icon to add a new webhook or find an existing one.",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v(
        "Style Guide: What are words / phrases that should or should not be used? "
      ),
      _c("em", [_vm._v("(Optional)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Persona"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }