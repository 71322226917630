var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-page" }, [
    _c("h1", { staticClass: "text-center mt-5" }, [
      _vm._v("What would you like to do today?"),
    ]),
    _vm._v(" "),
    _vm.client.plan_level == "insights" || _vm.client.plan_level == "trial"
      ? _c("div", { staticClass: "row mb-2 mt-5 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-12 col-lg-10 col-xl-10" },
            [
              _c(
                "div",
                { staticClass: "card box-content mx-2 mb-4 two-space-tile" },
                [
                  _c(
                    "div",
                    { staticClass: "card-body text-center tile-body" },
                    [
                      _c(
                        "a",
                        { attrs: { href: "/insights/" + _vm.client.url } },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-chart-pie fa-5x",
                          }),
                          _vm._v(" "),
                          _c("h4", { staticClass: "mt-3 mb-0" }, [
                            _vm._v("Insights"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tile-description" }, [
                            _vm._v(
                              "Explore the latest insights from your fundraising and marketing program"
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2 justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
        [
          _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
            _c("div", { staticClass: "card-body text-center tile-body" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "/settings/v1/" + _vm.client.url + "/integrations",
                  },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-plug fa-5x" }),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mt-3 mb-0" }, [
                    _vm._v("Setup Integrations"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "tile-description" }, [
                    _vm._v(
                      "Create or update connections into your 3rd party systems"
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.client.census_api_key &&
      (_vm.client.plan_level == "insights" || _vm.client.plan_level == "trial")
        ? _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
                _c("div", { staticClass: "card-body text-center tile-body" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/pathways/" + _vm.client.url + "/push_segment/",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa-solid fa-arrow-right-arrow-left fa-5x",
                      }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-3 mb-0" }, [
                        _vm._v("Create Pathways"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "tile-description" }, [
                        _vm._v(
                          "Link your marketing and fundraising tools together to keep your data in sync"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
                _c("div", { staticClass: "card-body text-center tile-body" }, [
                  _c(
                    "a",
                    { attrs: { href: "/benchmarks/" + _vm.client.url } },
                    [
                      _c("i", { staticClass: "fa-solid fa-chart-pie fa-5x" }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-3 mb-0" }, [
                        _vm._v("Benchmarks"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "tile-description" }, [
                        _vm._v(
                          "Explore the latest trends across the non-profit industry"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
      _vm._v(" "),
      _vm.user.agency_id != null && _vm.user.organization == "NextAfter"
        ? _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
                _c("div", { staticClass: "card-body text-center tile-body" }, [
                  _vm.published_plan
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "/results/" + _vm.client.url + "/projections",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-chart-simple fa-5x",
                          }),
                          _vm._v(" "),
                          _c("h4", { staticClass: "mt-3 mb-0" }, [
                            _vm._v("View Projections vs. Actuals"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tile-description" }, [
                            _vm._v(
                              "Track the performance of your initiatives compared to initial projections"
                            ),
                          ]),
                        ]
                      )
                    : _vm.active_plan
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/planning/" + _vm.active_plan.url + "/annual",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-chart-simple fa-5x",
                          }),
                          _vm._v(" "),
                          _c("h4", { staticClass: "mt-3 mb-0" }, [
                            _vm._v("Update an Annual Plan"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tile-description" }, [
                            _vm._v(
                              "Build program projections tied to individual initiative performance"
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          attrs: {
                            href: "/planning/" + _vm.client.url + "/versions",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-chart-simple fa-5x",
                          }),
                          _vm._v(" "),
                          _c("h4", { staticClass: "mt-3 mb-0" }, [
                            _vm._v("Create an Annual Plan"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tile-description" }, [
                            _vm._v(
                              "Create a new plan for your upcoming fiscal year"
                            ),
                          ]),
                        ]
                      ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.campaign_count != 0 && _vm.user.agency_id != null
        ? _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
                _c("div", { staticClass: "card-body text-center tile-body" }, [
                  _c("a", { attrs: { href: "/campaigns/" + _vm.client.url } }, [
                    _c("i", {
                      staticClass: "fa-solid fa-envelopes-bulk fa-5x",
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "mt-3 mb-0" }, [
                      _vm._v("View All Campaigns"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "tile-description" }, [
                      _vm._v(
                        "Create a new campaign, import an existing campaign to create additional assets, or add assets to an existing campaign"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.campaign_count == 0 && _vm.user.agency_id != null
        ? _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
                _c("div", { staticClass: "card-body text-center tile-body" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "/campaigns/" + _vm.client.url + "/new" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa-solid fa-envelopes-bulk fa-5x",
                      }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-3 mb-0" }, [
                        _vm._v("Create a Campaign"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "tile-description" }, [
                        _vm._v(
                          "Build a new campaign based upon your strategic goals"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.experiment_treatment_count != 0 && _vm.user.agency_id != null
        ? _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
                _c("div", { staticClass: "card-body text-center tile-body" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/experiment_treatments/" + _vm.client.url,
                      },
                    },
                    [
                      _c("i", { staticClass: "fa-solid fa-flask fa-5x" }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-3 mb-0" }, [
                        _vm._v("View All Experiment Treatments"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "tile-description" }, [
                        _vm._v(
                          "Build a new version of an individual asset outside of an existing campaign"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.experiment_treatment_count == 0 && _vm.user.agency_id != null
        ? _c("div", { staticClass: "col-sm-10 col-md-6 col-lg-5 col-xl-5" }, [
            _c("div", { staticClass: "card box-content mx-2 mb-4 tile" }, [
              _c("div", { staticClass: "card-body text-center tile-body" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/experiment_treatments/" + _vm.client.url + "/new",
                    },
                  },
                  [
                    _c("i", { staticClass: "fa-solid fa-flask fa-5x" }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "mt-3 mb-0" }, [
                      _vm._v("Create an Experiment Treatment"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "tile-description" }, [
                      _vm._v(
                        "Build a new version of an individual asset outside of an existing campaign"
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.notif.length
      ? _c("div", { staticClass: "row mb-3 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-10 col-md-12 col-lg-10 col-xl-10" },
            [
              _c("h3", [
                _vm._v("Notifications (" + _vm._s(_vm.notif.length) + ")"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card box-content mx-2" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  _vm._l(_vm.notif, function (n, index) {
                    return _c("div", { key: n.id }, [
                      _c("span", { staticClass: "float-end lh-1" }, [
                        _vm._v(_vm._s(_vm._f("date")(n.created_at))),
                      ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(n.data["client_name"])),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(n.data["error_msg"]))]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        n.data["cta"] != null
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-primary me-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.markRead(n)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(n.data["cta"]))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-sm",
                            on: {
                              click: function ($event) {
                                return _vm.dismiss(n)
                              },
                            },
                          },
                          [_vm._v("Dismiss")]
                        ),
                      ]),
                      _vm._v(" "),
                      index != _vm.notif.length - 1 ? _c("hr") : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }