Vue.filter('currency_na', function (value) {
  if(value == 0) return "N/A";
  return numeral(value).format('$0,0');
});

Vue.filter('number_na', function(value) {
  if(value == 0) return "N/A";
  return numeral(value).format('0,0');
});
Vue.filter('percentage_na', function(value) {
  if(value == 0) return "N/A";
  var flt = parseFloat(value);
  if (flt < 0.01 && flt > -0.01) {
    if (flt == 0.00) {
      return numeral(value).format('0%');
    }
    return numeral(value).format('0.00%');
  }
  return numeral(value).format('0,0.0%');
});

Vue.filter('currency', function (value) {
  if(value == 0) return "";
  return numeral(value).format('$0,0');
});

Vue.filter('number', function(value) {
  if(value == 0) return "";
  return numeral(value).format('0,0');
});


Vue.filter('currency_with_zero', function (value) {
  return numeral(value).format('$0,0');
});

Vue.filter('number_with_zero', function(value) {
  return numeral(value).format('0,0');
});
Vue.filter('number_with_decimal', function(value) {

  var flt = parseFloat(value);
  if (flt < 10 && flt > -10) {
    if (flt == 0.00) {
      return numeral(value).format('0');
    }
    return numeral(value).format('0.0');
  }
  return numeral(value).format('0,0');

  // return numeral(value).format('0,0');
});

Vue.filter('percentage', function(value) {
  var flt = parseFloat(value);
  if (flt < 0.01 && flt > -0.01) {
    if (flt == 0.00) {
      return numeral(value).format('0%');
    }
    return numeral(value).format('0.00%');
  }
  return numeral(value).format('0,0.0%');
});

Vue.filter('propercase', function(value) {
  if(value == undefined) return "";

  value = String(value).replaceAll("_", " ");

  return value.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
});

Vue.filter('date', function(value) {
  if (value) {
    return moment(String(value)).utc().format('M/D/YYYY')
  }
});

Vue.filter('month', function(value) {
  if (value) {
    return moment(String(value)).utc().format('M/YYYY')
  }
});


Vue.filter('datetime', function(value) {
  if (value) {
    return moment(String(value)).local().format('M/D/YYYY h:mm a');
  }
});

Vue.filter('date_name', function(value) {
  if (value) {
    return moment(String(value)).utc().format('MMMM YYYY')
  }
});

Vue.filter('num_abbr', function(number) {
    const shortsymbols = {
        // length: symbol,
        4: 'T', //trillionths
        3: 'B', //billionths
        2: 'M', //millionths
        1: 'K' //thousanths
    }
    str_num = Math.round(number).toString()

    //How many commas would there be? (ex. 1000000 = 1,000,000 = 2 commas)
    let commaCount = Math.floor((str_num.length-1) / 3)

    //Do we need to shorten the str_num?
    if(commaCount > 0){
        // Shorten str_num with symbol to 6 characters max
        // ex. 123456 = 123.4k+

        //Based on comma count, we can tell if its thousanths, millionths, etc
        let symbol = shortsymbols[commaCount]

        //Determine dot placement. Hacky, but efficient in lines of code
        let dotPlacement = str_num.length%3;
        if(dotPlacement === 0) dotPlacement = 3;

        //Inserts dot. Hackily doesn't add dot if dotPlacement is  3
        str_num = str_num.substr(0,dotPlacement) + '.' + str_num.substr(dotPlacement)

        //Rounds to tenths place
        str_num = Math.round(Number(str_num) * 10)/10

        str_num += symbol
        return str_num;

    } else {
        var flt = parseFloat(number);
          if (flt < 10 && flt > -10) {
            if (flt == 0.00) {
              return numeral(number).format('0');
            }
            return numeral(number).format('0.0');
          }
          return numeral(number).format('0,0');

    } // else just return the plain number

    return number;
})


Vue.filter('currency_abbr', function(number) {
    const shortsymbols = {
        // length: symbol,
        4: 'T', //trillionths
        3: 'B', //billionths
        2: 'M', //millionths
        1: 'K' //thousanths
    }
    number = Math.round(number).toString()

    //How many commas would there be? (ex. 1000000 = 1,000,000 = 2 commas)
    let commaCount = Math.floor((number.length-1) / 3)

    //Do we need to shorten the number?
    if(commaCount > 0){
        // Shorten number with symbol to 6 characters max
        // ex. 123456 = 123.4k+

        //Based on comma count, we can tell if its thousanths, millionths, etc
        let symbol = shortsymbols[commaCount]

        //Determine dot placement. Hacky, but efficient in lines of code
        let dotPlacement = number.length%3;
        if(dotPlacement === 0) dotPlacement = 3;

        //Inserts dot. Hackily doesn't add dot if dotPlacement is  3
        number = number.substr(0,dotPlacement) + '.' + number.substr(dotPlacement)

        //Rounds to tenths place
        number = Math.round(Number(number) * 10)/10

        number += symbol

    } // else just return the plain number

    return "$" + number;
})


Vue.filter('htmlify', function(value) {
    if(value == null) return "";
    return value.replace(/\n/g, "<br />");
});
