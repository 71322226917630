var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "query-inclusion-builder query-inclusion-revamp" },
    [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.nested_units, function (unit, index) {
          return _vm.data_filter.logic_units.length > 0
            ? _c(
                "div",
                {
                  staticClass: "col-12 logic-grouping",
                  class: { inverted: _vm.inverted, white: _vm.is_white },
                },
                [
                  index == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          attrs: { id: "toggle-logic" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark dropdown-toggle",
                              attrs: {
                                "data-bs-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                            },
                            [_vm._v(_vm._s(_vm.inverted ? "OR" : "AND"))]
                          ),
                          _vm._v(" "),
                          _c("ul", { staticClass: "dropdown-menu" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.setInverted(false)
                                    },
                                  },
                                },
                                [_vm._v("AND")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.setInverted(true)
                                    },
                                  },
                                },
                                [_vm._v("OR")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._l(unit, function (el) {
                        return _c(
                          "div",
                          {
                            staticClass: "row ms-0 pb-2",
                            class: {
                              "is-invalid":
                                _vm.hasLogicUnitError(
                                  _vm.data_filter.logic_units[el]
                                ) && _vm.errors.logic_units[el],
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-lg-3 pb-2" },
                              [
                                _c("v-select", {
                                  staticClass:
                                    "searchable-select column-display",
                                  attrs: {
                                    options: _vm.db_columns,
                                    searchable: true,
                                    selectable: (option) => option.text != "",
                                    label: "text",
                                    filterable: false,
                                    "data-bs-toggle": "tooltip",
                                    title:
                                      _vm.data_filter.logic_units[el] &&
                                      _vm.data_filter.logic_units[el].db_column
                                        .category
                                        ? _vm.data_filter.logic_units[el]
                                            .db_column.category +
                                          ": " +
                                          _vm.data_filter.logic_units[el]
                                            .db_column.text
                                        : _vm.data_filter.logic_units[el]
                                            .db_column.text,
                                  },
                                  on: { search: _vm.fetchOptions },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selected-option",
                                        fn: function ({ text, category }) {
                                          return [
                                            _vm._v(
                                              "\n                              " +
                                                _vm._s(
                                                  category != null &&
                                                    category != "" &&
                                                    text != null &&
                                                    text != ""
                                                    ? category + ": "
                                                    : ""
                                                ) +
                                                _vm._s(text) +
                                                "\n                            "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "open-indicator",
                                        fn: function ({ attributes }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    width: "12px",
                                                    "line-height": "8px",
                                                  },
                                                },
                                                "span",
                                                attributes,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        fill: "none",
                                                        stroke: "#343a40",
                                                        "stroke-linecap":
                                                          "round",
                                                        "stroke-linejoin":
                                                          "round",
                                                        "stroke-width": "2",
                                                        d: "m2 5 6 6 6-6",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "option",
                                        fn: function ({ text, category }) {
                                          return [
                                            text == ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "category-header",
                                                    attrs: {
                                                      "data-category": category,
                                                      "data-isexpanded":
                                                        "false",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.expandCategory(
                                                          category,
                                                          null
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(category) +
                                                        " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa-solid fa-caret-right",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "suboption",
                                                    class:
                                                      category == null ||
                                                      category == ""
                                                        ? "show"
                                                        : "",
                                                    attrs: {
                                                      "data-subcategory":
                                                        category,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(text) +
                                                        "\n                              "
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "no-options",
                                        fn: function ({
                                          search,
                                          searching,
                                          loading,
                                        }) {
                                          return [
                                            _vm.is_loading
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "suboption show",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "spinner-border spinner-border-sm text-warning float-left",
                                                        attrs: {
                                                          role: "status",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "visually-hidden",
                                                          },
                                                          [_vm._v("Loading...")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "  Loading columns\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "suboption show",
                                                  },
                                                  [
                                                    _c("em", [
                                                      _vm._v(
                                                        "No results found"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.data_filter.logic_units[el].db_column,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data_filter.logic_units[el],
                                        "db_column",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data_filter.logic_units[el].db_column",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-lg-3 pb-2 operations" },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: _vm.operator_options,
                                    selectable: (option) => option.text != "",
                                    label: "text",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateOperator(
                                        _vm.data_filter.logic_units[el]
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "open-indicator",
                                        fn: function ({ attributes }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    width: "12px",
                                                    "line-height": "8px",
                                                  },
                                                },
                                                "span",
                                                attributes,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        fill: "none",
                                                        stroke: "#343a40",
                                                        "stroke-linecap":
                                                          "round",
                                                        "stroke-linejoin":
                                                          "round",
                                                        "stroke-width": "2",
                                                        d: "m2 5 6 6 6-6",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "option",
                                        fn: function ({ text, category }) {
                                          return [
                                            text == ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "category-header",
                                                    attrs: {
                                                      "data-category": category,
                                                      "data-isexpanded": true,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.expandCategory(
                                                          category,
                                                          null
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(category) +
                                                        " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa-solid fa-caret-down",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "suboption expanded",
                                                    attrs: {
                                                      "data-subcategory":
                                                        category,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(text) +
                                                        "\n                              "
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.data_filter.logic_units[el].operator,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data_filter.logic_units[el],
                                        "operator",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "data_filter.logic_units[el].operator",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.shouldShowValues(
                              _vm.data_filter.logic_units[el].operator
                            )
                              ? _c(
                                  "div",
                                  { staticClass: "col-lg-5 pb-2 text-center" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group" },
                                      [
                                        _vm.data_filter.logic_units[el]
                                          .operator == null ||
                                        (_vm.data_filter.logic_units[el]
                                          .operator.value != "IN" &&
                                          _vm.data_filter.logic_units[el]
                                            .operator.value != "NOT IN")
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary dropdown-toggle",
                                                attrs: {
                                                  type: "button",
                                                  "data-bs-toggle": "dropdown",
                                                  "aria-expanded": "false",
                                                  disabled:
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].db_column == "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.typeOfSelection(
                                                        _vm.data_filter
                                                          .logic_units[el]
                                                      )
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.data_filter.logic_units[el]
                                          .operator == null ||
                                        (_vm.data_filter.logic_units[el]
                                          .operator.value != "IN" &&
                                          _vm.data_filter.logic_units[el]
                                            .operator.value != "NOT IN")
                                          ? _c(
                                              "ul",
                                              {
                                                staticClass: "dropdown-menu",
                                                attrs: {
                                                  "aria-labelledby":
                                                    "dropdownMenuButton1",
                                                },
                                              },
                                              [
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          _vm.data_filter.logic_units[
                                                            el
                                                          ].is_static = false
                                                          _vm.data_filter.logic_units[
                                                            el
                                                          ].show_options = false
                                                          _vm.data_filter.logic_units[
                                                            el
                                                          ].value = ""
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Compare to Column"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("li", [
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].data_type != "datetime"
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.data_filter.logic_units[
                                                                el
                                                              ].is_static = true
                                                              _vm.data_filter.logic_units[
                                                                el
                                                              ].show_options = true
                                                              _vm.data_filter.logic_units[
                                                                el
                                                              ].value = ""
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "List of Options"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                                _vm._v(" "),
                                                _c("li", [
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].data_type == "datetime"
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.data_filter.logic_units[
                                                                el
                                                              ].is_static = false
                                                              _vm.data_filter.logic_units[
                                                                el
                                                              ].show_options = true
                                                              _vm.data_filter.logic_units[
                                                                el
                                                              ].value = ""
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Date Comparisons"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                                _vm._v(" "),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          _vm.data_filter.logic_units[
                                                            el
                                                          ].is_static = true
                                                          _vm.data_filter.logic_units[
                                                            el
                                                          ].show_options = false
                                                          _vm.data_filter.logic_units[
                                                            el
                                                          ].value = ""
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Static Value")]
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.data_filter.logic_units[el]
                                          .is_static &&
                                        _vm.data_filter.logic_units[el]
                                          .show_options == false &&
                                        !_vm.isDate(
                                          _vm.data_filter.logic_units[el]
                                            .data_type
                                        )
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].value,
                                                  expression:
                                                    "data_filter.logic_units[el].value",
                                                },
                                              ],
                                              staticClass: "form-control ps-2",
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.updateStaticValue(
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ]
                                                  )
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ],
                                                    "value",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.data_filter.logic_units[el]
                                          .is_static &&
                                        _vm.data_filter.logic_units[el]
                                          .show_options == false &&
                                        _vm.isDate(
                                          _vm.data_filter.logic_units[el]
                                            .data_type
                                        )
                                          ? _c("datepicker", {
                                              attrs: {
                                                "input-class":
                                                  "form-control date",
                                                placeholder: "MM/DD/YYYY",
                                                "bootstrap-styling": true,
                                                "use-utc": true,
                                                format: "M/d/yyyy",
                                              },
                                              model: {
                                                value:
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ],
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data_filter.logic_units[el].value",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.data_filter.logic_units[el]
                                          .is_static == false &&
                                        !_vm.data_filter.logic_units[el]
                                          .show_options
                                          ? _c("v-select", {
                                              staticClass:
                                                "searchable-select value_db_select vue_select column-display",
                                              attrs: {
                                                options: _vm.db_columns,
                                                searchable: true,
                                                selectable: (option) =>
                                                  option.text != "",
                                                label: "text",
                                                filterable: false,
                                              },
                                              on: { search: _vm.fetchOptions },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "open-indicator",
                                                    fn: function ({
                                                      attributes,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._b(
                                                            {
                                                              staticStyle: {
                                                                width: "12px",
                                                                "line-height":
                                                                  "8px",
                                                              },
                                                            },
                                                            "span",
                                                            attributes,
                                                            false
                                                          ),
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  viewBox:
                                                                    "0 0 16 16",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    fill: "none",
                                                                    stroke:
                                                                      "#343a40",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                    "stroke-width":
                                                                      "2",
                                                                    d: "m2 5 6 6 6-6",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "option",
                                                    fn: function ({
                                                      text,
                                                      category,
                                                    }) {
                                                      return [
                                                        text == ""
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "category-header",
                                                                attrs: {
                                                                  "data-category":
                                                                    category,
                                                                  "data-isexpanded":
                                                                    "false",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.expandCategory(
                                                                        category,
                                                                        null
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                    " +
                                                                    _vm._s(
                                                                      category
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa-solid fa-caret-right",
                                                                }),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "suboption",
                                                                class:
                                                                  category ==
                                                                    null ||
                                                                  category == ""
                                                                    ? "show"
                                                                    : "",
                                                                attrs: {
                                                                  "data-subcategory":
                                                                    category,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                    " +
                                                                    _vm._s(
                                                                      text
                                                                    ) +
                                                                    "\n                                  "
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "no-options",
                                                    fn: function ({
                                                      search,
                                                      searching,
                                                      loading,
                                                    }) {
                                                      return [
                                                        _vm.is_loading
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "suboption show",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "spinner-border spinner-border-sm text-warning float-left",
                                                                    attrs: {
                                                                      role: "status",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "visually-hidden",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Loading..."
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  "  Loading columns\n                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "suboption show",
                                                              },
                                                              [
                                                                _c("em", [
                                                                  _vm._v(
                                                                    "No results found"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ],
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data_filter.logic_units[el].value",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.data_filter.logic_units[el]
                                          .is_static &&
                                        _vm.data_filter.logic_units[el]
                                          .show_options
                                          ? _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.data_filter
                                                        .logic_units[el].value,
                                                    expression:
                                                      "data_filter.logic_units[el].value",
                                                  },
                                                ],
                                                staticClass: "form-select",
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.data_filter
                                                        .logic_units[el],
                                                      "value",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              _vm._l(
                                                _vm.db_column_values[
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].db_column.value
                                                ],
                                                function (col) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      domProps: {
                                                        value: col.name,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(col.name))]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.data_filter.logic_units[el]
                                          .is_static &&
                                        _vm.data_filter.logic_units[el]
                                          .show_options
                                          ? _c("input", {
                                              staticClass:
                                                "form-control date_num ps-2",
                                              attrs: {
                                                type: "number",
                                                "data-index": el,
                                                placeholder: "###",
                                              },
                                              domProps: {
                                                value: _vm.dateNumCalc(
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].value
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.updateDateValue(
                                                    el,
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ]
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.data_filter.logic_units[el]
                                          .is_static &&
                                        _vm.data_filter.logic_units[el]
                                          .show_options
                                          ? _c(
                                              "select",
                                              {
                                                staticClass:
                                                  "form-select input-group-prepend date_comparison",
                                                attrs: { "data-index": el },
                                                domProps: {
                                                  value: _vm.dateComparisonCalc(
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].value
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateDateValue(
                                                      el,
                                                      _vm.data_filter
                                                        .logic_units[el]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "day" } },
                                                  [_vm._v("Days Ago")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "week" } },
                                                  [_vm._v("Weeks Ago")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "month" } },
                                                  [_vm._v("Months Ago")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "year" } },
                                                  [_vm._v("Years Ago")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.data_filter.logic_units[el].operator !=
                                      null &&
                                    _vm.data_filter.logic_units[el].operator
                                      .value != null &&
                                    (_vm.data_filter.logic_units[
                                      el
                                    ].operator.value.indexOf("LIKE") > -1 ||
                                      _vm.data_filter.logic_units[el].operator
                                        .value == "IN" ||
                                      _vm.data_filter.logic_units[el].operator
                                        .value == "NOT IN" ||
                                      ((_vm.data_filter.logic_units[el]
                                        .operator == "=" ||
                                        _vm.data_filter.logic_units[el].operator
                                          .value == "=" ||
                                        _vm.data_filter.logic_units[el].operator
                                          .value == "<>" ||
                                        _vm.data_filter.logic_units[el]
                                          .operator == "<>") &&
                                        _vm.data_filter.logic_units[el]
                                          .data_type == "string"))
                                      ? _c(
                                          "div",
                                          { staticClass: "form-check" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.data_filter
                                                          .logic_units[el]
                                                          .modification,
                                                      expression:
                                                        "data_filter.logic_units[el].modification",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    value: "LOWER",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.data_filter
                                                        .logic_units[el]
                                                        .modification
                                                    )
                                                      ? _vm._i(
                                                          _vm.data_filter
                                                            .logic_units[el]
                                                            .modification,
                                                          "LOWER"
                                                        ) > -1
                                                      : _vm.data_filter
                                                          .logic_units[el]
                                                          .modification,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.data_filter
                                                            .logic_units[el]
                                                            .modification,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = "LOWER",
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.data_filter
                                                                .logic_units[
                                                                el
                                                              ],
                                                              "modification",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.data_filter
                                                                .logic_units[
                                                                el
                                                              ],
                                                              "modification",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.data_filter
                                                            .logic_units[el],
                                                          "modification",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "small",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "2px",
                                                      display: "inline-block",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Case-insensitive comparison"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.data_filter.logic_units[el].operator !=
                                      null &&
                                    (_vm.data_filter.logic_units[el].operator
                                      .value == "IN" ||
                                      _vm.data_filter.logic_units[el].operator
                                        .value == "NOT IN")
                                      ? _c("small", [
                                          _vm._v(
                                            "Comma separate the unique values. Quotes around strings are "
                                          ),
                                          _c("em", [_vm._v("not")]),
                                          _vm._v(" needed."),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-lg-1 col-close-btn" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-none show-on-hover",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeLogicUnit(el)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon p-0",
                                      attrs: {
                                        src: "/img/icons/dialexa-icons/trash.svg",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.hasLogicUnitError(
                              _vm.data_filter.logic_units[el]
                            ) && _vm.errors.logic_units[el]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pb-2 invalid-feedback col-12",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        All of the fields must be completed.\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-sm shadow-sm",
                          on: {
                            click: function ($event) {
                              return _vm.addLogicUnit(index)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-plus" }),
                          _vm._v(" " + _vm._s(_vm.inverted ? "AND" : "OR")),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e()
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "add-condition" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-dark shadow-sm",
            on: {
              click: function ($event) {
                return _vm.addLogicUnit(-1)
              },
            },
          },
          [_c("i", { staticClass: "fas fa-plus" }), _vm._v("Add Condition")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }