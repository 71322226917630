var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade modal-lg",
      attrs: {
        id: "cost-result-modal",
        "aria-labelledby": "cost-result-modal",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", class: _vm.modal_size }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
            _c("div", { staticClass: "text-center w-100" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-none float-end",
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "cost-result-modal",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-close" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-none float-end",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleModalSize()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa",
                    class:
                      _vm.modal_size == "modal-xl"
                        ? "fa-expand"
                        : "fa-compress",
                  }),
                ]
              ),
              _vm._v(" "),
              _c("h4", { staticClass: "mt-2" }, [
                _vm._v("Add a " + _vm._s(_vm.modal_version) + " Filter"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body pt-0 pb-0" }, [
            _c("h4", { staticClass: "modal-title text-center" }),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Data Source"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_filter.data_source,
                      expression: "local_filter.data_source",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { disabled: _vm.local_filter.id != null },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_filter,
                        "data_source",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.sources, function (s) {
                  return _c("option", { domProps: { value: s.key } }, [
                    _vm._v(_vm._s(s.value)),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Description"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_filter.title,
                        expression: "local_filter.title",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors.title != "" ? "is-invalid" : "",
                    attrs: { type: "text", name: "title" },
                    domProps: { value: _vm.local_filter.title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.local_filter, "title", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.title != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.errors.title) +
                              "\n                            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.modal_version == "Cost"
                ? _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Cost Type"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_filter.cost_type,
                              expression: "local_filter.cost_type",
                            },
                          ],
                          staticClass: "form-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.local_filter,
                                "cost_type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "media_cost" } }, [
                            _vm._v("Media Cost"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "project_cost" } }, [
                            _vm._v("Project Cost"),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.local_filter.data_source == "manual" &&
            _vm.modal_version == "Cost"
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Value"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-group",
                          class: _vm.errors.value != "" ? "is-invalid" : "",
                        },
                        [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v("$"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.local_filter.value,
                                expression: "local_filter.value",
                              },
                            ],
                            staticClass: "form-control",
                            class: _vm.errors.value != "" ? "is-invalid" : "",
                            attrs: { type: "text" },
                            domProps: { value: _vm.local_filter.value },
                            on: {
                              keypress: function ($event) {
                                return _vm.isNumber($event)
                              },
                              paste: _vm.cleanUpPaste,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.local_filter,
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.value != ""
                            ? _c(
                                "div",
                                { staticClass: "text-danger invalid-feedback" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.errors.value) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Date"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-group",
                          class: _vm.errors.date != "" ? "is-invalid" : "",
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("datepicker", {
                            class: _vm.errors.date != "" ? "is-invalid" : "",
                            attrs: {
                              "use-utc": true,
                              format: "MMM d, yyyy",
                              "input-class": "form-control",
                            },
                            model: {
                              value: _vm.local_filter.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.local_filter, "date", $$v)
                              },
                              expression: "local_filter.date",
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.date != ""
                            ? _c(
                                "div",
                                { staticClass: "text-danger invalid-feedback" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.errors.date) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm.local_filter.data_source == "manual" &&
                _vm.modal_version == "Result"
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Date"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "input-group",
                            class: _vm.errors.date != "" ? "is-invalid" : "",
                          },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("datepicker", {
                              class: _vm.errors.date != "" ? "is-invalid" : "",
                              attrs: {
                                "use-utc": true,
                                format: "MMM d, yyyy",
                                "input-class": "form-control",
                              },
                              model: {
                                value: _vm.local_filter.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.local_filter, "date", $$v)
                                },
                                expression: "local_filter.date",
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.date != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-danger invalid-feedback",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.errors.date) +
                                        "\n                                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Emails"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_filter.emails,
                              expression: "local_filter.emails",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors.emails != "" ? "is-invalid" : "",
                          attrs: { type: "text" },
                          domProps: { value: _vm.local_filter.emails },
                          on: {
                            keypress: function ($event) {
                              return _vm.isNumber($event)
                            },
                            paste: _vm.cleanUpPaste,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.local_filter,
                                "emails",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.emails != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.errors.emails) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Gifts"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_filter.gifts,
                              expression: "local_filter.gifts",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors.gifts != "" ? "is-invalid" : "",
                          attrs: { type: "text" },
                          domProps: { value: _vm.local_filter.gifts },
                          on: {
                            keypress: function ($event) {
                              return _vm.isNumber($event)
                            },
                            paste: _vm.cleanUpPaste,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.local_filter,
                                "gifts",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.gifts != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.errors.gifts) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("New Donors"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_filter.new_donors,
                              expression: "local_filter.new_donors",
                            },
                          ],
                          staticClass: "form-control",
                          class:
                            _vm.errors.new_donors != "" ? "is-invalid" : "",
                          attrs: { type: "text" },
                          domProps: { value: _vm.local_filter.new_donors },
                          on: {
                            keypress: function ($event) {
                              return _vm.isNumber($event)
                            },
                            paste: _vm.cleanUpPaste,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.local_filter,
                                "new_donors",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.new_donors != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.errors.new_donors) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Revenue"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "input-group",
                            class: _vm.errors.revenue != "" ? "is-invalid" : "",
                          },
                          [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("$"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.local_filter.revenue,
                                  expression: "local_filter.revenue",
                                },
                              ],
                              staticClass: "form-control",
                              class:
                                _vm.errors.revenue != "" ? "is-invalid" : "",
                              attrs: { type: "text" },
                              domProps: { value: _vm.local_filter.revenue },
                              on: {
                                keypress: function ($event) {
                                  return _vm.isNumber($event)
                                },
                                paste: _vm.cleanUpPaste,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.local_filter,
                                    "revenue",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.revenue != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-danger invalid-feedback",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.errors.revenue) +
                                        "\n                                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Recurring Revenue"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "input-group",
                            class:
                              _vm.errors.recurring_revenue != ""
                                ? "is-invalid"
                                : "",
                          },
                          [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("$"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.local_filter.recurring_revenue,
                                  expression: "local_filter.recurring_revenue",
                                },
                              ],
                              staticClass: "form-control",
                              class:
                                _vm.errors.recurring_revenue != ""
                                  ? "is-invalid"
                                  : "",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.local_filter.recurring_revenue,
                              },
                              on: {
                                keypress: function ($event) {
                                  return _vm.isNumber($event)
                                },
                                paste: _vm.cleanUpPaste,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.local_filter,
                                    "recurring_revenue",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.recurring_revenue != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-danger invalid-feedback",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.errors.recurring_revenue) +
                                        "\n                                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm.modal_version != null
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Inclusion Filter Logic"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("query-inclusion-builder", {
                          ref: "filter",
                          attrs: {
                            client: _vm.client,
                            include_trans:
                              _vm.local_filter.data_source == "warehouse",
                            source: isNaN(_vm.local_filter.data_source)
                              ? _vm.local_filter.data_source
                              : "dataset",
                            data_source_id: isNaN(_vm.local_filter.data_source)
                              ? null
                              : _vm.local_filter.data_source,
                          },
                          model: {
                            value: _vm.local_filter.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.local_filter, "value", $$v)
                            },
                            expression: "local_filter.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.local_filter.data_source == "warehouse",
                    expression: "local_filter.data_source == 'warehouse'",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_filter.exclude_other_initiatives,
                          expression: "local_filter.exclude_other_initiatives",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        id: "exclude_other_initiatives",
                        value: "1",
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.local_filter.exclude_other_initiatives
                        )
                          ? _vm._i(
                              _vm.local_filter.exclude_other_initiatives,
                              "1"
                            ) > -1
                          : _vm.local_filter.exclude_other_initiatives,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.local_filter.exclude_other_initiatives,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.local_filter,
                                  "exclude_other_initiatives",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.local_filter,
                                  "exclude_other_initiatives",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.local_filter,
                              "exclude_other_initiatives",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(2),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.form.error
              ? _c("div", { staticClass: "row mt-2" }, [_vm._m(3)])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer justify-content-start border-top-0" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", disabled: _vm.form.saving },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addFilter()
                    },
                  },
                },
                [
                  _vm.form.saving
                    ? _c("span", [_vm._m(4), _vm._v(" Saving")])
                    : _c("span", [
                        _c("i", { staticClass: "fa fa-btn fa-save" }),
                        _vm._v(" Save"),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", disabled: _vm.form.saving },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", {
        staticClass: "fa fa-calendar",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", {
        staticClass: "fa fa-calendar",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "exclude_other_initiatives" },
      },
      [
        _vm._v(
          "\n                            Also exclude results that would be included in other initiatives\n                            "
        ),
        _c("i", {
          staticClass: "fas fa-info-circle",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-placement": "top",
            title:
              "For initiatives that pull from the same source, their inclusion criteria would be treated as an exclusion in this initiative. For initiatives that pull from a different source, like Google Analytics, their raw results will be subtracted from this total.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "alert alert-danger" }, [
        _vm._v(
          "\n                            There was an issue saving your entry. Check the fields above to get more information.\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }