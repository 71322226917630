<template>
    <div class="results">
        <h2 class="mb-4 mt-5 border-bottom border-secondary border-3 pb-1">{{initiative.title}}</h2>
        <div class="row">
            <div class="col-sm-6 mb-3">
                <div class="row pb-3"><div class="col">
                    <h4 class="d-inline">Assigned Results</h4>
                    <button type="button" class="btn btn-sm btn-secondary float-end"
                        data-bs-toggle="modal" data-bs-target="#cost-result-modal"
                        @click="modal_version='Result'"><i class="fa fa-plus"></i> Add Result</button>
                </div></div>
                <div class="card box-content" v-if="init.results_filter == null || init.results_filter.length == 0">
                    <div class="card-body p-2">
                        <div class="p-2">None Added Yet</div>
                    </div>
                </div>
                <div class="card box-content mb-2" v-for="result in init.results_filter" v-else>
                    <div class="card-body p-2">
                        <div class="accordion accordion-flush" >
                            <div class="accordion-item border-bottom-0">
                                <div class="row accordion-header"><div class="col col-sm-8 col-md-9">
                                    <button class="btn btn-link text-start collapsed p-2" type="button"
                                        :data-bs-target="'#result-' + result.id" data-bs-toggle="collapse"
                                        aria-expanded="false" :aria-controls="'#result-' + result.id">
                                        <strong>{{ ((result.data_source_name != undefined)? result.data_source_name : result.data_source)  | propercase }}</strong>: {{result.description}}
                                    </button>
                                </div><div class="col">
                                    <button type="button" class="btn-xs btn-none float-end p-2" @click="deleteResult(result)" ><i class="fa fa-remove"></i></button>
                                    <button type="button" class="btn-xs btn-none float-end p-2" @click="editResult(result)" data-bs-toggle="modal" data-bs-target="#cost-result-modal"><i class="fa fa-edit"></i></button>
                                    <button type="button" class="btn-xs btn-none float-end p-2" @click="previewTable(result)" v-if="result.data_source != 'manual'"><i class="fa fa-eye"></i></button>
                                </div></div>
                                <div :id="'result-' + result.id" class="collapse accordion-collapse"
                                    v-if="result.data_source == 'manual'">
                                    <div class="accordion-body">
                                        <strong>Date:</strong> {{result.date | date}}<br>
                                        <strong>Emails:</strong> {{result.emails | number_with_zero}}<br>
                                        <strong>Gifts:</strong> {{result.gifts | number_with_zero}}<br>
                                        <strong>New Donors:</strong> {{result.new_donors | number_with_zero}}<br>
                                        <strong>Revenue:</strong> {{result.revenue | currency_with_zero}}<br>
                                        <strong>Recurring Revenue:</strong> {{result.recurring_revenue | currency_with_zero}}
                                    </div>
                                </div>
                                <div :id="'result-' + result.id" class="collapse accordion-collapse" v-else >
                                    <div class="accordion-body">
                                        <strong>Filter Logic:</strong><br>
                                        <div style="line-height: 2.4em;" v-html="logicToString(result.value.filter_logic, result.value.logic_units)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col mb-3">
                <div class="row pb-3"><div class="col">
                <h4 class="d-inline">Assigned Costs</h4>
                <button type="button" class="btn btn-sm btn-secondary float-end"
                        data-bs-toggle="modal" data-bs-target="#cost-result-modal"
                        @click="modal_version='Cost'"><i class="fa fa-plus"></i> Add Cost</button>
                    </div>
                </div>
                <div class="card box-content" v-if="init.costs_filter == null || init.costs_filter.length == 0">
                    <div class="card-body p-2">
                        <div class="p-2">None Added Yet </div>
                    </div>
                </div>
                <div class="card box-content mb-2" v-for="cost in init.costs_filter" v-else>
                    <div class="card-body p-2">
                        <div class="accordion accordion-flush" >
                            <div class="accordion-item border-bottom-0">
                                <div class="row accordion-header"><div class="col col-sm-8 col-md-9">
                                    <button class="btn btn-link text-start collapsed p-2" type="button"
                                        :data-bs-target="'#cost-' + cost.id" data-bs-toggle="collapse"
                                        aria-expanded="false" :aria-controls="'#cost-' + cost.id">
                                        <strong>{{ ((cost.data_source_name != undefined)? cost.data_source_name : cost.data_source) | propercase }}</strong>: {{cost.description}}
                                    </button>
                                </div>
                                <div class="col">
                                    <button type="button" class="btn-xs btn-none float-end p-2" @click="deleteCost(cost)" ><i class="fa fa-remove"></i></button>
                                    <button type="button" class="btn-xs btn-none float-end p-2" @click="editCost(cost)" data-bs-toggle="modal" data-bs-target="#cost-result-modal"><i class="fa fa-edit"></i></button>
                                    <button type="button" class="btn-xs btn-none float-end p-2" @click="previewTable(cost)"  v-if="cost.data_source != 'manual'"><i class="fa fa-eye"></i></button>
                                </div></div>
                            </div>
                            <div :id="'cost-' + cost.id" class="collapse accordion-collapse"
                                v-if="cost.data_source == 'manual'">
                                <div class="accordion-body">
                                    Total: {{cost.amount | currency_with_zero}}<br>
                                    Date: {{cost.date | date}}<br>
                                    Cost Type: {{cost.cost_type | propercase}}
                                </div>
                            </div>
                            <div :id="'cost-' + cost.id" class="collapse accordion-collapse" v-else >
                                <div class="accordion-body">
                                    <strong>Filter Logic:</strong><br>
                                    <div style="line-height: 2.4em;" v-html="logicToString(cost.value.filter_logic, cost.value.logic_units)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="table_data != null">
            <div class="col mb-3">
                <div class="card box-content" >
                    <div class="card-body p-0">
                        <div v-if="typeof table_data === 'string' && table_data == ''" class="m-3">
                            <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>
                            <span class="ms-2">Loading...</span>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length > 0'>
                            <div class="overflow-hidden rounded-top">
                                <div class="p-1 bg-dark"></div>
                            </div>
                            <div class="table-responsive gy-0 m-0 mb-2">
                                <table class="table gy-0 m-0">
                                    <thead class="table-dark">
                                        <tr>
                                            <th v-for="(row, index) in Object.keys(table_data[0])" class='align-middle pt-0'
                                                :class="(index==0) ? 'ps-3' : ''">{{row | propercase}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr v-for="row in table_data" >
                                            <td v-for="(col, index) in row" :class="(index==0) ? 'ps-3' : ''">{{ col }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length == 0'>
                            <div class="alert alert-warning box-content" >
                                No data was returned.
                            </div>
                        </div>
                        <div v-else>
                            <div class="alert alert-danger box-content" >
                                There was an error pulling the data:<br>
                                <em>{{table_data}}</em>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <cost-result-modal :modal_version.sync="modal_version" :initiative.sync="init"
            :client="client" :filter="filter" ></cost-result-modal>
        <div class="row">
            <div class="col">
                <a :href="'/results/' + client.url + '/projections'" class="btn btn-primary mt-4"><i class="fa fa-btn fa-save"></i> Update</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['initiative', 'client'],
        data() {
            return {
                init: null,
                modal_version: null,
                filter: {
                    data_source: "manual",
                    data_source_name: "Manual",
                    title: "",
                    value: null,
                    date: null,
                    cost_type: 'media_cost',
                    emails: 0,
                    gifts: 0,
                    revenue: 0,
                    new_donors: 0,
                    recurring_revenue: 0,
                    id: null,
                    exclude_other_initiatives: false
                },
                errors :{
                    date: "",
                    title: "",
                    value: ""
                },
                form: {
                    error: false
                },
                table_data: null
            };
        },
        watch: {
            init(old_pv, new_pv) {
                //Reset the filter if the initiative gets updated
                this.resetFilter();
            },
            modal_version(old_pv, new_pv) {
                //If they closed the modal, then wipe the filter
                if(old_pv == null)
                    this.resetFilter();
            }
        },
        beforeMount() {
            this.init = this.initiative;
        },
        methods: {
            resetFilter() {
                this.filter.data_source = "manual";
                this.filter.data_source_name = "";
                this.filter.title = "";
                this.filter.value = null;
                this.filter.date = null;
                this.filter.cost_type = 'media_cost';
                this.filter.emails=  0;
                this.filter.gifts= 0;
                this.filter.revenue=  0;
                this.filter.new_donors=  0;
                this.filter.recurring_revenue= 0;
                this.filter.id = null;
                this.filter.exclude_other_initiatives = false;
            },
            editCost(cost) {
                this.filter.data_source = cost.data_source;
                this.filter.data_source_name = cost.data_source_name;
                this.filter.title = cost.description;

                this.filter.date = cost.date;
                this.filter.cost_type = cost.cost_type;
                this.filter.id = cost.id;
                if(this.filter.data_source == 'manual')
                    this.filter.value = cost.amount;
                else
                    this.filter.value = cost.value;

                this.modal_version='Cost';
            },
            editResult(result) {
                this.filter.data_source = result.data_source;
                this.filter.data_source_name = result.data_source_name;
                this.filter.title = result.description;
                this.filter.value = result.value;
                this.filter.date = result.date;
                this.filter.id = result.id;
                this.filter.emails = result.emails;
                this.filter.gifts = result.gifts;
                this.filter.revenue = result.revenue;
                this.filter.new_donors = result.new_donors;
                this.filter.recurring_revenue = result.recurring_revenue;
                this.filter.exclude_other_initiatives = result.exclude_other_initiatives;
                this.modal_version='Result';
            },
            previewTable(item) {
                var self = this;
                this.table_data = "";
                item.client_id = this.client.id;

                //If it is a data set...
                if(!isNaN(item.data_source)){
                    item.data_source_id = item.data_source;
                    item.data_source = "dataset";
                }

                window.axios.post('/api/bigquery/get_detailed_samples', item).then(response => {
                    if(response.status == 200) {
                        self.table_data = response.data.samples;
                    }
                    else
                        self.table_data = response.data.error;
                  });
            },
            deleteCost(cost) {
                var self = this;
                cost.initiative_id = this.initiative.id;

                window.axios.post('/api/cost/delete/' + cost.id, cost).then(response => {
                    if(response.status == 200) {
                        self.form.successful = true;
                        self.modal_version = null;

                        self.init = response.data.initiative;
                        self.$emit('update:initiative', self.init);
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                  });

                if(typeof this.init.costs_filter  === 'object' &&
                    !Array.isArray(this.init.costs_filter))
                    this.init.costs_filter = Object.entries(this.init.costs_filter)

                //Remove it from the array
                for(var i = 0; i < this.init.costs_filter.length; i++) {
                    if(this.init.costs_filter[i].id == cost.id) {
                        this.init.costs_filter.splice(i, 1);
                        break;
                    }
                }
            },
            //When deleting results, we have to pass the actual result so it can find the initiative ID
            deleteResult(result) {
                var self = this;
                result.initiative_id = this.initiative.id;

                //I have to do a "post" instead of "delete" since I have to pass the initiative_id
                // in cases where it isn't an actual "Result" object
                window.axios.post('/api/result/delete/' + result.id, result).then(response => {
                    if(response.status == 200) {
                        self.form.successful = true;
                        self.modal_version = null;

                        self.init = response.data.initiative;
                        self.$emit('update:initiative', self.init);
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                  });

                if(typeof this.init.results_filter  === 'object' &&
                    !Array.isArray(this.init.results_filter))
                    this.init.results_filter = Object.entries(this.init.results_filter)
                //Remove it from the array
                for(var i = 0; i < this.init.results_filter.length; i++) {
                    if(this.init.results_filter[i].id == result.id) {
                        this.init.results_filter.splice(i, 1);
                        break;
                    }
                }
            },
            logicToString(logic, units) {
                var arr = [];
                for(var i = 0; i < units.length; i++) {

                    var str = "<code>" + units[i].db_column.text + " " + units[i].operator + " ";

                    //if it is a static date, just format it
                    if(units[i].db_column.type == 'date' && units[i].is_static)
                        str += this.$options.filters.date(units[i].value);
                    else if(units[i].db_column.type == 'float64' && !isNaN(units[i].value))
                        str += this.$options.filters.currency(units[i].value);
                    else if(units[i].db_column.type == 'int' && !isNaN(units[i].value))
                        str += this.$options.filters.number(units[i].value);
                    else if(typeof units[i].value === 'object' && units[i].value !== null)
                        str += units[i].value.text;
                    else if(typeof units[i].operator === "string" &&  units[i].operator.indexOf("NULL") == -1)
                        str += units[i].value;
                    else if(typeof units[i].operator !== "string" && units[i].operator.value != null && units[i].operator.value.indexOf("NULL") == -1)
                        str += units[i].value;

                    arr.push(str+ "</code>");
                }
                for(var i = 0; i < arr.length; i++) {
                    var str_num = String(i+1);
                    logic = logic.replaceAll(str_num, arr[i])
                }
                return logic;
            }

        }
    }
</script>
