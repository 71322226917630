<template>
    <div class="row onboarding">
        <div class="col-lg-6 ">
            <div class="d-lg-block d-none">
                <!--<h2 class="text-primary mt-5 text-center">Turn your data into donations</h2>-->
                <h2 class="text-primary mt-5 text-center">Be a part of the nonprofit industry's first <u>live</u> year-end benchmark report</h2>
                <div v-if="has_agency">
                    <div class="row">
                        <div class="col-4 mt-3 offset-sm-1 ">
                            <img src="/img/logo_png/logo_square_white@2x.png" class="w-100">
                        </div>
                        <div class="col-2 mt-3 d-flex justify-content-center align-items-center"><span class="h1">&</span></div>
                        <div class="col-4 mt-3">
                            <img :src="agency.logo" class="w-100">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-10 offset-sm-1 mt-3 text-center">
                            <!--<h5>Get access today to the platform used and endorsed by {{ agency.name }}. Start turning your data into donations today.</h5>-->
                            <h5>Get access today to the platform used and endorsed by {{ agency.name }}. Maximize your year-end results with realtime insights from similar nonprofits.</h5>
                        </div>
                    </div>
                </div>
                <div class="row"><div class="col-sm-10 offset-sm-1">
                    <!--<p class="mt-3">Avid AI is rethinking the old nonprofit benchmark report. Avid's FREE benchmarks are a constantly updating source of information on the nonprofit industry and how your organization compares to similar groups.</p>
                    <p>PLUS, get a 30-day free trial (<em>with no credit card necessary</em>) to the full Avid platform with Insights, Scorecard, and more!</p>-->
                    <p class="mt-3">Avid AI is rethinking the old nonprofit benchmark report. The FREE year end benchmark will give you live insights on your fundraising and marketing efforts compared to prior years and similar nonprofits.</p>
                    <p>PLUS, get a 60-day free trial (<em>with no credit card necessary</em>) to the full Avid platform with Insights, Scorecard, and more!</p>
                    <h5 class="mt-4 text-center">The benchmarks are live&mdash;get access today!</h5>
                </div></div>
                
                <div  v-if="!has_agency">
                    <div class="row d-none d-lg-block">
                        <div class="col-sm-10 offset-sm-1 mt-3">
                            <img src="/img/screenshot.png" class="w-100">
                        </div>
                    </div>
                    <div class="row d-none d-lg-block">
                        <div class="col-sm-10 offset-sm-1 mt-3">
                            <div class="card box-content">
                                <div class="card-body d-flex align-items-center">
                                    <img src="https://s3.us-west-2.amazonaws.com/nextafter-pulse/users%2F1726175140680_image+%2816%29.png" class="avatar rounded-circle me-3">
                                    <p class="mb-0">"Avid seems to excel at integrating data from our current complicated donor data collection methods, turning it into actionable insights that lead to measurable improvements." <small>- Rebecca, FamilyLife Ministries</small></p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div  class="d-block d-lg-none mb-3">
                <!--<h1 class="text-primary mt-5 text-center">Turn your data into donations</h1>-->
                <h1 class="text-primary mt-5 text-center">Be a part of the nonprofit industry's first <u>live</u> year-end benchmark report</h1>
                <h5 class=" mt-3 text-center" v-if="has_agency">Get access today to the platform used and endorsed by {{ agency.name }}.</h5>
                <!--<p class="mt-3 text-center">Avid AI is rethinking the old nonprofit benchmark report. Avid's FREE benchmarks are a constantly updating source of information on the nonprofit industry and how your organization compares to similar groups.</p>
                <p class=" text-center">Plus, you'll get a 30-day free trial (<em>with no credit card necessary</em>) to the full Avid platform with Insights, Scorecard, and more!</p>-->
                <p class="mt-3">Avid AI is rethinking the old nonprofit benchmark report. The FREE year end benchmark will give you live insights on your fundraising and marketing efforts compared to prior years and similar nonprofits.</p>
                    <p>PLUS, get a 60-day free trial (<em>with no credit card necessary</em>) to the full Avid platform with Insights, Scorecard, and more!</p>
                    <h5 class="mt-3 text-center mb-5">The benchmarks are live&mdash;get access today!</h5>
            </div>
        </div>
        <div class="col">
            <div>
                <Snowf 
                    :amount="50"
                    :size="5"
                    :speed="1.5"
                    :wind="0"
                    :opacity="0.8"
                    :swing="1"
                    :image="null"
                    :zIndex="1"
                    :resize="true"
                    color="#E5F3FE"
                />

                <nav class="navbar navbar-expand-lg pb-0 mt-lg-5">
                    <!-- <div class="container-fluid"> -->
                        <div class="collapse navbar-collapse" id="filter-navbar">
                            <ul class="flex-row d-flex navbar-nav w-100">
                                <li class="col-3 nav-item px-3">
                                    <a class="nav-link border-bottom border-4" :class="{'border-primary': step_num>=1}" href="/register?step=1" 
                                        role="button" aria-expanded="false"><span  v-if="step_num == 1">Sign Up</span></a>
                                </li>
                                <li class="col-3 nav-item pe-3">
                                    <a class="nav-link border-bottom border-4" :class="{'pe-none': (step_num == 1), 'border-primary': step_num>=2}"
                                    href="/register?step=2" role="button" aria-expanded="false"><span v-if="step_num == 2">Organization</span></a>
                                </li>
                                <li class="col-3 nav-item pe-3">
                                    <a class="nav-link border-bottom border-4" :class="{'pe-none': (step_num == 2), 'border-primary': step_num>=3}"
                                    href="/register?step=3" role="button" aria-expanded="false"><span  v-if="step_num == 3">Systems</span></a>
                                </li>
                                <li class="col-3 nav-item pe-3">
                                    <a class="nav-link border-bottom border-4" :class="{'pe-none': (step_num <= 3), 'border-primary': step_num>=4}"
                                    href="/register?step=4" role="button" aria-expanded="false"><span  v-if="step_num == 4">Connect</span></a>
                                </li>
                            </ul>
                        </div>
                    <!-- </div> -->
                </nav>

                <div class="card box-content" :class="step_num == 4 ? 'mb-3' : 'mb-5'">
                
                    <img class="holly-background-left" src="/img/images/holly-top-corner.png">
                    <img class="holly-background-right" src="/img/images/holly-bottom-corner.png">
        

                    <div class="card-body">
                        <onboarding-personal-info :error="error" v-if="step_num == 1" @nextStep="nextStep"></onboarding-personal-info>

                        <onboarding-org-info v-if="step_num == 2" @nextStep="nextStep" :agency="agency" :user="local_user" @reset="reset"></onboarding-org-info>

                        <onboarding-current-systems v-if="step_num == 3" @nextStep="nextStep" @reset="reset" :user="local_user" :client="local_client" :integrations="integrations"></onboarding-current-systems>

                        <onboarding-connect-crm v-if="step_num == 4" @reset="reset":user="local_user" :client="local_client" :integrations="integrations"></onboarding-connect-crm>
                    </div>
                </div>                
            </div>

            <div class="mb-5 row">
                <div class="col">
                    <div class="d-flex align-items-center mb-3">
                        <img src="/img/lock.png" alt="lock" class="me-3" style="width:64px;">
                        <div>
                            <h5 class="mb-2">Your data is secure</h5>
                            <p class="mb-0">Avid AI takes the security of your data seriously.
                            <a href="https://trust.avidai.com" target="_blank">Visit our trust center</a>
                            to see our SOC2 compliance status and the steps taken to ensure your organization's privacy.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Snowf from 'vue-snowf';
    export default {
        components: {
            Snowf
        },
        props: ['error','user', 'client', 'integrations', 'agency'],
        data() {
            return {
                step_num: 1,
                local_user: null,
                local_client: null,
                has_agency: this.agency != null
            };
        },
        beforeMount() {
            this.local_user = this.user;
            this.local_client = this.client;

            const params = new URLSearchParams(window.location.search);
            if(params.get('reg_step') != null)
                this.step_num = parseInt(params.get('reg_step'));

            if(this.step_num == 4 && (this.local_client == null || this.local_client.systems == null))
                this.step_num = 3;

            if(this.step_num == 3 && (this.local_client == null))
                this.step_num = 2;

            else if(this.step_num == 2 && this.local_user == null)
                this.step_num = 1;
        },
        mounted() {
                //Check the URL querystring for promo, promo_code, or code
                const params = new URLSearchParams(window.location.search);
                //Save it to local storage
                if(params.get('promo') != null)
                    localStorage.setItem('promo', params.get('promo'));
                if(params.get('promo_code') != null)
                    localStorage.setItem('promo_code', params.get('promo_code'));
                if(params.get('code') != null)
                    localStorage.setItem('code', params.get('code'));
        },
        methods: {
            reset(){
                this.step_num = 1;
            },
            nextStep(data){

                if(this.step_num == 1)
                    this.local_user = data;
                else if(this.step_num == 2 || this.step_num == 3)
                    this.local_client = data;

                this.step_num++;
                var new_url = '/?reg_step='+this.step_num
                window.history.pushState({}, {}, new_url);
                this.$forceUpdate();
            },
        }
    }
</script>
