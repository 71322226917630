var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pathways" }, [
    _c("h1", { staticClass: "mb-2" }, [_vm._v("Pathways")]),
    _vm._v(" "),
    _c("div", { staticClass: "row px-2 mb-5" }, [
      _c(
        "nav",
        {
          staticClass: "navbar navbar-expand primary-tab-bar pb-0",
          attrs: { id: "pathway-tab-nav" },
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "collapse navbar-collapse" }, [
              _c(
                "ul",
                { staticClass: "navbar-nav mb-lg-0" },
                _vm._l(_vm.pathway_tabs, function (tab_name, index) {
                  return _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link d-flex flex-nowrap pb-0 mb-0 mx-2 primary-tab",
                        class: {
                          "primary-tab-active":
                            index == _vm.pathway_tab_index_active,
                        },
                        attrs: {
                          href: "#",
                          role: "button",
                          "aria-expanded": "false",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setActiveTab(index)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(tab_name))])]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content p-0 mb-5" }, [
        _c("div", { staticClass: "card-body" }, [
          _vm.pathway_tab_index_active == 0
            ? _c(
                "div",
                [
                  _c("pathways-push-segment", {
                    attrs: {
                      connection_datasets: _vm.connection_datasets,
                      client: _vm.client,
                      segments: _vm.local_segments,
                      datasets: _vm.datasets,
                      datablends: _vm.datablends,
                      census_connections: _vm.census_connections,
                      segment_selected_passed: _vm.segment_selected_passed,
                      pathway_selected_passed: _vm.pathway_selected_passed,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.pathway_tab_index_active == 1
            ? _c(
                "div",
                [
                  _c("pathways-manage", {
                    attrs: {
                      connection_datasets: _vm.connection_datasets,
                      client: _vm.client,
                      segments: _vm.local_segments,
                      tab_name: _vm.tab_name,
                      datasets: _vm.datasets,
                      datablends: _vm.datablends,
                      census_connections: _vm.census_connections,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }