var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "agency_settings org_settings" }, [
    _c("h1", { staticClass: "mb-2" }, [_vm._v("Agency Settings")]),
    _vm._v(" "),
    _c("div", { staticClass: "row px-2 mb-5" }, [
      _c(
        "nav",
        {
          staticClass: "col-12 navbar navbar-expand primary-tab-bar pb-0",
          attrs: { id: "agency-setting-tab-nav" },
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "collapse navbar-collapse" }, [
              _c(
                "ul",
                { staticClass: "navbar-nav mb-lg-0" },
                _vm._l(_vm.setting_tabs, function (tab_name) {
                  return _c("li", { key: tab_name, staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link d-flex flex-nowrap pb-0 mb-0 mx-2 primary-tab",
                        class: {
                          "primary-tab-active":
                            tab_name === _vm.setting_tab_name_active,
                        },
                        attrs: {
                          href: "#",
                          role: "button",
                          "aria-expanded": "false",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setActiveTab(tab_name)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(tab_name))])]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content py-3" }, [
        _c("div", { staticClass: "card-body" }, [
          _vm.setting_tab_name_active === "PROFILE"
            ? _c(
                "div",
                [_c("agency-profile", { attrs: { agency: _vm.agency } })],
                1
              )
            : _vm.setting_tab_name_active === "TEAM"
            ? _c(
                "div",
                [_c("agency-users", { attrs: { agency: _vm.agency } })],
                1
              )
            : _vm.setting_tab_name_active === "CLIENTS"
            ? _c(
                "div",
                [_c("agency-clients", { attrs: { agency: _vm.agency } })],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }