var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "org_settings_connections" } },
    [
      !_vm.if_viewing_connection && _vm.unconnected_integration !== false
        ? _c(
            "div",
            {
              staticClass: "row mb-3",
              attrs: { id: "org_settings_connections_notifications" },
            },
            [
              _c("div", { staticClass: "col-md-7" }, [
                _c(
                  "div",
                  { staticClass: "card system-connection-card h-100" },
                  [
                    _c("div", { staticClass: "card-body d-flex" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ms-4 integration-list" },
                        [
                          _c("h6", [_vm._v("Avid AI Integration Checklist:")]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(
                              "Each integration that you connect allows Avid to derive deeper insights and better recommendations."
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.integration_types, function (type) {
                            return _c("p", { staticClass: "mb-2" }, [
                              _vm.connected_integration_list[type] != undefined
                                ? _c("img", {
                                    staticClass: "icon pe-1 green-icon",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/tick-circle.svg",
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "icon pe-1 red-icon",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/danger.svg",
                                    },
                                  }),
                              _vm._v(" "),
                              _vm.connected_integration_list[type] != undefined
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.getIntegrationLabel(type)) +
                                        " "
                                    ),
                                    _c("em", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(
                                            _vm.connected_integration_list[type]
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                  ])
                                : _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addSystemByIntegrationType(
                                            type
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getIntegrationLabel(type))
                                      ),
                                    ]
                                  ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "card system-connection-card h-100" },
                  [
                    _c("div", { staticClass: "card-body m-2" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _c("h6", [
                            _vm._v(
                              "Connect your " +
                                _vm._s(
                                  _vm.getIntegrationLabel(
                                    _vm.unconnected_integration
                                  )
                                ) +
                                " System"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getIntegrationReasoning(
                                  _vm.unconnected_integration
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addSystemByIntegrationType(
                                    _vm.unconnected_integration
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon pe-1",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/plus.svg",
                                },
                              }),
                              _vm._v(
                                "\n                                Connect Your " +
                                  _vm._s(
                                    _vm.getIntegrationLabel(
                                      _vm.unconnected_integration
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.if_viewing_connection
        ? _c("div", [
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-between gap-3" },
              [
                _c("div", { staticClass: "d-flex flex-row flex-grow-1" }, [
                  _c("div", { staticClass: "d-flex flex-column gap-2" }, [
                    _c("h4", {}, [
                      _vm._v(
                        "Your Connections (" +
                          _vm._s(_vm.connections.length) +
                          ")"
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-row gap-2" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu dropdown-menu-primary" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            "data-bs-toggle": "modal",
                            "data-bs-target":
                              "#connections-setting-system-modal",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addSystem()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon pe-1",
                            attrs: {
                              src: "/img/icons/dialexa-icons/keyboard-open.svg",
                            },
                          }),
                          _vm._v(" System\n                    "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            "data-bs-toggle": "modal",
                            "data-bs-target": "#file-modal",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addFile()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon pe-1",
                            attrs: {
                              src: "/img/icons/dialexa-icons/document-upload.svg",
                            },
                          }),
                          _vm._v(" File\n                    "),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "nav",
                { staticClass: "navbar navbar-expand primary-tab-bar pb-0" },
                [
                  _c("div", { staticClass: "container-fluid" }, [
                    _c("div", { staticClass: "collapse navbar-collapse" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn-group toggle-btn-group mb-4",
                          attrs: { role: "group" },
                        },
                        _vm._l(_vm.connection_tabs, function (tab_name, index) {
                          return _c(
                            "button",
                            {
                              staticClass: "btn btn-lg",
                              class:
                                index == _vm.connection_tab_index_active
                                  ? "btn-primary"
                                  : "btn-white",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActiveTab(index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(tab_name) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm.connection_tab_index_active == 0
                  ? _c("connections-settings-systems", {
                      attrs: {
                        client: _vm.client,
                        integrations: _vm.integrations,
                        connections: _vm.local_connections,
                      },
                      on: {
                        viewConnection: _vm.viewConnection,
                        updateConnection: _vm.updateConnection,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.connection_tab_index_active == 1
                  ? _c("connections-settings-files", {
                      attrs: {
                        client: _vm.client,
                        files: _vm.local_files,
                        num_files: _vm.num_files,
                      },
                      on: {
                        viewFile: _vm.viewFile,
                        openAddFileModal: _vm.openAddFileModal,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-text back-button",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.backToSelection()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon pe-1",
                      attrs: {
                        src: "/img/icons/dialexa-icons/chevrons-left.svg",
                      },
                    }),
                    _vm._v("\n                Back\n            "),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("connections-settings-view-dataset", {
                attrs: {
                  user: _vm.user,
                  client: _vm.client,
                  selected_connection_or_file: _vm.selected_connection_or_file,
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c("system-modal", {
        attrs: {
          id: "connections-setting-system-modal",
          client: _vm.client,
          integrations: _vm.local_intergation_list,
          connections: _vm.local_connections,
          connection_selected: _vm.connection_selected,
        },
        on: { connectionAdded: _vm.addConnection, resetModal: _vm.resetModal },
      }),
      _vm._v(" "),
      _c("file-modal", {
        attrs: { client: _vm.client, connection: _vm.file_connection },
        on: { connectionAdded: _vm.addConnection },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex flex-column justify-content-center" },
      [
        _c("canvas", {
          staticClass: "pie-chart",
          attrs: { id: "integration_completion_precentage" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("img", {
        staticClass: "modal-icon",
        attrs: { src: "/img/icons/dialexa-icons/plug-connected.svg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-lg btn-primary dropdown-toggle",
        attrs: {
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("img", {
          staticClass: "icon pe-1",
          attrs: { src: "/img/icons/dialexa-icons/plus.svg" },
        }),
        _vm._v("Add\n                "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }