var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: {
        id: "data-blend",
        "aria-labelledby": "data-blend",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "card box-content mt-3" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Data Blend Type"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_mf_selected.data_blend_type,
                        expression: "local_mf_selected.data_blend_type",
                      },
                    ],
                    staticClass: "form-select",
                    class: _vm.errors.data_blend_type != "" ? "is-invalid" : "",
                    attrs: {
                      disabled: _vm.mf_selected.id != -1,
                      id: "data-blend-type-select",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.local_mf_selected,
                            "data_blend_type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.changeDataBlendType()
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "", disabled: "", selected: "" } },
                      [_vm._v("Select the data blend type...")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "constituent" } }, [
                      _vm._v("Constituent"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "subscriber" } }, [
                      _vm._v("Subscriber"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "paid_media" } }, [
                      _vm._v("Paid Media"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "benchmark" } }, [
                      _vm._v("Benchmark"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.errors.data_blend_type != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.data_blend_type) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Client (optional)"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_db_selected,
                        expression: "local_db_selected",
                      },
                    ],
                    staticClass: "form-select",
                    attrs: {
                      id: "client-select",
                      disabled: _vm.mf_selected.id != -1,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.local_db_selected = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.changeDataBlendSelection()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "", selected: "" } }, [
                      _vm._v("Select the client..."),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      this.clients_filtered_by_data_blend_type,
                      function (i) {
                        return _c("option", { domProps: { value: i } }, [
                          _vm._v(_vm._s(i.client.name)),
                        ])
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "form-label" }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_mf_selected.name,
                      expression: "local_mf_selected.name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.name != "" ? "is-invalid" : "",
                  attrs: { type: "text", id: "name-input" },
                  domProps: { value: _vm.local_mf_selected.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_mf_selected,
                        "name",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.name) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            this.local_mf_selected.type == "metric"
              ? _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Format"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_mf_selected.format,
                            expression: "local_mf_selected.format",
                          },
                        ],
                        staticClass: "form-select",
                        class: _vm.errors.format != "" ? "is-invalid" : "",
                        attrs: { id: "format-select" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_mf_selected,
                              "format",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "", disabled: "", selected: "" } },
                          [_vm._v("Select the format...")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "money" } }, [
                          _vm._v("Money"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "percent" } }, [
                          _vm._v("Percent"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "number" } }, [
                          _vm._v("Number"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.format != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.errors.format) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "form-label" }, [_vm._v("Formula")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group formula-input-group" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_mf_selected.formula,
                          expression: "local_mf_selected.formula",
                        },
                      ],
                      staticClass: "form-control col-9",
                      class: _vm.errors.formula != "" ? "is-invalid" : "",
                      attrs: { type: "text", id: "formula-input" },
                      domProps: { value: _vm.local_mf_selected.formula },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.local_mf_selected,
                            "formula",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "searchable-select col-3",
                      attrs: {
                        options: _vm.local_columns,
                        searchable: true,
                        selectable: (option) => option.text != "",
                        label: "text",
                        filterable: false,
                      },
                      on: {
                        search: _vm.fetchOptions,
                        input: function ($event) {
                          return _vm.addColumnToText(_vm.column_selected)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selected-option",
                          fn: function ({ text, category }) {
                            return [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    category != null &&
                                      category != "" &&
                                      text != null &&
                                      text != ""
                                      ? category + ": "
                                      : ""
                                  ) +
                                  _vm._s(text) +
                                  "\n                                "
                              ),
                            ]
                          },
                        },
                        {
                          key: "open-indicator",
                          fn: function ({ attributes }) {
                            return [
                              _c(
                                "span",
                                _vm._b(
                                  {
                                    staticStyle: {
                                      width: "12px",
                                      "line-height": "8px",
                                    },
                                  },
                                  "span",
                                  attributes,
                                  false
                                ),
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 16 16",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "none",
                                          stroke: "#343a40",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d: "m2 5 6 6 6-6",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "option",
                          fn: function ({ text, category }) {
                            return [
                              text == ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "category-header",
                                      attrs: {
                                        "data-category": category,
                                        "data-isexpanded": "false",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.expandCategory(
                                            category,
                                            null
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(category) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "fa-solid fa-caret-right",
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "suboption",
                                      class:
                                        category == null || category == ""
                                          ? "show"
                                          : "",
                                      attrs: { "data-subcategory": category },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(text) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                        {
                          key: "no-options",
                          fn: function ({ search, searching, loading }) {
                            return [
                              _vm.is_loading
                                ? _c("div", { staticClass: "suboption show" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "spinner-border spinner-border-sm text-warning float-left",
                                        attrs: { role: "status" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "visually-hidden" },
                                          [_vm._v("Loading...")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "  Loading columns\n                                    "
                                    ),
                                  ])
                                : _c("div", { staticClass: "suboption show" }, [
                                    _c("em", [_vm._v("No results found")]),
                                  ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.column_selected,
                        callback: function ($$v) {
                          _vm.column_selected = $$v
                        },
                        expression: "column_selected",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.errors.formula != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.formula) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _vm.form.successful
            ? _c("div", { staticClass: "alert alert-success mt-3" }, [
                _vm._v(
                  "\n                The " +
                    _vm._s(this.mf_selected.type) +
                    "'s information has been updated!\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.error
            ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                _vm._v(
                  "\n                There was an error saving the " +
                    _vm._s(this.mf_selected.type) +
                    " information.\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  id: "save-button",
                  disabled: _vm.form.busy,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.update.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-btn fa-save" }),
                _vm._v(
                  " " +
                    _vm._s(this.local_mf_selected.id > 0 ? "Update" : "Add") +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            this.local_mf_selected.id > 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "button", id: "delete-button" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteMetricFilter()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-trash" }),
                    _vm._v(" Delete\n                "),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }