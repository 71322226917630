<template>
    <div class="generate-ppt--filter-panel sticky-filter-panel">
        <div class="generate-ppt--filter-panel--content flex-grow-1 mx-4">

            <div class="row mb-3">
                <label>Selected:</label>
                
                <div class="filter-badge-container">
                    <div class="badge badge-primary" :key="filter.filter_id + filter.filter_value"
                        v-for="(filter, index) in applied_filters_list" v-if="filter.filter_id != -1"
                        v-on:click.prevent="setActiveTab(filter.group_index)">
                        <span v-if="filter.filter_type == 'date_range'">
                            {{filter.filter_label}}: {{ dateRangeDisplay(filter.filter_value) }}
                        </span>
                        <span v-else-if="filter.filter_type == 'month_range'">
                            {{filter.filter_label}}: {{ monthRangeDisplay(filter.filter_value) }}
                        </span>
                        <span v-else-if="filter.filter_type == 'single_select'">
                            {{filter.filter_label}} = "{{filter.filter_value}}" 
                        </span>
                        <span v-else-if="filter.filter_type == 'multi_select' || filter.filter_type == 'month_picker'">
                            {{filter.filter_label}}{{ filter.filter_value.length >1 ? ' ('+filter.filter_value.length+')' : ' = "'+ filter.filter_value[0] +'"'}}
                        </span>
                        <span v-else-if="filter.filter_type == 'numeric_slider'">
                            {{filter.filter_label}} between {{ filter.filter_value[0] }} and {{ filter.filter_value[1] }}
                        </span>
                        <button v-if="!filter.is_required" class="btn btn-xs btn-link p-0 ps-1" v-on:click.prevent="removeAppliedFilter(filter, index)">
                            <i class="fas fa-close"></i>
                        </button>
                    </div>
                    <div class="badge badge-secondary text-dark" :key="filter.filter_id + filter.filter_value"
                        v-for="(filter, index) in applied_filters_list" v-if="filter.filter_id == -1">
                        <span v-if="filter.filter_type == 'single_select'">
                            {{filter.filter_label}} = "{{filter.filter_value}}" 
                        </span> 
                        <button class="btn btn-xs btn-link p-0 ps-1" v-on:click.prevent="removeAppliedFilter(filter, index)">
                            <i class="fas fa-close"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="btn-group toggle-btn-group mb-2 w-100" role="group">
                        <button type="button" class="btn" v-for="(tab, index) in getNonEmptyFilterGroup" v-on:click="setActiveTab(index)" v-if="tab.filter_group"
                            :class="index==active_tab_index ? 'btn-primary' : 'btn-white'">
                            {{ tab.filter_group }}
                        </button>
                    </div>
                </div>
            </div>

            <hr id="filter-panel-body-hr" />

            <div class="row flex-grow-1" v-for="(group, group_index) in getNonEmptyFilterGroup" :hidden="group_index != active_tab_index" :key="group.filter_group">
                <div class="mb-2" v-for="(filter, filter_index) in group.filters" v-if="!filter.options || filter.options.length != 1"> 
                    <advanced-filter-dropdown :filter="filter" :key="group.filter_group + '-' + filter.column"
                        :group_index="group_index" :field_index="filter_index"  :active_group_index="active_tab_index"  @updateFilter="updateFilterInGroup" :db_search="filter.db_search"
                        @searchInLongOptionList="searchInLongOptionList">
                    </advanced-filter-dropdown>
                </div>
            </div>
        </div>
        
        <!-- <div class="row sticky-bottom mx-0 py-2">
            <div class="w-100 d-flex flex-row justify-content-between align-items-center flex-nowrap gap-2">

                <div class="d-flex flex-row justify-content-end align-items-center gap-2">
                    <span>{{ filter_count }} {{(filter_count > 1 ? 'Filters': 'Filter')}} {{filter_count_label}}</span>
                </div>

                <div class="d-flex flex-row justify-content-end align-items-center flex-nowrap gap-2">
                    <button type="button" class="btn btn-secondary" @click.prevent="clearFilters()">
                        Clear All
                    </button>
                </div>

            </div>
        </div> -->
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import vSelect from "vue-select";
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
            Swal, vSelect, Datepicker
        },
        props: ['pages_selected', 'client'],
        data() {
            return {
                local_pages_selected: [],
                if_sticky: false,
                dimensional_filters_list: [],  // format: array of objects {filter_group: '', is_tab_active: boolean, filters: []}
                applied_filters_list:[],
                filter_errors: {},
                filter_count: 0,
                active_tab_index: 0
            };
        },
        // beforeMount() { 
        //     this.local_pages_selected = [...this.pages_selected]
        //     this.loadFilterGroups(); 
        //     this.getFilterCount();
        // },
        mounted() {
            // loop through dimensional_filters_list to update options fields

        },
        computed: {
            getNonEmptyFilterGroup: function() {
                return this.dimensional_filters_list.filter((group) => group.filters.length != 0);
            }
        },
        watch:{
            pages_selected: {
                handler: function(newVal, oldVal) {
                    this.local_pages_selected = [...this.pages_selected]
                    this.loadFilterGroups();   
                    this.dimensional_filters_list.forEach((filter_group, group_index) => {
                        filter_group.filters.forEach((filter, filter_index) => {
                            if (filter.options.length == 0){
                                this.loadFilterOptions(group_index, filter_index);
                            }
                        });
                    })
                    this.getAppliedFilterWithValue();
                    this.getFilterCount();
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
        },
        methods: {
            dateRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.date(value.start_date) + ' - ' + this.$options.filters.date(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it

                    return 'On or After ' + this.$options.filters.date(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.date(value.end_date);
                }
            },
            monthRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.month(value.start_date) + ' - ' + this.$options.filters.month(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it

                    return 'On or After ' + this.$options.filters.month(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.month(value.end_date);
                }
            },
            setDefaultFilterValues(dimension_list) {
                for (var i = 0; i <dimension_list.length; i++){
                    let dim = dimension_list[i];

                    //If there is value set...
                    if((dim.field_type != 'date_range' && dim.field_type != 'month_range' && dim.value && dim.value.length != 0) ||
                     ((dim.field_type == 'date_range' || dim.field_type == 'month_range') && dim.value && (dim.value.start_date || dim.value.end_date))) {
                        //Do nothing

                    }
                    //No value or default value, so set it to something blank
                    else {
                        if(dim.field_type == "multi_select" || dim.field_type == "month_picker"){
                            dim.value =  [];
                        } else if(dim.field_type == "single_select"){
                            dim.value = "";
                        } else if(dim.field_type == "numeric_slider"){
                            dim.value = null;
                        } else if(dim.field_type == "date_range" || dim.field_type == "month_range"){
                            dim.value = {
                                'start_date': null,
                                'end_date': null
                            }                            
                        }

                    }
                }
                return dimension_list;
            },
            getAppliedFilterWithValue(){
                this.applied_filters_list = [];
                this.dimensional_filters_list.forEach((group, group_i) => {
                    group.filters.forEach((filter, filter_i)=>{
                        if(filter.value == undefined && (filter.field_type == 'multi_select' || filter.field_type == 'month_picker')){
                            filter.value = [];
                        }
                        if(filter.value == undefined && filter.field_type == 'single_select'){
                            filter.value = '';
                        }
                        if(filter.value && (filter.value.length >0  ||
                            //Or a date/month range with one of the two dates set
                            ((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.value && (filter.value.end_date != null || filter.value.start_date != null)))) {

                            if (filter.field_type == "month_range"){
                                filter.value.start_date = moment(String(filter.value.start_date)).utc().format('YYYY-MM-DD');
                                filter.value.end_date =  moment(String(filter.value.end_date)).utc().format('YYYY-MM-DD');
                            }

                            this.applied_filters_list.push({
                                filter_id: filter.id,
                                filter_label: filter.label,
                                filter_value: filter.value,
                                //If it is year_type, that needs to come from the client, not the dim
                                filter_default_value: ((typeof filter.column === 'string' || filter.column instanceof String) && filter.column.indexOf('time_period.year_type') !== -1? this.client.default_reporting_period : filter.default_value),
                                filter_column_name: filter.column,
                                filter_type: filter.field_type,
                                is_required: filter.is_required,
                                group_index: group_i,
                                filter_index_in_group: filter_i
                            })
                        }
                    });
                });
                this.getFilterCount();
            },
            removeAppliedFilter(filter, filter_index){
                this.applied_filters_list.splice(filter_index, 1);
                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'single_select'){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = '';
                }
                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'numeric_slider'){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = null;
                }
                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'multi_select' 
                || this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'month_picker'){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = [];
                }

                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'date_range' ||
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'month_range'
                ){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = {
                        start_date: null,
                        end_date: null
                    };

                }
                this.getFilterCount();
                // this.$forceUpdate();
            },
            handleScroll() {
                if(this.$refs.stickyDiv == null) return;

                const divTop = this.$refs.stickyDiv.getBoundingClientRect().top;
                this.if_sticky = divTop <= 0;
            },
            loadFilterGroups(){
                var dimension_list_temp = [];

                for (var i = 0; i<this.local_pages_selected.length; i++){
                    for (var j = 0; j<this.local_pages_selected[i].dimensions.length; j++){
                        if(this.local_pages_selected[i].dimensions[j].disabled == undefined)
                            this.local_pages_selected[i].dimensions[j].disabled = true;
                        if(this.local_pages_selected[i].dimensions[j].options == undefined){
                            this.local_pages_selected[i].dimensions[j].options = [];
                            this.local_pages_selected[i].dimensions[j].db_search = false;
                        }

                        // check if duplicate dimension column exists
                        var index = dimension_list_temp.findIndex((dimension) => dimension.column == this.local_pages_selected[i].dimensions[j].column);
                        if (index < 0)
                            dimension_list_temp.push(this.local_pages_selected[i].dimensions[j]);
                    }
                }
                dimension_list_temp  = this.setDefaultFilterValues(dimension_list_temp);
                var group_array = [];

                var group_name = null;
                var group_filters_temp = [];
                
                dimension_list_temp.sort((a, b) => (a.filter_group > b.filter_group) ? 1 : -1); // sort by filter group name

                dimension_list_temp.forEach(pf => {
                    // setup filter error array
                    if (pf.is_required){
                        this.filter_errors[pf.label] = '';
                    }

                    if(group_name && pf.filter_group != group_name){
                        group_array.push({
                            filter_group: group_name,
                            filters: group_filters_temp
                        });
                        group_name = pf.filter_group;
                        group_filters_temp = [pf];
                    } else {
                        group_name = pf.filter_group;
                        group_filters_temp.push(pf);
                    }
                });
                group_array.push({
                    filter_group: group_name,
                    filters: group_filters_temp
                });

                // group_array[0].is_tab_active = true; // default first tab to be active
                this.dimensional_filters_list = group_array;
            },

            getMinMax(group_index, filter_index) {
                var filter = this.dimensional_filters_list[group_index].filters[filter_index];
                var data = {
                  source: 'data_blend',
                  data_source_id: this.pages_selected[0].data_blend.id,
                  client: this.client,
                  column: filter.column,
                };

                window.axios.post('/api/bigquery/get_min_max', data)
                    .then(response => {
                        filter.slider_min = response.data.min;
                        filter.slider_max = response.data.max;

                        if(filter.options && filter.options.length == 2 && !isNaN(filter.options[0]) && !isNaN(filter.options[1]) ){
                            if(filter.options[0] < this.slider_min)
                                filter.slider_min = filter.options[0];
                            if(filter.options[1] > this.slider_max)
                                filter.slider_max = filter.options[1];
                        }

                        this.dimensional_filters_list[group_index].filters[filter_index].disabled = false;
                        this.dimensional_filters_list[group_index].filters[filter_index] = JSON.parse(JSON.stringify(filter));
                        this.$forceUpdate();
                    });
            },
            loadFilterOptions(group_index, filter_index) {
                var filter = this.dimensional_filters_list[group_index].filters[filter_index];
                if(filter.column == null){
                    filter.options = [];
                    filter.db_search = false;
                    return;
                }
                if(filter.field_type == 'numeric_slider'){
                    this.getMinMax(group_index, filter_index);
                    return;
                }

                var data = {
                    client: this.client,
                    source: 'data_blend',
                    column: filter.column,
                    limit: 1000,
                    data_source_id: this.pages_selected[0].data_blend.id,
                    staging_table: false
                };

                window.axios.post('/api/bigquery/get_samples', data)
                .then(response => {
                    var db_values = [];

                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--){
                        if(response.data.samples[i].name == null || response.data.samples[i].name == 'Donation')
                            response.data.samples.splice(i, 1);
                        else {
                            if (filter.field_type == 'single_select' || filter.field_type == 'multi_select' || filter.field_type == 'month_picker'){
                                db_values.push(response.data.samples[i].name);
                            }
                        }
                    }
                    if (filter.field_type == 'month_picker'){ 
                        // sort by month order
                        const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        db_values.sort((a, b) => monthOrder.indexOf(a) > monthOrder.indexOf(b) ? 1 : -1);
                    } else {
                        db_values.sort((a, b) => (a.name > b.name ) ? 1 : -1); 
                    }

                    if (db_values.length == 0){
                        this.dimensional_filters_list[group_index].filters.splice(filter_index, 1);
                    } else {
                        filter.options = db_values;
                        filter.disabled = false;
                        filter.db_search = (db_values.length >= 999); //999 since it removes the null
                        this.dimensional_filters_list[group_index].filters[filter_index] = JSON.parse(JSON.stringify(filter));                        
                    }
                    this.$forceUpdate();
                });
            },
            checkIfDropdownsLoaded(){
                for (var i=0; i< this.dimensional_filters_list.length; i++){
                    for (var j=0; j<this.dimensional_filters_list[i].filters.length; j++){
                         if (this.dimensional_filters_list[i].filters[j].disabled == true)
                            return false;
                    }
                }
                return true;
            },
            setActiveTab(index){
                this.active_tab_index = index;
                // this.$forceUpdate();
            },
            getTileClass(filter, option){
                var classes = "";
                if (filter.field_type == 'tile-single-select'){
                    if (filter.value[0] && filter.value[0].id == option.id){
                        classes = 'tile-selected'
                    }                    
                }
                return classes;
            },
            selectOption(group_index, filter_index, option){
                if (this.dimensional_filters_list[group_index].filters[filter_index].field_type == 'tile-single-select'){
                    this.dimensional_filters_list[group_index].filters[filter_index].value = [option];
                } else { //'tile-multi-select'
                    this.dimensional_filters_list[group_index].filters[filter_index].value.push(option);
                }
                this.getFilterCount();
            },
            updateFilterInGroup(filter_details){
                // if there is chart filter applied, change filter option selection will remove the chart filter
                // if(this.applied_chart_filter.if_chart_filter_applied && filter_details.filter_value.includes(this.applied_chart_filter.chart_filters[0].value)){
                //     this.local_applied_chart_filter = {                    
                //         if_chart_filter_applied: false,
                //     };
                // }
                this.dimensional_filters_list[filter_details.group_index].filters[filter_details.field_index].value = filter_details.filter_value;
                this.getAppliedFilterWithValue();
                this.getFilterCount();
            },
            searchInLongOptionList(search_details){
                var filter = this.dimensional_filters_list[search_details.group_index].filters[search_details.field_index];
                var data = {
                    client: this.client,
                    source: 'data_blend',
                    column: filter.column,
                    limit: 1000,
                    data_source_id: this.pages_selected[0].data_blend.id,
                    staging_table: false,
                    search: search_details.search_key
                };
                window.axios.post('/api/bigquery/get_samples', data)
                .then(response => {
                    var db_values = [];

                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--){
                        if(response.data.samples[i].name == null)
                            response.data.samples.splice(i, 1);
                        else {
                            if (filter.field_type == 'single_select' || filter.field_type == 'multi_select'|| filter.field_type == 'month_picker'){
                                db_values.push(response.data.samples[i].name);
                            }
                        }
                    }
                    //No way a long seach can be a month picker
                    db_values.sort((a, b) => (a.name > b.name ) ? 1 : -1); 
                    filter.options = db_values;
                    filter.disabled = false;

                    this.dimensional_filters_list[search_details.group_index].filters[search_details.field_index] = JSON.parse(JSON.stringify(filter));
                    this.$forceUpdate();
                });
            },
            getFilterCount(){
                var filter_count_temp = 0;

                this.dimensional_filters_list.forEach((filter_group, group_index) => {
                    filter_group.filters.forEach((filter, filter_index) => {
                        if((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.value && (filter.value.start_date || filter.value.end_date))
                            filter_count_temp++;
                        else if(filter.field_type != 'date_range' && filter.field_type != 'month_range' && filter.value && filter.value.length != 0)
                            filter_count_temp++;
                     
                    });
                })
                this.filter_count = filter_count_temp;
                this.$emit('updateFilterCount', filter_count_temp);
                this.$emit('updateFilters', this.applied_filters_list);
            },  
        }
    }
</script>
