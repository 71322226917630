<template>
    <div class="campaign-overview asset-details">
        <div class="campaign-detail pb-3">
            <div class="card box-content">
                <div class="card-body">
                    <h4 class="mt-2 mb-0">Campaign Details</h4>
                    <div class="created_by_label mt-1" v-if="campaign.id > 0 && campaign.created_by_user">
                        <span><label class='mb-0 me-1'>Created By: </label>{{ campaign.created_by_user.first_name + ' ' + campaign.created_by_user.last_name }}</span>
                    </div>
                    <div class="row mb-3 mt-3">
                        <div class="col-8">
                            <label class="form-label">Name</label>
                            <input type="text" id="campaign-name-input" class="form-control" v-model="local_campaign.name"
                                :class="((errors.name != '' )? 'is-invalid':'')" >
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name }}
                            </div>
                        </div>
                        <div class="col-4">
                            <label class="form-label">Status</label>
                            <select class="form-select" id="status-select" v-model="local_campaign.status"
                            :class="((errors.status != '' )? 'is-invalid':'')">
                                <option value="" disabled selected>Select the status...</option>
                                <option value="draft">Draft</option>
                                <option value="in_review">In Review</option>
                                <option value="active">Active</option>
                                <option value="archive">Archive</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.status != ''">
                                {{errors.status}}
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="form-label">Type</label>
                            <select class="form-select" id="type-select" :class="((errors.campaign_type != '' )? 'is-invalid':'')"  
                            v-model="local_campaign.campaign_type_id" @change="changeCampaignType()">
                                <option value="" disabled selected>Select the type...</option>
                                <option v-for="i in this.campaign_types" v-bind:value="i.id" v-if="i.id > 0">{{i.name}}</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.campaign_type != ''">
                                {{errors.campaign_type}}
                            </div>
                        </div>
                        <div class="col-4" >
                            <label class="form-label">Start Date</label>
                            <div class="input-group" :class="((errors.start_date != '' )? 'is-invalid':'')" >
                                <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                <datepicker v-model="local_campaign.start_date" :use-utc="true" format="MMM d, yyyy"  
                                input-class="form-control" :class="((errors.start_date != '' )? 'is-invalid':'')" ></datepicker>
                                <div class="text-danger invalid-feedback" v-if="errors.start_date != ''">
                                    {{errors.start_date }}
                                </div>
                            </div>
                        </div>
                        <div class="col-4" >
                            <label class="form-label">End Date (Optional)</label>
                            <div class="input-group" :class="((errors.date != '' )? 'is-invalid':'')" >
                                <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                <datepicker v-model="local_campaign.end_date" :use-utc="true" format="MMM d, yyyy"  
                                input-class="form-control"></datepicker>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row mb-3" v-for="prompt in local_campaign.prompts">
                        <div class="col-12">
                            <label class="form-label mb-0">{{  prompt.title }}</label>
                            <p class="input-field-description text-dark">{{  prompt.question }}</p>
                            <textarea class="form-control"  v-model="prompt.answer"></textarea>
                        </div>
                    </div>

                    <div class="row mb-2" >
                        <div class="col">
                            <!-- Update Button -->
                            <button type="submit" class="btn btn-primary me-2" id="save-button"
                                    @click.prevent="update"
                                    :disabled="form.busy">
                                <i class="fa fa-btn fa-save"></i> {{ (this.local_campaign.id ? "Update" : "Add Campaign" ) }}
                            </button>
                            <button type="button" class="btn btn-secondary" id="delete-button"
                                v-if="local_campaign.id != null"
                                v-on:click="deleteCampaign()">
                                <i class="fa fa-btn fa-trash"></i> Delete
                            </button>
                            <a :href="'/campaigns/' + client.url" v-else class="btn btn-secondary"><i class="fas fa-close"></i> Cancel</a>
                            <!-- Success Message -->
                            <div class="alert alert-success mt-3" v-if="form.successful">
                                The campaign details have been saved!
                            </div>
                            <div class="alert alert-danger mt-3" v-if="form.error">
                                There was an error saving the campaign details.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="asset-list mt-4" v-if="local_campaign.id != null">
            <div class="row">
                <div class="col">
                    <h4 class="mb-2">
                        Assets <span class="fst-italic fs-6 ms-2 mb-2" v-if="hasAssetEdits()">(<a :href="'/campaigns/'+ client.url +'/review/'+ local_campaign.url">Review All Edits</a>)</span>
                        <button class="btn btn-primary btn-sm dropdown-toggle float-end ms-3 mb-2" type="button"
                            id="add-asset-type" data-bs-toggle="dropdown" aria-expanded="false"
                            v-if="available_asset_types.length > 0" :disabled="campaign_type_edited" >
                            <span><i class="fas fa-fw fa-plus"></i>Add Asset Type</span>
                        </button>
                        <ul v-if="available_asset_types.length > 0" class="dropdown-menu" aria-labelledby="add-asset-type">
                            <li v-for="type in available_asset_types"><a class="dropdown-item" href="#"
                                @click.prevent="addAssetType(type.id)">{{type.name }}</a></li>
                        </ul>
                                         
                        <button class="btn btn-secondary btn-sm float-end mb-2" v-if="if_has_unfinished_asset" @click="generateAllAssets()" type="button" :disabled="form.busy">
                            <i class="fa fa-btn fa-edit"></i> <span>Generate Unfinished Assets</span>
                        </button>
                    </h4>   
                </div>
            </div>

            <div class="card box-content mb-3">
                <div class="card-body p-0">
                    <div class="overflow-hidden rounded-top">
                        <div class="p-1 bg-dark"></div>
                    </div>
                    <div class="table-responsive mb-2">
                        <table class="table gy-0 m-0">
                            <thead class="table-dark">
                                <tr>
                                    <th class='align-middle pt-0 ps-3'>Type</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Total Assets</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Number in Draft</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Last Updated At</th>
                                    <th class="align-middle d-none pt-0 d-sm-table-cell">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr v-for="asset_type in local_campaign.asset_types">
                                    <td class="align-middle">
                                        <div class="d-flex align-items-center">
                                            <h4 class='d-inline-block mb-0 text-center me-2'><i :class="asset_type.icon_class"  style="width:24px;"></i></h4>
                                            <a class="p-0" :href="'/campaigns/'+ client.url +'/assets/'+ local_campaign.url+'/'+ asset_type.id+'/false'">
                                                {{ asset_type.name }}
                                            </a>
                                        </div>
                                    </td>
                                    <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                        <span>{{ getNumAssets(asset_type.id) }} <a :href="'/campaigns/'+ client.url +'/review/'+ local_campaign.url+'/'+ asset_type.id" v-if=" hasAssetEdits(asset_type.id)" >(Review Edits)</a></span>
                                    </td>
                                    <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                        <span>{{ getNumUnfinishedAssets(asset_type.id) }}</span>
                                    </td>
                                    <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                        {{ getLastUpdateDate(asset_type.id) | date }}
                                    </td>
                                    <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                        <button class="btn btn-secondary btn-sm" v-on:click="deleteAssets(asset_type.id)"><i class="fa fa-trash  me-0" aria-hidden="true"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="oauth-overlay" v-if="campaign_type_edited">
                        <div class="overlay"></div>
                        <div class="cta">
                            <div class="row d-flex align-items-center mx-3">
                                You must save your campaign updates before you can edit the new assets.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-3" v-if="local_campaign.id != null">
                <div class="col" id="public-links">
                    <label class="form-label">Approval Page Link <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="The link below will allow clients to review any assets that have the status of 'Ready for Review'."></i></label>
                    <input type="text" class="form-control bg-white ps-2 text-muted "
                        @click="copyToClipboard()" ref="clone" readonly
                        v-model="current_domain + 'campaigns/' + client.url + '/approval/' + local_campaign.url"
                        @focus="$event.target.select()">
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Datepicker from 'vuejs-datepicker';
    import Swal from 'sweetalert2';
    export default {
        components: {Datepicker, Swal},
        props: ['client','campaign','campaign_types'],
        data() {
            return {
                local_campaign: null,
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors: {
                    name:'',
                    status:'',
                    campaign_type:'',
                    start_date:'',
                },
                campaign_type_edited: false,
                if_has_unfinished_asset: false
            };
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            },
        },
        beforeMount() {
            if(!this.campaign.id){ // Add a new campaign
                this.resetForm();
            }

            //If there is an existing campaign
            if (this.campaign.campaign_type && this.campaign.campaign_type.id){
                this.local_campaign = {...this.campaign};
                this.checkUnfinishedAssets();
            }
        },
        computed: {
            campaign_type(){
                return this.campaign_types.find(type => (type.id == this.local_campaign.campaign_type_id));
            },
            available_asset_types() {
                var available_types = [];
                //Loop though the campaign types
                for(var i = 0; i < this.campaign_types.length; i++) {
                    for(var j = 0; j < this.campaign_types[i].asset_types.length; j++){
                        //If the asset type is not already in the campaign
                        if(this.local_campaign.asset_types.find(type => (type.id == this.campaign_types[i].asset_types[j].id)) == null && available_types.find(type => (type.id == this.campaign_types[i].asset_types[j].id)) == null) {
                            available_types.push(this.campaign_types[i].asset_types[j]);
                        }
                    }
                }

                //Sort the array by the name
                available_types.sort((a, b) => (a.name > b.name) ? 1 : -1);

                return available_types;
            },

            current_domain() {
                return window.location.protocol + '//'+window.location.host+'/';
            }
        },
        methods: {
            checkUnfinishedAssets(){
                this.local_campaign.assets.forEach(asset => {
                    if (asset.source == null){
                        this.if_has_unfinished_asset = true;
                    }
                })
            },
            copyToClipboard() {
              this.$refs.clone.focus();
              document.execCommand('copy');
              Swal.fire({
                  html: '<h4 class="mb-0 text-white">Copied!</h4>',
                  target: '#public-links',
                  icon: 'success',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 2000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
            },
            addAssetType(asset_type_id){
                var types = this.available_asset_types;

                //Find the asset type
                var asset_type = types.find(type => (type.id == asset_type_id));
                var asset = {
                    id: null,
                    client_id: this.client.id,
                    campaign_id: this.local_campaign.id,
                    initiative_id: null,
                    asset_type_id: asset_type.id,
                    description: "",
                    name: asset_type.name.slice(0, -1),
                    status: 'draft',
                    inputs: null,
                    order: 0,
                    //Get the current timestamp
                    updated_at: Date.now()
                };
                this.local_campaign.assets.push(asset);
                this.local_campaign.asset_types.push(asset_type);

                window.axios.post('/api/campaigns/'+this.client.url + '/assets', asset);
            },
            generateAllAssets() {
                Swal.fire({
                  html: '<h4 class="mb-0 text-white">Generating Unfinished Assets...</h4>',
                  target: '#public-links',
                  icon: 'warning',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 3000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });

                window.axios.post('/api/campaigns/'+this.client.url + '/generate_all/' + this.local_campaign.url)
                    .then(response => {
                        Swal.fire({
                            title: "Generated " + response.data.num_assets + " assets for this campaign" ,
                            text: "The unfinished assets for this campaign has been generated." ,
                            icon: "success",
                            iconColor:  "#46705F",
                            confirmButtonColor: "#46705F"
                        });
                    });
            },
            getNumAssets(asset_type_id) {
                var count = 0;
                for(var i = 0; i < this.local_campaign.assets.length; i++)
                    if(this.local_campaign.assets[i].asset_type_id == asset_type_id)
                        count++;

                return count;
            },
            getNumUnfinishedAssets(asset_type_id) {
                var count = 0;
                for(var i = 0; i < this.local_campaign.assets.length; i++)
                    if(this.local_campaign.assets[i].asset_type_id == asset_type_id && this.local_campaign.assets[i].status == 'draft')
                        count++;

                return count;
            },
            hasAssetEdits(asset_type_id = null) {
                var count = 0;
                for(var i = 0; i < this.local_campaign.assets.length; i++){
                    if((asset_type_id == null || this.local_campaign.assets[i].asset_type_id == asset_type_id) && this.local_campaign.assets[i].status == 'in_review' && this.local_campaign.assets[i].latest_review != null)
                        return true;
                }

                return false;
            },
            getLastUpdateDate(asset_type_id){
                var last_update = null;
                for(var i = 0; i < this.local_campaign.assets.length; i++){
                    if(this.local_campaign.assets[i].asset_type_id == asset_type_id &&
                        (last_update == null || last_update < this.local_campaign.assets[i].updated_at)){
                          last_update = this.local_campaign.assets[i].updated_at;       
                    }       
                }
                return last_update;
            },
            clearErrors() { //reset errors to empty strings
                this.errors = {
                    name:'',
                    status:'',
                    campaign_type:'',
                    start_date:'',
                }
            },
            hasErrors() { //validate the form               
                if(this.local_campaign.name == null || this.local_campaign.name == ""){
                    this.errors.name = "Name is required.";
                } else
                    this.errors.name = "";

                if(this.local_campaign.status == null || this.local_campaign.status == ""){
                    this.errors.status = "Status is required.";
                } else
                    this.errors.status = "";

                if(this.local_campaign.campaign_type_id == null || this.local_campaign.campaign_type_id == -1){
                    this.errors.campaign_type = "Type is required.";
                } else
                    this.errors.campaign_type = "";

                if(this.local_campaign.start_date == null || this.local_campaign.start_date == ""){
                    this.errors.start_date = "Start Date is required.";
                } else
                    this.errors.start_date = "";

                if (this.errors.name!="" || this.errors.status!=""
                || this.errors.campaign_type!="" || this.errors.start_date!=""){
                    return true
                } else{
                    return false
                }
            }, 
            resetForm(){ 
                this.local_campaign = {
                    client_id: this.campaign.client_id,
                    name: '',
                    status: 'draft', // default to draft 
                    campaign_type_id: -1,
                    start_date: null,
                    end_date: null,
                    prompts: [],
                    asset_types: [],
                    assets: []
                };
            },            
            update() {
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                this.form.busy = true;

                this.campaign_type_edited = false;

                if(!this.local_campaign.id) {

                    if(this.local_campaign.assets.length > 0) {
                        // loop through assets array to assign order
                        var asset_type_id_temp = this.local_campaign.assets[0].asset_type_id;
                        var order = 1;
                        this.local_campaign.assets.forEach(asset => {
                            if (asset.asset_type_id != asset_type_id_temp){
                                asset_type_id_temp = asset.asset_type_id;
                                order = 1;
                            }
                            asset.order = order;
                            order++;
                        });
                    }

                    window.axios.post('/api/campaigns/'+this.client.url, this.local_campaign)
                    .then(response => {
                        self.form.busy = false;
                        self.form.successful = true;
                        setTimeout( function() {self.form.successful = false;} , 5000);

                        var primary_asset_type = this.local_campaign.asset_types.find(type => type.pivot.is_primary == 1);
                        this.local_campaign = response.data.campaign;

                        if(primary_asset_type){ // if campaign type has a primary asset type
                           this.loadPrimaryAssetTypePopup(primary_asset_type);    
                        }
                                             
                    });
                } else {
                    window.axios.put('/api/campaigns/'+this.client.url +'/'+ this.local_campaign.id, this.local_campaign)
                    .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false;} , 5000);
                        } else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                    });
                } 
            },
            loadPrimaryAssetTypePopup(primary_asset_type){
                Swal.fire({
                  title: "This campaign has been created",
                  text: "Would you like to generate the primary " + primary_asset_type.name.toLowerCase().substring(0, primary_asset_type.name.length-1) + " now?",
                  icon: "success",
                  iconColor:  "#46705F",
                  showCancelButton: true,
                  cancelButtonText: "No, thank you.",
                  confirmButtonColor: "#46705F",
                  confirmButtonText: "Yes, please",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // start autogenerate process based on description and redirect

                        var primary_asset_object = this.local_campaign.assets.find(a => (a.asset_type_id == primary_asset_type.id && a.name == primary_asset_type.pivot.name ));
                        var request_body = {
                            option_selected: 'description',
                            content: null,
                            description: primary_asset_type.description
                        };
                        // Swal.fire({
                        //     html: '<h4 class="mb-0 text-white">Generating asset ...</h4>',
                        //     target: '#public-links',
                        //     icon: 'success',
                        //     toast: true,
                        //     position: 'bottom-right',
                        //     showConfirmButton: false,
                        //     timer: 2000,
                        //     iconColor: 'white',
                        //     customClass: {
                        //         popup: 'bg-primary'
                        //     },
                        // }); 
                        window.axios.put('/api/campaigns/'+this.client.url+'/generate/'+primary_asset_object.id, request_body)
                            .then(response => {
                                window.location = "/campaigns/"+this.client.url+'/assets/'+this.local_campaign.url+'/'+primary_asset_type.id+'/false';
                        });
                    } 
                });
            },
            changeCampaignType(){

                this.campaign_type_edited = true;

                let old_prompts = this.local_campaign.prompts;
                //Update the prompts
                this.local_campaign.prompts = this.campaign_type.prompts;
                for(var i = 0; i < this.local_campaign.prompts.length; i++) {
                    this.local_campaign.prompts[i].answer = "";
                }

                //Update the prompts if the old prompts exist and the title is the same
                if(old_prompts)
                    for(var i = 0; i < old_prompts.length; i++) {
                        for(var j = 0; j < this.local_campaign.prompts.length; j++) {
                            if(old_prompts[i].title == this.local_campaign.prompts[j].title) {
                                this.local_campaign.prompts[j].answer = old_prompts[i].answer;
                                break;
                            }
                        }
                    }

                this.errors.campaign_type = "";
                var used_assets = [];
                //Delete all existing assets that are empty
                for(var i = this.local_campaign.assets.length-1; i >= 0; i--) {
                    if(this.local_campaign.assets[i].inputs == null) {
                        this.local_campaign.assets.splice(i, 1);
                    }
                    else
                        used_assets.push(this.local_campaign.assets[i].asset_type_id);
                }

                var left_over_assets = {...this.local_campaign.assets};
                //Now update the campaign's asset types
                var old_asset_types = {...this.local_campaign.asset_types};
                this.local_campaign.asset_types = [];

                //Then, loop through the chosen campaign type and re-add any missing assets
                var assets = this.campaign_type.asset_types;
                for(var i = 0; i < assets.length; i++) {
                    if(used_assets.indexOf(assets[i].id) == -1) {
                        let init_id = null;

                        for(var j = 0; j < left_over_assets.length; j++){
                            if(left_over_assets[j].asset_type_id == assets[i].id){
                                init_id = left_over_assets[j].initiative_id;
                                break;
                            }
                        }

                        this.local_campaign.assets.push({
                            id: null,
                            campaign_id: this.local_campaign.id,
                            initiative_id: init_id,
                            asset_type_id: assets[i].id,
                            name: assets[i].pivot.name,
                            description: assets[i].pivot.description,
                            order: null, 
                            inputs: null
                        });

                        //If the asset type hasn't already been added (Need to look at the objects in the array for the id)
                        if(this.local_campaign.asset_types.filter(e => e.id == assets[i].id).length == 0)
                            this.local_campaign.asset_types.push(assets[i]);

                        if(assets[i].pivot.is_primary == 1){
                            for(var j = 0; j < this.local_campaign.asset_types.length; j++){
                                if(this.local_campaign.asset_types[j].id == assets[i].id){
                                    this.local_campaign.asset_types[j] = assets[i];
                                    break;
                                }
                            }
                        }

                    }
                    else
                        used_assets.splice(used_assets.indexOf(assets[i].id), 1);
                }

                //If there are any asset types left over, add them too
                for(var i = 0; i < old_asset_types.length; i++){
                    if(this.local_campaign.asset_types.filter(e => e.id == old_asset_types[i].id).length == 0)
                        this.local_campaign.asset_types.push(old_asset_types[i]);
                }
            },
            deleteAssets(asset_type_id) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this asset type?",
                  text: "When you remove the asset type, all of the existing assets, including those already approved, will be removed from the system.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {

                        //Remove all of the assets
                        for(var i = this.local_campaign.assets.length-1; i >= 0; i--) {
                            if(this.local_campaign.assets[i].asset_type_id == asset_type_id) {
                                this.local_campaign.assets.splice(i, 1);
                            }
                        }
                        //Remove the asset type
                        for(var i = this.local_campaign.asset_types.length-1; i >= 0; i--) {
                            if(this.local_campaign.asset_types[i].id == asset_type_id) {
                                this.local_campaign.asset_types.splice(i, 1);
                                break;
                            }
                        }
                        //And actually delete them from the database
                        window.axios.delete('/api/campaigns/'+this.local_campaign.url+'/asset_types/' + asset_type_id);
                    }
                });
            },
            deleteCampaign() {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this campaign?",
                  text: "This campaign will be removed from the client.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) { 
                        window.axios.delete('/api/campaigns/'+this.client.url +'/'+ this.local_campaign.id)
                            .then(response => {
                                if (response.status == 200) {                                    
                                    Swal.fire({
                                        title: "Deleted!",
                                        text: "This campaign has been removed.",
                                        icon: "success",
                                        iconColor:  "#46705F",
                                        confirmButtonColor: "#46705F"
                                    }).then((result) => {
                                        window.location = "/campaigns/"+this.client.url;
                                    });
                                }
                            });
                    }
                });
            }
        }
    }
</script>
