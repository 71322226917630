<template>
    <div class="files" >
        <div class="card file-dropzone box-content mb-3">
            <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone"
                :options="dropzoneOptions"
                v-on:vdropzone-processing="onProcessing"
                v-on:vdropzone-success="onSuccess"
                v-on:vdropzone-sending="onSending"></vue-dropzone>
        </div>
        <transition name="fade-slow">
            <div class="alert alert-success mb-3 box-content" v-if="successful_file">
                Your file(s) have been uploaded and processed. Thank you!
            </div>
        </transition>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        components: {
            vueDropzone: vue2Dropzone,
        },

        props: ['client'],
        data() {
            return {
                successful_file: false,
                dropzoneOptions: {
                    url: "#",
                    method: 'put',
                    timeout: 0,
                    parallelUploads: 2,
                    dictDefaultMessage: "Click or Drag/Drop a File Here",
                    headers: {
                        'charset': "utf-8",
                        "Cache-Control": "",
                    },
                    withCredentials: false,
                    accept: function(file, done) {

                        var url_parts = window.location.pathname.split('/');
                        var client_url = url_parts[2];
                        this.options.headers['Content-Type'] = file.type;
                        window.axios.post( "/api/file_upload/get_signed_url", { url: client_url, file_name: Date.now() + "_" +file.name }).then(function(data) {

                           //I can't access the variable in the "data" property so I'll just save it to the html
                          if($("#dropzone").data("file_urls") == undefined)
                            $("#dropzone").data("file_urls", []);

                            //Save the URL for when it is actually sent in a second
                            var arr = $("#dropzone").data("file_urls");
                            arr[file.upload.uuid] = data.data.url;

                            $("#dropzone").data("file_urls", arr);
                            done();
                        });

                    },
                    init: function() {
                        const dz = this;

                        dz.on("processing", (file) => {
                            dz.options.headers["Content-Type"] = file.type;
                        });
                    },
                    maxFilesize: null,
                },
            };
        },
        methods: {

            onProcessing(file) {
                //Set the upload URL for this specific file
                var file_urls = $("#dropzone").data('file_urls');
                document.getElementById("dropzone").dropzone.options.url = file_urls[file.upload.uuid];

            },
            onSuccess( file, res ) {
                var data = new FormData();
                data.append("file_name", file.name);
                data.append("gcs_path", file.xhr.responseURL);
                data.append("client_url", this.client.url);

                //Remove it from the dropzone UI
                var fadeTarget = document.getElementsByClassName("dz-success")[0];
                var fadeEffect = setInterval(function () {
                    if (!fadeTarget.style.opacity) {
                        fadeTarget.style.opacity = 1;
                    }
                    if (fadeTarget.style.opacity > 0) {
                        fadeTarget.style.opacity -= 0.03;
                    } else {
                        clearInterval(fadeEffect);
                        document.getElementById("dropzone").dropzone.removeFile(file);
                    }
                }, 50);

                window.axios.post('/api/file_upload/new_file', data);
                this.successful_file = true;
                var self = this;

                setTimeout(function() {
                    self.successful_file = false;
                }, 5000);

            },
            onSending( file, xhr, formData ) {
                var _send = xhr.send;
                xhr.send = function() {
                  _send.call(xhr, file);
                };
            },

        }
    }
</script>