var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "file-modal",
        "aria-labelledby": "file-modal",
        "data-bs-backdrop": "static",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content mx-2" }, [
          _c("div", { staticClass: "modal-header border-bottom-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-none close-modal",
                attrs: { type: "button", "data-bs-dismiss": "file-modal" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-close me-0" })]
            ),
          ]),
          _vm._v(" "),
          !_vm.add_file_option
            ? _c("div", { staticClass: "modal-body pt-0" }, [
                _c("h3", [_vm._v("Add Data Files")]),
                _vm._v(" "),
                _c("p", [_vm._v("How would you like to add data files?")]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card option-card p-3 h-100",
                        on: {
                          click: function ($event) {
                            return _vm.selectAddFileOption("upload_file")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "modal-icon mb-2",
                          attrs: {
                            src: "/img/icons/dialexa-icons/file-upload.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("h5", [_vm._v("Upload")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Upload files from your computer or enable a public upload link."
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card option-card p-3 h-100",
                        on: {
                          click: function ($event) {
                            return _vm.selectAddFileOption("sftp_3rd_party")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "modal-icon mb-2",
                          attrs: {
                            src: "/img/icons/dialexa-icons/folder-connection.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("h5", [_vm._v("SFTP")]),
                        _vm._v(" "),
                        _c("h5", [_vm._v("(3rd Party Hosting)")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("Pull your data from your own SFTP server."),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card option-card p-3 h-100",
                        on: {
                          click: function ($event) {
                            return _vm.selectAddFileOption("sftp_avid")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "modal-icon mb-2",
                          attrs: {
                            src: "/img/icons/dialexa-icons/folder-connection.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("h5", [_vm._v("SFTP")]),
                        _vm._v(" "),
                        _c("h5", [_vm._v("(Avid AI Hosted)")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Create your own secure SFTP site.")]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3 mx-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.add_file_option == "upload_file"
            ? _c("div", { staticClass: "modal-body pt-0" }, [
                _c("h3", [_vm._v("Add Data Files")]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-4" }, [
                  _c(
                    "nav",
                    {
                      staticClass: "navbar navbar-expand primary-tab-bar pb-0",
                    },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "collapse navbar-collapse" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 d-flex toggle-btn-group btn-group",
                              attrs: { role: "group" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-lg col-6",
                                  class:
                                    _vm.upload_option == "upload"
                                      ? "btn-primary"
                                      : "btn-white",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.upload_option = "upload"
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        Upload\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-lg col-6",
                                  class:
                                    _vm.upload_option == "public_link"
                                      ? "btn-primary"
                                      : "btn-white",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.upload_option = "public_link"
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        Public Link\n                                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.upload_option == "upload"
                  ? _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("p", [
                          _vm._v(
                            "Accepted File Types: .csv, .txt, and similar delimited text files."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Once the file is dropped on the box area, the uploading process will start automatically."
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "file-dropzone my-2" },
                          [
                            _c("vue-dropzone", {
                              ref: "myVueDropzone",
                              staticClass: "dropzone",
                              attrs: {
                                id: "dropzone",
                                options: _vm.dropzoneOptions,
                              },
                              on: {
                                "vdropzone-processing": _vm.onProcessing,
                                "vdropzone-success": _vm.onSuccess,
                                "vdropzone-sending": _vm.onSending,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.if_uploaded
                        ? _c(
                            "div",
                            {
                              staticClass: "alert alert-primary",
                              attrs: { role: "alert" },
                            },
                            [
                              _vm._v(
                                "\n                        This file has been uploaded and processing. You can click on the "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/settings/" + _vm.client.url + "/files",
                                  },
                                },
                                [_vm._v("File tab")]
                              ),
                              _vm._v(
                                " to see the file list and the processing status.\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary w-100",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.add_file_option = null
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Back to Upload Options\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.upload_option == "public_link"
                  ? _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("h5", [_vm._v("Public Upload Link")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Enabling a public link will allow you, or other users, to upload data files without having to login. You’ll be given a secure link and users will only be able to add new files; they cannot view or edit existing ones. "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-12 my-2",
                            attrs: { id: "public-links" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLink()
                                      },
                                    },
                                  },
                                  [
                                    this.local_conn &&
                                    this.local_conn.connection_criteria
                                      .public_link == true
                                      ? _c("span", [
                                          _vm._v("Disable Public Upload Link"),
                                        ])
                                      : _c("span", [
                                          _vm._v("Enable Public Upload Link"),
                                        ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  {
                                    class:
                                      _vm.upload_option == "public_link"
                                        ? ""
                                        : "hidden",
                                    attrs: { name: "slide-slow" },
                                  },
                                  [
                                    this.local_conn &&
                                    this.local_conn.connection_criteria
                                      .public_link == true
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.file_url + _vm.client.url,
                                              expression:
                                                "file_url + client.url",
                                            },
                                          ],
                                          ref: "clone",
                                          staticClass:
                                            "form-control border-primary bg-white ps-2 text-muted",
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value:
                                              _vm.file_url + _vm.client.url,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyToClipboard()
                                            },
                                            focus: function ($event) {
                                              return $event.target.select()
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.file_url + _vm.client,
                                                "url",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary w-100",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.add_file_option = null
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Back to Upload Options\n                            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.add_file_option == "sftp_3rd_party" ||
          _vm.add_file_option == "sftp_avid"
            ? _c("div", { staticClass: "modal-body pt-0" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.add_file_option == "sftp_3rd_party"
                        ? "SFTP (3rd Party Hosting)"
                        : "SFTP (Avid AI Hosted)"
                    )
                  ),
                ]),
                _vm._v(" "),
                _vm.form.loading
                  ? _c("div", [_vm._m(1)])
                  : _vm.selected_integration_info.type == "credentials"
                  ? _c("div", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.selected_integration_info.instructions
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "system-connection-fields row mt-2" },
                        _vm._l(
                          _vm.selected_integration_info.field,
                          function (field, field_index) {
                            return _c("div", { staticClass: "mb-1" }, [
                              field.type != "checkbox" && field.type != "hidden"
                                ? _c("label", { staticClass: "form-label" }, [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(field.label),
                                      },
                                    }),
                                    _vm._v(" "),
                                    field.tooltip && field.tooltip.length > 0
                                      ? _c("i", {
                                          staticClass: "fas fa-info-circle",
                                          attrs: {
                                            "data-bs-toggle": "tooltip",
                                            title: field.tooltip,
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "text"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.value,
                                        expression: "field.value",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: field.error ? "is-invalid" : "",
                                    attrs: { type: "text", name: field.id },
                                    domProps: { value: field.value },
                                    on: {
                                      change: function ($event) {
                                        field.error = false
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          field,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "label"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.value,
                                        expression: "field.value",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      disabled: "disabled",
                                      name: field.id,
                                    },
                                    domProps: { value: field.value },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          field,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "textarea"
                                ? _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.value,
                                        expression: "field.value",
                                      },
                                    ],
                                    staticClass: "form-control full-height",
                                    attrs: { name: field.id },
                                    domProps: { value: field.value },
                                    on: {
                                      change: function ($event) {
                                        field.error = false
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          field,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "password" ||
                              field.type == "password-text"
                                ? _c("div", { staticClass: "input-group" }, [
                                    (field.type == "password"
                                      ? "password"
                                      : "text") === "checkbox"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.value,
                                              expression: "field.value",
                                            },
                                          ],
                                          ref: "password",
                                          refInFor: true,
                                          staticClass:
                                            "form-control password-input input_v1",
                                          class: field.error
                                            ? "is-invalid"
                                            : "",
                                          attrs: {
                                            "aria-label": "Password",
                                            "aria-describedby":
                                              "password-addon",
                                            autocomplete: "off",
                                            name: field.id,
                                            name: "password",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(field.value)
                                              ? _vm._i(field.value, null) > -1
                                              : field.value,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = field.value,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        field,
                                                        "value",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        field,
                                                        "value",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(field, "value", $$c)
                                                }
                                              },
                                              function ($event) {
                                                field.error = false
                                              },
                                            ],
                                          },
                                        })
                                      : (field.type == "password"
                                          ? "password"
                                          : "text") === "radio"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.value,
                                              expression: "field.value",
                                            },
                                          ],
                                          ref: "password",
                                          refInFor: true,
                                          staticClass:
                                            "form-control password-input input_v1",
                                          class: field.error
                                            ? "is-invalid"
                                            : "",
                                          attrs: {
                                            "aria-label": "Password",
                                            "aria-describedby":
                                              "password-addon",
                                            autocomplete: "off",
                                            name: field.id,
                                            name: "password",
                                            type: "radio",
                                          },
                                          domProps: {
                                            checked: _vm._q(field.value, null),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                return _vm.$set(
                                                  field,
                                                  "value",
                                                  null
                                                )
                                              },
                                              function ($event) {
                                                field.error = false
                                              },
                                            ],
                                          },
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.value,
                                              expression: "field.value",
                                            },
                                          ],
                                          ref: "password",
                                          refInFor: true,
                                          staticClass:
                                            "form-control password-input input_v1",
                                          class: field.error
                                            ? "is-invalid"
                                            : "",
                                          attrs: {
                                            "aria-label": "Password",
                                            "aria-describedby":
                                              "password-addon",
                                            autocomplete: "off",
                                            name: field.id,
                                            name: "password",
                                            type:
                                              field.type == "password"
                                                ? "password"
                                                : "text",
                                          },
                                          domProps: { value: field.value },
                                          on: {
                                            change: function ($event) {
                                              field.error = false
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                field,
                                                "value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "input-group-text",
                                        staticStyle: {
                                          "border-left": "none",
                                          "background-color": "white",
                                        },
                                        attrs: {
                                          title: !field.visible
                                            ? "Show password"
                                            : "Hide password",
                                          id: "password-addon",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleVisibility(field)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            !field.visible
                                              ? "fa-eye"
                                              : "fa-eye-slash",
                                          ],
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "select"
                                ? _c("div", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: field.value,
                                            expression: "field.value",
                                          },
                                        ],
                                        staticClass: "form-select",
                                        class: field.error ? "is-invalid" : "",
                                        attrs: { name: field.id },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                field,
                                                "value",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              field.error = false
                                            },
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        Object.keys(field.options),
                                        function (option) {
                                          return _c(
                                            "option",
                                            { domProps: { value: option } },
                                            [
                                              _vm._v(
                                                _vm._s(field.options[option])
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "warning"
                                ? _c("div", [
                                    _c(
                                      "p",
                                      { staticStyle: { "margin-top": "-8px" } },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_vm._v(_vm._s(field.text))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "alert alert-warning",
                                        attrs: { role: "alert" },
                                      },
                                      [
                                        _c("code", {
                                          domProps: {
                                            innerHTML: _vm._s(field.value),
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.error
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-danger invalid-feedback",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                 " +
                                          _vm._s(field.error_msg) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.error
                  ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.form.msg) },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-50",
                        attrs: { disabled: _vm.form.saving },
                        on: {
                          click: function ($event) {
                            return _vm.addIntegration()
                          },
                        },
                      },
                      [
                        _vm.form.saving
                          ? _c("span", [_vm._m(2), _vm._v(" Saving")])
                          : _c("span", [_vm._v("Save")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        attrs: { disabled: _vm.form.saving },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.add_file_option = null
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Back to Upload Options\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 d-flex flex-column gap-1" }, [
      _c("img", {
        staticClass: "modal-icon",
        attrs: { src: "/img/icons/dialexa-icons/file-text.svg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex flex-column gap-2 align-items-center" },
      [
        _c(
          "div",
          {
            staticClass: "spinner-border text-primary",
            attrs: { role: "status" },
          },
          [
            _c("span", { staticClass: "visually-hidden" }, [
              _vm._v("Loading..."),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("h5", { staticClass: "text-center" }, [
          _vm._v("Loading SFTP Information"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-white float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }