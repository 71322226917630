var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-notification" }, [
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Title")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_notification.title,
                    expression: "local_notification.title",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.title != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "title" },
                domProps: { value: _vm.local_notification.title },
                on: {
                  change: function ($event) {
                    return _vm.fieldValueChange("title")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_notification,
                      "title",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.title != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.title) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Description"),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_notification.description,
                    expression: "local_notification.description",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.description != "" ? "is-invalid" : "",
                attrs: { rows: "2" },
                domProps: { value: _vm.local_notification.description },
                on: {
                  change: function ($event) {
                    return _vm.fieldValueChange("description")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_notification,
                      "description",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.description != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.description) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Email HTML"),
                ]),
                _vm._v(" "),
                _c("vue-editor", {
                  attrs: { editorToolbar: _vm.customToolbar },
                  on: {
                    "text-change": function ($event) {
                      return _vm.fieldValueChange("email_html")
                    },
                  },
                  model: {
                    value: _vm.local_notification.email_html,
                    callback: function ($$v) {
                      _vm.$set(_vm.local_notification, "email_html", $$v)
                    },
                    expression: "local_notification.email_html",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Link (Optional)"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_notification.link,
                    expression: "local_notification.link",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "link" },
                domProps: { value: _vm.local_notification.link },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_notification,
                      "link",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("CTA (Optional)"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_notification.cta,
                      expression: "local_notification.cta",
                    },
                  ],
                  staticClass: "form-select",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_notification,
                        "cta",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "learn_more" } }, [
                    _vm._v("Learn more"),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "mb-3 multi-select-field" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("User Types (Optional)"),
                ]),
                _vm._v(" "),
                _c("accordion-multi-select", {
                  attrs: {
                    id: "user-types-multi-select",
                    value: _vm.local_notification.user_types,
                    options: _vm.user_types_list,
                    field_name: "user_types",
                  },
                  on: { input: _vm.changeMultiSelect },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "mb-3 multi-select-field" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Clients (Optional)"),
                ]),
                _vm._v(" "),
                _c("accordion-multi-select", {
                  attrs: {
                    id: "clients-multi-select",
                    value: _vm.local_notification.clients,
                    options: _vm.local_clients,
                    field_name: "clients",
                  },
                  on: { input: _vm.changeMultiSelect },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.form.successful
              ? _c("div", { staticClass: "alert alert-success mb-3" }, [
                  _vm._v(
                    "\n                        The new notification has been created and sent to users.\n                    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.form.error
              ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
                  _vm._v(
                    "\n                        There was an error creating the notification.\n                    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: _vm.form.busy },
                  on: {
                    click: function ($event) {
                      return _vm.createNotification()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-save" }),
                  _vm._v(" Create\n                        "),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }