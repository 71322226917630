var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "generate-ppt view-report p-2 mb-4" }, [
    _c("h1", { staticClass: "mb-2" }, [_vm._v("Previous Presentations")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Below is the list of previously generated presentations. When you copy one, it will take you back to the prior tab so that you can make any additional adjustments before exporting it."
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex flex-column gap-3",
        attrs: { id: "choose-report" },
      },
      [
        _vm.exports.length == 0
          ? _c("h5", { staticClass: "mt-3" }, [
              _vm._v("No presentations have been generated yet."),
            ])
          : _c("div", { staticClass: "mt-1" }, [
              _c("div", { staticClass: "search-box mx-0 position-relative" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search_key,
                      expression: "search_key",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Search by Presentation Name",
                    "aria-label": "Search by Presentation Name ...",
                  },
                  domProps: { value: _vm.search_key },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.search_key = $event.target.value
                      },
                      function ($event) {
                        return _vm.searchPage()
                      },
                    ],
                  },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "fa fa-search" }),
              ]),
            ]),
        _vm._v(" "),
        _vm.table_data.length > 0
          ? _c(
              "div",
              { staticClass: "table-responsive border rounded table-template" },
              [
                _c("table", { staticClass: "table m-0" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      { staticClass: "table-header-row" },
                      [
                        _vm._l(_vm.labels, function (label) {
                          return _c(
                            "th",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.sortByKey(label)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm._f("propercase")(label)) +
                                    "\n                            "
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("img", {
                                      staticClass: "sort-key",
                                      class:
                                        _vm.sort_key == label &&
                                        _vm.sort_order == "asc"
                                          ? "sort-key-active"
                                          : "",
                                      attrs: {
                                        src: "/img/icons/dialexa-icons/chevron-up.svg",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "sort-key",
                                      class:
                                        _vm.sort_key == label &&
                                        _vm.sort_order == "des"
                                          ? "sort-key-active"
                                          : "",
                                      attrs: {
                                        src: "/img/icons/dialexa-icons/chevron-down.svg",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("th", [_vm._v("Actions")]),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.table_data_shown, function (data) {
                      return _c(
                        "tr",
                        { staticClass: "table-template-row show-on-row-hover" },
                        [
                          _vm._l(_vm.labels, function (label) {
                            return _c(
                              "td",
                              { staticClass: "align-middle py-2" },
                              [
                                label == "actions"
                                  ? _c("span")
                                  : label == "sections"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(data[label].length) +
                                          "\n                        "
                                      ),
                                    ])
                                  : label == "created_at"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("datetime")(data[label])
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : label == "created_by"
                                  ? _c("span", [
                                      _c("img", {
                                        staticClass:
                                          "avatar rounded-circle avatar-sm bg-white me-2",
                                        attrs: {
                                          src: data.creator.image,
                                          alt: data.creator.name,
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(data.creator.name) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("propercase")(data[label])
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "align-start table-column-5 show-on-row-hover",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-none px-1",
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "/export/" +
                                      _vm.client.url +
                                      "/" +
                                      data.id,
                                    "data-bs-toggle": "tooltip",
                                    "data-bs-placement": "bottom",
                                    title: "Download Presentation",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/file-download.svg",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.copyExport(data)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-solid fa-copy",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      "data-bs-placement": "bottom",
                                      title: "Clone Presentation",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.deleteExport(data.id)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/trash.svg",
                                      "data-bs-toggle": "tooltip",
                                      "data-bs-placement": "bottom",
                                      title: "Archive Presentation",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("tfoot", { staticClass: "table-border-bottom-0" }, [
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "py-0", attrs: { colspan: "100%" } },
                        [
                          _c("div", { staticClass: "table-pagination" }, [
                            _c(
                              "div",
                              { staticClass: "table-pagination--group" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-lg-inline" },
                                  [_vm._v("Showing: ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.num_per_page,
                                        expression: "num_per_page",
                                      },
                                    ],
                                    staticClass: "form-select",
                                    attrs: {
                                      name: "num_per_page",
                                      id: "num_per_page",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.num_per_page = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {
                                          return _vm.reloadPagination()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "5000" } }, [
                                      _vm._v("ALL"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.num_per_page_options,
                                      function (num) {
                                        return _c(
                                          "option",
                                          { domProps: { value: num } },
                                          [_vm._v(_vm._s(num))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Results ")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "table-pagination--group text-center",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.item_interval) +
                                    " of " +
                                    _vm._s(_vm.table_data.length) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-pagination--group" },
                              [
                                _vm.page_count > 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-none px-1",
                                        attrs: {
                                          disabled: _vm.page_index == 0,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveToPrevPage()
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: "/img/icons/dialexa-icons/chevron-left.svg",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.page_index_array.length <= 3
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.page_index_array,
                                        function (page) {
                                          return _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-none px-1",
                                              class:
                                                page == _vm.page_index + 1
                                                  ? "current-page"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveToPage(page)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(page) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.page_index_array.slice(0, 2),
                                          function (page) {
                                            return _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1",
                                                class:
                                                  page == _vm.page_index + 1
                                                    ? "current-page"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveToPage(page)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(page) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.page_index > 2
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1",
                                                attrs: { disabled: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    ...\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.page_index >= 2 &&
                                        _vm.page_index <
                                          _vm.page_index_array.length - 1
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1 current-page",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(_vm.page_index + 1) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.page_index <
                                        _vm.page_index_array.length - 2
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1",
                                                attrs: { disabled: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    ...\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-none px-1",
                                            class:
                                              _vm.page_index ==
                                              _vm.page_index_array.length - 1
                                                ? "current-page"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.moveToPage(
                                                  _vm.page_index_array.length
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.page_index_array.length
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                _vm._v(" "),
                                _vm.page_count > 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-none px-1",
                                        attrs: {
                                          disabled:
                                            _vm.page_index ==
                                            _vm.page_count - 1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveToNextPage()
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: "/img/icons/dialexa-icons/chevron-right.svg",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }