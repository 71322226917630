<template>
    <div class="asset-approval-page">
        <div>
            <div class="row">
                <div class="col">
                    <h2>
                        Asset Approval for {{ (client.name.charAt(client.name.length - 1) =='s'? client.name+"' ": client.name+"'s ") }} <em>{{campaign.name}}</em>
                    </h2>
                </div>
                <div class="col-sm-2 v" v-if="is_user_logged_in && local_assets.length > 0">
                    <button class="btn btn-primary btn-sm float-end" v-on:click="switchToPDFView()" >
                        <span><i class="fas fa-fw fa-eye"></i>{{!is_pdf_view? "PDF View" : "Editable View"}}</span>
                    </button>
                </div>
            </div>

            <div v-if="!is_user_logged_in">
                <p>Before you can begin reviewing the assets, please enter your name and email below.</p>
                <div class="card" :class="(is_pdf_view? '':'box-content')">
                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label">First Name</label>
                                <input type="text" class="form-control"
                                    :class="((errors.first_name != '' )? 'is-invalid':'')"
                                    name="first_name" v-model="local_user.first_name">
                                <div class="text-danger invalid-feedback" v-if="errors.first_name != ''">
                                    {{errors.first_name}}
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Last Name</label>
                                <input type="text" class="form-control"
                                    :class="((errors.last_name != '' )? 'is-invalid':'')"
                                    name="last_name" v-model="local_user.last_name">
                                <div class="text-danger invalid-feedback" v-if="errors.last_name != ''">
                                    {{errors.last_name}}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label class="form-label">Email</label>
                                <input type="text" class="form-control" :class="((errors.email != '' )? 'is-invalid':'')"
                                    name="email" v-model="local_user.email">
                                <div class="text-danger invalid-feedback" v-if="errors.email != ''">
                                    {{errors.email}}
                                </div>                            
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-12">

                            <button type="submit" class="btn btn-primary" id="log-in-button"
                                    :disabled="form.busy" @click="guestLogIn()">
                                Submit
                            </button>                     
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card box-content mb-3" v-else-if="assets.length == 0">
                    <div class="card-body p-4" >
                        <h3>No assets are ready for review at this time</h3>
                        <p>There are no assets ready for your review at this time. Once future assets have been generated by the client service team, they will appear here.</span></p>
                </div>
            </div>
           
            <div v-else>
                <div v-if="local_assets.length > 0 ">
                    <p>Please go through each of the assets below to edit and approve them for launch. <strong>Other individuals in your organization will be able to add their own edits / approvals after you finish.</strong> Make any necessary edits to the copy and then press the "Approve" button to move on to the next asset. The comments section is optional but can also be used to communicate specific information to your client service team.</p>
                    
                    <div class="row mt-4">
                        <transition-group tag="div" name="fade-slow" >
                            <div v-for="(asset, index) in unreviewed_assets" :key="asset.id">
                                <div class='mb-4'>
                                    <h2 class="mb-0">{{getAssetTypeNameInSingular(asset.asset_type.name)}}: {{asset.name}} </h2>
                                    <div class="created_by_label" v-if="asset.id > 0">
                                        <span><label class='mb-0 me-1'>Created By: </label>{{ asset.created_by_user.first_name + ' ' + asset.created_by_user.last_name }}</span>
                                    </div>
                                    <small class="text-muted">Asset #{{ index+1}} of {{unreviewed_assets.length}}</small>
                                    <asset-details-approval :asset="asset" :client="client" :is_pdf_view="is_pdf_view" @approveAsset="approveAsset"></asset-details-approval>
                                </div>
                            </div>
                            <div v-for="(asset, index) in reviewed_assets" :key="asset.id">
                                <div class="card box-content mb-3">
                                    <div class="card-body p-2">
                                        <div class="accordion accordion-flush" >
                                            <div class="accordion-item border-bottom-0">
                                                <div class="row accordion-header ps-3">
                                                    <button class="col-12 btn btn-link text-start collapsed p-2" type="button"
                                                        :data-bs-target="'#asset-approval-' + asset.id" data-bs-toggle="collapse"
                                                        aria-expanded="false" :aria-controls="'#asset-approval-' + asset.id" @click="resizeTextarea()">
                                                        <div class="row">
                                                            <div class="col-6 vertical-center-text">
                                                                <div>
                                                                <h4 class="mb-0">{{getAssetTypeNameInSingular(asset.asset_type.name)}}: {{asset.name}} </h4>
                                                                <div class="mt-1 created_by_label" v-if="asset.id > 0">
                                                                    <label class='form-label mb-0 me-1'>Created By: </label><label>{{ asset.created_by_user.first_name + ' ' + asset.created_by_user.last_name }}</label>
                                                                </div>                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-6">
                                                                <label class="form-label border-bottom">Reviewed By</label>
                                                                <div>
                                                                    <span class="viewed-user" v-for="review in asset.previous_review_list"
                                                                    data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                    :title="(review.status=='approved') ?  'Approved at: '+  getDateAsString(review.updated_at):  'Rejected at: '+  getDateAsString(review.updated_at)">
                                                                        <i class="fa fa-check green" v-if="review.status=='approved'"></i>
                                                                        <i class="fa fa-close red" v-if="review.status=='rejected'"></i>
                                                                        {{ review.updated_by_user.name }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div :id="'asset-approval-' + asset.id" class="collapse accordion-collapse" >
                                                    <div class="mb-3" :class="((asset.if_current_user_reviewed_already)? '':'mx-3')">
                                                        <hr v-if="asset.if_current_user_reviewed_already" class="mx-3 section-divider">
                                                        <asset-details-approval :asset="asset" :client="client" :is_pdf_view="is_pdf_view" @approveAsset="approveAsset"></asset-details-approval>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </div>                        
                    <button v-if="unreviewed_assets.length==0" type="submit" class="btn btn-primary" id="final-approval-button"
                    @click="finishApproval()">
                        Finished
                    </button>
                </div>
                <div  class="card box-content mb-3" v-else>
                    <div class="card-body p-4" >
                        <h3>All assets have been reviewed</h3>
                        <p>You have finished reviewing all available assets for {{ (client.name.charAt(client.name.length - 1) =='s'? client.name+"' ": client.name+"'s ") }} <em>{{campaign.name}}</em>. <span v-if="!approval_process.is_final_approval">You now have two options: You can finalize the approval which will notify your client service team or you can send it to the next person in the approval chain.</span></p>
                        <div class="row two-options mt-4" v-if="!approval_process.is_final_approval">
                            <div class="col-md-6 mb-md-0 mb-4">
                                <h5>Option #1: Final Approval</h5>
                                <p>This will notify your client service team that all assets have been reviewed and will move the campaign into production after an initial review</p>
                                <button type="submit" class="btn btn-primary" id="final-approval-button"
                                :disabled="form.busy" @click="clientApproval(true)">
                                    Final Approval!
                                </button>

                            </div>
                            <div class="col-md-6 mb-0">
                                <h5>Option #2: Notify a Colleague</h5>
                                <p>If there are additional people who need to approve these assets, enter their email below and they'll receive an email with a direct link to this approval page. They will see all of your edits and comments and be able to add their own.</p>
                                <div class="row mb-3"><div class="col">
                                    <label class="form-label">Next Approver's Email</label>
                                    <input type="text" class="form-control" name="email" v-model="approval_process.next_approver_email"  :class="((errors.email != '' )? 'is-invalid':'')">

                                    <div class="text-danger invalid-feedback" v-if="errors.email != ''">
                                        {{errors.email}}
                                    </div>
                                </div></div>
                                <button class="btn btn-secondary" type="button" @click="clientApproval(false)" ><i class="fas fa-envelope"></i> Send Notification</button>

                            </div>
                        </div>
                        <div v-else>
                            <p><em>Thank you!</em></p>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import moment from 'moment';

    export default {
        components: {Swal},
        props: ['client', 'campaign', 'assets', 'user'],
        data() {
            return {
                local_assets: [],
                unreviewed_assets: [],
                reviewed_assets:[],
                local_user: {
                    id: -1,
                    first_name: '',
                    last_name:'',
                    email:''
                },
                is_user_logged_in: false,
                errors: {
                    first_name: '',
                    last_name:'',
                    email:''
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                approval_process:{
                    next_approver_email: '',
                    is_final_approval: false
                },
                is_pdf_view:false,
                // num_assets: 0
            };
        },
        beforeMount() {
            if(this.user.first_name) {
                this.local_user = this.user;
                this.is_user_logged_in = true;
            }
            this.local_assets = [...this.assets]
            this.getAssetsWithApprovalRecords(this.local_assets);
        },
        updated(){

        },
        methods: {
            getAssetTypeNameInSingular(assetTypeName){
                if (assetTypeName == 'Popups, "Dear Reader" Ads, and Sticky Bars'){
                    return 'Popup, "Dear Reader" Ad, and Sticky Bar';
                } else {
                    return assetTypeName.slice(0, -1);
                }
            },
            getDateAsString(value) {
                return moment.utc(String(value)).local().format('M/DD/YYYY h:mm a')
            },
            resizeTextarea(id) {
                if(id == undefined)
                    var element = document.querySelectorAll('textarea');
                else
                    var element = [document.getElementById(id)];

                for(var index = 0; index<element.length; index++){
                    if (element[index] && element[index].style){
                        element[index].style.height = '';
                        element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                    }                            
                }
            },
            switchToPDFView(){
                // let route = window.location.pathname;
                // window.open(route);
                this.is_pdf_view = !this.is_pdf_view;
            },
            hasErrors() {
                if(this.local_user.first_name == null || this.local_user.first_name == ""){
                    this.errors.first_name = "First name is required.";
                } else{
                    this.errors.first_name = "";
                }

                if(this.local_user.last_name == null || this.local_user.last_name == ""){
                    this.errors.last_name = "Last name is required.";
                } else{
                    this.errors.last_name = "";
                }
                    
                const reg = /^\w+([\.-]?\w+)*(\+?\w+([\.-]?\w+)*)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if(this.local_user.email == null || this.local_user.email == ""){
                    this.errors.email = "Email is required.";
                } else if(!reg.test(this.local_user.email)){
                    this.errors.email = 'Please enter a valid email address';
                } else{
                    this.errors.email = "";
                }
                    
                for (const error_name in this.errors) {
                    if (this.errors[error_name] != "") {
                        return true;
                    }
                }
                return false; 
            },
            clearErrors(){
                for (const error_name in this.errors) {
                    this.errors[error_name] = ""
                }
            },
            guestLogIn(){
                var self = this;
                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                self.form.busy = true;

                window.axios.post('/api/user/'+this.client.url+'/client_login', this.local_user)
                    .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            this.local_user = response.data.user;
                            this.is_user_logged_in = true;
                            this.getAssetsWithApprovalRecords(this.local_assets);
                            this.overwriteLoginButton(); 
                            // self.form.successful = true;
                            // setTimeout( function() {self.form.successful = false;} , 5000);
                        }
                    })
                    .catch(function (error) {
                        if(error.response.status == 403) {
                            Swal.fire({
                              title: "Login Unsuccessful",
                              text: "This login screen is for client users only. Full system users must use the primary login screen. Once logged in there, you'll be able to come back to this approval page.",
                              icon: "warning",
                              iconColor: "#46705F",
                              confirmButtonColor: "#46705F",
                              confirmButtonText: "Proceed to Login",
                            }).then((result) => {
                                window.location = "/login";
                            });
                        }
                    });
            },
            overwriteLoginButton(){
                if(this.local_user.first_name){
                    const guestLoggedIn = document.createElement("a");
                    guestLoggedIn.classList.add("nav-link");
                    guestLoggedIn.href ="/login";
                    guestLoggedIn.textContent = this.local_user.first_name + ' ' + this.local_user.last_name;

                    const loginbutton = document.querySelector("#guest-login-button");
                    loginbutton.replaceWith(guestLoggedIn);                    
                }
            },
            getAssetsWithApprovalRecords(assets_array){
                var unreviewed_assets_temp = [];
                var reviewed_assets_temp = [];  
                for (var i = 0; i< assets_array.length; i++){
                    if(assets_array[i].siblings){
                        // If same user reviewed multiple times, only keep the last one
                        var review_records = [];
                        var reviewer_names = [];
                        assets_array[i].siblings.forEach((review)=>{
                            if(review.updated_by_user == null && !reviewer_names.includes(this.local_user.first_name + " " + this.local_user.last_name)) {
                                reviewer_names.push(this.local_user.first_name + " " + this.local_user.last_name);
                                review.updated_by_user = {
                                    'name' : this.local_user.first_name + " " + this.local_user.last_name
                                };
                                review_records.push(review);
                            }
                            else if (review.updated_by_user != null && !reviewer_names.includes(review.updated_by_user.name)){
                                reviewer_names.push(review.updated_by_user.name);
                                review_records.push(review);
                            }
                        });
                        assets_array[i].previous_review_list = review_records;
                        if(this.local_user){
                            assets_array[i].if_current_user_reviewed_already = reviewer_names.includes(this.local_user.name)
                        }
                    }
                    if (assets_array[i].if_current_user_reviewed_already == true){
                        reviewed_assets_temp.push(assets_array[i]); 
                    } else{
                        unreviewed_assets_temp.push(assets_array[i]); 
                    }                
                }
                this.unreviewed_assets = unreviewed_assets_temp;
                this.reviewed_assets = reviewed_assets_temp;
                
            },
            approveAsset(assetApproved){
                //if the user approve/reject on the last asset on the unreviewed asset list, they will be taken to the two option screen
                if (this.unreviewed_assets.length == 1 && this.unreviewed_assets[0].id == assetApproved.parent_id){
                   this.finishApproval();
                } else {
                    var asset_index = this.local_assets.findIndex(asset => asset.id == assetApproved.parent_id)
                    this.local_assets[asset_index].siblings.unshift(assetApproved);
                    this.getAssetsWithApprovalRecords(this.local_assets);

                    // For when approve/reject a previous reviewed asset
                    var element = document.querySelector("#asset-approval-"+assetApproved.parent_id);
                    if (element){
                        element.classList.remove("show");
                    }
                }
            },
            finishApproval(){
                this.local_assets = []
            },
            clientApproval(is_approved){

                if(!is_approved && this.approval_process.next_approver_email == '') {
                    this.errors.email = 'Please enter the email address of individual who should approve the assets next.'
                    return;
                }

                this.approval_process.is_final_approval = true;
                let data = {
                    is_approved: is_approved,
                    email: this.approval_process.next_approver_email
                }

                window.axios.post('/api/campaigns/'+this.client.url+'/notify/' + this.campaign.url, data);
            }
        }
    }
</script>
