<template>
    <div class="org_settings" >
        <h1 class="mb-2">User Settings</h1>
        <div class="row px-2 mb-5">
            <nav id="org-setting-tab-nav" class="col-12 navbar navbar-expand primary-tab-bar pb-0">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mb-lg-0">
                        <li class="nav-item" v-for="tab_name in setting_tabs">
                            <a class="nav-link d-flex flex-nowrap pb-0 mb-0 mx-2 primary-tab" :class="{'primary-tab-active': tab_name == setting_tab_name_active}"
                            href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(tab_name)">
                                <span>{{ tab_name }}</span>
                            </a>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>

            <div class="card box-content py-3">
                <div class="card-body">

                    <div v-if="setting_tab_name_active == 'PROFILE'">
                        <edit-user :user="user"></edit-user>
                    </div>
                    <div v-if="setting_tab_name_active == 'NOTIFICATIONS'">
                        <notification-settings :client="client"></notification-settings>
                    </div>
                    <div v-if="setting_tab_name_active == 'PRIORITIZATION'">
                        <prioritization-settings :client="client"></prioritization-settings>
                    </div>
                    <div v-if="setting_tab_name_active == 'SCORECARD'">
                        <scorecard-filter :client="client"></scorecard-filter>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user', 'client'],
        data() {
            return {
                setting_tabs:['PROFILE', 'NOTIFICATIONS'],
                setting_tab_name_active: 'PROFILE',
                user_prioritization: []
            };
        },
        beforeMount() {

            let tab_name = window.location.pathname.split('/').pop();

            if (tab_name =='profile')
                this.setting_tab_name_active = 'PROFILE';
            else if (tab_name =='notification')
                this.setting_tab_name_active = 'NOTIFICATIONS';
            else if (tab_name =='prioritization')
                this.setting_tab_name_active = 'PRIORITIZATION';
            else if (tab_name =='scorecard')
                this.setting_tab_name_active = 'SCORECARD';

            if ((this.user.user_type == 'admin' || this.user.agency_id != null) && (this.client.plan_level == 'insights' || this.client.plan_level == 'trial')){
                this.setting_tabs = ['PROFILE', 'NOTIFICATIONS', 'PRIORITIZATION', 'SCORECARD'];
            }
            else if(this.client.plan_level == 'insights' || this.client.plan_level == 'trial')
                this.setting_tabs = ['PROFILE', 'NOTIFICATIONS', 'PRIORITIZATION'];

        },
        methods: {
            setActiveTab(tab_name){
                this.setting_tab_name_active = tab_name;
                var newURL = '/user/'+this.client.url+'/';

                if (tab_name =='PROFILE')
                    newURL += 'profile';
                else if (tab_name =='PRIORITIZATION')
                    newURL += 'prioritization';
                else if (tab_name =='NOTIFICATIONS')
                    newURL += 'notification';
                else if (tab_name =='SCORECARD')
                    newURL += 'scorecard';

                window.history.replaceState({}, {}, newURL);
                // this.$forceUpdate();
            },
        }
    }
</script>
