var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asset-approval-page" }, [
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("h2", [
            _vm._v(
              "\n                    Asset Approval for " +
                _vm._s(
                  _vm.client.name.charAt(_vm.client.name.length - 1) == "s"
                    ? _vm.client.name + "' "
                    : _vm.client.name + "'s "
                ) +
                " "
            ),
            _c("em", [_vm._v(_vm._s(_vm.campaign.name))]),
          ]),
        ]),
        _vm._v(" "),
        _vm.is_user_logged_in && _vm.local_assets.length > 0
          ? _c("div", { staticClass: "col-sm-2 v" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-sm float-end",
                  on: {
                    click: function ($event) {
                      return _vm.switchToPDFView()
                    },
                  },
                },
                [
                  _c("span", [
                    _c("i", { staticClass: "fas fa-fw fa-eye" }),
                    _vm._v(
                      _vm._s(!_vm.is_pdf_view ? "PDF View" : "Editable View")
                    ),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.is_user_logged_in
        ? _c("div", [
            _c("p", [
              _vm._v(
                "Before you can begin reviewing the assets, please enter your name and email below."
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "card",
                class: _vm.is_pdf_view ? "" : "box-content",
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("First Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_user.first_name,
                            expression: "local_user.first_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.errors.first_name != "" ? "is-invalid" : "",
                        attrs: { type: "text", name: "first_name" },
                        domProps: { value: _vm.local_user.first_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local_user,
                              "first_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.first_name != ""
                        ? _c(
                            "div",
                            { staticClass: "text-danger invalid-feedback" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.errors.first_name) +
                                  "\n                            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Last Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_user.last_name,
                            expression: "local_user.last_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.errors.last_name != "" ? "is-invalid" : "",
                        attrs: { type: "text", name: "last_name" },
                        domProps: { value: _vm.local_user.last_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local_user,
                              "last_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.last_name != ""
                        ? _c(
                            "div",
                            { staticClass: "text-danger invalid-feedback" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.errors.last_name) +
                                  "\n                            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Email"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_user.email,
                            expression: "local_user.email",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.errors.email != "" ? "is-invalid" : "",
                        attrs: { type: "text", name: "email" },
                        domProps: { value: _vm.local_user.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local_user,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.email != ""
                        ? _c(
                            "div",
                            { staticClass: "text-danger invalid-feedback" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.errors.email) +
                                  "\n                            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            type: "submit",
                            id: "log-in-button",
                            disabled: _vm.form.busy,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.guestLogIn()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Submit\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm.assets.length == 0
        ? _c("div", { staticClass: "card box-content mb-3" }, [_vm._m(0)])
        : _c("div", [
            _vm.local_assets.length > 0
              ? _c("div", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row mt-4" },
                    [
                      _c(
                        "transition-group",
                        { attrs: { tag: "div", name: "fade-slow" } },
                        [
                          _vm._l(
                            _vm.unreviewed_assets,
                            function (asset, index) {
                              return _c("div", { key: asset.id }, [
                                _c(
                                  "div",
                                  { staticClass: "mb-4" },
                                  [
                                    _c("h2", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAssetTypeNameInSingular(
                                            asset.asset_type.name
                                          )
                                        ) +
                                          ": " +
                                          _vm._s(asset.name) +
                                          " "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    asset.id > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "created_by_label" },
                                          [
                                            _c("span", [
                                              _c(
                                                "label",
                                                { staticClass: "mb-0 me-1" },
                                                [_vm._v("Created By: ")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  asset.created_by_user
                                                    .first_name +
                                                    " " +
                                                    asset.created_by_user
                                                      .last_name
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "Asset #" +
                                          _vm._s(index + 1) +
                                          " of " +
                                          _vm._s(_vm.unreviewed_assets.length)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("asset-details-approval", {
                                      attrs: {
                                        asset: asset,
                                        client: _vm.client,
                                        is_pdf_view: _vm.is_pdf_view,
                                      },
                                      on: { approveAsset: _vm.approveAsset },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.reviewed_assets, function (asset, index) {
                            return _c("div", { key: asset.id }, [
                              _c(
                                "div",
                                { staticClass: "card box-content mb-3" },
                                [
                                  _c("div", { staticClass: "card-body p-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "accordion accordion-flush",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "accordion-item border-bottom-0",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row accordion-header ps-3",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "col-12 btn btn-link text-start collapsed p-2",
                                                    attrs: {
                                                      type: "button",
                                                      "data-bs-target":
                                                        "#asset-approval-" +
                                                        asset.id,
                                                      "data-bs-toggle":
                                                        "collapse",
                                                      "aria-expanded": "false",
                                                      "aria-controls":
                                                        "#asset-approval-" +
                                                        asset.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.resizeTextarea()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 vertical-center-text",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getAssetTypeNameInSingular(
                                                                        asset
                                                                          .asset_type
                                                                          .name
                                                                      )
                                                                    ) +
                                                                      ": " +
                                                                      _vm._s(
                                                                        asset.name
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              asset.id > 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mt-1 created_by_label",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "form-label mb-0 me-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Created By: "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              asset
                                                                                .created_by_user
                                                                                .first_name +
                                                                                " " +
                                                                                asset
                                                                                  .created_by_user
                                                                                  .last_name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-label border-bottom",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Reviewed By"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              _vm._l(
                                                                asset.previous_review_list,
                                                                function (
                                                                  review
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "viewed-user",
                                                                      attrs: {
                                                                        "data-bs-toggle":
                                                                          "tooltip",
                                                                        "data-bs-placement":
                                                                          "bottom",
                                                                        title:
                                                                          review.status ==
                                                                          "approved"
                                                                            ? "Approved at: " +
                                                                              _vm.getDateAsString(
                                                                                review.updated_at
                                                                              )
                                                                            : "Rejected at: " +
                                                                              _vm.getDateAsString(
                                                                                review.updated_at
                                                                              ),
                                                                      },
                                                                    },
                                                                    [
                                                                      review.status ==
                                                                      "approved"
                                                                        ? _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-check green",
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      review.status ==
                                                                      "rejected"
                                                                        ? _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-close red",
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        "\n                                                                    " +
                                                                          _vm._s(
                                                                            review
                                                                              .updated_by_user
                                                                              .name
                                                                          ) +
                                                                          "\n                                                                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "collapse accordion-collapse",
                                                attrs: {
                                                  id:
                                                    "asset-approval-" +
                                                    asset.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "mb-3",
                                                    class:
                                                      asset.if_current_user_reviewed_already
                                                        ? ""
                                                        : "mx-3",
                                                  },
                                                  [
                                                    asset.if_current_user_reviewed_already
                                                      ? _c("hr", {
                                                          staticClass:
                                                            "mx-3 section-divider",
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "asset-details-approval",
                                                      {
                                                        attrs: {
                                                          asset: asset,
                                                          client: _vm.client,
                                                          is_pdf_view:
                                                            _vm.is_pdf_view,
                                                        },
                                                        on: {
                                                          approveAsset:
                                                            _vm.approveAsset,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.unreviewed_assets.length == 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            type: "submit",
                            id: "final-approval-button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.finishApproval()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Finished\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "card box-content mb-3" }, [
                  _c("div", { staticClass: "card-body p-4" }, [
                    _c("h3", [_vm._v("All assets have been reviewed")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "You have finished reviewing all available assets for " +
                          _vm._s(
                            _vm.client.name.charAt(
                              _vm.client.name.length - 1
                            ) == "s"
                              ? _vm.client.name + "' "
                              : _vm.client.name + "'s "
                          ) +
                          " "
                      ),
                      _c("em", [_vm._v(_vm._s(_vm.campaign.name))]),
                      _vm._v(". "),
                      !_vm.approval_process.is_final_approval
                        ? _c("span", [
                            _vm._v(
                              "You now have two options: You can finalize the approval which will notify your client service team or you can send it to the next person in the approval chain."
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    !_vm.approval_process.is_final_approval
                      ? _c("div", { staticClass: "row two-options mt-4" }, [
                          _c("div", { staticClass: "col-md-6 mb-md-0 mb-4" }, [
                            _c("h5", [_vm._v("Option #1: Final Approval")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "This will notify your client service team that all assets have been reviewed and will move the campaign into production after an initial review"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  type: "submit",
                                  id: "final-approval-button",
                                  disabled: _vm.form.busy,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clientApproval(true)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Final Approval!\n                            "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6 mb-0" }, [
                            _c("h5", [_vm._v("Option #2: Notify a Colleague")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "If there are additional people who need to approve these assets, enter their email below and they'll receive an email with a direct link to this approval page. They will see all of your edits and comments and be able to add their own."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Next Approver's Email"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.approval_process
                                          .next_approver_email,
                                      expression:
                                        "approval_process.next_approver_email",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class:
                                    _vm.errors.email != "" ? "is-invalid" : "",
                                  attrs: { type: "text", name: "email" },
                                  domProps: {
                                    value:
                                      _vm.approval_process.next_approver_email,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.approval_process,
                                        "next_approver_email",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.errors.email != ""
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.errors.email) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clientApproval(false)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-envelope" }),
                                _vm._v(" Send Notification"),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", [_vm._m(2)]),
                  ]),
                ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body p-4" }, [
      _c("h3", [_vm._v("No assets are ready for review at this time")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "There are no assets ready for your review at this time. Once future assets have been generated by the client service team, they will appear here."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Please go through each of the assets below to edit and approve them for launch. "
      ),
      _c("strong", [
        _vm._v(
          "Other individuals in your organization will be able to add their own edits / approvals after you finish."
        ),
      ]),
      _vm._v(
        ' Make any necessary edits to the copy and then press the "Approve" button to move on to the next asset. The comments section is optional but can also be used to communicate specific information to your client service team.'
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("em", [_vm._v("Thank you!")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }