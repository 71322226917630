var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "asset-page" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "h2",
          {
            staticClass:
              "pb-1 d-flex border-bottom border-secondary border-3 mt-3",
          },
          [
            _c("span", { staticClass: "col-7" }, [
              _vm._v(
                "\n                 " +
                  _vm._s(
                    _vm.campaign.campaign_type_id == 0
                      ? _vm.campaign.name
                      : _vm.local_assets_type.name
                  ) +
                  "   \n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-5" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-sm ms-3 float-end mb-2",
                  attrs: { id: "add-asset" },
                  on: {
                    click: function ($event) {
                      return _vm.addAsset(null)
                    },
                  },
                },
                [_vm._m(0)]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-sm",
                  staticStyle: { display: "none" },
                  attrs: { id: "sticky-button" },
                  on: {
                    click: function ($event) {
                      return _vm.addAsset(null)
                    },
                  },
                },
                [_vm._m(1)]
              ),
              _vm._v(" "),
              _vm.canDeploy()
                ? _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-secondary btn-sm float-end ms-3 mb-2",
                      attrs: {
                        href:
                          "/campaigns/" +
                          _vm.client.url +
                          "/deploy/" +
                          _vm.campaign.url +
                          "/" +
                          this.local_assets_type.id,
                      },
                    },
                    [_vm._m(2)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.if_has_unfinished_asset
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary btn-sm float-end mb-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.generateAllAssets()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-edit" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Generate Unfinished Assets")]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { tag: "div", name: "list" } },
        _vm._l(_vm.local_assets_array, function (asset, index) {
          return _c(
            "div",
            {
              key: asset.id,
              ref: "assetSection",
              refInFor: true,
              staticClass: "mb-3 vertical-center-text asset-row",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card box-content mb-3 mx-0 row-with-reorder-btns",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "accordion accordion-flush" }, [
                      _c(
                        "div",
                        { staticClass: "accordion-item border-bottom-0" },
                        [
                          _c(
                            "div",
                            { staticClass: "accordion-header ms-2 me-5" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-start collapsed p-2 accordion-button",
                                  attrs: {
                                    type: "button",
                                    "data-bs-target":
                                      "#asset-editing-" + asset.id,
                                    "data-bs-toggle": "collapse",
                                    "aria-expanded": "false",
                                    "aria-controls":
                                      "#asset-editing-" + asset.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resizeTextareaExpanding(index)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "row col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        class: asset.if_expanded
                                          ? "col-11"
                                          : "col-8",
                                      },
                                      [
                                        _c("h4", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAssetTypeNameInSingular(
                                                asset.asset_type.name
                                              )
                                            ) +
                                              " - " +
                                              _vm._s(asset.name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        asset.id > 0 && asset.created_by_user
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "created_by_label",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "mb-0 me-1" },
                                                  [
                                                    _vm._v(
                                                      "Created By: " +
                                                        _vm._s(
                                                          asset.created_by_user
                                                            .first_name +
                                                            " " +
                                                            asset
                                                              .created_by_user
                                                              .last_name
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    asset.if_expanded == false
                                      ? _c("div", { staticClass: "col-3" }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "form-label border-bottom",
                                            },
                                            [_vm._v("Status")]
                                          ),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getStatusText(asset.status)
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    asset.if_expanded == false &&
                                    asset.description != null
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-2 col-11" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-label border-bottom",
                                              },
                                              [_vm._v("Asset Description")]
                                            ),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(_vm._s(asset.description)),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "collapse accordion-collapse",
                              class: { show: asset.if_expanded },
                              attrs: { id: "asset-editing-" + asset.id },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("hr", {
                                    staticClass: "mx-3 section-divider",
                                  }),
                                  _vm._v(" "),
                                  _c("asset-details", {
                                    ref: "asset",
                                    refInFor: true,
                                    attrs: {
                                      asset: asset,
                                      client: _vm.client,
                                      concepts: _vm.concepts,
                                      pages: _vm.pages,
                                    },
                                    on: {
                                      generateVariantPrompt:
                                        _vm.generateVariantPrompt,
                                      copyAsset: _vm.copyAsset,
                                      assetGenerating: _vm.assetGenerating,
                                      assetSaved: _vm.assetSaved,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      asset.status != "approved" && !asset.generating
                        ? _c(
                            "button",
                            {
                              staticClass: "asset-accordion-delete btn",
                              attrs: { type: "button", "aria-label": "Close" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteAsset(index)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-times",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      asset.status == "approved"
                        ? _c("div", { staticClass: "ribbon" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("propercase")(asset.status))
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    asset.generating
                      ? _c("div", { staticClass: "asset-overlay" }, [
                          _c("div", { staticClass: "overlay" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "cta" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row d-flex align-items-center mx-3",
                              },
                              [
                                _c("div", { staticClass: "col-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border text-white float-end",
                                      attrs: { role: "status" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "visually-hidden" },
                                        [_vm._v("Loading...")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col" }, [
                                  _c("p", { staticClass: "text-start m-0" }, [
                                    _vm._v(
                                      "Your content is being generated—when it is ready, it will appear for you to edit or approve. You can leave the page and return at any time."
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "px-3 hide reorder-btn-containter" }, [
                _c("div", { staticClass: "reorder-btn-containter" }, [
                  index != 0
                    ? _c(
                        "button",
                        {
                          staticClass: "reorder-btn mb-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.moveAssetUp(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-arrow-up" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  index != _vm.local_assets_array.length - 1
                    ? _c(
                        "button",
                        {
                          staticClass: "reorder-btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.moveAssetDown(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-arrow-down" })]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          {
            staticClass: "col row-with-reorder-btns",
            attrs: { id: "public-links" },
          },
          [
            _vm._m(3),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value:
                    _vm.current_domain +
                    "campaigns/" +
                    _vm.client.url +
                    "/approval/" +
                    _vm.campaign.url +
                    "/" +
                    _vm.local_assets_type.id,
                  expression:
                    " current_domain + 'campaigns/' + client.url + '/approval/' + campaign.url + '/' + local_assets_type.id",
                },
              ],
              ref: "clone",
              staticClass: "form-control bg-white ps-2 text-muted",
              attrs: { type: "text", readonly: "" },
              domProps: {
                value:
                  _vm.current_domain +
                  "campaigns/" +
                  _vm.client.url +
                  "/approval/" +
                  _vm.campaign.url +
                  "/" +
                  _vm.local_assets_type.id,
              },
              on: {
                click: function ($event) {
                  return _vm.copyToClipboard()
                },
                focus: function ($event) {
                  return $event.target.select()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.current_domain +
                      "campaigns/" +
                      _vm.client.url +
                      "/approval/" +
                      _vm.campaign.url +
                      "/" +
                      _vm.local_assets_type,
                    "id",
                    $event.target.value
                  )
                },
              },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Asset"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Asset"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-cube" }),
      _vm._v("Deploy Assets"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Asset Approval Page Link "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "The link below will allow clients to review any assets on this page that have the status of 'Ready for Review'.",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }