var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org_settings_connections_files" } }, [
    _c("div", { staticClass: "d-flex" }, [
      _vm.files.length > 0
        ? _c("div", { staticClass: "card card-has-table flex-grow-1" }, [
            _c("div", { staticClass: "card-header" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-row justify-content-between" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center flex-grow-1",
                    },
                    [
                      _c("h5", [
                        _vm._v(
                          "Data Files (" +
                            _vm._s(
                              _vm._f("number_with_zero")(_vm.local_num_files)
                            ) +
                            ")"
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center",
                    },
                    [
                      _c("div", { staticClass: "position-relative" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.search_key,
                              expression: "search_key",
                            },
                          ],
                          staticClass: "form-control me-1",
                          attrs: {
                            type: "text",
                            placeholder: "Search by File Name...",
                            "aria-label": "Search by File Name...",
                          },
                          domProps: { value: _vm.search_key },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.search_key = $event.target.value
                              },
                              _vm.debouncedSearchOptions,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("i", { staticClass: "fa fa-search" }),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body p-0" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-responsive table-with-pagination m-0",
                },
                [
                  _c("thead", [
                    _c("tr", { staticClass: "table-header-row" }, [
                      _c(
                        "th",
                        {
                          staticClass: "table-column-1",
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("name")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _vm._v(
                              "\n                                    File Name\n                                    "
                            ),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "name" &&
                                  _vm.sort_order == "asc"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-up.svg",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "name" &&
                                  _vm.sort_order == "des"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-down.svg",
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "table-column-2",
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("status")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _vm._v(
                              "\n                                    Status\n                                    "
                            ),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "status" &&
                                  _vm.sort_order == "asc"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-up.svg",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "status" &&
                                  _vm.sort_order == "des"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-down.svg",
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "table-column-3",
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("dataset")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _vm._v(
                              "\n                                    Data Set\n                                    "
                            ),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "dataset" &&
                                  _vm.sort_order == "asc"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-up.svg",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "dataset" &&
                                  _vm.sort_order == "des"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-down.svg",
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "table-column-4",
                          on: {
                            click: function ($event) {
                              return _vm.sortByKey("created_at")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _vm._v(
                              "\n                                    Upload Date\n                                    "
                            ),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "created_at" &&
                                  _vm.sort_order == "asc"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-up.svg",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "sort-key",
                                class:
                                  _vm.sort_key == "created_at" &&
                                  _vm.sort_order == "des"
                                    ? "sort-key-active"
                                    : "",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-down.svg",
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(0),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm.filtered_file_list.length == 0 && _vm.search_key != ""
                        ? _c(
                            "tr",
                            { staticClass: "table-row-1 no-results-text" },
                            [
                              _c("td", { staticClass: "align-start" }, [
                                _vm._v(
                                  "\n                                There is no file match the given search.\n                            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._l(_vm.file_list_shown, function (file, index) {
                            return _c(
                              "tr",
                              { class: `table-row-${index + 1}` },
                              [
                                _c("td", { staticClass: "align-start" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(file.name) +
                                      "\n                                "
                                  ),
                                  _c("small", { staticClass: "ms-2" }, [
                                    _c("em", [
                                      _vm._v("(" + _vm._s(file.source) + ")"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-start" }, [
                                  file.id >= 0
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            title: file.status_msg,
                                            id: "tooltip" + file.id,
                                            onmouseover:
                                              "$('#tooltip" +
                                              file.id +
                                              "').tooltip('show');",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "badge",
                                              class:
                                                _vm.getColorClass(
                                                  file.status
                                                ) === "green"
                                                  ? "badge-success"
                                                  : _vm.getColorClass(
                                                      file.status
                                                    ) === "yellow"
                                                  ? "badge-warning"
                                                  : "badge-danger",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("propercase")(
                                                    file.status
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._m(1, true),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ms-2" }, [
                                          _vm._v("Processing..."),
                                        ]),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-start" }, [
                                  file.dataset
                                    ? _c("span", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "#",
                                              "data-bs-toggle": "tooltip",
                                              title: "Edit Data Set Settings",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.viewFile(file)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("propercase")(
                                                  file.dataset.name
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : file.id >= 0 &&
                                      file.status == "ready" &&
                                      _vm.isDataFile(file)
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-secondary",
                                          attrs: {
                                            href:
                                              "/integrations/" +
                                              _vm.client.url +
                                              "/mapping/file/" +
                                              file.id,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "icon pe-1",
                                            attrs: {
                                              src: "/img/icons/dialexa-icons/plus.svg",
                                            },
                                          }),
                                          _vm._v(
                                            "Add\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-start" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm._f("date")(file.created_at)) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-end show-on-row-hover" },
                                  [
                                    _vm.isDataFile(file) &&
                                    _vm.isIncremental(file)
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-none",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rebaseDataset(file)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: "/img/icons/dialexa-icons/location-cross.svg",
                                                "data-bs-toggle": "tooltip",
                                                title:
                                                  "Rebase Data Set to File",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isDataFile(file)
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-none",
                                            attrs: {
                                              "data-bs-toggle": "modal",
                                              "data-bs-target":
                                                "#preview-modal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.previewTable(file)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: "/img/icons/dialexa-icons/eye.svg",
                                                "data-bs-toggle": "tooltip",
                                                title: "Preview File",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.withinThirtyDays(file)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-none",
                                            attrs: {
                                              href:
                                                "/integrations/" +
                                                _vm.client.url +
                                                "/files/download/" +
                                                file.id,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: "/img/icons/dialexa-icons/download.svg",
                                                "data-bs-toggle": "tooltip",
                                                title: "Download File",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-none me-1",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFile(file)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: "/img/icons/dialexa-icons/trash.svg",
                                            "data-bs-toggle": "tooltip",
                                            title: "Delete File",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("tfoot", { staticClass: "table-border-bottom-0" }, [
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "py-0", attrs: { colspan: "100%" } },
                        [
                          _c("div", { staticClass: "table-pagination" }, [
                            _c(
                              "div",
                              { staticClass: "table-pagination--group" },
                              [
                                _c("span", [_vm._v("Showing: ")]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.num_per_page,
                                        expression: "num_per_page",
                                      },
                                    ],
                                    staticClass: "form-select",
                                    attrs: {
                                      name: "num_per_page",
                                      id: "num_per_page",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.num_per_page = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {
                                          return _vm.reloadPagination()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "5000" } }, [
                                      _vm._v("ALL"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.num_per_page_options,
                                      function (num) {
                                        return _c(
                                          "option",
                                          { domProps: { value: num } },
                                          [_vm._v(_vm._s(num))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Files")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-pagination--group" },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.item_interval) +
                                    " of " +
                                    _vm._s(
                                      _vm._f("number_with_zero")(
                                        _vm.local_num_files
                                      )
                                    ) +
                                    "\n                                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-pagination--group" },
                              [
                                _vm.local_num_files / _vm.num_per_page > 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-none px-1",
                                        attrs: {
                                          disabled: _vm.page_index == 0,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveToPrevPage()
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: "/img/icons/dialexa-icons/chevron-left.svg",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.local_num_files / _vm.num_per_page <= 3
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.page_index_array,
                                        function (page) {
                                          return _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-none px-1",
                                              class:
                                                page == _vm.page_index + 1
                                                  ? "current-page"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveToPage(page)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(page) +
                                                  "\n                                            "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.page_index_array.slice(0, 2),
                                          function (page) {
                                            return _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1",
                                                class:
                                                  page == _vm.page_index + 1
                                                    ? "current-page"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveToPage(page)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(page) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.page_index > 2
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1",
                                                attrs: { disabled: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            ...\n                                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.page_index >= 2 &&
                                        _vm.page_index <
                                          Math.ceil(
                                            _vm.local_num_files /
                                              _vm.num_per_page
                                          ) -
                                            1
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1 current-page",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(_vm.page_index + 1) +
                                                    " \n                                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.page_index <
                                        _vm.local_num_files / _vm.num_per_page -
                                          2
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-none px-1",
                                                attrs: { disabled: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            ...\n                                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-none px-1",
                                            class:
                                              _vm.page_index ==
                                              Math.ceil(
                                                _vm.local_num_files /
                                                  _vm.num_per_page
                                              ) -
                                                1
                                                ? "current-page"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                _vm.moveToPage(
                                                  Math.ceil(
                                                    _vm.local_num_files /
                                                      _vm.num_per_page
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  Math.ceil(
                                                    _vm.local_num_files /
                                                      _vm.num_per_page
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                _vm._v(" "),
                                _vm.page_count >= 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-none px-1",
                                        attrs: {
                                          disabled:
                                            _vm.page_index ==
                                            Math.ceil(
                                              _vm.local_num_files /
                                                _vm.num_per_page
                                            ) -
                                              1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveToNextPage()
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: "/img/icons/dialexa-icons/chevron-right.svg",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _c("h5", [
            _vm._v("No files have been uploaded yet. \n            "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openAddFileModal()
                  },
                },
              },
              [_vm._v("Click here to add a file.")]
            ),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal fade px-0", attrs: { id: "preview-modal" } },
      [
        _c("div", { staticClass: "modal-dialog", class: _vm.modal_size }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "preview-modal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closePreviewModal()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close me-0" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleModalSize()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa",
                      class:
                        _vm.modal_size == "modal-xl"
                          ? "fa-expand"
                          : "fa-compress",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2 mb-3" }, [
                  _vm._v("Preview File Contents"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body p-0" }, [
              typeof _vm.table_data === "string" && _vm.table_data == ""
                ? _c(
                    "div",
                    {
                      staticClass:
                        "m-2 mb-4 d-flex flex-column gap-2 align-items-center",
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("h5", { staticClass: "text-center" }, [
                        _vm._v("Loading File Contents"),
                      ]),
                    ]
                  )
                : Array.isArray(_vm.table_data) && _vm.table_data.length > 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "table-responsive gy-0 m-0 mt-0" },
                      [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-responsive border rounded table-with-pagination gy-0 mb-3",
                          },
                          [
                            _c("thead", [
                              _c(
                                "tr",
                                { staticClass: "table-header-row" },
                                _vm._l(
                                  Object.keys(_vm.table_data[0]),
                                  function (row, index) {
                                    return _c(
                                      "th",
                                      {
                                        staticClass: "align-middle",
                                        class: index == 0 ? "ps-3" : "",
                                      },
                                      [_vm._v(_vm._s(_vm.originalHeader(row)))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.table_data, function (row) {
                                return _c(
                                  "tr",
                                  _vm._l(row, function (col, index) {
                                    return _c(
                                      "td",
                                      { class: index == 0 ? "ps-3" : "" },
                                      [_vm._v(_vm._s(col))]
                                    )
                                  }),
                                  0
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : Array.isArray(_vm.table_data) && _vm.table_data.length == 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "alert alert-danger box-content m-2" },
                      [
                        _vm._v(
                          "\n                            No data was returned.\n                        "
                        ),
                      ]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "alert alert-danger box-content m-2" },
                      [
                        _vm._v(
                          "\n                            There was an error pulling the data:"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("em", [_vm._v(_vm._s(_vm.table_data))]),
                      ]
                    ),
                  ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "table-column-5" }, [
      _c("div", { staticClass: "d-flex flex-row" }, [_vm._v("Action")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-warning float-left",
        attrs: { role: "status" },
      },
      [
        _c("span", { staticClass: "visually-hidden" }, [
          _vm._v("Processing..."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border text-primary", attrs: { role: "status" } },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }