var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "results" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8 results-container" }, [
          _c("div", { staticClass: "card box-content" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.init.title))]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2 floating_label" },
                  [
                    _c("datepicker", {
                      attrs: {
                        "minimum-view": "month",
                        "use-utc": true,
                        format: "MMM yyyy",
                        "input-class": "form-control",
                      },
                      model: {
                        value: _vm.init.start_month,
                        callback: function ($$v) {
                          _vm.$set(_vm.init, "start_month", $$v)
                        },
                        expression: "init.start_month",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v("From")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2 floating_label" },
                  [
                    _c("datepicker", {
                      attrs: {
                        "minimum-view": "month",
                        "use-utc": true,
                        format: "MMM yyyy",
                        "input-class": "form-control",
                      },
                      model: {
                        value: _vm.init.start_month,
                        callback: function ($$v) {
                          _vm.$set(_vm.init, "start_month", $$v)
                        },
                        expression: "init.start_month",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v("To")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-secondary",
                      staticStyle: { "text-decoration": "none" },
                      attrs: {
                        href: "/results/" + _vm.client.url + "/projections",
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-check" }),
                      _vm._v(" Finished"),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "col text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-sm btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.modal_version = "Result"
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.init.results_filter == null ||
              _vm.init.results_filter.length == 0
                ? _c("div", { staticClass: "card box-content" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _vm._v(
                        "\n                            None Added Yet\n                        "
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "accordion" },
                    _vm._l(_vm.init.results_filter, function (result) {
                      return _c("div", { staticClass: "card box-content" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-9" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-link text-left collapsed",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#result-" + result.id,
                                  "aria-controls": "collapseOne",
                                },
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("propercase")(result.data_source)
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  ": " +
                                    _vm._s(result.description) +
                                    "\n                                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col text-right" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-xs btn-none",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editResult(result)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-edit" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn-xs btn-none",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteResult(result)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-remove" })]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        result.data_source == "manual"
                          ? _c(
                              "div",
                              {
                                staticClass: "collapse card-body",
                                attrs: { id: "result-" + result.id },
                              },
                              [
                                _c("strong", [_vm._v("Date:")]),
                                _vm._v(
                                  " " + _vm._s(_vm._f("date")(result.date))
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Emails:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("number_with_zero")(result.emails)
                                    )
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Gifts:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("number_with_zero")(result.gifts)
                                    )
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("New Donors:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("number_with_zero")(
                                        result.new_donors
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Revenue:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency_with_zero")(
                                        result.revenue
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Recurring Revenue:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency_with_zero")(
                                        result.recurring_revenue
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "collapse card-body",
                                attrs: { id: "result-" + result.id },
                              },
                              [
                                _c("strong", [_vm._v("Filter Logic:")]),
                                _c("br"),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "line-height": "2.4em" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.logicToString(
                                        result.value.filter_logic,
                                        result.value.logic_units
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                      ])
                    }),
                    0
                  ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "30px" } },
            [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "row" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-sm btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.modal_version = "Cost"
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-plus" })]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.init.costs_filter == null ||
                _vm.init.costs_filter.length == 0
                  ? _c("div", { staticClass: "card box-content" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _vm._v(
                          "\n                            None Added Yet\n                        "
                        ),
                      ]),
                    ])
                  : _c(
                      "div",
                      { staticClass: "accordion" },
                      _vm._l(_vm.init.costs_filter, function (cost) {
                        return _c("div", { staticClass: "card box-content" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-9" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-link text-left collapsed",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "collapse",
                                    "data-target": "#cost-" + cost.id,
                                    "aria-controls": "collapseOne",
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("propercase")(cost.data_source)
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    ": " +
                                      _vm._s(cost.description) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-xs btn-none",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCost(cost)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-edit" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-xs btn-none",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCost(cost)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-remove" })]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          cost.data_source == "manual"
                            ? _c(
                                "div",
                                {
                                  staticClass: "collapse card-body",
                                  attrs: { id: "cost-" + cost.id },
                                },
                                [
                                  _vm._v(
                                    "\n                                Total: " +
                                      _vm._s(
                                        _vm._f("currency_with_zero")(
                                          cost.amount
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                Date: " +
                                      _vm._s(_vm._f("date")(cost.date))
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                Cost Type: " +
                                      _vm._s(
                                        _vm._f("propercase")(cost.cost_type)
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "collapse card-body",
                                  attrs: { id: "cost-" + cost.id },
                                },
                                [
                                  _c("strong", [_vm._v("Filter Logic:")]),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticStyle: { "line-height": "2.4em" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.logicToString(
                                          cost.value.filter_logic,
                                          cost.value.logic_units
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                        ])
                      }),
                      0
                    ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.modal_version != null
        ? _c("cost-result-modal", {
            attrs: {
              modal_version: _vm.modal_version,
              initiative: _vm.init,
              client: _vm.client,
              filter: _vm.filter,
            },
            on: {
              "update:modal_version": function ($event) {
                _vm.modal_version = $event
              },
              "update:initiative": function ($event) {
                _vm.init = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "table table-bordered" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { attrs: { scope: "col" } }, [_vm._v("Metric")]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [_vm._v("Projection")]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [_vm._v("Actual")]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [_vm._v("Variance")]),
        ]),
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("CPM")]),
          _vm._v(" "),
          _c("td", [_vm._v("$4.23")]),
          _vm._v(" "),
          _c("td", [_vm._v("$3.45")]),
          _vm._v(" "),
          _c("td", { staticClass: "positive-4" }, [_vm._v("-41%")]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Click Rate")]),
          _vm._v(" "),
          _c("td", [_vm._v("1.3%")]),
          _vm._v(" "),
          _c("td", [_vm._v("1.4%")]),
          _vm._v(" "),
          _c("td", { staticClass: "positive-1" }, [_vm._v("8%")]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Email Sign Up Rate")]),
          _vm._v(" "),
          _c("td", [_vm._v("43%")]),
          _vm._v(" "),
          _c("td", [_vm._v("35%")]),
          _vm._v(" "),
          _c("td", { staticClass: "negative-2" }, [_vm._v("-19%")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("h4", [_vm._v("Assigned Results")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("h4", [_vm._v("Assigned Costs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }