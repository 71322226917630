var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-builder" }, [
    _c("div", { staticClass: "card box-content my-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("Chart Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_chart.name,
                  expression: "local_chart.name",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors.name != "" ? "is-invalid" : "",
              attrs: { type: "text", id: "chart-name-input" },
              domProps: { value: _vm.local_chart.name },
              on: {
                change: function ($event) {
                  return _vm.clearErrorForField("name")
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.local_chart, "name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.name != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.errors.name) +
                      "\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.local_chart.id == null
            ? _c("div", { staticClass: "col-sm-3" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_client,
                        expression: "local_client",
                      },
                    ],
                    staticClass: "form-select",
                    attrs: { id: "client-select" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.local_client = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.updateDataBlend()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.clients, function (i) {
                    return _c("option", { domProps: { value: i } }, [
                      _vm._v(_vm._s(i.name)),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.local_chart.id == null
            ? _c("div", { staticClass: "col-sm-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Data Blend Type"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_chart.data_blend_type,
                        expression: "local_chart.data_blend_type",
                      },
                    ],
                    staticClass: "form-select",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.local_chart,
                            "data_blend_type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.updateDataBlend()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.local_client.data_blends, function (i) {
                    return _c("option", { domProps: { value: i.type } }, [
                      _vm._v(_vm._s(_vm._f("propercase")(i.type))),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("Chart Title")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_chart.title,
                  expression: "local_chart.title",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "title-input" },
              domProps: { value: _vm.local_chart.title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.local_chart, "title", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v(
                _vm._s(
                  this.local_chart.type == "scorecard"
                    ? "Image URL"
                    : "Chart Subtitle"
                ) + " "
              ),
              _c("em", [_vm._v("(Often phrased as a question)")]),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_chart.subtitle,
                  expression: "local_chart.subtitle",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "subtitle-input" },
              domProps: { value: _vm.local_chart.subtitle },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.local_chart, "subtitle", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local_chart.description,
                  expression: "local_chart.description",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.local_chart.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.local_chart, "description", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { class: _vm.render_large_chart ? "col-12" : "col-3" },
        [
          _c("chart-configuration", {
            ref: "chart_config",
            attrs: {
              chart: _vm.local_chart,
              render_large_chart: _vm.render_large_chart,
              data_blends_array:
                _vm.clients[0] && _vm.clients[0].data_blends
                  ? _vm.clients[0].data_blends
                  : [],
              errors: _vm.errors,
              metrics_filters: _vm.metrics_filters,
            },
            on: {
              updateChartConfig: _vm.updateChartConfig,
              updateChartData: _vm.updateChartData,
              loadingChartData: _vm.loadingChartData,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.render_large_chart ? "col-12 mt-3" : "col-9" },
        [
          _c("chart-preview", {
            attrs: {
              chart_selected: _vm.local_chart,
              chart_type: _vm.local_chart.type,
              if_loading: _vm.is_loading_chart_data,
              render_large_chart: _vm.render_large_chart,
            },
            on: { changePreviewSize: _vm.changePreviewSize },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
              _vm._v(
                "\n                There was an error saving the chart details.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 mb-5" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "submit", disabled: _vm.form.busy },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(
                " " +
                  _vm._s(this.local_chart.id != null ? "Update" : "Add") +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Client "),
      _c("em", [_vm._v("(Optional)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Chart Description "),
      _c("em", [
        _vm._v("(Used in search and as a "),
        _c("i", { staticClass: "fas fa-question-circle text-primary me-0" }),
        _vm._v(" icon next to chart title.)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-secondary ms-3",
        attrs: { href: "/insights/charts" },
      },
      [
        _c("i", { staticClass: "fa fa-btn fa-close" }),
        _vm._v(" Cancel\n                "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }