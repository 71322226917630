var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "generate-ppt-build-new" } },
    [
      _c("div", { attrs: { id: "choose" } }, [
        _c("div", { staticClass: "card", attrs: { id: "choose-report" } }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex-row flex-nowrap gap-2 align-items-stretch mt-1",
              attrs: { id: "choose-report_search-row" },
            },
            [
              _c(
                "div",
                { staticClass: "search-box flex-grow-1 position-relative" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search_key,
                        expression: "search_key",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder:
                        "Search by Report Name, Group Name or Description ...",
                      "aria-label":
                        "Search by Report Name, Group Name or Description ...",
                    },
                    domProps: { value: _vm.search_key },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.search_key = $event.target.value
                        },
                        function ($event) {
                          return _vm.searchPage()
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa fa-search" }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column gap-3",
              attrs: { id: "choose-report-list" },
            },
            [
              _vm.filtered_page_list.length == 0
                ? _c("div", [
                    _c("p", [_vm._v("No reports match your search.")]),
                  ])
                : _vm._l(_vm.available_page_list, function (page, page_index) {
                    return _c(
                      "div",
                      {
                        staticClass: "card long-card",
                        on: {
                          click: function ($event) {
                            return _vm.addPageSelectionToPPT(page, page_index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body long-card__body" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "long-card__content d-flex flex-row justfy-content-start gap-2",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-grow-1 gap-1",
                                  },
                                  [
                                    _c("div", { staticClass: "d-block" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row align-items-center position-relative",
                                        },
                                        [
                                          _c("h5", [_vm._v(_vm._s(page.name))]),
                                          _vm._v(" "),
                                          _c("small", [
                                            _c(
                                              "span",
                                              { staticClass: "badge" },
                                              [_vm._v(_vm._s(page.group_name))]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(page.description))]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
              _vm._v(" "),
              _c("div", { staticClass: "table-pagination" }, [
                _c("div", { staticClass: "table-pagination--group" }, [
                  _c("span", { staticClass: "d-none d-lg-inline" }, [
                    _vm._v("Showing: "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.num_per_page,
                          expression: "num_per_page",
                        },
                      ],
                      staticClass: "form-select",
                      attrs: { name: "num_per_page", id: "num_per_page" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.num_per_page = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.reloadPagination()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "5000" } }, [
                        _vm._v("ALL"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.num_per_page_options, function (num) {
                        return _c("option", { domProps: { value: num } }, [
                          _vm._v(_vm._s(num)),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Results")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-pagination--group text-center" },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.item_interval) +
                        " of " +
                        _vm._s(_vm.filtered_page_list.length) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-pagination--group" }, [
                  _vm.page_count > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-none",
                          attrs: { disabled: _vm.page_index == 0 },
                          on: {
                            click: function ($event) {
                              return _vm.moveToPrevPage()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: "/img/icons/dialexa-icons/chevron-left.svg",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.page_index_array.length <= 3
                    ? _c(
                        "div",
                        _vm._l(_vm.page_index_array, function (page) {
                          return _c(
                            "button",
                            {
                              staticClass: "btn btn-none",
                              class:
                                page == _vm.page_index + 1
                                  ? "current-page"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.moveToPage(page)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(page) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        [
                          _vm._l(
                            _vm.page_index_array.slice(0, 2),
                            function (page) {
                              return _c(
                                "button",
                                {
                                  staticClass: "btn btn-none",
                                  class:
                                    page == _vm.page_index + 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(page)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(page) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _vm.page_index > 2
                            ? _c("button", { staticClass: "btn btn-none" }, [
                                _vm._v(
                                  "\n                            ...\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.page_index >= 2 &&
                          _vm.page_index < _vm.page_index_array.length - 1
                            ? _c(
                                "button",
                                { staticClass: "btn btn-none current-page" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.page_index + 1) +
                                      " \n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.page_index < _vm.page_index_array.length - 2
                            ? _c("button", { staticClass: "btn btn-none" }, [
                                _vm._v(
                                  "\n                            ...\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-none",
                              class:
                                _vm.page_index ==
                                _vm.page_index_array.length - 1
                                  ? "current-page"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.moveToPage(
                                    _vm.page_index_array.length
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.page_index_array.length) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                  _vm._v(" "),
                  _vm.page_count > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-none px-1",
                          attrs: {
                            disabled: _vm.page_index == _vm.page_count - 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.moveToNextPage()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: "/img/icons/dialexa-icons/chevron-right.svg",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card", attrs: { id: "selected-report" } }, [
          _c(
            "div",
            {
              staticClass: "row d-flex",
              attrs: { id: "selected-report-description" },
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm.selected_page_list.length > 0
                ? _c("div", { staticClass: "col-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary float-end mb-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleFilterPanel()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon pe-0",
                          attrs: { src: "/img/icons/dialexa-icons/sort.svg" },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("(" + _vm._s(_vm.filter_count) + ")"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-grow-1 position-relative",
              attrs: { id: "selected-report-list" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "position-absolute top-0 start-0 end-0 bottom-0",
                },
                [
                  _c("div", { staticClass: "position-relative h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "scroll-view d-flex flex-column gap-3 flex-shrink-1 pt-1",
                        class: _vm.selected_page_list.length >= 5 ? "pe-2" : "",
                        staticStyle: { "max-height": "100%" },
                      },
                      [
                        _vm.selected_page_list.length == 0
                          ? _c(
                              "div",
                              { staticClass: "card long-card me-1 disabled" },
                              [_vm._m(2)]
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: { handle: ".handle" },
                                    model: {
                                      value: _vm.selected_page_list,
                                      callback: function ($$v) {
                                        _vm.selected_page_list = $$v
                                      },
                                      expression: "selected_page_list",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selected_page_list,
                                    function (page, page_index) {
                                      return _c(
                                        "div",
                                        {
                                          key: page.id,
                                          staticClass:
                                            "card long-card me-1 mb-3",
                                          staticStyle: { cursor: "default" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-body long-card__body row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "long-card__content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row d-flex align-items-center position-relative",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-1 float-start",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "handle",
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "icon",
                                                                attrs: {
                                                                  src: "/img/icons/dialexa-icons/vertical-dots.svg",
                                                                  alt: "drag",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-11",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  page.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        page.group_name
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              page.sections
                                                                .length
                                                                ? _c("small", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "badge",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            page
                                                                              .sections
                                                                              .length
                                                                          ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              page
                                                                                .sections
                                                                                .length >
                                                                                1
                                                                                ? "Sections"
                                                                                : "Section"
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "w-100",
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                page.blend_sections,
                                                                              expression:
                                                                                "page.blend_sections",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-check-input me-1",
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              Array.isArray(
                                                                                page.blend_sections
                                                                              )
                                                                                ? _vm._i(
                                                                                    page.blend_sections,
                                                                                    null
                                                                                  ) >
                                                                                  -1
                                                                                : page.blend_sections,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  page.blend_sections,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v =
                                                                                    null,
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      page,
                                                                                      "blend_sections",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      page,
                                                                                      "blend_sections",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  page,
                                                                                  "blend_sections",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                                                            Blend sections\n                                                            "
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-question-circle text-primary mt-1",
                                                                      attrs: {
                                                                        "data-bs-toggle":
                                                                          "tooltip",
                                                                        title:
                                                                          "In pages where the donors and revenue are split, it can be helpful to blend the sections together. This will alternate the charts in the first two sections.",
                                                                      },
                                                                    }),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "asset-accordion-delete btn",
                                              attrs: {
                                                type: "button",
                                                "aria-label": "Close",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removePageSelection(
                                                    page,
                                                    page_index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-times",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade", duration: "500" } }, [
        _c(
          "div",
          {
            staticClass: "border-start border-secondary",
            class: _vm.is_filter_panel_expanded ? "open" : "hidden",
            attrs: { id: "filter-panel" },
          },
          [
            _c(
              "div",
              {
                staticClass: "p-4 pb-0 mt-2",
                attrs: { id: "filter-panel--heading" },
              },
              [
                _c("h4", [_vm._v("Filters")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleFilterPanel()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-regular fa-circle-xmark" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "mx-4 small" }, [
              _vm._v(
                "Filters will only be applied to a page if the filter is present on the page by default."
              ),
            ]),
            _vm._v(" "),
            _c("hr", {
              staticClass: "mx-4",
              attrs: { id: "filter-panel-heading-hr" },
            }),
            _vm._v(" "),
            _c("generate-presentation-filter-panel", {
              attrs: {
                pages_selected: _vm.selected_page_list,
                client: _vm.client,
              },
              on: {
                updateFilters: _vm.updateFilters,
                updateFilterCount: _vm.updateFilterCount,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sticky-footer" }, [
        _c(
          "nav",
          {
            staticClass:
              "navbar navbar-expand py-0 d-flex justify-content-between row",
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary float-end",
                  attrs: {
                    disabled:
                      _vm.form.busy || _vm.selected_page_list.length == 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.downloadPPT()
                    },
                  },
                },
                [
                  !_vm.form.busy
                    ? _c("span", [_vm._v("Download PPT")])
                    : _c("span", [_vm._m(3), _vm._v(" Processing")]),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("link", {
        attrs: {
          rel: "preload",
          href: "/img/icons/dialexa-icons/vertical-dots.svg",
          as: "image",
          type: "image/svg+xml",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "choose-report-description" } }, [
      _c("h5", [_vm._v("Add Report Pages")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Select the report pages you want to include in your presentation."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("h5", [_vm._v("Manage the Page Order")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Change the order of the various pages or remove selected reports."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body long-card__body row" }, [
      _c("div", { staticClass: "long-card__content" }, [
        _c("h5", { staticClass: "mb-1" }, [_vm._v("No Pages Selected")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Select a page from the list on the left to start building your presentation."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "spinner-border spinner-border-sm me-2",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }