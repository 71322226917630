var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pathways-column-mapping" },
    _vm._l(_vm.mapping.slice(0, 2), function (column, i) {
      return _c(
        "section",
        {
          attrs: {
            id: i == 0 ? "primary-key-column-mapping" : "column-mapping",
          },
        },
        [
          i == 0 && _vm.showColumn(column, i)
            ? _c(
                "div",
                { staticClass: "w-100 d-flex flex-column gap-2 mb-3" },
                [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mapping",
                      class: { "is-invalid": _vm.errors[i].source != "" },
                      attrs: { id: "primary-key-column" },
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.source.operation != "append" ? "w-50" : "w-100",
                        },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.source.data_source.name) +
                                " Source Column(s)"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("accordion-multi-select", {
                            key: column.id,
                            class: { "is-invalid": _vm.errors[i].source != "" },
                            attrs: {
                              options: _vm.local_pk_source_columns,
                              field_name: "primary_key_column",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.selectKeyColumn($event, i)
                              },
                            },
                            model: {
                              value: column.source_column,
                              callback: function ($$v) {
                                _vm.$set(column, "source_column", $$v)
                              },
                              expression: "column.source_column",
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors[i].source != ""
                            ? _c(
                                "div",
                                { staticClass: "text-danger invalid-feedback" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.errors[i].source) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.source.operation != "append"
                        ? _c(
                            "div",
                            { staticClass: "w-50" },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.destination.integration.name) +
                                    " Column "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.hasOneKeyOption()
                                ? _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      disabled: "disabled",
                                    },
                                    domProps: {
                                      value: _vm.getColumnText(
                                        column.destination_column
                                      ),
                                    },
                                  })
                                : _c("accordion-select", {
                                    key: "dest" + column.id,
                                    class: {
                                      "is-invalid":
                                        _vm.errors[i].destination != "",
                                    },
                                    attrs: {
                                      options: _vm.getPrimayKeyDestinations(
                                        column.destination_column
                                      ),
                                    },
                                    model: {
                                      value: column.destination_column,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          column,
                                          "destination_column",
                                          $$v
                                        )
                                      },
                                      expression: "column.destination_column",
                                    },
                                  }),
                              _vm._v(" "),
                              _vm.errors[i].destination != ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-danger invalid-feedback",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.errors[i].destination) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isModel()
                    ? _c(
                        "div",
                        {
                          staticClass: "mapping mb-4",
                          attrs: { id: "prioritization-columns" },
                        },
                        [
                          _c("div", { staticClass: "w-100" }, [
                            _c("label", [_vm._v("Prioritization Column(s)")]),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "mb-2 d-block text-muted" },
                              [
                                _vm._v(
                                  "If there are multiple records with the same primary key, how should Avid AI determine which one to sync?"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "w-75 mb-2",
                                class:
                                  _vm.prioritization_column.length > 1
                                    ? "d-grid"
                                    : "d-flex",
                              },
                              [
                                _vm._l(
                                  _vm.prioritization_column,
                                  function (priority, i) {
                                    return [
                                      _vm.prioritization_column.length > 1
                                        ? _c("b", { staticClass: "fs-5" }, [
                                            _vm._v(_vm._s(i + 1) + "."),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group position-relative",
                                        },
                                        [
                                          _c("accordion-select", {
                                            attrs: {
                                              options: _vm.source_columns,
                                            },
                                            model: {
                                              value: priority.column,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  priority,
                                                  "column",
                                                  $$v
                                                )
                                              },
                                              expression: "priority.column",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary dropdown-toggle",
                                              attrs: {
                                                type: "button",
                                                "data-bs-toggle": "dropdown",
                                                "aria-expanded": "false",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  priority.sort == "DESC"
                                                    ? "Descending"
                                                    : "Ascending"
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "dropdown-menu dropdown-menu-end",
                                            },
                                            [
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        priority.sort = "ASC"
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Ascending")]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        priority.sort = "DESC"
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Descending")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.prioritization_column.length > 1
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mapping-grid__button btn btn-none px-0",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prioritization_column.splice(
                                                    i,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "icon p-1",
                                                attrs: {
                                                  src: "/img/icons/dialexa-icons/trash.svg",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.addPriorityColumn()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-plus" }),
                                _vm._v(" Add Prioritization"),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          i >= 1 && _vm.showAdditionalColumns()
            ? _c("div", { staticClass: "w-100 d-flex flex-column gap-2" }, [
                i == 1
                  ? _c("div", [
                      _c("strong", [_vm._v("Additional Columns")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Use the fields below to map the columns from your data source to those available in " +
                            _vm._s(_vm.destination.integration.name) +
                            ". "
                        ),
                        _c("strong", [
                          _vm._v(
                            "If you have recently added new columns to your destination, "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.refreshColumns()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "click here to refresh the available column list."
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-row gap-3" }, [
                  _c("div", { staticClass: "col-xl-8 col-12 card" }, [
                    _c("table", { staticClass: "mapping-table" }, [
                      _c("thead", [
                        _c("tr", [
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c("th"),
                          _vm._v(" "),
                          _c("th", [
                            _c("div", { staticClass: "flex-column" }, [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(_vm.destination.integration.name) +
                                    " Column"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "This is the column name that " +
                                    _vm._s(_vm.destination.integration.name) +
                                    " uses"
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("th"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.mapping.slice(1), function (column, j) {
                          return _vm.showColumn(column, j + i)
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "mapping-grid__source w-100" },
                                  [
                                    _c("div", { staticClass: "w-100 d-flex" }, [
                                      _vm.isDropdown(column)
                                        ? _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    column.source_column.value,
                                                  expression:
                                                    "column.source_column.value",
                                                },
                                              ],
                                              staticClass: "form-select",
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    column.source_column,
                                                    "value",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.dropdowns[
                                                column.destination_column
                                              ],
                                              function (option) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value: option.id,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(option.name))]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "input-group",
                                              class: {
                                                "is-invalid":
                                                  _vm.errors[j + 1].source !=
                                                  "",
                                              },
                                            },
                                            [
                                              _vm.isConstant(column)
                                                ? _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          column.source_column
                                                            .value,
                                                        expression:
                                                          "column.source_column.value",
                                                      },
                                                    ],
                                                    key: column.id,
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.errors[j + 1]
                                                          .source != "",
                                                    },
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        column.source_column
                                                          .value,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.updateConstant(
                                                          column
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          column.source_column,
                                                          "value",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("accordion-select", {
                                                    key: column.source_column
                                                      .id,
                                                    class: {
                                                      "is-invalid":
                                                        _vm.errors[j + 1]
                                                          .source != "",
                                                    },
                                                    attrs: {
                                                      options:
                                                        _vm.source_columns,
                                                    },
                                                    model: {
                                                      value:
                                                        column.source_column
                                                          .value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          column.source_column,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "column.source_column.value",
                                                    },
                                                  }),
                                              _vm._v(" "),
                                              _vm.isConstant(column)
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary p-0",
                                                      attrs: {
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        title:
                                                          "Use a value from a column",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleConstant(
                                                            column
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon px-1",
                                                        attrs: {
                                                          src: "/img/icons/dialexa-icons/map.svg",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary p-0",
                                                      attrs: {
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        title:
                                                          "Use a constant value",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleConstant(
                                                            column
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon px-1",
                                                        attrs: {
                                                          src: "/img/icons/dialexa-icons/edit.svg",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      !column.is_primary_identifier
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "close-btn d-sm-none",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-none px-0",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeColumn(
                                                        j - 1
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _vm.errors[j + 1].source != ""
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-danger invalid-feedback",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.errors[j + 1].source
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(2, true),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "mapping-grid__destination w-100",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-100 d-flex" },
                                      [
                                        !_vm.isColumnOptional(column)
                                          ? _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                disabled: "disabled",
                                              },
                                              domProps: {
                                                value: _vm.getColumnText(
                                                  column.destination_column
                                                ),
                                              },
                                            })
                                          : _c("accordion-select", {
                                              key: "dest" + column.id,
                                              class: {
                                                "is-invalid":
                                                  _vm.errors[j + 1]
                                                    .destination != "",
                                              },
                                              attrs: {
                                                options:
                                                  _vm.getAvailableDestinations(
                                                    column.destination_column
                                                  ),
                                              },
                                              model: {
                                                value:
                                                  column.destination_column,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    column,
                                                    "destination_column",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "column.destination_column",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.errors[j + 1].destination != ""
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-danger invalid-feedback",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.errors[j + 1].destination
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _vm.isColumnOptional(column)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "mapping-grid__button btn btn-none px-0",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeColumn(j + 1)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "icon p-1",
                                            attrs: {
                                              src: "/img/icons/dialexa-icons/trash.svg",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-xl-4 d-none d-xl-block drill-down pe-3",
                    },
                    [
                      _c("preview-table", {
                        attrs: {
                          client: _vm.client,
                          source: _vm.source,
                          source_columns: _vm.source_columns,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _vm._v("Primary Key Column(s) "),
      _c("i", {
        staticClass: "fas fa-info-circle ms-2",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "The primary key is what determines a unique row of data and how that aligns to the destination. It may be as simple as the Contact ID or an email. For advertising destinations like Facebook and Google, we use multiple columns to sync every possible bit of information.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("div", { staticClass: "flex-column" }, [
        _c("h5", [_vm._v("Column Name You're Importing")]),
        _vm._v(" "),
        _c("p", [_vm._v("What your column was named in the source")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("div", { staticClass: "w-100 d-flex align-items-center" }, [
        _c("small", [_vm._v("Maps to")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }