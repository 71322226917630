<template>
    <div class="generate-ppt view-report p-2 mb-4">
        <h1 class="mb-2">Previous Presentations</h1>
        <p>Below is the list of previously generated presentations. When you copy one, it will take you back to the prior tab so that you can make any additional adjustments before exporting it.</p>
        <div class="d-flex flex-column gap-3" id='choose-report'>
            <h5 class="mt-3" v-if="exports.length == 0">No presentations have been generated yet.</h5>

            <div v-else class="mt-1">
                <div class="search-box mx-0 position-relative ">
                    <input type="text" class="form-control" placeholder="Search by Presentation Name" aria-label="Search by Presentation Name ..." v-model="search_key" @input="searchPage()">
                    <i class="fa fa-search"></i>
                </div>
            </div>

            <div class="table-responsive border rounded table-template"  v-if="table_data.length > 0">
                <table class="table m-0">
                <thead>
                    <tr class="table-header-row">
                        <th v-on:click="sortByKey(label)" v-for="label in labels">
                            <div class="d-flex flex-row">
                                {{label | propercase}}
                                <div class="d-flex flex-column">
                                    <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key==label && sort_order=='asc')? 'sort-key-active' :''">
                                    <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key==label && sort_order=='des')? 'sort-key-active' :''">
                                </div>
                            </div>
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in table_data_shown"  class="table-template-row show-on-row-hover">
                        <td v-for="label in labels" class='align-middle py-2' >
                            <span v-if="label == 'actions'">

                            </span>
                            <span v-else-if="label == 'sections'">
                                {{ data[label].length }}
                            </span>
                            <span v-else-if="label == 'created_at'">
                                {{ data[label] | datetime }}
                            </span>
                            <span v-else-if="label == 'created_by'">
                                <img :src="data.creator.image" :alt=" data.creator.name " class="avatar rounded-circle avatar-sm bg-white me-2"> {{ data.creator.name }}
                            </span>
                            <span v-else>
                                {{ data[label] | propercase }}
                            </span>
                        </td>
                        <td class="align-start table-column-5 show-on-row-hover">
                            <a class="btn btn-none px-1" target="_blank" :href="'/export/'+client.url+'/' + data.id" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download Presentation" >
                                <img class="icon" src="/img/icons/dialexa-icons/file-download.svg" >
                            </a>

                            <button class="btn btn-none px-1" @click.prevent="copyExport(data)">
                                <i class="fa-solid fa-copy" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clone Presentation" ></i>
                            </button>

                            <button class="btn btn-none px-1" @click.prevent="deleteExport(data.id)">
                                <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                        title="Archive Presentation" >
                            </button>
                        </td>
                    </tr>
                </tbody>

                <tfoot class="table-border-bottom-0">
                    <tr>
                        <td colspan="100%" class="py-0">
                            <div class="table-pagination">
                                <div class="table-pagination--group">
                                    <span class="d-none d-lg-inline">Showing: </span>
                                    <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                        <option value="5000">ALL</option>
                                        <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                                    </select>
                                    <span>Results </span>
                                </div>

                                <div class="table-pagination--group text-center">
                                    {{ item_interval }} of {{ table_data.length }}
                                </div>

                                <div class="table-pagination--group">
                                    <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                        <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                    </button>

                                    <div v-if="page_index_array.length<=3">
                                        <button  v-for="page in page_index_array" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                        {{page}}
                                        </button>
                                    </div>
                                    <div v-else>
                                        <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                        {{page}}
                                        </button>

                                        <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                        ...
                                        </button>
                                        <button v-if="page_index >= 2 && page_index< page_index_array.length -1" class="btn btn-none px-1 current-page">
                                            {{page_index + 1 }}
                                        </button>

                                        <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1" disabled>
                                        ...
                                        </button>
                                        <button  class="btn btn-none px-1" :class="page_index ==page_index_array.length -1 ? 'current-page':''" @click="moveToPage(page_index_array.length)">
                                        {{ page_index_array.length }}
                                        </button>
                                    </div>

                                    <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == page_count -1 ">
                                        <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>

    </div>
</template>

<script>
    import Swal from 'sweetalert2';

    export default {
        components: {
            Swal
        },
        props: ['client', 'exports'],
        data() {
            return {
                labels: ['name', 'sections', 'created_at', 'created_by'],
                search_key: '',
                table_data: [],
                table_data_shown:[],
                sort_key: 'created_at',
                sort_order: 'desc',

                // pagination
                num_per_page: 15, // default to 15
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: ''
            };
        },
        beforeMount() {
            this.table_data = [...this.exports];
            this.table_data_shown = [...this.table_data];
            this.reloadPagination();
        },
        methods: {
            copyExport(data) {
                //Emit the function
                this.$emit('clone', data);
            },
            searchPage(){
                var filtered_list = [];

                if (this.search_key == ''){
                    filtered_list = this.exports;
                } else {
                    this.exports.forEach(page => {
                        if(page.name.toLowerCase().includes(this.search_key.toLowerCase())){
                            filtered_list.push(page);
                        }
                    });
                }
                this.table_data = filtered_list;
                this.reloadPagination();
                this.$forceUpdate();
            },

            sortByKey(key){
                if (this.sort_key != key){
                    this.sort_key = key;
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel();
            },
            sortDataWithLabel(){
                if(this.sort_order == 'asc')
                    this.table_data.sort((a, b) => (a[this.sort_key ] > b[this.sort_key ]) ? 1 : -1);
                else
                    this.table_data.sort((a, b) => (a[this.sort_key ] < b[this.sort_key ]) ? 1 : -1);
                this.reloadPagination();
            },
            reloadPagination(){
                this.page_count = Math.ceil(this.table_data.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            },
            getItemInterval(){
                var from = this.page_index * this.num_per_page + 1;
                var to = (this.page_index +1) * this.num_per_page;
                if(to>this.table_data.length)
                    to = this.table_data.length;
                if (from >= to)
                    this.item_interval = to
                else
                    this.item_interval = from +' - ' +to;

                this.table_data_shown = this.table_data.slice(from-1, to);
            },
            moveToPrevPage(){
                this.page_index--;
                this.getItemInterval();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getItemInterval();
            },
            moveToNextPage(){
                this.page_index++;
                this.getItemInterval();
            },
            deleteExport(id) {

                Swal.fire({
                  title: "Are you sure you want to delete this presentation?",
                  text: "The presentation will no longer be accessible to anyone else on the team once it is deleted.",
                  icon: "warning",
                  iconColor: "#E23434",
                  showCancelButton: true,
                  confirmButtonColor: "#E23434",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/export/ppt/' + id)
                        .then(response => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "This presentation has been removed.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                //Find the position of the deleted initiative
                                let pos = this.table_data.findIndex(e => e.id == id);
                                this.table_data.splice(pos, 1);
                                this.reloadPagination();
                            });
                        });
                  }
                });
            }
        }
    }
</script>
