var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "initiative client-user" }, [
    _vm.plan.is_published_plan == 1
      ? _c("div", { staticClass: "alert alert-warning mb-3 box-content" }, [
          _vm._v("\n          The plan being view is currently the "),
          _c("em", [_vm._v("published plan")]),
          _vm._v(
            ". This means you are not able to make any adjustments to the assumptions or initatives. If you'd like to make any updates, "
          ),
          _c(
            "a",
            { attrs: { href: "/planning/" + _vm.client.url + "/versions" } },
            [
              _vm._v(
                "you will need to make a new version. You can do that here."
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.initiative.id == null
      ? _c("div", { staticClass: "alert alert-warning mb-3 box-content" }, [
          _vm._v("\n          Any edits you make to the fields below will be "),
          _c("strong", [_vm._v("applied to every initiative")]),
          _vm._v(
            " with the same name and initiative type on the current annual plan. Leave a field blank if you don't want to override every initiative; you can then update those initiatives individually."
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Initiative Details:")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-6 col-sm-8" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Initiative Title"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.initiative.title,
                    expression: "initiative.title",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.title != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "title" },
                domProps: { value: _vm.initiative.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.initiative, "title", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.title != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _c("div", [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.errors.title) +
                          "\n                            "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-3 col-sm-4" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Priority")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.initiative.priority,
                      expression: "initiative.priority",
                    },
                  ],
                  staticClass: "form-select",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.initiative,
                        "priority",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", [_vm._v("Urgent")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Critical")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Elevated")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Recommended")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Required")]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Initiative Type"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.initiative.initiative_type.name,
                    expression: "initiative.initiative_type.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", disabled: "", name: "type" },
                domProps: { value: _vm.initiative.initiative_type.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.initiative.initiative_type,
                      "name",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.initiative.id != null
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm" }, [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Start Month"),
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      class: _vm.errors.start_month != "" ? "is-invalid" : "",
                      attrs: {
                        "minimum-view": "month",
                        "use-utc": true,
                        format: "MMM yyyy",
                        "input-class": "form-control",
                      },
                      model: {
                        value: _vm.initiative.start_month,
                        callback: function ($$v) {
                          _vm.$set(_vm.initiative, "start_month", $$v)
                        },
                        expression: "initiative.start_month",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.start_month != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.errors.start_month) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.initiative.initiative_type.calc_type != "one_time"
                ? _c("div", { staticClass: "col-sm" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("End Month"),
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          class: _vm.errors.end_month != "" ? "is-invalid" : "",
                          attrs: {
                            "minimum-view": "month",
                            "use-utc": true,
                            format: "MMM yyyy",
                            "input-class": "form-control",
                          },
                          model: {
                            value: _vm.initiative.end_month,
                            callback: function ($$v) {
                              _vm.$set(_vm.initiative, "end_month", $$v)
                            },
                            expression: "initiative.end_month",
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.end_month != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.errors.end_month) +
                                      "\n                            "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Strategic Focus"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.initiative.strategic_focus,
                          expression: "initiative.strategic_focus",
                        },
                      ],
                      staticClass: "form-select",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.initiative,
                            "strategic_focus",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", [_vm._v("Acquisition")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Analysis & Reporting")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Audience Generation")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Donor Upgrade")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Reactivation")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Retention")]),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mb-3 inline-dropdown" }, [
              _c("label", { staticClass: "form-label mb-0" }, [
                _vm._v("Costs"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.initiative.costs.length == 0
                    ? _c("div", { staticClass: "col" }, [_vm._m(0)])
                    : _vm._l(_vm.initiative.costs, function (c, index) {
                        return _c(
                          "div",
                          { staticClass: "col-md-3 col-sm-6 has-prefix" },
                          [
                            _c("label", { staticClass: "floating_label" }, [
                              _vm._v(_vm._s(c.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group mb-3" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _vm._v("$"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: c.value,
                                    expression: "c.value",
                                  },
                                ],
                                staticClass: "form-control",
                                class:
                                  _vm.errors.costs[index] != ""
                                    ? "is-invalid"
                                    : "",
                                attrs: { type: "text" },
                                domProps: { value: c.value },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.isNumber($event)
                                  },
                                  paste: _vm.cleanUpPaste,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(c, "value", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.errors.costs[index] != ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-danger invalid-feedback order-last",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.errors.costs[index]) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.initiative.inputs.length > 0
      ? _c("div", { staticClass: "card box-content mb-5" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", [_vm._v("Projected Metrics")]),
            _vm._v(" "),
            _vm.initiative.inputs.length > 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "mb-3 inline-dropdown" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        _vm._l(_vm.initiative.inputs, function (i, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "col-md-3 col-sm-6",
                              class: i.type == "money" ? "has-prefix" : "",
                            },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v(_vm._s(i.name) + " "),
                                i.description
                                  ? _c("i", {
                                      staticClass: "fas fa-info-circle",
                                      attrs: {
                                        "data-bs-toggle": "tooltip",
                                        "data-placement": "top",
                                        title: i.description,
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "mb-3",
                                  class:
                                    i.type != "number" ? "input-group" : "",
                                },
                                [
                                  i.type == "money"
                                    ? _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [_vm._v("$")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: i.value,
                                        expression: "i.value",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class:
                                      _vm.errors.inputs[index] != ""
                                        ? "is-invalid"
                                        : "",
                                    attrs: { type: "text" },
                                    domProps: { value: i.value },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.isNumber($event)
                                      },
                                      paste: _vm.cleanUpPaste,
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          i,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.inputs[index] != ""
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger invalid-feedback order-last",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.errors.inputs[index]) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  i.type == "percentage"
                                    ? _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [_vm._v("%")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.initiative.initiative_type.is_lifecycle
      ? _c("div", { staticClass: "mb-3" }, [
          _c("div", { staticClass: "card box-content" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", [_vm._v("Data Inclusion Filter")]),
                _vm._v(" "),
                _vm.initiative.initiative_type.is_lifecycle
                  ? _c("query-inclusion-builder", {
                      ref: "filter",
                      attrs: {
                        client: _vm.client,
                        include_trans: true,
                        in_card: true,
                      },
                      model: {
                        value: _vm.initiative.inputs_filter,
                        callback: function ($$v) {
                          _vm.$set(_vm.initiative, "inputs_filter", $$v)
                        },
                        expression: "initiative.inputs_filter",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-label" }, [_vm._v("Notes")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.initiative.notes,
                expression: "initiative.notes",
              },
            ],
            staticClass: "form-control bg-white",
            attrs: { rows: "3" },
            domProps: { value: _vm.initiative.notes },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.initiative, "notes", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    false
      ? _c("div", { staticClass: "card box-content mb-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", [_vm._v("Roadmap Documentation")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "The descriptions below will be used when exporting the initiative documentation for the Roadmap books."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("vue-editor", {
                      attrs: { editorToolbar: _vm.customToolbar },
                      model: {
                        value: _vm.initiative.objective,
                        callback: function ($$v) {
                          _vm.$set(_vm.initiative, "objective", $$v)
                        },
                        expression: "initiative.objective",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("vue-editor", {
                      attrs: { editorToolbar: _vm.customToolbar },
                      model: {
                        value: _vm.initiative.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.initiative, "description", $$v)
                        },
                        expression: "initiative.description",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("vue-editor", {
                      attrs: { editorToolbar: _vm.customToolbar },
                      model: {
                        value: _vm.initiative.at_stake,
                        callback: function ($$v) {
                          _vm.$set(_vm.initiative, "at_stake", $$v)
                        },
                        expression: "initiative.at_stake",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.successful
          ? _c("div", { staticClass: "alert alert-success mb-3" }, [
              _vm._v(
                "\n                The initiative has been updated!\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
              _vm._v(
                "\n                There was an issue saving this initiative. Check the fields above to get more information.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "submit", disabled: _vm.form.busy },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(" Update\n                "),
            ]
          ),
          _vm._v(" "),
          _vm.initiative.id != null
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary ms-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteInitiative()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-trash" }),
                  _vm._v(" Delete"),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("em", [_vm._v("No costs have been added yet.")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Strategic Objective"),
      _c("br"),
      _vm._v(" "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v("What is the primary purpose of this initiative?"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Tactical Overview"),
      _c("br"),
      _vm._v(" "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v(
          "Describe all of the tactical steps necessary to fully execute this initiative. Be as verbose as necessary."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Implications of Not Implementing"),
      _c("br"),
      _vm._v(" "),
      _c("em", { staticStyle: { "font-weight": "normal" } }, [
        _vm._v(
          "What bad things will happen if this initiative is not implemented?"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }