var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.id,
        "aria-labelledby": "system-modal",
        "aria-hidden": "true",
        "data-bs-backdrop": "static",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content mx-2" }, [
          _c("div", { staticClass: "modal-header border-bottom-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-none close-modal",
                attrs: { type: "button", "data-bs-dismiss": "#" + _vm.id },
                on: {
                  click: function ($event) {
                    return _vm.closeModal()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-close me-0" })]
            ),
          ]),
          _vm._v(" "),
          !_vm.selected_integration || !_vm.selected_integration.id
            ? _c("div", { staticClass: "modal-body pt-0" }, [
                _c("h3", [_vm._v("Add a System")]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v("Connect a system to unleash more insights!"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 position-relative mb-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search_key,
                          expression: "search_key",
                        },
                      ],
                      staticClass: "search-box form-control me-1",
                      attrs: {
                        type: "text",
                        placeholder: "Search by Name...",
                        "aria-label": "Search by Name...",
                      },
                      domProps: { value: _vm.search_key },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.search_key = $event.target.value
                          },
                          function ($event) {
                            return _vm.searchIntegration()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _vm.search_key == ""
                      ? _c("i", {
                          staticClass: "fa fa-search search-placeholder-icon",
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "system-connections-grid row" },
                  _vm._l(_vm.local_integrations, function (integration, index) {
                    return _c("div", { staticClass: "col-6 d-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card system-connection-card mb-2 w-100",
                          class:
                            integration.id === _vm.selected_integration.id
                              ? "system-connection-card-selected"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.changeIntegrationSelection(integration)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-body system-connection-card__header d-flex",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "system-connection-card__header__icon",
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "avatar rounded-circle avatar-sm me-2 bg-white",
                                    attrs: {
                                      src: integration.logo_url,
                                      alt: integration.display_name,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("h5", { staticClass: "mt-2" }, [
                                _vm._v(_vm._s(integration.display_name)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _c("div", { staticClass: "modal-body pt-0" }, [
                _vm.form.loading
                  ? _c("div", [_vm._m(1)])
                  : _c("div", [
                      !_vm.local_connection_selected
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-text back-button mb-2",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.backToSelection()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon pe-1",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevrons-left.svg",
                                },
                              }),
                              _vm._v(
                                "\n                        Back\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h3", [
                        _c("img", {
                          staticClass:
                            "avatar rounded-circle avatar-sm me-2 bg-white",
                          attrs: {
                            src: _vm.selected_integration.logo_url,
                            alt: _vm.selected_integration.display_name,
                          },
                        }),
                        _vm._v(_vm._s(_vm.selected_integration.display_name)),
                      ]),
                      _vm._v(" "),
                      _vm.selected_integration_info.type == "credentials"
                        ? _c("div", [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.selected_integration_info.instructions
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "system-connection-fields row mt-2",
                              },
                              _vm._l(
                                _vm.selected_integration_info.field,
                                function (field, field_index) {
                                  return _c("div", { staticClass: "mb-1" }, [
                                    field.type != "checkbox" &&
                                    field.type != "hidden"
                                      ? _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(field.label),
                                              },
                                            }),
                                            _vm._v(" "),
                                            field.tooltip &&
                                            field.tooltip.length > 0
                                              ? _c("i", {
                                                  staticClass:
                                                    "fas fa-info-circle",
                                                  attrs: {
                                                    "data-bs-toggle": "tooltip",
                                                    title: field.tooltip,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "text"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.value,
                                              expression: "field.value",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: field.error
                                            ? "is-invalid"
                                            : "",
                                          attrs: {
                                            type: "text",
                                            name: field.id,
                                            disabled: _vm.form.saving,
                                          },
                                          domProps: { value: field.value },
                                          on: {
                                            change: function ($event) {
                                              field.error = false
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                field,
                                                "value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "label"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.value,
                                              expression: "field.value",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            disabled: "disabled",
                                            name: field.id,
                                          },
                                          domProps: { value: field.value },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                field,
                                                "value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "textarea"
                                      ? _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: field.value,
                                              expression: "field.value",
                                            },
                                          ],
                                          staticClass:
                                            "form-control full-height",
                                          attrs: {
                                            disabled: _vm.form.saving,
                                            name: field.id,
                                          },
                                          domProps: { value: field.value },
                                          on: {
                                            change: function ($event) {
                                              field.error = false
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                field,
                                                "value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "password" ||
                                    field.type == "password-text"
                                      ? _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            (field.type == "password"
                                              ? "password"
                                              : "text") === "checkbox"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: field.value,
                                                      expression: "field.value",
                                                    },
                                                  ],
                                                  ref: "password",
                                                  refInFor: true,
                                                  staticClass:
                                                    "form-control password-input input_v1",
                                                  class: field.error
                                                    ? "is-invalid"
                                                    : "",
                                                  attrs: {
                                                    "aria-label": "Password",
                                                    disabled: _vm.form.saving,
                                                    "aria-describedby":
                                                      "password-addon",
                                                    autocomplete: "off",
                                                    name: field.id,
                                                    name: "password",
                                                    type: "checkbox",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      field.value
                                                    )
                                                      ? _vm._i(
                                                          field.value,
                                                          null
                                                        ) > -1
                                                      : field.value,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$a = field.value,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                field,
                                                                "value",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                field,
                                                                "value",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            field,
                                                            "value",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                      function ($event) {
                                                        field.error = false
                                                      },
                                                    ],
                                                  },
                                                })
                                              : (field.type == "password"
                                                  ? "password"
                                                  : "text") === "radio"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: field.value,
                                                      expression: "field.value",
                                                    },
                                                  ],
                                                  ref: "password",
                                                  refInFor: true,
                                                  staticClass:
                                                    "form-control password-input input_v1",
                                                  class: field.error
                                                    ? "is-invalid"
                                                    : "",
                                                  attrs: {
                                                    "aria-label": "Password",
                                                    disabled: _vm.form.saving,
                                                    "aria-describedby":
                                                      "password-addon",
                                                    autocomplete: "off",
                                                    name: field.id,
                                                    name: "password",
                                                    type: "radio",
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      field.value,
                                                      null
                                                    ),
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        return _vm.$set(
                                                          field,
                                                          "value",
                                                          null
                                                        )
                                                      },
                                                      function ($event) {
                                                        field.error = false
                                                      },
                                                    ],
                                                  },
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: field.value,
                                                      expression: "field.value",
                                                    },
                                                  ],
                                                  ref: "password",
                                                  refInFor: true,
                                                  staticClass:
                                                    "form-control password-input input_v1",
                                                  class: field.error
                                                    ? "is-invalid"
                                                    : "",
                                                  attrs: {
                                                    "aria-label": "Password",
                                                    disabled: _vm.form.saving,
                                                    "aria-describedby":
                                                      "password-addon",
                                                    autocomplete: "off",
                                                    name: field.id,
                                                    name: "password",
                                                    type:
                                                      field.type == "password"
                                                        ? "password"
                                                        : "text",
                                                  },
                                                  domProps: {
                                                    value: field.value,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      field.error = false
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        field,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "input-group-text",
                                                staticStyle: {
                                                  "border-left": "none",
                                                  "background-color": "white",
                                                },
                                                attrs: {
                                                  title: !field.visible
                                                    ? "Show password"
                                                    : "Hide password",
                                                  id: "password-addon",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleVisibility(
                                                      field
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa",
                                                  class: [
                                                    !field.visible
                                                      ? "fa-eye"
                                                      : "fa-eye-slash",
                                                  ],
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "select"
                                      ? _c("div", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: field.value,
                                                  expression: "field.value",
                                                },
                                              ],
                                              staticClass: "form-select",
                                              class: field.error
                                                ? "is-invalid"
                                                : "",
                                              attrs: {
                                                disabled: _vm.form.saving,
                                                name: field.id,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      field,
                                                      "value",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    field.error = false
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              Object.keys(field.options),
                                              function (option) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: { value: option },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        field.options[option]
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "warning"
                                      ? _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-top": "-8px",
                                              },
                                            },
                                            [
                                              _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [_vm._v(_vm._s(field.text))]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "alert alert-warning",
                                              attrs: { role: "alert" },
                                            },
                                            [
                                              _c("code", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    field.value
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "vue-select"
                                      ? _c(
                                          "div",
                                          [
                                            _c("accordion-select", {
                                              class: field.error
                                                ? "is-invalid"
                                                : "",
                                              attrs: {
                                                disabled: _vm.form.saving,
                                                options: field.options,
                                                name: field.id,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  field.error = false
                                                },
                                              },
                                              model: {
                                                value: field.value,
                                                callback: function ($$v) {
                                                  _vm.$set(field, "value", $$v)
                                                },
                                                expression: "field.value",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "checkbox"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "form-check",
                                            class: field.error
                                              ? "is-invalid"
                                              : "",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: field.value,
                                                  expression: "field.value",
                                                },
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                disabled: _vm.form.saving,
                                                type: "checkbox",
                                                value: "true",
                                                id: field.id,
                                                name: field.id,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  field.value
                                                )
                                                  ? _vm._i(
                                                      field.value,
                                                      "true"
                                                    ) > -1
                                                  : field.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = field.value,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "true",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          field,
                                                          "value",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          field,
                                                          "value",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      field,
                                                      "value",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: { for: field.id },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(field.label) +
                                                    "\n                                        "
                                                ),
                                                field.tooltip &&
                                                field.tooltip.length > 0
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fas fa-info-circle",
                                                      attrs: {
                                                        "data-bs-toggle":
                                                          "tooltip",
                                                        title: field.tooltip,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.type == "tables"
                                      ? _c(
                                          "div",
                                          { attrs: { id: field.id } },
                                          [
                                            _vm._m(2, true),
                                            _vm._v(" "),
                                            _vm._l(
                                              field.value,
                                              function (row, row_index) {
                                                return _c(
                                                  "div",
                                                  { staticClass: "row mb-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-1" },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "text-end mb-0 mt-1 nums",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row_index + 1
                                                              ) + "."
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-sm-7",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                field.value[
                                                                  row_index
                                                                ]["table_name"],
                                                              expression:
                                                                "field.value[row_index]['table_name']",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          class:
                                                            field.error &&
                                                            field.value[
                                                              row_index
                                                            ]["table_name"] ==
                                                              ""
                                                              ? "is-invalid"
                                                              : "",
                                                          attrs: {
                                                            name: "table_name[]",
                                                            disabled:
                                                              _vm.form.saving,
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              field.value[
                                                                row_index
                                                              ]["table_name"],
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                field.value[
                                                                  row_index
                                                                ],
                                                                "table_name",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        _c("select", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                field.value[
                                                                  row_index
                                                                ]["table_type"],
                                                              expression:
                                                                "field.value[row_index]['table_type']",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-select",
                                                          class:
                                                            field.error &&
                                                            field.value[
                                                              row_index
                                                            ]["table_type"] ==
                                                              ""
                                                              ? "is-invalid"
                                                              : "",
                                                          attrs: {
                                                            name: "table_type[]",
                                                            disabled:
                                                              _vm.form.saving,
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.bigquery_table_type_html
                                                            ),
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  field.value[
                                                                    row_index
                                                                  ],
                                                                  "table_type",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                field.error = false
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    field.value.length > 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-1 delete_table",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-none",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeTable(
                                                                        field_index,
                                                                        row_index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-trash",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            field.error
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-danger invalid-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          field.error_msg
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary mt-3 mb-4",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addTable(
                                                      field_index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-fw fa-plus",
                                                }),
                                                _vm._v(" Add Table"),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.error && field.type != "tables"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-danger invalid-feedback",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                     " +
                                                _vm._s(field.error_msg) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm.form.error
                              ? _c(
                                  "div",
                                  { staticClass: "alert alert-danger mt-3" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.form.msg),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 d-flex flex-row justify-content-center gap-3 mt-2",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary w-50",
                                    attrs: { disabled: _vm.form.saving },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addIntegration()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Save\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary w-50",
                                    attrs: { disabled: _vm.form.saving },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeModal()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Cancel\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm.selected_integration_info.type == "oauth"
                        ? _c("div", { attrs: { id: "oauth-section" } }, [
                            _vm.selected_integration_info.instructions != null
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.selected_integration_info.instructions
                                    ),
                                  },
                                })
                              : _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      "Avid AI connects to " +
                                        _vm._s(
                                          _vm.selected_integration.display_name
                                        ) +
                                        " through a one-click authentication."
                                    ),
                                  ]),
                                  _vm._v(
                                    " To connect your instance, click on the button below—you will be redirecting to " +
                                      _vm._s(
                                        _vm.selected_integration.display_name
                                      ) +
                                      " to complete the process. If additional details are needed after connecting, you'll be prompted when you get back."
                                  ),
                                ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary w-100",
                                    on: {
                                      click: function ($event) {
                                        return _vm.redirectOauth(
                                          _vm.selected_integration_info.url
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon p-0",
                                      attrs: {
                                        src: "/img/icons/dialexa-icons/profile-tick.svg",
                                      },
                                    }),
                                    _vm._v(
                                      " Authenticate\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm.form.error
                        ? _c("div", [
                            _vm.form.error
                              ? _c(
                                  "div",
                                  { staticClass: "alert alert-danger mt-3" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.form.msg),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 d-flex flex-row justify-content-center gap-3 mt-2",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary w-100",
                                    attrs: { disabled: _vm.form.saving },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeModal()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Close\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 d-flex flex-column gap-1" }, [
      _c("img", {
        staticClass: "modal-icon",
        attrs: { src: "/img/icons/dialexa-icons/plug-connected.svg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex flex-column gap-2 align-items-center" },
      [
        _c(
          "div",
          {
            staticClass: "spinner-border text-primary",
            attrs: { role: "status" },
          },
          [
            _c("span", { staticClass: "visually-hidden" }, [
              _vm._v("Loading..."),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("h5", { staticClass: "text-center" }, [
          _vm._v("Loading System Information"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-7" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v(
            "Dataset and Table Name\n                                                "
          ),
          _c("i", {
            staticClass: "fas fa-info-circle",
            attrs: {
              "data-bs-toggle": "tooltip",
              title:
                "The value below should be in the format of [DATASET].[TABLE NAME]",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Table Type")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }