import { render, staticRenderFns } from "./PrioritizationSettings.vue?vue&type=template&id=040779a3"
import script from "./PrioritizationSettings.vue?vue&type=script&lang=js"
export * from "./PrioritizationSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev.aviddata.co/releases/20241116232211/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('040779a3')) {
      api.createRecord('040779a3', component.options)
    } else {
      api.reload('040779a3', component.options)
    }
    module.hot.accept("./PrioritizationSettings.vue?vue&type=template&id=040779a3", function () {
      api.rerender('040779a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/settings/PrioritizationSettings.vue"
export default component.exports