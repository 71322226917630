<template>
    <div class="column-mapping">
        <div class="row">
            <div class="col">
                <div class="d-flex flex-row gap-3">
                    <div class="col-xl-8 col-12 card mb-3">
                        <table class="column-mapping-table mapping-table">
                            <thead class="w-100">
                                <tr>
                                    <th>
                                        <div class="flex-column">
                                            <h5 class="ms-2">Column Name You're Importing</h5>
                                            <p class="ms-2">What your column is named in the source</p>
                                        </div>
                                    </th>
                                    <th>
                                        <!-- <div>
                                            <small>Maps to</small>
                                        </div> -->
                                    </th>
                                    <th>
                                        <div class="flex-column">
                                            <h5 class="ms-2">Avid AI Column</h5>
                                            <p class="ms-2">This is the column name that Avid AI uses</p>
                                        </div>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="w-100 py-2">
                                <tr v-for="(column, i) in mapping" :key="column.id" class="mapping-new mb-2" :class="getMappingClass(errors.columns[i], column)">
                                    <td class="mapping-grid__source">
                                        <div class="input-group mb-2" :class="!column.optional && column.parent != null ? 'dashed-border': ''" :data-bs-toggle="!column.optional && column.parent != null ? 'tooltip' : ''"
                                        title="Optional field">

                                            <accordion-select-with-list-header v-model="column.source_column" :list_header_text="'Custom Logic'"
                                                :options="src_column_array" @editing="editing(column)" @input="checkHasNullValueInSource(column, i)" @listHeaderAction="selectCustomLogic(column)"
                                                :class="{'is-invalid' : errors.columns[i].source != ''}" v-if="column.static_text == null || column.static_text == false"></accordion-select-with-list-header>
                                            <input type="text" v-model="column.static_text_value" class="form-control" :class="{'is-invalid' : errors.columns[i].source != ''}" 
                                                @change="updateStaticText(column)" v-else :id="'text' + column.id">
                                            
                                            <button class="btn btn-secondary" v-if="(column.source_column == '' || column.source_column == null || column.static_text )" data-bs-toggle="tooltip" 
                                                :title="(column.static_text == null || column.static_text == false) ? 'Static Value' : 'Column Options'" @click="toggleText(column)" >
                                                <i class="fa fa-font" v-if="column.static_text == null || column.static_text == false"></i>
                                                <i class="fa-solid fa-list" v-else></i>
                                            </button>
                                            <button class="btn btn-secondary" v-if="column.source_column == 'CUSTOM' && !column.editing && (column.static_text == null || column.static_text == false)" data-bs-toggle="tooltip" title="Edit the Custom Logic" @click="editing(column)" ><i class="fa fa-edit" ></i></button>
                                            <button class="btn btn-secondary" v-if="column.source_column == 'CUSTOM' && column.editing && (column.static_text == null || column.static_text == false) " data-bs-toggle="tooltip" title="Close the Custom Logic Editor" @click.prevent="stopEditing(column)" ><i class="fa fa-close" ></i></button>
                                            <button class="btn btn-secondary" v-if="column.source_column != null && (column.static_text == null || column.static_text == false) && (column.source_column != 'CUSTOM' || !hasAnyLogicUnitError(column))" data-bs-toggle="tooltip" title="Preview"  @click="previewSourceColumn(column)"><i class="fa fa-eye" ></i></button>
                                        </div>
                                        <div class="text-danger invalid-feedback" v-if="errors.columns[i].source != ''">
                                            {{errors.columns[i].source}}
                                        </div>
                                    </td>

                                    <td class="mapping-grid_map_to" v-if="column.id!='custom_logic_temp_object'" >
                                        <div class="d-flex align-items-center">
                                            <small>Maps to</small>
                                        </div>
                                    </td>

                                    <td class="mapping-grid__destination" v-if="column.id!='custom_logic_temp_object'" >
                                        <div class="d-flex mb-2">
                                            <div class="mt-1">
                                                <img class="icon smaller-icon" src="/img/icons/dialexa-icons/toggle-on-circle-gray.svg" v-if="column.data_type == 'boolean'"
                                                    data-bs-toggle="tooltip" title="Data type: True / False">
                                                <img class="icon smaller-icon" src="/img/icons/dialexa-icons/calendar.svg" v-if="column.data_type == 'date'"
                                                    data-bs-toggle="tooltip" title="Data type: Date">
                                                <img class="icon smaller-icon" src="/img/icons/dialexa-icons/hashtag.svg" v-if="column.data_type == 'integer' || column.data_type == 'numeric'"
                                                    data-bs-toggle="tooltip" title="Data type: Number">
                                                <img class="icon smaller-icon" src="/img/icons/dialexa-icons/text.svg" v-if="column.data_type == 'string'"
                                                    data-bs-toggle="tooltip" title="Data type: Text">
                                                <img class="icon smaller-icon" src="/img/icons/dialexa-icons/clock.svg" v-if="column.data_type == 'timestamp'"
                                                    data-bs-toggle="tooltip" title="Data type: Date & Time">                                    
                                            </div>

                                            <input type="text" class="form-control" v-model="column.display_name" readonly v-if="!column.optional">


                                            <accordion-select-with-list-header v-else-if="column.destination_column == null || column.destination_column.indexOf('custom') == -1"  
                                            v-model="column.destination_column" :list_header_text="'Custom Column'"
                                            :options="getCurrentUnusedColumns(i, column)" @editing="editing(column)" @input="selectDestinationColumn(i, column)"
                                             @listHeaderAction="selectCustomColumn(column)"
                                             :class="{'is-invalid' : errors.columns[i].avid != ''}" ></accordion-select-with-list-header>

                                            <div class="input-group" v-else >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :id="'dropdown-'+ i"
                                                    :class="{'is-invalid' : errors.columns[i].avid.indexOf('dropdown') != -1}" ref="dropdown"
                                                    @click="editing(column)">{{( column.data_type == null ? "Data Type" : getPrettyDataType(column.data_type)) }}</button>
                
                                                <ul class="dropdown-menu dropdown-menu-start" >
                                                    <li><a class="dropdown-item" href="#"  v-on:click.prevent="setDataType(i, 'date')">Date</a></li>
                                                    <li><a class="dropdown-item" href="#"  v-on:click.prevent="setDataType(i, 'timestamp')">Date & Time</a></li>
                                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="setDataType(i, 'integer')">Integer</a></li>
                                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="setDataType(i, 'numeric')">Decimal</a></li>
                                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="setDataType(i, 'string')">Text</a></li>
                                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="setDataType(i, 'boolean')">True / False</a></li>
                
                                                </ul>
                                                <input type="text" class="form-control" placeholder="Column Name" v-model="column.display_name" :class="{'is-invalid' : errors.columns[i].avid.indexOf('name') != -1}"
                                                    @change="columnExists(i, column.display_name)">
                                            </div>
                                        </div>
                                        <div class="text-danger invalid-feedback" v-if="errors.columns[i].avid != ''">
                                            {{errors.columns[i].avid }}
                                        </div>
                                    </td>

                                    <td class="mapping-grid__action" v-if="column.id!='custom_logic_temp_object'" >
                                        <div>
                                            <button v-if="column.optional && column.parent == null" class="mapping-grid__button btn btn-none p-0" v-on:click="removeColumn(i);">
                                                <img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg">
                                            </button>
                                            
                                            <button v-if="column.parent && column.class_names.includes('middle')" class="mapping-grid__button btn btn-none p-0" v-on:click="removeColumn(i);">
                                                <img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg">
                                            </button> 
                                        </div>
                                        <div data-bs-toggle="tooltip" title="This column mapping is required and cannot be removed." >
                                            <button v-if="!column.optional && column.parent == null" class="mapping-grid__button btn btn-none p-0 " disabled >
                                                <img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg" >
                                                <div class="strike-line"></div>
                                            </button>
                                        </div>
                                    </td>

                                    <transition name="slide">
                                        <tr class="custom-logic mb-4" v-if="column.source_column == 'CUSTOM' && (column.static_text == null || column.static_text == false) && 
                                            (column.editing || errors.columns[i].source.indexOf('logic') > -1)">
                                            <hr class="divider w-100 mx-3">
                                            <div class="simplified-query-builder w-100 mx-3">
                                                <h5 class="mx-2 mb-2"><i class="fa-solid fa-sliders me-2"></i>Custom Logic</h5>

                                                <div class="accordion" :id="'accordion-' + column.id" ref="accordion" >
                                                    <draggable handle=".handle" :list="column.column_logic.units" :element="'div'" :tag="'span'"  @end="onDragEnd">
                                                        <div class="accordion-item border-bottom-0" v-for="(unit, j) in column.column_logic.units">
                                                            <h2 class="accordion-header position-relative" :id="'heading-'+column.id+'-'+j">
                                                            <div class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+column.id+'-'+j" :aria-expanded="(j==0 && errors.columns[i].source.indexOf('logic') == -1)?true:false"
                                                            :class="{'collapsed' : (j > 0 || errors.columns[i].source.indexOf('logic') > -1)}">
                                                                Output:  
                                                                <em class="ms-1" v-if="column.data_type =='boolean' && (unit.value === true || unit.value === false)">
                                                                    {{ (unit.value === true) ? "Yes": "No" }}
                                                                </em>
                                                                <em class="ms-1" v-else>
                                                                    <span v-html="(unit.value_type == 'static' && unit.value != '') ? '&ldquo;' : ''"></span>
                                                                    {{ (unit.value == '') ? "(Not Set)" : getPrettyColName(unit.value) }}
                                                                    <span v-html="(unit.value_type == 'static' && unit.value != '') ? '&rdquo;' : ''"></span>
                                                                </em>
                                                                <a class="btn btn-none p-2 action" style="right: 48px;" v-on:click.stop="addLogicUnit($event, column, j)"  data-bs-toggle="tooltip" data-placement="top"  title="Add Logic Unit" ><i class="fa-solid fa-plus"></i></a>
                                                                <a class="btn btn-none p-2 action" style="right: 84px;" v-on:click.stop="removeLogicUnit($event, column, j)"  data-bs-toggle="tooltip" data-placement="top"  title="Delete" ><i class="fa-solid fa-trash"></i></a>
                                                                <a class="btn btn-none p-2 action handle" style="right: 118px; cursor: move;"  data-bs-toggle="tooltip" data-placement="top"  title="Move to Adjust Priority" v-if="column.column_logic.units.length > 1" ><i class="fa-solid fa-up-down-left-right"></i></a>
                                                            </div>
                                                            </h2>
                                                            <div :id='"collapse-"+column.id+"-"+j' class="accordion-collapse collapse" :class="{'show' : (j==0 && errors.columns[i].source.indexOf('logic') == -1) }" :data-bs-parent="'#accordion-' + column.id">
                                                            <div class="accordion-body pt-0">
                                                                <div class="row mb-3">
                                                                    <div class="col">
                                                                        <query-builder-simplified :client="client" v-model="unit.logic"
                                                                            :static_db_columns="src_columns" :is_white="false" :ref="'simple-'+i+'-'+j"
                                                                            :source="source" :data_source_id="data_source_id"></query-builder-simplified>
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex flex-row align-items-center mt-3 ">
                                                                    <div class="col-4">Then, output value will be: </div>
                                                                    <div class="col-8">
                                                                        <div class="input-group" >
                                                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ getValueTypeDisplay(unit.value_type) }}</button>
                                                                            <ul class="dropdown-menu dropdown-menu-start">
                                                                                <li><a class="dropdown-item" v-on:click.prevent="unit.value_type='column'">Column Value</a></li>
                                                                                <li><a class="dropdown-item" v-on:click.prevent="unit.value_type='sql'">SQL Value</a></li>
                                                                                <li><a class="dropdown-item" v-on:click.prevent="unit.value_type='static'">Static Value</a></li>
                                                                            </ul>

                                                                            <select v-if="column.data_type == 'boolean' && unit.value_type != 'column'" class="form-control" placeholder="Value"
                                                                                v-model="unit.value" :id="'unit-text-'+unit.id" @change="errors.columns[i].logic[j]=''">
                                                                                <option :value="true">Yes</option>
                                                                                <option :value="false">No</option>
                                                                            </select>
                                                                
                                                                            <input v-else-if="unit.value_type != 'column'" type="text"
                                                                                class="form-control" placeholder="Value" @change="sanitizeSQLStatement(i, j, unit)" :class="{'is-invalid' : errors.columns[i].logic[j] != null && errors.columns[i].logic[j] != ''}"
                                                                                v-model="unit.value" :id="'unit-text-'+unit.id" >
                                    
                                                                            <accordion-select v-else v-model="unit.value" :options="preview_column_array" @input="errors.columns[i].logic[j] = ''"
                                                                            :class="{'is-invalid' : errors.columns[i].logic[j] != null && errors.columns[i].logic[j] != ''}"></accordion-select>
                        
                                                                            <button v-if="unit.value_type=='sql'" class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Columns</button>
                                                                            <ul v-if="unit.value_type=='sql'" class="dropdown-menu dropdown-menu-end">
                                                                                <li><a class="dropdown-item" v-for="(col_name, key) in src_columns_sorted" @click="addColumnToText(unit.id, key)">{{col_name}}</a></li>
                                                                            </ul>


                                                                        </div>
                                                                        <div class="text-danger invalid-feedback" v-if="errors.columns[i].logic[j] != null && errors.columns[i].logic[j] != ''">
                                                                            {{errors.columns[i].logic[j]}}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </draggable>
                                        
                                                    <div class="accordion-item" style="border-top-left-radius: 0; border-top-right-radius: 0;" >
                                                        <h2 class="accordion-header" :id="'default-'+column.id">
                                                            <div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+column.id+'-default'" aria-expanded="false"  style="border-top-left-radius: 0; border-top-right-radius: 0;">
                                                                Default Output: 
                                                                <em class="ms-1" v-if="column.data_type =='boolean' && (column.column_logic.default_value === true || column.column_logic.default_value === false)">
                                                                    {{ (column.column_logic.default_value === true) ? "Yes": "No" }}
                                                                </em>
                                                                <em class="ms-1" v-else-if="column.column_logic.default_type=='column'">
                                                                    {{ (column.column_logic.default_value == '') ? "(Not Set)" : column.column_logic.default_value || propercase }}
                                                                </em>
                                                                <em class="ms-1" v-else>
                                                                    <span v-html="(column.column_logic.default_type == 'static'  && column.column_logic.default_value != '') ? '&ldquo;' : ''"></span>
                                                                    {{ (column.column_logic.default_value == '') ? "(Not Set)" : column.column_logic.default_value }}
                                                                    <span v-html="(column.column_logic.default_type == 'static' && column.column_logic.default_value != '') ? '&rdquo;' : ''"></span>
                                                                </em>
                                                                <a class="btn btn-none p-2 action" style="right: 48px;" v-on:click.stop="addLogicUnit($event, column, column.column_logic.units.length)"  data-bs-toggle="tooltip" data-placement="top"  title="Add Logic Unit" ><i class="fa-solid fa-plus"></i></a>
                                                            </div>
                                                        </h2>

                                                        <div :id="'collapse-'+column.id+'-default'" class="accordion-collapse collapse"  :data-bs-parent="'#accordion-'+ column.id">
                                                            <div class="accordion-body pt-0">
                                                                <div class="flex-row d-flex">
                                                                    <span class="col-6">When none of cases above are met, output value will be: </span>
                                                                    <div class="col-6">
                                                                        <div class="input-group" >
                                                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ getValueTypeDisplay(column.column_logic.default_type)}}</button>
                                                                            <ul class="dropdown-menu dropdown-menu-start">
                                                                                <li><a class="dropdown-item" v-on:click.prevent="column.column_logic.default_type='column'">Column Value</a></li>
                                                                                <li><a class="dropdown-item" v-on:click.prevent="column.column_logic.default_type='sql'">SQL Value</a></li>
                                                                                <li><a class="dropdown-item" v-on:click.prevent="column.column_logic.default_type='static'">Static Value</a></li>
                                                                            </ul>
                                                                            <select v-if="column.data_type == 'boolean' && column.column_logic.default_type != 'column'" class="form-control" placeholder="Value"
                                                                                v-model="column.column_logic.default_value" :id="'unit-text--default-'+column.id"  @change="column.column_logic.default_value_error=''" >
                                                                                <option :value="true">Yes</option>
                                                                                <option :value="false">No</option>
                                                                            </select>
                                                            
                                                                            <input v-else-if="column.column_logic.default_type != 'column'" type="text"
                                                                                class="form-control" placeholder="Value"
                                                                                v-model="column.column_logic.default_value" id='unit-text-default'>
                            

                                                                            <accordion-select v-else  v-model="column.column_logic.default_value" :options="preview_column_array"></accordion-select>


                                                                            <button v-if="column.column_logic.default_type=='sql'" class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Columns</button>
                                                                            <ul v-if="column.column_logic.default_type=='sql'" class="dropdown-menu dropdown-menu-end">
                                                                                <li><a class="dropdown-item" v-for="(col_name, key) in src_columns" @click="addColumnToText('default', key)">{{col_name}}</a></li>
                                                                            </ul>


                                                                        </div>
                                                                        <div class="text-danger invalid-feedback" v-if="column.column_logic.default_value_error != ''">
                                                                            {{column.column_logic.default_value_error }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    </transition>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-xl-4 d-none d-xl-block drill-down">
                        <div :class="{ 'sticky-top': is_sticky }" ref="stickyDiv">
                            <div class="card mb-2" v-for="index in depth" :key="index">
                                <div class="card-body px-0 pb-0" :class="{'pt-0': depth != index}">
                                    <div class="row" v-if="depth == index">
                                        <div class="col mx-3 mb-3">
                                            <label class="form-label ">Column to Preview</label>
            
                                            <accordion-select v-model="preview_table_val[index-1]" :options="preview_column_array" @input="updatePreviewTable(preview_table_val[index-1])"></accordion-select>
                                        </div>
                                    </div>
                                    <p v-if="preview_table_val[index-1] != null && depth == 1" class="mx-3 mb-2">The table below represents the number of records for each of the top 10 values for the given column. Drill deeper into the data or view a preview using the icons below.</p>
                                    <div class="table-responsive mx-2" style="max-height: 300px;" >
                                        <!-- <div class="overflow-hidden rounded-top" v-if="depth != index"><div class="p-1 bg-dark"></div></div> -->
                                        <table class="mapping-table preview-mapping-table gy-0 mx-0 my-2" v-if="preview_table_val[index-1] != null">
                                            <thead>
                                                <tr>
                                                    <th class='ps-3' :class="{'pt-0': depth != index}"><p>{{mirrorAccordionSelectStyle(preview_table_val[index-1]) | propercase}} </p></th>
                                                    <th :class="{'pt-0': depth != index}"><p>Records</p></th>
                                                    <th :class="{'pt-0': depth != index}" style="width:70px;"><p>Actions</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="preview_table[index-1] == null">
                                                    <td colspan="3" class="text-center"><div class="spinner-border  spinner-border-sm float-left" role="status"></div> Loading</td></tr>
                                                <tr v-else-if="preview_table[index-1].length == 0">
                                                    <td colspan="3" class="text-center"><strong><em>No records were found for the given column.</em></strong></td></tr>
            
                                                <tr v-for="(f, row_num) in preview_table[index-1]" :key="f.name" v-if="preview_table[index-1] != null && (drill_index == null || drill_index == row_num || index > 1)" >
                                                    <td class='ps-3 align-middle ' v-if="f.name != null && f.name != ''">
                                                        {{f.name}}
                                                    </td>
                                                    <td class='ps-3 align-middle ' v-else>
                                                        <em>null</em>
                                                    </td>
                                                    <td class="align-middle ">{{ f.count | number_with_zero }}</td>
                                                    <td class="text-nowrap" >
                                                    <button type="button" class="btn btn-sm btn-none p-1" v-if="depth == index" @click="previewRecords(f.name)">
                                                            <i class="fa fa-eye"  v-if="preview_detail_loading != f.name || (f.name == '')" data-bs-toggle="tooltip" data-placement="top"  title="Preview" ></i>
                                                            <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                                        </button>
                                                    <button type="button" class="btn btn-sm btn-none p-1" v-if="depth == 1" @click="drilldown(row_num, f.name)" >
                                                            <i class="fa-solid fa-arrow-down" data-bs-toggle="tooltip" data-placement="top"  title="Drill Down"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-none p-1" v-if="depth == 2 && index == 1" @click="removeDrilldown()">
                                                            <i class="fas fa-close" data-bs-toggle="tooltip" data-placement="top" title="Remove Drill Down"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-secondary" v-on:click="addColumn()" ><i class="fas fa-plus"></i> Add Column</button>
                <button class="btn btn-secondary ms-3" v-on:click="addAllColumns()" ><i class="fa-solid fa-table-columns"></i> Add All Source Columns</button>
            </div>
        </div>

        <div class="modal fade modal" :id="'column-mapping-preview-' +data_source_id"
            aria-labelledby="column-mapping-preview" aria-hidden="true">
            <div class="modal-dialog" >
                <div class="modal-content" v-if="preview_detail_table.length > 0">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none position-absolute" style="top: 10px; right: 10px;" data-bs-dismiss="modal" v-on:click="preview_detail_index=0" >
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2 mb-1">Preview</h4>
                            <h5>
                                <button class="btn btn-none" v-if="preview_detail_index > 0 && preview_detail_table.length > 1" v-on:click="preview_detail_index--"><i class="fa-solid fa-chevron-left"></i></button>
                                {{preview_detail_index+1}} of {{preview_detail_table.length}} samples
                                <button class="btn btn-none" v-if="preview_detail_index < preview_detail_table.length - 1" v-on:click="preview_detail_index++"><i class="fa-solid fa-chevron-right"></i></button>
                            </h5>
                        </div>
                    </div>
                    <div class="modal-body p-0">
                        <table class="table table-striped mb-0">
                            <thead class="table-primary">
                                <tr>
                                    <th class="ps-3">Column Name</th>
                                    <th>Column Value</th>
                                </tr>
                            </thead>
                            <tbody class="table-striped">
                                <tr v-for="(value, index) in Object.values(preview_detail_table[preview_detail_index])">
                                    <td class="ps-3">{{getPrettyColNameByIndex(index)}}</td>
                                    <td>{{value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer justify-content-start border-top-0">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="preview_detail_index=0" >Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
        import draggable from 'vuedraggable';
        import Swal from 'sweetalert2';

        export default {
            components: {
                draggable,
                Swal
            },
            props: {
                client: {
                  type: Object,
                },
                value: {
                  type: Array,
                },
                dest_columns: {
                    default: () => []
                },
                dataset: { type: Object, },
                source: {default: 'file' },
                data_source_id: {default: null },
            },
            data() {
                return {
                    mapping: [],
                    depth: 1,
                    drill_index: null,
                    preview_table_val: [],
                    preview_table_logic: [],
                    preview_table: [],
                    preview_detail_table: [],
                    preview_detail_index: 0,
                    preview_detail_loading: "",
                    src_columns:null,
                    src_column_array: null,
                    preview_column_array: null, // preview_column_array is basically src_column_array without custom logic option at the end
                    local_dest_columns: [],
                    flat_dest_columns:[],
                    unused_dest_columns:[],
                    errors: {
                        columns: []
                    },
                    // if_boolean:{
                    //     columns: []
                    // },
                    is_sticky: false
                };
            },
            beforeMount() {
                this.local_dest_columns = this.dest_columns;
                this.setupScreen();
    
            },
            created () {
                window.addEventListener('scroll', this.handleScroll);
            },
            unmounted () {
                window.removeEventListener('scroll', this.handleScroll);
            },
            computed: {
                src_columns_sorted() {
                    var keys = Object.keys(this.src_columns);
                    keys.sort();
                    var obj = {};
                    for(var i in keys) {
                        //Split the this.src_columns[keys[i]] string based upon . and then rejoin with a :
                        let col_name = this.src_columns[keys[i]];
                        if(keys[i].indexOf(".") != -1) {
                            var split = keys[i].split(".", 2);
                            col_name = this.$options.filters.propercase(split[0].trim()) + ": " + this.$options.filters.propercase(split[1].trim());
                        }
                        obj[keys[i]] = col_name;
                    }
                    return obj;
                }
            },
            mounted() {
                //Ensure the tooltips show up
                const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
                const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    
                for (var i = 0; i < tooltipTriggerList.length; i++) {
                  (function(index) {
                    tooltipTriggerList[index].addEventListener('click', function() {
                      // Hide the tooltip using the index passed to the closure
                      tooltipList[index].hide();
                    });
                    tooltipTriggerList[index].addEventListener('mouseleave', function() {
                      // Hide the tooltip using the index passed to the closure
                      tooltipList[index].hide();
                    });
                  })(i);
                }
    
                const innerButtons = document.querySelectorAll('.action');
    
                innerButtons.forEach(button => {
                    button.addEventListener('mouseenter', (e) => {
                          let accordionButton = button.parentElement;
                          accordionButton.setAttribute('data-bs-toggle', '');
                    });
    
                    button.addEventListener('mouseleave', (e) => {
                      let accordionButton = button.parentElement;
                      accordionButton.setAttribute('data-bs-toggle', 'collapse');
                    });
                });
            },
            watch: {
                data_source_id() {
                    this.setupScreen();
                },
                dest_columns(new_columns){
                    this.getUnusedDestinationColumns();
                },
                mapping: {
                    handler(n, o) {
                        this.$emit('input', this.mapping);
                    },
                    deep: true
                },
            },
            methods: {
                setupScreen(){
                    //Set the default value for the src columns and the filter
                    this.src_columns = this.dataset.source_columns;
                    this.src_column_array = this.getSourceColumnArray();
                    this.preview_column_array = this.src_column_array.slice(1); // remove the custom logic option from preview option list
        
                    //Set the destination columns into a flat array
                    for(var i = 0; i < this.local_dest_columns.length; i++) {
                        if(this.local_dest_columns[i].type == 'array') {
                            for(var j = 0; j < this.local_dest_columns[i].columns.length; j++) {
                                this.flat_dest_columns.push(this.local_dest_columns[i].columns[j]);
                                this.flat_dest_columns[this.flat_dest_columns.length-1].parent_index = i
                            }
                        }
                        else
                            this.flat_dest_columns.push( this.local_dest_columns[i]);
                    }
        
        
                    //Set the default columns or whatever was sent to me
                    if(this.value != null) {
                        this.mapping = this.setColumnDetails(this.value);
                        this.mapping.forEach(m => {
                            m.editing = false;
                            //If it is the static value, set those values too
                            if(m.source_column == 'CUSTOM' && m.column_logic.units.length == 0 && m.column_logic.default_type == 'static') {
                                m.static_text = true;
                                m.static_text_value = m.column_logic.default_value;
                            }
                        });

                        //get the default mapping too and make sure it is in the provided mapping
                        var default_mapping = this.getDefaultMapping();
                        for(var i = 0; i < default_mapping.length; i++) {
                            var found = false;
                            for(var j = 0; j < this.mapping.length; j++) {
                                if(this.mapping[j].destination_column == default_mapping[i].destination_column) {
                                    found = true;
                                    break;
                                }
                            }
                            if(!found) {
                                //Add it to the top of the array
                                this.mapping.unshift(default_mapping[i]);
                            }
                        }

                    }
                    else
                        this.mapping = this.getDefaultMapping();
        
                    this.getUnusedDestinationColumns();
                    
                    //For the error message, set the default value to false.
                    for(var i = 0; i < this.mapping.length; i++) {
                        //Need to establish this before I set errors in the case of removed columns (logic below)
                        this.errors.columns.push({
                            source: "",
                            avid: "",
                            logic: []
                        });
        
                        //Determine if the source columns mapped are in the latest data upload (stored in the src_columns variable)
                        if( this.mapping[i].source_column == "CUSTOM" ) {
                            //Save placeholders for the error messages
                            this.errors.columns[this.errors.columns.length-1].logic = new Array(this.mapping[i].column_logic.units.length);
        
                            //This function should show the right error if there is one
                            var no_errors = this.isUnitValid(i, this.mapping[i].column_logic.units);
                            //If it is a column that was removed...
                            if(no_errors && this.mapping[i].default_type == 'column' && this.src_columns[this.default_value] == null)
                                this.errors.columns[this.errors.columns.length-1].source = 'A column used in the logic below has been removed from the latest data refresh.';
                        }
                        else if(this.mapping[i].source_column != null && this.mapping[i].source_column != ""  && this.src_columns[this.mapping[i].source_column] == null )
                            this.errors.columns[this.errors.columns.length-1].source = "The column that was mapped has been removed from the latest data refresh. Please update this field.";
                    }
                },
                mirrorAccordionSelectStyle(val) {
                    //If it contains a ., then replace it with a : and a space and then propercase all the words
                    if(val.indexOf(".") != -1) {
                        var split = val.split(".", 2);
                        return this.$options.filters.propercase(split[0].trim()) + ": " + this.$options.filters.propercase(split[1].trim());
                    }
                    return val;
                },
                getSourceColumnArray() {
                    var columns = [];
                    //Sort the this.src_columns
                    var keys = Object.keys(this.src_columns);
                    //sort keys but, if it has a period in the name, it should be lower than works without a period
                    keys.sort(function(a, b) {
                        if(a.indexOf(".") == -1 && b.indexOf(".") != -1)
                            return -1;
                        else if(a.indexOf(".") != -1 && b.indexOf(".") == -1)
                            return 1;
                        else
                            return a.localeCompare(b);
                    });
    
                    //Add the custom option to the start
                    columns.push({
                        value: 'CUSTOM',
                        text: 'Custom Logic',
                        category: 'list-header'
                    });
                    
                    for(var i in keys) {
                        let key = keys[i];
                        let text = this.src_columns[key];
                        let category = null;
    
                        //If the key contains a .
                        if(key.indexOf(".") != -1) {
                            //split string by . , separate any structs that are 3+ layers deep with a > in between
                            var split = key.split(".");
                            category = this.$options.filters.propercase(split[0]);
                            text = this.$options.filters.propercase(split.slice(1).join(' > '));
                        }
    
                        columns.push({
                            value: key,
                            text: text,
                            category: category
                        });
                    }

                    return columns;
                },
                handleScroll() {
                    if(this.$refs.stickyDiv == null) return;
    
                    const divTop = this.$refs.stickyDiv.getBoundingClientRect().top;
                    this.is_sticky = divTop <= 0;
                },
                getMappingClass(err, column) {
                    var c = '';
                    if(column.class_names != null)
                        c = column.class_names;
    
                    if(err.source != '' || err.avid != '')
                        c += " is-invalid";
    
                    return c;
                },
                onDragEnd(e) {
                    //If they moved it...
                    if(e.newIndex != e.oldIndex) {
                        let show_pos = -1;
    
                        //Get the ide of the currently open accordion item
                        var open_item =document.querySelector('.accordion-collapse.show');
                        if(open_item == null) return;
    
                        var open = document.querySelector('.accordion-collapse.show')
                        var open_pos = open.id.substring(open.id.indexOf('-')+1);
                        var new_index = open_pos.substring(open_pos.indexOf('-')+1);
    
                        //If the open one was the one that was moved
    
                        if(parseInt(new_index) == e.oldIndex) {
                            show_pos = open_pos.substring(0, open_pos.indexOf('-')+1) + e.newIndex;
                        }
    
                        //If it needs to be toggled
                        else if(parseInt(new_index) >= e.newIndex &&  parseInt(new_index) <= e.oldIndex || parseInt(new_index) <= e.newIndex &&  parseInt(new_index) >= e.oldIndex) {
                            show_pos = parseInt(new_index) + ((e.newIndex > e.oldIndex) ? -1 : 1);
                            show_pos = open_pos.substring(0, open_pos.indexOf('-')+1) + show_pos;
                        }
    
                        this.$nextTick(() => {
                            //Hide the open one
                            if (document.getElementById('collapse-' + open_pos)){
                                document.getElementById('collapse-' + open_pos).classList.remove('show');
                                document.querySelector('#heading-' + open_pos + ' .accordion-button').classList.add('collapsed');                                
                            }
                            //Show it without the annimations
                            if (document.getElementById('collapse-' + show_pos)){
                                document.getElementById('collapse-' + show_pos).classList.add('show');
                                document.querySelector('#heading-' + show_pos + ' .accordion-button').classList.remove('collapsed');
                            }
                        });
                    }
                },
                getValueTypeDisplay(type) {
                    if(type == 'sql')
                        return 'SQL Value';
                    if(type == 'column')
                        return 'Column Value';
    
                    return 'Static Value';
                },
                getPrettyDataType(type) {
                    if(type == 'boolean')
                        return 'True / False';
                    if(type == 'date')
                        return 'Date';
                    if(type == 'timestamp')
                        return 'Date & Time';
                    if(type == 'integer')
                        return 'Integer';
                    if(type == 'numeric')
                        return 'Decimal';
                    if(type == 'string')
                        return 'Text';
    
                    return "Unknown";
                },
                getPrettyColNameByIndex(index) {
                    var name = Object.keys(this.preview_detail_table[this.preview_detail_index])[index];
                    return this.getPrettyColName(name);
                },
                getPrettyColName(name) {
    
                    var cols = Object.keys(this.src_columns);
                    for(var i = 0; i < cols.length; i++) {
                        if(cols[i] == name){
                            let value = Object.values(this.src_columns)[i];
                            if(value.indexOf(".") != -1) {
                                let split = value.split(".", 2);
                                return this.$options.filters.propercase(split[0].trim()) + ": " + this.$options.filters.propercase(split[1].trim());
                            }
                            return value;
                        }
                    }
    
                    return name;
                },
                setColumnDetails(val) {
                    //Copy the map array as a deep copy
                    var map = JSON.parse(JSON.stringify(val));
                    var parent = null;
                    var index = 0;

                    //Determine if it is missing adjointing columns for email, phone, or address.
                    map = this.removeDupeColumns(map);
                    map = this.addMissingColumns(map);
    
                    for(var i = 0; i < map.length; i++) {
                        var col = this.flat_dest_columns.find(c => c.column == map[i].destination_column);
                        if(col != null){
                            map[i].optional = col.optional;
                            //The autogenerated columns will have null for this so we need to give it a default value
                            if(map[i].column_logic == null)
                                map[i].column_logic = {
                                    units: [this.getUnit()],
                                    default_value: '',
                                    default_type: 'static',
                                    default_value_error: ''
                                }
                            //if it is part of an array
                            if(col.parent_index != null){
    
                                //The first element should be optional, all of the others aren't
                                // (This is because "optional" means they aren't required either in this case. It is mainly to achieve the visual I needed)
                                if(index != 0 && index != cols.length)
                                    map[i].optional = false;
    
                                var cols = this.local_dest_columns[col.parent_index].columns;
                                var middle_index = this.findMiddleIndex(cols);
    
                                map[i].parent = this.local_dest_columns[col.parent_index].column;
                                map[i].class_names = 'is-'+this.local_dest_columns[col.parent_index].column;
                                //If it doesn't have a parent, the parent type is different, or it has counted
                                // the total number of columns in the set. (This handles when there are multiple
                                // emails or phones together)
                                if(parent == null || parent != map[i].parent || index == cols.length) {
                                    map[i].class_names += " first";
                                    //If I was in an array in the last iteration...
                                    if(parent != null && parent != map[i].parent|| index == cols.length) {
                                        map[i-1].class_names += " last";
                                        index = 0;
                                    }
    
                                    parent = map[i].parent;
                                }
    
                                else if(index == middle_index)
                                    map[i].class_names += " middle";
    
                                index++;
    
                            }
                            //If I'm not in an array but I was previously
                            else if (parent != null) {
                                parent = null;
                                map[i-1].class_names += " last";
                                index = 0;
                            }
                        }
    
                        else {
                            map[i].optional = true;
                            //If it is a custom defined column and it was in an array
                            if (parent != null) {
                                parent = null;
                                map[i-1].class_names += " last";
                                index = 0;
                            }
                        }
                    }
                    //Make sure I didn't end in an array
                    if (parent != null) {
                        map[map.length-1].class_names += " last";
                    }
                    return map;
                },
                removeDupeColumns(map) {
                    //Some of the old data may have resulted in duplicate columns from an array existing. Need to remove them on load
                    for(var i = 0; i < map.length; i++) {
                        var col = this.flat_dest_columns.find(c => c.column == map[i].destination_column);
                        //This only applies to columns that are part of an array
                        if(col != null && col.parent_index != null){
                            var cols = this.local_dest_columns[col.parent_index].columns;
                            //All of the cols need to be in the next cols.length-1 positions in map
                            for(var j = 0; j < cols.length; j++) {
                                let existing_column = [];
                                for(var k = i; k < Math.min(i+cols.length, map.length); k++) {
                                    if(map[k].destination_column == cols[j].column) {
                                        if(existing_column.includes(map[k].destination_column)){
                                            map.splice(k, 1);
                                            k--;
                                        }
                                        else
                                            existing_column.push(map[k].destination_column);
                                        break;
                                    }
                                }
                            }
                            i += cols.length-1;
                        }
                    }
                    
                    return map;
                },
                addMissingColumns(map) {
                    //Determine if it is missing adjointing columns for email, phone, or address.
                    for(var i = 0; i < map.length; i++) {
                        var col = this.flat_dest_columns.find(c => c.column == map[i].destination_column);
                        if(col != null && col.parent_index != null){
                            let added = 0;
                            var cols = this.local_dest_columns[col.parent_index].columns;
                            //All of the cols need to be in the next cols.length-1 positions in map
                            for(var j = 0; j < cols.length; j++) {
                                var found = false;
                                for(var k = i; k < Math.min(i+cols.length, map.length); k++) {
                                    if(map[k].destination_column == cols[j].column) {
                                        found = true;
                                        break;
                                    }
                                }
                                if(!found) {
                                    var new_map = this.getMap(cols[j]);
                                    map.splice(i+1, 0, new_map);
                                    added++;
                                }
                            }

                            if(added > 0) {
                                //Check those columns to make sure at least one of them has a value
                                var has_value = false;
                                for(var j = i; j < i+cols.length; j++) {
                                    if(map[j].source_column != null && map[j].source_column != '') {
                                        has_value = true;
                                        break;
                                    }
                                }
                                //If they are all empty, need to remove the array
                                if(!has_value) {
                                    map.splice(i, cols.length);
                                    i--;
                                }
                                else
                                    i += cols.length-1;
                            }
                            else i += cols.length-1;
                           
                        }
                    }
                    
                    return map;
                },
                getUnusedDestinationColumns(){
                    var unused_dest_columns = [...this.flat_dest_columns];
                    this.mapping.forEach((mapping) => {
                        var index = unused_dest_columns.findIndex((col) => mapping.destination_column == col.column && (col.parent_index == null || col.parent_index == undefined));
                        if (index >=0){
                            unused_dest_columns.splice(index, 1);
                        }
                    });
                    unused_dest_columns.forEach((column) => {
                        column.category = null;
                        column.text = column.name;
                        column.value = column.column;
                    });

                    this.unused_dest_columns = unused_dest_columns;
                    this.$forceUpdate();
                },
                getCurrentUnusedColumns(i, column){
                    // add current selected options to the beginning of the option list
                    var current_column_index = this.flat_dest_columns.findIndex(col => col.column == column.destination_column);
                    var current_unused_dest_columns = [...this.unused_dest_columns];
                    if(current_column_index >=0){
                        var current_column_obj = this.flat_dest_columns[current_column_index];
                        current_column_obj.category = null;
                        current_column_obj.text = current_column_obj.name;
                        current_column_obj.value = current_column_obj.column;
                       current_unused_dest_columns.unshift(current_column_obj);
                    }
                    return current_unused_dest_columns;
                },
                getDefaultMapping() {
                    var arr = [];
                    for(var i = 0; i < this.local_dest_columns.length; i++) {
                        if(!this.local_dest_columns[i].optional)
                            arr.push(this.getMap(this.local_dest_columns[i]));
                    }
                    return arr;
                },
                getMap(col=null) {
                    return {
                        id: -1*Math.round(Math.random()*100000),
                        client_id: this.client.id,
                        data_set_id: (this.source == "dataset" ? this.data_set_id : null),
                        destination_column: (col == null) ? null : col.column,
                        source_column: null,  //TODO: Set some default values
                        display_name: (col == null) ? null : col.name,
                        data_type: (col == null) ? null : col.type,
                        optional: (col == null) ? true : col.optional,
                        column_logic: {
                            units: [this.getUnit()],
                            default_value: '',
                            default_type: 'static',
                            default_value_error: ''
                        },
                        editing: false,
                        parent: (col != null && col.parent_index != null) ? this.local_dest_columns[col.parent_index].column : null
                    };
                },
                getUnit() {
                    return {
                        id: Math.round(Math.random()*100000),
                        value_type: 'static',
                        value: '',
                        logic: {
                            filter_logic: '1',
                            filter_option: 'custom',
                            filter_ui: 'simple',
                            logic_error_msg:null,
                            logic_units:[]
                        }
                    };
                },
                addColumnToText(id, valueToAdd) {
                    var textField = document.getElementById('unit-text-' + id );
    
                    // Get the cursor's position
                    const startPos = textField.selectionStart;
                    const endPos = textField.selectionEnd;
    
                    // Get the current value of the text field
                    const currentValue = textField.value;
    
                    // Construct the new value with the added text
                    const newValue =
                    currentValue.substring(0, startPos) +
                    valueToAdd +
                    currentValue.substring(endPos);
    
                    // Set the new value to the text field
                    textField.value = newValue;
    
                    // Move the cursor after the added text
                    const newCursorPos = startPos + valueToAdd.length;
                    textField.setSelectionRange(newCursorPos, newCursorPos);
    
                    // Trigger any necessary events, e.g., input or change
                    const inputEvent = new Event('input');
                    textField.dispatchEvent(inputEvent);
                },
                toggleText(column) {
                    if(column.static_text == null)
                        column.static_text = true;
                    else column.static_text = !column.static_text;

                    //if it is showing the text field
                    if(column.static_text){
                        column.source_column = 'CUSTOM';
                        column.column_logic = {
                            units: [],
                            default_type: "static",
                            default_value: column.static_text_value
                        }
                    } 
                    
                    //if it is showing the dropdown options
                    else{
                        column.column_logic = {
                            units: [this.getUnit()],
                            default_type: "static",
                            default_value: ""
                        }
                        column.source_column = null;
                    }
                        
                    //Find any classes with "tooltip bs-tooltip-auto show" and remove the show class
                    const tooltips = document.querySelectorAll('.tooltip.bs-tooltip-auto.show');

                    // Loop through each tooltip element and remove the "show" class
                    tooltips.forEach(tooltip => {
                        tooltip.classList.remove('show');
                    });

                    //Focus on the input with ID 'text' + column.id
                    this.$nextTick(() => {
                        if(document.getElementById('text' + column.id))
                            document.getElementById('text' + column.id).focus();
                    });
                    this.$forceUpdate();
                },
                updateStaticText(column) {
                    column.column_logic.default_value = column.static_text_value;
                },
                editing(column) {
                    //Close all of the custom editors if they are open
                    this.mapping.forEach(m => {
                        m.editing = false;
                    });
    
                    column.editing = true;
                    this.$forceUpdate();
                },
                stopEditing(column) {
                    column.editing = false;
                    this.$forceUpdate();
                },
                selectCustomLogic(column){
                   column.source_column = "CUSTOM";
                },
                selectCustomColumn(column){
                    column.destination_column = "custom";
                },
                checkHasNullValueInSource(column, column_index){
                    this.errors.columns[column_index].source = '';
                    if(column.optional || column.parent == null || column.source_column == "CUSTOM" ){
                        if(column.column_logic == null){
                            column.column_logic = {
                                units: [this.getUnit()],
                                default_value: '',
                                default_type: 'static',
                                default_value_error: ''
                            }
                        }
                        return;
                    }else { // only check null value for required fields
                        var data = {
                            client: this.client,
                            source: this.source,
                            column: column.source_column,
                            logic: null, 
                            limit: 10,
                            data_source_id: this.data_source_id,
                            value: this.dataset.filter
                        };
                        window.axios.post('/api/bigquery/get_samples', data)
                        .then(response => {
                            var sample_data = response.data.samples;
                            if(sample_data.length == 1 && sample_data[0].name == null){
                                this.errors.columns[column_index].source = 'Required fields cannot contain only NULL values, please select a different source column.'
                            }
                        })
                    }
                },
                selectDestinationColumn(mapping_index, column) {
                    //Clear the errors
                    this.errors.columns[mapping_index].avid = '';
                    this.getUnusedDestinationColumns();
    
                    for(var i = 0; i < this.flat_dest_columns.length; i++) {
                        if(column.destination_column == this.flat_dest_columns[i].column) {
                            column.display_name = this.flat_dest_columns[i].name;
                            column.data_type = this.flat_dest_columns[i].type;

                            if(column.data_type == 'boolean'){
                                // this.if_boolean.columns[column.id] = true;
                                // this.errors.columns[mapping_index].logic[j]  = mapping_index
                                if (column.column_logic.default_type === 'static' &&
                                    !['true', 'false', true, false].includes(column.column_logic.default_value)
                                ) {
                                    column.column_logic.default_value_error = "The column you selected only allows a Yes or No value.";
                                } else {
                                    column.column_logic.default_value_error = '';
                                }
                                for (var j = 0; j<column.column_logic.units.length; j++){
                                    if (column.column_logic.units[j].value_type == 'static' && 
                                        !['true', 'false', true, false].includes(column.column_logic.units[j].value_type)
                                    ){
                                        this.errors.columns[mapping_index].logic[j] = "The column you selected only allows a Yes or No value.";
                                    } else {
                                        this.errors.columns[mapping_index].logic[j] = '';
                                    }
                                }
                            }
    
                            //If the column is an array and this is the first time it has been selected...
                            if(this.flat_dest_columns[i].parent_index != null && column.class_names == null) {
                                this.addColumnSiblings(column, i, mapping_index);
                            }
                            //If it is an array and was already selected as part of the existing object
                            else if(this.flat_dest_columns[i].parent_index != null && column.parent != null && column.parent == this.local_dest_columns[this.flat_dest_columns[i].parent_index].column) {
    
                                //Find the column that is the same as the one selects
                                for(var k = mapping_index+1; k < this.mapping.length; k++){
                                    if(this.mapping[k].destination_column == column.destination_column) {
                                        //Find the one that isn't in the array
                                        var cols = this.local_dest_columns[this.flat_dest_columns[i].parent_index].columns;
                                        var arr = [];
                                        //Make an array of the existing destination columns
                                        for(var h = mapping_index+1; h < this.mapping.length; h++){
                                            if(this.mapping[h].optional) //Once it is optional,
                                                break;
                                            arr.push(this.mapping[h].destination_column);
                                        }
    
                                        for(var h = 0; h < cols.length; h++){
                                            //If it isn't in the list of columns, it is the one to replace the old mapping with
                                            if(!arr.includes(cols[h].column)) {
                                                //That is the column that I need to replace
                                                this.mapping[k].destination_column = cols[h].column;
                                                this.mapping[k].display_name = cols[h].name;
                                                this.mapping[k].data_type = cols[h].type;
                                                this.mapping[k].source_column = null;
    
                                                //Reset any errors
                                                this.errors.columns[k] = {
                                                    source: "",
                                                    avid: "",
                                                    logic: []
                                                };
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
    
                            }
                            //If it is an array but it is a different type of object. Ex: was an address and now is an email
                            else if(this.flat_dest_columns[i].parent_index != null && column.parent != null && column.parent != this.local_dest_columns[this.flat_dest_columns[i].parent_index].column){
    
                                //Need to remove all of the old columns and then add the new columns
                                //First, find out how many to remove...
                                this.removeColumnSiblings(mapping_index);
    
                                //Now add the
                                this.addColumnSiblings(column, i, mapping_index);
    
                            }
                            else if(this.flat_dest_columns[i].parent_index == null && column.parent != null) {
                                //If the new column is not in an array but the previously selected one was
                                this.removeColumnSiblings(mapping_index);
                                column.parent = null;
                                column.class_names = null;
                            }
    
                            return;
                        }
                    }
                },
                removeColumnSiblings(mapping_index) {
                    let num = 0;
                    for(var i = mapping_index+1; i < this.mapping.length; i++){
                        if(!this.mapping[i].optional) //Once it is optional,
                            num++;
                        else
                            break;
                    }
                    //And remove them
                    this.mapping.splice(mapping_index+1, num);
                    this.errors.columns.splice(mapping_index+1, num);
                },
                addColumnSiblings(column, flat_dest_index, mapping_index) {
                    column.parent = this.local_dest_columns[this.flat_dest_columns[flat_dest_index].parent_index].column;
                    column.class_names = 'is-' + this.local_dest_columns[this.flat_dest_columns[flat_dest_index].parent_index].column;
    
                    //Increase the mapping index since everything has to be inserted one below that spot
                    mapping_index++;
                    //Now add all of the other columns
                    var add_middle = false;
                    var cols = this.local_dest_columns[this.flat_dest_columns[flat_dest_index].parent_index].columns;
                    let middle_index = this.findMiddleIndex(cols);
                    //Find the index of the column variable in the cols array
    
                    let column_index = 0;
                    for(var i = 0; i < cols.length; i++)
                        if(cols[i].column == column.destination_column){
                            column_index = i;
                            break;
                        }
    
    
                    //Loop through each column
                    for(var j = 0; j < cols.length; j++) {
                        //If the column is not already selected
                        if(cols[j].column != column.destination_column) {
                            //Add the column to the mapping
                            this.mapping.splice(mapping_index+j, 0, this.getMap(cols[j]));
                            this.mapping[mapping_index+j].class_names = column.class_names;
                            //How
    
                            //If it is in the middle, add a "middle" to the class
                            if(j == middle_index - ((column_index > middle_index) ? 1 : 0) || add_middle){
                                //If add_middle is true, I need to add the class to the element prior to this one
                                this.mapping[mapping_index+j + (add_middle ? -1 : 0)].class_names += " middle";
                                add_middle = false;
                            }
                            //if it is the last one, add last to it
                            if(j == cols.length-1){
                                this.mapping[mapping_index+j].class_names += " last";
                            }
    
                            this.mapping[mapping_index+j].display_name = cols[j].name;
                            this.mapping[mapping_index+j].data_type = cols[j].type;
                            this.mapping[mapping_index+j].optional = false;
    
                            this.errors.columns.splice(mapping_index+j, 0, {
                                source: "",
                                avid: "",
                                logic: []
                            });
    
                        }
                        //If the one they selected is the middle one in the array, add it to the next one
                        else if(j == middle_index) {
                            add_middle = true;
                            //Reduce mapping index to account for skipping a column
                            mapping_index--;
                        }
                        else if(j == cols.length-1) {
                            //Reduce mapping index to account for skipping a column
                            mapping_index--;
                            this.mapping[mapping_index+j].class_names += " last";
                        }
                        //Reduce mapping index to account for skipping a column
                        else mapping_index--;
                    }
                    column.class_names += " first";
                },
                findMiddleIndex(arr) {
                    //I
                    const length = arr.length;
                    if (length % 2 === 0) {
                        return Math.floor(length / 2);
                    } else {
                        return Math.floor(length / 2);
                    }
                },
                removeLogicUnit(event, column, i) {
                    column.column_logic.units.splice(i, 1);
                    event.stopPropagation();
                },
                addLogicUnit(event, column, i) {
                    var unit = this.getUnit();
                    //Insert the unit in the column_logic object at the i position
                    column.column_logic.units.splice(i+1, 0, unit );
    
                    this.$nextTick(() => {
                        //If there is one open, close it
                        if(document.querySelector('.accordion-collapse.show') != null) {
                            var open = document.querySelector('.accordion-collapse.show')
                            var open_pos = open.id.substring(open.id.indexOf('-')+1);
                            if(document.getElementById('collapse-' + open_pos) != null) {
                                document.getElementById('collapse-' + open_pos).classList.remove('show');
                            }
                            if(document.querySelector('#heading-' + open_pos + ' .accordion-button') != null) {
                                document.querySelector('#heading-' + open_pos + ' .accordion-button').classList.add('collapsed');
                            }
                        }
                        const accordionElement = document.getElementById('collapse-' + column.id+'-' + (i+1));
                        if (accordionElement) {
                            const bootstrapAccordion = new bootstrap.Collapse(accordionElement);
                            bootstrapAccordion.show();
                        }
                    });
                },
                addColumn() {
                    this.mapping.push(this.getMap());
                    this.errors.columns.push({
                        source: "",
                        avid: "",
                        logic: []
                    });
                },
                addAllColumns() {
                    // Loop through the columns
                    var keys = Object.keys(this.src_columns);
                    for(var i = 0; i < keys.length; i++) {
                        let add_column = true;
                        //Verify it hasn't already been mapped
                        for(var j = 0; j < this.mapping.length; j++){
                            if(this.mapping[j].source_column == keys[i]){
                                add_column = false;
                                break;
                            }
                        }
    
                        //If it should be added, add it to mapping
                        if(add_column){
                            let map = this.getMap();
                            map.source_column = keys[i];
                            map.data_type = 'string';
                            map.destination_column = 'custom';
                            map.display_name = this.src_columns[keys[i]];
                            this.mapping.push(map);
                            this.errors.columns.push({
                                source: "",
                                avid: "",
                                logic: []
                            });
                        }
                    }
                },
                removeColumn(i) {
    
                    //Determine if this column is part of an array
                    if(this.mapping[i].class_names != null){
                        //If the class_names contains "middle"
                        let pos = i+1;
                        //Go forward and find the first class_names that has "last"
                        for(; pos < this.mapping.length; pos++) {
                            if(this.mapping[pos].class_names.indexOf("last") >= 0) {
                                break;
                            }
                        }
                        //Work backwards to find the elements to remove
                        for(var j = pos; j >= 0; j--) {
                            let is_optional = this.mapping[j].optional;

                            this.mapping.splice(j, 1);
                            this.errors.columns.splice(j, 1);

                            //The optional flag will be true only for the first one
                            if(is_optional)
                                break;
                        }
                    }
                    else {
                        //Remove the column
                        this.mapping.splice(i, 1);
                        this.errors.columns.splice(i, 1);
                    }
                },
                previewSourceColumn(column) {
                    this.depth = 1;
                    //Show the column value of the recently selected table (except CUSTOM)
                    if(column.source_column != "CUSTOM") {
                        this.preview_table_val[0] = column.source_column;
                        //Calling this will show the loading message
                        this.$forceUpdate();
                        this.updatePreviewTable(column.source_column);
                    }
                    else {
                        this.preview_table_val[0] = '*** Custom Logic ***';
                        this.preview_table_logic[0] = column.column_logic;
                        this.$forceUpdate();
                        this.getPreviewTableData(column.source_column, column.column_logic);
                    }
    
                },
                updatePreviewTable(column_value) {
                    this.preview_table[this.depth-1] = null;
                    this.preview_table_logic[this.depth-1] = null;
    
                    if(column_value == "") {
                        this.preview_table_val[this.depth-1] = null
                        this.preview_table_logic[this.depth-1] = null;
                        return;
                    }
    
                    this.getPreviewTableData(column_value);
                },
                getPreviewTableData(column, column_logic = null) {

                    //Hide the preview tool tips
                    let tips = document.getElementsByClassName('tooltip');
                    for (var i = 0; i < tips.length; i++)
                      //Remove the show class from ti
                        tips[i].classList.remove('show');

                    var data = {
                      client: this.client,
                      source: this.source,
                      column: column,
                      logic: column_logic,
                      limit: 10,
                      data_source_id: this.data_source_id,
                      value: this.dataset.filter
                    };
    
                    //I need to add the filter for the first level if I'm two down.
                    if(this.depth == 2) {
                        if(this.preview_table_val[0] == '*** Custom Logic ***') {
                            data.filter = [{
                                column: this.preview_table_val[0],
                                logic: this.preview_table_logic[0],
                                value: this.preview_table[0][this.drill_index].name
                            }];
                        }
                        else {
                            data.filter = [{
                                column: this.preview_table_val[0],
                                value: this.preview_table[0][this.drill_index].name
                            }];
                        }
                    }
    
                    var _self = this;
    
                    window.axios.post('/api/bigquery/get_samples', data)
                      .then(response => {
                        _self.preview_table[_self.depth-1] = response.data.samples;
                        _self.$forceUpdate();
                    })
                    .catch(error => {
                        // Handle errors here
                        if (error.response.status == 400 && error.response.data.error_type == 'missing_column') {
                            //Find the object that has a value of the column variable and remove it from the src_column_array array.
                            var index = _self.src_column_array.findIndex(c => c.value == column);
                            _self.src_column_array.splice(index, 1);
                            _self.preview_column_array.splice(index, 1);

                            _self.preview_table_val = [];

                            //Find all of the places where the source column is set to the column
                            for(var i = 0; i < _self.mapping.length; i++) {
                                if(_self.mapping[i].source_column == column)
                                    _self.mapping[i].source_column = null;
                            }

                            Swal.fire({
                              title: "The selected column has been removed",
                              text: "The database column was removed from the source data. We've updated this data set to reflect that recent change.",
                              icon: "warning",
                              iconColor: "#D11F1F",
                              showCancelButton: false,
                              confirmButtonColor: "#D11F1F",
                              confirmButtonText: "OK",
                            })

                            _self.$forceUpdate();
                        }
                        else if (error.response.status == 400 && error.response.data.error_type == 'sql_error') {
                            Swal.fire({
                              title: "There is an error with your custom logic",
                              html: error.response.data.message,
                              icon: "warning",
                              iconColor: "#D11F1F",
                              showCancelButton: false,
                              confirmButtonColor: "#D11F1F",
                              confirmButtonText: "OK",
                            })
                        }
                    });;
                },
                previewRecords(column_value) {
    
                    var _self = this;
                    var data = {
                      data_source: this.source,
                      filter:  [{
                        column: this.preview_table_val[this.depth-1],
                        logic: this.preview_table_logic[this.depth-1],
                        value:  column_value
                      }],
                      limit: 10,
                      data_source_id: this.data_source_id,
                      client_id: this.client.id,
                      value: this.dataset.filter
                    };
    
                    //I need to add the filter for the first level if I'm two down.
                    if(this.depth == 2) {
                        data.filter.push({
                            column: this.preview_table_val[0],
                            logic: this.preview_table_logic[0],
                            value: this.preview_table[0][this.drill_index].name
                        });
                    }
    
                    this.preview_detail_loading = column_value;
    
                    window.axios.post('/api/bigquery/get_detailed_samples', data).then(response => {
                        if(response.status == 200) {
    
                            _self.preview_detail_table = response.data.samples;
                            _self.preview_detail_loading = "";
    
                            var myModal = new bootstrap.Modal(document.getElementById('column-mapping-preview-'+this.data_source_id))
                            myModal.show()
                        }
                      });
                },
                drilldown(index, column_value) {
                    this.depth++;
                    this.drill_index = index;
                },
                removeDrilldown() {
                    this.drill_index = null;
                    this.depth--;
                    this.preview_table_val[1] = null;
                    this.preview_table[1] = null;
    
                },
                setDataType(index, type) {
                    if(this.errors.columns[index].avid.indexOf('name') > -1)
                        this.errors.columns[index].avid = 'Please enter a unique name for the column in the field above';
                    else
                        this.errors.columns[index].avid = '';
    
                    this.mapping[index].data_type = type;
    
                    const dropdownToggle = document.getElementById('dropdown-'+index);
                    if (dropdownToggle) {
                        dropdownToggle.click();
                    }
                },
                columnExists(index, col_name) {
                    //Now loop through the mapping to ensure this column doesn't already exist
                    for(var i = 0; i < this.mapping.length; i++) {
                        if(index != i && this.mapping[i].display_name == col_name) {
                            this.errors.columns[index].avid = 'A column with that name already exists.';
                            return true;
                        }
                    }
                    //Remove old errors
                    this.errors.columns[index].avid = '';
                    return false;
                },
                sanitizeSQLStatement(i, j, unit) {
                    //Only do this for SQL stuff
                    if(unit.value_type != 'sql'){
                        //Clear the errors
                        if(unit.value.trim() != "" && this.errors.columns[i].logic[j] != null){
                            this.errors.columns[i].logic[j] = "";
                            this.$forceUpdate();
                        }
                        return;
                    }
    
                    // List of keywords to remove from the parameterValue
                    const blacklistedKeywords = ['UPDATE', 'DROP', 'DELETE', 'INSERT', 'TRUNCATE'];
    
                    // Remove blacklisted keywords
                    let sanitizedValue = unit.value;
                    blacklistedKeywords.forEach(keyword => {
                        const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
                        sanitizedValue = sanitizedValue.replace(regex, '');
                    });
    
                    // Remove semicolons
                    sanitizedValue = sanitizedValue.replace(/;/g, '');
    
                    unit.value= sanitizedValue;
                    //Clear the errors
                    if(unit.value.trim() != "" && this.errors.columns[i].logic[j] != null){
                        this.errors.columns[i].logic[j] = "";
                        this.$forceUpdate();
                    }
                },
                hasAnyLogicUnitError(column) {
                    if(column.source_column != 'CUSTOM')
                        return false;
    
                    let units = column.column_logic.units;
    
                    //Look at the logic units
                    for(var j = 0; j < units.length; j++){
                        if(units[j].logic != null && this.hasLogicUnitError(units[j].logic) )
                            return true;
                        //If the value is blank
                        if(units[j].value == null || ( typeof units[j].value === 'string'  &&  units[j].value.trim() == ""))
                            return true;
                        
                        //If the value is of type column and the column isn't mapped
                        else if (units[j].value_type == 'column' && this.src_columns[units[j].value] == null)
                            return true;
                    }
                    return false;
                },
                hasLogicUnitError(units) {
                    for(var i = 0; i < units.logic_units.length; i++) {
                        //If one of the fields hasn't been selected ...
    
                        //If the logic operator is an object
                        var operator = units.logic_units[i].operator;
                        if(typeof operator == "object" && operator != null)
                            operator = units.logic_units[i].operator.value;
    
                        if(units.logic_units[i].db_column == null ||
                            units.logic_units[i].db_column.value == "" || operator == "" ||
                            ( units.logic_units[i].value == "" && operator.indexOf("NULL") == -1))
                            return true;
    
                        //If the database column is no longer available in the source table
                        if(this.src_columns[units.logic_units[i].db_column.value] == null) {
                            return true;
                        }

                    }
                    return false;
                },
                isValid() {
    
                    var is_valid = true;
                    //Loop through each of the different fields
                    for(var i = 0; i < this.mapping.length; i++) {
                        //For the source, check whether a column has been selected
                        // (Or, if it has a parent, it may be optional)
                        if((this.mapping[i].source_column == null || this.mapping[i].source_column.trim() == "") && (this.mapping[i].optional || this.mapping[i].parent == null)){
                            is_valid = false;
                            this.errors.columns[i].source = 'This field is required. Please select an option above.';
                        }
                        //If the column is CUSTOM, validate the custom values
                        else if(this.mapping[i].source_column == 'CUSTOM') {
    
                            let units = this.mapping[i].column_logic.units;
                            var unit_is_valid = this.isUnitValid(i, units);
                            if(!unit_is_valid) is_valid = false;
    
                        }
    
                        //This will be set if they have a duplicate column name set
                        if(this.errors.columns[i].avid != '')
                            is_valid = false;
    
                        //For the avid column, is a data type selected and does it have a value
                        if(this.mapping[i].destination_column == null || this.mapping[i].destination_column == ''){
                            is_valid = false;
                            this.errors.columns[i].avid = 'This field is required. Please select an option above.';
                        }
                        //If the name is blank and data type is empty
                        else if (this.mapping[i].destination_column.indexOf('custom') > -1 && (this.mapping[i].display_name == null || this.mapping[i].display_name == '') && this.mapping[i].data_type == null ) {
    
                            is_valid = false;
                            this.errors.columns[i].avid = 'Custom columns require you to enter a unique name and to select a data type from the dropdown above.';
                        }
    
                        else if (this.mapping[i].destination_column.indexOf('custom') > -1 && (this.mapping[i].display_name == null || this.mapping[i].display_name == '')) {
    
                            is_valid = false;
                            this.errors.columns[i].avid = 'Please enter a unique name for the column in the field above';
                        }
                        //If just the data type is empty
                        else if (this.mapping[i].destination_column.indexOf('custom') > -1 &&  this.mapping[i].data_type == null ) {
    
                            is_valid = false;
                            this.errors.columns[i].avid = 'Please select the data type of this column from the dropdown above.';
                        }
    
                    }
                    return is_valid;
    
                },
                isUnitValid(i, units) {
                    this.errors.columns[i].logic = new Array(units.length);
                    var is_valid = true;
                    let dest_data_type = this.mapping[i].data_type; //boolean
    
                    for(var j = 0; j < units.length; j++){
                        //Check for empty output values
                        if(units[j].value == null || ( typeof units[j].value === 'string'  &&  units[j].value.trim() == "")) {
                            is_valid = false;
                            this.errors.columns[i].source = 'There is an error with the custom logic defined below.'
                            this.errors.columns[i].logic[j] = 'The output value field is required.'
                        }
                        //If the source columns no longer contain the mapped field
                        else if(units[j].value_type == 'column' && this.src_columns[units[j].value] == null) {
    
                            is_valid = false;
                            this.errors.columns[i].source = 'A column used in the logic below has been removed from the latest data refresh.'
                            this.errors.columns[i].logic[j] = 'The output value field that was mapped has been removed in the most recent data refresh. Please select a new value.'
                        }else if(units[j].value_type == 'static' && !this.isValidForDataType(dest_data_type, units[j].value )) {
                            is_valid = false;
                            this.errors.columns[i].source = 'The output of one of the logic blocks below does not match the data type of the Avid AI column.'
                            this.errors.columns[i].logic[j] = 'The Avid AI column is a ' + dest_data_type + ' column. Your "Output Value" should match this data type.';
                        }
    
                        //  Also validate the logic statement
                        if(this.hasLogicUnitError(units[j].logic) ){
                            is_valid = false;
                            this.errors.columns[i].source = 'There is an error with the custom logic defined below.';
    
                            let row = i;
                            let col = j;
    
                            this.$nextTick(() => {
                                //Show the accordion units with an error
                                document.getElementById('collapse-' + this.mapping[row].id+'-' + col).classList.add("show");
                                document.querySelector('#heading-' + this.mapping[row].id+'-' + col + ' .accordion-button').classList.remove('collapsed');
                                this.$refs['simple-'+row+'-'+col][0].isFormValid()
                            });
                        }
                    }
                    return is_valid;
                },
                isValidForDataType(type, value){
                    if (type === 'boolean') {
                        // Check if value is 'true' or 'false'
                        if(typeof value === 'string') {
                            return value.toLowerCase() === 'true' || value.toLowerCase() === 'false';
                        } else { // when value is true of false as Boolean
                            //Return true if the value is a boolean
                            return typeof value === 'boolean';
                        }
                    }
                    value = value.trim();
                    if (type === 'date') {
                        // Define regex patterns for different date formats
                        const datePatterns = [
                            /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
                            /^\d{8}$/, // YYYYMMDD
                            /^\d{1,2}\/\d{1,2}\/\d{4}$/, // MM/DD/YYYY
                            /^\d{1,2}\/\d{1,2}\/\d{2}$/, // MM/DD/YY
                            /^\d{6}$/, // MMDDYY
                            /^\d{5}$/, // MDDYY
                            /^\d{1,2}[\s,/\-_]+(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[\s,/\-_]+\d{2}$/i, // DD-MMM-YY
                            /^\d{1,2}[\s,/\-_]+(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[\s,/\-_]+\d{4}$/i, // DD-MMM-YYYY
                            /^(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[\s,/\-_]+\d{1,2}[\s,/\-_]+\d{2}$/i, // MMM-DD-YY
                            /^(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[\s,/\-_]+\d{1,2}[\s,/\-_]+\d{4}$/i, // MMM-DD-YYYY
                            /^\d{1,2}(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\d{4}$/i // DDMMMYYYY
                        ];

                        // Check if value matches any of the patterns
                        for (let pattern of datePatterns) {
                            if (pattern.test(value)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    if (type === 'timestamp') {
                        // Define regex patterns for different timestamp formats
                        const timestampPatterns = [
                            /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, // YYYY-MM-DD HH:MM:SS
                            /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/, // DD/MM/YYYY HH:MM
                            /^\d{2}\/\d{2}\/\d{4}$/, // MM/DD/YYYY
                            /^[A-Z][a-z]+\s\d{2},\s\d{4}\s\d{2}:\d{2}:\d{2}$/, // Month DD, YYYY HH:MM:SS
                            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/ // YYYY-MM-DDTHH:MM:SSZ
                        ];

                        // Check if value matches any of the patterns
                        for (let pattern of timestampPatterns) {
                            if (pattern.test(value)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    if (type === 'integer') {
                        // Check if value is an integer
                        const intPattern = /^-?\d+$/;
                        return intPattern.test(value);
                    }
                    if (type === 'numeric') {
                        // Check if value is a valid number
                        return !isNaN(value) && !isNaN(parseFloat(value));
                    }

                    return true;
                }

            },
        }
    </script>
