var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-table pb-2" }, [
    _c("p", { staticClass: "px-3" }, [
      _vm._v(
        "The media, revenue and gifts are the actual program results based upon the start of the client's current fiscal plan through the last full month (" +
          _vm._s(_vm.getLastMonth()) +
          "). The percentage shown is your progress toward the annual plan at the end of that month."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", { staticClass: "table gy-0 m-0" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          { staticClass: "table-border-bottom-0" },
          _vm._l(_vm.clients, function (c) {
            return _c(
              "tr",
              [
                _c("td", { staticClass: "ps-3" }, [
                  _c("a", { attrs: { href: c.home_link } }, [
                    _c("img", {
                      staticClass: "avatar rounded-circle me-3",
                      attrs: { src: c.image },
                    }),
                    _vm._v(" " + _vm._s(c.name)),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.loaded[c.url]
                  ? _c(
                      "td",
                      {
                        staticClass:
                          "align-middle d-none d-sm-table-cell placeholder-glow",
                      },
                      [
                        _c("span", {
                          staticClass: "placeholder col-9 placeholder-lg",
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loaded[c.url]
                  ? _c(
                      "td",
                      {
                        staticClass:
                          "align-middle d-none d-sm-table-cell placeholder-glow",
                      },
                      [
                        _c("span", {
                          staticClass: "placeholder col-9 placeholder-lg",
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loaded[c.url]
                  ? _c(
                      "td",
                      {
                        staticClass:
                          "align-middle d-none d-sm-table-cell placeholder-glow",
                      },
                      [
                        _c("span", {
                          staticClass: "placeholder col-9 placeholder-lg",
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loaded[c.url]
                  ? _c(
                      "td",
                      {
                        staticClass:
                          "align-middle d-none d-sm-table-cell placeholder-glow",
                      },
                      [
                        _c("span", {
                          staticClass: "placeholder col-9 placeholder-lg",
                        }),
                      ]
                    )
                  : _vm._l(_vm.getActuals(c.url), function (actual, i) {
                      return _c(
                        "td",
                        {
                          staticClass:
                            "align-middle d-none d-sm-table-cell placeholder-glow",
                        },
                        [
                          i == "media_cost" || i == "revenue"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(actual.value))
                                ),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm._f("number")(actual.value))),
                              ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "badge ms-2", class: actual.class },
                            [_vm._v(_vm._s(actual.label))]
                          ),
                        ]
                      )
                    }),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "ps-3 align-middle" }, [_vm._v("Client")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none d-sm-table-cell" }, [
          _vm._v("Media Budget"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none d-sm-table-cell" }, [
          _vm._v("Total Revenue"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none d-sm-table-cell" }, [
          _vm._v("Total Gifts"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none d-sm-table-cell" }, [
          _vm._v("Emails Acquired"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }