<template>
    <div class="view-report p-2 mb-4 export-report" id="view-report-page">
        <div class="row mx-0"> 
            <div class="p-0 w-100">
                <div class="row mt-3 px-2">
                    <div class="card box-content">
                        <div class="card-body">
                            <report-view-section :page="local_page" :client="client" :connections="connections" :page_section="section" :is_filter_panel_expanded="false"
                            :if_refreshing="false" :applied_chart_filter="false" :section_index="section_index"></report-view-section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-for="svg in svgs" >
            <div class="col svg-container" :id="'svg-' + svg.id" :data-chart-id="svg.id" v-html="svg.svg"></div>
        </div>
    </div>
</template>

<script>
    import {Context} from "svgcanvas";
    export default {
        props: ['page', 'client', 'connections'],
        data() {
            return {
                local_page: {},
                section: null,
                section_index:0,
                svgs: [],
                sizes: {
                    FULL_WIDTH: {width: 1200, height: 450},
                    SINGLE_SMALL: {width: 750, height: 450},
                    THREE_CHARTS: {width: 400, height: 400},
                    TWO_CHARTS: {width: 600, height: 400}
                }
            };
        },
        beforeMount() {
            this.local_page = this.page;
            var section_id = window.location.pathname.split('/').slice(-1);
            //If it is a number
            if(section_id[0].match(/^\d+$/)){
                for(let i = 0; i < this.local_page.sections.length; i++){
                    if(this.local_page.sections[i].id == section_id[0]){
                        this.section = this.local_page.sections[i];
                        this.section_index = i;
                        break;
                    }
                }
            }
            //Just select the first section
            else{
                this.section_index = 0;
                this.section = this.local_page.sections[0];
            }

            //Get the sizes array from the querystring
            const params = new URLSearchParams(window.location.search);
            let size_overrides = params.get('sizes');
            if(size_overrides){
                size_overrides = JSON.parse(size_overrides);
                for (const [key, value] of Object.entries(size_overrides)) {
                    this.sizes[key] = value;
                }
            }
        },
        mounted() {
            //Resize the sections
            let containers = document.querySelectorAll('.size-container');
            for(var i = 0; i < containers.length; i++){
                let container = containers[i];
                let chart = container.querySelector("canvas");
                if(!chart) continue; //If it is a table or html

                //Get the size
                let size = container.dataset.size;
                //Full Width
                if(size == 'x-large' || size == 'large') {
                    chart.dataset.width = this.sizes.FULL_WIDTH.width;
                    chart.dataset.height = this.sizes.FULL_WIDTH.height;
                }
                //If this chart and the next two are all small...
                else if(size == 'small' && containers[i+1] && containers[i+2] && containers[i+1].dataset.size == 'small' && containers[i+2].dataset.size == 'small'){
                    chart.dataset.width = this.sizes.THREE_CHARTS.width;
                    chart.dataset.height = this.sizes.THREE_CHARTS.height;

                    //And set the next two dataset values
                    containers[i+1].querySelector("canvas").dataset.width = this.sizes.THREE_CHARTS.width;
                    containers[i+1].querySelector("canvas").dataset.height = this.sizes.THREE_CHARTS.height;
                    containers[i+2].querySelector("canvas").dataset.width = this.sizes.THREE_CHARTS.width;
                    containers[i+2].querySelector("canvas").dataset.height = this.sizes.THREE_CHARTS.height;

                    i+=2;
                }
                else if(size == 'small'){
                    chart.dataset.width = this.sizes.SINGLE_SMALL.width;
                    chart.dataset.height = this.sizes.SINGLE_SMALL.height;
                }
                //If this chart and the next is medium
                else if(size == 'medium' && containers[i+1] && containers[i+1].dataset.size == 'medium'){
                    chart.dataset.width = this.sizes.TWO_CHARTS.width;
                    chart.dataset.height = this.sizes.TWO_CHARTS.height;

                    //And set the next two dataset values
                    containers[i+1].querySelector("canvas").dataset.width = this.sizes.TWO_CHARTS.width;
                    containers[i+1].querySelector("canvas").dataset.height = this.sizes.TWO_CHARTS.height;

                    i+=1;
                }
                //By default, set it to the full width
                else {
                    chart.dataset.width = this.sizes.FULL_WIDTH.width;
                    chart.dataset.height = this.sizes.FULL_WIDTH.height;
                }
            }


            //Add the necessary modifications to canvas2svg library to work with chartjs
            Context.prototype.getContext = function (contextId) {
                if (contextId=="2d" || contextId=="2D")
                    return this;
                return null;
            }
            Context.prototype.style = function () { return this.__canvas.style }
            Context.prototype.getAttribute = function (name) { return this[name];}
            Context.prototype.addEventListener =  function(type, listener, eventListenerOptions) {}

            this.exportCharts();
        },
        methods: {
            exportCharts(){

                //Get all
                let containers = document.querySelectorAll('.chart-template');

                //Loop through each chart and convert it to an svg
                containers.forEach(c => {
                    let chart = c.querySelector("canvas");
                    if(!chart) return; //If it is a table or html

                    let svg = this.convertToSVG(chart);
                    svg = this.updateTextElements(svg);
                    if(c.querySelector('.line-chart-template') || c.querySelector('.bar-line-chart-template')){
                        svg = this.addLinesToChart(svg);
                    }

                    this.svgs.push({
                        id: c.dataset.chartId,
                        svg: svg
                    });
                    //c.remove();
                });
            },


            convertToSVG(c) {
                var context = c.getContext("2d");
                const chart = Chart.getChart(c);

                let data = chart.config;
                //Make a copy of the config
                //data = JSON.parse(JSON.stringify(data));

                // deactivate responsiveness and animation
                data.options.responsive = false;
                data.options.animation = false;
                data.options.devicePixelRatio = 1;

                // canvas2svg 'mock' context
                let width = c.dataset.width || c.offsetWidth;
                let height = c.dataset.height || c.offsetHeight;
                var svgContext =  Context({width: width, height: height});

                // new chart on 'mock' context fails:
                var mySvg = new Chart(svgContext, data);
                // Failed to create chart: can't acquire context from the given item

                //return svgContext.getSvg();
                return svgContext.getSerializedSvg(false);

            },
            updateTextElements(svgString) {
              // Create a new DOMParser
              const parser = new DOMParser();

              // Parse the SVG string into an XML document
              const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

              // Get the root SVG element
              const svg = svgDoc.documentElement;

              // Find all <text> elements
              const textElements = svg.querySelectorAll('text');

              textElements.forEach(text => {
                // Check if font-size is not set or empty
                if (!text.getAttribute('font-size') || text.getAttribute('font-size') === '') {
                  text.setAttribute('font-size', '15px');
                }

                // Check if font-family is set to "MDSystem-Regular"
                if (text.getAttribute('font-family') === 'MDSystem-Regular') {
                  text.setAttribute('font-family', 'MDSystem-Regular, "Franklin Gothic Medium", Arial');
                }
              });

              // Serialize the SVG back to a string
              const serializer = new XMLSerializer();
              return serializer.serializeToString(svg);
            },

            addLinesToChart(svgString) {
              // Create a new DOMParser
              const parser = new DOMParser();

              // Parse the SVG string into an XML document
              const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

              // Get the root SVG element
              const svg = svgDoc.documentElement;

              // Find all <g> elements that contain the points
              const pointGroups = svg.querySelectorAll('g > g');

              // Loop through each group and extract points
              pointGroups.forEach(group => {
                const points = Array.from(group.querySelectorAll('path[fill]'));
                if (points.length < 2) return;

                // Build the path data
                let pathData = '';
                points.forEach((point, index) => {
                  // Extract the coordinates and radius from the d attribute
                  const coords = point.getAttribute('d').match(/M (\d+(\.\d+)?) (\d+(\.\d+)?)/);
                  const radiusMatch = point.getAttribute('d').match(/A (\d+(\.\d+)?)/);

                  if (coords && radiusMatch) {
                    const x = parseFloat(coords[1]);
                    const y = parseFloat(coords[3]);
                    const radius = parseFloat(radiusMatch[1]);

                    const centerX = x - radius;
                    const centerY = y;

                    if (index === 0) {
                      pathData += `M ${centerX} ${centerY}`;
                    } else {
                      pathData += ` L ${centerX} ${centerY}`;
                    }
                  }
                });

                // Create a new path element
                const linePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                linePath.setAttribute('d', pathData);
                linePath.setAttribute('fill', 'none');
                linePath.setAttribute('stroke', group.querySelector('path[stroke]').getAttribute('stroke'));
                linePath.setAttribute('stroke-width', '1');

                // Append the new path to the same group as the points
                group.appendChild(linePath);
              });

              // Serialize the SVG back to a string
              const serializer = new XMLSerializer();
              return serializer.serializeToString(svg);
            }
        }
    }
</script>
