<template>
    <div class="chart-list">
        <div class="mb-5">
            <div>
                <div class="row mb-2">
                    <div class="col">
                        <h2 class="d-inline">Charts</h2>
                        <a v-if="local_charts.length > 0" class="btn btn-secondary float-end" href="/insights/chart_builder/new">
                            <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Chart
                        </a>
                    </div>
                </div>

                <div class="row mb-2" v-if="local_charts.length > 0 || chart_search_key!= ''">
                    <div class="col">
                        <input type="text" class="form-control white-background" placeholder="Type to search charts..."
                                name="chart_search" v-model="chart_search_key" @input="searchChart()">
                    </div>
                </div>
                <div v-if="local_charts.length > 0" class="card box-content mb-3">
                    <div class="card-body p-0">
                        <div class="overflow-hidden rounded-top">
                            <div class="p-1 bg-dark"></div>
                        </div>
                        <div class="table-responsive mb-2">
                            <table class="table gy-0 m-0">
                                <thead class="table-dark">
                                    <tr>
                                        <th class='align-middle pt-0'>Name</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Chart Type</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Data Blend Type</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell">Client</th>
                                        <th class="align-middle d-none pt-0 d-sm-table-cell" style="width: 86px;">Actions</th>
                                    </tr>
                                </thead>
                                <transition-group tag="tbody" name="fade-slow" class="table-border-bottom-0">
                                    <tr v-for="(chart, index) in local_charts" :key="chart.id">
                                        <td class="align-middle">
                                            <a :href="'/insights/chart_builder/' + chart.id">{{ chart.name | propercase }} ({{ chart.type | propercase }} Chart)</a>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ chart.type | propercase }}
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ chart.data_blend_type | propercase }}
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ (chart.client != null ? chart.client.name : '*** Global Template ***') }}
                                        </td>

                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Copy Chart" data-bs-toggle="tooltip" v-on:click="copyChart(chart.id)">
                                                <i class="fa-solid fa-copy me-0" aria-hidden="true"></i>
                                            </button>
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Chart" data-bs-toggle="tooltip" v-on:click="deleteChart(chart)">
                                                <i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </transition-group>
                            </table>
                        </div>
                    </div>       
                </div>

                <div v-else>
                    <div class="card box-content mb-3">
                        <div class="card-body mt-3 text-center">
                            <h3 class="text-center">No charts have been created.</h3>
                            <a class="btn btn-primary mb-3" href="/insights/chart_builder/new">
                                <span><i class="fas fa-fw fa-plus"></i>Add a Chart</span>
                            </a>
                        </div>
                    </div>
                </div>      

            </div>      
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['charts', 'clients'],
        data() {
            return {
                local_charts: [],
                chart_search_key: '',
            };
        },
        beforeMount() {
            this.local_charts = this.charts
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            }
        },
        methods: {
            searchChart(){
                if(this.chart_search_key == '') {
                    this.local_charts = this.charts;
                } else{ // search against chart name and title for now
                    this.local_charts = this.charts.filter(c =>
      
                        c.name.toLowerCase().includes(this.chart_search_key.toLowerCase())
                        ||  (c.title && c.title.toLowerCase().includes(this.chart_search_key.toLowerCase()))
                    );
                }   
            },
            copyChart(chart_id) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to copy this chart?",
                  text: "All filters, metrics, and associated information will be copied.",
                  icon: "question",
                  iconColor: "#46705F",
                  showCancelButton: true,
                  confirmButtonColor: "#46705F",
                  confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.post('/api/insights/chart/copy/'+ chart_id)
                            .then(response => {
                                if (response.status == 200) {
                                    window.location = "/insights/chart_builder/" + response.data.chart.id;
                                }
                            });
                    }
                });
            },
            deleteChart(chart) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this chart?",
                  text: "This chart will be removed from all associated reports.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/insights/chart/'+ chart.id);
                        
                        for(var i = 0; i < this.local_charts.length; i++){
                            if(this.local_charts[i].id == chart.id){
                                this.local_charts.splice(i, 1);
                                break;
                            }
                        }
                    }
                });
            },

        }
    }
</script>
