var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "results" },
    [
      _c(
        "h2",
        {
          staticClass: "mb-4 mt-5 border-bottom border-secondary border-3 pb-1",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.client.name) + ":")]),
          _vm._v(" "),
          _c("em", [_vm._v(_vm._s(_vm.plan.name))]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary float-end btn-sm d-none d-sm-inline-block",
              on: {
                click: function ($event) {
                  _vm.customize = !_vm.customize
                },
              },
            },
            [_vm._v(_vm._s(_vm.customize ? "Finished" : "Customize Dashboard"))]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-slow" } },
        _vm._l(_vm.notifs, function (n) {
          return _c(
            "div",
            { staticClass: "alert mb-4 box-content", class: n.data.class },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-none px-0 close-btn",
                  on: {
                    click: function ($event) {
                      return _vm.dismissNotification(n)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-close" })]
              ),
              _vm._v("\n        " + _vm._s(n.data.error_msg) + "\n    "),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row stat-container",
          class: _vm.customize ? "customize" : "",
        },
        _vm._l(_vm.metrics, function (m) {
          return _c(
            "div",
            {
              staticClass: "col-sm-6 col-md-4 mb-4 scorecard",
              class: _vm.isVisible(m) ? "" : "hidden",
            },
            [
              _c(
                "button",
                {
                  staticClass: "close btn",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: {
                    click: function ($event) {
                      return _vm.customizeVisibility(m)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-times",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card box-content",
                  on: {
                    click: function ($event) {
                      _vm.current_metric = m
                    },
                  },
                },
                [
                  _c("h5", { staticClass: "card-header pt-3" }, [
                    _vm._v(_vm._s(_vm._f("propercase")(m))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body pt-0 pb-3" }, [
                    _c("img", {
                      staticClass: "float-start me-3",
                      attrs: { src: _vm.getMetricIcon(m) },
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber(m, _vm.annual_stats[m]["actuals"])
                        ) + "\n                    "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "badge",
                          class: {
                            "bg-label-success":
                              _vm.annual_stats[m]["variance"] > 0,
                            "bg-label-danger":
                              _vm.annual_stats[m]["variance"] < 0,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("percentage_na")(
                                _vm.annual_stats[m]["variance"]
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.numMetricsVisible() > 0
        ? _c("div", { staticClass: "card box-content mb-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.page_view == "ytd" ? "Year to Date" : "Total"
                      ) +
                        " " +
                        _vm._s(_vm._f("propercase")(_vm.current_metric))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-toolbar flex-row-reverse",
                      attrs: {
                        role: "toolbar",
                        "aria-label": "Toolbar with button groups",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn-group mx-2 mb-2 result-selector btn-group-sm",
                          attrs: { role: "group" },
                        },
                        _vm._l(_vm.metrics, function (m) {
                          return _vm.isVisible(m)
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  class:
                                    _vm.current_metric == m
                                      ? "btn-primary"
                                      : "btn-secondary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.current_metric = m
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm._f("propercase")(m)))]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn-group mx-2 mb-2 btn-group-sm",
                          attrs: { role: "group" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              class:
                                _vm.current_view == "monthly"
                                  ? "btn-primary"
                                  : "btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.current_view = "monthly"
                                },
                              },
                            },
                            [_vm._v("Monthly")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              class:
                                _vm.current_view == "cumulative"
                                  ? "btn-primary"
                                  : "btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.current_view = "cumulative"
                                },
                              },
                            },
                            [_vm._v("Cumulative")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn-group mx-2 mb-2 btn-group-sm",
                          attrs: { role: "group" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              class:
                                _vm.page_view == "ytd"
                                  ? "btn-primary"
                                  : "btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.page_view = "ytd"
                                },
                              },
                            },
                            [_vm._v("Year to Date")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              class:
                                _vm.page_view == "full"
                                  ? "btn-primary"
                                  : "btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.page_view = "full"
                                },
                              },
                            },
                            [_vm._v("Full Year")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeYear(-1)
                                },
                              },
                            },
                            [_vm._v("Last Year")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeYear(1)
                                },
                              },
                            },
                            [_vm._v("Next Year")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TransitionGroup",
        { attrs: { name: "move", tag: "div" } },
        _vm._l(_vm.sortedDataset(), function (init_ids, index) {
          return _vm.dataset[init_ids] != null &&
            (_vm.shouldRender(_vm.dataset[init_ids]) || _vm.customize)
            ? _c(
                "div",
                {
                  key: init_ids,
                  staticClass: "row results-container align-items-center",
                  class: _vm.customize ? "customize" : "",
                },
                [
                  _vm.customize
                    ? _c("div", { staticClass: "col-1 text-center" }, [
                        index > 0
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary my-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.customizePosition(
                                      _vm.dataset[init_ids],
                                      -1
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-solid fa-chevron-up",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "clearfix" }),
                        _vm._v(" "),
                        index < Object.keys(_vm.dataset).length - 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary my-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.customizePosition(
                                      _vm.dataset[init_ids],
                                      1
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-solid fa-chevron-down",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyMetric(_vm.dataset[init_ids])
                    ? _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("initiative-results-table", {
                            attrs: {
                              headers: _vm.headers,
                              initiative: _vm.dataset[init_ids],
                              client: _vm.client,
                              customizations: _vm.local_custs,
                              start_date: _vm.start_date,
                              end_date: _vm.end_date,
                            },
                            on: {
                              addCustomization: _vm.addCustomization,
                              deleteCustomization: _vm.deleteCustomization,
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "col" }, [
                        _c("h4", [_vm._v(_vm._s(_vm.dataset[init_ids].title))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card box-content mb-5" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "The initiative has not launched yet so there are no metrics to display."
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                ]
              )
            : _vm._e()
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row chart-container" }, [
      _c("div", { staticClass: "col" }, [
        _c("canvas", { attrs: { id: "stats-chart" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }