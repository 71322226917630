<template>
    <div class="annual-plan-initiatives">
        <div class="date-selector">
            <button type="button" class="btn btn-sm btn-outline-secondary back-btn" v-on:click="moveDatesBackward()"><i class="fa fa-angle-double-left" aria-hidden="true"></i></button>
            <div style="display:inline-block; line-height: 1.1em">
                {{ month_names[current_month] }} {{ current_year }} &mdash; {{ future_date }}
                <span class="duplicate-annual"  v-if="plan.is_published_plan != 1">(<a href="#" v-on:click.prevent="copyYear()" class="btn btn-link" :class="(processing?'disabled': '')">Duplicate Annual Initiatives</a>)</span>
            </div>

            <button type="button" class="btn btn-sm btn-outline-secondary forward-btn" v-on:click="moveDatesForward()"><i class="fa fa-angle-double-right" aria-hidden="true"></i></button>

        </div>

        <div class="initiatives-selections" :class="{'show-priority-zones':show_zones}">
            <table class="table">
                <thead>
                    <tr>
                        <th colspan="3" scope="colgroup" class="bare"></th>
                        <template v-for="(months, year) in calendar_headers">
                        <th v-bind:colspan="months.length" scope="colgroup">{{ year }}</th>
                        </template>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <th class="priority">Priority</th>
                        <th class="cost">Cost</th>
                        <template v-for="(months, year) in calendar_headers">
                        <th scope="col" class="month" v-for="month in months" :class="{'disabled': isInThePast(month, year) }">{{ month_names[month] }}</th>
                        </template>
                    </tr>
                </thead>

                <template v-for="(val, key) in all_initiatives">
                    <draggable :list="val" group="initiatives" tag="tbody" class="zone"
                        :disabled="plan.is_published_plan == 1"
                        handle=".handle" item-key="id" @start="startSort" @end="endSort"
                        @change="enterNewDropZone($event, key)" :class="'count-'+ val.length + ' ' + key.toLowerCase()">
                        <tr v-for="item in val" v-bind:class="item.priority.toLowerCase()" :data-id="item.id"
                            class="init-row">

                            <td scope="row" :colspan="key == 'Required' ? 2 : 1"
                                v-if="plan.is_published_plan == 1">{{ item.title }}</td>
                            <td scope="row" :colspan="key == 'Required' ? 2 : 1" class="handle"
                                v-else-if="item.url.length == 1"><a :href="'/planning/' + plan.url + '/get_initiative/' + item.url[0]">{{ item.title }}</a></td>
                            <td scope="row" :colspan="key == 'Required' ? 2 : 1" class="handle" v-else><a :href="'/planning/' + plan.url + '/get_initiative_type/' + item.url[0]">{{ item.title }}</a></td>
                            <td class="priority" v-if="key != 'Required'">{{ item.priority }}</td>
                            <td class="cost-type"><span>Project</span><span v-if="hasMedia(item.media)">Media</span></td>
                            <template v-for="(months, year) in calendar_headers">
                            <td scope="col" v-for="month in months" class="cost-type"
                                :class="initiativeClasses(item, month, year)" :data-month="month"
                                :data-year="year" :data-id="(initiativeIndex(item, month, year) !== false) ? item.id[initiativeIndex(item, month, year)] : ''"
                                @mousedown="dragStart" @mouseenter="mouseDraggedOrExpanded"
                                @mouseup="mouseDraggedOrExpandedStop">

                                <template v-if="initiativeIndex(item, month, year) !== false">
                                    <span v-if="isFirstMonthForInitiative(item, month, year)">{{ formatShortMoney(item.project[initiativeIndex(item, month, year)]+item.retainer[initiativeIndex(item, month, year)]) }}</span>
                                    <span v-else>{{ formatShortMoney(item.retainer[initiativeIndex(item, month, year)]) }}</span>
                                    <span v-if="hasMedia(item.media)">{{ formatShortMoney(item.media[initiativeIndex(item, month, year)]) }}</span>
                                    <span v-if="isLastMonthForInitiative(item, month, year) && !isOneTimeInitiative(item) && plan.is_published_plan != 1" class="stretch"
                                        @mousedown="stretchStart"><span></span></span>
                                    <button v-if="plan.is_published_plan != 1 && isFirstMonthForInitiative(item, month, year)" class="tiny_button copy"
                                        v-on:click.prevent="copyInitiative(item, month, year)"><i class="fas fa-copy"></i></button>
                                    <button v-if="plan.is_published_plan != 1 && isFirstMonthForInitiative(item, month, year)" class="tiny_button delete"
                                        v-on:click.prevent="deleteInitiative(item, month, year)"><i class="fas fa-trash"></i></button>
                                    <a v-if="plan.is_published_plan != 1 && isFirstMonthForInitiative(item, month, year)" class="tiny_button edit"
                                        :href="'/planning/' + plan.url + '/get_initiative/' + item.url[initiativeIndex(item, month, year)]"><i class="fas fa-edit"></i></a>
                                </template>

                            </td>
                            </template>
                        </tr>
                        <tr class="drop" v-if="(!val.length) && show_zones"><td colspan="16"><span>{{key}}</span></td></tr>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <th rowspan="2" class="bare"></th>
                        <th rowspan="2" style="text-align: center; vertical-align: middle; ">Totals</th>
                        <th>Project</th>
                        <template v-for="(months, year) in calendar_headers">
                           <td v-for="month in months" :class="{'disabled': isInThePast(month, year) }">{{ formatShortMoney(getTotalByDate(month, year, 'project') + getTotalByDate(month, year, 'retainer')) }}</td>
                        </template>
                    </tr>
                    <tr>
                        <th class="media-total">Media</th>
                        <template v-for="(months, year) in calendar_headers">
                            <td v-for="month in months" :class="{'disabled': isInThePast(month, year), 'media-total':1 }">{{ formatShortMoney(getTotalByDate(month, year, 'media')) }}</td>
                        </template>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import moment from 'moment';
    import Swal from 'sweetalert2';
    export default {
        components: {
            draggable, Swal
        },
        props: ['plan', 'client', 'init_types', 'current_month', 'current_year'],
        data() {
            return {
                month_names: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
                show_zones: false,
                list: [],
                dragged_item: null,
                stretched_item: null,
                should_save: false,
                moved_bucket: false,
                processing: false
            };
        },
        mounted() {

            this.populateInitiativeList(this.plan);
        },
        updated() {
            this.addIsEmptyClass();

        },
        computed: {

            all_initiatives() {
                var inits = {};
                inits['Urgent'] = this.list.filter(item => item.priority == 'Urgent');
                inits['Critical'] = this.list.filter(item => item.priority == 'Critical');
                inits['Elevated'] = this.list.filter(item => item.priority == 'Elevated');
                inits['Recommended'] = this.list.filter(item => item.priority == 'Recommended');
                inits['Required'] = this.list.filter(item => item.priority == 'Required');

                return inits;

            },

            future_date() {
                return moment().set({ 'year': this.current_year, 'month': this.current_month }).add(11, 'M').format('MMM YYYY');
            },

            calendar_headers() {
                var headers = {};

                if (this.current_month == null || this.current_year == null)
                    return headers;

                let startDate = moment().set({ 'year': this.current_year, 'month': this.current_month });
                let endDate = moment().set({ 'year': this.current_year, 'month': this.current_month }).add(11, 'M')
                while (endDate.isSameOrAfter(startDate)) {
                    if (!headers[startDate.year()])
                        headers[startDate.year()] = [];

                    headers[startDate.year()].push(startDate.month());
                    //increment by one day
                    startDate = startDate.add(1, 'M')
                }

                return headers;
            }
        },
        methods: {
            isOneTimeInitiative(init) {
                for(var i = 0; i < this.init_types.length; i++){
                    if(this.init_types[i].id == init.initiative_type_id){
                        return this.init_types[i].calc_type == "one_time";
                    }
                }
                return false;
            },
            populateInitiativeList(plan) {
                this.list = [];
                for(var i = 0; i < plan.initiatives.length; i++) {
                    var init = plan.initiatives[i];
                    this.list.push(
                        { id: [init.id],
                            title: init.title,
                            priority: init.priority,
                            initiative_type_id: init.initiative_type_id,
                            url: [init.url],
                            media: [this.calcCost(init.costs, 'media')],
                            project: [this.calcCost(init.costs, 'one_time')],
                            retainer: [this.calcCost(init.costs, 'monthly')],
                            start_month: [init.start_month],
                            end_month: [init.end_month],
                            order: [init.order]
                        }
                    );
                    //Keep looking ahead while the titles are the same
                    searchLoop:
                    for(var j = i+1; j< plan.initiatives.length && plan.initiatives[j].title == plan.initiatives[i].title && plan.initiatives[j].priority == plan.initiatives[i].priority; j++) {

                        var sub_init = plan.initiatives[j];

                        //We also can't add it to the list if it overlaps on dates from some of the other initiatives in the group
                        var sub_init_start_date = this.getJSDate(sub_init.start_month);
                        var sub_init_end_date = this.getJSDate(sub_init.end_month);

                        dateLoop:
                        for(var k = 0; k < this.list[this.list.length-1].id.length; k++){
                            var tmp_start_date = this.getJSDate(this.list[this.list.length-1].start_month[k]);
                            var tmp_end_date = this.getJSDate(this.list[this.list.length-1].end_month[k]);

                            //If the start date is in the middle of another initiative
                            if(sub_init_start_date >= tmp_start_date && sub_init_start_date <= tmp_end_date ||
                                //If the end date is in the middle of another initative
                                sub_init_end_date >= tmp_start_date && sub_init_end_date <= tmp_end_date ||
                                //If another initative is in the middle of this one
                                sub_init_start_date <= tmp_start_date && sub_init_end_date >= tmp_end_date
                                 ) break searchLoop;
                        }


                        this.list[this.list.length-1].id.push(sub_init.id);
                        this.list[this.list.length-1].url.push(sub_init.url);
                        this.list[this.list.length-1].media.push(this.calcCost(sub_init.costs, 'media'));
                        this.list[this.list.length-1].project.push(this.calcCost(sub_init.costs, 'one_time'));
                        this.list[this.list.length-1].retainer.push(this.calcCost(sub_init.costs, 'monthly'));
                        this.list[this.list.length-1].start_month.push(sub_init.start_month);
                        this.list[this.list.length-1].end_month.push(sub_init.end_month);
                        this.list[this.list.length-1].order.push(sub_init.order);

                        i++;
                    }
                }
            },
            calcCost (costs, type) {
                var amount = 0;
                for(var i = 0; i < costs.length; i++) {
                    if(costs[i].type == type && !isNaN(costs[i].value))
                        amount += parseFloat(costs[i].value);
                }
                return  parseFloat(amount);
            },

            formatShortMoney(amount) {
                if(parseFloat(amount) == 0)
                    return "";
                return numeral(amount).format('$0[.]0a', Math.floor);
            },

            initiativeClasses(item, month, year) {
                return {
                    'disabled': this.isInThePast(month, year),
                    'selected': (this.initiativeIndex(item, month, year)!== false)
                }
            },

            isInThePast(month, year) {
                var timeframe = new Date(year, month, 1);
                var now = new Date();

                return timeframe <= now;
                //return moment().set({ 'year': year, 'month': month }).isBefore(moment());
            },

            getTotalByDate(month, year, type) {

                var total = 0;
                for(var i = 0; i < this.list.length; i++)
                    for(var j = 0; j < this.list[i].id.length; j++) {
                        var date = new Date(Date.UTC(year,month));
                        var start_date = this.getJSDate(this.list[i].start_month[j]);
                        var end_date = this.getJSDate(this.list[i].end_month[j]);

                        if(type == "retainer" && date >= start_date && date <= end_date)
                            total += this.list[i].retainer[j];
                        else if(type == 'project' && date >= start_date && date <= start_date)
                            total += this.list[i].project[j];
                        else if(type == 'media' && date >= start_date && date <= end_date)
                            total += this.list[i].media[j];
                    }
                return total;
            },
            hasMedia(arr) {
                for(var i = 0; i < arr.length; i++) {
                    if(arr[i] > 0) return true;
                }
                return false;
            },
            initiativeIndex(item, month, year) {
                var current = new Date(Date.UTC(year,month));

                //Loop through all of the initiatives in this row entry
                for(var i = 0; i < item.id.length; i++){
                    var start = this.getJSDate(item.start_month[i]);
                    var end = this.getJSDate(item.end_month[i]);

                    if (current >= start && current <= end) return i;
                }

                return false;
            },
            getJSDate(str_date) {
                var part = str_date.split('T')[0];
                var date = new Date(Date.UTC(part.split("-")[0], parseInt(part.split("-")[1])-1, part.split("-")[2]));

                return date;
            },
            isLastMonthForInitiative(item, month, year) {
                var current = new Date(Date.UTC(year, month));
                //Loop through all of the initiatives in this row entry
                for(var i = 0; i < item.id.length; i++){

                    var end = this.getJSDate(item.end_month[i]);
                    //Need to compare the string value since it was apparently comparing the pointer value
                    if( current.getUTCMonth() == end.getUTCMonth() &&  current.getUTCFullYear() == end.getUTCFullYear() )
                        return true;
                }
                return false;
            },
            isFirstMonthForInitiative(item, month, year) {
                var current = new Date(Date.UTC(year, month));
                //Loop through all of the initiatives in this row entry
                for(var i = 0; i < item.id.length; i++){
                    var start = this.getJSDate(item.start_month[i]);

                    //Need to compare the string value since it was apparently comparing the pointer value
                    if( current.getUTCMonth() == start.getUTCMonth() &&  current.getUTCFullYear() == start.getUTCFullYear())
                        return true;
                }
                return false;
            },
            moveDatesBackward() {
                var timeframe = moment().set({ 'year': this.current_year, 'month': this.current_month }).subtract(12, 'M');

                this.$emit('update:current_month', timeframe.month());
                this.$emit('update:current_year', timeframe.year());

                this.addIsEmptyClass();
            },

            moveDatesForward() {
                var timeframe = moment().set({ 'year': this.current_year, 'month': this.current_month }).add(12, 'M');

                this.$emit('update:current_month', timeframe.month());
                this.$emit('update:current_year', timeframe.year());

                this.addIsEmptyClass();
            },
            copyYear() {

                this.processing = true;
                var self = this;
                var vars = {
                    year: this.current_year,
                    month: this.current_month,
                    plan: this.plan
                }

                window.axios.post('/api/initiative/clone_year', vars)
                  .then(response => {

                    if(response.status == 200) {
                        self.$emit('update:plan', response.data.plan)
                        self.$emit('notificationEvent', {
                            type: 'success',
                            message: '<strong>Success:</strong> The year was successfully copied to the first empty year in the plan.'
                        });
                        self.populateInitiativeList(response.data.plan);
                        self.processing = false;
                    }
                    else {
                        self.$emit('notificationEvent', {
                            type: 'warning',
                            message: '<strong>Warning:</strong> The current year could not be copied due to a system error.'
                        });

                        self.processing = false;
                    }
                  });
            },
            copyInitiative(item, month, year) {

                var index = this.initiativeIndex(item, month, year);
                var self = this;
                window.axios.post('/api/initiative/clone/'+ item.url[index])
                  .then(response => {

                    self.$emit('update:plan', response.data.plan)

                    if(response.status == 200) {

                        //Update the UI dataset ID for dragging and expanding
                        var els = document.getElementsByClassName('init-row');
                        for(var i = 0; i < els.length; i++) {
                            if(els[i].dataset.id == item.id.join(",")) {
                                els[i].dataset.id += "," + response.data.initiative.id;
                                break;
                            }
                        }

                        item.id.push(response.data.initiative.id);
                        item.url.push(response.data.initiative.url);
                        item.media.push(self.calcCost(response.data.initiative.costs, 'media'));
                        item.project.push(self.calcCost(response.data.initiative.costs, 'one_time'));
                        item.retainer.push(self.calcCost(response.data.initiative.costs, 'monthly'));
                        item.start_month.push(response.data.initiative.start_month);
                        item.end_month.push(response.data.initiative.end_month);
                        item.order.push(response.data.initiative.order);


                    }

                  });

            },

            deleteInitiative(item, month, year) {
                var index = this.initiativeIndex(item, month, year);
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this initiative?",
                  text: "The initiative will be removed from the current annual plan.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/initiative/' + item.id[index])
                        .then(response => {
                            if (response.status == 200) {
                                //If there is only one item, remove it
                                if(item.id.length == 1) {
                                    for(var i = 0; i < self.list.length; i++)
                                        if(self.list[i] == item) {
                                            self.list.splice(i, 1);
                                            break;
                                        }
                                }
                                //There are others, just remove this one initiative
                                else {
                                    //Update the UI dataset ID for dragging and expanding functionality of the remaining items
                                    var els = document.getElementsByClassName('init-row');
                                    for(var i = 0; i < els.length; i++) {
                                        var arr = els[i].dataset.id.split(",");
                                        //If this is the row...
                                        if(arr.includes("" + item.id[index])) {
                                            arr.splice(arr.indexOf("" + item.id[index]), 1);
                                            els[i].dataset.id = arr.join(",");
                                            break;
                                        }
                                    }

                                    item.id.splice(index, 1);
                                    item.url.splice(index, 1);
                                    item.media.splice(index, 1);
                                    item.project.splice(index, 1);
                                    item.retainer.splice(index, 1);
                                    item.start_month.splice(index, 1);
                                    item.end_month.splice(index, 1);
                                    item.order.splice(index, 1);

                                }

                                //Now update the "plan" to remove the initiative
                                self.$emit('update:plan', response.data.plan)
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });
            },

            enterNewDropZone(e, type) {
                if (e.hasOwnProperty('added')){
                    //Remove it from the list
                    for(var i = 0; i < this.list.length; i++)
                        if(this.list[i] == e.added.element) {
                            this.list.splice(i, 1);
                            break;
                        }

                    var inserted = false;
                    e.added.element.priority = type;

                    //Loop through the list until I find the first "type" element"
                    for(var i = 0; i < this.list.length; i++) {
                        if(this.list[i].priority == type || this.isGreaterPriority(this.list[i].priority, type)) {
                            //Then insert it before that element

                            for(var j = 0; j < e.added.element.order.length; j++)
                                e.added.element.order[j] = i+e.added.newIndex+1+j;

                            this.list.splice(i+e.added.newIndex, 0, e.added.element);

                            //Set the order and the priority
                            i += e.added.newIndex;

                            inserted = true;
                            break;
                        }
                    }
                    if(!inserted){
                        for(var j = 0; j < e.added.element.order.length; j++)
                            e.added.element.order[j] = this.list.length+j;
                        this.list.push(e.added.element);
                    }

                    //Now update the actual initiative and save it to the DB
                    for(var j = 0; j < e.added.element.id.length; j++){
                        var index = this.getInitiativeIndex(e.added.element.id[j]);
                        this.plan.initiatives[index].priority = type;
                        this.plan.initiatives[index].order = e.added.element.order[j];
                    }
                    //Save the new order to the DB
                    var vars = {
                        initiatives: this.plan.initiatives
                    }
                    var self = this;
                    window.axios.post('/api/initiative/update_group', vars).then(response => {
                        if (response.status == 200) {
                            //Now update the "plan" to remove the initiative
                            self.$emit('update:plan', response.data.plan)
                        }
                    });
                    this.moved_bucket = true;
                }
            },

            startSort(e) { this.show_zones = true; },

            endSort(e) {
                //If it wasn't moved between the various buckets...
                if(!this.moved_bucket) {
                    //Find the current position
                    for(var i = 0; i < this.list.length; i++)
                        if(this.intArrayEquals(this.list[i].id, e.item.dataset.id.split(",")) ) {
                            //Remove it from the current list
                            var list_item = this.list[i];
                            this.list.splice(i, 1);

                            //And add it into their new position
                            this.list.splice(i+(e.newIndex - e.oldIndex), 0, list_item);
                            break;
                        }
                }

                //Now determine if we need to combine any of the "list" initiatives
                for(var i = this.list.length-1; i >= 1 ; i--) {
                    //If the title and priority are identical
                    if(this.list[i].priority == this.list[i-1].priority && this.list[i].title == this.list[i-1].title) {
                        this.list[i].id = this.list[i].id.concat(this.list[i-1].id);
                        this.list[i].url = this.list[i].url.concat( this.list[i-1].url);
                        this.list[i].media = this.list[i].media.concat(this.list[i-1].media);
                        this.list[i].project = this.list[i].project.concat(this.list[i-1].project);
                        this.list[i].retainer = this.list[i].retainer.concat(this.list[i-1].retainer);
                        this.list[i].start_month = this.list[i].start_month.concat( this.list[i-1].start_month);
                        this.list[i].end_month = this.list[i].end_month.concat( this.list[i-1].end_month);
                        this.list[i].order = this.list[i].order.concat( this.list[i-1].order);

                        //Now remove it from the array and jump over the old position
                        this.list.splice(i-1, 1);
                        i--;
                    }
                }

                this.show_zones = false;
                this.moved_bucket = false;
                var order = 1;
                //Now, loop through the whole thing to adjust the order number
                for(var i = 0; i < this.list.length; i++) {
                    for(var j = 0; j < this.list[i].id.length; j++){
                        if(this.list[i].order[j] != order) {
                            //Get the initiative
                            var index = this.getInitiativeIndex(this.list[i].id[j]);
                            this.plan.initiatives[index].order = order;
                            this.list[i].order[j] = order;
                        }
                        order++;
                    }
                }

                //Save the new order to the DB
                var vars = {
                    initiatives: this.plan.initiatives
                }
                var self = this;
                window.axios.post('/api/initiative/update_group', vars).then(response => {
                    if (response.status == 200) {
                        //Now update the "plan" to remove the initiative
                        self.$emit('update:plan', response.data.plan)
                    }
                });
            },
            intArrayEquals(a, b) {
              return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => parseInt(val) === parseInt(b[index]));
            },
            dragStart(ev) {

                if(!ev.currentTarget.classList.contains("selected") || this.stretched_item != null
                    || this.plan.is_published_plan == 1) return;

                //Record the item being dragged
                this.dragged_item = ev.currentTarget;
                ev.preventDefault();
            },
            stretchStart(ev) {
                //Record the item being expanded
                this.stretched_item = ev.currentTarget.parentNode;
                ev.preventDefault();
            },
            mouseDraggedOrExpanded(ev) {
                if(this.dragged_item == null && this.stretched_item == null) return;

                //Get the ID of the initative based upon the row
                var item_id = (this.dragged_item == null) ?
                    this.stretched_item.dataset.id : this.dragged_item.dataset.id;

                //If the mouse moved outside of the current row, stop everything!
                if(ev.srcElement == null || ev.srcElement.closest("tr").dataset.id == null ||
                    ev.srcElement.closest("tr").dataset.id.indexOf(item_id) == -1)
                    return;

                //Now look through the actual list and find the one being dragged
                for(var i = 0; i < this.list.length; i++) {
                    for(var j = 0; j < this.list[i].id.length; j++){
                        if(this.list[i].id[j] == item_id) {
                            //Get the month (saved as a dataset element on the table cell)
                            var month = parseInt(ev.srcElement.dataset.month);
                            //Make a date based upon where it is being dragged into
                            var new_date = new Date(Date.UTC(parseInt(ev.srcElement.dataset.year), month));
                            var start_date = this.getJSDate(this.list[i].start_month[j]);

                            //If I'm dragging it around instead of stretching
                            if(this.dragged_item != null) {

                                //Determine how many months were moved. Should be +1 or -1 but wanted to be safe in case they jump around
                                var month_diff = this.monthDiff(start_date, new_date);

                                var js_date = this.getJSDate(this.list[i].end_month[j]);
                                var new_end_date = this.addMonths(js_date, month_diff);

                                //If the new start date (and end date) falls in the middle of one of the other initatives, cancel the move
                                for(var k = 0; k < this.list[i].id.length; k++){
                                    if(k == j) continue; //Don't compare against myself
                                    var tmp_start_date = this.getJSDate(this.list[i].start_month[k]);
                                    var tmp_end_date = this.getJSDate(this.list[i].end_month[k]);

                                    //If the start date is in the middle of another initiative
                                    if(new_date >= tmp_start_date && new_date <= tmp_end_date ||
                                        //If the end date is in the middle of another initative
                                        new_end_date >= tmp_start_date && new_end_date <= tmp_end_date ||
                                        //If another initative is in the middle of this one
                                        new_date <= tmp_start_date && new_end_date >= tmp_end_date
                                         ) return;

                                }

                                //If they have moved to a new month, denote that it needs to be saved to the database
                                if(!this.should_save && month_diff != 0)
                                    this.should_save = true;

                                this.list[i].start_month[j] = new_date.toISOString();
                                this.list[i].end_month[j] = new_end_date.toISOString();

                                this.$forceUpdate();

                                //It was just moved, so I need to find the new location of the "dragged_item"
                                for(var k = 0; k < Math.abs(month_diff); k++)
                                    if(month_diff < 0) //I moved it to the left
                                        this.dragged_item = this.dragged_item.previousSibling;
                                    else
                                        this.dragged_item = this.dragged_item.nextSibling;
                            }
                            //I'm stretching it
                            else {
                                //Prevent the billing end is moved before the start month
                                if(new_date < start_date) return;

                                var end_date = this.getJSDate(this.list[i].end_month[j]);

                                //Determine how many months were moved. Should be +1 or -1 but wanted to be safe in case they jump around
                                var month_diff = this.monthDiff(end_date, new_date);

                                //If the month_diff is positive, make sure it doesn't fall between the other initiatives on the row
                                if(month_diff > 0) {
                                    for(var k = 0; k < this.list[i].id.length; k++){
                                        if(k == j) continue; //Don't compare against myself
                                        var tmp_start_date = this.getJSDate(this.list[i].start_month[k]);
                                        var tmp_end_date = this.getJSDate(this.list[i].end_month[k]);

                                        if(new_date == tmp_start_date ||
                                             new_date >= tmp_end_date && start_date < tmp_start_date ) return;

                                    }
                                }

                                this.list[i].end_month[j] = new_date.toISOString();

                                this.$forceUpdate();

                            }

                            return;

                        }
                    }
                }
            },
            mouseDraggedOrExpandedStop(ev) {
                var list_item = null;

                if(this.dragged_item != null && this.should_save) {
                    //Get the initative ID
                    var init_id = this.dragged_item.dataset.id;
                    //Find the "list" item
                    for(var i = 0; i < this.list.length; i++)
                        for(var j = 0; j < this.list[i].id.length; j++)
                            if(this.list[i].id[j] == init_id) {

                                var index = this.getInitiativeIndex(this.list[i].id[j]);
                                //Update the dates
                                this.plan.initiatives[index].start_month = this.list[i].start_month[j];
                                this.plan.initiatives[index].end_month = this.list[i].end_month[j];

                                //Send to the api
                                window.axios.put('/api/initiative/' + init_id, this.plan.initiatives[index]);
                                break;
                            }

                    this.should_save = false;
                //If I was stretching the item
                } else if (this.stretched_item != null) {
                    //If I reduced the end date, the ID will not appear on the source column anymore
                    if(this.stretched_item.dataset.id == "") {
                        this.stretched_item = this.stretched_item.previousSibling;
                    }

                    //Save the stretched item
                    for(var i = 0; i < this.list.length; i++)
                        for(var j = 0; j < this.list[i].id.length; j++)
                            if(this.list[i].id[j] == this.stretched_item.dataset.id) {
                                var index = this.getInitiativeIndex(this.list[i].id[j]);
                                //Update the date
                                this.plan.initiatives[index].end_month = this.list[i].end_month[j];

                                //Send to the api
                                window.axios.put('/api/initiative/' + this.stretched_item.dataset.id, this.plan.initiatives[index]);
                                break;
                            }
                }

                this.stretched_item = null;
                this.dragged_item = null;
            },

            monthDiff(dateFrom, dateTo) {
                return dateTo.getUTCMonth() - dateFrom.getUTCMonth() +
                    (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear()))
            },
            addMonths(date, months) {
                //This function will add a month but also accounts for issues where the number of days
                // differs between months which caused it to not add a full month
                var d = date.getUTCDate();
                date.setUTCMonth(date.getUTCMonth() + months);
                if (date.getUTCDate() != d) {
                    date.setUTCDate(0);
                }
                return date;
            },
            getInitiativeIndex(id) {
                for(var i = 0; i < this.plan.initiatives.length; i++)
                    if(this.plan.initiatives[i].id == id)
                        return i;

                return -1;
            },
            isGreaterPriority(current_priority, target_priority) {
                if(target_priority == 'Urgent') return true;

                if(target_priority == 'Critical' && current_priority != 'Urgent') return true;

                if(target_priority == 'Elevated' && current_priority != 'Urgent' && current_priority != 'Critical')
                    return true;

                if(target_priority == 'Recommended' && current_priority != 'Urgent' && current_priority != 'Critical' && current_priority != 'Elevated')
                    return true;

                return false;
            },
            addIsEmptyClass() {
                var els = document.getElementsByClassName('init-row');
                for(var i = 0; i < els.length; i++) {
                    if(els[i].getElementsByClassName('selected').length == 0) {
                        els[i].classList.add("is-empty");
                    }
                    else
                        els[i].classList.remove("is-empty");
                }
            }
        }
    }
</script>
<style>

</style>
