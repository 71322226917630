var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manage-users client-user" }, [
    this.local_users.length > 1
      ? _c("div", [
          _c("h1", { staticClass: "mb-2" }, [_vm._v("Agency Users")]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-3" }, [
            _vm._v(
              'The users below are those associated with your agency. Unless they are set as an "Agency Admin," they will only have access to clients they are associated with. When you delete a user, they will lose access to all clients.'
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card box-content" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Select a user"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user_pos,
                            expression: "user_pos",
                          },
                        ],
                        staticClass: "form-select",
                        attrs: { name: "user" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.user_pos = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.clearErrors()
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.local_users, function (u, pos) {
                        return _c("option", { domProps: { value: pos } }, [
                          _vm._v(
                            _vm._s(
                              pos == 0
                                ? "Add a New User"
                                : u.name +
                                    (u.agency != null
                                      ? "(" + u.agency.name + ")"
                                      : "")
                            )
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c("div", [
          _c("h1", { staticClass: "mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.user_pos > 0 ||
                  (_vm.local_users.length == 1 && _vm.local_users[0].id > 0)
                  ? "Update"
                  : "Add"
              ) + " Team Member"
            ),
          ]),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v(" User Information")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-8" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("First Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_users[_vm.user_pos].first_name,
                      expression: "local_users[user_pos].first_name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.first_name != "" ? "is-invalid" : "",
                  attrs: { type: "text", name: "first_name" },
                  domProps: { value: _vm.local_users[_vm.user_pos].first_name },
                  on: {
                    change: function ($event) {
                      _vm.errors.first_name = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_users[_vm.user_pos],
                        "first_name",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.first_name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.errors.first_name) +
                          "\n                            "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Last Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_users[_vm.user_pos].last_name,
                      expression: "local_users[user_pos].last_name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.last_name != "" ? "is-invalid" : "",
                  attrs: { type: "text", name: "last_name" },
                  domProps: { value: _vm.local_users[_vm.user_pos].last_name },
                  on: {
                    change: function ($event) {
                      _vm.errors.last_name = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_users[_vm.user_pos],
                        "last_name",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.last_name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.errors.last_name) +
                          "\n                            "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            Array.isArray(_vm.agencies)
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Agency"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_users[_vm.user_pos].agency_id,
                          expression: "local_users[user_pos].agency_id",
                        },
                      ],
                      staticClass: "form-select",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.local_users[_vm.user_pos],
                            "agency_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.agencies, function (a) {
                      return _c("option", { domProps: { value: a.id } }, [
                        _vm._v(_vm._s(a.name)),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_users[_vm.user_pos].email,
                    expression: "local_users[user_pos].email",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.email != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "email" },
                domProps: { value: _vm.local_users[_vm.user_pos].email },
                on: {
                  change: function ($event) {
                    return _vm.validateEmail()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local_users[_vm.user_pos],
                      "email",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.email != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.email) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("User Role")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_users[_vm.user_pos].user_type,
                      expression: "local_users[user_pos].user_type",
                    },
                  ],
                  staticClass: "form-select",
                  class: _vm.errors.user_type != "" ? "is-invalid" : "",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_users[_vm.user_pos],
                          "user_type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        _vm.errors.user_type = ""
                      },
                    ],
                  },
                },
                [
                  _vm.user.user_type == "admin"
                    ? _c("option", { attrs: { value: "admin" } }, [
                        _vm._v("Avid Admin"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "agency_admin" } }, [
                    _vm._v("Admin"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "user" } }, [_vm._v("User")]),
                ]
              ),
              _vm._v(" "),
              _vm.errors.user_type != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.user_type) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col profile-image" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Profile Image"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    attrs: {
                      "data-name": _vm.local_users[_vm.user_pos].id,
                      id: "user_id",
                    },
                  }),
                  _vm._v(" "),
                  _c("vue-dropzone", {
                    ref: "'dropzone'",
                    staticClass: "profile-dropzone",
                    style:
                      "background-image: url(" +
                      _vm.local_users[_vm.user_pos].image +
                      ")",
                    attrs: {
                      id: "dropzone",
                      options: _vm.dropzoneOptions,
                      awss3: _vm.awss3,
                    },
                    on: { "vdropzone-s3-upload-success": _vm.s3UploadSuccess },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Clients")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("Client Name")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.role.client,
                    expression: "role.client",
                  },
                ],
                staticClass: "form-select",
                class: _vm.errors.clients != "" ? "is-invalid" : "",
                attrs: { id: "client-select", placeholder: "Client" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.role,
                      "client",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: {
                      value: "",
                      disabled: "",
                      selected: "",
                      hidden: "",
                    },
                  },
                  [_vm._v("Select the client...")]
                ),
                _vm._v(" "),
                _vm._l(_vm.clients, function (i) {
                  return _c("option", { domProps: { value: i.id } }, [
                    _vm._v(_vm._s(i.name)),
                  ])
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c("label", { staticClass: "form-label w-100" }, [_vm._v(" ")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addRole.apply(null, arguments)
                  },
                },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.errors.clients != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.clients) +
                  "\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.local_users[_vm.user_pos].clients.length > 0
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col" },
                _vm._l(
                  _vm.local_users[_vm.user_pos].clients,
                  function (client) {
                    return _c(
                      "span",
                      { staticClass: "label label-default tag" },
                      [
                        _c("i", {
                          staticClass: "fa fa-remove me-2 ms-0",
                          attrs: { id: "remove-role" },
                          on: {
                            click: function ($event) {
                              return _vm.removeClient(client.id)
                            },
                          },
                        }),
                        _vm._v(_vm._s(client.name) + "\n                    "),
                      ]
                    )
                  }
                ),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.successful
          ? _c("div", { staticClass: "alert alert-success mb-3" }, [
              _vm._v(
                "\n                The user's information has been updated!\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
              _vm._v(
                "\n                There was an error saving your information.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-big",
              attrs: { type: "submit", disabled: _vm.form.busy },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.user_pos > 0 ||
                      (_vm.local_users.length == 1 && _vm.local_users[0].id > 0)
                      ? "Update"
                      : "Add Team Member"
                  ) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.user_pos > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-big ms-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteUser()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-trash" }),
                  _vm._v(" Delete"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.local_users.length == 1
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-big ms-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("Cancel")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }