var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calendar-view" }, [
    _c(
      "div",
      { staticClass: "calendar-grid" },
      _vm._l(_vm.months, function (month, monthIndex) {
        return _c("div", { key: monthIndex, staticClass: "month" }, [
          _c("div", { staticClass: "month-name" }, [
            _vm._v(_vm._s(month.name)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "days" },
            _vm._l(month.days, function (day, dayIndex) {
              return _c(
                "div",
                {
                  key: dayIndex,
                  staticClass: "day",
                  class: { "launch-date": _vm.isLaunchDate(day) },
                  on: {
                    dragover: function ($event) {
                      $event.preventDefault()
                      return _vm.onDragOver.apply(null, arguments)
                    },
                    drop: function ($event) {
                      return _vm.onDrop(day)
                    },
                  },
                },
                [
                  _vm.isLaunchDate(day)
                    ? _c("div", { staticClass: "launch-label" }, [
                        _vm._v("End Date"),
                      ])
                    : _c("div", { staticClass: "date-number" }, [
                        _vm._v(_vm._s(day.date)),
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "assets-container" },
                    _vm._l(day.assets, function (asset, assetIndex) {
                      return _c("div", {
                        key: asset.pivot.id,
                        staticClass: "asset",
                        style: { backgroundColor: _vm.getAssetColor(asset) },
                        attrs: {
                          draggable: "true",
                          "data-bs-toggle": "tooltip",
                          "data-bs-html": "true",
                          title:
                            asset.pivot.name +
                            "<br>(" +
                            (asset.name.endsWith("s")
                              ? asset.name.slice(0, -1)
                              : asset.name) +
                            ")",
                        },
                        on: {
                          dragstart: function ($event) {
                            return _vm.onDragStart(asset, day)
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "asset-key" },
      _vm._l(_vm.getUniqueAssetTypes(), function (assetType, index) {
        return _c("div", { key: assetType.id, staticClass: "key-item" }, [
          _c("div", {
            staticClass: "color-box",
            style: { backgroundColor: _vm.getAssetColor(assetType) },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(assetType.name))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }