<template>
    <div class="chart-builder">
        <div class="card box-content my-3">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col">
                        <label class="form-label">Chart Name</label>
                        <input type="text" id="chart-name-input" class="form-control" v-model="local_chart.name"
                                :class="((errors.name != '' )? 'is-invalid':'')"  @change="clearErrorForField('name')">
                        <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                            {{errors.name }}
                        </div>
                    </div>
                    <div class="col-sm-3"  v-if="local_chart.id==null">
                        <label class="form-label">Client <em>(Optional)</em></label>
                        <select class="form-select" id="client-select" v-model="local_client"
                            v-on:change="updateDataBlend()" >
                            <option v-for="i in clients" :value="i">{{i.name}}</option>
                        </select>
                    </div>
                    <div class="col-sm-3" v-if="local_chart.id==null">
                        <label class="form-label">Data Blend Type</label>
                        <select class="form-select" v-model="local_chart.data_blend_type" v-on:change="updateDataBlend()">
                            <option  v-for="i in local_client.data_blends" :value="i.type">{{ i.type | propercase }}</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-3">

                    <div class="col">
                        <label class="form-label">Chart Title</label>
                        <input type="text" id="title-input" class="form-control" v-model="local_chart.title">
                    </div>
                    <div class="col">
                        <label class="form-label">{{(this.local_chart.type=='scorecard'? 'Image URL': 'Chart Subtitle')}} <em>(Often phrased as a question)</em></label>
                        <input type="text" id="subtitle-input" class="form-control" v-model="local_chart.subtitle">
                    </div>
                </div>
                <div class="row mb-3">

                    <div class="col">
                        <label class="form-label">Chart Description <em>(Used in search and as a <i class="fas fa-question-circle text-primary me-0"></i> icon next to chart title.)</em></label>
                        <textarea class="form-control" v-model="local_chart.description"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div :class="((render_large_chart)? 'col-12':'col-3')">
                <chart-configuration :chart="local_chart" :render_large_chart="render_large_chart" :data_blends_array="clients[0] && clients[0].data_blends ? clients[0].data_blends : []"
                    :errors="errors" :metrics_filters="metrics_filters" ref="chart_config"
                    @updateChartConfig="updateChartConfig" @updateChartData="updateChartData" @loadingChartData="loadingChartData"></chart-configuration>
            </div>
            <div :class="((render_large_chart)? 'col-12 mt-3':'col-9')">
                <chart-preview :chart_selected="local_chart" :chart_type="local_chart.type" :if_loading="is_loading_chart_data" :render_large_chart="render_large_chart"  @changePreviewSize="changePreviewSize"></chart-preview>
            </div>
        </div>
       
        <div class="row">
            <div class="col">
                <div class="alert alert-danger mt-3" v-if="form.error">
                    There was an error saving the chart details.
                </div>
                <!-- Update Button -->
                <div class="mt-3 mb-5">
                    <button type="submit" class="btn btn-primary"
                            @click.prevent="update" :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> {{ (this.local_chart.id != null ? "Update" : "Add" ) }}
                    </button>
                    <a href="/insights/charts" class="btn btn-secondary ms-3">
                        <i class="fa fa-btn fa-close"></i> Cancel
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },
        props: ['chart', 'metrics_filters', 'clients'],
        data() {
            return {
                local_chart: null,
                local_client: [],
                render_large_chart: false,
                form: {
                    busy: false,
                    error: false
                },
                is_loading_chart_data: false,
                errors :{
                    name:'',
                    title:'',
                    subtitle:'',
                    chart_type:'',
                    chart_size:'',
                    group_by:'',
                    metrics:'',
                    filters:'',
                    order_by:''
                }
            };
        },
        beforeMount() {
            this.local_chart = this.chart;
            //If it is a new chart
            if(this.local_chart.id == null){
                //Pick the first client (which is Global)
                this.local_client = this.clients[0];
                //Also pick the datablend based on type
                if (this.local_chart.data_blend_type){
                    var index = this.local_client.data_blends.findIndex((db) => db.type == this.local_chart.data_blend_type);
                    this.local_chart.data_blend_id = this.local_client.data_blends[index].id;
                    this.local_chart.data_blend = this.local_client.data_blends[index];                    
                } else {
                    //pick the first data blend when creating a new chart
                    this.local_chart.data_blend_type = this.local_client.data_blends[0].type;
                    this.local_chart.data_blend = this.local_client.data_blends[0];
                }
            }
            //If it is a template chart
            else if(this.local_chart.client_id == null){
                for(let i = 0; i < this.clients.length; i++){
                    for(let j = 0; j < this.clients[i].data_blends.length; j++){
                        //Find the first client that has the rigth data blend
                        if(this.local_chart.data_blend_type == this.clients[i].data_blends[j].type){
                            this.local_client = this.clients[i];
                            this.local_chart.data_blend = this.local_client.data_blends[j];
                            i = this.clients.length;
                            break;
                        }
                    }
                }
            }
            else {
                this.local_client = this.local_chart.client;
            }
        },
        methods: {
            changePreviewSize(size) {
                if (this.local_chart.type == 'textbox')
                    this.render_large_chart = true;
                else
                    this.render_large_chart = (size == 'xl');
            },
            updateChartConfig(chartConfig){
                this.local_chart.data = null;
                this.local_chart.type = chartConfig.type;
                if (chartConfig.type == 'textbox'){
                    this.render_large_chart = true;                    
                }
                else{
                    this.render_large_chart = false;
                }
                this.$forceUpdate();
            },
            updateChartData(chartData){
                this.local_chart.data = chartData.data;
                this.local_chart.labels = chartData.labels;
                this.local_chart.metrics = chartData.metrics;
                this.local_chart.group_by = chartData.group_by;
                this.local_chart.metric_formats = chartData.metric_formats;
                this.local_chart.html = chartData.html;
                this.is_loading_chart_data = false;
                this.$forceUpdate();
            },
            loadingChartData(is_loading){
                this.is_loading_chart_data = is_loading;
            },
            updateDataBlend(){
                this.local_chart.client = this.local_client;
                //If the new client doesn't have the same type of data blends available
                if(this.local_chart.data_blend_type == null){
                    this.local_chart.data_blend_type = this.local_client.data_blends[0].type;
                    //Copy this data blend
                    this.local_chart.data_blend = this.local_client.data_blends[0];
                    if(this.local_client.id != null){
                        this.local_chart.data_blend_id = this.local_chart.data_blend.id;
                        this.local_chart.client_id = this.local_client.id;
                    }
                    else this.local_chart.data_blend_id = null;
                }
                //Both are still good so just need to find the right data blend with the same type
                else {
                    for(let i = 0; i < this.local_client.data_blends.length; i++){
                        if(this.local_client.data_blends[i].type == this.local_chart.data_blend_type){
                            this.local_chart.data_blend = this.local_client.data_blends[i];
                            //If it is the global one
                            if(this.local_client.id != null){
                                this.local_chart.data_blend_id = this.local_chart.data_blend.id;
                                this.local_chart.client_id = this.local_client.id;
                            }
                            else this.local_chart.data_blend_id = null;

                            break;
                        }
                    }
                }
                this.local_chart = Object.assign({}, this.local_chart);
                // this.$refs.chart_config.refreshColumns();
            },
            clearErrorForField(field_name){
                this.errors[field_name] = '';
            },
            clearErrors() {//reset errors to empty strings
                this.errors = {
                    name:''
                }
            },
            hasErrors() {//validate the form

                if(this.local_chart.name == null || this.local_chart.name == ""){
                    this.errors.name = "A chart name is required.";
                } else
                    this.errors.name = "";
                
                // Validate the form in chart-configuration component
                let config_not_valid = this.$refs.chart_config.hasErrors();

                if (this.errors.name!="" || config_not_valid){
                    return true
                } else{
                    return false
                }
            },
            update() {
                this.clearErrors();

                //To trigger the refresh of the data source, I have to clone the chart but that disconnects
                // it from the one in the config. I'm sure there is a better way to do this but here we are.
                let chart = this.$refs.chart_config.getChart();
                this.local_chart.html = chart.html;
                this.local_chart.include_projections = chart.include_projections;
                this.local_chart.highlight_last_value = chart.highlight_last_value;
                this.local_chart.show_all_data_labels = chart.show_all_data_labels;
                this.local_chart.show_series_percentage = chart.show_series_percentage;
                this.local_chart.max_results = chart.max_results;
                this.local_chart.filter = chart.filter;
                this.local_chart.metrics = chart.metrics;
                this.local_chart.type = chart.type;
                this.local_chart.group_by = chart.group_by;

                this.updateText(this.local_chart.group_by);
                this.updateText(this.local_chart.metrics);
                
                if (this.local_chart.type != 'textbox'){
                    if(this.hasErrors()) {
                        this.form.error = true;
                        return;
                    }
                    this.local_chart.order_by = this.$refs.chart_config.getOrderBys();
                } else { // save the textbox content to html field instead of data 
                    if(this.local_chart.name == null || this.local_chart.name == ""){
                        this.errors.name = "A chart name is required.";
                        this.form.error = true;
                        return;
                    } else
                        this.errors.name = "";
                }

                this.form.busy = true;

                //Make a new chart
                if(this.local_chart.id == null) {
                    window.axios.post('/api/chart', this.local_chart)
                      .then(response => {
                        // //redirect back to the chart builder
                        document.location = "/insights/charts";
                      }).catch(error => {
                        this.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                      });
                } else {
                    window.axios.put('/api/chart/' + this.local_chart.id, this.local_chart)
                      .then(response => {
                        document.location = "/insights/charts";
                      }).catch(error => {
                        this.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                      });
                }
 
                //Now change them back
                if(this.local_chart.metrics && this.local_chart.metrics.length > 0)
                    for(var i = 0; i < this.local_chart.metrics.length; i++)
                        if(this.local_chart.metrics[i].admin_name)
                            this.local_chart.metrics[i].text = this.local_chart.metrics[i].admin_name;
                
                if(this.local_chart.group_by && this.local_chart.group_by.length > 0)
                    for(var i = 0; i < this.local_chart.group_by.length; i++){
                        if(this.local_chart.group_by[i].admin_name)
                            this.local_chart.group_by[i].text = this.local_chart.group_by[i].admin_name;
                    }
            },

            updateText(arr){
                if(arr && arr.length > 0)
                    for(var i = 0; i < arr.length; i++){
                        if (arr[i].display_name)
                            arr[i].text = arr[i].display_name;
                        //If value exists and is a string
                        else if(arr[i].value && typeof arr[i].value === 'string') {
                            //Split the value by . and then, take the last value and call the propercase filter
                            let split = arr[i].value.split('.');
                            //Replace _ with a space and capitalize the first letter of each word
                            let value = String(split[split.length-1]).replaceAll("_", " ");

                            value=  value.replace(
                                /\w\S*/g,
                                function(txt) {
                                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                });
                            arr[i].text = value;
                        }
                    }
            }
        }
    }
</script>
