var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manage-clients client-user mb-5" }, [
    _vm.client == null
      ? _c("div", [
          _c("h1", { staticClass: "mb-2" }, [_vm._v("Agency Clients")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card box-content" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Select a client"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.client_pos,
                          expression: "client_pos",
                        },
                      ],
                      staticClass: "form-select",
                      attrs: { name: "client" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.client_pos = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.changeClient()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.local_clients, function (c, pos) {
                      return _c("option", { domProps: { value: pos } }, [
                        _vm._v(_vm._s(pos == 0 ? "Add a New Client" : c.name)),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c("div", [
          _c("h1", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.client.id > 0 ? "Update" : "Add") + " Client"),
          ]),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Organization Profile")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-8" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Organization Name"),
                ]),
                _vm._v(" "),
                _vm.client_pos == 0
                  ? _c("v-select", {
                      staticClass: "searchable-select column-display",
                      class: _vm.errors.name != "" ? "is-invalid" : "",
                      attrs: {
                        options: _vm.local_org_list,
                        searchable: true,
                        name: "org_name",
                        id: "org_search",
                        filterable: false,
                      },
                      on: {
                        search: _vm.debouncedSearchOptions,
                        input: _vm.selectOrg,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "list-header",
                            fn: function ({ search }) {
                              return [
                                search != ""
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "border-bottom border-primary border-1",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.addNewOrg(search)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          { staticClass: "btn btn-link" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa-solid fa-circle-plus text-primary",
                                            }),
                                            _vm._v(" Add Organization"),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "option",
                            fn: function ({ value, label }) {
                              return [
                                _c("li", { staticClass: "suboption show" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(label) +
                                      "\n                              "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "no-options",
                            fn: function ({ search }) {
                              return [
                                search == ""
                                  ? _c(
                                      "div",
                                      { staticClass: "suboption show mt-1" },
                                      [
                                        _c("em", [
                                          _vm._v(
                                            "Type to search for the nonprofit..."
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm.searching == true
                                  ? _c(
                                      "div",
                                      { staticClass: "suboption show mt-1" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "spinner-border spinner-border-sm text-primary float-left",
                                            attrs: { role: "status" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "visually-hidden",
                                              },
                                              [_vm._v("Loading...")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "  Loading options\n                                "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "suboption show mt-1" },
                                      [
                                        _c("em", [
                                          _vm._v(
                                            "No organizations match your search"
                                          ),
                                        ]),
                                      ]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4010613820
                      ),
                      model: {
                        value: _vm.local_client.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.local_client, "name", $$v)
                        },
                        expression: "local_client.name",
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_client.name,
                          expression: "local_client.name",
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.errors.name != "" ? "is-invalid" : "",
                      attrs: { type: "text", name: "name" },
                      domProps: { value: _vm.local_client.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.local_client,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                _vm._v(" "),
                _vm.errors.name != ""
                  ? _c("div", {
                      staticClass: "text-danger invalid-feedback",
                      domProps: { innerHTML: _vm._s(_vm.errors.name) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            Array.isArray(_vm.agencies)
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Agency"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.agency_id,
                          expression: "agency_id",
                        },
                      ],
                      staticClass: "form-select",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.agency_id = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _vm.agency_id == -1
                        ? _c("option", { domProps: { value: -1 } }, [
                            _vm._v("(Multiple Agencies)"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.agencies, function (a) {
                        return _c("option", { domProps: { value: a.id } }, [
                          _vm._v(_vm._s(a.name)),
                        ])
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Vertical"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_client.vertical,
                          expression: "local_client.vertical",
                        },
                      ],
                      staticClass: "form-select",
                      class: _vm.errors.vertical != "" ? "is-invalid" : "",
                      attrs: { id: "vertical" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_client,
                              "vertical",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            _vm.errors.vertical = ""
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.vertical_types, function (item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _vm.errors.vertical != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.errors.vertical) +
                          "\n                            "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col mb-3" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Website Domain"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.domain,
                      expression: "local_client.domain",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.domain != "" ? "is-invalid" : "",
                  attrs: { type: "text", name: "domain" },
                  domProps: { value: _vm.local_client.domain },
                  on: {
                    change: function ($event) {
                      _vm.errors.domain = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.local_client, "domain", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.domain != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.errors.domain) +
                          "\n                            "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.user.agency_id == 1
                ? _c("div", { staticClass: "col mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Stage"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local_client.stage,
                            expression: "local_client.stage",
                          },
                        ],
                        staticClass: "form-select",
                        attrs: { id: "stage-select" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.local_client,
                              "stage",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "roadmap" } }, [
                          _vm._v("Roadmap"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "full_service" } }, [
                          _vm._v("Full Service"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "data_only" } }, [
                          _vm._v("Data Only"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.user.agency_id == 1
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("NextAfter.io Client Name"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.local_client.nextafterio_id,
                          expression: "local_client.nextafterio_id",
                        },
                      ],
                      staticClass: "form-select",
                      attrs: { id: "io-client-name" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.local_client,
                            "nextafterio_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.nextafterio_clients, function (i) {
                      return _c("option", { domProps: { value: i.id } }, [
                        _vm._v(_vm._s(i.name)),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_client.slack_url,
                    expression: "local_client.slack_url",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.slack_url != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "slack_url" },
                domProps: { value: _vm.local_client.slack_url },
                on: {
                  change: function ($event) {
                    return _vm.validateURL()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.local_client, "slack_url", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.slack_url != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.errors.slack_url) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.user.agency_id == 1
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Billable Media Types"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn form-select btn-field",
                        attrs: {
                          type: "button",
                          id: "dropdownMenuButton",
                          "data-bs-toggle": "dropdown",
                          "aria-haspopup": "true",
                          "aria-expanded": "false",
                          "data-bs-auto-close": "outside",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.local_client.billable_media_types != null &&
                                _vm.local_client.billable_media_types.length > 0
                                ? _vm.local_client.billable_media_types.join(
                                    ", "
                                  )
                                : "Please Select"
                            ) +
                            "\n                          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { "aria-labelledby": "dropdownMenuButton" },
                      },
                      _vm._l(_vm.media_types, function (item) {
                        return _c(
                          "label",
                          { key: item, staticClass: "dropdown-item" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.local_client.billable_media_types,
                                  expression:
                                    "local_client.billable_media_types",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", "true-value": [] },
                              domProps: {
                                value: item,
                                checked: Array.isArray(
                                  _vm.local_client.billable_media_types
                                )
                                  ? _vm._i(
                                      _vm.local_client.billable_media_types,
                                      item
                                    ) > -1
                                  : _vm._q(
                                      _vm.local_client.billable_media_types,
                                      []
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.local_client.billable_media_types,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? [] : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.local_client,
                                          "billable_media_types",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.local_client,
                                          "billable_media_types",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.local_client,
                                      "billable_media_types",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ms-2" }, [
                              _vm._v(
                                "\n                                  " +
                                  _vm._s(item) +
                                  "\n                                "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col profile-image" },
            [
              _c("label", { staticClass: "form-label" }, [_vm._v("Logo")]),
              _vm._v(" "),
              _c("span", {
                attrs: { "data-name": _vm.local_client.id, id: "client_id" },
              }),
              _vm._v(" "),
              _c("vue-dropzone", {
                ref: "dropzone",
                staticClass: "profile-dropzone",
                style: "background-image: url(" + _vm.local_client.image + ")",
                attrs: {
                  id: "dropzone",
                  options: _vm.dropzoneOptions,
                  awss3: _vm.awss3,
                },
                on: { "vdropzone-s3-upload-success": _vm.s3UploadSuccess },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mt-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Fiscal Year & Donor Settings")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4 mb-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Fiscal Year Start Month"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.fiscal_year_offset,
                      expression: "local_client.fiscal_year_offset",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { name: "local_client.fiscal_year_offset" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_client,
                        "fiscal_year_offset",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "0" } }, [_vm._v("January")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("February")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("March")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("April")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("May")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "5" } }, [_vm._v("June")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "6" } }, [_vm._v("July")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "7" } }, [_vm._v("August")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "8" } }, [
                    _vm._v("September"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "9" } }, [_vm._v("October")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "10" } }, [
                    _vm._v("November"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "11" } }, [
                    _vm._v("December"),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4 mb-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Donor Level Criteria"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.giving_levels.split,
                      expression: "local_client.giving_levels.split",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { name: "local_client.giving_levels.split" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_client.giving_levels,
                        "split",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "HAC" } }, [
                    _vm._v("Highest Annual Cumulative Giving"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "HPC" } }, [
                    _vm._v("Highest Previous Gift"),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4 mb-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Preferred Reporting Period"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.default_reporting_period,
                      expression: "local_client.default_reporting_period",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { name: "local_client.default_reporting_period" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.local_client,
                        "default_reporting_period",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "Calendar Year to Date" } }, [
                    _vm._v("Calendar Year to Date"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Fiscal Year to Date" } }, [
                    _vm._v("Fiscal Year to Date"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Trailing 12 Months" } }, [
                    _vm._v("Trailing 12 Months"),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "form-label" }, [_vm._v("Donor Levels")]),
        _vm._v(" "),
        _c("div", { staticClass: "overflow-table" }, [
          _c("table", { staticClass: "table" }, [
            _c("thead", { staticClass: "table-dark" }, [
              _c("tr", [
                _c("th", { staticClass: "align-middle" }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", { staticClass: "align-middle" }, [
                  _vm._v("Minimum Threshold"),
                ]),
                _vm._v(" "),
                _vm.local_client.giving_levels.levels.length > 3
                  ? _c("th", { staticClass: "align-middle" }, [
                      _vm._v("Report on Upgrades? "),
                      _c("i", {
                        staticClass: "fas fa-info-circle text-white ms-2",
                        attrs: {
                          "data-bs-toggle": "tooltip",
                          title:
                            "AVID has special reporting set up to monitor donors who upgrade to a higher giving level. However, only two giving levels can be reported on at a time. Select those two levels below.",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "align-middle",
                    staticStyle: { width: "80px" },
                  },
                  [_vm._v("Actions")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(
                _vm.local_client.giving_levels.levels,
                function (level, index) {
                  return _c("tr", [
                    index != _vm.edit_index
                      ? _c("td", { staticClass: "align-middle w-50" }, [
                          _vm._v(_vm._s(level.name)),
                        ])
                      : _c("td", { staticClass: "align-middle w-50" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.local_level.name,
                                expression: "local_level.name",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "text", placeholder: "" },
                            domProps: { value: _vm.local_level.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.local_level,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                    _vm._v(" "),
                    index != _vm.edit_index
                      ? _c("td", { staticClass: "align-middle" }, [
                          index ==
                          _vm.local_client.giving_levels.levels.length - 1
                            ? _c("span", [_vm._v("—")])
                            : _c("span", [
                                _vm._v(_vm._s(_vm._f("currency")(level.min))),
                              ]),
                        ])
                      : _c("td", { staticClass: "align-middle" }, [
                          index !=
                          _vm.local_client.giving_levels.levels.length - 1
                            ? _c(
                                "div",
                                { staticClass: "input-group input-group-sm" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "input-group-text" },
                                    [_vm._v("$")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.local_level.min,
                                        expression: "local_level.min",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.local_level.min },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.isPositiveNumber($event)
                                      },
                                      paste: _vm.cleanUpPaste,
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.local_level,
                                          "min",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                    _vm._v(" "),
                    _vm.local_client.giving_levels.levels.length > 3
                      ? _c("td", { staticClass: "align-middle" }, [
                          index !=
                          _vm.local_client.giving_levels.levels.length - 1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "checkbox-animated mt-1 mb-0 ms-2",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: level.report,
                                        expression: "level.report",
                                      },
                                    ],
                                    attrs: {
                                      id: "checkbox_animated_" + index,
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(level.report)
                                        ? _vm._i(level.report, null) > -1
                                        : level.report,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = level.report,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  level,
                                                  "report",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  level,
                                                  "report",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(level, "report", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.limitChecks(index)
                                        },
                                      ],
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "checkbox_animated_" + index,
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "check" }),
                                      _c("span", { staticClass: "box" }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [
                      _vm.edit_index != index
                        ? _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1",
                                attrs: { title: "Edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-fw fa-edit" })]
                            ),
                          ])
                        : _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1",
                                attrs: {
                                  title: "Save",
                                  disabled: !_vm.canSaveLevel(index),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-save" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1 me-0",
                                attrs: { title: "Cancel" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-close" })]
                            ),
                          ]),
                      _vm._v(" "),
                      index !=
                        _vm.local_client.giving_levels.levels.length - 1 &&
                      _vm.edit_index != index
                        ? _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-none p-1 me-0",
                                attrs: { title: "Delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteLevel(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-fw fa-trash" })]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                }
              ),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.edit_index == -1
          ? _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary mb-3",
                on: {
                  click: function ($event) {
                    return _vm.addLevel()
                  },
                },
              },
              [_vm._m(2)]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.successful
          ? _c("div", { staticClass: "alert alert-success mt-3" }, [
              _vm._v(
                "\n                Your organization's information has been updated!\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
              _vm._v(
                "\n                There is an error in the fields above. Please correct and try again.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-big",
              attrs: {
                type: "submit",
                disabled: _vm.form.busy || _vm.hasError(),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.client_pos > 0 ||
                      (_vm.client != null && _vm.client.id > 0)
                      ? "Update"
                      : "Add Client"
                  ) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.client_pos > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-big ms-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteClient()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-btn fa-trash" }),
                  _vm._v(" Delete"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.client != null
            ? _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-big ms-2",
                  attrs: { type: "button", disabled: _vm.form.busy },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("Cancel")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-3" }, [
      _vm._v(
        "The clients listed below are all of those you've created within the system or that are attached to your agency. "
      ),
      _c("strong", [
        _vm._v(
          "You will be charged $2,500 for each new client that you create."
        ),
      ]),
      _vm._v(
        ' This gives you three months of use; once this is up the client moves to "inactive" where charts & integrations stop functioning unless the agency is willing to pay for additional months.'
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Slack Webhook URL\n                            "),
      _c(
        "a",
        {
          attrs: {
            href: "https://api.slack.com/apps/A054J13HM0T/incoming-webhooks",
            target: "_blank",
          },
        },
        [
          _c("i", {
            staticClass: "fas fa-info-circle",
            attrs: {
              "data-bs-toggle": "tooltip",
              "data-placement": "top",
              title:
                "Slack Webhook URLs have to be set up for each client so the messages go to a client-specific channel. Click on this icon to add a new webhook or find an existing one.",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Level"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }