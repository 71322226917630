<template>
    <div class="asset-review-edits-page">
        <div v-if="assets.length == 0">
            <h2 class="mt-5">There are no client approved assets ready for your review for this campaign.</h2>
        </div>
        <div v-else>
            <h2>Review Approved Assets</h2>
            <div v-if="local_assets.length > 0">
                <p>The differences between the Approved version and the Original are highlighted in the right column. <strong>You can make additional changes to the <em>Client's Edited Version</em> or leave it as is.</strong> Once finished, set the status as approved or send it back to them for further review.</p>

                <div class="row">
                    <transition-group tag="div" name="fade-slow">
                        <div class="mb-4" v-for="(asset, index) in local_assets" :key="asset.id">
                            <h3 class="mb-0">{{getAssetTypeNameInSingular(asset.asset_type.name)}}: {{asset.name}}</h3>
                            <div class="created_by_label" v-if="asset.id > 0">
                                <span><label class='mb-0 me-1'>Created By: </label>{{ asset.created_by_user.first_name + ' ' + asset.created_by_user.last_name }}</span>
                            </div>
                            <small class="text-muted">Asset #{{ index+1}} of {{local_assets.length}}</small>
                            <asset-details-review-edits :asset="asset" :client="client" ref="asset" @assetReviewedWithStatusChange="assetReviewedWithStatusChange"></asset-details-review-edits>
                        </div>
                    </transition-group>
                </div>
            </div>
            <div v-else>
                <p><em>All assets have now been reviewed.</em></p>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        components: {},
        props: ['client', 'campaign', 'assets'],
        data() {
            return {
                local_assets: [],
            };
        },
        beforeMount() {
            this.local_assets = Object.values(this.assets);
            if (this.local_assets.length > 0){
                this.getAssetsWithApprovalRecords(this.local_assets);
            }
        },
        updated(){

        },
        methods: {
            getAssetTypeNameInSingular(assetTypeName){
                if (assetTypeName == 'Popups, "Dear Reader" Ads, and Sticky Bars'){
                    return 'Popup, "Dear Reader" Ad, and Sticky Bar';
                } else {
                    return assetTypeName.slice(0, -1);
                }
            },
            getDateAsString(value) {
                return moment.utc(String(value)).local().format('M/DD/YYYY h:mm a')
            },
            getAssetsWithApprovalRecords(assets_array){
                var assets_temp = [];
                for (var i = 0; i< assets_array.length; i++){
                    if(assets_array[i].siblings){
                        // If same user reviewed multiple times, only keep the last one
                        var review_records = [];
                        var reviewer_names = [];
                        assets_array[i].siblings.forEach((review)=>{
                            if(review.updated_by_user == null && !reviewer_names.includes(this.local_user.first_name + " " + this.local_user.last_name)) {
                                reviewer_names.push(this.local_user.first_name + " " + this.local_user.last_name);
                                review.updated_by_user = {
                                    'name' : this.local_user.first_name + " " + this.local_user.last_name
                                };
                                review_records.push(review);
                            }
                            else if (review.updated_by_user != null && !reviewer_names.includes(review.updated_by_user.name)){
                                reviewer_names.push(review.updated_by_user.name);
                                review_records.push(review);
                            }
                        });
                        assets_array[i].previous_review_list = review_records;
                        if(this.local_user){
                            assets_array[i].if_current_user_reviewed_already = reviewer_names.includes(this.local_user.name)
                        }
                    }
                    assets_temp.push(assets_array[i]);     
                }
                this.local_assets = assets_temp;
            },
            assetReviewedWithStatusChange(assetChanged){
                // remove assetChanged from local_assets
                var asset_index = this.local_assets.findIndex(asset => asset.id == assetChanged.id);
                this.local_assets.splice(asset_index, 1); 
            }
        }
    }
</script>
