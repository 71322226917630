<template>
    <div class="create-notification">
        <div class="card box-content mb-3">
            <div class="card-body">
                <!-- <h4>Create a Notification:</h4> -->
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Title</label>
                            <input type="text" class="form-control" :class="((errors.title != '' )? 'is-invalid':'')"
                                name="title" v-model="local_notification.title" @change="fieldValueChange('title')">
                            <div class="text-danger invalid-feedback" v-if="errors.title != ''">
                                {{errors.title}}
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Description</label>
                            <textarea class="form-control" :class="((errors.description != '' )? 'is-invalid':'')" 
                            v-model="local_notification.description" rows="2" @change="fieldValueChange('description')"></textarea>
                            <div class="text-danger invalid-feedback" v-if="errors.description != ''">
                                {{errors.description}}
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-3"  >
                            <label class="form-label">Email HTML</label>
                            <vue-editor v-model="local_notification.email_html" :editorToolbar="customToolbar" @text-change="fieldValueChange('email_html')" ></vue-editor>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Link (Optional)</label>
                            <input type="text" class="form-control" name="link" v-model="local_notification.link">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">CTA (Optional)</label>
                            <select class="form-select" v-model="local_notification.cta">
                                <option value="learn_more">Learn more</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="mb-3 multi-select-field">
                            <label class="form-label">User Types (Optional)</label>                                
                            <accordion-multi-select id="user-types-multi-select" :value="local_notification.user_types" @input="changeMultiSelect"
                            :options="user_types_list" :field_name="'user_types'"></accordion-multi-select>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="mb-3 multi-select-field">
                            <label class="form-label">Clients (Optional)</label>                                
                            <accordion-multi-select id="clients-multi-select" :value="local_notification.clients" @input="changeMultiSelect" 
                            :options="local_clients" :field_name="'clients'"></accordion-multi-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <!-- Success Message -->
                        <div class="alert alert-success mb-3" v-if="form.successful">
                            The new notification has been created and sent to users.
                        </div>
                        <div class="alert alert-danger mb-3" v-if="form.error">
                            There was an error creating the notification.
                        </div>
                        <!-- Save Button -->
                        <div>
                            <button type="submit" class="btn btn-primary" :disabled="form.busy" @click="createNotification()">
                                <i class="fa fa-save"></i> Create
                            </button>                                 
                        </div>
                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import Swal from 'sweetalert2';
    import vSelect from "vue-select";

    export default {
        components: { VueEditor, Swal, vSelect},
        props: ['clients'],
        data() {
            return {
                local_notification: null,
                local_clients:[],
                user_types_list: [
                    { name: "Agency Admins", id: "agency_admin"  },
                    { name: "Users", id: "user"  }],
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    title: '',
                    description: '',
                },
                customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], [{ indent: "-1" }, { indent: "+1" }],["clean"]],
            };
        },
        beforeMount() {
            this.clients.forEach(client =>{
                client.text = client.name;
                client.value = client.id;
                this.local_clients.push(client);
            });
            this.createNewNotification();
        },
        methods: {
            createNewNotification(){
                this.local_notification = {
                    title: '',
                    description: '',
                    email_html: '',
                    link: '',
                    cta: '',
                    user_types: [],
                    clients: []
                }
            },
            fieldValueChange(fieldName){
                this.errors[fieldName] = '';
            },
            changeMultiSelect(event){
                var fieldValue = [];

                if(event.field == 'user_types'){   
                    for (var i=0; i<event.value.length; i++){
                        fieldValue.push({
                            'text': event.value[i].text,
                            'value': event.value[i].value,
                        })
                    }
                    this.local_notification.user_types = fieldValue
                }
                if(event.field == 'clients'){   
                    for (var i=0; i<event.value.length; i++){
                        fieldValue.push({
                            'text': event.value[i].text,
                            'value': event.value[i].value,
                        })
                    }
                    this.local_notification.clients = fieldValue
                }
            },
            clearErrors() {//reset errors to empty strings
                this.errors = {
                    title: '',
                    description: '',
                }
            },
            hasErrors() { //validate the form
                if(this.local_notification.title == null || this.local_notification.title == ""){
                    this.errors.title = "Title is required.";
                } else
                    this.errors.title = "";

                if(this.local_notification.description == null || this.local_notification.description == ""){
                    this.errors.description = "Description is required.";
                } else
                    this.errors.description = "";

                for (const error_name in this.errors) {
                    if (this.errors[error_name] != '') {
                        return true;
                    }
                }
                return false;
            },
            createNotification(){
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                this.form.busy = true; 
                
                var request_body = {
                    // type: 'App\Notifications\Updates',
                    // notifiable_type: 'App\Models\User'
                    // notifiable_id
                    data: null 
                }
                
                // TODO: API
                window.axios.post('/api/notification/new', this.local_notification)
                    .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false;} , 5000);
                            this.createNewNotification();
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
            }            
        }
    }
</script>
