var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "files" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card box-content mb-4 mt-3 p-0" }, [
          _c("div", { staticClass: "card-body px-3 py-1 m-0" }, [
            _c("nav", { staticClass: "navbar navbar-expand-lg" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("span", { staticClass: "navbar-brand mb-0 h1" }, [
                  _vm._v("Steps:"),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse",
                    attrs: { id: "filter-navbar" },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "navbar-nav me-auto mb-2 mb-lg-0" },
                      [
                        _c("li", { staticClass: "nav-item me-4" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link d-flex flex-nowrap",
                              class: { "text-dark": _vm.step_num == 1 },
                              attrs: {
                                href: "#",
                                role: "button",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.step_num = 1
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-circle avatar flex-fill",
                                  class: _vm.step_num == 1 ? "primary" : "",
                                },
                                [_vm._v("1")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "m-2" }, [
                                _vm._v("Information"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item me-4" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link d-flex flex-nowrap",
                              class: {
                                "pe-none": _vm.step_num == 1,
                                "text-dark": _vm.step_num == 2,
                              },
                              attrs: {
                                href: "#",
                                role: "button",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.step_num = 2
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-circle avatar flex-fill",
                                  class: _vm.step_num == 2 ? "primary" : "",
                                },
                                [_vm._v("2")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "m-2" }, [
                                _vm._v("Filters"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item me-4" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link d-flex flex-nowrap",
                              class: {
                                "pe-none": _vm.step_num <= 2,
                                "text-dark": _vm.step_num == 3,
                              },
                              attrs: {
                                href: "#",
                                role: "button",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-circle avatar flex-fill",
                                  class: _vm.step_num == 3 ? "primary" : "",
                                },
                                [_vm._v("3")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "m-2" }, [
                                _vm._v("Column Mapping"),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.step_num == 1
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "card box-content mb-3" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h3", [_vm._v("Data Set Information")]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-3" }, [
                  _vm._v(
                    'A "data set" is a view of the data extracted from your integrated systems. To be properly used within the system, we need to establish the information about the data set and the most commonly used data points need to be mapped to standard columns. If there are additional columns you\'d like to use for filters, be sure to add those columns too.'
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Data Set Name"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_dataset.name,
                        expression: "local_dataset.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.errors.name != "" ? "is-invalid" : "",
                    attrs: { type: "text", name: "name" },
                    domProps: { value: _vm.local_dataset.name },
                    on: {
                      change: function ($event) {
                        _vm.form.error = false
                        _vm.errors.name = ""
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.local_dataset, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.name != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.name) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.file_or_dataset == "file" || _vm.errors.category != ""
                  ? _c("div", { staticClass: "mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Data Category"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_dataset.source_type_id,
                              expression: "local_dataset.source_type_id",
                            },
                          ],
                          staticClass: "form-select",
                          class: _vm.errors.category != "" ? "is-invalid" : "",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.local_dataset,
                                  "source_type_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.setDataSourceType()
                              },
                              function ($event) {
                                _vm.form.error = false
                                _vm.errors.category = ""
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.source_types, function (cols, key) {
                          return _c("option", { domProps: { value: key } }, [
                            _vm._v(_vm._s(key)),
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.category != ""
                        ? _c(
                            "div",
                            { staticClass: "text-danger invalid-feedback" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.errors.category) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.local_dataset.source != "Airbyte" &&
                _vm.local_dataset.source != "Table"
                  ? _c("div", [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Refresh Type"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.local_dataset.settings.refresh_type,
                                expression:
                                  "local_dataset.settings.refresh_type",
                              },
                            ],
                            staticClass: "form-select",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.local_dataset.settings,
                                    "refresh_type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.columnsValid()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "incremental" } }, [
                              _vm._v(
                                "Append: New files are merged with prior uploads"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "full" } }, [
                              _vm._v(
                                "Replace: New files replace prior uploads"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.local_dataset.settings.refresh_type == "incremental"
                          ? _c("em", { staticClass: "small" }, [
                              _vm._v(
                                'When appending files, the "Updated At" field will ensure the most recent record is used (if it is set on the Column Mapping screen).'
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.local_dataset.settings.refresh_type == "incremental"
                        ? _c("div", { staticClass: "mb-3" }, [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn form-select btn-field",
                                  attrs: {
                                    type: "button",
                                    id: "dropdownMenuButton",
                                    "data-bs-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                    "data-bs-auto-close": "outside",
                                  },
                                },
                                [
                                  _vm.local_dataset.settings.id_columns !=
                                    null &&
                                  _vm.local_dataset.settings.id_columns.length >
                                    0
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.id_columns)),
                                      ])
                                    : _c("span", [
                                        _c("em", [_vm._v("None Selected")]),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: {
                                    "aria-labelledby": "dropdownMenuButton",
                                  },
                                },
                                _vm._l(
                                  _vm.local_dataset.source_columns,
                                  function (item, key) {
                                    return _c(
                                      "label",
                                      {
                                        key: key,
                                        staticClass: "dropdown-item",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.local_dataset.settings
                                                  .id_columns,
                                              expression:
                                                "local_dataset.settings.id_columns",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "checkbox",
                                            "true-value": [],
                                          },
                                          domProps: {
                                            value: key,
                                            checked: Array.isArray(
                                              _vm.local_dataset.settings
                                                .id_columns
                                            )
                                              ? _vm._i(
                                                  _vm.local_dataset.settings
                                                    .id_columns,
                                                  key
                                                ) > -1
                                              : _vm._q(
                                                  _vm.local_dataset.settings
                                                    .id_columns,
                                                  []
                                                ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.local_dataset.settings
                                                    .id_columns,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? [] : false
                                              if (Array.isArray($$a)) {
                                                var $$v = key,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.local_dataset
                                                        .settings,
                                                      "id_columns",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.local_dataset
                                                        .settings,
                                                      "id_columns",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.local_dataset.settings,
                                                  "id_columns",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ms-2" }, [
                                          _vm._v(
                                            "\n                                      " +
                                              _vm._s(item) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_dataset.settings.pattern,
                              expression: "local_dataset.settings.pattern",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.errors.pattern != "" ? "is-invalid" : "",
                          attrs: { type: "text", name: "pattern" },
                          domProps: {
                            value: _vm.local_dataset.settings.pattern,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.refreshFiles()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.local_dataset.settings,
                                "pattern",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.pattern != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.errors.pattern) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.local_dataset.settings.ignore_unused,
                                expression:
                                  "local_dataset.settings.ignore_unused",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", id: "ignore_unused" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.local_dataset.settings.ignore_unused
                              )
                                ? _vm._i(
                                    _vm.local_dataset.settings.ignore_unused,
                                    null
                                  ) > -1
                                : _vm.local_dataset.settings.ignore_unused,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a =
                                      _vm.local_dataset.settings.ignore_unused,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.local_dataset.settings,
                                          "ignore_unused",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.local_dataset.settings,
                                          "ignore_unused",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.local_dataset.settings,
                                      "ignore_unused",
                                      $$c
                                    )
                                  }
                                },
                                function ($event) {
                                  return _vm.columnsValid()
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _vm._m(3),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.local_dataset.source != "Airbyte" &&
          _vm.local_dataset.source != "Table"
            ? _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "card box-content mb-3 overflow-hidden" },
                  [
                    _c("div", { staticClass: "card-body px-0 pb-0" }, [
                      _c("p", { staticClass: "px-3" }, [
                        _vm._v(
                          "Based upon the pattern specified, the following files will be included in this data set:"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.local_files.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "table-responsive",
                              staticStyle: { "max-height": "271px" },
                            },
                            [
                              _c(
                                "table",
                                { staticClass: "table gy-0 m-0 mb-2 w-100" },
                                [
                                  _vm._m(4),
                                  _vm._v(" "),
                                  _c(
                                    "transition-group",
                                    {
                                      staticClass: "table-border-bottom-0",
                                      attrs: {
                                        tag: "tbody",
                                        name: "table-row",
                                      },
                                    },
                                    _vm._l(
                                      _vm.local_files,
                                      function (f, index) {
                                        return _c(
                                          "tr",
                                          {
                                            key: f.id,
                                            class:
                                              f.status_msg == "MISMATCH"
                                                ? "error"
                                                : "",
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "ps-3 align-middle",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(f.name) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "align-middle text-nowrap",
                                                attrs: {
                                                  colspan:
                                                    f.status_msg == "MISMATCH"
                                                      ? "1"
                                                      : "2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("datetime")(
                                                      f.created_at
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            f.status_msg == "MISMATCH"
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-end" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-none red",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-trash",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeFile(
                                                                f
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c("div", [_vm._m(5)]),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step_num == 1
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.form.error
              ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
                  _vm._v(
                    "\n                There is an issue with the information provided in the form above. Please check the fields and make the necessary corrections.\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: this.form.busy },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep()
                    },
                  },
                },
                [
                  _vm.file_or_dataset == "data_set"
                    ? _c("span", [_vm._v("Save & ")])
                    : _vm._e(),
                  _vm._v("Next "),
                  _vm.form.busy
                    ? _c("div", {
                        staticClass:
                          "spinner-border spinner-border-sm float-left ms-1",
                        attrs: { role: "status" },
                      })
                    : _c("i", {
                        staticClass: "fa fa-btn fa-chevron-right ms-1",
                      }),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step_num == 2
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("div", { staticClass: "card box-content" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "You are able to limit the records in your data set. This is helpful if removing records like soft credits from a list of transactions or non-donors from a list of constituents."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("query-builder", {
                    key: _vm.dataset.id,
                    ref: "filter",
                    attrs: {
                      client: _vm.client,
                      id: "dataset-inclusion-filters-" + _vm.dataset.id,
                      static_db_columns: _vm.local_dataset.source_columns,
                      include_trans: false,
                      source: _vm.file_or_dataset,
                      in_card: true,
                      add_blank: false,
                      data_source_id: _vm.object_id,
                    },
                    model: {
                      value: _vm.local_dataset.filter,
                      callback: function ($$v) {
                        _vm.$set(_vm.local_dataset, "filter", $$v)
                      },
                      expression: "local_dataset.filter",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step_num == 2
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.form.error
              ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
                  _vm._v(
                    "\n                There is an issue with the information provided in the fields above. Please check your entries and make the necessary corrections.\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit", disabled: this.form.busy },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep()
                    },
                  },
                },
                [
                  _vm.file_or_dataset == "data_set"
                    ? _c("span", [_vm._v("Save & ")])
                    : _vm._e(),
                  _vm._v("Next "),
                  _vm.form.busy
                    ? _c("div", {
                        staticClass:
                          "spinner-border spinner-border-sm float-left ms-1",
                        attrs: { role: "status" },
                      })
                    : _c("i", {
                        staticClass: "fa fa-btn fa-chevron-right ms-1",
                      }),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step_num == 3
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col mb-3" }, [
            _c("div", { staticClass: "card box-content" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h3", [_vm._v("Column Mapping")]),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _c("column-mapping", {
                    ref: "column_mapping",
                    attrs: {
                      client: _vm.client,
                      dataset: _vm.local_dataset,
                      dest_columns: _vm.dest_columns,
                      data_source_id: _vm.object_id,
                      source: _vm.file_or_dataset,
                    },
                    model: {
                      value: _vm.local_dataset.columns,
                      callback: function ($$v) {
                        _vm.$set(_vm.local_dataset, "columns", $$v)
                      },
                      expression: "local_dataset.columns",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step_num == 3
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.form.error
              ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
                  _vm._v(
                    "\n                There was an issue saving the data set. Check the fields above to get more information.\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-big",
                  attrs: { type: "submit", disabled: _vm.form.busy },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  !_vm.form.busy
                    ? _c("i", { staticClass: "fa fa-save" })
                    : _c("i", { staticClass: "fas fa-sync fa-spin" }),
                  _vm._v(" Save\n                "),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#filter-navbar",
          "aria-controls": "filter-navbar",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-label",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "When appending files, you can select one or more columns to be used as a unique ID. Records with the same ID as a record in a prior upload will be used as a replacement rather than a new record. If no columns are selected, records will always be appended.",
        },
      },
      [
        _vm._v("Column(s) to Identify Unique Records "),
        _c("i", { staticClass: "fas fa-info-circle" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-label",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Identify what files should be included in this data set. You can use a wildcard character (%) if there are parts of the file name that will change with each upload or, for more advanced rules, use a regular expression by starting the pattern with r' and ending it with a '",
        },
      },
      [
        _vm._v("File Name Pattern "),
        _c("i", { staticClass: "fas fa-info-circle" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "ignore_unused" } },
      [
        _c("strong", [_vm._v("Ignore Unused Column Changes.")]),
        _vm._v(
          " If columns are added or removed in future uploads, the file will still process when the changes don't impact existing mappings.\n                              "
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "align-middle" }, [_vm._v("File Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle", attrs: { colspan: "2" } }, [
          _vm._v("Uploaded At"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "px-3" }, [
      _c("strong", [
        _c("em", [_vm._v("No files were found that match the given pattern.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [
      _vm._v("Inclusion Filters "),
      _c("em", [_c("small", [_vm._v("(Optional)")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        'Based upon the "Data Category" selected, there are several mandatory and optional fields to be mapped. '
      ),
      _c("strong", [
        _vm._v(
          "If you have additional columns you'd like to use for filters, be sure to add those columns too."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }