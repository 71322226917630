var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "simplified-query-builder" }, [
    _vm.data_filter.logic_units.length > 0
      ? _c("p", { staticClass: "d-flex text-row mb-3" }, [
          _vm._v("Match                              \n        "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inverted,
                  expression: "inverted",
                },
              ],
              staticClass: "form-select w-auto mx-2",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.inverted = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    $event.preventDefault()
                    return _vm.setInverted()
                  },
                ],
              },
            },
            [
              _c("option", { domProps: { value: false } }, [_vm._v("All")]),
              _vm._v(" "),
              _c("option", { domProps: { value: true } }, [_vm._v("Any")]),
            ]
          ),
          _vm._v(" \n        of the following conditions:\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.nested_units, function (unit, index) {
        return _vm.data_filter.logic_units.length > 0
          ? _c(
              "div",
              {
                staticClass: "col-12",
                class: { inverted: _vm.inverted, white: _vm.is_white },
              },
              [
                _vm._l(unit, function (el, el_index) {
                  return _c(
                    "div",
                    {
                      staticClass: "row pb-2",
                      class: {
                        "is-invalid":
                          _vm.hasLogicUnitError(
                            _vm.data_filter.logic_units[el]
                          ) && _vm.errors.logic_units[el],
                      },
                    },
                    [
                      _c("div", { staticClass: "col-4 pe-0" }, [
                        _c("div", { staticClass: "d-flex" }, [
                          el_index > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "me-2 ms-3 mt-1 logic-operator-text",
                                },
                                [_vm._v(_vm._s(_vm.inverted ? "AND " : "OR "))]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "me-2 mt-4 d-flex align-items-center",
                                },
                                [_vm._v(_vm._s(index + 1) + ". ")]
                              ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-100 text-center" },
                            [
                              el_index == 0
                                ? _c(
                                    "label",
                                    { staticClass: "form-label fs-7 m-0" },
                                    [_vm._v("Column Name")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-select", {
                                staticClass:
                                  "searchable-select column-display w-100 category",
                                attrs: {
                                  options: _vm.db_columns,
                                  searchable: true,
                                  selectable: (option) => option.text != "",
                                  label: "text",
                                  filterable: false,
                                  "data-bs-toggle": "tooltip",
                                  title: _vm.getColumnTitle(
                                    _vm.data_filter.logic_units[el]
                                  ),
                                },
                                on: { search: _vm.fetchOptions },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selected-option",
                                      fn: function ({ text, category }) {
                                        return [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                category != null &&
                                                  category != "" &&
                                                  text != null &&
                                                  text != ""
                                                  ? category + ": "
                                                  : ""
                                              ) +
                                              _vm._s(text) +
                                              "\n                                "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "open-indicator",
                                      fn: function ({ attributes }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._b(
                                              {
                                                staticStyle: {
                                                  width: "12px",
                                                  "line-height": "8px",
                                                },
                                              },
                                              "span",
                                              attributes,
                                              false
                                            ),
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 16 16",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "none",
                                                      stroke: "#343a40",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                      "stroke-width": "2",
                                                      d: "m2 5 6 6 6-6",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "option",
                                      fn: function ({ text, category }) {
                                        return [
                                          text == ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "category-header",
                                                  attrs: {
                                                    "data-category": category,
                                                    "data-isexpanded": "false",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.expandCategory(
                                                        category,
                                                        null
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(category) +
                                                      " "
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "fa-solid fa-caret-right",
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "suboption",
                                                  class:
                                                    category == null ||
                                                    category == ""
                                                      ? "show"
                                                      : "",
                                                  attrs: {
                                                    "data-subcategory":
                                                      category,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(text) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "no-options",
                                      fn: function ({
                                        search,
                                        searching,
                                        loading,
                                      }) {
                                        return [
                                          _vm.is_loading
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "suboption show",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "spinner-border spinner-border-sm text-warning float-left",
                                                      attrs: { role: "status" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "visually-hidden",
                                                        },
                                                        [_vm._v("Loading...")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "  Loading columns\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "suboption show",
                                                },
                                                [
                                                  _c("em", [
                                                    _vm._v("No results found"),
                                                  ]),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value:
                                    _vm.data_filter.logic_units[el].db_column,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data_filter.logic_units[el],
                                      "db_column",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "data_filter.logic_units[el].db_column",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-2 operations pe-0 text-center" },
                        [
                          el_index == 0
                            ? _c(
                                "label",
                                { staticClass: "form-label fs-7 m-0" },
                                [_vm._v("Operator")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              options: _vm.getOperatorOptions(
                                _vm.data_filter.logic_units[el]
                              ),
                              selectable: (option) => option.text != "",
                              label: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.updateOperator(
                                  _vm.data_filter.logic_units[el]
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "open-indicator",
                                  fn: function ({ attributes }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._b(
                                          {
                                            staticStyle: {
                                              width: "12px",
                                              "line-height": "8px",
                                            },
                                          },
                                          "span",
                                          attributes,
                                          false
                                        ),
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 16 16",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "none",
                                                  stroke: "#343a40",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "2",
                                                  d: "m2 5 6 6 6-6",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function ({ text, category }) {
                                    return [
                                      text == ""
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "category-header",
                                              attrs: {
                                                "data-category": category,
                                                "data-isexpanded": false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.expandCategory(
                                                    category,
                                                    null
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(category) +
                                                  " "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "fa-solid fa-caret-down",
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "suboption",
                                              class: {
                                                show: _vm.shouldShowClass(el),
                                              },
                                              attrs: {
                                                "data-subcategory": category,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(text) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                                {
                                  key: "list-footer",
                                  fn: function ({ search }) {
                                    return [
                                      _vm.data_filter.logic_units[el]
                                        .operator_option_type != "full"
                                        ? _c(
                                            "li",
                                            {
                                              staticClass:
                                                "border-top border-primary border-1 text-center",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.loadFullOperatorList(
                                                    el
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-link mb-0",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa-solid fa-eye me-2",
                                                  }),
                                                  _vm._v("View All Operators"),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.data_filter.logic_units[el].operator,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.data_filter.logic_units[el],
                                  "operator",
                                  $$v
                                )
                              },
                              expression:
                                "data_filter.logic_units[el].operator",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.shouldShowValues(
                        _vm.data_filter.logic_units[el].operator
                      )
                        ? _c(
                            "div",
                            {
                              staticClass: "col-5 text-center pe-0 text-center",
                            },
                            [
                              el_index == 0 &&
                              _vm.shouldShowValues(
                                _vm.data_filter.logic_units[el].operator
                              )
                                ? _c(
                                    "label",
                                    { staticClass: "form-label fs-7 m-0" },
                                    [_vm._v("Value")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.shouldShowSelect(
                                _vm.data_filter.logic_units[el]
                              )
                                ? _c("v-select", {
                                    ref: "valueSelect",
                                    refInFor: true,
                                    staticClass:
                                      "searchable-select column-display vue-multi-select w-100",
                                    attrs: {
                                      id: "id-" + el,
                                      options: _vm.getOptions(
                                        _vm.data_filter.logic_units[el]
                                      ),
                                      searchable: true,
                                      selectable: (option) => option.text != "",
                                      label: "text",
                                      "map-keydown": _vm.handleEnter,
                                      filterable: false,
                                      multiple: "",
                                    },
                                    on: {
                                      open: function ($event) {
                                        return _vm.setEditingIndex(el)
                                      },
                                      close: function ($event) {
                                        return _vm.handleBlur(el)
                                      },
                                      search: _vm.debouncedSearchOptions,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "list-header",
                                          fn: function ({ search }) {
                                            return [
                                              search != ""
                                                ? _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "border-bottom border-primary border-1",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.addEntryToValue(
                                                            el,
                                                            search
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "btn btn-link",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa-solid fa-circle-plus text-primary",
                                                          }),
                                                          _vm._v(" Add Entry"),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "open-indicator",
                                          fn: function ({ attributes }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      width: "12px",
                                                      "line-height": "8px",
                                                    },
                                                  },
                                                  "span",
                                                  attributes,
                                                  false
                                                ),
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "none",
                                                          stroke: "#343a40",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                          "stroke-width": "2",
                                                          d: "m2 5 6 6 6-6",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "option",
                                          fn: function ({ text, category }) {
                                            return [
                                              text == ""
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "category-header",
                                                      attrs: {
                                                        "data-category":
                                                          category,
                                                        "data-isexpanded":
                                                          "false",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.expandCategory(
                                                            category,
                                                            null
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(category) +
                                                          " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "fa-solid fa-caret-right",
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass: "suboption",
                                                      class:
                                                        category == null ||
                                                        category == ""
                                                          ? "show"
                                                          : "",
                                                      attrs: {
                                                        "data-subcategory":
                                                          category,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(text) +
                                                          "\n                            "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "no-options",
                                          fn: function ({
                                            search,
                                            searching,
                                            loading,
                                          }) {
                                            return [
                                              _vm.is_loading
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "suboption show",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "spinner-border spinner-border-sm text-primary float-left",
                                                          attrs: {
                                                            role: "status",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "visually-hidden",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Loading..."
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "  Loading columns\n                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm.is_searching
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "suboption show",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "spinner-border spinner-border-sm text-primary float-left",
                                                          attrs: {
                                                            role: "status",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "visually-hidden",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Searching..."
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "  Searching\n                            "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "suboption show",
                                                    },
                                                    [
                                                      _c("em", [
                                                        _vm._v(
                                                          "No results found"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.data_filter.logic_units[el].value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data_filter.logic_units[el],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data_filter.logic_units[el].value",
                                    },
                                  })
                                : _vm.shouldShowDate(
                                    _vm.data_filter.logic_units[el]
                                  )
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center input-group",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary dropdown-toggle",
                                          attrs: {
                                            type: "button",
                                            "data-bs-toggle": "dropdown",
                                            "aria-expanded": "false",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.typeOfSelection(
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ]
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "dropdown-menu",
                                          attrs: {
                                            "aria-labelledby":
                                              "dropdownMenuButton1",
                                          },
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "dropdown-item",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].is_static = false
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].show_options = true
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].value = ""
                                                  },
                                                },
                                              },
                                              [_vm._v("Date Comparisons")]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "dropdown-item",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].is_static = true
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].show_options = false
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ].value = ""
                                                  },
                                                },
                                              },
                                              [_vm._v("Static Value")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.data_filter.logic_units[el]
                                        .is_static &&
                                      _vm.data_filter.logic_units[el]
                                        .show_options == false
                                        ? _c("datepicker", {
                                            attrs: {
                                              "input-class":
                                                "form-control date",
                                              placeholder: "MM/DD/YYYY",
                                              "bootstrap-styling": false,
                                              "use-utc": true,
                                              format: "M/d/yyyy",
                                            },
                                            model: {
                                              value:
                                                _vm.data_filter.logic_units[el]
                                                  .value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data_filter.logic_units[el].value",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.data_filter.logic_units[el]
                                        .is_static &&
                                      _vm.data_filter.logic_units[el]
                                        .show_options
                                        ? _c("input", {
                                            staticClass:
                                              "form-control date_num ps-2",
                                            attrs: {
                                              type: "number",
                                              "data-index": el,
                                              placeholder: "###",
                                            },
                                            domProps: {
                                              value: _vm.dateNumCalc(
                                                _vm.data_filter.logic_units[el]
                                                  .value
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.updateDateValue(
                                                  el,
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ]
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.data_filter.logic_units[el]
                                        .is_static &&
                                      _vm.data_filter.logic_units[el]
                                        .show_options
                                        ? _c(
                                            "select",
                                            {
                                              staticClass:
                                                "form-select date_comparison",
                                              attrs: { "data-index": el },
                                              domProps: {
                                                value: _vm.dateComparisonCalc(
                                                  _vm.data_filter.logic_units[
                                                    el
                                                  ].value
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.updateDateValue(
                                                    el,
                                                    _vm.data_filter.logic_units[
                                                      el
                                                    ]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "day" } },
                                                [_vm._v("Days Ago")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "week" } },
                                                [_vm._v("Weeks Ago")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "month" } },
                                                [_vm._v("Months Ago")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "year" } },
                                                [_vm._v("Years Ago")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.data_filter.logic_units[el].value,
                                        expression:
                                          "data_filter.logic_units[el].value",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    domProps: {
                                      value:
                                        _vm.data_filter.logic_units[el].value,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.data_filter.logic_units[el],
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-none btn-sm p-1",
                              class: el_index == 0 ? "mt-4" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.removeLogicUnit(el)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon p-0",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/trash.svg",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.hasLogicUnitError(_vm.data_filter.logic_units[el]) &&
                      _vm.errors.logic_units[el]
                        ? _c(
                            "div",
                            { staticClass: "pb-2 invalid-feedback col-12" },
                            [
                              _vm._v(
                                "\n                    All of the fields must be completed.\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary btn-sm ms-3",
                      on: {
                        click: function ($event) {
                          return _vm.addLogicUnit(index)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus" }),
                      _vm._v(" " + _vm._s(_vm.inverted ? "AND" : "OR")),
                    ]
                  ),
                ]),
              ],
              2
            )
          : _vm._e()
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "add-condition d-flex align-items-center mb-3 mt-3" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary me-3",
            on: {
              click: function ($event) {
                return _vm.addLogicUnit(-1)
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-plus" }),
            _vm._v(" Add Condition\n        "),
          ]
        ),
        _vm._v(" "),
        _vm.data_filter.logic_units.length > 0
          ? _c("div", { staticClass: "form-check" }, [
              _c(
                "label",
                { staticClass: "form-check-label d-flex align-items-center" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data_filter.logic_units_modification,
                        expression: "data_filter.logic_units_modification",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", value: "LOWER" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.data_filter.logic_units_modification
                      )
                        ? _vm._i(
                            _vm.data_filter.logic_units_modification,
                            "LOWER"
                          ) > -1
                        : _vm.data_filter.logic_units_modification,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.data_filter.logic_units_modification,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "LOWER",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.data_filter,
                                  "logic_units_modification",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.data_filter,
                                  "logic_units_modification",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.data_filter,
                              "logic_units_modification",
                              $$c
                            )
                          }
                        },
                        function ($event) {
                          return _vm.updateModification()
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "ms-2 mt-1" }, [
                    _vm._v(
                      "Treat upper and lower case letters as the same (case-insensitive)"
                    ),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }