<template>
    <div class="versions" >
        <div class="card box-content mb-3" >
            <div class="card-body p-0">
                <div class="overflow-hidden rounded-top">
                    <div class="p-1 bg-dark"></div>
                </div>
                <table class="table gy-0 m-0 mb-2">
                    <thead class="table-dark">
                        <tr>
                            <th class='ps-3 align-middle pt-0'>Version Name</th>
                            <th class="align-middle pt-0">Status</th>
                            <th class="align-middle pt-0 d-none d-md-table-cell">Created On</th>
                            <th class="align-middle pt-0 d-none d-md-table-cell">Created By</th>
                            <th class="align-middle pt-0  d-none d-md-table-cell">Updated On</th>
                            <th class="align-middle pt-0 d-none d-md-table-cell">Updated By</th>
                            <th class="align-middle pt-0">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="table-border-bottom-0">
                        <tr v-for="(plan, index) in plans" v-bind:id="'row' + plan.id">
                            <td class='ps-3 align-middle' v-if="plan.client_id != null"><a :href='"/planning/"+plan.url+"/annual"'>{{ plan.name }}</a></td>
                            <td class='ps-3 m align-middle' colspan="2" v-else>
                                <div class="spinner-border  spinner-border-sm text-warning float-left" role="status">
                                <span class="visually-hidden">Copying...</span>
                            </div> <span class="ms-2">Copying...</span></td>
                            <td class="align-middle">
                                <span class="badge rounded-pill badge-primary" v-if="plan.is_published_plan == 1">Published</span>
                                <span class="badge rounded-pill badge-secondary" v-else-if="plan.is_active_plan == 1">Draft</span>
                            </td>
                            <td class="align-middle d-none d-md-table-cell" v-if="plan.client_id != null">{{ plan.created_at | date }}</td>
                            <td class="align-middle d-none d-md-table-cell"><img v-if="plan.client_id != null"
                                class="avatar rounded-circle me-3" data-bs-toggle="tooltip"
                                data-popup="tooltip-custom" data-bs-placement="top"
                                :data-bs-original-title="plan.creator.name"
                                :src="plan.creator.image" /></td>
                            <td class="align-middle d-none d-md-table-cell">{{ checkDuplicate(plan.created_at, plan.updated_at, plan.updated_at) | date }}</td>
                            <td class="align-middle d-none d-md-table-cell"><img v-if="plan.client_id != null"
                                class="avatar rounded-circle me-3"
                                data-bs-toggle="tooltip"
                                data-popup="tooltip-custom" data-bs-placement="top"
                                :data-bs-original-title="plan.updater.name"
                                :src="plan.updater.image" /></td>
                            <td class="align-middle">
                                <div class="dropdown">
                                  <button class="btn btn-secondary" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-caret-down me-0"></i>
                                  </button>
                                  <ul class="dropdown-menu" >
                                    <li><a  class="dropdown-item" :href='"/planning/"+plan.url+"/annual"'>View Plan</a></li>
                                    <li><a  class="dropdown-item" :href='"/planning/"+plan.url+"/assumptions"'>View Assumptions</a></li>
                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="editPlan(plan.id)">Edit Title</a></li>
                                    <li><a class="dropdown-item" href="#"
                                        v-if="plan.is_published_plan == 0"
                                        v-on:click.prevent="setAsPublished(plan)">Publish Plan</a></li>
                                    <li><a class="dropdown-item"  href="#"
                                        v-if="plan.is_active_plan == 0"
                                        v-on:click.prevent="setAsActive(plan)" title="Edit">Set as Primary Draft</a></li>
                                    <li><a class="dropdown-item"  href="#"
                                        v-on:click.prevent="copyPlan(plan.id)">Clone Plan</a></li>
                                    <li><a class="dropdown-item"  href="#"
                                        v-on:click.prevent="transferPlan(plan)">Transfer Plan</a></li>
                                    <li><a class="dropdown-item"  href="#"
                                        v-on:click.prevent="deletePlan(plan)"
                                        v-if="!(plan.is_active_plan == 1 || plan.is_published_plan == 1)">Delete Plan</a></li>
                                  </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card box-content mb-3">
            <div class="card-body">
                <h4 v-if="plan.id == null">Create a Blank Plan</h4>
                <h4 v-else>Edit This Plan Name</h4>
                <!-- Name -->
                <div class="pb-3" id="form_start">
                    <label class="form-label">Name</label>
                    <input type="text" class="form-control" name="name" v-model="plan.name"
                         :class="((errors.name != '' )? 'is-invalid':'')" >
                    <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                        {{errors.name}}
                    </div>
                </div>
                <!-- Success Message -->
                <div class="alert alert-success pb-3" v-if="form.successful">
                    Your information has been updated!
                </div>
                <div class="alert alert-danger pb-3" v-if="form.error">
                    There was an error saving your information. Please tell Kevin.
                </div>
                <div class=" pb-3">
                    <button type="submit" class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy || hasError()">
                        <i class="fa fa-btn fa-save"></i> <span  v-if="plan.id == null">Save</span><span v-else>Update</span>
                    </button>
                    <button type="button ms-2" class="btn btn-secondary" v-on:click="resetPlan()"
                        v-if="plan.id != null">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },

        props: ['plans', 'client', 'client_list'],
        data() {
            return {
                pos: -1,
                plan: {
                    id: null,
                    name: "",
                    is_active_plan: false,
                    is_published_plan: false,
                    client_id: null
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    name: ""
                },
            };
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            },
        },
        mounted() {
            this.plan.client_id = this.client.id;
        },
        methods: {
            editPlan(id) {
                for(var i = 0; i < this.plans.length; i++)
                    if(this.plans[i].id == id) {
                        this.pos = i;
                        this.plan = JSON.parse(JSON.stringify(this.plans[i]));
                        break;
                    }

                $('html,body').animate({ scrollTop: $("#form_start").offset().top}, 400);
            },
            copyPlan(id) {
                var self = this;
                this.form.busy = true;

                //Insert a blank placehold to show that it is loading
                this.plans.push({
                    id: null,
                    name: "",
                    is_active_plan: false,
                    is_published_plan: false,
                    client_id: null
                });

                window.axios.get('/api/annual_plan/clone/' + id)
                  .then(response => {
                    self.form.busy = false;
                    self.plans.pop();
                    if(response.status == 200) {

                        //Add the new plan to the list
                        self.plans.push(response.data.data);
                        self.form.successful = true;
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                  });
            },
            resetPlan() {

                //Reset the "active" plan
                this.plan.id = null;
                this.plan.name = "";
                this.plan.is_active_plan = false;
                this.plan.is_published_plan = false;

                this.pos = -1;
            },
            setAsActive(plan) {
                this.plan = plan;
                this.plan.is_active_plan = true;
                this.update();
            },
            setAsPublished(plan) {
                this.plan = plan;
                this.plan.is_published_plan = true;
                this.update();
            },
            update() {

                if(this.plan.name == "") {
                    this.errors.name = 'Please enter a name for this plan.';
                    return;
                }

                var self = this;
                this.form.busy = true;

                //Make a new plan
                if(this.plan.id == null) {
                    window.axios.post('/api/client/'+this.client.id+'/annual_plan', this.plan)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {

                            //Reset the active plans if the new one is set to active
                            if(response.data.data.is_active_plan == 1)
                                for(var i = 0; i < self.plans.length; i++)
                                    self.plans[i].is_active_plan = 0;

                            //Reset the active plans if the new one is set to published
                            if(response.data.data.is_published_plan == 1)
                                for(var i = 0; i < self.plans.length; i++)
                                    self.plans[i].is_published_plan = 0;

                            //Add the new plan to the list
                            self.plans.push(response.data.data);
                            self.form.successful = true;

                            self.resetPlan();

                            setTimeout( function() {self.form.successful = false;} , 5000);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }
                else {
                    window.axios.put('/api/annual_plan/' + this.plan.id, this.plan)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;

                            //Reset the active plans if the new one is set to active
                            if(response.data.data.is_active_plan == 1)
                                for(var i = 0; i < self.plans.length; i++)
                                    if(response.data.data.id != self.plans[i].id)
                                        self.plans[i].is_active_plan = 0;

                            //Reset the active plans if the new one is set to published
                            if(response.data.data.is_published_plan == 1)
                                for(var i = 0; i < self.plans.length; i++)
                                    self.plans[i].is_published_plan = 0;

                            for(var i = 0; i < self.plans.length; i++)
                                if(self.plans[i].id == self.plan.id)
                                    self.plans[i] = response.data.data;

                            self.resetPlan();

                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }

            },
            checkDuplicate(created, updated, value) {
                if(created == updated)
                    return null;
                return value;
            },
            hasError() {
                return this.errors.name != "" ;
            },
            deletePlan(p) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete "+ p.name + "?",
                  text: "This will remove the plan and any associated initiatives from Avid AI.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/annual_plan/' + p.id)
                        .then(response => {
                            if (response.status == 200) {
                                //self.getSystemArray(system.system_id).$remove(system);
                                //console.log('deleted',response.data);
                                Swal.fire({
                                    title: "Deleted!",
                                    text: p.name + " has been deleted.",
                                    icon: "success",
                                    iconColor:  "#46705F",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    //Remove it from the array
                                    for(var i = 0; i < self.plans.length; i++)
                                        if(self.plans[i].id == p.id) {
                                            self.plans.splice(i, 1);
                                            self.$forceUpdate();
                                            break;
                                        }
                                });
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });

            },
            transferPlan(plan) {
                var self = this;

                var options = [];
                for(var i = 0; i < this.client_list.length; i++)
                    options[this.client_list[i].id] = this.client_list[i].name;

                Swal.fire({
                  title: "Select the client to receive the plan",
                  text: "This will duplicate \""+plan.name+"\" to the selected client. Once duplicated, you'll need to edit the assumptions and inputs for each initative.",
                  showCancelButton: true,
                  confirmButtonColor: "#46705F",
                  confirmButtonText: "Copy Plan",
                  input: 'select',
                  inputOptions: options
                }).then((result) => {
                  if (result.isConfirmed) {
                    var vars = {
                        source_plan_id: plan.id,
                        destination_client_id: result.value
                    };
                    window.axios.post('/api/annual_plan/transfer', vars)
                        .then(response => {
                            if (response.status == 200) {
                                 window.location = "/planning/"+response.data.plan.url+"/assumptions"
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });
            }
        }
    }
</script>
