var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "onboarding-org-info" }, [
    _c("h2", { staticClass: "mt-2 mb-3" }, [
      _vm._v("Step 2: Organization Information"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("label", [_vm._v("Non-Profit Name")]),
          _vm._v(" "),
          _c("v-select", {
            class: _vm.errors.name != "" ? "is-invalid" : "",
            attrs: {
              options: _vm.local_org_list,
              placeholder: "Type to search...",
              searchable: true,
              name: "name",
              id: "org_search",
              filterable: false,
            },
            on: { search: _vm.debouncedSearchOptions, input: _vm.selectOrg },
            scopedSlots: _vm._u([
              {
                key: "list-header",
                fn: function ({ search }) {
                  return [
                    search != ""
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "border-bottom border-primary border-1",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addNewOrg(search)
                              },
                            },
                          },
                          [
                            _c("a", { staticClass: "btn btn-link" }, [
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-circle-plus text-primary",
                              }),
                              _vm._v(" Add Organization as Written"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "option",
                fn: function ({ value, label }) {
                  return [
                    _c("li", { staticClass: "suboption show" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(label) +
                          "\n                    "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "no-options",
                fn: function ({ search }) {
                  return [
                    search == ""
                      ? _c("div", { staticClass: "suboption show mt-1" }, [
                          _c("em", [
                            _vm._v("Type to search for the nonprofit..."),
                          ]),
                        ])
                      : _vm.searching == true
                      ? _c("div", { staticClass: "suboption show mt-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "spinner-border spinner-border-sm text-primary float-left",
                              attrs: { role: "status" },
                            },
                            [
                              _c("span", { staticClass: "visually-hidden" }, [
                                _vm._v("Loading..."),
                              ]),
                            ]
                          ),
                          _vm._v("  Loading options\n                    "),
                        ])
                      : _vm.search_error == true
                      ? _c("div", { staticClass: "suboption show mt-1" }, [
                          _c("em", [
                            _vm._v(
                              'We seem to be having trouble searching for your organization; please enter the name and click "Add Organization as Written" to continue'
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "suboption show mt-1" }, [
                          _c("em", [
                            _vm._v("No organizations match your search"),
                          ]),
                        ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.local_client.name,
              callback: function ($$v) {
                _vm.$set(_vm.local_client, "name", $$v)
              },
              expression: "local_client.name",
            },
          }),
          _vm._v(" "),
          _vm.errors.name != ""
            ? _c("div", {
                staticClass: "text-danger invalid-feedback",
                domProps: { innerHTML: _vm._s(_vm.errors.name) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "my-2" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_client.if_agency,
                    expression: "local_client.if_agency",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", id: "agency_for_org" },
                domProps: {
                  checked: Array.isArray(_vm.local_client.if_agency)
                    ? _vm._i(_vm.local_client.if_agency, null) > -1
                    : _vm.local_client.if_agency,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.local_client.if_agency,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.local_client,
                            "if_agency",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.local_client,
                            "if_agency",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.local_client, "if_agency", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ms-2" }, [
                _vm._v(
                  "I am part of an agency or consultancy serving this non-profit."
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.local_client.if_agency == true
            ? _c("div", { staticClass: "mt-2" }, [
                _c("label", [_vm._v("Agency Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.agency_name,
                      expression: "local_client.agency_name",
                    },
                  ],
                  staticClass: "form-control me-2",
                  class: _vm.errors.agency_name != "" ? "is-invalid" : "",
                  attrs: { type: "textbox", id: "agency_name" },
                  domProps: { value: _vm.local_client.agency_name },
                  on: {
                    change: function ($event) {
                      return _vm.checkAgency()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_client,
                        "agency_name",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.agency_name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.errors.agency_name) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.searched_org == false
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("h5", [
                _vm._v(
                  "Please fill out the details for " +
                    _vm._s(_vm.local_client.name) +
                    ". "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Your responses will help us tailor our benchmark dashboard to provide you with the most valuable insights for your organization."
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mb-3 col-sm-6" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Vertical")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.vertical,
                      expression: "local_client.vertical",
                    },
                  ],
                  staticClass: "form-select",
                  class: _vm.errors.vertical != "" ? "is-invalid" : "",
                  attrs: { id: "vertical" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_client,
                          "vertical",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        _vm.errors.vertical = ""
                      },
                    ],
                  },
                },
                _vm._l(_vm.vertical_types, function (item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.errors.vertical != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.vertical) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 col-sm-6" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Annual Revenue"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_client.size,
                      expression: "local_client.size",
                    },
                  ],
                  staticClass: "form-select",
                  class: _vm.errors.size != "" ? "is-invalid" : "",
                  attrs: { id: "size" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_client,
                          "size",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        _vm.errors.size = ""
                      },
                    ],
                  },
                },
                _vm._l(_vm.revenue_levels, function (item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.errors.size != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.size) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6 mb-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Fiscal Year Start Month"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_client.fiscal_year_offset,
                        expression: "local_client.fiscal_year_offset",
                      },
                    ],
                    staticClass: "form-select",
                    attrs: { name: "local_client.fiscal_year_offset" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_client,
                          "fiscal_year_offset",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("January"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("February"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("March")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("April")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("May")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("June")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("July")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("August")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [
                      _vm._v("September"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [
                      _vm._v("October"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [
                      _vm._v("November"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "11" } }, [
                      _vm._v("December"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6 mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Website Domain"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_client.domain,
                    expression: "local_client.domain",
                  },
                ],
                staticClass: "form-control",
                class: _vm.errors.domain != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "domain" },
                domProps: { value: _vm.local_client.domain },
                on: {
                  change: function ($event) {
                    _vm.errors.domain = ""
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.local_client, "domain", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.domain != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.domain) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "label",
          { class: _vm.errors.terms_and_conditions != "" ? "text-danger" : "" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.terms_and_conditions,
                  expression: "terms_and_conditions",
                },
              ],
              staticClass: "form-check-input",
              attrs: {
                type: "checkbox",
                name: "terms_and_conditions",
                id: "terms_and_conditions",
              },
              domProps: {
                checked: Array.isArray(_vm.terms_and_conditions)
                  ? _vm._i(_vm.terms_and_conditions, null) > -1
                  : _vm.terms_and_conditions,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.terms_and_conditions,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.terms_and_conditions = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.terms_and_conditions = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.terms_and_conditions = $$c
                  }
                },
              },
            }),
            _vm._m(0),
          ]
        ),
        _vm._v(" "),
        _vm.errors.terms_and_conditions != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.terms_and_conditions) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary w-100 mt-2",
            attrs: {
              type: "submit",
              disabled:
                _vm.local_client.name == "" ||
                _vm.processing ||
                _vm.errors.name != "" ||
                _vm.errors.agency_name != "",
            },
            on: {
              click: function ($event) {
                return _vm.nextStep()
              },
            },
          },
          [
            _vm.processing
              ? _c("span", [
                  _c("div", {
                    staticClass:
                      "spinner-border spinner-border-sm text-white float-left",
                    attrs: { role: "status" },
                  }),
                  _vm._v("Processing\n                "),
                ])
              : _c("span", [
                  _vm._v("\n                    Continue "),
                  _c("i", { staticClass: "fa fa-arrow-right" }),
                ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ms-2" }, [
      _vm._v("By activating your Avid AI account, you agree to Avid's "),
      _c("a", { attrs: { href: "/privacy_policy", target: "_blank" } }, [
        _vm._v("privacy policy"),
      ]),
      _vm._v(" and the "),
      _c("a", { attrs: { href: "/terms_and_conditions", target: "_blank" } }, [
        _vm._v("terms of service"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }