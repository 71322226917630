<template>
    <div class="row chart mb-4">
        <div class="col-md-12 col-lg-10 mb-3">
            <div class="card box-content p-3" >
                <h5 v-if="title != ''" class="mt-2 mx-2 mb-1"><img class="avatar avatar-sm rounded-circle me-2" :src="client.image" /> {{title}}</h5>
                <div class="card-body">
                    <horizontal-bar-chart :id="client.id" type="revenue" :data="data" :labels="labels" v-if="data != null"></horizontal-bar-chart>
                    <div v-else>There is no projected paid media spend based upon the currently selected filters.</div>

                    <!-- Notifications -->
                    <transition name="fade-slow">
                    <div class="alert mt-3 mb-0" v-for="n in notifs" :class='n.data.class'>
                        <button class="btn btn-none px-0 close-btn" v-on:click="dismissNotification(n);"><i class="fa fa-close"
                            ></i></button>
                        {{ n.data.error_msg}}
                    </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="col"  v-if="data != null">
            <div class="row">
                <div class="col-md-6 col-lg-12 scorecard mb-3" style="max-width: 100%;">
                    <div class="card box-content p-3" >
                      <h5 class="mb-2">Total Media Spent</h5>
                        <h4 class="mt-0 mb-0">{{actuals_total | currency_with_zero}}
                        <span class="badge" :class="{ 'bg-label-success':( percent_spent > 0), 'bg-label-danger': (percent_spent < 0)}">{{percent_spent | percentage_na}}</span>
                        </h4 >
                    </div>
                </div>
                <div class="col-md-6 col-lg-12 scorecard mb-3 " style="max-width: 100%;" v-if="show_media_balance">
                    <div class="card box-content p-3" >
                      <h5 class="mb-2">Billable Media Dollars Available</h5>
                        <h4 class="mt-0 mb-0"  :class="{ 'text-danger': (media_balance_total < 0)}">{{media_balance_total | currency_with_zero}}
                        </h4 >
                    </div>
                </div>
                <div class="col-md-6 col-lg-12 scorecard mb-3 " style="max-width: 100%;" v-if="outstanding_invoices != 0">
                    <div class="card box-content p-3" >
                      <h5 class="mb-2">Outstanding Media Invoices</h5>
                        <h4 class="mt-0 mb-0" >{{outstanding_invoices | currency_with_zero}}
                        </h4 >
                    </div>
                </div>
                <div class="col-12 mb-3" style="max-width: 100%;">
                    <a :href="'/results/'+ client.url+'/projections'" class="btn btn-secondary"><i class="fa fa-btn fa-eye"></i> View Results</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'client', 'filters', 'projections', 'actuals', 'media_balance', 'notifications'],
        data() {
            return {
                data: null,
                labels: ['Projected Media Spend', 'Actual Media Spent'],
                notifs: []
            };
        },
        watch: {
            filters: {
                handler(n, o) {
                    //Only update it after it has initially been set
                    if(o != null)
                        this.renderChart()
                },
                deep: true
            }
        },
        computed: {
            projections_total() {
                var total = 0;
                for (let i in this.data) {
                    total += this.data[i][0];
                }
                return total;
            },
            actuals_total() {
                var total = 0;
                for (let i in this.data) {
                    total += this.data[i][1];
                }
                return total;
            },
            billable_projections_total() {
                var total = 0;
                for (let i in this.data) {
                    if(this.client.billable_media_types.includes(i))
                        total += this.data[i][0];
                }
                return total;
            },
            billable_actuals_total() {
                var total = 0;
                for (let i in this.data) {
                    if(this.client.billable_media_types.includes(i))
                        total += this.data[i][1];
                }
                return total;
            },
            media_balance_total() {
                //If there isn't a media balance or they have filtered out Billable / Quickbooks, return 0
                if(this.media_balance == null || Object.keys(this.media_balance).length == 0 || !this.filters.media_types.includes("Billable") || !this.filters.media_sources.includes("QuickBooks"))
                    return 0;

                let start_of_period = this.getStartDate().toISOString().slice(0, 10);
                let balance = this.media_balance[start_of_period];
                let spent = this.billable_actuals_total;

                if(this.filters.timeframe.indexOf("w_current") == -1 && this.media_balance[this.start_of_month] != null) {

                    let current_month = this.media_balance[this.start_of_month];
                    //The period's total minus the most recent month's activities and what has been spent
                    return (parseFloat(balance.paid)+ parseFloat(balance.balance)) -
                        (parseFloat(current_month.paid)+ parseFloat(current_month.balance)) - spent;
                }
                else {
                    return parseFloat(balance.paid)+ parseFloat(balance.balance) - spent;
                }

            },
            outstanding_invoices() {
                //If there isn't a media balance or they have filtered out Billable / Quickbooks, return 0
                if(this.media_balance == null || Object.keys(this.media_balance).length == 0 || !this.filters.media_types.includes("Billable") || !this.filters.media_sources.includes("QuickBooks"))
                    return 0;

                let start_of_period = this.getStartDate().toISOString().slice(0, 10);
                let balance = this.media_balance[start_of_period];

                if(this.filters.timeframe.indexOf("w_current") == -1 && this.media_balance[this.start_of_month] != null) {

                    let current_month = this.media_balance[this.start_of_month];
                    //The period's total minus the most recent month's activities and what has been spent
                    return (parseFloat(balance.outstanding) - parseFloat(current_month.outstanding));
                }
                else {
                    return parseFloat(balance.outstanding);
                }

            },
            start_of_month() {
                let date = new Date();
                return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1).toISOString().slice(0, 10);
            },
            percent_spent() {
                return (this.actuals_total - this.projections_total) / this.projections_total;
            },
            show_media_balance() {
                return this.media_balance != null && Object.keys(this.media_balance).length > 0 && this.filters.media_types.includes("Billable") && this.filters.media_sources.includes("QuickBooks");
            }
        },
        mounted() {
            this.renderChart();
            this.notifs = this.notifications;
        },
        methods: {

            dismissNotification(n) {
                window.axios.get('/api/notification/read/' + n.id);

                //Remove the notification from the list
                for(var i = 0; i < this.notifs.length; i++) {
                    if(this.notifs[i].id == n.id) {
                        this.notifs.splice(i, 1);
                    }
                }
            },
            //Calculage percent change
            getVariance(projected, actual) {
                return (actual - projected) / projected;
            },
            renderChart() {
                this.data = {};

                // Get the labels for the individual bar segments
                var sources = this.getLabels();
                //Make the starting array
                var start = [
                    this.getDataArray(this.projections, sources),
                    this.getDataArray(this.actuals, sources),
                ];

                //Nullify the array if it would should a blank chart
                if(this.calcTotalOfArray(start) == 0) {
                    this.data = null;
                    return;
                }


                //Flip it so it works with a horizontal bar chart
                let arr = this.invertArray(start);

                //Label each of the arrays
                for(var i = 0; i < arr.length; i++){
                    this.data[sources[i]] = arr[i];
                }

                //If there is a media balance to add
                if(this.show_media_balance){

                    //Add two values to the end of the array for Quickbooks
                    for(var i = 0; i < arr.length; i++){
                        this.data[sources[i]].push(0);
                        this.data[sources[i]].push(0);
                    }

                    let start_date = this.getStartDate().toISOString().slice(0, 10);
                    //If there is a media balance, add it to the chart
                    let balance = this.media_balance[start_date];

                    //If there wasn't a balance on the given date, just take the earliest balance
                    if(balance == null)
                        balance = this.media_balance[Object.keys(this.media_balance)[0]];

                    //Fill up the array with zeros
                    var media = [0,0];

                    //If the last one is the current month (It is in ASC order so it should be)
                    if(this.filters.timeframe.indexOf("w_current") == -1 && this.media_balance[this.start_of_month] != null) {

                        let current_month = this.media_balance[this.start_of_month];
                        //media.push(parseFloat(balance.invoiced) - parseFloat(current_month.invoiced));
                        media.push((parseFloat(balance.paid)+ parseFloat(balance.balance)) -
                            (parseFloat(current_month.paid)+ parseFloat(current_month.balance)));
                    }
                    else {
                        //media.push(parseFloat(balance.invoiced));
                        media.push(parseFloat(balance.paid)+ parseFloat(balance.balance));
                    }

                    if(!this.labels.includes('Billable Media Dollars Paid')) {

                        //this.labels.push('Invoiced Media');
                        this.labels.push('Billable Media Dollars Paid');
                    }

                    this.data['QuickBooks'] = media;
                }
            },
            getLabels() {
                var labels = [];
                //Look at every element
                for (let date in this.projections) {
                    //Then loop through the source names (which are the keys)
                    for (let source in this.projections[date]) {
                        if(this.filters.media_sources.includes(source) && this.isValidMediaType(source) && !labels.includes(source))
                            labels.push(source);
                    }
                }
                return labels;
            },
            getDataArray(source, labels) {
                //I need to make sure the array is in the same order as the labels
                var arr = [];
                let start_date = this.getStartDate();
                let end_date = this.getEndDate();
                for(var i = 0; i < labels.length; i++){
                    var total = 0;
                    //Look at every date
                    for (let date in source) {
                        //Then loop through the source names (which are the keys)
                        for (let arr_src in source[date]) {
                            //If it is the correct source and it is within the right time frame
                            // I also verify it hasn't been deselected from the filter (TODO: Verify this)
                            if(arr_src == labels[i] && Date.parse(date) >= start_date && Date.parse(date) <= end_date &&
                                this.filters.media_sources.includes(arr_src) && this.isValidMediaType(arr_src))

                                // Now I need to check the billable filter status
                                total += source[date][arr_src];
                        }
                    }

                    arr.push(total);
                }
                return arr;
            },
            isValidMediaType(media_type) {
                //If it is a billable media type
                if(this.client.billable_media_types.includes(media_type)) {
                    return this.filters.media_types.includes("Billable");
                }
                else {
                    return this.filters.media_types.includes("Non-Billable");
                }
            },

            getStartDate() {
                //If it is their fiscal year
                if(this.filters.timeframe.indexOf("fiscal") !== -1) {
                    var start_date = new Date(Date.parse(this.client.published_plan.start_month));
                    var today = new Date();
                    //Make sure it is within 12 months
                    today.setFullYear(today.getUTCFullYear() - 1);
                    while(start_date < today) {
                        start_date.setUTCFullYear(start_date.getUTCFullYear() + 1);
                    }
                    return start_date;
                }
                else {
                    // Get the start of the current year
                    const year = new Date().getUTCFullYear();
                    return new Date(Date.UTC(year, 0, 1));
                }

            },
            getEndDate(start_date) {
                var end_date = new Date();

                //If it shouldn't include the most recent month
                if(this.filters.timeframe.indexOf("w_current") == -1) {
                    // Get the current month and year from the Date object
                    const current_month = end_date.getMonth();
                    const current_year = end_date.getFullYear();

                    // Calculate the previous month's index
                    const previous_month = current_month === 0 ? 11 : current_month - 1;

                    // Create a new Date object for the last date of the previous month
                    return new Date(current_year, previous_month + 1, 0);
                }
                return end_date;
            },
            invertArray(matrix) {
              const numRows = matrix.length;
              const numCols = Math.max(...matrix.map(row => row.length)); // Get the maximum number of columns in the array

              const invertedMatrix = new Array(numCols).fill(null).map(() => new Array(numRows).fill(null));

              for (let i = 0; i < numRows; i++) {
                for (let j = 0; j < matrix[i].length; j++) {
                  invertedMatrix[j][i] = matrix[i][j];
                }
              }

              return invertedMatrix;
            },
            calcTotalOfArray(arr) {
              let total = 0;

              if(arr instanceof Array)
                  for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < arr[i].length; j++) {
                      total += arr[i][j];
                    }
                }
              else
                for (let i in arr) {
                    for (let j in arr[i]) {
                      total += arr[i][j];
                    }
                }


              return total;
            }

        }
    }
</script>
