<template>
    <div >
        <p v-if="filter_type==1">(For more advanced logic, <a href="#" v-on:click.prevent="filter_type = 2">you can use the advanced filter here.</a>)</p>
        <p v-if="filter_type==2">(For an better user experience, <a href="#" v-on:click.prevent="filter_type=1">switch back to the simple filter.</a>)</p>
        <div :class="((errors.filter != '' )? 'is-invalid':'')" class="mt-2" >
            <query-builder-simplified ref="simple" :client="client" v-model="filter" :is_white="!in_card"
                :include_trans="include_trans" :source="source" v-if="filter_type==1" :add_blank="add_blank"
                :static_db_columns="static_db_columns"
                :data_source_id="data_source_id"></query-builder-simplified>
            <query-inclusion-advanced ref="advanced" :client="client" v-model="filter"
                :include_trans="include_trans" :source="source" v-else :static_db_columns="static_db_columns"
                :data_source_id="data_source_id"></query-inclusion-advanced>
        </div>
        <div class="text-danger invalid-feedback mb-2" v-if="errors.filter != ''">
            {{errors.filter }}
        </div>
    </div>
</template>

<script>

    export default {

        props: {
            value: {
              type: Object,
            },
            client: {
                type: Object,
                required: true
            },
            source: {default: 'client_db' },
            data_source_id: {default: null },
            static_db_columns: {default: null },
            include_trans: {default: false },
            in_card: {default: true },
            add_blank: {default: true }

        },
        data() {
            return {
                filter: null,
                form: {
                    error: false,
                    busy: false
                },
                errors: {
                    filter: ''
                },
                filter_type: 1,
            };
        },
        beforeMount() {
            this.filter = this.value;

            //Set the filter type accordingly
            if(this.filter != undefined) {
                if(this.filter.filter_ui != undefined && this.filter.filter_ui == 'advanced')
                    this.filter_type = 1;
                else if(!this.validSimpleStatement(this.filter.filter_logic))
                    this.filter_type = 0;
            }
        },
        watch: {
            filter: {
                handler(n, o) {
                    if(this.filter != undefined && !this.validSimpleStatement(this.filter.filter_logic)){
                        this.filter_type = 0;
                    }
                    else if (this.filter != undefined && this.filter.filter_ui == 'advanced') {
                        this.filter_type = 2;
                    }
                    else{
                        this.filter_type = 1;
                    }
                    this.$emit('input', this.filter);
                },
                deep: true
            },
            value(old_pv, new_pv) {
                if(old_pv != null)
                    this.filter = old_pv;
                else
                    this.filter = null;
            }
        },
        mounted() {

        },
        methods: {
            validSimpleStatement(statement) {

                if(statement == null || statement == '')
                    return true;

                //Get each token which are numbers or parentheses
                statement = statement.replaceAll("(", " ( ").replaceAll(")", " ) ");
                const tokens = statement.match(/[\w()]+|[^\w\s()+-]+/g);
                let expectDigit = true;
                let parensCount = 0;

                //Loop through the parenthesis
                for (let i = 0; i < tokens.length; i++) {
                    const token = tokens[i];
                    //For open parenthesis, make sure this isn't the second one in a row and that we were expected a number
                    if (token === "(") {
                      if (!expectDigit || parensCount > 0)
                        return false;

                      expectDigit = true;
                      parensCount++;
                      //For closing paretheses, be looking for a number and not have more than two in a row
                    } else if (token === ")") {
                      if (expectDigit || parensCount === 0)
                        return false;

                      expectDigit = false;
                      parensCount--;
                    //Looking for a number...
                    } else if (/^\d+$/.test(token)) {
                      if (!expectDigit)
                        return false;

                      expectDigit = false;
                    } else if (token.toUpperCase() === "AND" || token.toUpperCase() === "OR") {
                      if (expectDigit)
                        return false;

                      expectDigit = true;
                    }
                    else
                        return false;
                    if (parensCount < 0) {
                      return false;
                    }
                }

                //Now make sure the same number isn't repeated in the tokens
                for (let i = 0; i < tokens.length; i++) {
                    //if it isn't a number, don't evaluate it
                    if(/^\d+$/.test(tokens[i]) === false) continue;

                    for (var j = i+1; j < tokens.length; j++)
                        if(tokens[i] == tokens[j]) return false;

                }

                return !expectDigit && parensCount === 0;
            },

            isFormValid() {
                if(this.filter_type==1)
                    return this.$refs.simple.isFormValid();

                return this.$refs.advanced.isFormValid();
            }

        }
    }
</script>
