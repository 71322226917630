var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-user client-user", attrs: { id: "edit-user" } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-8" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mb-3 col" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("First Name"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.first_name,
                    expression: "user.first_name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "first_name" },
                domProps: { value: _vm.user.first_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.user, "first_name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 col-sm-6" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Last Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.last_name,
                    expression: "user.last_name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "last_name" },
                domProps: { value: _vm.user.last_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.user, "last_name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("E-Mail Address"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors.email != "" ? "is-invalid" : "",
              attrs: { type: "text", name: "email" },
              domProps: { value: _vm.user.email },
              on: {
                change: function ($event) {
                  return _vm.validateEmail()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "email", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.email != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.email) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("Password")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "password", name: "password" },
              domProps: { value: _vm.user.password },
              on: {
                change: function ($event) {
                  return _vm.validatePassword()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "password", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Confirm Password"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.confirm_password,
                  expression: "user.confirm_password",
                },
              ],
              staticClass: "form-control",
              class: _vm.errors.confirm_password != "" ? "is-invalid" : "",
              attrs: { type: "password", name: "confirm_password" },
              domProps: { value: _vm.user.confirm_password },
              on: {
                change: function ($event) {
                  return _vm.validatePassword()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "confirm_password", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.confirm_password != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.confirm_password) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col profile-image" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Profile Image"),
                ]),
                _vm._v(" "),
                _c("span", {
                  attrs: { "data-name": _vm.user.id, id: "user_id" },
                }),
                _vm._v(" "),
                _c("vue-dropzone", {
                  ref: "'dropzone'",
                  staticClass: "profile-dropzone",
                  style: "background-image: url(" + _vm.user.image + ")",
                  attrs: {
                    id: "dropzone",
                    options: _vm.dropzoneOptions,
                    awss3: _vm.awss3,
                  },
                  on: { "vdropzone-s3-upload-success": _vm.s3UploadSuccess },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _vm.form.error
            ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
                _vm._v(
                  "\n                There was an error saving your information. Please fix the errors above and try again.\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3 mt-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  disabled: _vm.form.busy || _vm.hasError(),
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.update.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-btn fa-save" }),
                _vm._v(" \n                    Update\n                "),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }