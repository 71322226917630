<template>
    <div class="edit-user client-user" id="edit-user">

        <div class="row">

            <div class="col-sm-8">

                <!-- Name -->
                <div class="row">
                    <div class="mb-3 col">
                        <label class="form-label">First Name</label>
                        <input type="text" class="form-control" name="first_name" v-model="user.first_name">
                    </div>
                    <div class="mb-3 col-sm-6">
                        <label class="form-label">Last Name</label>
                        <input type="text" class="form-control" name="last_name" v-model="user.last_name">
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">E-Mail Address</label>
                    <input type="text" class="form-control" :class="((errors.email != '' )? 'is-invalid':'')"
                        name="email" v-model="user.email" v-on:change="validateEmail()">
                    <div class="text-danger invalid-feedback" v-if="errors.email != ''">
                        {{errors.email}}
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Password</label>
                    <input type="password" class="form-control" name="password"
                        v-model="user.password" v-on:change="validatePassword()">
                </div>
                <div class="mb-3">
                    <label class="form-label">Confirm Password</label>
                    <input type="password" class="form-control" name="confirm_password"
                        :class="((errors.confirm_password != '' )? 'is-invalid':'')"
                        v-model="user.confirm_password"  v-on:change="validatePassword()">
                    <div class="text-danger invalid-feedback" v-if="errors.confirm_password != ''">
                        {{errors.confirm_password}}
                    </div>
                </div>

            </div>
            <div class="col profile-image">
                <div class="row"><div class="col">
                    <!-- Photo Preview-->
                    <label class="form-label">Profile Image</label>
                    <span :data-name='user.id' id="user_id"></span>
                    <vue-dropzone ref="'dropzone'" id="dropzone" class="profile-dropzone"
                        v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                        :options="dropzoneOptions" :awss3="awss3" :style="'background-image: url(' +user.image+')'" ></vue-dropzone>
                </div></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an error saving your information. Please fix the errors above and try again.
                </div>
                <!-- Update Button -->
                <div class="mb-3 mt-3">
                    <button type="submit" class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy || hasError()">
                        <i class="fa fa-btn fa-save"></i> 
                        Update
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    export default {
        components: {
            vueDropzone: vue2Dropzone,
        },
        props: ['user'],
        data() {
            return {

                dropzoneOptions: {
                    url: "#",
                    resizeWidth: 512,
                    resizeHeight: 512,
                    resizeMethod: 'crop',
                    acceptedFiles: "image/*",
                    dictDefaultMessage: "Click or Drag/Drop a new profile image here",
                    renameFile: function (file) {
                        let new_name = "users/" + Date.now() + "_" + file.name;
                        return new_name;
                    },
                },
                awss3: {
                      signingURL: '/api/user/get_signed_url', //Where you will get signed url
                      headers: {
                        'X-Requested-With':'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                      },
                      params : {},
                      sendFileToServer : false //If you want to upload file to your server along with s3
                },
                form: {
                    busy: false,
                    error: false
                },
                errors :{
                    email: "",
                    confirm_password: ""
                },
            };
        },
        mounted() {
            this.user.password = "";
            this.user.confirm_password = "";
        },

        methods: {
            validateEmail() {
                var self = this;
                window.axios.post('/api/user/check_email', this.user)
                  .then(response => {
                    if(response.status == 200) {
                        if(response.data.exists) {
                            self.errors.email = 'The email entered is already attached to an existing user. You should log out and use "Forgot Password" to recover that login.';
                        }
                        else
                            self.errors.email = '';
                    }
                  });
            },
            validatePassword() {

                if(this.user.confirm_password != "" && this.user.password != this.user.confirm_password)
                    this.errors.confirm_password = 'The password entered does not match the one above.';
                else
                    this.errors.confirm_password = '';
            },
            update() {

                if(this.user.password != "" && this.user.password != this.user.confirm_password) {
                    this.errors.confirm_password = 'Please confirm the new password by entering it again.';
                    return;
                }

                var self = this;
                this.form.busy = true;
                window.axios.put('/api/user/' + this.user.id, this.user)
                  .then(response => {
                    self.form.busy = false;
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">User Profile Updated!</h4>',
                        target: '#edit-user',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        }
                    });
                  }).catch(error => {
                    // Handle errors here
                    if (error.response.status === 422) {
                      self.form.error = true;
                      self.errors.email = 'The email entered is not in the correct format.';
                    }
                  });;

            },
            hasError() {
                return this.errors.email != "" || this.errors.confirm_password != "" ;
            },
            s3UploadSuccess(s3ObjectLocation){
                //Stupid Amazon URL encoding my folder
                s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
                this.user.photo_url = s3ObjectLocation;
                this.user.image = s3ObjectLocation;
                this.update();
            }
        }
    }
</script>
