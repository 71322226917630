var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "campaign-list" }, [
    _vm.client.client_mission == null || _vm.client.why_give == null
      ? _c("div", { staticClass: "alert alert-warning mb-3 box-content" }, [
          _vm._v(
            "\n        Organization details that are necessary for campaign generation are currently empty, "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "/settings/" + _vm.client.url + "#organization-details",
              },
            },
            [_vm._v("please fill them out here")]
          ),
          _vm._v(".\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.campaigns.length == 0
      ? _c("div", [
          _c("div", { staticClass: "card box-content mb-3" }, [
            _c("div", { staticClass: "card-body mt-3 text-center" }, [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v(
                  "No experiment treatments have been created for " +
                    _vm._s(_vm.client.name) +
                    " yet."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "An experiment treatment is an single asset like an ad or single email. You will be able import your existing version and then generate new versions to test against it."
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary mb-3 ms-3",
                  attrs: {
                    href: "/experiment_treatments/" + _vm.client.url + "/new",
                  },
                },
                [_vm._m(0)]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.campaigns.length != 0
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("h2", { staticClass: "d-inline" }, [
                _vm._v("Experiment Treatments"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-secondary mb-2 float-end",
                  attrs: {
                    href: "/experiment_treatments/" + _vm.client.url + "/new",
                  },
                },
                [
                  _c("i", { staticClass: "fa fas fa-fw fa-btn fa-plus" }),
                  _vm._v(" Add Treatment\n                "),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card box-content mb-3" }, [
            _c("div", { staticClass: "card-body p-0" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive mb-2" }, [
                _c("table", { staticClass: "table gy-0 m-0" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { staticClass: "table-border-bottom-0" },
                    [
                      _vm._l(
                        _vm.experiment_variants,
                        function (campaign, index) {
                          return _vm.experiment_variants.length > 0
                            ? _c("tr", [
                                _c("td", { staticClass: "align-middle" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/campaigns/" +
                                          _vm.client.url +
                                          "/assets/" +
                                          campaign.url +
                                          "/" +
                                          campaign.asset_types[0].id +
                                          "/false",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(campaign.name))]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-sm-table-cell placeholder-glow",
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getIcons(campaign.asset_types)
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      _vm._s(campaign.asset_types[0].name) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-sm-table-cell placeholder-glow",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "badge",
                                        class: _vm.getStatusFontColor(
                                          campaign.status
                                        ),
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("propercase")(
                                              campaign.status
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-md-table-cell d-sm-table-cell placeholder-glow",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("date")(campaign.created_at)
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "align-middle d-none d-md-table-cell d-sm-table-cell placeholder-glow",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary btn-sm ms-1 mb-1",
                                        attrs: {
                                          title: "Copy Experiment Treatment",
                                          "data-bs-toggle": "tooltip",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyExperiment(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa-solid fa-copy me-0",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.hasAssetEdits(index)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-sm ms-1 mb-1",
                                            attrs: {
                                              href:
                                                "/campaigns/" +
                                                _vm.client.url +
                                                "/review/" +
                                                campaign.url,
                                              title: "Review Changes",
                                              "data-bs-toggle": "tooltip",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa-solid fa-magnifying-glass me-0",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary btn-sm ms-1 mb-1",
                                        attrs: {
                                          title: "Delete Experiment Treatment",
                                          "data-bs-toggle": "tooltip",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteExperiment(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa-solid fa-trash me-0",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e()
                        }
                      ),
                      _vm._v(" "),
                      _vm.experiment_variants.length == 0
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n                                    There is no experiment treatments created for this organization yet.\n                                "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-fw fa-plus" }),
      _vm._v("Add Treatment"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "align-middle pt-0" }, [_vm._v("Asset Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Asset Type"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Status"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Created On"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none pt-0 d-sm-table-cell" }, [
          _vm._v("Actions"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }