var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asset-review-edits-page" }, [
    _vm.assets.length == 0
      ? _c("div", [
          _c("h2", { staticClass: "mt-5" }, [
            _vm._v(
              "There are no client approved assets ready for your review for this campaign."
            ),
          ]),
        ])
      : _c("div", [
          _c("h2", [_vm._v("Review Approved Assets")]),
          _vm._v(" "),
          _vm.local_assets.length > 0
            ? _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "transition-group",
                      { attrs: { tag: "div", name: "fade-slow" } },
                      _vm._l(_vm.local_assets, function (asset, index) {
                        return _c(
                          "div",
                          { key: asset.id, staticClass: "mb-4" },
                          [
                            _c("h3", { staticClass: "mb-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getAssetTypeNameInSingular(
                                    asset.asset_type.name
                                  )
                                ) +
                                  ": " +
                                  _vm._s(asset.name)
                              ),
                            ]),
                            _vm._v(" "),
                            asset.id > 0
                              ? _c("div", { staticClass: "created_by_label" }, [
                                  _c("span", [
                                    _c("label", { staticClass: "mb-0 me-1" }, [
                                      _vm._v("Created By: "),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        asset.created_by_user.first_name +
                                          " " +
                                          asset.created_by_user.last_name
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Asset #" +
                                  _vm._s(index + 1) +
                                  " of " +
                                  _vm._s(_vm.local_assets.length)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("asset-details-review-edits", {
                              ref: "asset",
                              refInFor: true,
                              attrs: { asset: asset, client: _vm.client },
                              on: {
                                assetReviewedWithStatusChange:
                                  _vm.assetReviewedWithStatusChange,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", [_vm._m(1)]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "The differences between the Approved version and the Original are highlighted in the right column. "
      ),
      _c("strong", [
        _vm._v("You can make additional changes to the "),
        _c("em", [_vm._v("Client's Edited Version")]),
        _vm._v(" or leave it as is."),
      ]),
      _vm._v(
        " Once finished, set the status as approved or send it back to them for further review."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("em", [_vm._v("All assets have now been reviewed.")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }