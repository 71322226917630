var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "generate-ppt--filter-panel sticky-filter-panel" },
    [
      _c(
        "div",
        { staticClass: "generate-ppt--filter-panel--content flex-grow-1 mx-4" },
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("label", [_vm._v("Selected:")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-badge-container" },
              [
                _vm._l(_vm.applied_filters_list, function (filter, index) {
                  return filter.filter_id != -1
                    ? _c(
                        "div",
                        {
                          key: filter.filter_id + filter.filter_value,
                          staticClass: "badge badge-primary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setActiveTab(filter.group_index)
                            },
                          },
                        },
                        [
                          filter.filter_type == "date_range"
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(filter.filter_label) +
                                    ": " +
                                    _vm._s(
                                      _vm.dateRangeDisplay(filter.filter_value)
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : filter.filter_type == "month_range"
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(filter.filter_label) +
                                    ": " +
                                    _vm._s(
                                      _vm.monthRangeDisplay(filter.filter_value)
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : filter.filter_type == "single_select"
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(filter.filter_label) +
                                    ' = "' +
                                    _vm._s(filter.filter_value) +
                                    '" \n                    '
                                ),
                              ])
                            : filter.filter_type == "multi_select" ||
                              filter.filter_type == "month_picker"
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(filter.filter_label) +
                                    _vm._s(
                                      filter.filter_value.length > 1
                                        ? " (" +
                                            filter.filter_value.length +
                                            ")"
                                        : ' = "' + filter.filter_value[0] + '"'
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : filter.filter_type == "numeric_slider"
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(filter.filter_label) +
                                    " between " +
                                    _vm._s(filter.filter_value[0]) +
                                    " and " +
                                    _vm._s(filter.filter_value[1]) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !filter.is_required
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-xs btn-link p-0 ps-1",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeAppliedFilter(
                                        filter,
                                        index
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fas fa-close" })]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.applied_filters_list, function (filter, index) {
                  return filter.filter_id == -1
                    ? _c(
                        "div",
                        {
                          key: filter.filter_id + filter.filter_value,
                          staticClass: "badge badge-secondary text-dark",
                        },
                        [
                          filter.filter_type == "single_select"
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(filter.filter_label) +
                                    ' = "' +
                                    _vm._s(filter.filter_value) +
                                    '" \n                    '
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-link p-0 ps-1",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeAppliedFilter(filter, index)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-close" })]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                {
                  staticClass: "btn-group toggle-btn-group mb-2 w-100",
                  attrs: { role: "group" },
                },
                _vm._l(_vm.getNonEmptyFilterGroup, function (tab, index) {
                  return tab.filter_group
                    ? _c(
                        "button",
                        {
                          staticClass: "btn",
                          class:
                            index == _vm.active_tab_index
                              ? "btn-primary"
                              : "btn-white",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(tab.filter_group) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("hr", { attrs: { id: "filter-panel-body-hr" } }),
          _vm._v(" "),
          _vm._l(_vm.getNonEmptyFilterGroup, function (group, group_index) {
            return _c(
              "div",
              {
                key: group.filter_group,
                staticClass: "row flex-grow-1",
                attrs: { hidden: group_index != _vm.active_tab_index },
              },
              _vm._l(group.filters, function (filter, filter_index) {
                return !filter.options || filter.options.length != 1
                  ? _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _c("advanced-filter-dropdown", {
                          key: group.filter_group + "-" + filter.column,
                          attrs: {
                            filter: filter,
                            group_index: group_index,
                            field_index: filter_index,
                            active_group_index: _vm.active_tab_index,
                            db_search: filter.db_search,
                          },
                          on: {
                            updateFilter: _vm.updateFilterInGroup,
                            searchInLongOptionList: _vm.searchInLongOptionList,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              0
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }