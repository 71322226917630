var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "versions" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.plans, function (plan, index) {
              return _c("tr", { attrs: { id: "row" + plan.id } }, [
                _c("td", [_vm._v(_vm._s(plan.name))]),
                _vm._v(" "),
                _c("td", { attrs: { align: "center" } }, [
                  plan.is_active_plan == 1
                    ? _c("i", { staticClass: "fas fa-check" })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", { attrs: { align: "center" } }, [
                  plan.is_published_plan == 1
                    ? _c("i", { staticClass: "fas fa-check" })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm._f("date")(plan.created_at)))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(plan.creator.name))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("date")(
                        _vm.checkDuplicate(
                          plan.created_at,
                          plan.updated_at,
                          plan.updated_at
                        )
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.checkDuplicate(
                        plan.created_at,
                        plan.updated_at,
                        plan.updater.name
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "actions" }, [
                  plan.is_published_plan == 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary",
                          attrs: { title: "Publish" },
                          on: {
                            click: function ($event) {
                              return _vm.publishPlan(plan)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-upload" }),
                          _vm._v(" Publish"),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-secondary",
                          attrs: { title: "Unpublish" },
                          on: {
                            click: function ($event) {
                              return _vm.unpublishPlan(plan)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-ban" }),
                          _vm._v(" Unpublish"),
                        ]
                      ),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Version Name")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Active")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Published")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Created On")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Created By")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Updated On")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Updated By")]),
        _vm._v(" "),
        _c("th", { staticClass: "actions", attrs: { scope: "col" } }, [
          _vm._v("Actions"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }