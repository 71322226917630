<template>
    <div class="onboarding-current-systems">       

        <h2 class="mt-2 mb-3">Step 3: Personalize Your Experience</h2>
        <p>Select all of the systems that your organization uses. You can select multiple options in any category. If your tool isn't in the list, click <em>Add Unlisted System</em>.</p>

        <div class="row mt-3">
            <div class="mb-3 integration_type" v-for="type in integration_types" :id="type" :class="type == 'crm' ? 'col-12' : 'col-sm-6'">
                <label><span v-if="type == 'crm'">CRM (Required)</span><span v-else-if="type == 'email'">Email Marketing</span><span v-else>{{type | propercase}}</span></label>
                <v-select :options="local_system_options[type]" label="display_name"
                    placeholder="Type to search..." multiple
                    v-model="current_systems[type]"
                    :class="((errors[type] != '' )? 'is-invalid':'')"
                    :searchable="true" @search="(search, loading) => fetchOptions(search, loading, type)">
                    <template #list-footer="{search}">
                        <li class="border-top border-primary border-1 mt-2" @click.prevent="selectSystem(search, type)" v-if="search != ''">
                            <a class="btn btn-none pb-0">+ Add Unlisted System</a></li>
                    </template>

                    <template #no-options="{ search, searching, loading }">
                        <div class="mt-2"><em>Press "+ Add Unlisted System" to add.</em></div>
                    </template>
                </v-select>                
                <div class="text-danger invalid-feedback" v-if="errors[type] != ''">
                    {{errors[type]}}
                </div>
            </div>
        </div>         
        
        <div class="row mt-2">
            <div class="col-12">
                <button type="submit" class="btn btn-primary w-100 mt-2" @click="nextStep()" :disabled="processing">
                    <span v-if="processing">
                        <div class="spinner-border  spinner-border-sm text-white float-left" role="status"></div>Processing
                    </span>
                    <span v-else>
                        Continue <i class="fa fa-arrow-right"></i>
                    </span>
                </button>                
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";   

    export default {
        components: { vSelect },
        props: ['user', 'client', 'integrations'],
        data() {
            return {
                current_systems: {
                    crm: [],
                    advertising: [],
                    email: [],
                    email_form: [],
                    donation_form: []
                },
                local_system_options: {
                    crm: [],
                    advertising: [],
                    email: [],
                    email_form: [],
                    donation_form: []
                },
                original_system_options: {
                    crm: [],
                    advertising: [],
                    email: [],
                    email_form: [],
                    donation_form: []
                },
                integration_types: ['crm', 'advertising', 'email', 'email_form', 'donation_form'], // email is the type for email marketing
                errors: [],
                processing: false
            };
        },
        beforeMount() {
            this.getSystemsList();
            //Loop through integration types and add them as a key to errors
            for(let i = 0; i < this.integration_types.length; i++)
                this.errors[this.integration_types[i]] = false;

            if(this.client.systems != null){
                this.current_systems = this.client.systems;
            }
        },
        methods: {
            getSystemsList(){
               this.integrations.forEach(integration => {
                    Object.keys(this.original_system_options).forEach(int_type =>{
                        if(integration.integration_types.includes(int_type)){
                            this.original_system_options[int_type].push(integration.display_name);
                            this.local_system_options[int_type].push(integration.display_name)
                        }
                    });
                });
            },
            fetchOptions(search, loading, field_name){
                var search_result = this.original_system_options[field_name].filter(integration => integration.toLowerCase().includes(search.toLowerCase()));
                this.local_system_options[field_name] = [...search_result];
            },
            selectSystem(search, field_name){
                if(search == '')
                    return;
                this.current_systems[field_name].push(search);
                //Remove duplicates
                this.current_systems[field_name] = this.current_systems[field_name].filter((item, index, self) => {
                    return self.indexOf(item) === index;
                });// Clear search key

                let inputs = document.querySelectorAll('.vs__search');
                for(let i = 0; i < inputs.length; i++) {
                    // Check if input has the matching value and then clear it
                    if(inputs[i].value === search) {
                        inputs[i].value = ''; // Clear the value
                        // If it's part of a Vue component, trigger an input event to ensure reactivity
                        inputs[i].dispatchEvent(new Event('input'));
                    }
                }
                this.$forceUpdate();

            },
            hasErrors() {//validate the form
                if(!this.current_systems.crm || this.current_systems.crm.length == 0){
                    this.errors.crm = "Your CRM is required.";
                    this.$forceUpdate();
                    return true;
                } else
                    this.errors.crm = "";
                
                return false; 
            },
            nextStep(){
                if(this.hasErrors()) {
                    return;
                }

                //If they have manually entered into a system, capture it
                let integrations = document.querySelectorAll('.integration_type');
                for(let i = 0; i < integrations.length; i++){
                    let input = integrations[i].querySelector('.vs__search');

                    if(input.value != ''){
                        //Make sure it isn't already in the array too
                        if (!this.current_systems[integrations[i].id].includes(input.value))
                            this.current_systems[integrations[i].id].push(input.value);
                        input.value = '';
                    }
                }

                var client = this.client;
                client.systems = this.current_systems;
                this.processing = true;

                window.axios.put('/api/client/' + client.id, client)
                    .then(response => {
                        this.processing = false;
                        this.$emit('nextStep', response.data.data);
                    });

                this.$forceUpdate();
            },
        }
    }
</script>
