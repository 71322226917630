var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "onboarding-current-systems" }, [
    _c("h2", { staticClass: "mt-2 mb-3" }, [
      _vm._v("Step 3: Personalize Your Experience"),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mt-3" },
      _vm._l(_vm.integration_types, function (type) {
        return _c(
          "div",
          {
            staticClass: "mb-3 integration_type",
            class: type == "crm" ? "col-12" : "col-sm-6",
            attrs: { id: type },
          },
          [
            _c("label", [
              type == "crm"
                ? _c("span", [_vm._v("CRM (Required)")])
                : type == "email"
                ? _c("span", [_vm._v("Email Marketing")])
                : _c("span", [_vm._v(_vm._s(_vm._f("propercase")(type)))]),
            ]),
            _vm._v(" "),
            _c("v-select", {
              class: _vm.errors[type] != "" ? "is-invalid" : "",
              attrs: {
                options: _vm.local_system_options[type],
                label: "display_name",
                placeholder: "Type to search...",
                multiple: "",
                searchable: true,
              },
              on: {
                search: (search, loading) =>
                  _vm.fetchOptions(search, loading, type),
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "list-footer",
                    fn: function ({ search }) {
                      return [
                        search != ""
                          ? _c(
                              "li",
                              {
                                staticClass:
                                  "border-top border-primary border-1 mt-2",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.selectSystem(search, type)
                                  },
                                },
                              },
                              [
                                _c("a", { staticClass: "btn btn-none pb-0" }, [
                                  _vm._v("+ Add Unlisted System"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "no-options",
                    fn: function ({ search, searching, loading }) {
                      return [
                        _c("div", { staticClass: "mt-2" }, [
                          _c("em", [
                            _vm._v('Press "+ Add Unlisted System" to add.'),
                          ]),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.current_systems[type],
                callback: function ($$v) {
                  _vm.$set(_vm.current_systems, type, $$v)
                },
                expression: "current_systems[type]",
              },
            }),
            _vm._v(" "),
            _vm.errors[type] != ""
              ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.errors[type]) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary w-100 mt-2",
            attrs: { type: "submit", disabled: _vm.processing },
            on: {
              click: function ($event) {
                return _vm.nextStep()
              },
            },
          },
          [
            _vm.processing
              ? _c("span", [
                  _c("div", {
                    staticClass:
                      "spinner-border spinner-border-sm text-white float-left",
                    attrs: { role: "status" },
                  }),
                  _vm._v("Processing\n                "),
                ])
              : _c("span", [
                  _vm._v("\n                    Continue "),
                  _c("i", { staticClass: "fa fa-arrow-right" }),
                ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Select all of the systems that your organization uses. You can select multiple options in any category. If your tool isn't in the list, click "
      ),
      _c("em", [_vm._v("Add Unlisted System")]),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }