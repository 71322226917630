<template>
    <div class="generate-ppt p-2 mb-4" id="generate-ppt-page">
        <h1 class="mb-2">Generate Presentation</h1>

        <div class="row px-2">
            <nav id="generate-ppt-tab-nav" class="col-12 navbar navbar-expand primary-tab-bar pb-0">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav">
                            <li class="nav-item" v-for="tab_name in generate_ppt_tabs">
                                <a class="nav-link pb-0 mb-0 primary-tab mx-2" :class="{'primary-tab-active': tab_name == generate_ppt_tab_active}"
                                href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(tab_name)">
                                    <span>{{ tab_name }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div class="card box-content py-3">
                <div class="card-body">
                    <div v-if="generate_ppt_tab_active == 'BUILD PRESENTATION'">
                        <generate-presentation-build-new :client="client" :report_pages="report_pages"
                            ref="generate"></generate-presentation-build-new>
                    </div>
                    <div v-if="generate_ppt_tab_active == 'PREVIOUS PRESENTATIONS'">
                        <generate-presentation-previous-list :client="client" @clone="clonePresentation"
                            :exports="exports"></generate-presentation-previous-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';

    export default {
        components: {
            Swal
        },
        props: ['report_pages', 'client', 'exports'],
        data() {
            return {
                generate_ppt_tabs:['BUILD PRESENTATION', 'PREVIOUS PRESENTATIONS'],
                generate_ppt_tab_active: 'BUILD PRESENTATION',
            };
        },
        beforeMount() {

        },
        mounted() {

        },
        beforeUnmount() {
 
        },
        computed: {

        },
        watch:{

        },
        methods: {
            clonePresentation(data){
                this.setActiveTab('BUILD PRESENTATION');
                this.$nextTick(() => {
                    this.$refs.generate.clonePresentation(data);
                });
            },
            setActiveTab(tab_name){
                this.generate_ppt_tab_active = tab_name;
                this.$forceUpdate();
            },
        }
    }
</script>
