<template>
    <div class="scorecard-template">
        <div v-if="chart_selected.data" class="mt-1">
            <img id="scorecard-image" v-if="url != null " :src="url" class="float-start me-3" >        
            <h4 class="mb-0" >{{current_value}}
                <span class="badge ms-2" v-if="variance != null"
                :class="{ 'bg-label-success':( variance >= 0), 'bg-label-danger': (variance < 0)}"
                >{{variance | percentage_na}}</span>
            </h4 >       
        </div>
        <div v-else class='d-inline-block text-center'>
            <h1 class="text-secondary_v1  mb-1"><i class="fa-solid fa-chart-simple"></i></h1>
            <h5 class="text-secondary_v1  mb-2"><em>Press the <strong>Preview</strong> button to render the chart</em></h5>
        </div>
    </div>
</template>

<script>
    // import moment from 'moment';
    // import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        props: ['chart_selected', 'if_refreshing'],
        data() {
            return {
                current_value: null,
                variance: null
            };
        },
        watch:{
            chart_selected(old_value, new_value){
                this.local_chart_selected = {...this.chart_selected}
                if (this.chart_selected.data){
                    this.renderChart()
                }
            },
            if_refreshing:{
                handler: function(newVal, oldVal) {
                    this.$forceUpdate();
                }
            }
        },
        computed:{
            url(){
                //If it is empty or doesn't match the format of a URL
                if(this.chart_selected.subtitle == null || !this.chart_selected.subtitle.match(/\b\S+/gi)){
                    return null
                }
                return this.chart_selected.subtitle
            }
        },
        mounted() {
            if (this.chart_selected.data){
                this.renderChart();
            }
        },
        methods: {
            renderChart() {
                this.current_value = null

                // TODO: scorecard doesn't handle order_by right now, I am reversing the data array manually to get the latest value 
                Object.keys(this.chart_selected.data).forEach(key => {
                    var reversedData = this.chart_selected.data[key].reverse();
                    this.current_value = reversedData[0];
                    if (reversedData.length > 1){
                        this.variance = 0;
                        if(reversedData[1] != null &&  reversedData[1] != 0) {
                            this.variance = (this.current_value -  reversedData[1]) / reversedData[1];
                        }                        
                    }
                });

                this.applyFormatToCurrentValue()
            },
            applyFormatToCurrentValue(){
                // loop through metric_objects in chart to find format
                var formatType = 'number';
                if(this.chart_selected.metric_formats != null && this.chart_selected.metric_formats[0] != undefined)
                    var formatType = this.chart_selected.metric_formats[0];

                if(formatType == 'money'){
                    //Call the currency filter
                    this.current_value = this.$options.filters.currency_na(this.current_value)
                }
                else if(formatType == 'percent'){
                    this.current_value = this.$options.filters.percentage_na(this.current_value)
                }
                else {
                    this.current_value = this.$options.filters.number_na(this.current_value)
                }
            }
        }
    }
</script>
