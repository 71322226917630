<template>
    <div class="report-builder-list">
       
        <h2 class="mb-3">Report Builder</h2>
        <div class="row">
            <div class="col-sm-4  mb-3">
                <select class="form-select d-inline white-background" v-model="local_client_id"
                    @change="changeClientSelection()">
                    <option value="" selected>*** Template Reports ***</option>
                    <option v-for="c in clients" v-bind:value="c.id">{{c.name}} Reports</option>
                </select>
            </div>
            <div class="col mb-3">
                <a v-if="local_client_id!='' && local_report_pages.length > 0 && local_blends.length <= 1" class="btn btn-secondary float-sm-end ms-3" data-bs-toggle="modal" data-bs-target="#dimension-filter-modal">
                    <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Custom Filter
                </a>
                <div v-else-if="local_client_id != '' && local_report_pages.length > 0 && local_blends.length > 1" class="dropdown float-sm-end ms-3">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Custom Filter
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li v-for="blend in local_blends" :key="blend.id" >
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#dimension-filter-modal" @click.prevent="selected_blend = blend">{{ blend.type | propercase}}</a>
                        </li>
                    </ul>
                </div>
                <a v-if="local_client_id=='' && local_report_pages.length > 0" class="btn btn-primary float-sm-end"  :href="'/insights/report_builder/template/null/new'">
                    <i class="fa fas fa-fw fa-btn fa-plus"></i> Create a Report
                </a>
                <a v-if="local_client_id!='' && local_report_pages.length > 0" class="btn btn-primary float-sm-end"  :href="'/insights/report_builder/client_specific/' + local_client.url + '/new'">
                    <i class="fa fas fa-fw fa-btn fa-plus"></i> Create a Report
                </a>

            </div>
        </div>
        <div v-if="local_report_pages.length > 0" class="card box-content mb-3">
            <div class="card-body p-0">
                <div class="overflow-hidden rounded-top">
                    <div class="p-1 bg-dark"></div>
                </div>
                <div class="table-responsive mb-2">
                    <table class="table gy-0 m-0">
                        <thead class="table-dark">
                            <tr>
                                <th class='align-middle pt-0'>Report Name</th>
                                <th class="align-middle pt-0">Status</th>
                                <th class="align-middle pt-0">Group Name</th>
                                <th class="align-middle d-none pt-0 d-sm-table-cell">Data Blend Type</th>
                                <th class="align-middle d-none pt-0 d-sm-table-cell">Last Updated At</th>
                                <th class="align-middle d-none pt-0 d-sm-table-cell" v-if="local_client_id==''">Last Deployed At</th>
                                <th class="align-middle d-none pt-0 d-sm-table-cell text-end">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            <tr v-for="(page, index) in local_report_pages">
                                <td class="align-middle">
                                    <a :href="'/insights/report_builder/' + page.id">{{ page.name }}</a>
                                </td>
                                <td class="align-middle">
                                    <div class="badge p-2" :class="{'badge-secondary': (page.status == 'archived'), 'badge-success' : (page.status == 'active'), 'badge-primary': (page.status == 'draft')}">{{ page.status | propercase }}
                                    </div>
                                </td>
                                <td class="align-middle">
                                    {{ page.group_name }}
                                </td>
                                <td class="align-middle d-none d-sm-table-cell">
                                    {{ page.data_blend_type | propercase}}
                                </td>
                                <td class="align-middle d-none d-sm-table-cell">
                                    {{ page.updated_at | datetime }}
                                </td>
                                <td class="align-middle d-none d-sm-table-cell"  v-if="local_client_id==''">
                                    {{ page.deployed_at | datetime }}
                                </td>
                                <td class="align-middle d-none d-sm-table-cell text-end">
                                    <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Deploy Report: This will update existing copies and create new versions in organizations that don't yet have a copy of this report." data-bs-toggle="tooltip" v-on:click="deployTemplate(page.id)" v-if="page.is_template">
                                        <i class="fa-solid fa-rocket me-0" aria-hidden="true"></i>
                                    </button>
                                    <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Copy Report: This will duplicate the report and all associated charts and filters." data-bs-toggle="tooltip" v-on:click="copyReport(page.id)">
                                        <i class="fa-solid fa-copy me-0" aria-hidden="true"></i>
                                    </button>

                                    <a class="btn btn-secondary btn-sm ms-1 mb-1" title="View Live Report" data-bs-toggle="tooltip" v-if="page.is_template == false" :href="'/insights/'+local_client.url+'/' + page.url">
                                        <i class="fa-solid fa-eye me-0" aria-hidden="true"></i>
                                    </a>
                                    <button class="btn btn-secondary btn-sm ms-1 mb-1" title="Delete Report" data-bs-toggle="tooltip" v-on:click="deleteReport(page.id)">
                                        <i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="card box-content mb-3">
                <div class="card-body mt-3 text-center">
                    <h3 class="text-center">No reports have been created yet.</h3>
                    <a v-if="local_client_id==''" class="btn btn-primary mb-3"  :href="'/insights/report_builder/template/null/new'">
                        <i class="fa fas fa-fw fa-btn fa-plus"></i> Create a Report
                    </a>
                    <a v-if="local_client_id!=''" class="btn btn-primary mb-3"  :href="'/insights/report_builder/client_specific/' + local_client.url + '/new'">
                        <i class="fa fas fa-fw fa-btn fa-plus"></i> Create a Report
                    </a>
                </div>
            </div>
        </div>
        <div class="modal fade" id="dimension-filter-modal"
            aria-labelledby="dimension-filter-modal" data-bs-backdrop="static" aria-hidden="true">
            <report-builder-udf-popup :client_id="local_client_id" :data_blend="selected_blend" @updateFilterWithinGroup="addFilter" :show_group_name="true"></report-builder-udf-popup>
        </div>
   
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
        components: {Swal},
        props: ['report_pages', 'clients'],
        data() {
            return {
                source_pages:null,
                local_report_pages:[],
                local_blends: [],
                selected_blend: null,
                local_client_id: "",
                local_client: {}
            };
        },
        beforeMount() { 
            //Clone the report_pages object
            this.local_report_pages = JSON.parse(JSON.stringify(this.report_pages));
            this.source_pages = JSON.parse(JSON.stringify(this.report_pages));
            const params = new URLSearchParams(window.location.search);
            //If the URL containst the word list, get the last part of the URL and set it as the client
            if(window.location.href.includes('list')){
                var url = window.location.href;
                var client_id = url.substring(url.lastIndexOf('/') + 1);
                this.local_client = Object.values(this.clients).find(c => c.id == client_id);       
                this.local_client_id = client_id;
                this.local_blends = this.local_client.data_blends;
            }
        },
        mounted() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            addFilter(dim){
                dim.client_id = this.local_client_id;
                dim.data_blend_type =  this.selected_blend.type;

                window.axios.post('/api/metric_filter/deploy', dim)
                  .then(response => {
                    Swal.fire({
                      title: "Success!",
                      text: "A user-defined filter was deployed to all " + this.selected_blend.type + " report pages.",
                      icon: "success",
                      iconColor:  "#25473E",
                      confirmButtonColor: "#25473E",
                    });
                  });

                var myModalEl = document.getElementById('dimension-filter-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
            getReportPageList(){
                let url = '/api/insights/page';
                if(this.local_client_id != null)
                    url = '/api/insights/page/' + this.local_client_id;

                window.axios.get(url)
                  .then(response => {
                    this.local_report_pages = response.data.pages;
                  });
            },
            getDataBlendList(){
                this.local_blends = [];

                if (this.local_client_id != null && this.local_client_id != ""){

                    this.source_pages.forEach(report => {
                        if (!this.local_blends.some(blend => blend.id === report.data_blend.id))
                            this.local_blends.push(report.data_blend);
                    });

                    if(this.local_blends.length == 1)
                        this.selected_blend = this.local_blends[0];
                }
            },
            changeClientSelection(){
                var newURL;
                if(this.local_client_id){
                    this.local_client = Object.values(this.clients).find(c => c.id == this.local_client_id);
                    newURL = '/insights/report_builder/list/'+this.local_client.id;
                }else {
                    this.local_client = {};
                    newURL = '/insights/report_builder';
                }

                window.history.replaceState({}, {}, newURL);

                this.local_blends = this.local_client.data_blends;
                this.getReportPageList();
            },
            copyReport(report_page_id) {

                // Initialize the options array with the default option
                var options = ['*** Template Report ***'];

                // Convert this.clients into an array of {id, name} objects and add them to the options array
                Object.keys(this.clients).forEach(key => {
                  options[key] = this.clients[key].name;
                });

                Swal.fire({
                  title: "Copy this report?",
                  text: "Please select the client to copy this report to.",
                  input: "select",
                  inputPlaceholder: "Select an organization",
                  inputOptions: options,
                  icon: "question",
                  iconColor: "#46705F",
                  showCancelButton: true,
                  confirmButtonColor: "#46705F",
                  confirmButtonText: "Copy",
                  inputValidator: (value) => {
                    return new Promise((resolve) => {
                      if (value) {
                        resolve();
                      } else {
                        resolve('You need to select a client');
                      }
                    });
                  },

                }).then((result) => {
                    if (result.isConfirmed) {
                        let client_id = -1;
                        //Find the client ID for the given selection
                        if(result.value > 0)
                            client_id = this.clients[result.value].id;

                        let obj = {
                            client_id: client_id
                        };
                        window.axios.post('/api/insights/page/copy/'+ report_page_id, obj)
                            .then(response => {
                                window.location = "/insights/report_builder/" + response.data.page.id;
                            });
                    }
                });
            },
            deployTemplate(report_page_id) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to deploy this template?",
                  text: "Doing this activate this template. If previously deployed, it will update the child reports to mirror the recent changes.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, deploy",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.post('/api/insights/page/deploy/'+ report_page_id)
                            .then(response => {
                                //Look through at what was returned
                                for(var i = 0; i < response.data.pages.length; i++){
                                    let found = false;
                                    //See if it exists in the source_pages array
                                    for(var j = 0; j < this.source_pages.length; j++) {
                                        if(this.source_pages[j].id == response.data.pages[i].id){
                                            found = true;
                                            //Update it if it does
                                            this.source_pages[j] = response.data.pages[i];
                                            break;
                                        }
                                    }
                                    //If it does not, then add it to it
                                    if(!found){
                                        this.source_pages.push(response.data.pages[i]);
                                    }

                                }

                                this.$forceUpdate();

                                Swal.fire({
                                  title: "Success!",
                                  text: "This template was deployed to " + response.data.num_pages + " different report pages.",
                                  icon: "success",
                                  iconColor:  "#25473E",
                                  confirmButtonColor: "#25473E",

                                });
                            });
                    }
                });
            },

            deleteReport(report_page_id) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this report?",
                  text: "This report will be removed.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/insights/page/'+ report_page_id);
                        
                        for(var i = 0; i < this.local_report_pages.length; i++){
                            if(this.local_report_pages[i].id == report_page_id){
                                this.local_report_pages.splice(i, 1);
                                break;
                            }
                        }
                    }
                });
            },
        }
    }
</script>
