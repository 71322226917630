import { render, staticRenderFns } from "./CampaignTypeList.vue?vue&type=template&id=251b13ff"
import script from "./CampaignTypeList.vue?vue&type=script&lang=js"
export * from "./CampaignTypeList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev.aviddata.co/releases/20241116232211/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('251b13ff')) {
      api.createRecord('251b13ff', component.options)
    } else {
      api.reload('251b13ff', component.options)
    }
    module.hot.accept("./CampaignTypeList.vue?vue&type=template&id=251b13ff", function () {
      api.rerender('251b13ff', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/campaigns/CampaignTypeList.vue"
export default component.exports