<template>
    <div class="initiative-editor client-user">
        <div class="card box-content mb-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Select an Initiative</label>
                            <select class="form-select" name="initiative" v-model="init_pos" v-on:change="resetErrors()">
                                <option :value="pos" v-for="(i, pos) in initiatives">{{(pos==0 ? "Add a New Initiative" : i.name)}}</option>
                            </select>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mb-3">
            <div class="card-body">

                <h4> Initiative Information</h4>
                <div class="row">
                    <div class="col-sm-6">
                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" :class="((errors.name != '' )? 'is-invalid':'')"
                                name="name" v-model="initiatives[init_pos].name">
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name}}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-3" >
                            <label class="form-label">Strategic Focus</label>
                            <select class="form-select"  v-model="initiatives[init_pos].strategic_focus"
                                 :class="((errors.strategic_focus != '' )? 'is-invalid':'')" >
                                <option>Acquisition</option>
                                <option>Analysis & Reporting</option>
                                <option>Audience Generation</option>
                                <option>Donor Upgrade</option>
                                <option>Reactivation</option>
                                <option>Retention</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.strategic_focus != ''">
                                {{errors.strategic_focus}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">Result Calculation Type</label>
                            <select class="form-select"  v-model="initiatives[init_pos].calc_type" >
                                <option value="one_time">One-Time Launch - The start and end date are the same month</option>
                                <option value="repeatable">Repeatable - Results will be recalculated every month</option>
                                <option value="launch_and_automate">Launch and Repeat - Project launched to the whole file and is then automated or promoted on the website moving forward.</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group inline-dropdown">
                            <label class="form-label">Suggested Costs &nbsp;<span style="white-space: nowrap;">(
                              <a class="dropdown-toggle" type="button" id="costDropdown" data-bs-toggle="dropdown" aria-expanded="false">Add a Cost</a>
                              <ul class="dropdown-menu" role="menu" aria-labelledby="costDropdown">
                                <li v-for="c in cost_types" role="presentation"><a role="menuitem" tabindex="-1"
                                    href="#" v-on:click.prevent="addCost(c.name, c.type)" v-if="!isInCosts(c.name)">{{c.name}}</a></li>
                              </ul>
                            )</span></label>

                            <div class="row ">
                                <div class="col" v-if="initiatives[init_pos].costs.length == 0">
                                    <p><em>No costs have been added yet.</em></p>
                                </div>
                                <div class="col-md-3 col-sm-6" v-else v-for="c in initiatives[init_pos].costs">
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <div class=" form-floating">
                                            <input type="text" class="form-control" v-model="c.value" @keypress="isNumber($event)" :id="c.token" v-on:paste="cleanUpPaste">
                                            <label :for="c.token">{{c.name }}</label>
                                        </div>
                                        <button class="btn btn-secondary" @click.prevent="removeCost(c.name);"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mb-3">
            <div class="card-body">

                <h4>Projection Calculations</h4>
                <p>Initiative projections are calculated on a monthly basis. That means, <strong>the formulas below will be run every month that this initiative is active in the annual plan</strong>.</p>
                <p>For one-time initiatives like appeals or reactivation campaigns, this means you will likely want to use the entire available audience in calculations; e.g. <code>All Subscribers</code> or <code>All Active Donors</code>. For ongoing automations such as a New Subscriber Welcome Series or New Donor Onboarding, you will want to utilize the previous month's data with tokens such as <code>Last Month's New Subscribers</code> or <code>Last Month's New Donors</code>.</p>
                <hr>
                <div class="row">
                    <div class="col">
                        <div class="form-group inline-dropdown">
                            <label class="form-label">Custom Variables &nbsp;<span style="white-space: nowrap;">(
                              <a class="dropdown-toggle" type="button" id="inputDropdown" data-bs-toggle="dropdown">Add a Variable</a>
                              <ul class="dropdown-menu" role="menu" aria-labelledby="inputDropdown">
                                <li role="presentation"><a role="menuitem" tabindex="-1"
                                    href="#" v-on:click.prevent="addInput('money')" >Money</a></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1"
                                    href="#" v-on:click.prevent="addInput('number')" >Number</a></li>
                                <li role="presentation"><a role="menuitem" tabindex="-1"
                                    href="#" v-on:click.prevent="addInput('percentage')" >Percentage</a></li>
                              </ul>
                            )</span></label>
                            <div class="row ">
                                <div class="col" v-if="initiatives[init_pos].inputs.length == 0">
                                    <p><em>No custom variables have been created.</em></p>
                                </div>
                                <div v-else class="col">
                                    <div class="row" v-for="(i, pos) in initiatives[init_pos].inputs">
                                        <div class="col-sm-3">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" v-model="i.name" @change="generateToken(pos, i);">

                                                <label>Name</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" v-model="i.description">
                                                <label>Description</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-3" :class="i.type == 'money' ? 'has-prefix' : ''">
                                            <div class="mb-3" :class="i.type != 'number' ? 'input-group' : ''">
                                                <div class="input-group-text" v-if="i.type == 'money'">
                                                    $
                                                </div>
                                                <div class="form-floating ">
                                                    <input type="text" class="form-control" v-model="i.value" @keypress="isNumber($event)" v-on:paste="cleanUpPaste">
                                                    <label>Default Value <em style='font-weight: normal;'>(Optional)</em></label>
                                                </div>
                                                <div class="input-group-text" v-if="i.type == 'percentage'">%</div>
                                            </div>

                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" v-model="i.token" disabled>
                                                <label>Formula Token</label>
                                            </div>
                                        </div>
                                        <div class='col-sm-1'>
                                            <div class="mb-3">
                                                <button class="btn btn-primary" @click.prevent="removeInput(pos);"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="is_lifecycle"
                                v-model="initiatives[init_pos].is_lifecycle">
                            <label class="form-check-label" for="is_lifecycle">Include extended lifecycle variables?</label>
                        </div>
                        <br>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class=" mb-3">
                            <label class="form-label">Email Formula</label>
                            <TokenTextfield v-model="initiatives[init_pos].emails_calc" :selections="getAllInputs"
                                 :error="errors.emails_calc" />
                            <div class="text-danger invalid-feedback" v-if="errors.emails_calc != ''">
                                {{errors.emails_calc}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class=" mb-3">
                            <label class="form-label">Gifts Formula</label>
                            <TokenTextfield v-model="initiatives[init_pos].gifts_calc" :selections="getAllInputs"
                                :error="errors.gifts_calc" />
                            <div class="text-danger invalid-feedback" v-if="errors.gifts_calc != ''">
                                {{errors.gifts_calc}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class=" mb-3">
                            <label class="form-label">New Donor Formula</label>
                            <TokenTextfield v-model="initiatives[init_pos].new_donor_calc" :selections="getAllInputs"
                                :error="errors.new_donor_calc" />
                            <div class="text-danger invalid-feedback" v-if="errors.new_donor_calc != ''">
                                {{errors.new_donor_calc}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class=" mb-3">
                            <label class="form-label">Revenue Formula</label>
                            <TokenTextfield v-model="initiatives[init_pos].revenue_calc" :selections="getAllInputs"
                                :error="errors.revenue_calc" />
                            <div class="text-danger invalid-feedback" v-if="errors.revenue_calc != ''">
                                {{errors.revenue_calc}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class=" mb-3">
                            <label class="form-label">Monthly Recurring Revenue Formula</label>
                            <TokenTextfield v-model="initiatives[init_pos].recurring_revenue_calc" :selections="getAllInputs" :error="errors.recurring_revenue_calc" />
                            <div class="text-danger invalid-feedback" v-if="errors.recurring_revenue_calc != ''">
                                {{errors.recurring_revenue_calc}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class=" mb-3">
                            <label class="form-label">Lifetime Value Formula</label>
                            <TokenTextfield v-model="initiatives[init_pos].lifetime_value_calc" :selections="getAllInputs" :error="errors.lifetime_value_calc" />
                            <div class="text-danger invalid-feedback" v-if="errors.lifetime_value_calc != ''">
                                {{errors.lifetime_value_calc}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Roadmap Documentation</h4>
                <p>You can use <code>{client_name}</code> in the descriptions below as placeholders. The tool will automatically replace them when outputting the information for the client.</p>
                <div class="row">
                    <div class="col">
                        <div class="mb-3"  >
                            <label class="form-label">Strategic Objective<br>
                                <em style="font-weight: normal;">What is the primary purpose of this initiative?</em></label>
                            <vue-editor v-model="initiatives[init_pos].objective" :editorToolbar="customToolbar" ></vue-editor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3"  >
                            <label class="form-label">Tactical Overview<br>
                                <em style="font-weight: normal;">Describe all of the tactical steps necessary to fully execute this initiative. Be as verbose as necessary.</em></label>
                            <vue-editor v-model="initiatives[init_pos].description"  :editorToolbar="customToolbar" ></vue-editor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3"  >
                            <label class="form-label">Implications of Not Implementing<br>
                                <em style="font-weight: normal;">What bad things will happen if this initiative is not implemented?</em></label>
                            <vue-editor v-model="initiatives[init_pos].at_stake"  :editorToolbar="customToolbar" ></vue-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Success Message -->
                <div class="alert alert-success" v-if="form.successful">
                    The initiative's details have been updated!
                </div>
                <div class="alert alert-danger" v-if="form.error">
                    There was an issue saving this initiative. Check the fields above to get more information.
                </div>
                <!-- Update Button -->
                <div class="form-group">
                    <button type="submit" class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> {{ (init_pos > 0 ? "Update" : "Add Initiative" ) }}
                    </button>
                    <button type="button" class="btn btn-secondary"  style="margin-left:10px;"
                        v-on:click="deleteInitiative()" v-if="init_pos > 0">
                        <i class="fa fa-btn fa-trash"></i> Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import TokenTextfield from '../components/TokenTextfield';
    import Swal from 'sweetalert2';

    export default {
        components: { VueEditor,TokenTextfield, Swal},
        props: ['initiative_types'],
        data() {
            return {
                initiatives: [],
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    name: "",
                    strategic_focus: "",
                    emails_calc: "",
                    gifts_calc: "",
                    new_donor_calc: "",
                    revenue_calc: "",
                    recurring_revenue_calc: "",
                    lifetime_value_calc: ""
                },
                init_pos: 0,
                initiatives: [],
                cost_types: [
                    {name: "Media: Email Prospecting", type: "media" },
                    {name: "Media: Facebook", type: "media" },
                    {name: "Media: Google Grant", type: "media" },
                    {name: "Media: Other", type: "media" },
                    {name: "Media: Pay Per Click", type: "media" },
                    {name: "Monthly Retainer", type: "monthly" },
                    {name: "Passthrough Cost", type: "one_time" },
                    {name: "Project Cost", type: "one_time" },
                ],
                standard_inputs: [],
                customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], [{ indent: "-1" }, { indent: "+1" }],["clean"]]
            };
        },
        beforeMount() {
            this.initiatives = this.initiative_types;
            this.initiatives.unshift(this.getNewInitiative());

            for(var i = 0; i < this.initiatives.length; i++)
                for(var j = 0; j < this.initiatives[i].inputs.length; j++){
                    if(this.initiatives[i].inputs[j].type == 'percentage' && this.initiatives[i].inputs[j].value != null && this.initiatives[i].inputs[j].value != "")
                        this.initiatives[i].inputs[j].value = parseFloat(this.initiatives[i].inputs[j].value * 100) ;
                }
        },
        mounted() {
            this.refreshData();

        },
        computed: {

            getAllInputs: function() {

                var inputs = JSON.parse(JSON.stringify(this.standard_inputs));

                if(this.initiatives[this.init_pos].is_lifecycle == false)
                    for(var i = 0; i < inputs.length; i++)
                        if(inputs[i].is_lifecycle == 1) {
                            inputs.splice(i, 1);
                            i--;
                        }
                var costs = this.initiatives[this.init_pos].costs;
                costs.sort(function(a, b){
                    return b.name.length - a.name.length;
                });
                return this.initiatives[this.init_pos].inputs.concat(costs).concat(inputs);
            }
        },
        methods: {
            refreshData() {

                var self = this;

                self.getNewInitiative();

                window.axios.get('/api/standard_input/list')
                  .then(response => {
                    if(response.status == 200) {
                        self.standard_inputs = response.data.data;
                    }
                  });

            },
            getNewInitiative() {
                return {
                        id: -1,
                        name: "",
                        objective: "",
                        description: "",
                        at_stake: "",
                        strategic_focus:"",
                        costs: [
                            {
                                name: 'Project Cost',
                                type: 'one_time',
                                value: null,
                                overridable: 1,
                                token: '{project_cost}'
                            }
                        ],
                        inputs: [],
                        is_lifecycle: false,
                        calc_type: 'one_time',
                        emails_calc: "",
                        gifts_calc: "",
                        new_donor_calc: "",
                        revenue_calc: "",
                        recurring_revenue_calc: "",
                        lifetime_value_calc: ""
                    };
            },
            isInCosts(name) {
                for(var i = 0; i < this.initiatives[this.init_pos].costs.length; i++) {
                    if(this.initiatives[this.init_pos].costs[i].name == name)
                        return true;
                }
                return false;
            },
            addCost(name, type) {
                this.initiatives[this.init_pos].costs.push({
                    name: name,
                    type: type,
                    token: this.tokenize(-1, name),
                    overridable: 1,
                    value: null
                });
            },
            removeCost(name) {
                for(var i = 0; i < this.initiatives[this.init_pos].costs.length; i++) {
                    if(this.initiatives[this.init_pos].costs[i].name == name) {
                        this.initiatives[this.init_pos].costs.splice(i, 1);
                        return;
                    }
                }
            },
            addInput(type) {
                this.initiatives[this.init_pos].inputs.push({
                    name: "",
                    description: "",
                    type: type,
                    token: "{}",
                    overridable: 1,
                    value: null,
                });
            },
            removeInput(pos) {
                this.initiatives[this.init_pos].inputs.splice(pos, 1);
            },
            tokenize(pos, name) {
                var all_inputs = this.getAllInputs;

                //Create a temp placeholder
                var token = name.trim();
                token = token.split(' ').join('_');
                token = token.toLowerCase();
                token = token.replace(/[^A-Za-z0-9_]/g, "");

                for(var i = 0; i < all_inputs.length; i++) {
                    //If it isn't the same input and the tokens match
                    if(i != pos && "{" + token + "}" == all_inputs[i].token) {
                        token = token + "_1";
                        i = 0;
                    }
                }

                return "{" + token + "}";
            },
            generateToken(pos, input) {
                input.token = this.tokenize(pos, input.name);
            },
            update() {
                var self = this;
                this.form.error = false;

                //Validate the form
                if(!this.formIsValid()){
                    this.form.error = true;
                    return false;
                }

                this.form.busy = true;
                //Change all percentages to their actual number
                for(var i = 0; i < this.initiatives[this.init_pos].inputs.length; i++)
                    if(this.initiatives[this.init_pos].inputs[i].type == 'percentage' && this.initiatives[this.init_pos].inputs[i].value != null && this.initiatives[this.init_pos].inputs[i].value != "")
                        this.initiatives[this.init_pos].inputs[i].value = parseFloat(this.initiatives[this.init_pos].inputs[i].value) / 100;

                //Make a new user
                if(this.initiatives[this.init_pos].id == -1) {
                    window.axios.post('/api/initiative_type', this.initiatives[this.init_pos])
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {

                            //Add the new user to the list and set the dropdown
                            self.initiatives.push(response.data.data);

                            //Reset the "New User" option
                            self.initiatives.shift();
                            self.initiatives.unshift(self.getNewInitiative());

                            self.init_pos = self.initiatives.length -1;

                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false;} , 5000);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }
                else {
                    window.axios.put('/api/initiative_type/' + this.initiatives[this.init_pos].id, this.initiatives[this.init_pos])
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                }

                //Change the percentage back
                  for(var i = 0; i < this.initiatives[this.init_pos].inputs.length; i++)
                    if(this.initiatives[this.init_pos].inputs[i].type == 'percentage' && this.initiatives[this.init_pos].inputs[i].value != null && this.initiatives[this.init_pos].inputs[i].value != "")
                        this.initiatives[this.init_pos].inputs[i].value = parseFloat(this.initiatives[this.init_pos].inputs[i].value * 100) ;

            },
            resetErrors() {
                this.errors.name = "";
                this.errors.strategic_focus = "";
                this.errors.emails_calc = "";
                this.errors.gifts_calc = "";
                this.errors.new_donor_calc = "";
                this.errors.revenue_calc = "";
                this.errors.recurring_revenue_calc = "";
                this.errors.lifetime_value_calc = "";
            },
            formIsValid() {
                var is_valid = true;

                //First reset the errors
                this.resetErrors();
                if(this.initiatives[this.init_pos].name == ""){
                    this.errors.name = "The initiative name is required."
                    is_valid = false;
                }
                if(this.initiatives[this.init_pos].strategic_focus == ""){
                    this.errors.strategic_focus = "A strategic focus selection is required."
                    is_valid = false;
                }

                let math_error = "This is not a proper math formula. Please check your logic.";
                //Email formula
                var calc = this.initiatives[this.init_pos].emails_calc;
                if( calc != null && calc.trim() != ""){
                    try {
                        if(calc.indexOf("}{") !== -1) throw "Error";
                        Function(this.getFunctionEval(calc))();
                    }catch (error) {
                        this.errors.emails_calc = math_error;
                        is_valid = false;
                    }
                }
                //Gifts formula
                var calc = this.initiatives[this.init_pos].gifts_calc;
                if( calc != null && calc.trim() != ""){
                    try {
                        if(calc.indexOf("}{") !== -1) throw "Error";
                        Function(this.getFunctionEval(calc))();
                    }catch (error) {
                        this.errors.gifts_calc = math_error;
                        is_valid = false;
                    }
                }
                //New donor formula
                var calc = this.initiatives[this.init_pos].new_donor_calc;
                if( calc != null && calc.trim() != ""){
                    try {
                        if(calc.indexOf("}{") !== -1) throw "Error";
                        Function(this.getFunctionEval(calc))();
                    }catch (error) {
                        this.errors.new_donor_calc = math_error;
                        is_valid = false;
                    }
                }
                //Revenue formula
                var calc = this.initiatives[this.init_pos].revenue_calc;
                if( calc != null && calc.trim() != ""){
                    try {
                        if(calc.indexOf("}{") !== -1) throw "Error";
                        Function(this.getFunctionEval(calc))();
                    }catch (error) {
                        this.errors.revenue_calc = math_error;
                        is_valid = false;
                    }
                }
                //Recurring Revenue formula
                var calc = this.initiatives[this.init_pos].recurring_revenue_calc;
                if( calc != null && calc.trim() != ""){
                    try {
                        if(calc.indexOf("}{") !== -1) throw "Error";
                        Function(this.getFunctionEval(calc))();
                    }catch (error) {
                        this.errors.recurring_revenue_calc = math_error;
                        is_valid = false;
                    }
                }
                //Recurring Revenue formula
                var calc = this.initiatives[this.init_pos].lifetime_value_calc;
                if( calc != null && calc.trim() != ""){
                    try {
                        if(calc.indexOf("}{") !== -1) throw "Error";
                        Function(this.getFunctionEval(calc))();
                    }catch (error) {
                        this.errors.lifetime_value_calc = math_error;
                        is_valid = false;
                    }
                }

                return is_valid;

            },
            getFunctionEval(text) {
                var tokens = this.getAllInputs;
                for(var i = 0; i < tokens.length; i++)
                    text = text.replaceAll(tokens[i].token, "1");

                return '"use strict";return (' + text + ')';
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },

            cleanUpPaste: function(e) {

                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                var paste = (e.clipboardData || window.clipboardData).getData('text/plain')
                paste = paste.replace(/[^\d.-]/g, '');

                if (document.queryCommandSupported("insertText")) {
                  document.execCommand("insertText", false, paste);
                } else {
                  document.execCommand("paste", false, paste);
                }
            },
            deleteInitiative() {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this initiative?",
                  text: "Annual Plans that incorporate this initiative will continue to function using the previous version of this initiative.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/initiative_type/' + self.initiatives[self.init_pos].id)
                        .then(response => {
                            if (response.status == 200) {
                                //self.getSystemArray(system.system_id).$remove(system);
                                //console.log('deleted',response.data);
                                Swal.fire({
                                    title: "Deleted!",
                                    text: "This initiative type has been removed.",
                                    icon: "success",
                                    iconColor:  "#46705F",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    self.initiatives.splice(self.init_pos, 1);
                                    self.init_pos = 0;

                                });
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });
            }
        }
    }
</script>
