<template>
    <div class="manage-clients client-user mb-5">
        <div v-if="client == null">
            <h1 class="mb-2">Agency Clients</h1>
            <p class="mb-3">The clients listed below are all of those you've created within the system or that are attached to your agency. <strong>You will be charged $2,500 for each new client that you create.</strong> This gives you three months of use; once this is up the client moves to "inactive" where charts & integrations stop functioning unless the agency is willing to pay for additional months.</p>

            <div class="row mb-3">
                <div class="col-sm-6">
                    <div class="card box-content" >
                        <div class="card-body">
                            <label class="form-label">Select a client</label>
                            <select class="form-select" name="client" v-model="client_pos"
                                v-on:change="changeClient()">
                                <option :value="pos" v-for="(c, pos) in local_clients">{{(pos==0?"Add a New Client" : c.name)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h1 class="mb-2">{{ ( client.id > 0? "Update" : "Add" ) }} Client</h1>
        </div>
        <div class="card box-content">
            <div class="card-body">
                <h4>Organization Profile</h4>
                <div class="row">
                    <div class="col-sm-8">

                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">Organization Name</label>
                            <v-select :options="local_org_list" class="searchable-select column-display"
                                v-model="local_client.name" v-if="client_pos==0"
                                :searchable="true" name="org_name" id="org_search"
                                @search="debouncedSearchOptions" :filterable="false"
                                @input="selectOrg" :class="((errors.name != '' )? 'is-invalid':'')">
                                <template #list-header="{ search }">
                                    <li class="border-bottom border-primary border-1" @click.prevent="addNewOrg(search)" v-if="search != ''">
                                        <a class="btn btn-link"><i class="fa-solid fa-circle-plus text-primary"></i> Add Organization</a>
                                    </li>
                                </template>
                                <template #option="{value, label}">
                                  <li class="suboption show">
                                    {{label}}
                                  </li>
                                </template>
                                <template #no-options="{ search}">
                                    <div class="suboption show mt-1" v-if="search == ''">
                                        <em>Type to search for the nonprofit...</em>
                                    </div>
                                    <div class="suboption show mt-1" v-else-if="searching == true">
                                        <div class="spinner-border  spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading options
                                    </div>

                                    <div class="suboption show mt-1" v-else>
                                        <em>No organizations match your search</em>
                                    </div>
                                </template>
                            </v-select>
                            <input type="text" class="form-control" :class="((errors.name != '' )? 'is-invalid':'')"
                                    name="name" v-model="local_client.name" v-else>
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''" v-html="errors.name">
                            </div>
                        </div>
                        <div class="mb-3" v-if="Array.isArray(agencies)">
                            <label class="form-label">Agency</label>
                            <select class="form-select" v-model="agency_id" >
                                <option :value="-1" v-if="agency_id == -1" >(Multiple Agencies)</option>
                                <option :value="a.id" v-for="a in agencies" >{{a.name}}</option>
                            </select>
                        </div>
                        <div class="row">
                            <div class="col mb-3">
                                <label class="form-label">Vertical</label>
                                <div class="dropdown">
                                    <select class="form-select" id="vertical" v-model="local_client.vertical" :class="((errors.vertical != '' )? 'is-invalid':'')"
                                        v-on:change="errors.vertical = ''" >
                                        <option :value="item" v-for="item in vertical_types" :key="item" >{{item}}</option>
                                    </select>
                                </div>
                                <div class="text-danger invalid-feedback" v-if="errors.vertical != ''">
                                    {{errors.vertical}}
                                </div>
                            </div>
                            <div class="col mb-3">
                                <label class="form-label">Website Domain</label>
                                <input type="text" class="form-control" :class="((errors.domain != '' )? 'is-invalid':'')" name="domain" v-model="local_client.domain" @change="errors.domain = ''">
                                <div class="text-danger invalid-feedback" v-if="errors.domain != ''">
                                    {{errors.domain}}
                                </div>
                            </div>
                            <div class="col mb-3" v-if="user.agency_id == 1">
                                <label class="form-label">Stage</label>
                                <select class="form-select" id="stage-select" v-model="local_client.stage" >
                                    <option value="roadmap">Roadmap</option>
                                    <option value="full_service">Full Service</option>
                                    <option value="data_only">Data Only</option>
                                    <!--<option value="inactive">Inactive</option>-->
                                </select>
                            </div>
                        </div>
                        <div class="mb-3" v-if="user.agency_id == 1">
                            <label class="form-label">NextAfter.io Client Name</label>
                            <select class="form-select" id="io-client-name" v-model="local_client.nextafterio_id" >
                                <option v-for="i in nextafterio_clients" v-bind:value="i.id">{{i.name}}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Slack Webhook URL
                                <a href='https://api.slack.com/apps/A054J13HM0T/incoming-webhooks' target="_blank"><i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="Slack Webhook URLs have to be set up for each client so the messages go to a client-specific channel. Click on this icon to add a new webhook or find an existing one."></i></a>
                            </label>
                            <input type="text" class="form-control" :class="((errors.slack_url != '' )? 'is-invalid':'')"
                                name="slack_url" v-model="local_client.slack_url" v-on:change="validateURL()">
                            <div class="text-danger invalid-feedback" v-if="errors.slack_url != ''">
                                {{errors.slack_url}}
                            </div>
                        </div>
                        <div class="mb-3" v-if="user.agency_id == 1">
                            <label class="form-label">Billable Media Types</label>
                            <div class="dropdown">
                              <button class="btn form-select btn-field" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
                                {{ (local_client.billable_media_types != null && local_client.billable_media_types.length > 0 ) ? local_client.billable_media_types.join(", ") : 'Please Select' }}
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                                  <label class="dropdown-item" v-for="item in media_types" :key="item">
                                    <input class="form-check-input" type="checkbox" :value="item"  v-model="local_client.billable_media_types" :true-value="[]">
                                    <span class="ms-2" >
                                      {{ item }}
                                    </span>
                                  </label>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="col profile-image">
                        <!-- Photo Preview-->
                        <label  class="form-label">Logo</label>
                        <span :data-name='local_client.id' id="client_id"></span>
                        <vue-dropzone ref="dropzone" id="dropzone" class="profile-dropzone"
                            v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                            :options="dropzoneOptions" :awss3="awss3" :style="'background-image: url(' +local_client.image+')'" ></vue-dropzone>
                    </div>
                </div>
            </div>
        </div>
        <div class="card box-content mt-4">
            <div class="card-body">
                <h4>Fiscal Year & Donor Settings</h4>
                <div class="row">
                    <div class="col-sm-4 mb-3">
                        <div class="form-group">
                            <label class="form-label">Fiscal Year Start Month</label>
                            <select class="form-select" name="local_client.fiscal_year_offset" v-model="local_client.fiscal_year_offset"  >
                                <option value="0">January</option>
                                <option value="1">February</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                                <option value="6">July</option>
                                <option value="7">August</option>
                                <option value="8">September</option>
                                <option value="9">October</option>
                                <option value="10">November</option>
                                <option value="11">December</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 mb-3">
                        <div class="form-group">
                            <label class="form-label">Donor Level Criteria</label>
                            <select class="form-select" name="local_client.giving_levels.split" v-model="local_client.giving_levels.split" >
                                <option value="HAC">Highest Annual Cumulative Giving</option>
                                <option value="HPC">Highest Previous Gift</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 mb-3">
                        <div class="form-group">
                            <label class="form-label">Preferred Reporting Period</label>
                            <select class="form-select" name="local_client.default_reporting_period" v-model="local_client.default_reporting_period" >
                                <option value="Calendar Year to Date">Calendar Year to Date</option>
                                <option value="Fiscal Year to Date">Fiscal Year to Date</option>
                                <option value="Trailing 12 Months">Trailing 12 Months</option>
                            </select>
                        </div>
                    </div>
                </div>
                <label class="form-label">Donor Levels</label>
                <div class="overflow-table">
                    <table class="table ">
                        <thead class="table-dark">
                            <tr>
                                <th class='align-middle'>Name</th>
                                <th class='align-middle'>Minimum Threshold</th>
                                <th class='align-middle'  v-if="local_client.giving_levels.levels.length > 3" >Report on Upgrades? <i class="fas fa-info-circle text-white ms-2" data-bs-toggle="tooltip" title="AVID has special reporting set up to monitor donors who upgrade to a higher giving level. However, only two giving levels can be reported on at a time. Select those two levels below."></i></th>
                                <th class='align-middle' style="width:80px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(level, index) in local_client.giving_levels.levels" >
                                <td class='align-middle w-50' v-if="index != edit_index">{{ level.name }}</td>
                                <td class='align-middle w-50' v-else>
                                    <input type="text" class="form-control form-control-sm" v-model="local_level.name" placeholder="">
                                </td>
                                <td class='align-middle' v-if="index != edit_index">
                                    <span v-if="index == local_client.giving_levels.levels.length-1">&mdash;</span>
                                    <span v-else>{{ level.min | currency }}</span>
                                </td>
                                <td class='align-middle' v-else>
                                    <div class="input-group input-group-sm" v-if="index != local_client.giving_levels.levels.length-1">
                                        <span class="input-group-text">$</span>
                                        <input type="text" class="form-control" v-model="local_level.min"
                                            @keypress="isPositiveNumber($event)" @paste="cleanUpPaste" >
                                    </div>
                                </td>
                                <td class='align-middle' v-if="local_client.giving_levels.levels.length > 3" >
                                    <div class="checkbox-animated mt-1 mb-0 ms-2" v-if="index != local_client.giving_levels.levels.length-1">
                                        <input :id="'checkbox_animated_'+index" type="checkbox" v-model="level.report" @change="limitChecks(index)">
                                        <label :for="'checkbox_animated_'+index" ><span class="check" ></span><span class="box"></span></label>
                                    </div>
                                </td>
                                <td>
                                    <span v-if="edit_index != index">
                                        <button class="btn btn-sm btn-none p-1" v-on:click="editLevel(index)" title="Edit"><i class="fas fa-fw fa-edit"></i></button>
                                    </span>
                                    <span v-else>
                                        <button class="btn btn-sm btn-none p-1" v-on:click="saveLevel(index)" title="Save" :disabled="!canSaveLevel(index)"><i class="fas fa-save" ></i></button>
                                        <button class="btn btn-sm btn-none p-1 me-0" v-on:click="cancelLevel(index)" title="Cancel"><i class="fas fa-close"></i></button>
                                    </span>
                                    <span v-if="index != local_client.giving_levels.levels.length-1 && edit_index != index">
                                        <button class="btn btn-sm btn-none p-1 me-0" v-on:click="deleteLevel(index)" title="Delete"><i class="fas fa-fw fa-trash"></i></button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button class="btn btn-sm btn-secondary mb-3" v-on:click="addLevel()" v-if="edit_index == -1" >
                    <span><i class="fas fa-fw fa-plus"></i>Add Level</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <!-- Success Message -->
                <div class="alert alert-success mt-3" v-if="form.successful">
                    Your organization's information has been updated!
                </div>
                <div class="alert alert-danger mt-3" v-if="form.error">
                    There is an error in the fields above. Please correct and try again.
                </div>
                <!-- Update Button -->
                <div class="mt-3">
                    <button type="submit" class="btn btn-primary btn-big "
                            @click.prevent="update"
                            :disabled="form.busy || hasError()">
                        <i class="fa fa-btn fa-save"></i> {{ (client_pos > 0 || (client != null && client.id > 0) ? "Update" : "Add Client" ) }}
                    </button>
                    <button type="button" class="btn btn-secondary btn-big ms-2" v-on:click="deleteClient()"
                        v-if="client_pos > 0"><i class="fa fa-btn fa-trash"></i> Delete</button>
                    <button type="button" class="btn btn-secondary btn-big ms-2" :disabled="form.busy"
                        v-on:click="back()" v-if="client != null">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import vSelect from "vue-select";
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import debounce from 'lodash.debounce';
    import Swal from 'sweetalert2';
    export default {
        components: {
            vueDropzone: vue2Dropzone, Swal, vSelect
        },
        props: ['client', 'clients','user', 'agencies', 'users'],
        data() {
            return {

                dropzoneOptions: {
                    url: "#",
                    resizeWidth: 512,
                    resizeHeight: 512,
                    resizeMethod: 'crop',
                    acceptedFiles: "image/*",
                    renameFile: function (file) {
                        let new_name = "client/" + Date.now() + "_" + file.name;
                        return new_name;
                    },
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> DROP IMAGE TO UPLOAD",
                    clickable: true
                },
                awss3: {
                      signingURL: '/api/client/get_signed_url', //Where you will get signed url
                      headers: {
                        'X-Requested-With':'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                      },
                      params : {},
                      sendFileToServer : false //If you want to upload file to your server along with s3
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    name: "",
                    slack_url: "",
                    domain: "",
                    vertical: ""
                },
                agency_id: null,
                client_pos: 0,
                local_clients: [ ],
                nextafterio_clients: [{
                    id: -1,
                    name: "",
                    na_dataset: ""
                }],
                local_client: null,
                media_types: [ 'Email Prospecting', 'Facebook', "Google Grant", 'Other', 'Pay Per Click'],

                vertical_types: [  'Arts, Culture, and Humanities', "Animal-Related", "Education", "Environment", "General Nonprofit", "Health & Human Services", 'Public Policy/Advocacy','Religion'],

                local_level: {
                    name: '',
                    min: '',
                    report: false
                },
                edit_index: -1,
                prioritization_options: [],
                user_prioritization: [],
                debouncedSearchOptions: null,
                local_org_list: [],
                searching: false
            };
        },
        created() {
            this.debouncedSearchOptions = debounce(this.searchOptions, 1000);
        },
        beforeMount() {
            if(this.client != null){
                //If it is a new client...
                if(this.client.id == -1){
                    this.local_client = this.getNewClient();
                    this.agency_id = this.client.agency_id;
                }
                else{
                    this.local_client = this.client;

                    if(this.local_client.agencies.length == 1)
                        this.agency_id = this.local_client.agencies[0].id;
                    else if(this.local_client.agencies.length > 1)
                        this.agency_id = -1;
                }

                if(this.local_client.giving_levels == null)
                    this.local_client.giving_levels = this.getDefaultGivingLevels();

                if(this.local_client.vertical == null)
                    this.local_client.vertical = [];
            }
            else {
                this.local_clients = this.clients;
                this.local_clients.unshift(this.getNewClient());
                this.local_client = this.getNewClient();
            }
        },
        mounted() {
            this.refreshData();
   
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            checkOrganization(){
                this.errors.name = '';

                if(this.local_client.name != null && this.local_client.name != '')
                    window.axios.post('/api/register/organization_exists', {
                        name: this.local_client.name,
                        ein: this.local_client.ein}).then(response => {

                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors.name = error.response.data.message;
                    }
                });
            },
            searchOptions(search_name){
                this.searching = true;
                window.axios.post('/api/client/api_lookup', {name: search_name})
                  .then(response => {
                    this.local_org_list = []
                    if(response.data.results != null){
                        for(let i = 0; i < response.data.results.length; i++){
                            this.local_org_list.push( {
                                ein: response.data.results[i].ein,
                                revenue: response.data.results[i].revenues_total.value,
                                ntee: response.data.results[i].ntee.value,
                                label: response.data.results[i].name
                            });
                        }
                    }
                    this.searching = false;
                });
            },
            selectOrg(org_selected){
                if (org_selected){
                    this.local_client.name = org_selected.label;
                    this.local_client.ein = org_selected.ein;

                    this.checkOrganization();
                    if(org_selected.revenue != null){
                        if(org_selected.revenue < 1000000)
                            this.local_client.size = "Less than $1M";
                        else if(org_selected.revenue < 5000000)
                            this.local_client.size = "$1M-$5M";
                        else if(org_selected.revenue < 10000000)
                            this.local_client.size = "$5M-$10M";
                        else if(org_selected.revenue < 25000000)
                            this.local_client.size = "$10M-$25M";
                        else if(org_selected.revenue < 50000000)
                            this.local_client.size = "$25M-$50M";
                        else "$50M+"
                    }

                    if(org_selected.ntee){
                        //If it starts with A
                        if(org_selected.ntee[0] == 'A')
                            this.local_client.vertical = 'Arts, Culture, and Humanities';
                        else if(org_selected.ntee[0] == 'B')
                            this.local_client.vertical = 'Education';
                        else if(org_selected.ntee[0] == 'C')
                            this.local_client.vertical = 'Environment';
                        else if(org_selected.ntee[0] == 'D')
                            this.local_client.vertical = 'Animal-Related';
                        else if(org_selected.ntee[0] == 'E' || org_selected.ntee[0] == 'F' || org_selected.ntee[0] == 'G'||org_selected.ntee[0] == 'H' || org_selected.ntee[0] == 'M' || org_selected.ntee[0] == 'P')
                            this.local_client.vertical = "Health & Human Services";
                        else if(org_selected.ntee[0] == 'Q' || org_selected.ntee[0] == 'R'|| org_selected.ntee[0] == 'V')
                            this.local_client.vertical = "Public Policy/Advocacy";
                        else if(org_selected.ntee[0] == 'X')
                            this.local_client.vertical = 'Religion';
                        else
                            this.local_client.vertical = 'General Nonprofit';
                    }

                    window.axios.post('/api/client/api_lookup', {ein: this.local_client.ein})
                        .then(response => {
                            if(response.data) {
                                //If website_url starts with http, strip out all but the domain (including www)
                                if(response.data.website_url != null && response.data.website_url != "") {
                                    var url = new URL(response.data.website_url);
                                    let host = url.hostname;
                                    //Remove www if it is at the start
                                    if(host.startsWith("www."))
                                        host = host.substring(4);
                                    this.local_client.domain = host;
                                }
                                else
                                    this.local_client.domain = "";
                                this.local_client.mission = response.data.description;
                                this.local_client.systems = response.data.widget_types;
                                this.local_client.fiscal_year_offset = this.getFiscalYearStart(response.data.fiscal_year_end);
                                this.$forceUpdate();
                            }
                        });
                }


            },
            getFiscalYearStart(fiscalYearEnd) {
                const months = {
                    "January": 1,
                    "February": 2,
                    "March": 3,
                    "April": 4,
                    "May": 5,
                    "June": 6,
                    "July": 7,
                    "August": 8,
                    "September": 9,
                    "October": 10,
                    "November": 11,
                    "December": 0
                };

                // Find the numerical value for the fiscal year start
                return months[fiscalYearEnd];
            },
            addNewOrg(search){
                this.local_client.ein = "";
                this.local_client.name = search;
                this.local_client.mission = "";
                this.local_client.size = "";
                this.local_client.systems = null;
                this.checkOrganization();

                //remove the vs--open and vs--searching class from #org_search element
                document.getElementById("org_search").classList.remove("vs--open");
                document.getElementById("org_search").classList.remove("vs--searching");
                //Now remove the focus off the .vs__search element
                document.querySelector(".vs__search").blur();
                this.errors.name = '';

            },
            refreshData() {
                window.axios.get('/api/outbound/nextafterio')
                  .then(response => {
                        this.nextafterio_clients = response.data.clients;
                  });
            },
            getNewClient() {
                return {
                    id: -1,
                    url: null,
                    name: "",
                    photo_url: "",
                    image: "",
                    stage: "roadmap",
                    nextafterio_id: null,
                    na_dataset: null,
                    billable_media_types: [],
                    vertical: "",
                    ein: null,
                    domain: "",
                    size: null,
                    slack_url: null,
                    fiscal_year_offset: 0,
                    giving_levels: this.getDefaultGivingLevels(),
                    default_reporting_period: 'Fiscal Year to Date',
                    users: [],
                    personas: null,
                    why_give: '',
                    style_guide: '',
                    client_mission: ''

                };
            },
            ///client/api_lookup
            getDefaultGivingLevels() {
                return {
                    split: 'HPC',
                    levels: [
                        { name:"Major", min:10000, report: true },
                        { name:"Midlevel",min:1000, report: true },
                        { name:"Broadbase",min:0, report: false }
                    ]
                };
            },
            changeClient() {
                this.errors.name = "";
                this.local_client = this.local_clients[this.client_pos];
                if(this.local_client.giving_levels == null)
                    this.local_client.giving_levels = this.getDefaultGivingLevels();

                if(this.local_client.agencies && this.local_client.agencies.length == 1)
                    this.agency_id = this.local_client.agencies[0].id;
                else if(this.local_client.agencies && this.local_client.agencies.length > 1)
                    this.agency_id = -1;

            },
            validateName() {
                var self = this;
                window.axios.post('/api/client/check_name', this.local_client)
                  .then(response => {
                    if(response.status == 200) {
                        if(response.data.exists) {
                            self.errors.name = 'The client name entered already exists.';
                        }
                        else
                            self.errors.name = '';
                    }
                  });
            },
            validateURL() {
                //The URL should start with https://hooks.slack.com/services/ and be a valid URL
                if(this.local_client.slack_url != null && this.local_client.slack_url != "") {
                    if(this.local_client.slack_url.indexOf("https://hooks.slack.com/services/") == -1) {
                        this.errors.slack_url = "A valid Slack Webhook URL should start with https://hooks.slack.com/services/";
                        return;
                    }

                }
                this.errors.slack_url = '';
            },
            isValid() {
                this.form.error = false;

                if(this.local_client.name == ""){
                    this.errors.name = "The organization name is required.";
                    this.form.error = true;
                }
                else this.errors.name = "";

                if(this.local_client.domain == ""){
                    this.errors.domain = "The organization's website domain is required.";
                    this.form.error = true;
                }
                else this.errors.domain = "";

                if(this.local_client.vertical == "") {
                    this.errors.vertical = "The vertical is required.";
                    this.form.error = true;
                }
                else this.errors.vertical = "";

                return !this.form.error;

            },
            update() {

                if(!this.isValid())
                    return;

                var self = this;
                this.form.busy = true;

                //Set the BQ dataset variable
                if(this.local_client.nextafterio_id != null && this.local_client.nextafterio_id > 0)
                    for(var i = 0; i < this.nextafterio_clients.length; i++) {
                        if(this.nextafterio_clients[i].id == this.local_client.nextafterio_id) {
                            this.local_client.na_dataset = this.nextafterio_clients[i].bigquery_dataset;
                            this.local_client.acq_dashboard_id = this.nextafterio_clients[i].acq_dashboard_id;
                            break;
                        }
                    }

                if(this.agency_id != null && this.agency_id > 0)
                    this.local_client.agency_id = this.agency_id;

                //Clear out any empty donor personas
                if(this.local_client.personas != null) {
                    for(var i = 0; i < this.local_client.personas.length; i++) {
                        if(this.local_client.personas[i].name == "" && this.local_client.personas[i].description == ""){
                            this.local_client.personas.splice(i, 1);
                            i--;
                            continue;
                        }
                        if(this.local_client.personas[i].name == "" && this.local_client.personas[i].description != "")
                            this.local_client.personas[i].name = 'Persona #' + (i+1);
                    }
                }

                if(this.user_prioritization.length > 0)
                    this.local_client.prioritization_preference = this.user_prioritization;

                //Make a new client
                if(this.local_client.id == -1) {
                    window.axios.post('/api/client', this.local_client)
                      .then(response => {
                        if(this.client != null)
                            window.location.href = '/admin/agency/clients';
                        else {
                            self.form.busy = false;

                            //Add the new client to the list and set the dropdown
                            self.local_clients.push(response.data.data);
                            self.local_client = response.data.data;
                            
                            //Reset the "New Client" option
                            self.local_clients.shift();
                            self.local_clients.unshift(self.getNewClient());

                            self.client_pos = self.local_clients.length -1;

                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                      });
                }
                else {
                    window.axios.put('/api/client/' + this.local_client.id, this.local_client)
                      .then(response => {
                        self.form.busy = false;
                        if(this.client != null)
                            window.location.href = '/admin/agency/clients';
                        else {
                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                      });
                }

            },
            editLevel(index) {
                this.local_level = {
                    name: this.local_client.giving_levels.levels[index].name ,
                    min: this.local_client.giving_levels.levels[index].min,
                    report: this.local_client.giving_levels.levels[index].report
                };
                this.edit_index = index;
            },
            deleteLevel(index) {
                this.local_client.giving_levels.levels.splice(index, 1);
                this.resetAndSortLevels();
            },
            saveLevel(index) {
                this.local_client.giving_levels.levels[index].name = this.local_level.name;
                this.local_client.giving_levels.levels[index].min = this.local_level.min;
                this.resetAndSortLevels();
                this.resetLocalLevel();
                this.edit_index = -1;
            },
            cancelLevel(index) {
                this.edit_index = -1;

                //If it was a newly added level, remove it
                if(this.local_client.giving_levels.levels[index].name == "" && !this.canSaveLevel(index))
                    this.local_client.giving_levels.levels.splice(index, 1);

                this.resetLocalLevel()
            },
            addLevel() {
                this.edit_index = this.local_client.giving_levels.levels.length-1;

                this.local_client.giving_levels.levels.splice(this.edit_index, 0, {
                    name: '',
                    min: null,
                    report: this.local_client.giving_levels.levels.length < 3 ? true : false
                });

                this.resetLocalLevel()
            },
            canSaveLevel(index) {
                var has_name = this.local_level.name.trim() != "";

                var is_unique_name = true;
                for(var i = 0; i < this.local_client.giving_levels.levels.length; i++)
                    if(i != index && this.local_client.giving_levels.levels[i].name == this.local_level.name) {
                        is_unique_name = false;
                        break;
                    }

                var has_min = this.local_level.min != null && !isNaN(this.local_level.min);

                var is_unique_num = true;
                for(var i = 0; i < this.local_client.giving_levels.levels.length; i++)
                    if(i != index && this.local_client.giving_levels.levels[i].min == this.local_level.min) {
                        is_unique_num = false;
                        break;
                    }

                return has_min && is_unique_name && has_min && is_unique_num;

            },
            resetLocalLevel() {
                this.local_level = {
                    name: '',
                    min: '',
                    report: false
                };
            },
            resetAndSortLevels() {
                var levels = this.local_client.giving_levels.levels;

                //Sort the levels based upon the min in decending order
                levels.sort(function(a, b) {
                    return parseFloat(b.min) - parseFloat(a.min);
                });

                //If there are three or less, set all but the last one to have report as true
                if(levels.length <= 3)
                    for(var i = 0; i < levels.length-1; i++)
                        levels[i].report = true;

                this.local_client.giving_levels.levels = levels;
            },
            limitChecks(index) {
                //If there are more than two levels with report as true...
                var count = 0;
                for(var i = 0; i < this.local_client.giving_levels.levels.length; i++)
                    if(this.local_client.giving_levels.levels[i].report)
                        count++;

                //If there are too many checks
                if(count >= 3) {
                    //Skip the first one and then set next one encountered that is true to false
                    for(var i = 1; i < this.local_client.giving_levels.levels.length; i++)
                        if(i != index && this.local_client.giving_levels.levels[i].report) {
                            this.local_client.giving_levels.levels[i].report = false;
                            break;
                        }
                }
            },
            hasError() {
                return this.errors.name != "" || this.errors.slack_url != "" || this.errors.domain != "" || this.errors.vertical != "";
            },
            s3UploadSuccess(s3ObjectLocation){
                //Stupid Amazon URL encoding my folder
                s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
                this.local_client.photo_url = s3ObjectLocation;
                this.local_client.image = s3ObjectLocation;

                //Get the primary colors
                window.axios.post('/api/client/get_colors', this.local_client)
                  .then(response => {
                    if(response.data.light_color != null) {
                        this.local_client.light_color = response.data.light_color;
                        this.local_client.dark_color = response.data.dark_color;
                    }
                  });
            },
            deleteClient() {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete "+ self.local_clients[self.client_pos].name + "?",
                  text: "This will remove the client and all associated annual plans from the Pulse system.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/client/' + self.local_clients[self.client_pos].id)
                        .then(response => {
                            if (response.status == 200) {
                                //self.getSystemArray(system.system_id).$remove(system);
                                //console.log('deleted',response.data);
                                Swal.fire({
                                    title: "Deleted!",
                                    text: self.local_clients[self.client_pos].name + " has been deleted.",
                                    icon: "success",
                                    iconColor:  "#46705F",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    self.local_clients.splice(self.client_pos, 1);
                                    self.client_pos = 0;
                                    self.local_client = this.getNewClient();
                                });
                            }
                        });
                  } else if (result.isDenied) {

                  }
                });

            },
            isPositiveNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            cleanUpPaste: function(e) {

                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                var paste = (e.clipboardData || window.clipboardData).getData('text/plain')
                paste = paste.replace(/[^\d.-]/g, '');

                if (document.queryCommandSupported("insertText")) {
                  document.execCommand("insertText", false, paste);
                } else {
                  document.execCommand("paste", false, paste);
                }
            },
            back() {
                //Redirect back to agency admin
                window.location.href = '/admin/agency/clients';
            }
        }
    }
</script>
