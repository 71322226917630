<template>
    <div id="org_settings_profile">
        <div class="d-flex">
            <div class="flex-shrink-1 profile-image px-2 pe-4">
                <!-- Photo Preview-->
                <span :data-name='local_client.id' id="client_id"></span>
                <vue-dropzone ref="dropzone" id="dropzone" class="profile-dropzone" v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                    :options="dropzoneOptions" :awss3="awss3" :style="'background-image: url(' +local_client.image+')'" ></vue-dropzone>
            </div>
            <div class="px-2 w-100">
                <h4>Profile</h4>
                <div class="row">
                    <div class="col-12">

                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label">Organization Name</label>
                            <input type="text" class="form-control" :class="((errors.name != '' )? 'is-invalid':'')"
                                name="name" v-model="local_client.name" v-on:change="validateName()">
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col" v-if="user.user_type == 'agency_admin' || user.user_type == 'admin'">
                                <div class="mb-3">
                                    <label class="form-label">Stage</label>
                                    <select class="form-select" id="stage-select" v-model="local_client.stage" >
                                        <option value="roadmap">Roadmap</option>
                                        <option value="full_service">Full Service</option>
                                        <option value="data_only">Data Only</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">Vertical</label>
                                    <div class="dropdown">
                                        <select class="form-select" id="vertical" v-model="local_client.vertical" :class="((errors.vertical != '' )? 'is-invalid':'')" @change="errors.vertical = ''" >
                                            <option :value="item" v-for="item in vertical_types" :key="item">{{item}}</option>
                                        </select>
                                    </div>
                                    <div class="text-danger invalid-feedback" v-if="errors.vertical != ''">
                                        {{errors.vertical}}
                                    </div>
                                </div>
                            </div>

                                <div class="col mb-3">
                                    <label class="form-label">Website Domain</label>
                                    <input type="text" class="form-control" :class="((errors.domain != '' )? 'is-invalid':'')"
                                        name="domain" v-model="local_client.domain" @change="errors.domain = ''">
                                    <div class="text-danger invalid-feedback" v-if="errors.domain != ''">
                                        {{errors.domain}}
                                    </div>
                                </div>
                        </div>
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-sm-2">
                                    <label class="form-label">Dark Color
                                        <i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="Pick a dark color used in titles and other fonts that will be used when generating slides. It should match your branding."></i>
                                    </label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" :style="{'background-color': local_client.dark_color}">
                                        <verte picker="square" model="hex" v-model="local_client.dark_color"></verte>
                                        </span>
                                        <input type="text" class="form-control" v-model="local_client.dark_color">
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <label class="form-label">Accent Color
                                        <i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="Pick a bright color that will be used as an accent when generating slides. It should match your branding."></i>
                                    </label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" :style="{'background-color': local_client.light_color}">
                                        <verte picker="square" model="hex" v-model="local_client.light_color"></verte>
                                        </span>
                                        <input type="text" class="form-control" v-model="local_client.light_color">
                                    </div>
                                </div>
                                <div class="col">
                                    <label class="form-label">Slack Webhook URL
                                        <a href='https://api.slack.com/apps/A054J13HM0T/incoming-webhooks' target="_blank"><i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="Slack Webhook URLs have to be set up for each client so the messages go to a client-specific channel. Click on this icon to add a new webhook or find an existing one."></i></a>
                                    </label>
                                    <input type="text" class="form-control" :class="((errors.slack_url != '' )? 'is-invalid':'')"
                                        name="slack_url" v-model="local_client.slack_url" v-on:change="validateURL()">
                                    <div class="text-danger invalid-feedback" v-if="errors.slack_url != ''">
                                        {{errors.slack_url}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <hr class="my-4">

        <div class="row">
            <h4>Mission & Goals</h4>
            <p>The information below is used when generating copy for the various campaigns made within AVID. For best results, be both concise and descriptive.</p>

            <!-- Name -->
            <div class="mb-3">
                <label class="form-label">Client Mission</label>
                <textarea class="form-control"
                    name="client_mission" v-model="local_client.client_mission"></textarea>
            </div>

            <div class="mb-3">
                <label class="form-label">Why would a donor give to {{(local_client.name == null || local_client.name == '')? 'this organization' : local_client.name }} rather than some other nonprofit or not at all?</label>
                <textarea class="form-control"
                    name="why_give" v-model="local_client.why_give"></textarea>
            </div>
            <div class="mb-3">
                <label class="form-label">Style Guide: What are words / phrases that should or should not be used? <em>(Optional)</em></label>
                <textarea class="form-control"
                    name="style_guide" v-model="local_client.style_guide"></textarea>
            </div>
        </div>

        <hr class="my-4">

        <div class="row">
            <h4>Constituent Personas</h4>
            <p>When AVID generates copy, it is possible to craft the text so that it would be more likely to appeal to certain types of people. Use the fields below to describe your constituents and <strong>what motivates them to take action</strong>. If you're unsure about the organization's personas, it is possible to ask ChatGPT to describe the types of donors who would give to {{(local_client.name == null || local_client.name == '')? 'this organization' : local_client.name }}. This is often a good starting place.</p>

            <div v-for="(persona, index) in local_client.personas">
                <!-- Name -->
                <div class="mb-3">
                    <label class="form-label">Persona Name #{{index+1}}</label>
                    <input type="text" class="form-control"
                        name="persona_name" v-model="persona.name">
                </div>

                <div :class='(index+1 == local_client.personas.length ) ? "mb-3" : "mb-5"'>
                    <label class="form-label">Description</label>
                    <textarea class="form-control"
                        name="persona_description" v-model="persona.description"></textarea>
                </div>
            </div>
            
            <div>
            <button class="btn btn-secondary me-3" v-on:click="addPersona()">
                <span><i class="fas fa-fw fa-plus"></i>Add Persona</span>
            </button>                
            </div>

        </div>
        <hr class="my-4">
        <div class="alert alert-danger mt-3" v-if="form.error">
            There is an error in the fields above. Please correct and try again.
        </div>
        <button type="submit" class="btn btn-primary mt-3" @click.prevent="update" :disabled="form.busy || hasError()">
            <i class="fa fa-btn fa-save"></i> Save Changes
        </button>
    </div>
</template>

<script>
    import Verte from 'verte';
    import 'verte/dist/verte.css';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Swal from 'sweetalert2';

    export default {
        components: {
            vueDropzone: vue2Dropzone,
            Swal,
            Verte
        },
        props: ['user', 'client'],
        data() {
            return {
                local_client: {},
                vertical_types: [  'Arts, Culture, and Humanities', "Animal-Related", "Education", "Environment", "General Nonprofit", "Health & Human Services", 'Public Policy/Advocacy','Religion'],
                dropzoneOptions: {
                    url: "#",
                    resizeWidth: 512,
                    resizeHeight: 512,
                    resizeMethod: 'crop',
                    acceptedFiles: "image/*",
                    renameFile: function (file) {
                        let new_name = "client/" + Date.now() + "_" + file.name;
                        return new_name;
                    },
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> DROP IMAGE TO UPLOAD",
                    clickable: true
                },
                awss3: {
                      signingURL: '/api/client/get_signed_url', //Where you will get signed url
                      headers: {
                        'X-Requested-With':'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                      },
                      params : {},
                      sendFileToServer : false //If you want to upload file to your server along with s3
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors :{
                    name: "",
                    vertical: "",
                    slack_url: "",
                    domain: "",
                },
            };
        },
        beforeMount() {
            this.local_client = this.client;

            if(this.local_client.light_color == null)
                this.local_client.light_color = '#EFEFEF';
            if(this.local_client.dark_color == null)
                this.local_client.dark_color = '#000000';

        },
        mounted(){
            this.resizeTextarea();
        }, 
        methods: {
            resizeTextarea() {
                var element = document.querySelectorAll('textarea');

                for(var index = 0; index<element.length; index++){
                    if (element[index] && element[index].style){
                        element[index].style.height = '';
                        element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                    }                            
                }
            },
            s3UploadSuccess(s3ObjectLocation){
                //Stupid Amazon URL encoding my folder
                s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
                this.local_client.photo_url = s3ObjectLocation;
                this.local_client.image = s3ObjectLocation;
                //Only call this if they've saved the client already
                if(this.local_client.id != -1)
                    this.update();

                //Get the primary colors
                window.axios.post('/api/client/get_colors', this.local_client)
                  .then(response => {
                    if(response.data.light_color != null) {
                        this.local_client.light_color = response.data.light_color;
                        this.local_client.dark_color = response.data.dark_color;
                    }
                  });

            },
            validateName() {
                var self = this;
                window.axios.post('/api/client/check_name', this.local_client)
                  .then(response => {
                    if(response.data.exists) {
                        self.errors.name = 'The client name entered already exists.';
                    }
                    else
                        self.errors.name = '';
                  });
            },
            validateURL() {
                //The URL should start with https://hooks.slack.com/services/ and be a valid URL
                if(this.local_client.slack_url != null && this.local_client.slack_url != "") {
                    if(this.local_client.slack_url.indexOf("https://hooks.slack.com/services/") == -1) {
                        this.errors.slack_url = "A valid Slack Webhook URL should start with https://hooks.slack.com/services/";
                        return;
                    }

                }
                this.errors.slack_url = '';
            },

            hasError() {
                return this.errors.name != "" || this.errors.slack_url != "" ||
                    this.errors.vertical != "" || this.errors.domain != "";
            },
            addPersona() {
                if(this.local_client.personas == null)
                    this.local_client.personas = [];

                this.local_client.personas.push({name: "", description: ""});
                //Force refresh the screen
                this.$forceUpdate();
            },
            isValid() {
                this.form.error = false;

                if(this.local_client.name == ""){
                    this.errors.name = "The organization name is required.";
                    this.form.error = true;
                }
                else this.errors.name = "";

                if(this.local_client.domain == ""){
                    this.errors.domain = "The organization's website domain is required.";
                    this.form.error = true;
                }
                else this.errors.domain = "";

                if(this.local_client.vertical == "") {
                    this.errors.vertical = "The vertical is required.";
                    this.form.error = true;
                }
                else this.errors.vertical = "";

                return !this.form.error;

            },
            update() {
                if(this.isValid() == false)
                    return;

                var self = this;
                this.form.busy = true;
               
                //Set the BQ dataset variable
                // for(var i = 0; i < this.nextafterio_clients.length; i++) {
                //     if(this.nextafterio_clients[i].id == this.local_client.nextafterio_id) {
                //         this.local_client.na_dataset = this.nextafterio_clients[i].na_dataset;
                //         this.local_client.acq_dashboard_id = this.nextafterio_clients[i].acq_dashboard_id;
                //         break;
                //     }
                // }

                //Clear out any empty donor personas
                if(this.local_client.personas != null) {
                    for(var i = 0; i < this.local_client.personas.length; i++) {
                        if(this.local_client.personas[i].name == "" && this.local_client.personas[i].description == ""){
                            this.local_client.personas.splice(i, 1);
                            i--;
                            continue;
                        }
                        if(this.local_client.personas[i].name == "" && this.local_client.personas[i].description != "")
                            this.local_client.personas[i].name = 'Persona #' + (i+1);
                    }
                }

                window.axios.put('/api/client/' + this.local_client.id, this.local_client)
                    .then(response => {
                        self.form.busy = false;
                        self.form.successful = true;
                        setTimeout( function() {self.form.successful = false} , 5000);
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Profile Updated!</h4>',
                            target: '#org_settings_profile',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });
                    }).catch(error =>{
                        self.form.busy = false;
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Error on updating profile</h4>',
                            target: '#org_settings_profile',
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });
                    });
            },
        }
    }
</script>
