<template>
    <div class="pie-chart-template">
       <canvas :id="'chart-'+this.id" :class="(if_chart_has_data ? '':'hide')" class="pie-chart" ></canvas>
       <div :class="(!if_chart_has_data ? 'd-inline-block':'hide')" class='text-center'>
           <h1 class="text-secondary_v1 mb-1"><i class="fa-solid fa-chart-simple"></i></h1>
           <h5 class="text-secondary_v1 mb-2"><em>Press the <strong>Preview</strong> button to render the chart</em></h5>
       </div>
   </div>
</template>

<script>
   import ChartDataLabels from 'chartjs-plugin-datalabels';

   export default {
       props: ['chart_selected', 'id', 'if_refreshing', 'if_on_report_page', 'if_benchmark_report', 'applied_chart_filter'],
       data() {
           return {
               chart: null,
               color: null,
               formatType: null,
               if_chart_has_data: false,
               if_chart_filter_applied: false,
               chart_filter_label: '',
               font_size: 12,
               max_value: 0,
           };
       },
       watch:{
           chart_selected: {
               handler: function(newVal, oldVal) {
                   // Since chart_selected is a complex object, you might want to perform
                   // a more specific check to determine if renderChart should be called.
                   // For now, we'll just check if it has 'data' to decide.
                   if (newVal.data) {
                       if(this.chart_selected.metric_formats[0] != undefined){
                           this.formatType = this.chart_selected.metric_formats[0];
                        } else{
                            this.formatType = 'number';
                        }
                        if(this.chart_selected.metrics[0] != undefined && this.chart_selected.metrics[0].color){
                           this.color = this.chart_selected.metrics[0].color;
                       } else{
                           this.color = 'yellow';
                       }
                           
                       this.renderChart();
                       this.if_chart_has_data = true;
                   }
               },
               deep: true, // This makes sure the watcher is looking for changes deeply within the object
           },
           if_refreshing:{
               handler: function(newVal, oldVal) {
                   this.$forceUpdate();
               }
           },
           applied_chart_filter:{
                handler: function(newVal, oldVal) {
                    this.if_chart_filter_applied = this.applied_chart_filter.if_chart_filter_applied && this.id == this.applied_chart_filter.section_chart_id;
                    this.renderChart();
                }
            }
       },
       mounted() {
            var export_report_class = document.querySelector('.export-report');
            if (export_report_class){ // if on export view, make font bigger, set to 15 for now
                this.font_size = 15;
            }
           if (this.chart_selected.data){
               // loop through metric_objects in chart to find format 
               if(this.chart_selected.metric_formats[0] != undefined){
                   this.formatType = this.chart_selected.metric_formats[0];
                   this.color = this.chart_selected.metrics[0].color;
               } else{
                   this.formatType = 'number';
                   this.color = 'yellow';
               }
               this.renderChart();
               this.if_chart_has_data = true;
           }
       },
       methods: {
           renderChart() {
               var dataset = this.getChartData(); 
               var options = this.getChartOptions(dataset);

               if(this.chart != null)
                   this.chart.destroy();

               var ctx = document.getElementById('chart-'+this.id);

               if(ctx != undefined)
                   this.chart = new Chart(ctx, options);
           },
           getColor(color) { // colors are from dark to light shades
               const colors = {
                   yellows: [
                        'rgb(220, 150, 0)',
                        'rgb(235, 181, 0)',
                        'rgb(255, 203, 32)',
                        'rgb(255, 219, 102)',
                        'rgb(255, 229, 145)',
                        'rgb(255, 236, 174)',
                   ],
                   greens: [
                        'rgb(10, 110, 62)',
                        'rgb(15, 166, 92)',
                        'rgb(25, 207, 120)',
                        'rgb(69, 223, 149)',
                        'rgb(107, 243, 178)',
                        'rgb(167, 248, 209)',
                    ],
                   blues: [
                       'rgb(9, 71, 137)',
                       'rgb(10, 89, 250)',
                       'rgb(51, 149, 255)',
                       'rgb(122, 186, 255)',
                       'rgb(168, 204, 244)',
                       'rgb(211, 228, 247)',
                   ],
                   purples: [
                        'rgb(65, 1, 121)',
                        'rgb(133, 48, 209)',
                        'rgb(124, 82, 149)',
                        'rgb(183, 123, 244)',
                        'rgb(210, 168, 253)',
                        'rgb(223, 192, 255)',
                    ],
                   grays: [
                       'rgb(18, 18, 18)',
                       'rgb(57, 74, 69)',
                       'rgb(101, 121, 115)',
                       'rgb(143, 158, 153)',
                       'rgb(173, 188, 183)',
                       'rgb(200, 215, 211)',
                       'rgb(225, 234, 230)',
                   ],
               };
               if(color == 'yellow')
                   return colors.yellows;
               if(color == 'green')
                   return colors.greens;
               if(color == 'blue')
                   return colors.blues;
               if(color == 'purple')
                   return colors.purples;
               if(color == 'gray')
                   return colors.grays;
               return colors.yellows;
           },
           getChartData() {
               const chartColors = this.getColor(this.color); // the array of RGB color values indicated by the database
               const datasetArray = [];
               var max = 0;

               var backgroundColorArray = [];
               if (this.if_chart_filter_applied){
                   var index = this.chart_selected.labels.findIndex(label => label == this.chart_filter_label);
                   backgroundColorArray = this.getColor('gray').slice(-this.chart_selected.labels.length).reverse();
                   backgroundColorArray[index] = chartColors[0];
               }
               else {
                   backgroundColorArray = chartColors;
               }

               Object.keys(this.chart_selected.data).forEach((key, index) => {
                    var max_temp = Math.max(...this.chart_selected.data[key]);
                    if (max_temp > max){
                        max = max_temp;
                    }
                   datasetArray.push({
                       label: key,
                       data: this.chart_selected.data[key],
                       backgroundColor: backgroundColorArray,
                       borderWidth: 0,
                       hoverOffset: 5,
                   })
               });

               this.max_value = max;
               return {
                   labels: this.chart_selected.labels, // only take first 10 elements in array,
                   datasets: datasetArray
               };
           },
           getChartOptions(data) {
               var self = this;
               var config = {
                   plugins: [ChartDataLabels],
                   type: 'pie',
                   data: data,
                   options: {
                       responsive: true,
                    //    maintainAspectRatio: false,
                       plugins: {
                           legend: {
                               display: true, 
                               position: (data.datasets.length > 0 && data.datasets[0].label != null) ? 'right' : 'none', //Only show the legend if there is more than one media type
                               align: 'middle',
                               labels: {
                                   usePointStyle: true,
                                //    generateLabels: (chart) => {
                                   //     let labelArray = [];
                                   //     chart.data.labels.forEach((label, index) => { // loop over the labels, not the dataset, to get the label text
                                   //         let dataset = chart.data.datasets[0]; // only one dataset in Pie chart
                                   //         labelArray.push({
                                   //             text: label,
                                   //             fillStyle: dataset.backgroundColor[index], // backgroundColor is an array of colors
                                   //             //strokeStyle: dataset.borderColor[index], // borderColor is NOT an array of colors
                                   //             pointStyleWidth: 16,
                                   //             lineWidth: 0, // remove box border by default
                                   //             hidden: false,
                                   //         });
                                   //     });
                                   //     return labelArray;
                                //    },
                                   font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                   },
                                },
                                onHover: function(e,chart) {
                                    e.native.target.style.cursor = 'pointer';
                                },
                                onLeave: function(e,chart) {
                                    e.native.target.style.cursor = 'default';
                                }
                           },
                           tooltip: {
                               enabled: false,
                               external: function(context) {
                                   // Tooltip Element
                                   let tooltipEl = document.getElementById('chart-'+self.id+'-tooltip');

                                   // Create element on first render
                                   if (!tooltipEl) {
                                       tooltipEl = document.createElement('div');
                                       tooltipEl.id = 'chart-'+self.id+'-tooltip';
                                       tooltipEl.classList.add("chart-template-tooltip");
                                       tooltipEl.innerHTML = '<table></table>';
                                       document.body.appendChild(tooltipEl);
                                   }

                                   const position = context.chart.canvas.getBoundingClientRect();
                                   // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

                                   // Display, position, and set styles for font
                                   const tooltipModel = context.tooltip;
                                   tooltipModel.title = tooltipModel.body[0].lines[0].split(': ') 
                                   tooltipEl.style.opacity = 1;
                                   tooltipEl.style.position = 'absolute';
                                   tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX - tooltipModel.width/2 + 'px';// center align
                                   tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';
                                   tooltipEl.style.backgroundColor = 'white';
                                   tooltipEl.style.padding = '5px';
                                    tooltipEl.style.boxShadow = '2px 4px 20px 0px #0000001A';
                                    tooltipEl.style.fontFamily = 'MDSystem-Regular';

                                    // Hide if no tooltip 
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }
                                    tooltipEl.addEventListener('mouseover',()=>{
                                        tooltipEl.style.opacity = 1;
                                    });
                                    tooltipEl.addEventListener('mouseout',()=>{
                                        tooltipEl.style.opacity = 0;
                                    });

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        const titleLines = tooltipModel.title || [];
                                        const bodyLines = tooltipModel.body.map(b => b.lines);

                                        let innerHtml = '<thead>';
                                        
                                        // title
                                        innerHtml += '<tr><th>' + titleLines[0] + '</th></tr>';
                                        innerHtml += '</thead><tbody>';

                                        // Calculate the total 
                                        var total = Object.values(self.chart_selected.data)[0].reduce((partialSum, a) => partialSum + a, 0);

                                        bodyLines.forEach(function(body, i) {
                                            var label = '';
                                            var body_label = body[0].split(': ');
                                            var value = tooltipModel.dataPoints[i].raw;

                                            var colors = tooltipModel.labelColors[i];
                                            var style = 'background-color:' + colors.backgroundColor + '; width:10px; height:10px; display:inline-block; margin-right:5px'
                                            var colorDiv = '<div style="' + style + '"></div>';

                                            if(self.formatType == 'money')
                                                label = self.$options.filters.currency_with_zero(value) + ' ('+ self.$options.filters.percentage(value / total) +')';
                                            if(self.formatType == 'number' && self.max_value >= 10)
                                                label = self.$options.filters.number_with_zero(value) + ' ('+ self.$options.filters.percentage(value / total) +')';
                                            if(self.formatType == 'number' && self.max_value < 10)
                                                label = self.$options.filters.number_with_decimal(value) + ' ('+ self.$options.filters.percentage(value / total) +')';
                                            if(self.formatType == 'percent')
                                                label = self.$options.filters.percentage(value) + ' ('+ self.$options.filters.percentage(value / total) +')';

                                            const span = '<span>' + label + '</span>';
                                            innerHtml += '<tr><td>' + colorDiv + span + '</td></tr>';
                                        });

                                        if(self.if_on_report_page && !self.if_benchmark_report){
                                            innerHtml += '<hr class="m-1">';
                                            
                                            if (self.if_chart_filter_applied && self.chart_filter_label == titleLines[0]){
                                                innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Remove as Filter</button>'
                                            } else {
                                                innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Explore</button>'
                                            }
                                        
                                            innerHtml += '</tbody>';  

                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;

                                            let btn = tooltipEl.querySelector('button');
                                            if (self.if_chart_filter_applied && self.chart_filter_label != titleLines[0]){
                                                btn.disabled=true;
                                            }

                                            // if chart filter applied on any other chart, disable explore for this current chart to block cross filtering for now
                                            // this is temporyary till we add cross filtering in
                                            if (self.applied_chart_filter.if_chart_filter_applied && self.if_chart_filter_applied == false){
                                                btn.disabled=true;
                                            }

                
                                            if (self.if_chart_filter_applied){
                                                btn.addEventListener('click', () => {
                                                    self.if_chart_filter_applied = false;
                                                    self.$emit('exploreChart', {
                                                        chart_selected: null,
                                                        chart_filters: []
                                                    });
                                                    var dataset = self.getChartData(); 
                                                    self.chart.data = dataset;
                                                    self.chart.update();
                                                });
                                            } else {
                                                btn.addEventListener('click', () => {
                                                    self.if_chart_filter_applied = true;
                                                    self.chart_filter_label = titleLines[0];
                                                    self.$emit('exploreChart', {
                                                        chart_selected: self.chart_selected,
                                                        chart_filters: [{
                                                            name: self.chart_selected.group_by[0].text,
                                                            column: self.chart_selected.group_by[0].value,
                                                            value: titleLines[0]
                                                        }]
                                                    });

                                                    // set the other columns as grayscale and change button text
                                                    var dataset = self.getChartData(); 
                                                    let btn = tooltipEl.querySelector('button');
                                                    btn.textContent = "Remove as Filter";
                                                    self.chart.data = dataset;
                                                    self.chart.update();
                                                });
                                            }
                                        } else { // benchmark chart tooltip
                                            innerHtml += '</tbody>';  
                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;
                                        }
                                        // tableRoot.appendChild(btn);
                                    }  
                               }
                           },
                           datalabels: {
                                // display: false,
                               // anchor: 'center', // Specify the anchor position for the labels
                               align: 'end', // Specify the alignment of the labels
                               // clamp: false,
                               textAlign: 'center',
                               labels: {
                                   font: {
                                        color: (chart) => { // adjust color to contrast background
                                            if (chart.dataset){
                                                return chart.dataset.backgroundColor.map((color) => {
                                                    var colorArray = color.match(/\d+/g).map(Number);
                                                    let brightness = 0.2126 * colorArray[0] +0.7152* colorArray[1]+0.0722*colorArray[2];
                                                    return brightness >= 128? // if light background
                                                        'rgb(102, 102, 102)': // then black text
                                                        'rgb(255, 255, 255)' // else white text
                                                });                                                
                                            }else {
                                                return 'rgb(255, 255, 255)';
                                            }

                                        },
                                        size: this.font_size,
                                        weight: 600,
                                        family: 'MDSystem-Regular',
                                   }                            
                               },
                               formatter: function(value, context) {
                                    if(context.datasetIndex < data.datasets.length-1)
                                       return null;
                                    if (self.if_chart_filter_applied){
                                        var chart_filter_index = self.chart_selected.labels.findIndex((label) => label == self.chart_filter_label)// use to keep track on which data to explore one
                                        if(context.dataIndex != chart_filter_index)
                                            return null;
                                    }

                                   if(self.formatType == 'money' && value < 1000)
                                       var label = self.$options.filters.currency_with_zero(value);
                                   if(self.formatType == 'money')
                                       var label = self.$options.filters.currency_abbr(value);
                                    if(self.formatType == 'number' && value >= 1000)
                                        var label = self.$options.filters.num_abbr(value);
                                    if(self.formatType == 'number' && value < 1000 && self.max_value >= 10)
                                        var label = self.$options.filters.number_with_zero(value);
                                    if(self.formatType == 'number' && value < 1000 && self.max_value < 10)
                                       var label = self.$options.filters.number_with_decimal(value);
                                    if(self.formatType == 'percent')
                                       var label = self.$options.filters.percentage(value);

                                   // //If it is within 95% of the width, add padding to the end
                                   // // Ideally it would be based upon the width of the bar + label but
                                   // // this is an easy alternative
                                   // var chart_width = context.chart.chartArea.width;
                                   // //The characters are, at most 7px wide and then the padding of 6
                                   // var label_width = label.length*7+6;

                                   // if(total >= context.chart.scales.x.end*(1-label_width/chart_width) && context.chart.options.layout.padding.right == 0){

                                   //     context.chart.options.layout.padding.right = label_width;
                                   //     context.chart.update();

                                   // }
                                   return label;
                               },
                           }
                       },
                       title: {
                           display:false,
                       },
                   },
               };
               return config;
           }
       }
   }
</script>

