<template>
    <div class="annual-plan-initiatives">
        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Projected Results</h4>
                <p>Results last calculated at {{ getMaxDate() }}.</p>
                <div class="date-selector">
                    <button type="button" class="btn btn-sm btn-outline-secondary back-btn" v-on:click="moveDatesBackward()"><i class="fa fa-angle-double-left" aria-hidden="true"></i></button>
                    {{ month_names[current_month] }} {{ current_year }} &mdash; {{ future_date }}
                    <button type="button" class="btn btn-sm btn-outline-secondary forward-btn" v-on:click="moveDatesForward()"><i class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                </div>

                <div class="initiatives-selections">
                    <table class="table">
                        <thead>
                            <tr>
                                <th >Initiative</th>
                                <th class="priority">Priority</th>
                                <th >Strategic Focus</th>
                                <th class="metric">Cost</th>
                                <th class="metric">Emails Acquired</th>
                                <th class="metric">New Donors</th>
                                <th class="metric">Initiative Revenue</th>
                                <th class="metric">Recurring Revenue</th>
                                <th class="ltv">Lifetime Value Potential</th>
                            </tr>
                        </thead>
                        <tbody v-for="item in results"  v-bind:class="item.priority.toLowerCase()" v-if="item.timeframe == current_year">
                            <tr class="initiative" :class="isEmptyClass(item)" v-if="resultInPlan(item)">
                                <td class="bare" scope="colgroup" v-if="item.priority == 'Required'" ></td>
                                <td :colspan="(item.priority == 'Required' ? 2 : 1)">{{ item.title }}</a></td>
                                <td class="priority" v-if="item.priority != 'Required'" >{{ item.priority }}</td>
                                <td  v-if="item.priority != 'Required'" ><i class="fas" :class="getIcon(item.strategic_focus)"></i>
                                {{ item.strategic_focus }}</td>
                                <td class="text-left metric" >{{ getCostByInitiative(item) | currency_na }}</td>
                                <td class="text-start metric" >{{ item.emails | number_na}}</td>
                                <td class="text-start metric" >{{ item.new_donors | number_na }}</td>
                                <td class="text-start metric" >{{ item.revenue | currency_na }}</td>
                                <td class="text-start metric" >{{ item.recurring_revenue | currency_na }}</td>
                                <td class="text-start ltv" >{{ item.lifetime_value | currency_na }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="bare"></th>
                                <th class=" border-white" colspan="2" style="text-align: center; vertical-align: middle; ">Totals</th>
                                <th class="text-left metric border-white" >{{ getAllCosts() | currency_na }}</th>
                                <th class="text-start metric border-white" >{{ getTotalByMetric('emails') | number_na}}</th>
                                <th class="text-start metric border-white" >{{ getTotalByMetric('new_donors') | number_na }}</th>
                                <th class="text-start metric p-0 border-white" colspan="2">
                                    <table class="table mb-0">
                                        <tr>
                                            <td class="text-start metric h-auto border-top-0 border-start-0 py-2 border-white" style='padding-left: 0.75rem; background-color:rgb(225, 229, 238);'>{{ getTotalByMetric('revenue') | currency_na }}</td>
                                            <td class="text-start metric h-auto border-top-0 border-end-0 py-2 border-white" style='padding-left: 0.75rem;background-color:rgb(225, 229, 238);'>{{ getTotalByMetric('recurring_revenue') | currency_na }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="metric h-auto border-bottom-0 border-start-0 border-end-0 py-2" style="background-color:rgb(225, 229, 238);"><span class='fw-normal'>Total:</span> {{ getTotalByMetric('revenue') + getTotalByMetric('recurring_revenue') | currency_na }}</td>
                                        </tr>
                                    </table>
                                </th>
                                <th class="text-start ltv border-white" >{{ getTotalByMetric('lifetime_value')  | currency_na }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <div class="card box-content">
            <div class="card-body">
                <h4>Result Details</h4>
                <div class="btn-group result-selector" role="group">
                  <button type="button" class="btn" @click="current_metric = 'emails'"
                    :class="current_metric == 'emails' ? 'btn-secondary' : 'btn-outline-secondary'">Emails</button>
                  <button type="button" class="btn" @click="current_metric = 'new_donors'"
                    :class="current_metric == 'new_donors' ? 'btn-secondary' : 'btn-outline-secondary'">New Donors</button>
                  <button type="button" class="btn" @click="current_metric = 'gifts'"
                    :class="current_metric == 'gifts' ? 'btn-secondary' : 'btn-outline-secondary'">Gifts</button>
                  <button type="button" class="btn" @click="current_metric = 'revenue'"
                    :class="current_metric == 'revenue' ? 'btn-secondary' : 'btn-outline-secondary'">Revenue</button>
                  <button type="button" class="btn" @click="current_metric = 'recurring_revenue'"
                    :class="current_metric == 'recurring_revenue' ? 'btn-secondary' : 'btn-outline-secondary'">Recurring</button>
                  <button type="button" class="btn" @click="current_metric = 'lifetime_value'"
                    :class="current_metric == 'lifetime_value' ? 'btn-secondary' : 'btn-outline-secondary'">LTV</button>
                </div>

                <div class="initiatives-selections">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="2" scope="colgroup" class="bare"></th>
                                <template v-for="(months, year) in calendar_headers">
                                <th v-bind:colspan="months.length" scope="colgroup">{{ year }}</th>
                                </template>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <th class="priority">Priority</th>
                                <template v-for="(months, year) in calendar_headers">
                                <th scope="col" class="month" v-for="month in months">{{ month_names[month] }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in results" v-bind:class="row.priority.toLowerCase()"
                                v-if="row.timeframe == current_year && resultInPlan(row)">
                                <td scope="row" >{{ row.title }}</td>
                                <td class="priority">{{ row.priority }}</td>
                                <template v-for="(months, year) in calendar_headers">
                                <td scope="col" v-for="month in months" class="text-center month" >
                                    <span v-if="current_metric == 'emails' || current_metric == 'new_donors' || current_metric == 'gifts'">
                                        {{ getMetric(row, month, year) | number }}
                                    </span>
                                    <span v-else> {{ getMetric(row, month, year) | currency }} </span>
                                </td>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="2" class="border-white" style="text-align: center; vertical-align: middle; ">Total</th>
                                <template v-for="(months, year) in calendar_headers">
                                    <th scope="col" v-for="month in months" class="text-center month border-white" >
                                        <span v-if="current_metric == 'emails' || current_metric == 'new_donors' || current_metric == 'gifts'">
                                            {{ getTotalByMonth( month, year) | number }}
                                        </span>
                                        <span v-else> {{ getTotalByMonth(month, year) | currency }} </span>
                                    </th>
                                </template>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['plan', 'client', 'results', 'result_details', 'current_month', 'current_year'],
        data() {
            return {
                month_names: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
                current_metric: 'emails',
                result_arr: [],
                num_calls: 0
            };
        },
        created() {

            //Need to make the results into an easily accessible array to speed up the search time
            for(var i = 0; i < this.result_details.length; i++) {

                var year = parseInt(this.result_details[i].date.split("-")[0]);
                var month = parseInt(this.result_details[i].date.split("-")[1])-1;

                this.result_arr[year+"-"+month+"-"+this.result_details[i].initiative_id] = this.result_details[i];
            }
        },
        watch: {
            result_details: function(newVal, oldVal) {
                this.result_arr = [];
                //Need to make the results into an easily accessible array to speed up the search time
                for(var i = 0; i < newVal.length; i++) {

                    var year = parseInt(newVal[i].date.split("-")[0]);
                    var month = parseInt(newVal[i].date.split("-")[1])-1;

                    this.result_arr[year+"-"+month+'-'+newVal[i].initiative_id] = newVal[i];
                }
            }
        },
        computed: {

            future_date() {
                return moment().set({ 'year': this.current_year, 'month': this.current_month }).add(11, 'M').format('MMM YYYY');
            },

            calendar_headers() {
                var headers = {};

                if (this.current_month == null || this.current_year == null)
                    return headers;

                let startDate = moment().set({ 'year': this.current_year, 'month': this.current_month });
                let endDate = moment().set({ 'year': this.current_year, 'month': this.current_month }).add(11, 'M')
                while (endDate.isSameOrAfter(startDate)) {
                    if (!headers[startDate.year()])
                        headers[startDate.year()] = [];

                    headers[startDate.year()].push(startDate.month());
                    //increment by one day
                    startDate = startDate.add(1, 'M')
                }

                return headers;
            }
        },
        
        methods: {

            getMaxDate() {
                if(this.results == null || this.results.length == 0)
                    return null;

                var dte = new Date(this.results[0].updated_at + " UTC");
                return dte.toLocaleString();
            },
            isEmptyClass(item) {
                if(this.getCostByInitiative(item) > 0 ||
                    parseInt(item.emails) + parseInt(item.new_donors) + parseFloat(item.revenue) + parseFloat(item.recurring_revenue) + parseFloat(item.lifetime_value) > 0)
                    return "";
                return "is-empty";
            },
            getIcon(focus) {
                if(focus == 'Analysis & Reporting')
                    return 'fa-chart-bar';
                if(focus == 'Acquisition')
                    return 'fa-filter';
                if(focus == 'Audience Generation')
                    return 'fa-users';
                if(focus == 'Donor Upgrade')
                    return 'fa-arrow-up';
                if(focus == 'Reactivation')
                    return 'fa-redo';
                if(focus == 'Retention')
                    return 'fa-coins';
            },
            resultInPlan(init) {
                var init_arr = init.initiative_ids.split(",");
                for(var j = 0; j < this.plan.initiatives.length; j++)
                    if(init_arr.includes(this.plan.initiatives[j].id + ""))
                        return true;

                return false;
            },

            moveDatesBackward() {
                var timeframe = moment().set({ 'year': this.current_year, 'month': this.current_month }).subtract(12, 'M');
                this.$emit('update:current_month', timeframe.month());
                this.$emit('update:current_year', timeframe.year());
            },

            moveDatesForward() {
                var timeframe = moment().set({ 'year': this.current_year, 'month': this.current_month }).add(12, 'M');
                this.$emit('update:current_month', timeframe.month());
                this.$emit('update:current_year', timeframe.year());
            },

            getCostByInitiative(initiative_needed) {

                var start_date = new Date(Date.UTC(this.current_year,this.current_month));
                var timeframe = moment().set({ 'year': this.current_year, 'month': this.current_month }).add(11, 'M');
                var end_date = new Date(Date.UTC(timeframe.year(),timeframe.month()));

                var total = 0;
                //Look at each initiative
                for(var i = 0; i < this.plan.initiatives.length; i++){
                    //If this init matches the title and priority of the one in the loop
                    if(this.plan.initiatives[i].title == initiative_needed.title &&
                        this.plan.initiatives[i].priority == initiative_needed.priority) {

                        var init = this.plan.initiatives[i];
                        var init_start_date = this.getJSDate(init.start_month);
                        if(start_date > init_start_date)
                            init_start_date = start_date;

                        var init_end_date = this.getJSDate(init.end_month);
                        if(end_date < init_end_date)
                            init_end_date = end_date;

                        if(init_start_date > init_end_date) continue;

                        for(var j = 0; j < this.plan.initiatives[i].costs.length; j++) {
                            var cost = this.plan.initiatives[i].costs[j];

                            if(cost.type == "monthly")
                                //Have to add 1 to account the initial month (July - July would be 0 but should be 1)
                                total += parseFloat(cost.value) * (1+this.monthDiff(init_start_date, init_end_date));
                            else if(cost.type == 'one_time' && this.getJSDate(init.start_month) <= init_end_date && this.getJSDate(init.start_month) >= init_start_date)
                                total += parseFloat(cost.value);
                            else if(cost.type == 'media')
                                total += parseFloat(cost.value) * (1+ this.monthDiff(init_start_date, init_end_date));
                        }
                    }

                }
                return total;
            },
            getAllCosts() {
                var total = 0;
                for(var i = 0; i < this.results.length; i++){
                    if(this.results[i].timeframe == this.current_year && this.resultInPlan(this.results[i]))
                        total += this.getCostByInitiative(this.results[i]);
                }
                return total;
            },
            getTotalByMetric(metric) {
                var total = 0;
                for(var i = 0; i < this.results.length; i++){
                    if(this.results[i].timeframe == this.current_year && this.resultInPlan(this.results[i])) {
                        if(metric == 'emails')
                            total += parseInt(this.results[i].emails);
                        else if(metric == 'new_donors')
                            total += parseInt(this.results[i].new_donors);
                        else if(metric == 'revenue')
                            total += parseFloat(this.results[i].revenue);
                        else if(metric == 'recurring_revenue')
                            total += parseFloat(this.results[i].recurring_revenue);
                        else if(metric == 'lifetime_value')
                            total += parseFloat(this.results[i].lifetime_value);
                        else if(metric == 'gifts')
                            total += parseFloat(this.results[i].gifts);
                    }
                }
                return total;
            },
            getTotalByMonth(month, year) {

                var total = 0;
                var arr = [];
                for(let row in this.results)
                     arr = arr.concat(this.results[row].initiative_ids.split(","));

                let unique_arr = Array.from(new Set(arr));

                for(var i = 0; i < unique_arr.length; i++) {
                    var init_id = unique_arr[i];

                    if(this.result_arr[year+'-'+month+'-'+init_id] == undefined)
                        return 0;

                    if(this.current_metric == 'emails')
                        total += parseInt(this.result_arr[year+'-'+month+'-'+init_id].emails);
                    else if(this.current_metric == 'new_donors')
                        total += parseInt(this.result_arr[year+'-'+month+'-'+init_id].new_donors);
                    else if(this.current_metric == 'revenue')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].revenue);
                    else if(this.current_metric == 'recurring_revenue')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].recurring_revenue);
                    else if(this.current_metric == 'lifetime_value')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].lifetime_value);
                    else if(this.current_metric == 'gifts')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].gifts);

                }

                return total;
            },
            getMetric(row, month, year) {
                var arr = row.initiative_ids.split(",");

                var total = 0;
                for(var i = 0; i < arr.length; i++) {
                    var init_id = arr[i];

                    if(this.result_arr[year+'-'+month+'-'+init_id] == undefined)
                        return 0;

                    if(this.current_metric == 'emails')
                        total += parseInt(this.result_arr[year+'-'+month+'-'+init_id].emails);
                    else if(this.current_metric == 'new_donors')
                        total += parseInt(this.result_arr[year+'-'+month+'-'+init_id].new_donors);
                    else if(this.current_metric == 'revenue')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].revenue);
                    else if(this.current_metric == 'recurring_revenue')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].recurring_revenue);
                    else if(this.current_metric == 'lifetime_value')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].lifetime_value);
                    else if(this.current_metric == 'gifts')
                        total += parseFloat(this.result_arr[year+'-'+month+'-'+init_id].gifts);

                }
                return total;
            },
            getJSDate(str_date) {
                var part = str_date.split('T')[0];
                var date = new Date(Date.UTC(part.split("-")[0], parseInt(part.split("-")[1])-1, part.split("-")[2]));

                return date;
            },
            monthDiff(d1, d2) {
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                return months <= 0 ? 0 : months;
            }
        }
    }
</script>
<style>

</style>
