<template>
    <div class="versions">

        <div class="row">

            <div class="col-sm-12">

                <!-- Appeals Table -->
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Version Name</th>
                            <th scope="col">Active</th>
                            <th scope="col">Published</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Updated On</th>
                            <th scope="col">Updated By</th>
                            <th scope="col" class="actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(plan, index) in plans" v-bind:id="'row' + plan.id">
                            <td>{{ plan.name }}</td>
                            <td align="center"><i class="fas fa-check" v-if="plan.is_active_plan == 1"></i></td>
                            <td align="center"><i class="fas fa-check" v-if="plan.is_published_plan == 1"></i></td>
                            <td>{{ plan.created_at | date }}</td>
                            <td>{{ plan.creator.name  }}</td>
                            <td>{{ checkDuplicate(plan.created_at, plan.updated_at, plan.updated_at) | date }}</td>
                            <td>{{ checkDuplicate(plan.created_at, plan.updated_at, plan.updater.name)  }}</td>
                            <td class="actions">
                                <button class="btn btn-sm btn-primary" title="Publish" v-on:click="publishPlan(plan)" v-if="plan.is_published_plan == 0"><i class="fa fa-upload"></i> Publish</button>
                                <button class="btn btn-sm btn-secondary" v-on:click="unpublishPlan(plan)" title="Unpublish" v-else><i class="fa fa-ban"></i> Unpublish</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';
    export default {

        props: ['plans', 'client'],
        data() {
            return {
                plans_copy: []
            };
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            },
        },
        mounted() {
            this.plans_copy = this.plans;
        },
        methods: {
            checkDuplicate(created, updated, value) {
                if(created == updated)
                    return null;
                return value;
            },
            publishPlan(plan) {
                for(var i = 0; i < this.plans_copy.length; i++)
                    if(this.plans_copy[i].is_published_plan == 1) {
                        this.plans_copy[i].is_published_plan = 0;
                        window.axios.put('/api/annual_plan/' + this.plans_copy[i].id, this.plans_copy[i]);
                    }

                plan.is_published_plan = 1;
                window.axios.put('/api/annual_plan/' + plan.id, plan).then(function() {
                    location.reload();
                });
            },
            unpublishPlan(plan) {
                plan.is_published_plan = 0;
                window.axios.put('/api/annual_plan/' + plan.id, plan).then(function() {
                    location.reload();
                });
            }
        }
    }
</script>
