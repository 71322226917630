<template>
    <div class="results">
        <h2 class="mb-4 mt-5 border-bottom border-secondary border-3 pb-1" v-if="client != null">
            <span >{{client.name}}:</span> <em>Media Management</em>
        </h2>
        <div class="row">
            <div class= "col">
                <nav class="navbar navbar-expand-lg bg-secondary mb-4 rounded">
                  <div class="container-fluid">
                    <span class="navbar-brand mb-0 ms-1 h1">Filters:</span>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#filter-navbar" aria-controls="filter-navbar" aria-expanded="false" aria-label="Toggle Filters">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="filter-navbar">
                      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown me-3" v-if="client == null">
                          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                           <i class="fa-solid fa-building me-1"></i> Clients
                          </a>
                          <div class="dropdown-menu"  >
                              <div>
                                <button class="btn btn-link" v-on:click="filters.clients = getAllClientIds()"><em>Select All</em></button>
                                |
                                <button class="btn btn-link" v-on:click="filters.clients = []"><em>Unselect All</em></button>
                              </div>
                              <div class="dropdown-divider"></div>
                              <label class="dropdown-item" v-for="c in clients" :key="c.id">
                                <input class="form-check-input" type="checkbox" :value="c.id" v-model="filters.clients" :true-value="[]">
                                <span class="ms-2" >
                                  {{ c.name }}
                                </span>
                              </label>
                          </div>
                        </li>
                        <li class="nav-item dropdown me-3">
                          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-calendar me-1" aria-hidden="true"></i> Timeframe
                          </a>
                          <ul class="dropdown-menu">
                            <li v-for="t in timeframes"><a class="dropdown-item" href="#" v-on:click.prevent="filters.timeframe = t"><span v-html="getTimeframeText(t)"></span> <i class="fa fa-circle-check text-primary ms-2" v-if="filters.timeframe==t"></i></a></li>
                          </ul>
                        </li>
                        <li class="nav-item dropdown me-3">
                          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                           <i class="fa-solid fa-photo-film me-1"></i> Media Sources
                          </a>
                          <div class="dropdown-menu"  >
                              <label class="dropdown-item" v-for="item in media_sources" :key="item">
                                <input class="form-check-input" type="checkbox" :value="item" v-model="filters.media_sources" :true-value="[]">
                                <span class="ms-2" >
                                  {{ item }}
                                </span>
                              </label>
                          </div>
                        </li>
                        <li class="nav-item dropdown me-3">
                          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                           <i class="fa-solid fa-money-bill me-1"></i> Media Type
                          </a>
                          <div class="dropdown-menu"  >
                              <label class="dropdown-item" v-for="item in media_types" :key="item">
                                <input class="form-check-input" type="checkbox" :value="item" v-model="filters.media_types" :true-value="[]">
                                <span class="ms-2" >
                                  {{ item }}
                                </span>
                              </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button class="btn btn-dark float-end btn-sm d-none d-sm-inline-block me-3 text-nowrap"
                    :class="{'disabled': !filtered}" v-on:click="saveSettings()"><i class="fa fa-btn fa-save"></i> Save Settings</button>
                </nav>
            </div>
        </div>
        <!-- Overview Scorecards -->
        <div class="row stat-container mb-3" >

            <div class="col-sm-6 col-md-4 mb-4 scorecard" v-for="m in metrics">
                <div class="card box-content" >
                  <h5 class="card-header pt-3">{{m.name | propercase}}</h5>
                  <div class="card-body pt-0 pb-3">
                    <img :src="m.icon" class="float-start me-3" >
                    <h4 class="mt-2">{{m.value | currency_with_zero}}
                        <span class="badge" :class="{ 'bg-label-success':( m.variance > 0), 'bg-label-danger': (m.variance < 0)}" v-if="m.variance != 0">{{m.variance | percentage_na}}</span>
                        </h4 >
                  </div>
                </div>
            </div>
        </div>

        <!-- Charts -->
        <media-management-chart ref="chart" v-for="c in clients" :key="c.id" :title="client == null ? c.name : ''"
            v-if="filters.clients.includes(c.id)" :client="c" :actuals="actuals[c.id]"
            :projections="projections[c.id]" :media_balance="media_balances[c.id]"  :data-client-id="c.id"
            :filters="filters" :notifications="notifs[c.id]"></media-management-chart>

    </div>
</template>

<script>

    export default {
        props: ['user', 'client', 'clients', 'customization', 'actuals', 'projections', 'media_balances', 'notifications'],
        data() {
            return {
                media_sources: [ 'Email Prospecting', 'Facebook', "Google Grant", 'Other', 'Pay Per Click', 'QuickBooks'],
                media_types: ['Billable', 'Non-Billable'],
                timeframes: ['calendar_w_current', 'calendar', 'fiscal_w_current', 'fiscal'],
                metrics: [{
                    name: 'Total Media Spent',
                    icon: '/img/icons/average_amount.png',
                    value: 0,
                    variance: 0
                },{
                    name: 'Billable Media Spent',
                    icon: "/img/icons/average_amount_2nd_gift.svg",
                    value: 0,
                    variance: 0
                },{
                    name: 'Billable Media Dollars Available',
                    icon: "/img/icons/revenue.png",
                    value: 0,
                    variance: 0
                }],
                filters: null,
                filtered: false,
                notifs: []

            };
        },
        watch: {
            filters: {
                handler(n, o) {
                    //Only update it after it has initially been set
                    if(o != null){
                        this.filtered = true;
                        this.$nextTick(() => {
                            this.calculateMetrics();
                        });
                    }

                },
                deep: true
            },
        },
        computed: {

        },
        beforeMount() {
            var client_ids = this.getAllClientIds();

            if(this.customization != null)
                this.filters = this.customization.filter;
            else {
                this.filters = {
                    timeframe: 'fiscal_w_current',
                    media_sources: this.media_sources,
                    media_types: this.media_types,
                    //Add the clients to the filter array so they are all selected
                    clients: null
                }

                this.filters.clients = client_ids;
            }

            //Make an array of the notifications specific to the clients
            for(let n in this.notifications) {
                if(this.notifs[this.notifications[n].data.client_id] == null)
                    this.notifs[this.notifications[n].data.client_id] = []
                this.notifs[this.notifications[n].data.client_id].push(this.notifications[n]);
            }
        },
        mounted() {
            this.calculateMetrics();
        },
        methods: {
            getAllClientIds() {
                var arr = [];
                for(let i in this.clients) {
                    arr.push(this.clients[i].id);
                }
                return arr;
            },
            calculateMetrics() {
                //Total Spend
                let tms = this.getTotalMediaSpend('projections');
                this.metrics[0].value = this.getTotalMediaSpend('actuals');
                if(tms == 0) this.metrics[0].variance = 0;
                else this.metrics[0].variance = (this.metrics[0].value - tms)/tms;

                //Billable Media Spent
                let bms = this.getBillableMediaSpend('projections');
                this.metrics[1].value = this.getBillableMediaSpend('actuals');
                if(bms == 0) this.metrics[1].variance = 0;
                else this.metrics[1].variance = (this.metrics[1].value - bms)/bms;

                //Billable Media Dollars Available
                this.metrics[2].value = this.getBillableMediaBalance();


            },
            getTimeframeText: function (value) {
                var mo = new Date().toLocaleString('en-US', { month: 'long' });
                if(value == 'calendar_w_current')
                    return "Calendar Year";
                if(value == 'calendar')
                    return "Calendar Year <em>(excluding " + mo + ')</em>';
                if(value == 'fiscal_w_current')
                    return "Fiscal Year";
                if(value == 'fiscal')
                    return "Fiscal Year <em>(excluding " + mo + ')</em>';
                return "Unknown";
            },

            getTotalMediaSpend(type) {
                if(this.$refs.chart == null)
                    return 0;

                var total = 0;

                for(let i in this.clients) {
                    let chart = null;
                    //Find this client's chart
                    for(let j in this.$refs.chart)
                        if(this.$refs.chart[j].client.id == this.clients[i].id){
                            chart = this.$refs.chart[j];
                            break;
                        }

                    if(this.filters.clients.includes(this.clients[i].id) && chart != null)
                        if(type == 'actuals')
                            total+= chart.actuals_total;
                        else
                            total+= chart.projections_total;
                }

                return total;

            },
            getBillableMediaSpend(type) {
                if(this.$refs.chart == null)
                    return 0;

                var total = 0;

                for(let i in this.clients) {

                    let chart = null;
                    //Find this client's chart
                    for(let j in this.$refs.chart)
                        if(this.$refs.chart[j].client.id == this.clients[i].id){
                            chart = this.$refs.chart[j];
                            break;
                        }

                    if(this.filters.clients.includes(this.clients[i].id) && chart != null)
                        if(type == 'actuals')
                            total+= chart.billable_actuals_total;
                        else
                            total+= chart.billable_projections_total;
                }

                return total;

            },
            getBillableMediaBalance() {
                if(this.$refs.chart == null)
                    return 0;

                var total = 0;

                for(let i in this.clients) {

                    let chart = null;
                    //Find this client's chart
                    for(let j in this.$refs.chart)
                        if(this.$refs.chart[j].client.id == this.clients[i].id){
                            chart = this.$refs.chart[j];
                            break;
                        }

                    if(this.filters.clients.includes(this.clients[i].id) && chart != null)
                        total+= chart.media_balance_total;
                }

                return total;

            },
            saveSettings(){

                //Create the baseline data structure
                if(this.client == null)
                    var data = {
                        action: 'filter',
                        user_id: this.user.id,
                        filter: this.filters
                    };
                else
                    var data = {
                        action: 'filter',
                        client_id: this.client.id,
                        filter: this.filters
                    };

                //If it is new...
                if(this.customization == null)
                    window.axios.post('/api/customization', data);

                //It is existing so update it
                else {
                    window.axios.put('/api/customization/' + this.customization.id, data);
                }

                this.filtered = false;

            }
        }
    }
</script>
