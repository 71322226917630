var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "insights-dashboard scorecard-page pt-2 mb-4" },
    [
      _c("div", { staticClass: "row mx-2 mb-3" }, [
        _c("div", { staticClass: "p-0" }, [
          _c("h1", [_vm._v("Fundraising Scorecard")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "There are five key aspects for successfully managing a nonprofit fundraising program. Accurately tracking and measuring these are critical to successfully grow the impact of your nonprofit. The metrics below are based upon your personal settings; it should focus on the segments or channels you care most about. "
            ),
            _c(
              "a",
              {
                staticClass: "text-decoration-underline",
                attrs: { href: "/user/" + this.client.url + "/scorecard" },
              },
              [_vm._v(" You can adjust those settings here.")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.page != null &&
      _vm.page.sections[0].charts[0].chart.labels[0] == "(No Data)"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col mb-5" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card box-content h-100 w-100 p-3 d-flex flex-row",
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-fill p-1" }, [
                    _c("h3", [_vm._v("Invalid Filter Selection")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Based upon the filters selected, there are no records that match your criteria. "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-underline",
                          attrs: {
                            href: "/user/" + this.client.url + "/scorecard",
                          },
                        },
                        [_vm._v(" Please adjust that filter here.")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "float-end navbar navbar-expand primary-tab-bar pb-0",
                  },
                  [
                    _c("div", { staticClass: "container-fluid" }, [
                      _c("div", { staticClass: "collapse navbar-collapse" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn-group toggle-btn-group mb-4",
                            attrs: { role: "group" },
                          },
                          _vm._l(_vm.time_period_options, function (t) {
                            return _c(
                              "button",
                              {
                                staticClass: "btn",
                                class: [
                                  _vm.current_time_period == t
                                    ? "btn-primary"
                                    : "btn-white",
                                  _vm.ifWithinThreeMonths() &&
                                  t == "fiscal_year_to_date"
                                    ? "pe-4"
                                    : "",
                                ],
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.ifWithinThreeMonths() &&
                                    t == "fiscal_year_to_date",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTimePeriod(t)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm._f("propercase")(t)) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.ifWithinThreeMonths()
                          ? _c("i", {
                              staticClass:
                                "fas fa-info-circle position-absolute",
                              staticStyle: { right: "158px", top: "23px" },
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                title:
                                  "The Fiscal Year to Date Analysis is disabled until you are on month #4 of your fiscal year. Any analysis performed prior to this will not have enough data to be accurate.",
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row insight-dashboard-info d-flex" }, [
              _c("div", { staticClass: "col mb-3 d-flex" }, [
                _c("div", { staticClass: "card box-content h-100 w-100" }, [
                  _vm.page != null
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex flex-row overflow-hidden h-100",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "overall-score d-flex align-items-center justify-content-center text-center w-100",
                              class: _vm.getTabClass(true, _vm.overall_grade),
                            },
                            [
                              _c("div", [
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "mb-0 text-center",
                                    class: _vm.getGradeClass(_vm.overall_grade),
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title: _vm.getTooltipTextByGrade(
                                        _vm.overall_grade
                                      ),
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "m-0 fa-xs fa-star",
                                      class:
                                        _vm.overall_grade == "4" ||
                                        _vm.overall_grade == "3" ||
                                        _vm.overall_grade == "2" ||
                                        _vm.overall_grade == "1"
                                          ? "fa-solid"
                                          : "fa-regular",
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "m-0 fa-xs fa-star",
                                      class:
                                        _vm.overall_grade == "4" ||
                                        _vm.overall_grade == "3" ||
                                        _vm.overall_grade == "2"
                                          ? "fa-solid"
                                          : "fa-regular",
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "m-0 fa-xs fa-star",
                                      class:
                                        _vm.overall_grade == "4" ||
                                        _vm.overall_grade == "3"
                                          ? "fa-solid"
                                          : "fa-regular",
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "m-0 fa-xs fa-star",
                                      class:
                                        _vm.overall_grade == "4"
                                          ? "fa-solid"
                                          : "fa-regular",
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-fill p-3 d-flex align-items-center justify-content-center",
                            },
                            [
                              _c("div", [
                                _c("h5", [
                                  _vm._v("Overall Rating Explanation"),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "mb-0",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.getFocusMetric()),
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "d-flex flex-row overflow-hidden h-100",
                        },
                        [_vm._m(2), _vm._v(" "), _vm._m(3)]
                      ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4 mb-3 d-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card box-content h-100 w-100 p-3 d-flex flex-row",
                  },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-fill p-1" }, [
                      _c("h5", [_vm._v("Time Period Data")]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "mb-0" }, [
                        _c("li", [
                          _vm._v("Time Period: "),
                          _c("em", [
                            _vm._v(_vm._s(_vm.client.default_reporting_period)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v("Last Transaction Date: "),
                          _c("em", [
                            _vm.meta_data.constituent != null
                              ? _c("span", [
                                  _vm.meta_data.constituent
                                    ._avid_max_display_date != null &&
                                  _vm.meta_data.constituent
                                    ._avid_max_display_date.date != null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm.meta_data.constituent
                                                ._avid_max_display_date.date
                                            )
                                          ) + " "
                                        ),
                                      ])
                                    : _vm.meta_data.constituent
                                        ._avid_max_display_date != null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm.meta_data.constituent
                                                ._avid_max_display_date
                                            )
                                          ) + " "
                                        ),
                                      ])
                                    : _vm.meta_data.constituent
                                        ._max_overall_donation_date != null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm.meta_data.constituent
                                                ._max_overall_donation_date.date
                                            )
                                          ) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _c("span", [
                                  _vm._m(5),
                                  _vm._v(
                                    " Loading\n                                "
                                  ),
                                ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.meta_data.subscriber != null
                          ? _c("li", [
                              _vm._v("Last Email Date: "),
                              _c("em", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.meta_data.subscriber
                                        ._max_overall_record_date
                                    )
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "row mx-0 mb-3 top-5-metrics box-content d-flex flex-row",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-shrink-1 p-0",
                    staticStyle: { "max-width": "180px" },
                  },
                  [
                    _c(
                      "nav",
                      {
                        staticClass:
                          "navbar bg-secondary nav-tab-side flex-column h-100 p-0",
                      },
                      [
                        _vm.page != null
                          ? _c(
                              "ul",
                              {
                                staticClass:
                                  "navbar-nav w-100 flex-column h-100",
                              },
                              [
                                _vm._l(_vm.scores, function (metric, index) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass:
                                        "nav-item flex-fill d-flex align-items-center justify-content-center",
                                      class: _vm.getTabClass(
                                        metric.is_tab_active,
                                        metric.grade
                                      ),
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "nav-link w-100 h-100 d-flex flex-column justify-content-center align-items-center metric-tab",
                                          attrs: {
                                            href: "#",
                                            role: "button",
                                            "aria-expanded": "false",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.setActiveTab(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "h6" }, [
                                            _vm._v(_vm._s(metric.metric_name)),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "h3",
                                            {
                                              staticClass: "mb-2 text-center",
                                              class: _vm.getGradeClass(
                                                metric.grade,
                                                index
                                              ),
                                              attrs: {
                                                "data-bs-toggle": "tooltip",
                                                title:
                                                  _vm.getTooltipTextByGrade(
                                                    metric.grade
                                                  ),
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "m-0 fa-xs fa-star",
                                                class:
                                                  metric.grade == "4" ||
                                                  metric.grade == "3" ||
                                                  metric.grade == "2" ||
                                                  metric.grade == "1"
                                                    ? "fa-solid"
                                                    : "fa-regular",
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "m-0 fa-xs fa-star",
                                                class:
                                                  metric.grade == "4" ||
                                                  metric.grade == "3" ||
                                                  metric.grade == "2"
                                                    ? "fa-solid"
                                                    : "fa-regular",
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "m-0 fa-xs fa-star",
                                                class:
                                                  metric.grade == "4" ||
                                                  metric.grade == "3"
                                                    ? "fa-solid"
                                                    : "fa-regular",
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "m-0 fa-xs fa-star",
                                                class:
                                                  metric.grade == "4"
                                                    ? "fa-solid"
                                                    : "fa-regular",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "badge",
                                              class: _vm.getGradeLabelClass(
                                                metric.grade,
                                                index
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("percentage")(
                                                    metric.modified_change
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    metric.modified_change < 0
                                                      ? "decrease"
                                                      : metric.modified_change >
                                                        0
                                                      ? "increase"
                                                      : "change"
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "nav-item flex-fill d-flex align-items-center justify-content-center",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "nav-link w-100 h-100 d-flex flex-column justify-content-center align-items-center",
                                        attrs: {
                                          role: "button",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _c("label", [_vm._v("Awareness")]),
                                        _vm._v(" "),
                                        _c(
                                          "h1",
                                          {
                                            staticClass: "mb-0",
                                            class: _vm.getGradeClass("2"),
                                          },
                                          [_vm._v("–")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "badge",
                                            class: _vm.getGradeLabelClass("2"),
                                          },
                                          [_vm._v("Coming Soon")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _c(
                              "ul",
                              {
                                staticClass:
                                  "navbar-nav w-100 flex-column h-100",
                              },
                              _vm._l(_vm.metrics, function (metric, index) {
                                return _c(
                                  "li",
                                  {
                                    staticClass:
                                      "nav-item flex-fill d-flex align-items-center justify-content-center",
                                    class: _vm.getTabClass(index == 0, "C"),
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "nav-link w-100 h-100 d-flex flex-column justify-content-center align-items-center",
                                        attrs: {
                                          href: "#",
                                          role: "button",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _c("label", [_vm._v(_vm._s(metric))]),
                                        _vm._v(" "),
                                        _vm._m(6, true),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "badge text-neutral" },
                                          [_vm._v("Loading")]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.page != null
                  ? _c(
                      "div",
                      { staticClass: "flex-fill p-0" },
                      _vm._l(_vm.scores, function (score, index) {
                        return score.is_tab_active
                          ? _c(
                              "div",
                              {
                                key: score.id,
                                staticClass: "card position-relative",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "card-body" },
                                  [
                                    _c("scorecard-projection-chart", {
                                      attrs: { data: score },
                                      on: {
                                        change: function ($event) {
                                          return _vm.recalculateFY(index)
                                        },
                                      },
                                      model: {
                                        value: score.modified_change,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            score,
                                            "modified_change",
                                            $$v
                                          )
                                        },
                                        expression: "score.modified_change",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  : _c("div", { staticClass: "flex-fill p-0" }, [_vm._m(7)]),
              ]
            ),
            _vm._v(" "),
            _vm.page != null
              ? _c("div", { staticClass: "row mb-5" }, [
                  _c("div", { staticClass: "mb-3 col-xl-3 col" }, [
                    _c(
                      "div",
                      { staticClass: "card box-content report-preview-card" },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            { staticClass: "report-preview-card--text" },
                            [
                              _c("h3", { staticClass: "pt-2" }, [
                                _vm._v(
                                  _vm._s(_vm.one_year.label) + " Projections "
                                ),
                                _vm._m(8),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "mb-3" }, [
                                _vm._v(
                                  "Based upon the adjusted metrics above, we have projected how the upcoming time period will end. "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row d-flex justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col d-flex flex-column" },
                                    [
                                      _c("h5", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency_abbr")(
                                              _vm.one_year.revenue
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [_vm._v("Revenue")]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col d-flex flex-column border-start px-3",
                                    },
                                    [
                                      _c("h5", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("num_abbr")(
                                              _vm.one_year.donors
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [_vm._v("Donors")]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col d-flex flex-column border-start px-3",
                                    },
                                    [
                                      _c("h5", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.one_year.rev_per_donor
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [_vm._v("Rev / Donor")]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.three_year.active_chart != null
                    ? _c("div", { staticClass: "mb-3 col-xl-9 col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card box-content report-preview-card",
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-shrink-2 chart",
                                    staticStyle: { "min-width": "350px" },
                                  },
                                  [
                                    _c("chart-template", {
                                      staticClass: "full-width-chart-preview",
                                      attrs: {
                                        chart: _vm.three_year.active_chart,
                                        id: "summary_chart",
                                        if_benchmark_report: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-preview-card--text flex-fill ms-4",
                                  },
                                  [
                                    _vm._m(9),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "mb-3" }, [
                                      _vm._v(
                                        "Using the estimates above, Avid has created projections for the next three years. Below is a comparison of these three year projections and the actual return from the previous three years."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row d-flex justify-content-between",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col d-flex flex-column",
                                            attrs: { role: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.three_year.active_chart =
                                                  _vm.three_year.revenue_chart
                                              },
                                            },
                                          },
                                          [
                                            _c("h5", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency_abbr")(
                                                    _vm.three_year.revenue
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.three_year.revenue > 0
                                                    ? "Increase"
                                                    : "Decrease"
                                                ) + " in 3-Year Revenue"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col d-flex flex-column border-start px-3",
                                            attrs: { role: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.three_year.active_chart =
                                                  _vm.three_year.donor_chart
                                              },
                                            },
                                          },
                                          [
                                            _c("h5", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("num_abbr")(
                                                    _vm.three_year.donors
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.three_year.donors > 0
                                                    ? "Increase"
                                                    : "Decrease"
                                                ) +
                                                  " in Total Donors over 3 Years"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-5 text-center" }, [
        _c("p", { staticClass: "mb-1" }, [
          _vm._v("Current Filters Applied: "),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.filter_description) },
          }),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Want to limit the data in the scorecard? "),
          _c(
            "a",
            {
              staticClass: "text-decoration-underline",
              attrs: { href: "/user/" + this.client.url + "/scorecard" },
            },
            [_vm._v("Adjust your settings here.")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex-shrink-1 insights-dashboard-group-icon mt-2 me-2" },
      [
        _c("img", {
          staticClass: "circle-icon bg-secondary",
          attrs: { src: "/img/icons/dialexa-icons/warning-circle.svg" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "h5 text-nowrap" }, [
      _vm._v("Overall Rating "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.avidai.com/knowledge-base/scorecard-grading",
          },
        },
        [
          _c("i", {
            staticClass: "fas fa-question-circle text-primary",
            attrs: {
              "data-bs-toggle": "tooltip",
              title:
                "The grades are based upon a weighted improvement average compared to the prior period. Click on this icon to learn more about the grading.",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "overall-score d-flex align-items-center justify-content-center text-center w-100 active-tab",
      },
      [
        _c("div", [
          _c("span", { staticClass: "h6 text-nowrap" }, [
            _vm._v("Overall Rating "),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "https://www.avidai.com/knowledge-base/scorecard-grading",
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-question-circle text-primary",
                  attrs: {
                    "data-bs-toggle": "tooltip",
                    title:
                      "The grades are based upon a weighted improvement average compared to the prior period. Click on this icon to learn more about the grading.",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mb-0 text-center text-neutral" }, [
            _c(
              "div",
              { staticClass: "spinner-border", attrs: { role: "status" } },
              [
                _c("span", { staticClass: "visually-hidden" }, [
                  _vm._v("Loading..."),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "flex-fill p-3 d-flex align-items-center justify-content-center position-relative",
      },
      [
        _c("div", { staticClass: "asset-overlay" }, [
          _c("div", {
            staticClass: "overlay",
            staticStyle: {
              "border-bottom-left-radius": "0",
              "border-top-left-radius": "0",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center text-center w-100 h-100 position-absolute",
              staticStyle: { "z-index": "1000", top: "0", bottom: "0" },
            },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "spinner-border text-white me-2",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "visually-hidden" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("h5", { staticClass: "text-white mt-2" }, [
                  _vm._v("Loading..."),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex-shrink-1 insights-dashboard-group-icon mt-2 me-2" },
      [
        _c("img", {
          staticClass: "circle-icon bg-tertiary",
          attrs: { src: "/img/icons/dialexa-icons/clock-tertiary.svg" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-inline-block spinner-border spinner-border-sm ms-2",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "mb-0 text-neutral" }, [
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }, [
        _c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card position-relative" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "asset-overlay" }, [
          _c("div", {
            staticClass: "overlay",
            staticStyle: {
              "border-bottom-left-radius": "0",
              "border-top-left-radius": "0",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "cta" }, [
            _c("div", { staticClass: "row d-flex align-items-center mx-3" }, [
              _c("div", { staticClass: "col-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "spinner-border text-white",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "visually-hidden" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("h5", { staticClass: "text-white mt-2" }, [
                  _vm._v("Loading..."),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("sup", [
      _c("i", {
        staticClass: "fas fa-exclamation-circle text-primary",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Projections are based upon an analysis of the  past three years. Avid AI does not guarantee these results.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "pt-2" }, [
      _vm._v("Three-Year Projection Comparison "),
      _c("sup", [
        _c("i", {
          staticClass: "fas fa-exclamation-circle text-primary",
          attrs: {
            "data-bs-toggle": "tooltip",
            title:
              "Projections are based upon an analysis of the  past three years. Avid AI does not guarantee these results.",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }