<template>
    <div id="org_settings_prioritization">
        <h4>Insight Prioritization for {{ client.name }}</h4>
        <p>The Insights dashboard can be tailored to the channels and user segments that you care most about. Use the inputs below to ensure that your dashboard provides you with the insights that are most important to you.</p>
        <p class="mb-4">This prioritization will only apply to your user; each user can make adjustments based upon their own preferences.</p>
        <div v-if="user_prioritization.length == 0">
            <h5 class="d-flex align-items-center">
                <div class="spinner-border text-primary me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading Prioritization Options
            </h5>
        </div>
        <div class="row" v-for="p in user_prioritization" v-else>
            <div class="col mb-3">
                <h5>{{p.label}}: {{ p.text }}</h5>
                <div class="d-flex">
                    <div  class="d-flex justify-content-center align-items-center "><label class="form-label mb-0">Least Important</label></div>
                    <div class="flex-grow-1 mx-2">
                        <vue-slider v-model="p.value" :tooltip-formatter="val => Math.round(p.value*100) +'%'" :min="0.1" :max="1" :interval="0.1"/>
                    </div>
                    <div class="d-flex justify-content-center align-items-center " ><label class="form-label mb-0">Most Important</label></div>
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-primary mt-3" @click.prevent="update" :disabled="form.busy"  v-if="user_prioritization.length > 0">
            <i class="fa fa-btn fa-save"></i> Save Changes
        </button>

    </div>
</template>

<script>
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'
    export default {
        components: {
            VueSlider,
        },
        props: ['client'],
        data() {
            return {
                local_client:{},
                form: {
                    busy: false,
                    error: false
                },
                user_prioritization: []
            };
        },
        beforeMount() {
            this.local_client = this.client;
            this.getPrioritizationOptions();
        },
        methods: {

            getPrioritizationOptions() {
                window.axios.post('/api/bigquery/' + this.client.url + '/prioritization_columns', [])
                  .then(response => {
                        this.user_prioritization = response.data.options;
                  });
            },
            update() {
                var self = this;
                this.form.busy = true;

                if(this.user_prioritization.length > 0)
                    this.local_client.prioritization_preference = this.user_prioritization;

                    window.axios.put('/api/client/' + this.local_client.id, this.local_client)
                    .then(response => {
                        self.form.busy = false;
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Prioritization Updated!</h4>',
                            target: '#org_settings_prioritization',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            }
                        });
                    }).catch(error =>{
                        self.form.busy = false;
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Error on updating prioritization</h4>',
                            target: '#org_settings_prioritization',
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-danger'
                            },
                        });
                    
                });
            },
        }
    }
</script>
