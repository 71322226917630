var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "initiative-results" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-9 col-sm-8" }, [
          _c("h4", [_vm._v(_vm._s(_vm.initiative.title))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col text-sm-end mb-3" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: {
                href:
                  "/results/" +
                  _vm.client.url +
                  "/costs_results/" +
                  _vm.initiative.url,
              },
            },
            [_vm._v("Edit Costs / Results")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row show-tables",
          on: {
            click: function ($event) {
              return _vm.showTable()
            },
          },
        },
        _vm._l(_vm.metrics, function (m) {
          return _vm.hasMetric(m)
            ? _c(
                "div",
                {
                  staticClass: "col-sm-4 col-lg-3 col-xl mb-3 scorecard",
                  class: _vm.isVisible(m) ? "" : "hidden",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "close btn",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.customizeVisibility(m)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "card box-content" }, [
                    _c("canvas", {
                      ref: m + "_chart",
                      refInFor: true,
                      staticClass: "tiny-chart",
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("h5", { staticClass: "text-muted" }, [
                        _c("img", {
                          staticClass: "icon me-2",
                          attrs: { src: _vm.getImgSrc(m) },
                        }),
                        _vm._v(_vm._s(_vm._f("propercase")(m))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-info" }, [
                        _c("h4", { staticClass: "card-title mb-0 me-2" }, [
                          m == "all_revenue" ||
                          m == "project_cost" ||
                          m == "media_cost"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency_with_zero")(
                                      _vm.getMetricLabel(m).actuals
                                    )
                                  )
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("number_with_zero")(
                                      _vm.getMetricLabel(m).actuals
                                    )
                                  )
                                ),
                              ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e()
        }),
        0
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.show_table
          ? _c("div", { staticClass: "table-container mt-3" }, [
              _c("div", { staticClass: "card box-content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-body p-0 table-responsive text-nowrap stat-table-holder",
                  },
                  _vm._l(_vm.all_metrics, function (m) {
                    return _vm.hasMetric(m) && _vm.isVisible(m)
                      ? _c("table", { staticClass: "table table-sm" }, [
                          _c("thead", { staticClass: "table-dark" }, [
                            _c(
                              "tr",
                              [
                                _c(
                                  "th",
                                  {
                                    staticClass: "ps-3",
                                    attrs: { scope: "col" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("propercase")(
                                          m == "revenue"
                                            ? "One-Time Revenue"
                                            : m
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.headers, function (h) {
                                  return _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v(_vm._s(h)),
                                  ])
                                }),
                                _vm._v(" "),
                                _c("th", [_vm._v("Total")]),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c(
                              "tr",
                              [
                                _c("td", { staticClass: "ps-3" }, [
                                  _vm._v("Projections"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.getDateArray(), function (dte) {
                                  return _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(
                                          m,
                                          _vm.initiative[m].projections[dte]
                                        )
                                      )
                                    ),
                                  ])
                                }),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatNumber(
                                        m,
                                        _vm.getTotal(
                                          _vm.initiative[m].projections
                                        )
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "tr",
                              [
                                _c("td", { staticClass: "ps-3" }, [
                                  _vm._v("Actuals"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.getDateArray(), function (dte) {
                                  return _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(
                                          m,
                                          _vm.initiative[m].actuals[dte]
                                        )
                                      )
                                    ),
                                  ])
                                }),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatNumber(
                                        m,
                                        _vm.getTotal(_vm.initiative[m].actuals)
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "tr",
                              [
                                _c("td", { staticClass: "ps-3" }, [
                                  _vm._v("Variance"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.getDateArray(), function (dte) {
                                  return _c(
                                    "td",
                                    {
                                      class: _vm.getVarianceClass(
                                        _vm.initiative[m].variance[dte]
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("percentage")(
                                            _vm.initiative[m].variance[dte]
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    class: _vm.getVarianceClass(
                                      _vm.getTotalVariance(_vm.initiative[m])
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(
                                          _vm.getTotalVariance(
                                            _vm.initiative[m]
                                          )
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e()
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }