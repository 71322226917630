var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "assumptions" }, [
    _vm.plan.is_published_plan == 1
      ? _c("div", { staticClass: "alert alert-warning mb-3 box-content" }, [
          _vm._v("\n        The plan being view is currently the "),
          _c("em", [_vm._v("published plan")]),
          _vm._v(
            ". This means you are not able to make any adjustments to the assumptions or initatives. If you'd like to make any updates, "
          ),
          _c(
            "a",
            { attrs: { href: "/planning/" + _vm.client.url + "/versions" } },
            [
              _vm._v(
                "you will need to make a new version. You can do that here."
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [
          _vm._v("Assumptions for "),
          _c("em", { domProps: { innerHTML: _vm._s(_vm.plan.name) } }),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Starting Month of Plan"),
                ]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: {
                    "minimum-view": "month",
                    "use-utc": true,
                    format: "MMM yyyy",
                    "input-class": "form-control",
                    disabled: _vm.plan.is_published_plan == 1,
                  },
                  model: {
                    value: _vm.plan.start_month,
                    callback: function ($$v) {
                      _vm.$set(_vm.plan, "start_month", $$v)
                    },
                    expression: "plan.start_month",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5 offset-sm-1" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Average Gift"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.avg_gift,
                        expression: "plan.avg_gift",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.avg_gift },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      paste: function ($event) {
                        return _vm.stripNonNumbers($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.plan, "avg_gift", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.avg_gift,
                        expression: "actuals.avg_gift",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.avg_gift },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.actuals, "avg_gift", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Average New Donor Gift"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.new_donor_avg_gift,
                        expression: "plan.new_donor_avg_gift",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.new_donor_avg_gift },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "new_donor_avg_gift",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.new_donor_avg_gift,
                        expression: "actuals.new_donor_avg_gift",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.new_donor_avg_gift },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "new_donor_avg_gift",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5 offset-sm-1" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Average Recurring Gift"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.recur_avg_gift,
                        expression: "plan.recur_avg_gift",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.recur_avg_gift },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "recur_avg_gift",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.recur_avg_gift,
                        expression: "actuals.recur_avg_gift",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.recur_avg_gift },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "recur_avg_gift",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("New Subscriber Annual Attrition"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.new_subscriber_attrition,
                        expression: "plan.new_subscriber_attrition",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.new_subscriber_attrition },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "new_subscriber_attrition",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("%"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.new_subscriber_attrition,
                        expression: "actuals.new_subscriber_attrition",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "text-align": "right" },
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.new_subscriber_attrition },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "new_subscriber_attrition",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("%"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5 offset-sm-1" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Existing Subscriber Annual Attrition"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.existing_subscriber_attrition,
                        expression: "plan.existing_subscriber_attrition",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.existing_subscriber_attrition },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "existing_subscriber_attrition",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("%"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.existing_subscriber_attrition,
                        expression: "actuals.existing_subscriber_attrition",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "text-align": "right" },
                    attrs: { type: "text", disabled: "" },
                    domProps: {
                      value: _vm.actuals.existing_subscriber_attrition,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "existing_subscriber_attrition",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("%"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Active Email File Size"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.starting_email_size,
                        expression: "plan.starting_email_size",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.starting_email_size },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "starting_email_size",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.starting_email_size,
                        expression: "actuals.starting_email_size",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.starting_email_size },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "starting_email_size",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5 offset-sm-1" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Previous Year's Donors with Emails"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.existing_donors,
                        expression: "plan.existing_donors",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.existing_donors },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "existing_donors",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.existing_donors,
                        expression: "actuals.existing_donors",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "true" },
                    domProps: { value: _vm.actuals.existing_donors },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "existing_donors",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Lifetime Value"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.lifetime_value,
                        expression: "plan.lifetime_value",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.lifetime_value },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "lifetime_value",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("$"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.lifetime_value,
                        expression: "actuals.lifetime_value",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.lifetime_value },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "lifetime_value",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5 offset-sm-1" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Donor Retention"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.donor_retention,
                        expression: "plan.donor_retention",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.donor_retention },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "donor_retention",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.donor_retention,
                        expression: "actuals.donor_retention",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.donor_retention },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "donor_retention",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-sm-5" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Avg. Rev. per Donor:"),
                ]),
                _vm._v(" "),
                _c("em", [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.actuals.rev_per_donor))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Attrition Rate:"),
                ]),
                _vm._v(" "),
                _c("em", [
                  _vm._v(
                    _vm._s(_vm._f("percentage")(_vm.actuals.attrition_rate))
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-5 offset-sm-1" }, [
            _c("label", { staticClass: "form-label mb-0" }, [
              _vm._v("Annual Recurring Donor Attrition"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Override"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.plan.recurring_attrition,
                        expression: "plan.recurring_attrition",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      type: "text",
                      disabled: _vm.plan.is_published_plan == 1,
                    },
                    domProps: { value: _vm.plan.recurring_attrition },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.plan,
                          "recurring_attrition",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("%"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("label", { staticClass: "floating_label" }, [
                  _vm._v("Actual"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actuals.recurring_attrition,
                        expression: "actuals.recurring_attrition",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "text-align": "right" },
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.actuals.recurring_attrition },
                    on: {
                      change: function ($event) {
                        return _vm.stripNonNumbers()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.actuals,
                          "recurring_attrition",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("%"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("h4", [_vm._v("Data Inclusion Filter")]),
          _vm._v(" "),
          _c("query-inclusion-builder", {
            ref: "filter",
            attrs: { client: _vm.client, in_card: true, add_blank: false },
            model: {
              value: _vm.plan.data_filter,
              callback: function ($$v) {
                _vm.$set(_vm.plan, "data_filter", $$v)
              },
              expression: "plan.data_filter",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.successful
          ? _c("div", { staticClass: "alert alert-success mb-3" }, [
              _vm._v(
                "\n                Your information has been updated!\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
              _vm._v(
                "\n                There was an error saving your information. Please make the corrections noted above.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "submit",
                disabled: _vm.form.busy || _vm.plan.is_published_plan == 1,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(" Save\n                "),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              staticStyle: {},
              attrs: {
                type: "button",
                disabled: _vm.form.busy || _vm.plan.is_published_plan == 1,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.getManualActuals.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-sync" }),
              _vm._v(" Refresh Stats\n                "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("The data shown in the "),
      _c("em", [_vm._v("Actual")]),
      _vm._v(
        " fields are pulled from the transaction data. The data can be filtered using the filters below. To change the dataset, you will need to update the client information in the organization's Profile page."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }