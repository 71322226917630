var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "onboarding-personal-info" }, [
    _c("h2", { staticClass: "mt-2 mb-3" }, [
      _vm._v("Step 1: Personal Information"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mb-3 col-6" }, [
        _c("label", [_vm._v("First Name *")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.new_user.first_name,
              expression: "new_user.first_name",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors.first_name != "" ? "is-invalid" : "",
          attrs: { type: "text", name: "first_name" },
          domProps: { value: _vm.new_user.first_name },
          on: {
            change: function ($event) {
              _vm.errors.first_name = ""
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.new_user, "first_name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.first_name != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.first_name) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3 col-6" }, [
        _c("label", [_vm._v("Last Name *")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.new_user.last_name,
              expression: "new_user.last_name",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors.last_name != "" ? "is-invalid" : "",
          attrs: { type: "text", name: "last_name" },
          domProps: { value: _vm.new_user.last_name },
          on: {
            change: function ($event) {
              _vm.errors.last_name = ""
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.new_user, "last_name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.last_name != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.last_name) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3 col-12" }, [
        _c("label", [_vm._v("Email *")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.new_user.email,
              expression: "new_user.email",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors.email != "" ? "is-invalid" : "",
          attrs: { type: "text", name: "email" },
          domProps: { value: _vm.new_user.email },
          on: {
            change: function ($event) {
              return _vm.validateEmail()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.new_user, "email", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.email != ""
          ? _c("div", {
              staticClass: "text-danger invalid-feedback",
              domProps: { innerHTML: _vm._s(_vm.errors.email) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3 col-12" }, [
        _c("label", [_vm._v("Password *")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          (_vm.new_user.password.type == "password" ? "password" : "text") ===
          "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.new_user.password.value,
                    expression: "new_user.password.value",
                  },
                ],
                ref: "password",
                staticClass: "form-control password-input input_v1",
                class: _vm.errors.password != "" ? "is-invalid" : "",
                attrs: {
                  "aria-label": "Password",
                  "aria-describedby": "password-addon",
                  autocomplete: "off",
                  name: "password",
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.new_user.password.value)
                    ? _vm._i(_vm.new_user.password.value, null) > -1
                    : _vm.new_user.password.value,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.new_user.password.value,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.new_user.password,
                              "value",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.new_user.password,
                              "value",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.new_user.password, "value", $$c)
                      }
                    },
                    function ($event) {
                      _vm.errors.password = ""
                    },
                  ],
                },
              })
            : (_vm.new_user.password.type == "password"
                ? "password"
                : "text") === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.new_user.password.value,
                    expression: "new_user.password.value",
                  },
                ],
                ref: "password",
                staticClass: "form-control password-input input_v1",
                class: _vm.errors.password != "" ? "is-invalid" : "",
                attrs: {
                  "aria-label": "Password",
                  "aria-describedby": "password-addon",
                  autocomplete: "off",
                  name: "password",
                  type: "radio",
                },
                domProps: {
                  checked: _vm._q(_vm.new_user.password.value, null),
                },
                on: {
                  change: [
                    function ($event) {
                      return _vm.$set(_vm.new_user.password, "value", null)
                    },
                    function ($event) {
                      _vm.errors.password = ""
                    },
                  ],
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.new_user.password.value,
                    expression: "new_user.password.value",
                  },
                ],
                ref: "password",
                staticClass: "form-control password-input input_v1",
                class: _vm.errors.password != "" ? "is-invalid" : "",
                attrs: {
                  "aria-label": "Password",
                  "aria-describedby": "password-addon",
                  autocomplete: "off",
                  name: "password",
                  type:
                    _vm.new_user.password.type == "password"
                      ? "password"
                      : "text",
                },
                domProps: { value: _vm.new_user.password.value },
                on: {
                  change: function ($event) {
                    _vm.errors.password = ""
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.new_user.password,
                      "value",
                      $event.target.value
                    )
                  },
                },
              }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "input-group-text",
              staticStyle: {
                "border-left": "none",
                "background-color": "white",
              },
              attrs: {
                title: !_vm.new_user.password.visible
                  ? "Show password"
                  : "Hide password",
                id: "password-addon",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleVisibility(_vm.new_user.password)
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa",
                class: [
                  !_vm.new_user.password.visible ? "fa-eye" : "fa-eye-slash",
                ],
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.errors.password != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.password) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3 col-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary w-100 mt-2",
            attrs: { type: "submit", disabled: _vm.processing },
            on: {
              click: function ($event) {
                return _vm.nextStep()
              },
            },
          },
          [
            _vm.processing
              ? _c("span", [
                  _c("div", {
                    staticClass:
                      "spinner-border spinner-border-sm text-white float-left",
                    attrs: { role: "status" },
                  }),
                  _vm._v("Processing\n                "),
                ])
              : _c("span", [_vm._v("Sign Up")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", { staticClass: "text-center" }, [_vm._v("Or Sign Up Using:")]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-row gap-2" }, [
          _c(
            "a",
            {
              staticClass: "btn w-100",
              class: _vm.error.google != null ? "btn-error" : "btn-secondary ",
              attrs: {
                href: "/auth/register/google",
                disabled: _vm.processing,
              },
            },
            [
              _c("i", { staticClass: "fa-brands fa-google me-2" }),
              _vm._v(" Google\n                "),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.error.google != null
          ? _c(
              "span",
              {
                staticClass: "invalid-feedback mt-2",
                attrs: { role: "alert" },
              },
              [
                _c("strong", {
                  domProps: { innerHTML: _vm._s(_vm.error.google[0]) },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("hr"),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Already have an account?\n        "),
      _c("a", { staticClass: "btn btn-link ps-0", attrs: { href: "/login" } }, [
        _vm._v("\n            Log In\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }