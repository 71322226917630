<template>
    <div class="insights-dashboard scorecard-page pt-2 mb-4">
        <div class="row mx-2 mb-3">
            <div class="p-0">
                <h1>Fundraising Scorecard</h1>
                <p>There are five key aspects for successfully managing a nonprofit fundraising program. Accurately tracking and measuring these are critical to successfully grow the impact of your nonprofit. The metrics below are based upon your personal settings; it should focus on the audiences or channels you care most about. <a :href="'/user/' + this.client.url + '/scorecard'" class="text-decoration-underline"> You can adjust those settings here.</a></p>
            </div>
        </div>

        <div class="row" v-if="page != null && page.sections[0].charts[0].chart.labels[0] == '(No Data)'"><div class="col mb-5">
            <div class="card box-content h-100 w-100 p-3 d-flex flex-row" >
                <div class="flex-shrink-1 insights-dashboard-group-icon mt-2 me-2">
                     <img class="circle-icon bg-secondary" src="/img/icons/dialexa-icons/warning-circle.svg">
                </div>
                <div class="flex-fill p-1">
                    <h3>Invalid Filter Selection</h3>
                    <p>Based upon the filters selected, there are no records that match your criteria. <a :href="'/user/' + this.client.url + '/scorecard'" class="text-decoration-underline"> Please adjust that filter here.</a></p>
                </div>
            </div>
        </div></div>
        <div v-else>
            <div class="row">
            <div class="col-12">
                <div class="float-end navbar navbar-expand primary-tab-bar pb-0">
                    <div class="container-fluid">
                        <div class="collapse navbar-collapse">
                            <div class="btn-group toggle-btn-group mb-4" role="group">
                                <button type="button" class="btn"
                                    v-for="t in time_period_options"
                                    @click="setTimePeriod(t)"
                                    :class="[
                                        current_time_period == t ? 'btn-primary' : 'btn-white',
                                        ifWithinThreeMonths() && t == 'fiscal_year_to_date' ? 'pe-4' : ''
                                    ]"
                                    :disabled="ifWithinThreeMonths() && t == 'fiscal_year_to_date'">
                                    {{t | propercase}}
                                </button>
                            </div>
                            <i class="fas fa-info-circle position-absolute" style="right: 158px; top: 23px;" data-bs-toggle="tooltip" title="The Fiscal Year to Date Analysis is disabled until you are on month #4 of your fiscal year. Any analysis performed prior to this will not have enough data to be accurate." v-if="ifWithinThreeMonths()" ></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row insight-dashboard-info d-flex">
            <div class="col mb-3 d-flex">
                <div class="card box-content h-100 w-100">
                    <div class="d-flex flex-row overflow-hidden h-100" v-if="page != null">
                        <div class="overall-score d-flex align-items-center justify-content-center text-center w-100" :class="getTabClass(true, overall_grade)">
                            <div>
                                <label class="h5 text-nowrap">Overall Rating <a target="_blank" href="https://www.avidai.com/knowledge-base/scorecard-grading"><i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" title="The grades are based upon a weighted improvement average compared to the prior period. Click on this icon to learn more about the grading."></i></a></label>
                                <h2 class="mb-0 text-center" :class="getGradeClass(overall_grade)"  data-bs-toggle="tooltip" :title="getTooltipTextByGrade(overall_grade)">
                                    <i class="m-0 fa-xs fa-star" :class="(overall_grade== '4' || overall_grade== '3' || overall_grade== '2' || overall_grade== '1') ? 'fa-solid': 'fa-regular'"></i>
                                    <i class="m-0 fa-xs fa-star" :class="(overall_grade== '4' || overall_grade== '3' || overall_grade== '2') ? 'fa-solid': 'fa-regular'"></i>
                                    <i class="m-0 fa-xs fa-star" :class="(overall_grade== '4' || overall_grade== '3') ? 'fa-solid': 'fa-regular'"></i>
                                    <i class="m-0 fa-xs fa-star" :class="(overall_grade== '4') ? 'fa-solid': 'fa-regular'"></i>
                                </h2>
                            </div>
                        </div>
                        <div class="flex-fill p-3 d-flex align-items-center justify-content-center ">
                            <div>
                                <h5>Overall Rating Explanation</h5>
                                <div class="mb-0" v-html="getFocusMetric()"></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row overflow-hidden h-100" v-else>
                        <div class="overall-score d-flex align-items-center justify-content-center text-center w-100 active-tab">
                            <div>
                                <span class="h6 text-nowrap">Overall Rating <a target="_blank" href="https://www.avidai.com/knowledge-base/scorecard-grading"><i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" title="The grades are based upon a weighted improvement average compared to the prior period. Click on this icon to learn more about the grading."></i></a></span>
                                <h3 class="mb-0 text-center text-neutral"><div class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span></div></h3>
                            </div>
                        </div>
                        <div class="flex-fill p-3 d-flex align-items-center justify-content-center position-relative">
                            <div class="asset-overlay">
                                <div class="overlay" style="border-bottom-left-radius: 0; border-top-left-radius:0"></div>
                                <div class="d-flex align-items-center justify-content-center text-center w-100 h-100 position-absolute" style="z-index: 1000; top: 0; bottom: 0;">
                                    <div class="d-flex align-items-center">
                                        <div class="spinner-border text-white me-2" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <h5 class="text-white mt-2">Loading...</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 mb-3 d-flex">
                    <div class="card box-content h-100 w-100 p-3 d-flex flex-row">
                        <div class="flex-shrink-1 insights-dashboard-group-icon mt-2 me-2">
                            <img class="circle-icon bg-tertiary" src="/img/icons/dialexa-icons/clock-tertiary.svg">
                        </div>
                        <div class="flex-fill p-1">
                            <h5>Time Period Data</h5>
                            <ul class="mb-0">
                                <li>Time Period: <em>{{client.default_reporting_period}}</em></li>
                                <li>Last Transaction Date: <em>
                                    <span v-if="meta_data.constituent != null">
                                        <span v-if="meta_data.constituent._avid_max_display_date != null && meta_data.constituent._avid_max_display_date.date != null">{{meta_data.constituent._avid_max_display_date.date | date }} </span>
                                        <span v-else-if="meta_data.constituent._avid_max_display_date != null">{{meta_data.constituent._avid_max_display_date | date }} </span>
                                        <span v-else-if="meta_data.constituent._max_overall_donation_date != null">{{meta_data.constituent._max_overall_donation_date.date | date }} </span>
                                    </span>
                                    <span v-else>
                                        <div class="d-inline-block spinner-border spinner-border-sm ms-2" role="status">
                                            <span class="visually-hidden"></span>
                                        </div> Loading
                                    </span></em>
                                </li>
                                <li v-if="meta_data.subscriber != null">Last Email Date: <em>{{ meta_data.subscriber._max_overall_record_date | date }}</em></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-0 mb-3 top-5-metrics box-content d-flex flex-row">
                <div class="flex-shrink-1 p-0" style="max-width:180px;">
                    <nav class="navbar bg-secondary nav-tab-side flex-column h-100 p-0">
                        <ul class="navbar-nav w-100 flex-column h-100" v-if="page != null">
                            <li class="nav-item flex-fill d-flex align-items-center justify-content-center" v-for="(metric, index) in scores" :class="getTabClass(metric.is_tab_active, metric.grade)">
                                <a class="nav-link w-100 h-100 d-flex flex-column justify-content-center align-items-center metric-tab"  href="#" role="button" aria-expanded="false" v-on:click.prevent="setActiveTab(index)">
                                    <span class="h6">{{metric.metric_name}}</span>
                                    <h3 class="mb-2 text-center" :class="getGradeClass(metric.grade, index)" data-bs-toggle="tooltip" :title="getTooltipTextByGrade(metric.grade)">
                                        <i class="m-0 fa-xs fa-star" :class="(metric.grade== '4' || metric.grade== '3' || metric.grade== '2' || metric.grade== '1') ? 'fa-solid': 'fa-regular'"></i>
                                        <i class="m-0 fa-xs fa-star" :class="(metric.grade== '4' || metric.grade== '3' || metric.grade== '2') ? 'fa-solid': 'fa-regular'"></i>
                                        <i class="m-0 fa-xs fa-star" :class="(metric.grade== '4' || metric.grade== '3') ? 'fa-solid': 'fa-regular'"></i>
                                        <i class="m-0 fa-xs fa-star" :class="(metric.grade== '4') ? 'fa-solid': 'fa-regular'"></i>
                                    </h3>
                                    <label class="badge" :class="getGradeLabelClass(metric.grade, index)">{{ metric.modified_change | percentage}} {{ (metric.modified_change < 0 ? "decrease" : (metric.modified_change> 0 ? "increase" : "change")) }}</label>
                                </a>
                            </li>
                            <li class="nav-item flex-fill d-flex align-items-center justify-content-center">
                                <span class="nav-link w-100 h-100 d-flex flex-column justify-content-center align-items-center"   role="button" aria-expanded="false">
                                    <label>Awareness</label>
                                    <h1 class="mb-0" :class="getGradeClass('2')">&ndash;</h1>
                                    <label class="badge" :class="getGradeLabelClass('2')">Coming Soon</label>
                                </span>
                            </li>
                        </ul>
                        <ul class="navbar-nav w-100 flex-column h-100"  v-else>
                            <li class="nav-item flex-fill d-flex align-items-center justify-content-center" v-for="(metric, index) in metrics" :class="getTabClass(index == 0, 'C')">
                                <a class="nav-link w-100 h-100 d-flex flex-column justify-content-center align-items-center"  href="#" role="button" aria-expanded="false">
                                    <label>{{metric}}</label>
                                    <h3 class="mb-0 text-neutral"><div class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span></div></h3>
                                    <label class="badge text-neutral">Loading</label>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>


                <div class=" flex-fill p-0" v-if="page != null">
                    <div class="card position-relative" v-for="(score, index) in scores" v-if="score.is_tab_active" :key="score.id">
                        <div class="card-body">
                            <scorecard-projection-chart :data="score" v-model="score.modified_change"
                                @change="recalculateFY(index)"></scorecard-projection-chart>
                        </div>
                    </div>
                </div>
                <div class=" flex-fill p-0" v-else>
                    <div class="card position-relative" >
                        <div class="card-body"  >
                            <div class="asset-overlay">
                                <div class="overlay" style="border-bottom-left-radius: 0; border-top-left-radius:0"></div>
                                <div class="cta">
                                    <div class="row d-flex align-items-center mx-3">
                                        <div class="col-2">
                                            <div class="spinner-border text-white" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <h5 class="text-white mt-2">Loading...</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row mb-5" v-if="page != null" >
                <div class="mb-3 col-xl-3 col">
                    <div class="card box-content report-preview-card">
                        <div class="card-body">
                            <div class=" report-preview-card--text">
                                <h3 class="pt-2">{{ one_year.label }} Projections <sup><i class="fas fa-exclamation-circle text-primary" data-bs-toggle="tooltip" title="Projections are based upon an analysis of the  past three years. Avid AI does not guarantee these results."></i></sup></h3>
                                <p class="mb-3">Based upon the adjusted metrics above, we have projected how the upcoming time period will end. </p>
                                <div class="row d-flex justify-content-between">
                                    <div class="col d-flex flex-column">
                                        <h5 class="mb-0">{{ one_year.revenue | currency_abbr }}</h5>
                                        <small>Revenue</small>
                                    </div>
                                    <div class="col d-flex flex-column border-start px-3">
                                        <h5 class="mb-0">{{ one_year.donors | num_abbr }}</h5>
                                        <small>Donors</small>
                                    </div>
                                    <div class="col d-flex flex-column border-start px-3">
                                        <h5 class="mb-0">{{ one_year.rev_per_donor | currency }}</h5>
                                        <small>Rev / Donor</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-xl-9 col" v-if="three_year.active_chart != null">
                    <div class="card box-content report-preview-card">
                        <div class="card-body">
                            <div class=" d-flex flex-row">
                                <div class="flex-shrink-2 chart" style="min-width: 350px;" >
                                    <chart-template class="full-width-chart-preview" :chart="three_year.active_chart" :id="'summary_chart'" :if_benchmark_report="true"></chart-template>
                                </div>
                                <div class=" report-preview-card--text flex-fill ms-4">
                                    <h3 class="pt-2">Three-Year Projection Comparison <sup><i class="fas fa-exclamation-circle text-primary" data-bs-toggle="tooltip" title="Projections are based upon an analysis of the  past three years. Avid AI does not guarantee these results."></i></sup></h3>
                                    <p class="mb-3">Using the estimates above, Avid has created projections for the next three years. Below is a comparison of these three year projections and the actual return from the previous three years.</p>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col d-flex flex-column" @click="three_year.active_chart = three_year.revenue_chart"  role="button">
                                            <h5 class="mb-0">{{ three_year.revenue | currency_abbr}}</h5>
                                            <small>{{three_year.revenue > 0 ? 'Increase' : 'Decrease'}} in 3-Year Revenue</small>
                                        </div>
                                        <div class="col d-flex flex-column border-start px-3" @click="three_year.active_chart = three_year.donor_chart"  role="button">
                                            <h5 class="mb-0">{{ three_year.donors | num_abbr}}</h5>
                                            <small>{{three_year.donors > 0 ? 'Increase' : 'Decrease'}} in Total Donors over 3 Years</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5 text-center">
            <p class="mb-1">Current Filters Applied: <span v-html="filter_description"></span></p>
            <p>Want to limit the data in the scorecard? <a :href="'/user/' + this.client.url + '/scorecard'" class="text-decoration-underline">Adjust your settings here.</a></p>
        </div>
    </div>
</template>


<script>
    export default {
        props: ['client', 'user', 'time_period'],
        data() {
            return {
                page: null,
                filter_description: '<em>None</em>',
                overall_grade:'',
                scores:[],
                meta_data: [],
                time_period_options:['fiscal_year_to_date', 'trailing_12_months'],
                current_time_period: 'fiscal_year_to_date',
                one_year: {
                    'label': 'FY2024',
                    'revenue': 0,
                    'donors': 0,
                    'rev_per_donor': 0
                },
                three_year: {
                    active_chart: null,
                    donor_chart: null,
                    revenue_chart: null,
                    donors: 0,
                    revenue: 0
                },
                metrics: ['Retention', 'Acquisition', 'Reactivation', 'Upgrade', 'Awareness'],
                if_metric_modified: [false, false, false, false, false]
            };
        },
        beforeMount() {

            //If the client.fiscal_year_offset (representing the number of months from jan) is within 3 months of the current month
            if(this.ifWithinThreeMonths())
                this.current_time_period = 'trailing_12_months';
            else
                this.current_time_period = this.time_period;
            this.loadScorecard();
            this.loadMetaData();
        },
        mounted() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
            for (var i = 0; i < tooltipTriggerList.length; i++) {
                (function(index) {
                    tooltipTriggerList[index].addEventListener('click', function() {
                    // Hide the tooltip using the index passed to the closure
                    tooltipList[index].hide();
                    });
                    tooltipTriggerList[index].addEventListener('mouseleave', function() {
                    // Hide the tooltip using the index passed to the closure
                    tooltipList[index].hide();
                    });
                })(i);
            }
        },
        methods: {
            loadScorecard() {

                window.axios.get('/api/scorecard/' + this.client.url + "/charts")
                  .then(response => {
                    //I need to save the section for the takeaways
                    this.page = response.data.page;
                    this.filter_description = response.data.filter_description;
                    this.loadScores();
                    this.$forceUpdate();
                    this.loadLinks();
                });
            },
            ifWithinThreeMonths() {
                 let currentMonth = new Date().getMonth(); // Get the current month (0 for Jan, 11 for Dec)
                let fiscalOffset = this.client.fiscal_year_offset; // Get the fiscal year offset (number of months from Jan)

                // Calculate the difference and handle the wraparound using modulo
                let difference = (currentMonth - fiscalOffset + 12) % 12;

                // Check if the difference is within 3 months (either forward or backward)
                return difference <= 3;
            },
            setTimePeriod(time) {
                this.current_time_period = time;
                this.loadScores();
                this.$forceUpdate();

                var data = {
                    action: 'toggle',
                    client_id: this.client.id,
                    user_id: this.user.id,
                    filter: {
                        time_period: time
                    }
                };
                window.axios.post('/api/customization', data);
            },
            recalculateFY(metric_index) {
                for(let i = 0; i < this.scores.length; i++)
                    this.scores[i].fy_chart = this.prepFY(this.scores[i].orig_chart, this.scores[i].modified_change);

                let rank = this.getGradeRank(metric_index, this.scores[metric_index].modified_change);
                this.scores[metric_index].grade = this.getRating(rank);
                this.scores[metric_index].numerical_grade = rank;

                this.if_metric_modified[metric_index] = true;
                
                this.updateAvailable();
                this.calcYearOneProjections();
                this.prepThreeYear();

                this.$forceUpdate();
            },
            getTabClass(is_active, grade) {
                if(is_active && (grade == '1' || grade == '0'))
                    return 'active-tab danger-tab';
                if(is_active && (grade == '4' || grade == '3'))
                    return 'active-tab success-tab';
                if(is_active)
                    return 'active-tab';
            },
            getGradeClass(grade, metric_index = null) {
                var classnames = '';
                if(grade == '1' || grade == '0')
                    classnames = 'text-danger';
                if(grade == '4' || grade == '3')
                    classnames = 'text-success';
                if(grade == '2')
                    classnames = 'text-neutral';

                if( this.if_metric_modified[metric_index])
                    classnames += ' opacity-75';

                return classnames;
            },
            getGradeLabelClass(grade, metric_index = null) {
                var classnames = '';
                if(grade == '1' || grade == '0')
                    classnames += 'bg-label-danger ';
                if(grade == '4' || grade == '3')
                    classnames += 'bg-label-success ';

                if (metric_index != null && this.if_metric_modified[metric_index])
                    classnames += 'dashed-border-badge';
                return classnames;
            },
            getTooltipTextByGrade(grade){
                let text = '';
                switch (grade) {
                    case '0':
                        text = 'Rubbish';
                        break;
                    case '1':
                        text = 'Subpar';
                        break;
                    case '2':
                        text = 'Unwavering';
                        break;
                    case '3':
                        text = 'Advancing';
                        break;
                    default:
                        text = 'Superb';
                }
                return text;
            },
            loadLinks() {
                window.axios.get('/api/scorecard/' + this.client.url + '/links')
                  .then(response => {
                    //Loop through the response "links"
                    response.data.links.forEach(link => {
                        //Find the scorecard with the metric_name that matches the link
                        let score = this.scores.find(element => element.metric_name == link.metric_name);
                        //Set the link
                        score.link = link.link;
                    });
                    this.$forceUpdate();

                });
            },
            loadMetaData() {
                var data = {
                  client: this.client,
                  blend_type: 'constituent'
                };

                window.axios.post('/api/bigquery/get_metadata', data)
                  .then(response => {
                   this.meta_data['constituent'] = response.data.metadata;
                   this.$forceUpdate();

                });

                var data = {
                  client: this.client,
                  blend_type: 'subscriber'
                };

                window.axios.post('/api/bigquery/get_metadata', data)
                  .then(response => {
                   this.meta_data['subscriber'] = response.data.metadata;
                   this.$forceUpdate();

                });
            },
            loadScores() {
                let old_scores = this.scores;
                this.scores = [];
                for(let i = 0; i < 4; i++) {

                    if(this.current_time_period == 'fiscal_year_to_date') {
                        this.scores.push(
                        {
                            //TODO: Save this to prioritizations
                            id: this.page.sections[0].charts[i].id,
                            description: this.getDescription(this.page.sections[0].charts[i].title),
                            metric_name: this.page.sections[0].charts[i].title,
                            fytd_chart: this.prepFYTD(this.page.sections[0].charts[i]),
                            orig_chart: JSON.parse(JSON.stringify(this.page.sections[1].charts[i])),
                            fy_chart: this.prepFY(this.page.sections[1].charts[i],
                                    this.getPercentChange(this.page.sections[0].charts[i].chart)),
                            percent_change: this.getPercentChange(this.page.sections[0].charts[i].chart),
                            modified_change: this.getPercentChange(this.page.sections[0].charts[i].chart),
                            is_tab_active: i==0,
                            link: null
                        });
                    //Trailing 12 months
                    }
                    else {
                        var chart_num = i;
                        if(this.page && this.page.sections[2].charts[i].title == 'Retention')
                            chart_num = 9
                        if(this.page && this.page.sections[2].charts[i].title == 'Reactivation')
                            chart_num = 10
                        this.scores.push(
                        {
                            //TODO: Save this to prioritizations
                            id: this.page.sections[2].charts[i].id,
                            description: this.getDescription(this.page.sections[2].charts[i].title),
                            metric_name: this.page.sections[2].charts[i].title,
                            fytd_chart: this.prepFYTD(this.page.sections[2].charts[i]),
                            orig_chart: JSON.parse(JSON.stringify(this.page.sections[2].charts[chart_num])),
                            fy_chart: this.prepFY(this.page.sections[2].charts[chart_num],
                                    this.getPercentChange(this.page.sections[2].charts[i].chart)),
                            percent_change: this.getPercentChange(this.page.sections[2].charts[i].chart),
                            modified_change: this.getPercentChange(this.page.sections[2].charts[i].chart),
                            is_tab_active: i==0,
                            link: null
                        });
                    }
                }
                //Copy over the links
                for(let i = 0; i < old_scores.length; i++)
                    this.scores[i].link = old_scores[i].link;
                this.setGrades();
                this.updateAvailable();
                this.calcYearOneProjections();
                this.prepThreeYear();
            },
            getOverallGrade(){
                var total = 0;
                this.scores.forEach(metric => {
                    //This method prevents a really bad F or really good A from skewing everything
                    let grade = metric.numerical_grade;
                    if(grade >= 0.1) {
                        total += 0.15;
                    } else if(grade >= 0.025) {
                        total += 0.0625;
                    } else if(grade >= -.075) {
                        total += -.03;
                    } else if(grade >= -0.15)
                        total += -0.1;
                    else {
                        total += -0.175;
                    }
                });
                this.overall_grade = this.getRating(total / this.scores.length);
                this.$forceUpdate();
            },
            getDescription(metric) {
                if(metric == 'Retention')
                    return "In fundraising, retention of existing donors is more cost-effective than acquiring new supporters, and loyal donors tend to increase their contributions over time. High retention rates also signal strong donor engagement and satisfaction, which are key to long-term financial stability and growth for nonprofit organizations."
                if(metric == "Acquisition")
                    return "Acquisition is essential to a strong fundraising program. Bringing in new donors helps to grow and diversify the donor base, ensuring a steady stream of support for future initiatives. It also offsets natural donor attrition, keeping the organization’s funding pipeline robust and sustainable over time."
                if(metric == "Reactivation")
                    return "Re-engaged lapsed donors are often pre-disposed to give more generously and the efforts are more cost-effective than acquiring new donors. Reactivating lapsed donors also taps into an audience that already understands and supports your organization’s mission, making them more likely to contribute again if approached strategically."
                if(metric == "Upgrade")
                    return "In fundraising, upgrading an existing donor's financial support takes dedicated effort and focused cultivation but the effort is worthwhile. Donor upgrades can significantly boost revenue without the added cost of acquiring new donors, while also deepening donor commitment and investment in the organization’s mission."
                if(metric == 'Awareness')
                    return "In fundraising, awareness is fundamental because it builds public recognition and understanding of an organization’s mission, attracting potential donors and supporters. Increased awareness broadens the reach of fundraising efforts, making it easier to acquire new donors and inspire engagement from a wider audience.";
            },
            getPercentChange(chart) {
                let chart_copy = JSON.parse(JSON.stringify(chart));

                let data = chart_copy.data;
                //get the last element in the object
                let lastElement = data[Object.keys(data)[Object.keys(data).length - 1]];
                //get the last two values in the array
                let lastTwoValues = lastElement.slice(-2);
                //get the percent difference between the two values
                return (lastTwoValues[1] - lastTwoValues[0]) / lastTwoValues[0];
            },
            setGrades() {
                for(let i = 0; i < this.scores.length; i++) {
                    let rank = this.getGradeRank(i);
                    this.scores[i].grade = this.getRating(rank);
                    this.scores[i].numerical_grade = rank;
                }
                this.getOverallGrade();
            },
            getGradeRank(index, percent_change = null) {
                let chart_copy = JSON.parse(JSON.stringify(this.scores[index].fytd_chart.chart));
                if(percent_change == null)
                    var percentDifference = this.getPercentChange(chart_copy);
                else
                    var percentDifference = percent_change;
                
                //Get the second to last element
                let lastElement = chart_copy.data[Object.keys(chart_copy.data)[Object.keys(chart_copy.data).length - 1]];

                var rank = 0;
                //If the last element is less than 1, it is a percentage rate
                if(this.scores[index].metric_name != 'Acquisition'){
                    rank = percentDifference / (1-lastElement[0]) / 2;
                }
                //In the case of new donors
                else {
                    let section_num = (this.current_time_period == 'fiscal_year_to_date' ? 0 : 2);
                    //Need to get active donors from the FYTD (or last year)
                    let scorecard_data = this.page.sections[section_num].charts[8].chart.data[Object.keys(this.page.sections[section_num].charts[8].chart.data)[0]];
                    var total_donors = scorecard_data[scorecard_data.length-1];

                    let new_donors = lastElement[lastElement.length-1];
                    let rate = new_donors / (total_donors);

                    rank = percentDifference / (1-rate) / 2;
                }
                return rank;
            },
            getRating(rank) {
                if(rank >= 0.10) {
                    return '4';
                } else if(rank >= 0.025) {
                    return '3';
                } else if(rank >= -.075) {
                    return '2';
                } else if(rank >= -0.15) {
                    return '1';
                } else {
                    return '0';
                }
            },
            setActiveTab(index){

                this.scores.forEach((metric, i) =>{
                    if (i == index){
                        metric.is_tab_active = true;
                    } else {
                        metric.is_tab_active = false;
                    }
                });
                this.$forceUpdate();
            },
            prepFYTD(orig_chart) {
                let chart = JSON.parse(JSON.stringify(orig_chart));

                //Reduce the data points down to two
                let data = chart.chart.data
                // Loop through each attribute in the object
                Object.keys(data).forEach(key => {
                    if (Array.isArray(data[key])) {
                        // Update the array to keep only the last two elements
                        data[key] = data[key].slice(-2);
                    }
                });
                chart.chart.data = data;

                // Update the labels array
                let labels = chart.chart.labels
                labels = labels.slice(-2);
                chart.chart.labels = labels;

                return chart;
            },
            prepFY(orig_chart, percent_change) {
                let chart = JSON.parse(JSON.stringify(orig_chart));
                let data = chart.chart.data;

                //If the object has two keys, it is a percent vs available graph
                if (Object.keys(data).length === 2) {
                    // Loop through each attribute in the object to find the percent
                    Object.keys(data).forEach(key => {
                        if (Array.isArray(data[key])) {
                            data[key] = data[key].slice(-2);
                            //See if the last value is less than 1 (percent)
                            if (data[key][data[key].length-1] < 1) {
                                for(let i = 0; i < 3; i++){
                                    let change = Math.min(1, data[key][data[key].length-1] * (1+percent_change));
                                    data[key].push(change);
                                }
                            }
                        }
                    });
                //If the object has one key, see if it is a percent value or large number
                } else {
                    Object.keys(data).forEach(key => {
                        if (Array.isArray(data[key])) {
                            data[key] = data[key].slice(-2);
                            for(let i = 0; i < 3; i++)
                                data[key].push(data[key][data[key].length-1] * (1+percent_change));
                        }
                    });
                }

                chart.chart.data = data;

                // Update the labels array
                let labels = chart.chart.labels
                labels = labels.slice(-2);
                labels = this.getLabels(labels);

                chart.chart.labels = labels;

                return chart;

            },
            prepThreeYear() {
                //Last chart on the full year page

                let section_num = (this.current_time_period == 'fiscal_year_to_date' ? 1 : 2);
                let chart = JSON.parse(JSON.stringify(this.page.sections[section_num].charts[7].chart));
                let donor_chart = JSON.parse(JSON.stringify(this.page.sections[section_num].charts[8].chart));
                let donor_data = donor_chart.data;
                let data = chart.data;

                //Get the next three years of data
                var results = [];
                for(let i = 0; i < 3; i++){
                    results.push(this.calcProjections(i));
                }

                var old_revenue = 0;
                var old_donors = 0;
                Object.keys(data).forEach(key => {
                    if (Array.isArray(data[key])) {
                        //Take the last three years
                        data[key] = data[key].slice(-3);
                        donor_data[Object.keys(donor_data)[0]] = donor_data[Object.keys(donor_data)[0]].slice(-3);

                        //Sum the remaining data to old_revenue
                        //And add in the projected revenue
                        for(let i = 0; i < 3; i++){
                            old_revenue += data[key][i];
                            old_donors += donor_data[Object.keys(donor_data)[0]][i];
                            if(results[i]['label'] != undefined){
                                data[key].push(results[i]['revenue']);
                                donor_data[Object.keys(donor_data)[0]].push(results[i]['donors']);
                            }
                        }
                    }
                });

                chart.data = data;
                donor_chart.data = donor_data;

                // Update the labels array
                let labels = chart.labels
                labels = labels.slice(-3);
                labels = this.getLabels(labels, false);

                chart.labels = labels;
                donor_chart.labels = labels;

                this.three_year.revenue_chart = chart;
                this.three_year.donor_chart = donor_chart;
                this.three_year.active_chart = chart;

                //Get the total revenue for the next three years
                let total_revenue = 0;
                let total_donors = 0;
                for(let i = 0; i < 3; i++){
                    total_revenue += results[i]['revenue'];
                    total_donors += results[i]['donors'];
                }

                this.three_year.revenue = total_revenue - old_revenue;
                this.three_year.donors = total_donors - old_donors;


            },
            getLabels(labels, include_months = true) {
                if(this.current_time_period == 'fiscal_year_to_date') {
                    //Now add three more on to the end. Look at the last two characters and use that as the year
                    let lastYear = parseInt(labels[labels.length - 1].slice(-2));
                    //Get the characters before the last two
                    let prefix = labels[labels.length - 1].slice(0, -2);
                    //Add three more years
                    for (let i = 1; i <= 3; i++)
                        labels.push(prefix + (lastYear + i));
                }
                else {
                    let startYear = parseInt(labels[labels.length - 1].substring(0, 4));
                    let dateRange = labels[labels.length - 1].match(/\(([^)]+)\)/)[1];
                    // Create an array and add the next three years
                    let yearsArray = [];
                    for (let i = 1; i <= 3; i++)
                      labels.push(`${startYear + i} (${dateRange})`);

                    //loop through labels and remove the text in parenthesis
                    if(!include_months)
                        labels = labels.map(label => label.replace(/\s*\(.*?\)\s*/g, ''));
                }
                return labels;
            },
            updateAvailable() {
                //Find the scores element with the metric_name of "Retention"
                let existing_obj = this.scores.find(element => element.metric_name == "Retention");
                let new_obj = this.scores.find(element => element.metric_name == "Acquisition");
                let lapsed_obj = this.scores.find(element => element.metric_name == "Reactivation");

                for(let i = 0; i < 3; i++){
                    var new_donors = 0;

                    //The starting_pos is the minimum array length for the objects in existing_obj.fy_chart.chart.data
                    let lengths = Object.values( existing_obj.fy_chart.chart.data ).map(arr => arr.length);
                    // Find the smallest length
                    let smallestLength = Math.min(...lengths);

                    //Get the new donors based upon whatever year we are on
                    new_donors = new_obj.fy_chart.chart.data[Object.keys(new_obj.fy_chart.chart.data)[0]][smallestLength-1];

                    var existing = this.getDonorCount(existing_obj.fy_chart.chart);
                    var lapsed = this.getDonorCount(lapsed_obj.fy_chart.chart);

                    //Set the available for existing
                    Object.keys(existing_obj.fy_chart.chart.data).forEach(key => {
                        let starting_pos = existing_obj.fy_chart.chart.data[key].length - 1;
                        if(existing_obj.fy_chart.chart.data[key][starting_pos] > 1)
                            existing_obj.fy_chart.chart.data[key][starting_pos+1] = (existing['retained'] + lapsed['retained'] + new_donors);
                    });

                    //Set the available for lapsed donors
                    Object.keys(lapsed_obj.fy_chart.chart.data).forEach(key => {
                        let starting_pos = lapsed_obj.fy_chart.chart.data[key].length - 1;
                        if(lapsed_obj.fy_chart.chart.data[key][starting_pos] > 1)
                            lapsed_obj.fy_chart.chart.data[key][starting_pos+1] = (existing['lost'] + lapsed['lost']);
                    });
                }

            },
            getDonorCount(chart, starting_pos= 0) {
                var rate = 0, available_lapsed = 0;

                Object.keys(chart.data).forEach(key => {
                    if(starting_pos == 0)
                        starting_pos = chart.data[key].length - 1;

                    if(chart.data[key][starting_pos] <= 1)
                        rate = chart.data[key][starting_pos];
                    else
                        available_lapsed = chart.data[key][starting_pos];
                });
                return {
                    'rate': rate,
                    'available': available_lapsed,
                    'retained': rate * available_lapsed,
                    'lost': available_lapsed - (rate * available_lapsed),
                    'label': chart.labels[starting_pos]
                };
            },
            getFocusMetric() {
                // Step 1: Prioritize finding the first metric that has an '0' or '1'
                let focusMetric = this.scores.find(metric => metric.grade === '0' || metric.grade === '1');

                // Step 2: If no '0' or '1' is found, search for '2'
                if (!focusMetric) {
                    focusMetric = this.scores.find(metric => metric.grade === '2');
                }

                // Step 3: If no '2' is found, search for '3'
                if (!focusMetric) {
                    focusMetric = this.scores.find(metric => metric.grade === '3');
                }

                // Step 4: If no matching metrics (i.e., all '4's or no metrics), return a default message
                if (!focusMetric) {
                    return "Your fundraising program is performing exceptionally! With all metrics doing well, the best suggestion is to keep the pedal down with regards to acquisition. Keep investing in this area since you know the new donors will be well cared for and will be more likely to stick around.";
                }

                // Generate a summary for the focus metric
                return this.generateSummary(focusMetric);
            },

            generateSummary(metric) {
                let reason;

                // Customize the reason for each metric based on the metric_name
                switch (metric.metric_name) {
                    case 'Retention':
                        reason = `Retention is key to long-term success, as keeping donors engaged reduces the cost of acquiring new ones and increases lifetime value.`;
                        break;
                    case 'Acquisition':
                        reason = `Acquisition is critical for expanding your donor base, ensuring the program has a steady influx of new supporters.`;
                        break;
                    case 'Upgrade':
                        reason = `Upgrading donors helps increase average donation sizes, which boosts revenue without needing to acquire new donors.`;
                        break;
                    case 'Reactivation':
                        reason = `Reactivation focuses on bringing back lapsed donors, which is more cost-effective than acquiring entirely new donors.`;
                        break;
                    default:
                        reason = `This metric is important for overall program success.`;
                }

                let intro = '';
                switch (this.overall_grade) {
                    case '1':
                    case '0':
                        intro += `<p>With an overall rating of &ldquo;${this.overall_grade + '-star'}&rdquo;, there is significant opportunity for improvement.</p>`;
                        break;
                    case '2':
                        intro += `<p>With an overall rating of &ldquo;${this.overall_grade+ '-star'}&rdquo;, your program is performing at a steady pace but there is still room for improvement.</p>`;
                        break;
                    case '3':
                        intro += `<p>With an overall rating of &ldquo;${this.overall_grade+ '-star'}&rdquo;, you are doing very well!</p>`;
                        break;
                    default:
                        intro += `<p>AMAZING!</p>`;
                }

                return `${intro} <p class="mb-0" >Given all of the details below, you should focus on ${metric.metric_name} where your rating was `+ (metric.grade == '1' ? 'an' : 'a' )+` &ldquo;${metric.grade+'-star'}&rdquo;. ${reason}</p>`;
            },
            calcYearOneProjections() {
                let proj = this.calcProjections(0);
                this.one_year.donors = proj.donors;
                //Rev per donor is the current FYTD / the average percent they are toward the end of the fy
                this.one_year.revenue = proj.revenue;
                this.one_year.rev_per_donor = proj.revenue / proj.donors;
                this.one_year.label =  proj.label;
            },
            calcProjections(year_num) {
                let existing_obj = this.scores.find(element => element.metric_name == "Retention");
                let new_obj = this.scores.find(element => element.metric_name == "Acquisition");
                let lapsed_obj = this.scores.find(element => element.metric_name == "Reactivation");
                let upgrade_obj = this.scores.find(element => element.metric_name == "Upgrade");

                //Get the number of projected donors.
                //If there are less than 2, the number is the starting position
                let start_pos = Math.min(2, existing_obj.orig_chart.chart.labels.length);
                var existing = this.getDonorCount(existing_obj.fy_chart.chart, year_num+start_pos);

                start_pos = Math.min(2, new_obj.orig_chart.chart.labels.length);
                var new_donors = this.getDonorCount(new_obj.fy_chart.chart, year_num+start_pos);

                start_pos = Math.min(2, lapsed_obj.orig_chart.chart.labels.length);
                var lapsed = this.getDonorCount(lapsed_obj.fy_chart.chart, year_num+start_pos);

                var rev_per_donor = this.calcRevPerDonorProjections();

                //If they modified the upgrade rate
                var upgrade_change = 0;
                var upgrade_amount = 0;
                if(upgrade_obj.modified_change - upgrade_obj.percent_change != 0) {
                    let new_rate = this.getDonorCount(upgrade_obj.fy_chart.chart, year_num+2)['rate'];
                    //This is what the calculated rate would be for the given year
                    let old_rate = this.getDonorCount(upgrade_obj.orig_chart.chart)['rate'] * Math.pow(1+upgrade_obj.percent_change, 1+year_num);

                    upgrade_change = new_rate - old_rate;

                    let section_num = (this.current_time_period == 'fiscal_year_to_date' ? 0 : 2);
                    //Get the upgrade amount (just assume last year's Median number)
                    upgrade_amount = this.getValueByLabel('Upgraded', this.page.sections[section_num].charts[4].chart);
                }

                return {
                    'donors':existing['retained'] + new_donors['available'] + lapsed['retained'],
                    'revenue' : existing['retained'] * rev_per_donor['Retained'] +
                        new_donors['available'] * rev_per_donor['New'] +
                        lapsed['retained'] * rev_per_donor['Reactivated'] +
                        upgrade_change * existing['retained'] * upgrade_amount,
                    'label' : lapsed['label']
                };

            },
            calcRevPerDonorProjections() {
                let section_num = (this.current_time_period == 'fiscal_year_to_date' ? 0 : 2);

                //Avg Gift (5th chart on the page)
                let new_avg_percent = this.getPercentOfFY('New', 5);
                if(new_avg_percent == 0) new_avg_percent = 1;
                let lapsed_avg_percent = this.getPercentOfFY('Reactivated', 5);
                if(lapsed_avg_percent == 0) lapsed_avg_percent = 1;
                let existing_avg_percent = this.getPercentOfFY('Retained', 5);
                if(existing_avg_percent == 0) existing_avg_percent = 1;
                let fytd_new_avg = this.getThreeYearWeightedAverage('New', this.page.sections[section_num].charts[5].chart)
                let fytd_lapsed_avg = this.getThreeYearWeightedAverage('Reactivated', this.page.sections[section_num].charts[5].chart)
                let fytd_existing_avg = this.getThreeYearWeightedAverage('Retained', this.page.sections[section_num].charts[5].chart)

                //Frequency (6th chart on the page)
                let new_freq_percent = this.getPercentOfFY('New', 6);
                if(new_freq_percent == 0) new_freq_percent = 1;
                let lapsed_freq_percent = this.getPercentOfFY('Reactivated', 6);
                if(lapsed_freq_percent == 0) lapsed_freq_percent = 1;
                let existing_freq_percent = this.getPercentOfFY('Retained', 6);
                if(existing_freq_percent == 0) existing_freq_percent = 1;
                let fytd_new_freq = this.getThreeYearWeightedAverage('New', this.page.sections[section_num].charts[6].chart)
                let fytd_lapsed_freq = this.getThreeYearWeightedAverage('Reactivated', this.page.sections[section_num].charts[6].chart);
                let fytd_existing_freq = this.getThreeYearWeightedAverage('Retained', this.page.sections[section_num].charts[6].chart);

                return {
                    'New': fytd_new_avg/new_avg_percent * fytd_new_freq/new_freq_percent,
                    'Retained': fytd_existing_avg/existing_avg_percent * fytd_existing_freq/existing_freq_percent,
                    'Reactivated': fytd_lapsed_avg/lapsed_avg_percent * fytd_lapsed_freq/lapsed_freq_percent
                }
            },
            getThreeYearWeightedAverage(label, chart){
                //The most recent year
                let year1 = this.getValueByLabel(label, chart);
                //The year before
                if(Object.keys(chart.data).length < 2)
                    return year1;
                let year2 = this.getValueByLabel(label, chart, Object.keys(chart.data).length-2);

                if(Object.keys(chart.data).length < 3)
                    return (year1*2 + year2) / 3;

                //The year before that
                let year3 = this.getValueByLabel(label, chart, Object.keys(chart.data).length-3);
                return (year1*3 + year2*2 + year3) / 6;
            },
            getPercentOfFY(label, index){
                //Trailing 12 has 100% done
                if(this.current_time_period == 'trailing_12_months')
                    return 1;
                // This function will determine the average percent change over the past three full years
                // of how much the given metric (label) changes from today to the end of the year

                let fy_section = 1;
                let fytd_section = 0;

                let arr = [];
                //Always go from the last year starting three years back of the full FY
                let num_years = Object.keys(this.page.sections[fy_section].charts[index].chart.data).length;
                //Get the average of the last four years (since FYTD will have 5 years but I have to ignore the most recent year)
                for(let i = num_years - 1; i > Math.min(num_years-3, 0); i--){
                    //Get the full FY metric of the given year
                    let fy_new_avg = this.getValueByLabel(label, this.page.sections[fy_section].charts[index].chart, i);
                    //Get the fytd metric (i-1 assumes it has one extra year in the labels so got to go back one)
                    let fytd_new_avg = this.getValueByLabel(label, this.page.sections[fytd_section].charts[index].chart, i-1)
                    let percent = fytd_new_avg/ Math.max(1, fy_new_avg );
                    arr.push(percent);
                }

                //return the average of each array element
                return arr.reduce((a, b) => a + b, 0) / arr.length;

            },
            getValueByLabel(label, chart, year_num = -1) {
                // Get the index of the label in the 'labels' array
                const labelIndex = chart.labels.indexOf(label);
                //If there isn't a year number
                if(year_num == -1) //Get the last year of data
                    year_num = Object.keys(chart.data).length-1;
                // Check if the label exists
                if (labelIndex !== -1) {
                    let val = 0;
                    //Use i to count to the year num (since the keys aren't indexed)
                    let i = 0;
                    Object.keys(chart.data).forEach(key => {
                        if(i == year_num)
                            val = chart.data[key][labelIndex];
                        i++;
                    });
                    return val;
                } else {
                    // If the label is not found, return a message or null
                    return 0;
                }
            }
        }   
    }
</script>
