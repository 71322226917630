var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-builder-section-settings" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Required Data Sets"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown" }, [
            _c(
              "button",
              {
                staticClass: "btn form-select btn-field",
                attrs: {
                  type: "button",
                  id: "dropdownMenuButton",
                  "data-bs-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                  "data-bs-auto-close": "outside",
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.local_section.required_data_sets != null &&
                        _vm.local_section.required_data_sets.length > 0
                        ? _vm.getSelectedDataSetNames()
                        : "Please Select"
                    ) +
                    "\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dropdown-menu",
                attrs: { "aria-labelledby": "dropdownMenuButton" },
              },
              _vm._l(_vm.data_set_types, function (item) {
                return _c(
                  "label",
                  {
                    key: item.source_subtype,
                    staticClass: "dropdown-item",
                    on: {
                      click: function ($event) {
                        return _vm.changeDataSets(item.source_subtype)
                      },
                    },
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: _vm.local_section.required_data_sets.includes(
                          item.source_subtype
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ms-2" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm._f("propercase")(item.source_subtype)) +
                          "\n                            "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("Display Value Metric"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-group" },
          [
            _c("accordion-select", {
              class: {
                "is-invalid": _vm.local_errors.display_value_metric_id != "",
              },
              attrs: {
                id: "metrics-multi-select",
                options: _vm.display_value_metric_options,
                field_name: "display_value_metric",
                show_hidden_fields: true,
              },
              on: {
                input: function ($event) {
                  return _vm.updateSection()
                },
              },
              model: {
                value: _vm.local_section.display_value_metric_id,
                callback: function ($$v) {
                  _vm.$set(_vm.local_section, "display_value_metric_id", $$v)
                },
                expression: "local_section.display_value_metric_id",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  type: "button",
                  id: "add-display-value-metric",
                  "data-bs-toggle": "modal",
                  "data-bs-target":
                    "#add-section-metric-filter-modal-" + _vm.section_index,
                },
                on: {
                  click: function ($event) {
                    return _vm.addPageFilter("metric", "display_value_metric")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-plus",
                  attrs: {
                    "data-bs-toggle": "tooltip",
                    title:
                      "Creating a new Metric option will add it to the Metrics option dropdown.",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.local_errors.display_value_metric_id != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback mb-3" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.local_errors.display_value_metric_id) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_section.display_value_name,
              expression: "local_section.display_value_name",
            },
          ],
          staticClass: "form-control",
          class: _vm.local_errors.display_value_name != "" ? "is-invalid" : "",
          attrs: { type: "text", id: "section-display-name" },
          domProps: { value: _vm.local_section.display_value_name },
          on: {
            change: function ($event) {
              return _vm.clearErrorForField("display_value_name")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.local_section,
                "display_value_name",
                $event.target.value
              )
            },
          },
        }),
        _vm._v(" "),
        _vm.local_errors.display_value_name != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback mb-3" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.local_errors.display_value_name) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("Prioritization Metric"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-group" },
          [
            _c("accordion-select", {
              class: {
                "is-invalid": _vm.local_errors.prioritization_metric_id != "",
              },
              attrs: {
                id: "metrics-multi-select",
                options: _vm.prioritization_metric_options,
                field_name: "prioritization_metric",
                show_hidden_fields: true,
              },
              on: {
                input: function ($event) {
                  return _vm.updateSection()
                },
              },
              model: {
                value: _vm.local_section.prioritization_metric_id,
                callback: function ($$v) {
                  _vm.$set(_vm.local_section, "prioritization_metric_id", $$v)
                },
                expression: "local_section.prioritization_metric_id",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  type: "button",
                  id: "add-prioritization-metric",
                  "data-bs-toggle": "modal",
                  "data-bs-target":
                    "#add-section-metric-filter-modal-" + _vm.section_index,
                },
                on: {
                  click: function ($event) {
                    return _vm.addPageFilter("metric", "prioritization_metric")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-plus",
                  attrs: {
                    "data-bs-toggle": "tooltip",
                    title:
                      "Creating a new Metric option will add it to the Metrics option dropdown.",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.local_errors.prioritization_metric_id != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback mb-3" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.local_errors.prioritization_metric_id) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("nav", { staticClass: "bg-secondary mt-0 rounded" }, [
          _c("div", { staticClass: "d-flex pt-3 px-2" }, [
            _c("span", { staticClass: "py-1 ms-1 me-3 mb-0 h5" }, [
              _vm._v("Section Filters:"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c("v-select", {
                  staticClass: "searchable-select column-display w-50",
                  attrs: {
                    options: _vm.section_filter_options,
                    placeholder: "Select the Filter to add to Section...",
                    searchable: true,
                    selectable: (option) => option.text != "",
                    label: "text",
                    filterable: false,
                  },
                  on: {
                    search: _vm.searchSectionFilterOption,
                    input: _vm.emitSectionFilterChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selected-option",
                      fn: function ({ text, category }) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s("Select the Filter to add to Section...")
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function ({ text, category }) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "suboption",
                              class:
                                category == null || category == ""
                                  ? "show"
                                  : "",
                              attrs: { "data-subcategory": category },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(text) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "no-options",
                      fn: function ({ search, searching, loading }) {
                        return [
                          _c("div", { staticClass: "suboption show" }, [
                            _c("em", [_vm._v("No results found")]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: {
                      "data-bs-toggle": "modal",
                      "data-bs-target":
                        "#add-section-metric-filter-modal-" + _vm.section_index,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addPageFilter("filter", "section-filter")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-btn fa-plus" })]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex py-2 px-2" },
            _vm._l(_vm.local_section.section_filters, function (filter, index) {
              return _c("span", { staticClass: "badge badge-dark me-2" }, [
                _vm._v(
                  "\n                         " +
                    _vm._s(filter.admin_name) +
                    " "
                ),
                _c("i", {
                  staticClass: "fa fa-remove",
                  attrs: { id: "remove-filter" },
                  on: {
                    click: function ($event) {
                      return _vm.removeSectionFilterByIndex(index)
                    },
                  },
                }),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-label" }, [_vm._v("Section Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.local_section.title,
                expression: "local_section.title",
              },
            ],
            staticClass: "form-control",
            class: _vm.local_errors.section_name != "" ? "is-invalid" : "",
            attrs: { type: "text", id: "section-title-input" },
            domProps: { value: _vm.local_section.title },
            on: {
              change: function ($event) {
                return _vm.clearErrorForField("section_name")
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.local_section, "title", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.local_errors.section_name != ""
            ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.local_errors.section_name) +
                    "\n                "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_section.description,
              expression: "local_section.description",
            },
          ],
          ref: "textarea",
          staticClass: "form-control full-height",
          attrs: { id: "section-description" },
          domProps: { value: _vm.local_section.description },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.local_section, "description", $event.target.value)
              },
              function ($event) {
                return _vm.resizeTextarea()
              },
            ],
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Charts:")]),
        _vm._v(" "),
        _vm.local_errors.if_has_primary_chart != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.local_errors.if_has_primary_chart) +
                  "\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.local_section.charts && _vm.local_section.charts.length > 0
          ? _c(
              "div",
              { staticClass: "row chart-container" },
              [
                _c(
                  "draggable",
                  {
                    staticClass: "row",
                    attrs: {
                      list: _vm.local_section.charts,
                      element: "div",
                      tag: "div",
                    },
                    on: { end: _vm.onDragEnd },
                  },
                  _vm._l(
                    _vm.local_section.charts,
                    function (chart, chart_index) {
                      return _c(
                        "div",
                        {
                          staticClass: "chart-tile",
                          class: _vm.loadChartSize(chart_index),
                        },
                        [
                          _c("div", { staticClass: "card mx-2 mb-4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-body text-center tile-body p-0",
                              },
                              [
                                _c("div", { staticClass: "row my-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "chart-content-row col-8 d-flex text-start",
                                    },
                                    [
                                      chart.type == "scorecard"
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-address-card",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type == "pie"
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-chart-pie",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type == "line" ||
                                      chart.type == "bar_line" ||
                                      chart.type == "cumulative_line"
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-chart-line",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type == "column" ||
                                      chart.type == "stacked_column" ||
                                      chart.type == "100_percent_stacked_column"
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-chart-column",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type == "horizontal_bar" ||
                                      chart.type == "stacked_bar" ||
                                      chart.type == "100_percent_stacked_bar"
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-chart-bar",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type == "table"
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-table",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type == "textbox" &&
                                      !chart.title
                                        .toLowerCase()
                                        .startsWith("divider")
                                        ? _c("i", {
                                            staticClass:
                                              "mt-1 ms-3 fa-solid fa-quote-left",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      chart.type != "textbox"
                                        ? _c(
                                            "div",
                                            { staticClass: "ms-3 w-100" },
                                            [
                                              _c("p", { staticClass: "mb-0" }, [
                                                _vm._v("Title: "),
                                                _c("em", [
                                                  _vm._v(_vm._s(chart.title)),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("p", { staticClass: "mb-0" }, [
                                                _vm._v("Subtitle: "),
                                                _c("em", [
                                                  _vm._v(
                                                    _vm._s(chart.subtitle)
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "ms-3 w-100" },
                                            [
                                              _c("p", { staticClass: "mb-0" }, [
                                                _vm._v(
                                                  "Title: " +
                                                    _vm._s(chart.title)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              chart.type == "textbox" &&
                                              chart.title
                                                .toLowerCase()
                                                .startsWith("divider")
                                                ? _c("hr")
                                                : _vm._e(),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-4" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "chart-button-row float-end me-1",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn solid-border p-0",
                                            class: chart.is_primary
                                              ? "btn-primary"
                                              : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setPrimaryChart(
                                                  chart_index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ms-1 fa-solid fa-star",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn solid-border py-0 px-1",
                                            class:
                                              chart.size == "small"
                                                ? "btn-primary"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectChartSize(
                                                  chart_index,
                                                  "small"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("S")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn solid-border py-0 px-1",
                                            class:
                                              chart.size == "medium"
                                                ? "btn-primary"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectChartSize(
                                                  chart_index,
                                                  "medium"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("M")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn solid-border py-0 px-1",
                                            class:
                                              chart.size == "large"
                                                ? "btn-primary"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectChartSize(
                                                  chart_index,
                                                  "large"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("L")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn solid-border py-0 px-1",
                                            class:
                                              chart.size == "x-large"
                                                ? "btn-primary"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectChartSize(
                                                  chart_index,
                                                  "x-large"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("XL")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn solid-border p-0",
                                            attrs: {
                                              "data-bs-toggle": "modal",
                                              "data-bs-target":
                                                "#update-chart-modal-" +
                                                _vm.section_index,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editChart(
                                                  chart_index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ms-1 fa fa-btn fa-edit",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn solid-border p-0",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteChart(
                                                  chart_index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ms-1 fa fa-btn fa-trash",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            )
          : _c("p", [
              _vm._v(
                'No chart has been added to this section yet. Please click on the "Add Chart" button in the footer to add charts to the active section.'
              ),
            ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "add-section-metric-filter-modal-" + _vm.section_index,
          "aria-labelledby": "add-section-metric-filter-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end close-modal",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss":
                        "add-section-metric-filter-modal-" + _vm.section_index,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal(
                          "add-section-metric-filter-modal-" + _vm.section_index
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(
                    _vm._s(_vm._f("propercase")(_vm.mf_type)) + " Builder"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0 pb-0" },
              [
                _c("metric-filter-builder", {
                  ref: "mf_builder",
                  attrs: {
                    modal_id:
                      "add-section-metric-filter-modal-" + _vm.section_index,
                    data_blend: _vm.data_blend,
                    client_id: _vm.page_client_id,
                    default_metric_format: _vm.default_metric_format,
                    type: _vm.mf_type,
                  },
                  on: { update: _vm.sectionMetricFilterAdded },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "update-chart-modal-" + _vm.section_index,
          "aria-labelledby": "update-chart-modal",
          "data-bs-backdrop": "static",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss":
                        "update-chart-modal-" + _vm.section_index,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal(
                          "update-chart-modal-" + _vm.section_index
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(
                    "Update Chart in " +
                      _vm._s(
                        _vm.local_section.title
                          ? _vm.local_section.title
                          : "New Section(unsaved)"
                      )
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0" },
              [
                _c("section-chart-modal", {
                  attrs: {
                    modal_action: "Update",
                    charts: _vm.charts,
                    section_chart:
                      _vm.local_section.charts[this.chart_index_editing],
                    section_index: _vm.section_index,
                  },
                  on: { chartUpdated: _vm.updateChartInSection },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Display Metric Name "),
      _c("i", {
        staticClass: "fas fa-question-circle text-primary",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "What title should describe the display value metric on the dashboard?",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Description "),
      _c("em", [_vm._v("(Optional)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }