<template>
    <div class="report-builder">
        <div class="report-builder-overview">
            
            <h2 v-if="!is_editing_report_overview" class="align-baseline">{{ local_report_page.name }}
                <a @click.prevent="is_editing_report_overview = true" href="#" class="fs-6">(Edit Report Details)</a>
            </h2>
            <p class="">Report Client Name: <code>{{ local_client.name }}</code></p>

            <div v-if="is_editing_report_overview" class="card box-content mt-3" >
                <div class="card-body">
                    <div class="row">
                        <div class="col mb-3">
                            <label class="form-label">Report Name</label>
                            <input type="text" id="name-input" class="form-control" v-model="local_report_page.name"
                                :class="((errors.name != '' )? 'is-invalid':'')" @change="clearErrorForField('name')">
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name}}
                            </div>
                        </div>
                        <div class="col-sm-3 mb-3" v-if="report_page.id == null">
                            <label class="form-label">Client / Template</label>
                            <select class="form-select" v-model="local_report_page.client_id"
                                @change="changeClientSelection()">
                                <option v-for="c in clients" :value="c.id">{{c.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mb-3" v-if="local_report_page.client_id > 0">
                            <label class="form-label">Report Status</label>
                            <select class="form-select" v-model="local_report_page.status">
                                <option value="active">Active</option>
                                <option value="archived">Archived</option>
                                <option value="draft">Draft</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mb-3">
                            <label class="form-label">User Visibility</label>
                            <select class="form-select" v-model="local_report_page.visibility"
                                :class="((errors.visibility != '' )? 'is-invalid':'')" @change="clearErrorForField('visibility')">
                                <option value="user">All Users</option>
                                <option value="agency">Only Agency Users</option>
                                <option value="admin">Only Avid Admins</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.visibility != ''">
                                {{errors.visibility}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-3">
                            <label class="form-label">Group Name</label>
                            <input v-if="group_names.length == 0" type="text" id="group-input" class="form-control"  v-model="local_report_page.group_name"
                                    :class="((errors.group_name != '' )? 'is-invalid':'')" @change="clearErrorForField('group_name')">

                            <div v-else class="input-group" >
                                <input type="text" class="form-control" v-model="local_report_page.group_name" :class="((errors.group_name != '' )? 'is-invalid':'')" 
                                v-if="is_new_group_name" @change="clearErrorForField('group_name')">

                                <select v-else class="form-select" :class="((errors.group_name != '' )? 'is-invalid':'')"
                                id="group-select" v-model="local_report_page.group_name" @change="clearErrorForField('group_name')">
                                    <option value="" disabled selected>Select the group name...</option>
                                    <option v-for="group in group_names" :value="group.group_name">{{ group.group_name | propercase }}</option>
                                </select>
             
                                <button class="btn btn-secondary" @click="toggleGroupNameField()" v-if="is_new_group_name" ><i class="fa-solid fa-map"></i></button>
                                <button class="btn btn-secondary" @click="toggleGroupNameField()" v-else><i class="fa fa-edit" ></i></button>
                            </div>

                            <div class="text-danger invalid-feedback" v-if="errors.group_name != ''">
                                {{errors.group_name}}
                            </div>
                        </div>
                        <div class="col mb-3" v-if="local_report_page.id == null">
                            <label class="form-label">Data Blend Type</label>
                            <select class="form-select" id="data-blend-select" v-model="local_report_page.data_blend_type" @change="filterMetricFilters()" :disabled="this.report_page.id != null">
                                <option v-for="db in local_client.data_blends" :value="db.type">{{ db.type | propercase}}</option>
                            </select>
                        </div>
                        <div class="col mb-3">
                            <label class="form-label">Auto-Generated Filter Column <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="If a value is entered, this will make a unique version of this report for every value of the selected column. ** The column value will be placed at the beginning of the page name. **"></i></label>

                            <accordion-select id="deploy-filter-column" :options="columns"
                                v-model="local_report_page.deploy_filter_column" :show_hidden_fields="false" >
                            </accordion-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Description</label>
                            <textarea id="report-description" class="form-control full-height" :class="((errors.description != '' )? 'is-invalid':'')"
                            v-model="local_report_page.description" ref="textarea"  @input="resizeTextarea()" @change="clearErrorForField('description')"></textarea>
                            <div class="text-danger invalid-feedback" v-if="errors.description != ''">
                                {{errors.description}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="alert alert-success mt-3" v-if="form.successful">
                                The report's information has been updated!
                            </div>
                            <div class="alert alert-danger mt-3" v-if="form.error">
                                There was an error saving the information above. Please make any necessary corrections noted above.
                            </div>
                            <div class="mt-3">
                                <button type="submit" class="btn btn-primary" id="save-button"
                                        @click.prevent="update" :disabled="form.busy">
                                    <i class="fa fa-btn fa-save"></i> <span v-if="local_report_page.id!=null">Save</span><span v-else>Create</span> Report
                                </button>
                                <button v-if="local_report_page.id!=null" class="btn btn-secondary ms-2" id="cancel-button"
                                        @click.prevent="revertOverviewChange()" :disabled="form.busy">
                                    <i class="fa fa-btn fa-close"></i>Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="local_report_page.id!=null">
            <report-builder-filter-settings  :report_page="local_report_page" :data_blend="data_blend"  :metrics_filters="metrics_filters" 
             @updatePageFilters="updatePageFilters" @updateUserDefinedFilters="updateUserDefinedFilters" ref="filters"></report-builder-filter-settings>

            <h5 class="mt-4">Report Section Settings</h5>

            <div v-if="local_report_page.sections && local_report_page.sections.length === 0">
                <div class="card box-content mb-3">
                    <div class="card-body mt-3 text-center">
                        <h3 class="text-center">No section have been added yet.</h3>
                        <button type="submit" class="btn btn-secondary" id="add-section-button" @click.prevent="addSection" :disabled="form.busy">
                                <i class="fa fa-btn fa-plus"></i> Add a Section
                            </button>
                    </div>
                </div>
            </div>
            <transition-group v-else tag="div" name="list" class="mb-5">
                <div v-for="(section, index) in local_report_page.sections" ref="pageSection"  @click.prevent="setActiveSection(index)" :key="section.id" :class="((local_report_page.sections.length > 1)? 'section-row':'')" class="vertical-center-text ">
                    <div class="card mb-3 mx-0 row-with-reorder-btns" :class="section.if_active ?'active-section' : ' box-content'">
                        <div class="card-body">
                            <div class="accordion accordion-flush" >
                                <div class="accordion-item border-0">
                                    <div class="accordion-header ms-2 me-5">
                                        <div class="text-start collapsed p-2 accordion-button section-accordion-button" type="button" 
                                            :data-bs-target="'#section-editing-' + index" data-bs-toggle="collapse"
                                            aria-expanded="false" :aria-controls="'#section-editing-' + index" @click="resizeTextareaExpanding(index)">
                                            <div class="row">
                                                <div class="col">
                                                    <h4 class="mb-1">
                                                        <i class="fa-solid fa-circle-exclamation fa-red" v-if="errors.sections.length == 0 || Object.values(errors.sections[index]).join('') != ''"></i>
                                                        Section {{ section.order }} : {{ section.title? section.title: 'New Section(unsaved)' }}
                                                    </h4>
                                                </div>
                                                <!-- <div class="mt-2 col-12" v-if="section.if_active==false&& section.description != null">
                                                    <label class="form-label border-bottom">Description</label>
                                                    <p class="mb-0">{{ section.description }}</p>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div :id="'section-editing-' + index" class="collapse accordion-collapse section-accordion-collapse" >
                                        <div>
                                            <hr class="mx-3 mb-2 section-divider">
                                            <report-builder-section-settings :section_selected="section" :metrics_filters="local_metrics_filters" :charts="charts" :page_client_id="local_report_page.client_id"
                                            :data_blend="data_blend" :data_set_types="data_set_types" :section_index="index" :section_errors="errors.sections[index]" @updateSection="updateSection"></report-builder-section-settings>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="asset-accordion-delete btn" aria-label="Close"
                                    @click="deleteSection(index)">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div class="px-3 reorder-btn-containter">
                        <div class="reorder-btn-containter">
                            <button v-if="index!=0" type="button" class="reorder-btn mb-2" v-on:click="moveSectionUp(index)"><i class="fa fa-arrow-up"></i></button>
                            <button v-if="index!=local_report_page.sections.length-1" type="button" class="reorder-btn" v-on:click="moveSectionDown(index)"><i class="fa fa-arrow-down"></i></button>
                        </div>
                    </div>
                </div>
            </transition-group>

            <div v-if="local_report_page.sections.length >0" class="sticky-footer w-100">
                <div>
                    <nav class="col-12 navbar navbar-expand">
                        <button type="submit" class="btn btn-primary ms-2" id="save-button" @click.prevent="update" :disabled="form.busy">
                            <i class="fa fa-btn fa-save"></i> Save 
                        </button>
                        <button class="btn btn-secondary ms-2" id="add-section-button" @click.prevent="addSection" :disabled="form.busy">
                            <i class="fa fa-btn fa-plus"></i> Add Section
                        </button>

                        <div v-if="!checkIfAnyActiveSection()" class=" btn btn-secondary dropup ms-2">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                                <i class="fa fa-btn fa-plus"></i> Add Chart
                            </a>
                            <div class="dropdown-menu dropdown-menu-overflow px-2">
                                <span>No section has been selected. Please click on a Section which you want to add a chart to.</span>
                            </div>
                        </div>

                        <button v-else class="btn btn-secondary ms-2" id="add-chart-button" data-bs-toggle="modal" data-bs-target="#add-chart-modal" >
                            <i class="fa fa-btn fa-plus"></i> Add Chart
                        </button>

                        <div class="dropup" v-if="local_report_page.client_id == null">
                          <button type="button" class="btn btn-secondary dropdown-toggle  ms-2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-btn fa-eye"></i> Preview
                          </button>
                          <ul class="dropdown-menu">
                            <li v-for="client in clients" :key="client.id" v-if="client.id > 0">
                                <a class="dropdown-item" :href="'/preview/'+client.url+'/'+local_report_page.id" target="_blank">{{client.name}}</a></li>
                          </ul>
                        </div>
                        <a class="btn btn-secondary ms-2"  target="_blank" v-else :href="'/preview/'+local_client.url+'/'+local_report_page.id">
                            <i class="fa fa-btn fa-eye"></i> Preview
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="add-chart-modal" aria-labelledby="add-chart-modal" data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" data-bs-dismiss="add-chart-modal" @click.prevent="closeModal('add-chart-modal')">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">Add Chart in {{ active_section && active_section.title ? active_section.title : 'New Section(unsaved)' }}</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 ">
                        <section-chart-modal :modal_action="'Add'" :charts="local_charts" @chartAdded="addChartToSection"></section-chart-modal>
                    </div>
                </div>
            </div>   
        </div>
    </div>
</template>

<script>
import { error } from 'jquery';
import Swal from 'sweetalert2';
import vSelect from "vue-select";

    export default {
        components: {
            Swal, vSelect
        },
        props: ['report_page','metrics_filters', 'charts', 'group_names', 'data_set_types', 'clients'],
        data() {
            return {
                local_report_page: null,
                local_metrics_filters: null,
                local_client: null,
                local_charts: null,
                is_editing_report_overview: true,
                is_new_group_name: false,
                errors: {
                    // errors on overview
                    name: '',
                    group_name: '',
                    description: '',
                    visibility: '',
                    // errors on each section
                    sections:[]
                },
                active_section: {},
                form: {
                    successful: false,
                    busy: false,
                    error: false 
                },
                if_changes_made: false,
                columns: []
            };
        },
        beforeMount() {
            this.local_report_page = {...this.report_page};

            if(this.local_report_page.id){
                this.is_editing_report_overview = false;
            }
            if(!this.report_page.sections){
                this.local_report_page.sections = [];
            }
            else {
                for (var i = 0; i < this.local_report_page.sections.length; i++)
                    this.errors.sections.push({
                        section_name: '',
                        if_has_primary_chart: '',
                        display_value_metric_id: '',
                        display_value_name: '',
                        prioritization_metric_id: ''
                    });

            }
            this.local_client = this.getClientObject();

            //Set a default data_blend_type
            if(this.local_report_page.data_blend_type == null){
                //Filter the metric_filters to just this type
                this.local_report_page.data_blend_type = this.local_client.data_blends[0].type;
            }

            this.filterMetricFilters();

            this.refreshColumns();
            window.addEventListener("beforeunload", this.preventNav)

        },
        beforeDestroy() {
            window.removeEventListener("beforeunload", this.preventNav);
        },
        computed: {
            data_blend: function(){
                return this.local_client.data_blends.find((db)=>db.type == this.local_report_page.data_blend_type);
            },
        },
        methods: {
            filterMetricFilters() {
                this.local_metrics_filters = this.metrics_filters.filter((mf) => {
                    return mf.data_blend_type == this.local_report_page.data_blend_type;
                });
                this.local_charts = this.charts.filter((chart) => {
                    return chart.data_blend_type == this.local_report_page.data_blend_type;
                });
                this.local_charts = [...this.local_charts];

            },
            refreshColumns(){
                var data = {
                  include_trans: true,
                  source: 'data_blend',
                  data_source_id: this.data_blend.id
                };
                this.columns = [];

                window.axios.post('/api/bigquery/get_db_columns', data)
                  .then(response => {
                    var cols = response.data.columns;
                    var headers = "";
                    for(var i = 0; i < cols.length; i++){

                        cols[i].main_category = 'Columns';
                    }
                    this.columns = cols;
                    this.$forceUpdate();
                  });
            },

            preventNav(event) {
                if (!this.if_changes_made) return;

                event.preventDefault();    
                event.returnValue = false;
                return false;
            },            
            getClientObject() {

                if(this.local_report_page.client_id){
                    return Object.values(this.clients).find((c)=>c.id == this.local_report_page.client_id);
                }
                //Return the one with no client ID
                return Object.values(this.clients).find((c)=>c.id == null);
            },
            changeClientSelection() {
                this.local_client = this.getClientObject();
                //If there is only one option, set it
                if(this.local_client.data_blends.length == 1){
                    this.local_report_page.data_blend_type = this.local_client.data_blends[0].type;
                }
                //If the currently selected option is not in the available list of data_blend types
                else if(this.local_client.data_blends.findIndex((db)=>db.type == this.local_report_page.data_blend_type) == -1){
                    this.local_report_page.data_blend_type = this.local_client.data_blends[0].type;
                }
                this.refreshColumns();
            },
            toggleGroupNameField(){
                this.is_new_group_name = !this.is_new_group_name;

                if(this.is_new_group_name){
                    this.local_report_page.group_name = null
                }
            },
            revertOverviewChange(){
                if(this.report_page.name != null) {
                    this.local_report_page.name = this.report_page.name;
                    this.local_report_page.group_name = this.report_page.group_name;
                    this.local_report_page.description = this.report_page.description;
                    this.local_report_page.data_blend_type = this.report_page.data_blend_type;
                }
                this.is_editing_report_overview=false;
            },
            addChartToSection(section_chart_detail){
                var active_section_index = this.local_report_page.sections.findIndex(s=>
                 s.if_active== true
                )
                if (active_section_index>=0){
                    var new_chart = {     
                        report_chart_id: section_chart_detail.chart_selected.id,
                        sizes: ['small', 'medium', 'large', 'x-large'],
                        size: 'x-large', // Default to the largest size for now
                        type: section_chart_detail.chart_selected.type,
                        is_primary: false,
                        title: section_chart_detail.title,
                        subtitle: section_chart_detail.subtitle,
                        order: this.local_report_page.sections[active_section_index].charts.length+1
                    }
                    this.local_report_page.sections[active_section_index].charts.push(new_chart);
                }
                this.if_changes_made = true;
                this.closeModal('add-chart-modal');
            },
            createNewSection(){
                var section_obj = {
                    title: '',
                    description: null,
                    order: this.local_report_page.sections.length+1,
                    required_data_sets: [],
                    section_filters: [],
                    display_value_metric_id: null,
                    prioritization_metric_id: null,
                    charts: [],
                    if_active: false,
                    id: Math.floor(Math.random() * -1000000)

                };
                this.if_changes_made = true;
                return section_obj;
            },
            clearErrors() { //reset errors to empty strings
                this.errors = {
                    // errors on overview
                    name: '',
                    group_name: '',
                    data_blend_type: '',
                    description: '',
                    visibility: '',
                     // errors on sections
                    sections:[]
                }
                this.local_report_page.sections.forEach((s)=>{
                    this.errors.sections.push({
                        section_name: '',
                        if_has_primary_chart: '',
                        display_value_metric_id: '',
                        display_value_name: '',
                        prioritization_metric_id: ''
                    });
                })
            },
            clearErrorForField(field_name){
                this.errors[field_name] = '';
            },     
            clearChartErrorForField(field_name){
                this.add_chart_errors[field_name] = '';
            },       
            resizeTextarea() {
                var element = document.querySelectorAll('textarea')[0];
                if (element && element.style){
                    element.style.height = '';
                    element.style.height = Math.max(element.offsetHeight, element.scrollHeight) + "px";
                }                   
            },
            hasErrors() {//validate the form
                // Error checking for overview section
                if(this.local_report_page.name == null || this.local_report_page.name == ""){
                    this.errors.name = "A name is required.";
                } else
                    this.errors.name = "";

                if(this.local_report_page.visibility == null || this.local_report_page.visibility == ""){
                    this.errors.visibility = "A visibility level is required.";
                } else
                    this.errors.visibility = "";
                
                if(this.local_report_page.group_name == null || this.local_report_page.group_name == ""){
                    this.errors.group_name = "A group name is required.";
                } else
                    this.errors.group_name = "";

                if(this.local_report_page.description == null || this.local_report_page.description == ""){
                    this.errors.description = "A description is required.";
                } else
                    this.errors.description = "";

                this.local_report_page.sections.forEach((section, index)=>{
                    if (section.title == null || section.title.trim() == ""){
                        this.errors.sections[index].section_name = "Section name is required.";
                    }

                    if (section.display_value_metric_id == null && section.prioritization_metric_id != null || section.display_value_metric_id != null && section.prioritization_metric_id == null){
                        this.errors.sections[index].display_value_metric_id = "Both Display and Prioritization need to have values or neither can have values.";
                        this.errors.sections[index].prioritization_metric_id = "Both Display and Prioritization need to have values or neither can have values.";
                    }

                    if(section.display_value_name != null && section.display_value_name.trim() == ""){
                        this.errors.sections[index].display_value_name = "Display value name is required.";
                    }

                    if (!this.checkIfSectionHasPrimaryChart(section)) {
                        this.errors.sections[index].if_has_primary_chart = "Each section must have a primary chart";
                    }
                })    

                var if_section_has_error = this.checkIfSectionHasError();
                if (this.errors.name!="" || this.errors.group_name!="" || this.errors.description!="" || if_section_has_error){
                    return true
                } else {
                    return false
                }
            },
            checkIfSectionHasPrimaryChart(section){
                for(var i = 0; i < section.charts.length; i++) {
                    if (section.charts[i].is_primary) {
                        return true;
                    }
                }
                return false;
            },
            checkIfSectionHasError(){
                for(var i = 0; i < this.errors.sections.length; i++) {
                    if(Object.values(this.errors.sections[i]).join('') != ''){
                        return true;                       
                    }
                }
                return false;
            },
            addSection(){
                var new_section = this.createNewSection();
                new_section.order = this.local_report_page.sections.length + 1;
                this.local_report_page.sections.push(new_section);

                this.errors.sections.push({
                    section_name: '',
                    if_has_primary_chart: '',
                    display_value_metric_id: '',
                    display_value_name: '',
                    prioritization_metric_id: ''
                });

                this.if_changes_made = true;
                this.$forceUpdate();
            },
            checkIfAnyActiveSection(){
                for(var i = 0; i < this.local_report_page.sections.length; i++){
                    if (this.local_report_page.sections[i].if_active){
                        return true;
                    }
                }
                return false;
            },
            deleteSection(index){
                Swal.fire({
                  title: "Are you sure you want to delete the section?",
                  text: "This will remove this section and all associated charts from the report.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.local_report_page.sections.splice(index, 1);
                        this.errors.sections.splice(index, 1);
                        this.if_changes_made = true;
                        // this.reloadSectionOrder();
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Section Deleted!</h4>',
                            target: '.report-builder',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });
                    }
                });
            },
            setActiveSection(index){
                var collapsableSections = document.querySelectorAll('.section-accordion-collapse');
                var collapsableSectionAccordionButtons = document.querySelectorAll('.section-accordion-button');

                for(var i = 0; i < this.local_report_page.sections.length; i++){
                    if(i == index){
                        this.local_report_page.sections[i].if_active = true;
                        this.active_section = this.local_report_page.sections[i];
                    } else {
                        collapsableSections[i].classList.remove('show');
                        collapsableSectionAccordionButtons[i].classList.add('collapsed');
                        this.local_report_page.sections[i].if_active = false;    
                    }
                }      
                this.$forceUpdate();
            },
            updateSection(section_details){ // only triggered when updating a section
                // map section_details into active section
                var active_section_index = this.local_report_page.sections.findIndex(s=>
                 s.if_active== true
                )
                if (active_section_index>=0){
                    Object.keys(section_details).forEach((field)=>{
                        this.local_report_page.sections[active_section_index][field] = section_details[field];
                    });
                }
                this.if_changes_made = true;
            },
            moveSectionUp(section_index){
                var current_section = {...this.local_report_page.sections[section_index]};
                var previous_section = {...this.local_report_page.sections[section_index-1]};

                current_section.order = this.local_report_page.sections[section_index-1].order;
                previous_section.order = this.local_report_page.sections[section_index].order;

                // switch the two sections' order on the UI
                this.local_report_page.sections[section_index-1] = current_section;
                this.local_report_page.sections[section_index] = previous_section;

                this.if_changes_made = true;
                this.$forceUpdate();
            },
            moveSectionDown(section_index){
                var current_section = {...this.local_report_page.sections[section_index]};
                var next_section = {...this.local_report_page.sections[section_index+1]};

                current_section.order = this.local_report_page.sections[section_index+1].order;
                next_section.order = this.local_report_page.sections[section_index].order;

                // switch the two sections' order on the UI
                this.local_report_page.sections[section_index+1] = current_section;
                this.local_report_page.sections[section_index] = next_section;

                this.if_changes_made = true;
                this.$forceUpdate();
            },
            reloadSectionOrder(){
                for (var i = 0; i < this.local_report_page.sections.length; i++){
                    this.local_report_page.sections[i].order = i+1;
                }
            },
            resizeTextareaExpanding(section_index) {
                if(this.local_report_page.sections[section_index].if_active == true){
                    var element = document.querySelectorAll('textarea');

                    for(var index = 0; index<element.length; index++){
                        if (element[index] && element[index].style){
                            element[index].style.height = '';
                            element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                        }                            
                    }                    
                }
            },

            // Not sure if I tied the fields correctly
            updatePageFilters(filters) {
                this.local_report_page.filters = filters;
                this.if_changes_made = true;
            },
            updateUserDefinedFilters(filters) {
                this.local_report_page.page_dimensions = filters;

                filters.forEach((filter_group) => {
                    filter_group.filters.forEach((udf) => {
                        let index = this.local_report_page.dimensions.findIndex((dimension) => dimension.id == udf.id);
                        this.local_report_page.dimensions[index] = {
                            column: udf.column,
                            default_value: udf.default_value,
                            field_type: udf.field_type,
                            is_required: udf.is_required,
                            label: udf.label,
                            order: udf.order,
                            id: this.local_report_page.dimensions[index].id,
                            filter_group: this.local_report_page.dimensions[index].filter_group,
                            parent_id: this.local_report_page.dimensions[index].parent_id,
                            report_page_id: this.local_report_page.dimensions[index].report_page_id,
                        }
                    })
                })
                this.if_changes_made = true;
            },
            update(){
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    Swal.fire({
                    title: "Error saving the report",
                    text: "There were some issues saving this report; please review and make the suggested changes. ",
                    icon: "warning",
                    iconColor: "#D11F1F",
                    confirmButtonColor: "#D11F1F",
                    confirmButtonText: "View Errors",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // collapse all section accordion and scroll to the first error
                            var collapsableSections = document.querySelectorAll('.section-accordion-collapse');
                            var collapsableSectionAccordionButtons = document.querySelectorAll('.section-accordion-button');

                            for(var i = 0; i < this.local_report_page.sections.length; i++){
                                collapsableSections[i].classList.remove('show');
                                collapsableSectionAccordionButtons[i].classList.add('collapsed');
                                this.local_report_page.sections[i].if_active = false;    
                            }  

                            //next tick then do the scroll
                            var firstSectionErrorIndex = 0;
                            for (var i =0; i<this.errors.sections.length; i++){
                                if(Object.values(this.errors.sections[i]).join('') != ''){
                                    firstSectionErrorIndex = i;
                                    break;
                                }
                            }
                            if(this.$refs.pageSection != null && this.$refs.pageSection.length > 0) {
                                const firstError = this.$refs.pageSection[firstSectionErrorIndex];
                                firstError.scrollIntoView({ behavior: 'smooth'});

                                // expand the first section with error and mark it as active section
                                collapsableSections[firstSectionErrorIndex].classList.add('show');
                                collapsableSectionAccordionButtons[firstSectionErrorIndex].classList.remove('collapsed');
                                this.local_report_page.sections[firstSectionErrorIndex].if_active = true;
                            }
                        }
                    });
                    return;
                }

                this.form.busy = true;

                if(this.local_report_page.id == null){
                    window.axios.post('/api/insights/page', this.local_report_page)
                        .then(response => {
                            this.local_report_page = response.data.page;
                            this.is_editing_report_overview = false;
                            this.if_changes_made = false;
                            
                            if(this.local_report_page.sections == null)
                                this.local_report_page.sections = [];
                            this.form.busy = false;
                            setTimeout( function() {self.form.successful = false;} , 5000);
                
                        }).catch(error=> {
                            this.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        });                    
                } else{
                    window.axios.put('/api/insights/page/'+this.local_report_page.id, this.local_report_page)
                        .then(response => {

                            this.local_report_page = response.data.page;
                            if(this.local_report_page.sections == null)
                                this.local_report_page.sections = [];

                            else {
                                for (var i = 0; i <this.local_report_page.sections.length; i++){             
                                    // Reset Chart order in section
                                    for (var j = 0; j <this.local_report_page.sections[i].charts.length; j++){       
                                        this.local_report_page.sections[i].charts[j].order = j+1;
                                    }     
                                    this.local_report_page.sections[i].order = i+1;
                                }
                            }
                            this.$forceUpdate();
                            this.local_report_page.page_dimensions = this.$refs.filters.dimensional_filters_list;

                            this.is_editing_report_overview = false;
                            this.if_changes_made = false;
                            this.form.busy = false;
                            // document.location = "/insights/report_builder";
                            Swal.fire({
                                html: '<h4 class="mb-0 text-white">Report Updated!</h4>',
                                target: '.report-builder',
                                icon: 'success',
                                toast: true,
                                position: 'bottom-right',
                                showConfirmButton: false,
                                timer: 2000,
                                iconColor: 'white',
                                customClass: {
                                    popup: 'bg-primary'
                                },
                            });
                            // setTimeout( function() {self.form.successful = false} , 10000);
                        }).catch(error=> {
                            this.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        });
                }
            },
            closeModal(modal_ID){
                var myModalEl = document.getElementById(modal_ID)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
        }
    }
</script>
