var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-group" }, [
    _c("div", {
      ref: "editable",
      staticClass: "token-textfield form-control",
      class: _vm.error != "" ? "is-invalid" : "",
      attrs: { contenteditable: "" },
      on: {
        input: _vm.onInput,
        keypress: _vm.onKeypress,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
              "Backspace",
              "Delete",
              "Del",
            ])
          )
            return null
          return _vm.onDelete.apply(null, arguments)
        },
        paste: _vm.onPaste,
      },
    }),
    _vm._v(" "),
    _vm.selections.length > 0
      ? _c(
          "button",
          {
            staticClass: "btn btn-secondary dropdown-toggle",
            class: _vm.error != "" ? "is-invalid" : "",
            attrs: {
              type: "button",
              "data-bs-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
          },
          [_vm._v("Variables")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dropdown-menu token-dropdown" },
      [
        _vm._l(_vm.selections, function (s) {
          return s.id == null
            ? _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addToken(s)
                    },
                  },
                },
                [_vm._v(_vm._s(s.name))]
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _vm.showDivider()
          ? _c("div", {
              staticClass: "dropdown-divider",
              attrs: { role: "separator" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.selections, function (s) {
          return s.id != null
            ? _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addToken(s)
                    },
                  },
                },
                [_vm._v(_vm._s(s.name))]
              )
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }