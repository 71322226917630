<template>
    <div class="report-builder-section-settings">
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label class="form-label">Required Data Sets</label>
                    <div class="dropdown">
                        <button class="btn form-select btn-field" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
                        {{ (local_section.required_data_sets != null && local_section.required_data_sets.length > 0 ) ? getSelectedDataSetNames()  : 'Please Select' }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                            <label class="dropdown-item" v-for="item in data_set_types" :key="item.source_subtype" @click="changeDataSets(item.source_subtype)">
                                <input class="form-check-input" type="checkbox"  :checked="local_section.required_data_sets.includes(item.source_subtype)">
                                <span class="ms-2" >
                                    {{ item.source_subtype | propercase }}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <label class="form-label">Display Value Metric</label>
                <div class="input-group" >
                    <accordion-select id="metrics-multi-select" :options="display_value_metric_options" 
                        v-model="local_section.display_value_metric_id" @input="updateSection()" 
                        :field_name="'display_value_metric'" :show_hidden_fields="true" 
                        :class="{'is-invalid' :local_errors.display_value_metric_id != ''}" >
                    </accordion-select>
                    <button type="button" id="add-display-value-metric" class="btn btn-secondary"
                    data-bs-toggle="modal" :data-bs-target="'#add-section-metric-filter-modal-'+ section_index"
                    @click="addPageFilter('metric', 'display_value_metric')">
                        <i class="fas fa-plus" data-bs-toggle="tooltip"
                        title="Creating a new Metric option will add it to the Metrics option dropdown."></i>
                    </button>
                </div>
                <div class="text-danger invalid-feedback mb-3" v-if="local_errors.display_value_metric_id != ''">
                    {{local_errors.display_value_metric_id}}
                </div>
            </div>

            <div class="col-3">
                <label class="form-label">Display Metric Name <i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" title="What title should describe the display value metric on the dashboard?"></i></label>
                <input type="text" id="section-display-name" class="form-control"
                    v-model="local_section.display_value_name" :class="((local_errors.display_value_name != '' )? 'is-invalid':'')" @change="clearErrorForField('display_value_name')">
                <div class="text-danger invalid-feedback mb-3" v-if="local_errors.display_value_name != ''">
                    {{local_errors.display_value_name}}
                </div>
            </div>

            <div class="col-3">
                <label class="form-label">Prioritization Metric</label>
                <div class="input-group" >
                    <accordion-select id="metrics-multi-select" :options="prioritization_metric_options" 
                        v-model="local_section.prioritization_metric_id" @input="updateSection()" 
                        :field_name="'prioritization_metric'" :show_hidden_fields="true" 
                        :class="{'is-invalid' :local_errors.prioritization_metric_id != ''}" >
                    </accordion-select>
                    <button type="button" id="add-prioritization-metric" class="btn btn-secondary"
                    data-bs-toggle="modal" :data-bs-target="'#add-section-metric-filter-modal-'+ section_index"
                    @click="addPageFilter('metric', 'prioritization_metric')">
                        <i class="fas fa-plus" data-bs-toggle="tooltip"
                        title="Creating a new Metric option will add it to the Metrics option dropdown."></i>
                    </button>                   
                </div>
                <div class="text-danger invalid-feedback mb-3" v-if="local_errors.prioritization_metric_id != ''">
                    {{local_errors.prioritization_metric_id}}
                </div>                
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                <nav class="bg-secondary mt-0 rounded">
                    <div class="d-flex pt-3 px-2">            
                        <span class="py-1 ms-1 me-3 mb-0 h5">Section Filters:</span>
                        <div class="input-group" >
                            <v-select :options="section_filter_options" placeholder="Select the Filter to add to Section..."
                                :searchable="true" class="searchable-select column-display w-50"
                                :selectable="(option) => option.text != ''"
                                label="text" @search="searchSectionFilterOption"
                                @input="emitSectionFilterChange"
                                :filterable="false">
                                <template #selected-option="{ text, category }">
                                    <span>{{"Select the Filter to add to Section..."}}</span>
                                </template>

                                <template #option="{ text, category }">
                                    <div class="suboption" :data-subcategory="category"
                                        :class="category==null || category==''? 'show' : ''">
                                        {{ text }}
                                    </div>
                                </template>
                                
                                <template #no-options="{ search, searching, loading }">
                                    <div class="suboption show">
                                        <em>No results found</em>
                                    </div>
                                </template>
                            </v-select>

                            <button class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#add-section-metric-filter-modal-'+ section_index" @click="addPageFilter('filter', 'section-filter')" >
                                <i class="fa fa-btn fa-plus"></i>
                            </button>                                 
                        </div>
                        
                    </div>    
                        
                    <div class="d-flex py-2 px-2">
                        <span class="badge badge-dark me-2" v-for="(filter,index) in local_section.section_filters">
                            &nbsp;{{ filter.admin_name }} <i class="fa fa-remove" id="remove-filter" @click="removeSectionFilterByIndex(index)"></i>
                        </span>
                    </div>
                </nav>
            </div>
        </div>

        <div class="row mt-2" >
            <div class="col-12">
                <div class="mb-3">
                    <label class="form-label">Section Name</label>
                    <input type="text" id="section-title-input" class="form-control"
                        v-model="local_section.title" :class="((local_errors.section_name != '' )? 'is-invalid':'')" @change="clearErrorForField('section_name')">
                    <div class="text-danger invalid-feedback" v-if="local_errors.section_name != ''">
                        {{local_errors.section_name}}
                    </div>
                </div>
            </div>

            <div class="col-12">
                <label class="form-label">Description <em>(Optional)</em></label>
                <textarea id="section-description" class="form-control full-height" 
                v-model="local_section.description" ref="textarea"  @input="resizeTextarea()"></textarea>
            </div>
        </div>

        <div class="row mt-2" >      
            <div class="col-12">
                <label class="form-label">Charts:</label>
                <div class="text-danger invalid-feedback" v-if="local_errors.if_has_primary_chart != ''">
                    {{local_errors.if_has_primary_chart}}
                </div>
                
                <div v-if="local_section.charts && local_section.charts.length >0" class="row chart-container" >
                    <div v-for="(chart, chart_index) in local_section.charts" class="chart-tile" :class="loadChartSize(chart_index)"> 
                        <div class="card mx-2 mb-4">
                            <div class="card-body text-center tile-body p-0">
                                
                                <div class="tile-content w-100">
                                    <div class="chart-button-row mb-5 float-end">
                                        <button class="btn solid-border p-0" :class="((chart.is_primary)? 'btn-primary':'')" @click="setPrimaryChart(chart_index)"><i class="ms-1 fa-solid fa-star"></i></button>
                                        <button class="btn solid-border py-0 px-1" :class="((chart.size == 'small' )? 'btn-primary':'')" @click="selectChartSize(chart_index, 'small')">Small</button>
                                        <button class="btn solid-border py-0 px-1" :class="((chart.size == 'medium' )? 'btn-primary':'')" @click="selectChartSize(chart_index, 'medium')">Medium</button>
                                        <button class="btn solid-border py-0 px-1" :class="((chart.size == 'large' )? 'btn-primary':'')" @click="selectChartSize(chart_index, 'large')">Large</button>
                                        <button class="btn solid-border py-0 px-1" :class="((chart.size == 'x-large' )? 'btn-primary':'')" @click="selectChartSize(chart_index, 'x-large')">X-Large</button>

                                        <button class="btn solid-border p-0" data-bs-toggle="modal" :data-bs-target="'#update-chart-modal-'+ section_index" @click="editChart(chart_index)"><i class="ms-1 fa fa-btn fa-edit"></i></button>

                                        <button class="btn solid-border p-0" @click="deleteChart(chart_index)"><i class="ms-1 fa fa-btn fa-trash"></i></button>
                                    </div>

                                    <div class="chart-content-row mb-4 w-100 d-flex text-start">
                                        <i v-if="chart.type == 'scorecard'" class="ms-5 fa-4x fa-solid fa-address-card" ></i>
                                        <i v-if="chart.type == 'pie'" class="ms-5 fa-4x fa-solid fa-chart-pie" ></i>
                                        <i v-if="chart.type == 'line' || chart.type == 'bar_line' || chart.type=='cumulative_line'" class="ms-5 fa-4x fa-solid fa-chart-line" ></i>
                                        <i v-if="chart.type == 'column' || chart.type == 'stacked_column' || chart.type == '100_percent_stacked_column'" class="ms-5 fa-4x fa-solid fa-chart-column" ></i>
                                        <i v-if="chart.type == 'horizontal_bar' || chart.type == 'stacked_bar' || chart.type == '100_percent_stacked_bar'" class="ms-5 fa-4x fa-solid fa-chart-bar" ></i>
                                        <i v-if="chart.type == 'table'" class="ms-5 fa-4x fa-solid fa-table" ></i>
                                        <i v-if="chart.type == 'textbox'" class="ms-5 fa-4x fa-solid fa-quote-left" ></i>

                                        <div class="ms-5" v-if="chart.type != 'textbox'">
                                            <p class="mt-2 mb-0 ">Title: <em>{{ chart.title }}</em></p>
                                            <p class="mt-2 mb-0">Subtitle: <em>{{ chart.subtitle }}</em></p>
                                        </div>
                                        <div v-else class="ms-5">
                                            <p class="mt-2 mb-0">Title: {{chart.title}}</p>
                                        </div>
                                    </div>

                                    <div class="chart-reorder-row">
                                        <button v-if="chart_index!=0" type="button" class="reorder-btn float-start" @click="moveChartUp(chart_index)"><i class="fa fa-arrow-left"></i></button>
                                        <button v-if="chart_index!=local_section.charts.length-1" type="button" class="reorder-btn float-end" v-on:click="moveChartDown(chart_index)"><i class="fa fa-arrow-right"></i></button>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <p v-else>No chart has been added to this section yet. Please click on the "Add Chart" button in the footer to add charts to the active section.</p>
            </div>
        </div>

        <!-- <div class="row mb-2">
            <div class="col">
                <div class="alert alert-success mt-3" v-if="form.successful">
                    The Section's information has been updated!
                </div>
                <div class="alert alert-danger mt-3" v-if="form.error">
                    There was an error saving the Section's information.
                </div>
                <div class="mt-3">
                    <button type="submit" class="btn btn-secondary" id="save-button"
                            @click.prevent="saveSection()"s
                            :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> Save Section
                    </button>
                </div>
            </div>
        </div> -->

        <div class="modal fade" :id="'add-section-metric-filter-modal-'+ section_index" aria-labelledby="add-section-metric-filter-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end close-modal" :data-bs-dismiss="'add-section-metric-filter-modal-'+ section_index"
                                 @click="closeModal('add-section-metric-filter-modal-'+ section_index)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">{{ mf_type | propercase}} Builder</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <metric-filter-builder :modal_id="'add-section-metric-filter-modal-'+ section_index" :data_blend="data_blend" ref="mf_builder" :client_id="page_client_id"
                        :default_metric_format="default_metric_format" :type="mf_type" @update="sectionMetricFilterAdded"></metric-filter-builder>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" :id="'update-chart-modal-'+ section_index" aria-labelledby="update-chart-modal" data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" :data-bs-dismiss="'update-chart-modal-'+ section_index" @click.prevent="closeModal('update-chart-modal-'+ section_index)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">Update Chart in {{ local_section.title ? local_section.title : 'New Section(unsaved)' }}</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 ">
                        <section-chart-modal :modal_action="'Update'" :charts="charts" :section_chart="local_section.charts[this.chart_index_editing]" 
                        :section_index="section_index" @chartUpdated="updateChartInSection"></section-chart-modal>  
                    </div>
                </div>
            </div>   
        </div>
        
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import vSelect from "vue-select";

    export default {
        components: {
            Swal, vSelect
        },
        props: ['section_selected', 'metrics_filters', 'charts', 'data_blend', 'data_set_types', 'section_errors', 'page_client_id', 'section_index'],
        data() {
            return {
                local_metrics_filters:[],
                section_filter_options:[],
                display_value_metric_options: [],
                prioritization_metric_options: [],

                mf_type: 'filter',
                mf_field_to_add: '',
                local_section: {},
                default_metric_format: null,
                chart_index_editing: null,
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                local_errors: {}
            };
        },
        watch:{
            section_selected(new_value, old_value){
                this.local_section = this.section_selected;
                this.loadSectionChartTypes();
                this.setupFilters();
                // if (this.local_section.charts && this.local_section.charts.length > 1){
                //     this.sortChartByOrder();
                // }
            },
            section_errors(new_value, old_value){
                this.local_errors = this.section_errors;
            },
        },
        beforeMount() {
            this.local_section = this.section_selected;
            this.loadSectionChartTypes();
            this.local_errors = this.section_errors;
            if (this.data_blend.type == "constituent"){
                this.local_section.required_data_sets = ["constituents","transactions"]
            }

            this.local_section.section_filters = [];
            this.local_metrics_filters = [...this.metrics_filters];
            this.section_filter_options = this.getAllSectionFilterOptions();
            this.display_value_metric_options = this.getDisplayValueMetricOptions();
            this.prioritization_metric_options = this.getPrioritizationMetricOptions();

            this.setupFilters();
            if (this.local_section.charts && this.local_section.charts.length > 1){
                this.sortChartByOrder();
            }
        },
        methods: {
            sortChartByOrder(){
                this.local_section.charts = this.local_section.charts.sort(function(a, b){
                    return a.order - b.order;
                });
            },
            loadSectionChartTypes(){ 
                // this is to load chart type into this.local_section.charts so we can display chart icon based on type
                if (this.local_section.charts && this.local_section.charts.length !=0){
                    for(var i=0; i<this.local_section.charts.length; i++){
                        let chart_obj = this.charts.find(c=> c.id == this.local_section.charts[i].report_chart_id);
                        this.local_section.charts[i].type = chart_obj.type;
                    }
                }
            },
            //Translate the section filters into the UI equivalent
            setupFilters() {
                this.local_section.section_filters = [];
                if(this.section_selected.filters != null)
                    this.section_selected.filters.forEach((filter)=>{
                        var obj = this.local_metrics_filters.find((mf)=>mf.id == filter.report_metrics_filters_id);
                        if(obj != null){
                            this.local_section.section_filters.push(obj);
                            this.section_filter_options = this.section_filter_options.filter(option => option.value != obj.id);
                        }
                    });
            },

            getAllSectionFilterOptions(){
                var filter_list = [];
                this.local_metrics_filters.forEach((mf)=>{
                    // push if the mf not in the selected list
                    if (this.local_section.section_filters.indexOf(mf) < 0){
                        if(mf.type=='filter'){
                            filter_list.push({
                                category:"",
                                text: mf.admin_name,
                                type: mf.format,
                                value: mf.id,
                            })
                        }
                    }
                });
                return filter_list;
            },
            getDisplayValueMetricOptions(){
                var metric_list = [];
                this.local_metrics_filters.forEach((mf)=>{
                    if (this.display_value_metric_options.indexOf(mf) < 0){
                        if(mf.type=='metric'){
                            metric_list.push({
                                category:"",
                                text: mf.admin_name,
                                type: mf.format,
                                value: mf.id.toString(),
                            })
                        }
                    }
                });
                return metric_list;
            },
            getPrioritizationMetricOptions(){
                var metric_list = [];
                this.local_metrics_filters.forEach((mf)=>{
                    if (this.display_value_metric_options.indexOf(mf) < 0){
                        if(mf.type=='metric' && mf.format == 'percent'){
                            metric_list.push({
                                category:"",
                                text: mf.admin_name,
                                type: mf.format,
                                value: mf.id.toString(),
                            })
                        }
                    }
                });
                return metric_list;
            },
            updateSection(){
                this.$emit('updateSection', this.local_section);

            },
            searchSectionFilterOption(searchKey){
                var option_list = this.getAllSectionFilterOptions();
                if(searchKey == "") 

                    this.section_filter_options = option_list;
                else    
                    this.section_filter_options = option_list.filter(option => option.text.toLowerCase().includes(searchKey.toLowerCase()));
            },
            emitSectionFilterChange(event){
                var filter_object = this.local_metrics_filters.find((filter)=>filter.id == event.value);
                this.local_section.section_filters.push(filter_object);
                this.section_filter_options = this.section_filter_options.filter(option => option.value != event.value);

                this.$emit('updateSection', this.local_section);
            },
            removeSectionFilterByIndex(index){
                this.local_section.section_filters.splice(index, 1);
                // reset option dropdown list
                this.section_filter_options = this.getAllSectionFilterOptions();
            },
            sectionMetricFilterAdded(mf) {
                this.local_metrics_filters.push(mf);

                // sort this.local_metric_filter
                this.local_metrics_filters.sort((a, b) => (a.admin_name.toLowerCase() > b.admin_name.toLowerCase()) ? 1 : -1);

                if(this.mf_type == 'filter'){ // add to section filter dropdown
                    this.section_filter_options = this.getAllSectionFilterOptions();
                } else { // add to display_value_metric dropdown and prioritization_metric dropdown
                    this.display_value_metric_options = this.getDisplayValueMetricOptions();
                    this.prioritization_metric_options = this.getPrioritizationMetricOptions();
                }
                this.closeModal('add-section-metric-filter-modal-' + section_index);
            },
            clearErrorForField(field_name){
                this.local_errors[field_name] = '';
            },
            closeModal(modal_ID){
                var myModalEl = document.getElementById(modal_ID)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                // this.$refs.mf_builder.setMetricFilter(null);
                modal.hide();
            },
            changeDataSets(dataset){
                const index = this.local_section.required_data_sets.indexOf(dataset);
                if (index >= 0){
                    //remove from the array
                    this.local_section.required_data_sets.splice(index, 1)
                } else {
                    this.local_section.required_data_sets.push(dataset);
                }
                this.$emit('updateSection', this.local_section);
            }, 
            editChart(chart_index){
                this.chart_index_editing = chart_index;
            },
            updateChartInSection(section_chart_detail){
                this.local_section.charts[this.chart_index_editing].report_chart_id = section_chart_detail.chart_selected.id;
                this.local_section.charts[this.chart_index_editing].title = section_chart_detail.title;
                this.local_section.charts[this.chart_index_editing].subtitle = section_chart_detail.subtitle;
                this.closeModal('update-chart-modal-'+ section_chart_detail.section_index);
            },
            addPageFilter(option, field) {
                // this.$refs.mf_builder.setMetricFilter(null);
                this.mf_type = option;
                this.mf_field_to_add = field;

                if (field == 'prioritization_metric'){
                    this.default_metric_format = 'percent';
                }
            },
            getSelectedDataSetNames(){
                var names = [];
                this.local_section.required_data_sets.forEach((datasets)=>{
                    var name =  datasets.replaceAll("_", " ").replace( /\w\S*/g,
                        function(txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                        });
                    names.push(name);
                })
                return names.join(', ');
            },
            resizeTextarea() {
                var element = document.querySelectorAll('textarea')[0];
                if (element && element.style){
                    element.style.height = '';
                    element.style.height = Math.max(element.offsetHeight, element.scrollHeight) + "px";
                }        
                // this.$forceUpdate();                  
            },
            deleteChart(index){
                this.local_section.charts.splice(index, 1);
                this.$emit('updateSection', this.local_section);
            },
            selectChartSize(chart_index, size_selected){
                this.local_section.charts[chart_index].size = size_selected;
                this.$emit('updateSection', this.local_section);
                this.$forceUpdate();
            },
            loadChartSize(chart_index){
                switch (this.local_section.charts[chart_index].size) {
                    case 'small':
                        return 'col-4';
                    case 'medium':
                        return 'col-6';
                    case 'large':
                        return 'col-8'
                    case 'x-large':
                        return 'col-12'
                    default:
                        return 'col';
                }
            },
            setPrimaryChart(chart_index){
                for(var i = 0; i < this.local_section.charts.length; i++){
                    if(i == chart_index)
                        this.local_section.charts[i].is_primary = true;
                    else
                        this.local_section.charts[i].is_primary = false;
                }
                this.clearErrorForField('if_has_primary_chart');
                this.$emit('updateSection', this.local_section);
            },
            moveChartUp(chart_index){
                var current_chart = {...this.local_section.charts[chart_index]};
                var previous_chart = {...this.local_section.charts[chart_index-1]};

                current_chart.order = this.local_section.charts[chart_index-1].order;
                previous_chart.order = this.local_section.charts[chart_index].order;

                // switch the two sections' order on the UI
                this.local_section.charts[chart_index-1] = current_chart;
                this.local_section.charts[chart_index] = previous_chart;
                this.$emit('updateSection', this.local_section);
                this.$forceUpdate();
            },
            moveChartDown(chart_index){
                var current_chart = {...this.local_section.charts[chart_index]};
                var next_chart = {...this.local_section.charts[chart_index+1]};

                current_chart.order = this.local_section.charts[chart_index+1].order;
                next_chart.order = this.local_section.charts[chart_index].order;

                // switch the two sections' order on the UI
                this.local_section.charts[chart_index+1] = current_chart;
                this.local_section.charts[chart_index] = next_chart;
                this.$emit('updateSection', this.local_section);
                this.$forceUpdate();
            }
        }
    }
</script>
