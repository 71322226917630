var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "onboarding-connect-crm" }, [
    _c("input", {
      attrs: { type: "hidden", id: "client_url" },
      domProps: { value: _vm.local_client.url },
    }),
    _vm._v(" "),
    _c("h2", { staticClass: "mt-2 mb-0" }, [
      _vm._v("Step 4: Make Your First Connection!"),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "To get custom-built benchmarks, " +
          _vm._s(
            _vm.crm == null
              ? "upload your donor data"
              : "connect " + _vm.crm.display_name
          ) +
          " to see your organization's key metrics compared to similar nonprofits."
      ),
    ]),
    _vm._v(" "),
    _vm.connection_option == "account"
      ? _c("div", [
          _c("h3", { staticClass: "mt-4" }, [
            _c("img", {
              staticClass: "avatar rounded-circle avatar-sm me-2 bg-white",
              attrs: { src: _vm.crm.logo_url, alt: _vm.crm.display_name },
            }),
            _vm._v(_vm._s(_vm.crm.display_name)),
          ]),
          _vm._v(" "),
          _vm.selected_crm_info.type == "credentials"
            ? _c("div", [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.selected_crm_info.instructions),
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "system-connection-fields row mt-2" },
                  _vm._l(
                    _vm.selected_crm_info.field,
                    function (field, field_index) {
                      return _c("div", { staticClass: "mb-1" }, [
                        field.type != "checkbox" && field.type != "hidden"
                          ? _c("label", { staticClass: "form-label" }, [
                              _vm._v(
                                _vm._s(field.label) +
                                  "\n                        "
                              ),
                              field.tooltip && field.tooltip.length > 0
                                ? _c("i", {
                                    staticClass: "fas fa-info-circle",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title: field.tooltip,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "text"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.value,
                                  expression: "field.value",
                                },
                              ],
                              staticClass: "form-control",
                              class: field.error ? "is-invalid" : "",
                              attrs: { type: "text", name: field.id },
                              domProps: { value: field.value },
                              on: {
                                change: function ($event) {
                                  field.error = false
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(field, "value", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "label"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.value,
                                  expression: "field.value",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                disabled: "disabled",
                                name: field.id,
                              },
                              domProps: { value: field.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(field, "value", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "textarea"
                          ? _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.value,
                                  expression: "field.value",
                                },
                              ],
                              staticClass: "form-control full-height",
                              attrs: { name: field.id },
                              domProps: { value: field.value },
                              on: {
                                change: function ($event) {
                                  field.error = false
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(field, "value", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "password" ||
                        field.type == "password-text"
                          ? _c("div", { staticClass: "input-group" }, [
                              (field.type == "password"
                                ? "password"
                                : "text") === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.value,
                                        expression: "field.value",
                                      },
                                    ],
                                    ref: "password",
                                    refInFor: true,
                                    staticClass:
                                      "form-control password-input input_v1",
                                    class: field.error ? "is-invalid" : "",
                                    attrs: {
                                      "aria-label": "Password",
                                      "aria-describedby": "password-addon",
                                      autocomplete: "off",
                                      name: field.id,
                                      name: "password",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(field.value)
                                        ? _vm._i(field.value, null) > -1
                                        : field.value,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = field.value,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  field,
                                                  "value",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  field,
                                                  "value",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(field, "value", $$c)
                                          }
                                        },
                                        function ($event) {
                                          field.error = false
                                        },
                                      ],
                                    },
                                  })
                                : (field.type == "password"
                                    ? "password"
                                    : "text") === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.value,
                                        expression: "field.value",
                                      },
                                    ],
                                    ref: "password",
                                    refInFor: true,
                                    staticClass:
                                      "form-control password-input input_v1",
                                    class: field.error ? "is-invalid" : "",
                                    attrs: {
                                      "aria-label": "Password",
                                      "aria-describedby": "password-addon",
                                      autocomplete: "off",
                                      name: field.id,
                                      name: "password",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(field.value, null),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          return _vm.$set(field, "value", null)
                                        },
                                        function ($event) {
                                          field.error = false
                                        },
                                      ],
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.value,
                                        expression: "field.value",
                                      },
                                    ],
                                    ref: "password",
                                    refInFor: true,
                                    staticClass:
                                      "form-control password-input input_v1",
                                    class: field.error ? "is-invalid" : "",
                                    attrs: {
                                      "aria-label": "Password",
                                      "aria-describedby": "password-addon",
                                      autocomplete: "off",
                                      name: field.id,
                                      name: "password",
                                      type:
                                        field.type == "password"
                                          ? "password"
                                          : "text",
                                    },
                                    domProps: { value: field.value },
                                    on: {
                                      change: function ($event) {
                                        field.error = false
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          field,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "input-group-text",
                                  staticStyle: {
                                    "border-left": "none",
                                    "background-color": "white",
                                  },
                                  attrs: {
                                    title: !field.visible
                                      ? "Show password"
                                      : "Hide password",
                                    id: "password-addon",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleVisibility(field)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa",
                                    class: [
                                      !field.visible
                                        ? "fa-eye"
                                        : "fa-eye-slash",
                                    ],
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "select"
                          ? _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: field.value,
                                      expression: "field.value",
                                    },
                                  ],
                                  staticClass: "form-select",
                                  class: field.error ? "is-invalid" : "",
                                  attrs: { name: field.id },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          field,
                                          "value",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        field.error = false
                                      },
                                    ],
                                  },
                                },
                                _vm._l(
                                  Object.keys(field.options),
                                  function (option) {
                                    return _c(
                                      "option",
                                      { domProps: { value: option } },
                                      [_vm._v(_vm._s(field.options[option]))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "warning"
                          ? _c("div", [
                              _c(
                                "p",
                                { staticStyle: { "margin-top": "-8px" } },
                                [
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(_vm._s(field.text)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-warning",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _c("code", {
                                    domProps: {
                                      innerHTML: _vm._s(field.value),
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "vue-select"
                          ? _c(
                              "div",
                              [
                                _c("accordion-select", {
                                  class: field.error ? "is-invalid" : "",
                                  attrs: {
                                    options: field.options,
                                    name: field.id,
                                  },
                                  on: {
                                    change: function ($event) {
                                      field.error = false
                                    },
                                  },
                                  model: {
                                    value: field.value,
                                    callback: function ($$v) {
                                      _vm.$set(field, "value", $$v)
                                    },
                                    expression: "field.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "checkbox"
                          ? _c(
                              "div",
                              {
                                staticClass: "form-check",
                                class: field.error ? "is-invalid" : "",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: field.value,
                                      expression: "field.value",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "checkbox",
                                    value: "true",
                                    id: field.id,
                                    name: field.id,
                                  },
                                  domProps: {
                                    checked: Array.isArray(field.value)
                                      ? _vm._i(field.value, "true") > -1
                                      : field.value,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = field.value,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "true",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              field,
                                              "value",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              field,
                                              "value",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(field, "value", $$c)
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: field.id },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(field.label) +
                                        "\n                            "
                                    ),
                                    field.tooltip && field.tooltip.length > 0
                                      ? _c("i", {
                                          staticClass: "fas fa-info-circle",
                                          attrs: {
                                            "data-bs-toggle": "tooltip",
                                            title: field.tooltip,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        field.type == "tables"
                          ? _c(
                              "div",
                              { attrs: { id: field.id } },
                              [
                                _vm._m(0, true),
                                _vm._v(" "),
                                _vm._l(field.value, function (row, row_index) {
                                  return _c(
                                    "div",
                                    { staticClass: "row mb-3" },
                                    [
                                      _c("div", { staticClass: "col-1" }, [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "text-end mb-0 mt-1 nums",
                                          },
                                          [_vm._v(_vm._s(row_index + 1) + ".")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-sm-7" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                field.value[row_index][
                                                  "table_name"
                                                ],
                                              expression:
                                                "field.value[row_index]['table_name']",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class:
                                            field.error &&
                                            field.value[row_index][
                                              "table_name"
                                            ] == ""
                                              ? "is-invalid"
                                              : "",
                                          attrs: {
                                            name: "table_name[]",
                                            type: "text",
                                          },
                                          domProps: {
                                            value:
                                              field.value[row_index][
                                                "table_name"
                                              ],
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                field.value[row_index],
                                                "table_name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col" }, [
                                        _c("select", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                field.value[row_index][
                                                  "table_type"
                                                ],
                                              expression:
                                                "field.value[row_index]['table_type']",
                                            },
                                          ],
                                          staticClass: "form-select",
                                          class:
                                            field.error &&
                                            field.value[row_index][
                                              "table_type"
                                            ] == ""
                                              ? "is-invalid"
                                              : "",
                                          attrs: { name: "table_type[]" },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.bigquery_table_type_html
                                            ),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  field.value[row_index],
                                                  "table_type",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                field.error = false
                                              },
                                            ],
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      field.value.length > 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-1 delete_table",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-none",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeTable(
                                                        field_index,
                                                        row_index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-trash",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                field.error
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(field.error_msg) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary mt-3 mb-4",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addTable(field_index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-fw fa-plus",
                                    }),
                                    _vm._v(" Add Table"),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        field.error && field.type != "tables"
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(field.error_msg) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }
                  ),
                  0
                ),
                _vm._v(" "),
                _vm.form.error
                  ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.form.msg) },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-100 mt-2",
                        attrs: { type: "submit", disabled: _vm.processing },
                        on: {
                          click: function ($event) {
                            return _vm.nextStep()
                          },
                        },
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("div", {
                                staticClass:
                                  "spinner-border spinner-border-sm text-white float-left",
                                attrs: { role: "status" },
                              }),
                              _vm._v("Processing\n                        "),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                                Connect "
                              ),
                              _c("i", { staticClass: "fa fa-arrow-right" }),
                            ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm.selected_crm_info.type == "oauth"
            ? _c("div", [
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      "Avid AI connects to " +
                        _vm._s(_vm.crm.display_name) +
                        " through a one-click authentication."
                    ),
                  ]),
                  _vm._v(
                    " To connect your instance, click on the button below—you will be redirecting to " +
                      _vm._s(_vm.crm.display_name) +
                      " to complete the process. If additional details are needed after connecting, you'll be prompted when you get back."
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary w-100",
                        attrs: { href: _vm.selected_crm_info.url },
                      },
                      [
                        _c("img", {
                          staticClass: "icon p-0",
                          attrs: {
                            src: "/img/icons/dialexa-icons/profile-tick.svg",
                          },
                        }),
                        _vm._v(" Authenticate\n                "),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mt-2 text-end" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "/year_end_benchmarks/" + this.local_client.url,
                  },
                },
                [
                  _vm._v(
                    "\n                    (Skip for now and view the benchmarks)\n                "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card mt-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", [_vm._v("Can't connect your CRM yourself?")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary w-100 mt-2",
                      attrs: { type: "button", disabled: _vm.processing },
                      on: {
                        click: function ($event) {
                          _vm.connection_option = "file_upload"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Upload Files\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary w-100 mt-2",
                      attrs: { type: "button", disabled: _vm.processing },
                      on: {
                        click: function ($event) {
                          _vm.connection_option = "send_to_colleague"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Ask a Colleague for Help\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.connection_option == "file_upload"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "file-dropzone my-2" },
              [
                _c("vue-dropzone", {
                  ref: "myVueDropzone",
                  staticClass: "dropzone",
                  attrs: { id: "dropzone", options: _vm.dropzoneOptions },
                  on: {
                    "vdropzone-processing": _vm.onProcessing,
                    "vdropzone-success": _vm.onSuccess,
                    "vdropzone-sending": _vm.onSending,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.if_uploaded
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-primary",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      "\n                This file has been uploaded and is processing. We'll notify you once it is ready for mapping. "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/year_end_benchmarks/" + _vm.client.url,
                        },
                      },
                      [_vm._v("Until then, you can explore Avid's benchmarks.")]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._m(2),
          ]),
          _vm._v(" "),
          _vm.if_uploaded == false
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mt-2 text-end" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/year_end_benchmarks/" + this.local_client.url,
                      },
                    },
                    [
                      _vm._v(
                        "\n                    (Skip for now and view the benchmarks)\n                "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card mt-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", [_vm._v("Need help uploading files?")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.crm != null
                  ? _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary w-100 mt-2",
                          attrs: { type: "button", disabled: _vm.processing },
                          on: {
                            click: function ($event) {
                              _vm.connection_option = "account"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            1-Click Integration\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn w-100 mt-2",
                      class: _vm.crm == null ? "btn-secondary" : "btn-primary",
                      attrs: { type: "button", disabled: _vm.processing },
                      on: {
                        click: function ($event) {
                          _vm.connection_option = "send_to_colleague"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Ask a Colleague for Help\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.crm == null
                  ? _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-secondary w-100 mt-2",
                          attrs: {
                            target: "_blank",
                            href: "https://share.hsforms.com/1GwisSMM2TTKAQ8uKpNUBpQram1t",
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Get Help from Avid Staff\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.connection_option == "send_to_colleague"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mb-3 col-6" }, [
              _c("label", [_vm._v("First Name *")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.send_to_colleague.first_name,
                    expression: "send_to_colleague.first_name",
                  },
                ],
                staticClass: "form-control",
                class:
                  _vm.send_to_colleague_errors.first_name != ""
                    ? "is-invalid"
                    : "",
                attrs: { type: "text", name: "first_name" },
                domProps: { value: _vm.send_to_colleague.first_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.send_to_colleague,
                      "first_name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.send_to_colleague_errors.first_name != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.send_to_colleague_errors.first_name) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 col-6" }, [
              _c("label", [_vm._v("Last Name *")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.send_to_colleague.last_name,
                    expression: "send_to_colleague.last_name",
                  },
                ],
                staticClass: "form-control",
                class:
                  _vm.send_to_colleague_errors.last_name != ""
                    ? "is-invalid"
                    : "",
                attrs: { type: "text", name: "last_name" },
                domProps: { value: _vm.send_to_colleague.last_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.send_to_colleague,
                      "last_name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.send_to_colleague_errors.last_name != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.send_to_colleague_errors.last_name) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 col-12" }, [
              _c("label", [_vm._v("Email *")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.send_to_colleague.email,
                    expression: "send_to_colleague.email",
                  },
                ],
                staticClass: "form-control",
                class:
                  _vm.send_to_colleague_errors.email != "" ? "is-invalid" : "",
                attrs: { type: "text", name: "email" },
                domProps: { value: _vm.send_to_colleague.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.send_to_colleague,
                      "email",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.send_to_colleague_errors.email != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.send_to_colleague_errors.email) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary w-100 mt-2",
                  attrs: { type: "submit", disabled: _vm.processing },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep()
                    },
                  },
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("div", {
                          staticClass:
                            "spinner-border spinner-border-sm text-white float-left",
                          attrs: { role: "status" },
                        }),
                        _vm._v("Processing\n                    "),
                      ])
                    : _c("span", [
                        _vm._v("\n                        Send Invite "),
                        _c("i", { staticClass: "fa fa-arrow-right" }),
                      ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 mt-2 text-end" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "/year_end_benchmarks/" + this.local_client.url,
                  },
                },
                [
                  _vm._v(
                    "\n                    (Skip for now and view the benchmarks)\n                "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card mt-3" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", [_vm._v("Want to set up the integration yourself?")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.crm != null
                  ? _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary w-100 mt-2",
                          attrs: { type: "button", disabled: _vm.processing },
                          on: {
                            click: function ($event) {
                              _vm.connection_option = "account"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            1-Click Integration\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary w-100 mt-2",
                      attrs: { type: "button", disabled: _vm.processing },
                      on: {
                        click: function ($event) {
                          _vm.connection_option = "file_upload"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Upload Files\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-7" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v(
            "Dataset and Table Name\n                                    "
          ),
          _c("i", {
            staticClass: "fas fa-info-circle",
            attrs: {
              "data-bs-toggle": "tooltip",
              title:
                "The value below should be in the format of [DATASET].[TABLE NAME]",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Table Type")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Avid AI requires two file types: transactions and constituents. "
      ),
      _c(
        "a",
        { attrs: { href: "/templates/transactions.csv", target: "_blank" } },
        [_vm._v("Click here for an example file of transactions")]
      ),
      _vm._v(" and "),
      _c(
        "a",
        { attrs: { href: "/templates/constituents.csv", target: "_blank" } },
        [_vm._v("here for an example file of constituents.")]
      ),
      _vm._v(" "),
      _c("em", [_vm._v("(Required fields are denoted with a *.)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("small", [
        _vm._v(
          "Accepted File Types: .csv, .txt, and similar delimited text files."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }