var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pie-chart-template" }, [
    _c("canvas", {
      staticClass: "pie-chart",
      class: _vm.if_chart_has_data ? "" : "hide",
      attrs: { id: "chart-" + this.id },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-center",
        class: !_vm.if_chart_has_data ? "d-inline-block" : "hide",
      },
      [_vm._m(0), _vm._v(" "), _vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "text-secondary_v1 mb-1" }, [
      _c("i", { staticClass: "fa-solid fa-chart-simple" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-secondary_v1 mb-2" }, [
      _c("em", [
        _vm._v("Press the "),
        _c("strong", [_vm._v("Preview")]),
        _vm._v(" button to render the chart"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }