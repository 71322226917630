var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pathways-column-mapping" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-column gap-3 mb-4" },
      [
        _vm._l(_vm.mapping.slice(0, 2), function (column, i) {
          return _c(
            "section",
            {
              attrs: {
                id: i == 0 ? "primary-key-column-mapping" : "column-mapping",
              },
            },
            [
              i >= 1
                ? _c("div", { staticClass: "w-100 d-flex flex-column gap-2" }, [
                    _c("div", { staticClass: "d-flex flex-row gap-3" }, [
                      _c("div", { staticClass: "col-xl-8 col-12 card" }, [
                        _c(
                          "table",
                          { staticClass: "mapping-table" },
                          [
                            _vm._m(1, true),
                            _vm._v(" "),
                            _c(
                              "draggable",
                              {
                                attrs: { tag: "tbody", handle: ".handle" },
                                model: {
                                  value: _vm.mapping,
                                  callback: function ($$v) {
                                    _vm.mapping = $$v
                                  },
                                  expression: "mapping",
                                },
                              },
                              _vm._l(_vm.mapping, function (column, j) {
                                return j > 0
                                  ? _c("tr", { key: column.id }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "mapping-grid__source w-100",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-100 d-flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-group",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.errors[j].source !=
                                                      "",
                                                  },
                                                },
                                                [
                                                  _vm.isConstant(column)
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              column
                                                                .source_column
                                                                .value,
                                                            expression:
                                                              "column.source_column.value",
                                                          },
                                                        ],
                                                        key: column.id,
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-invalid":
                                                            _vm.errors[j]
                                                              .source != "",
                                                        },
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            column.source_column
                                                              .value,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateConstant(
                                                              column
                                                            )
                                                          },
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              column.source_column,
                                                              "value",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _c("accordion-select", {
                                                        key: column
                                                          .source_column.id,
                                                        class: {
                                                          "is-invalid":
                                                            _vm.errors[j]
                                                              .source != "",
                                                        },
                                                        attrs: {
                                                          options:
                                                            _vm.source_columns,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateDestination(
                                                              column
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            column.source_column
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              column.source_column,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "column.source_column.value",
                                                        },
                                                      }),
                                                  _vm._v(" "),
                                                  _vm.isConstant(column)
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-secondary p-0",
                                                          attrs: {
                                                            "data-bs-toggle":
                                                              "tooltip",
                                                            title:
                                                              "Use a value from a column",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleConstant(
                                                                column
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "icon px-1",
                                                            attrs: {
                                                              src: "/img/icons/dialexa-icons/map.svg",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-secondary p-0",
                                                          attrs: {
                                                            "data-bs-toggle":
                                                              "tooltip",
                                                            title:
                                                              "Use a constant value",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleConstant(
                                                                column
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "icon px-1",
                                                            attrs: {
                                                              src: "/img/icons/dialexa-icons/edit.svg",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              !column.is_primary_identifier
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "close-btn d-sm-none",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-none px-0",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeColumn(
                                                                j - 1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-close",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.errors[j].source != ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-danger invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.errors[j].source
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 d-flex align-items-center",
                                          },
                                          [_c("small", [_vm._v("Maps to")])]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "mapping-grid__destination w-100",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-100 d-flex" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      column.destination_column,
                                                    expression:
                                                      "column.destination_column",
                                                  },
                                                ],
                                                key: "dest" + column.id,
                                                staticClass: "form-control",
                                                class: {
                                                  "is-invalid":
                                                    _vm.errors[j].destination !=
                                                    "",
                                                },
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    column.destination_column,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.errors[j].destination =
                                                      ""
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      column,
                                                      "destination_column",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.errors[j].destination != ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-danger invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.errors[j]
                                                          .destination
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-none px-0 ms-2 handle border-0",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: "/img/icons/dialexa-icons/vertical-dots.svg",
                                                alt: "drag",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        j > 1
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "mapping-grid__button btn btn-none px-0",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeColumn(j)
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "icon p-1",
                                                  attrs: {
                                                    src: "/img/icons/dialexa-icons/trash.svg",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ])
                                  : _vm._e()
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xl-4 d-none d-xl-block drill-down pe-3",
                        },
                        [
                          _c("preview-table", {
                            attrs: {
                              client: _vm.client,
                              source: _vm.source,
                              source_columns: _vm.source_columns,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        }),
        _vm._v(" "),
        _c("div", { staticClass: "my-2 d-flex flex-row gap-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function ($event) {
                  return _vm.addColumnMapping()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-plus" }), _vm._v(" Add Column")]
          ),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    Object.keys(_vm.questions).length > 0
      ? _c(
          "div",
          { staticClass: "mb-3" },
          [
            _vm._m(2),
            _vm._v(" "),
            _vm._l(Object.keys(_vm.questions), function (key) {
              return _c("div", { key: key, staticClass: "ms-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column gap-1 form-check" },
                  [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.keys[key],
                            expression: "keys[key]",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.keys[key])
                            ? _vm._i(_vm.keys[key], null) > -1
                            : _vm.keys[key],
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.keys[key],
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(_vm.keys, key, $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.keys,
                                      key,
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.keys, key, $$c)
                              }
                            },
                            function ($event) {
                              return _vm.setPrioritization()
                            },
                          ],
                        },
                      }),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.questions[key]) },
                      }),
                    ]),
                  ]
                ),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "form-check form-switch d-inline-flex align-items-center gap-2 my-0 mb-3",
      },
      [
        _c("input", {
          staticClass: "form-check-input mt-0",
          attrs: { type: "checkbox", role: "switch" },
          domProps: { checked: _vm.advanced_setting },
          on: {
            change: function ($event) {
              return _vm.toggleAdvanced()
            },
          },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("Advanced Settings")]),
      ]
    ),
    _vm._v(" "),
    _vm.advanced_setting
      ? _c("div", { staticClass: "w-100 d-flex flex-column gap-2 mb-3 mt-4" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mapping",
              class: { "is-invalid": _vm.errors[0].source != "" },
              attrs: { id: "primary-key-column" },
            },
            [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.source.data_source.name) + " Source Column(s)"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("accordion-multi-select", {
                    key: _vm.mapping[0].id,
                    class: { "is-invalid": _vm.errors[0].source != "" },
                    attrs: {
                      options: _vm.source_columns,
                      field_name: "primary_key_column",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.selectKeyColumn($event, 0)
                      },
                    },
                    model: {
                      value: _vm.mapping[0].source_column,
                      callback: function ($$v) {
                        _vm.$set(_vm.mapping[0], "source_column", $$v)
                      },
                      expression: "mapping[0].source_column",
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors[0].source != ""
                    ? _c(
                        "div",
                        { staticClass: "text-danger invalid-feedback" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.errors[0].source) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mapping mb-4",
              attrs: { id: "prioritization-columns" },
            },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("label", [_vm._v("Prioritization Column(s)")]),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "mb-2 d-block text-muted" }, [
                  _vm._v(
                    "If there are multiple records with the same primary key, how should Avid AI determine which one to sync?"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "w-75 mb-2",
                    class:
                      _vm.prioritization_column.length > 1
                        ? "d-grid"
                        : "d-flex",
                  },
                  [
                    _vm._l(_vm.prioritization_column, function (priority, i) {
                      return [
                        _vm.prioritization_column.length > 1
                          ? _c("b", { staticClass: "fs-5" }, [
                              _vm._v(_vm._s(i + 1) + "."),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-group position-relative" },
                          [
                            _c("accordion-select", {
                              attrs: { options: _vm.source_columns },
                              model: {
                                value: priority.column,
                                callback: function ($$v) {
                                  _vm.$set(priority, "column", $$v)
                                },
                                expression: "priority.column",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  "data-bs-toggle": "dropdown",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    priority.sort == "DESC"
                                      ? "Descending"
                                      : "Ascending"
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-menu dropdown-menu-end",
                              },
                              [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          priority.sort = "ASC"
                                        },
                                      },
                                    },
                                    [_vm._v("Ascending")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          priority.sort = "DESC"
                                        },
                                      },
                                    },
                                    [_vm._v("Descending")]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.prioritization_column.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "mapping-grid__button btn btn-none px-0",
                                on: {
                                  click: function ($event) {
                                    return _vm.prioritization_column.splice(
                                      i,
                                      1
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon p-1",
                                  attrs: {
                                    src: "/img/icons/dialexa-icons/trash.svg",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    on: {
                      click: function ($event) {
                        return _vm.addPriorityColumn()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-plus" }),
                    _vm._v(" Add Prioritization"),
                  ]
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column gap-1" }, [
      _c("h5", [_vm._v("Columns to Export")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-1" }, [
        _vm._v(
          "Customize the data being exported to your file. This configuration will be saved for this segment moving forward."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _c("div", { staticClass: "flex-column" }, [
            _c("h5", [_vm._v("Source Column")]),
            _vm._v(" "),
            _c("p", [_vm._v("What column do you want to export?")]),
          ]),
        ]),
        _vm._v(" "),
        _c("th"),
        _vm._v(" "),
        _c("th", [
          _c("div", { staticClass: "flex-column" }, [
            _c("h5", [_vm._v("Output Name")]),
            _vm._v(" "),
            _c("p", [_vm._v("What header do you want to use for the column?")]),
          ]),
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "80px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column gap-1" }, [
      _c("h5", [_vm._v("Questions About Your Export")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "Depending upon how the export will be used, you may want more or less information. Answer the questions below to ensure you get the data you're looking for."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", [
      _vm._v("Advanced Settings "),
      _c("i", {
        staticClass: "fas fa-info-circle ms-2",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "The primary key is what determines a unique row of data and how that aligns to the destination.",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }