<template>
    <div id="org_settings_fiscal_year_donor">
        <div class="row">
            <h4>Fiscal Year & Donor Settings</h4>              
            <div class="col-sm-4 mb-3">
                <div class="form-group">
                    <label class="form-label">Fiscal Year Start Month</label>
                    <select class="form-select" name="local_client.fiscal_year_offset" v-model="local_client.fiscal_year_offset"  >
                        <option value="0">January</option>
                        <option value="1">February</option>
                        <option value="2">March</option>
                        <option value="3">April</option>
                        <option value="4">May</option>
                        <option value="5">June</option>
                        <option value="6">July</option>
                        <option value="7">August</option>
                        <option value="8">September</option>
                        <option value="9">October</option>
                        <option value="10">November</option>
                        <option value="11">December</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4 mb-3">
                <div class="form-group">
                    <label class="form-label">Donor Level Criteria</label>
                    <select class="form-select" name="local_client.giving_levels.split" v-model="local_client.giving_levels.split" >
                        <option value="HAC">Highest Annual Cumulative Giving</option>
                        <option value="HPC">Highest Previous Gift</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4 mb-3">
                <div class="form-group">
                    <label class="form-label">Preferred Reporting Period</label>
                    <select class="form-select" name="local_client.default_reporting_period" v-model="local_client.default_reporting_period" >
                        <option value="Calendar Year to Date">Calendar Year to Date</option>
                        <option value="Fiscal Year to Date">Fiscal Year to Date</option>
                        <option value="Trailing 12 Months">Trailing 12 Months</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <label class="form-label">Donor Levels</label>
            <div class="overflow-table">
                <table class="table table-responsive border rounded table-with-pagination">
                    <thead class="table-header-row">
                        <tr>
                            <th class='align-middle'>Name</th>
                            <th class='align-middle'>Minimum Threshold</th>
                            <th class='align-middle'  v-if="local_client.giving_levels.levels.length > 3" >Report on Upgrades? 
                                <i class="fas fa-info-circle text-white ms-2" data-bs-toggle="tooltip" 
                                title="AVID has special reporting set up to monitor donors who upgrade to a higher giving level. However, only two giving levels can be reported on at a time. Select those two levels below.">
                                </i>
                            </th>
                            <th class='align-middle' style="width:80px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(level, index) in local_client.giving_levels.levels" >
                            <td class='align-middle w-50' v-if="index != edit_index">{{ level.name }}</td>
                            <td class='align-middle w-50' v-else>
                                <input type="text" class="form-control form-control-sm" v-model="local_level.name" placeholder="" @change="changeDonorLevelInputs()">
                            </td>
                            <td class='align-middle' v-if="index != edit_index">
                                <span v-if="index == local_client.giving_levels.levels.length-1">&mdash;</span>
                                <span v-else>{{ level.min | currency }}</span>
                            </td>
                            <td class='align-middle' v-else>
                                <div class="input-group input-group-sm" v-if="index != local_client.giving_levels.levels.length-1">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" v-model="local_level.min"
                                        @keypress="isPositiveNumber($event)" @paste="cleanUpPaste" @change="changeDonorLevelInputs()">
                                </div>
                            </td>
                            <td class='align-middle' v-if="local_client.giving_levels.levels.length > 3" >
                                <div class="checkbox-animated mt-1 mb-0 ms-2" v-if="index != local_client.giving_levels.levels.length-1">
                                    <input :id="'checkbox_animated_'+index" type="checkbox" v-model="level.report" @change="limitChecks(index)">
                                    <label :for="'checkbox_animated_'+index" ><span class="check" ></span><span class="box"></span></label>
                                </div>
                            </td>
                            <td>
                                <span v-if="edit_index != index">
                                    <button class="btn btn-sm btn-none p-1" v-on:click="editLevel(index)" title="Edit"><i class="fas fa-fw fa-edit"></i></button>
                                </span>
                                <span v-else>
                                    <button class="btn btn-sm btn-none p-1" v-on:click="saveLevel(index)" title="Save" :disabled="!if_can_save_new_level"><i class="fas fa-save" ></i></button>
                                    <button class="btn btn-sm btn-none p-1 me-0" v-on:click="cancelLevel(index)" title="Cancel"><i class="fas fa-close"></i></button>
                                </span>
                                <span v-if="index != local_client.giving_levels.levels.length-1 && edit_index != index">
                                    <button class="btn btn-sm btn-none p-1 me-0" v-on:click="deleteLevel(index)" title="Delete"><i class="fas fa-fw fa-trash"></i></button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-danger invalid-feedback" v-if="errors.giving_levels != ''">
                {{errors.giving_levels}}
            </div>
        </div>
        <div class="d-flex mt-3">
            <button class="btn btn-secondary me-3" v-on:click="addLevel()" v-if="edit_index == -1" >
                <span><i class="fas fa-fw fa-plus"></i>Add Level</span>
            </button>      
            <button type="submit" class="btn btn-primary" @click.prevent="update" :disabled="form.busy">
                <i class="fa fa-btn fa-save"></i> Save Changes
            </button>            
        </div>

    </div>
</template>

<script>
    export default {
        props: ['client'],
        data() {
            return {
                local_client:{},
                edit_index: -1,
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors:{
                    giving_levels:''
                },
                if_can_save_new_level: true
            };
        },
        beforeMount() {
            this.local_client = this.client;

             if(this.local_client.giving_levels == null)
                this.local_client.giving_levels = this.getDefaultGivingLevels();

        },
        methods: {

            getDefaultGivingLevels() {
                return {
                    split: 'HPC',
                    levels: [
                        { name:"Major", min:10000, report: true },
                        { name:"Midlevel",min:1000, report: true },
                        { name:"Broadbase",min:0, report: false }
                    ]
                };
            },
            isPositiveNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            limitChecks(index) {
                //If there are more than two levels with report as true...
                var count = 0;
                for(var i = 0; i < this.local_client.giving_levels.levels.length; i++)
                    if(this.local_client.giving_levels.levels[i].report)
                        count++;

                //If there are too many checks
                if(count >= 3) {
                    //Skip the first one and then set next one encountered that is true to false
                    for(var i = 1; i < this.local_client.giving_levels.levels.length; i++)
                        if(i != index && this.local_client.giving_levels.levels[i].report) {
                            this.local_client.giving_levels.levels[i].report = false;
                            break;
                        }
                }
            },
            editLevel(index) {
                this.local_level = {
                    name: this.local_client.giving_levels.levels[index].name ,
                    min: this.local_client.giving_levels.levels[index].min,
                    report: this.local_client.giving_levels.levels[index].report
                };
                this.edit_index = index;
            },
            deleteLevel(index) {
                this.local_client.giving_levels.levels.splice(index, 1);
                this.resetAndSortLevels();
            },
            saveLevel(index) {
                if(this.checkIfCanSaveLevel(index)){
                    this.local_client.giving_levels.levels[index].name = this.local_level.name;
                    this.local_client.giving_levels.levels[index].min = this.local_level.min;
                    this.resetAndSortLevels();
                    this.resetLocalLevel();
                    this.edit_index = -1;
                    this.form.busy = false;                    
                }

            },
            cancelLevel(index) {
                this.edit_index = -1;
                this.form.busy = false;
                
                //If it was a newly added level, remove it
                if(this.local_client.giving_levels.levels[index].name == "" && !this.checkIfCanSaveLevel(index))
                    this.local_client.giving_levels.levels.splice(index, 1);
                this.errors.giving_levels = '';
                this.resetLocalLevel()
            },
            addLevel() {
                this.edit_index = this.local_client.giving_levels.levels.length-1;

                this.local_client.giving_levels.levels.splice(this.edit_index, 0, {
                    name: '',
                    min: null,
                    report: this.local_client.giving_levels.levels.length < 3 ? true : false
                });
                this.form.busy = true;

                this.resetLocalLevel()
            },
            checkIfCanSaveLevel(index) {
                var has_name = this.local_level.name.trim() != "";

                var is_unique_name = true;
                for(var i = 0; i < this.local_client.giving_levels.levels.length; i++)
                    if(i != index && this.local_client.giving_levels.levels[i].name == this.local_level.name) {
                        is_unique_name = false;
                        break;
                    }

                var has_min = this.local_level.min != null && !isNaN(this.local_level.min);

                var is_unique_num = true;
                for(var i = 0; i < this.local_client.giving_levels.levels.length; i++)
                    if(i != index && this.local_client.giving_levels.levels[i].min == this.local_level.min) {
                        is_unique_num = false;
                        break;
                    }

                if (!has_name){
                    this.errors.giving_levels = 'Donor Level Name cannot be empty';
                } else if (!is_unique_name){
                    this.errors.giving_levels = 'Donor Level Name cannot be duplicate';
                } else if (!has_min){
                    this.errors.giving_levels = 'Donor Level Minimum Threshold cannot be empty';
                } else if (!is_unique_num){
                    this.errors.giving_levels = 'Donor Level Minimum Threshold cannot be duplicate';
                }
                var result = has_name && is_unique_name && has_min && is_unique_num;
                this.if_can_save_new_level = result;
                return result;
            },
            changeDonorLevelInputs(){
                this.errors.giving_levels = '';
                this.if_can_save_new_level = true;
            },
            resetLocalLevel() {
                this.local_level = {
                    name: '',
                    min: '',
                    report: false
                };
            },
            resetAndSortLevels() {
                var levels = this.local_client.giving_levels.levels;

                //Sort the levels based upon the min in decending order
                levels.sort(function(a, b) {
                    return parseFloat(b.min) - parseFloat(a.min);
                });

                //If there are three or less, set all but the last one to have report as true
                if(levels.length <= 3)
                    for(var i = 0; i < levels.length-1; i++)
                        levels[i].report = true;

                this.local_client.giving_levels.levels = levels;
            },
            cleanUpPaste: function(e) {

                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                var paste = (e.clipboardData || window.clipboardData).getData('text/plain')
                paste = paste.replace(/[^\d.-]/g, '');

                if (document.queryCommandSupported("insertText")) {
                document.execCommand("insertText", false, paste);
                } else {
                document.execCommand("paste", false, paste);
                }
            },
            update() {
                var self = this;
                this.form.busy = true;

                window.axios.put('/api/client/' + this.local_client.id, this.local_client)
                    .then(response => {
                        self.form.busy = false;
                        self.form.successful = true;
                        setTimeout( function() {self.form.successful = false} , 5000);
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Fiscal Year & Donor Settings Updated!</h4>',
                            target: '#org_settings_fiscal_year_donor',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });    
                    }).catch(error =>{
                        self.form.busy = false;
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Error on updating Fiscal Year & Donor Settings</h4>',
                            target: '#org_settings_fiscal_year_donor',
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });
                });
            },
        }
    }
</script>
