var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "annual-plan-initiatives" }, [
    _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Projected Results")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Results last calculated at " + _vm._s(_vm.getMaxDate()) + "."
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "date-selector" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-secondary back-btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.moveDatesBackward()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa fa-angle-double-left",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
          _vm._v(
            "\n                " +
              _vm._s(_vm.month_names[_vm.current_month]) +
              " " +
              _vm._s(_vm.current_year) +
              " — " +
              _vm._s(_vm.future_date) +
              "\n                "
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-secondary forward-btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.moveDatesForward()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa fa-angle-double-right",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "initiatives-selections" }, [
          _c(
            "table",
            { staticClass: "table" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.results, function (item) {
                return item.timeframe == _vm.current_year
                  ? _c("tbody", { class: item.priority.toLowerCase() }, [
                      _vm.resultInPlan(item)
                        ? _c(
                            "tr",
                            {
                              staticClass: "initiative",
                              class: _vm.isEmptyClass(item),
                            },
                            [
                              item.priority == "Required"
                                ? _c("td", {
                                    staticClass: "bare",
                                    attrs: { scope: "colgroup" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  attrs: {
                                    colspan:
                                      item.priority == "Required" ? 2 : 1,
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _vm._v(" "),
                              item.priority != "Required"
                                ? _c("td", { staticClass: "priority" }, [
                                    _vm._v(_vm._s(item.priority)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.priority != "Required"
                                ? _c("td", [
                                    _c("i", {
                                      staticClass: "fas",
                                      class: _vm.getIcon(item.strategic_focus),
                                    }),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.strategic_focus)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-left metric" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency_na")(
                                      _vm.getCostByInitiative(item)
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-start metric" }, [
                                _vm._v(
                                  _vm._s(_vm._f("number_na")(item.emails))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-start metric" }, [
                                _vm._v(
                                  _vm._s(_vm._f("number_na")(item.new_donors))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-start metric" }, [
                                _vm._v(
                                  _vm._s(_vm._f("currency_na")(item.revenue))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-start metric" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency_na")(
                                      item.recurring_revenue
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-start ltv" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency_na")(item.lifetime_value)
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("tfoot", [
                _c("tr", [
                  _c("th", { staticClass: "bare" }),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass: "border-white",
                      staticStyle: {
                        "text-align": "center",
                        "vertical-align": "middle",
                      },
                      attrs: { colspan: "2" },
                    },
                    [_vm._v("Totals")]
                  ),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-left metric border-white" }, [
                    _vm._v(_vm._s(_vm._f("currency_na")(_vm.getAllCosts()))),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-start metric border-white" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("number_na")(_vm.getTotalByMetric("emails"))
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-start metric border-white" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("number_na")(_vm.getTotalByMetric("new_donors"))
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass: "text-start metric p-0 border-white",
                      attrs: { colspan: "2" },
                    },
                    [
                      _c("table", { staticClass: "table mb-0" }, [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass:
                                "text-start metric h-auto border-top-0 border-start-0 py-2 border-white",
                              staticStyle: {
                                "padding-left": "0.75rem",
                                "background-color": "rgb(225, 229, 238)",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency_na")(
                                    _vm.getTotalByMetric("revenue")
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "text-start metric h-auto border-top-0 border-end-0 py-2 border-white",
                              staticStyle: {
                                "padding-left": "0.75rem",
                                "background-color": "rgb(225, 229, 238)",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency_na")(
                                    _vm.getTotalByMetric("recurring_revenue")
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass:
                                "metric h-auto border-bottom-0 border-start-0 border-end-0 py-2",
                              staticStyle: {
                                "background-color": "rgb(225, 229, 238)",
                              },
                              attrs: { colspan: "2" },
                            },
                            [
                              _c("span", { staticClass: "fw-normal" }, [
                                _vm._v("Total:"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency_na")(
                                      _vm.getTotalByMetric("revenue") +
                                        _vm.getTotalByMetric(
                                          "recurring_revenue"
                                        )
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-start ltv border-white" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currency_na")(
                          _vm.getTotalByMetric("lifetime_value")
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card box-content" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Result Details")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group result-selector",
            attrs: { role: "group" },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.current_metric == "emails"
                    ? "btn-secondary"
                    : "btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.current_metric = "emails"
                  },
                },
              },
              [_vm._v("Emails")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.current_metric == "new_donors"
                    ? "btn-secondary"
                    : "btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.current_metric = "new_donors"
                  },
                },
              },
              [_vm._v("New Donors")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.current_metric == "gifts"
                    ? "btn-secondary"
                    : "btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.current_metric = "gifts"
                  },
                },
              },
              [_vm._v("Gifts")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.current_metric == "revenue"
                    ? "btn-secondary"
                    : "btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.current_metric = "revenue"
                  },
                },
              },
              [_vm._v("Revenue")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.current_metric == "recurring_revenue"
                    ? "btn-secondary"
                    : "btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.current_metric = "recurring_revenue"
                  },
                },
              },
              [_vm._v("Recurring")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class:
                  _vm.current_metric == "lifetime_value"
                    ? "btn-secondary"
                    : "btn-outline-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.current_metric = "lifetime_value"
                  },
                },
              },
              [_vm._v("LTV")]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "initiatives-selections" }, [
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", {
                    staticClass: "bare",
                    attrs: { colspan: "2", scope: "colgroup" },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return [
                      _c(
                        "th",
                        {
                          attrs: { colspan: months.length, scope: "colgroup" },
                        },
                        [_vm._v(_vm._s(year))]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "tr",
                [
                  _c("th", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "priority" }, [_vm._v("Priority")]),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return _vm._l(months, function (month) {
                      return _c(
                        "th",
                        { staticClass: "month", attrs: { scope: "col" } },
                        [_vm._v(_vm._s(_vm.month_names[month]))]
                      )
                    })
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.results, function (row) {
                return row.timeframe == _vm.current_year &&
                  _vm.resultInPlan(row)
                  ? _c(
                      "tr",
                      { class: row.priority.toLowerCase() },
                      [
                        _c("td", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(row.title)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "priority" }, [
                          _vm._v(_vm._s(row.priority)),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.calendar_headers, function (months, year) {
                          return _vm._l(months, function (month) {
                            return _c(
                              "td",
                              {
                                staticClass: "text-center month",
                                attrs: { scope: "col" },
                              },
                              [
                                _vm.current_metric == "emails" ||
                                _vm.current_metric == "new_donors" ||
                                _vm.current_metric == "gifts"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("number")(
                                              _vm.getMetric(row, month, year)
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.getMetric(row, month, year)
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            )
                          })
                        }),
                      ],
                      2
                    )
                  : _vm._e()
              }),
              0
            ),
            _vm._v(" "),
            _c("tfoot", [
              _c(
                "tr",
                [
                  _c(
                    "th",
                    {
                      staticClass: "border-white",
                      staticStyle: {
                        "text-align": "center",
                        "vertical-align": "middle",
                      },
                      attrs: { colspan: "2" },
                    },
                    [_vm._v("Total")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.calendar_headers, function (months, year) {
                    return _vm._l(months, function (month) {
                      return _c(
                        "th",
                        {
                          staticClass: "text-center month border-white",
                          attrs: { scope: "col" },
                        },
                        [
                          _vm.current_metric == "emails" ||
                          _vm.current_metric == "new_donors" ||
                          _vm.current_metric == "gifts"
                            ? _c("span", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(
                                      _vm._f("number")(
                                        _vm.getTotalByMonth(month, year)
                                      )
                                    ) +
                                    "\n                                    "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.getTotalByMonth(month, year)
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      )
                    })
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Initiative")]),
        _vm._v(" "),
        _c("th", { staticClass: "priority" }, [_vm._v("Priority")]),
        _vm._v(" "),
        _c("th", [_vm._v("Strategic Focus")]),
        _vm._v(" "),
        _c("th", { staticClass: "metric" }, [_vm._v("Cost")]),
        _vm._v(" "),
        _c("th", { staticClass: "metric" }, [_vm._v("Emails Acquired")]),
        _vm._v(" "),
        _c("th", { staticClass: "metric" }, [_vm._v("New Donors")]),
        _vm._v(" "),
        _c("th", { staticClass: "metric" }, [_vm._v("Initiative Revenue")]),
        _vm._v(" "),
        _c("th", { staticClass: "metric" }, [_vm._v("Recurring Revenue")]),
        _vm._v(" "),
        _c("th", { staticClass: "ltv" }, [_vm._v("Lifetime Value Potential")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }