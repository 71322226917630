<template>
    <div class="pathways-column-mapping">
        <section v-for="(column, i) in mapping.slice(0,2)" :id="i == 0 ? 'primary-key-column-mapping' : 'column-mapping'">

            <div v-if="i==0 && showColumn(column, i)" class="w-100 d-flex flex-column gap-2 mb-3">
                <strong>Primary Key Column(s) <i class="fas fa-info-circle ms-2" data-bs-toggle="tooltip" title="The primary key is what determines a unique row of data and how that aligns to the destination. It may be as simple as the Contact ID or an email. For advertising destinations like Facebook and Google, we use multiple columns to sync every possible bit of information." ></i></strong>
                <div class="mapping" id="primary-key-column" :class="{ 'is-invalid' : errors[i].source != '' }">
                    <div :class="(source.operation != 'append' ? 'w-50' : 'w-100')">
                        <label>{{ source.data_source.name }} Source Column(s)</label>
                        <accordion-multi-select v-model="column.source_column" @input="selectKeyColumn($event, i)" :class="{'is-invalid' : errors[i].source != ''}"
                            :options="local_pk_source_columns" :key="column.id" :field_name="'primary_key_column'"></accordion-multi-select>
                        <div class="text-danger invalid-feedback" v-if="errors[i].source != ''">
                            {{ errors[i].source }}
                        </div>
                    </div>

                    <div v-if="source.operation != 'append'" class="w-50">
                        <label>{{ destination.integration.name }} Column </label>
                        <input type="text" class="form-control" v-if="hasOneKeyOption()" :value="getColumnText(column.destination_column)" disabled="disabled">
                        <accordion-select v-model="column.destination_column" :class="{'is-invalid' : errors[i].destination != ''}" v-else :options="getPrimayKeyDestinations(column.destination_column)"  :key="'dest'+column.id"></accordion-select>
                        <div class="text-danger invalid-feedback" v-if="errors[i].destination != ''">
                            {{ errors[i].destination }}
                        </div>
                    </div>
                </div>
                <div class="mapping mb-4" id="prioritization-columns">
                    <div class="w-100">
                        <label>Prioritization Column(s)</label><br>
                        <small class="mb-2 d-block text-muted">If there are multiple records with the same primary key, how should Avid AI determine which one to sync?</small>
                        <div class="w-75 mb-2" :class="prioritization_column.length > 1 ? 'd-grid' : 'd-flex'">
                            <template v-for="(priority, i) in prioritization_column">
                                <b v-if="prioritization_column.length > 1" class="fs-5">{{ i+1 }}.</b>
                                <div class="input-group position-relative">
                                    <accordion-select v-model="priority.column"
                                        :options="source_columns"></accordion-select>
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ priority.sort == 'DESC'? 'Descending' : 'Ascending' }}</button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li><a class="dropdown-item" href="#" @click.prevent="priority.sort = 'ASC'">Ascending</a></li>
                                        <li><a class="dropdown-item" href="#" @click.prevent="priority.sort = 'DESC'">Descending</a></li>
                                    </ul>
                                </div>
                                <button v-if="prioritization_column.length > 1" class="mapping-grid__button btn btn-none px-0 " v-on:click="prioritization_column.splice(i, 1);"><img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg"></button>
                            </template>
                        </div>
                        <button class="btn btn-secondary btn-sm" @click="addPriorityColumn()"><i class="fas fa-plus"></i> Add Prioritization</button>
                    </div>
                </div>
            </div>

            <div v-if="i>=1 && showAdditionalColumns()" class="w-100 d-flex flex-column gap-2">
                <div v-if="i==1">
                    <strong>Additional Columns</strong>
                    <p>Use the fields below to map the columns from your data source to those available in {{ destination.integration.name }}. <strong>If you have recently added new columns to your destination, <a href="#" @click.prevent="refreshColumns()">click here to refresh the available column list.</a></strong></p>
                </div>

                <div class="d-flex flex-row gap-3">
                <div class="col-xl-8 col-12 card">
                    <table class="mapping-table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="flex-column">
                                        <h5>Column Name You're Importing</h5>
                                        <p>What your column was named in the source</p>
                                    </div>
                                </th>
                                <th>
                                    <!-- <div>
                                        <small>Maps to</small>
                                    </div> -->
                                </th>
                                <th>
                                    <div class="flex-column">
                                        <h5>{{ destination.integration.name }} Column</h5>
                                        <p>This is the column name that {{ destination.integration.name }} uses</p>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(column, j) in mapping.slice(1)" v-if="showColumn(column, j+i)">
                                <td class="mapping-grid__source w-100">
                                    <div class="w-100 d-flex">
                                        <select v-if="isDropdown(column)" v-model="column.source_column.value" class="form-select">
                                            <option v-for="option in dropdowns[column.destination_column]" :value="option.id">{{ option.name }}</option>
                                        </select>
                                        <div class="input-group" :class="{'is-invalid' : errors[j+1].source != ''}" v-else>
                                            <input type="text" class="form-control" v-model="column.source_column.value" :class="{'is-invalid' : errors[j+1].source != ''}" v-if="isConstant(column)" @change="updateConstant(column)"
                                                :key="column.id">
                                            <accordion-select v-model="column.source_column.value" :key="column.source_column.id" :class="{'is-invalid' : errors[j+1].source != ''}" v-else :options="source_columns"></accordion-select>
                                            <button class="btn btn-secondary p-0" data-bs-toggle="tooltip" title="Use a value from a column" @click="toggleConstant(column)" v-if="isConstant(column)">
                                                <img class="icon px-1" src="/img/icons/dialexa-icons/map.svg"> 
                                            </button>
                                            <button class="btn btn-secondary p-0" data-bs-toggle="tooltip" title="Use a constant value" @click="toggleConstant(column)" v-else>
                                                <img class="icon px-1" src="/img/icons/dialexa-icons/edit.svg"> 
                                            </button>
                                        </div>
                                        <div class="close-btn d-sm-none" v-if="!column.is_primary_identifier">
                                            <button class="btn btn-none px-0" v-on:click="removeColumn(j-1);"><i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                    <div class="text-danger invalid-feedback" v-if="errors[j+1].source != ''">
                                        {{ errors[j+1].source }}
                                    </div>
                                </td>
                                <td>
                                    <div class="w-100 d-flex align-items-center">
                                        <small>Maps to</small>
                                    </div>
                                </td>
                                <td class="mapping-grid__destination w-100">
                                    <div class="w-100 d-flex">
                                        <input type="text" class="form-control" v-if="!isColumnOptional(column)" :value="getColumnText(column.destination_column)" disabled="disabled">
                                        <accordion-select v-model="column.destination_column" :class="{'is-invalid' : errors[j+1].destination != ''}" v-else :options="getAvailableDestinations(column.destination_column)" :key="'dest'+column.id"></accordion-select>
                                    </div>
                                    <div class="text-danger invalid-feedback" v-if="errors[j+1].destination != ''">
                                        {{ errors[j+1].destination }}
                                    </div>
                                </td>
                                <td>
                                    <button v-if="isColumnOptional(column)" class="mapping-grid__button btn btn-none px-0" v-on:click="removeColumn(j+1);">
                                        <img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg">
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-xl-4 d-none d-xl-block drill-down pe-3">
                        <preview-table :client="client" :source="source"
                            :source_columns="source_columns"></preview-table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    export default {
        props: {
            client: {
              type: Object,
            },
            destination: {
              type: Object,
            },
            value: {
              type: Array,
            },
            dest_columns: {
                default: () => []
            },
            source: {type: Object },
            source_columns: {
                default: () => []
            },
            show_unmapped_only: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                mapping: [],
                errors: [],
                keep_showing: [],
                local_pk_source_columns: [],
                flat_dest_columns:[],
                flat_primary_columns:[],
                prioritization_column:[{
                    column: '',
                    sort: 'DESC'
                }],
                dropdowns: [],

            };
        },
        beforeMount() {
            this.setupUI();

            //For the error message, set the default value to false.
            this.errors = [];
            for(var i = 0; i < this.mapping.length; i++) {
                this.errors.push({
                    source: "",
                    destination: ""
                });
            }
            this.local_pk_source_columns = this.getFormattedColumns(this.source_columns);
            if(this.source.prioritization_column != null)
                this.prioritization_column = this.source.prioritization_column;

        },
        mounted() {
            //Ensure the tooltips show up
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }

        },
          watch: {
            // Watch for changes in the computed property to trigger the re-render of the component.
            local_census_connection: {
              handler(n, o) {
                this.setupUI();
              },
              deep: true,
            },
            value(val) {
                if(val != null)
                    this.mapping = val;
                else
                    this.mapping = this.getDefaultMapping();
            },
            mapping: {
                handler(n, o) {
                    this.$emit('input', this.mapping);
                    this.$emit('changeMappingDetails', this.mapping)
                    if (this.mapping.length > this.errors.length){
                        for(var i = 0; i < this.mapping.length - this.errors.length; i++) {
                            this.errors.push({
                                source: "",
                                destination: ""
                            });
                        }
                    }
                },
                deep: true
            },
            prioritization_column: {
                handler(n, o) {
                    this.$emit('prioritize', this.prioritization_column);
                },
                deep: true
            },
            dest_columns: {
                handler(n, o) {

                    this.flat_dest_columns = this.getDestinationColumns();
                    this.flat_primary_columns = this.getDestinationColumns(true);

                    this.$forceUpdate();
                },
                deep: true
            }
        },
        methods: {

            isModel() {
                //The model update will always be 7 "folders" long
                let urlString = window.location.href;
                // Parse the URL
                const url = new URL(urlString);
                // Split the pathname part of the URL on '/', filter out empty segments
                const segments = url.pathname.split('/').filter(segment => segment !== '');
                // Get the seventh segment (the model ID) if there is one
                return segments.length == 7;
            },
            showColumn(col, index){
                if(!this.show_unmapped_only) return true;

                if(this.keep_showing.length > index && this.keep_showing[index] == true)
                    return true;

                //If the first one is an array but the first value isn't set
                let show_primary = (index == 0 && Array.isArray(col.source_column) && col.source_column.length > 0 && (col.source_column[0].value == null || col.source_column[0].value == ""));
                //If it is a normal object but the value hasn't been set
                let show_other = col.source_column && (col.source_column.value == null || col.source_column.value == "");

                return show_primary || show_other;
            },
            showAdditionalColumns(){
                if(!this.show_unmapped_only) return true;

                for(let i = 1; i < this.mapping.length; i++){
                    if(this.showColumn(this.mapping[i], i))
                        return true;
                }

                return false;
            },
            addPriorityColumn() {
                this.prioritization_column.push({
                    column: '',
                    sort: 'DESC'
                });
            },
            resetMapping() {
                this.$emit('input', null);
            },
            setupUI() {
                this.flat_dest_columns = this.getDestinationColumns();
                this.flat_primary_columns = this.getDestinationColumns(true);

                //Set the default columns or whatever was sent to me
                if(this.value != null && this.value.length > 0) {
                    this.mapping = this.value;
                    let def = this.getDefaultMapping();

                    //Remove any columns that are no longer available
                    for(var i = 0; i < this.mapping.length; i++){
                        //If it is an array...
                        if(Array.isArray(this.mapping[i].source_column)) {
                            for(var j = 0; j < this.mapping[i].source_column.length; j++)
                                //If the value is in the list of source_columns
                                if(this.mapping[i].source_column[j].main_category=="Columns" && !this.source_columns.some(obj => obj.value === this.mapping[i].source_column[j].value)){
                                    this.mapping[i].source_column[j] = this.getBlankSourceColumn();
                                    break;
                                }
                        }
                        //Just look at the object
                        else if(this.mapping[i].source_column.main_category=="Columns" && !this.source_columns.some(obj => obj.value === this.mapping[i].source_column.value)){

                            //If it is the primary column, it should be an array
                            if(this.mapping[i].is_primary_identifier == 1)
                                this.mapping[i].source_column = [this.getBlankSourceColumn()];
                            else
                                this.mapping[i].source_column = this.getBlankSourceColumn();
                        }
                    }

                    //Add any missing required fields
                    for(var i = 0; i < def.length; i++){
                        let is_mapped = false;
                        for(var j = 0; j < this.mapping.length; j++)
                            if(def[i]['destination_column'] == this.mapping[j]['destination_column']){
                                is_mapped = true;
                                break;
                            }
                        //Primary needs to be inserted in the beginning
                        if(!is_mapped && def[i]['is_primary_identifier'] == 1 && (this.mapping.length == 0 || this.mapping[0].is_primary_identifier == false)){
                            this.mapping.unshift(def[i]);
                        }
                        //Otherwise, put it at the end
                        else if(!is_mapped && def[i]['destination_column'] != null){
                            this.mapping.push(def[i]);
                        }

                    }

                }
                else
                    this.mapping = this.getDefaultMapping();

                this.keep_showing = [];
                for(var i = 0; i < this.mapping.length; i++)
                    this.keep_showing.push(this.showColumn(this.mapping[i], i));

            },
            refreshColumns() {
                this.$emit('refresh');
            },
            getFormattedColumns(db_columns) {
                var columns = [];
                db_columns.forEach(column => {
                    column.main_category = "Columns";
                    columns.push(column);
                });
                return columns;
            },
            getAvailableDestinations(column, primary_key = false) {
                if(primary_key)
                    var dest_array = this.flat_primary_columns;
                else
                    var dest_array = this.flat_dest_columns;

                var arr = [];
                //Loop through the mapping variable and see if the column is used
                for(var j = 0; j < dest_array.length; j++){
                    let dest = dest_array[j];
                    let found = false
                    for(var i = 0; i < this.mapping.length; i++) {
                        if(this.mapping[i].destination_column == dest.value && column != dest.value) {
                            found = true;
                            break;
                        }
                    }

                    if((primary_key && dest.can_be_key) || (!found && !primary_key))
                        arr.push(dest);
                }
                return arr;
            },
            getPrimayKeyDestinations(column = null) {
                var arr = this.getAvailableDestinations(column, true);
                if(arr.length == 1)
                    return arr[0].value;

                //if(this.source.operation == 'append'){
                //    column = 'unique_id';
                //}

                return arr;
            },
            selectKeyColumn(event, index){
                if(event.value != null)
                    this.mapping[index].source_column = event.value;
                else
                    this.mapping[index].source_column = event;
            },
            hasOneKeyOption() {
                var arr = this.getPrimayKeyDestinations();
                //See if arr is an array or a single value
                if(!Array.isArray(arr)){
                    return true;
                }
                return false;
            },
            isColumnOptional(column) {
                const foundInstance = this.flat_dest_columns.find(item => item.value === column.destination_column);

                if(foundInstance == null)
                    return true;

                return foundInstance.optional;
            },
            getColumnText(column) {
                const foundInstance = this.flat_dest_columns.find(item => item.value === column);

                if(foundInstance == null)
                    return column;

                return foundInstance.text;
            },
            canBeKey(column) {

                const foundInstance = this.flat_dest_columns.find(item => item.value === column.destination_column);

                if(foundInstance == null)
                    return false;

                return foundInstance.can_be_key;
            },
            getDefaultMapping() {
                var arr = [];
                //Add one for the primary key
                if(this.hasOneKeyOption()){
                    let val = this.getPrimayKeyDestinations();
                    arr.push(this.getMapping(val, true));
                }
                else
                    arr.push(this.getMapping(null, true));

                //Add any other non-optional columns
                for(var i = 0; i < this.flat_dest_columns.length; i++) {
                    //Also make sure the values in arr[].destination_column aren't equal to this.flat_dest_columns[i]
                    if(!this.flat_dest_columns[i].optional && !arr.some(obj => obj.destination_column === this.flat_dest_columns[i].value))
                        arr.push(this.getMapping(this.flat_dest_columns[i].value));
                }

                //If it is only the primary key, add one more mapping for them to set up
                if(arr.length == 1)
                    arr.push(this.getMapping(null));

                return arr;
            },
            // addMapping() {
            //     this.mapping.push(this.getMapping(null));
            //     this.errors.push({
            //         source: "",
            //         destination: ""
            //     });
            // },
            getMapping(col, is_primary = false) {
                return {
                    id: -1 * Math.random(),
                    client_id: this.client.id,
                    census_connection_id: this.source.id,
                    source_column: this.getBlankSourceColumn(),
                    destination_column: col,
                    is_primary_identifier: is_primary,
                }
            },
            getBlankSourceColumn() {
                return {
                    main_category: 'Columns',
                    category: null,
                    type: 'string',
                    value: null,
                    text: ""
                };
            },

            getDestinationColumns(is_primary = false) {
                let arr = [];
                let textSet = new Set(); // Set to track the text properties

                //TODO: exclude non-updatable columns if it isn't primary key and is an upsert / update

                // Loop through dest_columns
                for(let i = 0; i < this.dest_columns.length; i++) {
                    let column = this.dest_columns[i];
                    if((is_primary || column.updateable) && !column.is_static_list_reference) {
                        let key = 'can_be_' + this.source.operation + '_key';
                        if(this.source.operation === 'mirror') key = 'can_be_upsert_key';
                        else if(this.source.operation === 'delete') key = 'can_be_update_key';
                        else if(this.source.operation === 'create' || this.source.operation === 'append') key = 'can_be_insert_key';

                        // Check if the text property already exists
                        if(!textSet.has(column.label) && column.type != 'Unmappable') {
                            arr.push({
                                value: column.full_name,
                                text: column.label,
                                category: null,
                                optional: !column.required_for_mapping,
                                can_be_key: column[key] != null && column[key] === true,
                            });
                            textSet.add(column.label); // Add text to the Set
                        }
                    }
                }

                // Sort the arr by text
                arr.sort((a, b) => a.text.localeCompare(b.text));

                return arr;
            },

            updateConstant(column) {
                column.source_column.main_category = 'Static';
            },
            toggleConstant(column) {

                //If it is a constant, switch back to the column
                if(this.isConstant(column))
                    column.source_column.main_category = 'Columns';
                else
                    column.source_column.main_category = 'Static';

                column.source_column.value = "";

            },
            isConstant(column) {
                if(Array.isArray(column.source_column)) return false;


                return column.source_column != null && column.source_column.main_category == 'Static';
            },
            isValid() {
                //Loop through the mapping and make sure that each column has a source and destination
                var valid = true;



                for(var i = 0; i < this.mapping.length; i++) {
                    //There isn't a column selected
                    if((this.mapping[i].source_column.value == null || this.mapping[i].source_column.value == "" ) && this.mapping[i].source_column.main_category == 'Columns') {
                        this.errors[i].source = "Please select a source column";
                        valid = false;
                    }
                    //There is a blank value
                    else if(!Array.isArray(this.mapping[i].source_column) && (this.mapping[i].source_column.value == null || this.mapping[i].source_column.value == "")) {
                        this.errors[i].source = "Please enter a static value above";
                        valid = false;
                    }
                    //The column has been removedPa
                    else if (this.mapping[i].source_column.main_category == 'Columns' && !this.source_columns.some(obj => obj.value === this.mapping[i].source_column.value)) {
                        this.errors[i].source = "The previously mapped column has been removed from the source data. Please select another column.";
                        valid = false;
                    }
                    else
                        this.errors[i].source = "";

                    if(this.source.operation != 'append' && (this.mapping[i].destination_column == null || this.mapping[i].destination_column == "")) {
                        this.errors[i].destination = "Please select a destination column";
                        valid = false;
                    }
                    else if(this.source.operation == 'append' && i == 0){
                        var val = this.getPrimayKeyDestinations(null);
                        if(Array.isArray(val) && val.length == 1)
                            this.mapping[i].destination_column = val[0].value;
                        else if(!Array.isArray(val))
                            this.mapping[i].destination_column = val;
                        else
                            this.mapping[i].destination_column = 'unique_id';
                        this.errors[i].destination = "";
                    }
                    else
                        this.errors[i].destination = "";
                }

                return valid;

            },
            removeColumn(i) {
                //Remove the column
                this.mapping.splice(i, 1);
                this.errors.splice(i, 1);
                this.$forceUpdate();
            },
            isDropdown(column) {
                if(column.source_column != null && (column.source_column.main_category == 'Dropdown' || column.source_column.main_category == 'Dropdowns')) {

                    if(this.dropdowns[column.destination_column] != null)
                        return true;

                    //Make the array index
                    this.dropdowns[column.destination_column] = [];

                    //Then populate that array
                    var form_data = {
                        column: column.destination_column,
                        connection_id: this.destination.id
                    }

                    window.axios.post('/api/census/get_dropdown_options', form_data)
                      .then(response => {
                        if(response.data.objects != null)
                            this.dropdowns[column.destination_column] = response.data.objects;
                        else
                            column.source_column.main_category = 'Static';

                        this.$forceUpdate();
                    });

                    return true;
                }
                return false;
            },
        }
    }
</script>
