var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org_settings_profile" } }, [
    _c(
      "form",
      {
        attrs: { enctype: "multipart/form-data" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateAgency.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "d-flex mb-4" }, [
          _c(
            "div",
            { staticClass: "flex-shrink-1 profile-image px-2 pe-4" },
            [
              _c("vue-dropzone", {
                ref: "dropzone",
                staticClass: "profile-dropzone",
                style: "background-image: url(" + _vm.form.logo + ")",
                attrs: {
                  id: "dropzone",
                  options: _vm.dropzoneOptions,
                  awss3: _vm.awss3,
                },
                on: { "vdropzone-s3-upload-success": _vm.s3UploadSuccess },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "px-2 w-100" }, [
            _c("h4", [_vm._v("Update Agency Details")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Agency Name"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.errors.name },
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.name },
                    on: {
                      blur: _vm.validateName,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.name
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.errors.name) +
                            "\n                            "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "mb-3" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Website Domain"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group mb-3" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(
                            "\n                                            https://\n                                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.domain,
                              expression: "form.domain",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.errors.domain },
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.domain },
                          on: {
                            blur: _vm.validateDomain,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "domain", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm.errors.domain
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(_vm.errors.domain) +
                                "\n                                    "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3 mb-3" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group mb-3" }, [
                      _c(
                        "span",
                        {
                          staticClass: "input-group-text",
                          style: { "background-color": _vm.form.dark_color },
                        },
                        [
                          _c("verte", {
                            attrs: { picker: "square", model: "hex" },
                            model: {
                              value: _vm.form.dark_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dark_color", $$v)
                              },
                              expression: "form.dark_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.dark_color,
                            expression: "form.dark_color",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.form.dark_color },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "dark_color",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3 mb-3" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group mb-3" }, [
                      _c(
                        "span",
                        {
                          staticClass: "input-group-text",
                          style: { "background-color": _vm.form.light_color },
                        },
                        [
                          _c("verte", {
                            attrs: { picker: "square", model: "hex" },
                            model: {
                              value: _vm.form.light_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "light_color", $$v)
                              },
                              expression: "form.light_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.light_color,
                            expression: "form.light_color",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.form.light_color },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "light_color",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(
                        "\n                                    https://app.avidai.com/register/\n                                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.url_slug,
                          expression: "form.url_slug",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.url_slug },
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.url_slug },
                      on: {
                        blur: _vm.validateUrlSlug,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "url_slug", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: {
                          type: "button",
                          disabled: !_vm.form.url_slug,
                          "aria-label": "Copy Registration URL",
                        },
                        on: { click: _vm.copyRegistrationUrl },
                      },
                      [_c("i", { staticClass: "fas fa-copy" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.errors.url_slug
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.errors.url_slug) +
                            "\n                            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
              _vm._v(
                "\n            There is an error in the fields above. Please correct and try again.\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary mt-3",
            attrs: {
              type: "submit",
              disabled: _vm.form.busy || _vm.hasError(),
            },
          },
          [
            _c("i", { staticClass: "fa fa-btn fa-save" }),
            _vm._v(" Save Changes\n        "),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Dark Color\n                                    "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Pick a dark color used in titles and other fonts that will be used when generating slides. It should match your branding.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Light Color\n                                    "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Pick a bright color that will be used as an accent when generating slides. It should match your branding.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Custom Registration URL "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "You will receive a 20% referral bonus for any nonprofit that signs up for Avid via your custom registration link.",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }