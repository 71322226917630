var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "annual-plan" },
    [
      _c(
        "div",
        { staticClass: "border-bottom border-secondary border-3 mb-4 mt-5" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("h2", { staticClass: "mb-2 pb-1" }, [
                _c("span", [_vm._v(_vm._s(_vm.client.name) + ":")]),
                _vm._v(" "),
                _c("em", [_vm._v(_vm._s(_vm.plan.name))]),
              ]),
            ]),
            _vm._v(" "),
            _vm.plans.length > 1
              ? _c("div", { staticClass: "col-2 d-none d-sm-block" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn form-select btn-field pe-5 bg-white",
                      attrs: {
                        id: "version-dropdown",
                        href: "#",
                        type: "button",
                        "data-bs-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Switch Version\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-end",
                      attrs: { "aria-labelledby": "version-dropdown" },
                    },
                    _vm._l(_vm.plans, function (p) {
                      return _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "/planning/" + p.url + "/annual/" },
                        },
                        [_vm._v(_vm._s(p.name))]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.plan.is_published_plan == 1
        ? _c("div", { staticClass: "alert alert-warning mb-3 box-content" }, [
            _vm._v("\n            The plan being view is currently the "),
            _c("em", [_vm._v("published plan")]),
            _vm._v(
              ". This means you are not able to make any adjustments to the assumptions or initatives. If you'd like to make any updates, "
            ),
            _c(
              "a",
              { attrs: { href: "/planning/" + _vm.client.url + "/versions" } },
              [
                _vm._v(
                  "you will need to make a new version. You can do that here."
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.plan.is_published_plan != 1
        ? _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card box-content" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Add New Initiative"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.initiative_type,
                            expression: "initiative_type",
                          },
                        ],
                        staticClass: "form-select",
                        attrs: { name: "initiative_type" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.initiative_type = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.add_initiative()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "-1" } }, [
                          _vm._v("** Please select from the list **"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.init_types, function (init) {
                          return _c(
                            "option",
                            { domProps: { value: init.id } },
                            [_vm._v(_vm._s(init.name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content mb-3" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("h4", [_vm._v("Billing Schedule")]),
            _vm._v(" "),
            _vm.plan.is_published_plan != 1
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.calculate_results()
                      },
                    },
                  },
                  [
                    _vm._v("\n                Calculate Results "),
                    _vm.calculating
                      ? _c("i", { staticClass: "fas fa-sync fa-spin ms-2" })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-sm",
                attrs: {
                  type: "button",
                  "data-toggle": "button",
                  "aria-pressed": "false",
                  autocomplete: "off",
                },
                on: {
                  click: function ($event) {
                    return _vm.make_print_ready()
                  },
                },
              },
              [
                _c("span", { staticClass: "show" }, [_vm._v("Show")]),
                _c("span", { staticClass: "hide" }, [_vm._v("Hide")]),
                _vm._v(" Print-Ready Version\n            "),
              ]
            ),
            _vm._v(" "),
            _c("annual-plan-table", {
              attrs: {
                plan: _vm.plan_copy,
                client: _vm.client,
                init_types: _vm.init_types,
                current_month: _vm.current_month,
                current_year: _vm.current_year,
              },
              on: {
                "update:plan": function ($event) {
                  _vm.plan_copy = $event
                },
                "update:current_month": function ($event) {
                  _vm.current_month = $event
                },
                "update:current_year": function ($event) {
                  _vm.current_year = $event
                },
                notificationEvent: _vm.notification,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.result_set.length > 0
        ? _c("annual-plan-results", {
            attrs: {
              plan: _vm.plan_copy,
              client: _vm.client,
              results: _vm.result_set,
              result_details: _vm.result_details_copy,
              current_month: _vm.current_month,
              current_year: _vm.current_year,
            },
            on: {
              "update:plan": function ($event) {
                _vm.plan_copy = $event
              },
              "update:current_month": function ($event) {
                _vm.current_month = $event
              },
              "update:current_year": function ($event) {
                _vm.current_year = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.notification_message != ""
          ? _c(
              "div",
              { staticClass: "notification", class: _vm.notification_class },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.notification_message) },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        _vm.notification_message = ""
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-times-circle" })]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }