var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "experiment_treatment_overview asset-details" },
    [
      _c("div", { staticClass: "asset-variant-detail mb-2" }, [
        _c("div", { staticClass: "card box-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "mt-2 mb-3" }, [
              _vm._v("Experiment Treatment Details"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "This will allow you to create a new version of an existing asset to be used as an experiment treatment. You'll be able to apply behavioral psychology concepts, donor personas, or simply describe how the asset should be changed. To get started, provide the details below."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_asset.name,
                      expression: "local_asset.name",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.errors.name != "" ? "is-invalid" : "",
                  attrs: {
                    type: "text",
                    id: "campaign-name-input",
                    disabled: _vm.form.busy,
                  },
                  domProps: { value: _vm.local_asset.name },
                  on: {
                    change: function ($event) {
                      return _vm.inputFieldValueChange()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.local_asset, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.name != ""
                  ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.name) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_asset.description,
                      expression: "local_asset.description",
                    },
                  ],
                  ref: "textarea",
                  staticClass: "form-control",
                  attrs: { disabled: _vm.form.busy },
                  domProps: { value: _vm.local_asset.description },
                  on: {
                    change: function ($event) {
                      return _vm.inputFieldValueChange()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_asset,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 mx-2" }, [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row tile-container" },
            _vm._l(_vm.asset_types, function (asset_type) {
              return _c(
                "div",
                {
                  staticClass:
                    "col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-1-5 asset-type-tile",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card box-content mx-2 mb-4",
                      class: _vm.getTileClass(asset_type),
                      on: {
                        click: function ($event) {
                          return _vm.selectAssetType(asset_type)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body text-center tile-body py-2" },
                        [
                          _c("div", { staticClass: "tile-content" }, [
                            _c("i", {
                              staticClass: "fa-4x",
                              class: asset_type.icon_class,
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mt-2 mb-0 tile-title input-field-description",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    asset_type.name.substring(
                                      0,
                                      asset_type.name.length - 1
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.errors.asset_type != ""
            ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.asset_type) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.local_asset.asset_type
        ? _c(
            "div",
            {
              ref: "assetSection",
              staticClass: "asset-variant-input-detail pb-3",
            },
            [
              _c("div", { staticClass: "card box-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("h4", { staticClass: "mt-2 mb-3" }, [
                      _vm._v("Original Version"),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.local_asset_inputs,
                      function (input_field, index) {
                        return _c(
                          "div",
                          {
                            key:
                              _vm.local_asset.asset_type.id +
                              "_" +
                              index +
                              "_" +
                              input_field.placeholder,
                          },
                          [
                            _c("div", { staticClass: "row mb-3" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label mb-0" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(input_field.name) +
                                          " "
                                      ),
                                      input_field.optional == 1
                                        ? _c("em", [_vm._v("(Optional)")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      input_field.type == "image"
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "ms-2",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.addEmptyImageSlot(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._m(3, true)]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "input-field-description" },
                                    [_vm._v(_vm._s(input_field.description))]
                                  ),
                                  _vm._v(" "),
                                  input_field.type == "text" ||
                                  input_field.type == "link"
                                    ? _c(
                                        "div",
                                        {
                                          class:
                                            _vm.getErrorClasses(input_field),
                                          attrs: {
                                            maxlength: input_field.max_length,
                                            currentlength:
                                              input_field.value != null
                                                ? input_field.value.length
                                                : 0,
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: input_field.value,
                                                expression: "input_field.value",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              maxlength: input_field.max_length,
                                              disabled: _vm.form.busy,
                                            },
                                            domProps: {
                                              value: input_field.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.inputFieldValueChange()
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  input_field,
                                                  "value",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "textarea" ||
                                  input_field.type == "template"
                                    ? _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: input_field.value,
                                            expression: "input_field.value",
                                          },
                                        ],
                                        ref: "textarea",
                                        refInFor: true,
                                        staticClass: "form-control",
                                        class:
                                          _vm.errors[input_field.placeholder] !=
                                          ""
                                            ? "is-invalid"
                                            : "",
                                        attrs: {
                                          id:
                                            input_field.placeholder +
                                            "-" +
                                            _vm.local_asset.asset_type_id,
                                          disabled: _vm.form.busy,
                                          maxlength: input_field.max_length,
                                        },
                                        domProps: { value: input_field.value },
                                        on: {
                                          change: function ($event) {
                                            return _vm.inputFieldValueChange()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              input_field,
                                              "value",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "image"
                                    ? _c(
                                        "div",
                                        { staticClass: "image-array-section" },
                                        _vm._l(
                                          input_field.value,
                                          function (image_url, image_index) {
                                            return _c(
                                              "div",
                                              { staticClass: "me-3" },
                                              [
                                                _c("vue-dropzone", {
                                                  ref: "image-dropzone",
                                                  refInFor: true,
                                                  staticClass: "input-dropzone",
                                                  class:
                                                    image_url == null
                                                      ? ""
                                                      : "has-background",
                                                  style:
                                                    image_url != null
                                                      ? "background-image: url('" +
                                                        image_url +
                                                        "')"
                                                      : "",
                                                  attrs: {
                                                    id:
                                                      "image-dropzone-" +
                                                      _vm.local_asset.id +
                                                      "-" +
                                                      image_index,
                                                    options:
                                                      _vm.imageDropzoneOptions,
                                                    awss3: _vm.awss3,
                                                  },
                                                  on: {
                                                    "vdropzone-s3-upload-success":
                                                      _vm.imageUpload,
                                                    "vdropzone-sending":
                                                      function ($event) {
                                                        return _vm.imageUploadStarted(
                                                          input_field,
                                                          image_index
                                                        )
                                                      },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "image-field-buttons",
                                                  },
                                                  [
                                                    _c("span", [
                                                      image_url != null
                                                        ? _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-label mb-2",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "ms-2",
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return _vm.deleteImage(
                                                                          index,
                                                                          image_index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-trash",
                                                                  }),
                                                                  _vm._v(
                                                                    "Delete"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "file"
                                    ? _c("vue-dropzone", {
                                        ref: "file-dropzone",
                                        refInFor: true,
                                        staticClass: "input-dropzone",
                                        attrs: {
                                          id:
                                            "file-dropzone-" +
                                            _vm.local_asset.asset_type_id,
                                          options: _vm.fileDropzoneOptions,
                                          disabled: _vm.form.busy,
                                        },
                                        on: {
                                          "vdropzone-processing":
                                            _vm.onProcessing,
                                          "vdropzone-success": _vm.onSuccess,
                                          "vdropzone-sending": _vm.onSending,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "wysiwyg"
                                    ? _c("wysiwyg-editor-advanced", {
                                        attrs: {
                                          if_show_button_input:
                                            _vm.if_show_button_input,
                                          input_value: input_field.value,
                                          input_index: index,
                                          asset_id: null,
                                          if_disabled: _vm.form.busy,
                                        },
                                        on: {
                                          wysiwygValueChange:
                                            _vm.wysiwygValueChange,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  input_field.type == "dropdown"
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: input_field.value,
                                              expression: "input_field.value",
                                            },
                                          ],
                                          staticClass:
                                            "form-select w-auto form-control",
                                          class:
                                            _vm.errors[
                                              input_field.placeholder
                                            ] != ""
                                              ? "is-invalid"
                                              : "",
                                          attrs: { disabled: _vm.form.busy },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  input_field,
                                                  "value",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.inputFieldValueChange()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                value: "",
                                                disabled: "",
                                                selected: "",
                                              },
                                            },
                                            [_vm._v("Select the value...")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            input_field.options,
                                            function (option) {
                                              return _c(
                                                "option",
                                                { domProps: { value: option } },
                                                [_vm._v(_vm._s(option))]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.errors[input_field.placeholder] != null &&
                              _vm.errors[input_field.placeholder] != ""
                                ? _c("div", {
                                    staticClass: "text-danger invalid-feedback",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.errors[input_field.placeholder]
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm.if_has_errors
                          ? _c(
                              "div",
                              { staticClass: "alert alert-danger mb-3" },
                              [
                                _vm._v(
                                  "\n                            Fill out all required fields above including name before creating a treatment.\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "dropup" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn me-2 dropdown-toggle btn-primary",
                              attrs: {
                                type: "button",
                                disabled: _vm.form.busy,
                                "data-bs-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-btn fa-copy" }),
                              _vm._v(
                                " Create a Treatment\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              ref: "variant_dropdown",
                              staticClass: "dropdown-menu pt-0 prompt-dropdown",
                            },
                            [
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "px-3 py-2 text-white form-label bg-dark",
                                },
                                [_vm._v("Generate a variant based upon ...")]
                              ),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item btn btn-link",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleDonorPersonaOptions()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Constituent Personas\n                                    "
                                    ),
                                    !_vm.is_donor_personas_expanded
                                      ? _c("i", {
                                          staticClass: "fa fa-caret-right mx-1",
                                        })
                                      : _c("i", {
                                          staticClass: "fa fa-caret-down mx-1",
                                        }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.client.personas != null &&
                              _vm.is_donor_personas_expanded
                                ? _c(
                                    "ul",
                                    { staticClass: "my-1" },
                                    _vm._l(
                                      _vm.client.personas,
                                      function (persona) {
                                        return _c("li", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-link p-0 dropdown-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateVariantPrompt(
                                                    "donor_persona",
                                                    persona.description
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(persona.name))]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.client.personas == null &&
                              _vm.is_donor_personas_expanded
                                ? _c("ul", { staticClass: "my-1" }, [
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            href:
                                              "/settings/" +
                                              _vm.client.url +
                                              "/#personas",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v("Define your first persona "),
                                          _vm._m(4),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item btn btn-link",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleBehavioralConceptOptions()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Behavioral Psychology Concept\n                                    "
                                    ),
                                    !_vm.is_concept_expanded
                                      ? _c("i", {
                                          staticClass: "fa fa-caret-right mx-1",
                                        })
                                      : _c("i", {
                                          staticClass: "fa fa-caret-down mx-1",
                                        }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.is_concept_expanded
                                ? _c(
                                    "ul",
                                    { staticClass: "my-1" },
                                    [
                                      _vm.concepts.length == 0
                                        ? _c("li", [
                                            _vm._v(
                                              "No behavioral concepts defined yet"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(_vm.concepts, function (concept) {
                                        return _c("li", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-link p-0 dropdown-item",
                                              attrs: {
                                                "data-bs-toggle": "tooltip",
                                                "data-bs-placement": "right",
                                                title: concept.definition,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateVariantPrompt(
                                                    "concept",
                                                    concept.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(concept.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item btn btn-link",
                                    attrs: {
                                      href: "#",
                                      "data-bs-toggle": "modal",
                                      "data-bs-target":
                                        "#refine-prompt-modal-" +
                                        _vm.local_asset.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.is_variant = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Describe What You Are Testing\n                                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal example-modal fade",
          attrs: {
            id: "refine-prompt-modal-" + _vm.local_asset.id,
            "aria-labelledby": "refine-prompt-modal",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
                _c("div", { staticClass: "text-center w-100" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close btn",
                      attrs: {
                        type: "button",
                        "data-bs-dismiss":
                          "refine-prompt-modal-" + _vm.local_asset.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal(
                            "refine-prompt-modal-" + _vm.local_asset.id
                          )
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-close" })]
                  ),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mt-0" }, [
                    _vm._v("Describe What to Change in the Treatment"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Use the field below to describe what you are testing—how the treatment should be different. The AI will then generate new content based upon your directions."
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body pt-0 pb-0" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.provided_example,
                      expression: "provided_example",
                    },
                  ],
                  staticClass: "form-control mb-3",
                  attrs: { name: "example_content" },
                  domProps: { value: _vm.provided_example },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.provided_example = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "submit",
                        "data-bs-dismiss":
                          "refine-prompt-modal-" + _vm.local_asset.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.generateVariantPrompt(
                            "refine_prompt",
                            _vm.provided_example
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-btn fa-edit" }),
                      _vm._v(" Generate New Asset\n                        "),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Name "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title: "The name of the asset that a treatment will be built off of.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Asset Description "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Describe the audience that is engaging with this asset, what their motivations are, and what action you are trying to get them to take.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Asset Type "),
      _c("em", [_vm._v("(Select one)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { attrs: { "data-bs-toggle": "tooltip", title: "Add Image" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ms-2" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-up-right-from-square" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }