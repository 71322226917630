var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("canvas", {
    staticClass: "bar-chart",
    class: "bars-" + _vm.labels.length,
    attrs: { id: "chart-" + this.id },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }