var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column gap-3",
      attrs: { id: "org_settings_connections_systems" },
    },
    [
      _c(
        "div",
        { attrs: { id: "system-connections-grid" } },
        _vm._l(_vm.local_connections, function (conn, index) {
          return _c(
            "div",
            { staticClass: "card system-connection-card h-100" },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "system-connection-card__header" }, [
                  _c(
                    "div",
                    { staticClass: "system-connection-card__header__icon" },
                    [
                      _c("img", {
                        staticClass:
                          "avatar rounded-circle avatar-sm me-2 bg-white",
                        attrs: {
                          src: conn.integration.logo_url,
                          alt: conn.integration.display_name,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "system-connection-card__header__status" },
                    [
                      !conn.sync_error_msg &&
                      !conn.last_synced_at &&
                      !conn.initial_sync_at
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-secondary d-inline-block",
                            },
                            [_vm._v("Initializing")]
                          )
                        : conn.sync_error_msg
                        ? _c(
                            "span",
                            { staticClass: "badge badge-error d-inline-block" },
                            [_vm._v("Error")]
                          )
                        : conn.active_jobs && conn.active_jobs.length > 0
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-success-dark d-inline-block",
                            },
                            [_vm._v("Syncing")]
                          )
                        : _c(
                            "span",
                            {
                              staticClass: "badge badge-success d-inline-block",
                            },
                            [_vm._v("Active")]
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.canSync(conn) || _vm.canDelete(conn)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "system-connection-card__header__actions",
                        },
                        [
                          _vm._m(0, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-menu" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.updateConnection(conn)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-btn fa-key" }),
                                  _vm._v(
                                    "Update Credentials\n                            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.canSync(conn)
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "#",
                                        disabled: _vm.disable_actions,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.syncConnection(conn)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-btn fa-sync me-2",
                                      }),
                                      _vm._v("Sync Now"),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canDelete(conn)
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "#",
                                        disabled: _vm.disable_actions,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.deleteConnection(conn.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-btn fa-trash me-2",
                                      }),
                                      _vm._v("Delete"),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "system-connection-card__content" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center" },
                    [
                      _c("div", { staticClass: "d-flex-flex-column" }, [
                        _c("h4", [
                          _vm._v(_vm._s(conn.integration.display_name)),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(conn.name))]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center" },
                    [
                      _c("div", { staticClass: "d-flex-flex-column" }, [
                        conn.sync_error_msg
                          ? _c("small", [
                              _vm._v(
                                "Error Message: " + _vm._s(conn.sync_error_msg)
                              ),
                            ])
                          : _c("small", [
                              _vm._v(
                                "Last Synced: " +
                                  _vm._s(_vm._f("date")(conn.last_synced_at)) +
                                  " | Created on: " +
                                  _vm._s(_vm._f("date")(conn.created_at))
                              ),
                            ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-100 d-flex flex-row align-items-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex-flex-column flex-grow-1",
                          on: {
                            click: function ($event) {
                              return _vm.viewConnection(conn)
                            },
                          },
                        },
                        [
                          _c(
                            "button",
                            { staticClass: "btn btn-secondary w-100 mt-2" },
                            [_vm._v("View Connection")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("system-modal", {
        attrs: {
          id: "connections-setting-systems-system-modal",
          client: _vm.client,
          integrations: _vm.integrations,
          connections: _vm.connections,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-none p-1",
        attrs: {
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "true",
          "aria-expanded": "false",
        },
      },
      [
        _c("img", {
          staticClass: "icon pe-0",
          attrs: { src: "/img/icons/dialexa-icons/vertical-dots.svg" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row", attrs: { id: "need-more-insights" } },
      [
        _c(
          "div",
          {
            staticClass:
              "d-inline-flex justify-content-center align-items-center",
          },
          [
            _c("p", [
              _vm._v("Need more insights?\n            "),
              _c(
                "a",
                {
                  staticClass: "text-decoration-underline",
                  attrs: {
                    href: "#",
                    "data-bs-toggle": "modal",
                    "data-bs-target":
                      "#connections-setting-systems-system-modal",
                  },
                },
                [_vm._v("Add another connection")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }