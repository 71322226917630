<template>
    <div id="org_settings_scorecard">
        <h4>Scorecard Filter for {{ client.name }}</h4>
        <p>Avid AI's Fundraiser's Scorecard is a great way to track the progress on the most important metrics for a successful fundraising program. However, not everyone at an organization cares about every segment of donor. To make this scorecard more meaningful for you, we've created a way for you to filter the metrics to the metrics you care most about.</p>
        <p class="mb-4">This filter will only apply to your user; each user can make adjustments based upon their own preferences.</p>
        <div v-if="data_blend.id == null">
            <h5 class="d-flex align-items-center">
                <div class="spinner-border text-primary me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading Filter Options
            </h5>
        </div>
        <div v-else>
            <query-inclusion-builder :client="client" v-model="scorecard_filter"
                :static_db_columns="data_blend.source_columns"
                :include_trans="false" :source="data_blend.data_source" :in_card="true" :add_blank="true"
                :data_source_id="data_blend.id" ref="filter"></query-inclusion-builder>
        </div>

        <button type="submit" class="btn btn-primary mt-5 btn-big" @click.prevent="update" :disabled="form.busy">
            <i class="fa fa-btn fa-save"></i> Save Changes
        </button>

    </div>
</template>

<script>
    export default {
        props: ['client'],
        data() {
            return {
                local_client:{},
                form: {
                    busy: false,
                    error: false
                },
                scorecard_filter: null,
                data_blend: {}
            };
        },
        beforeMount() {
            this.local_client = this.client;
            this.getScorecardFilter();
        },
        methods: {
            getScorecardFilter() {
                window.axios.get('/api/scorecard/' + this.client.url + '/filter', [])
                  .then(response => {
                    if(response.data.filter != null)
                        this.scorecard_filter = response.data.filter;
                    this.data_blend = response.data.blend;
                  });
            },
            update() {
                var self = this;
                this.form.busy = true;
                this.local_client.scorecard_filter = this.scorecard_filter;

                window.axios.put('/api/client/' + this.local_client.id, this.local_client)
                .then(response => {
                    self.form.busy = false;
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Scorecard Filter Updated!</h4>',
                        target: '#org_settings_scorecard',
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-primary'
                        }
                    });
                }).catch(error =>{
                    self.form.busy = false;
                    self.form.error = true;
                    setTimeout( function() {self.form.error = false} , 10000);
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Error on updating your scorecard filter</h4>',
                        target: '#org_settings_scorecard',
                        icon: 'warning',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-danger'
                        },
                    });

                });
            },
        }
    }
</script>
