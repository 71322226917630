<template>
    <div class="asset-details">
        <div class="card" :class="getCardViewClassList()" :id="'asset-card-'+asset.id">
            <div class="card-body position-relative">
                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label border-bottom">Name</label>
                        <p class="mb-0">{{ local_asset.name }}</p>
                    </div>
                    <div class="col-6 mb-3" v-if="!local_asset.if_current_user_reviewed_already && local_asset.previous_review_list.length > 0">
                        <label class="form-label border-bottom">Reviewed By</label>
                        <div>
                            <span class="viewed-user" v-for="review in local_asset.previous_review_list"
                            data-bs-toggle="tooltip" data-bs-placement="bottom" 
                            :title="(review.status=='approved') ?  'Approved at: '+  getDateAsString(review.updated_at):  'Rejected at: '+  getDateAsString(review.updated_at)">
                                <i class="fa fa-check green" v-if="review.status=='approved'"></i>
                                <i class="fa fa-close red" v-if="review.status=='rejected'"></i>
                                {{ review.updated_by_user.name }}
                            </span>                                
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label class="form-label border-bottom">Asset Description</label>
                        <p v-if="local_asset.description != null && local_asset.description != '' && local_asset.description != 'TODO'" class="mb-0">{{ local_asset.description }}</p>
                    </div>
                </div>

                <div class="mt-3" v-if="(asset.status !='approved' || (asset.status=='approved' && is_editing_approved_asset))">
                    <div v-for="(input_field, index) in local_inputs" :key="asset.id + input_field.placeholder">
                        <div class="row mb-3" v-if="input_field.orig_value != null && input_field.orig_value != ''">
                            <div class="col-12">
                                <label class="form-label mb-0"  :class="{'border-bottom' : (is_pdf_view)}">{{input_field.name}}</label>
                                
                                <p v-if="(is_pdf_view &&input_field.type!='wysiwyg') ">{{ input_field.value }}</p>
                                <div class="mt-2" v-if="(is_pdf_view &&input_field.type=='wysiwyg')" v-html="input_field.value"></div>
                                
                                <div v-if="!is_pdf_view">
                                    <div :class="getErrorForMaxLength(input_field)" :maxlength="input_field.max_length" :currentlength="(input_field.value != null) ? input_field.value.length: 0" v-if="(input_field.type=='text' || input_field.type=='link')">
                                        <input type="text" v-model="input_field.value" :maxlength="input_field.max_length"
                                        class="form-control" :class="getErrorForInputField(input_field)" @change="inputFieldValueChange()">
                                    </div>
                                    <textarea :id="input_field.placeholder+'-'+asset.id" v-if="(input_field.type=='textarea' || input_field.type=='template')"  v-model="input_field.value"  :maxlength="input_field.max_length" class="full-height form-control" 
                                    :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange()" ref="textarea"></textarea>
                                    <div v-if="input_field.type=='image'" class="image-array-section">
                                        <div v-for="(image_url, image_index) in input_field.value">
                                            <img :src="image_url" class="me-3 mb-1 img-thumbnail">
                                        </div>
                                    </div>
                                    <!-- <vue-dropzone v-if="input_field.type=='file'" ref="file-dropzone"
                                        class="input-dropzone" :id="'file-dropzone-' + local_asset.id"
                                        :options="fileDropzoneOptions" :disabled="asset.status =='approved'"
                                        v-on:vdropzone-processing="onProcessing"
                                        v-on:vdropzone-success="onSuccess"
                                        v-on:vdropzone-sending="onSending"></vue-dropzone> -->
                                    <wysiwyg-editor-advanced v-if="input_field.type=='wysiwyg'" :if_show_button_input="if_show_button_input" :input_value="input_field.value" :input_index="index" :asset_id="local_asset.id"
                                        @wysiwygValueChange="wysiwygValueChange"></wysiwyg-editor-advanced>

                                    <select v-if="input_field.type=='dropdown'"  v-model="input_field.value" class="form-select w-auto form-control"
                                    :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange()">
                                        <option value="" disabled selected>Select the value...</option>
                                        <option v-for="option in input_field.options" :value=option>{{ option }}</option>
                                    </select>                                    

                                </div>
                                <div class="text-danger invalid-feedback" v-if="(errors[input_field.placeholder] != null && errors[input_field.placeholder] != '' )" v-html="errors[input_field.placeholder]">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <!-- Success Message -->
                        <div class="alert alert-success" v-if="form.successful">
                            The asset's information has been updated!
                        </div>
                        <div class="alert alert-danger" v-if="form.error">
                            There was an error saving the asset information.
                        </div>
                        <div class="alert alert-danger unsaved-warning d-none" >
                            This asset has been changed since you last saved it. Please save your changes or they will be lost.
                        </div>
     
                        <div v-if="!is_pdf_view">
                            <hr class="section-divider">
                            <div class="mt-2 mb-3">
                                <label class="form-label">Your Comments <em>(Optional)</em></label>
                                <textarea :id="'comment-'+asset.id" v-model="local_asset.comments" class="full-height form-control" ref="textarea" @input="resizeTextarea('comment-'+asset.id)"></textarea>

                            </div>
                            <div class="d-flex align-items-center">
                                <button type="submit" class="btn btn-primary me-2"
                                        :disabled="form.busy" @click="approveAsset()">
                                    <i class="fa fa-btn fa-check"></i>
                                    {{ (this.is_changes_made ? "Approve with Edits" : "Approve" ) }}
                                </button>
                                <button type="button" class="btn btn-secondary"
                                        :disabled="form.busy" @click="unapproveAsset()">
                                    <i class="fa fa-btn fa-trash"></i> Reject
                                </button>
                                <div class="callout">
                                    Please note, this is not a final approval or rejection. Others in your organization can review once you are finished, if you choose.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        components: {},
        props: {
            asset: {},
            client: {},
            is_pdf_view: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {    
                local_asset: {},
                local_inputs:[],
                is_changes_made:false,
                is_editing_approved_asset: false,
                if_show_button_input: false,
                errors: {},
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
            };
        },
        beforeMount() {
            this.local_asset = {...this.asset};
            this.loadInputs();
            this.checkIfShowButtonInput(); 
            this.errors['asset_name']='';
            window.addEventListener("beforeunload", this.preventNav)
        },
        mounted(){
            this.resizeTextarea();

            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        beforeDestroy() {
            window.removeEventListener("beforeunload", this.preventNav);
        },
        methods: {
            getCardViewClassList(){
                if (this.local_asset.if_current_user_reviewed_already){
                    return "no-border"
                }
                if (!this.is_pdf_view){
                    return 'mt-3 box-content';
                } 
                return 'mt-3';
            },
            checkIfShowButtonInput(){
                if(this.asset.asset_type.name == "Donor Emails" || this.asset.asset_type.name == "Prospecting Emails"
                || this.asset.asset_type.name == "Housefile Emails"){
                    this.if_show_button_input = true;
                }
            },
            preventNav(event) {
                if (!this.is_changes_made) return;
                this.getAssetDetailCardClass();
                event.preventDefault();
                event.returnValue = false;
                return false;
            },
            wysiwygValueChange(newValueWithIndex){
                this.local_inputs.forEach((input, index) => {
                    if (input.type=='wysiwyg' && index == newValueWithIndex.index){
                        if (this.local_inputs[index].value != newValueWithIndex.value){
                            this.is_changes_made = true;
                        }
                        this.local_inputs[index].value = newValueWithIndex.value;
                    }
                });
            },
            loadInputs(){
                this.local_inputs = [];
                this.local_asset.asset_type.inputs.forEach(input => {
                    var val = null;
                    if(this.local_asset.inputs != null) {
                        let ph = this.local_asset.inputs.find(i => i.placeholder == input.placeholder);
                        if(ph != null)
                            val = ph.value;
                    }

                    this.local_inputs.push({
                        name: input.name,
                        description:input.description,
                        max_length:input.max_length,
                        placeholder:input.placeholder,
                        type:input.type,
                        optional:input.optional,
                        options:input.options,
                        value: val,
                        orig_value: val,
                    });

                    if(input.max_length != null && val != null && input.max_length < val.length){
                        this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". When generating text, the AI has a hard time following character limit rules&mdash;you'll need to adjust."
                    }
                    else{
                        this.errors[input.placeholder]='';
                    }
                });
            },
            resizeTextarea(id) {
                if(id == undefined)
                    var element = document.querySelectorAll('textarea');
                else
                    var element = [document.getElementById(id)];

                for(var index = 0; index<element.length; index++){
                    if (element[index] && element[index].style){
                        element[index].style.height = '';
                        element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                    }                            
                }
            },
            getDateAsString(value) {
                return moment.utc(String(value)).local().format('M/DD/YYYY h:mm a')
            },
            inputFieldValueChange(){
                this.is_changes_made = true;
            },
            getErrorForMaxLength(input) {
                var classes = ''
                if (input.value != null && input.max_length != null && input.value.length > input.max_length){
                    this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". When generating text, the AI has a hard time following character limit rules&mdash;please adjust the text above."
                }
                else if(input.value != null && input.value != "")
                    this.errors[input.placeholder] = "";
                
                if (input.max_length != null)
                    classes += 'max-warning'

                if(this.errors[input.placeholder] != null && this.errors[input.placeholder] != '' )
                    classes +=' is-invalid';

                return classes;
            },
            getErrorForInputField(input){
                if(this.errors[input.placeholder] != null && this.errors[input.placeholder] != '' )
                    return 'is-invalid';
                else
                    return '';
            },
            clearErrors(){
                for (const error_name in this.errors) {
                    this.errors[error_name] = ""
                }
                this.form.error = false;
            },
            hasErrors(){// only check for empty field when trying to approve an asset
                this.local_inputs.forEach(input => {
                    //Just handle the null exceptions
                    if(input.value == null)
                        input.value = '';

                    if (input.value == "" && input.optional == 0){
                        this.errors[input.placeholder] = input.name + " is required.";
                    } else if (input.max_length != null && input.value.length > input.max_length){
                        this.errors[input.placeholder] = "Maximum length exceeded. The max length for " + input.name + " is " + input.max_length;
                    } else {
                        this.errors[input.placeholder] = ""
                    }
                })

                for (const error_name in this.errors) {
                    if (this.errors[error_name] != "") {
                        return true;
                    }
                }

                return false;
            },
            approveAsset(){
                var self = this;

                this.clearErrors();
                if(this.hasErrors()) {
                    this.$forceUpdate();
                    return;
                }

                var asset_input = []
                this.local_inputs.forEach(input_field =>{
                    asset_input.push({
                        placeholder: input_field.placeholder,
                        value: input_field.value
                    });
                });
                this.local_asset.inputs = asset_input;

                window.axios.put('/api/campaigns/'+this.client.url+'/approve/'+this.local_asset.id, this.local_asset)
                    .then(response => {
                    self.form.busy = false;
                    if(response.status == 200) {
                        self.form.successful = true;
                        this.local_asset.status = 'approved';
                        setTimeout( function() {self.form.successful = false} , 5000);
                        this.local_asset.previous_review_list.push(response.data.asset);
                        this.$emit('approveAsset', response.data.asset);
                        this.is_changes_made = false;
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                });
            },
            unapproveAsset(){
                var self = this;

                this.clearErrors();
                this.form.busy = true;

                window.axios.put('/api/campaigns/'+this.client.url+'/unapprove/'+this.local_asset.id, this.local_asset)
                    .then(response => {
                    self.form.busy = false;
                    if(response.status == 200) {
                        self.form.successful = true;
                        this.local_asset.status = 'rejected';
                        setTimeout( function() {self.form.successful = false} , 5000);
                        this.local_asset.previous_review_list.push(response.data.asset);
                        this.$emit('approveAsset', response.data.asset)
                        this.is_changes_made = false;
                    }
                    else {
                        self.form.error = true;
                        setTimeout( function() {self.form.error = false} , 10000);
                    }
                });
            },
        }
    }
</script>
