<template>   
    <div class="row">
        <div class="col-12 mb-3">
            <label class="form-label">Chart</label>
            <v-select id="add-chart-dropdown" :options="chart_options" v-model="chart_dropdown_selected"
                placeholder="Select chart..."
                :searchable="true" class="searchable-select column-display w-100"
                :selectable="(option) => option.text != ''"
                label="text" @search="searchChartOption"
                @input="emitChartSelect"
                :filterable="false">
                <template #selected-option="{ text, category }">
                    <span>{{text}}</span>
                </template>

                <template #option="{ text, category }">
                    <div class="suboption" :data-subcategory="category"
                        :class="category==null || category==''? 'show' : ''">
                        {{ text }}
                    </div>
                </template>
                
                <template #no-options="{ search, searching, loading }">
                    <div class="suboption show">
                        <em>No results found</em>
                    </div>
                </template>
            </v-select>
            <div class="text-danger invalid-feedback" v-if="add_chart_errors.chart_selected != ''">
                {{add_chart_errors.chart_selected}}
            </div>
        </div>
        

        <div class="col-12 mb-3">
            <label class="form-label">Title</label>
            <input type="text" id="chart-name-input" class="form-control" v-model="chart_modal_fields.title" :disabled="!chart_modal_fields.chart_selected">                                
        </div>
        <div class="col-12 mb-3">
            <label class="form-label">Subtitle</label>
            <input type="text" id="chart-name-input" class="form-control" v-model="chart_modal_fields.subtitle" :disabled="!chart_modal_fields.chart_selected">                                
        </div>
        
        <div class="col-12">
            <button type="submit" class="btn btn-primary" id="add-chart-button"
                    @click.prevent="emitChartChangeToSection"
                    :disabled="form.busy || !chart_modal_fields.chart_selected">
                <i class="fa fa-btn fa-save"></i> {{modal_action}}
            </button>
        </div>
    </div>
</template>


<script>
import vSelect from "vue-select";   

    export default {
        components: { vSelect },
        props: ['modal_action', 'charts', 'section_chart', 'section_index'],
        data() {
            return {
                chart_options: [],
                chart_dropdown_selected: null,
                chart_modal_fields:{
                    chart_selected: null,
                    title: '',       
                    subtitle: '',
                    section_index: null             
                },
                add_chart_errors:{
                    chart_selected: '',
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false 
                },
            };
        },
        beforeMount() {
            this.chart_options = this.loadChartOptions();
            if(this.section_chart){
                this.loadSectionChartDetails();
            }
        },
        watch: {
            section_chart(new_value, old_value){
                this.loadSectionChartDetails();
            },
            charts: {
                handler(n, o) {
                    this.chart_options = this.loadChartOptions();
                },
                deep: true
            }
        },
        methods: {
            loadChartOptions(){
                var chart_list = [];
                if(this.charts)
                    this.charts.forEach((c)=>{
                        var chart_type = String(c.type).replaceAll("_", " ").replace(
                        /\w\S*/g,
                        function(txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                        });
                        chart_list.push({
                            category:"",
                            text: c.name + " (" + chart_type +" Chart)",
                            type: c.type,
                            value: c.id,
                        })
                    });
                return chart_list;
            },
            searchChartOption(search_key){
                var option_list = this.loadChartOptions();
                if(search_key == "") 
                    this.chart_options = option_list;
                else    
                    this.chart_options = option_list.filter(option => option.text.toLowerCase().includes(search_key.toLowerCase()));
            },
            emitChartSelect(event){
                var chart_obj = this.charts.find((c)=>c.id == event.value);
                this.chart_modal_fields = {
                    chart_selected: chart_obj,
                    title: chart_obj.title,
                    subtitle: chart_obj.subtitle,
                    section_index: this.section_index
                }
            },
            loadSectionChartDetails(){
                this.chart_modal_fields = {
                    chart_selected: this.charts.find((c)=>c.id == this.section_chart.report_chart_id),
                    title: this.section_chart.title,
                    subtitle: this.section_chart.subtitle,
                    section_index: this.section_index
                }; 
                this.chart_dropdown_selected = this.chart_options.find((c)=>c.value == this.section_chart.report_chart_id);
            },
            emitChartChangeToSection(){
                if (this.modal_action == 'Add'){
                    this.$emit('chartAdded', this.chart_modal_fields);
                    this.chart_modal_fields={
                        chart_selected: null,
                        title: '',       
                        subtitle: '',
                        section_index: this.section_index             
                    }
                    this.chart_dropdown_selected = null;
                } else {
                    this.$emit('chartUpdated', this.chart_modal_fields)
                }

            }
        }
    }
</script>
