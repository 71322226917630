var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "metric-filter-builder" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Display Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_mf_selected.name,
              expression: "local_mf_selected.name",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors.admin_name != "" ? "is-invalid" : "",
          attrs: { type: "text", id: "name-input" },
          domProps: { value: _vm.local_mf_selected.name },
          on: {
            change: function ($event) {
              return _vm.clearErrorForField("name")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.local_mf_selected, "name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.name != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.name) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Admin Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_mf_selected.admin_name,
              expression: "local_mf_selected.admin_name",
            },
          ],
          staticClass: "form-control",
          class: _vm.errors.admin_name != "" ? "is-invalid" : "",
          attrs: { type: "text", id: "name-input" },
          domProps: { value: _vm.local_mf_selected.admin_name },
          on: {
            change: function ($event) {
              return _vm.clearErrorForField("admin_name")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.local_mf_selected, "admin_name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.admin_name != ""
          ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.admin_name) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.type.toLowerCase() == "metric"
        ? _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("Format")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_mf_selected.format,
                      expression: "local_mf_selected.format",
                    },
                  ],
                  staticClass: "form-select",
                  class: _vm.errors.format != "" ? "is-invalid" : "",
                  attrs: { id: "format-select" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_mf_selected,
                          "format",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.selectFormat(_vm.local_mf_selected.format)
                      },
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [_vm._v("Select the format...")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "money" } }, [
                    _vm._v("Money"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "percent" } }, [
                    _vm._v("Percent"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "number" } }, [
                    _vm._v("Number"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "string" } }, [
                    _vm._v("String"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.errors.format != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.format) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type.toLowerCase() == "metric"
        ? _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Preferred Color (suggested based on Format type)"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_mf_selected.color,
                      expression: "local_mf_selected.color",
                    },
                  ],
                  staticClass: "form-select",
                  class: _vm.errors.color != "" ? "is-invalid" : "",
                  attrs: { id: "color-select" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_mf_selected,
                          "color",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.clearErrorForField("color")
                      },
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [_vm._v("Select the color...")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "yellow" } }, [
                    _vm._v("Yellow"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "green" } }, [
                    _vm._v("Green"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "blue" } }, [_vm._v("Blue")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "purple" } }, [
                    _vm._v("Purple"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.errors.color != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.color) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col mb-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Description")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_mf_selected.description,
              expression: "local_mf_selected.description",
            },
          ],
          staticClass: "form-control",
          attrs: { name: "mf_description" },
          domProps: { value: _vm.local_mf_selected.description },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.local_mf_selected,
                "description",
                $event.target.value
              )
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.type.toLowerCase() == "metric"
        ? _c("div", { staticClass: "col-3 mb-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_mf_selected.importance,
                    expression: "local_mf_selected.importance",
                  },
                ],
                staticClass: "form-select",
                attrs: { id: "importance" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.local_mf_selected,
                      "importance",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0.1" } }, [
                  _vm._v("1 - Not Very Important"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "0.3" } }, [_vm._v("2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "0.5" } }, [_vm._v("3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "0.7" } }, [_vm._v("4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "0.9" } }, [
                  _vm._v("5 - Most Important"),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "mb-3" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_mf_selected.formula,
                    expression: "local_mf_selected.formula",
                  },
                ],
                staticClass: "full-height form-control formula-input",
                class: {
                  "is-invalid": _vm.errors.formula != "",
                  "is-valid":
                    _vm.errors.formula == "" &&
                    _vm.local_mf_selected.formula.trim() != "" &&
                    !_vm.is_validating,
                  "is-validating": _vm.is_validating,
                },
                attrs: { name: "mf_description" },
                domProps: { value: _vm.local_mf_selected.formula },
                on: {
                  change: function ($event) {
                    return _vm.validateFormula()
                  },
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.local_mf_selected,
                        "formula",
                        $event.target.value
                      )
                    },
                    function ($event) {
                      return _vm.resizeTextarea()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "searchable-select",
                attrs: {
                  id: "formula-column-toggle ",
                  options: _vm.local_columns,
                  searchable: true,
                  placeholder: "Columns",
                  selectable: (option) => option.text != "",
                  label: "text",
                  filterable: false,
                },
                on: {
                  search: _vm.fetchOptions,
                  input: function ($event) {
                    return _vm.addColumnToText(_vm.column_selected)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selected-option",
                    fn: function ({ text, category }) {
                      return [
                        _vm._v(
                          "\n                        " +
                            _vm._s("Columns") +
                            "\n                        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "open-indicator",
                    fn: function ({ attributes }) {
                      return [
                        _c(
                          "span",
                          _vm._b(
                            {
                              staticStyle: {
                                width: "12px",
                                "line-height": "8px",
                              },
                            },
                            "span",
                            attributes,
                            false
                          ),
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 16 16",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    fill: "white",
                                    stroke: "#ffffff",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    d: "m2 5 6 6 6-6",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function ({ text, category }) {
                      return [
                        text == ""
                          ? _c(
                              "div",
                              {
                                staticClass: "category-header",
                                attrs: {
                                  "data-category": category,
                                  "data-isexpanded": "false",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.expandCategory(category, null)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(category) +
                                    " "
                                ),
                                _c("i", {
                                  staticClass: "fa-solid fa-caret-right",
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "suboption",
                                class:
                                  category == null || category == ""
                                    ? "show"
                                    : "",
                                attrs: { "data-subcategory": category },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(text) +
                                    "\n                        "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                  {
                    key: "no-options",
                    fn: function ({ search, searching, loading }) {
                      return [
                        _vm.is_loading
                          ? _c("div", { staticClass: "suboption show" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "spinner-border spinner-border-sm text-warning float-left",
                                  attrs: { role: "status" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "visually-hidden" },
                                    [_vm._v("Loading...")]
                                  ),
                                ]
                              ),
                              _vm._v(
                                "  Loading columns\n                            "
                              ),
                            ])
                          : _c("div", { staticClass: "suboption show" }, [
                              _c("em", [_vm._v("No results found")]),
                            ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.column_selected,
                  callback: function ($$v) {
                    _vm.column_selected = $$v
                  },
                  expression: "column_selected",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.errors.formula != ""
            ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.errors.formula) +
                    "\n                "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.clients
        ? _c("div", { staticClass: "col-6 mb-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Client's Data Blend to Validate Against"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local_mf_selected.validate_data_blend,
                    expression: "local_mf_selected.validate_data_blend",
                  },
                ],
                staticClass: "form-select",
                attrs: { id: "validate_data_blend" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.local_mf_selected,
                      "validate_data_blend",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.clients, function (c) {
                return _c("option", { domProps: { value: c } }, [
                  _vm._v(_vm._s(c.name)),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _vm.form.error
          ? _c("div", { staticClass: "alert alert-danger mb-3" }, [
              _vm._v(
                "\n                There was an error saving the " +
                  _vm._s(this.type) +
                  " information.\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              type: "submit",
              id: "save-button",
              disabled: _vm.form.busy || _vm.is_validating,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.update.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "fa fa-btn fa-save" }),
            _vm._v(
              " " +
                _vm._s(this.local_mf_selected.id > 0 ? "Update" : "Save") +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Importance "),
      _c("i", {
        staticClass: "fas fa-info-circle ms-2",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Rate the importance of this metric with regards to a fundraising program on a scall from 1 to 5",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Formula ("),
      _c("small", [
        _vm._v("Use "),
        _c("code", { pre: true }, [_vm._v("{{client.id}}")]),
        _vm._v(" to insert the client ID into the formula"),
      ]),
      _vm._v(")"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }