<template>
    <div class="onboarding-personal-info">       

        <h2 class="mt-2 mb-3">Step 1: Personal Information</h2>
        <div class="row">
            <div class="mb-3 col-6">
                <label>First Name *</label>
                <input type="text" class="form-control" :class="((errors.first_name != '' )? 'is-invalid':'')"
                    name="first_name" v-model="new_user.first_name" @change="errors.first_name = ''">
                <div class="text-danger invalid-feedback" v-if="errors.first_name != ''">
                    {{errors.first_name}}
                </div>
            </div>
            <div class="mb-3 col-6">
                <label>Last Name *</label>
                <input type="text" class="form-control" :class="((errors.last_name != '' )? 'is-invalid':'')"
                    name="last_name" v-model="new_user.last_name"  @change="errors.last_name = ''">
                <div class="text-danger invalid-feedback" v-if="errors.last_name != ''">
                    {{errors.last_name}}
                </div>
            </div>
            <div class="mb-3 col-12">
                <label>Email *</label>
                <input type="text" class="form-control" :class="((errors.email != '' )? 'is-invalid':'')"
                    name="email" v-model="new_user.email" @change="validateEmail()">
                <div class="text-danger invalid-feedback" v-if="errors.email != ''" v-html='errors.email'></div>
            </div>
            <div class="mb-3 col-12">
                <label>Password *</label>
                <div class="input-group" >
                    <input aria-label="Password" aria-describedby="password-addon" ref="password" autocomplete="off"  name="password" 
                    :type="new_user.password.type == 'password'? 'password' : 'text'"  :class="((errors.password != '' )? 'is-invalid':'')"
                    class="form-control password-input input_v1" v-model="new_user.password.value"  @change="errors.password = ''">

                    <span :title="!new_user.password.visible ? 'Show password' : 'Hide password'" @click="toggleVisibility(new_user.password)"
                        class="input-group-text" id="password-addon"
                        style="border-left: none; background-color: white">
                        <i class="fa" :class="[!new_user.password.visible ? 'fa-eye' : 'fa-eye-slash']"></i>
                    </span>
                </div>
                <div class="text-danger invalid-feedback" v-if="errors.password != ''">
                    {{errors.password}}
                </div>
            </div>

            <div class="mb-3 col-12">
                <button type="submit" class="btn btn-primary w-100 mt-2" @click="nextStep()"
                     :disabled="processing">
                     <span v-if="processing">
                        <div class="spinner-border  spinner-border-sm text-white float-left" role="status"></div>Processing
                    </span>
                    <span v-else>Sign Up</span>
                </button>
            </div>

            <hr>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="text-center">Or Sign Up Using:</p>
                <div class="d-flex flex-row gap-2">
                    <a href="/auth/register/google" class="btn w-100"  :class="error.google != null ? 'btn-error' : 'btn-secondary '" :disabled="processing">
                        <i class="fa-brands fa-google me-2"></i> Google
                    </a>
                </div>
                <span class="invalid-feedback mt-2" role="alert" v-if="error.google != null">
                    <strong v-html="error.google[0]"></strong>
                </span>
                <hr>
            </div>
            
        </div>
        
        <p>Already have an account?
            <a class="btn btn-link ps-0" href="/login">
                Log In
            </a>
        </p>
              
    </div>
</template>

<script>
    export default {
        props: ['error'],
        data() {
            return {
                new_user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: {
                        value: '',
                        type: 'password',
                        visible: false
                    },
                },
                errors: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                },
                processing: false
            };
        },
        beforeMount() {
        },
        methods: {
            toggleVisibility(field){
             
                if (field.type === 'password') {
                    field.type = 'password-text';
                    field.visible = true;
                } else if (field.type === 'password-text') {
                    field.type = 'password';
                    field.visible = false;
                }
            },
            hasErrors() {//validate the form
                if(this.new_user.first_name == null || this.new_user.first_name == ""){
                    this.errors.first_name = "First Name is required.";
                } else
                    this.errors.first_name = "";
                
                if(this.new_user.last_name == null || this.new_user.last_name == ""){
                    this.errors.last_name = "Last Name is required.";
                } else
                    this.errors.last_name = "";

                const reg = /^\w+([\.-]?\w+)*(\+?\w+([\.-]?\w+)*)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if(this.new_user.email == null || this.new_user.email == ""){
                    this.errors.email = "Email is required.";
                } else if(!reg.test(this.new_user.email)){
                    this.errors.email = 'Please enter a valid email address.';
                } else if (this.errors.email == 'The email entered is already attached to an existing user.'){
                    this.errors.email = 'The email entered is already attached to an existing user.';
                } else{
                    this.errors.email = "";
                }

                if(this.new_user.password.value == null || this.new_user.password.value == ""){
                    this.errors.password = "Password is required.";
                } else
                    this.errors.password = "";
                
                for (const error_name in this.errors) {
                    if (this.errors[error_name] != "") {
                        return true;
                    }
                }
                return false; 
            },
            validateEmail() {
                window.axios.post('/api/new_user/check_email', this.new_user)
                  .then(response => {
                    if(response.data.exists) {
                        this.errors.email = 'The email entered is already attached to an existing user. <a href="/login">Click here to login.</a>';
                    } else {
                        this.errors.email = '';
                    }
                  }).catch(error => {
                    // Handle errors here
                    if (error.response.data.message != null) {
                      this.errors.email = error.response.data.message;
                    }
                  });
                  this.errors.email = ''
            },
            nextStep(){
                if(this.hasErrors()) {
                     return;
                }
                this.processing = true;

                //Get the hubspotutk cookie
                this.new_user.hubspotutk = this.getCookieValue('hubspotutk');
                this.new_user.page_url = window.location.href;
                this.new_user.page_name = document.title;

                window.axios.post('/api/register', this.new_user)
                  .then(response => {
                    this.processing = false;
                    this.$emit('nextStep', response.data.user);

                  }).catch(error => {
                    // Handle errors here
                    if (error.response.status === 422) {
                      self.form.error = true;
                      this.errors.email = 'The email entered is not in the correct format.';
                    }
                    // Handle errors here
                    else if (error.response.status === 412) {
                      this.errors.email = 'The email entered is already attached to an existing user. <a href="/login">Click here to login.</a>';
                    }
                  });

            },
            getCookieValue(cookieName) {
                const cookies = document.cookie.split('; ');
                
                for (let i = 0; i < cookies.length; i++) {
                    const [name, value] = cookies[i].split('=');
                    if (name === cookieName) {
                        return decodeURIComponent(value);
                    }
                }
                return null; // Return null if the cookie is not found
            }

        }
    }
</script>
