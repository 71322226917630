var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "pathways-manage-segments" } }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-secondary float-end",
            attrs: { href: "/segment/" + _vm.client.url + "/new" },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: { src: "/img/icons/dialexa-icons/plus.svg" },
            }),
            _vm._v("Add Segment\n            "),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card my-3" }, [
      _c("div", { staticClass: "card-header" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row justify-content-between align-items-center gap-3",
          },
          [
            _c("div", { staticClass: "d-flex justify-content-start" }, [
              _c("h5", [
                _vm._v(
                  "Segments (" + _vm._s(_vm.filtered_segment_list.length) + ")"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-end justify-self-end gap-2",
              },
              [
                _c("div", { staticClass: "position-relative" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search_key,
                        expression: "search_key",
                      },
                    ],
                    staticClass: "search-box form-control me-1",
                    attrs: {
                      type: "text",
                      placeholder: "Search by Segment Name...",
                      "aria-label": "Search by Segment Name...",
                    },
                    domProps: { value: _vm.search_key },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.search_key = $event.target.value
                        },
                        function ($event) {
                          return _vm.handleFilterSelection()
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _vm.search_key == ""
                    ? _c("i", {
                        staticClass: "fa fa-search search-placeholder-icon",
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown-menu ps-3" },
                  [
                    _c("p", [_vm._v("Source:")]),
                    _vm._v(" "),
                    _vm._l(_vm.source_list, function (item) {
                      return _c(
                        "label",
                        { key: "source-" + item, staticClass: "dropdown-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.source,
                                expression: "filters.source",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", "true-value": [] },
                            domProps: {
                              value: item,
                              checked: Array.isArray(_vm.filters.source)
                                ? _vm._i(_vm.filters.source, item) > -1
                                : _vm._q(_vm.filters.source, []),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.filters.source,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? [] : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.filters,
                                          "source",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.filters,
                                          "source",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.filters, "source", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.handleFilterSelection()
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ms-2" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("p", [_vm._v("Pushed Pathway:")]),
                    _vm._v(" "),
                    _vm._l(_vm.dest_list, function (item) {
                      return _c(
                        "label",
                        { key: "dest-" + item, staticClass: "dropdown-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.destination,
                                expression: "filters.destination",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", "true-value": [] },
                            domProps: {
                              value: item,
                              checked: Array.isArray(_vm.filters.destination)
                                ? _vm._i(_vm.filters.destination, item) > -1
                                : _vm._q(_vm.filters.destination, []),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.filters.destination,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? [] : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.filters,
                                          "destination",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.filters,
                                          "destination",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.filters, "destination", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.handleFilterSelection()
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ms-2" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-body avid-table pathways-manage-segment-table p-0",
        },
        [
          _c("table", { staticClass: "table table-responsive m-0" }, [
            _c("thead", [
              _c("tr", { staticClass: "table-header-row" }, [
                _c(
                  "th",
                  {
                    staticClass: "table-column-1",
                    on: {
                      click: function ($event) {
                        return _vm.sortByKey("name")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _vm._v(
                        "\n                                Segment\n                                "
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "name" && _vm.sort_order == "asc"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "name" && _vm.sort_order == "des"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "table-column-2",
                    on: {
                      click: function ($event) {
                        return _vm.sortByKey("sizes")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _vm._v(
                        "\n                                Contacts\n                                "
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "sizes" && _vm.sort_order == "asc"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "sizes" && _vm.sort_order == "des"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "table-column-3",
                    on: {
                      click: function ($event) {
                        return _vm.sortByKey("data_source")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _vm._v(
                        "\n                                Data Source\n                                "
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "data_source" &&
                            _vm.sort_order == "asc"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "data_source" &&
                            _vm.sort_order == "des"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "table-column-4",
                    on: {
                      click: function ($event) {
                        return _vm.sortByKey("pushed_pathways")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _vm._v(
                        "\n                                Pushed Pathways\n                                "
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "pushed_pathways" &&
                            _vm.sort_order == "asc"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-up.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "sort-key",
                          class:
                            _vm.sort_key == "pushed_pathways" &&
                            _vm.sort_order == "des"
                              ? "sort-key-active"
                              : "",
                          attrs: {
                            src: "/img/icons/dialexa-icons/chevron-down.svg",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "table-column-5",
                    staticStyle: { width: "182px" },
                  },
                  [_vm._v("Action")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.segment_list_shown, function (segment, index) {
                return _c("tr", { class: `table-row-${index + 1}` }, [
                  _c("td", { staticClass: "align-start table-column-1" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(segment.name) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "table-column-2 text-nowrap" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm._f("number_with_zero")(
                            _vm.getDefaultSize(segment.sizes)
                          )
                        ) +
                        " "
                    ),
                    _c("i", {
                      staticClass: "fas fa-info-circle text-primary ms-2",
                      attrs: {
                        "data-bs-toggle": "tooltip",
                        title:
                          "The actual number of records synced to the destination will vary based what that system uses to determine unique records.",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-start table-column-3" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          segment.data_source ? segment.data_source.name : ""
                        ) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "align-start table-column-4" },
                    _vm._l(segment.pushed_pathways, function (pathway) {
                      return pathway.indexOf("File") === -1
                        ? _c(
                            "div",
                            { staticClass: "badge badge-secondary me-2 my-1" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(pathway) +
                                  "\n                            "
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "align-start table-column-5 show-on-row-hover",
                    },
                    [
                      _vm.export_connection_id != null
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none me-1",
                              attrs: {
                                disabled:
                                  _vm.export_busy[segment.id] != null &&
                                  _vm.export_busy[segment.id] == true,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.exportSegment(segment)
                                },
                              },
                            },
                            [
                              _vm.export_busy[segment.id] == null ||
                              _vm.export_busy[segment.id] == false
                                ? _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/file-download.svg",
                                      "data-bs-toggle": "tooltip",
                                      "data-bs-placement": "bottom",
                                      title: "Export Segment",
                                    },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border spinner-border-sm ms-2",
                                      attrs: { role: "status" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "visually-hidden" },
                                        [_vm._v("Loading...")]
                                      ),
                                    ]
                                  ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-none px-1",
                          attrs: {
                            href:
                              "/segment/" +
                              _vm.client.url +
                              "/clone/" +
                              segment.id,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: "/img/icons/dialexa-icons/document-copy.svg",
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "bottom",
                              title: "Copy Segment",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-none px-1",
                          attrs: {
                            href:
                              "/segment/" + _vm.client.url + "/" + segment.id,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: "/img/icons/dialexa-icons/pencil.svg",
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "bottom",
                              title: "Edit Segment",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-none px-1",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteSegment(segment)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: "/img/icons/dialexa-icons/trash.svg",
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "bottom",
                              title: "Delete Segment",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("tfoot", { staticClass: "table-border-bottom-0" }, [
              _c("tr", [
                _c("td", { staticClass: "py-0", attrs: { colspan: "100%" } }, [
                  _c("div", { staticClass: "table-pagination" }, [
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _c("span", [_vm._v("Showing: ")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.num_per_page,
                              expression: "num_per_page",
                            },
                          ],
                          staticClass: "form-select",
                          attrs: { name: "num_per_page", id: "num_per_page" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.num_per_page = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.reloadPagination()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "5000" } }, [
                            _vm._v("ALL"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.num_per_page_options, function (num) {
                            return _c("option", { domProps: { value: num } }, [
                              _vm._v(_vm._s(num)),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Segments")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.item_interval) +
                          " of " +
                          _vm._s(_vm.filtered_segment_list.length) +
                          "\n                                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _vm.page_count > 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: { disabled: _vm.page_index == 0 },
                              on: {
                                click: function ($event) {
                                  return _vm.moveToPrevPage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-left.svg",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.page_index_array.length <= 3
                        ? _c(
                            "div",
                            _vm._l(_vm.page_index_array, function (page) {
                              return _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  class:
                                    page == _vm.page_index + 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(page)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(page) +
                                      "\n                                        "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            [
                              _vm._l(
                                _vm.page_index_array.slice(0, 2),
                                function (page) {
                                  return _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      class:
                                        page == _vm.page_index + 1
                                          ? "current-page"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveToPage(page)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(page) +
                                          "\n                                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.page_index > 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        ...\n                                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index >= 2 &&
                              _vm.page_index < _vm.page_index_array.length - 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-none px-1 current-page",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(_vm.page_index + 1) +
                                          " \n                                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index < _vm.page_index_array.length - 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        ...\n                                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  class:
                                    _vm.page_index ==
                                    _vm.page_index_array.length - 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(
                                        _vm.page_index_array.length
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.page_index_array.length) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                      _vm._v(" "),
                      _vm.page_count > 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: {
                                disabled: _vm.page_index == _vm.page_count - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.moveToNextPage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-right.svg",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("h5", [_vm._v("Your Segments")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The various sources of data you've connected to Avid AI all can be segmented for marketing or analysis purposes. If there is a segment not yet defined, you can build it by clicking on the Add Segment button."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary me-1 dropdown-toggle",
        attrs: {
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("img", {
          staticClass: "icon pe-1",
          attrs: { src: "/img/icons/dialexa-icons/sort.svg" },
        }),
        _vm._v("Filter\n                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }