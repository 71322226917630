<template>
    <div class="view-report benchmark-report year-end-page p-2 mb-4" id="year-end-page">
        <div class="row mx-0"> 
            <div class="p-0 w-100">
                <div class="row ">
                    <div class="col-xl-5 col-12 mb-3 year-end-benchmark-title-image">
                        <div class="position-relative w-100 h-100">
                            <div class="year-end-circle-1">
                                <div class="position-relative">
                                    <img src="/img/images/christmas-ornaments-top.png">
                                    <span v-if="page == null" >
                                        <span class="d-inline-block spinner-border m-0" role="status"></span>
                                        <br>Loading
                                    </span>
                                    <span v-else>{{meta_data.revenue | currency_abbr}} <br/> Revenue</span>
                                </div>
                            </div>

                            <div class="year-end-circle-3">
                                <div class="position-relative">
                                    <img src="/img/images/christmas-ornaments-top.png">
                                    <span v-if="page == null" >
                                        <span class="d-inline-block spinner-border m-0" role="status"></span>
                                        <br>Loading
                                    </span>
                                    <span v-else>{{meta_data.org | number}} <br/> Orgs</span>                                    
                                </div>

                            </div>

                            <div class="year-end-circle-2">
                                <div class="position-relative">
                                    <img src="/img/images/christmas-ornaments-top.png">
                                    <span v-if="page == null" >
                                        <span class="d-inline-block spinner-border m-0" role="status"></span>
                                        <br>Loading
                                    </span>
                                    <span v-else>{{meta_data.donors | num_abbr}} <br/> Donors</span>                                    
                                </div>
                            </div>                            
                        </div>
                    </div> 

                    <div class="col-xl-7 col-12 mb-3 ">
                        <Snowf 
                            :amount="50"
                            :size="5"
                            :speed="1.5"
                            :wind="0"
                            :opacity="0.8"
                            :swing="1"
                            :image="null"
                            :zIndex="null"
                            :resize="true"
                            color="#E5F3FE"
                            />
                        <h2 class="mx-3">Welcome to Avid's Year End Benchmarks
                            <!-- <a :href="'/export/'+page.client.url+'/' + page.url + '/' + local_page.sections[active_tab_index].id + params" target="_blank" class="btn btn-primary float-end mb-2" id="export-button" >
                                <img class="icon pe-0" src="/img/icons/dialexa-icons/presention-chart.svg">
                                <span class="ps-1">Generate Presentation</span>
                            </a> -->
                        </h2>
                        <div  class="row mb-5 mt-3 mx-3">
                            <div class="col-12">                            
                                <div class="card year-end-text-card mt-3 h-100">
                                    <img class="holly-background-left" src="/img/images/holly-top-corner.png">
                                    <img class="holly-background-right" src="/img/images/holly-bottom-corner.png">

                                    <div class="card-body m-2 mb-0">
                                        <div class="row">
                                            <div class="col" v-if="page == null || local_page.crm_status == 'connected' || local_page.crm_status == 'loading'">
                                                <p class="top-card-text mx-3 my-2">
                                                    Avid’s Year End Benchmarks are designed to keep up with the fast pace of year end giving. As you run your year end campaigns, from Giving Tuesday through December 31, check back often to see how your performance stacks up against similar nonprofits. These live benchmarks help you pinpoint where you excel and where to adjust your strategy to maximize your impact during this critical fundraising season.</p>
                                                <div v-if="local_page != null && local_page.crm_status == 'loading'">
                                                    <div class="alert alert-warning mt-2 mb-0" role="alert">
                                                        <div class="spinner-border spinner-border-sm float-left" role="status"> <span class="visually-hidden">Loading...</span></div>
                                                        Your data is being processed, we'll notify you when your benchmarks have been customized.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col" v-else>
                                                <div class="w-100">
                                                    <p class="top-card-text">Avid’s Year End benchmarks are designed to keep up with the fast pace of year end giving. As you run your year end campaigns, from Giving Tuesday through December 31, check back often to see how your performance stacks up against similar nonprofits. These live benchmarks help you pinpoint where you excel and where to adjust your strategy to maximize your impact during this critical fundraising season. </p>
                                                </div>
                                                <div class="w-100 text-end">
                                                    <a class="btn btn-primary" :href="'/settings/'+client.url+'/integrations'">Customize your Benchmarks&mdash;Connect your CRM</a>
                                                </div>
                                                <div class="w-100 text-end">
                                                    <small>* Without a CRM connection, your benchmark access ends in 60 days</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3" v-if="page == null">
                    <div class="col-12">
                        <div class="navbar navbar-expand bg-secondary rounded p-3">
                            <h5 class="mb-0 text-primary"><span class="d-inline-block spinner-border spinner-border-sm me-2 " role="status"></span>
                            Your year end report is loading...</h5>
                        </div>
                    </div>
                </div>

                <div class="row mb-3" v-else>
                    <div class="col-12">
                        <benchmark-filter-panel :page="page" :client="client" :section_id="section_id" @refreshing="refreshing" @updatePage="updatePage"></benchmark-filter-panel>
                    </div>
                </div>

                <div class="row mt-3 px-2" id="top-of-page" v-if="page != null">
                    <div class="card box-content">
                        <div class="card-body">
                            <nav id="report-section-nav" class="navbar navbar-expand secondary-tab-bar p-0 mb-2">
                                <div class="container-fluid px-0 mb-3">
                                    <div class="collapse navbar-collapse" id="filter-navbar">
                                    <ul class="navbar-nav mb-2 mb-lg-0">
                                        <li class="nav-item" v-for="(section, index) in local_page.sections" v-if="index!=0">
                                            <a  class="nav-link d-flex flex-nowrap secondary-tab" :class="{'secondary-tab-active': section.is_section_tab_active}" 
                                            href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(index)" data-bs-toggle="tooltip" :title="section.description">
                                                <h6>{{ section.title }}</h6>
                                                <!--<p>Optional description</p>-->
                                            </a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </nav>

                            <div v-for="(section, index) in local_page.sections" :class="(section.is_section_tab_active ? '' : 'd-none')">
                                <report-view-section v-if="index!=0" :page="local_page" :client="client" :connections="connections" :page_section="section" :is_filter_panel_expanded="false"
                                :if_refreshing="if_refreshing" :applied_chart_filter="applied_chart_filter" :if_benchmark_report="true" :section_index="index" @goToNextSection="goToNextSection"></report-view-section> 
                            </div>                              
                        </div>
                    </div>
                </div>

                <div class="row mt-3 px-2" v-if="page != null">
                    <div class="col-12">
                        <p>We are always looking for ways to make this benchmark better. In the coming months, we'll be rolling out benchmarks for more areas of fundraising like Email and Paid Social Media. 
                            <a href="#" data-bs-toggle="modal" :data-bs-target="'#support-modal-'+selected_ticket_type" @click="openSupportModal()">If you have suggestions or questions, we would love to hear about it.</a>
                        </p>
                    </div>
                </div>

            </div>
        </div>
        <support-modal :user="user" :client="client" :selected_ticket_type="selected_ticket_type" :id="'support-modal-'+selected_ticket_type"></support-modal>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import Snowf from 'vue-snowf';

    export default {
        components: {
            Swal, Snowf
        },
        props: ['user', 'client', 'connections', 'section_id'],
        data() {
            return {
                page: null,
                local_page: {},
                meta_data: {
                    revenue: '',
                    org: '',
                    donors: ''
                },
                active_tab_index: 1,
                // filter_count: 0,
                if_refreshing: false,
                applied_chart_filter: {},
                params: "",

                selected_ticket_type: ''
            };
        },
        beforeMount() {

            this.loadBenchmark();

        },
        computed: {
            date_type: function() {
                if(this.page.data_blend_type == 'constituent')
                    return "Transaction ";
                else if(this.page.data_blend_type == 'subscriber')
                    return "Email ";
                return "";
            },
            applied_filters: function() {
                let applied_filters_list = [];


                for (var i = 0; i <this.local_page.dimensions.length; i++){
                    let dim = this.local_page.dimensions[i];

                    //Todo: consolidate the default_value and value checks
                    if( (
                        //If it has a default value
                        dim.default_value && (dim.default_value.length  ||
                        //Or is a date/month range with some values set
                        ((dim.field_type == 'date_range'|| dim.field_type == 'month_range') && (dim.default_value.end_date != null || dim.default_value.start_date != null))))
                        //Or it has a value
                        || (dim.value && (dim.value.length ||
                            //Or is a date/month range with some values set
                            ((dim.field_type == 'date_range'|| dim.field_type == 'month_range') && (dim.value.end_date != null || dim.value.start_date != null)) 
                        ))){
                            if ((dim.default_value && (dim.default_value.length != 1 || dim.default_value[0] != 'Donation')) || 
                            (dim.value && (dim.value.length != 1 || dim.value[0] != 'Donation'))){ // hide is_donation 
                                applied_filters_list.push({
                                    filter_id: dim.id,
                                    filter_label: dim.label,
                                    filter_value: (dim.value != null ? dim.value : dim.default_value),
                                    //If it is year_type, that needs to come from the client, not the dim
                                    filter_default_value: ((typeof dim.column === 'string' || dim.column instanceof String) && dim.column.indexOf('time_period.year_type') !== -1? this.page.client.default_reporting_period : dim.default_value),
                                    filter_column_name: dim.column,
                                    filter_type: dim.field_type,
                                    is_required: dim.is_required
                                })                                    
                            }
                        }
                    }
                return applied_filters_list;
            },
        },
        watch:{
            section_id: {
                handler: function(newVal, oldVal) {
                    if (this.section_id>0){
                        var index = this.local_page.sections.findIndex(section => section.id == this.section_id);
                        if (index >= 0){
                            this.setActiveTab(index);  
                        }else {
                            this.setActiveTab(0);  
                        }
                    }
                },
                // deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
        },
        methods: {

            loadBenchmark() {

                let url = '/api/benchmarks/' + this.client.url + "/4601/charts";
                if (this.section_id>0)
                    url += '/' + this.section_id;

                window.axios.get(url)
                  .then(response => {
                    //I need to save the section for the takeaways
                    this.page = response.data.page;
                    this.local_page = JSON.parse(JSON.stringify(this.page));
                    this.setDefaultFilterValues();
                    this.reloadBenchmarkView();
                    this.loadSectionTab();
                    if (this.section_id>0){
                        var index = this.local_page.sections.findIndex(section => section.id == this.section_id);
                        if (index >= 0){
                            this.setActiveTab(index);
                        }else {
                            this.setActiveTab(1);
                        }
                    }

                    this.$forceUpdate();
                });
            },
            reloadBenchmarkView(){
                this.meta_data.revenue = Object.values(this.local_page.sections[0].charts[2].chart.data)[0][0];
                this.meta_data.org = Object.values(this.local_page.sections[0].charts[0].chart.data)[0][0];
                this.meta_data.donors = Object.values(this.local_page.sections[0].charts[1].chart.data)[0][0];
                                
                this.checkGivingLevelSection();
            },
            dateRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.date(value.start_date) + ' - ' + this.$options.filters.date(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it
                    return 'On or After ' + this.$options.filters.date(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.date(value.end_date);
                }
            },
            monthRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.month(value.start_date) + ' - ' + this.$options.filters.month(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it

                    return 'On or After ' + this.$options.filters.month(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.month(value.end_date);
                }
            },
            setDefaultFilterValues() {
                for (var i = 0; i <this.local_page.dimensions.length; i++){
                    let dim = this.local_page.dimensions[i];
                        if(dim.field_type == "multi_select")
                            this.local_page.dimensions[i].value =  [];
                        else if(dim.field_type == "single_select")
                            this.local_page.dimensions[i].value = "";
                }
            },
            checkGivingLevelSection(){
                if(this.client.giving_levels && this.client.giving_levels.split && this.client.giving_levels.split != ''){
                    if (this.client.giving_levels.split == 'HPC'){
                        this.local_page.sections = this.local_page.sections.filter(section => !section.title.includes('HAC'));
                    } else { // HAC
                        this.local_page.sections = this.local_page.sections.filter(section => !section.title.includes('HPC'));
                    }    
                    var giving_level_section_index = this.local_page.sections.findIndex(section => section.title.includes('(HAC)') || section.title.includes('(HPC)'));
                    if(giving_level_section_index && this.local_page.sections[giving_level_section_index]){
                        this.local_page.sections[giving_level_section_index].title = this.local_page.sections[giving_level_section_index].title.replace('(HAC)', '').replace('(HPC)', '');                
                    } 
                }
                this.$forceUpdate()
            },
            loadSectionTab(){
                for(var i= 0; i<this.local_page.sections.length; i++){
                    if(i == 1){
                        this.local_page.sections[i].is_section_tab_active = true;
                    } else {
                        this.local_page.sections[i].is_section_tab_active = false;
                    }
                }
            },
            setActiveTab(index){
                for(var i=0; i<this.local_page.sections.length; i++){
                    if(i == index){
                        this.local_page.sections[i].is_section_tab_active = true;
                    } else {
                        this.local_page.sections[i].is_section_tab_active = false;
                    }
                }
                this.active_tab_index = index;

                var newURL = '/year_end_benchmarks/'+this.client.url + '/'+this.local_page.sections[index].id;
                window.history.replaceState({}, {}, newURL);

                [...document.querySelectorAll('.chart-template-tooltip')].forEach(t => t.remove()); // remove tooltips from the page before switching tabs. fixes a bug where other tab tooltips could show on another tab.
                
                // //Get the current query parameters
                // this.params = "";
                // if(window.location.search != null)
                //     this.params = window.location.search;

                // var newURL = '/benchmarks/'+this.client.url +'/'+this.local_page.sections[index].id + this.params;
                // window.history.replaceState({}, {}, newURL);

                // //Also append the params to the export buttons
                // let export_btn = document.getElementById('export-button');
                // if(export_btn)
                //     export_btn.href = '/export/'+this.client.url +'/' + this.page.url+'/'+this.local_page.sections[index].id + this.params;

                // //Save what section they were looking at
                // var data = {
                //   client_id: this.client.id,
                //   report_page_id: this.local_page.id,
                //   report_page_section_id: this.local_page.sections[this.active_tab_index].id
                // };
                // window.axios.post('/api/insights/history', data);
       
                this.$forceUpdate();
            },
            refreshing(if_refreshing){
                this.if_refreshing = if_refreshing;

                if(if_refreshing){
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Refreshing...</h4>', 
                        target: '#year-end-page',
                        icon: 'warning',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        iconColor: 'white',
                        customClass: {
                            popup: 'bg-warning'
                        },
                    });
                }
            },
            updatePage(new_page){
                this.local_page = {...new_page};
                this.reloadBenchmarkView();


                this.local_page.id = this.page.id;  
                // this.getFilterCount();
                // this.loadSectionTab();
                this.setActiveTab(this.active_tab_index);

                //Remove any existing swal toasts
                Swal.close();

                Swal.fire({
                  html: '<h4 class="mb-0 text-white">Data Refreshed!</h4>', 
                  target: '#view-report-page',
                  icon: 'success',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 3000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
                this.$forceUpdate();
            },
            goToNextSection(){
                var new_index = this.active_tab_index + 1;
                this.setActiveTab(new_index);
                //Animate scrolling to top-of-page
                document.getElementById('top-of-page').scrollIntoView({behavior: 'smooth'});
            },
            openSupportModal(){
                this.selected_ticket_type='FEATURE_REQUEST';
                // var modal = new bootstrap.Modal(document.getElementById('support-modal-'+this.selected_ticket_type));
                // modal.show();
            }
        }
    }
</script>