var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card box-content banner mx-3 mb-3" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h6", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.text))]),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-row gap-1" }, [
      _c("button", { staticClass: "btn btn-secondary" }, [_vm._v("Dismiss")]),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn-primary" }, [_vm._v("Upgrade")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }