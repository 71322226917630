var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "view-report p-2 mb-4 export-report",
      attrs: { id: "view-report-page" },
    },
    [
      _c("div", { staticClass: "row mx-0" }, [
        _c("div", { staticClass: "p-0 w-100" }, [
          _c("div", { staticClass: "row mt-3 px-2" }, [
            _c("div", { staticClass: "card box-content" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("report-view-section", {
                    attrs: {
                      page: _vm.local_page,
                      client: _vm.client,
                      connections: _vm.connections,
                      page_section: _vm.section,
                      is_filter_panel_expanded: false,
                      if_refreshing: false,
                      applied_chart_filter: false,
                      section_index: _vm.section_index,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.svgs, function (svg) {
        return _c("div", { staticClass: "row" }, [
          _c("div", {
            staticClass: "col svg-container",
            attrs: { id: "svg-" + svg.id, "data-chart-id": svg.id },
            domProps: { innerHTML: _vm._s(svg.svg) },
          }),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }