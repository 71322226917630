<template>
    <div class="view-report-benchmark-filter-panel sticky-filter-panel" ref="stickyDiv">
        <nav class="navbar navbar-expand bg-secondary rounded">
            <div class="container-fluid row">
                <span class="navbar-brand mb-0 ms-1 h4 col-1">Filters:</span>

                <div class="collapse navbar-collapse col-11" id="benchmark-filter-navbar">
                    <ul class="navbar-nav row">
                        <li class="nav-item dropdown col-4" v-for="(dimension, index) in local_page.dimensions">
                            <a class="btn btn-secondary nav-link dropdown-toggle px-3 w-100" href="#" role="button" data-bs-toggle="dropdown" :data-bs-auto-close='dimension.column =="period_type"? "true" : "outside"' aria-expanded="false"
                            :disabled="dimension.column=='org_vertical' && if_revenue_filter_applied || dimension.column=='org_annual_revenue' && if_vertical_filter_applied">
                                {{ dimension.column =="period_type" ?   dimension.label + ': ' + (dimension.value == undefined? 'Trailing 12 Months' : dimension.value) : dimension.label }}

                                <i class="ms-2 fas fa-info-circle fas-fade" v-if="dimension.column=='org_vertical' && if_revenue_filter_applied" data-bs-toggle="tooltip" title='Annual Revenue Range filter applied, Click on the "Reset Filters" link to reset all filters and enable the Vertical filter'></i>
                                <i class="ms-2 fas fa-info-circle fas-fade" v-if="dimension.column=='org_annual_revenue' && if_vertical_filter_applied" data-bs-toggle="tooltip" title='Vertical filter applied, Click on the "Reset Filters" link to reset all filters and enable the Annual Revenue Range filter'></i>
                            </a>
                            <div class="dropdown-menu" v-if="if_filter_options_loaded && dimension.field_type == 'multi_select'">
                                <label class="dropdown-item" v-for="option in dimension.options" :key="option"  @mouseover="setHover(option, true)" @mouseleave="setHover(option, false)">
                                    <input class="form-check-input" type="checkbox" :value="option" v-model="dimension.value" @change="applyFilter(index, option)">
                                    <span class="ms-2" >
                                        {{ option }} ({{ benchmark_filters[dimension.id].counts[option] }}) 
                                        <i v-if="dimension.column=='org_vertical' && client.vertical == option || dimension.column=='org_annual_revenue' && client.size == option" class="ms-1 fa-solid fa-star"></i>
                                    </span> 
                                    <button class="btn btn-sm btn-secondary float-end only-selection-btn py-0"  v-show="show_only_button[option] && benchmark_filters[dimension.id].counts[option] >= 6" @click.prevent="toggleOnly(index, option)">ONLY</button>
                                </label>
                                <div class="px-2 mt-2 d-inline-block" v-if="dimension.column=='org_vertical' && client.vertical && dimension.org_count >= 6">
                                    <div class="dropdown-divider"></div>
                                    <small>Note: Include your vertical (marked with star) to see yourself in the benchmark comparison.</small>
                                </div>
                                <div class="px-2 mt-2 d-inline-block" v-if="dimension.column=='org_annual_revenue' && client.size && dimension.org_count >= 6">
                                    <div class="dropdown-divider"></div>
                                    <small>Note: Include your annual revenue (marked with star) to see yourself in the benchmark comparison.</small>
                                </div>                                
                                <div class="text-danger invalid-feedback px-2 mt-2 d-inline-block" v-if="dimension.org_count < 6">
                                    <div class="dropdown-divider"></div>
                                    Your selection would match less than 6 organizations. <b>For privacy reasons, please select more options.</b>
                                </div>
                            </div>

                            <div class="dropdown-menu" v-if="if_filter_options_loaded && dimension.field_type == 'single_select'">
                                <label class="dropdown-item" v-for="option in dimension.options" :key="option" @click="applyFilter(index, option)">
                                    <i class="fa fa-circle-check text-primary ms-2" v-if="dimension.value == option"></i> 
                                    <i class="fa ms-4" v-else></i> 
                                    <span class="ms-2" >
                                        {{ option}}
                                    </span>
                                </label>
                            </div>

                            <div class="dropdown-menu" v-if="!if_filter_options_loaded">
                                <label class="ms-2">
                                    <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading ...
                                </label>
                            </div>
                        </li>

                        <a href="#" class="mt-2 text-end w-25" @click.prevent="resetFilterStatus()" v-if=" if_revenue_filter_applied || if_vertical_filter_applied">Reset Filters</a>
                    </ul>
                </div>          
            </div>
        </nav>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import debounce from 'lodash.debounce';

    export default {
        components: {
            Swal
        },
        props: ['page', 'client', 'section_id'],
        data() {
            return {
                local_page: {},
                benchmark_filters: {},
                if_filter_options_loaded: false,
                if_vertical_filter_applied: false,
                if_revenue_filter_applied: false,
                if_refreshing: false,
                show_only_button: {},
            };
        },
        beforeMount() { 
            this.local_page = {...this.page}
        },
        mounted() {
            this.loadFilterOptions();
        },
        watch:{},
        computed: {},
        methods: {
            loadFilterOptions() {
                var data = {
                    page: this.local_page,
                    filters: this.local_page.dimensions,
                };

                window.axios.post('/api/benchmarks/filters', data)
                    .then(response => {
                        this.if_filter_options_loaded = true;

                        response.data.filters.forEach(filter => {
                            this.benchmark_filters[filter.id] = filter;
                        });

                        for (var i=0; i< this.local_page.dimensions.length; i++){
                            for(var j = 0; j < response.data.filters.length; j++){
                                if (this.local_page.dimensions[i].id == response.data.filters[j].id){
                                    this.local_page.dimensions[i].options = response.data.filters[j].options;
                                    response.data.filters[j].options.forEach((option)=>{
                                        this.show_only_button[option] = false
                                    })
                                    this.local_page.dimensions[i].disabled = false;
                                    this.local_page.dimensions[i].org_count = 0;
                                }
                            }
                        }
                        this.loadInitialFilterStatus();

                        // this.$forceUpdate();
                });
            },
            setHover(item, flag){
                this.show_only_button[item] = flag
                this.$forceUpdate();
            },
            resetFilterStatus(){
                this.loadInitialFilterStatus();
                this.applyFilter();
            },
            sortRevenueRangeOptions(){
                const revenue_range = ["Less than $1M", "$1M-$5M", "$5M-$10M","$10M-$25M","$25M-$50M","$50M+"];
                var index = this.local_page.dimensions.findIndex((dimension) => dimension.column == "org_annual_revenue");
                this.local_page.dimensions[index].options.sort((a, b) => revenue_range.indexOf(a) > revenue_range.indexOf(b) ? 1 : -1);
            },
            loadInitialFilterStatus(){
                this.sortRevenueRangeOptions();

                var vertical_index = this.local_page.dimensions.findIndex((dimension) => dimension.column == "org_vertical");
                this.local_page.dimensions[vertical_index].value = this.local_page.dimensions[vertical_index].options;
                var org_count_vertical = 0;
                this.local_page.dimensions[vertical_index].value.forEach((option) =>{
                    org_count_vertical += this.benchmark_filters[this.local_page.dimensions[vertical_index].id].counts[option];
                });
                this.local_page.dimensions[vertical_index].org_count = org_count_vertical;

                var annual_revenue_index = this.local_page.dimensions.findIndex((dimension) => dimension.column == "org_annual_revenue");
                this.local_page.dimensions[annual_revenue_index].value = this.local_page.dimensions[annual_revenue_index].options;
                var org_count_annual_revenue = 0;
                this.local_page.dimensions[annual_revenue_index].value.forEach((option) =>{
                    org_count_annual_revenue += this.benchmark_filters[this.local_page.dimensions[annual_revenue_index].id].counts[option];
                });
                this.local_page.dimensions[annual_revenue_index].org_count = org_count_annual_revenue;

                if(this.local_page.name != 'Year End Benchmark'){ // regular benchmarks have one more filter
                    var period_type_index = this.local_page.dimensions.findIndex((dimension) => dimension.column == "period_type");
                    this.local_page.dimensions[period_type_index].value = "Trailing 12 Months";
                }

                this.if_vertical_filter_applied = false;
                this.if_revenue_filter_applied = false;

                this.$forceUpdate();
            },
            toggleOnly(index = null, option = null) {
                this.local_page.dimensions[index].value = [option];
                this.local_page.dimensions[index].org_count = this.benchmark_filters[this.local_page.dimensions[index].id].counts[option];
                if (index == 0){ // vertical
                    this.if_vertical_filter_applied = true;
                } else if (index == 1){  // revenue range
                    this.if_revenue_filter_applied = true;
                }
                this.refresh();
                this.$forceUpdate();
            },
            applyFilter(index=null, option=null){
                if (index != null) {
                    if (index == 0){ // vertical
                        this.if_vertical_filter_applied = (this.local_page.dimensions[index].value.length != this.local_page.dimensions[index].options.length);
                        var org_count_vertical = 0;
                        this.local_page.dimensions[index].value.forEach((option) =>{
                            org_count_vertical += this.benchmark_filters[this.local_page.dimensions[index].id].counts[option];
                        });
                        this.local_page.dimensions[index].org_count = org_count_vertical;

                        if (org_count_vertical >= 6){
                            this.applyFilterCall();
                        }
                    } else if (index == 1){  // revenue range
                        this.if_revenue_filter_applied = (this.local_page.dimensions[index].value.length != this.local_page.dimensions[index].options.length);
                        var org_count_annual_revenue = 0;
                        this.local_page.dimensions[index].value.forEach((option) =>{
                            org_count_annual_revenue += this.benchmark_filters[this.local_page.dimensions[index].id].counts[option];
                        });
                        this.local_page.dimensions[index].org_count = org_count_annual_revenue;

                        if (org_count_annual_revenue >= 6){
                            this.applyFilterCall();
                        }
                    } else { //period type
                        this.local_page.dimensions[index].value = option;
                        this.refresh();
                    }
                } else {
                    //Reset link
                    this.refresh();
                }
                this.$forceUpdate();
            },    
            applyFilterCall: debounce(function () { 
                this.refresh();
            }, 1000),
            refresh() {
                var self = this;
                this.if_refreshing = true;
                this.$emit('refreshing', this.if_refreshing);
                // var local_page_temp = {...this.local_page};

                if (this.if_vertical_filter_applied && this.local_page.dimensions[0].org_count < 6 ||this.if_revenue_filter_applied && this.local_page.dimensions[1].org_count < 6)
                    Swal.fire({
                        title: "Your filter is too specific",
                        text: "Your current filter selection would match fewer than 6 organizations, which isn't allowed for privacy reasons. Please broaden your filter to see results.",
                        icon: "warning",
                        iconColor: "#D11F1F",
                        confirmButtonColor: "#D11F1F",
                        showCancelButton: false,
                    });
                else
                window.axios.post('/api/insights/page/' + this.page.id, this.local_page)
                    .then(response => {
                        var page_temp = self.local_page;
                        self.local_page = response.data.page;
                        self.local_page.dimensions = page_temp.dimensions
                        self.local_page.id = page_temp.id
                        self.local_page.crm_status = page_temp.crm_status
                        self.if_refreshing = false;

                        self.$emit('refreshing', self.if_refreshing);
                        self.$emit('updatePage', self.local_page);
                    }).catch(error=> {

                });
            }
        }
    }
</script>
