var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "org_settings_notification" } },
    [
      _c("h4", [
        _vm._v("Notification Preferences for " + _vm._s(_vm.client.name)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "Decide which alerts you would like to receive email notifications for."
        ),
      ]),
      _vm._v(" "),
      _vm.user_notification.length == 0 ? _c("div", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.user_notification, function (n) {
        return _c("div", { staticClass: "row mb-4" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              {
                staticClass:
                  "form-check form-switch d-inline-flex align-items-center gap-2 my-0",
              },
              [
                _c("input", {
                  staticClass: "form-check-input mt-0 me-2",
                  attrs: { type: "checkbox", role: "switch" },
                  domProps: { checked: n.active },
                  on: {
                    change: function ($event) {
                      n.active = !n.active
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", [
                  _c("h6", { staticClass: "mb-0" }, [_vm._v(_vm._s(n.name))]),
                  _vm._v(" "),
                  _c("small", [_vm._v(_vm._s(n.description))]),
                ]),
              ]
            ),
          ]),
        ])
      }),
      _vm._v(" "),
      _vm.user_notification.length != 0
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary mt-3",
              attrs: { type: "submit", disabled: _vm.form.busy },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-save" }),
              _vm._v(" Save Changes\n    "),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "d-flex align-items-center" }, [
      _c(
        "div",
        {
          staticClass: "spinner-border text-primary me-2",
          attrs: { role: "status" },
        },
        [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
      ),
      _vm._v("\n            Loading Notification Options\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }