<template>
    <div id="pathways-push-segment">
        <div id="choose" :class="stage > 0?  'hidden': ''">
            <div id="choose-segment" class="card">
                <div id="choose-segment__description">
                    <h5>Select Audience</h5>
                    <p>Select audience to push to a destination.</p>
                </div>

                <div id="choose-segment__filter-row" class="flex-row flex-nowrap gap-2 align-items-stretch">
                    <div class="search-box flex-grow-1 position-relative">
                        <input type="text" class="form-control" placeholder="Search by Audience Name..." aria-label="Search by Audience Name..." v-model="search_key" @input="handleFilterSelection()">
                        <!-- <button class="btn btn-none" type="button" :class="search_key.trim()=='' ? 'hidden': ''" @click="clearSearchKey()">X</button> -->
                        <i class="fa fa-search"></i>
                    </div>
                    <button class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                        <img class="icon pe-1" src="/img/icons/dialexa-icons/sort.svg">Filter
                    </button>
                    <div class="dropdown-menu">
                        <p>Source:</p>
                        <label class="dropdown-item" v-for="item in source_list" :key="'source-'+item">
                            <input class="form-check-input" type="checkbox" :value="item"  @change="handleFilterSelection()" v-model="filters.source" :true-value="[]">
                            <span class="ms-2" >{{ item }}</span>
                        </label>
                        <hr>
                        <p>Audience Type:</p>
                        <label class="dropdown-item" v-for="item in dest_list" :key="'dest-'+item">
                            <input class="form-check-input" type="checkbox" :value="item"  @change="handleFilterSelection()"  v-model="filters.destination" :true-value="[]">
                            <span class="ms-2" >{{ item }}</span>
                        </label>
                    </div>

                    <a class="btn btn-primary"  :href="'/audience/' + client.url +'/new'">
                        <img class="icon pe-0" src="/img/icons/dialexa-icons/plus.svg">
                    </a>
                </div>

                <div id="choose-segment__segments-list" class="d-flex flex-column gap-3">
                    <div v-if="segment_list_shown == null || segment_list_shown.length == 0"  class="card long-card disabled">
                        <div class="card-body long-card__body row">
                            <div class="long-card__content">
                                <h5 class="mb-1">No Segments Created</h5>
                                <p>To push a segment, you first need to create one using existing data sets or data blends. 
                                    <a :href="'/audience/' + client.url +'/new'">Click here to do so.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-else v-for="(segment, seg_index) in segment_list_shown" class="card long-card" :class="segment.id===segment_selected.id? 'long-card-selected':''"
                    @click="changeSegmentSelection(segment)">
                        
                        <div class="card-body long-card__body d-flex align-items-center" >
                            <div class="long-card__radio d-flex">
                                <input class="form-radio-input" type="radio" :id="segment.id" :value="segment.id" :checked="segment.id===segment_selected.id" >
                            </div>
                            
                            <div class="long-card__content d-flex flex-row justfy-content-start gap-2">
                                <!-- <div class="d-flex flex-column gap-1">
                                    <img :src="" :alt="" class="avatar rounded-circle avatar-sm bg-white">
                                </div> -->
                                <div class="d-flex flex-column flex-grow-1 gap-1">
                                    <div class="d-block">
                                        <div class="d-flex flex-row align-items-center position-relative">
                                            <h5>{{ segment.name }}</h5>
                                            <p class="contact-count" v-if="segment.sizes">{{ getDefaultSize(segment.sizes) | number_with_zero }} Contacts <i class="fas fa-info-circle text-primary ms-2 mb-1" data-bs-toggle="tooltip" title="The actual number of records synced to the destination will vary based upon the primary key selected when setting up the sync."></i>
                                                <small v-if="segment.dataset">
                                                    <span class="badge">{{ segment.dataset.name}}</span>
                                                </small>
                                                <small v-else-if="segment.datablend">
                                                    <span class="badge">{{ segment.datablend.name}}</span>
                                                </small>
                                                <small v-else-if="segment.data_source">
                                                    <span class="badge">{{ segment.data_source.name}}</span>
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <p>{{ segment.description }}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="table-pagination">
                        <div class="table-pagination--group">
                            <span class="d-none d-lg-inline">Showing: </span>
                            <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                <option value="5000">ALL</option>
                                <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                            </select>
                            <span>Audiences</span>
                        </div>

                        <div class="table-pagination--group text-center">
                            {{ item_interval }} of {{ filtered_segment_list.length }}
                        </div>

                        <div class="table-pagination--group">
                            <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                            </button>   

                            <div v-if="page_index_array.length<=3">
                                <button  v-for="page in page_index_array" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                {{page}}
                                </button>  
                            </div>
                            <div v-else>
                                <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                {{page}}
                                </button>  

                                <button v-if="page_index > 2" class="btn btn-none px-1">
                                ...
                                </button>  
                                <button v-if="page_index >= 2 && page_index< page_index_array.length -1" class="btn btn-none px-1 current-page">
                                    {{page_index + 1 }} 
                                </button>  

                                <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1">
                                ...
                                </button>  
                                <button  class="btn btn-none px-1" :class="page_index ==page_index_array.length -1 ? 'current-page':''" @click="moveToPage(page_index_array.length)">
                                {{ page_index_array.length }}
                                </button>  
                            </div>

                            <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == page_count -1 ">
                                <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                            </button>   
                        </div>
                    </div>
                </div>

            </div>

            <div id="choose-pathway" class="card">
                <div id="choose-pathway__description">
                    <h5>Choose a Destination</h5>
                    <p v-if="segment_selected.id==undefined"><img class="icon pe-2" src="/img/icons/dialexa-icons/warning-circle.svg">Select your audience before you chose the pathway</p>
                    <p v-else>Now you can select a pathway</p>
                </div>

                <div id="choose-pathway__pathways-list" class="flex-grow-1 position-relative">
                    <div class="position-absolute top-0 start-0 end-0 bottom-0">
                        <div class="position-relative h-100">
                            <div v-if="connection_datasets.length == 0"  class="card long-card disabled">
                                <div class="card-body long-card__body row ">
                                    <div class="long-card__content">
                                        <h5 class="mb-1">No Pathway Integrations Set Up</h5>
                                        <p>Before you can push data, <a :href="'/settings/'+client.url+'/integrations'">you first need to connect integrations that have valid Pathways</a>. <em>(If you have integrations but they are not showing, they are not available for Pathways yet.)</em></p>
                                    </div>
                                </div>
                            </div>
                            <div class="scroll-view d-flex flex-column gap-3 flex-shrink-1" style="max-height: 100%;" :class="connection_datasets.length >= 7 ? 'pe-2' : ''">

                                <div  v-for="(conn, conn_index) in connection_datasets" class="card long-card" :class="getPathwayCardClass(conn)" @click="changePathwaySelection(conn)">
                                    <div class="card-body long-card__body d-flex align-items-center">
                                        <div class="long-card__radio d-flex">
                                            <input class="form-radio-input" type="radio" :id="conn.id" :value="conn.id" :checked="conn.id===pathway_selected.id" 
                                        :disabled="segment_selected.id == undefined || isAlreadySynced(conn)" >
                                        </div>
                                        
                                        <div class="long-card__content d-flex flex-row justfy-content-start gap-2 align-items-center">
                                            <div class="d-flex flex-column gap-1">
                                                <img :src="conn.integration.logo_url" :alt="conn.integration.name" class="avatar rounded-circle avatar-sm bg-white">
                                            </div>
                                            <div class="d-flex flex-column gap-1">
                                                <h5>{{conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38' ? 'File Export' : conn.name}}</h5>
                                                <em class="text-muted" v-if="isAlreadySynced(conn)">(Already Synced)</em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="summary" :class="stage > 0 ?  '' : 'hidden'">
            <div>
                <button class="btn btn-text back-button" @click.prevent="backToSelection()">
                    <img class="icon pe-1" src="/img/icons/dialexa-icons/chevrons-left.svg">
                    Back
                </button>   
            </div>

            <pathways-push-segment-summary v-if="stage != 0" :segment_selected="segment_selected" :pathway_selected="pathway_selected" :client="client" ref="pathway_summary"
            :census_connection="local_census_connection" @updateCensusConnection="updateCensusConnection" :information_errors="information_errors"></pathways-push-segment-summary>
        </div>

        <div class="sticky-footer">
            <nav class="navbar navbar-expand py-0 d-flex justify-content-between">
                <div id="cost-summary-widget">
                    <div class="d-flex flex-row" v-if="showCost()">
                        <div class="cost-summary-widget__icon-wrapper d-flex flex-column justify-content-center align-items-center circle-icon bg-blend">
                            <img class="cost-summary-widget__icon" src="/img/icons/dialexa-icons/money-send.svg"> 
                        </div>
                        <div class="cost-summary-widget__content d-flex flex-column gap-0 ms-3">
                            <p class="m-0"><small>Additional Cost:</small> <i class="fas fa-info-circle ms-1" v-if="costText != 'Free'"
                                 data-bs-toggle="tooltip" title="Pathways are billed monthly based on unique destinations. After syncing the first audience, you can push unlimited audiences to each destination at no additional cost."></i> </p>
                            <h5 class="m-0" v-html="costText"></h5>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row gap-3">
                    <button class="btn btn-secondary" @click.prevent="cancelSelection()" v-if="segment_selected.id != undefined">
                        Cancel
                    </button>
                    <button class="btn btn-primary" v-if="stage == 0" :disabled="segment_selected.id == undefined || pathway_selected.id==undefined" @click.prevent="proceedToSummary()">
                        Review Pathway
                    </button>
                    <button class="btn btn-primary" v-if="stage == 1" :disabled="true">
                        <div class="spinner-border spinner-border-sm me-2" role="status"><span class="visually-hidden"></span></div> Validating
                    </button>
                    <button class="btn btn-primary" v-if="stage == 2" :disabled="true">
                        Needs Attention
                    </button>
                    <button class="btn btn-primary" v-if="stage == 3 " @click.prevent="saveAndPush()" :disabled="busy" >
                        <span v-if="!busy">Confirm</span>
                        <span v-else><span class="spinner-border spinner-border-sm me-2" role="status"><span class="visually-hidden"></span></span> Processing</span>

                    </button>
                </div>
            </nav>
        </div>
        
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },
        props: ['connection_datasets', 'client', 'segments','datasets', 'datablends', 'census_connections',
        'segment_selected_passed', 'pathway_selected_passed'],
        data() {
            return {    
                local_census_connection: {},
                stored_census_connection: null,
                filtered_segment_list: [],
                segment: {
                    name: '',
                    data_source_id: null,
                    filter: null
                },
                segment_list_shown:[],
                search_key: '',
                filters: {},
                source_list: [],
                dest_list: [],
                segment_selected: {},
                pathway_selected: {},
                stage: 0,
                // Error handling in advance settings
                information_errors:{
                    destination_object: "",
                    operation: "",
                    form: false
                },
                busy: false,

                // pagination
                num_per_page: 5, // default to 5
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: ''
                
            };
        },
        beforeMount() {
            this.filtered_segment_list = this.segments;
            this.reloadPagination();
            this.setupFilter();
            if (this.segment_selected_passed && this.pathway_selected_passed){
                this.segment_selected=this.segment_selected_passed;
                this.pathway_selected=this.pathway_selected_passed;
                this.stage = 1;
            }
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        computed: {
            costText() {
                if(this.pathway_selected.integration_id == "71b0c3b0-8390-49ec-9984-1c04b7384d38")
                    return 'Free';
                return '$300 / month <em>(Includes unlimited '+this.pathway_selected.integration.display_name+' audiences)</em>';
            }
        },
        methods: {
            showCost() {
                let conn_id = this.pathway_selected.id;
                //If no pathway is selected, don't show the cost
                if(conn_id == undefined)
                    return false;
                //See if there is an existing census connection
                for(let i = 0; i < this.client.census_connections.length; i++) {
                    if(this.client.census_connections[i].connection_id == conn_id && this.client.census_connections[i].syncs.length > 0) {
                        return false;
                    }
                }
                return true;
            },
            getDefaultSize(sizes) {
                for (var i = 0; i < sizes.length; i++){
                    if (sizes[i].id == 0)
                        return sizes[i].size;
                }
                return null
            },
            proceedToSummary(){
                if(this.pathway_selected.census_destination_id == null && this.pathway_selected.integration.census_requires_oauth){
                    // census oauth
                    var self = this;
                    Swal.fire({
                        title: "Additional Access is Needed",
                        text: "Avid AI has partnered with a software service named Census to activate the ability to push data to "+this.pathway_selected.integration.display_name+". This destination requires additional authentication before it can be used.",
                        iconHtml: '<i class="fa-solid fa-exclamation"></i>',
                        iconColor: "#25473E",
                        showCancelButton: true,
                        confirmButtonColor: "#25473E",
                        confirmButtonText: "Proceed",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location = '/integrations/'+this.client.url+'/census/oauth/' + self.pathway_selected.id + '/'+ self.segment_selected.id;
                            }
                        });                    
                }
                else{
                    var newURL = '/pathways/'+this.client.url +'/'+this.segment_selected.id+'/'+this.pathway_selected.id;
                    window.history.replaceState({}, {}, newURL);

                    this.stage = 1;
                }
            },
            setupFilter(){
                var destinations = [];
                this.connection_datasets.forEach(conn =>{
                    destinations.push(conn.name)
                });

                var sources = [];
                this.datablends.forEach( db =>{
                    if(db.segments.length > 0){
                        sources.push(db.name)
                    }
                });
                this.datasets.forEach( ds =>{
                    if(ds.segments.length > 0){
                        sources.push(ds.name)
                    }
                });

                this.source_list= sources;
                this.dest_list= destinations;
                this.filters = {
                    source: this.source_list,
                    destination: this.dest_list
                }
            },
            handleFilterSelection(){
                var filtered_list = [];
            
                this.segments.forEach(seg => {
                    let data_source = seg.dataset ? seg.dataset : seg.datablend;
                    if(!data_source)
                        data_source = seg.data_source ? seg.data_source : {};

                    let in_search = this.search_key == '' || seg.name.toLowerCase().includes(this.search_key.toLowerCase());
                    if(data_source && in_search){
                        filtered_list.push(seg);
                    } else if(this.filters.source.includes(data_source.name) && in_search){
                        if (!seg.pushed_pathways.length){
                            filtered_list.push(seg);
                        } else if (this.filters.destination.some(item => seg.pushed_pathways.includes(item))){
                            filtered_list.push(seg)
                        }
                    }
                });
            
                this.filtered_segment_list = filtered_list;
                this.reloadPagination();
                this.$forceUpdate();
            },
            addNewSegment() {
                if(this.datablends.length > 0){
                    this.segment = {
                        id: null,
                        source: 'data_blend',
                        name: '',
                        data_source_id: this.datablends[0].id,
                        filter: null,
                        expires_at: null
                    };
                }
                else {
                    this.segment = {
                        id: null,
                        source: 'data_set',
                        name: '',
                        data_source_id: this.datasets[0].id,
                        filter: null,
                        expires_at: null
                    };
                }
            },
            addSegmentToDataSet(seg) {
                this.filtered_segment_list.push(seg);
                // this.sortDataWithLabel();
                this.reloadPagination();
            },
            reloadPagination(){
                this.page_count = Math.ceil(this.filtered_segment_list.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            }, 
            getItemInterval(){
                var from = this.page_index * this.num_per_page + 1;
                var to = (this.page_index +1) * this.num_per_page;
                if(to>this.filtered_segment_list.length)
                    to = this.filtered_segment_list.length;
                if (from >= to) 
                    this.item_interval = to
                else 
                    this.item_interval = from +' - ' +to;
                
                this.segment_list_shown = this.filtered_segment_list.slice(from-1, to);
            },
            moveToPrevPage(){
                this.page_index--;
                this.getItemInterval();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getItemInterval();
            },
            moveToNextPage(){
                this.page_index++;
                this.getItemInterval();
            },
            changeSegmentSelection(segment){
                this.segment_selected = segment;
                //Determine if there are existing connections set up and disable those destinations

            },
            isAlreadySynced(conn) {
                if(conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38') return false;

                if(this.segment_selected == null || this.segment_selected.pushed_pathways == null) return false;
                //Check if the conn.name is in the this.segment_selected.pushed_pathways
                if(this.segment_selected.pushed_pathways.includes(conn.name)){
                    return true;
                }
                return false
            },
            getPathwayCardClass(conn){
                if (this.segment_selected.id == undefined  || this.isAlreadySynced(conn)){
                    return 'long-card-disabled';
                }
                if (conn.id == this.pathway_selected.id){
                    return 'long-card-selected';
                }
            },
            changePathwaySelection(pathway){
                if (!this.segment_selected.id){
                    return;
                }
                if(this.isAlreadySynced(pathway)){
                    return;
                }
                this.pathway_selected = pathway;
                var temp = this.census_connections.filter(conn => conn.connection_id == this.pathway_selected.id && conn.data_source_id == this.segment_selected.data_source_id);
                if (temp.length >0)
                    this.local_census_connection = temp[0];
            },
            cancelSelection(){
                this.segment_selected = {};
                this.pathway_selected = {};
                this.local_census_connection = {};
                this.stage = 0;
            },
            updateCensusConnection(details){
                this.local_census_connection = details;

                //Save the initial version of the connection but don't allow it to be overwritten but future edits
                if(this.stored_census_connection == null)
                    this.stored_census_connection = details;

                //If there aren't any mappings, need to make them
                if(this.local_census_connection.mappings == null){
                    this.stage = 2;
                    return;
                }

                var unmappedFields = this.local_census_connection.mappings.filter((mapping, index) => 
                    //If the first one isn't a primary identifier
                     (index == 0 && mapping.is_primary_identifier != 1) ||
                     //If the first one is an array but the first value isn't set
                     (index == 0 && Array.isArray(mapping.source_column) && mapping.source_column.length > 0 &&
                        (this.local_census_connection.is_file == false &&
                            (mapping.source_column[0].value == null || mapping.source_column[0].value == ""))) ||
                     //If it is a normal object but the value hasn't been set
                     (mapping.source_column && !Array.isArray(mapping.source_column) && (mapping.source_column.value == null || mapping.source_column.value == ""))
                );

                //If there are still unmapped fields or the connection isn't set
                if(unmappedFields.length > 0 || !this.local_census_connection.connection_id)
                    this.stage = 2;
                else{
                    this.stage = 3;
                }

                // this.$forceUpdate();
            },
            validateCensusConnection() {
                this.information_errors.operation = "";
                this.information_errors.destination_object = "";

                let is_valid = true;

                if(this.local_census_connection.operation == null || this.local_census_connection.operation == ''){
                    this.information_errors.operation = 'The method of sync is a required field';
                    is_valid = false;
                }
                if(this.local_census_connection.destination_object == null || this.local_census_connection.destination_object == '') {
                    is_valid = false;
                    this.information_errors.destination_object = 'The destination object is a required field';
                }

                return is_valid;
            },
            backToSelection(){
                this.stage = 0;
                this.cancelSelection()

                this.stored_census_connection = null
                var newURL = '/pathways/'+this.client.url+"/push_audience";
                window.history.replaceState({}, {}, newURL);
            },
            isFile() {
                return this.pathway_selected.integration_id == "71b0c3b0-8390-49ec-9984-1c04b7384d38";
            },
            saveAndPush() {
                var is_valid = true;
                if(this.isFile()){
                    this.local_census_connection.operation = 'create';
                    this.local_census_connection.destination_object = 'file';

                    if(this.$refs.pathway_summary.$refs.file_mapping != undefined && !this.$refs.pathway_summary.$refs.file_mapping.isValid())
                        is_valid = false;
                }
                else {
                    is_valid = this.validateCensusConnection();
                    if(this.$refs.pathway_summary.$refs.column_mapping != undefined && !this.$refs.pathway_summary.$refs.column_mapping.isValid())
                        is_valid = false;
                }


                if(!is_valid){
                    this.information_errors.form = true;           
                }
                else {
                    this.information_errors.form = false;
                    this.busy = true;
                    this.save(true);
                }
            },
            pushSegmentToPathway() {
                this.busy = true;
                //See if there is a census_connection set up
                if(this.local_census_connection.connection_id == this.pathway_selected.id && this.segment_selected.source ==
                    this.local_census_connection.source && this.segment_selected.data_source_id ==
                    this.local_census_connection.data_source_id) {

                    var form_data = {
                        client_id: this.client.id,
                        segment_id: this.segment_selected.id,
                        census_connection_id: this.local_census_connection.id,
                        mappings: this.local_census_connection.mappings,
                        active: true,
                    }

                    //Call the webhook to save the new sync
                    window.axios.post('/api/census/add_sync', form_data)
                        .then(response => {
                            //If it is a file export...
                            if(this.pathway_selected.integration_id == "71b0c3b0-8390-49ec-9984-1c04b7384d38") {
                                //Make the sweetalert fire using the returned url as a "download" button


                                Swal.fire({
                                    title: 'Your File is Ready',
                                    text: this.segment_selected.name+ ' has been exported to a file and is ready to download.',
                                    icon: 'success',
                                    iconColor:  "#46705F",
                                    confirmButtonText: "Download",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    this.busy = false;
                                    var newURL = '/pathways/'+this.client.url+"/push_audience";
                                    window.history.replaceState({}, {}, newURL);
                                    //Open a new tab and redirect to response.data.url
                                    window.open(response.data.url, '_blank');
                                    location.reload()
                                });
                            }
                            else {
                                Swal.fire({
                                    title: 'New Pathway Activated',
                                    text: this.segment_selected.name+ ' is being pushed to '+ this.pathway_selected.name,
                                    icon: 'success',
                                    iconColor:  "#46705F",
                                    confirmButtonColor: "#46705F"
                                }).then((result) => {
                                    this.busy = false;
                                    var newURL = '/pathways/'+this.client.url+"/push_audience";
                                    window.history.replaceState({}, {}, newURL);
                                    location.reload()
                                });
                            }

                        }).catch(error => {
                            this.busy = false;
                            if(error.response.status == 400)
                                //Fields weren't mapped, time to redirect....
                                document.location.href = '/pathways/' + this.client.url + '/' + this.pathway_selected.id + '/mapping/' + this.segment_selected.source + '/' + this.segment_selected.data_source_id;
                            if(error.response.status == 500) {
                                if(error.response.data.census_error != null){
                                    this.local_census_connection.status_msg = error.response.data.census_error;
                                    Swal.fire({
                                        title: "Error On Syncing",
                                        text: this.local_census_connection.status_msg,
                                        icon: 'warning',
                                        iconColor: "#D11F1F",
                                        showCancelButton: false,
                                        confirmButtonColor: "#D11F1F",
                                    }).then((result) => {
                                      if(this.local_census_connection.status_msg == 'The Census destination has been deleted') {
                                        location.reload()
                                      }
                                    });
                                }
                                if(error.response.data.sync_error != null)
                                    this.local_census_connection.syncs.push({
                                        last_sync_status: error.response.data.sync_error,
                                        client_id: this.client.id,
                                        segment_id: this.segment_selected.id,
                                        census_connection_id: this.local_census_connection.id
                                    });
                            } 
                        });
                    return;
                }
            
                //If not, redirect to the mapping page
                document.location.href = '/pathways/' + this.client.url + '/' + this.pathway_selected.id + '/mapping/' + this.segment_selected.source + '/' + this.segment_selected.data_source_id;
            },
            save(and_sync = false) {
                var self = this;
                this.busy = true;

                if(!this.local_census_connection.id) {
                    window.axios.post('/api/census_connection', this.local_census_connection)
                    .then(response => {
                        self.busy = false;
                        self.local_census_connection = response.data.census_connection;
                        let mappings = self.local_census_connection.mappings;
                        self.local_census_connection = response.data.census_connection
                        self.local_census_connection.mappings = mappings;

                        if(and_sync){
                            self.pushSegmentToPathway();
                        }
                    });
                }
                else {
                    if(this.ifCensusConnectionChanged()){
                        this.local_census_connection.save_mapping = 0;
                        // call update api when there is any actual changes
                        window.axios.put('/api/census_connection/' + this.local_census_connection.id, this.local_census_connection)
                        .then(response => {
                            self.busy = false;
                            let mappings = self.local_census_connection.mappings;
                            self.local_census_connection = response.data.census_connection
                            self.local_census_connection.mappings = mappings;

                            if(and_sync)
                                self.pushSegmentToPathway();
                        });                        
                    }
                    else if(and_sync)
                        this.pushSegmentToPathway();
                }
            },
            ifCensusConnectionChanged() {
                if (this.stored_census_connection.destination_object != this.local_census_connection.destination_object)
                    return true;
                if (this.stored_census_connection.operation != this.local_census_connection.operation)
                    return true;

                if (this.stored_census_connection.prioritization_column == null || this.local_census_connection.prioritization_column == null ||
                    this.stored_census_connection.prioritization_column.length != this.local_census_connection.prioritization_column.length) {
                    return true;
                } else {
                    // Create copies of the arrays and sort them for comparison
                    var old_value = this.stored_census_connection.prioritization_column ? this.stored_census_connection.prioritization_column.slice().sort((a, b) => (a.column > b.column) ? 1 : -1) : [];
                    var new_value = this.local_census_connection.prioritization_column ? this.local_census_connection.prioritization_column.slice().sort((a, b) => (a.column > b.column) ? 1 : -1) : [];
                    for (var i = 0; i < old_value.length; i++) {
                        if (old_value[i].column != new_value[i].column || old_value[i].sort != new_value[i].sort)
                            return true;
                    }
                }

                if (this.stored_census_connection.mappings == null || this.stored_census_connection.mappings.length == 0 || this.stored_census_connection.mappings.length != this.local_census_connection.mappings.length) {
                    return true;
                } else {
                    // Create copies of the arrays and sort them for comparison
                    var old_value = this.stored_census_connection.mappings.slice().sort((a, b) => (a.id > b.id) ? 1 : -1);
                    var new_value = this.local_census_connection.mappings.slice().sort((a, b) => (a.id > b.id) ? 1 : -1);
                    for (var i = 0; i < old_value.length; i++) {
                        if (old_value[i].source_column.toString() != new_value[i].source_column.toString()
                            || old_value[i].destination_column != new_value[i].destination_column)
                            return true;
                    }
                }

                return false;
            },

        }
    }
</script>
