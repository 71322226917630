<template>
    <div class="view-report-filter-panel sticky-filter-panel">
        <div class="view-report-filter-panel--content flex-grow-1 mx-4">

            <div class="row mb-3">
                <label>Selected:</label>
                
                <div class="filter-badge-container">
                    <div class="badge badge-primary" :key="filter.filter_id + filter.filter_value"
                        v-for="(filter, index) in applied_filters_list" v-if="filter.filter_id != -1"
                        v-on:click.prevent="setActiveTab(filter.group_index)">
                        <span v-if="filter.filter_type == 'date_range'">
                            {{filter.filter_label}}: {{ dateRangeDisplay(filter.filter_value) }}
                        </span>
                        <span v-if="filter.filter_type == 'month_range'">
                            {{filter.filter_label}}: {{ monthRangeDisplay(filter.filter_value) }}
                        </span>
                        <span v-else-if="filter.filter_type == 'single_select'">
                            {{filter.filter_label}} = "{{filter.filter_value}}" 
                        </span>
                        <span v-else-if="filter.filter_type == 'multi_select' || filter.filter_type == 'month_picker'">
                            {{filter.filter_label}}{{ filter.filter_value.length >1 ? ' ('+filter.filter_value.length+')' : ' = "'+ filter.filter_value[0] +'"'}}
                        </span>
                        <span v-else-if="filter.filter_type == 'numeric_slider'">
                            {{filter.filter_label}} between {{ filter.filter_value[0] }} and {{ filter.filter_value[1] }}
                        </span>
                        <button v-if="!filter.is_required" class="btn btn-xs btn-link p-0 ps-1" v-on:click.prevent="removeAppliedFilter(filter, index)">
                            <i class="fas fa-close"></i>
                        </button>
                    </div>
                    <div class="badge badge-secondary text-dark" :key="filter.filter_id + filter.filter_value"
                        v-for="(filter, index) in applied_filters_list" v-if="filter.filter_id == -1">
                        <span v-if="filter.filter_type == 'single_select'">
                            {{filter.filter_label}} = "{{filter.filter_value}}" 
                        </span>
                        <button class="btn btn-xs btn-link p-0 ps-1" v-on:click.prevent="removeAppliedFilter(filter, index)">
                            <i class="fas fa-close"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="btn-group toggle-btn-group mb-2 w-100" role="group">
                        <button type="button" class="btn" v-for="(tab, index) in dimensional_filters_list" v-on:click="setActiveTab(index)" v-if="tab.filter_group"
                            :class="index==active_tab_index ? 'btn-primary' : 'btn-white'">
                            {{ tab.filter_group }}
                        </button>
                    </div>
                </div> 
            </div>

            <hr id="filter-panel-body-hr" />

            <div class="row flex-grow-1" v-for="(group, group_index) in dimensional_filters_list"  :hidden="group_index != active_tab_index">
                <div class="mb-2" v-for="(filter, filter_index) in group.filters">
                    <advanced-filter-dropdown :filter="filter" :group_index="group_index" :field_index="filter_index" :active_group_index="active_tab_index"
                        @updateFilter="updateFilterInGroup" @searchInLongOptionList="searchInLongOptionList" :key="filter.id">
                    </advanced-filter-dropdown>
                </div>
            </div>
        </div>
        
        <div class="row sticky-bottom mx-0 py-2">
            <div class="w-100 d-flex flex-row justify-content-between align-items-center flex-nowrap gap-2">

                <div class="d-flex flex-row justify-content-end align-items-center gap-2">
                    <span>{{ filter_count }} {{(filter_count > 1 ? 'Filters': 'Filter')}} {{filter_count_label}}</span>
                </div>

                <div class="d-flex flex-row justify-content-end align-items-center flex-nowrap gap-2">
                    <button type="button" class="btn btn-secondary" @click.prevent="clearFilters()">
                        Clear All
                    </button>
                    <button v-if="!if_refreshing" type="button" class="btn btn-primary" :disabled="!checkIfDropdownsLoaded()" @click.prevent="applyFilter()">
                        Apply Filters
                    </button>
                    <button v-else type="button" class="btn btn-primary" disabled>
                        <div class="d-inline-block spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>
                        Loading Data...
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import vSelect from "vue-select";
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
            Swal, vSelect, Datepicker
        },
        props: ['page', 'applied_chart_filter', 'section_id'],
        data() {
            return {
                local_page: {},
                if_sticky: false,
                dimensional_filters_list: [],  // format: array of objects {filter_group: '', is_tab_active: boolean, filters: []}
                local_applied_chart_filter: null,
                applied_filters_list:[],
                filter_errors: {},
                if_refreshing: false,
                filter_count: 0,
                filter_count_label: 'Applied',
                active_tab_index: 0
            };
        },
        beforeMount() { 
            this.local_page = {...this.page}
            
            for (var i = 0; i<this.local_page.dimensions.length; i++){
                this.local_page.dimensions[i].disabled = true;
                this.local_page.dimensions[i].options = [];
                this.local_page.dimensions[i].db_search = false;
            }
            this.local_applied_chart_filter = this.applied_chart_filter;
            this.loadFilterGroups(); 
            this.getFilterCount();
        },
        mounted() {
            // loop through dimensional_filters_list to update options fields
            this.getAppliedFilterWithValue();
            this.loadPresetFilters();
            this.loadFilterOptions();
        },
        watch:{
            page: {
                handler: function(newVal, oldVal) {
                    this.local_page = {...this.page}
                    // this.loadFilterGroups(); 
                    this.updateFilterGroups();
                    this.getAppliedFilterWithValue();
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
            applied_chart_filter: {
                handler: function(newVal, oldVal) {
                    this.local_applied_chart_filter = this.applied_chart_filter;
                    this.updateURL();
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            }

        },
        computed: {},
        methods: {
            dateRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.date(value.start_date) + ' - ' + this.$options.filters.date(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it

                    return 'On or After ' + this.$options.filters.date(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.date(value.end_date);
                }
            },
            monthRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.month(value.start_date) + ' - ' + this.$options.filters.month(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it

                    return 'On or After ' + this.$options.filters.month(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.month(value.end_date);
                }
            },
            getAppliedFilterWithValue(){
                this.applied_filters_list = [];
                this.dimensional_filters_list.forEach((group, group_i) => {
                    group.filters.forEach((filter, filter_i)=>{                        
                        if(filter.value && (filter.value.length >0  ||
                            //Or a date/month range with one of the two dates set
                            ((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.value && (filter.value.end_date != null || filter.value.start_date != null)))) {
                            this.applied_filters_list.push({
                                filter_id: filter.id,
                                filter_label: filter.label,
                                filter_value: filter.value,
                                //If it is year_type, that needs to come from the client, not the dim
                                filter_default_value: ((typeof filter.column === 'string' || filter.column instanceof String) && filter.column.indexOf('time_period.year_type') !== -1? this.page.client.default_reporting_period : filter.default_value),
                                filter_column_name: filter.column,
                                filter_type: filter.field_type,
                                is_required: filter.is_required,
                                group_index: group_i,
                                filter_index_in_group: filter_i
                            })
                        }
                    });
                });
                this.getFilterCount();
            },
            removeAppliedFilter(filter, filter_index){
                if(filter.filter_id == -1 || (this.applied_chart_filter.if_chart_filter_applied && filter.filter_value.includes(this.applied_chart_filter.chart_filters[0].value))){
                    this.local_applied_chart_filter = {                    
                        if_chart_filter_applied: false,
                    };
                }
                this.applied_filters_list.splice(filter_index, 1);
                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'single_select'){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = '';
                }
                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'multi_select'
                    || this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'month_picker'){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = [];
                }
                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'numeric_slider'){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = null
                }

                if (this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'date_range' ||
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].field_type == 'month_range'
                ){
                    this.dimensional_filters_list[filter.group_index].filters[filter.filter_index_in_group].value = {
                        start_date: null,
                        end_date: null
                    };
                }
                this.getFilterCount();
                // this.$forceUpdate();
            },
            handleScroll() {
                if(this.$refs.stickyDiv == null) return;

                const divTop = this.$refs.stickyDiv.getBoundingClientRect().top;
                this.if_sticky = divTop <= 0;
            },
            loadFilterGroups(){
                var group_array = [];

                var group_name = null;
                var group_filters_temp = [];
                this.page.dimensions.forEach(pf => {
                    if(pf.value && pf.value.length == 1 && pf.value[0] == 'Donation'){ // hide is_donation in filter panel
                        return;
                    }
                    // setup filter error array 
                    if (pf.is_required){
                        this.filter_errors[pf.label] = '';
                    }

                    if(group_name && pf.filter_group != group_name){
                        group_array.push({
                            filter_group: group_name,
                            filters: group_filters_temp
                        });
                        group_name = pf.filter_group;
                        group_filters_temp = [pf];
                    } else {
                        group_name = pf.filter_group;
                        group_filters_temp.push(pf);
                    }
                });
                group_array.push({
                    filter_group: group_name,
                    filters: group_filters_temp
                });

                // group_array[0].is_tab_active = true; // default first tab to be active
                this.dimensional_filters_list = group_array;
            },
            updateFilterGroups(){
                this.page.dimensions.forEach(pf => {
                    if(pf.value && pf.value.length == 1 && pf.value[0] == 'Donation'){ // hide is_donation in filter panel
                        return;
                    }
                    var group_index = this.dimensional_filters_list.findIndex(group => group.filter_group == pf.filter_group);
                    if (group_index>=0){
                        var filter_index =this.dimensional_filters_list[group_index].filters.findIndex(filter => filter.id == pf.id);
                        this.dimensional_filters_list[group_index].filters[filter_index].value = pf.value;
                    } else { // chart filter
                        this.dimensional_filters_list.push({
                            filter_group: null,
                            filters: [pf]
                        })
                    }
                });

                if (!this.applied_chart_filter.if_chart_filter_applied){
                    // when removing chart filter, need to remove the chart filter and filter group in this.dimensional_filters_list as well
                    this.dimensional_filters_list = this.dimensional_filters_list.filter(group => group.filter_group != null);
                }
            },

            loadPresetFilters() {
                this.dimensional_filters_list.forEach((filter_group, group_index) => {
                    filter_group.filters.forEach((filter, filter_index) => {
                        //If the filter doesn't have a selected value...
                        if (((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.value &&
                              (filter.value.start_date || filter.value.end_date)) ||
                            (filter.field_type != 'date_range' && filter.field_type != 'month_range' && filter.value && filter.value.length != 0)) {
                                var data = {
                                    client: this.page.client,
                                    source: 'data_blend',
                                    column: filter.column,
                                    limit: 1000,
                                    data_source_id: this.page.data_blend.id,
                                    staging_table: false
                                };

                                window.axios.post('/api/bigquery/get_samples', data)
                                .then(response => {
                                    var db_values = [];

                                    //Remove the null value
                                    for(var i = response.data.samples.length - 1; i >= 0; i--){
                                        if(response.data.samples[i].name == null)
                                            response.data.samples.splice(i, 1);
                                        else {
                                            if (filter.field_type == 'single_select' || filter.field_type == 'multi_select'|| filter.field_type == 'month_picker' ){
                                                db_values.push(response.data.samples[i].name);
                                            }
                                        }
                                    }

                                    if (filter.field_type == 'month_picker') {
                                        // sort by month order
                                        const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                        db_values.sort((a, b) => monthOrder.indexOf(a) > monthOrder.indexOf(b) ? 1 : -1);
                                    } else {
                                        db_values.sort((a, b) => (a.name > b.name ) ? 1 : -1);
                                    }

                                    filter.options = db_values;
                                    filter.disabled = false;
                                    filter.db_search = (db_values != null && db_values.length >= 999);
                                });
                        }

                    });
                })
            },

            getMinMax(filter) {
                var data = {
                  source: 'data_blend',
                  data_source_id: this.page.data_blend.id,
                  client: this.page.client,
                  column: filter.column,
                };

                window.axios.post('/api/bigquery/get_min_max', data)
                    .then(response => {
                        filter.slider_min = response.data.min;
                        filter.slider_max = response.data.max;

                        if(filter.options && filter.options.length == 2 && !isNaN(filter.options[0]) && !isNaN(filter.options[1]) ){
                            if(filter.options[0] < this.slider_min)
                                filter.slider_min = filter.options[0];
                            if(filter.options[1] > this.slider_max)
                                filter.slider_max = filter.options[1];
                        }
                        for (var i=0; i< this.dimensional_filters_list.length; i++){
                            for (var j=0; j<this.dimensional_filters_list[i].filters.length; j++){
                                if (this.dimensional_filters_list[i].filters[j].id == filter.id){
                                    this.dimensional_filters_list[i].filters[j].disabled = false;
                                    this.dimensional_filters_list[i].filters[j] = JSON.parse(JSON.stringify(filter));
                                    break;
                                }
                            }
                        }
                        this.$forceUpdate();
                    });
            },
            loadFilterOptions(page = null) {
                //Unused filters
                let unused_filters = [];
                this.dimensional_filters_list.forEach((filter_group, group_index) => {
                    filter_group.filters.forEach((filter, filter_index) => {
                        //If the filter doesn't have a selected value...
                        if(filter.field_type == 'numeric_slider'){
                            this.getMinMax(filter);
                        }
                        else if (!((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.value &&
                              (filter.value.start_date || filter.value.end_date)) &&
                            !(filter.field_type != 'date_range' && filter.field_type != 'month_range' && filter.value && filter.value.length != 0)) {
                            unused_filters.push(filter);
                        }

                    });
                })
                var data = {
                    page: (page == null) ? this.local_page : page,
                    filters: unused_filters,
                };

                window.axios.post('/api/metric_filter/options', data)
                    .then(response => {
                        let filters = response.data.filters;

                        for (var i=0; i< this.dimensional_filters_list.length; i++){
                            for (var j=0; j<this.dimensional_filters_list[i].filters.length; j++){
                                for(var k = 0; k < filters.length; k++){
                                    if (this.dimensional_filters_list[i].filters[j].id == filters[k].id){
                                        //Resort the month dropdown option
                                        if (filters[k].field_type == 'month_picker' && filters[k].options != null ) {
                                            // sort by month order
                                            const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                            filters[k].options.sort((a, b) => monthOrder.indexOf(a) > monthOrder.indexOf(b) ? 1 : -1);
                                        }
                                        this.dimensional_filters_list[i].filters[j].options = filters[k].options;
                                        this.dimensional_filters_list[i].filters[j].disabled = false;
                                        this.dimensional_filters_list[i].filters[j].db_search = (filters[k].options != null && filters[k].options.length >= 999); //999 since it removes the null


                                        this.dimensional_filters_list[i] = JSON.parse(JSON.stringify(this.dimensional_filters_list[i]));
                                    }
                                }
                            }
                        }

                        this.$forceUpdate();
                //Catch the error if it is a 403 or 404 error
                }).catch(error => {
                    if(error.response.status == 403){
                        Swal.fire({
                            title: 'Error',
                            text: 'You do not have permission to view this page. It is likely due to a lost session. Please refresh the browser.',
                            icon: 'error',
                            iconColor: "#D11F1F",
                            confirmButtonColor: "#D11F1F",
                            confirmButtonText: "OK"
                        });
                    }
                    if(error.response.status == 404){
                        Swal.fire({
                            title: 'Error',
                            text: 'Something went wrong when trying to load the filters. Please refresh the page and try again.',
                            icon: 'error',
                            iconColor: "#D11F1F",
                            confirmButtonColor: "#D11F1F",
                            confirmButtonText: "OK"
                        });
                    }
                });
            },
            checkIfDropdownsLoaded(){
                for (var i=0; i< this.dimensional_filters_list.length; i++){
                    for (var j=0; j<this.dimensional_filters_list[i].filters.length; j++){
                         if (this.dimensional_filters_list[i].filters[j].disabled == true)
                            return false;
                    }
                }
                return true;
            },
            setActiveTab(index){
                this.active_tab_index = index;
                // this.$forceUpdate();
            },
            getTileClass(filter, option){
                var classes = "";
                if (filter.field_type == 'tile-single-select'){
                    if (filter.value[0] && filter.value[0].id == option.id){
                        classes = 'tile-selected'
                    }                    
                }
                return classes;
            },
            selectOption(group_index, filter_index, option){
                if (this.dimensional_filters_list[group_index].filters[filter_index].field_type == 'tile-single-select'){
                    this.dimensional_filters_list[group_index].filters[filter_index].value = [option];
                } else { //'tile-multi-select'
                    this.dimensional_filters_list[group_index].filters[filter_index].value.push(option);
                }
                this.filter_count_label = 'Selected';
                this.getFilterCount();
            },
            updateFilterInGroup(filter_details){
                // if there is chart filter applied, change filter option selection will remove the chart filter
                if(this.applied_chart_filter.if_chart_filter_applied && filter_details.filter_value.includes(this.applied_chart_filter.chart_filters[0].value)){
                    this.local_applied_chart_filter = {                    
                        if_chart_filter_applied: false,
                    };
                }
                this.dimensional_filters_list[filter_details.group_index].filters[filter_details.field_index].value = filter_details.filter_value;
                this.filter_count_label = 'Selected';
                this.getAppliedFilterWithValue();
                this.getFilterCount();
            },
            searchInLongOptionList(search_details){
                var filter = this.dimensional_filters_list[search_details.group_index].filters[search_details.field_index];
                filter.disabled = true;
                var data = {
                    client: this.page.client,
                    source: 'data_blend',
                    column: filter.column,
                    limit: 1000,
                    data_source_id: this.page.data_blend.id,
                    staging_table: false,
                    search: search_details.search_key
                };
                window.axios.post('/api/bigquery/get_samples', data)
                .then(response => {
                    var db_values = [];

                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--){
                        if(response.data.samples[i].name == null)
                            response.data.samples.splice(i, 1);
                        else {
                            if (filter.field_type == 'single_select' || filter.field_type == 'multi_select'){
                                db_values.push(response.data.samples[i].name);
                            }
                        }
                    }
                    db_values.sort((a, b) => (a.name > b.name ) ? 1 : -1);

                    filter.options = db_values;
                    filter.disabled = false;
                    this.$forceUpdate();
                });
            },
            applyFilter(){  
                // loop through this.dimensional_filters_list and destruct it into a flat array
                var self = this;
                this.if_refreshing = true;

                this.$emit('refreshing', this.if_refreshing);
           
                var dimension_list = [];
                this.dimensional_filters_list.forEach((group)=>{
                    dimension_list = dimension_list.concat(group.filters)
                })
    
                dimension_list.forEach((dimension, i)=>{
                    if (dimension.field_type == "month_range"){
                        dimension_list[i].value.start_date = moment(String(dimension_list[i].value.start_date)).utc().format('YYYY-MM-DD');
                        dimension_list[i].value.end_date =  moment(String(dimension_list[i].value.end_date)).utc().format('YYYY-MM-DD');
                    }
                })

                this.local_page.dimensions = dimension_list;
                this.getFilterCount();

                // Is_Donation filter field is hidden, if it is included in the original dimension list, we need to add it in when applying filter
                var is_donation_field_index = this.page.dimensions.findIndex(pf => pf.value && pf.value.length == 1 && pf.value[0] == 'Donation');
                if (is_donation_field_index >= 0){
                    this.local_page.dimensions.push(this.page.dimensions[is_donation_field_index]);
                }

                this.loadFilterOptions();

                window.axios.post('/api/insights/page/' + this.local_page.id, this.local_page)
                    .then(response => {
                        self.filter_count_label = 'Applied';
                        //self.updateURL();

                        var page_temp = self.local_page;
                        self.local_page = response.data.page;
                        self.local_page.dimensions = page_temp.dimensions
                        self.if_refreshing = false;

                        self.$emit('refreshing', self.if_refreshing);
                        if (self.local_applied_chart_filter.if_chart_filter_applied == false){
                            self.$emit('removeChartFilter', null);
                        }
                        self.$emit('updatePage', self.local_page);
                    }).catch(error=> {

                });
            },
            arraysAreEqual(a, b) {
                if (a === b) return true;

                if (typeof a != 'object' || a === null || typeof b != 'object' || b === null) {
                    return false;
                }

                let keysA = Object.keys(a);
                let keysB = Object.keys(b);

                if (keysA.length !== keysB.length) return false;

                keysA.sort();
                keysB.sort();

                for (let i = 0; i < keysA.length; i++) {
                    if (keysA[i] !== keysB[i]) return false;
                    if (!this.arraysAreEqual(a[keysA[i]], b[keysB[i]])) return false;
                }

                return true;
            },

            async updateURL(){
                // update url with filters
                const currentUrl = document.location.pathname;
                var filter_obj = {};

                // Handle each filter asynchronously
                for (const applied_filter of this.applied_filters_list) {
                    if(applied_filter.filter_default_value == null ||
                        (typeof applied_filter.filter_default_value === "object" ||
                            Array.isArray(applied_filter.filter_default_value))
                            && !this.arraysAreEqual(applied_filter.filter_default_value, applied_filter.filter_value) ||
                        (typeof applied_filter.filter_default_value !== "object" && Array.isArray(applied_filter.filter_default_value) == false) && applied_filter.filter_default_value != applied_filter.filter_value
                        ){

                        let col_name = applied_filter.filter_column_name;
                        if(typeof col_name === 'string' || col_name instanceof String){
                            col_name = col_name.toLowerCase();
                            //Replace new lines with a space
                            col_name = col_name.replace(/(\r\n|\n|\r)/gm, " ");
                            //Replace multiple spaces with a single space
                            col_name = col_name.replace(/\s\s+/g, ' ');
                        }
                            
                        const hashedColumnName = await this.sha256(col_name);
                        filter_obj[hashedColumnName] = applied_filter.filter_value;
                    }
                }

                //Now loop through the non-applied filter list
                //If it has a default value but it isn't in the applied filter list, then add it to the filter_obj
                for (const group of this.dimensional_filters_list) {
                    for (const filter of group.filters) {

                        //Find it the filter.column is not in the applied_filters_list in the filter_column_name property
                        var found = false;
                        for(var i = 0; i < this.applied_filters_list.length; i++){
                            if(this.applied_filters_list[i].filter_column_name == filter.column){
                                found = true;
                                break;
                            }
                        }

                        //If it wasn't in the applied filters list and it had a default value
                        if(!found &&
                            ((filter.field_type != 'date_range' && filter.field_type != 'month_range' && filter.default_value && filter.value.length != 0)
                            || ((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.default_value && (filter.default_value.start_date || filter.default_value.end_date)))) {

                            let col_name = filter.column;
                            if(typeof col_name === 'string' || col_name instanceof String){
                                col_name = col_name.toLowerCase();
                                //Replace new lines with a space
                                col_name = col_name.replace(/(\r\n|\n|\r)/gm, " ");
                                //Replace multiple spaces with a single space
                                col_name = col_name.replace(/\s\s+/g, ' ');
                            }
                            const hashedColumnName = await this.sha256(col_name);

                            //They have to have some value or it will set it to the default value. The value will be an empty value
                            if(filter.field_type == 'date_range' || filter.field_type == 'month_range' )
                                filter_obj[hashedColumnName] = {
                                    start_date: null,
                                    end_date: null
                                };
                            else if(filter.field_type == 'single_select')
                                filter_obj[hashedColumnName] = "";
                            else if(filter.field_type == 'multi_select' || filter.field_type == 'month_picker') {
                                filter_obj[hashedColumnName] = [];
                            }

                        }

                    }
                }

                //append the params to the export button
                let export_btn = document.getElementById('export-button');

                //If the filter_obj doesn't have any values, just remove the params variable
                if(Object.keys(filter_obj).length == 0){
                    window.history.replaceState({}, '', currentUrl);

                    if(export_btn)
                        export_btn.href = currentUrl.replace('insights', 'export');
                }
                else {
                    //If it is longer than 1600 characters, save it to the DB
                    let params = encodeURI(JSON.stringify(filter_obj));

                    if(params.length >= 1600){
                        //Save what section they were looking at
                        var data = {
                          client_id: this.page.client_id,
                          params: params
                        };
                        window.axios.post('/api/insights/parameters', data).then(response => {
                            var newURL = currentUrl + '?param_id=' + response.data.id;
                            window.history.replaceState({}, '', newURL);

                            if(export_btn)
                                export_btn.href = newURL.replace('insights', 'export');
                        })
                    }
                    else{
                        var newURL = currentUrl + '?params=' + params;
                        window.history.replaceState({}, '', newURL);

                        if(export_btn)
                            export_btn.href = newURL.replace('insights', 'export');
                    }
                }
            },
            async sha256(message) {
                // encode as UTF-8
                const msgBuffer = new TextEncoder().encode(message);

                // hash the message
                const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

                // convert ArrayBuffer to Array
                const hashArray = Array.from(new Uint8Array(hashBuffer));

                // convert bytes to hex string
                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                return hashHex;
            },

            clearFilters(){
                for (var i=0; i< this.dimensional_filters_list.length; i++){
                    for (var j=0; j<this.dimensional_filters_list[i].filters.length; j++){
                         if (!this.dimensional_filters_list[i].filters[j].is_required){
                            if(this.dimensional_filters_list[i].filters[j].field_type == "multi_select")
                                this.dimensional_filters_list[i].filters[j].value = [];
                            else
                                this.dimensional_filters_list[i].filters[j].value = '';
                         }
                    }
                }
                //Remove the params
                const currentUrl = document.location.pathname;
                let export_btn = document.getElementById('export-button');
                window.history.replaceState({}, '', currentUrl);
                if(export_btn)
                    export_btn.href = currentUrl.replace('insights', 'export');

                this.getFilterCount();
                this.applyFilter();
            },
            getFilterCount(){
                var filter_count_temp = 0;

                this.dimensional_filters_list.forEach((filter_group, group_index) => {
                    filter_group.filters.forEach((filter, filter_index) => {
                        if((filter.field_type == 'date_range' || filter.field_type == 'month_range') && filter.value && (filter.value.start_date || filter.value.end_date))
                            filter_count_temp++;
                        else if(filter.field_type != 'date_range' && filter.field_type != 'month_range' && filter.value && filter.value.length != 0)
                            filter_count_temp++;
                     
                    });
                })
                this.filter_count = filter_count_temp;
            },  
        }
    }
</script>
