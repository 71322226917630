<template>
    <div class="metric-filter-list">
        <div class="row">
            <div class="col">
                <div class="card box-content mb-3 mt-3">
                    <div class="card-body">
                        <h2>Metric, Dimension & Filter Management</h2>
                        <p>Create or edit the metrics, dimensions and filters that power the charts utilized throughout Avid AI. You can create new values that will be available for all clients or you can select a client from the list below to create some specific to them.</p>
                        <div class="row mb-3">
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Select Visibility</label>
                                <select class="form-select" name="client" v-model="local_client"
                                    v-on:change="changeClient()">
                                    <option value="">*** Global Calculations & Filters ***</option>
                                    <option :value="c" v-for="c in clients">{{ c.name }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Data Source</label>
                                <select class="form-select" name="data_source" v-model="data_blend">
                                    <option :value="d" v-for="d in local_data_blends">{{ d.type | propercase}} Data Blend</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input type="text" class="form-control white-background" placeholder="Search by Name..."
                                        name="metric_filter_search" v-model="search_key" @input="searchByName()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-for="type in data_types">
                <div class="card box-content mb-3">
                    <div class="card-body px-0 pb-2">
                        <div class="row">
                            <div class="col">
                                <h3 class="mx-3">{{ type | propercase }}s
                                    <a class="btn btn-primary mb-2 float-end btn-sm"
                                        @click="addMetricFilter(type)" data-bs-toggle="modal"
                                        data-bs-target="#metric-filter-modal" >
                                        <i class="fa fas fa-fw fa-btn fa-plus"></i> Add a {{ type | propercase }}
                                    </a>
                                    <a class="btn btn-secondary mb-2 float-end btn-sm me-2"
                                        @click="getUsage()" v-if="get_usage == false">
                                        <i class="fa fas fa-fw fa-btn fa-eye"></i> Show Usage
                                    </a>
                                </h3>
                            </div>
                        </div>

                        <div class="table-responsive mb-0" v-if="getDataTypeOptions(type).length > 0">
                            <table class="table gy-0 m-0">
                                <thead class="table-dark">
                                    <tr>
                                        <th class='align-middle ps-3'>Name</th>
                                        <th class="align-middle d-none d-sm-table-cell">Source</th>
                                        <th class="align-middle d-none d-sm-table-cell" v-if="type =='metric'">Format</th>
                                        <th class="align-middle d-none d-sm-table-cell">Pages in Use</th>
                                        <th class="align-middle d-none d-sm-table-cell">Charts in Use</th>
                                        <th class="align-middle d-none d-sm-table-cell" style="width:138px;"></th>
                                    </tr>
                                </thead>
                                <transition-group tag="tbody" name="table-row" class="table-border-bottom-0" >
                                    <tr v-for="metric in getDataTypeOptions(type)" :key="metric.id" >
                                        <td class="align-middle ps-3">
                                            {{metric.admin_name}} <i class="fas fa-info-circle ms-2" data-bs-toggle="tooltip" :title="metric.description" v-if="metric.description != null && metric.description != ''"></i>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            {{ metric.data_blend_type | propercase }} Blend
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell"
                                            v-if="type =='metric'">
                                            {{ metric.format | propercase }}
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <span v-if="get_usage == false"> -- </span>
                                            <a :href="'/insights/report_builder/filter/' + metric.id" v-else-if="metric.page_usage_count> 0">
                                                {{ metric.page_usage_count | number_with_zero}}
                                            </a>
                                            <span v-else-if="metric.page_usage_count == 0">{{ metric.page_usage_count | number_with_zero}}</span>
                                            <span v-else class="spinner-border  spinner-border-sm text-primary" role="status"></span>
                                        </td>
                                        <td class="align-middle d-none d-md-table-cell d-sm-table-cell">
                                            <span v-if="get_usage == false"> -- </span>
                                            <a :href="'/insights/charts/' + metric.id" v-else-if="metric.chart_usage_count> 0">
                                                {{ metric.chart_usage_count | number_with_zero}}
                                            </a>
                                            <span v-else-if="metric.chart_usage_count == 0">{{ metric.chart_usage_count | number_with_zero}}</span>
                                            <span v-else class="spinner-border  spinner-border-sm text-primary" role="status"></span>
                                        </td>
                                        <td class="align-middle text-end pe-3">
                                            <span data-bs-toggle="tooltip" :title="'Copy ' + type">
                                                <button class="btn btn-secondary btn-sm ms-1 mb-1"
                                                    @click="copyMetricFilter(metric)" data-bs-toggle="modal"
                                                    data-bs-target="#metric-filter-modal">
                                                    <i class="fa-solid fa-copy me-0" aria-hidden="true"></i>
                                                </button>
                                            </span>
                                            <span data-bs-toggle="tooltip" :title="'Edit ' + type">
                                                <button class="btn btn-secondary btn-sm ms-1 mb-1"
                                                    @click="editMetricFilter(metric)"
                                                    data-bs-toggle="modal" data-bs-target="#metric-filter-modal">
                                                    <i class="fa-solid fa-edit me-0" aria-hidden="true"></i>
                                                </button>
                                            </span>
                                            <button class="btn btn-secondary btn-sm ms-1 mb-1" :title="'Delete ' + type"
                                                data-bs-toggle="tooltip" @click="deleteMetricFilter(metric)">
                                                <i class="fa-solid fa-trash me-0" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </transition-group>
                            </table>
                        </div>
                        <div v-else-if="search_key != ''" class="text-center px-3 border-top pt-3 pb-2">
                            <p>No {{type}}s match your search criteria</p>
                        </div>
                        <div v-else class="text-center px-3 border-top pt-3 pb-2">
                            <p>No {{type}}s have been created yet. Add your first {{type}} using the button below.</p>
                            <a class="btn btn-primary" @click="addMetricFilter(type)"
                                data-bs-toggle="modal" data-bs-target="#metric-filter-modal">
                                <i class="fas fa-fw fa-plus"></i> Add a {{  type | propercase }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="metric-filter-modal" data-bs-backdrop="static" 
            aria-labelledby="add-metric-filter-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end close-modal"
                                data-bs-dismiss="add-metric-filter-modal" @click="closeModal()">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">{{local_type | propercase}} Editor</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <metric-filter-builder :data_blend="data_blend" :clients="clients" :modal_id="'metric-filter-modal'"
                            :client_id="local_client_id" ref="mf_builder" :type="local_type" @update="mfAdded"></metric-filter-builder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            Swal
        },
        props:['metrics_filters', 'data_blends', 'clients'],
        data() {
            return {
                local_mfs: [],
                visible_metrics_filters: [],
                local_type: '',
                local_data_blends: null,
                local_client: "",
                local_client_id: null,
                data_blend:null,
                data_types: ['metric', 'filter', 'dimension'],
                search_key: '',
                get_usage: false
            };
        },
        beforeMount() {
            this.local_mfs = this.metrics_filters;
            this.local_data_blends = this.data_blends;
            this.data_blend = this.local_data_blends[0];
            this.visible_metrics_filters =  this.loadMetricFilterLists();
        },
        mounted() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {

            getUsage() {
                this.get_usage= true;
                window.axios.post('/api/metric_filter/usage').then(response => {
                    let mfs = response.data.metric_filters;

                    for(var i = 0; i < this.local_mfs.length; i++){
                        for(var j = 0; j < mfs.length; j++){
                            if(mfs[j].id == this.local_mfs[i].id){
                                this.local_mfs[i].page_usage_count = mfs[j].page_usage_count;
                                this.local_mfs[i].chart_usage_count = mfs[j].chart_usage_count;
                                break;
                            }
                        }
                    }
                    this.$forceUpdate();

                });
            },
            loadMetricFilterLists(){
                let arr = [];
                for(var i = 0; i < this.local_mfs.length; i++)
                    //If it is the given type and it is assigned to the selected client (or the template)
                    if(this.local_mfs[i].data_blend_type == this.data_blend.type && (this.local_mfs[i].data_blend_id == null && this.local_client == "" || (this.local_client != "" && this.local_mfs[i].data_blend_id == this.data_blend.id)))
                        arr.push(this.local_mfs[i]);

                return arr;
            },
            searchByName(){
                var arr = this.loadMetricFilterLists();
                if(this.search_key == '') {
                    this.visible_metrics_filters = arr
                } else{ // search against chart name and title for now
                    this.visible_metrics_filters = arr.filter(m =>
                        m.admin_name.toLowerCase().includes(this.search_key.toLowerCase())
                    );
                }

            },
            mfAdded(mf) {
                //Add to the array and re-sort
                //Look through local_mfs to see if the ID is already in there
                var found = false;
                for(var i = 0; i < this.local_mfs.length; i++){
                    if(this.local_mfs[i].id == mf.id){
                        this.local_mfs[i] = mf;
                        found = true;
                        break;
                    }
                }

                if(!found)
                    this.local_mfs.push(mf);

                //Resort the local_mfs based upon name (case insensitive)
                this.local_mfs.sort((a, b) => a.admin_name.toLowerCase() > b.admin_name.toLowerCase() ? 1 : -1);
                this.searchByName();
                this.closeModal();

            },
            getDataTypeOptions(type) {
                let arr = [];
                for(var i = 0; i < this.visible_metrics_filters.length; i++)
                    if(this.visible_metrics_filters[i].type == type)
                        arr.push(this.visible_metrics_filters[i]);
                return arr;
            },
            closeModal() {
                var myModalEl = document.getElementById('metric-filter-modal');
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
            },
            changeClient() {
                var type = this.data_blend.type;

                //Find the client records based upon the local_client variable and
                if(this.local_client != ""){
                    this.local_client_id = this.local_client.id;
                    this.local_data_blends = this.local_client.data_blends;
                }
                else {
                    this.local_data_blends = this.data_blends;
                    this.local_client_id = null;
                }
                this.data_blend = null;

                //Find the datablend of the same type as what was just selected
                for(var i = 0; i < this.local_data_blends.length; i++)
                    if(this.local_data_blends[i].type == type)
                        this.data_blend = this.local_data_blends[i];

                //If I didn't find it, just pick the first one
                if(this.data_blend == null)
                    this.data_blend = this.local_data_blends[0];
            },
            addMetricFilter(type) {
                this.local_type = type;
                this.$refs.mf_builder.setMetricFilter(null);
            },
            copyMetricFilter(mf) {
                let new_mf = JSON.parse(JSON.stringify(mf));
                this.local_type = mf.type;
                new_mf.id = null;
                this.$refs.mf_builder.setMetricFilter(new_mf);
            },
            editMetricFilter(mf) {
                let new_mf = JSON.parse(JSON.stringify(mf));
                this.local_type = new_mf.type;
                this.$refs.mf_builder.setMetricFilter(new_mf);
            },
            deleteMetricFilter(el) {
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to delete this " + el.type + "?",
                  text: "When you delete it, all charts and reports that utilize it will also be deleted. (That is a big deal!)",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/metric_filter/'+ el.id);

                        for(var i = 0; i < this.local_mfs.length; i++){
                            if(this.local_mfs[i].id == el.id){
                                this.local_mfs.splice(i, 1);
                                break;
                            }
                        }
                        this.visible_metrics_filters =  this.loadMetricFilterLists();
                        this.$forceUpdate();

                        Swal.fire({
                            title: "Deleted",
                            text: "The " + el.type + " has been deleted.",
                            icon: "success",
                            iconColor: "#D11F1F",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                });
            }
        }
    }
</script>
