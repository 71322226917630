var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "files" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-sm-4 d-flex flex-row",
          attrs: { id: "public-links" },
        },
        [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c(
                "button",
                {
                  staticClass: "btn ms-auto",
                  class:
                    _vm.local_conn &&
                    _vm.local_conn.connection_criteria.public_link == true
                      ? "btn-secondary"
                      : "btn-primary",
                  attrs: {
                    "data-bs-toggle": "tooltip",
                    title:
                      "Enabling a public link will allow you, or other users, to upload data files without having to log in. You'll be given a secure link and users will only be able to add new files; they cannot view or edit existing ones.",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggleLink()
                    },
                  },
                },
                [
                  _vm.local_conn &&
                  _vm.local_conn.connection_criteria.public_link == true
                    ? _c("span", [_vm._v("Disable")])
                    : _c("span", [_vm._v("Enable")]),
                  _vm._v(" Public Link\n                "),
                ]
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "slide-slow" } }, [
                _vm.local_conn &&
                _vm.local_conn.connection_criteria.public_link == true
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.file_url + _vm.client.url,
                          expression: "file_url + client.url",
                        },
                      ],
                      ref: "clone",
                      staticClass:
                        "form-control border-primary bg-white ps-2 text-muted",
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: _vm.file_url + _vm.client.url },
                      on: {
                        click: function ($event) {
                          return _vm.copyToClipboard()
                        },
                        focus: function ($event) {
                          return $event.target.select()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.file_url + _vm.client,
                            "url",
                            $event.target.value
                          )
                        },
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown ms-2",
              attrs: { id: "settings-dropdown" },
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu p-3",
                  staticStyle: { "max-width": "300px" },
                },
                [
                  _c("form", [
                    _c("div", { staticClass: "mb-3" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.local_conn.connection_criteria.data_qualifier,
                            expression:
                              "local_conn.connection_criteria.data_qualifier",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "data_qualifier" },
                        domProps: {
                          value:
                            _vm.local_conn.connection_criteria.data_qualifier,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local_conn.connection_criteria,
                              "data_qualifier",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.saveFileSettings()
                          },
                        },
                      },
                      [_vm._v("Save")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Click on the box below or drag and drop a data file to add it to your Avid AI instance. The files are limited to csv, txt, and similar delimited text files."
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card box-content mb-5" },
      [
        _c("vue-dropzone", {
          ref: "myVueDropzone",
          staticClass: "dropzone",
          attrs: { id: "dropzone", options: _vm.dropzoneOptions },
          on: {
            "vdropzone-processing": _vm.onProcessing,
            "vdropzone-success": _vm.onSuccess,
            "vdropzone-sending": _vm.onSending,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.local_files.length > 0
      ? _c("div", [
          _c("h3", [_vm._v("Existing Files")]),
          _vm._v(" "),
          _c("div", { staticClass: "card box-content mb-3" }, [
            _c("div", { staticClass: "card-body p-0" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table gy-0 m-0 mb-2" },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    {
                      staticClass: "table-border-bottom-0",
                      attrs: { tag: "tbody", name: "table-row" },
                    },
                    _vm._l(_vm.local_files, function (f, index) {
                      return _c("tr", { key: f.id }, [
                        _c("td", { staticClass: "ps-3 align-middle" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(f.name) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "align-middle d-none d-md-table-cell",
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(f.source) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "align-middle text-nowrap" }, [
                          f.id >= 0
                            ? _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    title: f.status_msg,
                                    id: "tooltip" + f.id,
                                    onmouseover:
                                      "$('#tooltip" +
                                      f.id +
                                      "').tooltip('show');",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-solid fa-circle me-2",
                                    class: _vm.getColorClass(f.status),
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("propercase")(f.status)) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-border spinner-border-sm text-warning float-left",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "visually-hidden" },
                                      [_vm._v("Processing...")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "ms-2" }, [
                                  _vm._v("Processing..."),
                                ]),
                              ]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "align-middle" }, [
                          f.dataset != null
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("propercase")(f.dataset.name)
                                    ) +
                                    "\n                                "
                                ),
                              ])
                            : f.id >= 0 &&
                              f.status == "ready" &&
                              _vm.isDataFile(f)
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-sm btn-primary",
                                  attrs: {
                                    href:
                                      "/integrations/" +
                                      _vm.client.url +
                                      "/mapping/file/" +
                                      f.id,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-btn fa-plus" }),
                                  _vm._v(
                                    " Create a Data Set\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "align-middle d-none d-md-table-cell",
                          },
                          [_vm._v(_vm._s(_vm._f("date")(f.created_at)))]
                        ),
                        _vm._v(" "),
                        f.id > -1
                          ? _c(
                              "td",
                              { staticClass: "align-middle text-center" },
                              [
                                _c("div", { staticClass: "dropdown" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: {
                                        type: "button",
                                        "data-bs-toggle": "dropdown",
                                        "aria-expanded": "false",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa-solid fa-caret-down me-0",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ul", { staticClass: "dropdown-menu" }, [
                                    _c("li", [
                                      f.status == "error"
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "dropdown-item btn btn-link",
                                              attrs: {
                                                href:
                                                  "/integrations/" +
                                                  _vm.client.url +
                                                  "/file_errors",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-wrench me-2",
                                              }),
                                              _vm._v("Fix the Error"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      f.dataset != null
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "dropdown-item btn btn-link",
                                              attrs: {
                                                href:
                                                  "/integrations/" +
                                                  _vm.client.url +
                                                  "/mapping/data_set/" +
                                                  f.dataset.id,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-edit me-2",
                                              }),
                                              _vm._v("Edit Data Set"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _vm.isDataFile(f)
                                      ? _c("li", [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "dropdown-item btn btn-link",
                                              attrs: {
                                                href: "#",
                                                "data-bs-toggle": "modal",
                                                "data-bs-target":
                                                  "#preview-modal",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewTable(f)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-eye me-2",
                                              }),
                                              _vm._v("Preview File"),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.withinThirtyDays(f)
                                      ? _c("li", [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "dropdown-item btn btn-link",
                                              attrs: {
                                                href:
                                                  "/integrations/" +
                                                  _vm.client.url +
                                                  "/files/download/" +
                                                  f.id,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-download me-2",
                                              }),
                                              _vm._v("Download File"),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "dropdown-item btn btn-link",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFile(f)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-trash me-2 ms-1",
                                          }),
                                          _vm._v("Delete File"),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          : _c("td"),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.local_files.length < _vm.local_conn.num_files
                ? _c("div", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary mt-3 mb-3",
                        on: {
                          click: function ($event) {
                            return _vm.loadFiles()
                          },
                        },
                      },
                      [_vm._v("Load More Files")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(5),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "preview-modal",
          "aria-labelledby": "preview-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog", class: _vm.modal_size }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "preview-modal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closePreviewModal()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleModalSize()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa",
                      class:
                        _vm.modal_size == "modal-xl"
                          ? "fa-expand"
                          : "fa-compress",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v("Preview File Contents"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body p-0" }, [
              typeof _vm.table_data === "string" && _vm.table_data == ""
                ? _c("div", { staticClass: "m-2 mb-4 text-center" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("span", { staticClass: "ms-2" }, [_vm._v("Loading...")]),
                  ])
                : Array.isArray(_vm.table_data) && _vm.table_data.length > 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "table-responsive gy-0 m-0 mt-0" },
                      [
                        _c("table", { staticClass: "table gy-0 m-0" }, [
                          _c("thead", { staticClass: "table-dark" }, [
                            _c(
                              "tr",
                              _vm._l(
                                Object.keys(_vm.table_data[0]),
                                function (row, index) {
                                  return _c(
                                    "th",
                                    {
                                      staticClass: "align-middle pt-0",
                                      class: index == 0 ? "ps-3" : "",
                                    },
                                    [_vm._v(_vm._s(_vm.originalHeader(row)))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            { staticClass: "table-border-bottom-0" },
                            _vm._l(_vm.table_data, function (row) {
                              return _c(
                                "tr",
                                _vm._l(row, function (col, index) {
                                  return _c(
                                    "td",
                                    { class: index == 0 ? "ps-3" : "" },
                                    [_vm._v(_vm._s(col))]
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ])
                : Array.isArray(_vm.table_data) && _vm.table_data.length == 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "alert alert-warning box-content m-2" },
                      [
                        _vm._v(
                          "\n                            No data was returned.\n                        "
                        ),
                      ]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "alert alert-danger box-content m-2" },
                      [
                        _vm._v(
                          "\n                            There was an error pulling the data:"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("em", [_vm._v(_vm._s(_vm.table_data))]),
                      ]
                    ),
                  ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h3", [_vm._v("Upload a New Data File")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fa-solid fa-gear me-0" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "data_qualifier" } },
      [
        _vm._v("Text Qualifier for Files "),
        _c("i", {
          staticClass: "fas fa-question-circle text-primary",
          attrs: {
            "data-bs-toggle": "tooltip",
            title:
              "For CSV or TXT files, enter the character used to enclose data fields containing the delimiter; it is typically double quotes (”).",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overflow-hidden rounded-top" }, [
      _c("div", { staticClass: "p-1 bg-dark" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "align-middle pt-0" }, [_vm._v("File Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0 d-none d-md-table-cell" }, [
          _vm._v("Source"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0" }, [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0" }, [
          _vm._v("Data Set Name"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle pt-0 d-none d-md-table-cell" }, [
          _vm._v("Uploaded On"),
        ]),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "align-middle pt-0", staticStyle: { width: "70px" } },
          [_vm._v("Actions")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-5" }, [
      _c("em", [
        _vm._v(
          "Files are automatically deleted 30 days after the initial upload, however, any data processed will be retained until your account is closed or deletion is requested."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-warning float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }