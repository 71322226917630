<template>
    <div class="segment-page p-2" id="segment-page">
        <h1 class="mb-3">{{page_name}} {{page_name == 'Add' ? 'an' : 'the'}} Audience</h1>

        <div class="card box-content p-0 mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col mb-3">
                        <h6 class="mt-2">Audience Source</h6>
                    </div>
                    <!--
                    <div class="col mb-3">
                        <button v-if="page_name =='Edit' && !local_segment.analysis && local_segment.source=='data_blend' && local_segment.data_source && local_segment.data_source.type=='constituent'"  
                        type="button" class="btn btn-secondary float-end" :disabled="form.saving" @click.prevent="openAnalysisAudienceModal('Add')" 
                        data-bs-toggle="modal" :data-bs-target="'#analysis-segment-modal-'+ local_segment.id">
                            <span><i class="fa fa-btn fa-plus"></i> Add Analysis Audience</span>
                        </button>

                        <button v-if="page_name =='Edit' && local_segment.analysis && local_segment.source=='data_blend' && local_segment.data_source && local_segment.data_source.type=='constituent'"  
                        type="button" class="btn btn-secondary float-end" :disabled="form.saving" @click.prevent="openAnalysisAudienceModal('Update')" 
                        data-bs-toggle="modal" :data-bs-target="'#analysis-segment-modal-'+ local_segment.id">
                            <span><i class="fa fa-btn fa-edit"></i> Update Analysis Audience</span>
                        </button>
                    </div>-->
                </div>

                <div class="row">
                    <div class="col mb-3">
                        <label class="form-label">Data Source</label>
                        <select class="form-select" name="source" @change="changeSource()" :disabled="(local_segment.id != null)"
                            v-model="local_segment.combo_source" >
                            <option v-for="s in sources" :value="s.value">{{s.text | propercase}}</option>
                        </select>
                    </div>
                    <div class="col mb-3" :class="((errors.name != '' )? 'is-invalid':'')">
                        <label class="form-label">Audience Name</label>
                        <input type="text" class="form-control" name="name"
                            v-model="local_segment.name"
                            :class="((errors.name != '' )? 'is-invalid':'')" >
                        <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                            {{errors.name }}
                        </div>
                    </div>
                    <div class="col-2 mb-3">
                        <label class="form-label">Expires At <i class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="top" title="Set a date for this audience to be automatically removed. This is helpful for data that is only being used for a short time period. The data will not be removed from any connected systems--it will simply stop syncing."></i></label>
                        <datepicker v-model="local_segment.expires_at" :use-utc="true"  :disabled-dates="disable_dates" :open-date="new Date()"
                            format="MMM d, yyyy" input-class="form-control date" :clear-button="true"
                            placeholder='MM/DD/YYYY' :bootstrap-styling="true" ></datepicker>
                    </div>
                </div>

                <h6 class="mt-4">Audience Definition</h6>
                <div class="row">
                    <div class="col">
                        <div :class="((errors.value != '' )? 'is-invalid':'')" >
                            <query-builder :client="client" v-if="local_segment.data_source_id != null" v-model="local_segment.filter" :include_trans="true"
                            :data_source_id="local_segment.data_source_id" :source="local_segment.source" :in_card="false"></query-builder>
                        </div>
                        <div class="text-danger invalid-feedback mb-2" v-if="errors.value != ''">
                            {{errors.value }}
                        </div>
                    </div>
                    <div class="col-xl-4 d-none d-xl-block drill-down pe-3">
                        <preview-table :client="client" :source="local_segment"
                            :source_columns="db_columns"></preview-table>
                    </div>
                </div>
            </div>         
        </div>

        <div class="row mt-2" v-if="form.error">
            <div class="col">
                <div class="alert alert-danger">
                    There was an issue saving your audience. Check the fields above to get more information.
                </div>
            </div>
        </div>
        <div class="mb-4 mt-2">
            <button type="button" class="btn btn-primary btn-big save-button" :disabled="form.saving"
                @click.prevent="saveSegment();"><span v-if="form.saving"><div class="spinner-border  spinner-border-sm float-left" role="status"> <span class="visually-hidden">Saving...</span></div> Saving</span><span v-else><i class="fa fa-btn fa-save"></i> Save</span></button>
        </div>   


        <div class="modal fade" :id="'analysis-segment-modal-'+ local_segment.id" aria-labelledby="analysis-segment-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end close-modal" :data-bs-dismiss="'analysis-segment-modal-'+ local_segment.id" 
                                @click="closeModal('analysis-segment-modal-'+ local_segment.id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">{{ modal_action }} Analysis Audience</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <analysis-segment-builder :client="client" :segment_selected="local_segment" :modal_action="modal_action"
                        @updated="analyasisSegmentUpdated" @deleted="analyasisSegmentDeleted"></analysis-segment-builder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal, Datepicker
        },
        props: ['client', 'segment', 'datasets', 'datablends'],
        data() {
            return {
                form: {
                    saving: false,
                    error: false
                },
                errors :{
                    name: "",
                    value: ""
                },
                local_segment:{},
                disable_dates: {
                    to: new Date(), // Disable all dates up to today
                },
                db_columns: [],

                //analysis audience modal
                modal_action: '',
            };
        },
        beforeMount() { 
            this.local_segment = {...this.segment};
            
            // If url contains dataset id
            const params = new URLSearchParams(window.location.search);
            if(params.get('dataset') != null){
                var dataset_id = parseInt(params.get('dataset'));
                this.local_segment.source = 'data_set'; 
                this.local_segment.data_source_id = dataset_id;
            }else if(params.get('datablend') != null){
                var datablend_id = parseInt(params.get('datablend'));
                this.local_segment.source = 'data_blend';
                this.local_segment.data_source_id = datablend_id;
            } else if(!this.local_segment.id){
                if(this.datablends != null){
                    this.local_segment.source = 'data_blend';
                    this.local_segment.data_source_id = this.datablends[0].id;            
                } else if (this.datasets != null){
                    this.local_segment.source = 'data_set';
                    this.local_segment.data_source_id = this.datasets[0].id;   
                }
            } 
            this.local_segment.data_source = {
                data_source: this.local_segment.source,
                type: this.local_segment.data_source? this.local_segment.data_source.type: null,
                id: this.local_segment.data_source_id
            };

            this.local_segment.combo_source = this.local_segment.source + "-" + this.local_segment.data_source_id; 
            this.loadDatabaseColumns();
        },
        computed: {
            sources() {
                var sources = [];

                if(this.datablends != null)
                    for(var i = 0; i < this.datablends.length; i++)
                        sources.push({text: this.datablends[i].type + " Data Blend", value: 'data_blend-' + this.datablends[i].id});
                if(this.datasets != null)
                    for(var i = 0; i < this.datasets.length; i++)
                        sources.push({text: this.datasets[i].name, value: 'data_set-' + this.datasets[i].id});

                return sources;
            },
            page_name() {
                if(this.local_segment.id == null || this.local_segment.id <= 0)
                    return 'Add';
                //If the URL contains the word "Clone"
                else if(window.location.href.indexOf('clone') > -1)
                    return 'Copy';
                else
                    return 'Edit';
            }
        },
        methods: {
            loadDatabaseColumns() {
                let data = {
                    client: this.client,
                    include_trans: true,
                    source: this.local_segment.data_source ? this.local_segment.data_source.data_source : this.local_segment.source,
                    data_source_id: this.local_segment.data_source_id,
                };
                window.axios.post('/api/bigquery/get_db_columns', data)
                .then(response => {
                    this.db_columns = response.data.columns;
                });
            },
            changeSource() {
                var parts = this.local_segment.combo_source.split('-');
                this.local_segment.source = parts[0];
                this.local_segment.data_source_id = parts[1];
                this.local_segment.data_source = {
                    data_source: this.local_segment.source,
                    id: this.local_segment.data_source_id
                };

                this.loadDatabaseColumns();
                this.$forceUpdate();
            },
            saveSegment() {
                this.form.error = !this.filterLogicIsValid(this.local_segment.filter)

                //Verify they have named the segment
                if(!this.local_segment.name || this.local_segment.name == '') {
                    this.errors.name = 'Please enter a name for the segment';
                    this.form.error = true;
                }
                else
                    this.errors.name = "";

                if(this.form.error)
                    return;

                var self = this;
                this.form.saving = true;

                if(this.local_segment.id == null) {
                    window.axios.post('/api/segment/'+this.client.url, this.local_segment)
                      .then(response => {

                        self.form.saving = false;
                        if(response.status == 200) {
                            self.local_segment = response.data.data;
                            // self.$emit('addSegmentFromModal', response.data.data);
                            // self.closeModal();

                            Swal.fire({
                                title: "Success!",
                                html: "The segment name <strong>"+response.data.data.name +"</strong> was successfully created. You'll be able to push this segment to other integrated tools and use it as a filter for analysis. You will be directed back to the previous page you were on",
                                icon: "success",
                                iconColor:  "#25473E",
                                confirmButtonColor: "#25473E"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if (document.referrer)
                                        window.location.href = document.referrer;
                                    else
                                        history.back();
                                }
                            });;
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }
                  //Just update the old filter
                  else {

                    window.axios.put('/api/segment/'+this.client.url + '/' + this.local_segment.id, this.local_segment)
                      .then(response => {

                        self.form.saving = false;
                        if(response.status == 200) {
                            // self.$emit('updateSegmentFromModal', response.data.data);
                            // self.closeModal();

                            Swal.fire({
                                title: "Success!",
                                html: "The audience was successfully updated. On the next sync, the updated data will be pushed into any connected systems. You will be directed back to the previous page you were on",
                                icon: "success",
                                iconColor:  "#25473E",
                                confirmButtonColor: "#25473E"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if (document.referrer)
                                        window.location.href = document.referrer;
                                    else
                                        history.back();
                                }
                            });
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                      });
                  }
            },
            filterLogicIsValid(filter) {
                var is_valid = true;

                //First reset the errors
                this.errors.value = "";

                //If they didn't enter any logic
                if(filter == "" || filter == null){
                    this.errors.value = "Please select a column and value to use with the filter."
                    is_valid = false;
                }
                //If they left one of the inputs blank in the logic units
                else {
                    var logic_units = filter.logic_units;
                    for(var i = 0; i < logic_units.length; i++) {
                        if(logic_units[i].db_column == "" || logic_units[i].db_column == null || logic_units[i].db_column.value == ""
                            || logic_units[i].operator == undefined 
                            || ( typeof logic_units[i].operator === 'string' && logic_units[i].operator == "" )
                            || ( logic_units[i].operator instanceof Object && logic_units[i].operator.value == "" )
                            || (logic_units[i].value == "" && logic_units[i].operator.value.indexOf("NULL") == -1)
                            ){
                            is_valid = false;
                            this.errors.value = "Please enter values for all of the inputs above."
                        }
                    }
                }
                return is_valid;
            },
            closeModal(modal_ID){
                var myModalEl = document.getElementById(modal_ID)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
            openAnalysisAudienceModal(action){
                this.modal_action = action;
            },
            analyasisSegmentUpdated(analysis_audience){
                this.closeModal('analysis-segment-modal-'+ this.local_segment.id);
                Swal.fire({
                    html: '<h4 class="mb-0 text-white">Analysis Audience Saved!</h4>',
                    target: '#segment-page',
                    icon: 'success',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 2000,
                    iconColor: 'white',
                    customClass: {
                        popup: 'bg-primary'
                    },
                });
                this.local_segment.analysis = analysis_audience;
                this.$forceUpdate();
            },
            analyasisSegmentDeleted(){
                this.closeModal('analysis-segment-modal-'+ this.local_segment.id);
                Swal.fire({
                    html: '<h4 class="mb-0 text-white">Analysis Audience Deleted!</h4>',
                    target: '#segment-page',
                    icon: 'success',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 2000,
                    iconColor: 'white',
                    customClass: {
                        popup: 'bg-primary'
                    },
                });
                this.local_segment.analysis = null;
                this.$forceUpdate();
                                
            }
        }
    }
</script>
